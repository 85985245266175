import EventEmitter from "eventemitter3";
import TriggerCodes from "./codes/TriggerCodes.js";
import TriggerDispatcher from "./TriggerDispatcher.js";
import CharacterValidator from "./validators/CharacterValidator.js";
import ItemValidator from "./validators/ItemValidator.js";
import MiscValidator from "./validators/MiscValidator.js";
import WorldValidator from "./validators/WorldValidator.js";

export default class TriggerManager extends EventEmitter
{
    constructor()
    {
        super();

        TriggerManager.instance = this;
    }

    get ARGUMENT_TYPES() {
        if (!this.argumentTypes)
        {
            this.argumentTypes = {
                //Map
                "TILE" :            1,
                "LOCATION":         2,
                "BARN_ZONE":        3,
                "RADIO_CLICK":      4,
                "STEPS_DOWN":       5,
                "BROTHERS":         6,
                //Character
                "STAT" :            7,
                "CHARACTER_STATE":  8,
                //Item
                "ITEM_FOUND":       9,
                "ITEM_USED":        10,
                "ITEM_CRAFTED":     11,
                "BACKPACK_CRAFTED": 12,
                //Mission
                "MISSION":          13,
                "OBJECTIVE":        14,
                //Misc
                "TIME":             15,
                "TRIGGER":          16,
                "TUTORIAL":         17,
                "CLICK_ON_MAP":     18,
                "CLICK_INDOOR_OBSTACLE": 19,
                "INDOOR_ROOM_ID": 20,
                "CLICK_OUTDOOR_OBSTACLE": 21

            };
        }
        return this.argumentTypes;
    }

    get TRIGGER_COMPLETED() { return "trigger-completed"; }

    //---------------------------------------------------------
    //  DEPENDENCIES
    //---------------------------------------------------------
    get WorldManager() { return this.dependencies.get("WorldManager"); }
    get GameManager() { return this.dependencies.get("GameManager"); }
    get SaveManager() { return this.dependencies.get("SaveManager"); }
    get ResponsiveManager() { return this.dependencies.get("ResponsiveManager"); }
    get UIManager() { return this.dependencies.get("UIManager"); }
    //---------------------------------------------------------

    /*******************************************
    *   INITIALIZATION
    *******************************************/
    init(dependencies)
    {
        this.dependencies = dependencies;
        this.triggerList = {};
        this.actionQueue = {};
        
        this.createClosure();
        this.bindEvents();
        this.createValidators();

        this.dispatcher = new TriggerDispatcher(this.dependencies);
    }

    createClosure()
    {
        this.fctMouseDown = this.onMouseDown.bind(this);
        // this.fctMouseMove =  this.onMouseMove.bind(this);
        // this.fctMouseUp =  this.onMouseUp.bind(this);
        this.fctTouchStart =  this.onTouchStart.bind(this);
        // this.fctTouchMove =  this.onTouchMove.bind(this);
        // this.fctTouchEnd =  this.onTouchEnd.bind(this);
    }

    bindEvents()
    {
        //Mouse
        window.addEventListener("mousedown", this.fctMouseDown);
        // window.addEventListener("mousemove", this.fctMouseMove);
        // window.addEventListener("mouseup", this.fctMouseUp);

        //Mobile touches
        window.addEventListener("touchstart", this.fctTouchStart);
        // window.addEventListener("touchmove", this.fctTouchMove);
        // window.addEventListener("touchend", this.fctTouchEnd);
    }

    onMouseDown(e)
    {
        if (this.UIManager.canWorldClick(e.clientX, e.clientY))
        {
            this.launchTriggerFromClick(e.clientX, e.clientY);
        }
    }

    onTouchStart(e)
    {
        if (this.lastInputId === null)
        {
            let touch = e.touches[0];
            if (this.CanMove && this.UIManager.canWorldClick(touch.clientX, touch.clientY))
            {
                this.launchTriggerFromClick(touch.clientX, touch.clientY);
            }
        }
    }

    launchTriggerFromClick(fX, fY)
    {
        let screenPadding = this.ResponsiveManager.ScreenPadding;
        fX -= screenPadding;

        if (this.WorldManager.Environment && this.WorldManager.Environment.Scene.Camera)
        {
            let worldPos = this.WorldManager.Environment.Scene.Camera.screenToWorldPosition(fX, fY, true);
            let gridPos = this.WorldManager.Grid.worldToGridPosition(worldPos.x, worldPos.z);

            this.validators["world"].onCheckClick(gridPos);
        }


    }

    createValidators()
    {
        this.validators = {
            "character": new CharacterValidator(this.dependencies),
            "item":      new ItemValidator(this.dependencies),
            "misc":      new MiscValidator(this.dependencies),
            "world":     new WorldValidator(this.dependencies),
        }
    }

    /*******************************************
    *   CALULCATIONS
    *******************************************/
    createValueArguments (strType, arrValues)
    {
        return {
            "type":   strType, 
            "values": arrValues
        };
    }

    calculateValidity (strOperator, value1, value2)
    {
        return (strOperator == TriggerCodes.OPERATOR_EQUALS && value1 === value2) ||
               (strOperator == TriggerCodes.OPERATOR_NOT_EQUALS && value1 !== value2) ||
               (strOperator == TriggerCodes.OPERATOR_GREATER_THAN && value1 > value2) ||
               (strOperator == TriggerCodes.OPERATOR_GREATER_THAN_EQUALS && value1 >= value2) ||
               (strOperator == TriggerCodes.OPERATOR_LESS_THAN && value1 < value2) ||
               (strOperator == TriggerCodes.OPERATOR_LESS_THAN_EQUALS && value1 <= value2);
    }

    /*******************************************
    *   TRIGGER PROCESSING
    *******************************************/
    getTriggerSaveKey (strId)
    {
        return "trg_" + strId;
    }

    getTriggerData (strId)
    {
        return this.SaveManager.getFromSave(this.getTriggerSaveKey(strId), {"r":0, "t":0});
    }

    registerTriggerCompletion (strId)
    {
        let triggerData = this.getTriggerData(strId);
        triggerData.r++;
        triggerData.t = new Date().getTime();

        this.SaveManager.setFromSave(this.getTriggerSaveKey(strId), triggerData);
    }

    forceTriggerStart (id, nbOfActionsToSkip = 0)
    {
        for (let key in this.triggerList)
        {
            let trigger = this.triggerList[key];

            if (trigger.id == id)
            {
                this.executeTrigger(key, nbOfActionsToSkip);

                return true;
            }
        }

        return false;
    }

    getTrigger (id)
    {
        for (let key in this.triggerList)
        {
            let trigger = this.triggerList[key];

            if (trigger.id == id)
            {
                return trigger;
            }
        }

        return null;
    }

    findTriggersAndExecute (objSupportedConditions, strArgumentType, arrArguments)
    {
        for (let key in this.triggerList)
        {
            let trigger = this.triggerList[key];
            let conditions = trigger.c;

            if (trigger.id == 134)
            {

                let a = 1;
            }
            for (let i = 0; i < conditions.length; i++)
            {
                let t = conditions[i].t;

                if (t in objSupportedConditions)
                {
                    let args = this.createValueArguments(strArgumentType, arrArguments);
                    if (this.validateTrigger(key, args))
                    {
                        this.executeTrigger(key);
                    }
                    break;
                }
            }
        }
    }

    executeTrigger (strId, nbOfActionsToSkip = 0)
    {
        console.log("%c ================== " , "background:#000099; color:#FFFFFF;")
        console.log("%c EXECUTE TRIGGER " + strId, "background:#000099; color:#FFFFFF;")
        console.log("%c ================== " + strId, "background:#000099; color:#FFFFFF;")

        if (strId in this.triggerList)
        {
            if (!this.actionQueue[strId])
            {
                this.actionQueue[strId] = [];
            }
            for (var i = nbOfActionsToSkip; i < this.triggerList[strId].a.length; i++)
            {
                this.actionQueue[strId].push(this.triggerList[strId].a[i]);
            }
            this.executeNextActionInQueue (strId);
        }
    }

    jumpToAction (strEventID, idAction)
    {
        if (strEventID in this.actionQueue)
        {
            let arrActions = this.actionQueue[strEventID];
            let bFound = false;

            while (arrActions.length > 0 && !bFound)
            {
                let action = arrActions[0];

                bFound = action.id == idAction;

                if (!bFound)
                {
                    arrActions.shift();
                }
            }
        }
    }


    executeNextActionInQueue (strId, ...args)
    {
        if (strId in this.actionQueue)
        {
            if (this.actionQueue[strId].length > 0)
            {
                let action = this.actionQueue[strId].shift();
                this.dispatcher.dispatchAction(action.t, strId, action.v, (action.w ? true : false), args);
            }
            else
            {
                delete this.actionQueue[strId];
                this.registerTriggerCompletion(strId);
                setTimeout(function(strId) {
                    this.emit(this.TRIGGER_COMPLETED, strId);
                }.bind(this, strId), 1);
            }
        }
        return false;
    }

    /*******************************************
    *   VALIDATIONS
    *******************************************/
    validateTrigger (strId, objNewValues = null)
    {
        let bOk = !this.actionQueue[strId] && this.validateRepeat(strId);
        if (bOk)
        {
            if (strId in this.triggerList)
            {
                let arrConditions = this.triggerList[strId].c;
                for (var i = 0; i < arrConditions.length; i++)
                {
                    let condition = arrConditions[i];
                    let t = condition.t;
                    let v = condition.v;
                    bOk = this.validateCondition(t, v, objNewValues);
                    if (!bOk)
                    {
                        break;
                    }
                }
            }
        }

        return bOk;
    }

    validateRepeat (strId)
    {
        let data = this.getTriggerData(strId);
        let bOk = false;
        if (strId in this.triggerList)
        {
            let repeatDef = this.triggerList[strId].r;
            //Repeat one time only
            if (repeatDef.t == TriggerCodes.REPEAT_ONCE)
            {
                bOk = data.r <= 0;
            }
            //Repeat for a maximum number of times
            else if (repeatDef.t == TriggerCodes.REPEAT_FINITE)
            {
                bOk = data.r <= repeatDef.v;
            }
            //Repeat at a minimum of seconds between each time
            else if (repeatDef.t == TriggerCodes.REPEAT_TIME)
            {
                let now = triggerData.t = new Date().getTime();
                bOk = now - data.t >= repeatDef.v;
            }
            //Always repeat
            else if (repeatDef.t == TriggerCodes.REPEAT_INFINITE)
            {
                bOk = true;
            }
        }
        return bOk;
    }

    validateCondition (strType, arrValues, objNewValues = null)
    {
        let bOk = true;
        for (let key in this.validators)
        {
            bOk = bOk && this.validators[key].validateCondition(strType, arrValues, objNewValues)

        }
        return bOk;
    }

    getCompareValidators ()
    {
        let validators = {};

        for (let key in this.validators)
        {
            let vals = this.validators[key].compareConditions;

            validators = Object.assign(validators, vals);

        }

        return validators;
    }

    /*******************************************
    *   EVENTS
    *******************************************/
    onLoadComplete(objSettings)
    {
        this.triggerList = objSettings.triggers;
    }

    onDispatchComplete (strEventId, ...args)
    {
        this.executeNextActionInQueue(strEventId, args);
    }
}