import { string2hex }  from '@pixi/utils';
import { TextStyle } from '@pixi/text';
import { TextMetrics } from '@pixi/text';
import BasePopup from "../BasePopup.js";
import CloseButton from "../buttons/CloseButton.js";
import Display from "../../../utils/Display.js";
import GraphicsResponsive from "../responsive/GraphicsResponsive.js";
import TextButton from "../buttons/TextButton.js";
import TextResponsive from "../responsive/TextResponsive.js";

export default class ChoicePopup extends BasePopup
{
    constructor()
    {
        super();
    }

    get EVENT_POSITIVE() { return "choice-window-positive"; }
    get EVENT_NEGATIVE() { return "choice-window-negative"; }

    get Title() { return this.title; }
    set Title(strNewValue) { this.title = strNewValue; this.build(); }

    get Description() { return this.description; }
    set Description(strNewValue) { this.description = strNewValue; this.build(); }

    get ShowCloseButton() { return this.showClose; }
    set ShowCloseButton(bNewValue) { this.showClose = bNewValue; this.build(); }

    get PositiveLabel() { return this.positiveLabel; }
    set PositiveLabel(strNewValue) { this.positiveLabel = strNewValue; this.build(); }

    get NegativeLabel() { return this.negativeLabel; }
    set NegativeLabel(strNewValue) { this.negativeLabel = strNewValue; this.build(); }

    get BgWidth() { return this.BgHeight * 1.75; }
    get BgHeight() { return this.evaluate("(ih * " + this.UI.ResponsiveManager.PopupSize + ")"); }
    get BgX() { return 0; }
    get BgY() { return 0; }

    get BackgroundColor() { return string2hex(this.UI.Values.modal.background.color);}
    get StrokeWidth() { return this.evaluate(this.valueFormula(this.UI.Values.modal.stroke.size), 0, 0, 0, 0);}
    get StrokeColor() { return string2hex("color" in this.UI.Values.modal.stroke ? this.UI.Values.modal.stroke.color : "#000000");}
    get CornerRadius() { return this.evaluate(this.valueFormula(this.UI.Values.modal.corner.radius), 0, 0, 0, 0);}

    /*******************************************
    *   INITIALIZATION
    *******************************************/
    /**
        Parameters to pass to the init function:
        - ui:           UI section object where this component resides
        - title:        Title to display for this popup
        - description:  Message to display as the description
        - clickToHide:  (Optional) If the popup should hide when the player clicks on the overlay behind. Default is TRUE
        - showOnStart:  (Optional) If the popup should be shown on start. Default is TRUE
        - showClose:    (Optional) If the close button should be shown. Default is TRUE
        - createOverlay (Optional) If this popup should create it's own overlay for itself. If not, it will use the general UI overlay. Default is FALSE
        - sideBySide    (Optional) If this popup should be positioned side-by-side with other popups on screen. Default is TRUE
        - linked        (Optional) Linked popup to this one. If this one closes, the linked popup will close too. Default is NULL
    */
    init(meta)
    {
        this.title = meta.title;
        this.description = meta.description;
        this.showClose = ("showClose" in meta ? meta.showClose : true);

        this.graphics = {};
        this.texts = {};
        this.sprites = {};

        this.positiveLabel = meta.ui.LabelManager.translate("Menu_Efface_Oui");
        this.negativeLabel = meta.ui.LabelManager.translate("Menu_Efface_Non");

        return super.init(meta);
    }

    createClosure ()
    {
        super.createClosure();

        this.fctOnCloseClick = this.onCloseClick.bind(this);
        this.fctOnPositiveClick = this.onPositiveClick.bind(this);
        this.fctOnNegativeClick = this.onNegativeClick.bind(this);
    }

    destroy(options)
    {
        if (this.closeButton)
        {
            this.closeButton.off(this.closeButton.EVENT_CLICK, this.fctOnCloseClick);
            this.closeButton.destroy();
        }

        if (this.positiveButton)
        {
            this.positiveButton.off(this.positiveButton.EVENT_CLICK, this.fctOnPositiveClick);
            this.negativeButton.destroy();
        }

        if (this.negativeButton)
        {
            this.negativeButton.off(this.negativeButton.EVENT_CLICK, this.fctOnNegativeClick);
            this.negativeButton.destroy();
        }

        super.destroy(options);
    }

    /*******************************************
    *   BUILD
    *******************************************/
    build()
    {
        super.build();

        let titleHeight = this.buildTitle();
        let rect = this.buildText(titleHeight);
        this.buildChoiceButtons(rect.x, rect.y, rect.width, rect.height);
        this.buildCloseButton();
    }

    clean()
    {
        for (let key in this.graphics)
        {
            this.graphics[key].clear();
        }

        super.clean();
    }

    buildTitle()
    {
        if (!this.graphics.title)
        {
            this.graphics.title = new GraphicsResponsive().init({"ui": this.UI});
            this.addChild(this.graphics.title);
        }

        if (!this.texts.title)
        {
            this.texts.title = new TextResponsive().init({"ui": this.UI});
            this.addChild(this.texts.title);
        }

        let graphics = this.graphics.title;

        let text = this.texts.title;
        let label = this.Title;

        let fontSize = this.evaluate(this.valueFormula(this.UI.Values.general.title.size * 1.2));
        fontSize = Math.max(fontSize, this.UI.ResponsiveManager.TitleMinFontSize);

        let style = new TextStyle({
            fontFamily: this.UI.Values.general.title.font,
            fontSize,
            fontWeight: this.UI.Values.general.title.weight,
            fill: this.UI.Values.general.text.color,
            align: "center"
        });

        let metrics = TextMetrics.measureText(label, style);

        let bgX = this.BgX;
        let bgY = this.BgY;
        let bgWidth = this.BgWidth;
        let bgHeight = this.BgWidth;

        let paddingHorizontal = metrics.width * 1.5;
        let paddingVertical = metrics.height * 0.5;
        let zoneWidth = metrics.width + paddingHorizontal
        let zoneHeight = metrics.height + paddingVertical;
        let zoneX = bgX + bgWidth / 2 - zoneWidth / 2;
        let zoneY = bgY - zoneHeight / 2;

        graphics.position.set(zoneX, zoneY);

        let bgColor = this.StrokeColor;
        let cornerRadius = this.CornerRadius;

        graphics.beginFill(bgColor, 1);
        if (cornerRadius > 0)
        {
            graphics.drawRoundedRect(0, 0, zoneWidth, zoneHeight, cornerRadius);
        }
        else
        {
            graphics.drawRect(0, 0, zoneWidth, zoneHeight);
        }
        graphics.endFill();

        let textX = bgX + bgWidth / 2 - metrics.width / 2
        let textY = zoneY + zoneHeight / 2 - metrics.height / 2;

        text.text = label;
        text.style = style;
        text.width = metrics.width;
        text.height = metrics.height;
        text.position.set(textX, textY);

        return zoneHeight;
    }

    buildText(fTitleHeight)
    {
        if (!this.texts.desc)
        {
            this.texts.desc = new TextResponsive().init({"ui": this.UI});
            this.addChild(this.texts.desc);
        }

        let descLabel = this.Description;
        let bgWidth = this.BgWidth;
        let totalSpace = this.BgHeight * 0.55;

        let fontSize = this.evaluate(this.valueFormula(this.UI.Values.general.text.size * 0.85));
        fontSize = Math.max(fontSize, this.UI.ResponsiveManager.TextMinSize);

        let style = new TextStyle({
            fontFamily: this.UI.Values.general.text.font,
            fontSize,
            fontWeight: this.UI.Values.general.text.weight,
            fill: this.UI.Values.general.text.color,
            align: "center",
            wordWrap: true,
            wordWrapWidth: bgWidth * 0.85
        });

        let metrics = TextMetrics.measureText(descLabel, style);

        let paddingTop = fTitleHeight / 2;

        let x = bgWidth / 2 - metrics.width / 2;
        let y = paddingTop + totalSpace / 2 - metrics.height / 2;

        this.texts.desc.text = descLabel;
        this.texts.desc.style = style;
        this.texts.desc.width = metrics.width;
        this.texts.desc.height = metrics.height;
        this.texts.desc.position.set(x, y);

        return {"x": x, "y": y, "width": metrics.width, "height": totalSpace};
    }

    buildChoiceButtons(fTextX, fTextY, fTextWidth, fTextHeight)
    {
        if (!this.positiveButton)
        {
            this.positiveButton = new TextButton().init({"ui": this.UI, "text": this.PositiveLabel});
            this.positiveButton.on(this.positiveButton.EVENT_CLICK, this.fctOnPositiveClick);//@
            this.addChild(this.positiveButton);
        }

        if (!this.negativeButton)
        {
            this.negativeButton = new TextButton().init({"ui": this.UI, "text": this.NegativeLabel});
            this.negativeButton.on(this.negativeButton.EVENT_CLICK, this.fctOnNegativeClick);//@
            this.addChild(this.negativeButton);
        }

        this.positiveButton.Text = this.PositiveLabel;
        this.negativeButton.Text = this.NegativeLabel;

        let positiveSize = this.positiveButton.getButtonSize();

        let bgWidth = this.BgWidth;
        let bgHeight = this.BgHeight;
        let spacing = bgWidth * 0.15;
        let spaceLeft = bgHeight - (fTextY + fTextHeight);

        let positiveX = bgWidth / 2 - spacing / 2 - positiveSize.width;
        let positiveY = (fTextY + fTextHeight) + spaceLeft / 2 - positiveSize.height;

        let negativeX = bgWidth / 2 + spacing / 2;
        let negativeY = positiveY;

        this.positiveButton.rX = [{on:"default", x: positiveX }];
        this.positiveButton.rY = [{on:"default", y: positiveY}];

        this.negativeButton.rX = [{on:"default", x: negativeX }];
        this.negativeButton.rY = [{on:"default", y: negativeY}];

        this.positiveButton.layout(Display.getSize());
        this.negativeButton.layout(Display.getSize());
    }

    buildCloseButton()
    {
        if (!this.closeButton && this.ShowCloseButton)
        {
            this.closeButton = new CloseButton().init({"ui": this.UI, "scale": 0.8});
            this.closeButton.on(this.closeButton.EVENT_CLICK, this.fctOnCloseClick);//@
        }
        else if (this.closeButton && !this.ShowCloseButton)
        {
            this.removeChild(this.closeButton);
            delete this.closeButton;
        }

        if (this.closeButton && this.ShowCloseButton)
        {
            this.addChild(this.closeButton);
        }
        
        if (this.closeButton)
        {
            this.closeButton.build();
        }

        this.closeButton.rX = [{on:"default", x: (this.BgWidth - this.closeButton.ButtonWidth / 3)}];
        this.closeButton.rY = [{on:"default", y: (this.closeButton.ButtonHeight / 4)}];
        this.closeButton.layout(Display.getSize());
    }

    //---------------------------------------------------
    //  EVENTS
    //---------------------------------------------------
    onCloseClick()
    {
        setTimeout(function()
        {
            this.hide();
        }
        .bind(this), 1);
    }

    onPositiveClick()
    {
        this.emit(this.EVENT_POSITIVE, this);
        this.hide();
    }

    onNegativeClick()
    {
        this.emit(this.EVENT_NEGATIVE, this);
        this.hide();
    }
}