export default class Direction
{
    static get North() { return 0; }
    static get East() { return 1; }
    static get South() { return 2; }
    static get West() { return 3; }

    static getAtlasName(iDirection)
    {
        if (iDirection == Direction.North)
        {
            return "back";
        }
        else if (iDirection == Direction.South)
        {
            return "front";
        }
        else if (iDirection == Direction.West)
        {
            return "left";
        }
        else
        {
            return "right";
        }
    }

    static getSpriteName(iDirection)
    {
        if (iDirection == Direction.North)
        {
            return "back";
        }
        else if (iDirection == Direction.South)
        {
            return "front";
        }
        else
        {
            return "lateral";
        }
    }
}