export default class ItemCodes
{
    //TYPES CODES
    //--------------------------------------------------------------
    static get TYPE_ALL() { return "ALL" };
    static get TYPE_EQUIPMENT() { return "EQ" };
    static get TYPE_TOOL() { return "OU" };
    static get TYPE_MATERIAL() { return "MA" };
    static get TYPE_FOOD() { return "NO" };
    static get TYPE_MUTATION_HEAD() { return "MT" };
    static get TYPE_MUTATION_ARM() { return "MB" };
    static get TYPE_MUTATION_LEG() { return "MJ" };
    static get TYPE_KEY() { return "KE" };
    static get TYPE_STEP() { return "STP" };
    static get TYPE_MOVABLE() { return "MV" };

    //EQUIPMENT CODES
    //--------------------------------------------------------------
    static get EQUIPMENT_BODY() { return "BODY" };
    static get EQUIPMENT_HANDS() { return "HANDS" };
    static get EQUIPMENT_FEET() { return "FEET" };
    static get EQUIPMENT_TOOL() { return "TOOL" };
    static get EQUIPMENT_BACKPACK() { return "BACKPACK" };
    static get EQUIPMENT_TOY() { return "TOY" };

    //FISHING CODES
    //--------------------------------------------------------------
    static get FISH_BIG() { return "BIG" };
    static get FISH_SMALL() { return "SMALL" };

    //POWERS CODES
    //--------------------------------------------------------------
     static get POWER_INVISIBLE() { return "INVIS" };
     static get POWER_JUMP() { return "JUMP" };
     static get POWER_PUSH() { return "PUSH" };
     static get POWER_SPIT() { return "SPIT" };
     static get POWER_STOMP() { return "STOMP" };
     static get POWER_SWIM() { return "SWIM" };
}