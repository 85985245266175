import { Container } from '@pixi/display';

export default class BaseComponent extends Container
{
    constructor()
    {
        super();
    }

    get UI() { return this.ui; }

    /*******************************************
    *   INITIALIZATION
    *******************************************/
    /**
        Parameters to pass to the init function:
        - ui:  UI section object where this component resides
    */
    init(meta)
    {
        this.ui = meta.ui;
        
        this.createClosure();
        this.bindEvents();

        this.build();

        return this;
    }

    createClosure ()
    {
        
    }

    bindEvents()
    {
        
    }

    destroy(options)
    {
        super.destroy(options);
    }

    /*******************************************
    *   BUILD
    *******************************************/
    build ()
    {
        this.clean();
    }

    clean()
    {

    }

    /*******************************************
    *   RESPONSIVE
    *******************************************/
    layout (objSize)
    {
        
    }

    containsPoint (point)
    {
        let bounds = this.getBounds();
        return (point.x >= bounds.x) && (point.x <= (bounds.x + bounds.width)) && (point.y >= bounds.y) && (point.y <= (bounds.y + bounds.height)) ;
    }
}