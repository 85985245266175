import { string2hex }  from '@pixi/utils'; 
import ContainerResponsive from "../responsive/ContainerResponsive.js";
import GraphicsResponsive from "../responsive/GraphicsResponsive.js";
import Display from "../../../utils/Display.js";
import Library from "../../../Library.js";
import SpriteResponsive from "../responsive/SpriteResponsive.js";

export default class ResourceFilterControl extends ContainerResponsive
{
    constructor()
    {
        super();
    }

    get EVENT_CLICK() { return "self-click"; }

    get FOOD() { return "food"; }
    get RESOURCES() { return "resources"; }
    get CLOTHES() { return "clothes"; }

    get Type() { return this.type; }

    get BgWidth() { return this.strWidth; }
    get BgHeight() { return this.strHeight; }
    get BgX() { return 0; }
    get BgY() { return 0; }

    get BackgroundColor() { return this.color;}
    get StrokeWidth() { return this.stroke;}
    get CornerRadius() { return this.evaluate(this.valueFormula(this.UI.Values.modal.corner.radius), 0, 0, 0, 0);}

    get StrokeColor()
    { 
        if (this.selected)
        {
            return 0xFFFFCC;
        }
        else
        {
            return 0x26212f;
        }
    }

    get IconTint()
    { 
        if (this.selected)
        {
            return 0xFFFFCC;
        }
        else
        {
            return 0x000000;
        }
    }

    getColorByType(type)
    {
        switch (type)
        {
            case this.UI.ItemManager.ITEM_CODES.TYPE_MATERIAL:
                return this.UI.GameManager.getSetting("character").backpack.itemColor.material;

            case this.UI.ItemManager.ITEM_CODES.TYPE_FOOD:
                return this.UI.GameManager.getSetting("character").backpack.itemColor.food;

            case this.UI.ItemManager.ITEM_CODES.TYPE_EQUIPMENT:
                return this.UI.GameManager.getSetting("character").backpack.itemColor.equipment;
        }

        return 0x009900;
    }

    getTextureNameFromType (type)
    {
        switch (type)
        {
            case this.UI.ItemManager.ITEM_CODES.TYPE_EQUIPMENT:
                return "clothes";

            case this.UI.ItemManager.ITEM_CODES.TYPE_FOOD:
                return "food";

            case this.UI.ItemManager.ITEM_CODES.TYPE_MATERIAL:
                return "resources";

        }
    }

    /*******************************************
    *   INITIALIZATION
    *******************************************/
    /**
        Parameters to pass to the init function:
        - ui:           UI section object where this component resides
        - rWidth:       Responsive equation for the width of this filter
        - rHeight:      Responsive equation for the height of this filter
        - type:         Type of item this filter applies to
        - strokeWidth:  (Optional) Size of the stroke around this filter. Default is 3
    */
    init(meta)
    {
        this.graphics = {};
        this.selected = false;

        this.ui = meta.ui;
        this.strWidth = meta.rWidth;
        this.strHeight = meta.rHeight;
        this.type = meta.type;
        this.color = string2hex(this.getColorByType(meta.type));
        this.stroke = ("strokeWidth" in meta ? meta.strokeWidth : 3);

        return super.init(meta);
    }

    createClosure()
    {
        super.createClosure();
        this.fctClick = this.onClick.bind(this);
    }

    destroy(options)
    {
        for (let key in this.graphics)
        {
            this.removeChild(this.graphics[key]);
            this.graphics[key].destroy(options);
        }
        delete this.graphics;

        if (this.icon)
        {
            this.icon.off("pointerup", this.fctClick);
            this.removeChild(this.icon);
            this.icon.destroy(options);
            delete this.icon;
        }

        super.destroy(options);
    }

    /*******************************************
    *   BUILD
    *******************************************/
    build ()
    {
        super.build();

        this.buildBackground();
        this.buildButton();
    }

    clean()
    {
        for (let key in this.graphics)
        {
            this.graphics[key].clear();
        }
        super.clean();
    }

    buildBackground ()
    {
        if (!this.graphics.background)
        {
            this.graphics.background = new GraphicsResponsive().init({"ui":this.UI});
            this.graphics.background.interactive = true;

            this.addChild(this.graphics.background);
        }

        let graphics = this.graphics.background;

        let width = this.evaluate(this.BgWidth, 0, 0, 0, 0, this);
        let height = this.evaluate(this.BgHeight, 0, 0, 0, 0, this);
        let x = this.BgX;
        let y = this.BgY;

        let bgColor = this.BackgroundColor;
        let strokeWidth = this.StrokeWidth;
        let strokeColor = this.StrokeColor;
        let cornerRadius = this.CornerRadius;

        if (strokeWidth > 0)
        {
            graphics.lineStyle({"color":strokeColor, "width":strokeWidth, "alignment":1});
        }

        graphics.beginFill(bgColor, 1);
        if (cornerRadius > 0)
        {
            graphics.drawRoundedRect(x, y, width, height, cornerRadius);
        }
        else
        {
            graphics.drawRect(x, y, width, height);
        }
        graphics.endFill();
    }

    buildButton ()
    {
        let strTexture = this.getTextureNameFromType(this.type);
        let texture  = Library.getTextureFromAtlas("ui", strTexture);

        let goalRatio = 221 / 159;

        let textureRatio = texture.orig.width / texture.orig.height;

        if (!this.icon)
        {
            this.icon = new SpriteResponsive(texture).init({"ui": this.UI});

            this.icon.interactive = true;
            this.icon.buttonMode = true;
            this.icon.on("pointerup", this.fctClick); //@

            this.addChild(this.icon);
        }

        let rWidth = "";
        let rHeight = "";

        if (textureRatio < goalRatio)
        {
            rHeight = `(${this.strHeight} * 90%)`;
            rWidth = "((" + rHeight + ") * " + textureRatio + ")";

            this.icon.rWidth = [{on: "default", width: rWidth}];
            this.icon.rHeight = [{on: "default", height: rHeight}];
        }
        else
        {
            textureRatio = texture.orig.height / texture.orig.width;
            rWidth = `${this.strWidth} * 90%`;
            rHeight = "((" + rWidth + ") * " + textureRatio + ")";

            this.icon.rWidth = [{on: "default", width: rWidth}];
            this.icon.rHeight = [{on: "default", height: rHeight}];
        }

        this.icon.rX = [ {on:"default", x: `(${this.strWidth} / 2) - (${rWidth} / 2)`}];
        this.icon.rY = [ {on:"default", y: `(${this.strHeight} / 2) - (${rHeight} / 2)`}];

        this.icon.tint = this.IconTint;

        this.icon.layout(Display.getSize());
    }

    /*******************************************
    *   ACTIONS
    *******************************************/
    toggle ()
    {
        this.selected = !this.selected;

        this.interactive = !this.selected;
        this.buttonMode = !this.selected;

        this.build();
    }

    onClick (e)
    {
        if (!this.selected)
        {
            this.toggle();
            this.emit(this.EVENT_CLICK, {type: this.type, active: this.selected, filter: this});
            this.interactive = false;
            this.buttonMode = false;
        }
    }
}