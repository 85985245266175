export default class AbstractSaveAdapter
{
    constructor()
    {
        this.values = {};
    }

    get IsConnected() { return false; }

    get (strKeyName, defaultValue = null)
    {
        if (strKeyName in this.values)
        {
            return this.values[strKeyName];
        }
        return defaultValue;
    }

    set (strKeyName, value)
    {
        this.values[strKeyName] = value;
    }

    deleteKey (strKeyName)
    {
        delete this.values[strKeyName];
    }

    clearWithPrefix (strPrefix)
    {
        let newValues = {};
        for (let key in this.values)
        {
            if (key.indexOf(strPrefix) !== 0)
            {
                newValues[key] = this.values[key];
            }
        }
        this.values = newValues;
    }

    save ()
    {

    }

    load (fctCallback)
    {
        fctCallback();
    }

    clear ()
    {
        
    }
}