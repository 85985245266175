export default class Display
{
    constructor ()
    {
        if (Display.instance)
        {
            throw("Display should not be instantiate directly");
            return;
        }

        Display.instance = this;

        this.arrMeasurable = [];
    }

    addNewMeasurable (measurable)
    {
        this.arrMeasurable.push(measurable);
    }

    getSizeFromMeasurables (id)
    {
        //if we have nothing to measure, use the window
        if (this.arrMeasurable.length === 0)
        {
            let width = window.innerWidth;
            let height = window.innerHeight;

            return {width, height};
        }

        //if there is no id, or if there is only one, we use the first
        let measurable = this.arrMeasurable[0];

        if (id)
        {
            let findMeasurable = this.arrMeasurable.find( m => m.id === id);

            if (findMeasurable)
                measurable = findMeasurable;
        }

        let size = this.getSizeFromMeasurable(measurable);

        return size;
    }

    getSizeFromMeasurable (measurable)
    {
        if (!measurable.closure)
        {
            let width = measurable.display.width;
            let height = measurable.display.height;

            return {width, height};
        }

        return measurable.closure(measurable.display)
    }

    static addMeasurable (measurable)
    {
        Display.instance.addNewMeasurable(measurable);
    }

    /**
     * @Goal: we might not want the app to be fullscreen, so it's better to always get the actual size from here
     * @returns {{width: number, height: number}}
     */
    static getSize (id)
    {
        return Display.instance.getSizeFromMeasurables(id)
    }
}

let __display__ = new Display();