import {DirectionalLight} from "three";
import {Mesh} from "three";
import {MeshBasicMaterial} from "three";
import {PlaneGeometry} from "three";
import {Scene} from "three";
import {Vector3} from "three";
import {WebGLRenderer} from "three";
import EventEmitter from "eventemitter3";
import BaseCamera from "../cameras/BaseCamera.js";
import Constants from "../../utils/Constants.js";
import PostProcessing from "./PostProcessing.js";
import DependencyContainer from "../../utils/DependencyContainer.js";

export default class BaseScene extends Scene
{
    constructor(canvas, options = {})
    {
        super();

        this.eventemitter = new EventEmitter();
        this.canvas = canvas;
        this.options = options;
        this.dependencies = DependencyContainer.getInstance();
    }

    //---------------------------------------------------------
    //  DEPENDENCIES
    //---------------------------------------------------------
    get GameManager() { return this.Dependencies.get("GameManager"); }
    get ResponsiveManager() { return this.Dependencies.get("ResponsiveManager"); }
    get UIManager() { return this.Dependencies.get("UIManager"); }
    get WorldManager() { return this.Dependencies.get("WorldManager"); }
    //---------------------------------------------------------

    get Dependencies() { return this.dependencies; }
    get Camera() { return this.camera; }
    get Lights() { return this.lights; }
    get PostProcessing() { return this.postProcessing; }

    get IsCinematic() { return this.WorldManager.IsCinematicMode; }
    get IsDialogMode() { return this.WorldManager.IsDialogMode; }
    get CanWorldClick() { return this.WorldManager.CanWorldClick; }

    /*******************************************
    *   INITIALIZATION
    *******************************************/
    init()
    {
        this.clickableMeshes = {};
        this.clickablesZones = {};
        this.clickablesMatrixes = {};
        this.postProcessing = {};
        this.elapsed = 0;

        this.createClosure();
        this.bindEvents();

        this.createRenderer();
        this.createCamera();
        this.createLights();
        this.createPostProcessing();
        this.createFloorMesh();

        this.render();

        return this;
    }

    createClosure()
    {
        this.fctOnResize = this.onResize.bind(this);
        this.fctMouseMove = this.onMouseMove.bind(this, true);
        this.fctMouseUp = this.onMouseUp.bind(this, true);
        this.fctTouchEnd = this.onTouchEnd.bind(this, true);
    }

    bindEvents()
    {
        if (this.ResponsiveManager)
            this.ResponsiveManager.on(this.ResponsiveManager.EVENT_RESIZE, this.fctOnResize);

        window.addEventListener("mousemove", this.fctMouseMove);
        window.addEventListener("mouseup", this.fctMouseUp);
        window.addEventListener("touchend", this.fctTouchEnd);
    }

    createRenderer()
    {
        //In our case it's preferable to only create 1 renderer and re-use it across scenes
        if (!this.WorldManager.renderer)
        {
            this.renderer = new WebGLRenderer( { canvas: this.canvas, antialias: true } );
            this.renderer.setPixelRatio(window.devicePixelRatio*8);
            this.renderer.sortObjects = true;

            this.WorldManager.renderer = this.renderer;
        }
        else
        {
            this.renderer = this.WorldManager.renderer;
        }
    }

    createCamera()
    {
        this.camera = new BaseCamera(30, this.ResponsiveManager.Width / this.ResponsiveManager.Height, 0.1, 1000);
    }

    createLights()
    {
        const light = new DirectionalLight( 0xffffff, 1.0 );
        light.position.set( 10, 10, 10 );
        this.add( light );

        this.lights = [light];
    }

    createPostProcessing()
    {
        this.postProcessing = new PostProcessing(this.camera, this.renderer, this);
    }

    /**
        This floor mesh is mainly used to detect clicks through a CameraRaycaster. It's way easier and safer to let ThreeJS
        calculate hit points for us, specially with a perspective camera.
    */
    createFloorMesh()
    {
        let geometry = new PlaneGeometry(500, 500, 10, 10);
        let material = new MeshBasicMaterial({});
        let mesh = new Mesh(geometry, material);
        mesh.visible = false;

        mesh.position.x = 0;
        mesh.position.y = 0;
        mesh.position.z = 0;
        mesh.rotation.x = -Math.PI / 2;
        mesh.rotation.y = this.Camera.rotation.y;
        mesh.rotation.z = this.Camera.rotation.z;

        this.add(mesh);
        this.Camera.FloorMesh = mesh;

        this.floorMesh = mesh;
    }

    destroy()
    {
        cancelAnimationFrame(this.rAFWorld);

        this.postProcessing.destroy();
        this.postProcessing = null;

        this.camera.destroy();
        this.camera = null;
        
        this.canvas = null;
        this.destroyed = true;

        this.eventemitter = null;

        this.remove(this.floorMesh);
        if (this.floorMesh)
        {
            this.floorMesh.geometry.dispose();
            this.floorMesh.material.dispose();
            this.floorMesh = null;
        }

        this.ResponsiveManager.off(this.ResponsiveManager.EVENT_RESIZE, this.fctOnResize);
        window.removeEventListener("mousemove", this.fctMouseMove);
        window.removeEventListener("mouseup", this.fctMouseUp);
        window.removeEventListener("touchend", this.fctTouchEnd);
    }

    /*******************************************
    *   EVENT EMITTER
    *******************************************/
    on (strEvent, closure)
    {
        this.eventemitter.on(strEvent, closure);
    }

    once (strEvent, closure)
    {
        this.eventemitter.on(strEvent, closure);
    }

    emit (strEvent)
    {
        this.eventemitter.emit(strEvent, arguments);
    }

    /*******************************************
    *   RENDERING
    *******************************************/
    render()
    {
        if (!this.destroyed)
        {
            this.rAFWorld = requestAnimationFrame(() => this.render());
        }

        let now = (new Date()).getTime();
        let delta = (now - this.elapsed) / 1000;
        this.elapsed = now;
        this.deltaTime = delta;

        if (this.options.closureRender)
        {
            this.options.closureRender(delta);
        }

        this.Camera.update(delta);
        this.renderPostProcessing(delta);
    }

    renderPostProcessing (fDelta)
    {
        if (this.postProcessing)
        {
            this.postProcessing.render(fDelta);
        }
        else
        {
            this.renderer.render(this, this.camera);
        }
    }

    setIsCursorPointer(bIsPointer)
    {
        document.body.style.cursor = (bIsPointer ? "pointer" : "default");
    }

    /*******************************************
    *   OBJECTS INTERACTION
    *******************************************/
    addClickableMesh(strKey, objMesh, fWidth, fHeight, fctClickCallback, fOffsetX = 0, fOffsetZ = 0)
    {
        this.clickableMeshes[strKey] = {
            "mesh": objMesh,
            "size": {"width": fWidth, "height": fHeight},
            "offset": {"x": fOffsetX, "z": fOffsetZ},
            "callback": fctClickCallback
        };
    }

    removeClickableMesh(strKey)
    {
        if (strKey in this.clickableMeshes)
        {
            delete this.clickableMeshes[strKey];
        }
    }

    getClickableMesh(iX, iY, bUseRaycast = false)
    {
        let result = null;

        // WITH RAYCASTING
        //----------------------------------------
        if (bUseRaycast)
        {
            let meshList = [];
            for (let key in this.clickableMeshes)
            {
                this.clickableMeshes[key].mesh.meshKey = key;
                meshList.push(this.clickableMeshes[key].mesh);
            }

            if (meshList.length > 0)
            {
                let rays = this.Camera.Raycaster.intersectsFromScreen(iX, iY, meshList);

                for (let i = 0; i < rays.length; i++)
                {
                    let key = rays[i].object.meshKey;
                    result = {
                        "key": key,
                        "position": new Vector3(iX, 0, iY),
                        "callback": this.clickableMeshes[key].callback
                    };

                    break;
                }
            }
        }
        // NO RAYCASTING
        //----------------------------------------
        else
        {
            for (let key in this.clickableMeshes)
            {
                let mesh = this.clickableMeshes[key];
                let pos = {"x": mesh.mesh.position.x + mesh.offset.x, "z": mesh.mesh.position.z + mesh.offset.z - mesh.size.height * 0.75};

                if (iX >= pos.x - mesh.size.width / 2 && iX <= pos.x + mesh.size.width / 2 && 
                    iY >= pos.z - mesh.size.height / 2 && iY <= pos.z + mesh.size.height * 0.75)
                {
                    result = {
                        "key": key,
                        "position": new Vector3(iX, 0, iY),
                        "callback": this.clickableMeshes[key].callback
                    };
                    break;
                }
            }
        }

        return result;
    }

    addClickableZone(strKey, fMinX, fMinY, fMaxX, fMaxY, fctClickCallback)
    {
        this.clickablesZones[strKey] = {
            "rect": {
                "min": {"x": fMinX, "y": fMinY},
                "max": {"x": fMaxX, "y": fMaxY}
            },
            "callback": fctClickCallback
        };
    }

    removeClickableZone(strKey)
    {
        if (strKey in this.clickablesZones)
        {
            delete this.clickablesZones[strKey];
        }
    }

    getClickableZone(iScreenX, iScreenY)
    {
        let result = null;

        let screenPadding = this.ResponsiveManager.ScreenPadding;
        let inputX = iScreenX - screenPadding;
        let inputY = iScreenY;

        for (let key in this.clickablesZones)
        {
            let zone = this.clickablesZones[key];
            if (inputX >= zone.rect.min.x && inputX <= zone.rect.max.x && inputY >= zone.rect.min.y && inputY <= zone.rect.max.y)
            {
                result = {
                    "key": key,
                    "position": {"x": inputX, "y": inputY},
                    "callback": this.clickablesZones[key].callback
                };
                break;
            }
        }

        return result;
    }

    /**
        Tracks the click events on an object matrix spawned on screen
        @param strKey           Key identifying this matrix
        @param objMesh          Mesh object possessing the matrix
        @param objMatrix        Matrix to use to calculate click events
        @param fctClickCallback Function to call when a click event occurs
    */
    addClickableMatrix(strKey, objMesh, objMatrix, fctClickCallback)
    {
        this.clickablesMatrixes[strKey] = {
            "mesh": objMesh,
            "matrix": objMatrix,
            "callback": fctClickCallback
        };
    }

    removeClickableMatrix(strKey)
    {
        if (strKey in this.clickablesMatrixes)
        {
            delete this.clickablesMatrixes[strKey];
        }
    }

    getClickableMatrix(iX, iY, bUseRaycast = false)
    {
        let result = null;

        let fctCalculateMatrix = function(iX, iY, matrix, clickGridPos, meshGridPos, strKey)
        {
            let origin = null;
            for (let y = 0; y < matrix.length; y++)
            {
                for (let x = 0; x < matrix[y].length; x++)
                {
                    if (matrix[y][x] > 1)
                    {
                        origin = {x, y};
                        break;
                    }
                }
                if (origin)
                {
                    break;
                }
            }

            if (origin)
            {
                let relX = clickGridPos.x - meshGridPos.x + origin.x;
                let relY = clickGridPos.y - meshGridPos.y + origin.y;

                if (relX >= 0 && relX < matrix[0].length && relY >= 0 && relY < matrix.length)
                {
                    if (matrix[relY][relX] % 2)
                    {
                        return {
                            "key": strKey,
                            "position": new Vector3(iX, 0, iY),
                            "callback": this.clickablesMatrixes[strKey].callback
                        };
                    }
                }
            }
            return null;
        }
        .bind(this, iX, iY);

        // WITH RAYCASTING
        //----------------------------------------
        if (bUseRaycast)
        {
            let meshList = [];
            for (let key in this.clickablesMatrixes)
            {
                this.clickablesMatrixes[key].mesh.meshKey = key;
                meshList.push(this.clickablesMatrixes[key].mesh);
            }

            if (meshList.length > 0)
            {
                let offset = Constants.getValue("GRID_CELL_SIZE");

                let rays = this.Camera.Raycaster.intersectsFromScreen(iX, iY, meshList);
                for (let i = 0; i < rays.length; i++)
                {

                    let clickGridPos = this.WorldManager.Environment.Grid.worldToGridPosition(rays[i].point.x, rays[i].point.z);
                    let key = rays[i].object.meshKey;
                    let pos = this.clickablesMatrixes[key].mesh.position;
                    //The asset geometry is translated upward to fit the grid at its bottom so we invert that translation
                    //by moving the position on the z axis
                    let meshGridPos = this.WorldManager.Environment.Grid.worldToGridPosition(
                        pos.x, 
                        pos.z - offset
                    );

                    result = fctCalculateMatrix(this.clickablesMatrixes[key].matrix, clickGridPos, meshGridPos, key);
                    if (result)
                    {
                        break;
                    }
                }
            }
        }
        // NO RAYCASTING
        //----------------------------------------
        else
        {
            //We offset the click a little bit to match to position of the assets on the tiles of the grid
            let cellSize = this.WorldManager.Environment.Grid.CellSize;
            let clickGridPos = this.WorldManager.Environment.Grid.worldToGridPosition(iX + cellSize / 2, iY + cellSize);
            
            for (let key in this.clickablesMatrixes)
            {
                let pos = this.clickablesMatrixes[key].mesh.position;
                let meshGridPos = this.WorldManager.Environment.Grid.worldToGridPosition(pos.x, pos.z);

                result = fctCalculateMatrix(this.clickablesMatrixes[key].matrix, clickGridPos, meshGridPos, key);
                if (result)
                {
                    break;
                }
            }
        }

        return result;
    }

    /*******************************************
    *   EVENTS
    *******************************************/
    onResize()
    {
        this.camera.aspect = this.ResponsiveManager.Width / this.ResponsiveManager.Height;
        this.camera.updateProjectionMatrix();

        this.renderer.setSize(this.ResponsiveManager.Width, this.ResponsiveManager.Height);
    }

    onMouseMove(bConvertToWorldPos, e)
    {
        //let isOverAction = Binder.instance.uis.isOverAction(e.clientX, e.clientY);

        if(!this.GameManager.IsPaused && this.CanWorldClick && !this.IsDialogMode && !this.IsCinematic /*&& !isOverAction*/)
        {
            let bIsOver = false;
            let obj = this.getClickableZone(e.clientX, e.clientY);

            if (obj)
            {
                bIsOver = true;
            }
            else
            {
                let screenPadding = this.ResponsiveManager.ScreenPadding;
                let inputPos = null;
                if (bConvertToWorldPos)
                {
                    inputPos = this.Camera.screenToWorldPosition(e.clientX - screenPadding, e.clientY);
                }
                else
                {
                    inputPos = new Vector3(e.clientX - screenPadding, 0, e.clientY);
                }

                bIsOver = (this.getClickableMesh(inputPos.x, inputPos.z, !bConvertToWorldPos) ? true : false);
                if (!bIsOver)
                {
                    bIsOver = (this.getClickableMatrix(inputPos.x, inputPos.z, !bConvertToWorldPos) ? true : false);
                }
            }
            
            this.setIsCursorPointer(bIsOver);
        }
    }

    onMouseUp(bConvertToWorldPos, e)
    {
        //let isOverAction = !Binder.instance.uis.isOverAction(e.clientX, e.clientY);

        let isCinematic = this.IsCinematic === undefined ? false : this.IsCinematic;
        if(!this.GameManager.IsPaused && this.CanWorldClick && !this.IsDialogMode && !isCinematic && this.UIManager.canWorldClick(e.clientX, e.clientY))
        {
            let obj = this.getClickableZone(e.clientX, e.clientY);
            if (obj)
            {
                obj.callback(obj.position);
                this.setIsCursorPointer(false);
            }
            else
            {
                let screenPadding = this.ResponsiveManager.ScreenPadding;
                let inputPos = null;
                if (bConvertToWorldPos)
                {
                    inputPos = this.Camera.screenToWorldPosition(e.clientX - screenPadding, e.clientY);
                }
                else
                {
                    inputPos = new Vector3(e.clientX - screenPadding, 0, e.clientY);
                }

                obj = this.getClickableMesh(inputPos.x, inputPos.z, !bConvertToWorldPos);
                if (obj)
                {
                    obj.callback(obj.position);
                    this.setIsCursorPointer(false);
                }
                else
                {
                    obj = this.getClickableMatrix(inputPos.x, inputPos.z, !bConvertToWorldPos);
                    if (obj)
                    {
                        obj.callback(obj.position);
                        this.setIsCursorPointer(false);
                    }
                }
            }
        }
    }

    onTouchEnd(bConvertToWorlPos, e)
    {
        if (e.changedTouches.length > 0)
        {
            let x = e.changedTouches[0].clientX;
            let y = e.changedTouches[0].clientY;

            //let isOverAction = !Binder.instance.uis.isOverAction(e.clientX, e.clientY);

            if(!this.GameManager.IsPaused && this.CanWorldClick && !this.IsDialogMode && !this.IsCinematic && this.UIManager.canWorldClick(x, y))
            {
                let obj = this.getClickableZone(x, y);
                if (obj)
                {
                    obj.callback(obj.position);
                    this.setIsCursorPointer(false);
                }
                else
                {
                    let screenPadding = this.ResponsiveManager.ScreenPadding;
                    let inputPos = this.Camera.screenToWorldPosition(x - screenPadding, y);

                    obj = this.getClickableMesh(inputPos.x, inputPos.z);
                    if (obj)
                    {
                        obj.callback(obj.position);
                        this.setIsCursorPointer(false);
                    }
                    else
                    {
                        obj = this.getClickableMatrix(inputPos.x, inputPos.z);
                        if (obj)
                        {
                            obj.callback(obj.position);
                            this.setIsCursorPointer(false);
                        }
                    }
                }
            }
        }
    }
}