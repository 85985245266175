import EventEmitter from "eventemitter3";
import ItemCodes from "./codes/ItemCodes.js";
import EquipmentItem from "./EquipmentItem.js";
import FoodItem from "./FoodItem.js";
import Library from "../Library.js";
import MaterialItem from "./MaterialItem.js";
import MutationItem from "./MutationItem.js";
import KeyItem from "./KeyItem.js";
import MapItem from "./MapItem.js";
import CharacterInventory from "./CharacterInventory.js";
import CharacterEquipment from "./CharacterEquipment.js";

export default class ItemManager extends EventEmitter
{
    constructor()
    {
        super();

        ItemManager.instance = this;

        this.itemDiscoveryKey = "itemdiscovery";
        this.equipment = {};
    }

    get EVENT_BACKPACK_CRAFTED() { return "backpack-crafted"; }
    get EVENT_EQUIPMENT_CHANGED() { return "equipment-changed"; }
    get EVENT_ITEM_CRAFTED() { return "item-crafted"; }
    get EVENT_ITEM_DROPPED() { return "item-dropped"; }
    get EVENT_ITEM_FOUND() { return "item-found"; }
    get EVENT_ITEM_USED() { return "item-used"; }

    get ITEM_CODES() { return ItemCodes; }

    //---------------------------------------------------------
    //  DEPENDENCIES
    //---------------------------------------------------------
    get AudioManager() { return this.dependencies.get("AudioManager"); }
    get GameManager() { return this.dependencies.get("GameManager"); }
    get CharacterManager() { return this.dependencies.get("CharacterManager"); }
    get LabelManager() { return this.dependencies.get("LabelManager"); }
    get SaveManager() { return this.dependencies.get("SaveManager"); }
    get WorldManager() { return this.dependencies.get("WorldManager"); }
    //---------------------------------------------------------

    get CurrentEquipment() { return this.equipment[this.CharacterManager.CurrentCharacter]; }
    get InventorySettings() { return this.GameManager.getSetting("inventory"); }
    get StorageSlotCountPerType() { return this.InventorySettings.storage.slotCountPerType; }

    //---------------------------------------------------------
    //  QUICK ACCESS
    //---------------------------------------------------------
    get BodyEquipment() { return this.CurrentEquipment ? this.CurrentEquipment.BodyEquipment : false; }
    get HandsEquipment() { return this.CurrentEquipment ? this.CurrentEquipment.HandsEquipment : false; }
    get FeetEquipment() { return this.CurrentEquipment ? this.CurrentEquipment.FeetEquipment : false; }
    get BagEquipment() { return this.CurrentEquipment ? this.CurrentEquipment.BagEquipment : false; }
    get ToolEquipment() { return this.CurrentEquipment ? this.CurrentEquipment.ToolEquipment : false; }
    get ToyEquipment() { return this.CurrentEquipment ? this.CurrentEquipment.ToyEquipment : false; }

    get MutationHead() { return this.CurrentEquipment ? this.CurrentEquipment.MutationHead : false; }
    get MutationArm() { return this.CurrentEquipment ? this.CurrentEquipment.MutationArm : false; }
    get MutationLeg() { return this.CurrentEquipment ? this.CurrentEquipment.MutationLeg : false; }

    get BackpackLevel() { return this.inventory.BackpackLevel; }

    get MutationPowers() { return this.CurrentEquipment ? this.CurrentEquipment.MutationPowers : false; }

    //Fishing Rod
    get CanFish() { return this.CurrentEquipment ? this.CurrentEquipment.CanFish : false; }
    get FishQuantity() { return this.CurrentEquipment ? this.CurrentEquipment.FishQuantity : false; }
    get FishItem() { return this.CurrentEquipment ? this.CurrentEquipment.FishItem : false; }

    get FishChances ()
    {
        return this.CurrentEquipment ? this.CurrentEquipment.FishChances / 200 : 0;
    }

    //Animal Trap
    get CanTrap() { return this.CurrentEquipment ? this.CurrentEquipment.CanTrap : false; }

    //Anti-stress tools
    get CanDropStress() { return this.CurrentEquipment ? this.CurrentEquipment.CanDropStress : false; }
    get StressDropValue() { return this.CurrentEquipment ? this.CurrentEquipment.StressDropValue : false; }

    //Mutation powers
    get CanInvisible() { return this.CurrentEquipment ? this.CurrentEquipment.CanInvisible : false; }
    get CanJump() { return this.CurrentEquipment ? this.CurrentEquipment.CanJump : false; }
    get CanPush() { return this.CurrentEquipment ? this.CurrentEquipment.CanPush : false; }
    get CanSpit() { return this.CurrentEquipment ? this.CurrentEquipment.CanSpit : false; }
    get CanStomp() { return this.CurrentEquipment ? this.CurrentEquipment.CanStomp : false; }
    get CanSwim() { return this.CurrentEquipment ? this.CurrentEquipment.CanSwim : false; }
    get CanTeleport() { return this.CurrentEquipment ? this.CurrentEquipment.CanTeleport : false; }

    get PowerDuration() { return this.CurrentEquipment ? this.CurrentEquipment.PowerDuration : false; }
    get PowerCooldown() { return this.CurrentEquipment ? this.CurrentEquipment.PowerCooldown : false; }

    //Stat bonuses
    get InventorySlotCount() { return this.inventory.InventorySlotCount; }
    get EnergyLossBonus() { return this.CurrentEquipment ? this.CurrentEquipment.EnergyLossBonus : false; }
    get StressLossBonus() { return this.CurrentEquipment ? this.CurrentEquipment.StressLossBonus : false; }
    get SearchSpeedBonus() { return this.CurrentEquipment ? this.CurrentEquipment.SearchSpeedBonus : false; }
    get ExtraMaterialChancesBonus() { return this.CurrentEquipment ? this.CurrentEquipment.ExtraMaterialChancesBonus : false; }
    get SprintDurationBonus() { return this.CurrentEquipment ? this.CurrentEquipment.SprintDurationBonus : false; }
    get WalkSpeedBonus() { return this.CurrentEquipment ? this.CurrentEquipment.WalkSpeedBonus : false; }
    get MaxEnergyRatio() { return this.CurrentEquipment ? this.CurrentEquipment.MaxEnergyRatio : false; }
    get MaxHungerRatio() { return this.CurrentEquipment ? this.CurrentEquipment.MaxHungerRatio : false; }
    get MaxStressRatio() { return this.CurrentEquipment ? this.CurrentEquipment.MaxStressRatio : false;}
    get SprintCooldownRatio() { return this.CurrentEquipment ? this.CurrentEquipment.SprintCooldownRatio : false; }
    //---------------------------------------------------------

    init(dependencies)
    {
        this.dependencies = dependencies;

        this.createClosure();
        this.bindEvents();
    }
    
    createClosure()
    {
        this.fctOnStartGame = this.onStartGame.bind(this);
        this.fctOnCharacterStateChange = this.onCharacterStateChange.bind(this);
    }

    bindEvents()
    {
        this.GameManager.on(this.GameManager.EVENT_START_GAME, this.fctOnStartGame);
        this.CharacterManager.on(this.CharacterManager.EVENT_STATE_CHANGED, this.fctOnCharacterStateChange);//@!
    }

    /**
        Checks if an item can be dragged on a slot in the storage

        @param item         Id of the item to check if it can be dropped. Can also be the whole item object
        @param iSlotIndex   The index of the slot to look at. The storage's slot indexes are the same as array indexes

        @return             A boolean indicating if the item can be dropped on this slot or if it will be auto placed in another page of the storage
    */
    canDropOnStorageSlot(item, iSlotIndex)
    {
        if (typeof item === "string")
        {
            item = this.getItem(item);
        }
        let pageIndex = this.inventory.getPageIndexFromType(item.InventoryPageType);
        let slotsPerPage = this.StorageSlotCountPerType;
        let index = iSlotIndex - pageIndex * slotsPerPage;

        return index >= 0 && index < slotsPerPage;
    }

    /**
        Gets the item located at an inventory's slot

        @param iSlotIndex   The index of the slot to look at. The inventory's slot indexes are the same as array indexes
        @param bInBackpack  (Optional) If the inventory it should look into is the current character's backpack or the general storage. Default is backpack
        @param iCharacter   (Optional) Only used if bInBackpack is TRUE. It specifies which character inventory should be used. If less than 0 then the
                                current character's inventory is used

        @return              If an item is on the slot, an object with the item/quantity/slotIndex will be returned. Otherwise the item property is NULL.
    */
    getItemAtSlot (iSlotIndex, bInBackpack = true, iCharacter = -1)
    {
        return this.inventory.getItemAtSlot(iSlotIndex, bInBackpack, iCharacter);
    }

    /**
        Gets all the items stored in the inventory

        @param bInBackpack  (Optional) If the inventory it should look into is the current character's backpack or the general storage. Default is backpack
        @param iCharacter   (Optional) Only used if bInBackpack is TRUE. It specifies which character inventory should be used. If less than 0 then the
                                current character's inventory is used

        @return              An object containing the definition of every object contained in the inventory.
    */
    getAllItems (bInBackpack = true, iCharacter = -1)
    {
        return this.inventory.getAllItems(bInBackpack, iCharacter);
    }

    /**
        Scans the inventory and checks if an item is stored there
        
        @param item         Id of the item to look for. Can also be the whole item object
        @param bInBackpack  (Optional) If the inventory it should look into is the current character's backpack or the general storage. Default is backpack
        @param iCharacter   (Optional) Only used if bInBackpack is TRUE. It specifies which character inventory should be used. If less than 0 then the
                                current character's inventory is used

        @return              An object containing the item, its quantity and its slot position
    */
    getItemFromInventory(item, bInBackpack = true, iCharacter = -1)
    {
        if (typeof item === "string")
        {
            item = this.getItem(item);
        }
        return this.inventory.getItemFromInventory(item, bInBackpack, iCharacter);
    }

    /**
        Scans the inventory and counts the total number of items the player possesses
        
        @param item                 Id of the item to look for. Can also be the whole item object
        @param bAllowStorageCheck   (Optional) If the algorithm should also count items in the storage or not
        @param iCharacter           (Optional) Specifies which character inventory should be used to check for the item. If less than 0 then the
                                        current character's inventory is used
        @param bCheckAllBackpacks   (Optional) If all backpacks of all characters should be looked into

        @return                     Amount of item the player possesses

    */
    getItemQuantity (item, bAllowStorageCheck = false, iCharacter = -1, bCheckAllBackpacks = false)
    {
        if (typeof item === "string")
        {
            item = this.getItem(item);
        }

        if (!item)
            return 0;

        return this.inventory.getItemQuantity(item, bAllowStorageCheck, iCharacter, bCheckAllBackpacks);
    }

    /**
        Scans the inventory and counts the total number of items of a type the player possesses
        
        @param itemType             Id of the type of item to look for
        @param bAllowStorageCheck   (Optional) If the algorithm should also count items in the storage or not
        @param iCharacter           (Optional) Specifies which character inventory should be used to check for the item. If less than 0 then the
                                        current character's inventory is used
        @param bCheckAllBackpacks   (Optional) If all backpacks of all characters should be looked into

        @return                     Amount of item the player possesses

    */
    getItemTypeQuantity (itemType, bAllowStorageCheck = false, iCharacter = -1, bCheckAllBackpacks = false)
    {
        let count = 0;
        let type = itemType.toUpperCase();
        for (let key in this.itemList)
        {
            if (this.itemList[key].Type == type)
            {
                count += this.inventory.getItemQuantity(this.itemList[key], bAllowStorageCheck, iCharacter, bCheckAllBackpacks);
            }
        }
        return count;
    }

    /**
        Gets the max amount of a stack for an item in the inventory
        
        @param item         Id of the item to find it's max stack size. Can also be the whole item object
        @param bInBackpack  (Optional) If the max stack is calculated on the backpack or in the storage. Default is backpack

        @return             Max stackable amount for the item
    */
    getMaxStackAmount (item, bInBackpack = true)
    {
        if (typeof item === "string")
        {
            item = this.getItem(item);


        }
        return this.inventory.getMaxStackAmount(item, bInBackpack);
    }

    /**
        Adds an item into the inventory

        @param item         Id of the item to add in the inventory. Can also be the whole item object
        @param quantity     Amount for this item to add
        @param iSlotIndex   (Optional) At which slot the item should be added. If less than 0, will try to auto place the item in the inventory
        @param bInBackpack  (Optional) If the inventory this item should go is in the current character's backpack or the general storage. Default is backpack
        @param iCharacter   (Optional) Only used if bInBackpack is TRUE. It specifies which character inventory this should be put. If less than 0 then the
                                current character's inventory is used
        @param bNotify      (Optional) If the addItem action should be notified through the Event system. Default is yes

        @return             An object describing the result of the operation :
                                "status": boolean describing if the opration was a success or not
                                "inventory": the new state of the inventory
                                "args": List of values describing why the operation is as is it:
                                    - wrongItemOnSlot: The given item was asked to be stored on the same slot of another type of item
                                    - leftOver: If the quantity was too big for the current stacks and there are no other available slots to start a new stack
                                    - autoStacked: SlotIndexes/quantities of the item that was autostacked
    */
    addItem (item, quantity, iSlotIndex = -1, bInBackpack = true, iCharacter = -1, bNotify = true)
    {
        if (typeof item === "string")
        {
            item = this.getItem(item);
        }

        if (item && item.definition && item.definition.pickupSwitch)
        {
            item = this.getItem(item.definition.pickupSwitch);
        }
        return this.inventory.addItem(item, quantity, iSlotIndex, bInBackpack, iCharacter, bNotify);
    }

    /**
        Removes an item from an inventory's slot

        @param quantity     Amount for this item to remove
        @param iSlotIndex   At which slot the quantity should be removed
        @param bInBackpack  (Optional) If the inventory this item should be removed is in the current character's backpack or the general storage. Default is backpack
        @param iCharacter   (Optional) Only used if bInBackpack is TRUE. It specifies which character inventory the item should be removed from. If less than 0 then the
                                current character's inventory is used

        @return              A boolean indicating if the operation was a success or not
    */
    removeItemFromSlot (quantity, iSlotIndex, bInBackpack = true, iCharacter = -1)
    {
        return this.inventory.removeItemFromSlot(quantity, iSlotIndex, bInBackpack, iCharacter);
    }

    /**
        Drags an item stored in a slot to another empty slot

        @param iFromSlot        From which slot index the item should be dragged from
        @param bFromIsBackpack  If the slot the item should be dragged from is from the backpack or not
        @param iToSlot          To which slot index the item should be dragged to. Set the value at -1 to autoplace in the inventory
        @param bToIsBackpack    If the slot to drag the item to is from the backpack or not

        @return                 An object describing the result of the operation :
                                    "status": boolean describing if the opration was a success or not
                                    "inventory": the new state of the inventories:
                                        - backpack: Backpack inventory state if the backpack was edited during the operation
                                        - storage: Storage inventory state if the storage was edited during the operation
                                    "args": List of values describing why the operation is as it is:
                                        - emptyFromSlot: The given slot index to drag from is empty
                                        - stackIsFull: The given slot contains a stack that no more items could be added to
                                        - autoStacked: Object containing the list of slots that received items in a result of an autostack operation
                                        - putBack: Object containing the slot that was put back in the original slot in case the FROM quantity is exceding the TO max stack quantity
    */
    dragItem (iFromSlot, bFromIsBackpack, iToSlot, bToIsBackpack)
    {
        return this.inventory.dragItem(iFromSlot, bFromIsBackpack, iToSlot, bToIsBackpack);
    }

    /**
        Takes all items from a character's backpack and dumps them into the general storage

        @param iCharacter   (Optional) Specifies which character inventory the items should be removed from. If less than 0 then the
                                current character's inventory is used

        @return             An object describing the result of the operation :
                                "status": boolean describing if the opration was a success or not
                                "inventory": the new state of the storage inventory (no need for backpack as it is empty)
    */
    dumpBackpackInStorage (iCharacter = -1)
    {
        return this.inventory.dumpBackpackInStorage(iCharacter);
    }

    /**
        Equips a piece of equipment in its right slot on the current character
        
        @param item             Id of the piece of equipement to equip on the current character. Can also be the whole item object
        @param bAllowStorage    (Optional) If the algorithm is allowed to use an equipment piece stored in the storage instead of only looking in the backpack. Same for
                                    the piece to put back, if allowed, it will try to put it back in the storage if the inventory is full

        @return             An object describing the result of the operation :
                                "status": boolean describing if the opration was a success or not
                                "inventory": the new state of the inventories:
                                    - backpack: Backpack inventory state if the backpack was edited during the operation
                                    - storage: If the storage is allowed and was edited, the state of the storage will also be returned here
                                "args": List of values describing why the operation is as is it:
                                    - wrongTypeItem: The given item was was not of type equipment
                                    - notOwningItem: The player doesn't own the item to equip in it's inventory
    */
    equipItem (item, bAllowStorage = false)
    {
        if (typeof item === "string") 
        {
            item = this.getItem(item);
        }
        let result = {"status": true, "inventory":{}, "args": {} };

        if (item.IsMutation)
        {
            this.CurrentEquipment.setEquipment(item.EquipLocation, item);
        }
        else
        {
            let fromBackpack = true;
            let itemLocation = this.getItemFromInventory(item, true);

            if (!itemLocation.item && bAllowStorage)
            {
                itemLocation = this.getItemFromInventory(item, false);
                fromBackpack = false;
            }

            if (itemLocation.item)
            {
                if (itemLocation.item.Type == ItemCodes.TYPE_EQUIPMENT)
                {
                    let backpackEdited = fromBackpack;
                    let storageEdited = !fromBackpack;

                    let otherItem = this.CurrentEquipment.getEquipment(itemLocation.item.EquipLocation);
                    this.CurrentEquipment.setEquipment(itemLocation.item.EquipLocation, itemLocation.item);

                    this.removeItemFromSlot(itemLocation.quantity, itemLocation.slot, fromBackpack);

                    if (otherItem)
                    {
                        let addResult = this.addItem(otherItem.Id, 1, -1, true);
                        if (!addResult.status)
                        {
                            this.addItem(otherItem.Id, 1, -1, false);
                            storageEdited = true;
                        }
                        else
                        {
                            backpackEdited = true;
                        }
                    }

                    if (backpackEdited)
                    {
                        result.inventory.backpack = this.getAllItems();
                    }
                    if (storageEdited)
                    {
                        result.inventory.storage = this.getAllItems(false);
                    }
                }
                else
                {
                    result.status = false;
                    result.args.wrongTypeItem = true;
                }
            }
            else
            {
                result.status = false;
                result.args.notOwningItem = true;
            }
        }

        if (result.status)
        {
            this.emit(this.EVENT_EQUIPMENT_CHANGED, item);
        }

        return result;
    }

    /**
     * Consume a food item from a slot in the inventory.
     * @param iSlotIndex        On which slot the item to eat is stored
     * @param bInBackpack       (Optional) If the inventory this item should be taken from is in the current character's backpack or the general storage. Default is backpack
     *
     *@return             An object describing the result of the operation :
                            "status": boolean describing if the opration was a success or not
                             "inventory": the new state of the inventories:
                            - backpack: Backpack inventory state if the backpack was edited during the operation
                            - storage: If the storage is allowed and was edited, the state of the storage will also be returned here
                            "args": List of values describing why the operation is as is it:
                            - wrongTypeItem: The given item was was not of type food
                            - notItemOnSlot: There is no item on the specified slot
     */
    eatAtSlot (iSlotIndex, bInBackpack = true)
    {
        let result = {"status": true, "inventory":{}, "args": {} };

        let itemLocation = this.getItemAtSlot(iSlotIndex, bInBackpack);

        if (itemLocation.item && itemLocation.quantity > 0)
        {
            if (itemLocation.item.Type == ItemCodes.TYPE_FOOD)
            {
                let eatResult  = itemLocation.item.eat(iSlotIndex, bInBackpack);

                itemLocation = this.getItemAtSlot(iSlotIndex, bInBackpack);

                if (itemLocation.item !== null)
                {
                    result.args.putBack = this.createItemData(
                        itemLocation.item,
                        itemLocation.quantity,
                        iSlotIndex
                    );

                }

                result.status = eatResult.status;
                result.eat = eatResult;

            }
            else
            {
                result.status = false;
                result.args.wrongTypeItem = true;
            }
        }
        else
        {
            result.status = false;
            result.args.notItemOnSlot = true;
        }

        return result;
    }

    createItemData (objItem, iQuantity, iSlotIndex)
    {
        if (objItem && (typeof objItem === 'string' || objItem instanceof String))
        {
            objItem = ItemManager.instance.getItem(objItem);
        }
        return {"item": objItem, "quantity": iQuantity, "slot": iSlotIndex};
    }

    /**
        Equips a piece of equipment on the current character from a slot in an inventory. This function should not be used to equip a mutation
        as they are not stored in the inventory.
        
        @param iSlotIndex       On which slot the item to equip is stored
        @param bInBackpack      (Optional) If the inventory this item should be taken from is in the current character's backpack or the general storage. Default is backpack

        @return             An object describing the result of the operation :
                                "status": boolean describing if the opration was a success or not
                                "inventory": the new state of the inventories:
                                    - backpack: Backpack inventory state if the backpack was edited during the operation
                                    - storage: If the storage is allowed and was edited, the state of the storage will also be returned here
                                "args": List of values describing why the operation is as is it:
                                    - wrongTypeItem: The given item was was not of type equipment
                                    - noMutation: The system cannot equip a mutation from an inventory's slot
                                    - notItemOnSlot: There is no item on the specified slot
    */
    equipAtSlot (iSlotIndex, bInBackpack = true)
    {
        let result = {"status": true, "inventory":{}, "args": {} };

        let itemLocation = this.getItemAtSlot(iSlotIndex, bInBackpack);

        if (itemLocation.item && itemLocation.quantity > 0)
        {
            if (!itemLocation.item.IsMutation)
            {
                if (itemLocation.item.Type == ItemCodes.TYPE_EQUIPMENT)
                {
                    let backpackEdited = bInBackpack;
                    let storageEdited = !bInBackpack;

                    let otherItem = this.CurrentEquipment.getEquipment(itemLocation.item.EquipLocation);
                    this.CurrentEquipment.setEquipment(itemLocation.item.EquipLocation, itemLocation.item);

                    this.removeItemFromSlot(1, itemLocation.slot, bInBackpack);

                    this.AudioManager.playSfx("vetement");

                    if (otherItem)
                    {
                        let toSlot = iSlotIndex;
                        let itemOnSlot = this.getItemAtSlot(toSlot, bInBackpack);
                        if (itemOnSlot.item && itemOnSlot.item.Id != otherItem.Id)
                        {
                            toSlot = -1;
                        }
                        let addResult = this.addItem(otherItem.Id, 1, toSlot, bInBackpack, -1, false);
                        if (!addResult.status)
                        {
                            this.addItem(otherItem.Id, 1, -1, false);
                            storageEdited = true;
                        }
                        else
                        {
                            backpackEdited = bInBackpack;
                            storageEdited = !bInBackpack;
                        }
                    }

                    if (backpackEdited)
                    {
                        result.inventory.backpack = this.getAllItems();
                    }
                    if (storageEdited)
                    {
                        result.inventory.storage = this.getAllItems(false);
                    }
                }
                else
                {
                    result.status = false;
                    result.args.wrongTypeItem = true;
                }
            }
            else
            {
                result.status = false;
                result.args.noMutation = true;
            }
        }
        else
        {
            result.status = false;
            result.args.notItemOnSlot = true;
        }

        return result;
    }

    /**
        Removes the equipped item from a specified slot. Be warn that this does not put back the old equipment in the inventory.
        
        @param strEquipmentSlot Id of the equipment slot to remove the item. The Ids are stored in the Commons/ItemCodes class
        @param iCharacter       (Optional) Specifies which character equipment the items should be removed from. If less than 0 then the
                                    current character's equipment is used

        @return                 An object representing the item equipped on the slot. Returns NULL if no item was equipped
    */
    removeEquipment (strEquipmentSlot, iCharacter = -1)
    {
        let charEquip = (iCharacter < 0 || !(iCharacter in this.equipment) ? this.CurrentEquipment : this.equipment[iCharacter]);
        let item = charEquip.getEquipment(strEquipmentSlot);

        charEquip.setEquipment(strEquipmentSlot, null);

        this.emit(this.EVENT_EQUIPMENT_CHANGED, item);

        return item;
    }

    /**
        Crafts an item and removes the ressources from the player's inventory

        @param item             Id of the item to craft. Can also be the whole item object
        @param bAllowStorage    (Optional) If the algorithm should also use items in the storage or not. Default is to allow storage
        @param iCharacter       (Optional) Specifies which character inventory should be used to check for the resources. Also, after the item has been crafted,
                                    the algorithm will try to put the item in the player's bag. If the ID is less than 0 then the current character's inventory is used.

        @return                 An object describing the result of the operation :
                                "status": boolean describing if the opration was a success or not
                                "inventory": the new state of the inventories:
                                    - backpack: Backpack inventory state if the backpack was edited during the operation
                                    - storage: If the storage is allowed and was edited, the state of the storage will also be returned here
                                "args": List of values describing why the operation is as is it:
                                    - missingResources: The doesn't have the required resources to craft the item
                                    - missingLevelRequirement: The player hasn't upgraded the barn enough to craft this item
                                    - notCraftable: The required result item is not craftable
                                    - missingInventorySpace: The crafted item could not be added due to missing backpack space (storage wasn't allowed)
    */
    craftItem (item, bAllowStorage = true, iCharacter = -1)
    {
        if (typeof item === "string")
        {
            item = this.getItem(item);
        }
        let result = {"status": true, "inventory": {}, "args": {}};
        let bIsOk = item && item.IsCraftable;

        if (bIsOk)
        {
            if (item.Type == ItemCodes.TYPE_EQUIPMENT)
            {
                bIsOk = item.CraftLevel <= this.WorldManager.BarnCraftingItemLevel;
            }

            if (bIsOk)
            {
                for (let i = 0; i < item.CraftRequirements.length; i++)
                {
                    if (this.getItemQuantity(item.CraftRequirements[i].item.Id, bAllowStorage, iCharacter, true) < item.CraftRequirements[i].quantity)
                    {
                        bIsOk = false;
                        break;
                    }
                }

                if (bIsOk)
                {
                    let editResult = this.removeRequiredItems(item.CraftRequirements, bAllowStorage, iCharacter, true);

                    //Items are added to the storage by default
                    let addResult = this.addItem(item, item.CraftResultAmount, -1, false, iCharacter);
                    editResult.backpack = addResult.status || editResult.backpack;

                    if (!addResult.status && bAllowStorage)
                    {
                        this.addItem(item, 1, -1, false);
                        editResult.storage = true;
                    }
                    else if (!addResult.status)
                    {
                        result.status = false;
                        result.args.missingBackpackSpace = true;
                    }

                    if (editResult.backpack) 
                    {
                        result.inventory.backpack = this.getAllItems(true, iCharacter);
                    }
                    if (editResult.storage) 
                    {
                        result.inventory.storage = this.getAllItems(false);
                    }

                    this.emit(this.EVENT_ITEM_CRAFTED, item.id, 1);
                }
                else
                {
                    result.status = false;
                    result.args.missingResources = true;
                }
            }
            else
            {
                result.status = false;
                result.missingLevelRequirement = true;
            }
        }
        else
        {
            result.status = false;
            result.args.notCraftable = true;
        }

        return result;
    }

    /**
        Cooks a food item and removes the ressources from the player's inventory

        @param item             Id of the item to cook. Can also be the whole item object
        @param bAllowStorage    (Optional) If the algorithm should also use items in the storage or not. Default is to allow storage
        @param iCharacter       (Optional) Specifies which character inventory should be used to check for the resources. Also, after the item has been crafted,
                                    the algorithm will try to put the item in the player's bag. If the ID is less than 0 then the current character's inventory is used.

        @return                 An object describing the result of the operation :
                                "status": boolean describing if the opration was a success or not
                                "inventory": the new state of the inventories:
                                    - backpack: Backpack inventory state if the backpack was edited during the operation
                                    - storage: If the storage is allowed and was edited, the state of the storage will also be returned here
                                "args": List of values describing why the operation is as is it:
                                    - missingResources: The doesn't have the required resources to craft the item
                                    - notCookable: The required result item is not cookable
                                    - missingInventorySpace: The crafted item could not be added due to missing backpack space (storage wasn't allowed)
    */
    cookItem (item, bAllowStorage = true, iCharacter = -1)
    {
        if (typeof item === "string")
        {
            item = this.getItem(item);
        }
        let result = {"status": true, "inventory": {}, "args": {}};
        let bIsOk = item.Type == ItemCodes.TYPE_FOOD && item.IsCookable;

        if (bIsOk)
        {
            let cookItem = item.getCookingItem();
            
            for (let i = 0; i < cookItem.CraftRequirements.length; i++)
            {
                let craftReq = cookItem.CraftRequirements[i];
                let craftReqItem = craftReq.item;;
                let craftReqItemId = craftReqItem.Id;
                let craftReqQuantity = craftReq.quantity;
                let itemQuantity = this.getItemQuantity(craftReqItemId, bAllowStorage, iCharacter, true);
                if ( itemQuantity < craftReqQuantity)
                {
                    bIsOk = false;
                    break;
                }
            }

            if (bIsOk)
            {

                let editResult = this.removeRequiredItems(cookItem.CraftRequirements, bAllowStorage, iCharacter, true);

                let addResult = this.addItem(cookItem, cookItem.CraftResultAmount, -1, false, iCharacter);
                editResult.backpack = addResult.status || editResult.backpack;

                if (!addResult.status && bAllowStorage)
                {
                    this.addItem(cookItem, 1, -1, false);
                    editResult.storage = true;
                }
                else if (!addResult.status)
                {
                    result.status = false;
                    result.args.missingBackpackSpace = true;
                }

                if (editResult.backpack) 
                {
                    result.inventory.backpack = this.getAllItems(true, iCharacter);
                }
                if (editResult.storage) 
                {
                    result.inventory.storage = this.getAllItems(false);
                }

                this.emit(this.EVENT_ITEM_CRAFTED, cookItem.Id, 1);
            }
            else
            {
                result.status = false;
                result.args.missingResources = true;
            }
        }
        else
        {
            result.status = false;
            result.args.notCraftable = true;
        }
        

        return result;
    }



    /**
        Gets the required item to craft a level of the backpack

        @param iLevel       Level to get the crafting requirements
        @return             An array containing the list of items with their quantity. If the crafting requirements could not be found, an empty array will be returned
    */
    getBackpackCraftRequirements (iLevel)
    {
        let requirements = [];

        if (iLevel in this.inventorySettings.backpack && "craft" in this.inventorySettings.backpack[iLevel])
        {
            for (let i = 0; i < this.inventorySettings.backpack[iLevel].craft.req.length; i++)
            {
                requirements.push({
                    "item": this.getItem(this.inventorySettings.backpack[iLevel].craft.req[i].id),
                    "quantity": this.inventorySettings.backpack[iLevel].craft.req[i].qty
                });
            }
        }

        return requirements;
    }

    /**
        Tries to craft the next level backpack with the current materials

        @param iToLevel         (Optional) To which level the backpack should be upgraded. If left empty, the next level will be considered
        @param bAllowStorage    (Optional) If the algorithm should also use items in the storage or not. Default is to allow storage
        @param iCharacter       (Optional) Specifies which character inventory should be used to check for the resources. If the ID is less than 0 then the current 
                                    character's inventory is used.

        @return                 An object describing the result of the operation :
                                "status": boolean describing if the opration was a success or not
                                "inventory": the new state of the inventories:
                                    - backpack: Backpack inventory state if the backpack was edited during the operation
                                    - storage: If the storage is allowed and was edited, the state of the storage will also be returned here
                                "args": List of values describing why the operation is as is it:
                                    - missingResources: The doesn't have the required resources to craft the new backpack
                                    - missingLevelRequirement: The player hasn't upgraded the barn enough to craft the backpack
                                    - notCraftable: The backpack is already at its highest level

    */
    upgradeBackpack (iToLevel = null, bAllowStorage = true, iCharacter = -1)
    {
        let result = { "status": true, "inventory": {}, "args": {} };
        let newLevel = iToLevel;
        if (newLevel === null)
        {
            newLevel = this.BackpackLevel + 1;
        }
        let requirements = this.getBackpackCraftRequirements(newLevel);

        if (requirements.length > 0 || newLevel <= this.BackpackLevel)
        {
            if (this.WorldManager.BarnCraftingItemLevel >= newLevel)
            {
                let bOk = true;
                for (let i = 0; i < requirements.length; i++)
                {
                    if (this.getItemQuantity(requirements[i].item.Id, bAllowStorage, iCharacter, true) < requirements[i].quantity)
                    {
                        bOk = false;
                        break;
                    }
                }

                if (bOk)
                {
                    let editResult = this.removeRequiredItems(requirements, bAllowStorage, iCharacter, true);

                    if (editResult.backpack) 
                    {
                        result.inventory.backpack = this.getAllItems(true, iCharacter);
                    }
                    if (editResult.storage) 
                    {
                        result.inventory.storage = this.getAllItems(false);
                    }

                    this.inventory.BackpackLevel = newLevel;

                    this.emit(this.EVENT_BACKPACK_CRAFTED, newLevel);
                }
                else
                {
                    result.status = false;
                    result.args.missingResources = true;
                }
            }
            else
            {
                result.status = false;
                result.args.missingLevelRequirement = true;
            }
        }
        else
        {
            result.status = false;
            result.args.notCraftable = true;
        }

        return result;
    }

    /**
        Gets the list of items that has already been discovered by the player
        @return             An array containing the list of discovered items
    */
    getDiscoveredItems()
    {
        let ids = this.SaveManager.getFromSave(this.itemDiscoveryKey, []);

        let items = [];
        for (let i = 0; i < ids.length; i++)
        {
            items.push(this.getItem(ids[i]));
        }

        return items;
    }

    /**
        Adds an item to the list of discovered items

        @param strItemId    Id of the item to add
    */
    setDiscoveredItem(strItemId)
    {
        let ids = this.SaveManager.getFromSave(this.itemDiscoveryKey, []);
        if (!ids.includes(strItemId))
        {
            ids.push(strItemId);
            this.SaveManager.setFromSave(this.itemDiscoveryKey, ids);
        }
    }

    getItem (strItemId)
    {
        if (strItemId in this.itemList)
        {
            return this.itemList[strItemId];
        }
        return null;
    }

    getBackpack (level)
    {
        for (let id in this.itemList)
        {
            if (this.itemList[id].IsBackpack && this.itemList[id].Level == level)
            {
                return this.itemList[id];
            }
        }
        return null;
    }

    getMutationFromCode(strCode)
    {
        for (let key in this.itemList)
        {
            if (this.itemList[key].IsMutation)
            {
                if (this.itemList[key].Code == strCode)
                {
                    return this.itemList[key];
                }
            }
        }
        return null;
    }

    getCharacterEquipment (iCharacter = -1)
    {
        if (iCharacter < 0)
        {
            iCharacter = this.CharacterManager.CurrentCharacter;
        }

        if (iCharacter in this.equipment)
        {
            return this.equipment[iCharacter];
        }

        return this.equipment[0]
    }

    removeRequiredItems (arrRequirements, bAllowStorage, iCharacter, bCheckAllBackpacks = false)
    {
        let backpackEdited = false;
        let storageEdited = false;
        for (let i = 0; i < arrRequirements.length; i++)
        {
            let req = arrRequirements[i];
            let amountRemoved = 0;
            let fctRemoveItems = function(objRequirements, amountRemoved, bLookInBackpack, iCharacterId)
            {
                let removed = 0;
                if (objRequirements.item !== undefined && objRequirements.item !== null)
                {
                    let objItem = this.getItemFromInventory(objRequirements.item.Id, bLookInBackpack, iCharacterId);
                    while (amountRemoved < objRequirements.quantity && objItem.item != null)
                    {
                        let toRemove = objRequirements.quantity - amountRemoved;
                        if (objItem.quantity >= toRemove)
                        {
                            amountRemoved += toRemove;
                            this.removeItemFromSlot(toRemove, objItem.slot, bLookInBackpack, iCharacterId);
                            objItem.item = null;
                        }
                        else
                        {
                            amountRemoved += objItem.quantity;
                            this.removeItemFromSlot(objItem.quantity, objItem.slot, bLookInBackpack, iCharacterId);
                            objItem = this.getItemFromInventory(objRequirements.item.Id, bLookInBackpack, iCharacterId);
                        }
                    }
                }
                else
                {
                    console.log("objRequirements as no item", objRequirements);
                }

                return amountRemoved;
            }.bind(this);

            let currentCharacter = (iCharacter < 0 ? this.CharacterManager.CurrentCharacter : iCharacter);
            let amount = 0;
            //We check in the storage first if allowed
            if (bAllowStorage)
            {
                amount = fctRemoveItems(req, amountRemoved, false, currentCharacter);
                amountRemoved += amount;
                storageEdited = (amount > 0 ? true : storageEdited);
            }

            //Then we check the current character's backpack
            if (amountRemoved < req.quantity)
            {
                amount = fctRemoveItems(req, amountRemoved, true, currentCharacter);
                backpackEdited = (amount > amountRemoved ? true : backpackEdited);
                amountRemoved = amount;
            }

            //If not enough and allowed to check all backpacks, we check the other 2 characters' backpack
            if (amountRemoved < req.quantity)
            {
                //We start with the character with the highest number in its backpack
                let order = [];
                for (let j = 0; j < 3; j++)
                {
                    if (j != currentCharacter)
                    {
                        if (req.item !== undefined && req.item !== null)
                        {
                            let qty = this.getItemQuantity(req.item.Id, false, j, false);
                            if (qty > 0)
                            {
                                order.push([j, qty]);
                            }
                        }
                        else
                        {
                            console.error("Can't get item quantity for", req);
                        }
                    }
                }

                order.sort(function(a, b) {
                    return b[1] - a[1];
                });

                for (let j = 0; j < order.length; j++)
                {
                    amount = fctRemoveItems(req, amountRemoved, true, order[j][0]);
                    backpackEdited = (amount > amountRemoved ? true : backpackEdited);
                    amountRemoved = amount;
                }

            }
        }

        return {
            "backpack": backpackEdited,
            "storage": storageEdited
        };
    }

    getItemPlaceholderImageUrl($strId)
    {
        return "/tools/placeholder-icon.php?id=" + $strId;
    }

    onItemsLoaded (objItemList)
    {
        this.itemList = {};
        this.mapItems = {};
        for (let key in objItemList)
        {
            let item = objItemList[key];
            if (item.food)
            {
                this.itemList[key] = new FoodItem(key, item);
            }
            else if (item.equip)
            {
                this.itemList[key] = new EquipmentItem(key, item);
            }
            else if (item.mutation)
            {
                this.itemList[key] = new MutationItem(key, item);
            }
            else if (item.keyItem)
            {
                this.itemList[key] = new KeyItem(key, item);
            }
            else if (item.material)
            {
                this.itemList[key] = new MaterialItem(key, item);
            }
            else if (item.enemy)
            {
                //why is a map item? that seems wrong!
                this.itemList[key] = new MapItem(key, item);
            }
            else
            {
                this.itemList[key] = new MapItem(key, item);
            }
        }
    }

    onLoadComplete(objSettings)
    {
        this.inventorySettings = objSettings.gameSettings.inventory;

        let items = {};

        if (objSettings.items)
        {
            for (let key in objSettings.items)
            {
                items[key] = objSettings.items[key];
            }
        }

        let indoor = Library.getData("indoor");

        if (indoor && indoor.obstacles)
        {

            for (let key in indoor.obstacles)
            {
                items[key] = indoor.obstacles[key];
            }
        }

        this.onItemsLoaded(items);
    }

    onTriggerGivePlayerItem (strItemId, quantity, callback)
    {
        let item = this.getItem(strItemId);

        if (item)
        {
            let result = this.addItem(item, quantity);
            if (!result.status)
            {
                //@TODO: Drop the given item on the ground if the inventory is full and the player's location is not in the barn
            }
        }
        else
        {
            console.warn("Could not add item " + strItemId + ": id not found");
        }

        callback();
    }

    onStartGame(iSlotIndex)
    {
        this.inventory = new CharacterInventory(this.inventorySettings);
        this.equipment = {
            0: new CharacterEquipment(0, this.inventorySettings),
            1: new CharacterEquipment(1, this.inventorySettings),
            2: new CharacterEquipment(2, this.inventorySettings)
        };
    }

    onCharacterStateChange(iCharacter, strNewState)
    {
        /*if (strNewState == TriggerCodes.CHARACTER_STATE_DEAD)
        {
            //@TODO: Drop backpack content on the ground
        }*/
    }
}