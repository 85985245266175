import BaseCheat from "./BaseCheat.js";

export default class Energy extends BaseCheat
{
    constructor(dependencies)
    {
        super(dependencies);
    }

    get CommandUse() { return "save [clear]"; }
    get CommandDesc() { return "Saves the game. If 'clear' is added, the current savegame is deleted"; }

    //---------------------------------------------------------
    //  DEPENDENCIES
    //---------------------------------------------------------
    get SaveManager() { return this.dependencies.get("SaveManager"); }
    //---------------------------------------------------------

    parseCommand(strCmd, arrArguments)
    {
        if (strCmd == "save" && arrArguments.length <= 1)
        {
            if (arrArguments.length == 1 && arrArguments[0].toLowerCase() == "clear")
            {
                this.SaveManager.clear();
                this.log("The game savefile was cleared");
            }
            else if (arrArguments.length == 0)
            {
                this.SaveManager.save();
                this.log("The game was saved");
            }
        }
    }
}