import TriggerCodes from "../codes/TriggerCodes.js";

export default class MiscValidator
{
    constructor (dependencies)
    {
        this.dependencies = dependencies;
        this.createSupportedConditionList();
        this.createCompareConditionList();
        this.createClosure();
        this.bindEvents();
    }

    //---------------------------------------------------------
    //  DEPENDENCIES
    //---------------------------------------------------------
    get GameManager() { return this.dependencies.get("GameManager"); }
    get TriggerManager() { return this.dependencies.get("TriggerManager"); }
    get UIManager() { return this.dependencies.get("UIManager"); }
    get WorldManager() { return this.dependencies.get("WorldManager"); }
    get SaveManager() { return this.dependencies.get("SaveManager"); }
    //---------------------------------------------------------

    get TimeArgumentType() { return this.TriggerManager.ARGUMENT_TYPES.TIME; };
    get TriggerArgumentType() { return this.TriggerManager.ARGUMENT_TYPES.TRIGGER; };
    get TutorialArgumentType() { return this.TriggerManager.ARGUMENT_TYPES.TUTORIAL; };
    get MissionArgumentType() { return this.TriggerManager.ARGUMENT_TYPES.MISSION; };


    createSupportedConditionList ()
    {
        this.supportedConditions = {};

        this.supportedConditions[TriggerCodes.CONDITION_TIME] = this.validateTimeChange.bind(this);
        this.supportedConditions[TriggerCodes.CONDITION_TRIGGER] = this.validateTriggerCompletion.bind(this);
        this.supportedConditions[TriggerCodes.CONDITION_TUTORIAL] = this.validateTutorialStepCompletion.bind(this);
        this.supportedConditions[TriggerCodes.CONDITION_OPEN_BAG] = this.validateOpenBag.bind(this);
        this.supportedConditions[TriggerCodes.CONDITION_LAUNCH_TRIGGER] = this.validateLaunchTrigger.bind(this);
        this.supportedConditions[TriggerCodes.CONDITION_MISSION] = this.validateCurrentMission.bind(this);

    }

    createCompareConditionList ()
    {
        this.compareConditions = {};

        // this.compareConditions[TriggerCodes.CONDITION_TIME] = this.validateTimeChange.bind(this);
         this.compareConditions[TriggerCodes.CONDITION_TRIGGER] = this.compareTriggerCompletion.bind(this);
        // this.compareConditions[TriggerCodes.CONDITION_TUTORIAL] = this.validateTutorialStepCompletion.bind(this);
        // this.compareConditions[TriggerCodes.CONDITION_OPEN_BAG] = this.validateOpenBag.bind(this);
        // this.compareConditions[TriggerCodes.CONDITION_LAUNCH_TRIGGER] = this.validateLaunchTrigger.bind(this);
        this.compareConditions[TriggerCodes.CONDITION_MISSION] = this.compareCurrentMission.bind(this);

    }

    createClosure ()
    {
        this.fctOnTimeChanged = this.onTimeChanged.bind(this);
        this.fctOnTriggerCompleted = this.onTriggerCompleted.bind(this);
        this.fctOnTutorialStepCompleted = this.onTutorialStepCompleted.bind(this);
        this.fctOnOpenBackPack = this.onOpenBackPack.bind(this);
    }

    bindEvents ()
    {
        this.WorldManager.on(this.WorldManager.EVENT_TIME_OF_DAY_CHANGED, this.fctOnTimeChanged);//@!
        this.TriggerManager.on(this.TriggerManager.TRIGGER_COMPLETED, this.fctOnTriggerCompleted);//@!
        this.GameManager.on(this.GameManager.EVENT_TUTORIAL_STEP_COMPLETED, this.fctOnTutorialStepCompleted);//@!
        this.UIManager.on(this.UIManager.EVENT_OPEN_BACKPACK, this.fctOnOpenBackPack);//@!
    }

    //EVENTS MAPPING
    //

    onOpenBackPack ()
    {
        this.TriggerManager.findTriggersAndExecute(
            this.supportedConditions,
            null,
            []
        );
    }
    onTimeChanged (fNewValue)
    {
        this.TriggerManager.findTriggersAndExecute(
            this.supportedConditions,
            this.TimeArgumentType,
            [fNewValue]
        );
    }

    onTriggerCompleted (strTriggerId)
    {
        this.TriggerManager.findTriggersAndExecute(
            this.supportedConditions,
            this.TriggerArgumentType,
            [strTriggerId, this.TriggerManager.getTriggerData(strTriggerId).r]
        );
    }

    onLaunchAction (strTriggerId, eventID)
    {
        this.TriggerManager.jumpToAction(eventID, strTriggerId);

    }

    onTutorialStepCompleted (strTutorialStep)
    {
        this.TriggerManager.findTriggersAndExecute(
            this.supportedConditions,
            this.TutorialArgumentType,
            [strTutorialStep, true]
        );
    }




    //CONDITION VALIDATION
    //--------------------------------------------------------------
    validateCondition (strType, arrValues, objNewValues = null)
    {
        if (strType in this.supportedConditions)
        {
            return this.supportedConditions[strType](arrValues, objNewValues);
        }
        return true;
    }

    validateTimeChange (arrValues, objNewValues = null)
    {
        let time = null;
        if (objNewValues && objNewValues.type == this.TimeArgumentType)
        {
            time = objNewValues.values[0];
        }
        else
        {
            time = this.WorldManager.TimeOfDay;
        }
        return time !== null && this.TriggerManager.calculateValidity(arrValues[0], time, arrValues[1]);
    }

    validateCurrentMission (arrValues, objNewValues = null)
    {
        let currentMission = parseInt(this.SaveManager.getFromSave("currentmission"));
        let goalMission = parseInt(arrValues[0]);

        return currentMission === goalMission;



    }

    compareCurrentMission (arrValues)
    {
        let currentMission = this.SaveManager.getFromSave("currentmission");


        let result = parseInt(currentMission) === parseInt(arrValues[0]);

        return {
            method: {name: "currentMission", shorthand:"MIS"},
            result,
            explanation: ["Current mission is :" + parseInt(currentMission) , "Goal mission is:  " + parseInt(arrValues[0]) ]
        };
    }

    validateTriggerCompletion (arrValues, objNewValues = null)
    {
        let count = 0;

        if (objNewValues && objNewValues.type == this.TriggerArgumentType && objNewValues.values[0] == arrValues[0])
        {
            count = objNewValues.values[1];
        }
        else
        {

            let data = this.TriggerManager.getTriggerData(arrValues[0]);
            count = data.r;
        }

        let goalValue = parseInt(arrValues[1]);
        let triggerId = parseInt(arrValues[0]);
        if ([43, 50,52].includes(triggerId))
        {
            let a = 1;
        }


        let isValid = (count === goalValue) || ((count > 1) && (count >= goalValue));
        return isValid;
    }

    compareTriggerCompletion (arrValues)
    {
        let [iTriggerID, iRepeated] = arrValues;

        let triggerData = this.TriggerManager.getTriggerData(iTriggerID);

        let repeatedCount = triggerData.r;
        let result = repeatedCount >= parseInt(iRepeated);

        return {
            method: {name: "TriggerCompletion", shorthand:"EVT"},
            result,
            explanation: [
                `Le trigger ${iTriggerID} doit avoir été fait ${iRepeated} fois, et il a été répété ${repeatedCount}  fois.`
                ]
        };
    }

    validateTutorialStepCompletion (arrValues, objNewValues = null)
    {
        let state = false;
        if (objNewValues && objNewValues.type == this.TutorialArgumentType && objNewValues.values[0] == arrValues[0])
        {
            state = objNewValues.values[1];
        }
        else
        {
            //@TODO: Fetch the state of the tutorial step (arrValues[0])
        }
        return state === arrValues[1];
    }

    validateOpenBag (arrValues, objNewValues = null)
    {
        let modal = this.UIManager.getBackPack();

        return modal !== undefined;
    }


    validateLaunchTrigger (arrValues, objNewValues = null)
    {
        let currentID = arrValues[0];
        let lookingFor = objNewValues["values"][0];

        return currentID == lookingFor; //WARNING not sure where the data can be from so using === can be dangerous here
    }



}