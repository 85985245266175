import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js';
import { BokehPass } from 'three/examples/jsm/postprocessing/BokehPass.js';
import { CustomGlitchPass } from './postprocessing/CustomGlitchPass.js';
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass.js';
import { ColorMultiplyShader } from './shaders/ColorMultiplyShader.js';
import { Color } from 'three/build/three.module.js';
import { Vector2 } from "three";
import { gsap } from "gsap";
import { VignetteShader } from './shaders/VignetteShader.js';
import { TimeOfDayShader } from "./shaders/TimeOfDayShader.js";
import WorldManager from '../WorldManager.js';
import Lerp from "../../utils/Lerp.js";
import DependencyContainer from "../../utils/DependencyContainer.js";


export default class PostProcessing
{
    constructor (objCamera, objRenderer, objScene)
    {
        this.camera = objCamera;
        this.renderer = objRenderer;
        this.scene = objScene;

        this.enabled = true;

        this.vignetteSize = 0.25;
        this.vignetteDarkness = 0.5;

        this.showGlitch = false;
        this.showOverlay = false;
        this.showRain = false;
        this.rainStrength = 1.5;
        this.overlayDarkness = 0.5;
        this.showVignette = true;
        this.showColorMultiply = true;
        this.timeLeftVignetteAnimation = 0;
        this.timeLeftColorAnimation = 0;
        this.timeElapsed = 0;

        this.dependencies = DependencyContainer.getInstance();

        this.timeOfDayDef = this.GameManager.getSetting("timeOfDay");
        TimeOfDayShader.uniforms.iResolution = new Vector2(
            this.ResponsiveManager.Width,
            this.ResponsiveManager.Height
        );

        TimeOfDayShader.uniforms.iMouse = new Vector2(
            this.ResponsiveManager.Width / 2,
            this.ResponsiveManager.Height / 2
        );


        this.night = this.IsNight;
        this.morning = this.IsMorning;
        this.evening = this.IsEvening;

        this.createPostProcessing();
    }

    //---------------------------------------------------------
    //  DEPENDENCIES
    //---------------------------------------------------------
    get GameManager() { return this.Dependencies.get("GameManager"); }
    get ResponsiveManager() { return this.Dependencies.get("ResponsiveManager"); }
    get WorldManager() { return this.Dependencies.get("WorldManager"); }
    //---------------------------------------------------------

    get Dependencies() { return this.dependencies; }

    get IsEnabled() { return this.enabled; }
    set IsEnabled(bNewValue) { this.enabled = (bNewValue ? true : false); this.createPostProcessing(); }

    get ShowGlitch() { return this.showGlitch; };
    set ShowGlitch(bShow) { this.showGlitch = (bShow ? true : false); this.createPostProcessing(); };

    get ShowRain() { return this.showRain; };
    set ShowRain(bShow) { this.showRain = (bShow ? true : false); this.createPostProcessing(); console.log("showrain", this.showRain)};

    get ShowInventoryOverlay() { return this.showOverlay; };
    set ShowInventoryOverlay(bShow) { this.showOverlay = (bShow ? true : false); this.createPostProcessing(); };
    
    /** Between 0 and 1. 0 is no darkness and 1 is pure black */
    get InventoryOverlayDarkness() { return this.overlayDarkness; };
    set InventoryOverlayDarkness(fDarkness) { this.overlayDarkness = 1 - Math.min(1, Math.max(0, fDarkness)); this.createPostProcessing(); };

    get ShowVignette() { return this.showVignette; };
    set ShowVignette(bShow) { this.showVignette = (bShow ? true : false); this.createPostProcessing(); };

    get ShowColorMultiply() { return this.showColorMultiply; };
    set ShowColorMultiply(bShow) { this.showColorMultiply = (bShow ? true : false); this.createPostProcessing(); };

    get TimeOfDay() { return this.WorldManager.TimeOfDay; };
    get IsNight() { return this.WorldManager.IsNight; };
    get IsMorning()
    {
        if (!this.timeOfDayDef)
            return false;

        let time = this.TimeOfDay;
        let nightRatio = this.timeOfDayDef.night.duration / (this.timeOfDayDef.day.duration + this.timeOfDayDef.night.duration);
        let morningLength = this.timeOfDayDef.day.morning.duration / (this.timeOfDayDef.night.duration + this.timeOfDayDef.day.duration);

        return time > nightRatio / 2.0 && time <= (nightRatio / 2.0) + morningLength;
    }
    get IsEvening()
    {
        if (!this.timeOfDayDef)
            return false;

        let time = this.TimeOfDay;
        let nightRatio = this.timeOfDayDef.night.duration / (this.timeOfDayDef.day.duration + this.timeOfDayDef.night.duration);
        let eveningLength = this.timeOfDayDef.day.evening.duration / (this.timeOfDayDef.night.duration + this.timeOfDayDef.day.duration);

        return time >= 1 - (eveningLength + nightRatio / 2.0) && time < 1 - (nightRatio / 2.0);
    }

    removePostProcessing ()
    {
        if (this.composer)
        {
            for (let i = 0;  i < this.composer.passes.length; i++)
            {
                this.composer.passes[i] = null;
            }

            this.composer.passes = [];
            this.composer.reset();

        }

        this.colorMultiplyPass = null;
        this.glitchPass = null
        this.vignettePass = null;
        this.shadowOverlayPass = null;

        this.renderpass = null;
        this.composer = null;
    }
    createPostProcessing ()
    {
        this.removePostProcessing();

        this.composer = new EffectComposer(this.renderer);

        this.renderpass = new RenderPass(this.scene, this.camera);
        this.composer.addPass(this.renderpass);

        if (this.IsEnabled)
        {
            this.colorMultiplyPass = null;
            if (this.showColorMultiply)
            {
                this.colorMultiplyIndex = this.composer.passes.length;
                this.colorMultiplyPass = new ShaderPass(ColorMultiplyShader);
                this.composer.addPass(this.colorMultiplyPass);
            }

            this.glitchPass = null;
            if (this.showGlitch)
            {
                this.glitchPassIndex = this.composer.passes.length;
                this.glitchPass = new CustomGlitchPass();

                this.glitchPass.setForce(0.00001);
                this.glitchPass.setFrequency(240, 300);
                this.glitchPass.setDuration(10, 20);

                this.composer.addPass(this.glitchPass);
            }

            this.vignettePass = null;
            if (this.showVignette)
            {
                this.vignettePassIndex = this.composer.passes.length;
                this.vignettePass = new ShaderPass(VignetteShader);
                this.composer.addPass(this.vignettePass);
            }

            this.bokehPass = null;
            this.shadowOverlayPass = null;
            if (this.showOverlay)
            {
                this.shadowOverlayIndex = this.composer.passes.length;
                this.shadowOverlayPass = new ShaderPass(ColorMultiplyShader);
                this.composer.addPass(this.shadowOverlayPass);
                this.composer.passes[this.shadowOverlayIndex].uniforms.color.value = new Color(this.overlayDarkness, this.overlayDarkness, this.overlayDarkness);

                this.bokehPassIndex = this.composer.passes.length;
                this.bokehParams = {
                    "focus": 3, 
                    "maxblur": 0.015, 
                    "aperture": 0.5
                };
                this.bokehPass = new BokehPass(this.scene, this.camera, this.bokehParams);

            }
        }

    }

    calculateVignette (fDelta)
    {
        if (this.night != this.IsNight && this.timeLeftVignetteAnimation <= 0)
        {
            this.night = this.IsNight;
            this.totalVignetteTime = this.timeOfDayDef.night.transition.vignette;
            this.timeLeftVignetteAnimation = this.totalVignetteTime;
        }

        let size = (this.night ? this.vignetteSize : 0);
        let darkness = (this.night ? this.vignetteDarkness : 0);

        if (this.timeLeftVignetteAnimation > 0)
        {
            this.timeLeftVignetteAnimation -= fDelta;
            let t = 1 - this.timeLeftVignetteAnimation / this.totalVignetteTime;
            t = t * t * t * (t * (6.0 * t - 15.0) + 10.0);

            let start = (this.night ? 0 : this.vignetteSize);
            let end = (this.night ? this.vignetteSize : 0);

            size = Lerp.lerp(start, end, t);

            start = (this.night ? 0 : this.vignetteDarkness);
            end = (this.night ? this.vignetteDarkness : 0);

            darkness = Lerp.lerp(start, end, t);
        }

        return {
            "size": size,
            "darkness": darkness
        };
    }

    calculateColorOverlay (fDelta)
    {
        if (this.timeLeftColorAnimation <= 0)
        {
            if (this.morning != this.IsMorning)
            {
                this.morning = this.IsMorning;
                this.totalColorTime = this.timeOfDayDef.day.morning.transition;
                this.timeLeftColorAnimation = this.totalColorTime;
                this.animType = 'morning';
            }
            else if (this.evening != this.IsEvening)
            {
                this.evening = this.IsEvening;
                this.totalColorTime = this.timeOfDayDef.day.evening.transition;
                this.timeLeftColorAnimation = this.totalColorTime;
                this.animType = 'evening';
            }
            else
            {
                let color;
                if (this.IsMorning) { color = this.timeOfDayDef.day.morning.color; }
                else if (this.IsEvening) { color = this.timeOfDayDef.day.evening.color; }
                else if (this.IsNight) { color = this.timeOfDayDef.night.color; }
                else if (this.timeOfDayDef) { color = this.timeOfDayDef.day.color; }
                else { color = {r:0xFF, g:0xFF, b:0xFF}}

                return new Color(color.r, color.g, color.b);
            }
        }

        if (this.timeLeftColorAnimation > 0)
        {
            this.timeLeftColorAnimation -= fDelta;
            let t = 1 - this.timeLeftColorAnimation / this.totalColorTime;
            t = t * t * t * (t * (6.0 * t - 15.0) + 10.0);

            let colorStart = null;
            let colorEnd = null;

            if (this.animType == 'morning')
            {
                colorStart = (this.morning) ? this.timeOfDayDef.night.color : this.timeOfDayDef.day.morning.color;
                colorEnd = (this.morning) ? this.timeOfDayDef.day.morning.color : this.timeOfDayDef.day.color;
            }
            else
            {
                colorStart = (this.evening) ? this.timeOfDayDef.day.color : this.timeOfDayDef.day.evening.color;
                colorEnd = (this.evening) ? this.timeOfDayDef.day.evening.color : this.timeOfDayDef.night.color;
            }

            return new Color(
                Lerp.lerp(colorStart.r, colorEnd.r, t),
                Lerp.lerp(colorStart.g, colorEnd.g, t),
                Lerp.lerp(colorStart.b, colorEnd.b, t)
            );
        }

        return new Color(1, 1, 1);
    }

    render (fDelta)
    {   
        if (this.enabled)
        {
            if (!fDelta) fDelta = 0;

            this.timeElapsed += fDelta;

            if (this.vignettePass)
            {
                let vignette = this.calculateVignette(fDelta);
                this.composer.passes[this.vignettePassIndex].uniforms.offset.value = vignette.size;
                this.composer.passes[this.vignettePassIndex].uniforms.darkness.value = vignette.darkness;
            }

            let color;

            if (this.showColorMultiply)
            {
                color = this.calculateColorOverlay(fDelta);

            }
            else //we want to reset color to white
            {
                color = new Color(1, 1, 1);
            }

            this.composer.passes[this.colorMultiplyIndex].uniforms.color.value = color;


            if (this.showRain)
            {
                this.composer.passes[this.rainShaderIndex].uniforms.time.value = this.timeElapsed;
                this.composer.passes[this.rainShaderIndex].uniforms.rainStrength.value = this.rainStrength;
            }
        }
        this.composer.render(fDelta);
    }

    destroy ()
    {
        this.timeOfDayDef = null;
        this.removePostProcessing();
    }
}