import EventEmitter from "eventemitter3";
import MutationCodes from "../items/codes/MutationCodes.js";
import TriggerCodes from "../triggers/codes/TriggerCodes.js";

export default class CharacterManager extends EventEmitter
{
    constructor()
    {
        super();

        CharacterManager.instance = this;

        this.currentCharacterSaveKey = "current_character";
        this.characterEnergySaveKey = "character_energy";
        this.characterHungerSaveKey = "character_hunger";
        this.characterStressSaveKey = "character_stress";
        this.sleepKey = "character_sleep";
        this.relaxKey = "character_relax";
        this.nameKey = "character_name";
        this.characterBuildKey = "character_build";

        this.lastSecond = 0;
        this.lastEnergyBoost = [0,0,0];
        this.stressReduction = {};
        this.paused = false;

        this.cooldowns = {
            "sprint": 0,
            "power": 0
        }

    }

    get EVENT_CURRENT_CHARACTER_SWITCH() { return "current-character-switch"; }
    get EVENT_PLAYER_HIDE() { return "player-hide"; }
    get EVENT_PLAYER_SHOW() { return "player-show"; }
    get EVENT_POWER_COOLDOWN() { return "power-cooldown"; }
    get EVENT_SPRINT_START() { return "sprint-start"; }
    get EVENT_SPRINT_COOLDOWN() { return "sprint-cooldown"; }
    get EVENT_STAT_VALUE_CHANGED() { return "stat-value-changed"; }
    get EVENT_STATE_CHANGED() { return "state-changed"; }

    get STAT_ENERGY() { return "energy"; }
    get STAT_HUNGER() { return "hunger"; }
    get STAT_STRESS() { return "stress"; }

    get POWER_INVISIBLE() { return "chameleon"; }
    get POWER_STOMP() { return "elephant"; }
    get POWER_SPIT() { return "toad"; }
    get POWER_TELEPORT() { return "rabbit"; }

    get DEFAULT_CHARACTER_BUILDS() { return ["463555_3010", "746000_4003", "130666_1100"]; }

    //---------------------------------------------------------
    //  DEPENDENCIES
    //---------------------------------------------------------
    get AudioManager() { return this.dependencies.get("AudioManager"); }
    get GameManager() { return this.dependencies.get("GameManager"); }
    get ItemManager() { return this.dependencies.get("ItemManager"); }
    get SaveManager() { return this.dependencies.get("SaveManager"); }
    get UIManager() { return this.dependencies.get("UIManager"); }
    get WorldManager() { return this.dependencies.get("WorldManager"); }


    //---------------------------------------------------------
    //  QUICK ACCESS
    //---------------------------------------------------------
    get CurrentCharacter() { return this.SaveManager.getFromSave(this.currentCharacterSaveKey, 0); }

    //Mutation powers
    get CanInvisible() { return this.ItemManager.CanInvisible; }
    get CanJump() { return this.ItemManager.CanJump; }
    get CanPush() { return this.ItemManager.CanPush; }
    get CanSpit() { return this.ItemManager.CanSpit; }
    get CanStomp() { return this.ItemManager.CanStomp; }
    get CanSwim() { return this.ItemManager.CanSwim; }
    get CanTeleport() { return this.ItemManager.CanTeleport; }

    get CanUsePower() { return this.cooldowns.power <= 0 && (this.CanSpit || this.CanStomp || this.CanInvisible); }
    get MutationPowerCooldownRatio()
    {
        if (this.cooldowns.power > 0)
        {
            let total = (this.cooldowns.powerTotalTime - this.cooldowns.powerTotalActiveTime);
            let timeLeft = Math.min(total, this.cooldowns.power);

            return timeLeft / total;
        }
        return 0; 
    }
    //---------------------------------------------------------

    //Settings
    get InvincibilityTime() { return this.invincibilityTime; }
    get HitStunTime() { return this.hitStunTime; }

    //Cooldowns
    get SprintCooldown() { return this.cooldowns.sprint; }
    get PowerCooldown() { return this.cooldowns.power; }

    get SprintSpeedRatio() { return this.sprintSettings.speedRatio; }
    get SprintDuration() { return this.sprintSettings.duration * this.ItemManager.SprintDurationBonus; }
    get SprintMaxCooldown() { return this.sprintSettings.cooldown * this.ItemManager.SprintCooldownRatio + this.SprintDuration; }

    //Character stats
    getCharacterEnergy (iCharacter = -1) { return this.getCharacterStat(this.energySettings, this.characterEnergySaveKey, iCharacter); }
    getCharacterMaxEnergy (iCharacter = -1) { return Math.floor(this.energySettings.max * this.ItemManager.getCharacterEquipment(iCharacter).MaxEnergyRatio); }
    getCharacterEnergyDeath (iCharacter = -1) { return Math.floor(this.energySettings.death * (1 - (this.ItemManager.getCharacterEquipment(iCharacter).MaxEnergyRatio - 1))); }
    getCharacterEnergyGlitch (iCharacter = -1) { return Math.floor(this.energySettings.showGlitchAt * (1 - (this.ItemManager.getCharacterEquipment(iCharacter).MaxEnergyRatio - 1))); }

    getCharacterHunger (iCharacter = -1) { return this.getCharacterStat(this.hungerSettings, this.characterHungerSaveKey, iCharacter); }
    getCharacterMaxHunger (iCharacter = -1)
    {

        let equipment = this.ItemManager.getCharacterEquipment(iCharacter);
        if (equipment === null)
            return this.hungerSettings.max;

        let maxHungerRatio = equipment.MaxHungerRatio;

        return Math.floor(this.hungerSettings.max * maxHungerRatio);
    }
    getCharacterHungerDeath (iCharacter = -1) { return Math.floor(this.hungerSettings.death * (1 - (this.ItemManager.getCharacterEquipment(iCharacter).MaxHungerRatio - 1))); }
    getCharacterHungerGlitch (iCharacter = -1) { return Math.floor(this.hungerSettings.showGlitchAt * (1 - (this.ItemManager.getCharacterEquipment(iCharacter).MaxHungerRatio - 1))); }

    getCharacterStress (iCharacter = -1) { return this.getCharacterStat(this.stressSettings, this.characterStressSaveKey, iCharacter); }
    getCharacterMaxStress (iCharacter = -1) { return Math.floor(this.stressSettings.max * this.ItemManager.getCharacterEquipment(iCharacter).MaxStressRatio); }
    getCharacterStressDeath (iCharacter = -1) { return Math.floor(this.stressSettings.death * this.ItemManager.getCharacterEquipment(iCharacter).MaxStressRatio); }
    getCharacterStressGlitch (iCharacter = -1) { return Math.floor(this.stressSettings.showGlitchAt * this.ItemManager.getCharacterEquipment(iCharacter).MaxStressRatio); }

    setCharacterEnergy (iNewValue, iCharacter = -1)
    { 
        let old = this.getCharacterEnergy(iCharacter);
        if (iNewValue < old)
        {
            iNewValue = old + (iNewValue - old) * (1-this.ItemManager.EnergyLossBonus);
        }

        let min =  this.energySettings.min;
        let max = this.getCharacterMaxEnergy(iCharacter);

        this.setCharacterStat(min, max, this.characterEnergySaveKey, iNewValue, iCharacter);

        if (old != iNewValue)
        {
            this.emit(this.EVENT_STAT_VALUE_CHANGED, this.STAT_ENERGY, iNewValue, (iCharacter < 0 ? this.CurrentCharacter : iCharacter));
        }
    }
    setCharacterHunger (iNewValue, iCharacter = -1)
    {  
        let old = this.getCharacterHunger(iCharacter);
        this.setCharacterStat(this.hungerSettings.min, this.getCharacterMaxHunger(iCharacter), this.characterHungerSaveKey, iNewValue, iCharacter);
        if (old != iNewValue)
        {
            this.emit(this.EVENT_STAT_VALUE_CHANGED, this.STAT_HUNGER, iNewValue, (iCharacter < 0 ? this.CurrentCharacter : iCharacter));
        }
    }

    powerIsActive (strPower)
    {
        if (this.cooldowns[strPower] === undefined)
        {
            this.cooldowns[strPower] = { power: 0, powerTotalTime:0, powerTotalActiveTime:0};
        }
        return this.cooldowns[strPower].power === 0;;
    }

    powerCooldownRatio (strPower)
    {
        if (this.cooldowns[strPower] === undefined)
        {
            this.cooldowns[strPower] = { power: 0, powerTotalTime:0, powerTotalActiveTime:0};
        }

        if (this.cooldowns[strPower].powerTotalTime === 0)
            return 0;

        return this.cooldowns[strPower].power / this.cooldowns[strPower].powerTotalTime;
    }

    setCharacterStress (iNewValue, iCharacter = -1)
    {
        let old = this.getCharacterStress(iCharacter);
        if (iNewValue > old)
        {
            iNewValue = old + (iNewValue - old) * (1-this.ItemManager.StressLossBonus);
        }

        this.setCharacterStat(this.stressSettings.min, this.getCharacterMaxStress(iCharacter), this.characterStressSaveKey, iNewValue, iCharacter);

        if (old != iNewValue)
        {
            this.emit(this.EVENT_STAT_VALUE_CHANGED, this.STAT_STRESS, iNewValue, (iCharacter < 0 ? this.CurrentCharacter : iCharacter));
        }
    }

    getCharacterStat (statDefinition, statSaveKey, iCharacter = -1)
    {
        if (iCharacter < 0)
        {
            iCharacter = this.CurrentCharacter;
        }

        return this.SaveManager.getFromSave(statSaveKey + iCharacter, statDefinition.start);
    }

    setCharacterStat (min, max, statSaveKey, iNewValue, iCharacter = -1)
    {
        if (iCharacter < 0)
        {
            iCharacter = this.CurrentCharacter;
        }
        iNewValue = Math.max(min, Math.min(max, iNewValue));

        this.SaveManager.setFromSave(statSaveKey + iCharacter, iNewValue);
    }

    isCharacterSleeping(iCharacter)
    {
        return this.SaveManager.getFromSave(this.sleepKey + iCharacter, false);
    }

    addCharacterToSleep(iCharacter)
    {
        this.SaveManager.setFromSave(this.sleepKey + iCharacter, true);
        this.emit(this.EVENT_STATE_CHANGED, iCharacter, TriggerCodes.CHARACTER_STATE_SLEEP);
    }

    removeCharacterFromSleep(iCharacter)
    {
        this.SaveManager.setFromSave(this.sleepKey + iCharacter, false);
        this.emit(this.EVENT_STATE_CHANGED, iCharacter, TriggerCodes.CHARACTER_STATE_NORMAL);
    }

    isCharacterRelaxing(iCharacter)
    {
        return this.SaveManager.getFromSave(this.relaxKey + iCharacter, false);
    }

    addCharacterToRelax(iCharacter)
    {
        this.SaveManager.setFromSave(this.relaxKey + iCharacter, true);
        this.emit(this.EVENT_STATE_CHANGED, iCharacter, TriggerCodes.CHARACTER_STATE_RELAX);
    }

    removeCharacterFromRelax(iCharacter)
    {
        this.SaveManager.setFromSave(this.relaxKey + iCharacter, false);
        this.emit(this.EVENT_STATE_CHANGED, iCharacter, TriggerCodes.CHARACTER_STATE_NORMAL);
    }

    getCharacterName(iCharacter)
    {
        return this.SaveManager.getFromSave(this.nameKey + iCharacter, "");
    }

    setCharacterName(iCharacter, strName)
    {
        this.SaveManager.setFromSave(this.nameKey + iCharacter, strName);
    }

    getCharacterCanGoOut(strStatType, iCharacter = -1)
    {
        if (iCharacter < 0)
        {
            iCharacter = this.CurrentCharacter;
        }

        let bOk = true;

        if (strStatType == this.STAT_ENERGY)
        {
            bOk = this.getCharacterEnergy(iCharacter) > this.energySettings.minToGoOut;
        }
        else if (strStatType == this.STAT_HUNGER)
        {
            bOk = this.getCharacterHunger(iCharacter) > this.hungerSettings.minToGoOut;
        }
        else if (strStatType == this.STAT_STRESS)
        {
            bOk = this.getCharacterMaxStress(iCharacter) - this.getCharacterStress(iCharacter) > this.stressSettings.max - this.stressSettings.minToGoOut;
        }

        return bOk;
    }

    getCharacter (iCharacter)
    {
        //TODO check if in barn

        if (this.WorldManager.IsBarn)
        {
            return this.WorldManager.Environment.Characters[iCharacter];
        }
    }
    getCharacterBuild(iCharacter)
    {
        return this.SaveManager.getFromSave(this.characterBuildKey + iCharacter, this.DEFAULT_CHARACTER_BUILDS[iCharacter]);
    }

    setCharacterBuild(iCharacter, strBuild)
    {
        this.SaveManager.setFromSave(this.characterBuildKey + iCharacter, strBuild);
    }

    /**
        Finds which mutation should be applied next for a character. It follows this logic:
            - If the character has a head mutation -> Next is a leg mutation
            - If the character has an arm mutation -> Next is a head mutation
            - If the character has a leg mutation -> Next is a arm mutation
        @param iCharacter   Id of the character to get its next mutation
        @return             Object containing the definition of the mutation to apply
    */
    getCharacterNextMutation(iCharacter)
    {
        let build = this.getCharacterBuild(iCharacter);
        let buildSplit = build.split("_");

        let mutation = null;

        for (let i = 0; i < this.mutationSettings.ingame.length; i++)
        {
            let definition = this.ItemManager.getItem(this.mutationSettings.ingame[i]);

            if 
            (
                (parseInt(buildSplit[1][1]) > 0 && definition.IsLeg) ||
                (parseInt(buildSplit[1][2]) > 0 && definition.IsHead) ||
                (parseInt(buildSplit[1][3]) > 0 && definition.IsArm)
            )
            {
                mutation = definition;
                break;
            }
        }

        return mutation
    }


    getCharacterMutations (iCharacter = -1)
    {
        let build = this.getCharacterBuild(iCharacter).split("_")[1];

        let iMutationHead = parseInt(build[1]);
        let iMutationArm = parseInt(build[2]);
        let iMutationLeg = parseInt(build[3]);

        let arrMutations = [];

       if (iMutationHead > 0)
       {
           arrMutations.push(MutationCodes.MUTATION_HEAD[iMutationHead - 1]);
       }

        if (iMutationArm > 0)
        {
            arrMutations.push(MutationCodes.MUTATION_ARM[iMutationArm - 1]);
        }

        if (iMutationLeg > 0)
        {
            arrMutations.push(MutationCodes.MUTATION_LEGS[iMutationLeg - 1]);
        }

        return arrMutations;
    }


    /**
     Finds a mutation
     @param strMutation
     @return             Object containing the definition of the mutation to apply
     */
    getMutation(strMutation)
    {
        let mutation = this.ItemManager.getItem(strMutation);
        return mutation;
    }

    /**
        Gets the mandatory mutation that is bound to characters at the start of the game
        @param strType  Type of mutation to get. Use the TYPE_MUTATION_XXX from the ItemCodes class
        @return int     Mutation found or returns NULL if no mandatory mutation could be found for this type
    */
    getMandatoryMutation(strType)
    {
        let index = 1;
        if (strType == this.ItemManager.ITEM_CODES.TYPE_MUTATION_ARM)
        {
            index = 2;
        }
        else if (strType == this.ItemManager.ITEM_CODES.TYPE_MUTATION_LEG)
        {
            index = 3;
        }

        let defaultBuilds = this.DEFAULT_CHARACTER_BUILDS;
        for (let i = 0; i < defaultBuilds.length; i++)
        {
            let split = defaultBuilds[i].split("_");
            let mutation = parseInt(split[1].substr(index, 1));

            if (mutation > 0)
            {
                return mutation;
            }
        }
        return null;
    }

    /**
        Applies to a character it's next mutation in its current build.
        @param iCharacter   Id of the character to set its next mutation
    */
    setCharacterNextMutation(iCharacter)
    {
        let mutation = this.getCharacterNextMutation(iCharacter);
        let mutationIndex = 0;
        let buildIndex = 1;
        let list = [];

        if (mutation.IsHead)
        {
            list = MutationCodes.MUTATION_HEAD;
            buildIndex = 8;
        }
        else if (mutation.IsArm)
        {
            list = MutationCodes.MUTATION_ARM;
            buildIndex = 9;
        }
        else if (mutation.IsLeg)
        {
            list = MutationCodes.MUTATION_LEGS;
            buildIndex = 10;
        }

        for (let i = 0; i < list.length; i++)
        {
            if (mutation.UICode == list[i])
            {
                mutationIndex = i + 1;
                break;
            }
        }

        let build = this.getCharacterBuild(iCharacter);
        build = build.substring(0, buildIndex) + mutationIndex + build.substring(buildIndex + 1);

        this.setCharacterBuild(iCharacter, build);
        this.ItemManager.equipment[iCharacter].calculateMutations();
    }

    /**
        Calculates which character should be next for a character mutation upgrade
        @return     Id of the character found otherwise NULL is returned if all characters have been upgraded
    */
    getNextCharacterForMutationUpgrade()
    {
        let character = null;

        for (let i = 0; i < 3; i++)
        {
            let build = this.getCharacterBuild(i);
            let mutationCount = 0;
            for (let j = 0; j < 3; j++)
            {
                if (parseInt(build[8 + j]) > 0)
                {
                    mutationCount++;
                }
            }

            if (mutationCount <= 1)
            {
                character = i;
                break;
            }
        }

        return character;
    }

    getCharacterWithThisMutation (strBodyPart)
    {
        let character = null;
        let objBodyPartIndex = {head: 8, arm:9, leg: 10};
        let iBodyPart = objBodyPartIndex[strBodyPart];

        for (let i = 0; i < 3 && character === null; i++)
        {
            let arrBuildPart = this.getCharacterBuild(i);
            let mutationCount = 0;
            for (let j = 0; j < 3; j++)
            {
                if (parseInt(arrBuildPart[8 + j]) > 0)
                {
                    mutationCount++;
                }
            }

            let iCharacterMutation = parseInt(arrBuildPart[iBodyPart])
            if (mutationCount <= 1 &&  iCharacterMutation > 0)
            {
                character = i;
            }
        }

        return character;

    }

    init(dependencies)
    {
        this.dependencies = dependencies;

        this.createClosure();
        this.bindEvents();
    }

    createClosure()
    {
        this.fctUpdate = this.update.bind(this);
        this.fctOnLocationChange = this.onLocationChange.bind(this);
        this.fctOnCharacterStateChange = this.onCharacterStateChange.bind(this);
    }

    bindEvents ()
    {
        if (this.WorldManager.on)
        { 
            console.log(this.WorldManager)
            this.WorldManager.on(this.WorldManager.EVENT_PLAYER_LOCATION_CHANGED, this.fctOnLocationChange);
        }
        this.GameManager.on(this.GameManager.EVENT_UPDATE, this.fctUpdate);
        this.on(this.EVENT_STATE_CHANGED, this.fctOnCharacterStateChange);//@!
    }

    pauseStatCalculations ()
    {
        this.paused = true;
    }

    resumeStatCalculations ()
    {
        this.paused = false;
    }

    //---------------------------------------------------------
    //  CHARACTER ACTIONS
    //---------------------------------------------------------
    switchCurrentCharacter(iNewCharacter)
    {
        this.SaveManager.setFromSave("current_character", Math.max(0, Math.min(2, iNewCharacter)));
        for (let i = 0; i < 3; i++)
        {
            this.stopStressReduction(i);
        }
        this.emit(this.EVENT_CURRENT_CHARACTER_SWITCH, iNewCharacter);
    }

    sprint ()
    {
        if (this.cooldowns.sprint <= 0 && 
                this.getCharacterEnergy() + this.energySettings.cost.sprint > this.getCharacterEnergyDeath() &&
                this.getCharacterHunger() + this.hungerSettings.cost.sprint > this.getCharacterHungerDeath())
        {
            this.cooldowns.sprint = this.SprintMaxCooldown;

            let energyCost = this.energySettings.cost.sprint;
            let hungerCost = this.hungerSettings.cost.sprint;

            let leg = this.ItemManager.MutationLeg;

            if (leg && leg.id === "MJ-TIG")
            {
                energyCost = Math.ceil(energyCost / 2);
                hungerCost = Math.ceil(hungerCost / 2);
            }

            this.setCharacterEnergy(this.getCharacterEnergy() + energyCost);
            this.setCharacterHunger(this.getCharacterHunger() + hungerCost);

            this.emit(this.EVENT_SPRINT_START);

            return true;
        }
        return false;
    }

    pickObject ()
    {
        if (this.getCharacterEnergy() + this.energySettings.cost.pickObject > this.getCharacterEnergyDeath())
        {
            this.setCharacterEnergy(this.getCharacterEnergy() + this.energySettings.cost.pickObject);
            return true;
        }
        return false;
    }

    swim (iTileCount)
    {
        if (this.CanSwim && this.getCharacterEnergy() + this.energySettings.cost.swim * iTileCount > this.getCharacterEnergyDeath())
        {
            this.setCharacterEnergy(this.getCharacterEnergy() + this.energySettings.cost.swim * iTileCount);

            //@TODO: Make the character swim
            return true;
        }
        return false;
    }

    push ()
    {
        if (this.CanPush && this.getCharacterEnergy() + this.energySettings.cost.push > this.getCharacterEnergyDeath())
        {
            this.setCharacterEnergy(this.getCharacterEnergy() + this.energySettings.cost.push);
            return true;
        }
        return false;
    }

    jump ()
    {
        if (this.CanJump && this.getCharacterEnergy() + this.energySettings.cost.jump > this.getCharacterEnergyDeath())
        {
            this.setCharacterEnergy(this.getCharacterEnergy() + this.energySettings.cost.jump);

            //@TODO: Make the character jump
            return true;
        }
        return false;
    }

    usePower(strPower = "")
    {

        if (this.cooldowns[strPower] === undefined  )
        {
            this.cooldowns[strPower] = { power: 0, powerTotalTime:0, powerTotalActiveTime:0};
        }

        if (this.cooldowns[strPower].power <= 0)
        {
            if (this.CanInvisible)
            {
                this.cooldowns[strPower].power = this.ItemManager.MutationHead.MutationPower.ActiveCooldown;
                this.cooldowns[strPower].powerTotalActiveTime = this.ItemManager.MutationHead.MutationPower.ActiveDuration;
                this.cooldowns[strPower].powerTotalTime = this.cooldowns[strPower].powerTotalActiveTime ;

                return true;
            }
            else if (this.CanSpit && strPower === this.POWER_SPIT)
            {
                this.cooldowns[strPower].power = this.ItemManager.MutationHead.MutationPower.ActiveCooldown;
                this.cooldowns[strPower].powerTotalTime = this.cooldowns[strPower].power;
                this.cooldowns[strPower].powerTotalActiveTime = this.ItemManager.MutationHead.MutationPower.ActiveDuration;

                return true;
            }
            else if (this.CanTeleport && strPower === this.POWER_TELEPORT)
            {

                if (this.WorldManager.Player)
                {
                    this.WorldManager.Player.stopMovement();
                    this.AudioManager.playSfx("teleportation");

                    this.WorldManager.preventWorldClick();

                    this.UIManager.showSceneTransition(
                        function()
                        {
                            setTimeout(function()
                            {
                                this.WorldManager.changeEnvironment(this.WorldManager.ENVIRONMENT_BARN);
                                this.WorldManager.allowWorldClick();
                            }
                            .bind(this) ,1);
                        }
                        .bind(this)
                    );
                    return true;
                }
                return false;
            }
            else if (this.CanStomp  && strPower === this.POWER_STOMP)
            {
                this.cooldowns[strPower].power = this.ItemManager.MutationLeg.MutationPower.ActiveCooldown;
                this.cooldowns[strPower].powerTotalTime = this.cooldowns[strPower].power;
                this.cooldowns[strPower].powerTotalActiveTime = this.ItemManager.MutationLeg.MutationPower.ActiveDuration;

                return true;
            }
        }

        return false;
    }

    startStressReduction ()
    {
        let amount = this.stressSettings.timeRelated.stressReduction.breathing.amount;
        let interval = this.stressSettings.timeRelated.stressReduction.breathing.interval

        let toy = this.ItemManager.ToyEquipment;
        if (toy && toy.CanDropStress)
        {
            amount = toy.StressDropAmount;
            interval = toy.StressDropInterval;
        }

        this.stressReduction[this.CurrentCharacter] = {
            "amount": amount,
            "interval": interval
        };

        this.isAntiStress = true;
    }

    stopStressReduction (iCharacter = -1)
    {
        if (iCharacter < 0)
        {
            iCharacter = this.CurrentCharacter;
        }

        this.isAntiStress = false;
        this.stressReduction[iCharacter] = null;

    }
    //---------------------------------------------------------

    //---------------------------------------------------------
    //  UPDATE METHODS
    //---------------------------------------------------------
    update (fDeltaTime)
    {
        if (!this.GameManager.IsPaused && this.WorldManager.IsInGameScene && !this.WorldManager.IsDialogMode && !this.WorldManager.IsCinematicMode && !this.WorldManager.IsPlayerHidden)
        {
            if (!this.paused)
            {
                let lastSec = Math.floor(new Date().getTime() / 1000);
                if (lastSec != this.lastSecond)
                {
                    this.lastSecond = lastSec;
                    this.updateCharactersEnergy();
                    this.updateCharactersHunger();
                    this.updateCharactersStress();
                    this.updateGlitch();
                }
            }
            this.updateCooldowns(fDeltaTime);
        }
    }

    getSleepAmount ()
    {
        let iLevelSleep = this.WorldManager.getBarnZoneLevel(this.WorldManager.BARN_ZONE_SLEEP) - 1;

        let iSleepAmount = this.energySettings.timeRelated.sleep[iLevelSleep].amount;

        return iSleepAmount;
    }

    getSleepInterval ()
    {
        let iLevelSleep = this.WorldManager.getBarnZoneLevel(this.WorldManager.BARN_ZONE_SLEEP) - 1;

        let iSleepInterval = this.energySettings.timeRelated.sleep[iLevelSleep].interval;

        return iSleepInterval;
    }

   updateCharactersEnergy ()
    {
        for (let i = 0; i < 3; i++)
        {
            if (i == this.CurrentCharacter && this.WorldManager.IsHostile && this.WorldManager.Player.IsDead)
            {
                continue;
            }

            let energy = this.getCharacterEnergy(i);
            let iSleepInterval = this.getSleepInterval();

            //let iMod = this.lastSecond % iSleepInterval;
            //let bSleepInterval = iMod >= 0;

            let diff = this.lastSecond - this.lastEnergyBoost[i];

            let bSleepInterval = diff > iSleepInterval;

            if (this.isCharacterSleeping(i) && bSleepInterval)
            {
                let iSleepAmount = this.getSleepAmount();
                this.setCharacterEnergy(this.getCharacterEnergy(i) + iSleepAmount, i);
                this.lastEnergyBoost[i] = this.lastSecond;
            }
            else if (!this.isCharacterSleeping(i) && this.lastSecond % this.energySettings.timeRelated.barn.interval == 0)
            {
                if (!this.WorldManager.IsHostile || i != this.CurrentCharacter)
                {
                    this.setCharacterEnergy(this.getCharacterEnergy(i) + this.energySettings.timeRelated.barn.amount, i);
                }
            }

            if (i == this.CurrentCharacter && this.WorldManager.IsHostile)
            {

                if (energy <= this.getCharacterEnergyDeath(i))
                {


                    if (this.WorldManager.PostProcessing.ShowGlitch)
                    {
                        this.WorldManager.PostProcessing.ShowGlitch = false;
                    }

                    this.WorldManager.Player.die(this.STAT_ENERGY);

                }

                let energyVal = this.getCharacterEnergy(this.CurrentCharacter);
                let interval = this.energySettings.timeRelated.forest.interval;

                let arm = this.ItemManager.MutationArm;

                if (arm && arm.id === "MB-RAT")
                {
                    interval = 59;
                }

                if (this.lastSecond % interval === 0)
                {
                    let body = this.ItemManager.BodyEquipment;

                    let energyLoss = this.energySettings.timeRelated.forest.amount;

                    this.setCharacterEnergy(energyVal - energyLoss);
                }
            }

        }
    }

    updateCharactersHunger ()
    {
        for (let i = 0; i < 3; i++)
        {
            let hunger = this.getCharacterHunger(i);
            if (i == this.CurrentCharacter)
            {
                if (this.WorldManager.IsHostile && this.WorldManager.Player.IsDead)
                {
                    continue;
                }

                if (hunger <= this.getCharacterHungerDeath(i))
                {
                    if (this.WorldManager.IsHostile)
                    {
                        if (this.WorldManager.PostProcessing.ShowGlitch)
                        {
                            this.WorldManager.PostProcessing.ShowGlitch = false;
                        }

                        this.WorldManager.Player.die(this.STAT_HUNGER);
                    }
                }
                else if (this.lastSecond % this.hungerSettings.timeRelated.forest.interval == 0)
                {
                    if (this.WorldManager.IsHostile && i == this.CurrentCharacter)
                    {
                        this.setCharacterHunger(hunger + this.hungerSettings.timeRelated.forest.amount, i);
                    }
                }
            }
            if (this.lastSecond % this.hungerSettings.timeRelated.barn.interval == 0)
            {
                if (!this.WorldManager.IsHostile || i != this.CurrentCharacter)
                {
                    this.setCharacterHunger(this.getCharacterHunger(i) + this.hungerSettings.timeRelated.barn.amount, i);
                }
            }
        }
    }

    updateCharactersStress ()
    {
        for (let i = 0; i < 3; i++)
        {
            if (i == this.CurrentCharacter && this.WorldManager.IsHostile && this.WorldManager.Player.IsDead)
            {
                continue;
            }

            let stress = this.getCharacterStress(i);
            if (this.WorldManager.IsHostile && stress >= this.getCharacterStressDeath(i) && i == this.CurrentCharacter)
            {
                if (this.WorldManager.IsHostile)
                {
                    if (this.WorldManager.PostProcessing.ShowGlitch)
                    {
                        this.WorldManager.PostProcessing.ShowGlitch = false;
                    }

                    this.WorldManager.Player.die(this.STAT_STRESS);
                }
            }
            else
            {
                //Playing with a tool to reduce stress
                if (i in this.stressReduction && this.stressReduction[i] &&
                    this.lastSecond % this.stressReduction[i].interval == 0)
                {
                    this.setCharacterStress(stress + this.stressReduction[i].amount, i);
                }
                //Chills in the relax zone
                if (this.isCharacterRelaxing(i) && this.lastSecond % this.stressSettings.timeRelated.stressReduction.relax.interval == 0)
                {
                    let stressReduction = this.stressSettings.timeRelated.stressReduction.relax.amount;

                    this.setCharacterStress(stress + stressReduction, i);
                }
                else if (!this.isCharacterRelaxing(i) && this.lastSecond % this.stressSettings.timeRelated.barn.interval == 0)
                {
                    if (!this.WorldManager.IsHostile || i != this.CurrentCharacter)
                    {
                        this.setCharacterStress(stress + this.stressSettings.timeRelated.barn.amount, i);
                    }
                }

                if (i == this.CurrentCharacter && (!(this.CurrentCharacter in this.stressReduction) || !this.stressReduction[this.CurrentCharacter]))
                {
                    if (this.WorldManager.IsHostile)
                    {
                        //Player is wandering in the forest
                        let values = (this.WorldManager.IsNight ? this.stressSettings.timeRelated.forest.night : this.stressSettings.timeRelated.forest.day);

                        let arm = (this.ItemManager.MutationArm);

                        let iInterval = values.interval;

                        if (arm && arm.id === "MB-PAR")
                        {
                            iInterval += 15;
                        }

                        if (this.lastSecond % iInterval == 0)
                        {

                            this.setCharacterStress(stress + values.amount, i);
                        }

                        //Enemies are in range or chasing the player
                        let enemyMaxRange = Math.pow(this.stressSettings.enemyMaxRange, 2);

                        let playerPos = this.WorldManager.Player.GridPos;
                        let bIsNear = false;
                        let bIsChasing = false;

                        for (let key in this.WorldManager.Enemies)
                        {
                            let enemy = this.WorldManager.Enemies[key];
                            let enemyPos = enemy.GridPos;

                            let sqrDist = Math.pow(playerPos.x - enemyPos.x, 2) + Math.pow(playerPos.y - enemyPos.y, 2);
                            if (sqrDist <= enemyMaxRange)
                            {
                                bIsNear = true;
                            }

                            if (enemy.IsAttacking)
                            {
                                bIsChasing = true;
                            }
                        }

                        if (bIsNear)
                        {
                            if (this.lastSecond % this.stressSettings.timeRelated.enemyOnScreen.interval == 0)
                            {
                                this.setCharacterStress(stress + this.stressSettings.timeRelated.enemyOnScreen.amount, i);
                            }
                        }

                        if (bIsChasing)
                        {
                            if (this.lastSecond % this.stressSettings.timeRelated.enemyTarget.interval == 0)
                            {
                                this.setCharacterStress(stress + this.stressSettings.timeRelated.enemyTarget.amount, i);
                            }
                        }
                    }
                }
            }
        }
    }

    updateGlitch()
    {
        let isGlitch = this.WorldManager.PostProcessing ? this.WorldManager.PostProcessing.ShowGlitch : false;

        //Only show the glitch effect in the forest
        if (this.WorldManager.IsHostile)
        {
            let energy = this.getCharacterEnergy();
            let hunger = this.getCharacterHunger();
            let stress = this.getCharacterStress();

            //If the main character is "dead" then stop showing the glitch effect
            if (energy <= this.getCharacterEnergyDeath() || hunger <= this.getCharacterHungerDeath() || stress >= this.getCharacterStressDeath())
            {
                if (isGlitch)
                {
                    this.WorldManager.PostProcessing.ShowGlitch = false;
                }
            }
            //If the character is near-death, then show the glitch effect
            else if (energy <= this.getCharacterEnergyGlitch() || hunger <= this.getCharacterHungerGlitch() || stress >= this.getCharacterStressGlitch())
            {
                if (!isGlitch)
                {
                    this.WorldManager.PostProcessing.ShowGlitch = true;
                }
            }
            //Otherwise, hide the glitch effect
            else if (isGlitch)
            {
                this.WorldManager.PostProcessing.ShowGlitch = false;
            }
        }
        else if (isGlitch)
        {
            this.WorldManager.PostProcessing.ShowGlitch = false;
        }

    }

    updateCooldowns (fDeltaTime)
    {
        if (this.cooldowns.sprint > 0)
        {
            this.cooldowns.sprint -= fDeltaTime;
            this.cooldowns.sprint = Math.max(0, this.cooldowns.sprint);

            this.emit(this.EVENT_SPRINT_COOLDOWN, this.cooldowns.sprint);
        }

        for (let strKey in this.cooldowns)
        {
            if (this.cooldowns[strKey] && this.cooldowns[strKey].power > 0)
            {
                this.cooldowns[strKey].power -= fDeltaTime;
                this.cooldowns[strKey].power = Math.max(0, this.cooldowns[strKey].power);

                this.emit(this.EVENT_POWER_COOLDOWN, this.cooldowns[strKey].power);
            }
        }
    }
    //---------------------------------------------------------

    onLoadComplete(objSettings)
    {
        let gameSettings = objSettings.gameSettings;

        this.energySettings = gameSettings.character.energy;
        this.hungerSettings = gameSettings.character.hunger;
        this.stressSettings = gameSettings.character.stress;
        this.sprintSettings = gameSettings.character.sprint;
        this.invincibilityTime = gameSettings.character.invincibilityWhenHit;
        this.hitStunTime = gameSettings.character.hitStunTime;
        this.mutationSettings = gameSettings.character.mutations;
    }

    onLocationChange (strLocation)
    {
        for (let i = 0; i < 3; i++)
        {
            this.stopStressReduction(i);
        }
    }

    onCharacterStateChange(iCharacter, strNewState)
    {
        if (strNewState == TriggerCodes.CHARACTER_STATE_DEAD)
        {
            let energy = this.getCharacterEnergy();
            let death = this.getCharacterEnergyDeath();

            console.log("stateChange", iCharacter, energy, death);

            if (energy <= death)
            {
                this.setCharacterEnergy(this.energySettings.afterDeath);
            }
            if (this.getCharacterHunger() <= this.getCharacterHungerDeath())
            {
                this.setCharacterHunger(this.hungerSettings.afterDeath);
            }
            if (this.getCharacterStress() >= this.getCharacterStressDeath())
            {
                this.setCharacterStress(this.getCharacterStressDeath() - (this.stressSettings.death - this.stressSettings.afterDeath));
            }
        }
    }
}