import { string2hex }  from "@pixi/utils"; 
import ContainerResponsive from "../../commons/responsive/ContainerResponsive.js";
import GraphicsResponsive from "../../commons/responsive/GraphicsResponsive.js";
import Library from "../../../Library.js";
import SpriteResponsive from "../../commons/responsive/SpriteResponsive.js";

export default class MutationButton extends ContainerResponsive
{
    constructor ()
    {
        super();
    }

    get EVENT_CLICK() { return "click"; }

    get Icon() { return this.icon; }
    set Icon(strNewIcon) { this.icon = strNewIcon; this.build(); }

    get ButtonWidth() { return this.strWidth; }
    set ButtonWidth(strNewWidth) { this.strWidth = strNewWidth; this.build(); }

    get ButtonHeight() { return this.strHeight; }
    set ButtonHeight(strNewHeight) { this.strHeight = strNewHeight; this.build(); }

    get IsSelected() { return this.selected; }
    set IsSelected(bNewValue) { this.selected = (bNewValue ? true : false); this.build(); }

    get IsDisabled() { return this.disabled; }
    set IsDisabled(bNewValue) { this.disabled = (bNewValue ? true : false); this.build(); }

    get IconTint() { return string2hex(this.UI.Values.charactercreator.icon.tint); }
    get BackgroundColor() { return string2hex(this.UI.Values.slot.background.color); }
    get CornerRadius() { return this.evaluate(this.valueFormula(this.UI.Values.textbutton.corner.radius), 0, 0, 0, 0, this); }
    get StrokeWidth() { return this.evaluate(this.valueFormula(this.UI.Values.colorpick.stroke.size), 0, 0, 0, 0);}
    get StrokeColor() { return string2hex(this.UI.Values.colorpick.stroke.color);}
    get SelectedStrokeColor() { return string2hex(this.UI.Values.colorpick.stroke.selected.color);}

    get DisabledOpacity() { return this.UI.Values.textbutton.disabled.opacity; }

    setSize(strWidth, strHeight)
    {
        this.strWidth = strWidth;
        this.strHeight = strHeight;

        this.build();
    }

    /*******************************************
    *   INITIALIZATION
    *******************************************/
    /**
        Parameters to pass to the init function:
        - ui:        UI section object where this component resides
        - index:     Index number representing the order of this button on screen
        - icon:      Name of the texture to use as the icon
        - rWidth:    Responsive equation representing the width of this component
        - rHeight:   Responsive equation representing the height of this component
        - clickable: (Optional) If this component is clickable or not. Default is TRUE
    */
    init(meta)
    {
        this.icon = meta.icon;
        this.index = meta.index;

        this.graphics = {};
        this.buttonScale = {"x": meta.ui.Values.textbutton.scale.normal, "y": meta.ui.Values.textbutton.scale.normal};

        this.strWidth = meta.rWidth;
        this.strHeight =  meta.rHeight;
        this.selected = false;
        this.disabled = false;
        this.clickable = ("clickable" in meta ? meta.clickable : true);

        return super.init(meta);
    }

    createClosure()
    {
        super.createClosure();

        this.fctOnClick = this.onClick.bind(this);
        this.fctOnPointerDown = this.onPointerDown.bind(this);
        this.fctOnWindowMouseUp = this.onWindowMouseUp.bind(this);
        this.fctOnWindowTouchEnd = this.onWindowTouchEnd.bind(this);
    }

    bindEvents()
    {
        super.bindEvents();

        window.addEventListener("mouseup", this.fctOnWindowMouseUp);
        window.addEventListener("touchend", this.fctOnWindowTouchEnd);
    }

    destroy(options)
    {
        window.removeEventListener("mouseup", this.fctOnWindowMouseUp);
        window.removeEventListener("touchend", this.fctOnWindowTouchEnd);

        for (let key in this.graphics)
        {
            if (this.graphics[key].spriteIcons)
            {
                for (let id in this.graphics[key].spriteIcons)
                {
                    this.graphics[key].removeChild(this.graphics[key].spriteIcons[id]);
                    this.graphics[key].spriteIcons[id].destroy(options);
                }
                delete this.graphics[key].spriteIcons;
            }
            this.removeChild(this.graphics[key]);
            this.graphics[key].destroy(options);
        }
        delete this.graphics;

        super.destroy(options);
    }

    /*******************************************
    *   BUILD
    *******************************************/
    build()
    {
        super.build();

        let values = this.buildBackground();

        if (this.Icon !== null)
        {
            this.buildIcon(values.width, values.height, values.graphics);
        }
    }

    clean()
    {
        for(let key in this.graphics)
        {
            this.graphics[key].clear();
        }
        super.clean();
    }

    buildBackground()
    {
        if (!this.graphics.background)
        {
            this.graphics.background = new GraphicsResponsive().init({"ui": this.UI});
            this.graphics.background.interactive = this.clickable;
            this.graphics.background.on("pointerdown", this.fctOnPointerDown);
            this.graphics.background.on("pointerup", this.fctOnClick);
            this.graphics.background.spriteIcons = {};

            this.addChild(this.graphics.background);
        }

        let graphics = this.graphics.background;
        graphics.buttonMode = !this.IsDisabled;

        let strokeWidth = this.StrokeWidth;
        let strokeColor = (this.IsSelected ? this.SelectedStrokeColor : this.StrokeColor);
        let cornerRadius = this.CornerRadius;

        let width = this.evaluate(this.ButtonWidth, 0, 0, 0, 0, this) * this.buttonScale.x;
        let height = this.evaluate(this.ButtonHeight, 0, 0, 0, 0, this) * this.buttonScale.y;

        let x = width * ((1 - this.buttonScale.x) / 2);
        let y = height * ((1 - this.buttonScale.y) / 2);

        if (strokeWidth > 0)
        {
            graphics.lineStyle({"color":strokeColor, "width":strokeWidth, "alignment":1});
        }

        graphics.beginFill(this.BackgroundColor, 1);
        if (cornerRadius > 0)
        {
            graphics.drawRoundedRect(x, y, width, height, cornerRadius);
        }
        else
        {
            graphics.drawRect(x, y, width, height);
        }
        graphics.endFill();

        graphics.alpha = (this.IsDisabled ? this.DisabledOpacity : 1);

        for (let key in graphics.spriteIcons)
        {
            graphics.removeChild(graphics.spriteIcons[key]);
        }

        return {"width": width, "height": height, "graphics": graphics};
    }

    buildIcon(fWidth, fHeight, objParent)
    {
        if (!objParent.spriteIcons[this.Icon])
        {
            let texture = Library.getTextureFromAtlas("ui", this.Icon);
            objParent.spriteIcons[this.Icon] = new SpriteResponsive(texture).init({"ui": this.UI});
        }
        objParent.addChild(objParent.spriteIcons[this.Icon]);

        let sprite = objParent.spriteIcons[this.Icon];

        let ratio = sprite.height / sprite.width;
        let width = fWidth * 0.85;
        let height = width * ratio;

        if (sprite.width < sprite.height)
        {
            ratio = sprite.width / sprite.height;
            height = fHeight * 0.85;
            width = height * ratio;
        }

        let x = fWidth / 2 - width / 2 + width * ((1 - this.buttonScale.x)/2);
        let y = fHeight / 2 - height / 2 + height * ((1 - this.buttonScale.y)/2);

        sprite.width = width;
        sprite.height = height;
        sprite.position.set(x, y);

        sprite.tint = this.IconTint;
    }

    /*******************************************
    *   EVENTS
    *******************************************/
    onClick()
    {
        if (!this.IsDisabled && this.clickable)
        {
            this.buttonScale.x = this.UI.Values.textbutton.scale.normal;
            this.buttonScale.y = this.UI.Values.textbutton.scale.normal;
            this.build();

            this.UI.AudioManager.playSfx(this.UI.Values.audio.click);
            this.emit(this.EVENT_CLICK, this);
        }
    }

    onPointerDown()
    {
        if (!this.IsDisabled && this.clickable)
        {
            this.buttonScale.x = this.UI.Values.textbutton.scale.down;
            this.buttonScale.y = this.UI.Values.textbutton.scale.down;
            this.build();
        }
    }

    onWindowMouseUp(e)
    {
        this.buttonScale.x = this.UI.Values.textbutton.scale.normal;
        this.buttonScale.y = this.UI.Values.textbutton.scale.normal;
        this.build();
    }

    onWindowTouchEnd(e)
    {
        if (e.touches.length == 0)
        {
            this.buttonScale.x = this.UI.Values.textbutton.scale.normal;
            this.buttonScale.y = this.UI.Values.textbutton.scale.normal;
            this.build();
        }
    }
}