import {DirectionalLight} from "three";
import {Mesh} from "three";
import {MeshBasicMaterial} from "three";
import {Raycaster} from "three";
import {Vector2} from "three";
import {Vector3} from "three";
import BaseScene from "./BaseScene.js";

export default class BarnScene extends BaseScene
{
    constructor(canvas)
    {
        super(canvas);
    }

    get MinScreen() { return this.screenSize.min; }
    get MaxScreen() { return this.screenSize.max; }

    /*******************************************
    *   INITIALIZATION
    *******************************************/
    init()
    {
        this.screenSize = {"min": new Vector2(), "max": new Vector2()};
        return super.init();
    }

    createCamera()
    {
        super.createCamera();

        this.camera.near = 0.01;
        this.camera.updateProjectionMatrix();
    }

    /*******************************************
    *   CALCULATIONS
    *******************************************/
    calculateScreenSize(objIntersectMesh)
    {
        let raycaster = new Raycaster();
        //Min screen
        raycaster.setFromCamera({x:-1, y:1}, this.camera);
        let intersects = raycaster.intersectObject(objIntersectMesh);
        this.screenSize.min = new Vector2(intersects[0].point.x, intersects[0].point.z);

        //Max screen
        raycaster.setFromCamera({x:1, y:-1}, this.camera);
        intersects = raycaster.intersectObject(objIntersectMesh);
        this.screenSize.max = new Vector2(intersects[0].point.x, intersects[0].point.z);
    }

    calculateCamera(fBaseAspect)
    {
        this.camera.position.x = 0;
        this.camera.position.z = 0;

        this.camera.rotation.x = 0;
        this.camera.rotation.y = 0;
        this.camera.rotation.z = 0;

        console.log("change camera rotation")
        this.camera.lookAt(new Vector3(0, 0, 0));

        let ratio = this.ScreenAspect;
        if (ratio > fBaseAspect)
        {
            this.camera.zoom = 1 + 2*(ratio - fBaseAspect);
            this.zDiff = -10*(ratio - fBaseAspect)
            this.camera.position.z += this.zDiff;
            this.camera.updateProjectionMatrix();
        }
    }
}