import { string2hex }  from '@pixi/utils'; 
import { Text } from '@pixi/text';
import { TextStyle } from '@pixi/text';
import { TextMetrics } from '@pixi/text';
import ContainerResponsive from "../responsive/ContainerResponsive.js";
import GraphicsResponsive from "../responsive/GraphicsResponsive.js";
import TextResponsive from "../responsive/TextResponsive.js";

export default class TextButton extends ContainerResponsive
{
    constructor ()
    {
        super();
    }

    get EVENT_CLICK() { return "self-click"; }

    get Text() { return this.text; }
    set Text(strNewValue) { this.text = strNewValue; this.build(); }

    get FontSize() { return this.evaluate(this.valueFormula(this.fontSize)); }
    set FontSize(fNewValue) { this.fontSize = fNewValue; this.build(); }

    get PaddingHorizontal() { return this.evaluate(this.valueFormula(this.padding.horizontal)); }
    set PaddingHorizontal(fNewValue) { this.padding.horizontal = padding.horizontal; this.build(); }

    get PaddingVertical() { return this.evaluate(this.valueFormula(this.padding.vertical)); }
    set PaddingVertical(fNewValue) { this.padding.vertical = padding.vertical; this.build(); }

    get IsDisabled() { return this.isDisabled; }
    set IsDisabled(bNewValue) { this.isDisabled = bNewValue; this.build(); }

    get ButtonWidth() { return this.size.width; }
    get ButtonHeight() { return this.size.height; }
    get BackgroundColor() { return string2hex(this.UI.Values.textbutton.background.color); }
    get StrokeWidth() { return this.evaluate(this.valueFormula(this.UI.Values.textbutton.stroke.size), 0, 0, 0, 0, this); }
    get StrokeColor() { return string2hex("color" in this.UI.Values.textbutton.stroke ? this.UI.Values.textbutton.stroke.color : "#000000"); }
    get CornerRadius() { return this.evaluate(this.valueFormula(this.UI.Values.textbutton.corner.radius), 0, 0, 0, 0, this); }
    get DisabledOpacity() { return this.UI.Values.textbutton.disabled.opacity; }

    get TextFont() { return this.UI.Values.textbutton.text.font; }
    get TextWeight() { return this.UI.Values.textbutton.text.weight; }
    get TextColor() { return string2hex(this.UI.Values.textbutton.text.color); }

    /*******************************************
    *   INITIALIZATION
    *******************************************/
    /**
        Parameters to pass to the init function:
        - ui:       UI section object where this component resides
        - text:     Text to display inside the button
        - disabled  (Optional) Disabled state of the button. Default is FALSE
        - fontSize  (Optional) Override of the size of the font inside the button. Default is NULL
        - padding   (Optional) Object containing either the keys "horizontal" and/or "vertical". Default is NULL
    */
    init(meta)
    {
        this.ui = meta.ui;
        this.text = meta.text;
        this.fontSize = ("fontSize" in meta ? meta.fontSize : meta.ui.Values.textbutton.text.size);
        if ("padding" in meta && meta.padding !== null)
        {
            this.padding = {
                "horizontal": ("horizontal" in meta.padding ? meta.padding.horizontal : this.UI.Values.textbutton.padding.horizontal),
                "vertical": ("vertical" in meta.padding ? meta.padding.vertical : this.UI.Values.textbutton.padding.vertical)
            };
        }
        else
        {
            this.padding = {
                "horizontal": this.UI.Values.textbutton.padding.horizontal, 
                "vertical": this.UI.Values.textbutton.padding.vertical
            };
        }

        this.buttonScale = {"x": meta.ui.Values.textbutton.scale.normal, "y": meta.ui.Values.textbutton.scale.normal};
        this.isDisabled = meta.disabled;

        return super.init(meta);
    }

    createClosure()
    {
        super.createClosure();

        this.fctOnClick = this.onClick.bind(this);
        this.fctOnPointerDown = this.onPointerDown.bind(this);
        this.fctOnWindowMouseUp = this.onWindowMouseUp.bind(this);
        this.fctOnWindowTouchEnd = this.onWindowTouchEnd.bind(this);
    }

    bindEvents()
    {
        super.bindEvents();
        
        window.addEventListener("mouseup", this.fctOnWindowMouseUp);
        window.addEventListener("touchend", this.fctOnWindowTouchEnd);
    }

    destroy(options)
    {
        window.removeEventListener("mouseup", this.fctOnWindowMouseUp);
        window.removeEventListener("touchend", this.fctOnWindowTouchEnd);

        super.destroy(options);
    }

    /*******************************************
    *   BUILD
    *******************************************/
    build ()
    {
        if (!this.graphics)
        {
            this.graphics = new GraphicsResponsive().init({"ui": this.UI});
            this.graphics.interactive = true;
            this.graphics.buttonMode = !this.IsDisabled;
            this.graphics.on("pointerup", this.fctOnClick);
            this.graphics.on("pointerdown", this.fctOnPointerDown);

            this.addChild(this.graphics);
        }

        this.size = this.getButtonSize();
        let width = this.size.width;
        let height = this.size.height;

        this.graphics.clear();
        this.graphics.scale.set(this.buttonScale.x, this.buttonScale.y);
        this.graphics.alpha = (this.IsDisabled ? this.DisabledOpacity : 1);

        let x = width * (1 - this.buttonScale.x) / 2;
        let y = height * (1 - this.buttonScale.y) / 2;

        if (this.StrokeWidth > 0)
        {
            this.graphics.lineStyle({"color":this.StrokeColor, "width":this.StrokeWidth, "alignment":1});
        }
        this.graphics.beginFill(this.BackgroundColor, 1);

        if (this.CornerRadius > 0)
        {
            this.graphics.drawRoundedRect(x, y, width, height, this.CornerRadius);
        }
        else
        {
            this.graphics.drawRect(x, y, width, height, this.CornerRadius);
        }

        this.buildText(x, y, width, height);
    }

    clean()
    {
        super.clean();
        if (this.graphics)
        {
            this.graphics.clear();
        }
    }

    buildText(fBoxX, fBoxY, fBoxWidth, fBoxHeight)
    {
        if (!this.textGraphic)
        {
            this.textGraphic = new TextResponsive().init({"ui": this.UI});
            this.addChild(this.textGraphic);
        }

        this.textGraphic.alpha = (this.IsDisabled ? this.DisabledOpacity : 1);

        let style = new TextStyle({
            fontFamily: this.TextFont,
            fontSize:   this.FontSize,
            fontWeight: this.TextWeight,
            fill: this.TextColor,
            align: "center"
        });

        let metrics = TextMetrics.measureText(this.Text, style);

        let x = fBoxX + fBoxWidth / 2 - metrics.width * (1 - this.buttonScale.x);
        let y = fBoxY + fBoxHeight / 2 - metrics.height * (1 - this.buttonScale.y);

        this.textGraphic.text = this.Text;
        this.textGraphic.style = style;
        this.textGraphic.width = metrics.width;
        this.textGraphic.height = metrics.height;
        this.textGraphic.anchor.set(0.5);
        this.textGraphic.scale.set(this.buttonScale.x, this.buttonScale.y);
        this.textGraphic.position.set(x, y);
    }

    getButtonSize(fMinWidth = -1)
    {
        let style = new TextStyle({
            fontFamily: this.TextFont,
            fontSize:   this.FontSize,
            fontWeight: this.TextWeight,
            fill: this.TextColor,
            align: "center"
        });

        let metrics = TextMetrics.measureText(this.Text, style);

        let size = {"width": this.PaddingHorizontal * 2 + metrics.width, "height": this.PaddingVertical * 2 + metrics.height};

        if (size.width < fMinWidth)
            size.width = fMinWidth;

        return size;
    }

    //---------------------------------------------------
    //  EVENTS
    //---------------------------------------------------
    onClick()
    {
        if (!this.IsDisabled)
        {
            this.buttonScale.x = this.UI.Values.textbutton.scale.normal;
            this.buttonScale.y = this.UI.Values.textbutton.scale.normal;
            this.build();

            this.emit(this.EVENT_CLICK, this);
        }
    }

    onPointerDown()
    {
        if (!this.IsDisabled)
        {
            this.buttonScale.x = this.UI.Values.textbutton.scale.down;
            this.buttonScale.y = this.UI.Values.textbutton.scale.down;
            this.build();
        }
    }

    onWindowMouseUp(e)
    {
        this.buttonScale.x = this.UI.Values.textbutton.scale.normal;
        this.buttonScale.y = this.UI.Values.textbutton.scale.normal;
        this.build();
    }

    onWindowTouchEnd(e)
    {
        if (e.touches.length == 0)
        {
            this.buttonScale.x = this.UI.Values.textbutton.scale.normal;
            this.buttonScale.y = this.UI.Values.textbutton.scale.normal;
            this.build();
        }
    }
}