import { string2hex }  from '@pixi/utils'; 
import { TextStyle } from '@pixi/text';
import { TextMetrics } from '@pixi/text';
import CloseButton from "../../commons/buttons/CloseButton.js";
import ContainerResponsive from "../../commons/responsive/ContainerResponsive.js";
import Display from "../../../utils/Display.js";
import GraphicsResponsive from "../../commons/responsive/GraphicsResponsive.js";
import TextResponsive from "../../commons/responsive/TextResponsive.js";

export default class MessageNotificationPopup extends ContainerResponsive
{
    constructor()
    {
        super();
    }

    get EVENT_CLOSE() { return "message-notification-window-close"; }
    get EVENT_CLICK() { return "message-notification-click"; }

    get TitleLabel() { return this.UI.LabelManager.translate("OBJ_TITRE"); }

    get Message() { return this.message; }
    set Message(strNewValue) { this.message = strNewValue; this.build(); }

    get ShowCloseButton() { return this.showClose; }
    set ShowCloseButton(bNewValue) { this.showClose = bNewValue; this.build(); }

    get ShowNewLayout() { return this.newLayout; }
    set ShowNewLayout(bNewValue) { this.newLayout = bNewValue; this.build(); }

    get Alpha() { return this.alpha; }
    set Alpha(fNewValue) { this.alpha = fNewValue; this.updateAlpha(); }

    get BgWidth() { return this.buildWidth; }
    set BgWidth(strNewValue) { this.buildWidth = strNewValue; this.build(); }

    get YPivot() { return this.yPivot; }
    set YPivot(fNewValue) { this.yPivot = fNewValue; this.build(); }

    get BgHeight() { return this.calculateHeight(false); }
    get TextPadding() { return "(ih * 2.5%)"; }
    get TitlePadding() { return "(ih * 1%)"; }

    get BackgroundColor() { return string2hex(this.UI.Values.modal.background.color);}
    get StrokeWidth() { return this.evaluate(this.valueFormula(this.UI.Values.modal.stroke.size), 0, 0, 0, 0);}
    get NewLayoutStrokeWidth() { return this.evaluate(this.valueFormula(this.UI.Values.notification.objective.stroke.size), 0, 0, 0, 0);}
    get StrokeColor() { return string2hex("color" in this.UI.Values.modal.stroke ? this.UI.Values.modal.stroke.color : "#000000");}
    get NewLayoutStrokeColor() { return string2hex("color" in this.UI.Values.notification.objective.stroke ? this.UI.Values.notification.objective.stroke.color : "#000000");}
    get CornerRadius() { return this.evaluate(this.valueFormula(this.UI.Values.modal.corner.radius), 0, 0, 0, 0);}

    get TextStyle() 
    {
        let fontSize = this.evaluate(this.valueFormula(this.UI.Values.general.text.size * 0.9));
        fontSize = Math.max(fontSize, this.UI.ResponsiveManager.TextMinSize);

        return new TextStyle({
            fontFamily: this.UI.Values.general.text.font,
            fontSize,
            fontWeight: this.UI.Values.general.text.weight,
            fill: this.UI.Values.general.text.color,
            align: "center",
            wordWrap: true,
            wordWrapWidth: this.evaluate(this.BgWidth + " - " + this.TextPadding + " * 2", 0, 0, 0, 0, this)
        });
    }

    get TitleStyle() 
    {
        let fontSize = this.evaluate(this.valueFormula(this.UI.Values.notification.objective.title.size));
        fontSize = Math.max(fontSize, this.UI.ResponsiveManager.TitleMinFontSize);

        return new TextStyle({
            fontFamily: this.UI.Values.general.title.font,
            fontSize,
            fontWeight: this.UI.Values.general.title.weight,
            fill: (this.ShowNewLayout ? this.UI.Values.notification.objective.title.color : this.UI.Values.general.text.color),
            align: "center"
        });
    }

    /*******************************************
    *   INITIALIZATION
    *******************************************/
    /**
        Parameters to pass to the init function:
        - ui:           UI section object where this component resides
        - message:      Message string to display in the notification
        - rWidth:       Reponsive equation for the width of this notification
        - rHeight:      (Optional) Reponsive equation for the height of this notification. Default is ""
        - showClose:    (Optional) If the close button should be shown. Default is TRUE
        - newLayout:    (Optional) If the new layout should be drawn when building this notification. Default is FALSE
    */
    init(meta)
    {
        this.message = meta.message;
        this.showClose = ("showClose" in meta ? meta.showClose : true);
        this.newLayout = ("newLayout" in meta ? meta.newLayout : false);

        this.buildWidth = meta.rWidth;
        this.buildHeight = ("rHeight" in meta ? meta.rHeight : "");

        this.alpha = 1;
        this.yPivot = 1;

        this.graphics = {};
        this.texts = {};

        return super.init(meta);
    }

    createClosure ()
    {
        super.createClosure();
        this.fctOnCloseClick = this.onCloseClick.bind(this);
        this.fctOnClick = this.onClick.bind(this);
    }

    destroy(options)
    {
        if (this.closeButton)
        {
            this.closeButton.off(this.closeButton.EVENT_CLICK, this.fctOnCloseClick);
            this.closeButton.destroy(options);
            delete this.closeButton;
        }

        this.graphics.bg.off("pointerup", this.fctOnClick);
        for (let key in this.graphics)
        {
            this.removeChild(this.graphics[key]);
            this.graphics[key].destroy(options);
        }
        delete this.graphics;

        for (let key in this.texts)
        {
            this.removeChild(this.texts[key]);
            this.texts[key].destroy(options);
        }
        delete this.texts;

        super.destroy(options);
    }

    /*******************************************
    *   BUILD
    *******************************************/
    build()
    {
        super.build();

        this.buildBackground();
        this.buildText();
        this.buildCloseButton();

        if (this.ShowNewLayout)
        {
            this.buildTitle();
        }
    }

    clean()
    {
        for (let key in this.graphics)
        {
            this.graphics[key].clear();
        }
        super.clean();
    }

    buildBackground()
    {
        if (!this.graphics.bg)
        {
            this.graphics.bg = new GraphicsResponsive().init({"ui": this.UI});
            this.graphics.bg.interactive = true;
            this.graphics.bg.buttonMode = true;
            this.graphics.bg.on("pointerup", this.fctOnClick)//@

            this.addChild(this.graphics.bg);
        }

        let bgColor = this.BackgroundColor;
        let strokeWidth = this.StrokeWidth;
        let strokeColor = this.StrokeColor;
        if (this.ShowNewLayout)
        {
            strokeWidth = this.NewLayoutStrokeWidth;
            strokeColor = this.NewLayoutStrokeColor;
        }
        let cornerRadius = this.CornerRadius;

        let width = this.evaluate(this.BgWidth, 0, 0, 0, 0, this);
        let height = this.evaluate(this.BgHeight, 0, 0, 0, 0, this);

        let x = 0;
        let y = (this.YPivot > 0 ? -this.evaluate(this.BgHeight, 0, 0, 0, 0, this) * this.YPivot : 0);

        if (strokeWidth > 0)
        {
            this.graphics.bg.lineStyle({"color":strokeColor, "width":strokeWidth, "alignment":1});
        }

        this.graphics.bg.beginFill(this.BackgroundColor, 1);
        if (cornerRadius > 0)
        {
            this.graphics.bg.drawRoundedRect(x, y, width, height, cornerRadius);
        }
        else
        {
            this.graphics.bg.drawRect(x, y, width, height);
        }
        this.graphics.bg.endFill();
    }

    getText ()
    {
        return this.texts.desc.text;
    }

    buildText()
    {
        if (!this.texts.desc)
        {
            this.texts.desc = new TextResponsive().init({"ui": this.UI});
            this.addChild(this.texts.desc);
        }

        let label = this.Message;
        let style = this.TextStyle;

        let metrics = TextMetrics.measureText(label, style);

        let y = (this.YPivot > 0 ? "-" + this.BgHeight + " * " + this.YPivot : 0);
        if (this.ShowNewLayout)
        {
            let titleMetrics = TextMetrics.measureText(this.TitleLabel, this.TitleStyle);
            y += " + ((" + titleMetrics.height + " + " + this.TitlePadding + " * 2) / 4)";
        }

        this.texts.desc.text = label;
        this.texts.desc.style = style;
        this.texts.desc.width = metrics.width;
        this.texts.desc.height = metrics.height;
        this.texts.desc.rX = [{on:"default", x: this.BgWidth + " / 2 - " + metrics.width + " / 2"}];
        this.texts.desc.rY = [{on:"default", y: y + " + " + this.TextPadding}];

        this.texts.desc.layout(Display.getSize());
    }

    buildCloseButton()
    {
        if (!this.closeButton && this.ShowCloseButton)
        {
            this.closeButton = new CloseButton().init({"ui": this.UI, "scale": 0.8});
            this.closeButton.on(this.closeButton.EVENT_CLICK, this.fctOnCloseClick);//@

            this.addChild(this.closeButton);
        }
        else if (this.closeButton && !this.ShowCloseButton)
        {
            this.removeChild(this.closeButton);
            this.closeButton.destroy({"children": true});
            delete this.closeButton;
        }

        if (this.closeButton)
        {
            let y = (this.YPivot > 0 ? "-" + this.BgHeight + " * " + this.YPivot : 0);

            this.closeButton.rX = [{on:"default", x: (this.BgWidth + " - " + this.closeButton.ButtonWidth + " / 3")}]
            this.closeButton.rY = [{on:"default", y: y + " + (" + this.BgHeight +  ")/2 - " + (this.closeButton.ButtonHeight + " / 2")}];

            this.closeButton.layout(Display.getSize());
        }
    }

    buildTitle()
    {
        if (!this.graphics.titleBg)
        {
            this.graphics.titleBg = new GraphicsResponsive().init({"ui": this.UI});
            this.addChild(this.graphics.titleBg);
        }

        if (!this.texts.title)
        {
            this.texts.title = new TextResponsive().init({"ui": this.UI});
            this.addChild(this.texts.title);
        }

        let text = this.texts.title;
        let label = this.TitleLabel;
        let style = this.TitleStyle;

        let metrics = TextMetrics.measureText(label, style);

        let padding = this.evaluate(this.TitlePadding, 0, 0, 0, 0, this);
        let width = metrics.width + padding * 2;
        let height = metrics.height + padding * 2;

        let graphics = this.graphics.titleBg;

        let bgColor = this.StrokeColor;
        let cornerRadius = this.CornerRadius;

        graphics.beginFill(bgColor);
        if (cornerRadius > 0)
        {
            graphics.drawRoundedRect(0, 0, width, height, cornerRadius);
        }
        else
        {
            graphics.drawRect(0, 0, width, height);
        }
        graphics.endFill();

        let bgX = this.BgWidth + " / 2 - " + width + " / 2";
        let bgY = "-" + this.BgHeight + " - " + height + " / 2";

        let size = Display.getSize();

        graphics.rX = [{on:"default", x: bgX}];
        graphics.rY = [{on:"default", y: bgY}];

        text.text = label;
        text.style = style;
        text.width = metrics.width;
        text.height = metrics.height;

        text.rX = [{on:"default", x: bgX + " + " + width + " / 2 - " + metrics.width + " / 2"}];
        text.rY = [{on:"default", y: bgY + " + " + height + " / 2 - " + metrics.height + " / 2"}];

        graphics.layout(size);
        text.layout(size);
    }

    updateAlpha()
    {
        for(let key in this.graphics)
        {
            this.graphics[key].alpha = this.Alpha;
        }

        for(let key in this.sprites)
        {
            this.sprites[key].alpha = this.Alpha;
        }

        for(let key in this.texts)
        {
            this.texts[key].alpha = this.Alpha;
        }
    }

    /*******************************************
    *   CALCULATIONS
    *******************************************/
    getSize()
    {
        return {
            "width": this.evaluate(this.BgWidth, 0, 0, 0, 0, this),
            "height": this.evaluate(this.calculateHeight(), 0, 0, 0, 0, this)
        };
    }

    calculateHeight(bCalculateTitle = true)
    {
        if (this.buildHeight !== "")
        {
            return "(" + this.buildHeight + ")";
        }

        let label = this.Message;
        let style = this.TextStyle;

        let metrics = TextMetrics.measureText(label, style);
        let titleHeight = 0;
        if (this.ShowNewLayout && bCalculateTitle)
        {
            let titleMetrics = TextMetrics.measureText(this.TitleLabel, this.TitleStyle);
            titleHeight = "((" + titleMetrics.height + " + " + this.TitlePadding + " * 2) / 2)";
        }
        else if (this.ShowNewLayout)
        {
            titleHeight = "(" + this.TitlePadding + ")";
        }

        return "(" + metrics.height + " + " + this.TextPadding + " * 2 + " + titleHeight + ")";
    }

    /*******************************************
    *   EVENTS
    *******************************************/
    onCloseClick()
    {
        setTimeout(function()
        {
            this.emit(this.EVENT_CLOSE, this);
        }
        .bind(this), this.UI.Notifications.NOTIFICATION_LENGTH_IN_SECS);
    }

    onClick()
    {
        this.emit(this.EVENT_CLICK, this);
    }
}