import { TextStyle } from "@pixi/text";
import { TextMetrics } from "@pixi/text";
import Display from "../../utils/Display.js";
import MenuUI from "../MenuUI.js";
import ProgressBar from "./components/ProgressBar.js";
import TextResponsive from "../commons/responsive/TextResponsive.js";

export default class LoadingUI extends MenuUI
{
    constructor()
    {
        super();
    }

    get Id() { return "loading"; }

    get LoadingLabel() { return "Chargement"; }
    get LoadingFontSize() { return this.evaluate(this.valueFormula(32), 0, 0, 0, 0, this); }

    get LoadingBarX () { return "(iw / 2) - " + this.LoadingBarWidth + " / 2"; }
    get LoadingBarY () { return "(ih * 76%)"; }
    get LoadingBarWidth () { return "(iw * 30%)"; }
    get LoadingBarHeight () { return "(ih * 2%)"; }

    get LoadingBarStrokeWidth() { return this.evaluate(this.valueFormula(5), 0, 0, 0, 0, this); }
    get LoadingBarCornerRadius() { return this.evaluate(this.valueFormula(3), 0, 0, 0, 0, this); }
    

    /*******************************************
    *   INITIALIZATION
    *******************************************/
    /**
        Parameters to pass to the init function:
        - dependencies:     Dependency injector object
    */
    init(meta)
    {
        this.pbActive = null;
        super.init(meta);
    }

    /*******************************************
    *   BUILD
    *******************************************/
    build ()
    {
        super.build();

        this.buildBackground();
        this.buildLoadingText();
    }

    buildLoadingText()
    {
        if (!this.loadingText)
        {
            this.loadingText = new TextResponsive().init({"ui": this});
            this.addChild(this.loadingText);
        }

        this.loadingText.visible = this.fontLoaded;

        let text = this.loadingText;
        let label = this.LoadingLabel;

        let style = new TextStyle({
            fontFamily: "'Barlow Condensed'",
            fontSize: Math.max(this.LoadingFontSize, 20),
            fill: 0xFFFFFF,
            align: "center"
        });

        let metrics = TextMetrics.measureText(label, style);

        text.text = label;
        text.style = style;
        text.width = metrics.width;
        text.height = metrics.height;
        text.position.set(
            this.evaluate(this.LoadingBarX + " + " + this.LoadingBarWidth + " / 2 - " + metrics.width + " / 2", 0, 0, 0, 0),
            this.evaluate(this.LoadingBarY + " - " + metrics.height + " * 1.66", 0, 0, 0, 0)
        );
    }

    /*******************************************
    *   PRELOADING
    *******************************************/
    showPreloading ()
    {
        this.pbPreloading = new ProgressBar().init({"ui": this});
        this.addChild (this.pbPreloading);

        this.pbActive = this.pbPreloading;
    }

    /*******************************************
    *   LOADING SCREEN
    *******************************************/
    showLoading ()
    {
        this.removeChild (this.pbPreloading);

        if (!this.pbLoading)
        {
            this.pbLoading = new ProgressBar().init({
                "ui": this,
                "rWidth": this.LoadingBarWidth,
                "rHeight": this.LoadingBarHeight
            });
            this.addChild(this.pbLoading);
        }

        this.pbLoading.rX =  [{on: "default", x: this.LoadingBarX}];
        this.pbLoading.rY =  [{on: "default", y: this.LoadingBarY}];

        this.pbLoading.layout(Display.getSize());

        this.pbActive = this.pbLoading;
    }

    /*******************************************
    *   EVENTS
    *******************************************/
    onAssetLoaded (index, total, pct, strGroup, strKey)
    {
        if (this.pbActive)
        {
            if (!this.fontLoaded && document.fonts.check("12px 'Barlow Condensed'"))
            {
                this.fontLoaded = true;
                this.buildLoadingText();
            }
            this.buildLoadingText();
            this.pbActive.progress(Math.min(1, pct));
        }
    }

    onGroupLoaded (index, total, pct, strType)
    {

    }
}
