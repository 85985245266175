import ItemCodes from "./codes/ItemCodes.js";

export default class MutationPower
{
    constructor(strId, strCode, objDefinition)
    {
        this.id = strId;
        this.code = strCode;
        this.definition = objDefinition;
    }

    get Id() { return this.id; }

    get IsActive() { return ("active" in this.definition ? this.definition.active : false); }
    get Code() { return this.code; }

    get CanInvisible() { return this.definition.id == ItemCodes.POWER_INVISIBLE; }
    get CanJump() { return this.definition.id == ItemCodes.POWER_JUMP; }
    get CanPush() { return this.definition.id == ItemCodes.POWER_PUSH; }
    get CanSpit() { return this.definition.id == ItemCodes.POWER_SPIT; }
    get CanStomp() { return this.definition.id == ItemCodes.POWER_STOMP; }
    get CanSwim() { return this.definition.id == ItemCodes.POWER_SWIM; }
    get CanTeleport() { return this.definition.id == ItemCodes.POWER_JUMP; } //we have altered the jump power for a teleport power late in the game

    get ActiveDuration() { return ("duration" in this.definition ? this.definition.duration : 0); }
    get ActiveCooldown() { return ("cooldown" in this.definition ? this.definition.cooldown : 0); }
}