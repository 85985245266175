import MapItem from "./MapItem.js";
import ItemManager from "./ItemManager.js";
import ItemCodes from "./codes/ItemCodes.js";

export default class FoodItem extends MapItem
{
    constructor(strId, objDefinition)
    {
        super(strId, objDefinition);

        this.foodDefinition = objDefinition.food;
        this.type = ItemCodes.TYPE_FOOD;
    }

    get BGColor() { return ItemManager.instance.GameManager.getSetting("character").backpack.itemColor.food; }

    get IsFood() { return true; }
    get FoodLevel() { return ("level" in this.definition ? this.definition.level : 1); }
    get HungerBonus() { return this.foodDefinition.bonus * this.HungerRatio; }
    get HungerRatio () { return ItemManager.instance.WorldManager.getCookingRatio(this.FoodLevel); }
    get EnergyBonus() { return ("energy" in this.foodDefinition ? this.foodDefinition.energy : 0); }
    get IsRaw() { return ("raw" in this.foodDefinition ? this.foodDefinition.raw : false); }
    get IsEatable() { return ("eat" in this.foodDefinition ? this.foodDefinition.eat : false); }
    get IsToxic() { return "toxic" in this.foodDefinition; }
    get ToxicValue() { return (this.IsToxic ? this.foodDefinition.toxic : 0); }
    get IsCookable() { return "next" in this.foodDefinition && this.foodDefinition.next; }

    get IsCraftable() { return "craft" in this.foodDefinition; }
    get CraftRequirements() { if (this.craftReq === -1) { this.calculateCraftRequirements(this.foodDefinition.craft); } return this.craftReq;}
    get CraftResultAmount() { return (this.IsCraftable ? this.foodDefinition.craft.qty : 1); }
    get CraftLevel() { return (this.IsCraftable ? this.definition.level : 1); }

    getCookingItem ()
    {
        let item = null;

        if (this.IsCookable)
        {
            item = ItemManager.instance.getItem(this.foodDefinition.next);
        }

        return item;
    }

    /**
        A character eats this food item to feed itself

        @param iSlotIndex   Slot index where the item resides in the inventory
        @param bInBackpack  If the inventory it should pick this food from is the current character's backpack or the general storage. Default is backpack
        @param iCharacter   (Optional) It specifies which character should be targeted. If less than 0 then the current character is used. If bInBackpack is TRUE then
                                the character's inventory will also be used to fetch the item to eat.

        @return             An object describing the result of the operation :
                                "status": boolean describing if the opration was a success or not
                                "inventory": the new state of the inventory. If bInBackpack is TRUE then the inventory is backpack otherwise its storage
                                "args": List of values describing why the operation is as is it:
                                    - wrongItemOnSlot: The item on the given slot is not the same as this item
                                    - quantityTooLow: Quantity of the item on the specified slot is too low
    */
    eat (iSlotIndex, bInBackpack, iCharacter = -1)
    {
        let result = {"status": true, "inventory": {}, "args": {}};
        let itemDef = ItemManager.instance.getItemAtSlot(iSlotIndex, bInBackpack, iCharacter);
        if (itemDef.item)
        {
            if (itemDef.item.Id == this.Id)
            {
                ItemManager.instance.removeItemFromSlot(1, iSlotIndex, bInBackpack, iCharacter);
                result.inventory = ItemManager.instance.getAllItems(bInBackpack, iCharacter);
                result.hunger = {from: ItemManager.instance.CharacterManager.getCharacterHunger(iCharacter), to: ItemManager.instance.CharacterManager.getCharacterHunger(iCharacter)};
                result.energy = {from: ItemManager.instance.CharacterManager.getCharacterEnergy(iCharacter), to: ItemManager.instance.CharacterManager.getCharacterEnergy(iCharacter)};
                result.affected = [];

                if (this.HungerBonus > 0)
                {
                    ItemManager.instance.CharacterManager.setCharacterHunger(ItemManager.instance.CharacterManager.getCharacterHunger(iCharacter) + this.HungerBonus, iCharacter)
                    result.hunger.to += this.HungerBonus;
                    result.affected.push("hunger");
                }
                //I don't think it possible to affect have a boost in energy and a toxic effect at the same time
                if (this.EnergyBonus > 0)
                {
                    ItemManager.instance.CharacterManager.setCharacterEnergy(ItemManager.instance.CharacterManager.getCharacterEnergy(iCharacter) + this.EnergyBonus, iCharacter)
                    result.energy.to += this.EnergyBonus;
                    result.affected.push("energy");
                }
                else if (this.ToxicValue > 0)
                {
                    let characterEnergy = ItemManager.instance.CharacterManager.getCharacterEnergy(iCharacter);
                    let newEnergy = characterEnergy - this.ToxicValue;
                    if (ItemManager.instance.WorldManager.IsBarn && newEnergy <= 0)
                    {
                        newEnergy = 1;
                    }
                    ItemManager.instance.CharacterManager.setCharacterEnergy(newEnergy, iCharacter);
                    result.energy.to -= characterEnergy - newEnergy;
                    result.affected.push("energy");
                }

                ItemManager.instance.emit(ItemManager.instance.EVENT_ITEM_USED, itemDef.item.Id);
            }
            else
            {
                result.status = false;
                result.args.wrongItemOnSlot = true;
            }
        }
        else
        {
            result.status = false;
            result.args.quantityTooLow = true;
        }

        return result;
    }
}