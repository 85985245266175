import {Mesh} from "three";
import {MeshBasicMaterial} from "three";
import {PlaneGeometry} from "three";
import {Texture} from "three";
import {Vector2} from "three";
import Constants from "../../utils/Constants.js";

export default class OutdoorMapFloor
{
    constructor()
    {
        
    }

    get Y_OFFSET() { return -0.0001; }
    get LOAD_OFFSET() { return Constants.getValue("MAP_FLOOR_LOAD_OFFSET"); }
    get FLOOR_TILE_GRID_SIZE() { return Constants.getValue("MAP_FLOOR_TILE_SIZE"); }
    get QUEUE_PER_FRAME() { return 3; }
    get TEXTURE_BASE_URL() { return Constants.getValue("MAP_FLOOR_URL"); }

    //---------------------------------------------------------
    //  DEPENDENCIES
    //---------------------------------------------------------
    get WorldManager() { return this.Dependencies.get("WorldManager");}
    //---------------------------------------------------------

    get Dependencies() { return this.dependencies; }
    get Environment() { return this.environment; }
    get Grid() { return this.environment.Grid; }
    get Scene() { return this.Environment.Scene; }

    /*******************************************
    *   INITIALIZATION
    *******************************************/
    init(environment, dependencies)
    {
        this.dependencies = dependencies;
        this.environment = environment;
        this.tilesOnScreen = {};
        this.loadedTextures = {};
        this.inProcess = {};
        this.loadQueue = [];

        this.createClosure();
        this.bindEvents();
        this.createBaseGeometry();

        return this;
    }

    createClosure()
    {

    }

    bindEvents()
    {

    }

    destroy()
    {   
        let keys = Object.keys(this.tilesOnScreen);
        for (let i = 0; i < keys.length; i++)
        {
            this.unloadTile(keys[i]);
        }
        delete this.tilesOnScreen;

        keys = Object.keys(this.loadedTextures);
        for (let i = 0; i < keys.length; i++)
        {
            this.unloadTexture(keys[i]);
        }
        delete this.loadedTextures;
    }

    createBaseGeometry()
    {
        let tileSize = this.FLOOR_TILE_GRID_SIZE;
        let gridSize =this.Grid.CellSize;

        this.geometry = new PlaneGeometry(
            tileSize.width * gridSize, 
            tileSize.height * gridSize, 
            10, 
            10
        );
    }

    /*******************************************
    *   DISPLAY MANAGEMENT
    *******************************************/
    updateDisplay(iX, iY)
    {
        let tileSize = this.FLOOR_TILE_GRID_SIZE;
        let rect = this.calculateLoadRect(iX, iY);
        let okList = [];

        for (let x = rect.min.x; x <= rect.max.x; x++)
        {
            for (let y = rect.min.y; y <= rect.max.y; y++)
            {
                let gridX = Math.floor(x / tileSize.width);
                let gridY = Math.floor(y / tileSize.height);

                let id = this.Grid.getTileId(gridX, gridY);
                if (!okList.includes(id))
                {
                    okList.push(id);

                    if (!this.inProcess[id])
                    {
                        if (!this.loadedTextures[id])
                        {
                            this.loadTexture(gridX, gridY);
                        }
                        else if (!this.tilesOnScreen[id])
                        {
                            this.createTile(gridX, gridY);
                        }
                    }
                }
            }
        }

        let keys = Object.keys(this.loadedTextures);
        for (let i = 0; i < keys.length; i++)
        {
            if (!okList.includes(parseInt(keys[i])) && !this.tilesOnScreen[keys[i]])
            {
                this.unloadTexture(keys[i]);
            }
        }

        keys = Object.keys(this.tilesOnScreen);
        for (let i = 0; i < keys.length; i++)
        {
            if (!okList.includes(parseInt(keys[i])))
            {
                this.unloadTile(keys[i]);
            }
        }
    }

    calculateLoadRect(iX, iY)
    {
        let offset = this.LOAD_OFFSET;
        return {
            "min": {
                "x": Math.max(0, iX - offset),
                "y": Math.max(0, iY - offset)
            },
            "max": {
                "x": Math.min(this.Grid.Width, iX + offset),
                "y": Math.min(this.Grid.Height, iY + offset)
            }
        };
    }

    /*******************************************
    *   TILE MANAGEMENT
    *******************************************/
    loadTexture(iX, iY)
    {
        let id = this.Grid.getTileId(iX, iY);
        this.WorldManager.updateExploration(id);
        this.addToLoadQueue(id);
    }

    createTile(iX, iY)
    {
        let id = this.Grid.getTileId(iX, iY);

        if (this.loadedTextures[id])
        {
            let material =  new MeshBasicMaterial({
                wireframe: false,
                opacity:1,
                transparent: false,
                map: this.loadedTextures[id]
            });

            let tile = new Mesh(this.geometry, material);
            tile.rotation.x = -Math.PI / 2;

            let tileGridSize = this.FLOOR_TILE_GRID_SIZE;
            let worldPos = this.Grid.gridToWorldPosition(
                iX * tileGridSize.width,
                iY * tileGridSize.height
            );

            let tileSize = this.FLOOR_TILE_GRID_SIZE;
            let gridSize = this.Grid.CellSize;

            tile.position.x = worldPos.x + (tileSize.width * gridSize) / 2 - gridSize / 2;
            tile.position.y = this.Y_OFFSET;
            tile.position.z = worldPos.y + (tileSize.height * gridSize) / 2 - gridSize / 2;

            this.Scene.add(tile);

            this.tilesOnScreen[id] = tile;
        }
    }

    unloadTexture(id)
    {
        if (this.loadedTextures[id])
        {
            this.loadedTextures[id].dispose();
            delete this.loadedTextures[id];
        }
    }

    unloadTile(id)
    {
        if (this.tilesOnScreen[id])
        {
            let tile = this.tilesOnScreen[id];
            this.Scene.remove(tile);

            tile.material.dispose();
            this.unloadTexture(id);

            delete this.tilesOnScreen[id];
        }
    }

    getTileTextureUrl(strId)
    {
        let pos = this.Grid.getPosFromTileId(strId);
        let url = this.TEXTURE_BASE_URL;

        return url.replace("{0}", pos.x)
                  .replace("{1}", pos.y);
    }

    /*******************************************
    *   LOAD QUEUE
    *******************************************/
    addToLoadQueue(strId)
    {
        let restartQueue = this.loadQueue.length == 0;
        this.loadQueue.push(strId);

        if (restartQueue)
        {
            this.updateLoadQueue();
        }
    }

    updateLoadQueue()
    {
        let loopCount = this.QUEUE_PER_FRAME;

        for (let i = 0; i < loopCount && this.loadQueue.length > 0; i++)
        {
            let id = this.loadQueue.shift();
            let url = this.getTileTextureUrl(id) + "?v=20210709_1830";
            let img = new Image();
            img.crossOrigin = "anonymous";

            this.inProcess[id] = img;

            img.onload = this.onFloorLoadComplete.bind(this, id, img);
            img.onerror = this.onFloorLoadError.bind(this, id, img);
            img.src = url;
        }

        if (this.loadQueue.length > 0)
        {
            window.requestAnimationFrame(this.updateLoadQueue.bind(this));
        }
    }

    /*******************************************
    *   EVENTS
    *******************************************/
    onFloorLoadComplete(strId, objImg)
    {
        let texture = new Texture(objImg);
        texture.needsUpdate = true;

        this.loadedTextures[strId] = texture;
        delete this.inProcess[strId];

        let pos = this.Grid.getPosFromTileId(strId);
        this.createTile(pos.x, pos.y);
    }

    onFloorLoadError(strId, objImg)
    {
        delete this.inProcess[strId];
    }
}