import axios from "axios";
import Constants from "./Constants.js"

export default class NameGenerator
{
    constructor()
    {
        NameGenerator._instance = this;
    }

    static get instance() { return (this._instance ? this._instance : new NameGenerator()); }


    async retrievesNames ()
    {
        let strURL = Constants.getValue("RANDOM_NAMES_URL");
        let response = await axios.get(strURL);
        this.arrNames = response.data;
    }


    async getOneName ()
    {
        await this.assignName();
        return this.currentName;
    }

    async assignName ()
    {
        if (this.arrNames === undefined || this.arrNames.length === 0)
        {
            await this.retrievesNames();
        }

        this.currentName = "";

        let strName = this.arrNames.shift();

        let arrNamePart = strName.split(/([\s\-])/);

        for (let i = 0; i < arrNamePart.length &&  this.currentName === ""; i++)
        {
            let strPart = arrNamePart[i];

            if (strPart.length > 2)
            {
                this.currentName = strPart;
            }
        }

        if (this.currentName === "")
            await this.assignName();
    }
}
