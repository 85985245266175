import RoamingAI from "./RoamingAI.js";

export default class ScareAI extends RoamingAI
{
    constructor()
    {
        super();
    }

    get IsTriggered() { return this.isRunningAway; }
    get RunSpeed() { return this.params.speed; }

    get MovementSpeed()
    {
        if (this.IsTriggered)
        {
            return this.RunSpeed / 2;
        } 
        return this.RoamSpeed / 2;
    }

    /*******************************************
    *   INITIALIZATION
    *******************************************/
    /**
        Parameters to pass to the init function:
        - character     Character object this AI should have governance over
        - spawnPos      Vector2 containing the world position where the character is supposed to spawn on the map
        - definition    Behaviour definition from the settings.json file (only the sub part of a definition called "ai")
    */
    init(meta)
    {
        super.init(meta);
        this.isRunningAway = false;
    }

    createClosure()
    {

    }

    bindEvents()
    {

    }

    parseDefinition(objDefinition)
    {
        super.parseDefinition(objDefinition);

        this.params = {
            "distance": new Vector2(20, 20),
            "notify": []
        };

        if ("scare" in objDefinition)
        {
            if ("distance" in objDefinition.scare)
            {
                if ("x" in objDefinition.scare.distance)
                {
                    this.params.distance.x = objDefinition.scare.distance.x;
                }
                if ("y" in objDefinition.scare.distance)
                {
                    this.params.distance.y = objDefinition.scare.distance.y;
                }
            }
            if ("notify" in objDefinition)
            {
                this.params.notify = objDefinition.notify;
            }
        }
    }

    /*******************************************
    *   UPDATE LOOP
    *******************************************/
    update(fDeltaTime)
    {
        if (!this.isRunningAway)
        {
            super.update(fDeltaTime);
        }
    }

    /*******************************************
    *   ACTIONS
    *******************************************/
    runAway(objCharacter, bNotifyOthers = false)
    {
        if (!objCharacter.IsInvisible && !objCharacter.IsDead)
        {
            this.Character.setFOVState(true);
            this.isRunningAway = true;

            this.Character.fovAnimation.current = null;
            this.Character.fovAnimation.next = null;
            this.Character.stopMovement();

            let tile = null;
            let diffX = this.params.distance.x;
            let diffY = this.params.distance.y;

            let currentPos = this.Character.GridPos;
            let playerPos = objCharacter.GridPos;
            
            while (!tile && (diffX > 0 || diffY > 0))
            {
                let x = playerPos.x + diffX * (currentPos.x < playerPos.x ? -1 : 1)
                let y = playerPos.y + diffY * (currentPos.y < playerPos.y ? -1 : 1);

                tile = this.Character.Grid.getNearestWalkableTile(x, y, currentPos.x, currentPos.y, 30, 30, null, false, true);

                diffX = Math.max(0, diffX - 1);
                diffY = Math.max(0, diffY - 1);
            }

            if (bNotifyOthers)
            {
                let enemies = this.Character.Environment.Enemies;
                for (let key in enemies)
                {
                    if (enemies[key] != this.Character && this.params.notify.includes(enemies[key].EnemyId))
                    {
                        enemies[key].onEnemyScareStart(this, objCharacter);
                    }
                }
            }

            let fctCallback = function()
            {
                this.Character.Environment.removeEnemy(this.character);
            }
            .bind(this);

            if (tile)
            {
                this.Character.goTo(
                    tile.x,
                    tile.y,
                    fctCallback
                );
            }
            else
            {
                fctCallback();
            }

            this.Character.emit(this.Character.EVENT_ON_SCARE, this.Character);

            AudioManager.instance.playSfx("surprise", false);
        }
    }

    /*******************************************
    *   EVENTS
    *******************************************/
    onFOVCollision(target)
    {
        if (!this.IsTriggered && !target.IsDead && !target.IsInvincible && !target.IsInvisible && target.Type == "player")
        {
            this.runAway(target);
        }
    }

    onEnemyScareStart(sender, target)
    {
        if (!this.IsTriggered && !target.IsDead && !target.IsInvincible && !target.IsInvisible && target.Type == "player")
        {
            this.runAway(target, false);
        }
    }
}