import {Vector2} from "three";
import {Vector3} from "three";
import CharacterAction from "./CharacterAction.js";
import Direction from "../../../utils/Direction.js";
import UIManager from "../../../ui/UIManager.js";
import TriggerManager from "../../../triggers/TriggerManager.js";

export default class PlayerAction extends CharacterAction
{
    constructor(character)
    {
        super(character);

        this.pickupFcts = {};
    }

    get AnimationController() { return this.Character.AnimationController; }
    get Camera() { return this.Character.Camera; }
    get CharacterAction() { return this.Character.CharacterAction; }
    get Direction() { return this.Character.Direction; }
    get Environment() { return this.Character.Environment; }
    get Grid() { return this.Character.Grid; }
    get GridPos() { return this.Character.GridPos; }
    get IsAntiStress() { return this.Character.IsAntiStress; }
    get IsFishing() { return this.Character.IsFishing; }
    get IsDead() { return this.Character.IsDead; }
    get IsPicking() { return this.Character.isPicking; }
    get IsStun() { return this.Character.IsStun; }
    get IsSwimming() { return this.Character.IsSwimming; }
    get PlayerControls() { return this.Character.PlayerControls; }
    get WorldPos() { return this.Character.WorldPos; }

    /*******************************************
    *   MOVEMENT
    *******************************************/
    /**
        Goes to a position in the ThreeJS environment. The position should be set in ThreeJS world units, not grid units.
    */
    goTo(x, y, arrMeshesToIgnore = null, bSkipDiagonals = false, fctCallback = null)
    {
        let pos = super.goTo(x, y, arrMeshesToIgnore, bSkipDiagonals, fctCallback);
        if (pos)
        {
            this.updateSwimState(this.Character.GridPos);
        }
        return pos;
    }

    reachDestination()
    {
        this.updateSwimState(this.Character.GridPos);
        super.reachDestination();
    }

    updateSwimState(objPos)
    {
        if (this.Character.Environment.Obstacles)
        {
            if (this.movement.toReach)
            {
                objPos = this.Grid.worldToGridPosition(this.movement.toReach.x, this.movement.toReach.y);
            }

            if (this.Character.Environment.Obstacles.getIsTileSwimmable(objPos.x, objPos.y))
            {
                this.Character.startSwimming();
            }
            else
            {
                this.Character.stopSwimming();
            }
        }
    }

    /*******************************************
    *   ACTIONS
    *******************************************/
    pickup(objObstacle)
    {
        if (!this.IsPicking && this.Character.CanMove && !this.IsSwimming)
        {
            this.stopLoopingActions();

            this.Environment.MapMoveIcon.setVisible(false);
            let fctLoot = this.animatePickup.bind(this, objObstacle);

            let pos = this.Character.goTo(
                objObstacle.Mesh.position.x,
                objObstacle.Mesh.position.z,
                false,
                fctLoot
            );

            if (!pos)
            {
                let diff = Math.pow(this.GridPos.x - objObstacle.GridPos.x, 2) + Math.pow(this.GridPos.y - objObstacle.GridPos.y, 2);
                if (diff <= 1)
                {
                    fctLoot();
                }
            }
        }
    }

    animatePickup(objObstacle)
    {

        this.stop();

        let direction = this.Direction;
        let diff = new Vector2(this.WorldPos.x - objObstacle.WorldPos.x, this.WorldPos.z - objObstacle.WorldPos.z);
        if (Math.abs(diff.x) > Math.abs(diff.y))
        {
            direction = (diff.x > 0 ? Direction.West : Direction.East);
        }
        else
        {
            direction = (diff.y > 0 ? Direction.North : Direction.South);
        }
        if (direction != this.Direction)
        {
            this.Character.changeDirection(direction);
        }

        if (objObstacle.meta.item.definition.map.trigger)
        {
            let tm = TriggerManager.instance;
            tm.validators["world"].onOutdoorObstacleClick({x: objObstacle.WorldPos.x, y: objObstacle.WorldPos.z});
            return;
        }

        this.Character.isPicking = true;
        this.PlayerControls.CanMove = false;
        this.pickupFcts[objObstacle.Id] = this.pickupEnd.bind(this, objObstacle, direction);


        this.AnimationController.on(this.AnimationController.EVENT_ANIMATION_END, this.pickupFcts[objObstacle.Id]);
        this.AnimationController.play(this.Character.ANIMATION_USE, direction);
    }

    pickupEnd(objObstacle, iDirection, strAnimId)
    {
        if (strAnimId == this.AnimationController.getAnimationId(this.Character.ANIMATION_USE, iDirection))
        {
            this.AnimationController.off(this.AnimationController.EVENT_ANIMATION_END, this.pickupFcts[objObstacle.Id]);
            delete this.pickupFcts[objObstacle.Id];

            this.CharacterManager.pickObject();
            objObstacle.applyPickup();

            this.AnimationController.play(this.Character.ANIMATION_IDLE, this.Direction, true);
            this.PlayerControls.CanMove = true;
            this.Character.isPicking = false;
        }
    }

    //-----------------------------------------
    //  STOMP
    //-----------------------------------------
    stomp()
    {
        if (this.CharacterManager.usePower(this.CharacterManager.POWER_STOMP) && !this.stompFct && !this.IsSwimming && !this.IsStun && !this.IsDead)
        {
            this.stopLoopingActions();
            this.Character.stopMovement();

            this.AnimationController.play(this.Character.ANIMATION_STOMP, this.Direction, false);
            this.PlayerControls.CanMove = false;

            this.stompFct = {
                "end": this.stompEnd.bind(this, this.Direction),
                "frame": this.stompFrame.bind(this)
            };

            this.AnimationController.on(this.AnimationController.EVENT_ANIMATION_END, this.stompFct.end);
            this.AnimationController.on(this.AnimationController.EVENT_ANIMATION_FRAME_CHANGED, this.stompFct.frame);
            this.AnimationController.play(this.Character.ANIMATION_STOMP, this.Direction);

            return true;
        }
        return false;
    }

    stompFrame(iFrameId, iAtlasIndex)
    {
        //On the 10th frame the foot hit the ground
        if (iFrameId == 9)
        {
            this.Camera.shake(this.Character.StompShakeTime);
        }
    }

    stompEnd(iDirection, strAnimId)
    {
        if (strAnimId == this.AnimationController.getAnimationId(this.Character.ANIMATION_STOMP, iDirection))
        {
            this.AnimationController.off(this.AnimationController.EVENT_ANIMATION_END, this.stompFct.end);
            this.AnimationController.off(this.AnimationController.EVENT_ANIMATION_FRAME_CHANGED, this.stompFct.frame);
            delete this.stompFct;

            this.Environment.stunAllEnemies(
                this.Character.StompStunDistance,
                this.Character.StompStunTime
            );

            this.AnimationController.play(this.Character.ANIMATION_IDLE, iDirection, true);
            this.PlayerControls.CanMove = true;
        }
    }

    //-----------------------------------------
    //  HIT
    //-----------------------------------------
    getHit(iEnergyLossRatio)
    {
        this.stopLoopingActions();

        let energyMax = this.CharacterManager.getCharacterMaxEnergy();
        let deathThreashold = this.CharacterManager.getCharacterEnergyDeath();
        let loss = energyMax * iEnergyLossRatio;
        let energy = this.CharacterManager.getCharacterEnergy() - loss;

        this.CharacterManager.setCharacterEnergy(energy);

        this.Character.setStun(this.Character.StunTime);
        this.Character.stopMovement();

        if (energy <= deathThreashold)
        {
            console.log ("YOU DIED");
        }
        else
        {
            console.log("You got hit and lost", loss, "energy");
        }
    }

    //-----------------------------------------
    //  SWIM
    //-----------------------------------------
    startSwimming()
    {
        if (!this.IsSwimming)
        {
            this.stopLoopingActions();
            this.Character.isSwimming = true;
            this.AnimationController.play(this.Character.ANIMATION_SWIMMING, this.Direction, true);
        }
    }

    stopSwimming()
    {
        if (this.IsSwimming)
        {
            this.Character.isSwimming = false;
            if (this.Character.IsMoving)
            {
                this.AnimationController.play(this.Character.ANIMATION_RUN, this.Direction, true);
            }
            else
            {
                this.AnimationController.play(this.Character.ANIMATION_IDLE, this.Direction, true);
            }
        }
    }

    //-----------------------------------------
    //  SPIT
    //-----------------------------------------
    spit()
    {
        if (this.CharacterManager.usePower(this.CharacterManager.POWER_SPIT) && !this.spitFct && !this.IsSwimming && !this.IsStun && !this.IsDead)
        {
            this.stopLoopingActions();
            this.Character.stopMovement();
            this.PlayerControls.CanMove = false;

            let pos = this.GridPos;
            let enemy = this.Environment.getClosest(pos.x, pos.y, this.Environment.TYPE_ENEMY, this.SpitStunDistance);
            let direction = this.Direction;

            if (enemy)
            {
                let enemyPos = enemy.GridPos;
                direction = this.calculateDirection(pos, enemyPos);
            }

            this.Character.changeDirection(direction);

            this.spitFct = {
                "end": this.spitEnd.bind(this, direction),
                "frame": this.spitFrame.bind(this, enemy, direction)
            };

            this.AnimationController.on(this.AnimationController.EVENT_ANIMATION_END, this.spitFct.end);
            this.AnimationController.on(this.AnimationController.EVENT_ANIMATION_FRAME_CHANGED, this.spitFct.frame);
            this.AnimationController.play(this.Character.ANIMATION_SPIT, direction, false);

            this.AudioManager.playSfx("crachat");

            return true;
        }

        return false;
    }

    spitFrame(objEnemy, iDirection, iFrameId, iAtlasIndex)
    {
        //On the 10th frame, the spit goes out
        if (iFrameId == 9)
        {
            let pos = new Vector3(this.Character.Mesh.position.x, this.Character.Mesh.position.y, this.Character.Mesh.position.z);
            let targetPos = null;
            if (objEnemy)
            {
                targetPos = new Vector3(objEnemy.Mesh.position.x, objEnemy.Mesh.position.y, objEnemy.Mesh.position.z);
            }
            else
            {
                targetPos = new Vector3(
                    this.Character.Mesh.position.x - (iDirection == Direction.East || iDirection == Direction.West ? iDirection - 2 : 0), 
                    this.Character.Mesh.position.y,
                    this.Character.Mesh.position.z + (iDirection == Direction.South || iDirection == Direction.North ? iDirection - 1 : 0)
                );
            }

            let offset = this.Character.SpitOffset;
            pos.add(offset);
            targetPos.add(offset);

            this.Environment.spawnSpit(pos, targetPos, this.Character.SpitStunDistance, this.Character.SpitStunTime);
        }
    }

    spitEnd(iDirection, strAnimId)
    {
        if (strAnimId == this.AnimationController.getAnimationId(this.Character.ANIMATION_SPIT, iDirection))
        {
            this.AnimationController.off(this.AnimationController.EVENT_ANIMATION_END, this.spitFct.end);
            this.AnimationController.off(this.AnimationController.EVENT_ANIMATION_FRAME_CHANGED, this.spitFct.frame);
            delete this.spitFct;

            this.AnimationController.play(this.Character.ANIMATION_IDLE, iDirection, true);
            this.PlayerControls.CanMove = true;
        }
    }

    //-----------------------------------------
    //  BREAK
    //-----------------------------------------
    break(objObstacle)
    {
        if (this.CharacterManager.push() && !this.breakFct && this.Character.CanMove && !this.IsSwimming && !this.IsStun && !this.IsDead)
        {
            this.stopLoopingActions();
            this.Environment.MapMoveIcon.setVisible(false);
            let fctBreak = this.animateBreak.bind(this, objObstacle);

            let pos = this.Character.goTo(
                objObstacle.Mesh.position.x,
                objObstacle.Mesh.position.z,
                false,
                fctBreak
            );

            if (!pos)
            {
                let diff = Math.pow(this.GridPos.x - objObstacle.GridPos.x, 2) + Math.pow(this.GridPos.y - objObstacle.GridPos.y, 2);
                if (diff == 1)
                {
                    fctBreak();
                }
            }
            return true;
        }
        return false;
    }

    animateBreak(objObstacle)
    {
        this.PlayerControls.CanMove = false;
        this.stop();

        let direction = this.Direction;
        let diff = new Vector2(this.WorldPos.x - objObstacle.WorldPos.x, this.WorldPos.z - objObstacle.WorldPos.z);
        if (Math.abs(diff.x) > Math.abs(diff.y))
        {
            direction = (diff.x > 0 ? Direction.West : Direction.East);
        }
        else
        {
            direction = (diff.y > 0 ? Direction.North : Direction.South);
        }
        if (direction != this.Direction)
        {
            this.Character.changeDirection(direction);
        }

        this.breakFct = {
            "frame": this.breakFrame.bind(this, objObstacle, direction),
            "end": this.breakEnd.bind(this, objObstacle, direction)
        }

        this.AnimationController.on(this.AnimationController.EVENT_ANIMATION_END, this.breakFct.end);
        this.AnimationController.on(this.AnimationController.EVENT_ANIMATION_FRAME_CHANGED, this.breakFct.frame);
        this.AnimationController.play(this.Character.ANIMATION_PUSH, direction);
    }

    breakFrame(objObstacle, iDirection, iFrameId, iAtlasIndex)
    {
        //On the 15th frame, the obstacle is broken
        if (iFrameId == 14)
        {
            this.Camera.shake(this.Character.PushShakeTime);
            objObstacle.applyBreak();

            this.AudioManager.playSfx("rock_break");
        }

    }

    breakEnd(objObstacle, iDirection, strAnimId)
    {
        if (strAnimId == this.AnimationController.getAnimationId(this.Character.ANIMATION_PUSH, iDirection))
        {
            this.AnimationController.off(this.AnimationController.EVENT_ANIMATION_END, this.breakFct.end);
            this.AnimationController.off(this.AnimationController.EVENT_ANIMATION_FRAME_CHANGED, this.breakFct.frame);
            delete this.breakFct;

            this.AnimationController.play(this.Character.ANIMATION_IDLE, this.Direction, true);
            this.PlayerControls.CanMove = true;
        }
    }

    //-----------------------------------------
    //  TELEPORT
    //-----------------------------------------
    teleport()
    {
        return this.CharacterManager.usePower(this.CharacterManager.POWER_TELEPORT);
    }

    //-----------------------------------------
    //  TRAP
    //-----------------------------------------
    putTrap()
    {
        if (!this.isPicking && this.Character.CanMove && !this.IsSwimming && this.ItemManager.CanTrap)
        {
            let gridPos = this.GridPos;
            let offsetX = (this.Direction == Direction.West ? -1 : (this.Direction == Direction.East ? 1 : 0));
            let offsetY = (this.Direction == Direction.North ? -1 : (this.Direction == Direction.South ? 1 : 0));

            let targetPos = new Vector2(gridPos.x + offsetX, gridPos.y + offsetY);

            if (!this.Grid.isTileEmpty(gridPos.x + offsetX, gridPos.x + offsetY))
            {
                targetPos = this.Grid.getNearestWalkableTile(
                    gridPos.x + offsetX,
                    gridPos.y + offsetY,
                    gridPos.x,
                    gridPos.y,
                    false,
                    null,
                    false,
                    true
                );
            }

            if (targetPos)
            {
                this.stopLoopingActions();

                let fctTrap = this.animatePutTrap.bind(this, targetPos);

                let diff = Math.abs(gridPos.x - targetPos.x) + Math.abs(gridPos.y - targetPos.y);

                if (diff > 1)
                {
                    this.Character.goTo(
                        targetPos.x,
                        targetPos.y,
                        false,
                        fctTrap
                    );
                }
                else
                {
                    fctTrap();
                }

                return true;
            }
        }
        return false;
    }


    animatePutTrap(objTargetPos)
    {
        this.Character.isPicking = true;
        this.PlayerControls.CanMove = false;
        this.stop();

        let direction = this.Direction;
        let worldPos = this.Grid.gridToWorldPosition(objTargetPos.x, objTargetPos.y);

        let diff = new Vector2(this.WorldPos.x - worldPos.x, this.WorldPos.z - worldPos.y);
        if (Math.abs(diff.x) > Math.abs(diff.y))
        {
            direction = (diff.x > 0 ? Direction.West : Direction.East);
        }
        else
        {
            direction = (diff.y > 0 ? Direction.North : Direction.South);
        }
        if (direction != this.Direction)
        {
            this.Character.changeDirection(direction);
        }

        this.trapFct = this.putTrapEnd.bind(this, objTargetPos, direction);

        this.AnimationController.on(this.AnimationController.EVENT_ANIMATION_END, this.trapFct);
        this.AnimationController.play(this.Character.ANIMATION_USE, direction);
    }

    putTrapEnd(objTargetPos, iDirection, strAnimId)
    {
        if (strAnimId == this.AnimationController.getAnimationId(this.Character.ANIMATION_USE, iDirection))
        {
            this.AnimationController.off(this.AnimationController.EVENT_ANIMATION_END, this.trapFct);
            delete this.trapFct;

            let trapItem = this.Character.TrapObstacle;
            if (trapItem)
            {
                let gridPos = this.GridPos;
                let tileId = this.Grid.getTileId(objTargetPos.x, objTargetPos.y);

                this.WorldManager.updateCellContent(tileId, trapItem, 1);
                this.ItemManager.removeEquipment("TOOL");
                this.Environment.updateDisplay(gridPos.x, gridPos.y);
            }

            this.AnimationController.play(this.Character.ANIMATION_IDLE, this.Direction, true);
            this.PlayerControls.CanMove = true;
            this.Character.isPicking = false;
        }
    }

    //-----------------------------------------
    //  ANTISTRESS
    //-----------------------------------------
    startAntiStress()
    {
        if (!this.IsAntiStress && !this.IsSwimming && !this.IsStun && !this.IsDead)
        {
            this.CharacterManager.startStressReduction();

            this.Character.isAntiStress = true;
            this.Character.stopMovement();

            let playerToy =  this.ItemManager.ToyEquipment;
            let strAnimation = this.Character.ANIMATION_ANTISTRESS;

            let isMusic = playerToy && playerToy.Icon.ui == "icon_music";
            let isDoll = playerToy && playerToy.Icon.ui == "icon_doll";

            if (isMusic)
            {
                strAnimation = this.Character.ANIMATION_MUSIC;
            }
            else if (isDoll)
            {
                strAnimation = this.Character.ANIMATION_DOLL;
            }

            this.AnimationController.play(strAnimation, this.Direction, true);
            return true;
        }
        return false;
    }

    stopAntiStress()
    {
        if (this.IsAntiStress)
        {
            this.CharacterManager.stopStressReduction();
            this.Character.isAntiStress = false;

            this.AnimationController.play(this.Character.ANIMATION_IDLE, this.Direction, true);

            return true;
        }
        return false;
    }

    //-----------------------------------------
    //  ANTISTRESS
    //-----------------------------------------
    startMusic()
    {
        if (!this.IsAntiStress && !this.IsSwimming && !this.IsStun && !this.IsDead)
        {
            this.CharacterManager.startStressReduction();

            this.Character.isAntiStress = true;
            this.Character.stopMovement();

            this.AnimationController.play(this.Character.ANIMATION_MUSIC, this.Direction, true);

            return true;
        }
        return false;
    }

    stopMusic()
    {
        if (this.IsAntiStress)
        {
            this.CharacterManager.stopStressReduction();
            this.Character.isAntiStress = false;

            this.AnimationController.play(this.Character.ANIMATION_IDLE, this.Direction, true);

            return true;
        }
        return false;
    }

    //-----------------------------------------
    //  FISHING
    //-----------------------------------------
    startFishing()
    {
        if (this.ItemManager.CanFish && !this.IsFishing && !this.IsSwimming && !this.IsStun && !this.IsDead)
        {
            let gridPos = this.GridPos;
            let tile = new Vector2(
                gridPos.x + (this.Direction == Direction.West ? -1 : (this.Direction == Direction.East ? 1 : 0)),
                gridPos.y + (this.Direction == Direction.North ? -1 : (this.Direction == Direction.South ? 1 : 0))
            );

            if (!this.Character.Environment.Obstacles.getIsTileSwimmable(tile.x, tile.y))
            {
                tile = null;
                for (let i = 0; i < 4; i++)
                {
                    let x = gridPos.x + (i % 2 ? 0 : (i == 0 ? -1 : 1));
                    let y = gridPos.y + (i % 2 ? (i == 1 ? -1 : 1) : 0);

                    if (this.Character.Environment.Obstacles.getIsTileSwimmable(x, y))
                    {
                        tile = new Vector2(x, y);
                        break;
                    }
                }
            }

            if (tile)
            {
                let direction = this.Direction;
                let diff = new Vector2(tile.x - gridPos.x, tile.y - gridPos.y);

                if (Math.abs(diff.x) > Math.abs(diff.y))
                {
                    direction = (diff.x < 0 ? Direction.West : Direction.East);
                }
                else
                {
                    direction = (diff.y < 0 ? Direction.North : Direction.South);
                }

                if (direction != this.Direction)
                {
                    this.Character.changeDirection(direction, true);
                }

                this.fctFishing = this.fishingAnimLoop.bind(this, direction);
                this.startFishingTime = (new Date()).getTime();
                this.combineFishingChance = 0;

                this.AnimationController.on(this.AnimationController.EVENT_ANIMATION_LOOP_CYCLE, this.fctFishing);
                this.AnimationController.play(this.Character.ANIMATION_FISHING, direction, true);

                this.Character.isFishing = true;

                return true;
            }
        }

        return false;
    }

    stopFishing()
    {
        if (this.IsFishing)
        {
            this.AnimationController.off(this.AnimationController.EVENT_ANIMATION_LOOP_CYCLE, this.fctFishing);
            this.AnimationController.play(this.Character.ANIMATION_IDLE, this.Direction, true);
            delete this.fctFishing;

            this.Character.isFishing = false;
            return true;
        }
        return false;
    }

    fishingAnimLoop(iDirection, strAnimId)
    {
        if (strAnimId == this.AnimationController.getAnimationId(this.Character.ANIMATION_FISHING, iDirection))
        {
            let now = (new Date()).getTime();
            let elapsed = now - this.startFishingTime;

            if (elapsed < 10000)
            {
                return;
            }



            this.combineFishingChance += this.ItemManager.FishChances;
            let random = Math.random();

            if (this.combineFishingChance > random)
            {
                let quantity = this.ItemManager.FishQuantity;
                let item = this.ItemManager.getItem(this.ItemManager.FishItem);

                if (quantity > 0 && item)
                {
                    this.ItemManager.addItem(item.Id, quantity);
                    let uim = UIManager.instance;
                    uim.Notifications.showItemGain(item, quantity);

                    this.stopFishing();
                }
            }
        }
    }

    stopLoopingActions()
    {
        if (this.IsAntiStress)
        {
            this.stopAntiStress();
        }

        if (this.IsFishing)
        {
            this.stopFishing();
        }
    }
}