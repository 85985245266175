import ActionButton from "./ActionButton.js";

export default class ColoredActionButton extends ActionButton
{
    constructor()
    {
        super();
    }

    /*******************************************
    *   INITIALIZATION
    *******************************************/
    /**
        Parameters to pass to the init function:
        - ui:           UI section object where this component resides
        - textureName:  String ID of the texture to display inside the button
        - rWidth:       Responsive width equation to set for the ContainerResponsive functionnalities
        - rHeight:      Responsive height equation to set for the ContainerResponsive functionnalities
        - iconScale:    Scale to apply to the icon inside the button
        - bgColor:      Color to apply on the background
        - strokeColor:  Color to apply on the stroke
        - iconColor:    Color to apply as a tint on the icon
        - disabled:     (Optional) If this button is disabled at start. Default is FALSE
        - tint:         (Optional) Tint to apply to this button. Default is 0xFFFFFF
    */
    init(meta)
    {
        this.bgColor = meta.bgColor;
        this.strokeColor = meta.strokeColor;
        this.iconColor = ("iconColor" in meta ? meta.iconColor : null);

        return super.init(meta);
    }

    /*******************************************
    *   BUILD
    *******************************************/
    buildSprite(fWidth, fHeight, objParent)
    {
        super.buildSprite(fWidth, fHeight, objParent);

        if (this.iconColor)
        {
            objParent.iconSprite.tint = this.iconColor;
        }
    }
}