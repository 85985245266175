import { Graphics } from '@pixi/graphics';
import { Sprite } from "@pixi/sprite";
import Library from "../../../Library.js";
import ColorTheme from "../../ColorTheme.js"
import ContainerResponsive from "../responsive/ContainerResponsive.js";
import GraphicsResponsive from "../responsive/GraphicsResponsive.js";


export default class CharacterFace  extends ContainerResponsive
{

    constructor ()
    {
        super();
    }

    get BuildId() { return this.buildId; }

    /*******************************************
    *   INITIALIZATION
    *******************************************/
    /**
        Parameters to pass to the init function:
        - ui:       UI section object where this component resides
        - rHeight:  Responsive equation representing the height of this component
        - build:    A list of color and type = first 6 digits are colors and 4 last are part type
                    ex: 074353_1011
                    Digits are HAIR-SKIN-IRIS-SWEATER-LEGS-BOOTS  _   HAIR-HEAD-ARMS-LEGS
                    IMPORTANT NOTE FOR MUTATION (HEAD, ARMS AND LEGS): 0 means NO MUTATION, BUT 1 mean index 0 in ColorTheme.MUTATIONS array,
                    So if we have  074353_1011 means that we have NO head mutation, the BEAR arm and ELEPHANT leg
    */
    init(meta)
    {
        this.strHeight = meta.rHeight;
        this.buildId = meta.build;
        this.decomposeIndex(meta.build);
        this.isGeneratingAnimation = false;

        return super.init(meta);
    }

    decomposeIndex (_strItem)
    {
        if (_strItem.length !== 11)
        {
            throw ("Unable to create character. Character color string must be exactly 10 in length. We receive '" + _strItem + "'. It has a length of " +  _strItem.length );
        }

        this.hair = _strItem[0];
        this.skin = _strItem[1];
        this.iris = _strItem[2];
        this.sweater = _strItem[3];
        this.legs = _strItem[4];
        this.boots = _strItem[5];

        this.type_hair = parseInt(_strItem[7]) + 1;
        this.mutation_head = _strItem[8];
        this.mutation_arm = _strItem[9];
        this.mutation_legs = _strItem[10];



    }


    getLayers ()
    {
        let hasHeadMutation = this.mutation_head !== "0";
        let strMutationHead = hasHeadMutation ? ColorTheme.MUTATION_HEAD[this.mutation_head - 1] : ""
        let hasBackHair = this.type_hair === "6";

        let arrLayers = [];

        if (hasBackHair) //back hair must go behind everything
        {
            arrLayers.push("18_hair_6_back.png");
        }

        if (!hasHeadMutation)
        {
            arrLayers.push("11_face.png");
            arrLayers.push("12_mouth.png");
            arrLayers.push("13_nose.png");
            arrLayers.push("14_eyes.png");
            arrLayers.push("15_eyelashes.png");
            arrLayers.push("16_eyebrows.png");
            arrLayers.push("17_iris.png");


        }
        else
        {
            arrLayers.push(`11_${strMutationHead}.png`);
        }

        arrLayers.push(`18_hair_${this.type_hair}.png`);

        return arrLayers;
    }

    getColors(_strItem)
    {
        let hair = ColorTheme.HAIR[this.hair];
        let skin = ColorTheme.SKIN[this.skin];
        let mouth = ColorTheme.getMouthFromSkin(skin)
        let nose = ColorTheme.getNoseFromSkin(skin);
        let eyes = 0xdceff3;
        let iris = ColorTheme.IRIS[this.iris];
        let none = 0xFFFFFF;

        let hasHeadMutation = this.mutation_head !== "0";
        let hasBackHair = this.type_hair === "6";

        let arrColors = [];

        if (hasBackHair) //back hair must go behind everything
        {
            arrColors.push(hair);
        }

        if (!hasHeadMutation)
        {
            arrColors.push(skin);
            arrColors.push(mouth);
            arrColors.push(nose);
            arrColors.push(eyes);
            arrColors.push(hair);
            arrColors.push(hair)
            arrColors.push(iris);
        }
        else
        {
            arrColors.push(none);

        }

        arrColors.push(hair);

        return arrColors;
    }

    build ()
    {

        if (!this.container)
        {
            this.container = new ContainerResponsive().init({"ui": this.UI});
            this.addChild(this.container);
        }

        let textures = Library.getTextureListFromAtlas("character_1frame");

        let arrLayers = this.getLayers();
        let arrColors = this.getColors();

        let iCpt = 0;


        let iMinX = this.UI.ResponsiveManager.Width;
        let iMinY = this.UI.ResponsiveManager.Height;
        let iMaxX = 0;
        let iMaxY = 0;

        this.arrParts = [];

        for (let i = 0; i < arrLayers.length; i++)
        {
            let strKey = arrLayers[i];
            let texture = textures[strKey]

            let trim = texture.trim;

            if (iMinX > trim.x)
                iMinX = trim.x;

            if (iMinY > trim.y)
                iMinY = trim.y;

            if (iMaxX < trim.x + trim.width)
                iMaxX = trim.x + trim.width;

            if (iMaxY < trim.y + trim.height)
                iMaxY = trim.y + trim.height;

            let layer = new Sprite(texture)
            layer.tint = arrColors[iCpt]
            this.container.addChild(layer);

            iCpt++;

            this.arrParts.push(layer);
        }

        if (!this.faceMask)
        {
            this.faceMask = new GraphicsResponsive().init({"ui": this.UI});
            this.addChild(this.faceMask);
        }
        this.faceMask.beginFill(0, 0.25);
        this.faceMask.drawRect(0, 0, iMaxX - iMinX, iMaxY - iMinY);


        for (let i = 0; i < this.arrParts.length; i++)
        {
            let layer = this.arrParts[i];

            layer.x = -iMinX;
            layer.y = -iMinY;
        }


        this.container.mask = this.faceMask;

        let fullHeight = iMaxY - iMinY;
        let faceRatio = fullHeight / 1024;


        let height = this.evaluate(this.strHeight, 0,0,0,0) / faceRatio;

        this.container.height = height;
        this.faceMask.height = height;

        this.container.scale.x = this.container.scale.y;
        this.faceMask.scale.x = this.faceMask.scale.y;
    }

    clear ()
    {
        while (this.children.length > 0)
        {
            let layer = this.getChildAt(0);
            this.removeChildAt(0);

            layer.destroy();
            layer = null;
        }
    }
}