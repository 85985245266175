import { TextStyle } from '@pixi/text';
import { TextMetrics } from '@pixi/text';
import ContainerResponsive from "../responsive/ContainerResponsive.js";
import GraphicsResponsive from "../responsive/GraphicsResponsive.js";
import TextResponsive from "../responsive/TextResponsive.js";

export default class InventorySlotTitle extends ContainerResponsive
{
    constructor()
    {
        super();
    }

    /*******************************************
    *   INITIALIZATION
    *******************************************/
    /**
        Parameters to pass to the init function:
        - ui:           UI section object where this slot resides

    */
    init(meta)
    {
        this.strTitle = "";
        return super.init(meta);
    }

    setTitle (strTitle)
    {
        this.strTitle = strTitle;
        this.build();
    }

    /*******************************************
    *   BUILD
    *******************************************/
    build ()
    {
        super.build();

        let metrics = this.buildText();
        this.buildBackground(metrics);
    }

    clean()
    {
        if (this.bg)
        {
            this.bg.clear();
        }
        super.clean();
    }

    buildText()
    {
        if (!this.txt)
        {
            this.style = new TextStyle({ fontFamily: "Roboto", fontSize: 15,  fontWeight: 400, fill: "#000000", align: "center"});

            this.txt = new TextResponsive(this.strTitle, this.style).init({"ui": this.UI});
            this.addChild(this.txt);
        }

        let metrics = TextMetrics.measureText(" " + this.strTitle + " ", this.style);

        this.txt.text = this.strTitle;
        this.txt.style = this.style;
        this.txt.x = (metrics.width + 15) / 2;
        this.txt.y = (metrics.height + 15) / 2;
        this.txt.width = metrics.width;
        this.txt.height = metrics.height;
        this.txt.anchor.x = this.txt.anchor.y = 0.5;

        return metrics;
    }

    buildBackground(metrics)
    {
        if (!this.bg)
        {
            this.bg = new GraphicsResponsive().init({"ui": this.UI});
            this.addChildAt(this.bg, 0);
        }

        this.bg.beginFill(0xFFFFFF, 1);
        this.bg.drawRect(0, 0, metrics.width + 15, metrics.height + 15 );
        this.bg.endFill();
    }
}