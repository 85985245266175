import EventEmitter from "eventemitter3";
import {Mesh} from "three";
import {MeshBasicMaterial} from "three";
import {PlaneGeometry} from "three";
import {Vector2} from "three";
import Constants from "../../utils/Constants.js";
import WorldManager from "../WorldManager.js";
import Library from "../../Library.js";
import DependencyContainer from "../../utils/DependencyContainer.js";
import _ from "lodash";

export default class BaseObject extends EventEmitter
{
    constructor()
    {
        super();
        this.visualsCreated = false;
        this.dependencies = DependencyContainer.getInstance();
    }

    static get EVENT_VISUALS_CREATED() { return "visuals-created"; }
    static get EVENT_TILE_CHANGED() { return "tile-changed"; }

    //---------------------------------------------------------
    //  DEPENDENCIES
    //---------------------------------------------------------
    get AudioManager() { return this.Dependencies.get("AudioManager"); }
    get CharacterManager() { return this.Dependencies.get("CharacterManager"); }
    get GameManager() { return this.Dependencies.get("GameManager"); }
    get ItemManager() { return this.Dependencies.get("ItemManager"); }
    get ResponsiveManager() { return this.Dependencies.get("ResponsiveManager"); }
    get UIManager() { return this.Dependencies.get("UIManager"); }
    get WorldManager() { return this.Dependencies.get("WorldManager"); }
    //---------------------------------------------------------

    get Id() { return this.meta.id; }
    get Dependencies() { return this.dependencies; }
    get VisualsCreated() { return this.visualsCreated; }
    get AtlasName() { return this.meta.atlasName; }
    get TextureName() { return this.meta.textureName; }
    get SpawnPos() { return this.meta.spawnPos; }
    get Size() { return this.meta.size; }
    get Environment() { return this.meta.environment; }
    get Scene() { return this.Environment.Scene; }
    get Camera() { return this.Scene.Camera; }
    get Grid() { return this.Environment.Grid; }
    get Mesh() { return this.mesh; }
    get GridPos() { return this.gridPos; }
    
    get WorldPos() { return (this.mesh ? this.mesh.position : new Vector2()); }
    set WorldPos(newValue)
    {
        if (this.mesh)
        {
            this.mesh.position.set(newValue.x, newValue.y, newValue.z);
        }
    }

    get CollisionRect()
    {
        let origin = this.getMeshOrigin();
        return {
            "min": new Vector2(
                this.Mesh.position.x - this.PlaneSize.width * origin.x,
                this.Mesh.position.z - this.PlaneSize.height * origin.y
            ),
            "max": new Vector2(
                this.Mesh.position.x + this.PlaneSize.width * origin.x,
                this.Mesh.position.z + this.PlaneSize.height * origin.y
            )
        };
    }

    /*******************************************
    *   INITIALIZATION
    *******************************************/
    /**
        Parameters to pass to the init function:
        - id:           Logic id of this object instance. Usually managed by some sort of global manager
        - atlasName:    String identifying the atlas to use when creating the object 
        - textureName:  String identifying the texture to use in the atlas specified above
        - spawnPos      Vector2 containing the position to spawn this object on the field. Should be in grid coordinates (not ThreeJS coordinates)
        - environment   Environment instance where this object lives
        - spawnOnCreate (Optional)If this object should be spawned as soon as the instance is created. Default is TRUE
        - meshOrigin    (Optional)Relative position of the mesh's geometry. Default is {"x": 0.5, "y": 1}
        - params        JSON object containing additionnal parameters to pass to this object

    */
    init(meta)
    {
        this.lastPos = meta.spawnPos;
        this.gridPos = meta.spawnPos;

        if (!("spawnOnCreate" in meta))
        {
            meta.spawnOnCreate = true;
        }

        this.meta = meta;

        this.createClosure();
        this.bindEvents();

        this.createVisuals();
        if (meta.spawnOnCreate)
        {
            this.spawn(meta.spawnPos);
        }
    }

    createClosure()
    {
        this.fctUpdate = this.update.bind(this);
        this.fctOnCameraTileChanged = this.onCameraTileChanged.bind(this);
    }

    bindEvents()
    {
        this.GameManager.on(this.GameManager.EVENT_UPDATE, this.fctUpdate);
        this.Camera.on(this.Camera.EVENT_GRID_POS_CHANGED, this.fctOnCameraTileChanged);
    }

    destroy()
    {
        this.GameManager.off(this.GameManager.EVENT_UPDATE, this.fctUpdate);
        if (this.Camera)
        {
            this.Camera.off(this.Camera.EVENT_GRID_POS_CHANGED, this.fctOnCameraTileChanged);
        }

        if (this.Mesh)
        {
            this.Scene.remove(this.Mesh);

            this.geometry.dispose();
            this.material.dispose();
        }
    }

    createVisuals()
    {
        this.texture = null;
        let size = null;

        let atlasName;
        let textureName;

        if (!this.meta.texture)
        {

            atlasName = this.AtlasName;
            textureName = this.TextureName;

            if (!atlasName && textureName)
            {
                atlasName = textureName;
                textureName = null;
            }

            if (atlasName)
            {
                this.texture = this.fetchTexture(atlasName, textureName);
            }
        }
        else
        {
            this.texture = this.meta.texture;
            size = Library.getTexture3DSizeFromTexture(this.texture);
        }

        if (!this.texture && this.TextureName !== "")
        {
            console.error("No texture avaialable for", this.meta);
            return;

        }
        
        this.material = this.createMaterial(this.texture);


        if (!size && this.AtlasName)
        {
            size = this.getSizeFromTexture(atlasName, textureName);
        }

        this.geometry = this.createGeometry(size, this.getMeshOrigin());
        this.mesh = this.createMesh(this.geometry, this.material);

        this.visualsCreated = true;
        this.emit(BaseObject.EVENT_VISUALS_CREATED, this);
    }

    fetchTexture(strAtlasName, strTextureName = null)
    {
        return Library.getTexture3D(strAtlasName, strTextureName);
    }

    createMaterial(objTexture)
    {
        return new MeshBasicMaterial({
            map:         objTexture, 
            transparent: true, 
            wireframe:   false, 
            depthWrite:  false
        });
    }

    createGeometry(objSize, objOrigin)
    {

        let divider = this.ResponsiveManager.AssetDivider;
        if (!objSize)
        {
            objSize = {"w": this.Grid.CellSize, "h": this.Grid.CellSize};
            divider = 1;
        }

        let widthMultiplier = parseFloat(_.get(this, "meta.item.definition.scale", 1));
        let heightMultiplier = parseFloat(_.get(this, "meta.item.definition.scale", 1));


        let geometry = new PlaneGeometry((objSize.w / divider) * widthMultiplier, (objSize.h / divider)  * heightMultiplier, 10, 10);
        //We substract 0.5 to the mesh origin value because by default in ThreeJS, planes have their origin in the center (0.5, 0.5)
        geometry.translate(objSize.w / divider * (objOrigin.x - 0.5), (objSize.h / divider * (objOrigin.y-  0.5)) * heightMultiplier, 0);
        geometry.verticesNeedUpdate = true;

        return geometry;
    }

    createMesh(objGeometry, objMaterial)
    {
        let mesh = new Mesh(objGeometry, objMaterial);
        mesh.renderOrder = 10;

        return mesh;
    }

    getSizeFromTexture (strAtlasName, strTextureName = null)
    {   
        return Library.getTexture3DSize(strAtlasName, strTextureName);
    }

    getMeshOrigin()
    {   
        if (this.meta.meshOrigin)
        {
            return this.meta.meshOrigin;
        }
        return new Vector2(0.5, 1);
    }

    /*******************************************
    *   UPDATE LOOP
    *******************************************/
    update(fDeltaTime)
    {
        this.updateCameraRotation();
        this.updatePositionDetection();
    }

    updateCameraRotation()
    {
        if (this.meta.noRotation)
            return;

        if (this.Mesh && this.Environment)
        {
            if (this.Camera)
            {
                let camRotation = this.Camera.rotation;

                if (camRotation)
                {
                    this.mesh.rotation.x = camRotation.x; //Math.PI / 180 *  -40;
                    this.mesh.rotation.y = camRotation.y; //Math.PI / 180 * -10;
                    this.mesh.rotation.z = camRotation.z; //Math.PI / 180 * 1;
                }
            }
        }
    }

    updatePositionDetection()
    {
        if (this.Mesh && this.Environment)
        {
            if (!this.lastPos || this.lastPos.x != this.mesh.position.x || this.lastPos.z != this.mesh.position.z)
            {
                this.lastPos = new Vector2(this.mesh.position.x, this.mesh.position.z);

                let newGridPos = this.Grid.worldToGridPosition(this.lastPos.x, this.lastPos.y);
                let shouldEmit = this.gridPos.x != newGridPos.x || this.gridPos.y != newGridPos.y;
                this.gridPos = newGridPos;

                if (shouldEmit)
                {
                    this.emit(BaseObject.EVENT_TILE_CHANGED, this, newGridPos);
                }
            }
        }
    }

    /*******************************************
    *   SPAWN MANAGEMENT
    *******************************************/
    /**
        Spawns the object on the field
        @param objPos Position where to spawn the object. Should be in grid coordinates (not ThreeJS coordinates)
    */
    spawn(objPos)
    {
        let worldPos = this.Grid.gridToWorldPosition(objPos.x, objPos.y);
        
        if (!this.Mesh)
        {
            console.error("BaseObject.spawn - No Mesh to spawn", this.meta);
            return;
        }

        this.Mesh.position.set(
            worldPos.x,
            0,
            worldPos.y
        );
        this.Scene.add(this.Mesh);
    }

    despawn()
    {
        if (this.Mesh)
        {
            this.Scene.remove(this.Mesh);
        }
    }

    /*******************************************
    *   EVENTS
    *******************************************/
    onCameraTileChanged(newPos)
    {

    }
}