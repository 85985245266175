import { gtag } from "ga-gtag";
import { string2hex }  from "@pixi/utils"; 
import { TextStyle } from "@pixi/text";
import { TextMetrics } from "@pixi/text";
import Character from "../commons/characters/Character.js";
import ChoicePopup from "../commons/popups/ChoicePopup.js";
import CreationCategoryContent from "./components/CreationCategoryContent.js";
import CreationMenuContent from "./components/CreationMenuContent.js";
import CreationMutationMenuContent from "./components/CreationMutationMenuContent.js";
import CreationMutationCategoryContent from "./components/CreationMutationCategoryContent.js";
import ColorTheme from "../ColorTheme.js";
import Display from "../../utils/Display.js";
import GraphicsResponsive from "../commons/responsive/GraphicsResponsive.js";
import Lerp from "../../utils/Lerp.js";
import Library from "../../Library.js";
import MenuUI from "../MenuUI.js";
import ScreenTransition from "../commons/ScreenTransition.js";
import SpriteResponsive from "../commons/responsive/SpriteResponsive.js";
import TextButton from "../commons/buttons/TextButton.js";
import TextResponsive from "../commons/responsive/TextResponsive.js";

export default class CharacterCreatorUI extends MenuUI
{
    constructor()
    {
        super();
    }

    get SECTION_HAIR() { return "hair"; }
    get SECTION_SKIN() { return "face"; }
    get SECTION_EYES() { return "eye"; }
    get SECTION_BODY() { return "coat"; }
    get SECTION_PANTS() { return "pants"; }
    get SECTIONS() 
    {
        return [
            this.SECTION_SKIN,
            this.SECTION_EYES,
            this.SECTION_HAIR,
            this.SECTION_BODY,
            this.SECTION_PANTS
        ]; 
    }

    get Id() { return "creator"; }
    get SelectedCharacter() { return this.selectedCharacter; }
    set SelectedCharacter(iNewValue) { this.selectedCharacter = iNewValue; this.build(); }

    get TitleLabel() { return this.LabelManager.translate("Crea_Cree").toUpperCase(); }
    get ButtonLabel() { return this.LabelManager.translate("Crea_OK").toUpperCase(); }

    get CharacterWidth() { return this.CharacterHeight; }
    get CharacterHeight() { return "(ih * 33%)"; }
    get CharacterOutlineColor() { return string2hex(this.Values.charactercreator.character.selected.outline.color); }
    get CharacterOutlineScale() { return this.Values.charactercreator.character.selected.outline.scale; }

    get WindowWidth() { return CreationMenuContent.getMinWidth(this.WindowHeight); }
    get WindowHeight() { return "(ih * 40%)"; }
    get WindowX() { return "(iw / 2) - (" + this.WindowWidth + ") / 2"; }
    get WindowY() { return "(ih * 50%)"; }

    /*******************************************
    *   INITIALIZATION
    *******************************************/
    /**
        Parameters to pass to the init function:
        - dependencies:     Dependency injector object
    */
    init(meta)
    {
        this.graphics = {};
        this.sprites = {};
        this.characters = {};
        this.texts = {};
        this.buttons = {};

        this.selectedCharacter = 0;
        this.section = null;
        this.displayMutation = false;
        this.mutationSection = null;

        return super.init(meta);
    }

    createClosure()
    {
        super.createClosure();

        this.fctOnResize = this.onResize.bind(this);
        this.fctOnSectionClick = this.onSectionClick.bind(this);
        this.fctOnMenuBack = this.onMenuBack.bind(this);
        this.fctOnSectionBack = this.onSectionBack.bind(this);
        this.fctOnSectionColorPick = this.onSectionColorPick.bind(this);
        this.fctOnOkButtonClick = this.onOkButtonClick.bind(this);
        this.fctOnMutationSectionClick = this.onMutationSectionClick.bind(this);
        this.fctOnMutationRemove = this.onMutationRemove.bind(this);
        this.fctOnMutationClick = this.onMutationClick.bind(this);
    }

    bindEvents()
    {
        super.bindEvents();
        this.ResponsiveManager.on(this.ResponsiveManager.EVENT_RESIZE, this.fctOnResize);
    }

    destroy(options)
    {
        this.ResponsiveManager.off(this.ResponsiveManager.EVENT_RESIZE, this.fctOnResize);

        if (this.menu)
        {
            this.menu.off(this.menu.EVENT_SECTION_CLICK, this.fctOnSectionClick);
            this.menu.off(this.menu.EVENT_BACK, this.fctOnMenuBack);
            this.menu.off(this.menu.EVENT_NEXT, this.fctOnOkButtonClick);
            this.menu.destroy(options);

            delete this.menu;
        }

        if (this.sectionContent)
        {
            this.sectionContent.off(this.sectionContent.EVENT_BACK, this.fctOnSectionBack);
            this.sectionContent.off(this.sectionContent.EVENT_COLOR_PICK, this.fctOnSectionColorPick);
            this.sectionContent.off(this.sectionContent.EVENT_HAIRCUT_BACK, this.sectionContent.fctBack);
            this.sectionContent.off(this.sectionContent.EVENT_HAIRCUT_NEXT, this.sectionContent.fctNext);
            this.sectionContent.destroy(options);

            delete this.sectionContent;
        }

        if (this.mutationMenu)
        {
            this.mutationMenu.off(this.mutationMenu.EVENT_BACK, this.fctOnMenuBack);
            this.mutationMenu.off(this.mutationMenu.EVENT_REMOVE, this.fctOnMutationRemove);
            this.mutationMenu.off(this.mutationMenu.EVENT_CATEGORY_CLICK, this.fctOnMutationSectionClick);
            this.mutationMenu.off(this.mutationMenu.EVENT_NEXT, this.fctOnOkButtonClick);
            this.mutationMenu.destroy(options);

            delete this.mutationMenu;
        }

        if (this.mutationCategoryMenu)
        {
            this.mutationCategoryMenu.off(this.mutationCategoryMenu.EVENT_CATEGORY_CLICK, this.fctOnMutationClick);
            this.mutationCategoryMenu.off(this.mutationCategoryMenu.EVENT_BACK, this.fctOnMenuBack);
            this.mutationCategoryMenu.off(this.mutationCategoryMenu.EVENT_REMOVE, this.fctOnMutationRemove);
            this.mutationCategoryMenu.destroy(options);

            delete this.mutationCategoryMenu;
        }

        if (this.buttons.ok)
        {
            this.buttons.ok.off(this.buttons.ok.EVENT_CLICK, this.fctOnOkButtonClick);
        }

        for (let key in this.buttons)
        {
            this.buttons[key].destroy(options);
        }

        for (let key in this.graphics)
        {
            this.graphics[key].off("pointerup", this.graphics[key].fct);//@
            this.removeChild(this.graphics[key]);
            this.graphics[key].destroy(options);
        }
        delete this.graphics;

        super.destroy(options);
    }

    /*******************************************
    *   BUILD
    *******************************************/
    build()
    {
        super.build();

        this.buildCharacters();
        this.buildTitle();
        this.buildWindowContent();
        this.buildMutationWindowContent();

        if (!this.section && !this.mutationSection)
        {
            let bIsOk = !this.displayMutation || this.SelectedCharacter < 2;
            if (!bIsOk)
            {
                let mutations = {};
                mutations[this.ItemManager.ITEM_CODES.TYPE_MUTATION_HEAD] = false;
                mutations[this.ItemManager.ITEM_CODES.TYPE_MUTATION_ARM] = false;
                mutations[this.ItemManager.ITEM_CODES.TYPE_MUTATION_LEG] = false;

                for (let i = 0; i < 3; i++)
                {
                    let build = this.CharacterManager.getCharacterBuild(i);
                    let buildSplit = build.split("_");

                    let head = buildSplit[1].substring(1, 2);
                    let arm = buildSplit[1].substring(2, 3);
                    let leg = buildSplit[1].substring(3, 4);

                    if (head !== "0" && head != this.CharacterManager.getMandatoryMutation(this.ItemManager.ITEM_CODES.TYPE_MUTATION_HEAD))
                    {
                        mutations[this.ItemManager.ITEM_CODES.TYPE_MUTATION_HEAD] = true;
                    }
                    if (arm !== "0" && arm != this.CharacterManager.getMandatoryMutation(this.ItemManager.ITEM_CODES.TYPE_MUTATION_ARM))
                    {
                        mutations[this.ItemManager.ITEM_CODES.TYPE_MUTATION_ARM] = true;
                    }
                    if (leg !== "0" && leg != this.CharacterManager.getMandatoryMutation(this.ItemManager.ITEM_CODES.TYPE_MUTATION_LEG))
                    {
                        mutations[this.ItemManager.ITEM_CODES.TYPE_MUTATION_LEG] = true;
                    }
                }

                bIsOk = true;
                for (let key in mutations)
                {
                    if (!mutations[key])
                    {
                        bIsOk = false;
                        break;
                    }
                }
            }

            this.buildButton(bIsOk);
        }
    }

    clean()
    {
        for (let key in this.graphics)
        {
            this.graphics[key].clear();
        }

        if (this.buttons.arrowLeft)
        {
            this.buttons.arrowLeft.stopFlashing();
        }

        if (this.buttons.arrowRight)
        {
            this.buttons.arrowRight.stopFlashing();
        }

        super.clean();
    }

    buildLogo()
    {
        
    }

    buildCharacters()
    {
        this.characterValues = [];

        for (let i = 0; i < 3; i++)
        {
            this.characterValues.push(this.buildCharacter(i));
        }
    }

    buildCharacter(iCharacter)
    {
        let build = this.CharacterManager.getCharacterBuild(iCharacter);
        if (this.characters[iCharacter] && this.characters[iCharacter].Build != build)
        {
            this.removeChild(this.characters[iCharacter]);
            this.characters[iCharacter].destroy({"children": true});
            delete this.characters[iCharacter];
        }

        if (!this.characters[iCharacter])
        {
            this.characters[iCharacter] = new Character().init({"ui": this, build});
            this.addChild(this.characters[iCharacter]);
        }

        if (!this.graphics["clickZone_" + iCharacter])
        {
            this.graphics["clickZone_" + iCharacter] = new GraphicsResponsive().init({"ui": this});
            this.graphics["clickZone_" + iCharacter].interactive = true;
            this.graphics["clickZone_" + iCharacter].buttonMode = true;
            this.graphics["clickZone_" + iCharacter].fct = this.onCharacterClick.bind(this, iCharacter);
            this.graphics["clickZone_" + iCharacter].on("pointerup", this.graphics["clickZone_" + iCharacter].fct);//@

            this.addChild(this.graphics["clickZone_" + iCharacter]);
        }

        let size = Display.getSize();
        let width = this.CharacterWidth;
        let height = this.CharacterHeight;

        let padding = "((iw - " + width + ") / 10)";
        let screenPadding = "(iw-((" + padding + " * 2) + (" + width + " * 3))) * 0.5";
        let x = screenPadding + " + (" + padding + " * (" + iCharacter + ") + " + width + " * " + iCharacter + ")";
        let y = "(ih * 10%)";

        let character = this.characters[iCharacter];

        character.decomposeIndex(build);

        character.rWidth = [{on: "default", width: width}];
        character.rHeight = [{on: "default", height: height}];
        character.rX = [{on: "default", x: x}];
        character.rY = [{on: "default", y: y}];
        character.layout(size);

        for (let key in character.children)
        {
            if (!("originalTint" in character.children[key]))
            {
                character.children[key].originalTint = character.children[key].tint;
            }
            character.children[key].tint = Lerp.lerpColorHex(character.children[key].originalTint, 0x000000, (iCharacter == this.SelectedCharacter ? 0 : 0.55));
        }

        let clickZone = this.graphics["clickZone_" + iCharacter];

        clickZone.beginFill(0x000000, 1/255);
        clickZone.drawRect(0, 0, 100, 100);
        clickZone.endFill();

        clickZone.rX = [{on: "default", x: x + " + (" + width + ") / 4" }];
        clickZone.rY = [{on: "default", y: y}];
        clickZone.rWidth = [{on: "default", width: "(" + width + ") / 2"}];
        clickZone.rHeight = [{on: "default", height: height}];
        clickZone.layout(size);

        return {"x": x, "y": y, "width": width, "height": height};
    }

    buildTitle()
    {
        if (!this.texts.title)
        {
            this.texts.title = new TextResponsive().init({"ui": this});
            this.addChild(this.texts.title);
        }

        let text = this.texts.title;
        let label = this.TitleLabel;

        let fontSize = this.evaluate(this.valueFormula(this.Values.general.title.size * 1.2));
        fontSize = Math.max(fontSize, this.ResponsiveManager.TitleMinFontSize);

        let style = new TextStyle({
            fontFamily: this.Values.general.title.font,
            fontSize,
            fontWeight: this.Values.general.title.weight,
            fill: this.Values.general.title.color,
            align: "center"
        });

        let metrics = TextMetrics.measureText(label, style);

        let x = this.ResponsiveManager.Width / 2 - metrics.width / 2;
        let y = this.ResponsiveManager.Height * 0.05;

        text.text = label;
        text.style = style;
        text.width = metrics.width;
        text.height = metrics.height;
        text.position.set(x, y);
    }

    buildWindowContent()
    {
        let width = this.WindowWidth;
        let height = this.WindowHeight; 
        let x = this.WindowX;
        let y = this.WindowY;
        if (!this.displayMutation)
        {
            if (!this.section)
            {
                if (this.sectionContent && this.sectionContent.parent)
                {
                    this.removeChild(this.sectionContent);
                }
                if (!this.menu)
                {
                    this.menu = new CreationMenuContent().init({
                        "ui": this,
                        "creator": this,
                        "character": this.SelectedCharacter,
                        "rWidth": width,
                        "rHeight": height
                    });
                    this.menu.on(this.menu.EVENT_SECTION_CLICK, this.fctOnSectionClick)//@
                    this.menu.on(this.menu.EVENT_BACK, this.fctOnMenuBack);//@
                    this.menu.on(this.menu.EVENT_NEXT, this.fctOnOkButtonClick);//@

                    this.addChild(this.menu);
                }

                if (this.SelectedCharacter != this.menu.Character)
                {
                    this.menu.Character = this.SelectedCharacter;
                }

                this.menu.rX = [{on: "default", x: x}];
                this.menu.rY = [{on: "default", y: y}];
                this.menu.layout(Display.getSize());

                if (!this.menu.parent)
                {
                    this.addChild(this.menu);
                    this.menu.textbox.textbox.style.visibility = "visible";
                }
            }
            else
            {
                if (this.menu && this.menu.parent)
                {
                    this.removeChild(this.menu);
                    this.menu.textbox.textbox.style.visibility = "hidden";
                }
                if (!this.sectionContent)
                {
                    this.sectionContent = new CreationCategoryContent().init({
                        "ui": this,
                        "creator": this,
                        "character": this.SelectedCharacter,
                        "section": this.section,
                        "rWidth": width,
                        "rHeight": height
                    });
                    this.sectionContent.on(this.sectionContent.EVENT_BACK, this.fctOnSectionBack)//@
                    this.sectionContent.on(this.sectionContent.EVENT_COLOR_PICK, this.fctOnSectionColorPick);//@
                    this.sectionContent.fctBack =  this.onHairArrowClick.bind(this, true);
                    this.sectionContent.fctNext =  this.onHairArrowClick.bind(this, false);
                    this.sectionContent.on(this.sectionContent.EVENT_HAIRCUT_BACK, this.sectionContent.fctBack);//@
                    this.sectionContent.on(this.sectionContent.EVENT_HAIRCUT_NEXT, this.sectionContent.fctNext);//@

                    this.addChild(this.sectionContent);
                }

                if (this.SelectedCharacter != this.sectionContent.Character)
                {
                    this.sectionContent.Character = this.SelectedCharacter;
                }

                if (this.section != this.sectionContent.Section)
                {
                    this.sectionContent.Section = this.section;
                }

                this.sectionContent.rX = [{on: "default", x: x}];
                this.sectionContent.rY = [{on: "default", y: y}];
                this.sectionContent.layout(Display.getSize());

                if (!this.sectionContent.parent)
                {
                    this.addChild(this.sectionContent);
                }
            }
        }
        else
        {
            if (this.menu && this.menu.parent)
            {
                this.removeChild(this.menu);
                this.menu.textbox.textbox.style.visibility = "hidden";
            }
            if (this.sectionContent && this.sectionContent.parent)
            {
                this.removeChild(this.sectionContent);
            }
        }
    }

    buildMutationWindowContent()
    {
        let width = this.WindowWidth;
        let height = this.WindowHeight; 
        let x = this.WindowX;
        let y = this.WindowY;
        if (this.displayMutation)
        {
            if (!this.mutationSection)
            {
                if (this.mutationCategoryMenu && this.mutationCategoryMenu.parent)
                {
                    this.removeChild(this.mutationCategoryMenu);
                }
                if (!this.mutationMenu)
                {
                    this.mutationMenu = new CreationMutationMenuContent().init({
                        "ui": this,
                        "creator": this,
                        "character": this.SelectedCharacter,
                        "rWidth": width,
                        "rHeight": height
                    });
                    this.mutationMenu.on(this.mutationMenu.EVENT_BACK, this.fctOnMenuBack);//@
                    this.mutationMenu.on(this.mutationMenu.EVENT_REMOVE, this.fctOnMutationRemove);//@
                    this.mutationMenu.on(this.mutationMenu.EVENT_CATEGORY_CLICK, this.fctOnMutationSectionClick);//@
                    this.mutationMenu.on(this.mutationMenu.EVENT_NEXT, this.fctOnOkButtonClick);//@

                    this.addChild(this.mutationMenu);
                }

                if (this.SelectedCharacter != this.mutationMenu.Character)
                {
                    this.mutationMenu.Character = this.SelectedCharacter;
                }

                this.mutationMenu.rX = [{on: "default", x: x}];
                this.mutationMenu.rY = [{on: "default", y: y}];
                this.mutationMenu.layout(Display.getSize());

                if (!this.mutationMenu.parent)
                {
                    this.addChild(this.mutationMenu);
                }
            }
            else
            {
                if (this.mutationMenu && this.mutationMenu.parent)
                {
                    this.removeChild(this.mutationMenu);
                }

                if (!this.mutationCategoryMenu)
                {
                    this.mutationCategoryMenu = new CreationMutationCategoryContent().init({
                        "ui": this,
                        "creator": this,
                        "character": this.SelectedCharacter,
                        "type": this.mutationSection,
                        "rWidth": width,
                        "rHeight": height
                    });
                    this.mutationCategoryMenu.on(this.mutationCategoryMenu.EVENT_CATEGORY_CLICK, this.fctOnMutationClick);//@
                    this.mutationCategoryMenu.on(this.mutationCategoryMenu.EVENT_BACK, this.fctOnMenuBack);//@
                    this.mutationCategoryMenu.on(this.mutationCategoryMenu.EVENT_REMOVE, this.fctOnMutationRemove);//@

                    this.addChild(this.mutationCategoryMenu);
                }

                if (this.SelectedCharacter != this.mutationCategoryMenu.Character)
                {
                    this.mutationCategoryMenu.Character = this.SelectedCharacter;
                }

                if (this.mutationSection != this.mutationCategoryMenu.Type)
                {
                    this.mutationCategoryMenu.Type = this.mutationSection;
                }

                this.mutationCategoryMenu.rX = [{on: "default", x: x}];
                this.mutationCategoryMenu.rY = [{on: "default", y: y}];
                this.mutationCategoryMenu.layout(Display.getSize());

                if (!this.mutationCategoryMenu.parent)
                {
                    this.addChild(this.mutationCategoryMenu);
                }
            }
        }
        else
        {
            if (this.mutationMenu && this.mutationMenu.parent)
            {
                this.removeChild(this.mutationMenu);
            }
            if (this.mutationCategoryMenu && this.mutationCategoryMenu.parent)
            {
                this.removeChild(this.mutationCategoryMenu);
            }
        }
    }

    buildButton(bIsEnabled)
    {
        if (!this.buttons.ok)
        {
            this.buttons.ok = new TextButton().init({
                "ui": this,
                "text": this.ButtonLabel,
                "padding": {"horizontal": 50}
            });
            this.buttons.ok.on(this.buttons.ok.EVENT_CLICK, this.fctOnOkButtonClick);//@
            this.addChild(this.buttons.ok);
        }

        let button = this.buttons.ok;
        let size = button.getButtonSize();

        this.buttons.ok.IsDisabled = !bIsEnabled;

        let x = "((iw / 2) - " + size.width + " / 2)";
        let y = "(" + this.WindowY + " + " + this.WindowHeight + ")";
        y += " + (ih - " + y + ") / 2 - " + size.height + " / 2";

        button.rX = [{on: "default", x: x}];
        button.rY = [{on: "default", y: y}];
        button.layout(Display.getSize());
    }

    applyColorsToBuild(iIndex)
    {
        let sections = [
            this.SECTION_HAIR,
            this.SECTION_SKIN,
            this.SECTION_EYES,
            this.SECTION_BODY,
            this.SECTION_PANTS
        ];
        let build = this.CharacterManager.getCharacterBuild(this.SelectedCharacter);
        let buildSplit = build.split("_");
        let colors = buildSplit[0];

        let newColors = "";

        let colorIndex = iIndex;
        for (let i = 0; i < sections.length; i++)
        {
            colorIndex = colors.substring(i, i + 1);
            if (sections[i] == this.section)
            {
                colorIndex = iIndex;
            }
            newColors += colorIndex;
        }
        //We add this as there is 2 separate colors for pants
        newColors += colorIndex;

        this.CharacterManager.setCharacterBuild(this.SelectedCharacter, newColors + "_" + buildSplit[1]);
    }

    updateHair(iModifier)
    {
        let count = ColorTheme.HAIR.length;
        let build = this.CharacterManager.getCharacterBuild(this.SelectedCharacter);
        let buildSplit = build.split("_");
        let hairStyle = parseInt(buildSplit[1].substring(0, 1));

        hairStyle += iModifier;

        if (hairStyle < 1)
        {
            hairStyle = count - 1;
        }
        else if (hairStyle >= count)
        {
            hairStyle = 1;
        }

        let newBuild = buildSplit[0] + "_" + hairStyle + buildSplit[1].substring(1);
        this.CharacterManager.setCharacterBuild(this.SelectedCharacter, newBuild);
    }

    updateMutation(iIndex, strType)
    {
        let types = [this.ItemManager.ITEM_CODES.TYPE_MUTATION_HEAD, this.ItemManager.ITEM_CODES.TYPE_MUTATION_ARM, this.ItemManager.ITEM_CODES.TYPE_MUTATION_LEG];
        let build = this.CharacterManager.getCharacterBuild(this.SelectedCharacter);
        let buildSplit = build.split("_");

        let newMutations = buildSplit[1].substring(0, 1);
        let mutation = 0;

        for (let i = 0; i < types.length; i++)
        {
            mutation = buildSplit[1].substring(i + 1, i + 2);
            if (strType == types[i])
            {
                mutation = iIndex;
            }
            newMutations += mutation;
        }

        let newBuild = buildSplit[0] + "_" + newMutations;
        this.CharacterManager.setCharacterBuild(this.SelectedCharacter, newBuild);
    }

    /*******************************************
    *   LABELS
    *******************************************/
    getSectionLabel(strSection)
    {
        let label = null;

        if (strSection == this.SECTION_SKIN)
        {
            label = this.LabelManager.translate("Crea_Peau");
        }
        else if (strSection == this.SECTION_EYES)
        {
            label = this.LabelManager.translate("Crea_Yeux");
        }
        else if (strSection == this.SECTION_HAIR)
        {
            label = this.LabelManager.translate("Crea_Cheveux");
        }
        else if (strSection == this.SECTION_BODY)
        {
            label = this.LabelManager.translate("Crea_Chadail");
        }
        else if (strSection == this.SECTION_PANTS)
        {
            label = this.LabelManager.translate("Crea_Pantalons");
        }

        return label;
    }

    getSectionDescriptionLabel(strSection)
    {
        let label = null;

        if (strSection == this.SECTION_SKIN)
        {
            label = this.LabelManager.translate("Crea_Couleur_Peau");
        }
        else if (strSection == this.SECTION_EYES)
        {
            label = this.LabelManager.translate("Crea_Couleur_Yeux");
        }
        else if (strSection == this.SECTION_HAIR)
        {
            label = this.LabelManager.translate("Crea_Couleur_Cheveux");
        }
        else if (strSection == this.SECTION_BODY)
        {
            label = this.LabelManager.translate("Crea_Couleur_Chandail");
        }
        else if (strSection == this.SECTION_PANTS)
        {
            label = this.LabelManager.translate("Crea_Couleur_Pantalons");
        }

        return label;
    }

    getMutationTypeTitle(strType)
    {
        let label = null;

        if (strType == this.ItemManager.ITEM_CODES.TYPE_MUTATION_HEAD)
        {
            label = this.LabelManager.translate("Crea_Categorie_Tetes");
        }
        else if (strType == this.ItemManager.ITEM_CODES.TYPE_MUTATION_ARM)
        {
            label = this.LabelManager.translate("Crea_Categorie_Bras");
        }
        else if (strType == this.ItemManager.ITEM_CODES.TYPE_MUTATION_LEG)
        {
            label = this.LabelManager.translate("Crea_Categorie_Jambes");
        }

        return label;
    }

    getMutationTypeDescription(strType)
    {
        let label = null;

        if (strType == this.ItemManager.ITEM_CODES.TYPE_MUTATION_HEAD)
        {
            label = this.LabelManager.translate("Crea_Categorie_Tetes");
        }
        else if (strType == this.ItemManager.ITEM_CODES.TYPE_MUTATION_ARM)
        {
            label = this.LabelManager.translate("Crea_Categorie_Bras");
        }
        else if (strType == this.ItemManager.ITEM_CODES.TYPE_MUTATION_LEG)
        {
            label = this.LabelManager.translate("Crea_Categorie_Jambes");
        }

        return label;
    }

    /*******************************************
    *   POPUPS
    *******************************************/
    showChoicePopup(strTitle, strDescription, fctPositiveCallback, fctNegativeCallback, bShowCloseButton = true, fctCloseCallback = null)
    {
        if (!this.popups.choice)
        {
            this.popups.choice = new ChoicePopup();

            this.popups.choice.on(this.popups.choice.EVENT_POPUP_SHOW, this.fctOnPopupShow);
            this.popups.choice.on(this.popups.choice.EVENT_POPUP_HIDE, this.fctOnPopupHide);

            if (fctCloseCallback)
            {
                this.popups.choice.once(this.popups.choice.EVENT_POPUP_HIDE, fctCloseCallback);
            }
            
            this.popups.choice.once(this.popups.choice.EVENT_POSITIVE, fctPositiveCallback);
            this.popups.choice.once(this.popups.choice.EVENT_NEGATIVE, fctNegativeCallback);

            this.popups.choice.init({
                "ui": this,
                "title": strTitle,
                "description": strDescription,
                "showClose": bShowCloseButton
            });
        }
        else
        {
            if (fctCloseCallback)
            {
                this.popups.choice.once(this.popups.choice.EVENT_POPUP_HIDE, fctCloseCallback);
            }
            
            this.popups.choice.once(this.popups.choice.EVENT_POSITIVE, fctPositiveCallback);
            this.popups.choice.once(this.popups.choice.EVENT_NEGATIVE, fctNegativeCallback);

            this.popups.choice.show();
        }
    }

    closeChoicePopup()
    {
        if (this.popups.choice)
        {
            this.popups.choice.hide();
        }
    }

    /*******************************************
    *   UTILITIES
    *******************************************/
    showSceneTransition(fctCallbackMiddle = null, fctCallback = null)
    {
        if (!this.screenTransition)
        {
            this.screenTransition = new ScreenTransition().init({"ui": this});
        }

        this.addChild(this.screenTransition);

        this.screenTransition.startTransition(
            fctCallbackMiddle, 
            function(fctCallback) 
            { 
                if (fctCallback)
                {
                    fctCallback();
                }

                this.removeChild(this.screenTransition);
                this.screenTransition.destroy({"children": true});
                delete this.screenTransition;

            }.bind(this, fctCallback)
        );
    }

    /*******************************************
    *   ACTIONS
    *******************************************/
    endCreator()
    {
        this.showSceneTransition(() => 
        {
            this.closeChoicePopup();

            this.SaveManager.setFromSave(this.SaveManager.newGameKey, true);
            this.SaveManager.save();

            let builds = {};
            for (let i = 0; i < 3; i++)
            {
                builds[i] = this.CharacterManager.getCharacterBuild(i);
                this.ItemManager.equipment[i].calculateMutations();

                if (gtag)
                {
                    gtag('event', 'character', {
                        'event_category': "created",
                        'event_label': builds[i],

                    });
                }
            }

            let creator = this.AnimationsCreator;
            this.UIManager.showCharacterLoadingUI(true);
            creator.generateMultiple(builds);
        });
    }

    /*******************************************
    *   EVENTS
    *******************************************/
    onResize(iNewWidth, iNewHeight, iLeftPadding)
    {
        this.build();
    }

    onCharacterClick(iCharacter)
    {
        this.section = null;
        this.mutationSection = null;
        this.SelectedCharacter = iCharacter;
    }

    onSectionClick(sender, strSection)
    {
        this.section = strSection;
        this.build();
    }

    onSectionBack(sender)
    {
        this.section = null;
        this.build();
    }

    onMenuBack(sender)
    {
        if (!this.displayMutation)
        {
            if (this.SelectedCharacter > 0)
            {
                this.SelectedCharacter--;
            }
        }
        else
        {
            if (!this.mutationSection)
            {
                this.selectedCharacter--;

                if (this.selectedCharacter < 0)
                {
                    this.selectedCharacter = 2;
                    this.displayMutation = false;
                }
            }
            else
            {
                this.mutationSection = null;
            }
            this.build();
        }
    }

    onSectionColorPick(iIndex)
    {
        this.applyColorsToBuild(iIndex);
        this.build();
    }

    onOkButtonClick(sender)
    {
        if (this.SelectedCharacter < 2)
        {
            this.SelectedCharacter++;
            this.mutationSection = null;
        }
        else if (!this.displayMutation)
        {
            this.selectedCharacter = 0;
            this.displayMutation = true;
        }
        else
        {
            let strTitle = "Jouer";
            let strDescription = this.LabelManager.translate("Crea_Mutation_Confirm");
            this.showChoicePopup(strTitle, strDescription, 
                () => { this.endCreator(); },
                () => { this.closeChoicePopup() },
                true
            )
        }

        this.build();
    }

    onHairArrowClick(bIsLeft, sender)
    {
        if (bIsLeft)
        {
            this.updateHair(-1);
        }
        else
        {
            this.updateHair(1);
        }
        this.build();
    }

    onMutationSectionClick(sender, strMutationType)
    {
        this.mutationSection = strMutationType;
        this.build();
    }

    onMutationRemove(sender, strMutationType)
    {
        this.updateMutation(0, strMutationType);
        this.build();
    }

    onMutationClick(sender, iMutation)
    {
        this.updateMutation(iMutation + 1, this.mutationSection);
        this.mutationSection = null;
        this.build();
    }
}