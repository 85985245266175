export default class BaseParser
{
    constructor()
    {
        
    }

    get TYPE_BARN() { return "barn"; }
    get TYPE_ENEMY() { return "enemy"; }
    get TYPE_ITEM() { return "item"; }
    get TYPE_LANDMARK() { return "landmark"; }
    get TYPE_OTHER() { return "other"; }
    get TYPE_SPAWN() { return "spawn"; }

    //---------------------------------------------------------
    //  DEPENDENCIES
    //---------------------------------------------------------
    get ItemManager() { return this.dependencies.get("ItemManager"); }
    get WorldManager() { return this.dependencies.get("WorldManager"); }
    //---------------------------------------------------------

    /*******************************************
    *   INITIALIZATION
    *******************************************/
    /**
        Parameters to pass to the init function:
        - dependencies  DependencyContainer instance

    */
    init(meta)
    {
        this.dependencies = meta.dependencies;
        return this;
    }


    /*******************************************
    *   CALCULATIONS
    *******************************************/
    /**
        Retrives the content that should be spawned at a specific tile
        @param iX   X position of the tile (in grid coordinates)
        @param iY   Y position of the tile (in grid coordinates)
        @param type (Optional) Type of content that should be retrieved. If set, it converts the data in the right 
                    object instance. Otherwise it returns the raw data. The value to use should be the constants
                    defined in this class (TYPE_ITEM and TYPE_ENEMY) Default is NULL
        @return     Data found or returns NULL if nothing is on the tile
    */
    getAt(iX, iY, type = null)
    {
        console.warn("The getAt method of the BaseParser should be overriden by its children");
    }

    /**
        Retrives the content that should be spawned at an area of tiles
        @param iXMin    X position of the top left corner of the area to search in (in grid coordinates)
        @param iYMin    Y position of the top left corner of the area to search in (in grid coordinates)
        @param iXMax    X position of the bottom right corner of the area to search in (in grid coordinates)
        @param iYMax    Y position of the bottom right corner of the area to search in (in grid coordinates)
        @return         Array containing the data found
    */
    getInArea(iXMin, iYMin, iXMax, iYMax)
    {
        let dataFound = {};

        for (let x = iXMin; x <= iXMax; x++)
        {
            for (let y = iYMin; y <= iYMax; y++)
            {
                let data = this.getAt(x, y);
                if (data)
                {
                    if (!dataFound[x])
                    {
                        dataFound[x] = {};
                    }
                    dataFound[x][y] = data;
                }
            }
        }
        return dataFound;
    }

    /**
        Retrives the content that should be spawned at an area of tiles
        @param iXMin    X position of the top left corner of the area to search in (in grid coordinates)
        @param iYMin    Y position of the top left corner of the area to search in (in grid coordinates)
        @param iXMax    X position of the bottom right corner of the area to search in (in grid coordinates)
        @param iYMax    Y position of the bottom right corner of the area to search in (in grid coordinates)
        @return         Array containing the data found
    */
    getObjectsInArea(iXMin, iYMin, iXMax, iYMax)
    {
        let data = this.getInArea(iXMin, iYMin, iXMax, iYMax);

        let result = {};
        for (let x in data)
        {
            for (let y in data[x])
            {
                if (data[x][y].type == this.TYPE_ITEM)
                {
                    if (!result[x])
                    {
                        result[x] = {};
                    }
                    result[x][y] = this.ItemManager.getItem(data[x][y].id);
                }
            }
        }

        return result;
    }

    /**
        Retrives the content that should be spawned at an area of tiles
        @param iXMin    X position of the top left corner of the area to search in (in grid coordinates)
        @param iYMin    Y position of the top left corner of the area to search in (in grid coordinates)
        @param iXMax    X position of the bottom right corner of the area to search in (in grid coordinates)
        @param iYMax    Y position of the bottom right corner of the area to search in (in grid coordinates)
        @return         Array containing the data found
    */
    getEnemiesInArea(iXMin, iYMin, iXMax, iYMax)
    {
        let data = this.getInArea(iXMin, iYMin, iXMax, iYMax);

        let result = {};
        for (let x in data)
        {
            for (let y in data[x])
            {
                if (data[x][y].type == this.TYPE_ENEMY)
                {
                    if (!result[x])
                    {
                        result[x] = {};
                    }
                    result[x][y] = {
                        "id":   data[x][y].id,
                        "data": data[x][y].data
                    };
                }
            }
        }

        return result;
    }

    /**
        Gets the closest distance of an object from a specific point on the grid
        @param iX           X position of the start point to start searching
        @param iY           Y position of the start point to start searching
        @param strObjectId  Id of the object to look for

        @return             Distance of the closest object
    */
    getClosestObjectDistance(iX, iY, strObjectId)
    {
        
    }

    /*******************************************
    *   DATA PARSING
    *******************************************/
    calculateImportantLocations(arrAllItems)
    {

    }

    getCoordsByType (type)
    {
        return [];
    }
}