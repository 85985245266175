export default class Responsive
{
    constructor ()
    {
        
    }

    //---------------------------------------------------------
    //  DEPENDENCIES
    //---------------------------------------------------------
    get ResponsiveManager() { return this.ui.ResponsiveManager; }
    //---------------------------------------------------------

    /*******************************************
    *   INITIALIZATION
    *******************************************/
    /**
        Parameters to pass to the init function:
        - ui:  UI section object where this component resides
    */
    init(meta)
    {
        this.ui = meta.ui;

        this.strOrder = "w-h-sx-sy-x-y-ax-ay-cl";
        this.arrResponsiveX = [];
        this.arrResponsiveY = [];
        this.arrResponsiveWidth = [];
        this.arrResponsiveHeight = [];
        this.arrResponsiveVisible = [];
        this.arrResponsiveAlpha = [];
        this.arrResponsiveAnchorX = [];
        this.arrResponsiveAnchorY = [];
        this.arrResponsiveScaleX = [];
        this.arrResponsiveScaleY = [];
        this.arrResponsiveOrder = [];
        this.arrResponsiveClosure = [];

        return this;
    }

    get innerWidth ()
    {
        return this.ResponsiveManager.Width;
    }

    get innerHeight ()
    {
        return this.ResponsiveManager.Height;
    }

    get screenPadding ()
    {
        return this.ResponsiveManager.ScreenPadding;
    }

    normalizeBehaviors (arrBehaviors)
    {
        //we only keep behavior, that have a on property
        //we than map the actual value
        //then sort them from "smaller" to "larger"
        let sortedBehaviors = arrBehaviors
            .filter( b => b.on !== undefined)
            .map( b => this.mapBehavior(b))
            .sort( (a,b) => this.sortBehavior(a,b));

        return sortedBehaviors;
    }

    getBehavior (arrBehaviors)
    {
        let size = this.ResponsiveManager.size;

        //we get the behavior that has the "default" on property
        let defaultBehavior = arrBehaviors.find( b => b.on === undefined || b.on === "default");

        let currentBehavior = arrBehaviors
            .filter( b => b.on !== undefined && b.on !== "default")
            .find( b => this.findBehavior(b, size) );



        return currentBehavior !== undefined ? currentBehavior : defaultBehavior;
    }

    findBehavior (b, size)
    {
        if (b.ratio !== undefined)
        {
            return b.ratio >= size.ratio;
        }

        if (b.width !== undefined)
        {
            return b.width >= size.width;
        }


        return false;
    }

    //replace string with actual value
    //for example:   max-width:200px will become width:200 and aspect-ratio:(16/9) will become  ratio:16/9
    mapBehavior (b)
    {
        let strOn = b.on;


        if (strOn.match(/max-width/))
        {
            b.width = parseInt(strOn.replace(/max-width\s?:\s?([0-9]+)\s?px/, (a,b,c) => b));
        }

        if (strOn.match(/aspect-ratio/))
        {
            b.ratio = strOn.replace(/aspect-ratio\s?:\s?([0-9\.\\\(\)]+)/, (a,b,c) => b)
            b.ratio = eval(b.ratio);
        }

        return b;
    }


    sortBehavior (a,b)
    {

        if (a.ratio !== undefined && b.ratio !== undefined && a.ratio !== "default" && b.ratio !== "default")
        {
            return a.ratio - b.ratio;
        }

        if (a.max !== undefined && b.max !== undefined)
        {
           return a.max - b.max;
        }

        return 0;
    }

    evaluateFromObject (obj, prop)
    {
        let sprite = obj.sprite;
        return this.evaluate(obj.behavior[prop], sprite.width, sprite.height, sprite.x, sprite.y, sprite, obj.el);

    }

    evaluate (expr, width, height, x, y, self, el)
    {
        //console.log("expr", expr);
        if (typeof(expr) !== "string")
        {
          //  console.log(expr);
            expr = expr.toString();
        }

        let ratio = this.innerWidth / this.innerHeight;

        expr = expr.replace(/([0-9\.]+)%/g, (a,b,c) =>  (Math.round(b * 10000) / 10000) / 100 );
        expr = expr.replace(/([0-9\.]+)px/g, (a,b,c) => b );
        expr = expr.replace(/iw/g, this.innerWidth );
        expr = expr.replace(/ih/g, this.innerHeight );
        // console.log(expr);
        // console.log("eval", eval(expr));
        // console.log("----");
        let value =  eval(expr)

        //console.log("eval", expr, "=>", value);

        return value;
    }

    applyClosure (sprite)
    {
        let behavior = this.getBehavior(this.arrResponsiveClosure);
        return behavior.closure ( {width:sprite.width, height: sprite.height, x: sprite.x, y:sprite.y, sprite, el: behavior.el, behavior, responsive: this} );
    }

    getOrder (sprite)
    {
        let behavior = this.getBehavior(this.arrResponsiveOrder);

        return behavior !== undefined ? behavior.order : this.strOrder;
    }

    getX (sprite)
    {
        let behavior = this.getBehavior(this.arrResponsiveX);
        return this.evaluate(behavior.x, sprite.width, sprite.height, sprite.x, sprite.y, sprite, behavior.el);
    }

    getY(sprite)
    {
        let behavior = this.getBehavior(this.arrResponsiveY);

        return this.evaluate(behavior.y, sprite.width, sprite.height, sprite.x, sprite.y, sprite, behavior.el);
    }

    getWidth(sprite)
    {
        let behavior = this.getBehavior(this.arrResponsiveWidth);
        return this.evaluate(behavior.width, sprite.width, sprite.height, sprite.x, sprite.y, sprite, behavior.el);
    }

    getHeight(sprite)
    {
        let behavior = this.getBehavior(this.arrResponsiveHeight);

        //console.log("getHeight", behavior, this.arrResponsiveHeight);
        return this.evaluate(behavior.height, sprite.width, sprite.height, sprite.x, sprite.y, sprite, behavior.el);
    }

    getAnchorX(sprite)
    {
        let behavior = this.getBehavior(this.arrResponsiveAnchorX);
        return this.evaluate(behavior.anchor, sprite.width, sprite.height, sprite.x, sprite.y, sprite, behavior.el);
    }

    getAnchorY(sprite)
    {
        let behavior = this.getBehavior(this.arrResponsiveAnchorY);
        return this.evaluate(behavior.anchor, sprite.width, sprite.height, sprite.x, sprite.y, sprite, behavior.e, behavior.closure);
    }

   getScaleX(sprite)
    {
        let behavior = this.getBehavior(this.arrResponsiveScaleX);
        return this.evaluate(behavior.scale, sprite.width, sprite.height, sprite.x, sprite.y, sprite, behavior.el);
    }

    getScaleY(sprite)
    {
        let behavior = this.getBehavior(this.arrResponsiveScaleY);
        return this.evaluate(behavior.scale, sprite.width, sprite.height, sprite.x, sprite.y, sprite, behavior.el);
    }

    setResponsiveOrder (responsives, strOn = "")
    {
        if (strOn === "")
        {
            this.arrResponsiveOrder = this.normalizeBehaviors(responsives);
        }
    }

    setResponsiveX (responsives, strOn = "")
    {
        if (strOn === "")
        {
            this.arrResponsiveX = this.normalizeBehaviors(responsives);
        }
    }



    setResponsiveY (responsives, strOn = "")
    {
        if (strOn === "")
        {
            this.arrResponsiveY = this.normalizeBehaviors(responsives);
        }
    }

    setResponsiveWidth (responsives, strOn = "")
    {
        if (strOn === "")
        {
            this.arrResponsiveWidth = this.normalizeBehaviors(responsives);
        }
    }

    setResponsiveHeight (responsives, strOn = "")
    {
        if (strOn === "")
        {
            this.arrResponsiveHeight = this.normalizeBehaviors(responsives);
        }
    }

    setResponsiveAnchorX (responsives, strOn = "")
    {
        if (strOn === "")
        {
            this.arrResponsiveAnchorX = this.normalizeBehaviors(responsives);
        }
    }


    setResponsiveAnchorY (responsives, strOn = "")
    {
        if (strOn === "")
        {
            this.arrResponsiveAnchorY = this.normalizeBehaviors(responsives);
        }
    }


    setResponsiveScaleX (responsives, strOn = "")
    {
        if (strOn === "")
        {
            this.arrResponsiveScaleX = this.normalizeBehaviors(responsives);
        }
    }


    setResponsiveScaleY (responsives, strOn = "")
    {
        if (strOn === "")
        {
            this.arrResponsiveScaleY = this.normalizeBehaviors(responsives);
        }
    }

    setResponsiveClosure (responsives, strOn = "")
    {
        if (strOn === "")
        {
            this.arrResponsiveClosure = this.normalizeBehaviors(responsives);
        }
    }



    placeLayout (sprite)
    {
        if (sprite === null)
        {
            return;
        }

        let strOrder =  this.getOrder(sprite);

        let arrOrder = strOrder.split("-");

        for (let i = 0; i < arrOrder.length; i++)
        {
            let strCurrent = arrOrder[i];

            if (strCurrent === "w")
            {
                if (this.arrResponsiveWidth.length > 0)
                {
                    sprite.width = Math.round(this.getWidth(sprite));
                    //console.log("width", sprite.width);
                }
            }
            else if (strCurrent === "h")
            {
                if (this.arrResponsiveHeight.length > 0)
                {
                    sprite.height = Math.round(this.getHeight(sprite));
                    //console.log("height", sprite.height);
                }
            }
            else if (strCurrent === "x")
            {
                if (this.arrResponsiveX.length > 0)
                {
                    sprite.x = Math.round(this.getX(sprite));
                    //console.log("x", sprite.x);
                }
            }
            else if (strCurrent === "y")
            {
                if (this.arrResponsiveY.length > 0)
                {
                    sprite.y = Math.round(this.getY(sprite));
                }

            }
            else if (strCurrent === "ax")
            {
                if (this.arrResponsiveAnchorX.length > 0)
                {
                    sprite.anchor.x = this.getAnchorX(sprite);
                }
            }
            else if (strCurrent === "ay")
            {
                if (this.arrResponsiveAnchorY.length > 0)
                {
                    sprite.anchor.y = this.getAnchorY(sprite);
                }
            }
            else if (strCurrent === "sx")
            {
                if (this.arrResponsiveScaleX.length > 0)
                {
                    sprite.scale.x = this.getScaleX(sprite);
                }
            }
            else if (strCurrent === "sy")
            {
                if (this.arrResponsiveScaleY.length > 0)
                {
                    sprite.scale.y = this.getScaleY(sprite);
                }
            }
            else if (strCurrent === "cl")
            {
                if (this.arrResponsiveClosure.length > 0)
                {
                    this.applyClosure(sprite);
                }
            }

        }




    }

    destroy ()
    {
        //@TODO clean this up bro!
    }
}