import BaseCheat from "./BaseCheat.js";

export default class Audio extends BaseCheat
{
    constructor(dependencies)
    {
        super(dependencies);
    }

    get CommandUse() { return "audio dialog|sfx|music audio_id"; }
    get CommandDesc() { return "Play an audio"; }

    //---------------------------------------------------------
    //  DEPENDENCIES
    //---------------------------------------------------------
    get AudioManager() { return this.dependencies.get("AudioManager"); }
    //---------------------------------------------------------

    parseCommand(strCmd, arrArguments)
    {
        if (strCmd == "audio")
        {
            let [type, audio_id] = arrArguments;

            if (type === "dialog")
            {
                this.AudioManager.playDialog(audio_id)
            }
            else if (type === "sfx")
            {
                this.AudioManager.playSfx(audio_id);
            }
            else if (type === "music")
            {
                this.AudioManager.playBgm(audio_id);
            }

            return true;

        }
        return false;
    }
}