import ItemManager from "./ItemManager.js";

export default class CharacterInventory
{
    constructor(objInventorySettings)
    {
        this.settings = objInventorySettings;

        this.backpackSaveKey = "inventory_backback";
        this.storageSaveKey = "inventory_storage";
    }

    get BackpackLevel() { return ItemManager.instance.SaveManager.getFromSave("backpack_level", 0); }
    set BackpackLevel(iNewValue) { return ItemManager.instance.SaveManager.setFromSave("backpack_level", iNewValue); }

    get InventorySlotCount() { return this.settings.backpack[this.BackpackLevel].slots; }

    getPageIndexFromType(strType)
    {
        if (strType in ItemManager.instance.InventorySettings.storage.pageIndexes)
        {
            return ItemManager.instance.InventorySettings.storage.pageIndexes[strType];
        }
        return 0;
    }

    createItemData (objItem, iQuantity, iSlotIndex)
    {
        if (objItem && (typeof objItem === 'string' || objItem instanceof String))
        {
            objItem = ItemManager.instance.getItem(objItem);
        }
        return {"item": objItem, "quantity": iQuantity, "slot": iSlotIndex};
    }

    getSaveLocation (bInBackpack, iCharacter)
    {
        let location = this.storageSaveKey;
        if (bInBackpack)
        {
            location = this.backpackSaveKey + (iCharacter < 0 ? ItemManager.instance.CharacterManager.CurrentCharacter : iCharacter);
        }
        return location;
    }

    getInventoryFromSave (bInBackpack, iCharacter)
    {
        return ItemManager.instance.SaveManager.getFromSave(this.getSaveLocation(bInBackpack, iCharacter), {});
    }

    setInventoryFromSave (objInventory, bInBackpack, iCharacter)
    {
        return ItemManager.instance.SaveManager.setFromSave(this.getSaveLocation(bInBackpack, iCharacter), objInventory);
    }

    /**
        Gets the max amount of a stack for an item in the inventory
        
        @param objItem      Item object to find it's max stack size
        @param bInBackpack  (Optional) If the max stack is calculated on the backpack or in the storage. Default is backpack

        @return             Max stackable amount for the item
    */
    getMaxStackAmount (objItem, bInBackpack = true)
    {
        let stackDef = ItemManager.instance.InventorySettings.maxStack[(bInBackpack ? "backpack" : "storage")];
        let maxStack = 20;
        if (objItem.Type in stackDef)
        {
            maxStack = stackDef[objItem.Type];
        }
        else
        {
            console.warn("Defaulted the max stack value for " + objItem.Id + " to 20 per stack");
        }
        return maxStack;
    }

    /**
        Gets the item located at an inventory's slot

        @param iSlotIndex   The index of the slot to look at. The inventory's slot indexes are the same as array indexes
        @param bInBackpack  (Optional) If the inventory it should look into is the current character's backpack or the general storage. Default is backpack
        @param iCharacter   (Optional) Only used if bInBackpack is TRUE. It specifies which character inventory should be used. If less than 0 then the
                                current character's inventory is used

        @return              If an item is on the slot, an object with the item/quantity/slot will be returned. Otherwise the item property is NULL.
    */
    getItemAtSlot (iSlotIndex, bInBackpack = true, iCharacter = -1)
    {
        let item = null;
        let quantity = 0;

        if (iSlotIndex >= 0 && (!bInBackpack || iSlotIndex < this.InventorySlotCount))
        {
            let inventory = this.getInventoryFromSave(bInBackpack, iCharacter);

            if (inventory[iSlotIndex])
            {
                item = ItemManager.instance.getItem(inventory[iSlotIndex].id);
                quantity = parseInt(inventory[iSlotIndex].qty);
            }
        }

        return this.createItemData(item, quantity, iSlotIndex);
    }

    /**
        Gets all the items stored in the inventory

        @param bInBackpack  (Optional) If the inventory it should look into is the current character's backpack or the general storage. Default is backpack
        @param iCharacter   (Optional) Only used if bInBackpack is TRUE. It specifies which character inventory should be used. If less than 0 then the
                                current character's inventory is used

        @return              An object containing the definition of every object contained in the inventory.
    */
    getAllItems (bInBackpack = true, iCharacter = -1)
    {
        let inventory = this.getInventoryFromSave(bInBackpack, iCharacter);

        let items = {};
        for (var key in inventory)
        {
            if (inventory[key] && inventory[key].qty > 0 && (!bInBackpack || key < this.InventorySlotCount))
            {
                items[key] = this.createItemData(inventory[key].id, inventory[key].qty, key);
            }
        }

        return items;
    }

    /**
        Scans the inventory and checks if an item is stored there
        
        @param objItem      Item object to look for
        @param bInBackpack  (Optional) If the inventory it should look into is the current character's backpack or the general storage. Default is backpack
        @param iCharacter   (Optional) Only used if bInBackpack is TRUE. It specifies which character inventory should be used. If less than 0 then the
                                current character's inventory is used

        @return              An object containing the item, its quantity and its slot position
    */
    getItemFromInventory(objItem, bInBackpack = true, iCharacter = -1)
    {
        let inventory = this.getInventoryFromSave(bInBackpack, iCharacter);
        let result = this.createItemData(null, 0, -1);

        for (var key in inventory)
        {
            let item = null;
            let quantity = null;

            if (inventory[key] && inventory[key].qty > 0 && inventory[key].id == objItem.Id && (!bInBackpack || key < this.InventorySlotCount))
            {
                result = this.createItemData(inventory[key].id, inventory[key].qty, key);
                break;
            }
        }

        return result;
    }

    /**
        Scans the inventory and counts the total number of items the player possesses
        
        @param objItem              Item object to look for
        @param bAllowStorageCheck   (Optional) If the algorithm should also count items in the storage or not
        @param iCharacter           (Optional) Specifies which character inventory should be used to check for the item. If less than 0 then the
                                        current character's inventory is used
        @param bCheckAllBackpacks   (Optional) If all backpacks of all characters should be looked into

        @return                     Amount of item the player possesses

    */
    getItemQuantity (objItem, bAllowStorageCheck = false, iCharacter = -1, bCheckAllBackpacks = false)
    {
        let amount = 0;
        let backpacks = [];

        if (bCheckAllBackpacks)
        {
            for (let i = 0; i < 3; i++)
            {
                backpacks.push(this.getInventoryFromSave(true, i));
            }
        }
        else
        {
            backpacks.push(this.getInventoryFromSave(true, iCharacter));
        }

        for (let i = 0; i < backpacks.length; i++)
        {
            let backpack = backpacks[i];
            
            for (var key in backpack)
            {
                if (backpack[key] && backpack[key].id == objItem.Id)
                {
                    amount += backpack[key].qty;
                }
            }
        }

        if (bAllowStorageCheck)
        {
            let storage = this.getInventoryFromSave(false);

            for (var key in storage)
            {
                if (storage[key] && storage[key].id == objItem.Id)
                {
                    amount += storage[key].qty;
                }
            }
        }

        return amount;
    }

    /**
        Removes an item from an inventory's slot

        @param quantity     Amount for this item to remove
        @param iSlotIndex   At which slot the quantity should be removed
        @param bInBackpack  (Optional) If the inventory this item should be removed is in the current character's backpack or the general storage. Default is backpack
        @param iCharacter   (Optional) Only used if bInBackpack is TRUE. It specifies which character inventory the item should be removed from. If less than 0 then the
                                current character's inventory is used

        @return              A boolean indicating if the operation was a success or not
    */
    removeItemFromSlot (quantity, iSlotIndex, bInBackpack = true, iCharacter = -1)
    {
        quantity = parseInt(quantity);
        let inventory = this.getInventoryFromSave(bInBackpack, iCharacter);
        let result = true;

        if (inventory[iSlotIndex] && inventory[iSlotIndex].qty >= quantity)
        {
            inventory[iSlotIndex].qty -= quantity;
            if (inventory[iSlotIndex].qty == 0)
            {
                inventory[iSlotIndex] = null;
            }
        }
        else
        {
            inventory[iSlotIndex] = null;
            console.warn("Trying to remove the item with far too great quantity for the slot " + iSlotIndex);
            result = false;
        }

        this.setInventoryFromSave(inventory, bInBackpack, iCharacter);

        return result;
    }

    /**
        Adds an item into the inventory

        @param objItem      Item object to add in the inventory
        @param quantity     Amount for this item to add
        @param iSlotIndex   (Optional) At which slot the item should be added. If less than 0, will try to auto place the item in the inventory
        @param bInBackpack  (Optional) If the inventory this item should go is in the current character's backpack or the general storage. Default is backpack.
        @param iCharacter   (Optional) Only used if bInBackpack is TRUE. It specifies which character inventory this should be put. If less than 0 then the
                                current character's inventory is used.
        @param bNotify      (Optional) If the addItem action should be notified through the Event system. Default is yes

        @return             An object describing the result of the operation :
                                "status": boolean describing if the opration was a success or not
                                "inventory": the new state of the inventory
                                "args": List of values describing why the operation is as is it:
                                    - wrongItemOnSlot: The given item was asked to be stored on the same slot of another type of item
                                    - leftOver: If the quantity was too big for the current stacks and there are no other available slots to start a new stack
                                    - autoStacked: SlotIndexes/quantities of the item that was autostacked
    */
    addItem (objItem, quantity, iSlotIndex = -1, bInBackpack = true, iCharacter = -1, bNotify = true)
    {
        ItemManager.instance.setDiscoveredItem(objItem.Id);

        let inventory = this.getInventoryFromSave(bInBackpack, iCharacter);
        let maxStack = this.getMaxStackAmount(objItem, bInBackpack);
        let quantityToAutoPlace = parseInt(quantity);
        let result = {"status": true, "args": {}};
        let where = {index: iSlotIndex, "inventory": bInBackpack ? "backpack" : "closet"};
        let autoStacked = [];

        if (iSlotIndex >= 0)
        {
            let bCanPlaceOnSlot = true;
            if (!bInBackpack)
            {
                let pageIndex = this.getPageIndexFromType(objItem.InventoryPageType);
                let slotsPerPage = ItemManager.instance.StorageSlotCountPerType;
                let index = iSlotIndex - slotsPerPage * pageIndex;

                bCanPlaceOnSlot = index >= 0 && index < slotsPerPage;

                for(let key in inventory)
                {
                    if (inventory[key] && inventory[key].id && inventory[key].id == objItem.Id && key != iSlotIndex)
                    {
                        bCanPlaceOnSlot = false;
                        break;
                    }
                }
            }
            if (bCanPlaceOnSlot)
            {
                if (!inventory[iSlotIndex] || !inventory[iSlotIndex].id || inventory[iSlotIndex].id == objItem.Id)
                {
                    if (!inventory[iSlotIndex] || !inventory[iSlotIndex].id)
                    {
                        inventory[iSlotIndex] = {"id" : objItem.Id, "qty": 0};
                    }
                    inventory[iSlotIndex].qty += quantity;
                    if (inventory[iSlotIndex].qty > maxStack)
                    {
                        quantityToAutoPlace = inventory[iSlotIndex].qty - maxStack;
                        inventory[iSlotIndex].qty = maxStack;
                    }
                    else
                    {
                        quantityToAutoPlace = 0;
                    }
                }
                else
                {
                    result.status = false;
                    result.args.wrongItemOnSlot = true;
                }
            }
        }

        if (quantityToAutoPlace > 0)
        {
            let slotsPerPage = ItemManager.instance.StorageSlotCountPerType;
            let pageIndex = this.getPageIndexFromType(objItem.InventoryPageType);
            for (var key in inventory)
            {
                let index = key - (slotsPerPage * pageIndex);
                if (bInBackpack || (index >= 0 && index < slotsPerPage))
                {
                    if (inventory[key] && inventory[key].id == objItem.Id && inventory[key].qty < maxStack)
                    {
                        let oldQty = quantityToAutoPlace;

                        where.index = parseInt(key);
                        inventory[key].qty += quantityToAutoPlace;
                        quantityToAutoPlace = 0;
                        if (inventory[key].qty > maxStack)
                        {
                            quantityToAutoPlace = inventory[key].qty - maxStack;
                            inventory[key].qty = maxStack;
                        }

                        autoStacked.push({"slot": key, "quantity": (oldQty - quantityToAutoPlace), "item": objItem});
                    }
                }
            }

            if (quantityToAutoPlace > 0)
            {
                let pageIndex = 0;
                let slotsPerPage = this.InventorySlotCount;
                if (!bInBackpack)
                {
                    slotsPerPage = ItemManager.instance.StorageSlotCountPerType;
                    pageIndex = this.getPageIndexFromType(objItem.InventoryPageType);
                }
                for (var i = pageIndex * slotsPerPage; i < (slotsPerPage * (pageIndex + 1)) && quantityToAutoPlace > 0; i++)
                {
                    if (!inventory[i])
                    {
                        let oldQty = quantityToAutoPlace;

                        inventory[i] = {"id": objItem.Id, "qty": quantityToAutoPlace};
                        quantityToAutoPlace = 0;
                        if (inventory[i].qty > maxStack)
                        {
                            quantityToAutoPlace = inventory[i].qty - maxStack;
                            inventory[i].qty = maxStack;
                        }

                        autoStacked.push({"slot": i, "quantity": (oldQty - quantityToAutoPlace), "item": objItem});
                    }
                }

                if (quantityToAutoPlace > 0)
                {
                    result.status = false;
                    result.args.leftOver = quantityToAutoPlace;
                }
            }
        }

        this.setInventoryFromSave(inventory, bInBackpack, iCharacter);

        result.inventory = this.getAllItems(bInBackpack, iCharacter);
        result.where = where;

        if (autoStacked.length > 0)
        {
            result.args.autoStacked = autoStacked;
        }

        if (bNotify && (result.status || result.args.leftOver))
        {
            this.emitItemFound(objItem.Id, quantity - (result.args.leftOver ? result.args.leftOver : 0));
        }
        return result;
    }

    /**
        Drags an item stored in a slot to another empty slot

        @param iFromSlot        From which slot index the item should be dragged from
        @param bFromIsBackpack  If the slot the item should be dragged from is from the backpack or not
        @param iToSlot          To which slot index the item should be dragged to. Set the value at -1 to autoplace in the inventory
        @param bToIsBackpack    If the slot to drag the item to is from the backpack or not

        @return                 An object describing the result of the operation :
                                    "status": boolean describing if the opration was a success or not
                                    "inventory": the new state of the inventories:
                                        - backpack: Backpack inventory state if the backpack was edited during the operation
                                        - storage: Storage inventory state if the storage was edited during the operation
                                    "args": List of values describing why the operation is as it is:
                                        - emptyFromSlot: The given slot index to drag from is empty
                                        - stackIsFull: The given slot contains a stack that no more items could be added to
                                        - autoStacked: Object containing the list of slots that received items in a result of an autostack operation
                                        - putBack: Object containing the slot that was put back in the original slot in case the FROM quantity is exceding the TO max stack quantity
    */
    dragItem (iFromSlot, bFromIsBackpack, iToSlot, bToIsBackpack)
    {
        let result = {"status": true, "inventory":{}, "args": {}};
        let itemFrom = this.getItemAtSlot(iFromSlot, bFromIsBackpack);

        if (itemFrom.item)
        {
            let itemTo = this.getItemAtSlot(iToSlot, bToIsBackpack);
            let bIsSameType = itemTo.item && itemTo.item.Id == itemFrom.item.Id;
            let amountToMove = itemFrom.quantity;

            if (bIsSameType)
            {
                let maxStack = this.getMaxStackAmount(itemTo.item, bToIsBackpack);
                if (itemFrom.quantity + itemTo.quantity > maxStack)
                {
                    amountToMove = maxStack - itemTo.quantity;
                }
            }
            else
            {
                let maxStack = this.getMaxStackAmount(itemFrom.item, bToIsBackpack);
                amountToMove = Math.min(amountToMove, maxStack);
            }

            let slotsPerPage = ItemManager.instance.StorageSlotCountPerType;
            if (amountToMove > 0)
            {
                if (!bToIsBackpack)
                {
                    let toPageIndex = this.getPageIndexFromType(itemFrom.item.InventoryPageType);
                    let toIndex = iToSlot - toPageIndex * slotsPerPage;

                    if (toIndex < 0 || toIndex >= slotsPerPage)
                    {
                        let storage = this.getInventoryFromSave(false, 0);
                        let autoStackedSlot = null;
                        for (let key in storage)
                        {
                            if (storage[key])
                            {
                                storage[key].qty = parseInt(storage[key].qty);
                                if (storage[key] && storage[key].qty > 0 && storage[key].id == itemFrom.item.Id)
                                {
                                    autoStackedSlot = key;
                                }
                            }
                        }
                        if (autoStackedSlot)
                        {
                            iToSlot = autoStackedSlot;
                            if (!result.args.autoStacked)
                            {
                                result.args.autoStacked = [];
                            }
                            let stackedInfos = this.createItemData(
                                storage[iToSlot].id,
                                storage[iToSlot].qty + amountToMove,
                                iToSlot
                            );
                            stackedInfos.isBackpack = false;
                            result.args.autoStacked.push(stackedInfos);
                        }
                    }

                }
                if (amountToMove < itemFrom.quantity)
                {
                    result.args.putBack = this.createItemData(
                        itemFrom.item,
                        itemFrom.quantity - amountToMove,
                        iFromSlot
                    );
                    result.args.putBack.isBackpack = bFromIsBackpack;
                }

                let fromPageIndex = this.getPageIndexFromType(itemFrom.item.InventoryPageType);
                if (iToSlot >= 0 && !bToIsBackpack)
                {
                    let index = iToSlot - fromPageIndex * slotsPerPage;
                    if (index < 0 || index >= slotsPerPage)
                    {
                        iToSlot = -1;
                    }
                }

                this.removeItemFromSlot(amountToMove, iFromSlot, bFromIsBackpack);

                if (itemTo.item && !bIsSameType)
                {
                    this.removeItemFromSlot(itemTo.quantity, iToSlot, bToIsBackpack);

                    let newSlot = iFromSlot;
                    let inv = this.getInventoryFromSave(bFromIsBackpack, 0);
                    let currentInventory = {};
                    for (let key in inv)
                    {
                        if (inv[key])
                        {
                            currentInventory[key] = {"item":inv[key].id, "qty": inv[key].qty};
                        }
                    }
                    if (result.args.putBack)
                    {
                        newSlot = -1;
                    }
                    else if (!bFromIsBackpack)
                    {
                        let pageIndex = this.getPageIndexFromType(itemTo.item.InventoryPageType);
                        let index = iFromSlot - slotsPerPage * pageIndex;
                        if (index < 0 || index >= slotsPerPage)
                        {
                            newSlot = -1;
                        }
                    }

                    let addToResult = this.addItem(itemTo.item, itemTo.quantity, newSlot, bFromIsBackpack, -1, false);

                    if (addToResult.args.autoStacked)
                    {
                        if (!result.args.autoStacked)
                        {
                            result.args.autoStacked = [];
                        }

                        for (let i = 0; i < addToResult.args.autoStacked.length; i++)
                        {
                            let operationInfos = this.createItemData(
                                addToResult.args.autoStacked[i].item,
                                addToResult.args.autoStacked[i].quantity,
                                addToResult.args.autoStacked[i].slot
                            );
                            operationInfos.isBackpack = false;
                            result.args.autoStacked.push(operationInfos);
                        }
                    }
                }

                let currentInventory = {};
                let checkForAutoPlace = iToSlot == -1 && !itemTo.item && !bToIsBackpack;
                if (checkForAutoPlace)
                {
                    let currentInventory = {};
                    let storage = this.getInventoryFromSave(false, 0);
                    for (let key in storage)
                    {
                        if (storage[key])
                        {
                            currentInventory[key] = {"item":storage[key].id, "qty": storage[key].qty};
                        }
                    }
                }

                let operationResult = this.addItem(itemFrom.item, amountToMove, iToSlot, bToIsBackpack, -1, false);

                if (operationResult.args.autoStacked)
                {
                    if (!result.args.autoStacked)
                    {
                        result.args.autoStacked = [];
                    }

                    for (let i = 0; i < operationResult.args.autoStacked.length; i++)
                    {
                        let operationInfos = this.createItemData(
                            operationResult.args.autoStacked[i].item,
                            operationResult.args.autoStacked[i].quantity,
                            operationResult.args.autoStacked[i].slot
                        );
                        operationInfos.isBackpack = false;
                        result.args.autoStacked.push(operationInfos);
                    }
                }

                result.inventory.backpack = this.getInventoryFromSave(true, 0);
                if (!bFromIsBackpack || !bToIsBackpack)
                {
                    result.inventory.storage = this.getInventoryFromSave(false, 0);
                }
            }
            else
            {
                result.status = false;
                result.args.stackIsFull = true;
            }

        }
        else
        {
            result.status = false;
            result.args.emptyFromSlot = true;
        }

        return result;
    }

    /**
        Takes all items from a character's backpack and dumps them into the general storage

        @param iCharacter   (Optional) Specifies which character inventory the items should be removed from. If less than 0 then the
                                current character's inventory is used

        @return             An object describing the result of the operation :
                                "status": boolean describing if the opration was a success or not
                                "inventory": the new state of the storage inventory (no need for backpack as it is empty)
    */
    dumpBackpackInStorage (iCharacter = -1)
    {
        let inventory = this.getInventoryFromSave(true, iCharacter);

        for (var key in inventory)
        {
            if (inventory[key] && inventory[key].qty > 0)
            {
                this.addItem(inventory[key].id, inventory[key].qty, -1, false);
                inventory[key] = null;
            }
        }

        this.setInventoryFromSave(inventory, true, iCharacter);

        return {
            "status": true,
            "inventory": this.getAllItems(false)
        };
    }

    emitItemFound(strItemType, iAmount)
    {
        setTimeout( () => ItemManager.instance.emit(ItemManager.instance.EVENT_ITEM_FOUND, strItemType, iAmount), 5);
    }
}