import { TextStyle } from "@pixi/text";
import { TextMetrics } from "@pixi/text";
import BaseCreationContent from "./BaseCreationContent.js";
import CloseButton from "../../commons/buttons/CloseButton.js";
import ColorTheme from "../../ColorTheme.js";
import Display from "../../../utils/Display.js";
import DualColorButton from "./DualColorButton.js";
import Library from "../../../Library.js";
import MutationButton from "./MutationButton.js";
import SpriteResponsive from "../../commons/responsive/SpriteResponsive.js";
import TextButton from "../../commons/buttons/TextButton.js";
import TextResponsive from "../../commons/responsive/TextResponsive.js";

export default class CreationMutationMenuContent extends BaseCreationContent
{
    constructor()
    {
        super();
    }

    get EVENT_BACK() { return "back"; }
    get EVENT_NEXT() { return "next"; }
    get EVENT_CATEGORY_CLICK() { return "category-click"; }
    get EVENT_REMOVE() { return "remove"; }

    get TitleLabel() { return this.UI.LabelManager.translate("Crea_Categorie_Choix").replace("[Nom personnage]", this.UI.CharacterManager.getCharacterName(this.Character)); }

    get ButtonWidth() { return this.ButtonHeight; }
    get ButtonHeight() { return "(" + this.BgHeight + " * 33%)"; }

    get Types() { return [this.UI.ItemManager.ITEM_CODES.TYPE_MUTATION_ARM, this.UI.ItemManager.ITEM_CODES.TYPE_MUTATION_LEG, this.UI.ItemManager.ITEM_CODES.TYPE_MUTATION_HEAD]; }
    get CurrentBuild() { return this.UI.CharacterManager.getCharacterBuild(this.Character); }

    /*******************************************
    *   INITIALIZATION
    *******************************************/
    /**
        Parameters to pass to the init function:
        - ui:       UI section object where this component resides
        - creator:  Character creator owning this content
        - character Id of the currently selected character
        - rWidth:   Responsive equation representing the width of this component
        - rHeight:  Responsive equation representing the height of this component
    */
    init(meta)
    {
        this.scales = {};
        this.buttons = {};
        this.buttonsCallback = {};

        return super.init(meta);
    }

    createClosure()
    {
        super.createClosure();

        this.fctOnBackClick = this.onBackClick.bind(this);
        this.fctOnBackDown = this.onBackDown.bind(this);
        this.fctOnNextClick = this.onNextClick.bind(this);
        this.fctOnNextDown = this.onNextDown.bind(this);
    }

    bindEvents()
    {
        super.bindEvents();
    }

    destroy(options)
    {
        if (this.sprites.backArrow)
        {
            this.sprites.backArrow.off("pointerup", this.fctOnBackClick);
            this.sprites.backArrow.off("pointerdown", this.fctOnBackDown);
            this.removeChild(this.sprites.backArrow);
            this.sprites.backArrow.destroy(options);

            delete this.sprites.backArrow;
        }

        if (this.sprites.nextArrow)
        {
            this.sprites.nextArrow.off("pointerup", this.fctOnNextClick);
            this.sprites.nextArrow.off("pointerdown", this.fctOnNextDown);
            this.removeChild(this.sprites.nextArrow);
            this.sprites.nextArrow.destroy(options);

            delete this.sprites.nextArrow;
        }
        
        for (let key in this.buttons)
        {
            this.buttons[key].off(this.buttons[key].EVENT_CLICK, this.buttonsCallback[key]);
            this.removeChild(this.buttons[key]);
            this.buttons[key].destroy(options);
        }
        delete this.buttons;

        super.destroy(options);
    }

    /*******************************************
    *   INITIALIZATION
    *******************************************/
    build()
    {
        super.build();

        this.buildTitle();
        this.buildTypes();
        this.buildArrows();
    }

    buildTitle()
    {
        if (!this.texts.title)
        {
            this.texts.title = new TextResponsive().init({"ui": this.UI});
            this.addChild(this.texts.title);
        }

        let text = this.texts.title;
        let label = this.TitleLabel;

        let fontSize = this.evaluate(this.valueFormula(this.UI.Values.general.title.size * 1.1));
        fontSize = Math.max(fontSize, this.UI.ResponsiveManager.TitleMinFontSize * 0.8);

        let style = new TextStyle({
            fontFamily: this.UI.Values.general.title.font,
            fontSize,
            fontWeight: this.UI.Values.general.title.weight,
            fill: this.UI.Values.general.title.color,
            align: "center"
        });

        let metrics = TextMetrics.measureText(label, style);

        let x = this.evaluate(this.BgWidth + " / 2 - " + metrics.width + " / 2", 0, 0, 0, 0, this);
        let y = this.evaluate(this.BgHeight + " * 5%", 0, 0, 0, 0, this);

        text.text = label;
        text.style = style;
        text.width = metrics.width;
        text.height = metrics.height;
        text.position.set(x, y);
    }


    buildTypes()
    {
        let types = this.Types;
        let width = this.ButtonWidth;
        let height = this.ButtonHeight;
        let padding = "(" + this.BgWidth + " - " + width + " * " + types.length + ") / " + (types.length + 1);

        for (let i = 0; i < types.length; i++)
        {
            let playerType = this.getPlayerForType(types[i]);

            if (!this.buttons[i])
            {
                this.buttons[i] = new MutationButton().init({
                    "ui": this.UI,
                    "index": i,
                    "icon": this.getTypeIcon(types[i]),
                    "rWidth": width,
                    "rHeight": height
                });
                this.buttonsCallback[i] = this.onCategoryClick.bind(this, i);
                this.buttons[i].on(this.buttons[i].EVENT_CLICK, this.buttonsCallback[i]);//@
                this.addChild(this.buttons[i]);
            }
            else
            {
                this.buttons[i].setSize(width, height);
            }

            let button = this.buttons[i];

            let x = padding + " * " + (i % types.length + 1) + " + " + width + " * " + (i % types.length);
            let y = "(" + this.BgHeight + " * 32.5%)";

            button.rX = [{on: "default", x: x}];
            button.rY = [{on: "default", y: y}];
            button.layout(Display.getSize());

            button.IsSelected = playerType === this.Character;
            button.IsDisabled = ((playerType >= 0 && playerType !== this.Character) || !this.canEquipType(types[i])) && !button.IsSelected;


            let strType = types[i];
            if (button.IsSelected)
            {
                let mutation = this.getCharacterMutation(strType);
                strType = mutation.Name;
                button.Icon = this.getMutationIcon(mutation);
            }
            else
            {
                button.Icon = this.getTypeIcon(types[i]);
                strType = this.CharacterCreator.getMutationTypeTitle(strType);
            }
            this.buildTypeTitle(i, strType, x, y, width, height, button);
            this.buildCloseButton(i, x, y, width, height, button.IsSelected);
        }
    }

    buildTypeTitle(i, type, x, y, width, height, button)
    {
        if (!this.texts["mutation_title_" + i])
        {
            this.texts["mutation_title_" + i] = new TextResponsive().init({"ui": this.UI});
            this.addChild(this.texts["mutation_title_" + i]);
        }

        let text = this.texts["mutation_title_" + i];
        let label = type;

        let style = new TextStyle({
            fontFamily: this.UI.Values.general.title.font,
            fontSize:   this.evaluate(this.valueFormula(this.UI.Values.general.title.size)),
            fontWeight: this.UI.Values.general.title.weight,
            fill: this.UI.Values.general.title.color,
            align: "center"
        });

        let metrics = TextMetrics.measureText(label, style);

        let textX = x + " + (" + width + ") /2 - " + metrics.width + " / 2";
        let textY = y + " + " + height + " + " + metrics.height + " / 2";

        text.text = label;
        text.style = style;
        text.width = metrics.width;
        text.height = metrics.height;
        
        text.rX = [{on: "default", x: textX}];
        text.rY = [{on: "default", y: textY}];
        text.layout(Display.getSize());

        text.alpha = (button.IsDisabled ? button.DisabledOpacity : 1);
    }

    buildCloseButton(i, x, y, width, height, bIsSelected)
    {
        if (bIsSelected)
        {
            if (!this.buttons["close_" + i])
            {
                this.buttons["close_" + i] = new CloseButton().init({
                    "ui": this.UI,
                    "scale": 0.6
                });
                this.buttonsCallback["close_" + i] = this.onRemoveClick.bind(this, i);
                this.buttons["close_" + i].on(this.buttons["close_" + i].EVENT_CLICK, this.buttonsCallback["close_" + i]);//@

                this.addChild(this.buttons["close_" + i]);
            }
            else if (!this.buttons["close_" + i].parent)
            {
                this.addChild(this.buttons["close_" + i]);
            }

            let button = this.buttons["close_" + i];

            let buttonX = x + " + " + width + " - " + button.ButtonWidth + " * 0.5";
            let buttonY = y + " - " + button.ButtonHeight + " * 0.5";

            button.rX = [{on: "default", x: buttonX}];
            button.rY = [{on: "default", y: buttonY}];
            button.layout(Display.getSize());
        }
        else if (this.buttons["close_" + i] && this.buttons["close_" + i].parent)
        {
            this.removeChild(this.buttons["close_" + i]);
        }
    }

    buildArrows()
    {
        if (!this.sprites.backArrow)
        {
            this.scales.back = {"x":1, "y": 1};
            let texture = Library.getTextureFromAtlas("ui", "arrow_rtl");
            this.sprites.backArrow = new SpriteResponsive(texture).init({"ui": this.UI});
            this.sprites.backArrow.interactive = true;
            this.sprites.backArrow.buttonMode = true;
            this.sprites.backArrow.on("pointerup", this.fctOnBackClick);//@
            this.sprites.backArrow.on("pointerdown", this.fctOnBackDown);//@
            this.addChild(this.sprites.backArrow);
        }

        if (!this.sprites.nextArrow)
        {
            this.scales.next = {"x":1, "y": 1};
            let texture = Library.getTextureFromAtlas("ui","arrow_rtl");
            this.sprites.nextArrow = new SpriteResponsive(texture).init({"ui": this.UI});
            this.sprites.nextArrow.interactive = true;
            this.sprites.nextArrow.buttonMode = true;
            this.sprites.nextArrow.on("pointerup", this.fctOnNextClick);//@
            this.sprites.nextArrow.on("pointerdown", this.fctOnNextDown);//@

            this.sprites.nextArrow.scale.set(-1, 1);
            this.addChild(this.sprites.nextArrow);
        }

        let sprite = this.sprites.backArrow;
        let scale = this.scales.back;

        let ratio = sprite.width / sprite.height;
        let height = this.evaluate(this.BgHeight + " * 12.5%", 0, 0, 0, 0, this);
        let width = height * ratio * scale.x;
        height *= scale.y;
        let x = - width / 1.5 + width * (1 - scale.x) / 2;
        let y = height + height * (1 - scale.y) / 2;

        sprite.width = width;
        sprite.height = height;
        sprite.position.set(x, y);

        sprite = this.sprites.nextArrow;
        scale = this.scales.next;

        height = this.evaluate(this.BgHeight + " * 12.5%", 0, 0, 0, 0, this);
        width = height * ratio * scale.x;
        height *= scale.y;

        let bgWidth = this.evaluate(this.BgWidth, 0, 0, 0, 0, this);
        x = bgWidth + this.StrokeWidth + width * 0.5 - width * (1 - scale.x) / 2;
        y = height + height * (1 - scale.y) / 2;

        sprite.width = width;
        sprite.height = height;
        sprite.position.set(x, y);

        if (this.Character < 2 && !this.sprites.nextArrow.parent)
        {
            this.addChild(this.sprites.nextArrow);
        }
        else if (this.Character >= 2 && this.sprites.nextArrow.parent)
        {
            this.removeChild(this.sprites.nextArrow);
        }
    }

    /*******************************************
    *   MUTATIONS CALCULATIONS
    *******************************************/
    getMutations(strType) 
    {
        let mutations = [];

        let list = [];
        if (strType == this.UI.ItemManager.ITEM_CODES.TYPE_MUTATION_HEAD)
        {
            list = ColorTheme.MUTATION_HEAD;
        }
        else if (strType == this.UI.ItemManager.ITEM_CODES.TYPE_MUTATION_ARM)
        {
            list = ColorTheme.MUTATION_ARM;
        }
        else if (strType == this.UI.ItemManager.ITEM_CODES.TYPE_MUTATION_LEG)
        {
            list = ColorTheme.MUTATION_LEGS;
        }

        for (let i = 0; i < list.length; i++)
        {
            mutations.push(this.UI.ItemManager.getMutationFromCode(list[i]));
        }
            
        return mutations;
    }

    getTypeIcon(strType)
    {
        let icon = null;

        if (strType == this.UI.ItemManager.ITEM_CODES.TYPE_MUTATION_HEAD)
        {
            icon = "face";
        }
        else if (strType == this.UI.ItemManager.ITEM_CODES.TYPE_MUTATION_ARM)
        {
            icon = "arm";
        }
        else if (strType == this.UI.ItemManager.ITEM_CODES.TYPE_MUTATION_LEG)
        {
            icon = "leg";
        }

        return icon;
    }

    getPlayerForType(strType)
    {
        let player = -1;

        for (let i = 0; i < 3; i++)
        {
            let buildSplit = this.UI.CharacterManager.getCharacterBuild(i).split("_");

            let mutation = 0;
            if (strType == this.UI.ItemManager.ITEM_CODES.TYPE_MUTATION_HEAD)
            {
                mutation = parseInt(buildSplit[1].substring(1, 2));
                if (mutation == this.UI.CharacterManager.getMandatoryMutation(this.UI.ItemManager.ITEM_CODES.TYPE_MUTATION_HEAD))
                {
                    mutation = 0;
                }
            }
            else if (strType == this.UI.ItemManager.ITEM_CODES.TYPE_MUTATION_ARM)
            {
                mutation = parseInt(buildSplit[1].substring(2, 3));
                if (mutation == this.UI.CharacterManager.getMandatoryMutation(this.UI.ItemManager.ITEM_CODES.TYPE_MUTATION_ARM))
                {
                    mutation = 0;
                }
            }
            else if (strType == this.UI.ItemManager.ITEM_CODES.TYPE_MUTATION_LEG)
            {
                mutation = parseInt(buildSplit[1].substring(3, 4));
                if (mutation == this.UI.CharacterManager.getMandatoryMutation(this.UI.ItemManager.ITEM_CODES.TYPE_MUTATION_LEG))
                {
                    mutation = 0;
                }
            }

            if (mutation > 0)
            {
                player = i;
                break;
            }
        }

        return player;
    }

    getCharacterType()
    {
        let buildSplit = this.CurrentBuild.split("_");

        if (buildSplit[1].substring(1, 2) !== "0")
        {
            return this.UI.ItemManager.ITEM_CODES.TYPE_MUTATION_HEAD;
        }
        else if (buildSplit[1].substring(2, 3) !== "0")
        {
            return this.UI.ItemManager.ITEM_CODES.TYPE_MUTATION_ARM;
        }
        else if (buildSplit[1].substring(3, 4) !== "0")
        {
            return this.UI.ItemManager.ITEM_CODES.TYPE_MUTATION_LEG;
        }

        return null;
    }

    getMutationIcon(objMutation)
    {
        let code = objMutation.Code;
        if (code == "raccoon")
        {
            code = "racoon";
        }
        return "mutation_" + code;
    }

    getCharacterMutation(strType)
    {
        let mutation = this.getCharacterMutationId(strType);
        mutation--;

        if (mutation >= 0)
        {
            return this.getMutations(strType)[mutation];
        }

        return null;
    }

    getCharacterMutationId(strType, iCharacter = -1)
    {
        if (iCharacter < 0)
        {
            iCharacter = this.Character;
        }
        let buildSplit = this.UI.CharacterManager.getCharacterBuild(iCharacter).split("_");

        let mutation = 0;
        if (strType == this.UI.ItemManager.ITEM_CODES.TYPE_MUTATION_HEAD)
        {
            mutation = parseInt(buildSplit[1].substring(1, 2));
        }
        else if (strType == this.UI.ItemManager.ITEM_CODES.TYPE_MUTATION_ARM)
        {
            mutation = parseInt(buildSplit[1].substring(2, 3));
        }
        else if (strType == this.UI.ItemManager.ITEM_CODES.TYPE_MUTATION_LEG)
        {
            mutation = parseInt(buildSplit[1].substring(3, 4));
        }

        return mutation;
    }

    characterHasEquippedMutation(iCharacter)
    {
        let types = this.Types;
        for (let i = 0; i < types.length; i++)
        {
            let mutation = this.getCharacterMutationId(types[i], iCharacter);
            if (mutation && mutation != this.UI.CharacterManager.getMandatoryMutation(types[i]))
            {
                return true;
            }
        }
        return false;
    }

    canEquipType(strType)
    {
        let canEquip = true;
        let types = this.Types;
                
        for (let i = 0; i < types.length; i++)
        {
            let mutation = this.getCharacterMutationId(types[i]);
            if (mutation && (mutation != this.UI.CharacterManager.getMandatoryMutation(types[i]) || types[i] == strType))
            {
                canEquip = false;
                break;
            }
        }

        //We also need to check for the other unequipped character to prevent a lock where nothing can be equipped
        if (canEquip)
        {
            let characterCount = 0;
            let unequippedBuilds = [];

            for (let i = 0; i < 3; i++)
            {
                let hasEquipped = this.characterHasEquippedMutation(i);
                let mutation = this.getCharacterMutationId(strType, i);

                if (!hasEquipped && !mutation && i != this.Character)
                {
                    unequippedBuilds.push(this.UI.CharacterManager.getCharacterBuild(i).split("_")[1].substr(1) + "-" + i);
                }
            }

            if (unequippedBuilds.length > 0)
            {
                let types = this.Types;
                let available = [];
                for (let i = 0; i < types.length; i++)
                {
                    if (types[i] != strType && this.getPlayerForType(types[i]) < 0)
                    {
                        available.push(types[i]);
                    }
                }

                let isOk = false;
                for (let i = 0; i < unequippedBuilds.length; i++)
                {
                    if (
                        (available.includes(this.UI.ItemManager.ITEM_CODES.TYPE_MUTATION_HEAD) && parseInt(unequippedBuilds[i].substr(0, 1)) == 0) ||
                        (available.includes(this.UI.ItemManager.ITEM_CODES.TYPE_MUTATION_ARM) && parseInt(unequippedBuilds[i].substr(1, 1)) == 0) ||
                        (available.includes(this.UI.ItemManager.ITEM_CODES.TYPE_MUTATION_LEG) && parseInt(unequippedBuilds[i].substr(2, 1)) == 0)
                    )
                    {
                        isOk = true;
                        break;
                    }
                }
                canEquip = isOk;
            }
        }

        return canEquip;
    }


    /*******************************************
    *   EVENTS
    *******************************************/
    onBackClick(iIndex)
    {
        for (let key in this.scales)
        {
            this.scales[key].x = this.UI.Values.textbutton.scale.normal;
            this.scales[key].y = this.UI.Values.textbutton.scale.normal;
        }
        this.build();

        this.UI.AudioManager.playSfx(this.UI.Values.audio.click);
        this.emit(this.EVENT_BACK, this);
    }

    onNextClick(iIndex)
    {
        for (let key in this.scales)
        {
            this.scales[key].x = this.UI.Values.textbutton.scale.normal;
            this.scales[key].y = this.UI.Values.textbutton.scale.normal;
        }
        this.build();

        this.UI.AudioManager.playSfx(this.UI.Values.audio.click);
        this.emit(this.EVENT_NEXT, this);
    }

    onBackDown(iIndex)
    {
        this.scales.back.x = this.UI.Values.textbutton.scale.down;
        this.scales.back.y = this.UI.Values.textbutton.scale.down;
        this.build();
    }

    onNextDown(iIndex)
    {
        this.scales.next.x = this.UI.Values.textbutton.scale.down;
        this.scales.next.y = this.UI.Values.textbutton.scale.down;
        this.build();
    }

    onWindowMouseUp(e)
    {
        for (let key in this.scales)
        {
            this.scales[key].x = this.UI.Values.textbutton.scale.normal;
            this.scales[key].y = this.UI.Values.textbutton.scale.normal;
        }
        this.build();
    }

    onWindowTouchEnd(e)
    {
        if (e.touches.length == 0)
        {
            for (let key in this.scales)
            {
                this.scales[key].x = this.UI.Values.textbutton.scale.normal;
                this.scales[key].y = this.UI.Values.textbutton.scale.normal;
            }
            this.build();
        }
    }

    onCategoryClick(iIndex, sender)
    {
        this.emit(this.EVENT_CATEGORY_CLICK, this, this.Types[iIndex]);
    }

    onRemoveClick(iIndex, sender)
    {
        this.emit(this.EVENT_REMOVE, this, this.Types[iIndex]);
    }
}