import TriggerCodes from "../codes/TriggerCodes.js";

export default class ItemValidator
{
    constructor (dependencies)
    {
        this.dependencies = dependencies;
        this.createSupportedConditionList();
        this.createCompareConditionList();
        this.createClosure();
        this.bindEvents();
    }

    //---------------------------------------------------------
    //  DEPENDENCIES
    //---------------------------------------------------------
    get ItemManager() { return this.dependencies.get("ItemManager"); }
    get TriggerManager() { return this.dependencies.get("TriggerManager"); }
    //---------------------------------------------------------

    get ItemFoundArgumentType() { return this.TriggerManager.ARGUMENT_TYPES.ITEM_FOUND; };
    get ItemUsedArgumentType() { return this.TriggerManager.ARGUMENT_TYPES.ITEM_USED; };
    get ItemCraftedArgumentType() { return this.TriggerManager.ARGUMENT_TYPES.ITEM_CRAFTED; };
    get BackpackCraftedArgumentType() { return this.TriggerManager.ARGUMENT_TYPES.BACKPACK_CRAFTED; };

    createSupportedConditionList ()
    {
        this.supportedConditions = {};

        this.supportedConditions[TriggerCodes.CONDITION_OWN_ITEM] = this.validateItemOwned.bind(this);
        this.supportedConditions[TriggerCodes.CONDITION_OWN_TYPE_ITEM] = this.validateItemTypeOwned.bind(this);
        this.supportedConditions[TriggerCodes.CONDITION_USE_ITEM] = this.validateItemUsed.bind(this);
        this.supportedConditions[TriggerCodes.CONDITION_CRAFT_ITEM] = this.validateItemCrafted.bind(this);
        this.supportedConditions[TriggerCodes.CONDITION_CRAFT_BACKPACK] = this.validateBackpackCrafted.bind(this);
    }

    createCompareConditionList ()
    {
        this.compareConditions = {};

         this.compareConditions[TriggerCodes.CONDITION_OWN_ITEM] = this.compareItemOwned.bind(this);
        // this.supportedConditions[TriggerCodes.CONDITION_OWN_TYPE_ITEM] = this.validateItemTypeOwned.bind(this);
        // this.supportedConditions[TriggerCodes.CONDITION_USE_ITEM] = this.validateItemUsed.bind(this);
        // this.supportedConditions[TriggerCodes.CONDITION_CRAFT_ITEM] = this.validateItemCrafted.bind(this);
        // this.supportedConditions[TriggerCodes.CONDITION_CRAFT_BACKPACK] = this.validateBackpackCrafted.bind(this);

    }

    createClosure ()
    {
        this.fctOnItemFound = this.onItemFound.bind(this);
        this.fctOnItemUsed = this.onItemUsed.bind(this);
        this.fctOnItemCrafted = this.onItemCrafted.bind(this);
        this.fctOnBackpackCrafted = this.onBackpackCrafted.bind(this);
    }

    bindEvents ()
    {
        this.ItemManager.on(this.ItemManager.EVENT_ITEM_FOUND, this.fctOnItemFound)//@!
        this.ItemManager.on(this.ItemManager.EVENT_ITEM_USED, this.fctOnItemUsed);//@!
        this.ItemManager.on(this.ItemManager.EVENT_ITEM_CRAFTED, this.fctOnItemCrafted);//@!
        this.ItemManager.on(this.ItemManager.EVENT_BACKPACK_CRAFTED, this.fctOnBackpackCrafted);//@!
    }

    //EVENTS MAPPING
    //--------------------------------------------------------------
    onItemFound (strItemType, iAmount)
    {
        this.TriggerManager.findTriggersAndExecute(
            this.supportedConditions,
            this.ItemFoundArgumentType,
            [strItemType, iAmount]
        );
    }

    onItemUsed (strItemType)
    {
        this.TriggerManager.findTriggersAndExecute(
            this.supportedConditions,
            this.ItemUsedArgumentType,
            [strItemType]
        );
    }

    onItemCrafted (strItemType, iAmount)
    {
        this.TriggerManager.findTriggersAndExecute(
            this.supportedConditions,
            this.ItemCraftedArgumentType,
            [strItemType, iAmount]
        );
    }

    onBackpackCrafted (iNewLevel)
    {
        this.TriggerManager.findTriggersAndExecute(
            this.supportedConditions,
            this.BackpackCraftedArgumentType,
            [iNewLevel]
        );
    }

    //CONDITION VALIDATION
    //--------------------------------------------------------------
    validateCondition (strType, arrValues, objNewValues = null)
    {
        if (strType in this.supportedConditions)
        {
            return this.supportedConditions[strType](arrValues, objNewValues);
        }
        return true;
    }

    validateItemOwned (arrValues, objNewValues = null)
    {
        arrValues[2] = parseInt(arrValues[2]);
        let quantity = this.ItemManager.getItemQuantity(arrValues[0], arrValues.length <= 3 || arrValues[3] == "1", -1, true);

        return this.TriggerManager.calculateValidity(arrValues[1], quantity, arrValues[2]);
    }

    compareItemOwned (arrValues)
    {

        let [strItem, strCompare, strQty, inStorage] = arrValues;

        let quantity = this.ItemManager.getItemQuantity(strItem, arrValues.length <= 3 || inStorage == "1", -1, true);

        let result = this.TriggerManager.calculateValidity(strCompare, quantity, parseFloat(strQty));

        // static get OPERATOR_EQUALS() { return "EQ" };
        // static get OPERATOR_NOT_EQUALS() { return "!EQ" };
        // static get OPERATOR_GREATER_THAN() { return "GT" };
        // static get OPERATOR_GREATER_THAN_EQUALS() { return "GTE" };
        // static get OPERATOR_LESS_THAN() { return "LT" };
        // static get OPERATOR_LESS_THAN_EQUALS() { return "LTE" };

        let humanReadable = {};
        humanReadable[TriggerCodes.OPERATOR_EQUALS] = "égale à";
        humanReadable[TriggerCodes.OPERATOR_NOT_EQUALS] = "pas égale à ";
        humanReadable[TriggerCodes.OPERATOR_GREATER_THAN] = "plus grande que";
        humanReadable[TriggerCodes.OPERATOR_GREATER_THAN_EQUALS] = "plus grande ou égale à";
        humanReadable[TriggerCodes.OPERATOR_LESS_THAN] = "plus petite que";
        humanReadable[TriggerCodes.OPERATOR_LESS_THAN_EQUALS] = "plus petite ou égale à";

        let strOperationReadable = humanReadable[strCompare];

        return {
            method: {name: "ItemOwned", shorthand:"IOT"},
            result,
            explanation: [
                `On a besoin d'avoir  une quantité de  ${strItem} qui soit ${strOperationReadable} ${strQty}. On en a présentement ${quantity}`
            ]
        };
    }

    validateItemTypeOwned (arrValues, objNewValues = null)
    {
        arrValues[2] = parseInt(arrValues[2]);
        let quantity = this.ItemManager.getItemTypeQuantity(arrValues[0], arrValues.length <= 3 || arrValues[3] == "1", -1, true);

        return this.TriggerManager.calculateValidity(arrValues[1], quantity, arrValues[2]);
    }

    validateItemUsed (arrValues, objNewValues = null)
    {
        return objNewValues && objNewValues.type == this.ItemUsedArgumentType && arrValues[0] == objNewValues.values[0];
    }

    validateItemCrafted (arrValues, objNewValues = null)
    {
        arrValues[2] = parseInt(arrValues[2]);
        let quantity = null;
        if (objNewValues && objNewValues.type == this.ItemCraftedArgumentType && arrValues[0] == objNewValues.values[0])
        {
            quantity = objNewValues.values[1];
        }
        return quantity !== null && this.TriggerManager.calculateValidity(arrValues[1], quantity, arrValues[2]);
    }

    validateBackpackCrafted (arrValues, objNewValues = null)
    {
        let level = null;
        if (objNewValues && objNewValues.type == this.BackpackCraftedArgumentType)
        {
            level = objNewValues.values[0];
        }
        else
        {
            level = this.ItemManager.BackpackLevel;
        }
        return level !== null && this.TriggerManager.calculateValidity(arrValues[1], level, arrValues[0]);
    }
}