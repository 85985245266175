import { TextStyle } from "@pixi/text";
import { TextMetrics } from "@pixi/text";
import BaseCreationContent from "./BaseCreationContent.js";
import CloseButton from "../../commons/buttons/CloseButton.js";
import ColorTheme from "../../ColorTheme.js";
import Display from "../../../utils/Display.js";
import DualColorButton from "./DualColorButton.js";
import Library from "../../../Library.js";
import MutationButton from "./MutationButton.js";
import SpriteResponsive from "../../commons/responsive/SpriteResponsive.js";
import TextButton from "../../commons/buttons/TextButton.js";
import TextResponsive from "../../commons/responsive/TextResponsive.js";

export default class CreationMutationCategoryContent extends BaseCreationContent
{
    constructor()
    {
        super();
    }

    get EVENT_BACK() { return "back"; }
    get EVENT_CATEGORY_CLICK() { return "mutation-click"; }
    get EVENT_REMOVE() { return "remove"; }

    get Type() { return this.type; }
    set Type(strNewType) { this.type = strNewType; this.build(); }

    get TitleLabel() { return this.CharacterCreator.getMutationTypeTitle(this.Type); }

    get ButtonWidth() { return this.ButtonHeight; }
    get ButtonHeight() { return "(" + this.BgHeight + " * 25%)"; }

    get CurrentBuild() { return this.UI.CharacterManager.getCharacterBuild(this.Character); }

    /*******************************************
    *   INITIALIZATION
    *******************************************/
    /**
        Parameters to pass to the init function:
        - ui:       UI section object where this component resides
        - creator:  Character creator owning this content
        - character Id of the currently selected character
        - type:     Type of content to display
        - rWidth:   Responsive equation representing the width of this component
        - rHeight:  Responsive equation representing the height of this component
    */
    init(meta)
    {
        this.type = meta.type;

        this.scales = {};
        this.buttons = {};

        return super.init(meta);
    }

    createClosure()
    {
        super.createClosure();

        this.fctOnBackClick = this.onBackClick.bind(this);
        this.fctOnBackDown = this.onBackDown.bind(this);
    }

    bindEvents()
    {
        super.bindEvents();
    }

    destroy(options)
    {
        for (let key in this.buttons)
        {
            this.removeChild(this.buttons[key]);
            this.buttons[key].destroy(options);
        }
        delete this.buttons;

        super.destroy(options);
    }

    /*******************************************
    *   INITIALIZATION
    *******************************************/
    build()
    {
        super.build();

        this.buildTitle();
        this.buildMutations();
        this.buildBackArrow();
    }

    clean()
    {
        super.clean();
    }

    buildTitle()
    {
        if (!this.texts.title)
        {
            this.texts.title = new TextResponsive().init({"ui": this.UI});
            this.addChild(this.texts.title);
        }

        let text = this.texts.title;
        let label = this.TitleLabel;

        let fontSize = this.evaluate(this.valueFormula(this.UI.Values.general.title.size * 1.1));
        fontSize = Math.max(fontSize, this.UI.ResponsiveManager.TitleMinFontSize);

        let style = new TextStyle({
            fontFamily: this.UI.Values.general.title.font,
            fontSize,
            fontWeight: this.UI.Values.general.title.weight,
            fill: this.UI.Values.general.title.color,
            align: "center"
        });

        let metrics = TextMetrics.measureText(label, style);

        let x = this.evaluate(this.BgWidth + " / 2 - " + metrics.width + " / 2", 0, 0, 0, 0, this);
        let y = this.evaluate(this.BgHeight + " * 5%", 0, 0, 0, 0, this);

        text.text = label;
        text.style = style;
        text.width = metrics.width;
        text.height = metrics.height;
        text.position.set(x, y);
    }

    buildMutations()
    {
        let mutations = this.Mutations;
        let width = this.ButtonWidth;
        let height = this.ButtonHeight;
        let padding = "(" + this.BgWidth + " - " + width + " * " + mutations.length + ") / " + (mutations.length + 1);
        let characterMutation = this.getCharacterMutation();

        for (let i = 0; i < mutations.length; i++)
        {
            let icon = this.getMutationIcon(mutations[i]);
            if (!this.buttons[i])
            {
                this.buttons[i] = new MutationButton().init({
                    "ui": this.UI,
                    "rWidth": width,
                    "rHeight": height,
                    "index": i,
                    "icon": icon
                });
                this.buttons[i].on(this.buttons[i].EVENT_CLICK, this.onMutationClick.bind(this, i));
                this.addChild(this.buttons[i]);
            }
            else
            {
                this.buttons[i].setSize(width, height);
            }

            let button = this.buttons[i];

            let x = padding + " * " + (i % mutations.length + 1) + " + " + width + " * " + (i % mutations.length);
            let y = "(" + this.BgHeight + " * 27.5%)";

            button.rX = [{on: "default", x: x}];
            button.rY = [{on: "default", y: y}];
            button.layout(Display.getSize());

            button.Icon = icon;
            button.IsSelected = characterMutation && characterMutation.Id === mutations[i].Id;

            this.buildMutationTitle(i, mutations[i], x, y, width, height, button);
            this.buildMutationDescription(i, mutations[i], x, y, width, height, button);

            this.buildCloseButton(i, x, y, width, height, button.IsSelected);
        }
    }

    buildMutationTitle(i, mutation, x, y, width, height, button)
    {
        if (!this.texts["mutation_title_" + i])
        {
            this.texts["mutation_title_" + i] = new TextResponsive().init({"ui": this.UI});
            this.addChild(this.texts["mutation_title_" + i]);
        }

        let text = this.texts["mutation_title_" + i];
        let label = mutation.Name;

        let fontSize = this.evaluate(this.valueFormula(this.UI.Values.general.title.size));
        fontSize = Math.max(fontSize, this.UI.ResponsiveManager.TitleMinFontSize * 0.75);

        let style = new TextStyle({
            fontFamily: this.UI.Values.general.title.font,
            fontSize,
            fontWeight: this.UI.Values.general.title.weight,
            fill: this.UI.Values.general.title.color,
            align: "center"
        });

        let metrics = TextMetrics.measureText(label, style);

        let textX = x + " + (" + width + ") /2 - " + metrics.width + " / 2";
        let textY = y + " + " + height + " + " + metrics.height + " / 2";

        text.text = label;
        text.style = style;
        text.width = metrics.width;
        text.height = metrics.height;
        
        text.rX = [{on: "default", x: textX}];
        text.rY = [{on: "default", y: textY}];
        text.layout(Display.getSize());

        text.alpha = (button.IsDisabled ? button.DisabledOpacity : 1);
    }

    buildMutationDescription(i, mutation, x, y, width, height, button)
    {
        if (!this.texts["mutation_desc_" + i])
        {
            this.texts["mutation_desc_" + i] = new TextResponsive().init({"ui": this.UI});
            this.addChild(this.texts["mutation_desc_" + i]);
        }

        let text = this.texts["mutation_desc_" + i];
        let label = mutation.Description;

        let fontSize = this.evaluate(this.valueFormula(this.UI.Values.general.text.size * 0.85));
        fontSize = Math.max(fontSize, this.UI.ResponsiveManager.TextMinSize);

        let style = new TextStyle({
            fontFamily: this.UI.Values.general.text.font,
            fontSize,
            fontWeight: this.UI.Values.general.text.weight,
            fill: this.UI.Values.general.text.color,
            align: "center",
            wordWrap: true,
            wordWrapWidth: this.evaluate(width, 0, 0, 0, 0, this) * 1.75
        });

        let metrics = TextMetrics.measureText(label, style);

        let textX = x + " + (" + width + ") /2 - " + metrics.width + " / 2";
        let textY = y + " + " + height + " + (" + height + ")  * 60%";

        text.text = label;
        text.style = style;
        text.width = metrics.width;
        text.height = metrics.height;
        
        text.rX = [{on: "default", x: textX}];
        text.rY = [{on: "default", y: textY}];
        text.layout(Display.getSize());

        text.alpha = (button.IsDisabled ? button.DisabledOpacity : 1);
    }

    buildCloseButton(i, x, y, width, height, bIsSelected)
    {
        if (bIsSelected)
        {
            if (!this.buttons["close_" + i])
            {
                this.buttons["close_" + i] = new CloseButton().init({"ui": this.UI, "scale": 0.6});
                this.buttons["close_" + i].on(this.buttons["close_" + i].EVENT_CLICK, this.onRemoveClick.bind(this, i));
                this.addChild(this.buttons["close_" + i]);
            }
            else if (!this.buttons["close_" + i].parent)
            {
                this.addChild(this.buttons["close_" + i]);
            }

            let button = this.buttons["close_" + i];

            let buttonX = x + " + " + width + " - " + button.ButtonWidth + " * 0.5";
            let buttonY = y + " - " + button.ButtonHeight + " * 0.5";

            button.rX = [{on: "default", x: buttonX}];
            button.rY = [{on: "default", y: buttonY}];
            button.layout(Display.getSize());
        }
        else if (this.buttons["close_" + i] && this.buttons["close_" + i].parent)
        {
            this.removeChild(this.buttons["close_" + i]);
        }
    }

    buildBackArrow()
    {
        if (!this.sprites.arrow)
        {
            let texture = Library.getTextureFromAtlas("ui", "arrow_rtl");
            this.sprites.arrow = new SpriteResponsive(texture).init({"ui": this.UI});
            this.sprites.arrow.interactive = true;
            this.sprites.arrow.buttonMode = true;
            this.sprites.arrow.on("pointerup", this.fctOnBackClick);
            this.sprites.arrow.on("pointerdown", this.fctOnBackDown);
            this.sprites.arrow.ratio = texture.orig.width / texture.orig.height;
            this.scales.back = {"x": 1, "y": 1};

            this.addChild(this.sprites.arrow);
        }

        let sprite = this.sprites.arrow;
        let scale = this.scales.back;

        let height = this.evaluate(this.BgHeight + " * 12.5%", 0, 0, 0, 0, this);
        let width = height * sprite.ratio * scale.x;
        height *= scale.y;
        let x = - width / 1.5 + width * (1 - scale.x) / 2;
        let y = height + height * (1 - scale.y) / 2;

        sprite.width = width;
        sprite.height = height;
        sprite.position.set(x, y);
    }

    /*******************************************
    *   MUTATIONS
    *******************************************/
    get Mutations() 
    {
        let mutations = [];

        let list = this.UI.GameManager.getSetting("character").mutations.creator;

        for (let key in list)
        {
            let mutation = this.UI.ItemManager.getItem(list[key].id);
            if (this.Type == mutation.Id.split("-")[0])
            {
                mutation.mutationIndex = list[key].index
                mutations.push(mutation);
            }
        }

        return mutations;
    }

    getMutationIcon(objMutation)
    {
        let code = objMutation.Code;
        if (code == "raccoon")
        {
            code = "racoon";
        }
        return "mutation_" + code;
    }

    getCharacterMutation()
    {
        let buildSplit = this.CurrentBuild.split("_");

        let mutation = 0;
        if (this.Type == this.UI.ItemManager.ITEM_CODES.TYPE_MUTATION_HEAD)
        {
            mutation = parseInt(buildSplit[1].substring(1, 2));
        }
        else if (this.Type == this.UI.ItemManager.ITEM_CODES.TYPE_MUTATION_ARM)
        {
            mutation = parseInt(buildSplit[1].substring(2, 3));
        }
        else if (this.Type == this.UI.ItemManager.ITEM_CODES.TYPE_MUTATION_LEG)
        {
            mutation = parseInt(buildSplit[1].substring(3, 4));
        }

        if (mutation >= 0)
        {
            let mutationList = this.Mutations;
            for (let i = 0; i < mutationList.length; i++)
            {
                if (mutationList[i].mutationIndex == mutation)
                {
                    return mutationList[i];
                }
            }
        }

        return null;
    }


    /*******************************************
    *   MUTATIONS
    *******************************************/
    onBackClick(iIndex)
    {
        for (let key in this.scales)
        {
            this.scales[key].x = this.UI.Values.textbutton.scale.normal;
            this.scales[key].y = this.UI.Values.textbutton.scale.normal;
        }
        this.build();

        this.UI.AudioManager.playSfx(this.UI.Values.audio.click);
        this.emit(this.EVENT_BACK, this);
    }

    onBackDown(iIndex)
    {
        this.scales.back.x = this.UI.Values.textbutton.scale.down;
        this.scales.back.y = this.UI.Values.textbutton.scale.down;
        this.build();
    }

    onWindowMouseUp(e)
    {
        for (let key in this.scales)
        {
            this.scales[key].x = this.UI.Values.textbutton.scale.normal;
            this.scales[key].y = this.UI.Values.textbutton.scale.normal;
        }
        this.build();
    }

    onWindowTouchEnd(e)
    {
        if (e.touches.length == 0)
        {
            for (let key in this.scales)
            {
                this.scales[key].x = this.UI.Values.textbutton.scale.normal;
                this.scales[key].y = this.UI.Values.textbutton.scale.normal;
            }
            this.build();
        }
    }

    onMutationClick(iIndex, sender)
    {
        let index = 0;
        let mutation = this.Mutations[iIndex];
        let list = [];

        if (this.Type == this.UI.ItemManager.ITEM_CODES.TYPE_MUTATION_HEAD)
        {
            list = ColorTheme.MUTATION_HEAD;
        }
        else if (this.Type == this.UI.ItemManager.ITEM_CODES.TYPE_MUTATION_ARM)
        {
            list = ColorTheme.MUTATION_ARM;
        }
        else if (this.Type == this.UI.ItemManager.ITEM_CODES.TYPE_MUTATION_LEG)
        {
            list = ColorTheme.MUTATION_LEGS;
        }

        for (let i = 0; i < list.length; i++)
        {
            if(mutation.Id == this.UI.ItemManager.getMutationFromCode(list[i]).Id)
            {
                index = i;
                break;
            }
        }

        this.emit(this.EVENT_CATEGORY_CLICK, this, index);
    }

    onRemoveClick(iIndex, sender)
    {
        this.emit(this.EVENT_REMOVE, this, this.Type);
    }
}