import BaseAI from "./BaseAI.js";
import Utils from "../../utils/Utils.js";

export default class RoamingAI extends BaseAI
{
    constructor()
    {
        super();
    }

    get MovementSpeed() { return this.RoamSpeed / 2; }

    //Single values
    get IsRoaming() { return this.isRoaming; }
    get RoamRange() { return this.params.roaming.range; }
    get RoamSpeed() { return this.params.roaming.speed; }
    get RoamChances() { return this.params.roaming.chances; }
    //Min / Max
    get RoamAngle() { return this.params.roaming.angle; }
    get RoamPatrolTime() { return this.params.roaming.time.patrol; }
    get RoamWaitTime() { return this.params.roaming.time.wait; }

    /*******************************************
    *   INITIALIZATION
    *******************************************/
    /**
        Parameters to pass to the init function:
        - character     Character object this AI should have governance over
        - spawnPos      Vector2 containing the world position where the character is supposed to spawn on the map
        - definition    Behaviour definition from the settings.json file (only the sub part of a definition called "ai")
    */
    init(meta)
    {
        super.init(meta);

        this.currentAngle = Math.random() * 360;
        this.roamWait = {"time": {"left": 0, "total": 0}, "started": false};
        this.roamPatrol = {"time": {"left": 0, "total": 0}, "position": {"x":0, "z":0}};
    }

    createClosure()
    {
        super.createClosure();
        this.fctOnCharacterTileChange = this.onCharacterTileChange.bind(this);
    }

    bindEvents()
    {
        super.bindEvents();
        this.Character.on(this.Character.EVENT_TILE_CHANGED, this.fctOnCharacterTileChange);
    }

    destroy()
    {
        super.destroy();
        if (this.Character)
        {
            this.Character.off(this.Character.EVENT_TILE_CHANGED, this.fctOnCharacterTileChange);
        }
    }

    parseDefinition(objDefinition)
    {
        super.parseDefinition(objDefinition);

        //Default parameters
        this.params.roaming = {
            "range": 1,
            "speed": 0.5,
            "time": {
                "patrol": {"min":0, "max":3},
                "wait": {"min":0, "max":1}
            },
            "angle": {"min":0, "max":(Math.PI / 2)},
            "chances": 0.5
        };

        if ("roaming" in objDefinition)
        {
            let roaming = objDefinition.roaming;
            if ("range" in roaming)
            {
                this.params.roaming.range = roaming.range;
            }
            if ("speed" in roaming)
            {
                this.params.roaming.speed = roaming.speed;
            }
            if ("time" in roaming)
            {
                let time = roaming.time;
                if ("patrol" in time)
                {
                    this.params.roaming.time.patrol = time.patrol;
                }
                else if ("wait" in time)
                {
                    this.params.roaming.time.wait = time.wait;
                }
            }
            if ("angle" in roaming)
            {
                this.params.roaming.angle = roaming.angle;
                this.params.roaming.angle.min = Utils.degToRad(this.params.roaming.angle.min);
                this.params.roaming.angle.max = Utils.degToRad(this.params.roaming.angle.max);
            }
            if ("chances" in roaming)
            {
                this.params.roaming.chances = roaming.chances;
            }
        }
    }

    /*******************************************
    *   CALCULATIONS
    *******************************************/
    /**
        Represents the time it takes for a character to travel one tile on the grid
    */
    getBaseTravelTime(fWalkSpeed)
    { 
        return 1 / fWalkSpeed * 0.4; 
    }

    getDirectionFromAngle(fRadAngle)
    {
        return {
            "x": Math.sin(fRadAngle),
            "z": Math.cos(fRadAngle)
        };
    }

    getSqrDist(iX1, iZ1, iX2, iZ2)
    {
        return Math.pow(iX2 - iX1, 2) + Math.pow(iZ2 - iZ1, 2);
    }

    isOutOfRange(fRadAngle, fDistance)
    {
        let charPosOnGrid = this.Character.GridPos;
        let direction = this.getDirectionFromAngle(fRadAngle);
        let pos = {"x": direction.x * fDistance + charPosOnGrid.x, "z":direction.z * fDistance + charPosOnGrid.y};

        let maxSqrDist = this.getSqrDist(this.SpawnPosition.x, this.SpawnPosition.y, this.SpawnPosition.x + direction.x * this.RoamRange, this.SpawnPosition.y + direction.z * this.RoamRange);
        let sqrDist = this.getSqrDist(this.SpawnPosition.x, this.SpawnPosition.y, pos.x, pos.z);

        return sqrDist > maxSqrDist;
    }


    calculateNewRoam(bSkipChances = false)
    {
        let chance = 0;
        if (!bSkipChances)
        {
            chance = Math.random();
        }

        if (chance <= this.RoamChances)
        {
            let posOnGrid = this.Character.GridPos;
            let time = Math.round(Math.random() * (this.RoamPatrolTime.max - this.RoamPatrolTime.min)) + this.RoamPatrolTime.min;
            let distance = time / this.getBaseTravelTime(this.RoamSpeed);
            let sqrDistance = Math.pow(distance, 2);

            //The way the roaming works is that it tries to pick a direction based on a min/max angle calculated from its current facing direction.
            //If the character is too close from the range's limit, it tries to find another angle that would give enought space to roam from the
            //chosen distance.
            let angleDiff = this.RoamAngle.max - this.RoamAngle.min;
            let angleMin = this.currentAngle - angleDiff / 2;
            let angleMax = this.currentAngle + angleDiff / 2;

            if (this.isOutOfRange(angleMin, distance))
            {   
                let step = (2*Math.PI) / 360;
                for (let i = 0; i < 360; i++)
                {
                    let newAngle = angleMin + step * i;
                    if (!this.isOutOfRange(newAngle, distance))
                    {
                        angleMin = newAngle;
                        angleMax = angleMin + angleDiff;
                        break;
                    }
                }
            }

            if (this.isOutOfRange(angleMax, distance))
            {
                let step = (2*Math.PI) / 360;
                for (let i = 0; i < 360; i++)
                {
                    let newAngle = angleMax - step * i;
                    if (newAngle < angleMin)
                    {
                        angleMax = angleMin;
                        break;
                    }
                    if (!this.isOutOfRange(newAngle, distance))
                    {
                        angleMax = newAngle;
                        break;
                    }
                }
            }

            let angle = (angleMax - angleMin) * Math.random() + angleMin;
            this.currentAngle = angle;
            let direction = this.getDirectionFromAngle(angle);

            this.roamPatrol.position = {
                "x": Math.floor(posOnGrid.x + direction.x * distance),
                "z": Math.floor(posOnGrid.y + direction.z * distance)
            };

            this.roamPatrol.time.left = time;
            this.roamPatrol.time.total = time;

            this.Character.goTo(
                this.roamPatrol.position.x * this.Character.Grid.CellSize, 
                this.roamPatrol.position.z * this.Character.Grid.CellSize
            );
        }
        else
        {
            this.calculateNewWait();
        }
    }

    calculateNewWait()
    {
        let time = Math.round(Math.random() * (this.RoamWaitTime.max - this.RoamWaitTime.min)) + this.RoamWaitTime.min;
        this.roamWait.time.left = time;
        this.roamWait.time.total = time;
        this.roamWait.started = !this.Character.IsMoving;
    }

    /*******************************************
    *   ACTIONS
    *******************************************/
    startRoaming()
    {
        this.isRoaming = true;
    }

    stopRoaming()
    {
        this.isRoaming = false;
    }

    /*******************************************
    *   UPDATE LOOP
    *******************************************/
    update(fDeltaTime)
    {
        super.update(fDeltaTime);

        if (this.IsRoaming)
        {
            this.updateRoaming(fDeltaTime);
        }
    }

    updateRoaming(fDeltaTime)
    {
        if (this.roamWait.time.left <= 0 && this.roamPatrol.time.left <= 0)
        {
            this.calculateNewRoam(true);
        }

        if (this.roamPatrol.time.left > 0)
        {
            this.roamPatrol.time.left -= this.DeltaTime;
            if (this.roamPatrol.time.left <= 0)
            {
                this.calculateNewWait();
            }
        }
        else if (this.roamWait.time.left > 0)
        {
            this.roamWait.time.left -= this.DeltaTime;
            if (this.roamWait.time.left <= 0)
            {
                this.calculateNewRoam();
            }
        }
    }

    /*******************************************
    *   EVENTS
    *******************************************/
    onCharacterTileChange(objCharacter, iTileX, iTileY)
    {
        if (objCharacter != this.Character)
        {
            if (this.roamPatrol.time.left > 0)
            {
                let cellSize = this.Character.Grid.CellSize;
                this.Character.goTo(
                    this.roamPatrol.position.x * cellSize,
                    this.roamPatrol.position.z * cellSize
                );
            }
        }
        else if (this.roamWait.time.left > 0 && !this.roamWait.started)
        {
            this.roamWait.started = !this.Character.IsMoving;
        }
    }
}