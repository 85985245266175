import EventEmitter from "eventemitter3";

export default class Anchor extends EventEmitter
{
    constructor()
    {
        super();
        this.anchorX = 0;
        this.anchorY = 0;
    }

    get x ()
    {
        return this.anchorX;
    }

    get y ()
    {
        return this.anchorY;
    }

    set x (value)
    {
        if (this.anchorX !== value)
        {
            this.anchorX = value;
            this.emit("change-anchor-x", value);
        }
    }

    set y (value)
    {
        if (this.anchorY !== value)
        {
            this.anchorY = value;
            this.emit("change-anchor-y", value);
        }
    }


}