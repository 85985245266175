import BaseCheat from "./BaseCheat.js";

export default class IMOK extends BaseCheat
{
    constructor(dependencies)
    {
        super(dependencies);
    }

    get CommandUse() { return "im ok"; }
    get CommandDesc() { return "Reset all gauge"; }

    //---------------------------------------------------------
    //  DEPENDENCIES
    //---------------------------------------------------------
    get CharacterManager() { return this.dependencies.get("CharacterManager"); }
    //---------------------------------------------------------

    parseCommand(strCmd, arrArguments)
    {
        if (strCmd == "im" && arrArguments.length >= 1)
        {
            let qty = parseInt(arrArguments[0]);
            if (arrArguments[0] === "ok")
            {
                this.CharacterManager.setCharacterHunger(this.CharacterManager.getCharacterHunger() + 100);
                this.CharacterManager.setCharacterEnergy(this.CharacterManager.getCharacterEnergy() + 100);
                this.CharacterManager.setCharacterStress(this.CharacterManager.getCharacterStress() - 100);

            }
            else
            {
                this.warn("Cannot add that amount of hunger");
            }
        }
    }
}