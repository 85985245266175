import { string2hex }  from "@pixi/utils";
import { TextStyle } from "@pixi/text";
import { TextMetrics } from "@pixi/text";
import Character from "../../commons/characters/Character.js";
import ContainerDragger from "../../commons/ContainerDragger.js";
import ContainerResponsive from "../../commons/responsive/ContainerResponsive.js";
import Display from "../../../utils/Display.js";
import GraphicsResponsive from "../../commons/responsive/GraphicsResponsive.js";
import Lerp from "../../../utils/Lerp.js";
import Library from "../../../Library.js";
import SpriteResponsive from "../../commons/responsive/SpriteResponsive.js";
import TextButton from "../../commons/buttons/TextButton.js";
import TextResponsive from "../../commons/responsive/TextResponsive.js";
import WindowGauge from "../../commons/gauges/WindowGauge.js";

export default class RelaxZoneContent extends ContainerResponsive
{
    constructor()
    {
        super();
    }

    get EVENT_START_ACTION() { return "start-action"; }
    get EVENT_STOP_ACTION() { return "stop-action"; }
    get EVENT_DRAG_ANIMATION_END() { return "drag-animation-end"; }

    get Opacity() { return this.opacity; }
    set Opacity(fNewValue) { this.opacity = fNewValue; this.build(); }

    get DropZoneLabel() { return this.UI.LabelManager.translate("Dete_Instruction"); }
    get StopLabel() { return this.UI.LabelManager.translate("Dete_Arret"); }

    get BgWidth() { return this.drawWidth; }
    get BgHeight() { return this.drawHeight; }

    get PaddingHorizontal() { return "(" + this.BgWidth + " * 0.125)"; }
    get PaddingVertical() { return "(" + this.BgHeight + " * 0.2)"; }
    get ItemWidth() { return "(" + this.BgWidth + " * 0.7 - " + this.PaddingHorizontal + " / 2)"; }
    get ItemHeight() { return "((" + this.BgHeight + " - " + this.PaddingVertical + ") / 3)"; }
    get ItemImageWidth() { return this.ItemImageHeight; }
    get ItemImageHeight() { return "(" + this.ItemHeight + " * 0.8)"; }
    get RightZoneWidth() { return "(" + this.BgWidth  + " - " + this.ItemWidth + " - " + this.PaddingHorizontal + " / 2)"; }
    get RightZoneHeight() { return "(" + this.BgHeight + " - " + this.PaddingVertical + ")"; }

    get ImageBackgroundColor() { return string2hex(this.UI.Values.slot.background.color); }
    get ImageStrokeWidth() { return this.evaluate(this.valueFormula(this.UI.Values.slot.stroke.size), 0, 0, 0, 0, this); }
    get ImageStrokeColor() { return string2hex("color" in this.UI.Values.slot.stroke ? this.UI.Values.slot.stroke.color : "#000000"); }
    get ImageCornerRadius() { return this.evaluate(this.valueFormula(this.UI.Values.slot.corner.radius), 0, 0, 0, 0, this); }

    get DropZoneNormalColor() { return string2hex(this.UI.Values.drag.dropzone.background.normal.color); }
    get DropZoneHoverColor() { return string2hex(this.UI.Values.drag.dropzone.background.hover.color); }

    get ItemDisabledAlpha() { return this.UI.Values.general.disabled.opacity; }

    getCharacterStat(iCharacter, bIsMax)
    {
        let stat = 0;
        if (bIsMax)
        {
            stat = this.UI.CharacterManager.getCharacterMaxStress(iCharacter);
        }
        else
        {
            stat = this.UI.CharacterManager.getCharacterStress(iCharacter);
        }
        return stat;
    }

    getIsBusy(iCharacter)
    {
        return this.UI.CharacterManager.isCharacterRelaxing(iCharacter) ||
               this.UI.CharacterManager.isCharacterSleeping(iCharacter);
    }

    getIsAction(iCharacter)
    {
        return this.UI.CharacterManager.isCharacterRelaxing(iCharacter);
    }

    getGaugeType(gauge)
    { 
        return gauge.STRESS; 
    }

    /*******************************************
    *   INITIALIZATION
    *******************************************/
    /**
        Parameters to pass to the init function:
        - ui:       UI section object where this component resides
        - rWidth:   Responsive equation for the width of this content
        - rHeight:  Responsive equation for the height of this content
    */
    init(meta)
    {
        this.ui = meta.ui;

        this.drawWidth = "(" + meta.rWidth + ")";
        this.drawHeight = "(" + meta.rHeight + ")";
        this.opacity = 1;

        this.graphics = {};
        this.sprites = {};
        this.texts = {};
        this.gauges = {};

        this.drags = {};

        this.characterInAction = -1;

        return super.init(meta);
    }

    createClosure()
    {
        super.createClosure();

        this.fctOnDragStart = this.onDragStart.bind(this);
        this.fctOnDragEnd = this.onDragEnd.bind(this);
        this.fctOnDragDrop = this.onDragDrop.bind(this);
        this.fctOnDragEnterDropZone = this.onDragEnterDropZone.bind(this);
        this.fctOnDragExitDropZone = this.onDragExitDropZone.bind(this);
        this.fctOnDragAnimationEnd = this.onDragAnimationEnd.bind(this);
        this.fctOnStop = this.onStop.bind(this);
        this.fctOnCharacterStatChanged = this.onCharacterStatChanged.bind(this);
    }

    bindEvents()
    {
        super.bindEvents();
        this.UI.CharacterManager.on(this.UI.CharacterManager.EVENT_STAT_VALUE_CHANGED, this.fctOnCharacterStatChanged);
    }

    destroy(options)
    {
        this.UI.CharacterManager.off(this.UI.CharacterManager.EVENT_STAT_VALUE_CHANGED, this.fctOnCharacterStatChanged);

        for (let key in this.drags)
        {
            this.drags[key].off(this.drags[key].EVENT_DRAG_START, this.fctOnDragStart);
            this.drags[key].off(this.drags[key].EVENT_ENTER_DROP_ZONE, this.fctOnDragEnterDropZone);
            this.drags[key].off(this.drags[key].EVENT_EXIT_DROP_ZONE, this.fctOnDragExitDropZone);
            this.drags[key].off(this.drags[key].EVENT_DRAG_END, this.fctOnDragEnd);
            this.drags[key].off(this.drags[key].EVENT_DROP, this.fctOnDragDrop);
            this.drags[key].off(this.drags[key].EVENT_DRAG_END_ANIMATION_FINISHED, this.fctOnDragAnimationEnd);
            this.drags[key].destroy(options);
        }

        if (this.stopButton)
        {
            this.stopButton.off(this.stopButton.EVENT_CLICK, this.fctOnStop);

            this.removeChild(this.stopButton);
            this.stopButton.destroy(options);

            delete this.stopButton;
        }

        super.destroy(options);
    }

    /*******************************************
    *   BUILD
    *******************************************/
    build()
    {
        super.build();

        let iCharInZone = null;
        for (let i = 0; i < 3; i++)
        {
            if (this.getIsAction(i))
            {
                iCharInZone = i;
                break;
            }
        }

        this.characterInAction = -1;
        if (iCharInZone !== null)
        {
            this.characterInAction = iCharInZone;
        }

        this.buildRightZone(iCharInZone);

        for (let i = 0; i < 3; i++)
        {
            this.buildCharacter(i, iCharInZone);
        }
    }

    clean()
    {
        for(let key in this.graphics)
        {
            this.graphics[key].clear();
        }

        super.clean();
    }

    buildRightZone(iCharInZone)
    {
        if (!this.graphics.rightZone)
        {
            this.graphics.rightZone = new GraphicsResponsive().init({"ui": this.UI});
            this.graphics.rightZone.interactive = true;

            this.addChild(this.graphics.rightZone);
        }

        let graphics = this.graphics.rightZone;
        graphics.alpha = this.Opacity;

        let width = this.RightZoneWidth;
        let height = this.RightZoneHeight;
        let x = this.ItemWidth;
        let y = "(" + this.PaddingVertical + " / 2)";

        let iconBoxWidth = "(" + width + " * 0.85)";
        let iconBoxHeight = "(" + height + " * 0.55)";
        let iconBoxX = this.center(x, width, iconBoxWidth);
        let iconBoxY = y + " + " + height + "* 0.225";

        let calculated = {
            "iconBoxWidth": this.calculate(iconBoxWidth),
            "iconBoxHeight": this.calculate(iconBoxHeight),
            "iconBoxX": this.calculate(iconBoxX),
            "iconBoxY": this.calculate(iconBoxY)
        };

        let iconBoxBgColor = this.ImageBackgroundColor;
        let iconBoxCornerRadius = this.ImageCornerRadius;
        let iconBoxStrokeWidth = this.ImageStrokeWidth;
        let iconBoxStrokeColor = this.ImageStrokeColor;

        if (iconBoxStrokeWidth > 0)
        {
            graphics.lineStyle({"color":iconBoxStrokeColor, "width": iconBoxStrokeWidth, "alignment": 1});
        }

        graphics.beginFill(iconBoxBgColor, 1);
        if (iconBoxCornerRadius > 0)
        {
            graphics.drawRoundedRect(calculated.iconBoxX, calculated.iconBoxY, calculated.iconBoxWidth, calculated.iconBoxHeight, iconBoxCornerRadius);
        }
        else
        {
            graphics.drawRect(calculated.iconBoxX, calculated.iconBoxY, calculated.iconBoxWidth, calculated.iconBoxHeight);
        }
        graphics.endFill();

        this.buildRightZoneSprite(calculated.iconBoxX, calculated.iconBoxY, calculated.iconBoxWidth, calculated.iconBoxHeight);

        let zoneWidth = this.ItemImageWidth;
        let zoneHeight = this.ItemImageHeight;
        let zoneX = "(" + x + " + " + width + " / 2 - " + zoneWidth + " / 2)";
        let zoneY = "(" + iconBoxY + " - " + zoneHeight + " * 0.75)";

        this.buildDropZone(zoneX, zoneY, zoneWidth, zoneHeight);
        this.buildCharacterInAction(iCharInZone, iconBoxX, iconBoxY, iconBoxWidth, iconBoxHeight);
    }

    buildRightZoneSprite(x, y, width, height, spriteName = null)
    {
        if (!this.sprites.rightSprite)
        {
            let texture = spriteName;
            if (!texture)
            {
                texture = "upgrade_relax";
            }
            let visual = Library.getTextureFromAtlas("ui", texture);
            this.sprites.rightSprite = new SpriteResponsive(visual).init({"ui": this.UI});

            this.addChild(this.sprites.rightSprite);
        }

        let sprite = this.sprites.rightSprite;
        sprite.alpha = this.Opacity;

        let ratio = sprite.height / sprite.width;
        let spriteWidth = this.calculate(width);
        let spriteHeight = spriteWidth * ratio;
        let spriteX = this.calculate(this.center(x, width, spriteWidth));
        let spriteY = this.calculate(y + " + " + height + " - " + spriteHeight);

        sprite.width = spriteWidth;
        sprite.height = spriteHeight;
        sprite.position.set(spriteX, spriteY);
    }

    buildDropZone(x, y, width, height)
    {
        if (!this.sprites.dropZone)
        {
            let texture = "square_dashed";
            let visual = Library.getTextureFromAtlas("ui", texture);
            this.sprites.dropZone = new SpriteResponsive(visual).init({"ui": this.UI});
            this.sprites.dropZone.tint = this.DropZoneNormalColor;

            this.addChild(this.sprites.dropZone);
        }

        x = this.calculate(x);
        y = this.calculate(y);
        width = this.calculate(width);
        height = this.calculate(height);

        let sprite = this.sprites.dropZone;
        sprite.alpha = this.Opacity;
        sprite.tint = this.DropZoneNormalColor;

        this.dropRect = {"x": x, "y": y, "width": width, "height": height};

        sprite.width = width;
        sprite.height = height;
        sprite.position.set(x, y);

        if (!this.texts.dropZone)
        {
            this.texts.dropZone = new TextResponsive().init({"ui": this.UI});
            this.addChild(this.texts.dropZone);
        }

        let label = this.DropZoneLabel;
        let text = this.texts.dropZone;
        text.alpha = this.Opacity;

        let style = new TextStyle({
            fontFamily: this.UI.Values.general.text.font,
            fontSize:   this.evaluate(this.valueFormula(this.UI.Values.general.text.size * 0.75)),
            fontWeight: this.UI.Values.general.text.weight,
            fill: this.UI.Values.general.text.color,
            align: "center",
            wordWrap: true,
            wordWrapWidth: width * 0.85
        });

        let metrics = TextMetrics.measureText(label, style);

        let textX = x + width / 2 - metrics.width / 2;
        let textY = y + height / 2 - metrics.height / 2;

        text.text = label;
        text.style = style;
        text.width = metrics.width;
        text.height = metrics.height;
        text.position.set(textX, textY);
    }

    buildCharacterInAction(iCharacter, fX, fY, fWidth, fHeight)
    {
        let statValue = 1;
        let maxStatValue = 1;
        if (!this.gauges.action)
        {
            let rWidth = "((" + this.drawHeight + " - " + this.PaddingVertical + ") / 3 * 0.8) * 1.5";

            this.gauges.action = new WindowGauge()
            this.gauges.action.init({
                "ui": this.UI,
                "type": this.getGaugeType(this.gauges.action),
                "value": statValue / maxStatValue,
                "rWidth": fWidth
            });
            this.addChild(this.gauges.action);
        }
        this.removeChild(this.gauges.action);

        if (!this.texts.action)
        {
            this.texts.action = new TextResponsive().init({"ui": this.UI});
            this.addChild(this.texts.action);
        }
        this.removeChild(this.texts.action);

        if (!this.stopButton)
        {
            this.stopButton = new TextButton().init({
                "ui": this.UI,
                "text": this.StopLabel
            });
            this.stopButton.on(this.stopButton.EVENT_CLICK, this.fctOnStop);//@
            this.addChild(this.stopButton);
        }
        this.removeChild(this.stopButton);

        if (iCharacter !== null)
        {
            statValue = this.getCharacterStat(iCharacter, false);
            maxStatValue = this.getCharacterStat(iCharacter, true);

            let text = this.texts.action;
            let label = this.UI.CharacterManager.getCharacterName(iCharacter);

            let style = new TextStyle({
                fontFamily: this.UI.Values.general.title.font,
                fontSize:   this.evaluate(this.valueFormula(this.UI.Values.general.title.size)),
                fontWeight: this.UI.Values.general.title.weight,
                fill: this.UI.Values.general.text.color,
                align: "center"
            });

            let metrics = TextMetrics.measureText(label, style);

            let textX = this.center(fX, fWidth, metrics.width);
            let textY = fY + " + " + fHeight + " + (" + fHeight + " * 0.05)";

            text.text = label;
            text.style = style;
            text.width = metrics.width;
            text.height = metrics.height;
            text.position.set(this.calculate(textX), this.calculate(textY));

            let size = Display.getSize();

            this.gauges.action.Value = statValue / maxStatValue;
            this.gauges.action.rX = [{on:"default", x: fX}];
            this.gauges.action.rY = [{on:"default", y: textY + " + " + metrics.height + " + " + fHeight + " * 0.09"}];
            this.gauges.action.layout(size);

            this.stopButton.rX = [{on:"default", x:fX + " + " + fWidth + " / 2 - " + this.stopButton.size.width + " / 2"}];
            this.stopButton.rY = [{on:"default", y: textY + " + " + metrics.height + " + " + fHeight + " * 0.25"}];
            this.stopButton.layout(size);    

            this.addChild(this.texts.action);
            this.addChild(this.gauges.action);
            this.addChild(this.stopButton);        
        }
    }

    buildCharacter(iCharacter, iCharInZone)
    {
        let bIsBusy = this.getIsBusy(iCharacter);

        if (!this.graphics["c" + iCharacter])
        {
            this.graphics["c" + iCharacter] = new GraphicsResponsive().init({"ui": this.UI});
            this.graphics["c" + iCharacter].interactive = true;

            this.drags["c" + iCharacter] = new ContainerDragger().init({
                "ui": this.UI,
                "draggables": this.graphics["c" + iCharacter],
                "dropZones": this.sprites.dropZone,
                "id": iCharacter
            });

            this.drags["c" + iCharacter].on(this.drags["c" + iCharacter].EVENT_DRAG_START, this.fctOnDragStart);//@
            this.drags["c" + iCharacter].on(this.drags["c" + iCharacter].EVENT_ENTER_DROP_ZONE, this.fctOnDragEnterDropZone);//@
            this.drags["c" + iCharacter].on(this.drags["c" + iCharacter].EVENT_EXIT_DROP_ZONE, this.fctOnDragExitDropZone)//@
            this.drags["c" + iCharacter].on(this.drags["c" + iCharacter].EVENT_DRAG_END, this.fctOnDragEnd);//@
            this.drags["c" + iCharacter].on(this.drags["c" + iCharacter].EVENT_DROP, this.fctOnDragDrop);//@
            this.drags["c" + iCharacter].on(this.drags["c" + iCharacter].EVENT_DRAG_END_ANIMATION_FINISHED, this.fctOnDragAnimationEnd);//@
        }

        if (!this.graphics["l" + iCharacter])
        {
            this.graphics["l" + iCharacter] = new GraphicsResponsive().init({"ui": this.UI});
        }

        this.removeChild(this.graphics["c" + iCharacter]);
        this.removeChild(this.graphics["l" + iCharacter]);

        let width = "(" + this.ItemWidth + " * 0.85)";
        let height = this.ItemHeight;
        let x = "(" + this.PaddingHorizontal + " / 2)";
        let y = "(" + this.PaddingVertical + " / 2 + " + height + " * " + iCharacter + ")";

        if (iCharacter > 0)
        {
            let lineGraphics = this.graphics["l" + iCharacter];
            lineGraphics.alpha = this.Opacity * 0.5;

            let lineHeight = this.evaluate(this.valueFormula(3));

            lineGraphics.beginFill(0xFFFFFF, 1);
            lineGraphics.drawRect(this.calculate(x), this.calculate(y + " - " + lineHeight + " / 2"), this.calculate(width), lineHeight);
            lineGraphics.endFill();

            this.addChild(this.graphics["l" + iCharacter]);
        }

        let itemGraphics = this.graphics["c" + iCharacter];

        let canDrag = iCharInZone === null;
        this.drags["c" + iCharacter].Id = iCharacter;
        this.drags["c" + iCharacter].CanDrag = canDrag;
        itemGraphics.buttonMode = canDrag;

        let imageWidth = this.ItemImageWidth;
        let imageHeight = this.ItemImageHeight;
        let imagePadding = "(" + height + " - " + imageHeight + ")";
        let imageX = "(" + x + " + " + imagePadding + " / 2)";
        let imageY = "(" + y + " + " + imagePadding + " / 2)";

        let calculated = {
            imageX: this.calculate(imageX),
            imageY: this.calculate(imageY),
            imageWidth: this.calculate(imageWidth),
            imageHeight: this.calculate(imageHeight),
            imagePadding: this.calculate(imagePadding)
        };

        if (iCharacter === iCharInZone)
        {
            itemGraphics.position.set(this.sprites.dropZone.position.x, this.sprites.dropZone.position.y);
        }
        else
        {
            itemGraphics.position.set(calculated.imageX, calculated.imageY);
        }

        let imageBgColor = this.ImageBackgroundColor;
        let imageStrokeWidth = this.ImageStrokeWidth;
        let imageStrokeColor = this.ImageStrokeColor;
        let imageCornerRadius = this.ImageCornerRadius;

        if (iCharInZone !== null && iCharacter !== iCharInZone)
        {
            let t = 0.1;
            imageStrokeColor = Lerp.lerpColorHex(imageStrokeColor, 0x000000, t);
            imageBgColor = Lerp.lerpColorHex(imageBgColor, 0x000000, t);
        }

        if (imageStrokeWidth > 0)
        {
            itemGraphics.lineStyle({"color":imageStrokeColor, "width": imageStrokeWidth, "alignment": 1});
        }
        
        itemGraphics.beginFill(imageBgColor, 1);

        if (imageCornerRadius > 0)
        {
            itemGraphics.drawRoundedRect(0, 0, calculated.imageWidth, calculated.imageHeight, imageCornerRadius);
        }
        else
        {
            itemGraphics.drawRect(0, 0, calculated.imageWidth, calculated.imageHeight);
        }

        itemGraphics.endFill();

        if (!this.graphics["c_placeholder" + iCharacter])
        {
            this.graphics["c_placeholder" + iCharacter] = new GraphicsResponsive().init({"ui": this.UI});
            this.graphics["c_placeholder" + iCharacter].interactive = true;

            this.addChild(this.graphics["c_placeholder" + iCharacter]);
        }

        let placeholder = this.graphics["c_placeholder" + iCharacter];
        placeholder.alpha = this.Opacity * 0.5

        placeholder.position.set(calculated.imageX, calculated.imageY);
        placeholder.beginFill(imageBgColor, 1);
        if (imageCornerRadius > 0)
        {
            placeholder.drawRoundedRect(0, 0, calculated.imageWidth, calculated.imageHeight, imageCornerRadius);
        }
        else
        {
            placeholder.drawRect(0, 0, calculated.imageWidth, calculated.imageHeight);
        }
        placeholder.endFill();

        

        if (!bIsBusy || iCharacter === iCharInZone)
        {
            this.addChild(this.graphics["c" + iCharacter]);
        }

        this.buildCharacterPortrait(iCharacter, imageWidth, imageHeight, itemGraphics, (iCharInZone !== null && iCharacter !== iCharInZone));

        if (this.texts["c" + iCharacter])
        {
            this.removeChild(this.texts["c" + iCharacter]);
        }

        if (this.gauges["c" + iCharacter])
        {
            this.removeChild(this.gauges["c" + iCharacter]);
        }

        if (!bIsBusy)
        {
            this.buildCharacterName(iCharacter, imageX, imageY, imageWidth, imageHeight, iCharInZone !== null);
            this.buildCharacterStatBar(iCharacter, imageX, imageY, imageWidth, imageHeight);
        }
    }

    buildCharacterPortrait(iCharacter, fImageWidth, fImageHeight, objParent, isDisabled)
    {
        let width = "(" + fImageWidth + " - " + this.ImageCornerRadius + " * 2)";
        let height = "(" + fImageWidth + " - " + this.ImageCornerRadius + " * 2)";

        if (!objParent.characterContainer)
        {
            let container = new ContainerResponsive().init({"ui": this.UI});

            let character = new Character().init({
                "ui": this.UI,
                "build": this.UI.CharacterManager.getCharacterBuild(iCharacter)
            });

            character.rWidth = [{on:"default", width: fImageWidth  + " * 2"}];
            character.rHeight = [{on:"default", height: fImageHeight + " * 2"}];
            character.rX = [{on:"default", x: "-" + fImageWidth + " / 2.33"}];
            character.rY = [{on:"default", y: "-" + fImageHeight + " / 4"}];

            for (let key in character.children)
            {
                character.children[key].originalTint = character.children[key].tint;
            }

            container.addChild(character);

            let mask = new GraphicsResponsive().init({"ui": this.UI});

            container.characterMask = mask;
            container.addChild(mask);
            mask.position.set(0, 0);
            container.mask = mask;

            objParent.character = character;
            objParent.characterContainer = container;
            objParent.addChild(container);
        }

        let mask = objParent.characterContainer.characterMask;
        mask.clear();
        mask.beginFill(0xFFFFFF, 1);
        mask.drawRect(0, 0, this.calculate(fImageWidth), this.calculate(fImageHeight));
        mask.endFill(0xFFFFFF, 1);

        let character = objParent.character;
        character.rWidth = [{on:"default", width: fImageWidth  + " * 2"}];
        character.rHeight = [{on:"default", height: fImageHeight + " * 2"}];
        character.rX = [{on:"default", x: "-" + fImageWidth + " / 2.33"}];
        character.rY = [{on:"default", y: "-" + fImageHeight + " / 4"}];

        let tint = 0xFFFFFF;
        if (!this.IsSelected)
        {
            tint = 0x000000;
        }
        for (let key in objParent.character.children)
        {
            objParent.character.children[key].tint = Lerp.lerpColorHex(objParent.character.children[key].originalTint, tint, (!isDisabled ? 0 : 0.3));
        }

        objParent.characterContainer.layout(Display.getSize());
    }

    buildCharacterName(iCharacter, fImageX, fImageY, fImageWidth, fImageHeight, bIsDisabled)
    {
        if (!this.texts["c" + iCharacter])
        {
            this.texts["c" + iCharacter] = new TextResponsive().init({"ui": this.UI});
            this.removeChild(this.texts["c" + iCharacter]);
        }

        let text = this.texts["c" + iCharacter];
        let label = this.UI.CharacterManager.getCharacterName(iCharacter);

        text.alpha = this.Opacity * (bIsDisabled ? this.ItemDisabledAlpha : 1);

        let style = new TextStyle({
            fontFamily: this.UI.Values.general.title.font,
            fontSize:   this.evaluate(this.valueFormula(this.UI.Values.general.title.size)),
            fontWeight: this.UI.Values.general.title.weight,
            fill: this.UI.Values.general.text.color,
            align: "center"
        });

        let metrics = TextMetrics.measureText(label, style);

        let textX = fImageX + " + " + fImageWidth + " * 1.25";
        let textY = fImageY + " + " + fImageHeight + " * 0.175";

        text.text = label;
        text.style = style;
        text.width = metrics.width;
        text.height = metrics.height;
        text.position.set(this.calculate(textX), this.calculate(textY));

        this.addChild(this.texts["c" + iCharacter]);
    }

    buildCharacterStatBar(iCharacter, fImageX, fImageY, fImageWidth, fImageHeight)
    {
        let statValue = this.getCharacterStat(iCharacter, false);
        let maxStatValue = this.getCharacterStat(iCharacter, true);

        let rWidth = "((" + this.drawHeight + " - " + this.PaddingVertical + ") / 3 * 0.8) * 1.5";

        if (!this.gauges["c" + iCharacter])
        {
            this.gauges["c" + iCharacter] = new WindowGauge();
            this.gauges["c" + iCharacter].init({
                "ui": this.UI,
                "type": this.getGaugeType(this.gauges["c" + iCharacter]),
                "value": statValue / maxStatValue,
                rWidth
            });
            this.removeChild(this.gauges["c" + iCharacter]);
        }

        let gauge = this.gauges["c" + iCharacter];

        gauge.Value = statValue / maxStatValue;
        gauge.rX = [{on:"default", x: fImageX + " + " + fImageWidth + " * 1.25"}];
        gauge.rY = [{on:"default", y: fImageY + " + " + fImageHeight + " - " + fImageHeight + " * 0.4"}];

        this.addChild(this.gauges["c" + iCharacter]);
    }

    /*******************************************
    *   GAUGES
    *******************************************/
    updateGaugesValue()
    {
        for (let i = 0; i < 3; i++)
        {
            if (this.gauges["c" + i])
            {
                this.updateGaugeValue(i, this.gauges["c" + i]);
            }
        }

        if (this.characterInAction >= 0 && this.gauges.action)
        {
            this.updateGaugeValue(this.characterInAction, this.gauges.action);
        }

        if (this.updateValue)
        {
            window.requestAnimationFrame(() => { this.updateGaugesValue(); });
        }
    }

    updateGaugeValue(iCharacter, objGauge)
    {
        if (objGauge)
        {
            let value = this.UI.CharacterManager.getCharacterStress(iCharacter) / this.UI.CharacterManager.getCharacterMaxStress(iCharacter);
            if (value != objGauge.Value)
            {
                objGauge.Value = value;
            }
        }
    }

    /*******************************************
    *   UTILITIES
    *******************************************/
    calculate(strValue)
    {
        return this.evaluate(strValue, 0, 0, 0, 0, this);
    }

    center(value, parentWidth, currentWidth)
    {
        return "(" + value + " + (" + parentWidth + ") / 2 - (" + currentWidth + ") / 2)";
    }

    /*******************************************
    *   EVENTS
    *******************************************/
    onDragStart(sender, objContainer, fctCancelDrag)
    {
        for (let key in this.drags)
        {
            if (sender.Id != key)
            {
                this.drags[key].CanDrag = false;
            }
        }

        //Done for the element to be above the window's close button
        let parent = this.parent;
        parent.removeChild(this);
        parent.addChild(this);

        this.removeChild(objContainer);
        this.addChild(objContainer);
    }

    onDragEnd(sender, objContainer)
    {
        let bIsAction = false;
        for (let i = 0; i < 3; i++)
        {
            if (this.getIsAction(i))
            {
                bIsAction = true;
                break;
            }
        }

        for (let key in this.drags)
        {
            this.drags[key].CanDrag = !bIsAction;
        }
    }

    onDragDrop(sender, objContainer, objDropZone, fctCancelDrop)
    {
        this.dropped = true;
        for (let key in this.drags)
        {
            this.drags[key].CanDrag = false;
        }
        this.emit(this.EVENT_START_ACTION, sender.Id);
    }

    onDragEnterDropZone(sender)
    {
        this.sprites.dropZone.tint = this.DropZoneHoverColor;
    }

    onDragExitDropZone(sender)
    {
        this.sprites.dropZone.tint = this.DropZoneNormalColor;
    }

    onDragAnimationEnd(sender)
    {
        this.emit(this.EVENT_DRAG_ANIMATION_END, this);
    }

    onStop(sender)
    {
        this.emit(this.EVENT_STOP_ACTION, this.characterInAction);
    }

    onCharacterStatChanged(strType, fNewValue, iCharacter)
    {
        this.updateGaugeValue(iCharacter, this.gauges["c" + iCharacter]);
        if (iCharacter == this.characterInAction)
        {
            this.updateGaugeValue(iCharacter, this.gauges.action);
        }
    }
}