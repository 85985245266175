import {Howl,Howler} from "howler";
import {RepeatWrapping} from "three";
import * as PIXI from "pixi.js";

export default class Library
{
    constructor ()
    {
        Library.instance = this;

        this.datas = {};
        this.audios = {};
        this.textures3DFrames = {};
        this.textures3D = {};
        this.textures3DGroup = {};
    }

    initialize (resources)
    {
        if (this.resources) //we want to merge with existing resources
            resources = Object.assign(this.resources, resources);

        this.resources = resources;

        this.textures = [];
    }

    static arrayBufferToBase64( buffer )
    {
        let binary = '';
        let bytes = new Uint8Array( buffer );
        let len = bytes.byteLength;
        for (let i = 0; i < len; i++) {
            binary += String.fromCharCode( bytes[ i ] );
        }
        return window.btoa( binary );
    }

    static addAudio (key,audio)
    {
        Library.instance.audios[key] = audio;
    }

    static getAudio (key)
    {
        return Library.instance.audios[key];
    }

    static addData (key, data)
    {
        Library.instance.datas[key] = data;
    }

    static addTextures3DFrames (key, data)
    {
        Library.instance.textures3DFrames[key] = data;
    }

    static addTexture3D (key, data)
    {
        Library.instance.textures3D[key] = data;
    }

    static getTexture3DFramesName (key)
    {
        let frames = Library.instance.textures3DFrames[key];
        return frames.map( f => f.filename);
    }

    static getTexture3DFrame(key, filename)
    {
        let arrAtlas = key.split("|");

        for (let i = 0; i < arrAtlas.length; i++)
        {
            let atlas = arrAtlas[i];
            let frames = Library.instance.textures3DFrames[atlas];

            if (!frames)
            {
                continue;
            }

            let frame = frames.find(f => f.filename === filename);

            if (frame)
                return frame;
        }

        return null;
    }

    static getTexture3DSize(key, filename)
    {
        if (!filename)
        {
            let texture = Library.getTexture3D(key);

            if (texture)
                return {"w": texture.image.width, "h": texture.image.height};

            return {"w": 0, "h": 0};
        }
        
        let frame = Library.getTexture3DFrame(key, filename);
        return frame.sourceSize;
    }


    static getTexture3DSizeFromTexture(texture)
    {
        return {"w": texture.image.width, "h": texture.image.height};

    }

    static getTexture3D(key, filename)
    {
        if (!filename)
        {
            return Library.instance.textures3D["textures-" + key];
        }

        let frame = Library.getTexture3DFrame(key, filename);

        if (!frame)
        {
            let frame = Library.getTexture3DFrame(key, filename);
            console.error("Cannot find in Library.getTexture3D", key, filename)

            return null;
        }

        if (!frame.texture)
        {
            let texture = (Library.instance.textures3D[frame.key]).clone();

            let w = texture.image.naturalWidth;
            let h = texture.image.naturalHeight;

            let textureWidth = frame.sourceSize.w / w;
            let textureHeight = frame.sourceSize.h / h;
            let offsetX = frame.frame.x / w;
            let offsetY = ((h - frame.frame.y) / h) - textureHeight;

            texture.frameSize = {"width": frame.sourceSize.w, "height": frame.sourceSize.h};

            texture.wrapS = texture.wrapT = RepeatWrapping;
            texture.repeat.set(textureWidth, textureHeight);

            texture.offset.x = offsetX;
            texture.offset.y = offsetY;

            texture.needsUpdate = true;

            frame.texture = texture;

            //console.log(atlasTexture);
        }

        return frame.texture;
    }

    static getData (key)
    {
        return Library.instance.datas[key];
    }

    static setResources (resources)
    {
        Library.instance.initialize(resources);
    }

    static getSpritesheet (strKey)
    {
        return Library.instance.resources[strKey].spritesheet;
    }

    static getAnimation (strKey)
    {
        //console.log("getAnimations", strKey);
        let spritesheet = Library.getSpritesheet(strKey);
        let arrKeys = Object.keys(spritesheet.animations);

        if(arrKeys.length > 0)
        {
            let strKeyAnimations = arrKeys.shift();

            return spritesheet.animations[strKeyAnimations];
        }

        return null;
    }

    static getTextureFromResources (strKey)
    {
        if (!Library.instance.resources[strKey])
        {
            console.log("%c is not a valid resource in the Library", strKey);
            return null;
        }

        return Library.instance.resources[strKey].texture;
    }

    static getTextureFromAtlas(strAtlas, strKey)
    {
        let list = this.getTextureListFromAtlas(strAtlas);
        if (list)
        {
            return list[strKey];
        }
        return null;
    }

    static getTextureListFromAtlas(strAtlas)
    {
        if (Library.instance.resources[strAtlas] && Library.instance.resources[strAtlas].textures)
        {
            return Library.instance.resources[strAtlas].textures;
        }
        return null;
    }

    static getAtlas(strAtlas)
    {
        if (Library.instance.resources[strAtlas])
        {
            return Library.instance.resources[strAtlas];
        }
        return null;
    }

    static get2DTextureFromAtlas3D(strAtlas, strKey)
    {
        let frame = Library.getTexture3DFrame(strAtlas, strKey);

        let frames = Library.instance.resources[strAtlas].data.frames;

        let index = -1;
        for (let key in frames)
        {
            if (frames[key].filename == strKey)
            {
                index = key;
                break;
            }
        }

        if (index > -1)
            return Library.instance.resources[strAtlas].textures[index];

        let l = Library.instance;
        let textures = Library.instance.resources[strAtlas].textures;
        let strIndex = frame.filename;

        let texture = textures[strIndex];

        if (!texture)
        {
            let texture3D = Library.instance.textures3D[frame.key];
            let image = texture3D.image;

            let options = {width:frame.frame.w, height: frame.frame.h};
            let baseTexture = new PIXI.BaseTexture(image, options);
            texture = new PIXI.Texture(baseTexture, frame.frame, frame.spriteSourceSize );
            //texture = new PIXI.Texture()
        }
        return texture;
    }

    static getTexture (strKey)
    {
        return Library.instance.textures[strKey];
    }

    static add3DTextureToGroup (strGroup, strKey, texture)
    {
        if (!Library.instance.textures3DGroup[strGroup])
        {
            Library.instance.textures3DGroup[strGroup] = {}
        }

        Library.instance.textures3DGroup[strGroup][strKey] = texture;


    }

    static get3DTextureFromGroup (strGroup, strKey)
    {
        if (!Library.instance.textures3DGroup[strGroup])
        {
            return null;
        }

        if (!Library.instance.textures3DGroup[strGroup][strKey])
        {
            return null;
        }

        return Library.instance.textures3DGroup[strGroup][strKey];
    }
    
    //UTILS - this should be move to the AudioManager class
    static getAudiospriteDuration (strAudio, strSprite)
    {
        let audio = Library.getData(strAudio);

        if (!audio[strSprite])
            return -1;

        return audio[strSprite][1];
    }
}

let __library__ = new Library();