import { string2hex }  from "@pixi/utils"; 
import { TextStyle } from "@pixi/text";
import { TextMetrics } from "@pixi/text";
import { Vector2 } from "three";
import Dialog from "./Dialog.js";
import GraphicsResponsive from "../responsive/GraphicsResponsive.js";
import TextResponsive from "../responsive/TextResponsive.js";

export default class ChoiceDialog extends Dialog
{
    constructor()
    {
        super();
    }

    get EVENT_CHOICE_PICKED() { return "choice-picked"; }

    get MIN_CHOICE_COUNT() { return 1; }
    get MAX_CHOICE_COUNT() { return 4; }

    get ChoiceCount() { return Math.min(this.MAX_CHOICE_COUNT, Math.max(this.MIN_CHOICE_COUNT, Object.keys(this.choices).length)); }

    get ChoiceBoxY() { return this.BgHeight + " + " + this.ChoiceBoxMargin; }
    get ChoiceBoxWidth()
    {
        let length = this.ChoiceCount;
        let spacing = "(" + this.ChoiceBoxMargin + ") * " + (length - 1);

        return "(" + this.BgWidth + " - " + spacing + ") / " + length;
    }
    get ChoiceBoxHeight()
    {
        let longest = "";
        for (let key in this.choices)
        {
            if (this.choices[key].length > longest.length)
            {
                longest = this.choices[key];
            }
        }

        let metrics = TextMetrics.measureText(longest, this.getChoiceBoxTextStyle());
        return metrics.height + " + (" + this.ChoiceBoxPadding + ") * 2";
    }

    get ChoiceBoxMargin() { return "ih * 1.25%"; }
    get ChoiceBoxPadding() { return "ih * 2%"; }
    get ChoiceCornerRadius() { return this.CornerRadius * 0.75; }

    getChoiceBoxStrokeColor(bIsSelected = false)
    { 
        return string2hex(this.UI.Values.dialog.choice[(bIsSelected ? "selected" : "normal")].stroke.color);
    }

    getChoiceBoxTextStyle(bIsSelected = false)
    {
        return new TextStyle({
            fontFamily: this.UI.Values.general.text.font,
            fontSize: this.DialogFontSize,
            fontWeight: this.UI.Values.general.text.weight,
            fill: this.UI.Values.dialog.choice[(bIsSelected ? "selected" : "normal")].text.color,
            align: "left",
            wordWrap: true,
            wordWrapWidth: this.evaluate(this.ChoiceBoxWidth + " - (" + this.ChoiceBoxPadding + ")*2", 0, 0, 0, 0, this)
        });
    }

    /*******************************************
    *   INITIALIZATION
    *******************************************/
    /**
        Parameters to pass to the init function:
        - ui:           UI section object where this dialog resides
        - title:        Speaker's name to display in the dialog
        - content:      Message to display in the dialog
        - choices:      Key-Value pair object where the key is the ID and the value is the content of the choice. Between 2 and 4 choices have to be defined
        - audioId:      (Optional) Id of the audio sound to play when displaying this dialog. Default is NULL
        - showOnStart   (Optional) If the dialog should be shown on start. Default is TRUE
        - closeOnPick   (Optional) If this dialog should be closed when the player picks a choice. Default is TRUE
    */
    init(meta)
    {
        this.choices = meta.choices;
        this.boxes = [];
        this.closeOnPick = ("closeOnPick" in meta ? meta.closeOnPick : true);

        return super.init(meta);
    }

    createClosure ()
    {
        super.createClosure();
        this.fctOnMouseMove = this.onMouveMove.bind(this);
    }

    bindEvents()
    {
        super.bindEvents();
        window.addEventListener("mousemove", this.fctOnMouseMove);
    }

    destroy(options)
    {
        window.removeEventListener("mousemove", this.fctOnMouseMove);

        for (let i = 0; i < this.boxes.length; i++)
        {
            this.boxes[i].removeChild(this.boxes[i].textContent);
            this.boxes[i].textContent.destroy(options);
        }
        delete this.boxes;

        super.destroy(options);
    }

    /*******************************************
    *   BUILD
    *******************************************/
    build ()
    {
        super.build();

        this.buildChoices();
    }

    clean()
    {
        super.clean();

        for (let i = this.ChoiceCount; i < this.boxes.length; i++)
        {
            if (this.boxes[i] && this.boxes[i].parent)
            {
                this.removeChild(this.boxes[i]);
            }
        }
    }

    buildChoices()
    {
        //let keys = Object.keys(this.choices);
        let keys = this.choices;
        for (let i = 0; i < Math.max(keys.length, this.MIN_CHOICE_COUNT) && i < this.MAX_CHOICE_COUNT; i++)
        {
            this.buildChoice((i >= keys.length ? null : keys[i]), i);
        }
    }

    buildChoice(choice, iIndex)
    {
        this.buildChoiceBackground(choice, iIndex);
        this.buildChoiceText(choice, iIndex);
    }

    buildChoiceBackground(choice, iIndex)
    {
        let id = "box" + iIndex;
        if (!this.graphics[id])
        {
            this.graphics[id] = new GraphicsResponsive().init({"ui": this.UI});
            this.graphics[id].on("pointerup", this.onChoiceClick.bind(this, choice.action));
            this.graphics[id].interactive = true;
            this.graphics[id].buttonMode = true;

            this.boxes.splice(iIndex, 0, this.graphics[id]);
            this.graphics[id].selectedState = false;

            this.addChild(this.graphics[id]);
        }
        else
        {
            this.graphics[id].clear();
        }

        let graphics = this.graphics[id];
        let text = this.graphics[id].textContent;

        let rWidth = this.ChoiceBoxWidth;
        let rHeight = this.ChoiceBoxHeight;

        let width = this.evaluate(rWidth, 0, 0, 0, 0, this);
        let height = this.evaluate(rHeight, 0, 0, 0, 0, this);
        let padding = this.evaluate(this.ChoiceBoxPadding, 0, 0, 0, 0, this);

        let bgColor = this.BackgroundColor;
        let strokeWidth = this.StrokeWidth;
        let strokeColor = this.getChoiceBoxStrokeColor(graphics.selectedState);
        let cornerRadius = this.ChoiceCornerRadius;

        if (strokeWidth > 0)
        {
            graphics.lineStyle({"color":strokeColor, "width":strokeWidth, "alignment":1});
        }

        graphics.beginFill(bgColor, 1);
        if (cornerRadius > 0)
        {
            graphics.drawRoundedRect(0, 0, width, height, cornerRadius);
        }
        else
        {
            graphics.drawRect(0, 0, width, height);
        }
        graphics.endFill();

        graphics.x = this.evaluate("(" + rWidth + " + " + this.ChoiceBoxMargin + ") * " + iIndex, 0, 0, 0, 0, this);
        graphics.y = this.evaluate(this.ChoiceBoxY, 0, 0, 0, 0, this);

        this.graphics[id].hoverRect = {
            "min": graphics.toGlobal(new Vector2(0, 0)),
            "max": graphics.toGlobal(new Vector2(width, height))
        };

        if (!graphics.parent)
        {
            this.addChild(graphics);
        }
    }

    buildChoiceText(choice, iIndex)
    {
        let id = "box" + iIndex;
        if (!this.graphics[id].textContent)
        {
            this.graphics[id].textContent = new TextResponsive().init({"ui": this.UI});
            this.graphics[id].addChild(this.graphics[id].textContent);
        }

        let text = this.graphics[id].textContent;

        let label = choice.choice;
        let style = this.getChoiceBoxTextStyle(this.graphics[id].selectedState);

        let metrics = TextMetrics.measureText(label, style);

        let boxWidth = this.evaluate(this.ChoiceBoxWidth, 0, 0, 0, 0, this);
        let boxHeight = this.evaluate(this.ChoiceBoxHeight, 0, 0, 0, 0, this);

        text.text = label;
        text.style = style;
        text.x = boxWidth / 2 - metrics.width / 2;
        text.y = boxHeight / 2 - metrics.height / 2;
        text.width = metrics.width;
        text.height = metrics.height;
    }

    buildContinueButton ()
    {
        //We override with an empty function to kill the continue button
    }

    /*******************************************
    *   HOVER DETECTION
    *******************************************/
    updateHover(fX, fY)
    {
        //let keys = Object.keys(this.choices);
        let keys = this.choices;
        for (let i = 0; i < this.boxes.length && i < keys.length; i++)
        {
            if (this.boxes[i].parent)
            {
                let rect = this.boxes[i].hoverRect;
                let selected = fX >= rect.min.x && fX <= rect.max.x && fY >= rect.min.y && fY <= rect.max.y;

                if (this.boxes[i].selectedState != selected)
                {
                    this.boxes[i].selectedState = selected;
                    this.buildChoice(keys[i], i);
                }
            }
        }
    }

    /*******************************************
    *   ACTIONS
    *******************************************/
    /**
        Updates the dialog choices displayed on screen
        @param objNewChoices    Key-Value pair object where the key is the ID and the value is the content of the choice. 
                                Between 2 and 4 choices should be defined
    */
    updateChoices(objNewChoices)
    {
        this.choices = objNewChoices;
        this.resetBoxStates();

        this.build();
    }

    hide()
    {
        super.hide();
        this.resetBoxStates();

        // if (this.IsShown)
        // {
        //     if (this.LinkedPopup)
        //     {
        //         this.LinkedPopup.hide();
        //     }
            this.UI.removeFromLayoutable(this);

            this.UI.AudioManager.stopDialog();
            this.emit(this.EVENT_CLOSE, this);

        // }
    }

    resetBoxStates()
    {
        if (this.boxes)
        {
            for (let i = 0; i < this.boxes.length; i++)
            {
                this.boxes[i].selectedState = false;
            }
        }
    }

    /*******************************************
    *   EVENTS
    *******************************************/
    onPointerDown()
    {
        
    }

    onNextClick()
    {
        
    }

    onMouveMove(e)
    {
        this.updateHover(e.clientX, e.clientY);
    }

    onChoiceClick(strAction)
    {
        if (this.closeOnPick)
        {
            this.hide();
        }

        this.emit(this.EVENT_CHOICE_PICKED, strAction);

        /*
        if (this.boxes[iIndex] )
        {
            let keys = Object.keys(this.choices);
            if (iIndex < keys.length)
            {


                if (this.closeOnPick)
                {
                    this.hide();
                }

                this.emit(this.EVENT_CHOICE_PICKED, keys[iIndex]);
            }
        }*/
        
    }
}