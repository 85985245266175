import BaseCheat from "./BaseCheat.js";

export default class Time extends BaseCheat
{
    constructor(dependencies)
    {
        super(dependencies);
    }

    get CommandUse() { return "time +/-[qty]"; }
    get CommandDesc() { return "Adds or substracts hours to the ingame time. Ommit quantity to get current time"; }

    //---------------------------------------------------------
    //  DEPENDENCIES
    //---------------------------------------------------------
    get WorldManager() { return this.dependencies.get("WorldManager"); }
    //---------------------------------------------------------

    parseCommand(strCmd, arrArguments)
    {
        if (strCmd == "time")
        {
            let qty = 0;
            if (arrArguments.length >= 1)
            {
                qty = parseInt(arrArguments[0]);
            }

            if (qty)
            {
                this.WorldManager.addHours(qty);
                this.log(Math.abs(qty) + " hour" + (Math.abs(qty) > 1 ? "s were " : " was ") + (qty < 0 ? "substracted" : "added") + " to the ingame time");
            }
            else
            {
                let time = Math.round(this.WorldManager.TimeOfDay*10000)/100;
                let nightStart = Math.round((this.WorldManager.dayLength - this.WorldManager.timeOfDayDef.night.duration / 2) / this.WorldManager.dayLength*10000)/100
                let dayStart = Math.round(this.WorldManager.DayStart / this.WorldManager.dayLength*10000)/100;
                console.log("-----------------------------------------------------------------");
                console.log("Currently at " + time + "% of the day");
                console.log("");
                console.log("0% is midnight");
                console.log(dayStart + "% is the start of the morning");
                console.log(nightStart + "% is the end of the evening");
                console.log("-----------------------------------------------------------------");
            }
        }
    }
}