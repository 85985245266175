import * as PF from "pathfinding";
import {Vector2} from "three";
import Constants from "../../utils/Constants.js";

export default class MapGrid
{
    constructor()
    {
        
    }

    //---------------------------------------------------------
    //  DEPENDENCIES
    //---------------------------------------------------------
    get WorldManager() { return this.Dependencies.get("WorldManager"); }
    get SaveManager() { return this.Dependencies.get("SaveManager"); }
    //---------------------------------------------------------

    get Dependencies() { return this.dependencies; }
    get Width() { return this.size.width; }
    get Height() { return this.size.height; }
    get CellSize() { return this.cellSize; }

    /*******************************************
    *   INITIALIZATION
    *******************************************/
    /**
        Parameters to pass to the init function:
        - dependencies: Dependency container used to communicate with the different modules of the game
        - parser:       Obstacle parsers with values where blocking obstacles are on the grid
        - width         Number of tiles horizontally
        - height        Number of tiles vertically
    */
    init(params)
    {
        this.dependencies = params.dependencies;
        this.parser = params.parser;
        this.size = {
            width: params.width, 
            height: params.height
        };

        this.cellSize = Constants.getValue("GRID_CELL_SIZE");
        this.grid = {};
        this.blockingGrid = {};
        this.tracking = {};

        return this;
    }

    /*******************************************
    *   CONVERSION FUNCTIONS
    *******************************************/
    getTileId(iX, iY)
    {
        return iY * this.Width + parseInt(iX);
    }

    getTileIdOutside(iX, iY)
    {
        return iY * 500 + parseInt(iX);
    }

    getTileId2(iX, iY)
    {
        return this.getTileIdOutside(iX, iY);
    }

    getPosFromTileId(iId)
    {
        let y = Math.floor(iId / this.Width);
        let x = iId - y * this.Width;

        return new Vector2(x, y);
    }

    worldToGridPosition(x, y)
    {
        return new Vector2(
            Math.floor(x / this.cellSize), 
            Math.floor(y / this.cellSize)
        );
    }

    gridToWorldPosition(x, y, middleOfCell = false)
    {
        return new Vector2(
            x * this.cellSize + (middleOfCell ? this.cellSize / 2 : 0), 
            y * this.cellSize + (middleOfCell ? this.cellSize / 2 : 0)
        );
    }

    /*******************************************
    *   GRID MODIFICATIONS
    *******************************************/
    /**
        Adds a fixed content on a tile
        @params int iX              X position of tile to put the content on
        @params int iY              Y position of tile to put the content on
        @params array2D blockMatrix 2D array containing the matrix of how to treat the object. The first array is the rows and the second layer 
                                    of arrays is the columns. Use 1 if walkable, 0 if not and 2 or 3 to define the start point (3 is walkable, 2 is blocked). Ex:
                                    [
                                        [0, 1, 0]
                                        [0, 1, 0]
                                        [0, 2, 0]
                                    ]
                                    This array represents a U shaped prop where the start point to calculate on grid is in the middle at the bottom of the prop
                                    and is blocking walkable characters.
        @params object objParams    (Optional) Object containing information regarding on what's on the tile (can be anything). Default is NULL
        @params boolean bCalculate  (Optional) If the walkable grid should be calculated after adding this object. Be warned, if set to FALSE, you have to manually
                                    call the updateGrid method otherwise the grid won't be in sync with its content. Default is TRUE
    */
    addTileContent(iX, iY, blockMatrix, objParams = null, bCalculate = true)
    {
        this.grid[this.getTileId(iX, iY)] = {
            "m": blockMatrix,
            "p": objParams
        };

        if (bCalculate)
        {
            this.updateGrid();
        }
    }

    /**
        Removes a fixed content from a tile
        @params int iX              X position of tile to put the content on
        @params int iY              Y position of tile to put the content on
        @params boolean bCalculate  (Optional) If the walkable grid should be calculated after removing this object. Be warned, if set to FALSE, you have to manually
                                    call the updateGrid method otherwise the grid won't be in sync with its content. Default is TRUE
    */
    removeTileContent(iX, iY, bCalculate = true)
    {
        let id = this.getTileId(iX, iY);
        if (this.grid[id])
        {
            delete this.grid[id];
        }

        if (bCalculate)
        {
            this.updateGrid();
        }
    }

    /**
        Gets what's on a given cell
        @params int iX  X position of tile to get its content
        @params int iY  Y position of tile to get its content
        @return Object if there is something otherwise NULL
    */
    getTileContent(iX, iY)
    {
        let id = getTileId(iX, iY);
        if (this.grid[id])
        {
            return {
                "blockMatrix": this.grid[id].m,
                "params": this.grid[id].p,
            };
        }
        return null;
    }

    /**
        Updates the content of a tile
        @params int iX              X position of tile to update its content
        @params int iY              Y position of tile to update its content
        @params array2D blockMatrix Block matrix to update. Refer to the addTileContent definition for more infos on a block matrix
        @params object objParams    Object containing information regarding on what's on the tile (can be anything). 
    */
    updateTileContent(iX, iY, blockMatrix, objParams)
    {
        let id = getTileId(iX, iY);
        if (this.grid[id])
        {
            this.grid[id].m = blockMatrix;
            this.grid[id].p = objParams;
        }
    }

    /**
        Tracks a moving mesh on the map by its position. Everytime pathfinding is called, the meshes are recalculed to see if they are blocking the way.
        @param int iId          Id of the mesh to track. Use this value to untrack something
        @param object objMesh   Mesh to track its content.
    */
    trackMesh(iId, objMesh)
    {
        this.tracking[iId] = objMesh;
    }

    /**
        Stops tracking position of a moving mesh on the map
        @param int iId          Id of the mesh to stop tracking
    */
    stopTrackingMesh(iId)
    {
        if (this.tracking[iId])
        {
            delete this.tracking[iId];
        }
    }

    /**
        Updates the fixed grid in memory. This grid is used to calculate path in the pathfinding algorithm
    */
    updateGrid()
    {
        this.blockingGrid = {};

        for (let tId in this.grid)
        {
            let pos = this.getPosFromTileId(tId);
            let infos = this.grid[tId];
            let matrix = infos.m;

            if (this.blockingGrid[pos.x] === undefined)
                this.blockingGrid[pos.x] = {};

            this.blockingGrid[pos.x][pos.y] = matrix[0][0];
        }
    }

    updateGrid2()
    {
        this.blockingGrid = {};

        for (let tId in this.grid)
        {
            let pos = this.getPosFromTileId(tId);
            let infos = this.grid[tId];
            let matrix = infos.m;

            let isBlocking = false;
            let startPos = null;

            for (let y = 0; y < matrix.length; y++)
            {

                for (let x = 0; matrix[y] && x < matrix[y].length; y++)
                {
                    if (matrix[y][x] % 2 == 0)
                    {
                        isBlocking = true;
                    }

                    if (matrix[y][x] > 1)
                    {
                        startPos = {x, y}

                        if (isBlocking)
                        {
                            break;
                        }
                    }
                }

                if (startPos && isBlocking)
                {
                    break;
                }
            }

            if (startPos && isBlocking)
            {
                for (let y = 0; y < matrix.length; y++)
                {
                    for (let x = 0; x < matrix[y].length; y++)
                    {
                        if (matrix[y][x] % 2 == 0)
                        {
                            let posX = pos.x + (x - startPos.x);
                            let posY = pos.y + (y - startPos.y);

                            if (!this.blockingGrid[posX])
                            {
                                this.blockingGrid[posX] = {};
                            }
                            this.blockingGrid[posX][posY] = 1;
                        }
                    }
                }
            }
        }
    }

    /*******************************************
    *   PATHFINDING
    *******************************************/
    /**
        Calculates a path for a start point to a destination
        @param int iStartX              X position of the start point
        @param int iStartY              Y position of the start point
        @param int iEndX                X position of the end destination point
        @param int iEndY                Y position of the end destination point
        @param int iRangeWidth          Maximum width for the algorithm to search for a path. The width is split in 2 (half on each side). Further distance
                                        will be ignored. This value acts like creating a subgrid of a certain width to use for the algorithm.
        @param int iRangeWidth          Maximum width for the algorithm to search for a path. The width is split in 2 (half on each side). Further distance
                                        will be ignored. This value acts like creating a subgrid of a certain width to use for the algorithm.
        @param array arrMeshesToIgnore  List of tracked meshes to ignore while calculating. This array can either contains the meshes or their associated id
        @param bool bSkipDiagonals      If the diagonals should be skipped when calculating a path
        @param bool bPreventSwim        If swimmable tiles should prevent pathing or not. Default is FALSE
        @return array           Path containing the list of tile positions to take to reach the destination
    */
    calculatePath(iStartX, iStartY, iEndX, iEndY, iRangeWidth = 30, iRangeHeight = 30, arrMeshesToIgnore = null, bSkipDiagonals = false, bPreventSwim = false)
    {
        if (iStartX < 0 || iStartX > this.Width || iStartY < 0 || iStartY > this.Height ||
            iEndX < 0 || iEndX > this.Width || iEndY < 0 || iEndY > this.Height)
        {
            return [];
        }

        if (Math.abs(iEndX - iStartX) >= iRangeWidth / 2)
        {
            iRangeWidth = Math.ceil(Math.abs(iEndX - iStartX) * 2.5);
        }
        if (Math.abs(iEndY - iStartY) >= iRangeHeight / 2)
        {
            iRangeHeight = Math.ceil(Math.abs(iEndY - iStartY) * 2.5);
        }

        let oldValues = [iStartX, iStartY, iEndX, iEndY, iRangeWidth, iRangeHeight];
        let path = [];
        if (iStartX != iEndX || iStartY != iEndY)
        {
            let objWalkable = this.getGridPathfinding(iStartX, iStartY, iRangeWidth, iRangeHeight, arrMeshesToIgnore, bPreventSwim);

            let grid = new PF.Grid(objWalkable.grid);
            let finder = new PF.DijkstraFinder({
                allowDiagonal: !bSkipDiagonals,
                dontCrossCorners: false
            });

            iEndX = (iEndX - iStartX) + objWalkable.center.x;
            iEndY = (iEndY - iStartY) + objWalkable.center.y;

            iStartX = objWalkable.center.x;
            iStartY = objWalkable.center.y;


            path = finder.findPath(iStartX, iStartY, iEndX, iEndY, grid);

            for (let i = 0; i < path.length; i++)
            {
                path[i][0] += objWalkable.rect.xMin;
                path[i][1] += objWalkable.rect.yMin;
            }
        }

        return path;
    }

    /**
        Calculates the nearest walkable tile from a position on the grid
        @param int iPointX              X position of the point to reach
        @param int iPointY              Y position of the point to reach
        @param int iRelativeX           X position of the point to use to calculate the shortest distance (ex. where the player is located)
        @param int iRelativeY           Y position of the point to use to calculate the shortest distance (ex. where the player is located)
        @param boolean bSkipDiagonals   If diagonals should be skipped or included in the algorithm
        @param array arrMeshesToIgnore  List of tracked meshes to ignore while calculating. This array can either contains the meshes or their associated id
        @param array bPreventSwim       If swimmable tiles should prevent movement and be ignored. Default is FALSE
        @param bool bEmptyTilesOnly     If only empty tiles should be considered. Default is FALSE
        @param bool bSkipRelative       If the relative tile position (iRelativeX, iRelativeY) should be excluded as a choice of a tile
        @param int iMaxRange            Maximum range to look for an empty tile
        @return Vector2                 Position of the tile on the grid 
    */
    getNearestWalkableTile (iPointX, iPointY, iRelativeX, iRelativeY, bSkipDiagonals, arrMeshesToIgnore = null, bPreventSwim = false, bEmptyTilesOnly = false, bSkipRelative = false, iMaxRange = 3)
    {
        let tile = null;
        let distance = null;
        let iRange = 1;

        let grid = this.getGridPathfinding(iPointX, iPointY, iMaxRange, iMaxRange, arrMeshesToIgnore, bPreventSwim);
        if (grid.grid[grid.center.y] && grid.grid[grid.center.y][grid.center.x] == 0 && (!bSkipRelative || iPointX != iRelativeX || iPointY != iRelativeY))
        {
            tile = new Vector2(iPointX, iPointY);
        }
        else
        {
            for (let iRange = 1; iRange <= iMaxRange && tile === null; iRange++)
            {
                let minX = Math.max(0, iPointX - iRange);
                let maxX = Math.min(this.Width, iPointX + iRange);

                let minY = Math.max(0, iPointY - iRange);
                let maxY = Math.min(this.Height, iPointY + iRange);

                for (let y = minY; y <= maxY; y++)
                {
                    for (let x = minX; x <= maxX; x++)
                    {
                        let bIsOk = !bSkipDiagonals || x == iPointX || y == iPointY;
                        bIsOk = bIsOk && (x != iPointX || y != iPointY);
                        
                        let relX = grid.center.x + (x - minX - (iPointX - minX));
                        let relY = grid.center.y + (y - minY - (iPointY - minY));

                        if (bIsOk && bSkipRelative && x == iRelativeX && y == iRelativeY)
                        {
                            bIsOk = false;
                        }

                        if (relY >= 0 && relY < grid.grid.length && relX >= 0 && relX < grid.grid[relY].length)
                        {
                            if (bIsOk && grid.grid[relY][relX] == 0)
                            {
                                if (bEmptyTilesOnly)
                                {
                                    let tileId = this.getTileId(x, y);
                                    bIsOk = false;
                                    if (this.WorldManager.Environment.IsIndoor)
                                    {
                                        bIsOk = !this.WorldManager.getCellContent(
                                            tileId,
                                            this.WorldManager.Environment.Id,
                                            this.WorldManager.Environment.RoomId
                                        );
                                    }
                                    else
                                    {
                                        bIsOk = !this.WorldManager.getCellContent(tileId);
                                    }
                                }
                                if (bIsOk)
                                {
                                    let sqrDist = Math.pow(iRelativeX - x, 2) + Math.pow(iRelativeY - y, 2);
                                    
                                    //We try to find tiles in front  of the camera as much as possible
                                    if (distance === null || sqrDist < distance || (sqrDist == distance && y > tile.y))
                                    {
                                        tile = new Vector2(x, y);
                                        distance = sqrDist;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        return tile;
    }


    getGridPathfinding(iCenterX, iCenterY, iWidth, iHeight, arrMeshesToIgnore = null, bPreventSwim = false)
    {
        if (!this.parser)
        {
            console.warn("NO PARSER DEFINED");
            return;
        }

        let grid = [];
        let characterPositions = [];

        let rect = this.calculateRangeRect(iCenterX, iCenterY, iWidth, iHeight);


        let objects = this.parser.getObjectsInArea(rect.xMin, rect.yMin, rect.xMax, rect.yMax);


        for (let y = rect.yMin, i = 0; y <= rect.yMax; y++, i++)
        {
            let row = [];
            for (let x = rect.xMin, j = 0; x <= rect.xMax; x++, j++)
            {
                let walkable = this.isTileWalkable(x, y, arrMeshesToIgnore, objects);
                walkable = walkable === 1 ? 0 : 1; //we invert them for some  reason
                row.push(walkable);
            }
            grid.push(row);
        }

        this.applyObjectsMatrix(
            objects,
            grid,
            rect.xMin,
            rect.yMin,
            bPreventSwim
        );

        return {
            //Actual walkable grid
            "grid": grid,
            "rect": rect,

            //Adjusted relative center based from the edges of the grid (ex: if character too close on the edge)
            "center": {
                "x": iCenterX - rect.xMin,
                "y": iCenterY - rect.yMin
            }
        };
    }

    isTileWalkable(x, y, arrMeshesToIgnore = null, objects = [])
    {
        let walkable = 1;

        if (this.blockingGrid[x] !== undefined && this.blockingGrid[x][y] !== undefined)
        {
            walkable = this.blockingGrid[x][y];
            return walkable;
        }



        for (let id in this.tracking)
        {
            if (!arrMeshesToIgnore || (!arrMeshesToIgnore.includes(id) && !arrMeshesToIgnore.includes(this.tracking[id])))
            {
                let pos = this.calculateMeshPosition(this.tracking[id]);
                if (pos.x == x && pos.y == y)
                {
                    walkable = 0;
                    break;
                }
            }
        }


        return walkable;
    }

    /**
        Checks if the corresponding tile is free of obstacles and dropped content
        @param iX   X position of the tile to look for
        @param iY   Y position of the tile to look for
        @return     If the tile is free of obstacles and dropped content
    */
    isTileEmpty(iX, iY)
    {
        let empty = false;
        let obstacle = this.parser.getAt(iX, iY);

        if (!obstacle || obstacle.type != obstacle.TYPE_OBJECT)
        {
            let content = null;
            if (this.WorldManager.Environment.IsIndoor)
            {
                content = this.WorldManager.getCellContent(
                    this.getTileId(iX, iY),
                    this.WorldManager.Environment.Id,
                    this.WorldManager.Environment.RoomId
                )
            }
            else
            {
                content = this.WorldManager.getCellContent(this.getTileId(iX, iY))
            }
            return !content;
        }

        return empty;
    }

    calculateRangeRect(iCenterX, iCenterY, iWidth, iHeight)
    {
        return {
            "xMin": Math.max(0, iCenterX - Math.floor(iWidth / 2) + (iWidth % 2 == 0 ? 1 : 0)),
            "xMax": Math.min(this.Width, iCenterX + Math.floor(iWidth / 2)),
            "yMin": Math.max(0, iCenterY - Math.floor(iHeight / 2) + (iHeight % 2 == 0 ? 1 : 0)),
            "yMax": Math.min(this.Height, iCenterY + Math.floor(iHeight / 2)),
        };
    }

    calculateMeshPosition(mesh)
    {
        return new Vector2(
            Math.max(0, Math.min(this.Width, Math.floor(mesh.position.x / this.cellSize))),
            Math.max(0, Math.min(this.Height, Math.floor(mesh.position.z / this.cellSize)))
        );
    }

    applyObjectsMatrix(objects, grid, gridStartX, gridStartY, bPreventSwim = false)
    {
        for (let x in objects)
        {
            for (let y in objects[x])
            {
                if (this.blockingGrid[x] !== undefined && this.blockingGrid[x][y] !== undefined)
                {
                    continue;
                }

                if (objects[x][y])
                {
                    if (objects[x][y].IsEmpty)
                    {
                        if (grid[y - gridStartY])
                        {
                            grid[y - gridStartY][x - gridStartX] = 1;
                        }
                    }
                    else if (objects[x][y].WalkMatrix && objects[x][y].shouldSpawn(this.getTileId(x, y)))
                    {
                        let relX = x - gridStartX;
                        let relY = y - gridStartY;
                        let matrix = objects[x][y].WalkMatrix;

                        if (matrix.length > 0 && relX >= 0 && relX < grid[0].length && relY >= 0 && relY < grid.length)
                        {
                            let width = matrix[0].length;
                            let height = matrix.length;
                            let origin = null;

                            if (matrix.length > 1 || matrix[0].length > 1)
                            {
                                for (let mY = 0; mY < matrix.length; mY++)
                                {
                                    for (let mX = 0; mX < matrix[mY].length && origin == null; mX++)
                                    {
                                        let gridValue = matrix[mY][mX];
                                        let isOrigin = gridValue > 1;

                                        if (isOrigin)
                                        {
                                            origin = new Vector2(mX, mY);
                                            break;
                                        }
                                    }
                                    if (origin)
                                    {
                                        break;
                                    }
                                }
                            }
                            else
                            {
                                origin = new Vector2(0, 0);
                            }

                            if (origin)
                            {
                                for (let mX = 0; mX < width; mX++)
                                {
                                    for (let mY = 0; mY < height; mY++)
                                    {
                                        let gridX = relX - origin.x + mX;
                                        let gridY = relY - origin.y + mY;

                                        const isNotNegative = gridX >= 0 && gridY >= 0;
                                        const maxX = grid[0].length; //hopefully all row have the same length
                                        const maxY= grid.length; //hopefully all row have the same length

                                        if (isNotNegative && gridX < maxX &&  gridY < maxY)
                                        {
                                            let currentGridValue = grid[gridY][gridX];
                                            let currentMatrixValue = matrix[mY][mX];
                                            let currentMatrixValueMod = currentMatrixValue % 2;
                                            let blockForNotSwimmable = bPreventSwim && objects[x][y].IsSwimmable;

                                            if ( blockForNotSwimmable)
                                            {
                                                grid[gridY][gridX] = 1;
                                            }
                                            else
                                            {
                                                //in the forest, we switch for reason
                                                let isForest = this.WorldManager.IsForest;
                                                if (isForest) currentMatrixValueMod = currentMatrixValueMod === 1 ? 0 : 1;

                                                grid[gridY][gridX] = currentMatrixValueMod;
                                            }
                                        }
                                    }
                                }
                            }
                            else
                            {
                                return true;
                            }
                            
                        }
                    }
                }
            }
        }

        return grid;
    }

    calculateIsWalkableFromMatrix(iX, iY, iObjX, iObjY, objMatrix)
    {
        if (objMatrix.length == 0)
        {
            return true;
        }

        let height = objMatrix.length;
        let width = objMatrix[0].length;

        if (objMatrix.length > 1 || objMatrix[0].length > 1)
        {
            let origin = null;
            for (let y = 0; y < objMatrix.length; y++)
            {
                for (let x = 0; x < objMatrix[y].length; x++)
                {
                    if (objMatrix[y][x] > 1)
                    {
                        origin = new Vector2(x, y);
                        break;
                    }
                }
                if (origin)
                {
                    break;
                }
            }

            if (origin)
            {
                let diffX = iX - iObjX;
                let diffY = iY - iObjY;

                if (diffX + origin.x >= 0 && diffX + origin.x < width &&
                    diffY + origin.y >= 0 && diffY + origin.y < height)
                {
                    return objMatrix[origin.y + diffY][origin.x + diffX] % 2;
                }
                return true
            }
            else
            {
                return true;
            }
        }
        else
        {
            return objMatrix[0][0] % 2;
        }
    }

    /**
        Validates if a path is still walkable
        @param array arrPath            Path to validate
        @param array arrMeshesToIgnore  List of tracked meshes to ignore while calculating. This array can either contains the meshes or their associated id
        @param bool bSkipDiagonals      If the diagonals should be skipped when validating the path
        @param bool bPreventSwim        If swimmable tiles should prevent pathing or not. Default is FALSE
    */
    validatePath(arrPath, arrMeshesToIgnore = null, bSkipDiagonals = false, bPreventSwim = false)
    {
        let maxDistW = 0;
        let maxDistH = 0;
        let smallestX = this.Width;
        let smallestY = this.Height;

        for (let i = 1; i < arrPath.length; i++)
        {
            if (arrPath[i][0] < smallestX)
            {
                smallestX = arrPath[i][0];
            }
            if (arrPath[i][1] < smallestY)
            {
                smallestY = arrPath[i][1];
            }
        }

        for (let i = 0; i < arrPath.length; i++)
        {
            if (arrPath[i][0] - smallestX > maxDistW)
            {
                maxDistW = arrPath[i][0] - smallestX;
            }

            if (arrPath[i][1] - smallestY > maxDistH)
            {
                maxDistH = arrPath[i][1] - smallestY;
            }
        }

        maxDistW++;
        maxDistH++;

        let grid = this.getGridPathfinding(
            smallestX + maxDistW,
            smallestY + maxDistH,
            maxDistW * 2,
            maxDistH * 2,
            arrMeshesToIgnore,
            bPreventSwim
        );

        let isOk = false;
        if (grid.grid && grid.grid.length > 0)
        {
            isOk = true;
            for (let i = 0; i < arrPath.length; i++)
            {
                let x = Math.min(grid.grid[0].length - 1, Math.max(0, arrPath[i][0] - grid.rect.xMin));
                let y = Math.min(grid.grid.length - 1, Math.max(0, arrPath[i][1] - grid.rect.yMin));

                if (grid.grid[y][x] == 1)
                {
                    isOk = false;
                    break;
                }
            }
        }

        return isOk;
    }
}