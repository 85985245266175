import TriggerCodes from "../codes/TriggerCodes.js";

export default class CharacterValidator
{
    constructor (dependencies)
    {
        this.dependencies = dependencies;
        this.createSupportedConditionList();
        this.createCompareConditionList();
        this.createClosure();
        this.bindEvents();
    }

    //---------------------------------------------------------
    //  DEPENDENCIES
    //---------------------------------------------------------
    get CharacterManager() { return this.dependencies.get("CharacterManager"); }
    get ItemManager() { return this.dependencies.get("ItemManager"); }
    get TriggerManager() { return this.dependencies.get("TriggerManager"); }
    get WorldManager() { return this.dependencies.get("WorldManager"); }
    //---------------------------------------------------------

    get StatArgumentType() { return this.TriggerManager.ARGUMENT_TYPES.STAT; };
    get StateArgumentType() { return this.TriggerManager.ARGUMENT_TYPES.CHARACTER_STATE; };

    createSupportedConditionList ()
    {
        this.supportedConditions = {};

        this.supportedConditions[TriggerCodes.CONDITION_CHARACTER_STATE] = this.validateCharacterState.bind(this);
        this.supportedConditions[TriggerCodes.CONDITION_STAT] = this.validateStatValue.bind(this);
        this.supportedConditions[TriggerCodes.CONDITION_MUTATION] = this.validateMutation.bind(this);
        this.supportedConditions[TriggerCodes.CONDITION_NO_NPC] = this.validateNpcExist.bind(this);
    }

    createCompareConditionList ()
    {
        this.compareConditions = {};

        // this.compareConditions[TriggerCodes.CONDITION_CHARACTER_STATE] = this.validateCharacterState.bind(this);
        // this.compareConditions[TriggerCodes.CONDITION_STAT] = this.validateStatValue.bind(this);
        // this.compareConditions[TriggerCodes.CONDITION_MUTATION] = this.validateMutation.bind(this);
        // this.compareConditions[TriggerCodes.CONDITION_NO_NPC] = this.validateNpcExist.bind(this);

    }

    createClosure ()
    {
        this.fctOnStatValueChanged = this.onStatValueChanged.bind(this);
        this.fctOnCharacterStateChanged = this.onCharacterStateChanged.bind(this);
    }

    bindEvents ()
    {
        this.CharacterManager.on(this.CharacterManager.EVENT_STAT_VALUE_CHANGED, this.fctOnStatValueChanged);//@!
        this.CharacterManager.on(this.CharacterManager.EVENT_STATE_CHANGED, this.fctOnCharacterStateChanged);//@!
    }

    //EVENTS MAPPING
    //--------------------------------------------------------------
    onStatValueChanged (strStatType, fNewValue, iCharacter)
    {
        this.TriggerManager.findTriggersAndExecute(
            this.supportedConditions,
            this.StatArgumentType,
            [strStatType, fNewValue, iCharacter]
        );
    }

    onCharacterStateChanged (iCharacter, strNewState)
    {
        this.TriggerManager.findTriggersAndExecute(
            this.supportedConditions,
            this.StateArgumentType,
            [iCharacter, strNewState]
        );
    }

    //CONDITION VALIDATION
    //--------------------------------------------------------------
    validateCondition (strType, arrValues, objNewValues = null)
    {
        if (strType in this.supportedConditions)
        {
            return this.supportedConditions[strType](arrValues, objNewValues);
        }

        return true;
    }

    validateCharacterState (arrValues, objNewValues = null)
    {
        let state = null;
        if (objNewValues && objNewValues.type == this.StateArgumentType && objNewValues.values[0] == arrValues[0])
        {
            state = objNewValues.values[1];
        }
        else
        {
            state = "";
            if (this.CharacterManager.isCharacterSleeping(arrValues[0]))
            {
                state = TriggerCodes.CHARACTER_STATE_SLEEP;
            }
            else if (this.CharacterManager.isCharacterRelaxing(arrValues[0]))
            {
                state = TriggerCodes.CHARACTER_STATE_RELAX;
            }
            else
            {
                state = TriggerCodes.CHARACTER_STATE_NORMAL;
            }
        }
        return state == arrValues[1];
    }

    validateStatValue (arrValues, objNewValues = null)
    {
        let statValue = 0;
        if (objNewValues && objNewValues.type == this.StatArgumentType && objNewValues.values[0] == arrValues[0])
        {
            statValue = objNewValues.values[1];
        }
        else
        {
            if (arrValues[0] == this.CharacterManager.STAT_HUNGER)
            {
                statValue = this.CharacterManager.getCharacterHunger();
            }
            else if (arrValues[0] == this.CharacterManager.STAT_STRESS)
            {
                statValue = this.CharacterManager.getCharacterStress();
            }
            else if (arrValues[0] == this.CharacterManager.STAT_ENERGY)
            {
                statValue = this.CharacterManager.getCharacterEnergy();
            }
        }

        return this.TriggerManager.calculateValidity(arrValues[1], statValue, arrValues[2]);
    }

    validateMutation (arrValues, objNewValues = null)
    {
        return (this.ItemManager.MutationHead && this.ItemManager.MutationHead.Id == arrValues[0] ||
                this.ItemManager.MutationArm && this.ItemManager.MutationArm.Id == arrValues[0] ||
                this.ItemManager.MutationLeg && this.ItemManager.MutationLeg.Id == arrValues[0]);
    }

    validateNpcExist (arrValues, objNewValues = null)
    {
        let strNPC = arrValues[0];
        let bHasNPC = this.WorldManager.Environment.hasNpc(strNPC);

        return !bHasNPC;
    }
}