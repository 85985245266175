import ContainerResponsive from "../responsive/ContainerResponsive.js";
import GraphicsResponsive from "../responsive/GraphicsResponsive.js";
import InventorySlot from "./InventorySlot.js";


export default class InventoryGrid extends ContainerResponsive
{
    constructor ()
    {
        super();
    }

    get EVENT_DISPLAYED_SLOTS_CHANGE() { return "displayed-slots-changed"; }

    get TYPE_BACKPACK() { return "backpack"; }
    get TYPE_CLOSET() { return "closet"; }

    get DisplayedSlots() { return this.arrDisplaySlots; }

    /*******************************************
    *   INITIALIZATION
    *******************************************/
    /**
        Parameters to pass to the init function:
        - ui:           UI section object where this component resides
        - itemsByRow:   List of items separated by row used to logically create the grid
        - rWidth:       Reponsive width equation for a slot on the grid
        - rPadding:     Responsive padding equation to put between slots on the grid
        - type:         Type of this grid defining what it contains
    */
    init(meta)
    {
        this.arrItemsByRow = meta.itemsByRow;
        this.strWidth = meta.rWidth;
        this.strPadding = meta.rPadding;
        this.type = meta.type;

        this.arrSlots = [];
        this.arrDisplaySlots = [];

        return super.init(meta);
    }

    destroy(options)
    {
        for (let i = 0; i < this.arrSlots.length; i++)
        {
            let slot = this.arrSlots[i];
            slot.destroy(options);
        }
        delete this.arrSlots;

        super.destroy(options);
    }

    /*******************************************
    *   BUILD
    *******************************************/
    build ()
    {
        super.build();
        this.buildSlots();
    }

    buildSlots()
    {
        if (this.arrSlots.length == 0)
        {
            let cpt = 0;

            let page  = (this.type === this.TYPE_BACKPACK ? 1: 3);
            let itemByPage = 0;

            for (let k = 0; k < page; k++) //backpack has 1 page, closet as 3 page
            {
                itemByPage = 0;
                for (let i = 0; i < this.arrItemsByRow.length; i++)
                {
                    let rowCount = this.arrItemsByRow[i];

                    for (let j = 0; j < rowCount; j++)
                    {
                        let slot = this.buildInventorySlot(j, i, cpt, rowCount);
                        this.arrSlots.push(slot);

                        cpt++;
                        itemByPage++;
                    }
                }
            }

            this.itemByPage = itemByPage;
        }
    }

    buildInventorySlot (x, y, index, rowCount)
    {
        let slot = new InventorySlot();
        slot.init({
            "ui": this.UI,
            "rWidth": this.strWidth,
            "rHeight": this.strWidth,
            "type": slot.TYPE_ALL,
            "locationType": this.type,
            "index": index
        });

        slot.name = this.type + "-" + index;

        slot.rX =      [{on: "default", x:`${x} * (${this.strWidth} + (${this.strPadding}) + (${this.strPadding}*(0.25))) ` }];
        slot.rY =      [{on: "default", y:`${y} * (${this.strWidth} + (${this.strPadding}))`} ];

        this.addChild(slot);
        this.removeChild(slot);

        return slot;
    }

    /*******************************************
    *   ITEM MANAGEMENT
    *******************************************/
    addItem(iSlot, item)
    {
        if (iSlot < this.arrSlots.length)
        {
            let slot = this.arrSlots[iSlot];
            slot.addItem(item);
        }
        else //THIS SHOULD NEVER EVER EVER HAPPEN - Martin T.
        {
            console.trace(`You are trying to add an item in a out of scope slot ${iSlot}. Max slot is: ${this.arrSlots.length}`);
            throw "index out of bounds";
        }

    }

    updateAt (iSlot, item)
    {
        if (iSlot < this.arrSlots.length)
        {
            let slot = this.arrSlots[iSlot];
            slot.updateItem(item);
        }
        else //THIS SHOULD NEVER EVER EVER HAPPEN - Martin T.
        {
            console.trace(`You are trying to add an item in a out of scope slot ${iSlot}. Max slot is: ${this.arrSlots.length}`);
            throw "index out of bounds";
        }
    }

    removeItem(iSlot)
    {
        if (iSlot < this.arrSlots.length)
        {
            let slot = this.arrSlots[iSlot];
            slot.removeItem();
        }
        else //THIS SHOULD NEVER EVER EVER HAPPEN - Martin T.
        {
            console.trace(`You are trying to add an item in a out of scope slot ${iSlot}. Max slot is: ${this.arrSlots.length}`);
            throw "index out of bounds";
        }

    }

    removeAllItems ()
    {
        for (let i = 0; i < this.arrDisplaySlots.length; i++)
        {
            this.arrDisplaySlots[i].removeItem(0);
        }
    }

    /*******************************************
    *   PAGING
    *******************************************/
    showPage (type = "")
    {
        if (this.arrDisplaySlots.length > 0)
        {
            for (let i = 0; i < this.arrDisplaySlots.length; i++)
            {
                let slot = this.arrDisplaySlots[i];
                this.removeChild(slot);
            }
            this.arrDisplaySlots = [];
        }

        let currentPage = this.getIndexPageByType(type);
        let startIndex = (currentPage * this.itemByPage);
        let endIndex = startIndex + this.itemByPage;

        for (let i = startIndex; i < endIndex; i++)
        {
            let slot = this.arrSlots[i];

            let strTypeEmpty =type === "";
            let gotItem = slot.gotItem();
            let isOfType = slot.itemIs(type)
            slot.Enabled = strTypeEmpty || (!(gotItem && !isOfType));
            if (!slot.Enabled && slot.Item && slot.Item.item && slot.Item.item.id.split("-")[0] == "KE")
            {
                slot.Enabled = true;
            }

            this.addChild(slot);
            this.arrDisplaySlots.push(slot);
        }

        this.emit(this.EVENT_DISPLAYED_SLOTS_CHANGE);
    }

    getSlotAt (index)
    {
        let slot = this.arrSlots.find( (s) => s.index == index);

        return slot;
    }

    getIndexPageByType (type)
    {
        let inv = this.UI.GameManager.getSetting("inventory");
        let objPageIndexes = inv.storage.pageIndexes;

        if (type === "")
            return 0;

        return objPageIndexes[type];
    }
}