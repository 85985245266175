import EventEmitter from "eventemitter3";
import LocalStorageSaveAdapter from "./adapters/LocalStorageSaveAdapter.js";
import TeleQuebecSaveAdapter from "./adapters/TeleQuebecSaveAdapter.js";

export default class SaveManager extends EventEmitter
{
    constructor()
    {
        super();

        SaveManager.instance = this;
        this.saveSlot = 0;
        this.mode = "notdefined";
        this.localKey = "mutants-sv";
        this.newGameKey = "new-game";
    }

    get EVENT_TELE_QUEBEC_CONNECTION_STATE_CHANGED() { return "tele-quebec-connection-state-changed"; }
    get SAVE_SLOT_COUNT () { return 3; }

    get SaveSlot () { return this.saveSlot; }
    set SaveSlot (iSaveSlot) { this.saveSlot = iSaveSlot; }

    get IsConnected() { return this.adapter.IsConnected; }
    get ConnectUrl() { return this.connectUrl; }

    init(dependencies)
    {
        this.dependencies = dependencies;
    }

    getSaveSlotPrefix (iSlotIndex = null)
    {
        if (iSlotIndex === null)
        {
            iSlotIndex = this.saveSlot;
        }
        return "save" + iSlotIndex + "_";
    }

    /**
        Gets data from the current Save Slot instead of a generic global value
    */
    getFromSave(strKeyName, defaultValue = null)
    {
        return this.get(this.getSaveSlotPrefix() + strKeyName, defaultValue);
    }

    /**
        Sets a data on the current Save Slot instead of global
    */
    setFromSave (strKeyName, value)
    {
        this.set(this.getSaveSlotPrefix() + strKeyName, value);
    }


    createOrChange (strKeyName, operation, value)
    {
        let currentValue = this.getFromSave(strKeyName, "");

        if (operation === "change")
        {
            currentValue = value;
        }
        else if (operation === "delete")
        {
            this.deleteKey(strKeyName)
            return;
        }
        else if (operation === "increment")
        {
            currentValue = parseFloat(currentValue);
            currentValue += 1;
        }
        else if (operation === "decrement")
        {
            currentValue = parseFloat(currentValue);
            currentValue -= 1;
        }

        this.setFromSave(strKeyName, currentValue);
    }


    deleteKey (strKeyName)
    {
        let strKey = this.getSaveSlotPrefix() + strKeyName;

        return this.deleteAdapterKey(strKey);
    }

    get (strKeyName, defaultValue = null)
    {
        if (this.isReady)
        {
            return this.adapter.get(strKeyName, defaultValue);
        }
        console.warn("Save manager get was called to quickly, hasn't properly loaded yet.");
        return defaultValue;
    }

    set (strKeyName, value)
    {
        if (this.isReady)
        {
            this.adapter.set(strKeyName, value);
        }
        else
        {
            console.warn("Save manager set was called to quickly, hasn't properly loaded yet.");
        }
    }

    deleteAdapterKey (strKey)
    {
        return this.adapter.deleteKey(strKey);
    }

    save ()
    {
        if (this.isReady)
        {
            this.adapter.save();

            if (this.mode === "telequebec")
            {

                const items = localStorage["mutants-sv"] ? localStorage["mutants-sv"] : {};

                const data = items;

                if (Tq.Squat)
                {
                    Tq.Squat.setData(data, function (dataSaved)
                    {
                        console.log('dataSaved in game', dataSaved);
                    });
                }
            }
        }
        else
        {
            console.warn("Save manager save was called to quickly, hasn't properly loaded yet.");
        }
    }

    load (fctCallback)
    {
        if (this.isReady)
        {
            this.adapter.load(fctCallback);
        }
        else
        {
            console.warn("Save manager load was called to quickly, hasn't properly loaded yet.");
        }
    }

    clear ()
    {
        if (this.isReady)
        {
            this.adapter.clear();
        }
        else
        {
            console.warn("Save manager clear was called to quickly, hasn't properly loaded yet.");
        }
    }

    deleteSaveSlot (iSlotIndex)
    {
        if (this.isReady)
        {
            this.adapter.clearWithPrefix(this.getSaveSlotPrefix(iSlotIndex));
        }
        else
        {
            console.warn("Save manager deleteSaveSlot was called to quickly, hasn't properly loaded yet.");
        }
    }

    getIsSlotEmpty(iSlotIndex)
    {
        let currentSlot = this.SaveSlot;

        this.SaveSlot = iSlotIndex;
        let bIsEmpty = !this.getFromSave(this.newGameKey, false);
        this.SaveSlot = currentSlot;

        return bIsEmpty;
    }

    onLoadComplete(objSettings)
    {
        this.connectUrl = objSettings.gameSettings.connectUrl;

        if (objSettings.gameSettings.saveAdapter.toLowerCase() == "telequebec")
        {
            this.adapter = new TeleQuebecSaveAdapter();
        }
        else
        {
            this.adapter = new LocalStorageSaveAdapter();
        }
        
        this.isReady = true;
        this.load();
    }
}
