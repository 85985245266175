import { Sprite } from '@pixi/sprite';
import ContainerResponsive from "../commons/responsive/ContainerResponsive.js";
import MenuUI from "../MenuUI.js";
import Button from "../commons/Button.js";
import Copydeck from "../../utils/Copydeck.js";
import Display from "../../utils/Display.js";
import Library from "../../Library.js";
import ParametersPopup from "../commons/popups/ParametersPopup.js";
import SaveManagementDisplay from "./components/SaveManagementDisplay.js";
import ScreenTransition from "../commons/ScreenTransition.js";
import SpriteResponsive from "../commons/responsive/SpriteResponsive.js";
import TextButton from "../commons/buttons/TextButton.js";
import AudioManager from "../../audio/AudioManager.js";
import MessageNotificationPopup from "../notifications/popups/MessageNotificationPopup.js";

export default class SplashUI extends MenuUI
{
    constructor()
    {
        super();
    }

    get SECTION_HOME() { return "home"; }
    get SECTION_SAVE() { return "save"; }

    get Id() { return "splash"; }
    get Notifications() { return this.notifications; }

    get SaveZoneWidth() { return "(iw * 32.5%)"; }
    get SaveZoneHeight() { return"(ih * 30%)"; }

    /*******************************************
    *   INITIALIZATION
    *******************************************/
    /**
        Parameters to pass to the init function:
        - dependencies:     Dependency injector object
    */
    init(meta)
    {
        this.sections = {};
        this.buttons = {};
        this.currentSection = this.SECTION_HOME;


        super.init(meta);
    }



    createClosure ()
    {
        super.createClosure();

        this.fctOnPlayClick = this.onPlayClick.bind(this);
        this.fctOnParamsClick = this.onParamsClick.bind(this);
        this.fctOnSaveChoose = this.onSaveChoose.bind(this);
        this.fctOnSaveDelete = this.onSaveDelete.bind(this);
        this.fctOnSaveBack  = this.onSaveBack.bind(this);
    }

    destroy(options)
    {
        for (let key in this.buttons)
        {
            if (this.buttons[key].parent)
            {
                this.buttons[key].parent.removeChild(this.buttons[key]);
            }
            this.buttons[key].destroy(options);
        }
        delete this.buttons;

        for (let key in this.sections)
        {
            if (this.sections[key].parent)
            {
                this.sections[key].parent.removeChild(this.sections[key]);
            }
            this.sections[key].destroy(options);
        }
        delete this.sections;

        if (this.saveManagement)
        {
            if (this.saveManagement.parent)
            {
                this.saveManagement.parent.removeChild(this.saveManagement);
            }
            this.saveManagement.off(this.saveManagement.EVENT_CHOOSE_SAVE, this.fctOnSaveChoose);//@
            this.saveManagement.off(this.saveManagement.EVENT_DELETE_SAVE, this.fctOnSaveDelete);//@
            this.saveManagement.off(this.saveManagement.EVENT_BACK, this.fctOnSaveBack);//@
            this.saveManagement.destroy(options);

            delete this.saveManagement;
        }

        super.destroy(options);
    }

    /*******************************************
    *   BUILD
    *******************************************/
    build ()
    {
        super.build();

        this.buildHomeSection();
        this.buildSaveSection();

    }

    buildLogo()
    {
        super.buildLogo();

        if (this.arrShownPopups.length > 0)
        {
            this.removeChild(this.logo);
        }
        else if (!this.logo.parent)
        {
            this.addChild(this.logo);
        }
    }

    //-----------------------------------------
    //  HOME SECTION
    //-----------------------------------------
    buildHomeSection()
    {
        if (!this.sections.home)
        {
            this.sections.home = new ContainerResponsive().init({"ui": this});
            this.addChild(this.sections.home);
        }

        if (this.arrShownPopups.length > 0 || this.currentSection != this.SECTION_HOME)
        {
            this.removeChild(this.sections.home);
        }
        else if (this.currentSection == this.SECTION_HOME)
        {
            if (!this.sections.home.parent)
            {
                this.addChild(this.sections.home);
            }

            this.buildPlayButton();
            this.buildParamsButton();
            this.buildSponsors();

            let strUserAgent = navigator.userAgent;
            let isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(strUserAgent);
            let isIPadOs = navigator.maxTouchPoints && navigator.maxTouchPoints > 2 && /MacIntel/.test(navigator.platform);



            let popup = document.querySelector("#dontPlayOnMobile");
            if (isIPadOs || isMobile)
            {
                popup.style.display = "flex";
                popup.addEventListener("pointerup", () => popup.remove())
            }
            else
            {
                popup.remove()
            }

        }
    }

    buildPlayButton()
    {
        if (!this.buttons.play)
        {
            this.buttons.play = new TextButton().init({
                "ui": this,
                "text": this.LabelManager.translate("Menu_Play"),
                "fontSize": this.evaluate(this.valueFormula(70), 0, 0, 0, 0),
                "padding": {
                    "horizontal": this.evaluate(this.valueFormula(125), 0, 0, 0, 0),
                    "vertical": this.evaluate(this.valueFormula(25), 0, 0, 0, 0)
                }
            });

            this.buttons.play.on(this.buttons.play.EVENT_CLICK, this.fctOnPlayClick);//@
            this.sections.home.addChild(this.buttons.play);
        }

        let size = this.buttons.play.getButtonSize();

        this.buttons.play.x = this.ResponsiveManager.Width / 2 - size.width / 2;
        this.buttons.play.y = this.ResponsiveManager.Height * 0.40;

    }

    buildParamsButton()
    {
        if (!this.buttons.params)
        {
            this.buttons.params = new TextButton().init({
                "ui": this,
                "text": this.LabelManager.translate("Menu_Param"),
                "fontSize": this.evaluate(this.valueFormula(70), 0, 0, 0, 0),
                "padding": {
                    "horizontal": this.evaluate(this.valueFormula(55), 0, 0, 0, 0),
                    "vertical": this.evaluate(this.valueFormula(35), 0, 0, 0, 0)
                }
            });

            this.buttons.params.on(this.buttons.params.EVENT_CLICK, this.fctOnParamsClick);//@
            this.sections.home.addChild(this.buttons.params);
        }

        let size = this.buttons.params.getButtonSize();

        this.buttons.params.x = this.ResponsiveManager.Width / 2 - size.width / 2;
        this.buttons.params.y = this.ResponsiveManager.Height * 0.40 + this.buttons.play.getButtonSize().height * 1.665;
    }

    buildSponsors()
    {
        //@TODO: Don't forget to add the logos to the this.sections.home container, not directly to the UI

        {
            let arrLogos = ["FMC_blanc", "TQc", "Shaw",   "Telefiction", "Affranchis_blanc", "KidSafe"];
            let arrURLS =  ["", "", "", "", "", "https://www.kidsafeseal.com/certifiedproducts/lesmutants.html"];


            let rWidth = "(iw / 13)";

            for (let i = 0; i < arrLogos.length; i++)
            {
                let texture = arrLogos[i];
                let visual = Library.getTextureFromAtlas("ui", texture);
                let sp = new SpriteResponsive(visual).init({"ui": this});;

                sp.rWidth = [{on:"default", width: rWidth}];
                sp.rHeight = [{on:"default", height: rWidth}];

                let strX =  `${rWidth} + ((${i} * 2) * ${rWidth})`;

                console.log(texture, strX);
                sp.rX = [{on:"default", x: strX}];
                sp.rY = [{on:"default", y: `ih - ${rWidth} * 1.5`}];

                this.sections.home.addChild(sp);

                //this.arrLogos.push(sp);


                let url = arrURLS[i];

                if (url !== "")
                {
                    sp.interactive = sp.buttonMode = true;
                    sp.on("pointerup", () =>
                    {
                        window.open(url, "_blank");
                    })
                }
            }
        }
    }

    //-----------------------------------------
    //  SAVE SECTION
    //-----------------------------------------
    buildSaveSection()
    {
        if (!this.sections.save)
        {
            this.sections.save = new ContainerResponsive().init({"ui": this});
            this.addChild(this.sections.save);
        }

        if (this.arrShownPopups.length > 0 || this.currentSection != this.SECTION_SAVE)
        {
            this.removeChild(this.sections.save);
        }
        else if (this.currentSection == this.SECTION_SAVE)
        {
            if (!this.sections.home.parent)
            {
                this.addChild(this.sections.save);
            }
            this.buildSaveManagement();
        }
    }

    buildSaveManagement()
    {
        let rWidth = this.SaveZoneWidth;
        let rHeight = this.SaveZoneHeight;

        if (!this.saveManagement)
        {
            this.saveManagement = new SaveManagementDisplay().init({
                "ui": this,
                rWidth,
                rHeight,
            });

            this.saveManagement.on(this.saveManagement.EVENT_CHOOSE_SAVE, this.fctOnSaveChoose);//@
            this.saveManagement.on(this.saveManagement.EVENT_DELETE_SAVE, this.fctOnSaveDelete);//@
            this.saveManagement.on(this.saveManagement.EVENT_BACK, this.fctOnSaveBack);//@

            this.sections.save.addChild(this.saveManagement);
        }

        this.saveManagement.rX =  [{on:"default",     x: "(iw / 2) - " + rWidth + " / 2"}];
        this.saveManagement.rY =  [{on:"default",     y: "(ih * 42.5%)"}];
        this.saveManagement.layout(Display.getSize());


    }

    /*******************************************
    *   POPUPS
    *******************************************/
    showParamsPopup()
    {
        if (!this.popups.params)
        {
            this.popups.params = new ParametersPopup();

            this.popups.params.on(this.popups.params.EVENT_POPUP_SHOW, this.fctOnPopupShow);
            this.popups.params.on(this.popups.params.EVENT_POPUP_HIDE, this.fctOnPopupHide);

            this.popups.params.init({
                "ui": this
            });
        }
        else
        {
            this.popups.params.show();
        }
    }

    /*******************************************
    *   UTILITIES
    *******************************************/
    showSceneTransition(fctCallbackMiddle = null, fctCallback = null)
    {
        if (!this.screenTransition)
        {
            this.screenTransition = new ScreenTransition().init({"ui": this});
        }

        this.addChild(this.screenTransition);

        this.screenTransition.startTransition(
            fctCallbackMiddle, 
            function(fctCallback) 
            { 
                if (fctCallback)
                {
                    fctCallback();
                }

                this.removeChild(this.screenTransition);
                this.screenTransition.destroy({"children": true});
                delete this.screenTransition;

            }.bind(this, fctCallback)
        );
    }

    /*******************************************
    *   EVENTS
    *******************************************/
    onPlayClick()
    {
        AudioManager.instance.playBgm( "theme", true, false);
        this.currentSection = this.SECTION_SAVE;
        this.build();
    }

    onParamsClick()
    {
        this.showParamsPopup();
        this.build();
    }

    onSaveChoose(sender, iSlotIndex)
    {
        this.GameManager.playGameOnSlot(iSlotIndex);

    }

    onSaveDelete(sender, iSlotIndex)
    {
        this.SaveManager.deleteSaveSlot(iSlotIndex);
        this.SaveManager.save();

        this.build();
    }

    onSaveBack(sender)
    {
        this.currentSection = this.SECTION_HOME;
        this.build();
    }

    onPopupHide(sender)
    {
        super.onPopupHide(sender);
        this.build();
    }









    /*******************************************
    *   TEST (TO BE REMOVED)
    *******************************************/
    get EVENT_LAUNCH_BARN() { return "launch-barn"; }
    get EVENT_LAUNCH_LABO() { return "launch-labo"; }

    buildTestButtons()
    {
        let screenWidth = this.ResponsiveManager.Width;
        let screenHeight = this.ResponsiveManager.Height;
        let ratio = screenHeight / 840;

        //BARN
        if (!this.buttons.tmpBarn)
        {
            this.buttons.tmpBarn = new Button().init({
                "ui": this,
                "text": this.LabelManager.translate("BTN_LABEL_BARN")
            });
            this.buttons.tmpBarn.once("pointertap", this.launchBarn.bind(this));
            this.addChild(this.buttons.tmpBarn);
        }

        this.buttons.tmpBarn.x = screenWidth * 0.025;
        this.buttons.tmpBarn.y = screenHeight * 0.975 - this.buttons.tmpBarn.height * ratio;

        this.buttons.tmpBarn.scale.set(ratio);

        //LABO
        if (!this.buttons.tmpLabo)
        {
            this.buttons.tmpLabo = new Button().init({
                "ui": this,
                "text": this.LabelManager.translate("BTN_LABEL_LABO")
            });
            this.buttons.tmpLabo.once("pointertap", this.launchLabo.bind(this));
            this.addChild(this.buttons.tmpLabo);
        }

        this.buttons.tmpLabo.x = this.buttons.tmpBarn.x + 150 * ratio;
        this.buttons.tmpLabo.y = this.buttons.tmpBarn.y;

        this.buttons.tmpLabo.scale.set(ratio);

        //Ghetto lazy fix. Those are test buttons anyway. They are going to get scrapped
        if (!this.testButtonHasDoneFirstBuild)
        {
            this.testButtonHasDoneFirstBuild = true;
            this.buildTestButtons();
        }
    }

    launchBarn ()
    {
        this.emit(this.EVENT_LAUNCH_BARN);
    }

    launchLabo ()
    {
        this.emit(this.EVENT_LAUNCH_LABO);
    }
    /*******************************************/

}