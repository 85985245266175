export default class BaseCheat
{
    constructor(dependencies)
    {
        this.dependencies = dependencies;
    }

    /**
        How to use the command in the cheat console. This will be displayed in the browser's console
    */
    get CommandUse() { return ""; }
    /**
        Description of this cheat. This will be displayed in the browser's console
    */
    get CommandDesc() { return ""; }

    parseCommand(strCmd, arrArguments)
    {
        return false;
    }

    log(strMsg)
    {
        console.log("%cCHEAT", "font-weight:bold", strMsg);
    }

    warn(strMsg)
    {
        console.warn("%cCHEAT", "font-weight:bold", strMsg);
    }
}