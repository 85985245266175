import BaseCheat from "./BaseCheat.js";

export default class Power extends BaseCheat
{
    constructor(dependencies)
    {
        super(dependencies);
    }

    get CommandUse() { return "power [id]"; }
    get CommandDesc() { return "Uses a power if the player has the right mutation (stomp, spit, teleport, invisible)"; }

    //---------------------------------------------------------
    //  DEPENDENCIES
    //---------------------------------------------------------
    get CharacterManager() { return this.dependencies.get("CharacterManager"); }
    get WorldManager() { return this.dependencies.get("WorldManager"); }
    //---------------------------------------------------------

    parseCommand(strCmd, arrArguments)
    {
        if (strCmd == "power")
        {
            if (arrArguments.length >= 1 && this.WorldManager.IsInGameScene && this.WorldManager.IsHostile && this.WorldManager.Player)
            {
                let id = arrArguments[0].toLowerCase();
                switch (id)
                {
                    case "stomp":
                        if (this.WorldManager.Player.stomp())
                        {
                            this.log("Stomped on the ground");
                        }
                        else
                        {
                            this.warn("Could not stomp on the ground");
                        }
                        break;
                    case "spit":
                        if (this.WorldManager.Player.spit())
                        {
                            this.log("Spitted on the nearest enemy");
                        }
                        else
                        {
                            this.warn("Could not spit");
                        }
                        break;
                    case "teleport":
                        if (this.WorldManager.Player.teleport())
                        {
                            this.log("Teleported to the barn");
                        }
                        else
                        {
                            this.warn("Could not teleport to the barn");
                        }
                        break;
                    case "invisible":
                        if (this.WorldManager.Player.renderInvisible())
                        {
                            this.log("You are now invisible");
                        }
                        else
                        {
                            this.warn("Could not render invisible");
                        }
                        break;
                    default:
                        return false;
                }

                return true;
            }
        }
        return false;
    }
}