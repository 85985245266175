import { string2hex }  from "@pixi/utils"; 
import { TextStyle } from "@pixi/text";
import { TextMetrics } from "@pixi/text";
import Display from "../../utils/Display.js";
import Lerp from "../../utils/Lerp.js";
import Library from "../../Library.js";
import MenuUI from "../MenuUI.js";
import GraphicsResponsive from "../commons/responsive/GraphicsResponsive.js";
import ScreenTransition from "../commons/ScreenTransition.js";
import TextResponsive from "../commons/responsive/TextResponsive.js";

export default class CharacterLoading extends MenuUI
{
    constructor()
    {
        super();
    }

    get BACKGROUND_TEXTURE_ID() { return "forest_bg"; }

    get Id() { return "characterloading"; }

    get TipSettings() { return this.GameManager.getSetting("tips"); }
    get TipList() { return this.TipSettings.list; }
    get TipAnimationTime() { return this.TipSettings.animationTime * 1000; }
    get TipWaitTime() { return this.TipSettings.waitTime * 1000; }
    get IsTipFloatingText() { return (this.TipSettings.floatingText ? true : false); }

    get LoadingBarX () { return "(iw / 2) - " + this.LoadingBarWidth + " / 2"; }
    get LoadingBarY () { return "(ih * 90%)"; }
    get LoadingBarWidth () { return "(iw * 30%)"; }
    get LoadingBarHeight () { return "(ih * 2%)"; }

    get LoadingFontSize() { return  this.evaluate(this.valueFormula((this.showCreationMessage ? 20 : 32)), 0, 0, 0, 0, this); }
    get LoadingBarStrokeWidth() { return this.evaluate(this.valueFormula(5), 0, 0, 0, 0, this); }
    get LoadingBarCornerRadius() { return this.evaluate(this.valueFormula(3), 0, 0, 0, 0, this); }

    get LoadProgress() { return this.progress; }
    get LoadingLabel() { return this.LabelManager.translate(this.showCreationMessage ? "Chargement_CreationMonde" : "Spla_Charge"); }

    get TipY() { return "(" + this.LogoY + " + " + this.LogoHeight + " * 80%) + ((" + this.LoadingBarY + " - " + this.LoadingFontSize + ") - (" + this.LogoY + " + " + this.LogoHeight + " * 80%)) / 2 - " + this.TipHeight + " / 2";}
    get TipWidth() { return this.LogoWidth;}
    get TipHeight() 
    {
        if (this.currentTip.title)
        {
            let label = this.currentTip.title;
            let style = this.TipTitleStyle

            let titleMetrics = TextMetrics.measureText(label, style);

            label = this.currentTip.description;
            style = this.TipDescriptionStyle;

            let descMetrics = TextMetrics.measureText(label, style);

            return titleMetrics.height * 4 + descMetrics.height;
        }
        else
        {
            let label = this.currentTip.description;
            let style = this.TipTitleStyle

            let metrics = TextMetrics.measureText(label, style);

            return metrics.height + this.evaluate(this.valueFormula(this.Values.general.text.size)) * 4;
        }
    }

    get TipBackgroundColor() { return string2hex(this.Values.modal.background.color);}
    get TipStrokeWidth() { return this.evaluate(this.valueFormula(this.Values.modal.stroke.size), 0, 0, 0, 0);}
    get TipStrokeColor() { return string2hex("color" in this.Values.modal.stroke ? this.Values.modal.stroke.color : "#000000");}
    get TipCornerRadius() { return this.evaluate(this.valueFormula(this.Values.modal.corner.radius), 0, 0, 0, 0);}

    get TipTitleStyle()
    {

        let fontSize = this.evaluate(this.valueFormula(this.Values.general.title.size));
        fontSize = Math.max(fontSize, this.ResponsiveManager.TitleMinFontSize);

        return new TextStyle({
            fontFamily: this.Values.general.title.font,
            fontSize,
            fontWeight: this.Values.general.title.weight,
            fill: this.Values.general.title.color,
            align: "center",
            wordWrap: true,
            wordWrapWidth: this.evaluate(this.TipWidth + " * 80%", 0, 0, 0, 0, this)
        });
    }

    get TipDescriptionStyle()
    {
        let fontSize = this.evaluate(this.valueFormula(this.Values.general.text.size));
        fontSize = Math.max(fontSize, this.ResponsiveManager.TextMinSize);

        return new TextStyle({
            fontFamily: this.Values.general.text.font,
            fontSize,
            fontWeight: this.Values.general.text.weight,
            fill: this.Values.general.title.color,
            align: "center",
            wordWrap: true,
            wordWrapWidth: this.evaluate(this.TipWidth + " * 80%", 0, 0, 0, 0, this)
        });
    }

    /*******************************************
    *   INITIALIZATION
    *******************************************/
    /**
        Parameters to pass to the init function:
        - dependencies:         Dependency injector object
        - showCreationMessage:  If this UI should show a message related to character creation
    */
    init(meta)
    {
        this.dependencies = meta.dependencies;
        this.graphics = {};
        this.sprites = {};
        this.texts = {};

        this.progress = 0;
        this.showTips = true;
        this.showCreationMessage = meta.showCreationMessage;

        this.currentTip = {"description": ""};
        this.previousTips = [];
        this.tipWaitTime = 0;
        this.tipPos = {"x": meta.dependencies.get("ResponsiveManager").Width, "y": "0"};
        this.shouldDestroy = false;

        super.init(meta);

        if (this.showTips)
        {
            this.pickNewTip();
        }

        this.build();
        return this;
    }

    createClosure()
    {
        super.createClosure();

        this.fctOnResize = this.onResize.bind(this);
        this.fctOnLoadProgress = this.onLoadProgress.bind(this);
        this.fctOnLoadingDone = this.onLoadingDone.bind(this);
    }

    bindEvents()
    {
        super.bindEvents();

        this.ResponsiveManager.on(this.ResponsiveManager.EVENT_RESIZE, this.fctOnResize);
        this.AnimationsCreator.on(this.AnimationsCreator.EVENT_PROGRESSION, this.fctOnLoadProgress);//@
        this.AnimationsCreator.on(this.AnimationsCreator.EVENT_DONE, this.fctOnLoadingDone);
    }

    destroy(options)
    {
        this.ResponsiveManager.off(this.ResponsiveManager.EVENT_RESIZE, this.fctOnResize);
        this.AnimationsCreator.off(this.AnimationsCreator.EVENT_PROGRESSION, this.fctOnLoadProgress);
        this.AnimationsCreator.off(this.AnimationsCreator.EVENT_DONE, this.fctOnLoadingDone);

        this.shouldDestroy = true;

        super.destroy(options);
    }

    /*******************************************
    *   BUILD
    *******************************************/
    build()
    {
        super.build();

        this.buildLoadBar();
        if (this.showTips)
        {
            this.buildTip();
        }
    }

    clean()
    {
        for (let key in this.graphics)
        {
            this.graphics[key].clear();
        }
        super.clean();
    }

    buildLoadBar()
    {
        if (!this.graphics.loadingBarFrame)
        {
            this.graphics.loadingBarFrame = new GraphicsResponsive().init({"ui": this});
            this.addChild(this.graphics.loadingBarFrame);
        }

        let frame = this.graphics.loadingBarFrame;

        let loadingWidth = this.evaluate(this.LoadingBarWidth, 0, 0, 0, 0, this);
        let loadingHeight = this.evaluate(this.LoadingBarHeight, 0, 0, 0, 0, this);
        let strokeWidth = this.LoadingBarStrokeWidth;
        let cornerRadius = this.LoadingBarCornerRadius;

        let size = Display.getSize();

        frame.rX =  [{on: "default", x: this.LoadingBarX}];
        frame.rY =  [{on: "default", y: this.LoadingBarY}];
        frame.rWidth =  [{on: "default", width: this.LoadingBarWidth}];
        frame.rHeight =  [{on: "default", height: this.LoadingBarHeight}];
        frame.layout(size);

        frame.beginFill(0x26212F, 1);
        frame.drawRoundedRect(0, 0, loadingWidth, loadingHeight, cornerRadius);
        frame.endFill();

        if (!this.graphics.loadingBarFill)
        {
            this.graphics.loadingBarFill = new GraphicsResponsive().init({"ui": this});
            this.addChild(this.graphics.loadingBarFill);
        }

        let fill = this.graphics.loadingBarFill;

        fill.rX =  [{on: "default", x: this.LoadingBarX}];
        fill.rY =  [{on: "default", y: this.LoadingBarY}];
        fill.layout(size);

        fill.beginFill(0x8878A9, 1);
        fill.drawRoundedRect(0, 0, loadingWidth * Math.max(0, Math.min(1, this.LoadProgress)), loadingHeight, cornerRadius);
        fill.endFill();

        this.buildLoadText();
    }

    buildLoadText()
    {
        if (!this.texts.loading)
        {
            this.texts.loading = new TextResponsive().init({"ui": this});
            this.addChild(this.texts.loading);
        }

        let text = this.texts.loading;
        let label = this.LoadingLabel;

        let fontSize = this.LoadingFontSize;
        fontSize = Math.max(fontSize, this.ResponsiveManager.TextMinSize);

        let style = new TextStyle({
            fontFamily: (this.showCreationMessage ? "'Roboto'" : "'Barlow Condensed'"),
            fontSize,
            fill: 0xFFFFFF,
            align: "center",
            wordWrap: true,
            wordWrapWidth: this.evaluate(this.LoadingBarWidth, 0, 0, 0, 0, this) * this.ResponsiveManager.LoadingTextMultiplier
        });

        let metrics = TextMetrics.measureText(label, style);

        text.text = label;
        text.style = style;
        text.width = metrics.width;
        text.height = metrics.height;

        text.rX = [{on: "default", x: this.LoadingBarX + " + " + this.LoadingBarWidth + " / 2 - " + metrics.width + " / 2"}];
        text.rY = [{on: "default", y: this.LoadingBarY + " - " + metrics.height + " * 1.66"}];
        text.layout(Display.getSize());
    }

    buildTip()
    {
        if (!this.tipWindow)
        {
            this.tipWindow = new GraphicsResponsive().init({"ui": this});
            this.tipWindow.texts = {};
            this.addChild(this.tipWindow);
        }
        else
        {
            this.tipWindow.clear();
        }

        let graphics = this.tipWindow;

        let width = this.TipWidth;
        let height = this.TipHeight;
        let x = this.tipPos.x;
        let y = this.TipY;

        let calculatedWidth = this.evaluate(width, 0, 0, 0, 0, this);
        let calculatedHeight = this.evaluate(height, 0, 0, 0, 0, this);

        graphics.rX = [{on: "default", x: x}];
        graphics.rY = [{on: "default", y: y}];
        graphics.layout(Display.getSize());

        if (!this.IsTipFloatingText)
        {
            let bgColor = this.TipBackgroundColor;
            let strokeWidth = this.TipStrokeWidth;
            let strokeColor = this.TipStrokeColor;
            let cornerRadius = this.TipCornerRadius;

            if (strokeWidth > 0)
            {
                graphics.lineStyle({"color":strokeColor, "width":strokeWidth, "alignment":1});
            }

            graphics.beginFill(bgColor, 1);
            if (cornerRadius > 0)
            {
                graphics.drawRoundedRect(0, 0, calculatedWidth, calculatedHeight, cornerRadius);
            }
            else
            {
                graphics.drawRect(0, 0, calculatedWidth, calculatedHeight);
            }
            graphics.endFill();
        }
        else if (this.tipWindow.parent)
        {
            this.removeChild(this.tipWindow);
        }

        let titleHeight = 0;
        if (this.currentTip.title)
        {
            titleHeight = this.buildTipTitle(x, y, calculatedHeight);
        }
        this.buildTipDescription(x, y, titleHeight, calculatedHeight);
    }

    buildTipTitle(fX, fY, fTipHeight)
    {
        if (!this.texts.tipTitle)
        {
            this.texts.tipTitle = new TextResponsive().init({"ui": this});
            this.addChild(this.texts.tipTitle);
        }

        let text = this.texts.tipTitle;
        let label = this.currentTip.title;

        let style = this.TipTitleStyle;

        let metrics = TextMetrics.measureText(label, style);

        text.text = label;
        text.style = style;
        text.width = metrics.width;
        text.height = metrics.height;

        label = this.currentTip.description;
        style = this.TipDescriptionStyle;
        let descMetrics = TextMetrics.measureText(label, style);

        let x = fX + " + " + this.TipWidth + " / 2 - " + metrics.width + " / 2";
        let y = fY + " + (" + fTipHeight + " - (" + metrics.height + " + " + descMetrics.height + ")) * 0.4";

        text.rX = [{on: "default", x: x}];
        text.rY = [{on: "default", y: y}];
        text.layout(Display.getSize());

        return metrics.height;
    }

    buildTipDescription(fX, fY, fTitleHeight, fTipHeight)
    {
        if (!this.texts.tipDescription)
        {
            this.texts.tipDescription = new TextResponsive().init({"ui": this});
            this.addChild(this.texts.tipDescription);
        }

        let text = this.texts.tipDescription;
        let label = this.currentTip.description;

        let style = this.TipDescriptionStyle;

        let metrics = TextMetrics.measureText(label, style);

        text.text = label;
        text.style = style;
        text.width = metrics.width;
        text.height = metrics.height;

        let x = fX + " + " + this.TipWidth + " / 2 - " + metrics.width + " / 2";
        let y = "";
        if (this.currentTip.title)
        {
            y = fY + " + (" + fTipHeight + " - (" + metrics.height + " + " + fTitleHeight + ")) * 0.6 + " + fTitleHeight;
        }
        else
        {
            y = fY + " + " + this.TipHeight + " / 2 - " + metrics.height + " / 2";
        }

        text.rX = [{on: "default", x: x}];
        text.rY = [{on: "default", y: y}];
        text.layout(Display.getSize());
    }

    /*******************************************
    *   TIPS MANAGEMENT
    *******************************************/
    pickNewTip()
    {
        let tipList = [];

        for (let i = 0; i < this.TipList.length; i++)
        {
            let bIsOk = true;
            for (let j = 0; j < this.previousTips.length; j++)
            {
                if (this.previousTips[j].title == this.TipList[i].title && this.previousTips[j].description == this.TipList[i].description)
                {
                    bIsOk = false;
                    break;
                }
            }
            if (bIsOk)
            {
                tipList.push(this.TipList[i]);
            }
        }

        if (tipList.length == 0)
        {
            this.previousTips = [];
            tipList = this.TipList;
        }

        let index = Math.floor(Math.random() * tipList.length);

        this.previousTips.push(tipList[index]);

        this.currentTip = {};

        if (tipList[index].title)
        {
            this.currentTip.title = this.LabelManager.translate(tipList[index].title);
        }

        this.currentTip.description = this.LabelManager.translate(tipList[index].description);

        this.lastTime = new Date().getTime();
        this.totalTime = this.TipAnimationTime;
        this.timeLeft = this.totalTime;

        this.tipPos.x = "(iw + " + this.Values.modal.stroke.size + ")";
        this.tipPos.startX = this.tipPos.x;
        this.tipPos.endX = "((iw / 2) - " + this.TipWidth + " / 2)";
        this.tipPos.state = "in";

        this.animateTip();
    }

    animateTip()
    {
        let bShouldLoop = !this.shouldDestroy;

        if(bShouldLoop)
        {
            let time = new Date().getTime();
            let delta = time - this.lastTime;
            this.lastTime = time;

            this.timeLeft -= delta;

            if (this.tipPos.state == "in" || this.tipPos.state == "out")
            {
                let t = Math.max(0, Math.min(1, 1 - this.timeLeft / this.totalTime));
                t = t * t * t * (t * (6 * t - 15) + 10);

                this.tipPos.x = "(" + this.tipPos.endX + " - " + this.tipPos.startX + ") * " + t + " + " + this.tipPos.startX;
                this.buildTip();
            }

            if (this.timeLeft <= 0)
            {
                if (this.tipPos.state == "in")
                {
                    this.totalTime = this.TipWaitTime;
                    this.timeLeft = this.totalTime;
                    this.tipPos.state = "wait";
                }
                else if (this.tipPos.state == "wait")
                {
                    this.totalTime = this.TipAnimationTime;
                    this.timeLeft = this.totalTime;
                    this.tipPos.state = "out";

                    this.tipPos.startX = this.tipPos.endX;
                    this.tipPos.endX = "(-(" + this.TipWidth + " + " + this.Values.modal.stroke.size + "))";
                    this.tipPos.x = this.tipPos.startX;
                }
                else
                {
                    bShouldLoop = false;
                    this.pickNewTip();
                }

            }
        }

        if (bShouldLoop)
        {
            window.requestAnimationFrame(this.animateTip.bind(this));
        }
    }
    /*******************************************
    *   UTILITIES
    *******************************************/
    showSceneTransition(fctCallbackMiddle = null, fctCallback = null)
    {
        if (!this.screenTransition)
        {
            this.screenTransition = new ScreenTransition().init({"ui": this});
        }

        this.addChild(this.screenTransition);

        this.screenTransition.startTransition(
            fctCallbackMiddle, 
            function(fctCallback) 
            { 
                if (fctCallback)
                {
                    fctCallback();
                }

                this.removeChild(this.screenTransition);
                this.screenTransition.destroy({"children": true});
                delete this.screenTransition;

            }.bind(this, fctCallback)
        );
    }

    /*******************************************
    *   ACTIONS
    *******************************************/
    startGame()
    {
        this.showSceneTransition(() => 
        {
            this.GameManager.resume();
            this.WorldManager.resumeTimeOfDay();

            this.CharacterManager.switchCurrentCharacter(0);

            this.WorldManager.changeEnvironment(this.WorldManager.ENVIRONMENT_BARN);
            this.WorldManager.allowWorldClick();
        });
    }

    /*******************************************
    *   EVENTS
    *******************************************/
    onResize(iNewWidth, iNewHeight, iLeftPadding)
    {
        this.build();
    }

    onLoadProgress(ratio)
    {
        this.progress = ratio;
        this.buildLoadBar();
    }

    onLoadingDone()
    {
        this.startGame();
    }
}