import { string2hex }  from '@pixi/utils'; 
import { TextStyle } from '@pixi/text';
import { TextMetrics } from '@pixi/text';
import ContainerResponsive from "../responsive/ContainerResponsive.js";
import GraphicsResponsive from "../responsive/GraphicsResponsive.js";
import TextResponsive from "../responsive/TextResponsive.js";

export default class InventoryQuantity extends ContainerResponsive
{
    constructor()
    {
        super();
    }

    get Quantity() { return Math.min(999, this.quantity); }
    set Quantity (quantity)
    {
        this.quantity = quantity;
        this.build();
    }

    get TextFont() { return this.UI.Values.inventory.slot.text.font; }
    get TextSize() { return this.evaluate(this.valueFormula(this.UI.Values.inventory.slot.text.size), 0, 0, 0, 0, this); }
    get TextWeight() { return this.UI.Values.inventory.slot.text.weight; }
    get TextColor() { return string2hex(this.UI.Values.inventory.slot.text.color); }

    get BgColor() { return string2hex(this.UI.Values.inventory.slot.text.background.color); }
    get BgOpacity() { return this.UI.Values.inventory.slot.text.background.opacity; }

    /*******************************************
    *   INITIALIZATION
    *******************************************/
    /**
        Parameters to pass to the init function:
        - ui:       UI section object where this component resides
        - quantity: Quantity to display
    */
    init(meta)
    {
        this.quantity = meta.quantity;

        return super.init(meta);
    }

    destroy(options)
    {
        if (this.bg)
        {
            this.removeChild(this.bg);
            this.bg.destroy({"children": true});
            delete this.bg;
        }

        super.destroy(options);
    }

    /*******************************************
    *   BUILD
    *******************************************/
    build ()
    {
        super.build();

        this.buildBackground();
        this.buildText();
        this.buildVisuals();
    }

    clean()
    {
        if (this.bg)
        {
            this.bg.clear();
        }

        super.clean();
    }

    buildBackground()
    {
        if (!this.bg)
        {
            this.bg = new GraphicsResponsive().init({"ui": this.UI});
            this.addChild(this.bg);
        }
    }

    buildText()
    {
        if (!this.txt)
        {
            this.txt = new TextResponsive(this.Quantity).init({"ui": this.UI});
            this.txt.roundPixels = true;
            
            this.bg.addChild(this.txt);
        }
    }

    buildVisuals()
    {
        let label = this.Quantity + (this.quantity > this.Quantity ? "+" : "");

        let fontSize = this.TextSize;
        let f = this.evaluate(this.valueFormula(this.UI.Values.inventory.slot.text.size), 0, 0, 0, 0, this);

        fontSize = Math.max(fontSize, this.UI.ResponsiveManager.TextMinSize * 0.75);


        let style = new TextStyle({ 
            fontFamily: this.TextFont, 
            fontSize,
            fontWeight: this.TextWeight, 
            fill: this.TextColor, 
            align: "center"
        });

        let metrics = TextMetrics.measureText((label.length > 3 ? label : "999"), style);
        let quantityMetrics = TextMetrics.measureText(label, style);

        let bgWidth = metrics.width * 1.4;
        let bgHeight = metrics.height * 1.25;
        let x = 0;
        let y = 0;

        this.bg.clear();
        this.bg.beginFill(this.BgColor, this.BgOpacity);
        this.bg.drawRoundedRect(x,y, bgWidth, bgHeight, this.evaluate(this.valueFormula(5), 0, 0, 0, 0, this));
        this.bg.endFill();

        this.txt.text = label;
        this.txt.style = style;
        this.txt.x = x + bgWidth / 2 - quantityMetrics.width / 2;
        this.txt.y = y + bgHeight / 2 - quantityMetrics.height / 2;
    }

    setMask(objMask)
    {
        this.mask = objMask;
    }
}