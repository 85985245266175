import BaseCheat from "./BaseCheat.js";

export default class ItemEquip extends BaseCheat
{
    constructor(dependencies)
    {
        super(dependencies);
    }

    get CommandUse() { return "item equip [id]"; }
    get CommandDesc() { return "Equips an item from the character's inventory"; }

    //---------------------------------------------------------
    //  DEPENDENCIES
    //---------------------------------------------------------
    get ItemManager() { return this.dependencies.get("ItemManager"); }
    //---------------------------------------------------------

    parseCommand(strCmd, arrArguments)
    {
        if (strCmd == "item" && arrArguments.length >= 2 && arrArguments[0].toLowerCase() == "equip")
        {
            let id = arrArguments[1].toUpperCase();
            if (this.ItemManager.getItem(id))
            {
                let result = this.ItemManager.equipItem(id);
                if (result.status)
                {
                    this.log("Equipped " + id);
                    return;
                }
                else if (result.args.notOwningItem)
                {
                    let result = this.ItemManager.addItem(id, 1);
                    if (result.status)
                    {
                        this.log("The item " + id + " was added 1 time in your inventory");
                        let result = this.ItemManager.equipItem(id);
                        if (result.status)
                        {
                            this.log("Equipped " + id);
                            return;
                        }
                    }
                    this.warn("The character doesn't have " + id + " in it's inventory");
                    return;
                }
            }
            this.warn("Could not equip " + arrArguments[1]);
        }
    }
}