import BaseCheat from "./BaseCheat.js";

export default class Dialog extends BaseCheat
{
    constructor(dependencies)
    {
        super(dependencies);
    }

    get CommandUse() { return "dialog [\"speaker\"] [\"content\"]"; }
    get CommandDesc() { return "Displays a dialog on screen. Don't forget to add the \"\" for the parameters"; }

    //---------------------------------------------------------
    //  DEPENDENCIES
    //---------------------------------------------------------
    get UIManager() { return this.dependencies.get("UIManager"); }
    //---------------------------------------------------------

    parseCommand(strCmd, arrArguments)
    {
        if (strCmd == "dialog" && arrArguments.length >= 2)
        {
            let args = arrArguments[0];

            for (let i = 1; i < arrArguments.length; i++)
            {
                args += " " + arrArguments[i];
            }

            args = this.extractFromStr(args);
            if (args.length >= 2)
            {
                this.UIManager.showDialog(args[0], args[1]);
                this.log("Dialog was shown with speaker: " + args[0] + " and content: " + args[1]);
            }
            else
            {
                this.warn("Could not properly extract data from this command");
            }

        }
    }

    extractFromStr(str)
    {
        let re = /"(.*?)"/g;
        let result = [];
        let current;
        while (current = re.exec(str))
        {
            result.push(current.pop());
        }
        return result.length > 0 ? result : [str];
    }
}