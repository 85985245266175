import { Color } from "three/build/three.module.js";
import {Vector2} from "three";
import WorldManager from "../../WorldManager.js";


var TimeOfDayShader = {

    uniforms: {

        "tDiffuse": { value: null },
        "color": { value: new Color( 1, 1, 1 ) },
        "time": {value: 0.5 },
        "iResolution": { value: new  Vector2(window.innerWidth, window.innerHeight)},
        "iMouse": { value: new  Vector2(window.innerWidth / 2, window.innerHeight / 2)},

    },

    vertexShader: [

        "varying vec2 vUv;",

        "void main() {",

        "   vUv = uv;",
        "   gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );",

        "}"

    ].join( "\n" ),

    fragmentShader: [
        "uniform sampler2D tDiffuse;",
        "varying vec2 vUv;",
        "",
        "void main(void)",
        "{",
            "vec2 uv = vUv;",
            "",
            "vec3 orig = texture2D(tDiffuse, uv).xyz;",
            "float brightness = 1.0;", //3.0
            "vec3 col = orig;",//on augmente le contraste
            "float bri = (col.x+col.y+col.z) / brightness;",
            "",
            "vec3 mixColor1 = vec3(1.0, 1.0, 1.0);",
            "vec3 mixColor2 = vec3(1.0, 0.0, 0.0) / 5.0;", //vec3(1.0, .55, 0.)
            "",
            "float v = smoothstep(.0, .7, bri);",
            "col = mix(mixColor1 * bri, col, v);",
            "",
            "v = smoothstep(.2, 1.1, bri);",
            "",
            "col = mix(col, min(mixColor2 * col * 1.0, 1.0), v);",
            "",
            "gl_FragColor = vec4(min(col, 1.0),1.0);",
        "}"
    ].join( "\n" )

};

export { TimeOfDayShader };
