import {Vector2} from "three";
import {Vector3} from "three";
import BaseScene from "./BaseScene.js";
import IndoorCamera from "../cameras/IndoorCamera.js";
import IndoorJsonParser from "../map/parsers/IndoorJsonParser.js";
import IndoorMapLayout from "../map/IndoorMapLayout.js";
import MapGrid from "../map/MapGrid.js";
import MapObstacles from "../map/MapObstacles.js";

import {AxesHelper} from "three";
import DependencyContainer from "../../utils/DependencyContainer.js";

export default class IndoorScene extends BaseScene
{
    constructor(canvas)
    {
        super(canvas);

        this.dependencies = DependencyContainer.getInstance();
    }

    get EVENT_ROOM_CHANGE() { return "room-change"; }
    get EVENT_EXIT_OUTDOOR() { return "exit-outdoor"; }

    get CAMERA_DISTANCE_FROM_GROUND() { return 150; }

    get Environment() { return this.environment; }
    get RoomId() { return this.roomId; }
    get RoomData() { return this.roomData; }
    get MapLayout() { return this.layout;}
    get MapObstacles() { return this.obstacles;}
    get ObstacleParser() { return this.parser;}
    get Grid() { return this.grid;}
    get WorldManager()      { return this.dependencies.get("WorldManager"); }

    /*******************************************
    *   INITIALIZATION
    *******************************************/
    /**
        Parameters to pass to the init function:
        - environment:  Environment where this scene lives
        - roomId:       Id of the room this scene should represents
        - data:         Data of this current room
    */
    init(meta)
    {
        this.environment = meta.environment;
        this.roomId = meta.roomId;
        this.roomData = meta.data;

        super.init();

        this.createParser();
        this.createGrid();
        this.createLayout();
        this.createObstacles();
        this.createEnemies();

        this.layout.loadRoom( this.roomData);

        return this;
    }

    createClosure()
    {
        super.createClosure();

        this.fctMouseMove = this.onMouseMove.bind(this, false);
        this.fctMouseUp = this.onMouseUp.bind(this, false);
        this.fctTouchEnd = this.onTouchEnd.bind(this, false);
        this.fctOnDoorOpen = this.onDoorOpen.bind(this);
    }

    createCamera()
    {
        let size = new Vector2();
        this.WorldManager.renderer.getSize(size);

        this.camera = new IndoorCamera(size.x / -2, size.x / 2, size.y / 2, size.y / -2, 0, 1000);

        this.camera.rotation.set(
            this.RoomData.camera.rotation.x,
            this.RoomData.camera.rotation.y,
            this.RoomData.camera.rotation.z
        );

        console.log("change camera rotation")

        this.camera.aspect = size.width / size.height;


        this.camera.zoom = 2;
        this.camera.updateProjectionMatrix();

        let distance = this.CAMERA_DISTANCE_FROM_GROUND;
        let direction = new Vector3();
        this.camera.getWorldDirection(direction);

        this.camera.position.set(
            -direction.x * distance,
            -direction.y * distance,
            -direction.z * distance
        );

        this.camera.directionVector = direction;
        this.camera.distanceFromGround = distance;
    }

    /**
        This floor mesh is mainly used to detect clicks through a CameraRaycaster. It's way easier and safer to let ThreeJS
        calculate hit points for us, specially with a perspective camera.
    */
    createFloorMesh()
    {
        super.createFloorMesh();

        this.floorMesh.position.set(0, 0, 0)
        this.floorMesh.rotation.set(
            -Math.PI / 2,
            0,
            0
        );
    }

    createLayout()
    {
        this.layout = new IndoorMapLayout().init({
            "dependencies": this.Dependencies,
            "environment": this.Environment,
            "roomId": this.RoomId,
            "width": this.RoomData.size.width,
            "height": this.RoomData.size.height
        });

        this.layout.on(this.layout.EVENT_DOOR_OPEN, this.fctOnDoorOpen);
    }

    createParser()
    {
        this.parser = new IndoorJsonParser().init({
            "dependencies": this.Dependencies,
            "width": this.RoomData.size.width
        });
        this.parser.loadMapData(this.RoomData.obstacles, this.RoomData.enemies);
        this.parser.blockNearWall(this.RoomData.size.width);
    }

    createGrid()
    {
        this.grid = new MapGrid().init({
            "dependencies": this.Dependencies,
            "parser": this.parser,
            "width": this.RoomData.size.width,
            "height": this.RoomData.size.height
        });
    }

    createObstacles()
    {
        this.obstacles = new MapObstacles().init({
            "dependencies": this.Dependencies,
            "environment": this.Environment,
            "parser": this.parser
        });
    }

    createEnemies()
    {
        for (let tileId in this.RoomData.enemies)
        {
            let pos = this.Grid.getPosFromTileId(tileId);
            let data = this.RoomData.enemies[tileId];

            this.Environment.spawnEnemy(
                data.id,
                pos.x,
                pos.y,
                pos.x,
                pos.y,
                data.path
            );
        }
    }

    destroy()
    {
        this.layout.destroy();
        delete this.layout;

        this.obstacles.destroy();
        delete this.obstacles;

        delete this.parser;
        delete this.grid;

        super.destroy();
    }

    /*******************************************
    *   UPDATE LOOP
    *******************************************/
    updateDisplay (iX, iY)
    {
        if (this.obstacles)
        {
            this.obstacles.updateDisplay(iX, iY);
        }
    }

    /*******************************************
    *   EVENTS
    *******************************************/

    findExitPos ()
    {
        let door = this.RoomData.doors.find( door => door.out);
        let outIndex = this.WorldManager.Grid.getTileId2(door.out.x, door.out.y);
        return outIndex;
    }
    
    onDoorOpen(pos)
    {
        let door = this.RoomData.doors.find( door => parseInt(door.pos.x) == parseInt(pos.x) && parseInt(door.pos.y) == parseInt(pos.y));

        if (door && "out" in door)
        {
            let outIndex = this.WorldManager.Grid.getTileId2(door.out.x, door.out.y);
            this.emit(this.EVENT_EXIT_OUTDOOR, door.id, outIndex);
        }
        else
        {
            this.emit(this.EVENT_ROOM_CHANGE, door.room, door.door);
        }
    }
}