import { Graphics } from '@pixi/graphics';
import ContainerResponsive from "../../commons/responsive/ContainerResponsive.js";

export default class ProgressBar extends ContainerResponsive
{
    constructor()
    {
        super();
    }

    get BackgroundColor() { return 0x26212F; }
    get FillColor() { return 0x8878A9; }

    /*******************************************
    *   INITIALIZATION
    *******************************************/
    /**
        Parameters to pass to the init function:
        - ui:       UI section object where this component resides
        - rWidth:   Responsive equation representing the width of this component
        - rHeight:  Responsive equation representing the height of this component
    */
    init(meta)
    {
        this.strWidth = meta.rWidth;
        this.strHeight = meta.rHeight;
        this.pct = 0;

        return super.init(meta);
    }

    destroy(options)
    {
        if (this.background)
        {
            this.removeChild(this.background);
            this.background.destroy(options);
            delete this.background;
        }

        if (this.progressbar)
        {
            this.removeChild(this.progressbar);
            this.progressbar.destroy(options);
            delete this.progressbar;
        }

        if (this.gfxMask)
        {
            this.removeChild(this.gfxMask);
            this.gfxMask.destroy(options);
            delete this.gfxMask;
        }

        super.destroy(options);
    }

    /*******************************************
    *   BUILD
    *******************************************/
    build ()
    {
        super.build();

        if (this.strWidth && this.strHeight)
        {
            //Temp method. Will replace with real design later
            let color = this.BackgroundColor;

            let width = this.evaluate(this.strWidth, 0, 0, 0, 0);
            let height = this.evaluate(this.strHeight, 0, 0, 0, 0);

            if (!this.background)
            {
                this.background = new Graphics();
                this.addChild(this.background);
            }

            this.background.beginFill(color, 1);
            this.background.drawRect(0,0, width, height);
            this.background.endFill();

            color = this.FillColor;

            if (!this.gfxMask)
            {
                this.gfxMask = new Graphics();
                this.addChild(this.gfxMask);
            }
            this.gfxMask.scale.x = this.pct;
            this.gfxMask.beginFill(0xFFFFFF, 1);
            this.gfxMask.drawRect(0,0, width, height);
            this.gfxMask.endFill();

            if (!this.progressbar)
            {
                this.progressbar = new Graphics();
                this.progressbar.mask = this.gfxMask;
                this.addChild(this.progressbar);
            }

            this.progressbar.beginFill(color, 1);
            this.progressbar.drawRect(0,0, width, height);
            this.progressbar.endFill();
        }
    }

    progress (pct)
    {
        this.pct = pct;
        if (this.gfxMask)
        {
            this.gfxMask.scale.x = pct;
        }
    }
}