import gsap from "gsap";
import {OrthographicCamera} from "three";
import {Vector2} from "three";
import {Vector3} from "three";
import EventEmitter from "eventemitter3";
import Constants from "../../utils/Constants.js";
import CameraRaycaster from "./CameraRaycaster.js";
import Lerp from "../../utils/Lerp.js";
import DependencyContainer from "../../utils/DependencyContainer.js";

export default class IndoorCamera extends OrthographicCamera
{

    constructor(iLeft, iRight, iTop, iBottom, iNear, iFar)
    {
        super(iLeft, iRight, iTop, iBottom, iNear, iFar);



        this.eventemitter = new EventEmitter();
        this.dependencies = DependencyContainer.getInstance();

        this.init();
    }

    get EVENT_GRID_POS_CHANGED() { return "grid-pos-changed"; }

    //---------------------------------------------------------
    //  DEPENDENCIES
    //---------------------------------------------------------
    get GameManager() { return this.Dependencies.get("GameManager"); }
    get ResponsiveManager() { return this.Dependencies.get("ResponsiveManager"); }
    get WorldManager() { return this.Dependencies.get("WorldManager"); }
    //---------------------------------------------------------

    get Dependencies() { return this.dependencies; }
    get Raycaster() { return this.raycaster; }
    get FloorMesh() { return this.floorMesh; }
    set FloorMesh(newValue) { this.floorMesh = newValue; }
    get GridPos()
    {
        if (this.WorldManager.Grid)
        {
            return this.WorldManager.Grid.worldToGridPosition(
                this.position.x,
                this.position.z
            );
        }
        return new Vector2();
    }

    createClosure()
    {

    }

    bindEvents()
    {

    }

    init()
    {
        this.createClosure();
        this.bindEvents();

        this.origin = new Vector3(this.position.x, this.position.y, this.position.z);
        this.lastGridPos = null;

        this.raycaster = new CameraRaycaster(this);
    }

    destroy()
    {
        this.raycaster.destroy();
    }

    /*******************************************
    *   UPDATE LOOP
    *******************************************/
    update(fDeltaTime)
    {
        if (!this.GameManager.IsPaused)
        {
            this.updateMovement(fDeltaTime);
            this.updateFollowing(fDeltaTime);
        }
        this.updateFloorMesh(fDeltaTime);
    }

    updateMovement(fDeltaTime)
    {
        if (this.toReach)
        {
            let min = Math.pow(Constants.getValue("CAMERA_MIN_DIST_SMOOTHING"), 2);
            let sqrDist = Math.pow(this.toReach.x - this.position.x, 2) +  Math.pow(this.toReach.z - this.position.z, 2);

            if (sqrDist >= min)
            {
                //We want to instantly move the camera if the distance is too great because of all the
                //loading that occurs. We don't want the floor and obstacles to load/unload stuff all
                //the way to the new position
                let instantMove = Math.pow(Constants.getValue("CAMERA_MIN_DIST_FOR_INSTANT_MOVE"), 2);

                if (sqrDist < instantMove)
                {
                    let smooth = Constants.getValue("CAMERA_SMOOTHING");

                    this.position.x = Lerp.lerp(this.position.x, this.toReach.x, fDeltaTime * smooth);
                    this.position.y = Lerp.lerp(this.position.y, this.toReach.y, fDeltaTime * smooth);
                    this.position.z = Lerp.lerp(this.position.z, this.toReach.z, fDeltaTime * smooth);
                }
                else
                {
                    this.position.set(this.toReach.x, this.toReach.y, this.toReach.z);
                }

                let gridPos = this.GridPos;
                if (!this.lastGridPos || gridPos.x != this.lastGridPos.x || gridPos.y != this.lastGridPos.y)
                {
                    this.lastGridPos = gridPos;
                    this.emit(this.EVENT_GRID_POS_CHANGED, gridPos);
                }
            }
        }
    }

    updateFollowing(fDeltaTime)
    {
        if (this.objFollow)
        {
            this.positionCameraOnPosition(this.objFollow.position);
            
        }
    }

    updateFloorMesh(fDeltaTime)
    {
        if (this.FloorMesh && (this.position.x != this.FloorMesh.position.x || this.position.z != this.FloorMesh.position.z))
        {
            this.FloorMesh.position.x = this.position.x;
            this.FloorMesh.position.z = this.position.z;
        }
    }

    /*******************************************
    *   EVENT EMITTER
    *******************************************/
    on(strEvent, closure)
    {
        this.eventemitter.on(strEvent, closure);
    }

    once(strEvent, closure)
    {
        this.eventemitter.once(strEvent, closure);
    }

    off(strEvent, closure)
    {
        this.eventemitter.off(strEvent, closure);
    }

    emit(strEvent)
    {
        this.eventemitter.emit(strEvent, arguments);
    }

    /*******************************************
    *   UNIT CONVERSION
    *******************************************/
    screenToWorldPosition(iScreenX, iScreenY, bTargetGround = false)
    {
        if (bTargetGround)
        {
            let rays = this.raycaster.intersectsFromScreen(iScreenX, iScreenY, [this.FloorMesh]);

            if (rays && rays.length > 0)
            {
                let cellSize = Constants.getValue("GRID_CELL_SIZE");

                let x = rays[0].point.x;
                if (x % cellSize > cellSize / 2)
                {
                    x += cellSize - x % cellSize;
                }
                else
                {
                    x -= x % cellSize;
                }

                let z = Math.round(rays[0].point.z / 10) * 10;
                if (z % cellSize > cellSize / 2)
                {
                    z += cellSize - z % cellSize;
                }
                else
                {
                    z -= z % cellSize;
                }

                return new Vector3(x, 0, z);
            }
        }


        let width = this.ResponsiveManager.Width;
        let height = this.ResponsiveManager.Height;

        let mouse = new Vector3
        (
            (iScreenX / width) * 2 - 1,
           -(iScreenY / height) * 2 + 1,
            0.5
        );

        let vec = mouse.unproject(this);

        vec.sub(this.position).normalize();
        //Use the Y plane instead of Z as our objects are all positioned at 0 on the Y axis
        let distance = -this.position.y / vec.y;

        let pos = new Vector3();
        pos.copy(this.position).add(vec.multiplyScalar(distance));

        return pos;
    }

    worldToScreenPosition(iObjectX, iObjectY, iObjectZ, bNormalized = false)
    {
        let width = this.ResponsiveManager.Width;
        let height = this.ResponsiveManager.Height;
        let widthHalf = width / 2 + this.directionVector.x * this.distanceFromGround;
        let heightHalf = height / 2 + this.directionVector.y * this.distanceFromGround;

        var pos = new Vector3(iObjectX, iObjectY, iObjectZ);
        pos.project(this);
        if (!bNormalized)
        {
            pos.x = (pos.x * widthHalf) + widthHalf;
            pos.y = - (pos.y * heightHalf) + heightHalf;
        }

        return pos;
    }

    /*******************************************
    *   MOVEMENT FUNCTIONS
    *******************************************/
    moveTo(pos)
    {
        this.position.x = pos.x * 10;
        this.position.z = (pos.z + 10) * 10;

        this.updateProjectionMatrix();

        let gridPos = this.GridPos;
        if (!this.lastGridPos || gridPos.x != this.lastGridPos.x || gridPos.y != this.lastGridPos.y)
        {
            this.lastGridPos = gridPos;
            this.emit(this.EVENT_GRID_POS_CHANGED, gridPos);
        }
    }

    startFollowing(obj)
    {
        this.objFollow = obj;
        this.lastMeshPos = {x:obj.position.x, y:obj.position.y, z:obj.position.z};
        this.positionCameraOnPosition(obj.position, true);
    }

    stopFollowing()
    {
        this.objFollow = null;
    }

    /**
        Position the camera on a specific coordinate in the game
        @param objPos       Position to position the camera to. This value should be definied in ThreeJS world coordinates (not grid coordinates)
        @param jumpDirectly (Optional)Instantly jumps the camera to the position without smoothing. Default is FALSE
    */
    positionCameraOnPosition(objPos, jumpDirectly = false)
    {
        let divider = (!jumpDirectly ? Constants.getValue("CAMERA_SMOOTHING") / 5 : 1);
        let diffX = this.position.x - objPos.x + this.directionVector.x * this.distanceFromGround;
        let diffZ = this.position.z - objPos.z + this.directionVector.z * this.distanceFromGround;

        let newPos = {
            "x": this.position.x - diffX / divider,
            "y": this.position.y,
            "z": this.position.z - diffZ / divider
        };

        this.toReach = newPos;

        if (jumpDirectly)
        {
            this.position.x = newPos.x;
            this.position.y = newPos.y;
            this.position.z = newPos.z;

            let gridPos = this.GridPos;
            if (!this.lastGridPos || gridPos.x != this.lastGridPos.x || gridPos.y != this.lastGridPos.y)
            {
                this.lastGridPos = gridPos;
                this.emit(this.EVENT_GRID_POS_CHANGED, gridPos);
            }
        }
    }

    /*******************************************
    *   VISUAL EFFECTS
    *******************************************/
    shake(fDuration)
    {
        let pos = this.position.x;
        gsap.to(this.position, 0.06, {x:"+=4", yoyo:true, repeat:fDuration * 10,  onComplete: function(iX)
        {
            this.position.set(
                iX,
                this.position.y,
                this.position.z
            );
        }
        .bind(this, pos)});
    }
}