import {PlaneGeometry} from "three";
import AnimationController from "./animations/AnimationController.js";
import Character from "./Character.js";
import Direction from "../../utils/Direction.js";
import Library from "../../Library.js";
import _ from "lodash";

export default class Npc extends Character
{
    constructor()
    {
        super();
    }

    get SPRITESHEET_COLUMN_COUNT() { return 4; }

    get Definition() { return this.meta.definition; }

    /*******************************************
    *   INITIALIZATION
    *******************************************/
    /**
        Parameters to pass to the init function:
        - id:           Logic id of this object instance. Usually managed by some sort of global manager
        - definition    Definition of this enemy (from the settings.json file)
        - spawnPos      Vector2 containing the world position to spawn this object in ThreeJS
        - direction     Initial direction where the player will look at. Use the Direction class at utils/Direction.js
        - environment   Environment instance where the player lives
        - params        JSON object containing additionnal parameters to pass to the player

    */
    init(meta)
    {
        this.definition = meta.definition;

        meta.atlasName = meta.definition.spritesheet;
        meta.textureName = (!meta.textureName ? undefined : meta.textureName);

        if (meta.definition.mesh && meta.definition.mesh.origin)
        {
            meta.meshOrigin = meta.definition.mesh.origin;
        }

        super.init(meta);
    }

    createGeometry(objSize)
    {
        let divider = this.ResponsiveManager.AssetDivider * 0.85;

        //@TODO: We should do something about this instead of hardcoding
        //------------------------------------------------------
        let colCount = this.SPRITESHEET_COLUMN_COUNT;

        let data = Library.getData("animation_atlas");
        let animInfo = data[this.Definition.atlas];
        let animation = animInfo["idle"];
        let strDirection = Direction.getAtlasName(Direction.South);
        let animDirection = animation[strDirection][0];


        let rowCount = Math.ceil(
            animDirection.frameCount / colCount
        );
        //------------------------------------------------------

        this.planeSize = {
            "width": objSize.w / divider / colCount,
            "height": objSize.h / divider / rowCount
        };



        let widthMultiplier = _.get(this.meta.definition, "mesh.scaleMultiplier.width", 1);
        let heightMultiplier = _.get(this.meta.definition, "mesh.scaleMultiplier.height", 1);


        let geometry = new PlaneGeometry(this.planeSize.width * widthMultiplier, this.planeSize.height * heightMultiplier, 10, 10);
        geometry.translate(0, (objSize.h / divider / rowCount / 2) * heightMultiplier, 0);
        geometry.verticesNeedUpdate = true;

        return geometry;
    }

    createAnimationController()
    {
        this.animationController = new AnimationController();
        this.animationController.init({
            "atlasId":        this.Definition.atlas,
            "mesh":           this.mesh
        });
    }
}