import BaseCheat from "./BaseCheat.js";

export default class Stat extends BaseCheat
{
    constructor(dependencies)
    {
        super(dependencies);
    }

    get CommandUse() { return "stat"; }
    get CommandDesc() { return "Displays stats of the currently selected character"; }

    //---------------------------------------------------------
    //  DEPENDENCIES
    //---------------------------------------------------------
    get CharacterManager() { return this.dependencies.get("CharacterManager"); }
    get ItemManager() { return this.dependencies.get("ItemManager"); }
    //---------------------------------------------------------

    parseCommand(strCmd, arrArguments)
    {
        if (strCmd == "stat")
        {
            if (this.ItemManager.CurrentEquipment)
            {
                console.log("-----------------------------------------------------------------");
                console.log("Energy:", this.CharacterManager.getCharacterEnergy(), "/", this.CharacterManager.getCharacterMaxEnergy());
                console.log("Hunger:", this.CharacterManager.getCharacterHunger(), "/", this.CharacterManager.getCharacterMaxHunger());
                console.log("Stress:", this.CharacterManager.getCharacterStress(), "/", this.CharacterManager.getCharacterMaxStress());
                console.log("");
                console.log("Sprint duration:", this.CharacterManager.SprintDuration, "second" + (this.CharacterManager.SprintDuration > 1 ? "s" : ""));
                console.log("");
                console.log("Power Break rocks:", (this.CharacterManager.CanPush ? "Yes" : "No"));
                console.log("Power Invisible:", (this.CharacterManager.CanInvisible ? "Yes" : "No"));
                console.log("Power Spit:", (this.CharacterManager.CanSpit ? "Yes" : "No"));
                console.log("Power Stomp:", (this.CharacterManager.CanStomp ? "Yes" : "No"));
                console.log("Power Swim:", (this.CharacterManager.CanSwim ? "Yes" : "No"));
                console.log("Power Teleport:", (this.CharacterManager.CanTeleport ? "Yes" : "No"));
                console.log("");
                console.log("Equipment:");
                console.log("   - Body:", (this.ItemManager.CurrentEquipment.BodyEquipment ? this.ItemManager.CurrentEquipment.BodyEquipment.Id : "N/A"));
                console.log("   - Hands:", (this.ItemManager.CurrentEquipment.HandsEquipment ? this.ItemManager.CurrentEquipment.HandsEquipment.Id : "N/A"));
                console.log("   - Feet:", (this.ItemManager.CurrentEquipment.FeetEquipment ? this.ItemManager.CurrentEquipment.FeetEquipment.Id : "N/A"));
                console.log("   - Tool:", (this.ItemManager.CurrentEquipment.ToolEquipment ? this.ItemManager.CurrentEquipment.ToolEquipment.Id : "N/A"));
                console.log("   - Toy:", (this.ItemManager.CurrentEquipment.ToyEquipment ? this.ItemManager.CurrentEquipment.ToyEquipment.Id : "N/A"));
                console.log("   - Bag:", (this.ItemManager.CurrentEquipment.BagEquipment ? this.ItemManager.CurrentEquipment.BagEquipment.Id : "Default"));
                console.log("-----------------------------------------------------------------");
            }
            else
            {
                this.warn("Cannot display stats at this moment");
            }
        }
    }
}