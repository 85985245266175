import Constants from "../../utils/Constants.js"
export default class DoubleTapDetector
{
    constructor()
    {
        
    }

    get UI() { return this.ui; }
    get IsDetecting() { return this.detecting; }
    get Target() { return this.target; }

    get Callback() { return this.callback; }
    set Callback(fctNewCallback) { this.callback = fctNewCallback; }

    /*******************************************
    *   INITIALIZATION
    *******************************************/
    /**
        Parameters to pass to the init function:
        - ui:       UI section object where this detector resides
        - target:   Object to check for a double tap
        - callback: Function to call when a double tap has been registered on the target
    */
    init(meta)
    {
        this.ui = meta.ui;
        this.target = meta.target;
        this.callback = meta.callback;

        this.lastUp = (new Date().getTime());
        this.detecting = false;

        this.fctOnPointerUp = this.onPointerUp.bind(this);
        this.target.on("pointerup", this.fctOnPointerUp);//@

        return this;
    }

    destroy(options)
    {
        if (this.callback)
        {
            this.target.off("pointerup", this.callback);
        }
    }

    /*******************************************
    *   DETECTION
    *******************************************/
    startDetection()
    {
        this.detecting = true;
    }

    endDetection()
    {
        this.detecting = false;
    }

    calculateDoubleTap()
    {
        let time = (new Date().getTime());
        let diff = (time - this.lastUp) / 1000;

        if (diff >= Constants.getValue("DOUBLE_TAP_MIN_TIME") && diff <= Constants.getValue("DOUBLE_TAP_MAX_TIME"))
        {
            if (this.Callback)
            {
                this.Callback();
            }
        }

        this.lastUp = time;
    }

    /*******************************************
    *   EVENTS
    *******************************************/
    onPointerUp()
    {
        if (this.IsDetecting)
        {
            this.calculateDoubleTap();
        }
    }
}