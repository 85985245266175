import ItemManager from "./ItemManager.js";
import ItemCodes from "./codes/ItemCodes.js";

export default class Item
{
    constructor(strId, objDefinition)
    {
        this.id = strId;
        this.definition = objDefinition;
        this.craftReq = -1;
        this.bgColor = 0xde078b;
        this.type = ItemCodes.TYPE_ALL;
    }

    get Id() { return this.id; }
    get BGColor() { return this.bgColor; }
    get Asset() { return ("asset" in this.definition) ? this.definition.asset : ""; }
    get Type() { return this.type ? this.type : this.Id.split("-")[0]; }
    get InventoryPageType() { return this.Type; }
    get Name() { return ItemManager.instance.LabelManager.translate(this.definition.name); }
    get Description() { return ItemManager.instance.LabelManager.translate(this.definition.desc); }
    get IsDroppable() { return true; }
    get IsBackpack()
    {
        return (this.id.substr(0,2) === "BP")

        //return (this.definition.backpack ? true : false);
    }
    get MeshOrigin() { return (this.definition.mesh && this.definition.mesh.origin ? this.definition.mesh.origin : null); }
    get Level() { return this.definition.level; }
    
    calculateCraftRequirements (params)
    {
        this.craftReq = [];
        if (this.IsCraftable)
        {
            for (let id in params.req)
            {
                this.craftReq.push({
                    "item": ItemManager.instance.getItem(id),
                    "quantity": params.req[id]
                });
            }
        }
    }


}