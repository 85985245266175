import { string2hex }  from "@pixi/utils"; 
import { TextStyle } from "@pixi/text";
import { TextMetrics } from "@pixi/text";
import CloseButton from "./buttons/CloseButton.js";
import ContainerResponsive from "./responsive/ContainerResponsive.js";
import GraphicsResponsive from "./responsive/GraphicsResponsive.js";
import Display from "../../utils/Display.js";
import TextResponsive from "./responsive/TextResponsive.js";

export default class BasePopup extends ContainerResponsive
{
    constructor()
    {
        super();
    }

    get EVENT_POPUP_SHOW() { return "show"; }
    get EVENT_POPUP_HIDE() { return "hide"; }

    get SideBySide() { return this.sideBySide; }
    get ShowOverlay() { return true; }

    get IsShown() { return (this.parent ? true : false); }
    get ClickToHide() { return this.clickToHide; }
    get PauseOnShow() { return true; }

    get LinkedPopup() { return this.linked; }
    set LinkedPopup(newValue) { this.linked = newValue; }

    get BgWidth() { return `(ih * 90% * 70%)`; }
    get BgHeight() { return "(ih * 90%)"; }
    get BgX() { return 0; }
    get BgY() { return 0; }

    get BackgroundColor() { return string2hex(this.UI.Values.modal.background.color);}
    get StrokeWidth() { return this.evaluate(this.valueFormula(this.UI.Values.modal.stroke.size), 0, 0, 0, 0);}
    get StrokeColor() { return string2hex("color" in this.UI.Values.modal.stroke ? this.UI.Values.modal.stroke.color : "#000000");}
    get CornerRadius() { return this.evaluate(this.valueFormula(this.UI.Values.modal.corner.radius), 0, 0, 0, 0);}

    get TitleLabel() { return this.title; }
    get TitleFont() { return this.UI.Values.general.title.font; }
    get TitleFontSize() { return this.evaluate(this.valueFormula(this.UI.Values.general.title.size * 1.75)); }
    get TitleFontWeight() { return this.UI.Values.general.title.weight; }
    get TitleColor() { return this.UI.Values.general.text.color; }

    get CloseButtonX() { return this.BgX + " + " + this.BgWidth + " - " + this.closeButton.ButtonWidth + " / 3"; }
    get CloseButtonY() { return (this.BgY + " + " + this.closeButton.ButtonHeight + " / 2"); }
    get CloseButtonScale() { return 1; }

    /*******************************************
    *   INITIALIZATION
    *******************************************/
    /**
        Parameters to pass to the init function:
        - ui:           UI section object where this component resides
        - title:        Text to display as the title
        - clickToHide:  (Optional) If the popup should hide when the player clicks on the overlay behind. Default is TRUE
        - showOnStart   (Optional) If the popup should be shown on start. Default is TRUE
        - showClose:    (Optional) If the close button should be shown. Default is TRUE
        - createOverlay (Optional) If this popup should create it's own overlay for itself. If not, it will use the general UI overlay. Default is FALSE
        - sideBySide    (Optional) If this popup should be positioned side-by-side with other popups on screen. Default is TRUE
        - linked        (Optional) Linked popup to this one. If this one closes, the linked popup will close too. Default is NULL
    */
    init(meta)
    {
        this.graphics = {};
        this.sprites = {};
        this.texts = {};

        this.title = meta.title;
        this.linked = ("linked" in meta ? meta.linked : null);
        this.clickToHide = ("clickToHide" in meta ? meta.clickToHide : true);
        this.showClose = ("showClose" in meta ? meta.showClose : true);
        this.createOverlay = ("createOverlay" in meta ? meta.createOverlay : false);
        this.sideBySide = ("sideBySide" in meta ? meta.sideBySide : true);

        super.init(meta);

        this.UI.removeFromLayoutable(this);

        let showOnStart = ("showOnStart" in meta ? meta.showOnStart : true);
        if (showOnStart)
        {
            this.show();
        }

        return this;
    }

    createClosure()
    {
        super.createClosure();
        this.fctOnCloseClick = this.onCloseClick.bind(this);
        this.fctOnOverlayClick = this.onOverlayClick.bind(this);
    }

    destroy(options)
    {
        for (let key in this.graphics)
        {
            this.removeChild(this.graphics[key]);
            this.graphics[key].destroy(options);
        }
        delete this.graphics;

        for (let key in this.sprites)
        {
            this.removeChild(this.sprites[key]);
            this.sprites[key].destroy(options);
        }
        delete this.sprites;

        for (let key in this.texts)
        {
            this.removeChild(this.texts[key]);
            this.texts[key].destroy(options);
        }
        delete this.texts;

        if (this.closeButton)
        {
            this.closeButton.off(this.closeButton.EVENT_CLICK, this.fctOnCloseClick);
            this.closeButton.destroy(options);
        }
    }

    /*******************************************
    *   BUILD
    *******************************************/
    build()
    {
        super.build();

        if (this.createOverlay)
        {
            this.buildOverlay();   
        }

        this.buildBackground();
        this.buildTitle();
        if (this.showClose)
        {
            this.buildCloseButton();
        }
    }

    clean()
    {
        for (let key in this.graphics)
        {
            this.graphics[key].clear();
        }
        super.clean();
    }

    buildOverlay()
    {
        if (!this.graphics.overlay)
        {
            this.graphics.overlay = new GraphicsResponsive().init({"ui": this.UI});
            this.addChildAt(this.graphics.overlay, 0);
        }

        this.graphics.overlay.beginFill(0, this.UI.Values.modal.shadow.opacity);
        this.graphics.overlay.drawRect(0, 0, this.UI.ResponsiveManager.Width, this.UI.ResponsiveManager.Height);
        this.graphics.overlay.endFill();

        this.graphics.overlay.on("click", this.fctOnOverlayClick);

        this.graphics.overlay.interactive = true;
        this.graphics.overlay.buttonMode = false;

    }

    buildBackground ()
    {
        if (!this.graphics.background)
        {
            this.graphics.background = new GraphicsResponsive().init({"ui":this.UI});
            this.graphics.background.interactive = true;

            this.addChild(this.graphics.background);
        }

        let graphics = this.graphics.background;

        let width = this.evaluate(this.BgWidth, 0, 0, 0, 0, this);
        let height = this.evaluate(this.BgHeight, 0, 0, 0, 0, this);
        let x = this.evaluate(this.BgX, 0, 0, 0, 0, this);
        let y = this.evaluate(this.BgY, 0, 0, 0, 0, this);

        let bgColor = this.BackgroundColor;
        let strokeWidth = this.StrokeWidth;
        let strokeColor = this.StrokeColor;
        let cornerRadius = this.CornerRadius;

        if (strokeWidth > 0)
        {
            graphics.lineStyle({"color":strokeColor, "width":strokeWidth, "alignment":1});
        }

        graphics.beginFill(bgColor, 1);
        if (cornerRadius > 0)
        {
            graphics.drawRoundedRect(x, y, width, height, cornerRadius);
        }
        else
        {
            graphics.drawRect(x, y, width, height);
        }
        graphics.endFill();
    }

    buildTitle(fPaddingHorizontalRatio = 1.5, fPaddingVerticalRadio = 0.5)
    {
        if (!this.graphics.title)
        {
            this.graphics.title = new GraphicsResponsive().init({"ui":this.UI});
            this.addChild(this.graphics.title);
        }

        if (!this.texts.title)
        {
            this.texts.title = new TextResponsive().init({"ui": this.UI});
            this.addChild(this.texts.title);
        }

        let graphics = this.graphics.title;

        let text = this.texts.title;
        let label = this.TitleLabel;

        let style = new TextStyle({
            fontFamily: this.TitleFont,
            fontSize:   this.TitleFontSize,
            fontWeight: this.TitleFontWeight,
            fill: this.TitleColor,
            align: "center"
        });

        let metrics = TextMetrics.measureText(label, style);

        let bgX = this.evaluate(this.BgX, 0, 0, 0, 0, this);
        let bgY = this.evaluate(this.BgY, 0, 0, 0, 0, this);
        let bgWidth = this.evaluate(this.BgWidth, 0, 0, 0, 0, this);
        let bgHeight = this.evaluate(this.BgWidth, 0, 0, 0, 0, this);

        let paddingHorizontal = metrics.width * fPaddingHorizontalRatio;
        let paddingVertical = metrics.height * fPaddingVerticalRadio;
        if (paddingHorizontal < 0)
        {
            paddingHorizontal = bgWidth * 0.025;
        }
        if (paddingVertical < 0)
        {
            paddingVertical = bgWidth * 0.025;
        }
        let zoneWidth = metrics.width + paddingHorizontal
        let zoneHeight = metrics.height + paddingVertical;
        let zoneX = bgX + bgWidth / 2 - zoneWidth / 2;
        let zoneY = bgY - zoneHeight / 2;

        graphics.position.set(zoneX, zoneY);

        let bgColor = this.StrokeColor;
        let cornerRadius = this.CornerRadius;

        graphics.beginFill(bgColor, 1);
        if (cornerRadius > 0)
        {
            graphics.drawRoundedRect(0, 0, zoneWidth, zoneHeight, cornerRadius);
        }
        else
        {
            graphics.drawRect(0, 0, zoneWidth, zoneHeight);
        }
        graphics.endFill();

        let textX = bgX + bgWidth / 2 - metrics.width / 2
        let textY = zoneY + zoneHeight / 2 - metrics.height / 2;

        text.text = label;
        text.style = style;
        text.width = metrics.width;
        text.height = metrics.height;
        text.position.set(textX, textY);
    }

    buildCloseButton()
    {
        if (!this.closeButton)
        {
            this.closeButton = new CloseButton().init({"ui": this.UI, "scale": this.CloseButtonScale});
            this.closeButton.on(this.closeButton.EVENT_CLICK, this.fctOnCloseClick);//@

            this.addChild(this.closeButton);
        }

        this.closeButton.rX = [{on:"default", x: this.CloseButtonX}];
        this.closeButton.rY = [{on:"default", y: this.CloseButtonY}];
        this.closeButton.layout(Display.getSize());
    }

    /*******************************************
    *   RESPONSIVE
    *******************************************/
    layout()
    {
        this.setWindowPosition();
        super.layout();
    }

    setWindowPosition()
    {
        let arrPopups = this.UI.ShownPopups;
        let iCount = 0;
        for (let i = 0; i < arrPopups.length; i++)
        {
            if (arrPopups[i].SideBySide)
            {
                iCount++;
            }
        }

        if (arrPopups.length === 0)
        {
            return;
        }

        if (iCount <= 1) //when there is only one element, we must center it
        {
            let win = arrPopups[0];
            win.rX = [{on:"default", x:`(iw / 2) - (width / 2)`}];
            win.rY = [{on:"default", y:"ih / 2 - (height / 2) + 20"}];
        }
        else if (iCount > 1) //we expected 2
        {
            let strPadding = "((iw - (width * 2)) / 3)";
            let offset = `(width + ${strPadding})`;
            for (let i = 0; i < iCount; i++)
            {
                let win = arrPopups[i];
                win.rX = [{on: "default", x: `${strPadding} + (${i} * ${offset})`}];
                win.rY = [{on: "default", y: "ih / 2 - (height / 2) + 20"}];

            }
        }
    }

    /*******************************************
    *   ACTIONS
    *******************************************/
    show()
    {
        if (!this.IsShown)
        {
            this.emit(this.EVENT_POPUP_SHOW, this);
            this.UI.addToLayoutable(this);
        }
    }

    hide()
    {
        if (this.IsShown)
        {
            if (this.LinkedPopup)
            {
                this.LinkedPopup.hide();
            }

            setTimeout(this.UI.removeFromLayoutable.bind(this.UI, this), 50);
            this.emit(this.EVENT_POPUP_HIDE, this);
        }
    }

    /*******************************************
    *   EVENTS
    *******************************************/
    onOverlayClick()
    {
        if (this.ClickToHide)
        {
            this.hide();
        }
    }

    onCloseClick(sender)
    {
        this.hide();
    }
}