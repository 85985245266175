import ContainerResponsive from "../commons/responsive/ContainerResponsive.js";
import GraphicsResponsive from "../commons/responsive/GraphicsResponsive.js";
import Lerp from "../../utils/Lerp.js";

export default class Timer extends ContainerResponsive
{
    constructor()
    {
        super();
    }

    get EVENT_TIMER_END() { return "timer-end"; }

    get IsPaused() { return this.isPaused; }

    get BgWidth() { return this.bgWidth; }
    get BgHeight() { return this.bgHeight; }
    get BgX() { return 0; }
    get BgY() { return 0; }
    get CornerRadius() { return this.corner; }

    get BarWidth() { return "(" + this.BarHeight + " / 3)"; }
    get BarHeight() { return "((" + this.BgHeight + ") * 0.8)"; }
    get BarCornerRadius() { return "((" + this.CornerRadius + ") / 2)"; }
    get BarPadding() { return "((" + this.BgHeight + ") - " + this.BarHeight + ") / 2"; }
    get BarSpacing() { return "(" + this.BgWidth + " - " + this.BarPadding + " * 4 - " + this.BarWidth + " * " + this.BarCount + ") / " + this.BarCount; }

    get BarCount()
    {
        if (!this.barCount)
        {
            this.barCount = this.evaluate(
                "(" + this.BgWidth + " - " + this.BarPadding + " * 2) / (" + this.BarWidth + " * 1.5)",
                0, 0, 0, 0
            );
        }
        return this.barCount;
    }

    get BackgroundColor() { return 0x0E0E0E; }
    get BarFullColor() { return 0x62F8AC; }
    get BarEmptyColor() { return 0x2C6247; }

    /*******************************************
    *   INITIALIZATION
    *******************************************/
    /**
        Parameters to pass to the init function:
        - ui:       UI section object where this component resides
        - rWidth:   Responsive equation representing the width of this component
        - rHeight:  Responsive equation representing the height of this component
        - rCorner:  Responsive equation representing the size of the corner radius to apply
    */
    init(meta)
    {
        this.graphics = {};

        this.bgWidth = meta.rWidth;
        this.bgHeight = meta.rHeight;
        this.corner = meta.rCorner;

        this.isPaused = false;

        return super.init(meta);
    }

    destroy(options)
    {
        for (let key in this.graphics)
        {
            this.removeChild(this.graphics[key]);
            this.graphics[key].destroy(options);
        }
        delete this.graphics;

        super.destroy(options);
    }

    /*******************************************
    *   BUILD
    *******************************************/
    build()
    {
        super.build();

        this.buildBackground();
        this.buildBars();
    }

    clean()
    {
        for(let key in this.graphics)
        {
            this.graphics[key].clear();
        }
        super.clean();
    }

    buildBackground()
    {
        if (!this.graphics.background)
        {
            this.graphics.background = new GraphicsResponsive().init({"ui": this.UI});
            this.addChild(this.graphics.background);
        }

        let graphics = this.graphics.background;

        let x = this.evaluate(this.BgX, 0, 0, 0, 0);
        let y = this.evaluate(this.BgY, 0, 0, 0, 0);
        let width = this.evaluate(this.BgWidth, 0, 0, 0, 0);
        let height = this.evaluate(this.BgHeight, 0, 0, 0, 0);
        let corner = this.evaluate(this.CornerRadius, 0, 0, 0, 0);

        graphics.beginFill(this.BackgroundColor, 1);
        if (corner)
        {
            graphics.drawRoundedRect(x, y, width, height, corner);
        }
        else
        {
            graphics.drawRect(x, y, width, height);
        }
        graphics.endFill();
    }

    buildBars()
    {
        if (!this.graphics.bars)
        {
            this.graphics.bars = new GraphicsResponsive().init({"ui": this.UI});
            this.addChild(this.graphics.bars);
        }

        let graphics = this.graphics.bars;
        graphics.clear();

        let width = this.evaluate(this.BarWidth, 0, 0, 0, 0);
        let height = this.evaluate(this.BarHeight, 0, 0, 0, 0);
        let corner = this.evaluate(this.BarCornerRadius, 0, 0, 0, 0);
        let padding = this.evaluate(this.BarPadding, 0, 0, 0, 0);
        let spacing = this.evaluate(this.BarSpacing, 0, 0, 0, 0);
        let x = this.evaluate(this.BgX, 0, 0, 0, 0) + padding * 1.75;
        let y = this.evaluate(this.BgY, 0, 0, 0, 0) + padding;

        let currentCount = this.barsLit;
        let totalCount = this.BarCount;

        graphics.beginFill(this.BarFullColor, 1);

        for (let i = 0; i < totalCount; i++)
        {
            if (i == currentCount)
            {
                graphics.endFill();
                graphics.beginFill(this.BarEmptyColor, 1);
            }

            if (corner)
            {
                graphics.drawRoundedRect(x, y, width, height, corner);
            }
            else
            {
                graphics.drawRect(x, y, width, height);
            }

            x += width + spacing;
        }

        graphics.endFill();
    }

    /*******************************************
    *   UPDATE LOOP
    *******************************************/
    update(fDeltaTime)
    {
        if (!this.IsPaused && this.timeLeft > 0)
        {
            this.updateTime(fDeltaTime);
        }
    }

    updateTime(fDeltaTime)
    {
        this.timeLeft -= fDeltaTime;
        if (this.timeLeft <= 0)
        {
            this.barsLit = 0;
            this.buildBars();

            this.emit(this.EVENT_TIMER_END, this);
        }
        else
        {
            let newBarCount = Math.ceil(Lerp.lerp(0, this.BarCount, this.timeLeft / this.totalTime));
            if (newBarCount != this.barsLit)
            {
                this.barsLit = newBarCount;
                this.buildBars();
            }
        }
    }

    /*******************************************
    *   ACTIONS
    *******************************************/
    startTimer(fTotalTime)
    {
        this.totalTime = fTotalTime;
        this.timeLeft = fTotalTime;

        this.barsLit = this.BarCount;
    }

    stopTimer(fTotalTime)
    {
        this.timeLeft = 0;
    }

    pauseTimer()
    {
        this.isPaused = true;
    }

    resumeTimer()
    {
        this.isPaused = false;
    }

    substractTime(fTime)
    {
        this.timeLeft -= fTime;
        this.buildBars();

        if (this.timeLeft <= 0)
        {
            this.emit(this.EVENT_TIMER_END, this);
        }
    }
}