import IndoorEnvironment from "./IndoorEnvironment.js";
import MapObstacles from "../map/MapObstacles.js";

export default class HouseEnvironment extends IndoorEnvironment
{
    constructor(canvas, dependencies)
    {
        super(canvas);


    }

    init(params)
    {
        super.init(!params ? {} : params);

        
        this.AudioManager.playSfx("ambiance_grange", true, 2);
    }

    get Id() { return "house"; }
}