import BaseComponent from "../BaseComponent.js";
import Responsive from "./Responsive.js";
import Anchor from "./Anchor.js";

export default class ContainerResponsive extends BaseComponent
{
    constructor()
    {
        super();
    }

    /*******************************************
    *   INITIALIZATION
    *******************************************/
    /**
        Parameters to pass to the init function:
        - ui:  UI section object where this component resides
    */
    init(meta)
    {
        this.anchor = new Anchor();
        this.responsive = new Responsive().init({"ui": meta.ui});

        meta.ui.addToLayoutable(this);

        return super.init(meta);
    }

    createClosure ()
    {
        super.createClosure();

        this.fctLayout = () => this.layout(this.UI.Size);

        this.fctOnAdded = this.onAdded.bind(this);
        this.fctOnRemoved = this.onRemoved.bind(this);
        this.fctOnAnchorXChanged = this.onAnchorXChange.bind(this);
        this.fctOnAnchorYChanged = this.onAnchorYChange.bind(this);
    }

    bindEvents()
    {
        super.bindEvents();

        this.on("added", this.fctLayout);//@

        this.on("added", this.fctOnAdded);
        this.on("removed", this.fctOnRemoved);

        this.anchor.on("change-anchor-x", this.fctOnAnchorXChanged );
        this.anchor.on("change-anchor-y", this.fctOnAnchorYChanged );
    }

    destroy (options)
    {
        this.UI.removeFromLayoutable(this);
        
        this.off("added", this.fctOnAdded);
        this.off("removed", this.fctOnRemoved);

        this.anchor.off("change-anchor-x", this.fctOnAnchorXChanged );
        this.anchor.off("change-anchor-y", this.fctOnAnchorYChanged );

        while(this.children.length > 0)
        {
            let c = this.children[0];

            this.removeChild(c);

            if (c.destroy)
                c.destroy(options);

            c = null;
        }

        this.fctOnAdded = null;
        this.fctOnRemoved = null;
        this.fctOnAnchorXChanged = null;
        this.fctOnAnchorYChanged = null;

        if (this.responsive)
        {
            this.responsive.destroy();
        }

        super.destroy(options);
    }

    /*******************************************
    *   BUILD
    *******************************************/
    build()
    {
        super.build();
    }

    clean()
    {
        super.clean();
    }

    /*******************************************
    *   RESPONSIVE
    *******************************************/
    evaluate(expr, width, height, x, y, self, el)
    {
        return this.responsive.evaluate(expr, width, height, x, y, self, el);
    }

    valueFormula(strValue)
    {
        return this.UI.valueFormula(strValue);
    }

    //RESPONSIVE HANDLER
    set rOrder (responsives)
    {
        this.responsive.setResponsiveOrder(responsives);
    }

    setResponsiveOrder (responsives, strOn = "")
    {
        this.responsive.setResponsiveOrder(responsives);
    }

    set rX (responsives)
    {
        this.responsive.setResponsiveX(responsives);
    }

    setResponsiveX (responsives, strOn = "")
    {
        this.responsive.setResponsiveX(responsives);
    }

    set rY (responsives)
    {
        this.responsive.setResponsiveY(responsives);
    }

    setResponsiveY (responsives, strOn = "")
    {
        this.responsive.setResponsiveY(responsives);
    }

    set rHeight (responsives)
    {
        this.responsive.setResponsiveHeight(responsives);
    }

    setResponsiveHeight (responsives, strOn = "")
    {
        this.responsive.setResponsiveHeight(responsives);
    }

    set rWidth (responsives)
    {
        this.responsive.setResponsiveWidth(responsives);
    }

    setResponsiveWidth (responsives, strOn = "")
    {
        this.responsive.setResponsiveWidth(responsives);
    }

    set rScaleX (responsives)
    {
        this.responsive.setResponsiveScaleX(responsives);
    }

    setResponsiveScaleX (responsives, strOn = "")
    {
        this.responsive.setResponsiveScaleX(responsives);
    }

    set rScaleY (responsives)
    {
        this.responsive.setResponsiveScaleY(responsives);
    }

    setResponsiveScaleY (responsives, strOn = "")
    {
        this.responsive.setResponsiveScaleY(responsives);
    }

    set rAnchorX (responsives)
    {
        this.responsive.setResponsiveAnchorX(responsives);
    }

    setResponsiveAnchorX (responsives, strOn = "")
    {
        this.responsive.setResponsiveAnchorX(responsives);
    }

    set rAnchorY (responsives)
    {
        this.responsive.setResponsiveAnchorY(responsives);
    }

    setResponsiveAnchorY (responsives, strOn = "")
    {
        this.responsive.setResponsiveAnchorY(responsives);
    }

    set rClosure (responsives)
    {
        this.responsive.setResponsiveClosure(responsives);
    }

    setResponsiveClosure (responsives, strOn = "")
    {
        this.responsive.setResponsiveClosure(responsives);
    }

    /*******************************************
    *   LAYOUT
    *******************************************/
    layout(objSize)
    {
        super.layout(objSize);

        this.responsive.placeLayout(this);
        this.build();
    }

    /*******************************************
    *   EVENTS
    *******************************************/
    onAnchorXChange (value)
    {

    }

    onAnchorYChange (value)
    {

    }

    onAdded ()
    {
        
    }

    onRemoved ()
    {
        
    }
}
