import Constants from "../../utils/Constants.js";
import MessageNotificationPopup from "./popups/MessageNotificationPopup.js";
import ResultNotificationPopup from "./popups/ResultNotificationPopup.js";

export default class Notifications
{
    constructor ()
    {

    }

    get ANIMATION_TYPE_SLIDE_IN () { return "slide-in"; }
    get ANIMATION_TYPE_SLIDE_OUT () { return "slide-out"; }
    get ANIMATION_TYPE_FADE_OUT () { return "fade-in"; }

    get SLIDE_IN_ANIMATION_TIME() { return Constants.getValue("NOTIFICATIONS_SLIDE_IN_TIME"); }
    get SLIDE_OUT_ANIMATION_TIME() { return Constants.getValue("NOTIFICATIONS_SLIDE_OUT_TIME"); }
    get FADE_OUT_ANIMATION_TIME() { return Constants.getValue("NOTIFICATIONS_FADE_OUT_TIME"); }
    get NOTIFICATION_LENGTH_IN_SECS() { return Constants.getValue("NOTIFICATIONS_DISPLAY_TIME"); }

    get UI() { return this.ui; }
    get PixiApp() { return this.UI.UIManager.PixiApp; }
    get IsNotificationShown() { return false; }

    get WindowSpacing() { return "(ih * 2.5%)"; }
    get NotificationDisplayTime() { return this.displayTime; }
    get MessageDisplayTime() { return this.messageDisplayTime; }
    get BackpackWindowWidth() { return "((iw - " + ResultNotificationPopup.WINDOW_WIDTH + " - " + this.WindowSpacing + ") / 2.5)"; }

    /*******************************************
    *   INITIALIZATION
    *******************************************/
    /**
        Parameters to pass to the init function:
        - ui:   UI section object where this component resides
    */
    init(meta)
    {
        this.ui = meta.ui;
        this.globalId = 0;
        this.notificationQueue = [];
        this.animationQueue = [];

        this.initSettings();

        this.createClosure();
        this.bindEvents();

        return this;
    }

    createClosure()
    {
        this.fctUpdate = this.update.bind(this);
        this.fctOnPlayerLocationChanged = this.onPlayerLocationChanged.bind(this);
        this.fctOnNotificationClick = this.onNotificationClick.bind(this);
        this.fctOnBackpackClick = this.onBackpackClick.bind(this);
        this.fctOnObjectiveClick = this.onObjectiveClick.bind(this);
    }

    bindEvents()
    {
        this.UI.GameManager.on(this.UI.GameManager.EVENT_UPDATE, this.fctUpdate);
        this.UI.WorldManager.on(this.UI.WorldManager.EVENT_PLAYER_LOCATION_CHANGED, this.fctOnPlayerLocationChanged);//@!
    }

    destroy(options)
    {
        this.UI.GameManager.off(this.UI.GameManager.EVENT_UPDATE, this.fctUpdate);
        this.UI.WorldManager.off(this.UI.WorldManager.EVENT_PLAYER_LOCATION_CHANGED, this.fctOnPlayerLocationChanged);

        for (let i = this.notificationQueue.length - 1; i >= 0; i--)
        {
            this.removeNotificationWindow(this.notificationQueue[i].window);
        }

        for (let i = this.animationQueue.length - 1; i >= 0; i--)
        {
            if (this.animationQueue[i].window)
            {
                this.removeNotificationWindow(this.animationQueue[i].window);
            }
        }

        if (this.backpackAnimation)
        {
            this.removeNotificationWindow(this.backpackAnimation.window);
        }

        if (this.objectiveAnimation)
        {
            this.removeNotificationWindow(this.objectiveAnimation.window);
        }
    }

    initSettings()
    {
        this.displayTime = this.UI.GameManager.getSetting("notifications").displayTime;
        this.messageDisplayTime = this.UI.GameManager.getSetting("messages").displayTime;
    }

    /*******************************************
    *   UPDATE LOOP
    *******************************************/
    update(fDelta)
    {
        this.updateNotificationQueue(fDelta);
        this.updateAnimationQueue(fDelta);
        this.updateBackpackNotification(fDelta);
        this.updateObjectiveNotification(fDelta);
    }

    /*******************************************
    *   NOTIFICATION SPAWN / DESPAWN
    *******************************************/
    createMessageNotification(strText, rWidth, bShowClose = false, bShowNewLayout = false, rHeight = "")
    {
        let notificationWindow = new MessageNotificationPopup().init({
            "ui": this.UI,
            "message": strText,
            "rWidth": rWidth,
            "rHeight": rHeight,
            "showClose": bShowClose,
            "newLayout": bShowNewLayout
        });

        this.PixiApp.stage.addChild(notificationWindow);

        this.globalId++;
        notificationWindow.name = "notitificationWindow" + this.globalId;

        this.UI.addMouseEating(notificationWindow);

        this.currentNotification = notificationWindow;
        return notificationWindow;
    }

    createResultNotification(strAtlas, strTexture, strText, bShowClose = false)
    {
        let notificationWindow = new ResultNotificationPopup().init({
            "ui": this.UI,
            "atlasName": strAtlas,
            "textureName": strTexture,
            "message": strText,
            "showClose": bShowClose
        });

        this.PixiApp.stage.addChild(notificationWindow);

        this.globalId++;
        notificationWindow.name = "notitificationWindow" + this.globalId;

        this.UI.addMouseEating(notificationWindow);

        return notificationWindow;
    }

    removeNotificationWindow(objWindow)
    {
        this.UI.removeMouseEating(objWindow);
        this.PixiApp.stage.removeChild(objWindow);

        objWindow.destroy({"children": true});
    }

    /*******************************************
    *   NOTIFICATIONS DISPLAY
    *******************************************/
    showItemGain(item, iQuantity, bShowQuantity = true)
    {
        if (typeof item === "string")
        {
            item = this.UI.ItemManager.getItem(item);
        }
        
        let atlas = item.AtlasId;
        let img = item.TextureId;
        let label = this.UI.LabelManager.translate("Grang_Cuisson_Mess");
        label = label.replace("[Nom de l'aliment cuit]", item.Name + (bShowQuantity ? " (" + iQuantity + ")" : ""));
        let animTime = this.SLIDE_IN_ANIMATION_TIME;

        this.animationQueue.push({
            "type": this.ANIMATION_TYPE_SLIDE_IN,
            "atlas": atlas,
            "img": img,
            "label": label,
            "totalTime": animTime,
            "timeLeft": animTime
        });
    }

    showZoneUpgrade(strZone, iFromLevel, iToLevel)
    {
        if (typeof item === "string")
        {
            item = this.UI.ItemManager.getItem(item);
        }
        
        let img = this.UI.WorldManager.Environment.getZoneTextureId(strZone, iToLevel);
        let atlas = "barn";
        let animTime = this.SLIDE_IN_ANIMATION_TIME;

        let zoneName = "";
        let fromLevel = "";
        let toLevel = "";
        if (strZone == this.UI.WorldManager.BARN_ZONE_CRAFTING)
        {
            zoneName = this.UI.LabelManager.translate("Fabr_Amelior_Titre");
            fromLevel = this.UI.LabelManager.translate("Fabr_Amelior_Niv" + iFromLevel);
            toLevel = this.UI.LabelManager.translate("Fabr_Amelior_Niv" + iToLevel);
        }
        else if (strZone == this.UI.WorldManager.BARN_ZONE_COOKING)
        {
            zoneName = this.UI.LabelManager.translate("Cuis_Amelior_Titre");
            fromLevel = this.UI.LabelManager.translate("Cuis_Amelior_Niv" + iFromLevel);
            toLevel = this.UI.LabelManager.translate("Cuis_Amelior_Niv" + iToLevel);
        }
        else if (strZone == this.UI.WorldManager.BARN_ZONE_RELAX)
        {
            zoneName = this.UI.LabelManager.translate("Dete_Amelior_Titre");
            fromLevel = this.UI.LabelManager.translate("Dete_Amelior_Niv" + iFromLevel);
            toLevel = this.UI.LabelManager.translate("Dete_Amelior_Niv" + iToLevel);
        }
        else if (strZone == this.UI.WorldManager.BARN_ZONE_SLEEP)
        {
            zoneName = this.UI.LabelManager.translate("Somm_Amelior_Titre");
            fromLevel = this.UI.LabelManager.translate("Somm_Amelior_Niv" + iFromLevel);
            toLevel = this.UI.LabelManager.translate("Somm_Amelior_Niv" + iToLevel);
        }

        let label = this.UI.LabelManager.translate("Grang_Amelior_Mess");
        label = label.replace("[Nom de la zone]", zoneName);
        label = label.replace("[0]", fromLevel.toLowerCase());
        label = label.replace("[1]", toLevel.toLowerCase());

        this.animationQueue.push({
            "type": this.ANIMATION_TYPE_SLIDE_IN,
            "atlas": atlas,
            "img": img,
            "label": label,
            "totalTime": animTime,
            "timeLeft": animTime
        });
    }

    showGeneralNotification(strAtlas, strImg, strLabel)
    {
        let animTime = this.SLIDE_IN_ANIMATION_TIME;

        this.animationQueue.push({
            "type": this.ANIMATION_TYPE_SLIDE_IN,
            "img": strImg,
            "atlas": strAtlas,
            "label": strLabel,
            "totalTime": animTime,
            "timeLeft": animTime
        });
    }

    showBackpackFull()
    {
        if (!this.backpackAnimation)
        {
            let label = this.UI.LabelManager.translate("Inv_Plein_Mess");
            let msgWindow = this.createMessageNotification(label, this.BackpackWindowWidth);
            let animTime = this.SLIDE_IN_ANIMATION_TIME;

            msgWindow.on(msgWindow.EVENT_CLICK, this.fctOnBackpackClick)//@

            let rX = "iw / 2 - " + this.BackpackWindowWidth + " / 2";

            let fromY = "(ih + " + this.WindowSpacing + " + " + msgWindow.BgHeight + ")";
            let toY = "(ih - " + this.WindowSpacing + ")";

            msgWindow.rX = [{on:"default", x: rX}];
            msgWindow.rY = [{on:"default", y: fromY}];

            msgWindow.position.set(
                this.UI.evaluate(rX, 0, 0, 0, 0, msgWindow), 
                this.UI.evaluate(fromY, 0, 0, 0, 0, msgWindow)
            );

            this.backpackAnimation = {
                "window": msgWindow,
                "timeLeft": animTime,
                "totalTime": animTime,
                "from": fromY,
                "to": toY,
                "y": fromY
            }
        }
    }

    showObjectiveNotification(strLabel, bNewLayout = false)
    {
        if (!this.objectiveAnimation)
        {
            let msgWindow = this.createMessageNotification(strLabel, this.BackpackWindowWidth, false, bNewLayout);


            let animTime = this.SLIDE_IN_ANIMATION_TIME;

            msgWindow.on(msgWindow.EVENT_CLICK, this.fctOnObjectiveClick);//@

            if (bNewLayout)
                this.UI.AudioManager.playBgm("objective_complete", false, false);

            let rX = "iw / 2 - " + this.BackpackWindowWidth + " / 2";

            let fromY = "(-" + this.WindowSpacing + " - " + msgWindow.BgHeight + ")";
            let toY = "(" + this.WindowSpacing + (bNewLayout ? " * 1.5 " : "") + " + " + msgWindow.BgHeight + ")";

            msgWindow.rX = [{on:"default", x: rX}];
            msgWindow.rY = [{on:"default", y: fromY}];


            let nFromY = this.UI.evaluate(fromY, 0, 0, 0, 0, msgWindow);
            let nToY = this.UI.evaluate(toY, 0, 0, 0, 0, msgWindow);

            msgWindow.position.set(
                this.UI.evaluate(rX, 0, 0, 0, 0, msgWindow), 
                this.UI.evaluate(fromY, 0, 0, 0, 0, msgWindow)
            );


            this.objectiveAnimation = {
                "window": msgWindow,
                "timeLeft": animTime ,
                "totalTime": animTime,
                "displayTime" : this.messageDisplayTime,
                "from": fromY,
                "to": toY,
                "y": fromY
            }
        }
    }

    clearNotifications()
    {
        for (let i = this.notificationQueue.length - 1; i >= 0; i--)
        {
            this.removeNotificationWindow(this.notificationQueue[i].window);
        }

        for (let i = this.animationQueue.length - 1; i >= 0; i--)
        {
            if (this.animationQueue[i].window)
            {
                this.removeNotificationWindow(this.animationQueue[i].window);
            }
        }

        if (this.backpackAnimation)
        {
            this.removeNotificationWindow(this.backpackAnimation.window);
        }

        if (this.objectiveAnimation)
        {
            this.removeNotificationWindow(this.objectiveAnimation.window);
        }

        this.notificationQueue = [];
        this.animationQueue = [];
        this.backpackAnimation = null;
        this.objectiveAnimation = null;
    }

    /*******************************************
    *   ANIMATIONS AND VISUALS
    *******************************************/
    updateNotificationQueue(fDelta)
    {
        for (let i = 0; i < this.notificationQueue.length; i++)
        {
            let notif = this.notificationQueue[i];
            notif.timeLeft -= fDelta;
        }

        if (this.notificationQueue.length > 0 && this.notificationQueue[0].timeLeft <= 0)
        {
            let animTime = this.FADE_OUT_ANIMATION_TIME;

            this.animationQueue.push({
                "type": this.ANIMATION_TYPE_FADE_OUT,
                "window": this.notificationQueue[0].window,
                "timeLeft": animTime,
                "totalTime": animTime
            });

            this.notificationQueue.shift();
        }
    }

    updateAnimationQueue(fDelta)
    {
        if (this.animationQueue.length > 0)
        {
            let anim = this.animationQueue[0];
            if (anim.type == this.ANIMATION_TYPE_SLIDE_IN)
            {
                this.updateSlideInAnimation(fDelta);
            }
            else if (anim.type == this.ANIMATION_TYPE_SLIDE_OUT)
            {
                this.updateSlideOutAnimation(fDelta);
            }
            else if (anim.type == this.ANIMATION_TYPE_FADE_OUT)
            {
                this.updateFadeOutAnimation(fDelta);
            }
        }
    }

    updateSlideInAnimation(fDelta)
    {
        let anim = this.animationQueue[0];

        if (!anim.window)
        {
            anim.window = this.createResultNotification(
                anim.atlas,
                anim.img,
                anim.label
            );

            anim.window.on(anim.window.EVENT_CLICK, this.fctOnNotificationClick);//@

            anim.from = "(ih)";
            anim.to = "(ih - " + anim.window.BgHeight + " - " + this.WindowSpacing + ")";

            for(let i = 0; i < this.notificationQueue.length; i++)
            {
                this.notificationQueue[i].from = "(" + this.notificationQueue[i].y + ")";
                this.notificationQueue[i].to = "(" + this.notificationQueue[i].y + " + (" +  anim.to + " - " + anim.from + "))";
            }

            let strX = this.WindowSpacing;

            anim.window.rX = [{on:"default", x: strX}]; //left side
            //anim.window.rX = [{on:"default", x: ("iw - " + anim.window.BgWidth + " - " + this.WindowSpacing)}]; //right side
            anim.window.rY = [{on:"default", y: anim.from}];

            let iX = this.UI.evaluate(strX, 0, 0, 0, anim.window);
            let iY = anim.window.position.y;
            anim.window.position.set(iX, iY);
        }

        anim.timeLeft -= fDelta;
        let t = 1 - anim.timeLeft / anim.totalTime;
        t = Math.max(0, Math.min(1, t));
        t = t * t * t * (t * (6 * t - 15) + 10);

        let rY = "(" + anim.to + " - " + anim.from + ") * " + t + " + " + anim.from;
        anim.window.rY = [{on:"default", y: rY}];

        let iX = anim.window.position.x;
        let iY = this.UI.evaluate(rY, 0, 0, 0, 0, anim.window);
        anim.window.position.set(iX,iY );

        for(let i = 0; i < this.notificationQueue.length; i++)
        {
            let notif = this.notificationQueue[i];
            let notifrY = "((1 - " + t + ") * " + notif.from + " + " + t + " * " + notif.to + ")";

            notif.window.rY = [{on:"default", y: notifrY}];
            notif.y = notifrY;
            notif.window.position.set(notif.window.position.x, this.UI.evaluate(notifrY, 0, 0, 0, 0, anim.window));
        }

        if (anim.timeLeft <= 0)
        {
            this.notificationQueue.push({
                "window": anim.window,
                "timeLeft": anim.displayTime ? anim.displayTime : this.NotificationDisplayTime,
                "y": rY
            });

            this.animationQueue.shift();
        }
    }

    updateSlideOutAnimation(fDelta)
    {
        let anim = this.animationQueue[0];

        anim.timeLeft -= fDelta;
        let t = anim.timeLeft / anim.totalTime;
        t = Math.max(0, Math.min(1, t));
        t = t * t * t * (t * (6 * t - 15) + 10);

        if (anim.hasOut)
        {
            let height = "(" + anim.window.BgHeight + " + " + this.WindowSpacing + ")";
            for (let i = anim.index - 1; i >= 0; i--)
            {
                let notif = this.notificationQueue[i];

                if (!notif)
                {
                    continue;
                }

                let from = "(" + notif.startSlideOut + ")";
                let to = "(" + notif.startSlideOut + " + " + height + ")";

                let notifrY = "((1 - " + t + ") * " + to + " + " + t + " * " + from + ")";

                notif.window.rY = [{on:"default", y: notifrY}];
                notif.y = notifrY;
                notif.window.position.set(
                    notif.window.position.x,
                    this.UI.evaluate(notifrY, 0, 0, 0, 0, anim.window)
                );
            }

            if (anim.timeLeft <= 0)
            {
                this.removeNotificationWindow(anim.window);
                anim.window.off(anim.window.EVENT_CLICK, this.fctOnNotificationClick);
                anim.window.off(anim.window.EVENT_CLICK, this.fctOnObjectiveClick);
                this.animationQueue.shift();
            }
        }
        else
        {
            if (!anim.from)
            {
                anim.from = `(${this.WindowSpacing})`;
                //anim.from = "(iw - " + anim.window.BgWidth + " - " + this.WindowSpacing + ")";
                //anim.to = "(iw + " + this.WindowSpacing + ")";
                anim.to = `(-${anim.window.width })`;
            }

            let rX = "((1 - " + t + ") * " + anim.to + " + " + t + " * " + anim.from + ")";

            anim.window.rX = [{on:"default", x: rX}];
            anim.x = rX;

            anim.window.position.set(this.UI.evaluate(rX, 0, 0, 0, 0, anim.window), anim.window.position.y);

            if (anim.timeLeft <= 0)
            {

                for(let i = this.notificationQueue.length -1; i >= 0; i--)
                {
                    this.notificationQueue[i].startSlideOut = this.notificationQueue[i].y;

                    if (this.notificationQueue[i].window == anim.window)
                    {
                        this.notificationQueue.splice(i, 1);
                    }
                }
                anim.timeLeft = this.SLIDE_OUT_ANIMATION_TIME;
                anim.totalTime = anim.timeLeft;

                if (this.notificationQueue.length > 0)
                {
                    anim.hasOut = true;
                }
                else
                {
                    this.removeNotificationWindow(anim.window);
                    anim.window.off(anim.window.EVENT_CLICK, this.fctOnNotificationClick);
                    anim.window.off(anim.window.EVENT_CLICK, this.fctOnObjectiveClick);

                    this.animationQueue.shift();
                }
            }
        }
    }

    updateFadeOutAnimation(fDelta)
    {
        let anim = this.animationQueue[0];

        anim.timeLeft -= fDelta;
        let t = anim.timeLeft / anim.totalTime;
        t = t * t * t * (t * (6 * t - 15) + 10);

        anim.window.Alpha = t;

        if (anim.timeLeft <= 0)
        {
            this.removeNotificationWindow(anim.window);
            anim.window.off(anim.window.EVENT_CLICK, this.fctOnNotificationClick);

            this.animationQueue.shift();
        }
    }

    updateBackpackNotification(fDelta)
    {
        if (this.backpackAnimation)
        {
            this.updateMessageNotification(fDelta, this.backpackAnimation, () => { this.backpackAnimation = null; });
        }
    }

    updateObjectiveNotification(fDelta)
    {
        if (this.objectiveAnimation)
        {
            this.updateMessageNotification(fDelta, this.objectiveAnimation, () => { this.objectiveAnimation = null; });
        }
    }

    updateMessageNotification(fDelta, objAnimation, fctCallback)
    {
        let anim = objAnimation;

        anim.timeLeft -= fDelta;

        let t = anim.timeLeft / anim.totalTime;
        t = Math.max(0, Math.min(1, t));
        t = t * t * t * (t * (6 * t - 15) + 10);

        if (anim.fadeOut)
        {
            anim.window.Alpha = t;

            if (anim.timeLeft <= 0)
            {
                this.removeNotificationWindow(anim.window);
                anim.window.off(anim.window.EVENT_CLICK, this.fctOnBackpackClick);
                anim.window.off(anim.window.EVENT_CLICK, this.fctOnObjectiveClick);

                fctCallback();
            }
        }
        else if (!anim.wait)
        {
            let rY = "(1-" + t + ") * " + anim.to + " + " + t + " * " + anim.from;

            anim.window.rY = [{on:"default", y: rY}];
            anim.window.position.set(anim.window.position.x, this.UI.evaluate(rY, 0, 0, 0, 0, anim.window));
            anim.y = rY;

            if (anim.timeLeft <= 0)
            {
                let time = anim.displayTime ? anim.displayTime : this.NotificationDisplayTime;
                anim.wait = true;
                anim.timeLeft = time;
                anim.totalTime = time;
            }
        }
        else if (anim.timeLeft <= 0)
        {
            let time = this.FADE_OUT_ANIMATION_TIME;
            anim.fadeOut = true;
            anim.timeLeft = time;
            anim.totalTime = time;
        }
    }

    /*******************************************
    *   EVENTS
    *******************************************/
    onPlayerLocationChanged(strNewLocation)
    {
        this.clearNotifications();
    }

    onNotificationClick(sender)
    {
        let bIsOk = true;

        for (let i = 0; i < this.animationQueue.length; i++)
        {
            if (this.animationQueue[i].window == sender && 
                    (this.animationQueue[i].type == this.ANIMATION_TYPE_SLIDE_OUT || this.animationQueue[i].type == this.FADE_OUT_ANIMATION_TIME))
            {
                bIsOk = false;
                break;
            }
        }

        if (bIsOk)
        {
            let index = -1;

            for (let i = 0; i < this.notificationQueue.length; i++)
            {
                if (this.notificationQueue[i].window == sender)
                {
                    index = i;
                    break;
                }
            }

            if (index >= 0)
            {
                let animTime = this.SLIDE_OUT_ANIMATION_TIME;

                let anim = {
                    "type": this.ANIMATION_TYPE_SLIDE_OUT,
                    "window": sender,
                    "timeLeft": animTime,
                    "totalTime": animTime,
                    "index": index
                };

                if (this.animationQueue.length > 1)
                {
                    this.animationQueue.splice(1, 0, anim);
                }
                else
                {
                    this.animationQueue.push(anim);
                }
            }

        }
    }

    onBackpackClick(sender)
    {
        if (this.backpackAnimation && this.backpackAnimation.wait)
        {
            let time = this.FADE_OUT_ANIMATION_TIME;
            this.backpackAnimation.fadeOut = true;
            this.backpackAnimation.timeLeft = time;
            this.backpackAnimation.totalTime = time;
        }
    }

    onObjectiveClick(sender)
    {
        if (this.objectiveAnimation && this.objectiveAnimation.wait)
        {
            let time = this.FADE_OUT_ANIMATION_TIME;
            this.objectiveAnimation.fadeOut = true;
            this.objectiveAnimation.timeLeft = time;
            this.objectiveAnimation.totalTime = time;
        }
    }

    onInGameDisplayed()
    {
        for (let i = this.notificationQueue.length - 1; i >= 0; i--)
        {
            this.PixiApp.stage.removeChild(this.notificationQueue[i].window);
            this.PixiApp.stage.addChild(this.notificationQueue[i].window);
        }

        for (let i = this.animationQueue.length - 1; i >= 0; i--)
        {
            if (this.animationQueue[i].window)
            {
                this.PixiApp.stage.removeChild(this.animationQueue[i].window);
                this.PixiApp.stage.addChild(this.animationQueue[i].window);
            }
        }

        if (this.backpackAnimation)
        {
            this.PixiApp.stage.removeChild(this.backpackAnimation.window);
            this.PixiApp.stage.addChild(this.backpackAnimation.window);
        }

        if (this.objectiveAnimation)
        {
            this.PixiApp.stage.removeChild(this.objectiveAnimation.window);
            this.PixiApp.stage.addChild(this.objectiveAnimation.window);
        }
    }
}