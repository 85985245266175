import {gsap} from "gsap";
import { string2hex }  from '@pixi/utils'; 
import ContainerResponsive from "../responsive/ContainerResponsive.js";
import GraphicsResponsive from "../responsive/GraphicsResponsive.js";
import InventoryQuantity from "./InventoryQuantity.js";
import InventorySlotTitle from "./InventorySlotTitle.js";
import Lerp from "../../../utils/Lerp.js";
import Library from "../../../Library.js";
import SpriteResponsive from "../responsive/SpriteResponsive.js";
import DoubleTapDetector from "../DoubleTapDetector.js";


export default class InventorySlot extends ContainerResponsive
{
    constructor()
    {
        super();
    }

    get TYPE_ALL() { return "ALL" };
    get TYPE_EQUIPMENT() { return "EQ" };
    get TYPE_TOOL() { return "OU" };
    get TYPE_MATERIAL() { return "MA" };
    get TYPE_FOOD() { return "NO" };
    get TYPE_KEY() { return "KE" };
    get TYPE_STEP() { return "STP" };

    get Item() { return this.item; }
    get LocationType() { return this.locationType; }

    get Enabled() { return this.enabled; }
    set Enabled(enabled)
    {
        this.setEnabledItem(enabled);
    }

    get BgWidth() { return this.strWidth; }
    get BgHeight() { return this.strHeight; }
    get BgX() { return 0; }
    get BgY() { return 0; }

    get BackgroundColor() { return this.backgroundColor;}
    get StrokeWidth() { return this.strokeWidth;}
    get CornerRadius() { return this.evaluate(this.valueFormula(this.UI.Values.modal.corner.radius), 0, 0, 0, 0);}
    get StrokeColor()
    { 
        if (this.isHighlighted)
        {
            return 0xc6ff57;
        }
        else
        {
            return string2hex("color" in this.UI.Values.modal.stroke ? this.UI.Values.modal.stroke.color : "#000000");
        }
    }

    /*******************************************
    *   INITIALIZATION
    *******************************************/
    /**
        Parameters to pass to the init function:
        - ui:           UI section object where this slot resides
        - rWidth:       Reponsive width equation for this slot
        - rHeight       Reponsive height equation for this slot
        - type:         Type identifying this slot
        - locationType: Type of the agglomeration where this tile lives with other tiles (usally the TYPE_* constants from InventoryGrid)
        - index:        Numeric index representing where this slot is positionned in a grid of slots
        - strokeWidth:  (Optional) Size of the stroke for this slot. Default is 3
    */
    init(meta)
    {
        this.graphics = {};

        this.enabled = true;
        this.type = meta.type;
        this.locationType = meta.locationType;
        this.index = meta.index;
        this.isDrag = false;
        this.rollOverMode = false;
        this.isHighlighted = false;
        this.arrCurrentDestinationSlot = [];
        this.backgroundColor = 0x332c42;

        this.strWidth = meta.rWidth;
        this.strHeight = meta.rHeight;

        this.strokeWidth = ("strokeWidth" in meta ? meta.strokeWidth : 3);

        return super.init(meta);
    }

    createClosure()
    {
        super.createClosure();

        this.fctClone = this.clone.bind(this);
        this.fctHighlight = this.highlight.bind(this);
        this.fctUnhighlight = this.unhighlight.bind(this);

    }

    destroy(options)
    {
        if (this.spQuantity)
        {
            this.removeChild(this.spQuantity);
            this.spQuantity.destroy(options);
        }
        delete this.spQuantity;

        if (this.spTitle)
        {
            this.removeChild(this.spTitle);
            this.spTitle.destroy(options);
        }
        delete this.spTitle;

        if (this.parent != undefined)
        {
            this.parent.removeChild(this);
        }
        this.linkSlot = null;

        super.destroy(options);
    }

    /*******************************************
    *   BUILD
    *******************************************/
    build ()
    {
        super.build();

        this.buildBackground();
        this.buildTitle();
        this.buildQuantity();
    }

    clean()
    {
        super.clean();
        if (this.background)
        {
            this.background.clear();
        }
    }

    buildBackground()
    {
        if (!this.background)
        {
            this.background = new GraphicsResponsive().init({"ui":this.UI});
            this.background.interactive = true;

            this.addChild(this.background);
        }

        let graphics = this.background;

        let width = this.evaluate(this.BgWidth, 0, 0, 0, 0, this);
        let height = this.evaluate(this.BgHeight, 0, 0, 0, 0, this);
        let x = this.BgX;
        let y = this.BgY;

        let bgColor = this.BackgroundColor;
        let strokeWidth = this.StrokeWidth;
        let strokeColor = this.StrokeColor;
        let cornerRadius = this.CornerRadius;

        if (strokeWidth > 0)
        {
            graphics.lineStyle({"color":strokeColor, "width":strokeWidth, "alignment":1});
        }

        graphics.beginFill(bgColor, 1);
        if (cornerRadius > 0)
        {
            graphics.drawRoundedRect(x, y, width, height, cornerRadius);
        }
        else
        {
            graphics.drawRect(x, y, width, height);
        }
        graphics.endFill();
    }

    buildTitle()
    {
        if (!this.spTitle)
        {
            this.spTitle = new InventorySlotTitle().init({"ui": this.UI});
            if (this.spTitle.parent)
            {
                this.spTitle.parent.removeChild(this.spTitle);
            }
        }
        this.spTitle.rX = [{on:"default", x: `(${this.strWidth} / 2) - (width / 2)`}];
        this.spTitle.rY = [{on:"default", y: `-height - 25`}];
    }

    buildQuantity()
    {
        if (!this.spQuantity)
        {
            this.spQuantity = new InventoryQuantity().init({"ui": this.UI, "quantity": 0});
            if (this.spQuantity.parent)
            {
                this.spQuantity.parent.removeChild(this.spQuantity);
            }
        }
        this.spQuantity.setMask(this.mask);
        this.spQuantity.rX = [{on:"default", x: `${this.strWidth} - width`}];
        this.spQuantity.rY = [{on:"default", y: `${this.strHeight} - height`}];
    }

    /*******************************************
    *   TITLE
    *******************************************/
    showTitle (strTitle)
    {
        this.onlyIconWidth =  this.width;
        this.onlyIconHeight =  this.height;
        this.spTitle.setTitle(strTitle);
        this.addChild(this.spTitle);
    }

    removeTitle ()
    {
        this.removeChild(this.spTitle);
    }

    /*******************************************
    *   ITEM MANAGEMENT
    *******************************************/
    addItem (item, duration = 0 )
    {
        if (!item || !item.item)
            return;

        if (this.spItem)
        {
            this.removeChild(this.spItem);
            this.spItem.destroy({"children": true});
            delete this.spItem;
        }

        this.item = item;

        let color = string2hex(item.item.BGColor);

        this.goalColor = color;
        this.colorTint = 0;

        gsap.to(this, {colorTint: 1, duration, ease:"power1.in", onComplete: () => { this.currentColor = this.goalColor}});

        let texture = Library.get2DTextureFromAtlas3D(item.item.AtlasId, item.item.TextureId);

        let orig = texture.orig;
        let ratio = orig.width / orig.height;

        this.spItem = new SpriteResponsive(texture).init({"ui": this.UI});

        this.spItem.detector = new DoubleTapDetector().init({
            "ui": this.UI, 
            "target": this.spItem, 
            "callback": function(objItem)
            {
                this.UI.showItemDescription(objItem);
            }
            .bind(this, item.item)
        });

        this.spItem.interactive = true;
        this.spItem.buttonMode = true;
        
        this.spItem.detector.startDetection();


        let spWidth = "";
        let spHeight = "";
        if (ratio >= 1) //width is greater
        {
            ratio = orig.height / orig.width;
            spWidth = "(" + this.strWidth + " * 90%)";
            spHeight = "(" + spWidth + " * " + ratio + ")";

            this.spItem.rWidth = [{on: "default", width: spWidth}];
            this.spItem.rHeight = [{on: "default", height: spHeight}];
        }
        else //height is greater
        {
            spHeight = "(" + this.strHeight + " * 90%)";
            spWidth = "(" + spHeight + " * " + ratio + ")";

            this.spItem.rWidth = [{on: "default", width: spWidth}];
            this.spItem.rHeight = [{on: "default", height: spHeight}];
        }

        //this.spItem.anchor.x = this.spItem.anchor.y = 0.5;
        this.spItem.rX = [{on: "default", x: this.BgWidth + " / 2 - " + spWidth + " / 2"}];
        this.spItem.rY = [{on: "default", y: this.BgHeight + " / 2 - " + spHeight + " / 2"}];


        this.spItem.alpha = 0;
        gsap.to(this.spItem, {alpha:1, duration, ease:"power1.in"});

        this.addChild(this.spItem);


        this.spQuantity.Quantity = this.item.quantity;

        if (this.item.quantity > 1)
        {
            this.addChild(this.spQuantity);
        }

        this.makeDraggable();
    }

    gotItem ()
    {
        return this.item !== null && this.item !== undefined;
    }

    itemIs (type)
    {
        if (this.item === undefined)
        {
            return false;
        }

        return this.item.item.type === type;
    }

    updateItem (item)
    {
        if (!(this.item === undefined || this.item === null))
        {
            this.removeItem(0);
        }

        this.addItem(item);
    }

    removeItem (duration = 0.1)
    {
        this.goalColor = 0x332c42;
        this.colorTint = 0;
        gsap.to(this, {colorTint: 1, duration, ease:"power1.in", onComplete: () => { this.currentColor = this.goalColor}});

        if (this.spItem && this.spItem.detector)
        {
            this.spItem.detector.destroy({"children": true});
        }
        
        if (this.spItem !== undefined && this.spItem.parent !== undefined)
        {
            this.spItem.parent.removeChild(this.spItem);
            this.spItem.destroy({"children": true});
            this.spItem = undefined;
        }

        if (this.spQuantity !== undefined && this.spQuantity.parent !== undefined && this.spQuantity.parent !== null)
        {
            this.spQuantity.parent.removeChild(this.spQuantity);
        }

        this.makeUndraggable();

        let item = this.item;
        this.item = undefined;

        return item;
    }

    setEnabledItem (enabled)
    {
        if (this.item === undefined)
            return;

        this.enabled = enabled

        this.spItem.tint = this.enabled ? 0xFFFFFF : 0x333333;
        this.alpha = this.enabled ? 1: 0.5;
    }


    /*******************************************
    *   DRAG
    *******************************************/
    makeDraggable ()
    {
        this.interactive = true;
        this.buttonMode = true;

    }

    makeUndraggable ()
    {
        this.interactive = false;
        this.buttonMode = false;

    }

    highlight  ()
    {
        if (!this.isHighlighted)
        {
            this.isHighlighted = true;
            this.build();
        }
    }

    unhighlight ()
    {
        if (this.isHighlighted)
        {
            this.isHighlighted = false;
            this.build();
        }

    }

    /*******************************************
    *   UTILITIES
    *******************************************/
    clone ()
    {
        return new InventorySlot().init({
            "ui": this.UI,
            "rWidth": this.strWidth,
            "rHeight": this.strHeight,
            "accept": this.acceptedType,
            "type": this.type,
            "index": this.index
        });
    }

    sendBackToOrigin ()
    {
        let ptGlobal = this.linkSlot.toGlobal({x:0, y:0});
        ptGlobal.x += this.UI.ResponsiveManager.ScreenPadding;

        gsap.to(this, {x:ptGlobal.x, y:ptGlobal.y, duration: 0.2, onComplete: () => this.onBackToOrigin()});

    }

    moveTo (slotIndex, type)
    {
        let inventory = type === "closet" ? UIs.instance.closet : UIs.instance.backpack;
        let spSlot = inventory.inventory.getSlotAt(slotIndex);


        let ptGlobal = spSlot.toGlobal({x:0, y:0});
        ptGlobal.x += Responsive.screenPadding;

        gsap.to(this, {x:ptGlobal.x, y:ptGlobal.y, duration: 0.2, onComplete: () => this.onBackToOrigin()});
    }

    set colorTint (value)
    {
        this.backgroundColor = Lerp.lerpColorHex(this.currentColor, this.goalColor, value);
    }

    /*******************************************
    *   EVENTS
    *******************************************/
    onBackToOrigin()
    {
        this.destroy({"children": true});
        //UIs.instance.updateInventory();
    }
}