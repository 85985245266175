import AbstractSaveAdapter from "./AbstractSaveAdapter.js";
import SaveManager from "../SaveManager.js";

export default class LocalStorageSaveAdapter extends AbstractSaveAdapter
{
    constructor()
    {
        super();

        this.localStorageKey = SaveManager.instance.localKey;
    }

    save ()
    {
        //let contentValue = this.values[this.localStorageKey];
        let contentValue = this.values;

        //let encryptedData = this.encryptSaveData(JSON.stringify(contentValue));
        let encryptedData = this.encryptSaveData(contentValue);
        localStorage.setItem(this.localStorageKey, encryptedData);

        return encryptedData;
    }

    load (fctCallback)
    {
        let encryptedData = localStorage.getItem(this.localStorageKey);
        let values = {};
        if (encryptedData)
        {
            let decryptedData = this.decryptSaveData(encryptedData);

            try
            {
                values = JSON.parse(decryptedData);
            }

            catch (e)
            {
                values = decryptedData;
            }


        }

        this.values = values;

        if (fctCallback)
        {
            fctCallback();
        }
    }

    clear ()
    {
        localStorage.clear();
    }

    encryptSaveData (strSaveData)
    {
        let json;

        try
        {
            json = JSON.parse(strSaveData);
        }

        catch (e)
        {
            json = strSaveData;
        }

        strSaveData = JSON.stringify(json);

        //@TODO: Implement the logic to encrypt the save data before sending it to local storage
        return strSaveData;
    }

    decryptSaveData (strEncryptedData)
    {
        //@TODO: Implement the logic to decrypt the save data after retrieving it from the local storage
        return strEncryptedData;
    }
}