import BasePopup from "../commons/BasePopup.js";
import Display from "../../utils/Display.js";
import Library from "../../Library.js";
import Message from "./Message.js";
import SpriteResponsive from "../commons/responsive/SpriteResponsive.js";
import Timer from "./Timer.js";

export default class BaseMinigame extends BasePopup
{
    constructor(fctCallback)
    {
        super();

        this.fctOnCloseCallback = fctCallback;
    }

    get EVENT_SUCCESS() { return "success"; }
    get EVENT_FAILURE() { return "failure"; }

    static get TYPE_GADGET() { return "gadget"; }
    static get TYPE_HACKING() { return "hacking"; }
    static get TYPE_UNLOCK() { return "unlock"; }

    get Id() { return "";}
    get Difficulty() { return this.difficulty; }
    get Balancing() { return this.balancing; }
    get TotalTime() { return this.balancing.totalTime;}

    get AtlasId() { return "minigame-" + this.Id; }
    get BoardTextureId() { return this.Id + "_board.png"; }

    get BgHeight() { return "(ih * 90%)"; }
    get BgWidth()
    { 
        if (!this.boardRatio)
        {
            this.boardRatio = 1;
            let texture = Library.getTextureFromAtlas(this.AtlasId, this.BoardTextureId);
            if (texture)
            {
                this.boardRatio = texture.orig.width / texture.orig.height;
            }

        }
        return "(" + this.BgHeight + " * " + this.boardRatio + ")"; 
    }
    get BgX() { return "(iw / 2 - " + this.BgWidth + " / 2)"; }
    get BgY() { return "(ih / 2 - " + this.BgHeight + " / 2)"; }

    get TimerWidth() { return "(ih * 0.3)"; }
    get TimerHeight() { return "(ih * 0.03)"; }
    get TimerX() { return "(iw / 2 - " + this.TimerWidth + " / 2)"; }
    get TimerY() { return "(" + this.BgY + " + " + this.TimerHeight + " / 2)"; }

    get CloseButtonScale() { return 0.75; }

    /*******************************************
    *   INITIALIZATION
    *******************************************/
    /**
        Parameters to pass to the init function:
        - ui:           UI section object where this component resides
        - difficulty:   Difficulty to set the game to. The value should fit the difficulty levels set in the settings
        - showOnStart   (Optional) If the popup should be shown on start. Default is TRUE
        - createOverlay (Optional) If this popup should create it's own overlay for itself. If not, it will use the general UI overlay. Default is FALSE
        - linked        (Optional) Linked popup to this one. If this one closes, the linked popup will close too. Default is NULL
    */
    init(meta)
    {
        meta.title = "";
        meta.clickToHide = false;
        meta.showClose = true;
        meta.sideBySide = false;

        this.success = false;
        this.difficulty = Math.min(99, Math.max(1, meta.difficulty));

        let infos = meta.ui.UIManager.MinigameValues[this.Id].balancing;
        for (let i = 0; i < infos.length; i++)
        {
            if (infos[i].difficulty == this.difficulty)
            {
                this.balancing = infos[i];
                break;
            }
        }

        return super.init(meta);
    }

    createClosure()
    {
        super.createClosure();

        this.fctUpdate = this.update.bind(this);
        this.fctOnTimerEnd = this.onTimerEnd.bind(this);
        this.fctOnMessageButtonClick = this.onMessageButtonClick.bind(this);
    }

    bindEvents()
    {
        super.bindEvents();
        this.UI.GameManager.on(this.UI.GameManager.EVENT_UPDATE, this.fctUpdate);
    }

    destroy(options)
    {
        this.UI.GameManager.off(this.UI.GameManager.EVENT_UPDATE, this.fctUpdate);
        if (this.timer)
        {
            this.removeChild(this.timer);
            this.timer.destroy(options);
        }
        delete this.timer;

        if (this.message)
        {
            this.removeChild(this.message);
            this.message.destroy(options);
        }
        delete this.message;

        super.destroy(options);
    }

    /*******************************************
    *   BUILD
    *******************************************/
    build()
    {
        super.build();
        this.buildTimer();
    }

    buildBackground ()
    {
        if (!this.sprites.background)
        {
            let texture = Library.getTextureFromAtlas(this.AtlasId, this.BoardTextureId);
            this.sprites.background = new SpriteResponsive(texture).init({"ui": this.UI});

            this.addChild(this.sprites.background);
        }

        let width = this.evaluate(this.BgWidth, 0, 0, 0, 0);
        let height = this.evaluate(this.BgHeight, 0, 0, 0, 0);
        let x = this.evaluate(this.BgX, 0, 0, 0, 0);
        let y = this.evaluate(this.BgY, 0, 0, 0, 0);

        let background = this.sprites.background;

        background.width = width;
        background.height = height;
        background.x = x;
        background.y = y;
    }

    buildTitle()
    {
        
    }

    buildTimer()
    {
        if (!this.timer)
        {
            this.timer = new Timer().init({
                "ui": this.UI,
                "rWidth": this.TimerWidth,
                "rHeight": this.TimerHeight,
                "rCorner": "(ih * 0.005)"
            });
            this.timer.on(this.timer.EVENT_TIMER_END, this.fctOnTimerEnd);

            this.addChild(this.timer);
        }

        this.timer.rX = [{on:"default", x: this.TimerX}];
        this.timer.rY = [{on:"default", y: this.TimerY}];
        this.timer.layout(Display.getSize());
    }

    /*******************************************
    *   ACTIONS
    *******************************************/
    showMessage(strMessageLabel, strButtonLabel)
    {
        if (!this.message)
        {
            this.message = new Message().init({
                "ui": this.UI,
                "message": strMessageLabel,
                "buttonLabel": strButtonLabel
            });
            this.message.once(this.message.EVENT_BUTTON_CLICK, this.fctOnMessageButtonClick);

            this.addChild(this.message);

            return true;
        }
        return false;
    }

    /*******************************************
    *   UPDATE LOOP
    *******************************************/
    update(fDeltaTime)
    {
        this.updateTimer(fDeltaTime);
    }

    updateTimer(fDeltaTime)
    {
        if (this.timer)
        {
            this.timer.update(fDeltaTime);
        }
    }

    /*******************************************
    *   EVENTS
    *******************************************/
    onCloseClick(sender)
    {
        super.onCloseClick(sender);
        if (this.success)
        {
            this.emit(this.EVENT_SUCCESS, this);
        }
        else
        {
            this.emit(this.EVENT_FAILURE, this);
        }
    }

    closeCallback (success)
    {
        console.log("close callback for minigame", this.success);
        if (this.fctOnCloseCallback)
        {
            setTimeout( () => this.fctOnCloseCallback(success), 10);
        }
    }

    onTimerEnd(sender)
    {

    }

    onMessageButtonClick()
    {
        this.removeChild(this.message);
        this.message.destroy({"children": true});
        delete this.message;
    }
}