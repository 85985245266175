import _ from "lodash";
import { Texture } from '@pixi/core';
import BaseUI from "./BaseUI.js";
import Display from "../utils/Display.js";
import GraphicsResponsive from "./commons/responsive/GraphicsResponsive.js";
import Library from "../Library.js";
import SpriteResponsive from "./commons/responsive/SpriteResponsive.js";

export default class MenuUI extends BaseUI
{
    constructor()
    {
        super();
    }

    get BACKGROUND_URL() { return "/_/splash_bg.jpg"; }
    get BACKGROUND_TEXTURE_ID() { return "splash_bg"; }
    get LOGO_URL() { return "/_/assets/1x/images/logo_mutants.png"; }

    get Id() { return "menu"; }
    get ShownPopups() { return this.arrShownPopups; }

    get LogoX() { return "iw * 50% ";}
    get LogoY() { return "ih * 2.5%";}
    get LogoWidth() { return "ih * (16/9) * 35%"; }
    get LogoHeight() { return "ih * 35%"; }

    /*******************************************
    *   INITIALIZATION
    *******************************************/
    /**
        Parameters to pass to the init function:
        - dependencies:     Dependency injector object
    */
    init(meta)
    {
        this.popups = {};
        this.arrShownPopups = [];

        return super.init(meta);
    }

    createClosure()
    {
        super.createClosure();

        this.fctOnOverlayClick = this.onOverlayClick.bind(this);
        this.fctOnPopupShow = this.onPopupShow.bind(this);
        this.fctOnPopupHide = this.onPopupHide.bind(this);
    }

    destroy(options)
    {
        for (let key in this.popups)
        {
            if (this.popups[key].parent)
            {
                this.popups[key].parent.removeChild(this.popups[key]);
            }
            this.popups[key].destroy(options);
        }
        delete this.popups;

        if (this.background)
        {
            this.removeChild(this.background);
            this.background.destroy(options);
            delete this.background;
        }

        if (this.backgroundBehind)
        {
            this.removeChild(this.backgroundBehind);
            this.backgroundBehind.destroy(options);
            delete this.backgroundBehind;
        }

        if (this.logo)
        {
            this.removeChild(this.logo);
            this.logo.destroy(options);
            delete this.logo;
        }

        if (this.overlay)
        {
            this.removeChild(this.overlay);
            this.overlay.destroy(options);
            delete this.overlay;
        }

        super.destroy(options);
    }

    /*******************************************
    *   BUILD
    *******************************************/
    build()
    {
        super.build();

        this.buildBehindBackground();
        this.buildBackground();
        this.buildLogo();
    }

    clean()
    {
        if (this.backgroundBehind)
        {
            this.backgroundBehind.clear();
        }
        super.clean();
    }

    buildBehindBackground()
    {
        if (!this.backgroundBehind)
        {
            this.backgroundBehind = new GraphicsResponsive().init({"ui": this});
            this.addChild(this.backgroundBehind);
        }

        let graphics = this.backgroundBehind;

        graphics.beginFill();
        graphics.drawRect(0, 0, this.ResponsiveManager.Width, this.ResponsiveManager.Height);
        graphics.endFill();
    }

    buildBackground()
    {
        let newBuild = false;
        if (!this.background)
        {   
            let texture = null;

            if (Library.instance && Library.instance.resources && Library.instance.resources[this.BACKGROUND_TEXTURE_ID])
            {
                texture = Library.getTextureFromResources(this.BACKGROUND_TEXTURE_ID);
            }
            else
            {
                texture = Texture.from(this.BACKGROUND_URL);
            }

            this.background = new SpriteResponsive(texture).init({"ui": this});
            this.background.alpha = 0.3;
            newBuild = true;
        }

        this.fctPositionBackground = function(bNewBuild)
        {
            if (this.background && this.background.width > 1)
            {
                if (bNewBuild)
                {
                    this.addChild(this.background);
                }

                let sprite = this.background;

                let screenWidth = this.ResponsiveManager.Width;
                let screenHeight = this.ResponsiveManager.Height;

                let width = screenWidth;
                let height = screenHeight;

                if (sprite.width / sprite.height > screenWidth / screenHeight)
                {
                    width = height * (sprite.width / sprite.height);
                }
                else
                {
                    height = width * (sprite.height / sprite.width);
                }

                sprite.width = width;
                sprite.height = height;

                sprite.position.set(
                    -(width - screenWidth) / 2,
                    -(height - screenHeight) / 2
                );

                delete this.fctPositionBackground;
            }
            else
            {
                window.requestAnimationFrame(this.fctPositionBackground);
            }
        }
        .bind(this, newBuild);

        this.fctPositionBackground();
    }

    buildLogo()
    {
        let screenWidth = this.ResponsiveManager.Width;
        let screenHeight = this.ResponsiveManager.Height;

        if (!this.logo)
        {
            let texture = null;

            if (Library.instance && Library.instance.resources && Library.instance.resources["logo_mutants"])
            {
                texture = Library.getTextureFromResources("logo_mutants");
            }
            else
            {
                texture = Texture.from(this.LOGO_URL);
            }

            this.logo = new SpriteResponsive(texture).init({"ui": this});
            this.addChild(this.logo);
        }

        this.logo.rX =      [{on: "default", x:this.LogoX}];
        this.logo.rY =      [{on: "default", y:this.LogoY}];
        this.logo.rWidth =  [{on: "default", width: this.LogoWidth}];
        this.logo.rHeight = [{on: "default", height: this.LogoHeight}];

        this.logo.anchor.x = 0.5;
        this.logo.anchor.y = 0;

        this.logo.layout(Display.getSize());
    }

    buildOverlay()
    {
        this.overlay = new GraphicsResponsive().init({"ui": this});
        this.overlay.beginFill(0, this.Values.modal.shadow.opacity);
        this.overlay.drawRect(0,0, 100, 100);
        this.overlay.endFill();

        this.overlay.interactive = true;
        this.overlay.on("click", this.fctOnOverlayClick);

        this.overlay.rX =      [{on: "default", x:"0"}];
        this.overlay.rY =      [{on: "default", y:"0"}];
        this.overlay.rWidth =  [{on: "default", width: "iw"}];
        this.overlay.rHeight = [{on: "default", height: "ih"}];

        this.overlay.interactive = true;
        this.overlay.buttonMode = false;

        this.removeChild(this.overlay);
    }

    /*******************************************
    *   POPUPS
    *******************************************/
    updatePopupPositions()
    {
        let objSize = Display.getSize();
        for (let i = 0; i < this.arrShownPopups.length; i++)
        {
            if (this.arrShownPopups[i].IsShown)
            {
                this.arrShownPopups[i].setWindowPosition();
                this.arrShownPopups[i].layout(objSize);
            }
        }
    }

    /*******************************************
    *   OVERLAY
    *******************************************/
    showOverlay()
    {
        if (!this.overlay)
        {
            this.buildOverlay();
        }
        if (!this.overlay.parent)
        {
            this.addToLayoutable(this.overlay);
            this.overlay.layout(Display.getSize());

            return true;
        }
        return false;
    }

    hideOverlay()
    {
        if (this.overlay && this.overlay.parent)
        {
            this.removeFromLayoutable(this.overlay);
            return true;
        }
        return !this.overlay;
    }

    /*******************************************
    *   EVENTS
    *******************************************/
    onOverlayClick()
    {
        for (let key in this.popups)
        {
            if (this.popups[key].IsShown)
            {
                this.popups[key].onOverlayClick();
            }
        }
    }
    
    onPopupShow(sender)
    {
        if (this.arrShownPopups.length == 0 && sender.ShowOverlay)
        {
            this.showOverlay();
        }
        if (_.indexOf(this.arrShownPopups, sender) < 0)
        {
            this.arrShownPopups.push(sender);
        }
    }

    onPopupHide(sender)
    {
        _.remove(this.arrShownPopups, (l) => l === sender);
        this.updatePopupPositions();

        if (this.arrShownPopups.length == 0)
        {
            this.hideOverlay();
        }
    }
}