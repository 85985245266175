import AbstractSaveAdapter from "./AbstractSaveAdapter.js";
import LocalStorageSaveAdapter from "./LocalStorageSaveAdapter.js";
import SaveManager from "../SaveManager.js";

export default class TeleQuebecSaveAdapter extends AbstractSaveAdapter
{
    constructor()
    {
        super();
        /*
            The TeleQuebec adapter uses the LocalStorage adapter for its internal save
            logic but implements its own save/load to push and pull data from the portal 
        */
        this.adapter = new LocalStorageSaveAdapter();
        this.connected = false;

        this.checkForConnection();
    }

     get IsConnected() { return this.connected; }

    get (strKeyName, defaultValue = null)
    {
        return this.adapter.get(strKeyName, defaultValue);
    }

    set (strKeyName, value)
    {
        this.adapter.set(strKeyName, value);
    }

    save ()
    {
        let encryptedData = this.adapter.save();

        //const items = { ...localStorage };
        const items = localStorage["mutants-sv"] ? localStorage["mutants-sv"] : {};

        Tq.Squat.setData(items, function (dataSaved)
        {
            console.log('dataSaved', dataSaved);
        });
        //@TODO: Implement the logic to save data to the TeleQuebec portal
    }

    load (fctCallback)
    {
        //@TODO: Implement the logic to load data to the TeleQuebec portal
        this.adapter.load();
        if (fctCallback)
        {
            fctCallback();
        }
    }

    clear ()
    {
        this.adapter.clear();
        //@TODO: Implement the logic to clear data from the TeleQuebec portal
    }

    checkForConnection()
    {
        //@TODO: Replace this with the real check if connected to TeleQuebec
        //this.connected = true;

        //@TODO: Call this when the state change during gameplay
        SaveManager.instance.emit(SaveManager.instance.EVENT_TELE_QUEBEC_CONNECTION_STATE_CHANGED);
    }
}