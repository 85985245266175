import BaseObject from "./BaseObject.js";

export default class Obstacle extends BaseObject
{
    constructor()
    {
        super();
    }

    get DESPAWN_SELF() { return "despawn-self"; }

    get Item() { return this.meta.item; }
    get Quantity() { return (this.meta.params && this.meta.params.quantity ? this.meta.params.quantity : 1); }
    set Quantity(newValue)
    {
        if (!this.meta.params)
        {
            this.meta.params = {};
        }
        this.meta.params.quantity = newValue;
    }

    get CanLoot()
    {
        let gridPos = this.GridPos;
        let tileId = this.Grid.getTileId(gridPos.x, gridPos.y);

        if (this.Item.IsObstacle)
        {
            let state = null;
            if (this.WorldManager.Environment.IsIndoor)
            {
                state = this.WorldManager.getObstacleState(
                    tileId,
                    this.WorldManager.Environment.Id,
                    this.WorldManager.Environment.RoomId
                );
            }
            else
            {
                state = this.WorldManager.getObstacleState(tileId);
            }
            let next = (state ? state.pickup.next : null);
            let isLootable = this.Item.IsLootable;
            let nextIsInThePast = next <= new Date().getTime();
            let nextIsNull = next === null;

            return isLootable && (nextIsNull || nextIsInThePast);
        }
        return this.Item.IsLootable;
    }

    get IsTrap() { return this.Item.id === "EQ-TRA"; }
    get TrapMinTime() { return (this.IsTrap ? this.Item.TrapMinTime : -1); }

    get IsBarn() { return this.Item.Landmark && this.Item.Landmark.type == "barn"; }


    get IsIndoor ()
    {
        return this.Item.Landmark && this.Item.Landmark.type == "indoor";
    }

    /*******************************************
    *   INITIALIZATION
    *******************************************/
    /**
        Parameters to pass to the init function:
        - id:           Logic id of this object instance. Usually managed by some sort of global manager
        - spawnPos      Vector2 containing the world position to spawn this object in ThreeJS
        - environment   Environment instance where this object lives
        - item          Item object that logically represents this obstacle
        - params        JSON object containing additionnal parameters to pass to this object

    */
    init(meta)
    {
        meta.atlasName = meta.item.AtlasId;
        meta.textureName = meta.item.TextureId;

        if (meta.item.MeshOrigin)
        {
            meta.meshOrigin = meta.item.MeshOrigin;
        }

        super.init(meta);

        this.updatePickupVisual();
    }

    createClosure()
    {
        super.createClosure();
        this.fctOnClick = this.onClick.bind(this);
    }

    bindEvents()
    {
        super.bindEvents();
    }

    destroy()
    {
        super.destroy();
    }

    /*******************************************
    *   SPAWN MANAGEMENT
    *******************************************/
    /**
        Spawns the object on the field
        @param objPos Position where to spawn the object. Should be in grid coordinates (not ThreeJS coordinates)
    */
    spawn(objPos)
    {
        super.spawn(objPos);
        
        if (this.IsTrap)
        {
            this.isTrapFull = this.getIsTrapFull();
        }

        if (this.Item.ClickMatrix)
        {
            this.Scene.addClickableMatrix(
                this.Id,
                this.Mesh,
                this.Item.ClickMatrix,
                this.fctOnClick
            );
        }
        this.updatePickupVisual();
    }

    despawn()
    {
        super.despawn();
        this.isTrapFull = false;

        if (this.Scene)
        {
            this.Scene.removeClickableMatrix(this.Id);
        }
    }

    /*******************************************
    *   LOOTING
    *******************************************/
    /**
        This method is called to initiate a pickup of the item by the player
    */
    pickup()
    {
        if (this.CanLoot)
        {
            this.WorldManager.Player.pickup(this);
        }
    }

    /**
        This method is called when the player has finished animating and this item should be picked up
    */
    applyPickup()
    {
        let gridPos = this.Grid.worldToGridPosition(this.Mesh.position.x, this.Mesh.position.z);
        let tileId = this.Grid.getTileId(gridPos.x, gridPos.y);

        if (this.Item.IsBackpack)
        {
            let content = null;
            if (this.WorldManager.Environment.IsIndoor)
            {
                content = this.WorldManager.clearBackpackOnCell(
                    tileId,
                    this.WorldManager.Environment.Id,
                    this.WorldManager.Environment.RoomId
                );
            }
            else
            {
                content = this.WorldManager.clearBackpackOnCell(tileId);
            }

            for (let id in content.items)
            {
                let result = this.ItemManager.addItem(id, content.items[id]);
                this.validatePickupOverflow(result, this.ItemManager.getItem(id), content.items[id]);
            }

            this.emit(this.DESPAWN_SELF, this);
        }
        else if (this.Item.IsObstacle)
        {
            let now = (new Date()).getTime();
            let next = this.Item.getNextRefill(now);
            let shouldLoot = !this.IsTrap || this.isTrapFull;

            if (shouldLoot)
            {
                let cellContent = null;
                if (this.WorldManager.Environment.IsIndoor)
                {
                    cellContent = this.WorldManager.getCellContent(
                        tileId,
                        this.WorldManager.Environment.Id,
                        this.WorldManager.Environment.RoomId
                    );
                }
                else
                {
                    cellContent = this.WorldManager.getCellContent(tileId);
                }

                if (!cellContent || cellContent.id != this.Item.Id || cellContent.qty == 0)
                {
                    let drops = this.Item.LootDrops;
                    if (drops)
                    {
                        for (let i = 0; i < drops.length; i++)
                        {
                            let result = this.ItemManager.addItem(drops[i].id, drops[i].qty);
                            this.validatePickupOverflow(result, this.ItemManager.getItem(drops[i].id), drops[i].qty);
                        }
                    }
                }
                else
                {

                    let result = this.ItemManager.addItem(cellContent.id, cellContent.qty);
                    this.validatePickupOverflow(result, this.ItemManager.getItem(cellContent.id), cellContent.qty);
                }
            }

            if (this.IsTrap)
            {
                if (this.WorldManager.Environment.IsIndoor)
                {
                    this.WorldManager.updateCellContent(tileId, null, 0, false, this.WorldManager.Environment.Id, this.WorldManager.Environment.RoomId);
                }
                else
                {
                    this.WorldManager.updateCellContent(tileId);
                }
                let result = this.ItemManager.addItem("NO-LAP0", 1);

                this.validatePickupOverflow(result, this.ItemManager.getItem("NO-LAP0"), 1);

                this.emit(this.DESPAWN_SELF, this);
            }
            else
            {
                if (this.WorldManager.Environment.IsIndoor)
                {
                    this.WorldManager.setObstacleState(
                        tileId, 
                        {"lastPickup": now, "nextPickup": next},
                        this.WorldManager.Environment.Id,
                        this.WorldManager.Environment.RoomId
                    );
                }
                else
                {
                    this.WorldManager.setObstacleState(tileId, {"lastPickup": now, "nextPickup": next});
                }

                if (!this.Item.DespawnOnLoot && !this.Item.HideOnLoot)
                {
                    this.updatePickupVisual();
                }
                else
                {
                    this.emit(this.DESPAWN_SELF, this);
                }
            }
        }
        else
        {
            let result = this.ItemManager.addItem(this.Item.Id, this.Quantity);
            if (this.WorldManager.Environment.IsIndoor)
            {
                this.WorldManager.updateCellContent(tileId, null, 0, false, this.WorldManager.Environment.Id, this.WorldManager.Environment.RoomId);
            }
            else
            {
                this.WorldManager.updateCellContent(tileId);
            }

            this.validatePickupOverflow(result, this.Item, this.Quantity);

            this.emit(this.DESPAWN_SELF, this);
        }

    }

    validatePickupOverflow(objResult, objItem, iQuantity)
    {
        let quantity = iQuantity;
        if (objResult.args.leftOver)
        {
            quantity -= objResult.args.leftOver;
        }

        if (quantity > 0)
        {
            this.UIManager.Notifications.showItemGain(objItem, quantity);
        }
        if (quantity < iQuantity)
        {
            this.Environment.dropItem(objItem, iQuantity - quantity, -1, true);
            this.UIManager.Notifications.showBackpackFull();
        }
    }

    updatePickupVisual()
    {
        let ids = this.Item.LootTextureIds;
        if (this.isTrapFull || (this.Item.IsObstacle && ids && ids.length > 1))
        {
            let gridPos = this.Grid.worldToGridPosition(this.Mesh.position.x, this.Mesh.position.z);
            let tileId = this.Grid.getTileId(gridPos.x, gridPos.y);

            let state = null;
            if (this.WorldManager.Environment.IsIndoor)
            {
                state = this.WorldManager.getObstacleState(
                    tileId,
                    this.WorldManager.Environment.Id,
                    this.WorldManager.Environment.RoomId
                );
            }
            else
            {
                state = this.WorldManager.getObstacleState(tileId);
            }

            let next = (state ? state.pickup.next : null);

            let index = 0;

            if (this.isTrapFull)
            {
                let a = 1;
            }

            if ((next && next > new Date().getTime()) || this.isTrapFull)
            {
                index = 1;
            }

            let atlasName = this.Item.AtlasId;
            let textureName = this.isTrapFull ? "trap2.png" : ids[index];

            if (!atlasName)
            {
                atlasName = textureName;
                textureName = null;
            }

            this.material.map = this.fetchTexture(
                atlasName,
                textureName
            );
        }
    }

    /*******************************************
    *   BREAKING
    *******************************************/
    /**
        This method is called to initiate a breakup action on this obstacle by the player
    */
    break()
    {
        this.Environment.Player.break(this);
    }

    /**
        This method is called when the player has finished animating and this obstacle should be broken
    */
    applyBreak()
    {
        let gridPos = this.Grid.worldToGridPosition(this.Mesh.position.x, this.Mesh.position.z);
        let tileId = this.Grid.getTileId(gridPos.x, gridPos.y);

        if (this.WorldManager.Environment.IsIndoor)
        {
            this.WorldManager.setObstacleState(
                tileId, 
                {"broken": true},
                this.WorldManager.Environment.Id,
                this.WorldManager.Environment.RoomId
            );
        }
        else
        {
            this.WorldManager.setObstacleState(tileId, {"broken": true});
        }
        this.emit(this.DESPAWN_SELF, this);
    }

    /*******************************************
    *   TRAP
    *******************************************/
    getIsTrapFull()
    {
        let isFull = false;
        if (this.IsTrap)
        {
            let gridPos = this.Grid.worldToGridPosition(this.Mesh.position.x, this.Mesh.position.z);
            let tileId = this.Grid.getTileId(gridPos.x, gridPos.y);

            let cellContent = this.WorldManager.getCellContent(tileId);
            if (cellContent && cellContent.id == this.Item.Id)
            {
                let current = (new Date().getTime() / 1000) >> 0;
                let elapsed = current - cellContent.ts;


                if (elapsed > this.TrapMinTime)
                {
                    let distance = Math.max(1, this.Environment.ObstacleParser.getClosestObjectDistance(
                        gridPos.x,
                        gridPos.y,
                        this.Item.TrapNear
                    ));

                    if (distance >= 45)
                    {
                        distance = 45;
                    }

                    let chance = (1 - (distance / 50)) * 0.75;
                    isFull = Math.random() < chance;
                }
            }
        }

        return isFull;
    }

    /*******************************************
    *   BARN
    *******************************************/
    moveToBarn()
    {
        let gridPos = this.GridPos;
        let playerPos = this.WorldManager.Player.GridPos;

        gridPos = this.Grid.getNearestWalkableTile(
            gridPos.x,
            gridPos.y,
            playerPos.x,
            playerPos.y
        );

        if (gridPos)
        {
            if (gridPos.x == playerPos.x && gridPos.y == playerPos.y)
            {
                this.transitToBarn();
            }
            else
            {
                let pos = this.Grid.gridToWorldPosition(gridPos.x, gridPos.y);
                this.WorldManager.Player.goTo(pos.x, pos.y, null, this.transitToBarn.bind(this));
            }
        }
    }

    moveToIndoor()
    {
        let gridPos = this.GridPos;
        let playerPos = this.WorldManager.Player.GridPos;

        gridPos = this.Grid.getNearestWalkableTile(
            gridPos.x,
            gridPos.y,
            playerPos.x,
            playerPos.y
        );

        if (gridPos)
        {
            if (gridPos.x == playerPos.x && gridPos.y == playerPos.y)
            {


                this.transitToIndoor(this.meta.item.definition.map.landmark.indoor);
            }
            else
            {
                let pos = this.Grid.gridToWorldPosition(gridPos.x, gridPos.y);
                let fct = () => this.transitToIndoor(this.meta.item.definition.map.landmark.indoor);
                this.WorldManager.Player.goTo(pos.x, pos.y, null, fct);
            }
        }
    }

    transitToIndoor (strIndoor)
    {
        //to check condition
        let obj = this.meta.item.definition.map.landmark.indoorNeed;

        if (obj && this.ItemManager.getItemQuantity(obj, false) === 0)
        {
            let obj = this.meta.item.definition.map.landmark.indoorNoEntryMsg;
            this.UIManager.Notifications.showObjectiveNotification(obj, false);

            return;
        }

        this.WorldManager.preventWorldClick();
        this.UIManager.showSceneTransition(() =>
        {
            this.WorldManager.allowWorldClick();
            this.WorldManager.changeEnvironment(strIndoor);
        });
    }

    transitToBarn()
    {
        this.WorldManager.preventWorldClick();
        this.UIManager.showSceneTransition(() =>
        {
            this.WorldManager.allowWorldClick();
            this.WorldManager.changeEnvironment(this.WorldManager.ENVIRONMENT_BARN);
        });
    }

    /*******************************************
    *   EVENTS
    *******************************************/
    onClick()
    {
        if (this.Item.IsBreakable)
        {
            this.break();
        }
        else if (this.IsBarn)
        {
            this.moveToBarn();
        }
        else if (this.IsIndoor)
        {
            this.moveToIndoor();
        }
        else
        {
            this.pickup();
        }
    }
}