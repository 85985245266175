import BaseCheat from "./BaseCheat.js";

export default class ItemAdd extends BaseCheat
{
    constructor(dependencies)
    {
        super(dependencies);
    }

    get CommandUse() { return "item add [id] [qty]"; }
    get CommandDesc() { return "Adds an amount of item in the player's inventory"; }

    //---------------------------------------------------------
    //  DEPENDENCIES
    //---------------------------------------------------------
    get ItemManager() { return this.dependencies.get("ItemManager"); }
    //---------------------------------------------------------

    parseCommand(strCmd, arrArguments)
    {
        if (strCmd == "item" && arrArguments.length >= 2 && arrArguments[0].toLowerCase() == "add")
        {
            let id = arrArguments[1].toUpperCase();

            let qty = 1;
            if (arrArguments.length >= 3)
            {
                qty = parseInt(arrArguments[2]);
            }

            if (id && this.ItemManager.getItem(id) && qty)
            {
                let result = this.ItemManager.addItem(id, qty);
                if (result.status)
                {
                    this.log("The item " + id + " was added " + qty + " time" + (qty > 1 ? "s" : "") + " in your inventory");
                    return;
                }
            }
            this.warn("Could not add " + id + " to your inventory");
        }
        else if (strCmd == "items" && arrArguments.length >= 2 && arrArguments[0].toLowerCase() == "add")
        {
            let qty = 1;


            for (let i = 1; i < arrArguments.length; i++)
            {
                let id = arrArguments[i].toUpperCase();

                let result = this.ItemManager.addItem(id, qty);
                if (result.status)
                {
                    this.log("The item " + id + " was added " + qty + " time" + (qty > 1 ? "s" : "") + " in your inventory");

                }
            }

        }
    }
}