import { string2hex }  from "@pixi/utils"; 
import Character from "../../commons/characters/Character.js";
import ContainerResponsive from "../../commons/responsive/ContainerResponsive.js";
import Display from "../../../utils/Display.js";
import GraphicsResponsive from "../../commons/responsive/GraphicsResponsive.js";
import Lerp from "../../../utils/Lerp.js";
import Library from "../../../Library.js";
import SpriteResponsive from "../../commons/responsive/SpriteResponsive.js";

export default class CharacterSelectionButton extends ContainerResponsive
{
    constructor ()
    {
        super();
    }

    get EVENT_CLICK() { return "character_click"; }

    get Character() { return this.character; }
    get IsSelected() { return this.character == this.UI.CharacterManager.CurrentCharacter; }

    get ButtonWidth() { return this.valueFormula(this.UI.Values.characterselect.size.width); }
    get ButtonHeight() { return this.valueFormula(this.UI.Values.characterselect.size.height); }
    get BackgroundColorNormal() { return string2hex(this.UI.Values.characterselect.background.normal.color); }
    get BackgroundColorSelected() { return string2hex(this.UI.Values.characterselect.background.selected.color); }
    get StrokeWidth() { return this.evaluate(this.valueFormula(this.UI.Values.characterselect.stroke.size), 0, 0, 0, 0, this); }
    get StrokeColor() { return string2hex("color" in this.UI.Values.characterselect.stroke ? this.UI.Values.characterselect.stroke.color : "#000000"); }
    get CornerRadius() { return this.evaluate(this.valueFormula(this.UI.Values.characterselect.corner.radius), 0, 0, 0, 0, this); }

    getIconTexture (strType)
    {
        let texture = "";

        if (strType === this.UI.CharacterManager.STAT_HUNGER)
        {
            texture = "icon_gauge_food";
        }
        else if (strType === this.UI.CharacterManager.STAT_ENERGY)
        {
            texture = "icon_gauge_energy";
        }
        else if (strType === this.UI.CharacterManager.STAT_STRESS)
        {
            texture = "icon_gauge_stress";
        }

        return Library.getTextureFromAtlas("ui", texture);
    }

    /*******************************************
    *   INITIALIZATION
    *******************************************/
    /**
        Parameters to pass to the init function:
        - ui:           UI section object where this component resides
        - character:    Index of this character in the player's roster
        - scale:        (Optional) Scale to apply to this button. Default is 1
    */
    init(meta)
    {
        this.character = meta.character;
        this.baseScale = ("scale" in meta ? meta.scale : 1);
        this.buttonScale = {"x": this.baseScale, "y": this.baseScale};

        return super.init(meta);
    }

    createClosure()
    {
        super.createClosure();

        this.fctOnClick = this.onClick.bind(this);
        this.fctOnPointerDown = this.onPointerDown.bind(this);
        this.fctOnWindowMouseUp = this.onWindowMouseUp.bind(this);
        this.fctOnWindowTouchEnd = this.onWindowTouchEnd.bind(this);
        this.fctOnCharacterSwitch = this.onCharacterSwitch.bind(this);
        this.fctOnCharacterStateChange = this.onCharacterStateChange.bind(this);
    }

    bindEvents()
    {
        super.bindEvents();

        this.UI.CharacterManager.on(this.UI.CharacterManager.EVENT_CURRENT_CHARACTER_SWITCH, this.fctOnCharacterSwitch);
        this.UI.CharacterManager.on(this.UI.CharacterManager.EVENT_STATE_CHANGED, this.fctOnCharacterStateChange);

        window.addEventListener("mouseup", this.fctOnWindowMouseUp);
        window.addEventListener("touchend", this.fctOnWindowTouchEnd);
    }

    destroy(options)
    {
        this.UI.CharacterManager.off(this.UI.CharacterManager.EVENT_CURRENT_CHARACTER_SWITCH, this.fctOnCharacterSwitch);
        this.UI.CharacterManager.off(this.UI.CharacterManager.EVENT_STATE_CHANGED, this.fctOnCharacterStateChange);

        window.removeEventListener("mouseup", this.fctOnWindowMouseUp);
        window.removeEventListener("touchend", this.fctOnWindowTouchEnd);

        if (this.graphics)
        {
            this.graphics.off("pointerdown", this.fctOnPointerDown);
            this.graphics.off("pointerup", this.fctOnClick);

            this.removeChild(this.graphics);
            this.graphics.destroy(options);
            delete this.graphics;
        }

        super.destroy(options);
    }

    /*******************************************
    *   BUILD
    *******************************************/
    build ()
    {
        super.build();
        this.buildBackground();
    }

    clean()
    {
        super.clean();

        if (this.graphics)
        {
            this.graphics.clear();
        }
    }

    buildBackground()
    {
        if (!this.graphics)
        {
            this.graphics = new GraphicsResponsive().init({"ui": this.UI});
            this.graphics.interactive = true;
            this.graphics.on("pointerdown", this.fctOnPointerDown);//@
            this.graphics.on("pointerup", this.fctOnClick);//@
            this.graphics.stateIcons = {};

            this.addChild(this.graphics);
        }

        this.graphics.buttonMode = !this.IsSelected;

        let width = this.ButtonWidth + " * " + this.buttonScale.x;
        let calculatedWidth = this.evaluate(width, 0, 0, 0, 0, this);
        let height = this.ButtonHeight + " * " + this.buttonScale.y;
        let calculatedHeight = this.evaluate(height, 0, 0, 0, 0, this);
        let x = this.ButtonWidth + " * (1 - " + this.buttonScale.x + ") / 2";
        let y = this.ButtonHeight + " * (1 - " + this.buttonScale.y + ") / 2";

        this.graphics.rX = [{on: "default", "x": x}];
        this.graphics.rY = [{on: "default", "y": y}];

        let bgColor = (this.IsSelected ? this.BackgroundColorSelected : this.BackgroundColorNormal);
        let strokeWidth = this.StrokeWidth;
        let strokeColor = this.StrokeColor;
        let cornerRadius = this.CornerRadius;

        if (strokeWidth > 0)
        {
            this.graphics.lineStyle({"color":strokeColor, "width":strokeWidth, "alignment":1});
        }
        this.graphics.beginFill(bgColor, 1);

        if (cornerRadius > 0)
        {
            this.graphics.drawRoundedRect(0, 0, calculatedWidth, calculatedHeight, cornerRadius);
        }
        else
        {
            this.graphics.drawRect(0, 0, calculatedWidth, calculatedHeight, cornerRadius);
        }

        this.graphics.layout(Display.getSize());
        

        let state = null;
        if (this.UI.CharacterManager.isCharacterSleeping(this.Character))
        {
            state = this.UI.CharacterManager.STAT_ENERGY;
        }
        else if (this.UI.CharacterManager.isCharacterRelaxing(this.Character))
        {
            state = this.UI.CharacterManager.STAT_STRESS;
        }

        this.buildSprite(calculatedWidth, calculatedHeight, this.graphics);
        this.buildStateIcon(calculatedWidth, calculatedHeight, this.graphics, state);
    }

    buildSprite(fWidth, fHeight, objParent)
    {
        if (!objParent.characterContainer)
        {
            let container = new ContainerResponsive().init({"ui": this.UI});

            let character = new Character().init({
                "ui": this.UI,
                "build": this.UI.CharacterManager.getCharacterBuild(this.Character)
            });

            character.rWidth = [{on:"default", width: fWidth * 2}];
            character.rHeight = [{on:"default", height: fHeight * 2}];
            character.rX = [{on:"default", x: -fWidth / 2.33}];
            character.rY = [{on:"default", y: -fHeight / 4}];

            for (let key in character.children)
            {
                character.children[key].originalTint = character.children[key].tint;
            }

            container.addChild(character);

            let mask = new GraphicsResponsive().init({"ui": this.UI});

            container.characterMask = mask;
            container.addChild(mask);
            mask.position.set(0, 0);
            mask.beginFill(0xFFFFFF, 1);
            mask.drawRect(0, 0, fWidth, fHeight);
            mask.endFill(0xFFFFFF, 1);
            container.mask = mask;

            objParent.character = character;
            objParent.characterContainer = container;
            objParent.addChild(container);
        }

        let mask = objParent.characterContainer.characterMask;
        mask.clear();
        mask.beginFill(0xFFFFFF, 1);
        mask.drawRect(0, 0, fWidth, fHeight);
        mask.endFill(0xFFFFFF, 1);

        let character = objParent.character;
        character.rWidth = [{on:"default", width: fWidth * 2}];
        character.rHeight = [{on:"default", height: fHeight * 2}];
        character.rX = [{on:"default", x: -fWidth / 2.33}];
        character.rY = [{on:"default", y: -fHeight / 4}];

        character.layout(Display.getSize());

        let tint = 0xFFFFFF;
        if (!this.IsSelected)
        {
            tint = this.BackgroundColorNormal;
        }
        for (let key in objParent.character.children)
        {
            objParent.character.children[key].tint = Lerp.lerpColorHex(objParent.character.children[key].originalTint, tint, (this.IsSelected ? 0 : 0.33));
        }

        objParent.removeChild(objParent.characterContainer);
        objParent.addChild(objParent.characterContainer);
    }

    buildStateIcon(fWidth, fHeight, objParent, strCharacterState = null)
    {
        for (let iconKey in objParent.stateIcons)
        {
            objParent.removeChild(objParent.stateIcons[iconKey]);
        }

        if (strCharacterState)
        {
            if (!objParent.stateIcons[strCharacterState])
            {
                let texture = this.getIconTexture(strCharacterState);
                objParent.stateIcons[strCharacterState] = new SpriteResponsive(texture).init({"ui": this.UI});
            }

            let size = 0.3;

            let icon = objParent.stateIcons[strCharacterState];

            let tint = 0xFFFFFF;
            if (!this.IsSelected)
            {
                tint = this.BackgroundColorNormal;
            }
            icon.tint = tint;

            let ratio = icon.height / icon.width;
            let width = fWidth * size;
            let height = width * ratio;

            if (icon.width < icon.height)
            {
                ratio = icon.width / icon.height;
                height = fWidth * size;
                width = height * ratio;
            }

            let x = fWidth * (strCharacterState == this.UI.CharacterManager.STAT_ENERGY ? 0.08 : 0.04);
            let y = fHeight * 0.97 - height;

            icon.width = width;
            icon.height = height;
            icon.position.set(x, y);

            objParent.addChild(objParent.stateIcons[strCharacterState]);
        }
    }

    //---------------------------------------------------
    //  EVENTS
    //---------------------------------------------------
    onClick()
    {
        if (!this.IsSelected)
        {
            this.buttonScale.x = this.baseScale;
            this.buttonScale.y = this.baseScale;
            this.build();

            this.emit(this.EVENT_CLICK, this);
        }
    }

    onPointerDown()
    {
        if (!this.IsSelected)
        {
            this.buttonScale.x = this.baseScale * this.UI.Values.textbutton.scale.down;
            this.buttonScale.y = this.baseScale * this.UI.Values.textbutton.scale.down;
            this.build();
        }
    }

    onWindowMouseUp(e)
    {
        this.buttonScale.x = this.baseScale;
        this.buttonScale.y = this.baseScale;
        this.build();
    }

    onWindowTouchEnd(e)
    {
        if (e.touches.length == 0)
        {
            this.buttonScale.x = this.baseScale;
            this.buttonScale.y = this.baseScale;
            this.build();
        }
    }

    onCharacterSwitch(iNewCharacter)
    {
        this.build();
    }

    onCharacterStateChange(objCharacter, strNewState)
    {
        this.build();
    }
}