import {Math as MathThree} from "three";

export default class Utils
{
    static basename(path)
    {
        return path.replace(/.*\//, '');
    }

    static dirname(path)
    {
        return path.match(/.*\//)[0];
    }

    static degToRad(fDegrees)
    {
        return MathThree.degToRad(fDegrees);
    }

    static isOverlapping(rect1, rect2)
    {
        return !(rect1.max.x < rect2.min.x || rect1.min.x > rect2.max.x || 
                 rect1.max.y < rect2.min.y || rect1.min.y > rect2.max.y);
    }
}