import BaseCheat from "./BaseCheat.js";

export default class Creator extends BaseCheat
{
    constructor(dependencies)
    {
        super(dependencies);
    }

    get CommandUse() { return "creator"; }
    get CommandDesc() { return "Moves the player to the character creator section"; }

    //---------------------------------------------------------
    //  DEPENDENCIES
    //---------------------------------------------------------
    get UIManager() { return this.dependencies.get("UIManager"); }
    get WorldManager() { return this.dependencies.get("WorldManager"); }
    //---------------------------------------------------------

    parseCommand(strCmd, arrArguments)
    {
        if (strCmd == "creator")
        {
            this.UIManager.showSceneTransition(function() 
            {
                setTimeout(function()
                {   
                    this.WorldManager.destroyEnvironment();

                    this.UIManager.showCharacterCreatorUI();
                    this.WorldManager.allowWorldClick();

                    this.log("Moved to the character creator")
                }
                .bind(this) ,1);
            }
            .bind(this));
        }
    }
}