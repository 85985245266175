import { string2hex }  from "@pixi/utils"; 
import { TextStyle } from "@pixi/text";
import { TextMetrics } from "@pixi/text";
import ActionButton from "../../commons/buttons/ActionButton.js";
import BaseCreationContent from "./BaseCreationContent.js";
import Display from "../../../utils/Display.js";
import GraphicsResponsive from "../../commons/responsive/GraphicsResponsive.js";
import Lerp from "../../../utils/Lerp.js";
import Library from "../../../Library.js";
import NameGenerator from "../../../utils/NameGenerator.js";
import SpriteResponsive from "../../commons/responsive/SpriteResponsive.js";
import TextboxResponsive from "../../commons/controls/TextboxResponsive.js";
import TextButton from "../../commons/buttons/TextButton.js";
import TextResponsive from "../../commons/responsive/TextResponsive.js";

export default class CreationMenuContent extends BaseCreationContent
{
    constructor()
    {
        super();
    }

    get EVENT_SECTION_CLICK() { return "section-click"; }
    get EVENT_BACK() { return "back"; }
    get EVENT_NEXT() { return "next"; }

    static getMinWidth(strHeight) { return "(" + strHeight + " * 33%) * 5 + (" + strHeight + " * 33%) * 3"; }

    get NameTitle() { return this.UI.LabelManager.translate("Crea_Nom"); }

    get IconTint() { return string2hex(this.UI.Values.charactercreator.icon.tint); }
    get SlotBackgroundColor() { return string2hex(this.UI.Values.slot.background.color);}
    get SlotStrokeWidth() { return this.evaluate(this.valueFormula(this.UI.Values.slot.stroke.size), 0, 0, 0, 0);}
    get SlotStrokeColor() { return string2hex("color" in this.UI.Values.modal.stroke ? this.UI.Values.slot.stroke.color : "#000000");}
    get SlotCornerRadius() { return this.evaluate(this.valueFormula(this.UI.Values.slot.corner.radius), 0, 0, 0, 0);}

    getSectionLabel(iIndex)
    {
        return this.CharacterCreator.getSectionLabel(this.sections[iIndex]);
    }

    /*******************************************
    *   INITIALIZATION
    *******************************************/
    /**
        Parameters to pass to the init function:
        - ui:       UI section object where this component resides
        - creator:  Character creator owning this content
        - character Id of the currently selected character
        - rWidth:   Responsive equation representing the width of this component
        - rHeight:  Responsive equation representing the height of this component
    */
    init(meta)
    {
        this.sections = meta.creator.SECTIONS;
        this.backScale = {"x": 1, "y": 1};
        this.nextScale = {"x": 1, "y": 1};
        this.prevCharacter = -1;

        let strName1 = meta.ui.CharacterManager.getCharacterName(0);
        let strName2 = meta.ui.CharacterManager.getCharacterName(1);
        let strName3 = meta.ui.CharacterManager.getCharacterName(2);
        this.arrNames = [strName1, strName2, strName3];

        return super.init(meta);
    }

    createClosure()
    {
        super.createClosure();

        this.fctOnTextChange = this.onTextChange.bind(this);
        this.fctOnBackClick = this.onBackClick.bind(this);
        this.fctOnNextClick = this.onNextClick.bind(this);
        this.fctOnBackDown = this.onBackDown.bind(this);
        this.fctOnNextDown = this.onNextDown.bind(this);
        this.fctOnTextFocus = this.onTextFocus.bind(this);
        this.fctOnTextFocusOut = this.onTextFocusOut.bind(this);
        this.fctGetRandomName = this.onGetRandomName.bind(this);
    }

    bindEvents()
    {
        super.bindEvents();
    }

    destroy(options)
    {
        if (this.textbox)
        {
            this.removeChild(this.textbox);
            this.textbox.off(this.textbox.EVENT_CHANGE, this.fctOnTextChange);
            this.textbox.off(this.textbox.EVENT_FOCUS, this.fctOnTextFocus);
            this.textbox.off(this.textbox.EVENT_BLUR, this.fctOnTextFocusOut);
            this.textbox.destroy(options);

            delete this.textbox;
        }

        if (this.btnRandomName)
        {
            this.removeChild(this.btnRandomName);
            this.btnRandomName.off(this.btnRandomName.EVENT_CLICK, this.fctGetRandomName);
            this.btnRandomName.destroy(options);

            delete this.btnRandomName;
        }
        
        super.destroy(options);
    }

    /*******************************************
    *   BUILD
    *******************************************/
    build()
    {
        super.build();

        this.buildSections();
        this.buildArrows();
    }

    buildSections()
    {
        for (let i = 0; i < this.sections.length; i++)
        {
            this.buildSection(i);
        }

        this.buildNameSection();
    }

    buildSection(iIndex)
    {
        let infos = this.buildSectionBackground(iIndex);
        this.buildSectionSprite(iIndex, infos.width, infos.height, infos.bg);
        this.buildSectionText(iIndex, infos.x, infos.y, infos.width, infos.height, infos.bg);
    }

    buildSectionBackground(iIndex)
    {
        if (!this.graphics["bg_" + iIndex])
        {
            this.graphics["bg_" + iIndex] = new GraphicsResponsive().init({"ui":this.UI});
            this.graphics["bg_" + iIndex].interactive = true;
            this.graphics["bg_" + iIndex].buttonMode = true;

            this.graphics["bg_" + iIndex].on("pointerdown", this.onSectionDown.bind(this, iIndex));
            this.graphics["bg_" + iIndex].on("pointerup", this.onSectionUp.bind(this, iIndex));

            this.addChild(this.graphics["bg_" + iIndex]);
        }

        let graphics = this.graphics["bg_" + iIndex];

        let scale = graphics.scale;
        let height = "(" + this.BgHeight + " * 33%)";
        let width = height;
        let padding = "(" + width + " / 2)";

        let count = iIndex - Math.floor(this.sections.length / 2);
        let x = "(" + this.BgWidth + " / 2) - (" + width + " / 2) + (" + width + " * (" + count + ")) + (" + padding + " * (" + count + "))";
        let y = this.BgHeight  + " * 10%";

        let xWithScale = x + " + (" + (1 - scale.x) + " / 2) * " + width;
        let yWithScale = y + " + (" + (1 - scale.y) + " / 2) * " + height;

        graphics.rX = [{on: "default", x: xWithScale}];
        graphics.rY = [{on: "default", y: yWithScale}];
        graphics.layout(Display.getSize());

        let calculatedWidth = this.evaluate(width, 0, 0, 0, 0);
        let calculatedHeight = this.evaluate(height, 0, 0, 0, 0);

        let bgColor = this.SlotBackgroundColor;
        let strokeWidth = this.SlotStrokeWidth;
        let strokeColor = this.SlotStrokeColor;
        let cornerRadius = this.SlotCornerRadius;

        if (strokeWidth > 0)
        {
            graphics.lineStyle({"color":strokeColor, "width":strokeWidth, "alignment":1});
        }

        graphics.beginFill(bgColor, 1);
        if (cornerRadius > 0)
        {
            graphics.drawRoundedRect(0, 0, calculatedWidth, calculatedHeight, cornerRadius);
        }
        else
        {
            graphics.drawRect(0, 0, calculatedWidth, calculatedHeight);
        }
        graphics.endFill();

        return {"x": x, "y": y, "width": calculatedWidth, "height": calculatedHeight, "bg": graphics};
    }

    buildSectionSprite(iIndex, fWidth, fHeight, objParent)
    {
        if (!objParent.sectionSprite)
        {
            let texture = Library.getTextureFromAtlas("ui", this.sections[iIndex]);
            objParent.sectionSprite = new SpriteResponsive(texture).init({"ui": this.UI});
            objParent.addChild(objParent.sectionSprite);
        }

        let sprite = objParent.sectionSprite;

        let width = fWidth * 0.85;
        let height = fHeight * 0.85;

        let ratio = sprite.height / sprite.width;
        let spriteWidth = width;
        let spriteHeight = spriteWidth * ratio;

        if (sprite.width < sprite.height)
        {
            ratio = sprite.width / sprite.height;
            spriteHeight = height;
            spriteWidth = spriteHeight * ratio;
        }

        let x = fWidth / 2 - spriteWidth / 2;
        let y = fHeight / 2 - spriteHeight / 2;

        sprite.width = spriteWidth;
        sprite.height = spriteHeight;
        sprite.position.set(x, y);

        sprite.tint = this.IconTint;
    }

    buildSectionText(iIndex, strX, strY, fWidth, fHeight, objParent)
    {
        if (!this.texts["section_" + iIndex])
        {
            this.texts["section_" + iIndex] = new TextResponsive().init({"ui":this.UI});
            this.addChild(this.texts["section_" + iIndex]);
        }

        let text = this.texts["section_" + iIndex];
        let label = this.getSectionLabel(iIndex);

        let fontSize =   this.evaluate(this.valueFormula(this.UI.Values.general.title.size));
        fontSize = Math.max(fontSize, this.UI.ResponsiveManager.TitleMinFontSize * 0.8);

        let style = new TextStyle({
            fontFamily: this.UI.Values.general.title.font,
            fontSize,
            fontWeight: this.UI.Values.general.title.weight,
            fill: this.UI.Values.general.title.color,
            align: "center"
        });

        let metrics = TextMetrics.measureText(label, style);

        let x = strX + " + " + fWidth + " / 2 - " + metrics.width + " / 2";
        let y = strY + " + " + fHeight + " + " + metrics.height + " / 2";

        text.text = label;
        text.style = style;
        text.width = metrics.width;
        text.height = metrics.height;

        text.rX = [{on: "default", x: x}];
        text.rY = [{on: "default", y: y}];
        text.layout(Display.getSize());
    }

    buildNameSection()
    {
        let width = "(" + this.BgWidth + " * 70%)";
        let height = "(" + this.BgHeight + " * 15%)";

        if (!this.textbox)
        {
            this.textbox = new TextboxResponsive().init({
                "ui": this.UI,
                "rWidth": width,
                "rHeight": height,
                "text": this.UI.CharacterManager.getCharacterName(this.Character)
            });
            this.textbox.on(this.textbox.EVENT_CHANGE, this.fctOnTextChange)//@
            this.textbox.on(this.textbox.EVENT_FOCUS, this.fctOnTextFocus);//@
            this.textbox.on(this.textbox.EVENT_BLUR, this.fctOnTextFocusOut);//@

            this.addChild(this.textbox);

            //This is a ghetto hack to partially hide from the screen transition overlay
            if (this.UI.screenTransition)
            {
                this.textbox.textbox.style.opacity = 0;
                this.interval = setInterval(function()
                {
                    if (this.UI.screenTransition)
                    {
                        this.textbox.textbox.style.opacity = Lerp.lerp(0, 1, this.UI.screenTransition.t);
                    }
                    else
                    {
                        this.textbox.textbox.style.opacity = 1
                        clearInterval(this.interval);
                    }
                }
                .bind(this), 1);
            }


            this.btnRandomName = new ActionButton().init({
                "ui": this.UI,
                "textureName": "dice",
                "rWidth": height,
                "rHeight": width,
                "iconScale": 0.75,
                "tint": 0
            });
            this.btnRandomName.on(this.btnRandomName.EVENT_CLICK, this.fctGetRandomName);

            this.addChild(this.btnRandomName);

        }

        let textbox = this.textbox;

        let size = Display.getSize();
        let x = this.BgWidth + " / 2 - " + width + " / 2";
        let y = this.BgHeight + " * 68%";

        textbox.rX = [{on: "default", x: x}];
        textbox.rY = [{on: "default", y: y}];
        textbox.setResponsiveSize(width, height);
        textbox.layout(size);

        let btnX = `(${x}) + (${width}) + (${height}) / 4`;

        this.btnRandomName.rX = [{on: "default", x: btnX}];
        this.btnRandomName.rY = [{on: "default", y: y}];
        this.btnRandomName.layout(size);

        if (this.prevCharacter != this.Character)
        {
            this.prevCharacter = this.Character;

            textbox.Text = this.arrNames[this.character];

            if (this.arrNames[this.character] === "")
                this.onGetRandomName();
        }
        
        if (!this.texts.nameTitle)
        {
            this.texts.nameTitle = new TextResponsive().init({"ui":this.UI});
            this.addChild(this.texts.nameTitle);
        }

        let text = this.texts.nameTitle;
        let label = this.NameTitle;

        let fontSize = this.evaluate(this.valueFormula(this.UI.Values.general.title.size));
        fontSize = Math.max(fontSize, this.UI.ResponsiveManager.TitleMinFontSize * 0.6);

        let style = new TextStyle({
            fontFamily: this.UI.Values.general.title.font,
            fontSize,
            fontWeight: this.UI.Values.general.title.weight,
            fill: this.UI.Values.general.title.color,
            align: "center"
        });

        let metrics = TextMetrics.measureText(label, style);

        let textX = this.BgWidth + " / 2 - " + metrics.width + " / 2";
        let textY = this.BgHeight + " * 87.5%";

        text.rX = [{on: "default", x: textX}];
        text.rY = [{on: "default", y: textY}];
        text.layout(size);

        text.text = label;
        text.style = style;
        text.width = metrics.width;
        text.height = metrics.height;
    }

    buildArrows()
    {
        if (!this.sprites.backArrow)
        {
            let texture = Library.getTextureFromAtlas("ui", "arrow_rtl");
            this.sprites.backArrow = new SpriteResponsive(texture).init({"ui": this.UI});
            this.sprites.backArrow.interactive = true;
            this.sprites.backArrow.buttonMode = true;
            this.sprites.backArrow.on("pointerup", this.fctOnBackClick);
            this.sprites.backArrow.on("pointerdown", this.fctOnBackDown);

            this.addChild(this.sprites.backArrow);
        }

        if (!this.sprites.nextArrow)
        {
            let texture = Library.getTextureFromAtlas("ui", "arrow_rtl");
            this.sprites.nextArrow = new SpriteResponsive(texture).init({"ui": this.UI});
            this.sprites.nextArrow.interactive = true;
            this.sprites.nextArrow.buttonMode = true;
            this.sprites.nextArrow.on("pointerup", this.fctOnNextClick);
            this.sprites.nextArrow.on("pointerdown", this.fctOnNextDown);

            this.sprites.nextArrow.scale.set(-1, 1);

            this.addChild(this.sprites.nextArrow);
        }

        let sprite = this.sprites.backArrow;
        let scale = this.backScale;

        let ratio = sprite.width / sprite.height;
        let height = this.evaluate(this.BgHeight + " * 12.5%", 0, 0, 0, 0, this);
        let width = height * ratio * scale.x;
        height *= scale.y;
        let x = - width / 1.5 + width * (1 - scale.x) / 2;
        let y = height + height * (1 - scale.y) / 2;

        sprite.width = width;
        sprite.height = height;
        sprite.position.set(x, y);

        sprite = this.sprites.nextArrow;
        scale = this.nextScale;

        height = this.evaluate(this.BgHeight + " * 12.5%", 0, 0, 0, 0, this);
        width = height * ratio * scale.x;
        height *= scale.y;

        let bgWidth = this.evaluate(this.BgWidth, 0, 0, 0, 0, this);
        x = bgWidth + this.StrokeWidth + width * 0.5 - width * (1 - scale.x) / 2;
        y = height + height * (1 - scale.y) / 2;

        sprite.width = width;
        sprite.height = height;
        sprite.position.set(x, y);

        if (this.Character > 0 && !this.sprites.backArrow.parent)
        {
            this.addChild(this.sprites.backArrow);
        }
        else if (this.Character <= 0)
        {
            this.removeChild(this.sprites.backArrow);
        }

        if (this.Character < 2 && !this.sprites.nextArrow.parent)
        {
            this.addChild(this.sprites.nextArrow);
        }
        else if (this.Character >= 2)
        {
            this.removeChild(this.sprites.nextArrow);
        }
    }

    updateSectionsScale(iIndex)
    {
        let scaleNormal = this.UI.Values.textbutton.scale.normal;
        let scaleDown = this.UI.Values.textbutton.scale.down;

        for (let i = 0; i < this.sections.length; i++)
        {
            if (this.graphics["bg_" + i])
            {
                this.graphics["bg_" + i].scale.set((i == iIndex) ? scaleDown : scaleNormal);
            }
        }

        if (this.sprites.backArrow)
        {
            if (iIndex == -2)
            {
                this.backScale.x = scaleDown;
                this.backScale.y = scaleDown;
            }
            else
            {
                this.backScale.x = scaleNormal;
                this.backScale.y = scaleNormal;
            }
        }

        if (this.sprites.nextArrow)
        {
            if (iIndex == -3)
            {
                this.nextScale.x = scaleDown;
                this.nextScale.y = scaleDown;
            }
            else
            {
                this.nextScale.x = scaleNormal;
                this.nextScale.y = scaleNormal;
            }
        }
    }

    /*******************************************
    *   NAME GENERATION
    *******************************************/
    async onGetRandomName ()
    {
        let p = await NameGenerator.instance.getOneName();
        this.arrNames[this.character] = NameGenerator.instance.currentName;

        this.UI.CharacterManager.setCharacterName(this.character,  this.arrNames[this.character]);

        if (this.textbox)
        {
            this.textbox.Text = this.arrNames[this.character];
        }
    }

    /*******************************************
    *   EVENTS
    *******************************************/
    onSectionUp(iIndex)
    {
        this.updateSectionsScale(-1);
        this.build();

        this.UI.AudioManager.playSfx(this.UI.Values.audio.click);
        this.emit(this.EVENT_SECTION_CLICK, this, this.sections[iIndex]);
    }

    onSectionDown(iIndex)
    {
        this.updateSectionsScale(iIndex);
        this.build();
    }

    onBackDown()
    {
        this.updateSectionsScale(-2);
        this.build();
    }

    onNextDown()
    {
        this.updateSectionsScale(-3);
        this.build();
    }

    onWindowMouseUp(e)
    {
        super.onWindowMouseUp(e);

        this.updateSectionsScale(-1);
        this.build();
    }

    onWindowTouchEnd(e)
    {
        super.onWindowTouchEnd(e);

        if (e.touches.length == 0)
        {
            this.updateSectionsScale(-1);
            this.build();
        }
    }

    onTextChange(sender, strText)
    {
        if (strText)
        {
            this.UI.CharacterManager.setCharacterName(this.Character, strText);

            if (this.textbox.IsError)
            {
                this.textbox.IsError = false;
            }
        }
    }

    onBackClick()
    {
        this.UI.AudioManager.playSfx(this.UI.Values.audio.click);
        this.emit(this.EVENT_BACK, this);
    }

    onNextClick()
    {
        this.UI.AudioManager.playSfx(this.UI.Values.audio.click);
        this.emit(this.EVENT_NEXT, this);
    }

    onTextFocus(sender)
    {
        if (sender.Text == "Personnage " + (this.Character + 1))
        {
            sender.Text = "";
        }
    }

    onTextFocusOut(sender)
    {
        if (sender.Text == "")
        {
            sender.Text = "Personnage " + (this.Character + 1);
        }
    }
}