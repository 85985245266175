import Item from "./Item.js";
import ItemCodes from "./codes/ItemCodes.js";
import ItemManager from "./ItemManager.js";
import _ from "lodash";

export default class MapItem extends Item
{
    constructor(strId, objDefinition)
    {
        super(strId, objDefinition);
    }

    /*******************************************
    *   META
    *******************************************/
    get Landmark() { return (this.definition.map && this.definition.map.landmark) ? this.definition.map.landmark : null; }
    
    /*******************************************
    *   VISUALS
    *******************************************/
    get AtlasId() { return this.definition.atlas; }
    get TextureId() { return this.definition.asset; }

    get LootTextureIds()
    {
        if ("map" in this.definition)
        {
            if ("loot" in this.definition.map)
            {
                if ("sprites" in this.definition.map.loot)
                {
                    return this.definition.map.loot.sprites;
                }

                return [this.TextureId];
            }
        }
        return null;
    }

    get MapIndex()
    { 
        if ("map" in this.definition)
        {
            if ("index" in this.definition.map)
            {
                return this.definition.map.index;
            }
        }
        return -1;
    }

    /*******************************************
    *   MATRIXES
    *******************************************/
    get WalkMatrix()
    { 
        if ("map" in this.definition)
        {
            if ("matrix" in this.definition.map)
            {
                if ("walk" in this.definition.map.matrix)
                {
                    //If this obstacle is swimable and the player can swim, then we switch
                    //all blocked tiles to walkable tiles by increasing the value by 1
                    if (this.IsSwimmable && ItemManager.instance.CanSwim)
                    {
                        if (!this.swimMatrix)
                        {
                            this.swimMatrix = [];
                            for (let y = 0; y < this.definition.map.matrix.walk.length; y++)
                            {
                                let row = [];
                                for (let x = 0; x < this.definition.map.matrix.walk[y].length; x++)
                                {
                                    if (this.definition.map.matrix.walk[y][x] % 2 == 0)
                                    {
                                        row.push(this.definition.map.matrix.walk[y][x] + 1);
                                    }
                                    else
                                    {
                                        row.push(this.definition.map.matrix.walk[y][x]);
                                    }
                                }
                                this.swimMatrix.push(row);
                            }
                        }
                        return this.swimMatrix;
                    }

                    //Otherwise the original walking matrix is used
                    return this.definition.map.matrix.walk;
                }
            }
        }
        return null;
    }

    get ClickMatrix()
    { 
        if ("map" in this.definition)
        {
            if ("matrix" in this.definition.map)
            {
                if ("click" in this.definition.map.matrix)
                {
                    //If this obstacle is breakable and the player has the right power, then we switch
                    //all blocked tiles to clickable tiles by increasing the value by 1
                    if (this.IsBreakable && ItemManager.instance.CanPush)
                    {
                        if (!this.breakMatrix)
                        {
                            this.breakMatrix = [];
                            for (let y = 0; y < this.definition.map.matrix.click.length; y++)
                            {
                                let row = [];
                                for (let x = 0; x < this.definition.map.matrix.click[y].length; x++)
                                {
                                    if (this.definition.map.matrix.click[y][x] % 2 == 0)
                                    {
                                        row.push(this.definition.map.matrix.click[y][x] + 1);
                                    }
                                    else
                                    {
                                        row.push(this.definition.map.matrix.click[y][x]);
                                    }
                                }
                                this.breakMatrix.push(row);
                            }
                        }
                        return this.breakMatrix;
                    }

                    //Otherwise the original clicking matrix is used
                    return this.definition.map.matrix.click;
                }
            }
        }
        return null;
    }

    /*******************************************
    *   LOOTING
    *******************************************/
    get DespawnOnLoot()
    { 
        if ("map" in this.definition)
        {
            if ("loot" in this.definition.map)
            {
                if ("despawnOnLoot" in this.definition.map.loot)
                {
                    return this.definition.map.loot.despawnOnLoot;
                }
            }
        }
        return true;
    }

    get HideOnLoot()
    { 
        if ("map" in this.definition)
        {
            if ("loot" in this.definition.map)
            {
                if ("hideOnLoot" in this.definition.map.loot)
                {
                    return this.definition.map.loot.hideOnLoot;
                }
            }
        }
        return true;
    }

    get LootItemList()
    {
        if ("map" in this.definition)
        {
            if ("loot" in this.definition.map)
            {
                if ("loot" in this.definition.map.loot)
                {
                    if ("list" in this.definition.map.loot.loot)
                    {
                        return this.definition.map.loot.loot.list;
                    }
                }
            }
        }
        return null;
    }

    get LootAmountMin()
    {
        if ("map" in this.definition)
        {
            if ("loot" in this.definition.map)
            {
                if ("loot" in this.definition.map.loot)
                {
                    if ("min" in this.definition.map.loot.loot)
                    {
                        return parseInt(this.definition.map.loot.loot.min);
                    }
                }
            }
        }
        return null;
    }

    get LootAmountMax()
    {
        if ("map" in this.definition)
        {
            if ("loot" in this.definition.map)
            {
                if ("loot" in this.definition.map.loot)
                {
                    if ("max" in this.definition.map.loot.loot)
                    {
                        return parseInt(this.definition.map.loot.loot.max);
                    }
                }
            }
        }
        return null;
    }

    get LootDrops()
    {
        let list = this.LootItemList;
        let min = this.LootAmountMin != undefined ? this.LootAmountMin : 1;
        let max = this.LootAmountMax != undefined ? this.LootAmountMax : this.LootAmountMin;

        let lootList = [];

        if (list !== null && min !== null && max !== null)
        {
            let rand = Math.random();
            let amount = Math.round((max - min) * rand + min);



            let item = null;
            let chanceCt = 0;
            let totalChance = 0;

            for (let id in list)
            {
                let currentChance = parseFloat(list[id].chances)
                totalChance += currentChance;
            }

            let itemCount = Object.keys(list).length;

            for (let id in list)
            {
                chanceCt = parseFloat(list[id].chances);
                rand = Math.random();
                if (chanceCt / totalChance >= rand)
                {
                    lootList.push({id})
                    // item = id;
                    // break;
                }
            }

            //We have not found anything, so we choose one randomly
            if (lootList.length < 1)
            {
                let arrKeys = Object.keys(list);

                let iRand = _.random(0, arrKeys.length - 1);
                let strRandKey = arrKeys[iRand];

                lootList.push({id:strRandKey});

            }

            //we distribute the total amount amongst item given

            //example, we have an amount of 5 and two objects to give, we will give 3 of each
            for (let i = 0; i < lootList.length; i++)
            {
                let itemObj = lootList[i];
                itemObj.qty = Math.ceil(amount / lootList.length);
            }


            if (lootList.length > 0)
            {
                return lootList;
            }
        }

        return null;
    }

    /*******************************************
    *   TRAP
    *******************************************/
    get IsTrap()
    {
        if ("map" in this.definition)
        {
            if ("loot" in this.definition.map)
            {
                return ("trap" in this.definition.map.loot);
            }
        }
        return false;
    }

    get TrapNear()
    {
        if ("map" in this.definition)
        {
            if ("loot" in this.definition.map)
            {
                if ("trap" in this.definition.map.loot)
                {
                    if ("near" in this.definition.map.loot.trap)
                    {
                        return this.definition.map.loot.trap.near;
                    }
                }
            }
        }
        return null;
    }

    get TrapLootBack()
    {
        if ("map" in this.definition)
        {
            if ("loot" in this.definition.map)
            {
                if ("trap" in this.definition.map.loot)
                {
                    if ("lootBack" in this.definition.map.loot.trap)
                    {
                        return this.definition.map.loot.trap.lootBack;
                    }
                }
            }
        }
        return null;
    }

    get TrapMinTime()
    {
        if ("map" in this.definition)
        {
            if ("loot" in this.definition.map)
            {
                if ("trap" in this.definition.map.loot)
                {
                    if ("minTime" in this.definition.map.loot.trap)
                    {
                        return parseFloat(this.definition.map.loot.trap.minTime);
                    }
                }
            }
        }
        return null;
    }

    /*******************************************
    *   ACTIONS
    *******************************************/
    get MapActionPower()
    {
        if ("map" in this.definition)
        {
            if ("action" in this.definition.map)
            {
                if ("power" in this.definition.map.action)
                {
                    return this.definition.map.action.power;
                }
            }
        }
        return null;
    }

    get MapActionResult()
    {
        if ("map" in this.definition)
        {
            if ("action" in this.definition.map)
            {
                if ("result" in this.definition.map.action)
                {
                    return this.definition.map.action.result;
                }
            }
        }
        return null;
    }

    /*******************************************
    *   STATES
    *******************************************/
    get IsSpawnable() { return ("map" in this.definition) && (this.AtlasId || this.TextureId) ? true : false; }
    get IsObstacle()
    {
        return (this.definition.map &&
               this.definition.map.loot || (
                   !this.definition.food && 
                   !this.definition.equip &&
                   !this.definition.keyItem &&
                   !this.definition.mutation &&
                   !this.definition.material
                )) ? true : false;
    }

    get IsLootable() 
    {
        return (this.definition.map &&
               (
                   this.definition.map.loot ||
                   this.definition.food || 
                   this.definition.equip ||
                   this.definition.keyItem ||
                   this.definition.material
               )) ? true : false;
    }

    get IsSwimmable()
    {
        return  this.MapActionPower && this.MapActionPower.toUpperCase() == "SWIM" &&
                this.MapActionResult && this.MapActionResult.toUpperCase() == "MOVE";
    }

    get IsBreakable()
    {
        return  this.MapActionPower && this.MapActionPower.toUpperCase() == "PUSH" &&
                this.MapActionResult && this.MapActionResult.toUpperCase() == "BREAK";
    }

    shouldSpawn(strTileId)
    {
        if (strTileId === 128253)
        {
            let a= 1;
        }
        if (this.IsObstacle)
        {
            let state = null;
            if (ItemManager.instance.WorldManager.Environment.IsIndoor)
            {
                state = ItemManager.instance.WorldManager.getObstacleState(
                    strTileId,
                    ItemManager.instance.WorldManager.Environment.Id,
                    ItemManager.instance.WorldManager.Environment.RoomId
                );
            }
            else
            {
                state = ItemManager.instance.WorldManager.getObstacleState(strTileId);
            }
            if (!state || !state.broken)
            {
                let next = (state ? state.pickup.next : null);

                let now = new Date().getTime();
                let nextIsInThePast = now >= next;

                let gotLootNode = "map" in this.definition && "loot" in this.definition.map;
                 if(!gotLootNode && !nextIsInThePast)
                     return false;

                if (next !== null && gotLootNode)
                {
                    let dontDespawn = !this.DespawnOnLoot;
                    let dontHideOnLoot = !this.HideOnLoot;

                    return dontDespawn || dontHideOnLoot || nextIsInThePast;
                }
            }
            else
            {
                return false;
            }
        }
        else if (this.IsLootable)
        {
            let content = null;

            if (ItemManager.instance.WorldManager.Environment.IsIndoor)
            {
                content = ItemManager.instance.WorldManager.getCellContent(
                    strTileId,
                    ItemManager.instance.WorldManager.Environment.Id,
                    ItemManager.instance.WorldManager.Environment.RoomId
                );
            }
            else
            {
                content = ItemManager.instance.WorldManager.getCellContent(strTileId);
            }
            if (!content)
            {
                return false;
            }
        }
        return true;
    }

    getNextRefill(iLastPickupTime)
    {
        if (iLastPickupTime && "map" in this.definition && "loot" in this.definition.map && "refillTime" in this.definition.map.loot)
        {
            if (parseInt(this.definition.map.loot.refillTime.min) <= 0 || parseInt(this.definition.map.loot.refillTime.max) <= 0)
            {
                return Number.MAX_SAFE_INTEGER;
            }
            let range = this.definition.map.loot.refillTime.max - this.definition.map.loot.refillTime.min;
            let newValue = Math.round(range * Math.random());

            return newValue + this.definition.map.loot.refillTime.min + iLastPickupTime;
        }
        return Number.MAX_SAFE_INTEGER;
        //return iLastPickupTime;
    }
}