import {MeshBasicMaterial} from "three";
import {PlaneGeometry} from "three";
import {Vector2} from "three";
import {Vector3} from "three";
import AnimationController from "./animations/AnimationController.js";
import Character from "./Character.js";
import Constants from "../../utils/Constants.js";
import Direction from "../../utils/Direction.js";
import Library from "../../Library.js";
import PlayerAction from "./actions/PlayerAction.js";
import PlayerControls from "../controls/PlayerControls.js";

export default class Player extends Character
{
    constructor()
    {
        super();
    }

    get SPRITESHEET_COLUMN_COUNT() { return 4; }
    get INVINCIBILITY_TIME_AFTER_STUN() { return this.CharacterManager.InvincibilityTime; }

    //--------------------------------------------
    //  SPRITESHEET ANIMATIONS
    //--------------------------------------------
    get ANIMATION_ANTISTRESS() { return "antistress"; }
    get ANIMATION_MUSIC() { return "music"; }
    get ANIMATION_DOLL() { return "doll"; }
    get ANIMATION_FISHING() { return "fishing"; }

    get ANIMATION_IDLE() { return "idle"; }

    get ANIMATION_PUSH() { return "push"; }
    get ANIMATION_RUN() { return "run"; }
    get ANIMATION_SPIT() { return "spit"; }
    get ANIMATION_STOMP() { return "stomp"; }
    get ANIMATION_STRESSBALL() { return "stressball"; }
    get ANIMATION_SWIMMING() { return "swimming"; }
    get ANIMATION_USE() { return "use"; }
    //--------------------------------------------

    get PlayerControls() { return this.playerControls; }
    get AnimationController() { return this.animationController; }
    get Focus() { return this.meta.focus; }
    get PlaneSize() { return this.planeSize; }
    get StunTime() { return this.CharacterManager.HitStunTime; }
    get StompStunDistance() { return this.GameManager.getSetting("character").mutations.powers.stomp.stunDistance; }
    get StompStunTime() { return this.GameManager.getSetting("character").mutations.powers.stomp.stunTime; }
    get StompShakeTime() { return this.GameManager.getSetting("character").mutations.powers.stomp.shakeTime; }
    get SpitStunDistance() { return this.GameManager.getSetting("character").mutations.powers.spit.stunDistance; }
    get SpitStunTime() { return this.GameManager.getSetting("character").mutations.powers.spit.stunTime; }
    get SpitOffset() { return new Vector3(0, 0, -this.PlaneSize.height * 0.1); }
    get PushShakeTime() { return this.GameManager.getSetting("character").mutations.powers.push.shakeTime; }
    get InvisibilityTime() { return this.ItemManager.MutationHead.MutationPower.ActiveDuration; }
    get IsDead()
    {
        if (this.WorldManager.IsHostile)
        {
            let stat = this.CharacterManager.getCharacterEnergy();
            let statDeath = this.CharacterManager.getCharacterEnergyDeath();

            if (stat > statDeath)
            {
                stat = this.CharacterManager.getCharacterHunger();
                statDeath = this.CharacterManager.getCharacterHungerDeath();

                if (stat > statDeath)
                {
                    stat = this.CharacterManager.getCharacterStress();
                    statDeath = this.CharacterManager.getCharacterStressDeath();

                    return stat >= statDeath;
                }
            }
            return true;
        }
        return false;
    }
    get IsSprinting() { return this.isSprinting; }
    get IsSwimming() { return this.isSwimming; }
    get IsAntiStress() { return this.isAntiStress; }
    get IsFishing() { return this.isFishing; }

    get MovementSpeed() 
    {
        let multiplier = 1;
        if (this.IsSprinting)
        {
            multiplier = this.CharacterManager.SprintSpeedRatio;
        }

        let leg = this.ItemManager.MutationLeg;

        if (leg && leg.WalkSpeedMultiplier)
        {
            multiplier *= leg.WalkSpeedMultiplier;
        }

        let feet  = this.ItemManager.FeetEquipment;

        if (feet && feet.definition.multiplier)
        {
            multiplier += feet.definition.multiplier;
        }

        return super.MovementSpeed * multiplier;
    }

    get TrapObstacle()
    {
        if (this.ItemManager.CanTrap)
        {
            return this.ItemManager.ToolEquipment.TrapObstacle;
        }
        return null;
    }

    /*******************************************
    *   INITIALIZATION
    *******************************************/
    /**
        Parameters to pass to the init function:
        - id:           Logic id of this object instance. Usually managed by some sort of global manager
        - visualCode    String representing the visual structure of this object. Used to determine colors, clothing and mutations (ex. 100222_5002)
        - spawnPos      Vector2 containing the world position to spawn this object in ThreeJS
        - direction     Initial direction where the player will look at. Use the Direction class at utils/Direction.js
        - environment   Environment instance where the player lives
        - focus         If the focus is on this player object. Used when there is multiple players on screen (ex. in the Barn) and we need to know which one to use
        - params        JSON object containing additionnal parameters to pass to the player

    */
    init(meta)
    {
        meta.type = "player";

        meta.atlasName = (!meta.atlasName ? meta.visualCode + "_" + "front_idle" : meta.atlasName);
        meta.textureName = (!meta.textureName ? undefined : meta.textureName);

        this.isSwimming = false;

        super.init(meta);
        this.baseMovementSpeed = this.GameManager.getSetting("map").walkspeed.player;

        this.createPlayerControls();
    }

    createClosure()
    {
        super.createClosure();
        this.fctOnSprintStart = this.onSprintStart.bind(this);
        this.fctOnSprintCooldown = this.onSprintCooldown.bind(this);
    }

    bindEvents()
    {
        super.bindEvents();

        this.CharacterManager.on(this.CharacterManager.EVENT_SPRINT_START, this.fctOnSprintStart);
        this.CharacterManager.on(this.CharacterManager.EVENT_SPRINT_COOLDOWN, this.fctOnSprintCooldown);
    }

    destroy()
    {
        this.CharacterManager.off(this.CharacterManager.EVENT_SPRINT_START, this.fctOnSprintStart);
        this.CharacterManager.off(this.CharacterManager.EVENT_SPRINT_COOLDOWN, this.fctOnSprintCooldown);

        this.playerControls.destroy();
        super.destroy();
    }

    createCharacterAction()
    {
        this.characterAction = new PlayerAction(this);
    }

    createGeometry(objSize)
    {
        let divider = this.ResponsiveManager.AssetDivider * 0.85;

        //@TODO: We should do something about this instead of hardcoding
        //------------------------------------------------------
        let colCount = this.SPRITESHEET_COLUMN_COUNT;
        let rowCount = Math.ceil(
            Library.getData("animation_atlas")["player"]["idle"][Direction.getAtlasName(Direction.South)][0].frameCount / colCount
        );
        //------------------------------------------------------

        this.planeSize = {
            "width": objSize.w / divider / colCount,
            "height": objSize.h / divider / rowCount
        };

        let geometry = new PlaneGeometry(this.planeSize.width, this.planeSize.height, 10, 10);
        geometry.translate(0, objSize.h / divider / rowCount / 2, 0);
        geometry.verticesNeedUpdate = true;

        return geometry;
    }

    createAnimationController()
    {
        this.animationController = new AnimationController();
        this.animationController.init({
            "atlasId":        "player",
            "mesh":           this.mesh,
            "characterBuild": this.meta.visualCode
        });
    }

    createPlayerControls()
    {
        this.playerControls = new PlayerControls(this);
        this.playerControls.init();
    }

    /*******************************************
    *   UPDATE LOOP
    *******************************************/
    update(fDeltaTime)
    {
        super.update(fDeltaTime);
        this.updatePlayerControls(fDeltaTime);
        this.updateDeath(fDeltaTime);
    }

    updatePlayerControls(fDeltaTime)
    {
        if (this.playerControls)
        {
            this.playerControls.update(fDeltaTime);
        }
    }

    updateDeath(fDeltaTime)
    {
        if (!this.hasDied && this.IsDead)
        {
            this.die();
        }
    }

    /*******************************************
    *   ACTIONS
    *******************************************/
    pickup(objObstacle)
    {
        return this.CharacterAction.pickup(objObstacle);
    }

    stomp()
    {
        return this.CharacterAction.stomp();
    }

    getHit(iEnergyLossRatio)
    {
        return this.CharacterAction.getHit(iEnergyLossRatio);
    }

    startSwimming()
    {
        return this.CharacterAction.startSwimming();
    }

    stopSwimming()
    {
        return this.CharacterAction.stopSwimming();
    }

    spit()
    {
        return this.CharacterAction.spit();
    }

    break(objObstacle)
    {
        return this.CharacterAction.break(objObstacle);
    }

    teleport()
    {
        return this.CharacterAction.teleport();
    }

    putTrap()
    {
        return this.CharacterAction.putTrap();
    }

    startAntiStress()
    {
        return this.CharacterAction.startAntiStress();
    }

    stopAntiStress()
    {
        return this.CharacterAction.stopAntiStress();
    }

    startFishing()
    {
        return this.CharacterAction.startFishing();
    }

    stopFishing()
    {
        return this.CharacterAction.stopFishing();
    }

    renderInvisible()
    {
        if (this.CharacterManager.usePower(this.CharacterManager.POWER_INVISIBLE))
        {
            if (this.IsAntiStress)
            {
                this.stopAntiStress();
            }
            super.renderInvisible(this.InvisibilityTime);
            this.AudioManager.playSfx("invisibilite");

            return true;
        }
        return false;
    }

    preventMovement()
    {
        super.preventMovement();
        this.PlayerControls.CanMove = false;
    }

    enableMovement()
    {
        super.enableMovement();
        this.PlayerControls.CanMove = true;
    }

    /*******************************************
    *   DEATH
    *******************************************/
    die()
    {
        this.hasDied = true;

        this.stopMovement();
        this.preventMovement();

        this.dropBackpackOnGround();

        this.WorldManager.preventWorldClick();
        this.UIManager.showSceneTransition(() =>
        {
            this.WorldManager.allowWorldClick();
            this.WorldManager.changeEnvironment(this.WorldManager.ENVIRONMENT_BARN);
        });
    }

    dropBackpackOnGround()
    {
        let cell;

        if (this.WorldManager.IsForest)
        {
            let dropPos = this.Environment.getBackpackDropPosition(this);
            cell = this.WorldManager.Environment.Grid.getTileId(dropPos.x, dropPos.y);
        }
        else
        {
            cell = this.WorldManager.Environment.Scene.findExitPos();
            //cell = dropPos.y * 500 + parseInt(dropPos.x);
        }

        let items = this.ItemManager.getAllItems();
        let content = {};

        let count = 0;
        for(let key in items)
        {
            if (!content[items[key].item.Id])
            {
                content[items[key].item.Id] = 0
            }
            content[items[key].item.Id] += items[key].quantity;
            this.ItemManager.removeItemFromSlot(items[key].quantity, items[key].slot);

            count++;
        }

        if (count > 0)
        {
            /*if (this.WorldManager.Environment.IsIndoor)
            {
                this.WorldManager.setBackpackOnCell(
                    cell, 
                    content, 
                    Math.max(1, this.ItemManager.BackpackLevel),
                    this.WorldManager.Environment.Id,
                    this.WorldManager.Environment.RoomId
                );
            }
            else*/
            {
                this.WorldManager.setBackpackOnCell(cell, content, Math.max(1, this.ItemManager.BackpackLevel), this.WorldManager.ENVIRONMENT_FOREST);
            }
        }
    }

    /*******************************************
    *   EVENTS
    *******************************************/
    onCameraTileChanged(newPos)
    {
        if (this.PlayerControls && !this.IsDead)
        {
            this.PlayerControls.onCameraTileChanged(newPos);
        }   
    }

    onMoveStart()
    {
        if (!this.isPicking && !this.IsDead)
        {
            if (!this.IsSwimming)
            {
                this.CharacterAction.stopLoopingActions();
                super.onMoveStart();
            }
        }
    }

    onMoveEnd()
    {
        if (!this.isPicking)
        {
            if (!this.IsSwimming)
            {
                super.onMoveEnd();
            }
        }
    }

    onSprintStart()
    {
        this.isSprinting = true;
        this.CharacterAction.stopLoopingActions();
    }

    onSprintCooldown()
    {
        this.isSprinting = this.CharacterManager.SprintCooldown > 0;
    }
}