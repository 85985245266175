import {Vector2} from "three";
import Character from "../Character.js";
import Direction from "../../../utils/Direction.js";
import Lerp from "../../../utils/Lerp.js";

export default class CharacterAction
{
    constructor(character)
    {
        this.character = character;
        this.init();
    }

    get MOVEMENT_MIN_DIST() { return 0.75 * this.MovementSpeed; }

    //---------------------------------------------------------
    //  DEPENDENCIES
    //---------------------------------------------------------
    get AudioManager() { return this.Dependencies.get("AudioManager");}
    get CharacterManager() { return this.Dependencies.get("CharacterManager");}
    get ItemManager() { return this.Dependencies.get("ItemManager");}
    get GameManager() { return this.Dependencies.get("GameManager");}
    get WorldManager() { return this.Dependencies.get("WorldManager");}
    //---------------------------------------------------------

    get Dependencies() { return this.character.Dependencies; }
    get Character() { return this.character; }
    get Mesh() { return this.Character.Mesh; }
    get Environment() { return this.Character.Environment; }
    get Grid() { return this.Character.Grid; }
    get Direction() { return this.Character.Direction; }
    get MovementSpeed() { return this.Character.MovementSpeed; }
    get IsMoving() { return this.movement.toReach || (this.movement.path && this.movement.path.length > 0); }
    get CurrentPath() { return this.movement.path; }
    get TileToReach() { return this.movement.toReach; }

    /*******************************************
    *   INITIALIZATION
    *******************************************/
    init()
    {
        this.movement = {
            path: null,
            toReach: null,
            callback: null,
            target: null
        };
    }

    destroy()
    {
        
    }

    /*******************************************
    *   UPDATE LOOP
    *******************************************/
    update(deltaTime)
    {
        if (!this.GameManager.IsPaused)
        {
            this.updateMovement(deltaTime);
        }
    }

    updateMovement(deltaTime)
    {
        if (this.Mesh && this.Environment)
        {
            if (!this.movement.toReach && this.movement.path)
            {
                if (this.movement.path && this.movement.path.length > 0)
                {
                    //We recalculate the path on every grid cell change to be sure no new obstacles are in the way
                    let target = this.movement.target;
                    if (target)
                    {
                        this.movement.target = null;

                        this.goTo(target.x, target.y, null, false, this.movement.callback);
                    }
                    else
                    {
                        this.reachDestination();
                    }
                }
            }
            
            if (this.movement.toReach)
            {
                let direction = new Vector2(this.movement.toReach.x - this.Mesh.position.x, this.movement.toReach.y - this.Mesh.position.z);
                direction.normalize();

                let minDist = Math.pow(this.MOVEMENT_MIN_DIST, 2);
                let sqrDist = Math.pow(this.Mesh.position.x - this.movement.toReach.x, 2) + Math.pow(this.Mesh.position.z - this.movement.toReach.y, 2);
                if (sqrDist <= minDist)
                {
                    this.Mesh.position.x = this.movement.toReach.x;
                    this.Mesh.position.z = this.movement.toReach.y;
                    this.movement.toReach = null;

                    if (!this.movement.path || this.movement.path.length == 0)
                    {
                        this.reachDestination();
                    }

                }
                else
                {
                    this.Mesh.position.x += this.MovementSpeed * direction.x;
                    this.Mesh.position.z += this.MovementSpeed * direction.y;
                }

            }
        }
    }

    updateDirection(bIsNewMovement = false)
    {
        let newDirection = this.calculateDirection(
            this.Character.GridPos, 
            this.Grid.worldToGridPosition(this.movement.toReach.x, this.movement.toReach.y)
        );

        if (newDirection != this.Direction)
        {
            this.Character.changeDirection(newDirection);
        }
    }

    /*******************************************
    *   MOVEMENT
    *******************************************/
    /**
        Goes to a position in the ThreeJS environment. The position should be set in ThreeJS world units, not grid units.
    */
    goTo(x, y, arrMeshesToIgnore = null, bSkipDiagonals = false, fctCallback = null)
    {
        if (!this.movement.target || this.movement.target.x != x || this.movement.target.y != y)
        {
            let isNewMovement = !this.movement.path;

            let newPos = this.Grid.worldToGridPosition(x, y);
            let currentPos = this.Grid.worldToGridPosition(Math.round(this.Mesh.position.x), Math.round(this.Mesh.position.z));

            newPos = this.Grid.getNearestWalkableTile(
                newPos.x,
                newPos.y,
                currentPos.x,
                currentPos.y,
                true,
                arrMeshesToIgnore,
                this.Character.Type != "player" || !this.CharacterManager.CanSwim
            );

            if (newPos)
            {
                let path = this.Grid.calculatePath(
                    currentPos.x,
                    currentPos.y,
                    newPos.x,
                    newPos.y,
                    30,
                    30,
                    arrMeshesToIgnore,
                    bSkipDiagonals,
                    this.Character.Type != "player" || !this.CharacterManager.CanSwim
                );

                if (path && path.length > 1)
                {
                    let last = path[path.length - 1];
                    path.shift();

                    if (this.movement.path)
                    {
                        path = this.mergePaths(this.movement.path, path, arrMeshesToIgnore, bSkipDiagonals);
                    }

                    //this.movement.target = new Vector2(x, y);
                    if (this.WorldManager.Environment.Id === this.WorldManager.ENVIRONMENT_FOREST)
                    {
                        this.movement.target = new Vector2(x, y);
                    }
                    else
                    {
                        this.movement.target = new Vector2(x, y);
                    }


                    this.movement.toReach = path.shift();
                    this.movement.toReach = this.Grid.gridToWorldPosition(this.movement.toReach[0], this.movement.toReach[1]);

                    this.movement.path = path;
                    this.movement.callback = fctCallback;

                    this.updateDirection(isNewMovement);

                    if (isNewMovement)
                    {
                        this.Character.emit(this.Character.EVENT_MOVEMENT_START, this);
                    }
                    else
                    {
                        this.Character.emit(this.Character.EVENT_MOVEMENT_TILE_CHANGED, this);
                    }

                    if (!this.AudioManager.isSfxPlaying("pas_herbe"))
                    {
                        this.AudioManager.playSfx("pas_herbe");
                    }

                    return this.Grid.gridToWorldPosition(last[0], last[1]);
                }
            }
        }
        else
        {
            if (fctCallback)
            {
                fctCallback();
            }
        }
        return null;
    }

    /**
        Stops the current movement
    */
    stop()
    {
        let shouldEmit = this.IsMoving;

        let fctCallback = this.movement.callback;

        this.movement.toReach = null;
        this.movement.path = null;
        this.movement.callback = null;
        this.movement.target = null;

        if (this.AudioManager.isSfxPlaying("pas_herbe"))
        {
            this.AudioManager.stopSfx(null, "pas_herbe", 0.25);
        }

        if (shouldEmit)
        {
            this.Character.emit(this.Character.EVENT_MOVEMENT_END, this);
        }

        if (fctCallback)
        {
            fctCallback();
        }
    }

    calculateDirection(from, to)
    {
        let newDirection = this.Character.Direction;
        let diff = new Vector2(from.x - to.x, from.y - to.y);

        if (diff.x != 0 || diff.y != 0)
        {
            if (diff.x <= -1) { newDirection = Direction.East; }
            else if (diff.x >= 1) { newDirection = Direction.West; }
            else if (diff.y >= 1) { newDirection = Direction.North; }
            else { newDirection = Direction.South; }
        }

        return newDirection;
    }

    reachDestination()
    {
        let fctCallback = this.movement.callback;
        this.movement.path = null;
        this.movement.callback = null;
        this.movement.target = null;
        
        this.Character.emit(this.Character.EVENT_MOVEMENT_END, this);

        if (this.AudioManager.isSfxPlaying("pas_herbe"))
        {
            this.AudioManager.stopSfx(null, "pas_herbe", 0.25);
        }


        if (fctCallback)
        {
            fctCallback();
        }
    }

    mergePaths(arrOldPath, arrNewPath, arrMeshesToIgnore = null, bSkipDiagonals = false)
    {
        let indexOld = -1;
        let indexNew = -1;
        for (let i = arrOldPath.length - 1; i >= 0; i--)
        {
            for (let j = 0; j < arrNewPath.length; j++)
            {
                if (arrNewPath[j][0] == arrOldPath[i][0] && arrNewPath[j][1] == arrOldPath[i][1])
                {
                    indexOld = i;
                    indexNew = j;
                    break;
                }
            }
        }

        if (indexOld > 0 && indexNew > 0)
        {
            let mergedPath = [];
            for (let i = 0; i <= indexOld; i++)
            {
                mergedPath.push(arrOldPath[i]);
            }
            for (let i = indexNew + 1; i < arrNewPath.length; i++)
            {
                mergedPath.push(arrNewPath[i]);
            }

            let preventSwim = this.Character.Type != "player" || !this.CharacterManager.CanSwim;
            if (this.Grid.validatePath(mergedPath, arrMeshesToIgnore, bSkipDiagonals, preventSwim))
            {
                return mergedPath;
            }
        }

        return arrNewPath;
    }
}