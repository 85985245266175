import EventEmitter from "eventemitter3";
import Audio from "./cheats/Audio.js";
import Antistress from "./cheats/Antistress.js";
import Barn from "./cheats/Barn.js";
import ChoiceDialog from "./cheats/ChoiceDialog.js";
import Creator from "./cheats/Creator.js";
import Dialog from "./cheats/Dialog.js";
import Energy from "./cheats/Energy.js";
import Hunger from "./cheats/Hunger.js";
import ItemAdd from "./cheats/ItemAdd.js";
import ItemEquip from "./cheats/ItemEquip.js";
import Minigame from "./cheats/Minigame.js";
import Power from "./cheats/Power.js";
import Save from "./cheats/Save.js";
import Stat from "./cheats/Stat.js";
import Stress from "./cheats/Stress.js";
import Time from "./cheats/Time.js";
import ToolUse from "./cheats/ToolUse.js";
import ForceTrigger from "./cheats/ForceTrigger.js";
import WalkTo from "./cheats/WalkTo.js";
import TriggerValidator from "./cheats/TriggerValidator.js";
import IMOK from "./cheats/IMOK.js";
import Teleport from "./cheats/Teleport.js";

export default class CheatManager extends EventEmitter
{
    constructor()
    {
        super();

        CheatManager.instance = this;
    }

    /*******************************************
    *   INITIALIZATION
    *******************************************/
    init(dependencies)
    {
        this.dependencies = dependencies;

        this.isConsoleShown = false;
        this.hasProcessedInput = false;

        this.createClosure();
        this.bindEvents();


        this.createCheatList();
        this.createConsole();
        this.displayManual();
    }

    createClosure()
    {
        this.fctKeyUp = this.onKeyUp.bind(this);
    }

    bindEvents()
    {
        window.addEventListener("keyup", this.fctKeyUp);
    }

    createCheatList()
    {
        this.cheats = [
            new Audio(this.dependencies),
            new Antistress(this.dependencies),
            new Barn(this.dependencies),
            new ChoiceDialog(this.dependencies),
            new Creator(this.dependencies),
            new Dialog(this.dependencies),
            new Energy(this.dependencies),
            new Hunger(this.dependencies),
            new ItemAdd(this.dependencies),
            new ItemEquip(this.dependencies),
            new Minigame(this.dependencies),
            new Power(this.dependencies),
            new Save(this.dependencies),
            new Stat(this.dependencies),
            new Stress(this.dependencies),
            new Time(this.dependencies),
            new ToolUse(this.dependencies),
            new ForceTrigger(this.dependencies),
            new WalkTo(this.dependencies),
            new Teleport(this.dependencies),
            new TriggerValidator(this.dependencies),
            new IMOK(this.dependencies)
        ];
    }

    createConsole()
    {
        let input = document.createElement("DIV");
        input.style.position = "absolute";
        input.style.zIndex = 9999;
        input.style.bottom = "10px";
        input.style.right = "10px";
        input.style.display = "none";

        let text = document.createElement("INPUT");
        text.setAttribute("type", "text");
        text.setAttribute("value", "");
        text.style.width = "300px";
        text.style.height = "24px";
        input.appendChild(text);

        let cmdButton = document.createElement("INPUT");
        cmdButton.setAttribute("type", "button");
        cmdButton.value = "Ok";
        cmdButton.style.width = "80px";
        cmdButton.style.height = "30px";
        cmdButton.style.marginLeft = "5px";
        cmdButton.addEventListener("click", this.onCmdInput.bind(this));
        input.appendChild(cmdButton);

        let trigger = document.createElement("BUTTON");
        trigger.style.position = "absolute";
        trigger.style.zIndex = 9999;
        trigger.style.bottom = "10px";
        trigger.style.right = "10px";
        trigger.style.height = "30px";
        trigger.style.display = "inline";
        trigger.innerHTML = "Cheat";
        trigger.addEventListener("click", this.displayConsole.bind(this, true));
        //document.body.appendChild(trigger);

        this.cmdText = text;
        this.cmdInput = input;
        this.btnTrigger = trigger;

        document.body.appendChild(input);
    }

    displayManual()
    {
        console.log("#################################################################");
        console.log("                                                                       ");
        console.log("%c   CHEAT CONSOLE                       ", 'font-style:italic;font-size:1.25em');
        console.log("                                                                       ");
        console.log("#################################################################");
        console.log ("    Use SHIFT + C to open the cheat console");

        for (let i = 0; i < this.cheats.length; i++)
        {
            console.log("-----------------------------------------------------------------");

            let cheat = this.cheats[i];
            console.log("%c    " + cheat.CommandUse, "font-weight:bold");
            console.log("       " + cheat.CommandDesc);
            
        }
        console.log("#################################################################");
    }

    displayConsole(bIsOpen)
    {
        this.isConsoleShown = bIsOpen;
        this.btnTrigger.style.display = (bIsOpen ? "none" : "inline");
        this.cmdText.value = "";
        this.cmdInput.style.display = (bIsOpen ? "block" : "none");
        if (bIsOpen)
        {
            this.cmdText.focus();
        }
    }

    /*******************************************
    *   EVENTS
    *******************************************/
    onKeyUp(e)
    {
        if (!this.hasProcessedInput)
        {
            this.hasProcessedInput = true;

            if (e.key == "C")
            {
                this.displayConsole(true);
            }
            else if (e.key == "Enter" && this.isConsoleShown)
            {
                this.onCmdInput();
            }
            else if (e.key == "Escape" && this.isConsoleShown)
            {
                this.displayConsole(false);
            }

            setTimeout(function(){ this.hasProcessedInput = false; }.bind(this), 100);
        }
    }

    onCmdInput()
    {
        let cmd = this.cmdText.value;
        if (cmd)
        {
            let cmdSplit = cmd.split(" ");
            let cmdName = cmdSplit.shift().toLowerCase();

            for (let i = 0; i < this.cheats.length; i++)
            {
                if (this.cheats[i].parseCommand(cmdName, cmdSplit))
                {
                    break;
                }
            }
        }
        this.cmdText.value = "";

        this.displayConsole(false);
    }
}