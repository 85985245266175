import _ from "lodash";
import { Container } from '@pixi/display';
import { Point } from '@pixi/math';
import Display from "../utils/Display.js";
import Responsive from "./commons/responsive/Responsive.js";

export default class BaseUI extends Container
{
    constructor()
    {
        super();
    }

    //---------------------------------------------------------
    //  DEPENDENCIES
    //---------------------------------------------------------
    get AnimationsCreator() { return this.dependencies.get("AnimationsCreator"); }
    get AudioManager() { return this.dependencies.get("AudioManager"); }
    get CharacterManager() { return this.dependencies.get("CharacterManager"); }
    get GameManager() { return this.dependencies.get("GameManager"); }
    get ItemManager() { return this.dependencies.get("ItemManager"); }
    get LabelManager() { return this.dependencies.get("LabelManager"); }
    get ResponsiveManager() { return this.dependencies.get("ResponsiveManager"); }
    get SaveManager() { return this.dependencies.get("SaveManager"); }
    get UIManager() { return this.dependencies.get("UIManager"); }
    get WorldManager() { return this.dependencies.get("WorldManager"); }
    //---------------------------------------------------------

    get Id() { return "default"; }
    get Values() { return this.UIManager.UIValues; }
    get Size() { return Display.getSize(); }

    get rButtonWidth() { return "((ih / 1080) * 94)"; }
    get rButtonHeight() { return "((ih / 1080) * 94)"; }

    /*******************************************
    *   INITIALIZATION
    *******************************************/
    /**
        Parameters to pass to the init function:
        - dependencies:     Dependency injector object
    */
    init(meta)
    {
        this.arrLayoutables = [];
        this.arrMouseEating = [];
        this.dependencies = meta.dependencies;
        this.responsive = new Responsive().init({"ui": this});

        this.createClosure();
        this.bindEvents();

        this.build();

        return this;
    }

    createClosure ()
    {
        this.fctUpdateBase = this.update.bind(this);
    }

    bindEvents()
    {
        this.GameManager.on(this.GameManager.EVENT_UPDATE, this.fctUpdateBase);
    }

    destroy(options)
    {
        this.GameManager.off(this.GameManager.EVENT_UPDATE, this.fctUpdateBase);
        super.destroy(options);
    }

    /*******************************************
    *   UPDATE LOOP
    *******************************************/
    update(fDeltaTime)
    {

    }

    /*******************************************
    *   BUILD
    *******************************************/
    build ()
    {
        
    }

    /*******************************************
    *   RESPONSIVE
    *******************************************/
    evaluate(expr, width, height, x, y, self, el)
    {
        return this.responsive.evaluate(expr, width, height, x, y, self, );
    }

    valueFormula(strValue)
    {
        return "((" + strValue + ") * (ih/1080))";
    }

    layout (objSize)
    {
        let iCount = this.arrLayoutables.length;

        for (let i = 0; i < iCount; i++)
        {
            let layoutable = this.arrLayoutables[i];

            if (layoutable.layout)
            {
                layoutable.layout(objSize);
            }
            else
            {
                console.error("Missing layout method on", layoutable);
            }
        }
    }

    addToLayoutable (container, iIndex = -1)
    {
        this.arrLayoutables.push(container);
        if (iIndex >= 0)
        {
            this.addChildAt(container, iIndex);
        }
        else
        {
            this.addChild(container);
        }
    }
    
    removeFromLayoutable (container)
    {
        _.remove(this.arrLayoutables, (l) => l === container);
        this.removeChild(container);
    }

    /*******************************************
    *   UTILITIES
    *******************************************/
    addMouseEating(objComponent)
    {
        this.arrMouseEating.push(objComponent);
    }

    removeMouseEating(objComponent)
    {
        _.remove(this.arrMouseEating, (l) => l === objComponent);
    }

    canWorldClick(x, y)
    {
        for (let i = 0; i < this.arrMouseEating.length; i++)
        {
            let sp = this.arrMouseEating[i];

            if (!sp._destroyed)
            {
                if (sp.parent !== undefined)
                {
                    if (sp.containsPoint)
                    {
                        let ptMouse = new Point(x, y);
                        let isOver = sp.containsPoint(ptMouse);

                        if (isOver)
                        {
                            return false;
                        }
                    }
                    else
                    {
                        console.warn("The containsPoint methods should be implemented to validate this object", sp);
                    }
                }
            }
        }
        return true;
    }

    showSceneTransition(fctCallback)
    {
        fctCallback();
    }

    getMutationIcon(strMutation)
    {
        if (strMutation == "raccoon")
        {
            strMutation = "racoon";
        }
        return "mutation_" + strMutation;
    }

    startCinematicMode()
    {

    }

    stopCinematicMode()
    {
        
    }

    startDialogMode(strCharacterToFollow = null, bTiltCamera = false)
    {

    }

    stopDialogMode()
    {

    }
}