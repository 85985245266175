import MapItem from "./MapItem.js";
import ItemCodes from "./codes/ItemCodes.js";
import ItemManager from "./ItemManager.js";

export default class MaterialItem extends MapItem
{
    constructor(strId, objDefinition)
    {
        super(strId, objDefinition);

        this.type = ItemCodes.TYPE_MATERIAL;
    }

    get BGColor() { return ItemManager.instance.GameManager.getSetting("character").backpack.itemColor.material; }
}