import { Sprite } from "@pixi/sprite";
import BaseParser from "./BaseParser.js";

export default class IndoorJsonParser extends BaseParser
{
    constructor()
    {
        super();
    }

    get MapWidth() { return this.mapGridWidth; }

    /*******************************************
    *   INITIALIZATION
    *******************************************/
    /**
        Parameters to pass to the init function:
        - width         Width of the map grid
        - dependencies  DependencyContainer instance
    */
    init(meta)
    {
        this.mapGridWidth = meta.width;
        this.data = {
            "obstacles": null,
            "enemies": null
        };

        return super.init(meta);
    }

    /*******************************************
    *   CALCULATIONS
    *******************************************/
    getTileId(iX, iY)
    {
        return iY * this.MapWidth + iX;
    }

    /**
        Retrives the content that should be spawned at a specific tile
        @param iX   X position of the tile (in grid coordinates)
        @param iY   Y position of the tile (in grid coordinates)
        @param type (Optional) Type of content that should be retrieved. If set, it converts the data in the right 
                    object instance. Otherwise it returns the raw data. The value to use should be the constants
                    defined in this class (TYPE_ITEM and TYPE_ENEMY) Default is NULL (which means TYPE_ITEM)
        @return     Data found or returns NULL if nothing is on the tile
    */
    getAt(iX, iY, type = null)
    {
        let tileId = this.getTileId(iX, iY);
        let content = null;

        if (type == this.TYPE_ENEMY)
        {
            if (this.data.enemies[tileId])
            {
                content = {"id": this.data.enemies[tileId], "type": this.TYPE_ITEM};
            }
        }
        else
        {
            let obstacles =this.data.obstacles.filter ( o => o.position);
            let obstacle = obstacles.find( o => Math.floor(o.position.x) === iX && Math.floor(o.position.z) === iY  );

            if (obstacle)
            {
                const matrix = {};

                try
                {
                    matrix.click = JSON.parse(obstacle.matrix.click);
                }

                catch (e)
                {
                    console.error("unable to parse matrix click", obstacle.matrix.click, obstacle)
                    matrix.click = [[0]];
                }

                try
                {
                    matrix.walk = JSON.parse(obstacle.matrix.walk);
                }

                catch (e)
                {
                    console.error("unable to parse matrix walk", obstacle.matrix.walk, obstacle)
                    matrix.walk = [[0]];
                }

                content = {"id": obstacle.id, "type": this.TYPE_ITEM, offset: {x: obstacle.position.x - iX, z: obstacle.position.z - iY}, click: obstacle.click, matrix};

                if (obstacle.click)
                {
                    content.click = obstacle.click;
                }
            }


            //this was not effective
            // if (this.data.obstacles[tileId])
            // {
            //     content = {"id": this.data.obstacles[tileId], "type": this.TYPE_ITEM};
            // }
        }

        return content;
    }

    /**
        Retrives the content that should be spawned at an area of tiles
        @param iXMin    X position of the top left corner of the area to search in (in grid coordinates)
        @param iYMin    Y position of the top left corner of the area to search in (in grid coordinates)
        @param iXMax    X position of the bottom right corner of the area to search in (in grid coordinates)
        @param iYMax    Y position of the bottom right corner of the area to search in (in grid coordinates)
        @return         Array containing the data found
    */
    getObjectsInArea(iXMin, iYMin, iXMax, iYMax)
    {
        let data = this.getInArea(iXMin, iYMin, iXMax, iYMax);

        let result = {};
        for (let x in data)
        {
            for (let y in data[x])
            {
                if (data[x][y].type == this.TYPE_ITEM)
                {
                    if (!result[x])
                    {
                        result[x] = {};
                    }
                    if (data[x][y].id == "empty")
                    {
                        result[x][y] = {
                            "id": "empty",
                            "IsEmpty": true
                        };
                    }
                    else
                    {
                        let item = this.ItemManager.getItem(data[x][y].id);
                        item.offset = data[x][y].offset;

                        if (data[x][y].click)
                            item.click = data[x][y].click;

                        result[x][y] = item;
                    }
                }
            }
        }

        return result;
    }

    /*******************************************
    *   DATA PARSING
    *******************************************/
    loadMapData(objJsonObstacles, objJsonEnemies)
    {
        this.data.obstacles = objJsonObstacles;
        this.data.enemies = objJsonEnemies;
    }

    blockNearWall (width)
    {
        for (let x = 0; x < width; x++)
        {
            this.data.obstacles.push({id: "block_" + x, position:{x, y:0}})
        }
    }
}