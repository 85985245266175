import {Color} from "three";
import {Mesh} from "three";
import {MeshBasicMaterial} from "three";
import {PlaneGeometry} from "three";
import {Vector2} from "three";
import {Vector3} from "three";
import BarnScene from "../scenes/BarnScene.js";
import BaseEnvironment from "./BaseEnvironment.js";
import Direction from "../../utils/Direction.js";
import Lerp from "../../utils/Lerp.js";
import Library from "../../Library.js";
import Player from "../objects/Player.js";

export default class BarnEnvironment extends BaseEnvironment
{
    constructor(canvas)
    {
        super(canvas);
    }

    get MIN_PROP_SCALE() { return 0.75; }
    get MIN_CHARACTER_SCALE() { return 0.5; }
    get BASE_ASPECT() { return 16/9; }
    get MIN_ASPECT() { return 4/3; }
    get MAX_ASPECT() { return 1920/960; }
    get BASE_RENDER_ORDER() { return 10; }

    get Id() { return "barn"; }
    get Characters() { return this.characters; }
    get ScreenAspect() { return Math.max(this.MIN_ASPECT, Math.min(this.MAX_ASPECT, this.Width / this.Height)); }
    get ShouldRegisterInputs() { return this.registerInputs; }

    get Width() { return this.ResponsiveManager.Width; }
    get Height() { return this.ResponsiveManager.Height; }
    get MinScreen() { return this.Scene.MinScreen; }
    get MaxScreen() { return this.Scene.MaxScreen; }

    getZoneLevel(strZoneKey)
    {
        return this.WorldManager.getBarnZoneLevel(strZoneKey);
    }

    /*******************************************
    *   INITIALIZATION
    *******************************************/
    /**
        Parameters to pass to the init function:
        - positioning:  List of meta-data to be used to position objects on screen
    */
    init(meta)
    {
        this.characters = [];

        this.foreground = meta.positioning;
        this.parts = {};
        this.clickables = {};
        this.zDiff = 0;
        this.characters = {};
        this.canClick = true;
        this.bActive = false;

        super.init(meta);

        this.bInit = true;
        this.Scene.PostProcessing.IsEnabled = false;

        this.AudioManager.playSfx("ambiance_grange", true, 2);
    }

    createClosure()
    {
        super.createClosure();

        this.fctOnZoneUpgrade = this.onZoneUpgrade.bind(this);
        this.fctOnCharacterStateChanged = this.onCharacterStateChanged.bind(this);
        this.fctOnCharacterSelect = this.onCharacterSelect.bind(this);
    }

    bindEvents()
    {
        super.bindEvents();

        this.WorldManager.on(this.WorldManager.EVENT_BARN_ZONE_UPGRADED, this.fctOnZoneUpgrade);
        this.CharacterManager.on(this.CharacterManager.EVENT_STATE_CHANGED, this.fctOnCharacterStateChanged);
        this.CharacterManager.on(this.CharacterManager.EVENT_CURRENT_CHARACTER_SWITCH, this.fctOnCharacterSelect);
    }

    destroy(options)
    {
        this.AudioManager.stopSfx(null, "ambiance_grange", 2);

        this.WorldManager.off(this.WorldManager.EVENT_BARN_ZONE_UPGRADED, this.fctOnZoneUpgrade);
        this.CharacterManager.off(this.CharacterManager.EVENT_STATE_CHANGED, this.fctOnCharacterStateChanged);
        this.CharacterManager.off(this.CharacterManager.EVENT_CURRENT_CHARACTER_SWITCH, this.fctOnCharacterSelect);

        for (let key in this.characters)
        {
            this.characters[key].destroy(options);
        }

        for (let key in this.foreground)
        {
            for (let partId in this.foreground[key])
            {
                if (this.foreground[key][partId].mesh)
                {
                    this.Scene.remove(this.foreground[key][partId].mesh);

                    if (this.foreground[key][partId].mesh.material)
                    {
                        this.foreground[key][partId].mesh.material.dispose();
                    }
                    if (this.foreground[key][partId].mesh.geometry)
                    {
                        this.foreground[key][partId].mesh.geometry.dispose();
                    }
                }
            }
        }

        for (let key in this.parts)
        {
            for (let partId in this.parts[key])
            {
                if (this.parts[key][partId].material)
                {
                    this.parts[key][partId].material.dispose();
                }
                if (this.parts[key][partId].geometry)
                {
                    this.parts[key][partId].geometry.dispose();
                }
            }
        }

        if (this.intersectMesh)
        {
            this.Scene.remove(this.intersectMesh);

            if (this.intersectMesh.material)
            {
                this.intersectMesh.material.dispose();
            }

            if (this.intersectMesh.geometry)
            {
                this.intersectMesh.geometry.dispose();
            }
        }

        delete this.foreground;
        delete this.parts;
        delete this.clickables;
        delete this.characters;

        super.destroy(options);
    }

    createFloor()
    {
        this.Scene.calculateCamera(this.BASE_ASPECT);
        this.generateIntersectMesh();
    }

    createObstacles()
    {
        setTimeout(function()
        {
            this.UIManager.showBarnUI();

            this.Scene.calculateScreenSize(this.intersectMesh);
            this.populate();

            if (this.WorldManager.LastDeath && this.WorldManager.LastDeath.statType)
            {
                let lastDeath = this.WorldManager.LastDeath;
                this.WorldManager.LastDeath = null;

                this.UIManager.CurrentUI.showWakeUpWindow(lastDeath.character, lastDeath.statType);
            }
        }
        .bind(this), 150);
    }

    initUI()
    {
        
    }

    getScene ()
    {
        return new BarnScene(this.canvas).init();
    }

    /*******************************************
    *   BUILD
    *******************************************/
    populate ()
    {
        this.generateBackground();
        this.generateForeground();
        this.generateCharacters();

        this.positionCharacters();
    }

    generateIntersectMesh()
    {
        let material = new MeshBasicMaterial({ color:0x000000 });
        let geometry = this.createGeometry(3000, 3000);
        let mesh = this.createMesh(geometry, material);

        mesh.position.set(-1000, -2.1, -1000);
        mesh.rotation.x = this.Scene.Camera.rotation.x;
        mesh.rotation.y = this.Scene.Camera.rotation.y;
        mesh.rotation.z = this.Scene.Camera.rotation.z;

        this.intersectMesh = mesh;

        this.Scene.add(mesh);
    }

    generateBackground()
    {
        if (!this.parts.background)
        {
            this.parts.background = {};
        }

        if (this.parts.background && this.parts.background.floor)
        {
            this.Scene.remove(this.parts.background.floor);
        }

        if (this.parts.background && this.parts.background.walls)
        {
            this.Scene.remove(this.parts.background.walls);
        }

        this.createPart(
            "barn_floor", 
            (mesh) =>
            {
                this.parts.background.floor = mesh;
                mesh.position.x = 0;
                mesh.position.y = -2;
                mesh.position.z = this.MaxScreen.y - mesh.geometry.parameters.height / 2;
                mesh.renderOrder = 0;
            }, 
            (this.Width / this.Height >= 16/9) ? (this.MaxScreen.x - this.MinScreen.x) : -1, 
            (this.Width / this.Height >= 16/9) ? -1 : Math.abs(this.MaxScreen.y - this.MinScreen.y)
        );

        this.createPart(
            "barn_walls", 
            (mesh) =>
            {
                this.parts.background.walls = mesh;
                mesh.position.x = 0;
                mesh.position.y = -2;
                mesh.position.z = this.MinScreen.y + mesh.geometry.parameters.height / 2;
                mesh.renderOrder = 1;
            }, 
            (this.MaxScreen.x - this.MinScreen.x) * (this.Width / this.Height >= 16/9 ? 1 : 1.1), 
            -1
        );
    }

    generateForeground()
    {
        for (let type in this.foreground)
        {
            for (let key in this.foreground[type])
            {
                this.generateForegroundPart(type, key);
            }
        }
    }

    generateForegroundPart(type, key)
    {
        if (this.foreground[type][key].mesh)
        {
            this.Scene.remove(this.foreground[type][key].mesh);
            if (this.clickables[key])
            {
                delete this.clickables[key];
            }
            delete this.foreground[type][key].mesh;
        }

        let def = this.foreground[type][key];
        if (type == 'barnZones')
        {
            let level = this.getZoneLevel(key) - 1;
            if (level >= 0 && level < this.foreground[type][key].instances.length)
            {
                def = this.foreground[type][key].instances[level];
            }
            else
            {
                return;
            }
        }

        let isClickable = false;
        if (this.foreground[type][key].clickable)
        {
            isClickable = true;
        }

        let width = -1;
        let height = -1;
        if (this.foreground[type][key].size && this.foreground[type][key].size.width)
        {
            width = this.foreground[type][key].size.width;
        }
        if (this.foreground[type][key].size && this.foreground[type][key].size.height)
        {
            height = this.foreground[type][key].size.height;
        }

        let renderOrder = this.BASE_RENDER_ORDER;
        if ("renderOrder" in this.foreground[type][key])
        {
            renderOrder = this.foreground[type][key].renderOrder;
        }

        this.createPart(def.file, function(type, key, def, isClickable, renderOrder, mesh, size)
        {
            mesh.position.y = -1;

            mesh.position.x = this.calculatePosition(true, this.MinScreen, this.MaxScreen, def.anchor, def.pivot, def.override);
            mesh.position.z = this.calculatePosition(false, this.MinScreen, this.MaxScreen, def.anchor, def.pivot, def.override);
            let ratio = this.ScreenAspect;
            let base = this.BASE_ASPECT;
            let scale = Math.max(this.MIN_PROP_SCALE, 1- Math.max(0, ratio - base)) / this.ResponsiveManager.BarnForegroundDivider;

            mesh.scale.set(scale, scale, scale);

            mesh.renderOrder = renderOrder;

            this.foreground[type][key].mesh = mesh;
            this.foreground[type][key].meshSize = size;

            if (isClickable)
            {
                let min = this.Scene.Camera.worldToScreenPosition(
                    mesh.position.x - size.width / 2 * scale, 
                    mesh.position.y, 
                    mesh.position.z - size.height / 2 * scale
                );

                let max = this.Scene.Camera.worldToScreenPosition(
                    mesh.position.x + size.width / 2 * scale, 
                    mesh.position.y, 
                    mesh.position.z + size.height / 2 * scale
                );

                this.clickables[key] = mesh;
                this.Scene.addClickableZone(key, min.x, min.y, max.x, max.y, this.onZoneClick.bind(this, key));
            }
        }
        .bind(this, type, key, def, isClickable, renderOrder), width, height, def, type == 'colliders');
    }

    createPart(strFrameKey, fctCallback, width = -1, height = -1, def, isCollider = false)
    {
        let fctCreatePart = function(strFrameKey, fctCallback, width, height, isCollider, def, objTexture)
        {   
            let size = {"width": width, "height": height};
            let material = null;

            if (!isCollider)
            {
                let pixelRatio = (this.MaxScreen.y - this.MinScreen.y) / this.Height;
                pixelRatio *= (this.Height / 980);

                let defaultDivider = this.ResponsiveManager.AssetDivider / 30;
                let imgWidth = (objTexture.frameSize ? objTexture.frameSize.width : objTexture.image.width) * pixelRatio;
                let imgHeight = (objTexture.frameSize ? objTexture.frameSize.height : objTexture.image.height) * pixelRatio;

                let textureSize = {"width": imgWidth / defaultDivider, "height": imgHeight / defaultDivider};

                if (size.width <= 0 && size.height > 0)
                {
                    size.width = textureSize.width / textureSize.height * size.height;
                }
                else if (size.width > 0 && size.height <= 0)
                {
                    size.height = textureSize.height / textureSize.width * size.width;
                }
                else if (size.width <= 0 && size.height <= 0)
                {
                    size = textureSize;
                }

                material = this.createMaterial(objTexture);
            }
            else
            {
                material = this.createColliderMaterial();
            }

            let multiplier = this.ResponsiveManager.BarnMultiplier;

            if (def && def.multiplier)
                multiplier *= this.ResponsiveManager.getMultiplier(def);

            let geometry = this.createGeometry(size.width * multiplier, size.height * multiplier);
            let mesh = this.createMesh(geometry, material);

            mesh.rotation.x = this.Scene.Camera.rotation.x;
            mesh.rotation.y = this.Scene.Camera.rotation.y;
            mesh.rotation.z = this.Scene.Camera.rotation.z;

            this.Scene.add(mesh);

            fctCallback(mesh, size);
        }
        .bind(this, strFrameKey, fctCallback, width, height, isCollider, def);

        if (isCollider)
        {
            fctCreatePart(null);
        }
        else
        {
            let atlas = "barn";
            let frame = strFrameKey;

            //These two are on sperate files as they are too big
            if (frame == "barn_walls" || frame == "barn_floor")
            {
                atlas = frame;
                frame = null;
            }

            let texture = Library.getTexture3D(atlas, frame);
            fctCreatePart(texture);
        }
    }

    /*******************************************
    *   THREE JS
    *******************************************/
    createMaterial(texture)
    {
        let material = new MeshBasicMaterial({ 
            map:texture, 
            transparent: true, 
            wireframe: false, 
            depthWrite: false, 
            opacity: 1.0 
        } );
        material.needsUpdate = true;

        return material;
    }

    createColliderMaterial()
    {
        let material = new MeshBasicMaterial({ 
            color: 0xFFFFFF, 
            visible: false 
        });
        material.needsUpdate = true;
        
        return material;
    }

    createGeometry(width, height)
    {
        let divider = this.ResponsiveManager.AssetDivider;

        let geometry = new PlaneGeometry(width, height, 1, 1);
        geometry.verticesNeedUpdate = true;

        return geometry;
    }

    createMesh (geometry, material)
    {
        let mesh = new Mesh(geometry, material);
        return mesh;
    }

    /*******************************************
    *   CHARACTERS
    *******************************************/
    generateCharacters()
    {
        let ratio = this.ScreenAspect;
        let base = this.BASE_ASPECT;
        let scale = Math.max(this.MIN_CHARACTER_SCALE, 1- Math.max(0, ratio - base))*1.2;

        for (let i = 0; i < 3; i++)
        {
            let character = null;
            if (!this.characters[i])
            {
                character = new Player();
                character.init({
                    id: this.globalId,
                    visualCode: this.SaveManager.getFromSave(this.CharacterManager.characterBuildKey + "" + i),
                    spawnPos: new Vector2(0, 0),
                    direction: Direction.South,
                    focus:true,
                    environment: this
                });
                character.preventMovement();

                this.globalId++;
                this.characters[i] = character;
            }
            else
            {
                character = this.characters[i];
            }

            character.mesh.scale.set(scale, scale, scale);
        }

        this.updateCharacterSelection();
    }

    updateCharacterSelection()
    {
        for (let characterId in this.characters)
        {
            let color = 0x909090;
            if (characterId == this.CharacterManager.CurrentCharacter)
            {
                color = 0xFFFFFF;
            }
            this.characters[characterId].mesh.material.color = new Color(color);
        }
    }

    positionCharacters()
    {
        let relativeAspect = (this.ScreenAspect - this.MIN_ASPECT) / (this.MAX_ASPECT - this.MIN_ASPECT);

        for (let characterId in this.characters)
        {
            let mesh = this.characters[characterId].mesh;

            let x = mesh.position.x;
            let y = mesh.position.y;
            let z = mesh.position.z;

            if (this.CharacterManager.isCharacterSleeping(characterId))
            {
                let sleepMesh = this.foreground["barnZones"][this.WorldManager.BARN_ZONE_SLEEP].mesh;
                let size = this.foreground["barnZones"][this.WorldManager.BARN_ZONE_SLEEP].meshSize;
                
                let positions = {
                    "min": {
                        x: sleepMesh.position.x - size.width / 3.25,
                        y: sleepMesh.position.y,
                        z: sleepMesh.position.z + size.height / 3
                    },
                    "max": {
                        x: sleepMesh.position.x - size.width / 3.25,
                        y: sleepMesh.position.y,
                        z: sleepMesh.position.z + size.height / 5.5
                    }
                };

                x = Lerp.lerp(positions.min.x, positions.max.x, relativeAspect);
                y = Lerp.lerp(positions.min.y, positions.max.y, relativeAspect);
                z = Lerp.lerp(positions.min.z, positions.max.z, relativeAspect);
            }
            else if (this.CharacterManager.isCharacterRelaxing(characterId))
            {
                let relaxMesh = this.foreground["barnZones"][this.WorldManager.BARN_ZONE_RELAX].mesh;
                let size = this.foreground["barnZones"][this.WorldManager.BARN_ZONE_RELAX].meshSize;

                let positions = {
                    "min": {
                        x: relaxMesh.position.x,
                        y: relaxMesh.position.y,
                        z: relaxMesh.position.z + size.height / 2
                    },
                    "max": {
                        x: relaxMesh.position.x,
                        y: relaxMesh.position.y,
                        z: relaxMesh.position.z + size.height / 3
                    }
                };

                x = Lerp.lerp(positions.min.x, positions.max.x, relativeAspect);
                y = Lerp.lerp(positions.min.y, positions.max.y, relativeAspect);
                z = Lerp.lerp(positions.min.z, positions.max.z, relativeAspect);
            }
            else
            {
                let radioMesh = this.foreground["props"]["radio"].mesh;
                let radioSize = this.foreground["props"]["radio"].meshSize;
                x = radioMesh.position.x;
                y = radioMesh.position.y;
                z = radioMesh.position.z;

                let positions = null;

                if (characterId == 0)
                {
                    positions = {
                        "min": {
                            x: x - radioSize.width / 3.25,
                            y: radioMesh.position.y,
                            z: z + radioSize.height / 6.5
                        },
                        "max": {
                            x: x - radioSize.width / 3.25,
                            y: radioMesh.position.y,
                            z: z + radioSize.height / 6.5
                        }
                    };

                }
                else if (characterId == 1)
                {
                    positions = {
                        "min": {
                            x: x,
                            y: radioMesh.position.y,
                            z: z - radioSize.height / 12.75
                        },
                        "max": {
                            x: x ,
                            y: radioMesh.position.y,
                            z: z - radioSize.height / 12.5
                        }
                    };
                }
                else
                {
                    positions = {
                        "min": {
                            x: x + radioSize.width / 5.5,
                            y: radioMesh.position.y,
                            z: z + radioSize.height / 4
                        },
                        "max": {
                            x: x + radioSize.width / 4.5,
                            y: radioMesh.position.y,
                            z: z + radioSize.height / 4
                        }
                    };
                }

                x = Lerp.lerp(positions.min.x, positions.max.x, relativeAspect);
                y = Lerp.lerp(positions.min.y, positions.max.y, relativeAspect);
                z = Lerp.lerp(positions.min.z, positions.max.z, relativeAspect);
            }

            mesh.position.set(x, y, z);
        }
    }


    /*******************************************
    *   CALCULATIONS
    *******************************************/
    calculatePosition(bIsX, fMinScreen, fMaxScreen, anchor, pivot, override)
    {
        let values = {
            "min": (bIsX ? fMinScreen.x : fMinScreen.y),
            "max": (bIsX ? fMaxScreen.x : fMaxScreen.y),
            "anchor": (bIsX ? anchor.x : anchor.z),
            "pivot": (bIsX ? pivot.x : pivot.z)
        };

        let ratio = this.Width / this.Height;
        if (override)
        {
            for (let key in override)
            {
                for (let i = 0; i < override[key].length; i++)
                {
                    let parts = override[key][i].ratio.split('/');
                    let targetRatio = parseFloat(parts[0]) / parseFloat(parts[1]);

                    if ((key == 'over' && ratio > targetRatio) || (key == 'under' && ratio < targetRatio))
                    {
                        if (bIsX && override[key][i].anchor && override[key][i].anchor.x)
                        {
                            values.anchor = override[key][i].anchor.x;
                        }
                        if (bIsX && override[key][i].pivot && override[key][i].pivot.x)
                        {
                            values.pivot = override[key][i].pivot.x;
                        }
                        if (!bIsX && override[key][i].anchor && override[key][i].anchor.z)
                        {
                            values.anchor = override[key][i].anchor.z;
                        }
                        if (!bIsX && override[key][i].pivot && override[key][i].pivot.z)
                        {
                            values.pivot = override[key][i].pivot.z;
                        }
                    }
                }
            }
        }

        let diff = Math.abs(values.max - values.min);
        let start = values.pivot * diff + values.min;
        let move = diff * values.anchor * (values.pivot > 0.5 ? -1 : 1);

        return start + move - (!bIsX ? this.zDiff*0.5 : 0);
    }

    /*******************************************
    *   ZONES
    *******************************************/
    getZoneTextureId(strId, iLevel)
    {
        let img = "";

        if      (strId == this.WorldManager.BARN_ZONE_SLEEP)    { img = "sommeil"; }
        else if (strId == this.WorldManager.BARN_ZONE_RELAX)    { img = "detente"; }
        else if (strId == this.WorldManager.BARN_ZONE_COOKING)  { img = "cuisine"; }
        else if (strId == this.WorldManager.BARN_ZONE_CRAFTING) { img = "atelier"; }

        return img + "_" + iLevel + ".png";
    }

    getRadioTextureId()
    {
        return "radio.png";
    }

    /*******************************************
    *   PLAYER RELATED
    *******************************************/
    clickOnRadio()
    {
        this.AudioManager.playSfx("radio_synchro");
        this.WorldManager.emit(this.WorldManager.EVENT_RADIO_CLICK);
    }

    /*******************************************
    *   UTILITIES
    *******************************************/
    startDialogMode (strCharacterToFollow = null, bTiltCamera = false)
    { 
        super.startDialogMode(strCharacterToFollow, bTiltCamera);
        this.canClick = false;
    }

    stopDialogMode (strCharacterToFollow = null, bTiltCamera = false)
    {
        super.stopDialogMode(strCharacterToFollow, bTiltCamera);
        this.canClick = true;
    }

    startCinematicMode () 
    { 
        super.startCinematicMode();
        this.canClick = false; 
    }

    stopCinematicMode () 
    { 
        super.stopCinematicMode();
        this.canClick = true; 
    }

    /*******************************************
    *   EVENTS
    *******************************************/
    onZoneUpgrade(strBarnZoneId, iUpgradeLevel)
    {
        let type = "barnZones";
        let key = strBarnZoneId;

        this.Scene.remove(this.foreground[type][key].mesh);
        this.generateForegroundPart(type, key);
        this.positionCharacters();
    }

    onCharacterStateChanged(iCharacter, strNewState)
    {
        this.positionCharacters();
    }

    onResize(iNewWidth, iNewHeight, iLeftPadding)
    {
        super.onResize();

        setTimeout(function(){
            this.Scene.calculateCamera(this.BASE_ASPECT);
            setTimeout(function()
            {
                this.Scene.calculateScreenSize(this.intersectMesh);
                this.generateBackground();
                this.generateForeground();
                this.generateCharacters();

                this.positionCharacters();
            }
            .bind(this), 100);
        }.bind(this), 1);
    }

    onZoneClick(strZoneId, objPosition)
    {
        if (this.canClick)
        {
            console.log("Clicked on", strZoneId);

            if (strZoneId == this.WorldManager.BARN_ZONE_SLEEP || strZoneId == this.WorldManager.BARN_ZONE_RELAX ||
                strZoneId == this.WorldManager.BARN_ZONE_COOKING || strZoneId == this.WorldManager.BARN_ZONE_CRAFTING)
            {
                this.UIManager.CurrentUI.showZonePopup(strZoneId);
            }
            else if (strZoneId == "door")
            {
                //@TODO: The code is good, only need to plug the scene transition to work

                let bOk = true;
                let label = "";

                if (!this.CharacterManager.getCharacterCanGoOut(this.CharacterManager.STAT_ENERGY))
                {
                    bOk = false;
                    label = "GRANG_BLOC_ENER";
                }
                else if (!this.CharacterManager.getCharacterCanGoOut(this.CharacterManager.STAT_HUNGER))
                {
                    bOk = false;
                    label = "GRANG_BLOC_NUTRI";
                }
                else if (!this.CharacterManager.getCharacterCanGoOut(this.CharacterManager.STAT_STRESS))
                {
                    bOk = false;
                    label = "GRANG_BLOC_STRES";
                }

                if (bOk)
                {
                    this.AudioManager.stopAll(this.AudioManager.AUDIO_TYPE_DIALOG);
                    this.AudioManager.stopAll(this.AudioManager.AUDIO_TYPE_DIALOG2);

                    this.WorldManager.preventWorldClick();
                    this.clickables = {};

                    this.UIManager.showSceneTransition(() =>
                    {
                        this.WorldManager.changeEnvironment(this.WorldManager.ENVIRONMENT_FOREST);
                        this.WorldManager.allowWorldClick();
                    });
                }
                else
                {
                    let name = this.CharacterManager.getCharacterName(this.CharacterManager.CurrentCharacter);
                    label = this.LabelManager.translate(label).replace("[Nom personnage]", name);

                    this.UIManager.Notifications.showObjectiveNotification(label);
                }
            }
            else if (strZoneId == "radio")
            {
                this.clickOnRadio();
            }
        }
    }

    onCharacterClick(iCharacterId, objPosition)
    {
        
    }

    onCharacterSelect(iNewCharacter)
    {
        this.updateCharacterSelection();
    }
}