import {Howl, Howler} from 'howler';
import EventEmitter from "eventemitter3";
import AudioItem from "./AudioItem.js";
import HowlerController from "./HowlerController.js";
import _ from "lodash";
 
export default class AudioManager extends EventEmitter
{
    constructor()
    {
        super();

        AudioManager.instance = this;

        this.howlerControllers = [];

        this.volumeBGMSaveKey = "volume_bgm";
        this.volumeSFXSaveKey = "volume_sfx";
        this.volumeDialogSaveKey = "volume_dialog";

        this.lastDialogController = null;

        this.canPlayMusicTime = this.MIN_TIME_BETWEEN_BACKGROUND_MUSIC;

        Howler.pos(0, 0, 0);
        Howler.pos(0, 0, 1, 0, 1, 0);

    }

    get MIN_TIME_BETWEEN_BACKGROUND_MUSIC () { return 120; }
    get OFFSET_TIME_BETWEEN_BACKGROUND_MUSIC () { return 120; }
    get EVENT_ITEM_FADE_IN_COMPLETED() { return "item-fade-in-completed"; }
    get EVENT_ITEM_FADE_OUT_COMPLETED() { return "item-fade-out-completed"; }
    get EVENT_ITEM_PLAY_END() { return "item-play-end"; }
    get EVENT_ITEM_PLAY_PAUSE() { return "item-play-pause"; }
    get EVENT_ITEM_PLAY_START() { return "item-play-start"; }

    get AUDIO_TYPE_SFX() { return "SFX"; }
    get AUDIO_TYPE_BGM() { return "BGM"; }
    get AUDIO_TYPE_DIALOG() { return "DIALOG"; }

    get AUDIO_TYPE_DIALOG1() { return "DIALOG1"; }
    get AUDIO_TYPE_DIALOG2() { return "DIALOG2"; }
    get AUDIO_TYPE_DIALOG3() { return "DIALOG3"; }

    //---------------------------------------------------------
    //  DEPENDENCIES
    //---------------------------------------------------------
    get SaveManager() { return this.dependencies.get("SaveManager"); }
    get GameManager() { return this.dependencies.get("GameManager"); }
    get WorldManager() { if (this.wm) return this.wm;  this.wm = this.dependencies.get("WorldManager"); return this.wm}
    //---------------------------------------------------------

    get VolumeBGM() { return this.SaveManager.get(this.volumeBGMSaveKey, 1); }
    set VolumeBGM(fVolume) { this.SaveManager.set(this.volumeBGMSaveKey, Math.max(0, Math.min(0.5, fVolume))); }

    get VolumeSFX() { return this.SaveManager.get(this.volumeSFXSaveKey, 1); }
    set VolumeSFX(fVolume) { this.SaveManager.set(this.volumeSFXSaveKey, Math.max(0, Math.min(1, fVolume))); }

    get VolumeDialog() { return this.SaveManager.get(this.volumeDialogSaveKey, 1); }
    set VolumeDialog(fVolume) { this.SaveManager.set(this.volumeDialogSaveKey, Math.max(0, Math.min(1, fVolume))); }

    getVolume (strType)
    {
        if (strType === this.AUDIO_TYPE_SFX)
        {
            return this.VolumeSFX;
        }
        if (strType === this.AUDIO_TYPE_BGM)
        {
            return this.VolumeBGM;
        }
        if (strType === this.AUDIO_TYPE_DIALOG || strType === this.AUDIO_TYPE_DIALOG1 || strType === this.AUDIO_TYPE_DIALOG2 || strType === this.AUDIO_TYPE_DIALOG3)
        {
            return this.VolumeDialog;
        }

        return 0;
    }

    getController (strType)
    {
        let ctrl = this.howlerControllers.find( hc => hc.type === strType);

        return ctrl;
    }


    setVolumeInitialValue ()
    {
        this.setVolume(this.AUDIO_TYPE_BGM, this.getVolume(this.AUDIO_TYPE_BGM));
        this.setVolume(this.AUDIO_TYPE_SFX, this.getVolume(this.AUDIO_TYPE_SFX));
        this.setVolume(this.AUDIO_TYPE_DIALOG, this.getVolume(this.AUDIO_TYPE_DIALOG));
    }

    setVolume (strType, fNewValue)
    {
        let newVolume = fNewValue
        if (strType === this.AUDIO_TYPE_SFX)
        {
            this.VolumeSFX = fNewValue;
            newVolume = this.VolumeSFX;
        }
        if (strType === this.AUDIO_TYPE_BGM)
        {
            this.VolumeBGM = fNewValue;
            newVolume = this.VolumeBGM;
        }
        if (strType === this.AUDIO_TYPE_DIALOG )
        {
            this.VolumeDialog = fNewValue;
            newVolume = this.VolumeDialog;
        }

        //let ctrl = this.howlerControllers.first( hc => hc.type === strType);
        let ctrl = this.getController(strType);

        if (ctrl)
        {
            ctrl.setVolume(fNewValue)
        }


        if (strType === this.AUDIO_TYPE_DIALOG)
        {
            ctrl = this.getController(this.AUDIO_TYPE_DIALOG1);
            ctrl.setVolume(fNewValue)

            ctrl = this.getController(this.AUDIO_TYPE_DIALOG2);
            ctrl.setVolume(fNewValue)

            ctrl = this.getController(this.AUDIO_TYPE_DIALOG3);
            ctrl.setVolume(fNewValue)
        }



        return fNewValue;
    }

    init(dependencies)
    {
        this.dependencies = dependencies;

        this.createClosure();
        this.bindEvents();
    }

    createClosure()
    {
        
    }

    bindEvents()
    {

    }

    // getAudioItemFromAudioId (strKey, strType)
    // {
    //     let audioItem = this.audioLibrary[strType][strKey];
    //     return audioItem;
    // }


    //---------------------------------------------------------------------------------------------
    //  PLAY METHODS
    //---------------------------------------------------------------------------------------------
    /**
        Plays a sound effect clip from the audio library

        @param strKey       Key representing the sound effect to play
        @param bLoop        (Optional) Like Howler says: "To loop or not to loop, that is the question". (Default is not looping)

        @return             Id of the sound effect that was started. Use this Id to further interact with this specific audio clip.
                                Returns FALSE if could not play the audio clip
    */
    playSfx(strKey, bLoop = false, _fadeInDuration = 0)
    {
        return this.play(this.AUDIO_TYPE_SFX, strKey, bLoop, _fadeInDuration);
    }

    /**
        Plays a background music clip from the audio library. Only one background music could be played at a time, if another music is in
        play it will transition from one to another.

        @param strKey       Key representing the music to play
        @param bTransition  (Optional) If the music should transition with fade in/out in case another music is playing or if it should bluntly
                                stop the previous one and start the new music.
        @param bLoop        (Optional) Like Howler says: "To loop or not to loop, that is the question". (Default is looping)

        @return             Id of the music that was started. Use this Id to further interact with this specific audio clip.
                                Returns FALSE if could not play the audio clip
    */
    playBgm(strKey, bTransition = true, bLoop = true)
    {
        return this.playUnique(this.AUDIO_TYPE_BGM, strKey, bTransition, bLoop);
    }

    /**
        Plays a dialog audio clip from the audio library. Only one dialog could be played at a time, if another dialog is in
        play it will transition from one to another.

        @param strKey       Key representing the dialog to play
        @param bTransition  (Optional) If the dialog should transition with fade in/out in case another dialog is playing or if it should bluntly
                                stop the previous one and start the new dialog.

        @return             Id of the dialog that was started. Use this Id to further interact with this specific audio clip.
                                Returns FALSE if could not play the audio clip
    */
    playDialog(strKey, bTransition = true)
    {
        // let arrMatch = strKey.match(/Miss([0-9]+)/);
        //
        // let iMiss = arrMatch && arrMatch.length > 1 ? parseInt(arrMatch[1]) : 0;
        //
        // let strType = iMiss >= 7 ? this.AUDIO_TYPE_DIALOG2 : this.AUDIO_TYPE_DIALOG;

        let strType = "";

        let possibleType = [this.AUDIO_TYPE_DIALOG1, this.AUDIO_TYPE_DIALOG2, this.AUDIO_TYPE_DIALOG3];
        let ctrl;

        for (let i = 0; i < possibleType.length && strType === ""; i++)
        {
            let strCurrentType = possibleType[i];

            ctrl = this.getController(strCurrentType);

            if (ctrl.canPlay(strKey))
            {
                strType = strCurrentType;
            }
        }
        if (strType !== "")
        {
            if (this.lastDialogController)
            {
                this.lastDialogController.stopAll();
            }

            this.lastDialogController = ctrl;

            return this.playUnique(strType, strKey, bTransition, false, 100);
        }
    }

    //---------------------------------------------------------------------------------------------
    //  STOP METHODS
    //---------------------------------------------------------------------------------------------
    /**
        Stops a currently playing sound effect. This method will unload the sound from the playing pool. In case all parameters are set with their
        default values, all sound effects will be stopped.

        @param iAudioId     (Optional) Id of an audio clip being played. If NULL and the strKey parameter is defined, all audio clips linked to
                            the strKey will be stopped
        @param strKey       (Optional) Key representing the sound effect to stop. Default is NULL
    */
    stopSfx(iAudioId = null, strKey = null, fadeDiration = 0, type ="")
    {
        if (iAudioId === null && strKey === null)
        {
            this.stopAll(this.AUDIO_TYPE_SFX);
        }
        else
        {
            this.stop(this.AUDIO_TYPE_SFX, strKey, iAudioId, fadeDiration, type);
        }
    }

    /**
        Stops a currently playing background music. This method will unload the music from the playing pool. In case all parameters are set with their
        default values, all musics will be stopped.

        @param iAudioId     (Optional) Id of an audio clip being played. If NULL and the strKey parameter is defined, all audio clips linked to
                            the strKey will be stopped
        @param strKey       (Optional) Key representing the music to stop. Default is NULL
    */
    stopBgm(iAudioId = null, strKey = null, iFadeDuration = 0)
    {
        if (iAudioId === null && strKey === null)
        {
            this.stopAll(this.AUDIO_TYPE_BGM,  iFadeDuration > 0, iFadeDuration);
        }
        else
        {
            this.stop(this.AUDIO_TYPE_BGM, strKey, iAudioId, iFadeDuration);
        }
    }

    /**
        Stops a currently playing dialog audio clip. This method will unload the dialog from the playing pool. In case all parameters are set with their
        default values, all dialogs will be stopped.

        @param iAudioId     (Optional) Id of an audio clip being played. If NULL and the strKey parameter is defined, all audio clips linked to
                            the strKey will be stopped
        @param strKey       (Optional) Key representing the dialog to stop. Default is NULL
    */
    stopDialog(iAudioId = null, strKey = null)
    {
        if (iAudioId === null && strKey === null)
        {
            this.stopAll(this.AUDIO_TYPE_DIALOG1);
            this.stopAll(this.AUDIO_TYPE_DIALOG2);
        }
        else
        {
            this.stop(this.AUDIO_TYPE_DIALOG1, strKey, iAudioId);
            this.stop(this.AUDIO_TYPE_DIALOG2, strKey, iAudioId);
        }
    }

    //---------------------------------------------------------------------------------------------
    //  PAUSE METHODS
    //---------------------------------------------------------------------------------------------
    /**
        Pauses a currently playing sound effect. Be warned that this method will not unload the sound from the playing pool and keep it in memory. 
        In case all parameters are set with their default values, all sound effects will be paused.

        @param iAudioId     (Optional) Id of an audio clip being played. If NULL and the strKey parameter is defined, all audio clips linked to
                            the strKey will be paused
        @param strKey       (Optional) Key representing the sound effect to pause. Default is NULL

        return              An object containing all the sound effect ids paused. In case nothing could be stopped, an empty object will
                            be returned
    */
    pauseSfx(iAudioId = null, strKey = null)
    {
        if (iAudioId === null && strKey === null)
        {
            return this.pauseAll(this.AUDIO_TYPE_SFX);
        }
        else
        {
            return this.pause(this.AUDIO_TYPE_SFX, strKey, iAudioId);
        }
    }

    /**
        Pauses a currently playing background music. Be warned that this method will not unload the music from the playing pool and keep it in memory. 
        In case all parameters are set with their default values, all musics will be paused.

        @param iAudioId     (Optional) Id of an audio clip being played. If NULL and the strKey parameter is defined, all audio clips linked to
                            the strKey will be paused
        @param strKey       (Optional) Key representing the background music to pause. Default is NULL

        return              An object containing all the music ids paused. In case nothing could be stopped, an empty object will
                            be returned
    */
    pauseBgm(iAudioId = null, strKey = null)
    {
        if (iAudioId === null && strKey === null)
        {
            return this.pauseAll(this.AUDIO_TYPE_BGM);
        }
        else
        {
            return this.pause(this.AUDIO_TYPE_BGM, strKey, iAudioId);
        }
    }

    /**
        Pauses a currently playing dialog audio clip. Be warned that this method will not unload the dialog from the playing pool and keep it in memory. 
        In case all parameters are set with their default values, all dialogs will be paused.

        @param iAudioId     (Optional) Id of an audio clip being played. If NULL and the strKey parameter is defined, all audio clips linked to
                            the strKey will be paused
        @param strKey       (Optional) Key representing the dialog to pause. Default is NULL

        return              An object containing all the dialog ids paused. In case nothing could be stopped, an empty object will
                            be returned
    */
    pauseDialog(iAudioId = null, strKey = null)
    {
        if (iAudioId === null && strKey === null)
        {
            return this.pauseAll(this.AUDIO_TYPE_DIALOG);
        }
        else
        {
            return this.pause(this.AUDIO_TYPE_DIALOG, strKey, iAudioId);
        }
    }

    //---------------------------------------------------------------------------------------------
    //  RESUME METHODS
    //---------------------------------------------------------------------------------------------
    /**
        Resumes a previously paused sound effect

        @param iAudioId     Id of an audio clip to resume
        @param strKey       Key representing the sound effect to resume
    */
    resumeSfx(iAudioId, strKey)
    {
        this.resume(this.AUDIO_TYPE_SFX, strKey, iAudioId);
    }

    /**
        Resumes a previously paused background music

        @param iAudioId     Id of an audio clip to resume
        @param strKey       Key representing the background music to resume
    */
    resumeBgm(iAudioId, strKey)
    {
        this.resume(this.AUDIO_TYPE_BGM, strKey, iAudioId);
    }

    /**
        Resumes a previously paused dialog audio clip

        @param iAudioId     Id of an audio clip to resume
        @param strKey       Key representing the dialog to resume
    */
    resumeDialog(iAudioId, strKey)
    {
        this.resume(this.AUDIO_TYPE_DIALOG, strKey, iAudioId);
    }

    //---------------------------------------------------------------------------------------------
    //  IS PLAYING METHODS
    //---------------------------------------------------------------------------------------------
    /**
        Gets if a sound effect is playing or not

        @param iAudioId     (Optional) Id of an audio clip to check if it is playing. If NULL, all sound effect for strKey will be checked if one is playing
        @param strKey       (Optional) Key representing the sound effect to check. If left empty and iAudioId too, this will check if any sound effect is playing

        @return             Boolean indicating if it is playing or not
    */
    isSfxPlaying(iAudioId = null, strKey = null)
    {
        let isSfxPlaying = this.isPlaying(this.AUDIO_TYPE_SFX, iAudioId, strKey);

        console.log("isSfxPlaying", isSfxPlaying, arguments);
        return isSfxPlaying;
    }

    /**
        Gets if a background music is playing or not

        @param iAudioId     (Optional) Id of an audio clip to check if it is playing. If NULL, all musics for strKey will be checked if one is playing
        @param strKey       (Optional) Key representing the music to check. If left empty and iAudioId too, this will check if any background music is playing

        @return             Boolean indicating if it is playing or not
    */
    isBgmPlaying(iAudioId = null, strKey = null)
    {
        return this.isPlaying(this.AUDIO_TYPE_BGM, iAudioId, strKey);
    }

    /**
        Gets if a dialog audio clip is playing or not

        @param iAudioId     (Optional) Id of an audio clip to check if it is playing. If NULL, all dialogs for strKey will be checked if one is playing
        @param strKey       (Optional) Key representing the dialog to check. If left empty and iAudioId too, this will check if any dialog is playing

        @return             Boolean indicating if it is playing or not
    */
    isDialogPlaying(iAudioId = null, strKey = null)
    {
        return this.isPlaying(this.AUDIO_TYPE_DIALOG, iAudioId, strKey);
    }

    //---------------------------------------------------------------------------------------------
    //  SPATIAL METHODS
    //---------------------------------------------------------------------------------------------
    /**
        Sets the spatial position of the sound emitter relative to the listener. By default, the listener's head is static at 0,0,0 facing towards the Z positive axis with
        the head's top up in the air. This means that the -X is left, +X is right, -Z is behind and +Z is in front. The further the distance, the lower the sound will be.

        @param iAudioId     Id of a playing audio clip to set its spatial position
        @param strKey       Key representing the audio clip to set its spatial position
        @param fDirectionX  X value of the new sound's position
        @param fDirectionX  X value of the new sound's position
        @param fDirectionZ  Z value of the new sound's position
    */
    setSpatialSfx(iAudioId, strKey, fDirectionX, fDirectionY, fDirectionZ)
    {
        this.setSpatial(this.AUDIO_TYPE_SFX, iAudioId, strKey, fDirectionX, fDirectionY, fDirectionZ);
    }

    /**
        Sets the spatial position of the sound emitter relative to the listener. By default, the listener's head is static at 0,0,0 facing towards the Z positive axis with
        the head's top up in the air. This means that the -X is left, +X is right, -Z is behind and +Z is in front. The further the distance, the lower the sound will be.

        @param iAudioId     Id of a playing audio clip to set its spatial position
        @param strKey       Key representing the audio clip to set its spatial position
        @param fDirectionX  X value of the new sound's position
        @param fDirectionX  X value of the new sound's position
        @param fDirectionZ  Z value of the new sound's position
    */
    setSpatialBgm(iAudioId, strKey, fDirectionX, fDirectionY, fDirectionZ)
    {
        this.setSpatial(this.AUDIO_TYPE_BGM, iAudioId, strKey, fDirectionX, fDirectionY, fDirectionZ);
    }
    
    /**
        Sets the spatial position of the sound emitter relative to the listener. By default, the listener's head is static at 0,0,0 facing towards the Z positive axis with
        the head's top up in the air. This means that the -X is left, +X is right, -Z is behind and +Z is in front. The further the distance, the lower the sound will be.

        @param iAudioId     Id of a playing audio clip to set its spatial position
        @param strKey       Key representing the audio clip to set its spatial position
        @param fDirectionX  X value of the new sound's position
        @param fDirectionX  X value of the new sound's position
        @param fDirectionZ  Z value of the new sound's position
    */
    setSpatialDialog(iAudioId, strKey, fDirectionX, fDirectionY, fDirectionZ)
    {
        this.setSpatial(this.AUDIO_TYPE_DIALOG, iAudioId, strKey, fDirectionX, fDirectionY, fDirectionZ);
    }

    //---------------------------------------------------------------------------------------------
    //  GENERAL ACTION METHODS
    //---------------------------------------------------------------------------------------------
    play (strType, strKey, bLoop = false, iFadeInDuration = 0)
    {
        let ctrl = this.getController(strType);

        let iAudioId = ctrl.play(strKey, bLoop, iFadeInDuration);

        return iAudioId;
    }


    playUnique (strType, strKey, bTransition = true, bLoop = false, iFadeDuration = 3)
    {

        let ctrl = this.getController(strType);

        if (ctrl.isPlaying)
        {
            ctrl.stopAll(strType, bTransition ? iFadeDuration: 0);
        }

        let iAudioId = ctrl.play(strKey, bLoop);

        return iAudioId;
    }

    stop (strType, strKey, iAudioId = null, iFadeDuration = 0, type = "")
    {
        let ctrl = this.getController(strType);

        if (ctrl)
        {
            ctrl.stop(iAudioId, strKey, iFadeDuration);
        }
    }

    stopAll(strType, bTransition = false, iFadeDuration = 0)
    {
        let ctrl = this.getController(strType);

        if (ctrl && ctrl.isPlaying)
        {
            ctrl.stopAll(strType, bTransition ? iFadeDuration: 0);
        }
    }

    pause (strType, strKey, iAudioId = null)
    {

        return {};
    }

    pauseAll (strType)
    {

        return objPause;
    }

    resume (strType, strKey, iAudioId)
    {

    }

    isPlaying (strType, iAudioId = null)
    {
        let ctrl = this.getController(strType);
        let isPlaying = false;
        if (ctrl)
        {
            isPlaying = ctrl.isPlaying(iAudioId)
        }


        if (strType === this.AUDIO_TYPE_DIALOG)
        {
            ctrl = this.getController(this.AUDIO_TYPE_DIALOG1);
            let isPlaying1  = ctrl.isPlaying(iAudioId)

            ctrl = this.getController(this.AUDIO_TYPE_DIALOG2);
            let isPlaying2  = ctrl.isPlaying(iAudioId)

            ctrl = this.getController(this.AUDIO_TYPE_DIALOG3);
            let isPlaying3  = ctrl.isPlaying(iAudioId);

            isPlaying = isPlaying1 | isPlaying2 | isPlaying3;
        }

        return isPlaying;
    }

    setSpatial(strType, iAudioId, strKey, fDirectionX, fDirectionY, fDirectionZ)
    {
        if (this.audioLibrary[strType][strKey].setSpatial(iAudioId, strKey, {x: fDirectionX, y: fDirectionY, z: fDirectionZ}))
        {
            bPlaying = true;
        }
    }


    loadAudio (audio, type, sprites)
    {

        let hc = new HowlerController(audio, type, sprites, this.getVolume(type))

        this.howlerControllers.push(hc);



    }

    onResetBgmMusicDelay ()
    {
        this.canPlayMusicTime = this.WorldManager.Time + this.MIN_TIME_BETWEEN_BACKGROUND_MUSIC
    }


    onCheckPlayMusic ()
    {
        // if (this.canPlayMusicTime < GameManager.instance.Time)
        // {
        //     let strBGM = this.findAppropriateMusicEvent();
        //     let iAudioID = AudioManager.instance.playBgm(strBGM, true, false);
        //     this.currentBGM = AudioManager.instance.getAudioItemFromAudioId(strBGM, "BGM");
        //
        //     if (this.currentBGM !== null && this.currentBGM !== undefined)
        //     {
        //         let currentDuration = this.currentBGM.Duration;
        //         this.canPlayMusicTime = GameManager.instance.Time + this.DelayBetweenMusicEvent + currentDuration + (Math.random() * this.DelayBetweenMusicEvent);
        //     }
        //     else
        //     {
        //         console.debug("CANT FIND BGM", strBGM);
        //     }
        //
        // }
        let time = this.WorldManager.Time;
        if (this.canPlayMusicTime < time)
        {

            let strBGM = this.findAppropriateMusicEvent();
            let iAudioID = AudioManager.instance.playBgm(strBGM);

            let ctrl = this.getController(this.AUDIO_TYPE_BGM);

            let duration = Math.round(ctrl.getDuration(iAudioID) / 100);


            this.canPlayMusicTime = time + duration + this.MIN_TIME_BETWEEN_BACKGROUND_MUSIC + (Math.random() * this.OFFSET_TIME_BETWEEN_BACKGROUND_MUSIC);
        }
    }

    findAppropriateMusicEvent ()
    {
        let arrBGM = ["day_chilling_001", "day_walking_001", "day_walking_002", "morning_001", "neutral_001", "neutral_002", "neutral_003"];

        let index = _.random(0, arrBGM.length -1 );

        return arrBGM[index];


    }




}