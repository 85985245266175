import { Graphics } from '@pixi/graphics';
import { Sprite } from '@pixi/sprite';
import Gauge from "./Gauge.js";
import SpriteResponsive from "../responsive/SpriteResponsive.js";

export default class WindowGauge extends Gauge
{
    constructor()
    {
        super();
    }

    set Value (fValue)
    {
        fValue = Math.min(Math.max(fValue, 0), 1);
        this.value = this.rectMask.scale.x = fValue;

        let offset = this.icon.width / 2;
        this.icon.x = offset + ((this.rect.width - offset) * this.value);
    }

    /*******************************************
    *   INITIALIZATION
    *******************************************/
    /**
        Parameters to pass to the init function:
        - ui:       UI section object where this component resides
        - type      Type of gauge. Use the constants FOOD, ENERGY and STRESS defined in this object
        - rWidth    Responsive width equation to set for the ContainerResponsive functionnalities
        - value:    (Optional) Default value of the gauge (between 0 and 1). Default is 1
        - bgColor   (Optional) Color of the background of the gauge. Default is 0x332C42
    */
    init(meta)
    {
        meta.bgColor = ("bgColor" in meta ? meta.bgColor : 0x332C42);
        return super.init(meta);
    }

    /*******************************************
    *   BUILD
    *******************************************/
    clean()
    {
        super.clean();
    }

    /*******************************************
    *   GAUGE VISUALS
    *******************************************/
    drawGauge (objSize)
    {
        this.clean();

        let rectWidth = this.evaluate(this.strWidth, 0,0,0,0);
        let ratio = rectWidth / 218;
        let rectHeight = 18 * ratio;
        let cornerRadius = this.CornerRadius;

        if (!this.rect)
        {
            this.rect = new Graphics();
            this.addChild(this.rect);
        }
        this.rect.beginFill(this.bgColor, 1);
        this.rect.drawRoundedRect(0,0, rectWidth, rectHeight, cornerRadius);
        this.rect.endFill();

        if (!this.gradient)
        {
            let texture = this.getGradientTexture();
            this.gradient = new Sprite(texture);
            this.addChild(this.gradient);
        }
        this.gradient.width = rectWidth;
        this.gradient.height = rectHeight;


        if (!this.rectMask)
        {
            this.rectMask = new Graphics();
            this.addChild(this.rectMask);
        }
        this.rectMask.beginFill(0x000000, 1);
        this.rectMask.drawRoundedRect(0,0,rectWidth, rectHeight, cornerRadius);
        this.rectMask.endFill();

        this.gradient.mask = this.rectMask;

        if (!this.icon)
        {
            let iconTexture = this.getIconTexture();
            this.icon = new SpriteResponsive(iconTexture).init({"ui": this.UI});
            this.addChild(this.icon);
        }
        this.icon.anchor.x = this.icon.anchor.y = 0.5;

        this.icon.scale.x = ratio;
        this.icon.scale.y = ratio;

        this.icon.x = this.rect.width * this.val;
        if (this.type == this.ENERGY)
        {
            this.icon.x += objSize.width * 0.4;
        }
        this.icon.y = this.rect.height / 2;

        this.Value = this.value;
    }
}