import { string2hex }  from "@pixi/utils"; 
import ContainerResponsive from "../../commons/responsive/ContainerResponsive.js";
import GraphicsResponsive from "../../commons/responsive/GraphicsResponsive.js";
import Library from "../../../Library.js";
import SpriteResponsive from "../../commons/responsive/SpriteResponsive.js";

export default class DualColorButton extends ContainerResponsive
{
    constructor ()
    {
        super();
    }

    get EVENT_CLICK() { return "click"; }

    get Color1() { return this.colors[0]; }
    set Color1(iNewColor) { this.colors[0] = iNewColor; this.build(); }

    get Color2() { return this.colors[1]; }
    set Color2(iNewColor) { this.colors[1] = iNewColor; this.build(); }

    get Icon() { return this.icon; }
    set Icon(strNewIcon) { this.icon = strNewIcon; this.build(); }

    get ButtonWidth() { return this.strWidth; }
    set ButtonWidth(strNewWidth) { this.strWidth = strNewWidth; this.build(); }

    get ButtonHeight() { return this.strHeight; }
    set ButtonHeight(strNewHeight) { this.strHeight = strNewHeight; this.build(); }

    get IsSelected() { return this.selected; }
    set IsSelected(bNewValue) { this.selected = (bNewValue ? true : false); this.build(); }

    get CornerRadius() { return this.evaluate(this.valueFormula(this.UI.Values.textbutton.corner.radius), 0, 0, 0, 0, this); }
    get StrokeWidth() { return this.evaluate(this.valueFormula(this.UI.Values.colorpick.stroke.size), 0, 0, 0, 0);}
    get StrokeColor() { return string2hex(this.UI.Values.colorpick.stroke.color);}
    get SelectedStrokeColor() { return string2hex(this.UI.Values.colorpick.stroke.selected.color);}

    setSize(strWidth, strHeight)
    {
        this.strWidth = strWidth;
        this.strHeight = strHeight;

        this.build();
    }

    /*******************************************
    *   INITIALIZATION
    *******************************************/
    /**
        Parameters to pass to the init function:
        - ui:       UI section object where this component resides
        - index:    Index number representing the order of this button on screen
        - rWidth:   Responsive equation representing the width of this component
        - rHeight:  Responsive equation representing the height of this component
        - color:    Color to apply to this component
        - color2:   (Optional) Second color to apply to this component. Default is NULL 
        - icon:     (Optional) Name of the texture to use as the icon. Default is NULL
    */
    init(meta)
    {
        this.colors = [
            meta.color,
            ("color2" in meta ? meta.color2 : null)
        ];
        this.icon = ("icon" in meta ? meta.icon : null);
        this.index = meta.index;

        this.graphics = {};
        this.buttonScale = {"x": meta.ui.Values.textbutton.scale.normal, "y": meta.ui.Values.textbutton.scale.normal};

        this.strWidth = meta.rWidth;
        this.strHeight =  meta.rHeight;
        this.selected = false;

        return super.init(meta);
    }

    createClosure()
    {
        super.createClosure();

        this.fctOnClick = this.onClick.bind(this);
        this.fctOnPointerDown = this.onPointerDown.bind(this);
        this.fctOnWindowMouseUp = this.onWindowMouseUp.bind(this);
        this.fctOnWindowTouchEnd = this.onWindowTouchEnd.bind(this);
    }

    bindEvents()
    {
        super.bindEvents();

        window.addEventListener("mouseup", this.fctOnWindowMouseUp);
        window.addEventListener("touchend", this.fctOnWindowTouchEnd);
    }

    destroy(options)
    {
        for (let key in this.graphics)
        {
            if (key == "color1")
            {
                this.graphics.color1.off("pointerdown", this.fctOnPointerDown);
                this.graphics.color1.off("pointerup", this.fctOnClick);
            }

            this.removeChild(this.graphics[key]);
            this.graphics[key].destroy({"children": true});
        }

        window.removeEventListener("mouseup", this.fctOnWindowMouseUp);
        window.removeEventListener("touchend", this.fctOnWindowTouchEnd);

        super.destroy(options);
    }

    /*******************************************
    *   BUILD
    *******************************************/
    build ()
    {
        super.build();

        let values = this.buildFirstColor();

        if (this.Color2 !== null)
        {
            this.buildSecondColor(values.width, values.height, values.graphics);
        }
        if (this.Icon !== null)
        {
            this.buildIcon(values.width, values.height, values.graphics);
        }
    }

    clean()
    {
        for(let key in this.graphics)
        {
            this.graphics[key].clear();
        }
        super.clean();
    }

    buildFirstColor()
    {
        if (!this.graphics.color1)
        {
            this.graphics.color1 = new GraphicsResponsive().init({"ui": this.UI});
            this.graphics.color1.interactive = true;
            this.graphics.color1.on("pointerdown", this.fctOnPointerDown);//@
            this.graphics.color1.on("pointerup", this.fctOnClick);//@

            this.addChild(this.graphics.color1);
        }

        let graphics = this.graphics.color1;
        graphics.buttonMode = !this.IsSelected;

        let strokeWidth = this.StrokeWidth;
        let strokeColor = (this.IsSelected ? this.SelectedStrokeColor : this.StrokeColor);
        let cornerRadius = this.CornerRadius;

        let width = this.evaluate(this.ButtonWidth + " * " + this.buttonScale.x, 0, 0, 0, 0, this);
        let height = this.evaluate(this.ButtonHeight + " * " + this.buttonScale.y, 0, 0, 0, 0, this);

        let x = this.evaluate(this.ButtonWidth + " * ((1-" + this.buttonScale.x + ") / 2)", 0, 0, 0, 0, this);
        let y = this.evaluate(this.ButtonHeight + " * ((1-" + this.buttonScale.y + ") / 2)", 0, 0, 0, 0, this);

        if (strokeWidth > 0)
        {
            graphics.lineStyle({"color":strokeColor, "width":strokeWidth, "alignment":1});
        }

        graphics.beginFill(this.Color1, 1);
        if (cornerRadius > 0)
        {
            graphics.drawRoundedRect(x, y, width, height, cornerRadius);
        }
        else
        {
            graphics.drawRect(x, y, width, height);
        }
        graphics.endFill();

        if (this.Color2 !== null)
        {
            if (!graphics.maskGraphics)
            {
                graphics.maskGraphics = new GraphicsResponsive().init({"ui": this.UI});
                graphics.mask = graphics.maskGraphics;
                graphics.addChild(graphics.maskGraphics);
            }
            else
            {
                graphics.maskGraphics.clear();
            }

            let mask = graphics.maskGraphics;

            if (strokeWidth > 0)
            {
                mask.lineStyle({"color":strokeColor, "width":strokeWidth, "alignment":1});
            }
            mask.beginFill(this.Color1, 1);
            if (cornerRadius > 0)
            {
                mask.drawRoundedRect(x, y, width, height, cornerRadius);
            }
            else
            {
                graphics.drawRect(x, y, width, height);
            }
            mask.endFill();

            this.buildSecondColor(width, height, graphics);

            if (strokeWidth > 0)
            {
                if (!graphics.outlineGraphics)
                {
                    graphics.outlineGraphics = new GraphicsResponsive().init({"ui": this.UI});
                    graphics.addChild(graphics.outlineGraphics);
                }
                else
                {
                    graphics.outlineGraphics.clear();
                }

                graphics.outlineGraphics.lineStyle({"color":strokeColor, "width":strokeWidth, "alignment":1});
                if (cornerRadius > 0)
                {
                    graphics.outlineGraphics.drawRoundedRect(x, y, width, height, cornerRadius);
                }
                else
                {
                    graphics.outlineGraphics.drawRect(x, y, width, height);
                }
            }
        }
        
        return {"width": width, "height": height, "graphics": graphics};
    }

    buildSecondColor(fWidth, fHeight, objParent)
    {
        if (!objParent.secondColor)
        {
            objParent.secondColor = new GraphicsResponsive().init({"ui": this.UI});
            objParent.addChild(objParent.secondColor);
        }
        else
        {
            objParent.secondColor.clear();
            objParent.secondColor.angle = 0;
        }

        let graphics = objParent.secondColor;

        let width = this.evaluate(this.ButtonWidth + " * " + (this.buttonScale.x + 0.5), 0, 0, 0, 0, this);
        let height = this.evaluate(this.ButtonHeight + " * " + (this.buttonScale.y + 0.5), 0, 0, 0, 0, this);

        graphics.beginFill(this.Color2, 1);
        graphics.drawRect(0, 0, width, height);
        graphics.endFill();

        graphics.angle = 150;

        let x = width * 0.85;
        let y = height * 0.05;

        graphics.position.set(x, y);
    }

    buildIcon(fWidth, fHeight, objParent)
    {
        if (!objParent.spriteIcon)
        {
            let texture = Library.getTextureFromAtlas("ui", this.Icon);
            objParent.spriteIcon = new SpriteResponsive(texture).init({"ui": this.UI});
            objParent.addChild(objParent.spriteIcon);
        }

        let sprite = objParent.spriteIcon;

        let ratio = sprite.height / sprite.width;
        let width = fWidth * 0.9;
        let height = width * ratio;

        if (sprite.width < sprite.height)
        {
            ratio = sprite.width / sprite.height;
            height = fHeight * 0.9;
            width = height * ratio;
        }

        let x = fWidth / 2 - width / 2 + width * ((1 - this.buttonScale.x) / 2);
        let y = fHeight / 2 - height / 2;

        //Small hack for hair icon as it is not like other icons. It needs to be put at the very top
        if (this.Icon.includes("hair"))
        {
            y = height * ((1 - this.buttonScale.y) / 2);
        }

        sprite.width = width;
        sprite.height = height;
        sprite.position.set(x, y);

        sprite.tint = this.Color1;
    }

    /*******************************************
    *   EVENTS
    *******************************************/
    onClick()
    {
        if (!this.IsSelected)
        {
            this.buttonScale.x = this.UI.Values.textbutton.scale.normal;
            this.buttonScale.y = this.UI.Values.textbutton.scale.normal;
            this.build();

            this.UI.AudioManager.playSfx(this.UI.Values.audio.click);
            this.emit(this.EVENT_CLICK, this);
        }
    }

    onPointerDown()
    {
        if (!this.IsSelected)
        {
            this.buttonScale.x = this.UI.Values.textbutton.scale.down;
            this.buttonScale.y = this.UI.Values.textbutton.scale.down;
            this.build();
        }
    }

    onWindowMouseUp(e)
    {
        this.buttonScale.x = this.UI.Values.textbutton.scale.normal;
        this.buttonScale.y = this.UI.Values.textbutton.scale.normal;
        this.build();
    }

    onWindowTouchEnd(e)
    {
        if (e.touches.length == 0)
        {
            this.buttonScale.x = this.UI.Values.textbutton.scale.normal;
            this.buttonScale.y = this.UI.Values.textbutton.scale.normal;
            this.build();
        }
    }
}