import BaseCheat from "./BaseCheat.js";

export default class WalkTo extends BaseCheat
{
    constructor(dependencies)
    {
        super(dependencies);
    }

    get CommandUse() { return "walk x,y"; }
    get CommandDesc() { return "Walk to a specific tile"; }

    //---------------------------------------------------------
    //  DEPENDENCIES
    //---------------------------------------------------------
    get ItemManager() { return this.dependencies.get("ItemManager"); }
    get WorldManager() { return this.dependencies.get("WorldManager"); }
    get TriggerManager() { return this.dependencies.get("TriggerManager"); }
    //---------------------------------------------------------

    parseCommand(strCmd, arrArguments)
    {


        if (strCmd == "walk" && arrArguments.length >= 2)
        {

            let [x,z] = arrArguments;

            let player = this.WorldManager.Player;
            let playerPos = player.GridPos;

            let tile = this.WorldManager.Grid.getNearestWalkableTile(x, z, playerPos.x, playerPos.z);

            if (tile)
            {
                player.stopMovement();
                player.preventMovement();
                this.WorldManager.preventWorldClick();


                player.goTo(tile.x * 10, tile.y * 10, false, function (player)
                {
                    player.enableMovement();

                    this.WorldManager.allowWorldClick();

                }
                    .bind(this, player));

                return;

            }

            this.warn("Cannot start walk to", arguments);
        }
    }
}