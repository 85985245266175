import { Graphics } from '@pixi/graphics';
import { Sprite } from '@pixi/sprite';
import ContainerResponsive from "../responsive/ContainerResponsive.js";
import Library from "../../../Library.js"
import SpriteResponsive from "../responsive/SpriteResponsive.js";

export default class Gauge extends ContainerResponsive
{
    constructor()
    {
        super();
    }

    get FOOD () {  return "food"; };
    get ENERGY() {  return "energy"; };
    get STRESS() {  return "stress"; };

    get CornerRadius() { return this.evaluate(this.valueFormula(this.UI.Values.gauge.corner.radius), 0, 0, 0, 0, this); }

    get Value () { return this.val; }
    set Value (fValue)
    {
        fValue = Math.min(Math.max(fValue, 0), 1);
        this.value = this.rectMask.scale.x = fValue;
    }

    /*******************************************
    *   INITIALIZATION
    *******************************************/
    /**
        Parameters to pass to the init function:
        - ui:       UI section object where this component resides
        - type      Type of gauge. Use the constants FOOD, ENERGY and STRESS defined in this object
        - rWidth    Responsive width equation to set for the ContainerResponsive functionnalities
        - value:    (Optional) Default value of the gauge (between 0 and 1). Default is 1
        - bgColor   (Optional) Color of the background of the gauge. Default is 0x4D4362
    */
    init(meta)
    {
        this.type = meta.type;
        if (meta.rWidth)
        {
            this.strWidth = meta.rWidth;
        }
        this.value = ("value" in meta ? meta.value : 1);
        this.bgColor = ("bgColor" in meta ? meta.bgColor : 0x4D4362);

        return super.init(meta);
    }

    destroy(options)
    {
        if (this.line)
        {
            this.removeChild(this.line);
            this.line.destroy(options);
            delete this.line;
        }

        if (this.circle)
        {
            this.removeChild(this.circle);
            this.circle.destroy(options);
            delete this.circle;
        }

        if (this.icon)
        {
            this.removeChild(this.icon);
            this.icon.destroy(options);
            delete this.icon;
        }

        if (this.gradient)
        {
            this.removeChild(this.gradient);
            this.gradient.destroy(options);
            delete this.gradient;
        }

        if (this.rect)
        {
            this.removeChild(this.rect);
            this.rect.destroy(options);
            delete this.rect;
        }

        if (this.rectMask)
        {
            this.removeChild(this.rectMask);
            this.rectMask.destroy(options);
            delete this.rectMask;
        }

        super.destroy(options);
    }


    /*******************************************
    *   BUILD
    *******************************************/
    build ()
    {
        super.build();
        this.drawGauge(this.UI.Size);
    }

    clean()
    {
        super.clean();

        if (this.line)
        {
            this.line.clear();
        }

        if (this.circle)
        {
            this.circle.clear();
        }

        if (this.rect)
        {
            this.rect.clear();
        }

        if (this.rectMask)
        {
            this.rectMask.clear();
        }
    }

    /*******************************************
    *   GAUGE VISUALS
    *******************************************/
    getIconTexture ()
    {
        let texture = "";

        if (this.type === this.FOOD)
            texture = "icon_gauge_food";
        else if (this.type === this.ENERGY)
            texture = "icon_gauge_energy";
        else if (this.type === this.STRESS)
            texture = "icon_gauge_stress";

        return Library.getTextureFromAtlas("ui", texture);
    }

    getGradientTexture ()
    {
        let texture = "";

        if (this.type === this.FOOD)
            texture = "gradient_food";
        else if (this.type === this.ENERGY)
            texture = "gradient_energy";
        else if (this.type === this.STRESS)
            texture = "gradient_stress";

        return Library.getTextureFromAtlas("ui", texture);
    }

    drawGauge (objSize)
    {
        let ratio = objSize.height  / 1080;
        let radius = 34 * ratio;
        let stroke = 6 * ratio;
        let lineWidth = 23 * ratio;
        let rectWidth = 294 * ratio;
        let rectHeight = 36* ratio;
        let rectX = 94* ratio;
        let rectY = radius - (rectHeight / 2);
        let cornerRadius = this.CornerRadius;

        if (!this.line)
        {
            this.line = new Graphics();
            this.addChild(this.line);
        }
        this.line.lineStyle(stroke * 2, 0x26212f, 1);
        this.line.moveTo(0,0);
        this.line.lineTo(lineWidth,0)
        this.line.x = radius * 2;
        this.line.y = radius;


        if (!this.circle)
        {
            this.circle = new Graphics();
            this.addChild(this.circle);
        }
        this.circle.lineStyle(stroke, 0x26212f, 1);
        this.circle.beginFill(this.bgColor, 1);
        this.circle.drawCircle(0,0, radius);
        this.circle.endFill();
        this.circle.x = radius;
        this.circle.y = radius;

        if (!this.icon)
        {
            let iconTexture = this.getIconTexture();
            this.icon = new SpriteResponsive(iconTexture).init({"ui": this.UI});
            this.addChild(this.icon);
        }
        this.icon.anchor.x = this.icon.anchor.y = 0.5;
        this.icon.x = radius;
        this.icon.y = radius;
        this.icon.scale.x = ratio;
        this.icon.scale.y = ratio;

        if (!this.rect)
        {
            this.rect = new Graphics();
            this.addChild(this.rect);
        }
        this.rect.lineStyle(stroke, 0x26212f, 1, 1);
        this.rect.beginFill(this.bgColor, 1);
        this.rect.drawRoundedRect(0,0, rectWidth, rectHeight, cornerRadius);
        this.rect.endFill();
        this.rect.x = rectX;
        this.rect.y = rectY;

        if (!this.gradient)
        {
            let texture = this.getGradientTexture();
            this.gradient = new Sprite(texture);
            this.addChild(this.gradient);
        }
        this.gradient.width = rectWidth;
        this.gradient.height = rectHeight;
        this.gradient.x = rectX;
        this.gradient.y = rectY;

        if (!this.rectMask)
        {
            this.rectMask = new Graphics();
            this.addChild(this.rectMask);
        }
        this.rectMask.beginFill(0x000000, 1);
        this.rectMask.drawRoundedRect(0,0,rectWidth, rectHeight, cornerRadius);
        this.rectMask.endFill();
        this.rectMask.x = rectX;
        this.rectMask.y = rectY;

        this.gradient.mask = this.rectMask;

        this.Value = this.value;
    }

    /*******************************************
    *   RESPONSIVE
    *******************************************/
    layout (objSize)
    {
        super.layout(objSize);

        if (!objSize)
        {
            objSize = Display.getSize();
        }
    }
}