import { string2hex }  from '@pixi/utils';
import { TextStyle } from '@pixi/text';
import { TextMetrics } from '@pixi/text';
import ContainerResponsive from "../responsive/ContainerResponsive.js";
import GraphicsResponsive from "../responsive/GraphicsResponsive.js";
import Library from "../../../Library.js";
import SpriteResponsive from "../responsive/SpriteResponsive.js";
import TextResponsive from "../responsive/TextResponsive.js";

export default class MapObjective extends ContainerResponsive
{
    constructor()
    {
        super();
    }

    get TitleText() { return this.UI.LabelManager.translate("CART_OBJ"); }

    get Description() { return this.description; }
    set Description(strNewValue) { this.description = strNewValue; this.build(); }

    get BgWidth() { return this.bgWidth; }

    get BgMinWidth() { return "(" + this.buildWidth.min + ")"; }
    set BgMinWidth(strNewValue) { this.buildWidth.min = strNewValue; this.build(); }

    get BgMaxWidth() { return "(" + this.buildWidth.max + ")"; }
    set BgMaxWidth(strNewValue) { this.buildWidth.max = strNewValue; this.build(); }

    get BgHeight() { return "(" + this.buildHeight + ")"; }
    set BgHeight(strNewValue) { this.buildHeight = strNewValue; this.build(); }

    get BgX() { return 0; }
    get BgY() { return 0; }
    get TitlePaddingH() { return "(ih * 0.05)"; }
    get TitlePaddingV() { return "(ih * 0.01)"; }

    get DescriptionPaddingH() { return "(ih * 0.05)"; }

    get BackgroundColor() { return string2hex(this.UI.Values.modal.background.color);}
    get StrokeWidth() { return this.evaluate(this.valueFormula(this.UI.Values.modal.stroke.size), 0, 0, 0, 0);}
    get StrokeColor() { return string2hex("color" in this.UI.Values.modal.stroke ? this.UI.Values.modal.stroke.color : "#000000");}
    get CornerRadius() { return this.evaluate(this.valueFormula(this.UI.Values.modal.corner.radius), 0, 0, 0, 0);}

    get TitleStyle()
    {
        return {
            fontFamily: this.UI.Values.general.title.font,
            fontSize:   this.evaluate(this.valueFormula(this.UI.Values.general.title.size * 0.925)),
            fontWeight: this.UI.Values.general.title.weight,
            fill: this.UI.Values.general.title.color,
            align: "center"
        };
    }

    get DescriptionStyle()
    {
        let fontSize = this.evaluate(this.valueFormula(this.UI.Values.general.text.size * 0.75));
        fontSize = Math.max(fontSize, this.UI.ResponsiveManager.DialogMinFontSize * 0.75);

        return {
            fontFamily: this.UI.Values.general.text.font,
            fontSize   ,
            fontWeight: this.UI.Values.general.text.weight,
            fill: this.UI.Values.general.text.color,
            align: "center"
        };
    }

    /*******************************************
    *   INITIALIZATION
    *******************************************/
    /**
        Parameters to pass to the init function:
        - ui:           UI section object where this component resides
        - description:  Description to display inside this component
        - rMinWidth:    Responsive equation representing the minimum width this component should be
        - rMaxWidth:    Responsive equation representing the maximum width this component should be
        - rHeight:      Responsive equation representing the height of this component
    */
    init(meta)
    {
        this.description = meta.description;
        this.buildWidth = {
            "min": meta.rMinWidth,
            "max": meta.rMaxWidth
        };
        this.buildHeight = meta.rHeight;

        this.graphics = {};
        this.texts = {};

        return super.init(meta);
    }

    destroy(options)
    {
        for (let key in this.graphics)
        {
            this.removeChild(this.graphics[key]);
            this.graphics[key].destroy(options);
        }
        delete this.graphics;

        for (let key in this.texts)
        {
            this.removeChild(this.texts[key]);
            this.texts[key].destroy(options);
        }
        delete this.texts;

        super.destroy(options);
    }

    /*******************************************
    *   BUILD
    *******************************************/
    build()
    {
        super.build();

        this.buildDescription();
        this.buildBackground();
        this.buildTitle();
    }

    clean()
    {
        for (let key in this.graphics)
        {
            this.graphics[key].clear();
        }
        super.clean();
    }

    buildBackground()
    {
        if (!this.graphics.background)
        {
            this.graphics.background = new GraphicsResponsive().init({"ui": this.UI});
            this.graphics.background.interactive = true;

            this.addChildAt(this.graphics.background, 0);
        }

        let graphics = this.graphics.background;

        let width = this.evaluate(this.BgWidth, 0, 0, 0, 0, this);
        let height = this.evaluate(this.BgHeight, 0, 0, 0, 0, this);
        let x = this.BgX;
        let y = this.BgY;

        let bgColor = this.BackgroundColor;
        let strokeWidth = this.StrokeWidth;
        let strokeColor = this.StrokeColor;
        let cornerRadius = this.CornerRadius;

        graphics.position.set(
            this.evaluate(x, 0, 0, 0, 0, this),
            this.evaluate(y, 0, 0, 0, 0, this)
        );

        if (strokeWidth > 0)
        {
            graphics.lineStyle({"color":strokeColor, "width":strokeWidth, "alignment":1});
        }

        graphics.beginFill(bgColor, 1);
        if (cornerRadius > 0)
        {
            graphics.drawRoundedRect(0, 0, width, height, cornerRadius);
        }
        else
        {
            graphics.drawRect(0, 0, width, height);
        }
        graphics.endFill();
    }

    buildTitle()
    {
        if (!this.graphics.titleBg)
        {
            this.graphics.titleBg = new GraphicsResponsive().init({"ui": this.UI});
            this.addChild(this.graphics.titleBg);
        }

        if (!this.texts.title)
        {
            this.texts.title = new TextResponsive().init({"ui": this.UI});
            this.addChild(this.texts.title);
        }

        let graphics = this.graphics.titleBg;
        let text = this.texts.title;
        let label = this.TitleText;
        let style = new TextStyle(this.TitleStyle);

        let paddingH = this.TitlePaddingH;
        let paddingV = this.TitlePaddingV;

        let metrics = TextMetrics.measureText(label, style);

        let x = this.BgX + " + " + this.BgWidth + " / 2 - " + metrics.width + " / 2";
        let y = this.BgY + " - " + metrics.height + " / 2 - " + this.StrokeWidth;

        text.text = label;
        text.style = style;
        text.width = metrics.width;
        text.height = metrics.height;
        text.position.set(
            this.evaluate(x, 0, 0, 0, 0, this),
            this.evaluate(y, 0, 0, 0, 0, this)
        );

        x = this.BgX + " + " + this.BgWidth + " / 2 - " + metrics.width + " / 2 - " + paddingH;
        y = this.BgY + " - " + metrics.height + " / 2 - " + paddingV + " - " + this.StrokeWidth;
        let width = this.evaluate(metrics.width + " + " + paddingH + " * 2", 0, 0, 0, 0, this);
        let height = this.evaluate(metrics.height + " + " + paddingV + " * 2", 0, 0, 0, 0, this);

        let bgColor = this.StrokeColor;
        let cornerRadius = this.CornerRadius;

        graphics.beginFill(bgColor, 1);
        if (cornerRadius > 0)
        {
            graphics.drawRoundedRect(0, 0, width, height, cornerRadius);
        }
        else
        {
            graphics.drawRect(0, 0, width, height);
        }
        graphics.endFill();

        graphics.position.set(
            this.evaluate(x, 0, 0, 0, 0, this),
            this.evaluate(y, 0, 0, 0, 0, this)
        );
    }

    buildDescription()
    {
        if (this.texts || !this.texts.description)
        {
            this.texts.description = new TextResponsive().init({"ui": this.UI});
            this.addChild(this.texts.description);
        }

        let text = this.texts.description;
        let label = this.Description;
        let paddingV = this.TitlePaddingV;
        let styleDef = this.DescriptionStyle;

        styleDef.wordWrap = true;

        let style = null;
        let metrics = null;
        let step = "(" + this.BgMaxWidth + " - " + this.BgMinWidth + ") / 8";
        let maxHeight = this.evaluate(this.BgHeight + " - " + paddingV, 0, 0, 0, 0);
        let paddingH = this.DescriptionPaddingH;

        for (let i = 0; i <= 8; i++)
        {
            this.bgWidth = "(" + this.BgMinWidth + " + " + step + " * " + i + ")";
            styleDef.wordWrapWidth = this.evaluate(this.bgWidth + " - " + this.DescriptionPaddingH, 0, 0, 0, 0);

            style = new TextStyle(styleDef);
            metrics = TextMetrics.measureText(label, style);

            if (metrics.height < maxHeight * 0.8)
            {
                break;
            }
        }

        let x = this.BgX + " + " + this.BgWidth + " / 2 - " + metrics.width + " / 2";
        let y = this.BgY + " + " + this.BgHeight + " / 2 - " + metrics.height + " / 2" + " + " + paddingV + " / 2";

        text.text = label;
        text.style = style;
        text.width = metrics.width;
        text.height = metrics.height;
        text.position.set(
            this.evaluate(x, 0, 0, 0, 0, this),
            this.evaluate(y, 0, 0, 0, 0, this)
        );
    }
}