import { string2hex }  from '@pixi/utils';
import Display from "../../../utils/Display.js";
import PausePopup from "./PausePopup.js";
import TextButton from "../buttons/TextButton.js";

export default class ParametersPopup extends PausePopup
{
    constructor()
    {
        super();
    }

    get CREDIT_URL() { return "/credits.html"; }

    get ShowOverlay() { return false; }
    get TitleLabel() { return this.UI.LabelManager.translate("Menu_Param").toUpperCase(); }

    get BgWidth() { return this.evaluate("(ih * 90%)", 0, 0, 0, 0, this); }
    get BgHeight() { return this.evaluate("(ih * 50%)", 0, 0, 0, 0, this); }
    get BgX() { return 0; }
    get BgY() { return 0; }

    get VolumeSubBarWidth() { return this.evaluate(this.valueFormula(3), 0, 0, 0, 0);}
    get VolumeSubBarColor() { return 0xFFFFFF;}

    get BackgroundColor() { return string2hex(this.UI.Values.modal.background.color);}
    get StrokeWidth() { return this.evaluate(this.valueFormula(this.UI.Values.modal.stroke.size), 0, 0, 0, 0);}
    get StrokeColor() { return string2hex("color" in this.UI.Values.modal.stroke ? this.UI.Values.modal.stroke.color : "#000000");}
    get CornerRadius() { return this.evaluate(this.valueFormula(this.UI.Values.modal.corner.radius), 0, 0, 0, 0);}

    /*******************************************
    *   INITIALIZATION
    *******************************************/
    /**
        Parameters to pass to the init function:
        - ui:           UI section object where this component resides
        - showOnStart   (Optional) If the popup should be shown on start. Default is TRUE
        - linked        (Optional) Linked popup to this one. If this one closes, the linked popup will close too. Default is NULL
    */
    init(meta)
    {
        meta.createOverlay = false;
        meta.sideBySide = false;
        meta.clickToHide = false;

        return super.init(meta);
    }

    createClosure()
    {
        super.createClosure();
        this.fctOnCreditsClick = this.onCreditsClick.bind(this);
    }

    /*******************************************
    *   BUILD
    *******************************************/
    build()
    {
        super.build();

        this.buildBtn();
    }

    buildBtn ()
    {
        if (!this.btnCredits)
        {
            this.btnCredits = new TextButton().init({
                "ui": this.UI,
                "text": "Crédits",
                "fontSize": 75,
                "padding": {
                    "horizontal": 125,
                    "vertical": 20
                }
            });

            this.addChild(this.btnCredits);
        }
        
        let size = this.btnCredits.getButtonSize();

        this.btnCredits.rX =       [{on:"default",     x:`(width / 2)`}];
        this.btnCredits.rY =       [{on:"default",     y: `${this.BgHeight} + (${this.BgHeight}) * 10%`}];

        this.btnCredits.on(this.btnCredits.EVENT_CLICK, this.fctOnCreditsClick);//@

        this.btnCredits.layout(Display.getSize())
    }

    buildVolumeControls(baseY)
    {
        super.buildVolumeControls(this.BgHeight / 5);
    }

    buildTitle(fPaddingHorizontalRatio = 1.5, fPaddingVerticalRadio = 0.5)
    {
        super.buildTitle(0.7, fPaddingVerticalRadio);
    }

    buildSave()
    {
        //This method is overridden with nothing to prevent it from creating a Save section
    }

    buildQuit()
    {
        //This method is overridden with nothing to prevent it from creating a Quit button
    }

    /*******************************************
    *   EVENTS
    *******************************************/
    onCreditsClick ()
    {
        window.open(this.CREDIT_URL, "_blank");
    }
}