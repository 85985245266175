import EventEmitter from "eventemitter3";
import axios from "axios";
import Constants from "../utils/Constants.js";
import Utils from "../utils/Utils.js";
import { TextureLoader } from "three";
import Library from "../Library.js";

export default class ThreeJSMultipackLoader extends EventEmitter
{
    constructor()
    {
        super();
    }

    async load (strURL, strKey)
    {
        let first = await this.loadFirst(strURL);

        let related = first.data.meta.related_multi_packs;
        if (!related)
        {
            related = [];
        }

        let strBase = Utils.dirname(strURL);

        let frames = await this.loadRelated(strBase, related);

        frames = [...first.data.frames, ...frames];

        Library.addTextures3DFrames(strKey, frames);

    }

    async loadFirst (strURL)
    {
        return  await this.loadAtlas(strURL);
    }

    async loadRelated (strBase, related)
    {
        let frames = [];
        for (let i = 0; i < related.length; i++)
        {
            let atlas  = await this.loadAtlas(strBase + related[i]);
            let relatedFrames = atlas.data.frames;

            frames = [...frames, ...relatedFrames];
        }

        return frames;
    }

    async loadJson (strURL)
    {
        let response = await axios.get(strURL);

        let data = response.data;

        let basename = Utils.basename(strURL);
        let key = basename.replace(".json", ".png");
        this.addTextureKeyToFrames(key, data.frames);

        return data;
    }


    async loadTexture (strURL, strKey)
    {
        let loader = new TextureLoader();

        let texture = await this.promiseLoader(strURL, loader);

        if (!strKey)
            strKey = Utils.basename(strURL);

        Library.addTexture3D(`textures-${strKey}`, texture);
        this.emit("end-load-asset");


        return texture;
    }

    addTextureKeyToFrames (key, frames)
    {
        for (let i = 0; i < frames.length; i++)
        {
            let frame = frames[i];
            frame.key = "textures-" + key;
        }
    }
    promiseLoader(url, loader)
    {
        return new Promise((resolve, reject) => {
            loader.load(url, data=> {
                resolve(data);
            }, null, reject);
    });
}

    async loadAtlas (strURL)
    {
        let strBase = Utils.dirname(strURL);

        let data = await this.loadJson(strURL);
        let texture = await this.loadTexture(strBase + data.meta.image);


        return {data, texture};
    }

    async loadCharacterAtlases(objAtlases)
    {
        for (let i = 0; i < objAtlases.objects.length; i++)
        {
            let key = objAtlases.objects[i].key;
            //let url = (objAtlases.path.substr(0, 2) != "/_" ? "/_" : "") + Constants.getValue("HERO_ANIMATION_URL_PREFIX");
            let url = "https://lesmutants.s3.amazonaws.com";
            url += objAtlases.path + objAtlases.objects[i].name;

            let loader = new TextureLoader();

            let texture = await this.promiseLoader(url, loader);

            Library.addTexture3D("textures-" + key, texture);
            this.emit("end-load-atlas-asset", key);
        }
        this.emit("end-load-atlas", objAtlases);
    }
}