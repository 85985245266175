import {Vector2} from "three";
import BaseObject from "./BaseObject.js";
import CharacterAction from "./actions/CharacterAction.js";
import Constants from "../../utils/Constants.js";
import Direction from "../../utils/Direction.js";
import Lerp from "../../utils/Lerp.js";

export default class Character extends BaseObject
{
    constructor()
    {
        super();
    }

    get EVENT_MOVEMENT_START() { return "movement-start"; }
    get EVENT_MOVEMENT_END() { return "movement-end"; }
    get EVENT_MOVEMENT_TILE_CHANGED() { return "movement-tile-changed"; }
    get EVENT_STUN_START() { return "stun-start"; }
    get EVENT_STUN_END() { return "stun-end"; }
    get EVENT_INVINCIBILITY_START() { return "invincibility-start"; }
    get EVENT_INVINCIBILITY_END() { return "invincibility-end"; }
    get EVENT_INVISIBILITY_START() { return "invisibility-start"; }
    get EVENT_INVISIBILITY_END() { return "invisibility-end"; }

    get CHANGE_DIRECTION_WAIT_TIME() { return 0.33; }
    get STUN_OPACITY() { return Constants.getValue("CHARACTER_STUN_OPACITY"); }
    get INVISIBLE_OPACITY() { return Constants.getValue("CHARACTER_INVISIBLE_OPACITY"); }
    get INVINCIBILITY_TIME_AFTER_STUN() { return 1; }

    //--------------------------------------------
    //  SPRITESHEET ANIMATIONS
    //--------------------------------------------
    get ANIMATION_IDLE() { return "idle"; }
    get ANIMATION_RUN() { return "run"; }
    //--------------------------------------------

    get Type() { return this.meta.type; }
    get MovementSpeed() { return this.baseMovementSpeed; }
    get IsMoving() { return this.CharacterAction.IsMoving; }
    get Destination()
    {
        if (this.path && this.path.length > 0)
        {
            return new Vector2(this.path[this.path.length -1][0], this.path[this.path.length -1][1]);
        }
        return null;
    }
    /**
        Which direction the player is facing (Please use utils/Direction.js)
        0: North
        1: East
        2: South
        3: West
    */
    get Direction() { return this.direction; }
    get CharacterAction() { return this.characterAction; }
    get IsDead() { return false; }
    get IsInvincible() { return this.invincibilityTimeLeft > 0; }
    get IsInvisible() { return this.invisibleTimeLeft > 0; }
    get IsStun() { return this.stunTimeLeft > 0; }
    get CanMove() { return !this.IsStun && !this.IsDead; }

    /*******************************************
    *   INITIALIZATION
    *******************************************/
    /**
        Parameters to pass to the init function:
        - id:           Logic id of this object instance. Usually managed by some sort of global manager
        - type:         Type of character cateogrizing this object
        - spawnPos      Vector2 containing the world position to spawn this object in ThreeJS
        - direction     Initial direction where this character will look at. Use the Direction class at utils/Direction.js
        - environment   Environment instance where this character lives
        - meshOrigin    (Optional)Relative position of the mesh's geometry. Default is {"x": 0.5, "y": 1}
        - params        JSON object containing additionnal parameters to pass to this character

    */
    init(meta)
    {
        this.baseMovementSpeed = this.GameManager.getSetting("map").walkspeed.default;
        this.stunTimeLeft = 0;
        this.invincibilityTimeLeft = 0;
        this.invisibleTimeLeft = 0;
        this.directionTimeLeft = 0;
        this.nextDirection = 0;
        this.direction = meta.direction;
        this.createCharacterAction();

        super.init(meta);
        this.createAnimationController();
    }

    createClosure()
    {
        super.createClosure();
        this.fctOnMoveStart = this.onMoveStart.bind(this);
        this.fctOnMoveEnd = this.onMoveEnd.bind(this);
        this.fctOnTileChanged = this.onTileChanged.bind(this);
    }

    bindEvents()
    {
        super.bindEvents();
        this.on(this.EVENT_MOVEMENT_START, this.fctOnMoveStart);
        this.on(this.EVENT_MOVEMENT_END, this.fctOnMoveEnd);
        this.on(BaseObject.EVENT_TILE_CHANGED, this.fctOnTileChanged);
    }

    destroy()
    {
        this.characterAction.destroy();
        if (this.animationController)
        {
            this.animationController.destroy();
        }
        super.destroy();
    }

    createCharacterAction()
    {
        this.characterAction = new CharacterAction(this);
    }

    createAnimationController()
    {
        this.animationController = null;
        console.warn("Must be overriden by child class");
    }

    /*******************************************
    *   UPDATE LOOP
    *******************************************/
    update(fDeltaTime)
    {
        super.update(fDeltaTime);

        this.updateAnimation(fDeltaTime);


        if (!this.GameManager.IsPaused)
        {
            this.updateAction(fDeltaTime);
            this.updateStun(fDeltaTime);
            this.updateInvincibility(fDeltaTime);
            this.updateInvisibility(fDeltaTime);
            this.updateDirectionChange(fDeltaTime);
        }
    }

    updateAction(fDeltaTime)
    {
        if (this.characterAction)
        {
            this.characterAction.update(fDeltaTime);
        }
    }

    updateAnimation(fDeltaTime)
    {
        if (this.animationController)
        {
            if (this.GameManager.IsPaused)
            {
                if (this.animationController.IsPlaying && !this.animationController.IsPaused)
                {
                    this.animationController.pause();
                }
            }
            else
            {
                if (this.animationController.IsPaused)
                {
                    this.animationController.resume();
                }
                
                if (this.animationController.Speed != this.MovementSpeed)
                {
                    this.animationController.Speed = this.MovementSpeed;
                }
            }
        }
    }

    updateStun(fDeltaTime)
    {
        if (this.stunTimeLeft > 0)
        {
            this.stunTimeLeft -= fDeltaTime;
            if (this.stunTimeLeft <= 0)
            {
                this.invincibilityTimeLeft = this.INVINCIBILITY_TIME_AFTER_STUN;
                if (!this.IsInvisible)
                {
                    this.Mesh.material.opacity = 1;
                }
                this.emit(this.EVENT_STUN_END, this);
            }
        }
    }

    updateInvincibility(fDeltaTime)
    {
        if (this.invincibilityTimeLeft > 0)
        {
            this.invincibilityTimeLeft -= fDeltaTime;
            if (this.invincibilityTimeLeft <= 0)
            {
                this.emit(this.EVENT_INVINCIBILITY_END, this);
            }
        }
    }

    updateInvisibility(fDeltaTime)
    {
        if (this.invisibleTimeLeft > 0)
        {
            this.invisibleTimeLeft -= fDeltaTime;
            if (this.invisibleTimeLeft <= 0)
            {
                if (!this.IsStun)
                {
                    this.Mesh.material.opacity = 1;
                }
                this.emit(this.EVENT_INVISIBILITY_END, this);
            }
        }
    }

    updateDirectionChange(fDeltaTime)
    {
        if (this.directionTimeLeft > 0)
        {
            this.directionTimeLeft -= fDeltaTime;
            if (this.directionTimeLeft <= 0)
            {
                this.changeDirection(this.nextDirection, true);
            }
        }
    }

    /*******************************************
    *   MOVEMENT
    *******************************************/
    /**
        Goes to a position in the ThreeJS environment. The position should be set in world units, not grid units.
    */
    goTo(iX, iY, bSkipDiagonals = false, fctCallback = null)
    {
        if (this.CharacterAction && this.CanMove)
        {
            return this.CharacterAction.goTo(iX, iY, [this.Mesh], bSkipDiagonals, fctCallback);
        }
        return null;
    }

    stopMovement()
    {
        if (this.CharacterAction)
        {
            this.CharacterAction.stop();
        }
    }

    preventMovement()
    {
        this.CharacterAction.CanMove = false;
    }

    enableMovement()
    {
        this.CharacterAction.CanMove = true;
    }

    /*******************************************
    *   SPECIAL STATES
    *******************************************/
    setStun(fDuration)
    {
        let shouldEmit = this.stunTimeLeft <= 0;
        this.stunTimeLeft = fDuration;
        this.Mesh.material.opacity = this.STUN_OPACITY;
        this.stopMovement();

        if (shouldEmit)
        {
            this.emit(this.EVENT_STUN_START, this);
        }
    }

    renderInvisible(fDuration)
    {
        let shouldEmit = this.stunTimeLeft <= 0;
        this.invisibleTimeLeft = fDuration;
        this.Mesh.material.opacity = this.INVISIBLE_OPACITY;
    }

    /*******************************************
    *   VISUAL ANIMATION
    *******************************************/
    changeDirection(iNewDirection, bInstant = true)
    {
        if (!bInstant)
        {
            this.nextDirection = iNewDirection;
            if (this.directionTimeLeft <= 0)
            {
                this.directionTimeLeft = this.CHANGE_DIRECTION_WAIT_TIME;
            }
        }
        else
        {
            if (this.direction != iNewDirection)
            {
                if (this.animationController)
                {
                    this.animationController.changeDirection(iNewDirection);
                }
            }
            this.direction = iNewDirection;
        }
    }

    /*******************************************
    *   EVENTS
    *******************************************/
    onMoveStart()
    {
        if (this.animationController)
        {
            this.animationController.play(this.ANIMATION_RUN, this.Direction, true);
        }
    }

    onMoveEnd()
    {
        if (this.animationController)
        {
            this.animationController.play(this.ANIMATION_IDLE, this.Direction, true);
        }
    }

    onTileChanged(objCharacter, objNewPos)
    {
        if (this.WorldManager)
            this.WorldManager.emit(this.WorldManager.EVENT_CHARACTER_TILE_CHANGED, this, objNewPos.x, objNewPos.y);
    }
}