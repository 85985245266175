import EventEmitter from "eventemitter3";

export default class HowlerController extends EventEmitter
{
    constructor(audio, type, sprites, volume)
    {
        super();

        this.audio = audio;
        this.type = type;
        this.sprites = sprites;
        this.volume =  volume;

        this.audio.on("fade", this.onFade.bind(this));
        this.audio.on("play", this.onPlay.bind(this));
        this.audio.on("stop", this.onStop.bind(this));
        this.arrPlaying = [];
        this.fade = [];
    }

    canPlay (strKey)
    {
        return this.sprites.find( sp => sp === strKey);
    }

    play (key, bLoop = false, iFadeInDuration = 0)
    {

        let id = this.audio.play(key);
        console.log("%c Audio ID " + id + "-" + key, "background:#000000; color: #FFFFFF");

        let obj = {id, key};

        this.arrPlaying.push(obj);
    }

    stop (id = null, key = "", iFadeOutDuration = 0)
    {
        let playing;

        playing = this.arrPlaying.filter( obj => obj.key === key || obj.key === id || obj.id == id);


        if (playing.length === 0)
            return;

        if (iFadeOutDuration === 0)
        {
            for (let i = 0; i < playing.length; i++)
            {
                let currentPlaying = playing[i];
                currentPlaying.onFade = null;
                this.audio.stop(currentPlaying.id);
                console.log("%c STOP Audio ID " + playing[i].id + "-" + playing[i].key, "background:#990000; color: #FFFFFF");

            }

        }
        else
        {
            for (let i = 0; i < playing.length; i++)
            {
                let currentPlaying = playing[i];
                
                currentPlaying.onFade = () => this.stop(currentPlaying.id, null, 0);
                this.fadeAudio(currentPlaying, iFadeOutDuration, this.volume, 0 );

            }



        }
    }

    isPlaying (id = null)
    {

        let playing = this.arrPlaying.filter( playing => playing.key === id || playing.key === id || playing.id === id);


        if (playing.length > 0)
        {
            let isPlaying = false;

            for (let i = 0; i < playing.length; i++)
            {
                let isPlaying = this.audio.playing(playing[i].id);

                if (isPlaying)
                    return true;
            }

            return false;
        }

        return false;
    }

    stopAll (key, iFadeOut = 0)
    {
        for (let i = 0; i < this.arrPlaying.length; i++)
        {
            let obj = this.arrPlaying[i];

            this.stop(obj.id, obj.key, iFadeOut)

        }
    }

    fadeAudio (obj, duration, from, to, callback)
    {
        let volume = this.volume;
        this.audio.fade(volume, to, duration * 1000, obj.id)
    }

    onFade (id)
    {

        let fade = this.arrPlaying.find( obj => obj.id === id);

        if (fade && fade.onFade) //we have found the object and it has a callbakc
            fade.onFade();
    }

    onStop (id)
    {

        if (id)
            this.arrPlaying = this.arrPlaying.filter( obj => obj.id !== id);
    }

    onPlay (id)
    {
        //console.log("onPlay", id, arguments)
    }

    setVolume (fNewValue)
    {
        this.volume = fNewValue;

        this.audio.volume(fNewValue);
    }

    getDuration (iAudioID)
    {
        return this.audio.duration(iAudioID);
    }

}