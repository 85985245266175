import { string2hex }  from "@pixi/utils"; 
import ContainerResponsive from "../../commons/responsive/ContainerResponsive.js";
import GraphicsResponsive from "../../commons/responsive/GraphicsResponsive.js";

export default class BaseCreationContent extends ContainerResponsive
{
    constructor()
    {
        super();
    }

    get CharacterCreator() { return this.creator; }

    get Character() { return this.character; }
    set Character(iNewValue) { this.character = iNewValue; }

    get BgWidth() { return this.strWidth; }
    set BgWidth(strNewValue) { this.strWidth = strNewValue; }

    get BgHeight() { return this.strHeight; }
    set BgHeight(strNewValue) { this.strHeight = strNewValue; }

    get BgX() { return 0; }
    get BgY() { return 0; }

    get BackgroundColor() { return string2hex(this.UI.Values.modal.background.color);}
    get StrokeWidth() { return this.evaluate(this.valueFormula(this.UI.Values.modal.stroke.size), 0, 0, 0, 0);}
    get StrokeColor() { return string2hex("color" in this.UI.Values.modal.stroke ? this.UI.Values.modal.stroke.color : "#000000");}
    get CornerRadius() { return this.evaluate(this.valueFormula(this.UI.Values.modal.corner.radius), 0, 0, 0, 0);}

    /*******************************************
    *   INITIALIZATION
    *******************************************/
    /**
        Parameters to pass to the init function:
        - ui:       UI section object where this component resides
        - creator:  Character creator owning this content
        - character Id of the currently selected character
        - rWidth:   Responsive equation representing the width of this component
        - rHeight:  Responsive equation representing the height of this component
    */
    init(meta)
    {
        this.creator = meta.creator;

        this.strWidth = "(" + meta.rWidth + ")";
        this.strHeight = "(" + meta.rHeight + ")";

        this.graphics = {};
        this.sprites = {};
        this.texts = {};

        this.character = meta.character;

        return super.init(meta);
    }

    createClosure()
    {
        super.createClosure();

        this.fctOnWindowMouseUp = this.onWindowMouseUp.bind(this);
        this.fctOnWindowTouchEnd = this.onWindowTouchEnd.bind(this);
    }

    bindEvents()
    {
        super.bindEvents();

        window.addEventListener("mouseup", this.fctOnWindowMouseUp);
        window.addEventListener("touchend", this.fctOnWindowTouchEnd);
    }

    destroy(options)
    {
        window.removeEventListener("mouseup", this.fctOnWindowMouseUp);
        window.removeEventListener("touchend", this.fctOnWindowTouchEnd);

        for (let key in this.graphics)
        {
            this.removeChild(this.graphics[key]);
            this.graphics[key].destroy(options);
        }
        delete this.graphics;

        for (let key in this.sprites)
        {
            this.removeChild(this.sprites[key]);
            this.sprites[key].destroy(options);
        }
        delete this.sprites;

        for (let key in this.texts)
        {
            this.removeChild(this.texts[key]);
            this.texts[key].destroy(options);
        }
        delete this.texts;

        super.destroy(options);
    }

    /*******************************************
    *   BUILD
    *******************************************/
    build()
    {
        super.build();

        this.buildBackground();
    }

    clean()
    {
        for (let key in this.graphics)
        {
            this.graphics[key].clear();
        }
        super.clean();
    }

    buildBackground()
    {
        if (!this.graphics.bg)
        {
            this.graphics.bg = new GraphicsResponsive().init({"ui": this.UI});
            this.addChild(this.graphics.bg);
        }

        let graphics = this.graphics.bg;

        let bgColor = this.BackgroundColor;
        let strokeWidth = this.StrokeWidth;
        let strokeColor = this.StrokeColor;
        let cornerRadius = this.CornerRadius;

        let x = this.BgX;
        let y = this.BgY;
        let width = this.evaluate(this.BgWidth, 0, 0, 0, 0);
        let height = this.evaluate(this.BgHeight, 0, 0, 0, 0);

        if (strokeWidth > 0)
        {
            graphics.lineStyle({"color":strokeColor, "width":strokeWidth, "alignment":1});
        }

        graphics.beginFill(bgColor, 1);
        if (cornerRadius > 0)
        {
            graphics.drawRoundedRect(0, 0, width, height, cornerRadius);
        }
        else
        {
            graphics.drawRect(0, 0, width, height);
        }
        graphics.endFill();

        graphics.position.set(x, y);
    }

    /*******************************************
    *   EVENTS
    *******************************************/
    onWindowMouseUp(e)
    {
        
    }

    onWindowTouchEnd(e)
    {
        
    }
}