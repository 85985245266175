import { Color } from "three/build/three.module.js";

var ColorMultiplyShader = {

    uniforms: {

        "tDiffuse": { value: null },
        "color": { value: new Color( 1, 1, 1 ) }

    },

    vertexShader: [

        "varying vec2 vUv;",

        "void main() {",

        "   vUv = uv;",
        "   gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );",

        "}"

    ].join( "\n" ),

    fragmentShader: [

        "uniform vec3 color;",

        "uniform sampler2D tDiffuse;",

        "varying vec2 vUv;",

        "void main() {",

        "   vec4 texel = texture2D( tDiffuse, vUv );",
        "   vec3 newColor = vec3( texel.x * color.x, texel.y * color.y, texel.z * color.z );",
        "   gl_FragColor = vec4( newColor, texel.w );",

        "}"

    ].join( "\n" )

};

export { ColorMultiplyShader };
