import Constants from "../../utils/Constants.js";
import Obstacle from "../objects/Obstacle.js";

export default class MapObstacles
{
    constructor()
    {

    }

    get QUEUE_PER_FRAME() { return 3; }
    get POOL_SIZE() { return Constants.getValue("MAP_OBSTACLE_POOL_SIZE"); }

    //---------------------------------------------------------
    //  DEPENDENCIES
    //---------------------------------------------------------
    get ItemManager() { return this.Dependencies.get("ItemManager"); }
    get WorldManager() { return this.Dependencies.get("WorldManager"); }
    //---------------------------------------------------------

    get Dependencies() { return this.dependencies; }
    get Environment() { return this.environment; }
    get Grid() { return this.environment.Grid; }
    get Scene() { return this.Environment.Scene; }
    get SpawnableEnemies() { return this.spawnableEnemies; }
    get IsIndoor() { return this.Environment.IsIndoor; }
    get CellsContent()
    { 
        if (this.Environment.IsIndoor)
        {
            return this.WorldManager.getAllCellContents(
                this.Environment.Id,
                this.Environment.RoomId
            );
        }
        return this.WorldManager.getAllCellContents(); 
    }
    get BackpacksContent()
    { 
        if (this.Environment.IsIndoor)
        {
            return this.WorldManager.getAllBackpackCells(
                this.Environment.Id,
                this.Environment.RoomId
            ); 
        }
        return this.WorldManager.getAllBackpackCells(); 
    }
    get LoadOffset()
    {
        if (this.IsIndoor)
        {
            return Number.MAX_VALUE;
        }
        return Constants.getValue("MAP_OBSTACLE_OUTDOOR_LOAD_OFFSET"); 
    }

    /*******************************************
    *   INITIALIZATION
    *******************************************/
    /**
        Parameters to pass to the init function:
        - dependencies: Dependency container used to communicate with the different modules of the game
        - parser:       Obstacle parsers to populate the map with
        - environment   Environment which this object operates in
    */
    init(params)
    {
        this.dependencies = params.dependencies;
        this.environment = params.environment;
        this.parser = params.parser;

        this.obstaclesOnScreen = {};
        this.spawnableEnemies = {};
        this.pools = {};
        this.inProcess = {};
        this.loadQueue = [];
        this.unloadQueue = [];

        this.createClosure();
        this.bindEvents();

        this.calculateFurthestEnemyRange();

        return this;
    }

    createClosure()
    {
        this.fctOnObstacleDespawnSelf = this.onObstacleDespawnSelf.bind(this);
    }

    bindEvents()
    {

    }

    destroy()
    {
        for (let id in this.pools)
        {
            for (let i = 0; i < this.pools[id].length; i++)
            {
                this.pools[id][i].destroy();
            }
        }

        let ids = Object.keys(this.obstaclesOnScreen);
        for (let i = 0; i < ids.length; i++)
        {
            this.Environment.removeObstacle(this.obstaclesOnScreen[ids[i]]);
        }
    }

    calculateFurthestEnemyRange()
    {
        this.furtherEnemyRange = 20;

        let enemyDefinitions = this.WorldManager.enemies;
        for (let id in enemyDefinitions)
        {
            let range = this.calculateEnemyRange(enemyDefinitions[id]);
            if (range > this.furtherEnemyRange)
            {
                this.furtherEnemyRange = enemyDefinitions[id].ai.roaming.range;
            }
        }
    }

    /**
        Gets if a tile should be crossed by swimming
        @param iX   X position of the tile on the grid
        @param iY   Y position of the tile on the grid
        @return     Returns if the tile is swimmable or not
    */
    getIsTileSwimmable(iX, iY)
    {
        let obstacle = this.parser.getAt(iX, iY, this.parser.TYPE_ITEM);
        return (obstacle && obstacle.IsSwimmable) ? true : false;
    }

    /*******************************************
    *   DISPLAY MANAGEMENT
    *******************************************/
    updateDisplay(iX, iY)
    {
        let rect = this.calculateLoadRect(iX, iY);
        let okList = [];
        let allContent = this.CellsContent;
        let allBackpacks = this.BackpacksContent;

        let objects = this.parser.getObjectsInArea(rect.min.x, rect.min.y, rect.max.x, rect.max.y);
        let enemies = this.parser.getEnemiesInArea(
            Math.max(0, rect.min.x - this.furtherEnemyRange), 
            Math.max(0, rect.min.y - this.furtherEnemyRange), 
            Math.min(this.Grid.Width, rect.min.x + this.furtherEnemyRange), 
            Math.min(this.Grid.Height, rect.min.y + this.furtherEnemyRange)
        );

        //OBSTACLES
        //--------------------
        for (let x = rect.min.x; x <= rect.max.x; x++)
        {
            for (let y = rect.min.y; y <= rect.max.y; y++)
            {
                let id = this.Grid.getTileId(x, y);
                if (!this.inProcess[id])
                {
                    if (allContent[id])
                    {
                        if (!this.obstaclesOnScreen[id])
                        {
                            let itemId = allContent[id].id;
                            let item = this.ItemManager.getItem(itemId);
                            let qty =allContent[id].qty;

                            this.spawnObstacle(
                                x, 
                                y,
                                item,
                                qty
                            );
                        }
                        okList.push(id);
                    }
                    if (allBackpacks[id])
                    {
                        if (!this.obstaclesOnScreen[id])
                        {
                            this.spawnObstacle(
                                x, 
                                y, 
                                this.ItemManager.getBackpack(allBackpacks[id].level),
                                1
                            );
                        }
                        okList.push(id);
                    }
                    else if (objects[x] && objects[x][y] && !objects[x][y].IsEmpty)
                    {
                        if (!this.obstaclesOnScreen[id])
                        {
                            this.spawnObstacle(x, y, objects[x][y], 1);
                        }
                        okList.push(id);
                    }
                }
            }
        }
        let ids = Object.keys(this.obstaclesOnScreen);
        for (let i = 0; i < ids.length; i++)
        {
            let iId = parseInt(ids[i]);
            if (!okList.includes(iId) && !this.unloadQueue.includes(iId))
            {
                this.despawnObstacle(iId);
            }
        }

        //ENEMIES
        //--------------------
        okList = [];
        for (let x in enemies)
        {
            for (let y in enemies[x])
            {
                let id = this.Grid.getTileId(x, y);
                let range = this.calculateEnemyRange(enemies[x][y]);
                if (x >= range || y >= range)
                {
                    this.spawnableEnemies[id] = enemies[x][y];
                    okList.push(id);
                }
            }
        }
        ids = Object.keys(this.spawnableEnemies);
        for (let i = 0; i < ids.length; i++)
        {
            let iId = parseInt(ids[i]);
            if (!okList.includes(iId))
            {
                delete this.spawnableEnemies[iId];
            }
        }
    }

    calculateEnemyRange(objDefinition)
    {
        let range = 0;
        if (objDefinition.ai && objDefinition.ai.roaming && objDefinition.ai.roaming.range)
        {
            range = objDefinition.ai.roaming.range;
        }
        return range;
    }

    calculateLoadRect(iX, iY)
    {
        iX = parseFloat(iX);
        iY = parseFloat(iY);

        let offset = this.LoadOffset;
        return {
            "min": {
                "x": Math.max(0, iX - offset),
                "y": Math.max(0, iY - offset)
            },
            "max": {
                "x": Math.min(this.Grid.Width, iX + offset),
                "y": Math.min(this.Grid.Height, iY + offset)
            }
        };
    }

    /*******************************************
    *   SPAWN MANAGEMENT
    *******************************************/
    spawnObstacle(iX, iY, objItem, iQuantity)
    {
        if (objItem && objItem.IsSpawnable)
        {
            let id = this.Grid.getTileId(iX, iY);

            if (objItem.id === "NO-ASP0")
            {
                let a = 1;

            }

            if (objItem.shouldSpawn(id))
            {
                this.inProcess[id] = true;

                this.addToLoadQueue({
                    "id": id,
                    "pos": {"x": iX, "y": iY},
                    "item": objItem,
                    "qty": iQuantity
                });


            }
        }
    }

    despawnObstacle(iId)
    {
        this.addToUnloadQueue(iId);
    }

    getFromPool(strId)
    {
        if (this.pools[strId] && this.pools[strId].length > 0)
        {
            return this.pools[strId].shift();
        }

        return null;
    }

    putBackInPool(objObstacle)
    {
        if (!this.pools[objObstacle.Item.Id])
        {
            this.pools[objObstacle.Item.Id] = [];
        }

        if (this.pools[objObstacle.Item.Id].length < this.POOL_SIZE)
        {
            this.pools[objObstacle.Item.Id].push(objObstacle);
            return true;
        }

        return false;
    }

    /*******************************************
    *   LOAD QUEUE
    *******************************************/
    addToLoadQueue(objData)
    {
        let restartQueue = this.loadQueue.length == 0 && this.unloadQueue.length == 0;
        this.loadQueue.push(objData);

        if (restartQueue)
        {
            this.updateQueues();
        }
    }

    addToUnloadQueue(iId)
    {
        let restartQueue = this.loadQueue.length == 0 && this.unloadQueue.length == 0;
        this.unloadQueue.push(iId);

        if (restartQueue)
        {
            this.updateQueues();
        }
    }

    updateQueues()
    {
        let loopCount = this.QUEUE_PER_FRAME;

        //LOADING OBSTACLES
        for (let i = 0; i < loopCount && this.loadQueue.length > 0; i++)
        {
            let data = this.loadQueue.shift();



            let obstacle = this.getFromPool(data.item.Id);
            let shouldMap = !obstacle;

            let x = data.pos.x;
            let y = data.pos.y;

            if (data.item && data.item.offset)
            {
                x += data.item.offset.x;
                y += data.item.offset.z;
            }
            obstacle = this.Environment.spawnObstacle(
                data.item,
                x,
                y,
                data.qty,
                obstacle
            );

            if (obstacle)
            {
                obstacle.tileId = data.id;


                if (shouldMap)
                {
                    obstacle.on(obstacle.DESPAWN_SELF, this.fctOnObstacleDespawnSelf);
                }

                delete this.inProcess[data.id];
                this.obstaclesOnScreen[data.id] = obstacle;
            }

        }

        //UNLOADING OBSTACLES
        for (let i = 0; i < loopCount && this.unloadQueue.length > 0; i++)
        {
            let id = this.unloadQueue.shift();

            if (this.obstaclesOnScreen[id])
            {
                this.Environment.removeObstacle(
                    this.obstaclesOnScreen[id],
                    !this.putBackInPool(this.obstaclesOnScreen[id])
                );
            }
            delete this.obstaclesOnScreen[id];
        }

        if (this.loadQueue.length > 0 || this.unloadQueue.length > 0)
        {
            window.requestAnimationFrame(this.updateQueues.bind(this));
        }
    }

    /*******************************************
    *   EVENTS
    *******************************************/
    onObstacleDespawnSelf(sender)
    {
        let id = sender.tileId;
        if (!id)
        {
            let gridPos = this.Grid.worldToGridPosition(sender.Mesh.position.x, sender.Mesh.position.z);
            id = this.Grid.getTileId(gridPos.x, gridPos.y);
        }
        this.addToUnloadQueue(id);
    }
}