import { string2hex }  from "@pixi/utils"; 
import ContainerResponsive from "../responsive/ContainerResponsive.js";
import GraphicsResponsive from "../responsive/GraphicsResponsive.js";
import Library from "../../../Library.js";

export default class TextboxResponsive extends ContainerResponsive
{
    constructor ()
    {
        super();
    }

    static get EVENT_CHANGE() { return "change"; }
    static get EVENT_FOCUS() { return "focus"; }
    static get EVENT_FOCUS_OUT() { return "focusout"; }
    static get EVENT_BLUR() { return "blur"; }

    get Text() { return this.text; }
    set Text(strNewValue) { this.text = strNewValue; if (this.textbox){ this.textbox.value = strNewValue; } this.build(); }

    get Placeholder() { return this.placeholder; }
    set Placeholder(strNewValue) { this.placeholder = strNewValue; this.build(); }

    get Align() { return this.align; }
    set Align(strNewValue) { this.align = strNewValue; this.build(); }

    get IsError() { return this.isError; }
    set IsError(bNewValue) { this.isError = (bNewValue ? true : false); this.build(); }

    get DrawWidth() { return this.strWidth; }
    set DrawWidth(strNewValue) { this.strWidth = strNewValue; this.build(); }

    get DrawHeight() { return this.strHeight; }
    set DrawHeight(strNewValue) { this.strHeight = strNewValue; this.build(); }

    get BackgroundColor() { return this.UI.Values.textbox.background.color;}
    get BackgroundColorHex() { return string2hex(this.UI.Values.textbox.background.color);}
    get StrokeWidth() { return this.evaluate(this.valueFormula(this.UI.Values.textbox.stroke.size), 0, 0, 0, 0);}
    get StrokeColor() { return string2hex("color" in this.UI.Values.textbox.stroke ? this.UI.Values.textbox.stroke.color : "#000000");}
    get ErrorStrokeColor() { return string2hex("color" in this.UI.Values.textbox.stroke ? this.UI.Values.textbox.stroke.error.color : "#000000");}
    get CornerRadius() { return this.evaluate(this.valueFormula(this.UI.Values.textbox.corner.radius), 0, 0, 0, 0);}
    get MaxLength() { return this.UI.Values.textbox.maxlength;}

    setResponsiveSize(strWidth, strHeight)
    {
        this.strWidth = strWidth;
        this.strHeight = strHeight;
        this.build();
    }

    /*******************************************
    *   INITIALIZATION
    *******************************************/
    /**
        Parameters to pass to the init function:
        - ui:           UI section object where this component resides
        - rWidth:       Responsive equation representing the width of this component
        - rHeight:      Responsive equation representing the height of this component
        - text:         (Optional) Text to pre-fill inside the box. Default is empty string
        - placeholder:  (Optional) Text to display as a placeholder. Default is empty string
    */
    init(meta)
    {
        this.strWidth = meta.rWidth;
        this.strHeight = meta.rHeight;

        this.text = ("text" in meta ? meta.text : "");
        this.placeholder = ("placeholder" in meta ? meta.placeholder : "");

        this.align = "center";
        this.isError = false;

        this.graphics = {};

        return super.init(meta);
    }

    createClosure()
    {
        super.createClosure();

        this.fctOnTextChange = this.onTextChange.bind(this);
        this.fctOnFocus = this.onFocus.bind(this);
        this.fctOnFocusOut = this.onFocusOut.bind(this);
        this.fctOnBlur = this.onBlur.bind(this);
    }

    bindEvents()
    {
        super.bindEvents();
    }

    destroy(options)
    {
        if (this.textbox && this.textbox.parentNode)
        {
            this.textbox.parentNode.removeChild(this.textbox);

            if (!this.textbox)
            {
                this.textbox.removeEventListener("change", this.fctOnTextChange);
                this.textbox.removeEventListener("focus", this.fctOnFocus);
                this.textbox.removeEventListener("focusout", this.fctOnFocusOut);
                this.textbox.removeEventListener("blur", this.fctOnBlur);
            }
        }
        super.destroy(options);
    }

    /*******************************************
    *   INITIALIZATION
    *******************************************/
    build(size)
    {
        super.build();

        this.buildBackground();
        this.buildTextbox();
    }

    clean()
    {
        for(let key in this.graphics)
        {
            this.graphics[key].clear();
        }

        if (this.textbox)
        {
            this.textbox.style.display = "none";
        }

        super.clean();
    }

    buildBackground()
    {
        if (!this.graphics.bg)
        {
            this.graphics.bg = new GraphicsResponsive().init({"ui": this.UI});
            this.graphics.bg.interactive = true;
            this.addChild(this.graphics.bg);
        }

        let graphics = this.graphics.bg;

        let bgColor = this.BackgroundColorHex;
        let strokeWidth = this.StrokeWidth;
        let strokeColor = this.StrokeColor;
        if (this.IsError)
        {
            strokeColor = this.ErrorStrokeColor;
        }
        let cornerRadius = this.CornerRadius;

        let x = 0;
        let y = 0;
        let width = this.evaluate(this.DrawWidth, 0, 0, 0, 0, this);
        let height = this.evaluate(this.DrawHeight, 0, 0, 0, 0, this);

        if (strokeWidth > 0)
        {
            graphics.lineStyle({"color":strokeColor, "width":strokeWidth, "alignment":1});
        }

        graphics.beginFill(bgColor, 1);
        if (cornerRadius > 0)
        {
            graphics.drawRoundedRect(x, y, width, height, cornerRadius);
        }
        else
        {
            graphics.drawRect(x, y, width, height);
        }
        graphics.endFill();
    }

    buildTextbox()
    {
        let bAdd = false;
        if (!this.textbox)
        {
            this.textbox = document.createElement("INPUT")

            this.textbox.setAttribute("type", "text");
            this.textbox.style.position = "absolute";
            this.textbox.readOnly = true;

            this.textbox.addEventListener("change", this.fctOnTextChange);
            this.textbox.addEventListener("focus", this.fctOnFocus);
            this.textbox.addEventListener("focusout", this.fctOnFocusOut);
            this.textbox.addEventListener("blur", this.fctOnBlur);

            document.body.appendChild(this.textbox);

            if (this.textbox.blur)
            {
                this.textbox.blur();
                this.textbox.focus();
                this.textbox.blur();
            }
        }

        let cornerRadius = this.CornerRadius;

        this.textbox.setAttribute("maxlength", this.MaxLength);
        this.textbox.setAttribute("value", this.Text);
        this.textbox.setAttribute("placeholder", this.Placeholder);

        this.textbox.style.display = "block";
        this.textbox.style.textAlign = this.Align;
        //this.textbox.style.backgroundColor = this.BackgroundColor;
        this.textbox.style.backgroundColor = "transparent";
        this.textbox.style.border = "none";
        this.textbox.style.outline = "none";
        this.textbox.style.fontFamily  = this.UI.Values.general.text.font;
        this.textbox.style.fontWeight  = this.UI.Values.general.text.weight;
        this.textbox.style.fontSize  = this.evaluate(this.valueFormula(this.UI.Values.general.text.size * 1.25), 0, 0, 0, 0, this) + "px";
        this.textbox.style.color  = this.UI.Values.general.text.color;
        this.textbox.style.color  = this.UI.Values.general.text.color;

        let position = this.toGlobal({"x": 0, "y": 0});

        let width = this.evaluate(this.DrawWidth, 0, 0, 0, 0, this) - cornerRadius;
        let height = this.evaluate(this.DrawHeight, 0, 0, 0, 0, this) - cornerRadius;

        let x = position.x + (cornerRadius / 2) + this.UI.ResponsiveManager.ScreenPadding;
        let y = position.y + (cornerRadius / 2);

        this.textbox.style.left = x + "px";
        this.textbox.style.top = y + "px";
        this.textbox.style.width = width + "px";
        this.textbox.style.height = height + "px";
    }

    //---------------------------------------------------
    //  EVENTS
    //---------------------------------------------------
    onTextChange()
    {
        this.text = this.textbox.value;
        if (this.text.length > this.MaxLength)
        {
            this.text = this.text.substring(0, this.MaxLength);
            this.textbox.value = this.text;
        }

        this.emit(this.EVENT_CHANGE, this, this.text);
    }

    onFocus()
    {

        this.emit(this.EVENT_FOCUS, this);
    }

    onFocusOut()
    {
        this.emit(this.EVENT_FOCUS_OUT, this);
    }

    onBlur()
    {
        this.emit(this.EVENT_BLUR, this);
    }
}