import RelaxZoneContent from "./RelaxZoneContent.js";

export default class SleepZoneContent extends RelaxZoneContent
{
    constructor()
    {
        super();
    }

    get DropZoneLabel() { return this.UI.LabelManager.translate("Somm_Instruction"); }
    get StopLabel() { return this.UI.LabelManager.translate("Somm_Reveiller"); }

    getCharacterStat(iCharacter, bIsMax)
    {
        let stat = 0;
        if (bIsMax)
        {
            stat = this.UI.CharacterManager.getCharacterMaxEnergy(iCharacter);
        }
        else
        {
            stat = this.UI.CharacterManager.getCharacterEnergy(iCharacter);
        }
        return stat;
    }

    getIsAction(iCharacter)
    {
        return this.UI.CharacterManager.isCharacterSleeping(iCharacter);
    }

    getGaugeType(objGauge)
    { 
        return objGauge.ENERGY; 
    }

    /*******************************************
    *   INITIALIZATION
    *******************************************/
    /**
        Parameters to pass to the init function:
        - ui:       UI section object where this component resides
        - rWidth:   Responsive equation for the width of this content
        - rHeight:  Responsive equation for the height of this content
    */
    init(meta)
    {
        return super.init(meta);
    }

    buildRightZoneSprite(x, y, width, height, spriteName = null)
    {
        super.buildRightZoneSprite(x, y, width, height, "upgrade_pillow");

        let sprite = this.sprites.rightSprite;
        sprite.alpha = this.Opacity;

        let ratio = sprite.height / sprite.width;
        let spriteWidth = this.calculate(width);
        let spriteHeight = spriteWidth * ratio;
        let spriteX = this.calculate(this.center(x, width, spriteWidth));
        let spriteY = this.calculate(this.center(y, height, spriteHeight));

        sprite.width = spriteWidth;
        sprite.height = spriteHeight;
        sprite.position.set(spriteX, spriteY);
    }

    updateGaugeValue(iCharacter, objGauge)
    {
        if (objGauge)
        {
            let value = this.UI.CharacterManager.getCharacterEnergy(iCharacter) / this.UI.CharacterManager.getCharacterMaxEnergy(iCharacter);
            
            if (value != objGauge.Value)
            {
                objGauge.Value = value;
            }
        }
    }
}