import { Point } from '@pixi/math';
import { string2hex }  from '@pixi/utils';
import { TextStyle } from '@pixi/text';
import { TextMetrics } from '@pixi/text';
import CharacterFace from "../characters/CharacterFace.js";
import ContainerResponsive from "../responsive/ContainerResponsive.js";
import GraphicsResponsive from "../responsive/GraphicsResponsive.js";
import Library from "../../../Library.js";
import SpriteResponsive from "../responsive/SpriteResponsive.js";
import TextResponsive from "../responsive/TextResponsive.js";

export default class MapPin extends ContainerResponsive
{
    constructor()
    {
        super();
    }

    get TIME_PER_BLINK() { return 0.5; }

    get Definition() { return this.definition; }
    set Definition(newValue) { this.definition = newValue; }

    get Cell() { return this.cell; }
    get Width() { return this.strWidth; }
    get Height() { return this.strHeight; }
    get MapPosition() { return new Point(this.x + this.pinSize.width / 2, this.y + this.pinSize.height);}

    get LabelMargin() { return "(ih * 0.01)"; }
    get LabelPaddingH() { return "(ih * 0.025)"; }
    get LabelPaddingV() { return "(ih * 0.0075)"; }
    get LabelCornerRadius() { return "(ih * 0.01)"; }

    get IsBlinking() { return this.blinking; }
    get TextureId() { return this.textureId; }

    get BackgroundColor() { return string2hex(this.UI.Values.minimap.pin.background.color);}
    get StrokeWidth() { return this.evaluate(this.valueFormula(this.UI.Values.minimap.pin.stroke.size), 0, 0, 0, 0);}
    get StrokeColor() { return string2hex("color" in this.UI.Values.minimap.pin.stroke ? this.UI.Values.minimap.pin.stroke.color : "#000000");}
    get BackpackStrokeColor() { return string2hex("color" in this.UI.Values.minimap.pin.stroke.backpack ? this.UI.Values.minimap.pin.stroke.backpack.color : "#000000");}
    get LabelBackgroundColor() { return string2hex(this.UI.Values.minimap.pin.label.background.color);}
    get LabelTextFont() { return this.UI.Values.general.text.font; }
    get LabelTextWeight() { return this.UI.Values.general.text.weight; }
    get LabelTextColor() { return string2hex(this.UI.Values.minimap.pin.label.text.color);}
    get LabelTextSize() { return this.evaluate(this.valueFormula(this.UI.Values.minimap.pin.label.text.size), 0, 0, 0, 0);}

    get LabelText()
    {
        if (this.Definition)
        {
            if (this.Definition.landmark && this.Definition.landmark.def)
            {
                return this.Definition.landmark.def.Name;
            }
            else if (this.Definition.barn && this.Definition.barn.def)
            {
                return this.Definition.barn.def.Name;
            }
            else if (this.Definition.backpack)
            {
                return this.UI.LabelManager.translate("INVE_SACADOS");
            }
            else if (this.Definition.player)
            {
                return this.UI.CharacterManager.getCharacterName(this.UI.CharacterManager.CurrentCharacter);
            }
        }
        return null;
    }

    /*******************************************
    *   INITIALIZATION
    *******************************************/
    /**
        Parameters to pass to the init function:
        - ui:           UI section object where this component resides
        - cell:         Cell on the grid where this pin sits on
        - definition:   Definition describing the content of this pin
        - rWidth:       Responsive equation representing the width of this component
        - rHeight:      Responsive equation representing the height of this component
    */
    init(meta)
    {
        this.definition = meta.definition;
        this.strWidth = meta.rWidth;
        this.strHeight = meta.rHeight;

        this.blinking = false;
        this.blinkTimeLeft = 0;
        this.textureId = null;

        return super.init(meta);
    }

    destroy(options)
    {
        if (this.background)
        {
            if (this.background.face)
            {
                this.background.removeChild(this.background.face);
                this.background.face.destroy(options);
                delete this.background.face;
            }

            this.removeChild(this.background);
            this.background.destroy(options);
            delete this.background;
        }
        
        if (this.label)
        {
            if (this.label.parent)
            {
                this.label.parent.removeChild(this.label);
            }
            this.label.destroy(options);
            delete this.label;
        }

        if (this.labelBackground)
        {
            this.removeChild(this.labelBackground);
            this.labelBackground.destroy(options);
            delete this.labelBackground;
        }

        super.destroy(options);
    }

    /*******************************************
    *   BUILD
    *******************************************/
    build()
    {
        super.build();

        this.buildBackground();
        this.buildSprite();
        this.buildLabel();
    }

    clean()
    {
        if (this.background)
        {
            this.background.clear();
        }
        super.clean();
    }

    buildBackground()
    {
        if (!this.background)
        {
            this.background = new GraphicsResponsive().init({"ui": this.UI});
            this.background.sprites = [
                new SpriteResponsive().init({"ui": this.UI}),
                new SpriteResponsive().init({"ui": this.UI})
            ];
            this.addChild(this.background);
        }

        let pin = this.background;

        let width = this.evaluate(this.Width, 0, 0, 0, 0, this);
        let height = this.evaluate(this.Height, 0, 0, 0, 0, this);
        let x = 0;
        let y = 0;
        let radius = width / 2;
        let spikeWidth = width / 8;

        let bgColor = this.BackgroundColor;
        let strokeWidth = this.StrokeWidth;
        let strokeColor = (this.Definition.backpack ? this.BackpackStrokeColor : this.StrokeColor);

        this.pinSize = {width, height};

        pin.clear();

        for (let i = 0; i < pin.sprites.length; i++)
        {
            pin.removeChild(pin.sprites[i]);
        }
        if (pin.face)
        {
            pin.removeChild(pin.face);
        }

        if (strokeWidth > 0)
        {
            pin.lineStyle({"color":strokeColor, "width":strokeWidth, "alignment":1});
        }

        pin.beginFill(bgColor, 1);

        pin.moveTo(x, y + radius);
        pin.bezierCurveTo(x, y + radius * 0.45, x + radius * 0.45, y, x + radius, y);
        pin.bezierCurveTo(x + radius * 1.55, y, x + radius * 2, radius * 0.45, x + radius * 2, y + radius);
        pin.bezierCurveTo(x + radius * 2, y + radius * 1.55, x + radius * 1.6, y + radius * 2, x + radius + spikeWidth / 2, y + radius * 2);
        pin.bezierCurveTo(x + radius + spikeWidth / 4, y + radius * 2, x + radius + spikeWidth / 4, y + height, x + radius, y + height);
        pin.bezierCurveTo(x + radius - spikeWidth / 4, y + height, x + radius - spikeWidth / 4, y + radius * 2, x + radius - spikeWidth / 2, y + radius * 2);
        pin.bezierCurveTo(x + radius * 0.45, y + radius * 2, x, y + radius * 1.55, x, y + radius);

        pin.endFill();
    }

    buildSprite()
    {
        let pin = this.background;

        for (let i = 0; i < this.background.sprites.length; i++)
        {
            this.background.removeChild(pin.sprites[i]);
        }

        let pinWidth  = this.evaluate(this.Width, 0, 0, 0, 0);
        let widthRatio = 0.8;
        let smallRatio = 0.6;
        let spriteWidth = 0;
        let spriteHeight = 0;
        let x = 0;
        let y = 0;
        let bigSprite = null;
        let bigTexture = null;
        let smallSprite = null;
        let smallTexture = null;
        let strName = "pin";

        if (this.Definition.landmark && this.Definition.landmark.def)
        {
            strName = this.Definition.landmark.def.Landmark.ui;
            this.textureId = strName;

            bigSprite = pin.sprites[0];
            bigTexture = Library.getTextureFromAtlas("ui", strName);
        }
        if (this.Definition.barn && this.Definition.barn.def)
        {
            strName = this.Definition.barn.def.Landmark.ui;
            this.textureId = strName;

            bigSprite = pin.sprites[0];
            bigTexture = Library.getTextureFromAtlas("ui", strName);
        }
        if (this.Definition.backpack)
        {
            let texture = Library.getTextureFromAtlas("ui", "pin_backpack");
            this.textureId = "pin_backpack";

            if (bigSprite)
            {
                smallSprite = pin.sprites[1];
                smallTexture = texture;
            }
            else
            {
                widthRatio = 0.7;
                bigSprite = pin.sprites[0];
                bigTexture = texture;
            }

            strName = "backpack";
        }

        if (bigSprite && bigTexture)
        {
            let ratio = bigTexture.height / bigTexture.width;
            spriteWidth = pinWidth * widthRatio;
            spriteHeight = spriteWidth * ratio;

            if (bigTexture.width < bigTexture.height)
            {
                ratio = bigTexture.width / bigTexture.height;
                spriteHeight = pinWidth * widthRatio;
                spriteWidth = spriteHeight * ratio;
            }

            bigSprite.texture = bigTexture;
            bigSprite.width = spriteWidth;
            bigSprite.height = spriteHeight;
            bigSprite.position.set(pinWidth / 2 - spriteWidth / 2, pinWidth / 2 - spriteHeight / 2);
            bigSprite.name = strName;

            pin.addChild(bigSprite);

            if (smallSprite)
            {
                ratio = smallTexture.height / smallTexture.width;
                spriteWidth = pinWidth * widthRatio * smallRatio;
                spriteHeight = spriteWidth * ratio;

                if (smallTexture.width < smallTexture.height)
                {
                    ratio = smallTexture.width / smallTexture.height;
                    spriteHeight = pinWidth * widthRatio * smallRatio;
                    spriteWidth = spriteHeight * ratio;
                }

                smallSprite.texture = smallTexture;
                smallSprite.width = spriteWidth;
                smallSprite.height = spriteHeight;
                smallSprite.position.set(pinWidth / 2 - spriteWidth / 2, - spriteHeight / 2);

                pin.addChild(smallSprite);
            }
        }

        if (this.Definition.player)
        {
            if (!pin.face)
            {
                let build = this.UI.CharacterManager.getCharacterBuild(this.UI.CharacterManager.CurrentCharacter);
                let face = null;

                if (bigSprite && !smallSprite)
                {
                    let size = pinWidth * widthRatio * smallRatio;

                    face = new CharacterFace().init({
                        "ui": this.UI,
                        "rHeight": size + "",
                        build
                    });
                    pin.face = face;
                }
                else if (!bigSprite)
                {
                    let size = pinWidth * widthRatio;

                    face = new CharacterFace().init({
                        "ui": this.UI,
                        "rHeight": size + "",
                        build
                    });
                    pin.face = face;
                }
            }

            if (pin.face)
            {
                pin.addChild(pin.face);
                if (bigSprite && !smallSprite)
                {
                    let size = pinWidth * widthRatio * smallRatio;
                    pin.face.position.set(pinWidth / 2 - size / 2, - size / 2);
                }
                else
                {
                    let size = pinWidth * widthRatio;
                    pin.face.position.set(pinWidth / 2 - size / 2 + size / 20, pinWidth / 2 - size / 2);
                }
            }
        }
    }

    buildLabel()
    {
        if (this.LabelText)
        {
            this.buildLabelText();
            this.buildLabelBackground();
        }
    }

    buildLabelText()
    {
        if (!this.label)
        {
            this.label = new TextResponsive().init({"ui": this.UI});
        }

        let text = this.label;
        let content = this.LabelText;

        let style = new TextStyle({
            fontFamily: this.LabelTextFont,
            fontSize:   this.LabelTextSize,
            fontWeight: this.LabelTextWeight,
            fill: this.LabelTextColor,
            align: "center"
        });

        let metrics = TextMetrics.measureText(content, style);
        text.size = metrics;

        text.text = content;
        text.style = style;
        text.width = metrics.width;
        text.height = metrics.height;
    }

    buildLabelBackground()
    {
        if (!this.labelBackground)
        {
            this.labelBackground = new GraphicsResponsive().init({"ui": this.UI});
            this.labelBackground.addChild(this.label);

            this.addChild(this.labelBackground);
        }

        let graphics = this.labelBackground;
        let text = this.label;

        let paddingH = this.evaluate(this.LabelPaddingH, 0, 0, 0, 0);
        let paddingV = this.evaluate(this.LabelPaddingV, 0, 0, 0, 0);

        let width = text.size.width + paddingH;
        let height = text.size.height + paddingV;
        let cornerRadius = this.evaluate(this.LabelCornerRadius, 0, 0, 0, 0);
        let x = this.pinSize.width / 2 - width / 2;
        let y = -height - this.evaluate(this.LabelMargin, 0, 0, 0, 0);

        graphics.beginFill(this.LabelBackgroundColor, 1);
        if (cornerRadius > 0)
        {
            graphics.drawRoundedRect(0, 0, width, height, cornerRadius);
        }
        else
        {
            graphics.drawRect(0, 0, width, height);
        }
        graphics.endFill();

        graphics.x = x;
        graphics.y = y;
        text.x = width / 2 - text.size.width / 2;
        text.y = height / 2 - text.size.height / 2;
    }

    /*******************************************
    *   ACTIONS
    *******************************************/
    positionAt(x, y)
    {
        if (this.pinSize)
        {
            this.x = x - this.pinSize.width / 2;
            this.y = y - this.pinSize.height;
        }
        else
        {
            this.x = x;
            this.y = y;
        }
    }

    startBlinking()
    {
        if (!this.IsBlinking)
        {
            this.blinking = true;
            this.blinkTimeLeft = this.TIME_PER_BLINK;
        }
    }

    stopBlinking()
    {
        this.blinking = false;
    }

    /*******************************************
    *   UPDATE LOOP
    *******************************************/
    update(fDeltaTime)
    {
        this.updateBlink(fDeltaTime);
    }

    updateBlink(fDeltaTime)
    {
        if (this.IsBlinking)
        {
            this.blinkTimeLeft -= fDeltaTime;
            if (this.blinkTimeLeft <= 0)
            {
                this.background.visible = !this.background.visible;
                this.blinkTimeLeft = this.TIME_PER_BLINK;
            }
        }
    }
}