import {Vector2} from "three";
import BaseEnvironment from "./BaseEnvironment.js";
import Constants from "../../utils/Constants.js";
import Direction from "../../utils/Direction.js";
import OutdoorMapFloor from "../map/OutdoorMapFloor.js";
import MapObstacles from "../map/MapObstacles.js";
import gsap from "gsap";

export default class ForestEnvironment extends BaseEnvironment
{
    constructor(canvas)
    {
        super(canvas);
    }

    get ENEMY_SOUND_WAIT_TIME() { return Constants.getValue("ENEMY_AROUND_SOUND_WAIT_BEFORE_REPLAY") * 1000; }
    get ENEMY_SOUND_MAX_DIST() { return Constants.getValue("ENEMY_AROUND_SOUND_MAX_DIST"); }

    static get EVENT_CHECK_MUSIC() { return "EVENT_CHECK_MUSIC"; }

    get Id() { return "forest"; }
    get IsHostile() { return true; }
    get Obstacles() { return this.obstacles; }

    /*******************************************
    *   INITIALIZATION
    *******************************************/
    /**
        Parameters to pass to the init function:
        - parser:    Obstacle parsers to populate the map with
        - spawnTile: (Optional) Override the default spawn position of the player. Id of the tile in grid unit. Default is null
    */
    init(params)
    {
        this.enemySoundsPlayed = {};
        super.init(params);

        let spawnPos = ("spawnTile" in params ? params.spawnTile : null);
        if (!spawnPos && spawnPos !== 0 && spawnPos !== "0")
        {
            let barnPos = this.WorldManager.getBarnOnGrid();
            spawnPos = new Vector2(barnPos.x, barnPos.z + 2);
        }
        else
        {
            spawnPos = this.Grid.getPosFromTileId(spawnPos);
        }
        this.spawnPlayer(spawnPos.x, spawnPos.y);
        this.updateDisplay(spawnPos.x, spawnPos.y);
        this.Grid.updateGrid();

        let strAmbiance = this.GameManager.IsNight ? "ambiance_foret_nuit" : "ambiance_foret";


        this.AudioManager.playSfx(strAmbiance, true, 2);

        this.AudioManager.onResetBgmMusicDelay();
        this.fctCheckMusic = this.AudioManager.onCheckPlayMusic.bind(this.AudioManager);
        this.on(ForestEnvironment.EVENT_CHECK_MUSIC, this.fctCheckMusic)

    }

    createClosure()
    {
        super.createClosure();
        this.fctOnItemDrop = this.onItemDrop.bind(this);
    }

    bindEvents()
    {
        super.bindEvents();
        this.ItemManager.on(this.ItemManager.EVENT_ITEM_DROPPED, this.fctOnItemDrop);
    }

    destroy(options)
    {
        this.ItemManager.off(this.ItemManager.EVENT_ITEM_DROPPED, this.fctOnItemDrop);

        this.floor.destroy(options);
        this.obstacles.destroy(options);

        this.off(ForestEnvironment.EVENT_CHECK_MUSIC, this.fctCheckMusic)

        this.AudioManager.stopSfx(null, "ambiance_foret", 2);
        this.AudioManager.stopAll(this.AudioManager.AUDIO_TYPE_BGM, true, 0.5);
        this.AudioManager.stopAll(this.AudioManager.AUDIO_TYPE_SFX, true, 0.5);

        super.destroy(options);
    }

    createFloor()
    {
        this.floor = new OutdoorMapFloor().init(this, this.dependencies);
    }

    createObstacles()
    {
        this.obstacles = new MapObstacles();
        this.obstacles.init({
            "parser": this.parser,
            "environment": this,
            "dependencies": this.dependencies
        });
    }

    initUI()
    {
        this.UIManager.showHostileUI();
    }

    initPostProcessing()
    {
        if (this.Scene.PostProcessing)
        {
            this.Scene.PostProcessing.showColorMultiply = true;
        }   
    }

    /*******************************************
    *   ACTIONS
    *******************************************/
    pickupObstacle(objObstacle)
    {
        super.pickupObstacle(objObstacle);
        
        if (!objObstacle.Item.IsObstacle || (objObstacle.Item.DespawnOnLoot || objObstacle.Item.HideOnLoot))
        {
            let gridPos = objObstacle.GridPos;
            this.obstacles.despawnObstacle(this.Grid.getTileId(gridPos.x, gridPos.y));
        }
    }

    /*******************************************
    *   UPDATE LOOP
    *******************************************/

    update(iDeltaTime)
    {
        super.update(iDeltaTime);

        this.emit(ForestEnvironment.EVENT_CHECK_MUSIC)
    }

    updateDisplay (iX, iY)
    {
        this.floor.updateDisplay(iX, iY);
        this.obstacles.updateDisplay(iX, iY);
        this.updateEnemySpawns(iX, iY);
        this.updateEnemySound();

    }

    updateEnemySpawns(iX, iY)
    {
        let spawnables = this.obstacles.SpawnableEnemies;

        for (let tileId in spawnables)
        {
            let gridPos = this.Grid.getPosFromTileId(tileId);
            let isSpawned = false;
            for (let i = 0; i < this.enemies.length; i++)
            {
                if (this.enemies[i].SpawnPos.x == gridPos.x && this.enemies[i].SpawnPos.y == gridPos.y)
                {
                    isSpawned = true;
                    break;
                }
            }

            if (!isSpawned)
            {
                let spawnPos = this.calculateEnemySpawnPosition(spawnables[tileId].data, gridPos.x, gridPos.y);

                let enemy = this.spawnEnemy(
                    spawnables[tileId].id,
                    spawnPos.x,
                    spawnPos.y,
                    gridPos.x,
                    gridPos.y
                );

            }
        }
    }

    updateEnemySound()
    {
        let now = (new Date()).getTime();
        let minTime = this.ENEMY_SOUND_WAIT_TIME;
        let maxDist = Math.pow(this.ENEMY_SOUND_MAX_DIST, 2);
        let playerPos = this.Player.GridPos;

        for (let i = 0; i < this.enemies.length; i++)
        {
            let enemy = this.enemies[i];
            if (!this.enemySoundsPlayed[enemy.Id] || (this.enemySoundsPlayed[enemy.Id].outOfRange && now - this.enemySoundsPlayed[enemy.Id].time >= minTime))
            {
                let enemyPos = enemy.GridPos;
                let sqrDist = Math.pow(enemyPos.x - playerPos.x, 2) + Math.pow(enemyPos.y - playerPos.y, 2);

                if (sqrDist <= maxDist)
                {
                    if (!this.enemySoundsPlayed[enemy.Id])
                    {
                        this.enemySoundsPlayed[enemy.Id] = {};
                    }
                    this.enemySoundsPlayed[enemy.Id].outOfRange = false;
                    this.enemySoundsPlayed[enemy.Id].time = (new Date()).getTime();

                    //@TODO: Play enemy around sound
                    console.log("Play sfx for enemy", enemy.Definition.id);
                }
            }
        }

        let ids = Object.keys(this.enemySoundsPlayed);

        for (let i = 0; i < ids.length; i++)
        {
            if (!this.enemySoundsPlayed[ids[i]].outOfRange)
            {
                let enemy = null;
                for (let j = 0; j < this.enemies.length; j++)
                {
                    if (this.enemies[j].Id == ids[i])
                    {
                        enemy = this.enemies[j];
                        break;
                    }
                }

                if (enemy)
                {
                    let enemyPos = enemy.GridPos;
                    let sqrDist = Math.pow(enemyPos.x - playerPos.x, 2) + Math.pow(enemyPos.y - playerPos.y, 2);

                    if (sqrDist > maxDist)
                    {
                        this.enemySoundsPlayed[ids[i]].outOfRange = true;
                    }
                }
                else
                {
                    delete this.enemySoundsPlayed[ids[i]];
                }
            }
        }
    }

    /*******************************************
    *   SPAWN/DESPAWN MANAGEMENT
    *******************************************/
    calculateEnemySpawnPosition(objDefinition, iX, iY)
    {
        let range = 0;
        if (objDefinition.ai && objDefinition.ai.roaming && objDefinition.ai.roaming.range)
        {
            range = objDefinition.ai.roaming.range;
        }

        let rect = {
            "min": new Vector2(Math.max(0, iX - range), Math.max(0, iY - range)),
            "max": new Vector2(Math.min(this.Grid.Height - 1, iX + range), Math.min(this.Grid.Height - 1, iY + range))
        };

        let objects = this.parser.getObjectsInArea(rect.min.x, rect.min.y, rect.max.x, rect.max.y);

        let spawnableTiles = [];
        for (let x = rect.min.x; x <= rect.max.x; x++)
        {
            for (let y = rect.min.y; y <= rect.max.y; y++)
            {
                if (!objects[x] || !objects[x][y])
                {
                    spawnableTiles.push({x, y});
                }
            }
        }

        if (spawnableTiles.length > 0)
        {
            let tile = spawnableTiles[Math.floor(spawnableTiles.length * Math.random())];
            return new Vector2(tile.x, tile.y);
        }

        return new Vector2(iX, iY);
    }

    /*******************************************
    *   ITEM DROP
    *******************************************/
    dropItem(objItem, iQuantity, iSlot, bIsBackpack)
    {
        let playerPos = this.Player.GridPos;
        let tile = this.Grid.getNearestWalkableTile(
            playerPos.x,
            playerPos.y,
            playerPos.x,
            playerPos.y,
            false,
            null,
            false,
            true,
            true,
            5
        );

        if (tile)
        {
            this.WorldManager.updateCellContent(
                this.Grid.getTileId(tile.x, tile.y),
                objItem.Id, 
                iQuantity
            );
            this.updateDisplay(playerPos.x, playerPos.y);
        }
        else
        {
            //@TODO: Show some error feedback when an object cannot be dropped on the floor
            //This is a very rare situation but it can still happen
            this.ItemManager.addItem(objItem, iQuantity, iSlot, bIsBackpack);
        }

    }

    /*******************************************
    *   DIALOG MODE
    *******************************************/
    startDialogMode(strCharacterToFollow = null, bTiltCamera = true)
    {
        super.startDialogMode(strCharacterToFollow, bTiltCamera);

        if (strCharacterToFollow)
        {
            if (this.player && this.player.Id === strCharacterToFollow)
            {
                this.Scene.Camera.startFollowing(this.player);
            }
            else
            {
                for (let key in this.npcs)
                {
                    if (this.npcs[key].Id === strCharacterToFollow)
                    {
                        this.Scene.Camera.startFollowing(this.npcs[key]);
                        break;
                    }
                }
            }

        }

        if (bTiltCamera && !this.isCameraTilted)
        {
            this.isCameraTilted = true;
            let camRotX = Math.PI / 180 *-40;
            let camPosY = 70;

            // console.log("Before Tilt")
            // console.log("RotationX", Math.PI / 180 * this.Scene.Camera.rotation.x)
            // console.log("Postion Y", this.Scene.Camera.position.y)
            //
            // let pos = {x: this.Scene.Camera.position.x, y: this.Scene.Camera.position.y, z: this.Scene.Camera.position.z};
            // let rot = {x: this.Scene.Camera.rotation.x, y: this.Scene.Camera.rotation.y, z: this.Scene.Camera.rotation.z};
            // let rotDeg = {x: 180 / Math.PI  * this.Scene.Camera.rotation.x, y: 180 / Math.PI * this.Scene.Camera.rotation.y, z: 180 / Math.PI * this.Scene.Camera.rotation.z};
            // console.log("BEFORE TILT")
            // console.log("CAMERA POSITION", JSON.stringify(pos))
            // console.log("CAMERA ROTATION", JSON.stringify(rot))
            // console.log("CAMERA ROTATION DEG", JSON.stringify(rotDeg))

            if (this.Scene.Camera.position.y != 70)
            {
                gsap.to(this.Scene.Camera.position, {y:camPosY, duration: 1, ease: "power1.out"});
                //this.Scene.Camera.position.y = camPosY;
                gsap.to(this.Scene.Camera.rotation, {x:camRotX, duration: 1, ease: "power1.out"});

                this.Scene.Camera.stopFollowing();
                
                console.log("CHANGE CAMERA ROTATION")
                console.log("CHANGE CAMERA ROTATION")
                //this.Scene.Camera.rotation.x = camRotX;
            }
        }
    }

    stopDialogMode()
    {
        super.stopDialogMode();

        if (this.isCameraTilted)
        {
            this.isCameraTilted = false;
            setTimeout(function()
            {
                if (!this.IsDialogMode)
                {
                    this.consoleVector("Camera Position 1", this.Scene.Camera.position);
                    this.consoleVector("Camera Rotation 1", this.Scene.Camera.rotation, 180 / Math.PI);
                    gsap.to(this.Scene.Camera.position, {y:130, duration: 1, ease: "power1.out"});
                    gsap.to(this.Scene.Camera.rotation, {x:(Math.PI / 180) * -60, duration: 1, ease: "power1.out", onComplete: () => this.startFollowingPlayer()});

                }
            }
            .bind(this), 1);
        }
    }

    consoleVector (label, vec, multiply = 1)
    {
        let x = vec.x * multiply;
        let y = vec.y * multiply;
        let z = vec.z * multiply;


        console.log(label, x, y, z);
    }

    startFollowingPlayer ()
    {
        this.consoleVector("To Reach", this.Scene.Camera.toReach);
        this.consoleVector("Camera Position 2", this.Scene.Camera.position);
        this.consoleVector("Camera Rotation 2", this.Scene.Camera.rotation, 180 / Math.PI);
        this.Scene.Camera.startFollowing(this.Player.mesh, false);
    }

    changeOutdoorObject (arrValues, fctCallback)
    {
        console.log("changeOutdoorObject", arrValues)
        let iX = parseInt(arrValues[0]);
        let iY = parseInt(arrValues[1]);
        let itemId = arrValues[2];

        let tileId =  this.Grid.getTileId(iX, iY);

        let now = (new Date()).getTime();
        let next = Number.MAX_SAFE_INTEGER;

        this.WorldManager.setObstacleState(tileId, {"lastPickup": now, "nextPickup": next});
        this.obstacles.addToUnloadQueue(tileId);
        this.WorldManager.updateCellContent(tileId, itemId);
        this.WorldManager.clearObstacleState(tileId);
        //this.WorldManager.setObstacleState(tileId);

        this.obstacles.updateDisplay(iX, iY);
        //this.WorldManager.Environment.updateDisplay();
    }

    changeWalkableTile (arrValues, fctCallback)
    {
        // let strArrWalkable = this.SaveManager.get("walkables_status");
        //
        // if (strArrWalkable === null)
        //     strArrWalkable = "[]";
        //
        //let arrWalkable = JSON.parse (strArrWalkable);
        let x = parseInt(arrValues[0]);
        let y = parseInt(arrValues[1]);
        let walkable = arrValues[2];
        //
        // let tile = {x, y, walkable};
        //
        //
        // arrWalkable.push(tile);
        //
        // this.SaveManager.set("walkables_status", arrWalkable);

        //(iX, iY, blockMatrix, objParams = null, bCalculate = true)

        let iWalkable = walkable ? 1 : 0;
        this.WorldManager.Grid.addTileContent(
            x,
            y,
            [[iWalkable]],
            {}
        );
    }

    /*******************************************
    *   EVENTS
    *******************************************/
    onItemDrop(objItem, iQuantity, iSlot)
    {
        this.dropItem(objItem, iQuantity, iSlot);
    }
}