import BaseCheat from "./BaseCheat.js";

export default class ForceTrigger extends BaseCheat
{
    constructor(dependencies)
    {
        super(dependencies);
    }

    get CommandUse() { return "trigger id [skipAction]"; }
    get CommandDesc() { return "Launch a trigger by [option: skip some action]"; }

    //---------------------------------------------------------
    //  DEPENDENCIES
    //---------------------------------------------------------
    get ItemManager() { return this.dependencies.get("ItemManager"); }
    get WorldManager() { return this.dependencies.get("WorldManager"); }
    get TriggerManager() { return this.dependencies.get("TriggerManager"); }
    //---------------------------------------------------------

    parseCommand(strCmd, arrArguments)
    {


        if (strCmd == "trigger" && arrArguments.length >= 1)
        {

            let [id,nbOfActionToSkip] = arrArguments;

            if (this.TriggerManager.forceTriggerStart(id,nbOfActionToSkip))
            {
                return;
            }

            this.warn("Cannot start trigger", arguments);
        }
    }
}