import EventEmitter from "eventemitter3";
import Library from "../Library.js";

export default class LabelManager extends EventEmitter
{
    constructor()
    {
        super();

        LabelManager.instance = this;

        this.dictionary = {};
    }

    init(dependencies)
    {
        this.dependencies = dependencies;
        
        this.createClosure();
        this.bindEvents();
    }

    createClosure()
    {

    }

    bindEvents()
    {
        
    }

    onLabelsReady (objLabels)
    {
        this.dictionary = objLabels;
    }

    translate (strKey)
    {
        if (strKey)
        {
            let normalizedStrKey = strKey.toUpperCase();
            if (this.dictionary[normalizedStrKey] !== undefined)
            {
                return this.dictionary[normalizedStrKey];
            }
        }
        else
        {
            strKey = "";
        }
        return strKey;
    }

    onLoadComplete(objSettings)
    {
        this.dictionary = Library.getData("copydeck");
    }
}