import BaseCheat from "./BaseCheat.js";
import TriggerCodes from "../../triggers/codes/TriggerCodes.js";

export default class TriggerValidator extends BaseCheat
{
    constructor(dependencies)
    {
        super(dependencies);
    }

    get CommandUse() { return "validate trigger id"; }
    get CommandDesc() { return "Check condition for a trigger"; }

    //---------------------------------------------------------
    //  DEPENDENCIES
    //---------------------------------------------------------
    get ItemManager() { return this.dependencies.get("ItemManager"); }
    get WorldManager() { return this.dependencies.get("WorldManager"); }
    get TriggerManager() { return this.dependencies.get("TriggerManager"); }
    //---------------------------------------------------------

    parseCommand(strCmd, arrArguments)
    {

        let [strCmd2, strTriggerId] = arrArguments;

        if (strCmd == "validate" && strCmd2 === "trigger" && arrArguments.length >= 2)
        {

            let trigger = this.TriggerManager.getTrigger(strTriggerId);

            if (!trigger)
            {
                this.warn("Trigger " + strTriggerId + " does not exist.");
                return;
            }



            let objValidators = this.TriggerManager.getCompareValidators();



            let results = [];

            for (let i = 0; i < trigger.c.length; i++)
            {
                let condition = trigger.c[i];

                let method = condition.t;
                let values = condition.v;

                if (method in objValidators)
                {
                    let result = objValidators[method](values);
                    results.push(result);


                }

            }


            let bgTriggerColor = "#7575c6";
            console.log("%c ON VALIDATE LE TRIGGER " + strTriggerId, `background:${bgTriggerColor}; color:#000000; padding: 10px;`);
            console.log(trigger.c.length + " conditions à valider");
            console.log(trigger.c);
            //this.compareRepeat(trigger)

            for (let i = 0; i < results.length; i++)
            {
                let result = results[i];


                console.log("-----------")
                let  bgColor = result.result ? "#009900" : "#990000";
                let  textColor = result.result ? "#FFFFFF" : "#FFFFFF";

                console.log(`%c ${result.method.name} ( ${result.method.shorthand} )`, `background:${bgColor};color:${textColor}; padding:5px;`);

                console.table(result.explanation);


            }

            console.log("==============")
        }
    }


    compareRepeat (trigger)
    {
        let data = this.TriggerManager.getTriggerData(trigger.id);
        let triger2 = this.triggerList[trigger.id];

        console.log("compareRepeat", data, triger2);

        return 1;

        // let bOk = false;
        // if (strId in this.triggerList)
        // {
        //     let repeatDef = this.triggerList[strId].r;
        //     //Repeat one time only
        //     if (repeatDef.t == TriggerCodes.REPEAT_ONCE)
        //     {
        //         bOk = data.r <= 0;
        //     }
        //     //Repeat for a maximum number of times
        //     else if (repeatDef.t == TriggerCodes.REPEAT_FINITE)
        //     {
        //         bOk = data.r <= repeatDef.v;
        //     }
        //     //Repeat at a minimum of seconds between each time
        //     else if (repeatDef.t == TriggerCodes.REPEAT_TIME)
        //     {
        //         let now = triggerData.t = new Date().getTime();
        //         bOk = now - data.t >= repeatDef.v;
        //     }
        //     //Always repeat
        //     else if (repeatDef.t == TriggerCodes.REPEAT_INFINITE)
        //     {
        //         bOk = true;
        //     }
        // }
        // return bOk;
    }

}