import EventEmitter from "eventemitter3";
import Animation from "./Animation.js";
import Direction from "../../../utils/Direction.js";
import Library from "../../../Library.js";

export default class AnimationController extends EventEmitter
{
    constructor()
    {
        super();
    }

    get EVENT_ANIMATION_START() { return "animation-start"; }
    get EVENT_ANIMATION_END() { return "animation-end"; }
    get EVENT_ANIMATION_FRAME_CHANGED() { return "animation-frame-changed"; }
    /** Called everytime an animation loops */
    get EVENT_ANIMATION_LOOP_CYCLE() { return "animation-loop-cycle"; }

    get Animation() { return this.animation; }
    get Speed() { return this.speed; }
    set Speed(newValue)
    {
        this.speed = newValue;
        if (this.animation)
        {
            this.animation.Speed = newValue;
        }
    }
    get IsPlaying()
    {
        if (this.animation)
        {
            return this.animation.IsPlaying;
        } 
        return false;
    }
    get CurrentAnimationId()
    {
        if (this.Animation)
        {
            return this.Animation.Id.split("_")[0];
        }
        return null;
    }
    get IsPaused()
    {
        if (this.Animation)
        {
            return this.Animation.IsPaused;
        }
        return false;
    }

    /*******************************************
    *   INITIALIZATION
    *******************************************/
    /**
        Parameters to pass to the init function:
        - atlasId:          Id of the atlas to use for the animation list (animation_atlas.json)
        - mesh:             Mesh object to render the animations
        - defaultAnimId:    (Optional)Animation to play when nothing else is active. Default is "idle"
        - characterBuild:   (Optional)String representing the character build (ex. 100222_5002) to get the right textures in memory. Only required if it should run player animations.

    */
    init(meta)
    {
        this.atlasId = meta.atlasId;
        this.mesh = meta.mesh;
        this.characterBuild = meta.characterBuild;
        this.defaultAnimId = meta.defaultAnimId;
        this.speed = 1;
        if (!this.defaultAnimId)
        {
            this.defaultAnimId = "idle";
        }

        this.initAtlas();
        this.createClosure();
        this.bindEvents();

        this.play(this.defaultAnimId, Direction.South, true);
    }

    initAtlas()
    {
        let atlasList = Library.getData("animation_atlas");
        if (this.atlasId in atlasList)
        {
            this.atlases = atlasList[this.atlasId];
        }
        else
        {
            this.atlases = {};
            console.warn("Cannot find atlases for", this.atlasId);
        }
    }

    createClosure()
    {
        this.fctOnAnimationCycleDone = this.onAnimationCycleDone.bind(this);
        this.fctOnAnimationNextFrame = this.onAnimationNextFrame.bind(this);
    }

    bindEvents()
    {

    }

    destroy()
    {
        if (this.animation)
        {
            this.animation.destroy();
            this.animation = null;
        }
    }

    /*******************************************
    *   PLAYBACK
    *******************************************/
    /**
        Stops the current animation and plays a new one
        @param strAnimationId   Id of the animation to play
        @param iDirection       Number representing the direction of the animation (use constants in utils/Direction.js)
        @param bLoop            If the animation should be looped or sent back to the default animation once done
    */
    play(strAnimationId, iDirection, bLoop = false)
    {
        if (this.switchAnimation(strAnimationId, iDirection))
        {
            this.animation.play(bLoop);
        }
    }

    /**
        Stops the current animation and returns to the default state
        @param bSwitchToDefault (Optional)If the current animation should be stopped and play the default one. Default is TRUE
    */
    stop(bSwitchToDefault = true)
    {
        if (bSwitchToDefault)
        {
            this.switchAnimation(this.defaultAnimId);
            this.switchAnimation.play();
        }
        else if (this.animation)
        {
            this.animation.stop();
        }
    }

    /**
        Pauses the animation at the current frame
    */
    pause()
    {
        if (this.animation)
        {
            this.animation.pause();
        }
    }

    /**
        Resumes the animation from pausing
    */
    resume()
    {
        if (this.animation)
        {
            this.animation.resume();
        }
    }

    /**
        If an animation is playing, it stops it and changes its direction
    */
    changeDirection(iNewDirection)
    {
        if (this.IsPlaying)
        {
            let id = this.CurrentAnimationId;
            this.play(id, iNewDirection, this.Animation.IsLooping);
        }
    }

    /*******************************************
    *   ANIMATIONS MANAGEMENT
    *******************************************/
    getAnimationId(strAnimationId, iDirection)
    {
        return strAnimationId + "_" + iDirection;
    }

    getInfosFromId(strId)
    {
        return strId.split("_");
    }

    switchAnimation(strAnimationId, iDirection)
    {
        let animId = this.getAnimationId(strAnimationId, iDirection);

        if (this.animation)
        {
            if (this.animation.Id != animId)
            {
                this.animation.destroy();
                this.animation = null;
            }
        }

        if (!this.animation)
        {
            if (strAnimationId in this.atlases)
            {
                let directionName = Direction.getAtlasName(iDirection);
                if (directionName in this.atlases[strAnimationId])
                {
                    this.animation = new Animation();
                    this.animation.on(this.animation.EVENT_ANIMATION_CYCLE_DONE, this.fctOnAnimationCycleDone);
                    this.animation.on(this.animation.EVENT_ANIMATION_NEXT_FRAME, this.fctOnAnimationNextFrame);
                    this.animation.init({
                        "id":             animId,
                        "isPlayer":       this.atlasId == "player",
                        "atlases":        this.atlases[strAnimationId][directionName],
                        "mesh":           this.mesh,
                        "characterBuild": this.characterBuild
                    });
                    this.animation.Speed = this.Speed;

                    return true;
                }
                else
                {
                    console.warn("Cannot find direction", directionName, "for animation atlas", strAnimationId, "played by", this.atlasId);
                }
            }
            else
            {
                console.warn("Cannot find animation atlas", strAnimationId, "played by", this.atlasId);
            }
        }

        return false;
    }

    /*******************************************
    *   EVENTS
    *******************************************/
    onAnimationCycleDone(sender)
    {
        if (!sender.IsLooping)
        {
            this.emit(this.EVENT_ANIMATION_END, sender.Id);
        }
        else
        {
            this.emit(this.EVENT_ANIMATION_LOOP_CYCLE, sender.Id);
        }
    }

    onAnimationNextFrame(iFrameId, iAtlasIndex)
    {
        let infos = this.getInfosFromId(this.animation.Id);

        this.emit(this.EVENT_ANIMATION_FRAME_CHANGED, 
            iFrameId,       //Frame Id
            iAtlasIndex,    //Atlas index
            infos[0],       //Animation id
            infos[1]        //Direction
        );
    }
}