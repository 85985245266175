import BaseCheat from "./BaseCheat.js";

export default class Minigame extends BaseCheat
{
    constructor(dependencies)
    {
        super(dependencies);
    }

    get CommandUse() { return "minigame [gadget/hacking/unlock] [difficulty]"; }
    get CommandDesc() { return "Starts a minigame on screen"; }

    //---------------------------------------------------------
    //  DEPENDENCIES
    //---------------------------------------------------------
    get UIManager() { return this.dependencies.get("UIManager"); }
    //---------------------------------------------------------

    parseCommand(strCmd, arrArguments)
    {
        if (strCmd == "minigame" && arrArguments.length >= 2)
        {
            let type = arrArguments[0].toLowerCase();
            let difficulty = parseInt(arrArguments[1]);

            if (this.UIManager.startMinigame(type, difficulty))
            {
                this.log("Started the minigame " + type + " at difficulty " + difficulty);
            }
            else
            {
                this.warn("Cannot start minigame " + type + " at difficulty " + difficulty);
            }
        }
    }
}