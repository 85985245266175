import PIXILoader from "./PIXILoader.js";
import axios from "axios";
import Library from "../Library.js";
import WebFont from "webfontloader";
import EventEmitter from "eventemitter3";
import ThreeJSMultipackLoader from "./ThreeJSMultipackLoader.js";
import {TextureLoader} from "three";
import Utils from "../utils/Utils.js";
import AudioManager from "../audio/AudioManager.js";

export default class Loader extends EventEmitter
{
    constructor (_strAssetsList, strBasePath)
    {
        super();

        this.strBasePath = strBasePath;

        this.createClosure();

        this.threeTextureLoader = new TextureLoader();
        this.pixiLoader = new PIXILoader();
        this.pixiLoader.on("group-loaded", this.fctOnPixiLoaderGroupLoaded );

        this.fctLoader = {
            "images": (group) => this.loadPixiAssets(group),
            "atlas": (group) => this.loadPixiAssets(group),
            "atlas3D": (group) => this.loadThreeAssets(group),
            "fonts": (group) => this.loadFonts(group),
            "audios": (group) => this.loadAudios(group),
            "datas": (group) => this.loadDatas(group),
        };

        this.loadAssetsList(_strAssetsList);
    }

    createClosure ()
    {
        this.fctLoadNextGroup = this.loadNextGroup.bind(this);
        this.fctOnPixiLoaderGroupLoaded = this.onPixiLoadedComplete.bind(this);
        this.fctOnThreeEndLoadAsset = this.onThreeEndLoadAsset.bind(this);
        this.fctOnThreeAtlasEndLoadAsset = this.onThreeAtlasEndLoadAsset.bind(this);
    }



    static getLang ()
    {
        return document.documentElement.lang;
    }

    async loadAssetsList (_strAssetsList)
    {
        _strAssetsList = Loader.addVersionToURL (_strAssetsList);
        let response  = await axios.get(this.strBasePath + _strAssetsList);

        this.arrAssetsGroups = response.data;
        this.objCount = this.calculateAssetsCount (this.arrAssetsGroups);
        this.loadNextGroup();


    }

    calculateAssetsCount (arrAssetsGroups)
    {

        let count = {total:0, totalGroup: 0, current: 0, currentGroup: 0, groups: []};

        for (let i = 0; i < arrAssetsGroups.length; i++)
        {
            let assetGroup = arrAssetsGroups[i];
            let group = assetGroup.assets ? assetGroup.assets : [];

            if (assetGroup.type === "fonts") //font are harder to calculate, so we will said there is one
            {
                group = ["1"];
            }

            if (group.length > 0)
            {
                count.total += group.length;
                count.totalGroup++;
                count.groups.push({type: assetGroup.type, count: group.length})
            }
        }

        return count;
    }

    loadNextGroup ()
    {
        if (this.arrAssetsGroups.length === 0)
        {
            this.emit("load-complete");
            return;
        }

        let group = this.arrAssetsGroups.shift();
        let strType = group.type;

        this.fctLoader[strType](group);
    }

    startLoadGroup (strType)
    {
        this.emit("start-load-group", this.objCount.currentGroup, this.objCount.totalGroup, this.objCount.currentGroup / this.objCount.totalGroup, strType)
    }

    endLoadGroup (strType)
    {
        this.objCount.currentGroup++;
        this.emit("end-load-group", this.objCount.currentGroup, this.objCount.totalGroup, this.objCount.currentGroup / this.objCount.totalGroup, strType)
    }

    startLoadAsset (strType, strKey)
    {
        this.emit("start-load-asset", this.objCount.current, this.objCount.total, this.objCount.current / this.objCount.total, strType, strKey)
    }

    endLoadAsset (strType, strKey)
    {
        this.objCount.current++;
        this.emit("end-load-asset", this.objCount.current, this.objCount.total, this.objCount.current / this.objCount.total, strType, strKey)
    }

    pixiStartLoadAsset (strType, strKey)
    {

    }

    pixiEndLoadAsset (strType, strKey)
    {
        this.endLoadAsset(strType, "")
    }

    onThreeEndLoadAsset()
    {
        this.endLoadAsset("three", "");
    }

    async loadAudios (group)
    {
        let arrAssets = group.assets;

        if (arrAssets)
        {
            if (arrAssets.length > 0)
            {
                this.startLoadGroup("audios");
            }

            let audioSprites = Library.instance.datas["audio" ];

            for (let i = 0; i < arrAssets.length; i++)
            {
                let asset = arrAssets[i];

                if (audioSprites[asset.key])
                {
                    let sprites = audioSprites[asset.key].sprite;
                    let audio = await this.loadAudio(this.strBasePath + asset.url, sprites);

                    Library.addAudio("audio-" + asset.key, audio);

                    AudioManager.instance.loadAudio(audio, asset.key, Object.keys(sprites))
                }

            }



            if (arrAssets.length > 0)
            {
                this.endLoadGroup("audios");
            }
        }

        //since all group will 'await' for a response we can call this here instead of a callback
        this.loadNextGroup();
    }

    async loadAudio (strSrc, sprite = null)
    {
        let strLang = Loader.getLang();

        strSrc = strSrc.replace("{strLang}", strLang);
        strSrc = Loader.addVersionToURL (strSrc);

        let audio = new Howl({
            src: [strSrc],
            preload: false,
            html5: false,
            sprite
        });

        await audio.load();

        return audio;
    }

    async loadDatas (group)
    {
        let strLang = Loader.getLang();
        let arrAssets = group.assets;

        if (arrAssets)
        {

            if (arrAssets.length > 0)
            {
                this.startLoadGroup("datas");
            }

            for (let i = 0; i < arrAssets.length; i++)
            {
                let asset = arrAssets[i];
                let strUrl = asset.url.replace("{strLang}", strLang);
                strUrl = Loader.addVersionToURL(strUrl);

                this.startLoadAsset("datas", asset.key);

                let response = await axios.get(this.strBasePath + strUrl);

                this.endLoadAsset("datas", asset.key);

                let data = response.data;
                Library.addData(asset.key, data);
            }

            if (arrAssets.length > 0)
            {
                this.endLoadGroup("datas");
            }
        }

        //since all group will 'await' for a response we can call this here instead of a callback
        this.loadNextGroup();
    }

    loadFonts (group)
    {
        if (group && group.fonts)
        {
            this.startLoadGroup("fonts");
            this.startLoadAsset("fonts", "font");
        }

        group.fonts.loading = () => this.onFontLoaded(group);
        WebFont.load(group.fonts)
    }

    onFontLoaded (group)
    {
        if (group && group.fonts)
        {
            this.endLoadAsset("fonts", "font");
            this.endLoadGroup("fonts");
        }

        this.loadNextGroup();
    }

    loadPixiAssets (group)
    {
        if (group && group.assets.length > 0)
        {
            this.startLoadGroup(group.type);

            this.fctPixiStartLoadAsset = (a,b) => this.pixiStartLoadAsset(group.type, a, b);
            this.fctPixiEndLoadAsset = (a,b) => this.pixiEndLoadAsset(group.type, a, b);
            this.pixiLoader.on("start-load-asset", this.fctPixiStartLoadAsset);
            this.pixiLoader.on("end-load-asset", this.fctPixiEndLoadAsset);
            this.pixiLoader.loadGroup(group.assets, this.strBasePath, group);//when this is loaded, onPixiLoadedComplete will be called
        }
        else
        {
            this.loadNextGroup();
        }

    }

    onPixiLoadedComplete (resources, group)
    {
        if (group && group.assets.length > 0)
        {
            this.pixiLoader.off("start-load-asset", this.fctPixiStartLoadAsset);
            this.pixiLoader.off("end-load-asset", this.fctPixiEndLoadAsset);
            this.endLoadGroup(group.type);
        }

        this.loadNextGroup();
    }


    async loadThreeAssets (group)
    {
        if (group && group.assets.length > 0)
        {
            this.startLoadGroup(group.type);

            if (!this.threeJSLoader)
            {
                this.threeJSLoader = new ThreeJSMultipackLoader();
                this.threeJSLoader.on("end-load-asset", this.fctOnThreeEndLoadAsset);
                this.threeJSLoader.on("end-load-atlas-asset", this.fctOnThreeAtlasEndLoadAsset);
            }


            //NOT COMPLETED FOR NOW
            for (let i = 0; i < group.assets.length; i++)
            {
                let asset = group.assets[i];

                let url = BASE_PATH + asset.url;

                if (asset.basepath)
                {
                    if (asset.basepath === "direct")
                        url = asset.url;
                }
                else if (asset.url.substr(0,5) === "https")
                {
                    url = asset.url;
                }

                if (asset.type === "multipack")
                {

                    await this.threeJSLoader.load(url, asset.key)

                }
                else
                {
                    await this.threeJSLoader.loadTexture(url, asset.key)
                }
            }

            this.loadNextGroup();
        }
        else
        {
            this.loadNextGroup();
        }
    }


    async loadThreeTexture (strPath)
    {
        let texture = await this.promiseLoader(strPath, this.threeTextureLoader);
        console.log("Loader.loadThreeTexture", texture);
        return texture;


    }

    async loadPixiTexture (strKey, strPath, callback)
    {
        let texture = Library.getTextureFromResources(strKey);

        if (texture)
            return texture;

        texture = await this.pixiPromiseLoader(strKey, strPath, this.pixiLoader.loader)
        return texture;


    }

    pixiPromiseLoader (strKey, strPath, loader)
    {
        return new Promise((resolve, reject) =>
        {
            loader.add(strKey, strPath);

            loader.load((data, resources) =>
                {
                    console.log("onLoad asset", arguments, resources)
                    let t = resources[strKey].texture;
                    resolve(t);
                }
            , null, reject);

        });
    }


    promiseLoader(url, loader)
    {
        return new Promise((resolve, reject) =>
        {
            loader.load(url, data =>
            {
                resolve(data);
                this.emit("end-load-asset");
            }, null, reject);
        });
    }


    static addVersionToURL (url)
    {
        let strQueryParam = url.indexOf("?") > -1 ? "&" : "?";

        url += strQueryParam + "v=" + __COMPILATION_DATE__; //compilation comes from webpack






        url += "&time=" + (new Date().getTime());







        return url;
    }

    loadCharacterAtlases(objAtlases)
    {
        if (!this.threeJSLoader)
        {
            this.threeJSLoader = new ThreeJSMultipackLoader();
            this.threeJSLoader.on("end-load-asset", this.fctOnThreeEndLoadAsset);
            this.threeJSLoader.on("end-load-atlas-asset", this.fctOnThreeAtlasEndLoadAsset);
        }

        this.threeJSLoader.once("end-load-atlas", (objAtlases) => 
        {
            this.emit("end-load-atlas", objAtlases)
        });
        this.threeJSLoader.loadCharacterAtlases(objAtlases);
    }

    onThreeAtlasEndLoadAsset(strKey)
    {
        this.emit("end-load-atlas-asset", strKey);
    }
}