import { string2hex }  from '@pixi/utils'; 
import { TextStyle } from '@pixi/text';
import { TextMetrics } from '@pixi/text';
import BasePopup from "../BasePopup.js";
import Display from "../../../utils/Display.js";
import GraphicsResponsive from "../responsive/GraphicsResponsive.js";
import SliderControl from "../controls/SliderControl.js";
import TextButton from "../buttons/TextButton.js";
import TextResponsive from "../responsive/TextResponsive.js";

export default class PausePopup extends BasePopup
{
    constructor()
    {
        super();
    }

    get VOLUME_TYPE_MUSIC() { return this.UI.AudioManager.AUDIO_TYPE_BGM; }
    get VOLUME_TYPE_SFX() { return this.UI.AudioManager.AUDIO_TYPE_SFX; }
    get VOLUME_TYPE_DIALOG() { return this.UI.AudioManager.AUDIO_TYPE_DIALOG; }

    get MusicLabel() { return this.UI.LabelManager.translate("Para_Musique"); }
    get SfxLabel() { return this.UI.LabelManager.translate("Para_FX"); }
    get DialogLabel() { return this.UI.LabelManager.translate("Para_Voix"); }
    get ConnectLabel() { return this.UI.LabelManager.translate("Para_Connecte"); }
    get SaveLabel() { return this.UI.LabelManager.translate("Para_Save"); }
    get QuitLabel() { return this.UI.LabelManager.translate("Para_Quit"); }
    get QuitDescLabel() { return this.UI.LabelManager.translate("Pause_Quit_Valide"); }
    get ConnectUrl () { return this.UI.SaveManager.ConnectUrl; }

    get BgWidth() { return "(ih * 90%)"; }
    get BgHeight() { return "(ih * 60%)"; }

    get VolumeSubBarWidth() { return this.evaluate(this.valueFormula(3), 0, 0, 0, 0);}
    get VolumeSubBarColor() { return 0xFFFFFF;}

    get SaveBoxBackgroundColor() { return string2hex(this.UI.Values.connect.background.color);}
    get SaveBoxCornerRadius() { return this.evaluate(this.valueFormula(this.UI.Values.connect.corner.radius), 0, 0, 0, 0);}

    getVolumeLabel(strType)
    {
        let label = "";
        if (strType == this.VOLUME_TYPE_MUSIC)
        {
            label = this.MusicLabel;
        }
        else if (strType == this.VOLUME_TYPE_SFX)
        {
            label = this.SfxLabel;
        }
        else if (strType == this.VOLUME_TYPE_DIALOG)
        {
            label = this.DialogLabel;
        }
        return label;
    }

    /*******************************************
    *   INITIALIZATION
    *******************************************/
    /**
        Parameters to pass to the init function:
        - ui:           UI section object where this component resides
        - clickToHide:  (Optional) If the popup should hide when the player clicks on the overlay behind. Default is TRUE
        - showOnStart   (Optional) If the popup should be shown on start. Default is TRUE
        - showClose:    (Optional) If the close button should be shown. Default is TRUE
        - createOverlay (Optional) If this popup should create it's own overlay for itself. If not, it will use the general UI overlay. Default is FALSE
        - sideBySide    (Optional) If this popup should be positioned side-by-side with other popups on screen. Default is TRUE
        - linked        (Optional) Linked popup to this one. If this one closes, the linked popup will close too. Default is NULL
    */
    init(meta)
    {
        meta.title = meta.ui.LabelManager.translate("Para_Pause").toUpperCase();

        this.controls = {};
        this.controlsCallback = {};

        this.isConnected = false;
        this.timeLeftAnimating = 0;

        return super.init(meta);
    }

    createClosure()
    {
        super.createClosure();

        this.fctOnSaveClick = this.onSaveClick.bind(this);
        this.fctOnQuitClick = this.onQuitClick.bind(this);
        this.fctOnVolumeChange = this.onVolumeChange.bind(this);
        this.fctOnVolumeDragEnd = this.onVolumeDragEnd.bind(this);
        this.fctOnUrlClick = this.onUrlClick.bind(this);
        this.fctOnConnectionStateChange = this.onConnectionStateChange.bind(this);
    }

    bindEvents()
    {
        super.bindEvents();
        this.UI.SaveManager.on(this.UI.SaveManager.EVENT_TELE_QUEBEC_CONNECTION_STATE_CHANGED, this.fctOnConnectionStateChange);
    }

    destroy(options)
    {
        this.SaveManager.on(this.SaveManager.EVENT_TELE_QUEBEC_CONNECTION_STATE_CHANGED, this.fctOnConnectionStateChange)

        for (let key in this.controls)
        {
            this.controls[key].off(this.controls[key].EVENT_VALUE_CHANGED, this.fctOnVolumeChange);
            this.controls[key].off(this.controls[key].EVENT_DRAG_END, this.fctOnVolumeDragEnd);
            this.controls[key].destroy(options);
        }

        if (this.graphics.saveBox)
        {
            this.graphics.saveBox.off("pointerup", this.fctOnUrlClick);
        }

        if (this.saveButton)
        {
            this.saveButton.off(TextButton.EVENT_CLICK, this.fctOnSaveClick);
            this.saveButton.destroy(options);
        }

        if (this.quitButton)
        {
            this.quitButton.off(TextButton.EVENT_CLICK, this.fctOnQuitClick);
            this.quitButton.destroy(options);
        }

        super.destroy(options);
    }

    /*******************************************
    *   BUILD
    *******************************************/
    build()
    {
        super.build();

        this.buildVolumeControls();
        this.buildSave();
        this.buildQuit();

        if (this.timeLeftAnimating > 0)
        {
            this.buildLoadingAnimation();
        }
    }

    clean()
    {
        if (this.loadingGraphic)
        {
            this.loadingGraphic.clear();
        }
        super.clean();
    }

    buildVolumeControls(baseY)
    {
        let types = [this.VOLUME_TYPE_MUSIC, this.VOLUME_TYPE_SFX, this.VOLUME_TYPE_DIALOG];

        let bgHeight = this.evaluate(this.BgHeight, 0, 0, 0, 0, this);
        if (!baseY && baseY !== 0)
        {
            baseY = bgHeight / 8;
        }
        let spacing = bgHeight / 8;

        for (let i = 0; i < types.length; i++)
        {
            let id = types[i];
            let volumeValue = this.UI.AudioManager.getVolume(id);

            if (!this.controls[id])
            {
                this.controls[id] = new SliderControl().init({
                    "ui": this.UI,
                    "value": volumeValue,
                    "id": id
                });
                this.controls[id].on(this.controls[id].EVENT_VALUE_CHANGED, this.fctOnVolumeChange);//@
                this.controls[id].on(this.controls[id].EVENT_DRAG_END, this.fctOnVolumeDragEnd);//@

                this.addChild(this.controls[id]);
            }

            let control = this.controls[id];
            control.Value = volumeValue;

            let barWidth = this.evaluate(this.BgWidth + " * 0.425", 0, 0, 0, 0, this);
            let barHeight = control.Size.height;

            let barX = this.evaluate(this.BgWidth + " / 2 - " + barWidth + " / 2", 0, 0, 0, 0, this);
            let barY = baseY + spacing * i + barHeight * (i + 1) - barHeight / 2;

            control.rX = [{on:"default", x: barX}];
            control.rY = [{on:"default", y: barY}];
            control.barSize.width = barWidth;

            control.layout(Display.getSize());

            this.buildVolumeText(id, barX, barY, barWidth, control.BarHeight);
            this.buildVolumeSubBars(id, barX, barY, barWidth, barHeight);
        }
    }

    buildVolumeText(strId, fControlX, fControlY, fControlWidth, fControlHeight)
    {
        if (!this.texts[strId])
        {
            this.texts[strId] = new TextResponsive().init({"ui": this.UI});
            this.addChild(this.texts[strId]);
        }

        let text = this.texts[strId];
        let label = this.getVolumeLabel(strId);

        let style = new TextStyle({
            fontFamily: this.UI.Values.general.title.font,
            fontSize:   this.evaluate(this.valueFormula(this.UI.Values.general.title.size * 1.25)),
            fontWeight: this.UI.Values.general.title.weight,
            fill: this.UI.Values.general.text.color,
            align: "center"
        });

        let metrics = TextMetrics.measureText(label, style);

        let x = fControlX - metrics.width / 2 - fControlWidth * 0.25;
        let y = fControlY + fControlHeight / 2 - metrics.height / 2;

        text.text = label;
        text.style = style;
        text.width = metrics.width;
        text.height = metrics.height;
        text.position.set(x, y);
    }

    buildVolumeSubBars(strId, fControlX, fControlY, fControlWidth, fControlHeight)
    {
        if (!this.graphics["bars_" + strId])
        {
            this.graphics["bars_" + strId] = new GraphicsResponsive().init({"ui":this.UI});
            this.addChild(this.graphics["bars_" + strId]);
        }

        let graphics = this.graphics["bars_" + strId];
        let count = 10;
        let barWidth = this.VolumeSubBarWidth;
        let barHeight = fControlHeight * 0.5;
        let spacing = fControlWidth / (count - 2);

        graphics.beginFill(this.VolumeSubBarColor, 1);

        let x = fControlX + fControlWidth - barWidth;
        let y = fControlY + fControlHeight * 0.9;

        graphics.drawRect(x, y, barWidth, barHeight);

        x = fControlX;
        graphics.drawRect(x, y, barWidth, barHeight);

        for (let i = 1; i < count - 2; i++)
        {
            x += spacing;
            graphics.drawRect(x, y, barWidth, barHeight);
        }

        graphics.endFill();
    }

    buildSave()
    {
        this.isConnected = this.UI.SaveManager.IsConnected;

        if (this.isConnected)
        {
            this.buildSaveButton();
        }
        else
        {
            this.buildSaveConnection();
        }
    }

    buildSaveConnection()
    {
        if (!this.graphics.saveBox)
        {
            this.graphics.saveBox = new GraphicsResponsive().init({"ui":this.UI});
            this.graphics.saveBox.interactive = true;
            this.graphics.saveBox.buttonMode = true;
            this.graphics.saveBox.on("pointerup", this.fctOnUrlClick);//@

            this.addChild(this.graphics.saveBox);
        }

        if (!this.texts.saveBox)
        {
            this.texts.saveBox = new TextResponsive().init({"ui": this.UI});
            this.addChild(this.texts.saveBox);
        }

        if (!this.texts.connectUrl)
        {
            this.texts.connectUrl = new TextResponsive().init({"ui": this.UI});
            this.addChild(this.texts.connectUrl);
        }

        let graphics = this.graphics.saveBox;
        let text = this.texts.saveBox;
        let textUrl = this.texts.connectUrl;

        let label = this.ConnectLabel;
        let urlLabel = this.ConnectUrl;

        let bgWidth = this.evaluate(this.BgWidth, 0, 0, 0, 0, this);

        let style = {
            fontFamily: this.UI.Values.connect.text.font,
            fontSize:   this.evaluate(this.valueFormula(this.UI.Values.connect.text.size)),
            fontWeight: this.UI.Values.connect.text.weight,
            fill: this.UI.Values.connect.text.color,
            align: "center",
            wordWrap: true,
            wordWrapWidth: bgWidth
        };

        let textStyle = new TextStyle(style);
        style.fontWeight = "bold";
        let urlStyle = new TextStyle(style);

        let metrics = TextMetrics.measureText(label, textStyle);
        let metricsUrl = TextMetrics.measureText(urlLabel, urlStyle);

        let textX = bgWidth / 2 - metrics.width / 2;
        let textY = this.evaluate(this.BgHeight + " * 0.725", 0, 0, 0, 0, this);

        let textUrlX = bgWidth / 2 - metricsUrl.width / 2;
        let textUrlY = textY + metrics.height * 1.1;

        text.text = label;
        text.style = textStyle;
        text.width = metrics.width;
        text.height = metrics.height;
        text.position.set(textX, textY);

        textUrl.text = urlLabel;
        textUrl.style = urlStyle;
        textUrl.width = metricsUrl.width;
        textUrl.height = metricsUrl.height;
        textUrl.position.set(textUrlX, textUrlY);

        let padding = bgWidth * 0.01;
        let boxWidth = bgWidth * 0.9;
        let boxHeight = metrics.height * 1.1 + metricsUrl.height + padding * 2;
        let boxX = bgWidth / 2 - boxWidth / 2;
        let boxY = textY - padding;

        let bgColor = this.SaveBoxBackgroundColor;
        let cornerRadius = this.SaveBoxCornerRadius;

        graphics.beginFill(bgColor, 1);
        if (cornerRadius > 0)
        {
            graphics.drawRoundedRect(boxX, boxY, boxWidth, boxHeight, cornerRadius);
        }
        else
        {
            graphics.drawRect(boxX, boxY, boxWidth, boxHeight);
        }
        graphics.endFill();
    }

    buildSaveButton()
    {
        if (!this.saveButton)
        {
            this.saveButton = new TextButton().init({"ui": this.UI, "text": this.SaveLabel});
            this.saveButton.on(this.saveButton.EVENT_CLICK, this.fctOnSaveClick);//@

            this.addChild(this.saveButton);
        }

        let size = this.saveButton.getButtonSize();

        this.saveButton.rX = [{on:"default", x: this.BgWidth + " / 2 - " + size.width + " / 2"}];
        this.saveButton.rY = [{on:"default", y: this.BgHeight + " * 0.825 - " + size.height}];

        this.saveButton.layout(Display.getSize());
    }

    buildQuit()
    {
        if (!this.quitButton)
        {
            this.quitButton = new TextButton().init({"ui": this.UI, "text": this.QuitLabel});
            this.quitButton.on(this.quitButton.EVENT_CLICK, this.fctOnQuitClick);//@

            this.addChild(this.quitButton);
        }

        let size = this.quitButton.getButtonSize();

        this.quitButton.rX = [{on:"default", x: this.BgWidth + " / 2 - " + size.width + " / 2"}];
        this.quitButton.rY = [{on:"default", y: this.BgHeight + " * 0.96 - " + size.height}];

        this.quitButton.layout(Display.getSize());
    }

    buildLoadingAnimation()
    {
        if (!this.loadingGraphic)
        {
            this.loadingGraphic = new GraphicsResponsive().init({"ui":this.UI});
            this.addChild(this.loadingGraphic);
        }

        let graphics = this.loadingGraphic;

        let saveButtonSize = this.saveButton.getButtonSize();
        let radius = saveButtonSize.height / 4;
        let x = this.saveButton.position.x + saveButtonSize.width * 1.15;
        let y = this.saveButton.position.y + saveButtonSize.height / 2;

        graphics.position.set(x, y);

        graphics.angle = 0;
        graphics.lineStyle({"color":this.StrokeColor, "width":this.StrokeWidth, "alignment":1});
        graphics.drawEllipse(0, 0, radius, radius);

        graphics.lineStyle({"width":0});
        graphics.beginFill(this.BackgroundColor);
        graphics.drawRect(-radius / 4, -radius * 2, radius / 2, radius * 2);
        graphics.endFill();

        this.totalTime = this.timeLeftAnimating;
        this.lastTime = new Date().getTime();
        this.showLoadingAnimation();
    }

    showLoadingAnimation()
    {
        let time = new Date().getTime();
        let deltaTime = time - this.lastTime;
        this.lastTime = time;

        this.timeLeftAnimating -= deltaTime;
        let value = Math.max(0, Math.min(1, 1 - (this.timeLeftAnimating / this.totalTime)));
        let angle = 360 * value;

        this.loadingGraphic.angle = angle;

        if (this.timeLeftAnimating > 0)
        {
            window.requestAnimationFrame(this.showLoadingAnimation.bind(this));
        }
        else
        {
            this.saveButton.IsDisabled = false;
            this.build();
        }
    }

    /*******************************************
    *   EVENTS
    *******************************************/
    onSaveClick(sender)
    {
        this.UI.SaveManager.save();

        this.saveButton.IsDisabled = true;
        this.timeLeftAnimating = 666;
        this.build();
    }

    onQuitClick(sender)
    {
        this.UI.showChoicePopup(
            this.QuitLabel,
            this.QuitDescLabel,
            () => { this.UI.GameManager.quitCurrentGame(); },
            () => {
                this.show();
                this.UI.closeChoicePopup();
            }
        );
        this.hide();
    }

    onVolumeChange(sender, fNewValue)
    {
        this.UI.AudioManager.setVolume(sender.Id, fNewValue);
    }

    onVolumeDragEnd(sender)
    {
        this.UI.SaveManager.save();
    }

    onUrlClick()
    {
        let connectWindow = window.open(this.ConnectUrl, '_blank');
        connectWindow.focus();
    }

    onConnectionStateChange(bIsConnected)
    {
        if (this.isConnected != bIsConnected)
        {
            this.build();
        }
    }
}