import {PlaneGeometry} from "three";
import {Vector2} from "three";
import BaseObject from "../BaseObject.js";
import Constants from "../../../utils/Constants.js";
import Direction from "../../../utils/Direction.js";
import Library from "../../../Library.js";
import Utils from "../../../utils/Utils.js";

export default class BaseProjectile extends BaseObject
{
    constructor()
    {
        super();
    }

    get ATLAS_ID() { return ""; }
    get TEXTURE_ID() { return "{0}"; }

    get BaseFpsSpeed() { return 1/30; }
    get Direction() { return this.direction; }
    get Distance() { return this.meta.distance; }
    get MovementSpeed() { return 0.5; }
    get PlaneSize() { return this.planeSize; }
    get TargetType() { return this.meta.targetType; }

    /*******************************************
    *   INITIALIZATION
    *******************************************/
    /**
        Parameters to pass to the init function:
        - id:           Logic id of this object instance. Usually managed by some sort of global manager
        - spawnPos      Vector2 containing the position to spawn this object on the field. Should be in ThreeJS world coordinates (not grid)
        - targetPos     Vector2 containing the position to aim the projectile to. Should be in ThreeJS world coordinates (not grid)
        - distance      Maximum number of tiles before the projectile is despawned
        - targetType    Type of target to hit. Use the TYPE_XXX constants from the BaseEnvironment
        - environment   Environment instance where this object lives
        - spawnOnCreate (Optional)If this object should be spawned as soon as the instance is created. Default is TRUE
        - meshOrigin    (Optional)Relative position of the mesh's geometry. Default is {"x": 0.5, "y": 1}
        - params        JSON object containing additionnal parameters to pass to this object

    */
    init(meta)
    {
        meta = this.initMovement(meta);
        meta = this.initDirection(meta);
        meta = this.initVisuals(meta);

        super.init(meta);

        if (this.Direction == Direction.East)
        {
            this.Mesh.scale.x = -1;
        }

        this.Mesh.position.set(this.movement.from.x, this.movement.from.y, this.movement.from.z);
    }

    initMovement(meta)
    {
        this.movement = {
            "from": meta.spawnPos,
            "to": meta.targetPos,
            "direction": new Vector2(meta.targetPos.x - meta.spawnPos.x, meta.targetPos.z - meta.spawnPos.z),
            "maxSqrtDist": Math.pow(meta.distance * meta.environment.Grid.CellSize, 2)
        };
        this.movement.direction.normalize();

        return meta;
    }

    initDirection(meta)
    {
        meta.spawnPos = meta.environment.Grid.worldToGridPosition(meta.spawnPos.x, meta.spawnPos.z);
        this.direction = this.calculateDirection(this.movement.from, this.movement.to);

        return meta;
    }

    createGeometry(objSize, objOrigin)
    {
        let divider = this.ResponsiveManager.AssetDivider;
        if (!objSize)
        {
            objSize = {"w": this.Grid.CellSize, "h": this.Grid.CellSize};
            divider = 1;
        }

        this.planeSize = {
            "width": objSize.w / divider,
            "height": objSize.h / divider
        };

        let geometry = new PlaneGeometry(this.PlaneSize.width, this.PlaneSize.height, 10, 10);
        geometry.translate(this.PlaneSize.width * objOrigin.x, this.PlaneSize.height * objOrigin.y, 0);
        geometry.verticesNeedUpdate = true;

        return geometry;
    }

    calculateDirection(from, to)
    {
        let direction = Direction.South;
        let diff = new Vector2(from.x - to.x, from.z - to.z);

        if (diff.x != 0 || diff.y != 0)
        {
            if (diff.x <= -1) { direction = Direction.East; }
            else if (diff.x >= 1) { direction = Direction.West; }
            else if (diff.y >= 1) { direction = Direction.North; }
            else { direction = Direction.South; }
        }

        return direction;
    }

    /*******************************************
    *   UPDATE LOOP
    *******************************************/
    update(fDeltaTime)
    {
        this.updateMovement(fDeltaTime);
        super.update(fDeltaTime);

        this.updateCollision(fDeltaTime);
    }

    updateMovement(fDeltaTime)
    {
        let sqrDist = Math.pow(this.Mesh.position.x - this.movement.from.x, 2) + Math.pow(this.Mesh.position.z - this.movement.from.z, 2);
        if (sqrDist < this.movement.maxSqrtDist)
        {
            let ratio = this.BaseFpsSpeed / fDeltaTime;
            let speed = this.MovementSpeed * ratio;

            this.Mesh.position.x += this.movement.direction.x * speed;
            this.Mesh.position.z += this.movement.direction.y * speed;
        }
        else
        {
            this.removeProjectile();
        }
    }

    updateCollision(fDeltaTime)
    {
        let self = this.CollisionRect;
        let content = this.Environment.getAll(this.TargetType);

        for (let i = 0; i < content.length; i++)
        {
            let target = content[i];
            let collision = target.CollisionRect;

            if (Utils.isOverlapping(self, collision))
            {
                this.collide(target);
                break;
            }
        }
    }

    /*******************************************
    *   OVERRIDE
    *******************************************/
    initVisuals(meta)
    {
        //This is where the atlasName and texture are getting set in the 
        //meta data object
        console.warn("The initVisuals method should be overriden by the child class");

        return meta;
    }

    collide(objTarget)
    {
        console.warn("The collide method should be overriden by the child class");
    }

    removeProjectile()
    {
        console.warn("The removeProjectile method should be overriden by the child class");
    }
}
