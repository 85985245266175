import EventEmitter from "eventemitter3";
import {RepeatWrapping} from "three";
import Library from "../../../Library.js";

export default class Animation extends EventEmitter
{
    constructor()
    {
        super();
        this.rand = Math.random();
    }

    get EVENT_SWITCH_ATLAS() { return "switch-atlas"; }
    get EVENT_ANIMATION_CYCLE_DONE() { return "animation-cycle-done"; }
    get EVENT_ANIMATION_NEXT_FRAME() { return "animation-next-frame"; }

    get DEFAULT_FPS() { return 30; }
    get SPRITE_PER_COLUMN() { return 4; }
    get SPRITE_PER_ROW() { return Math.ceil(this.atlases[this.atlasIndex].frameCount / this.SPRITE_PER_COLUMN); }

    get Id() { return this.id; }
    get IsPlaying() { return (this.animate ? true : false); }
    get IsLooping() { return this.isLooping; }
    get IsPaused() { return this.isPaused; }
    get Fps() { return this.fps * this.speed; }
    get Speed() { return this.speed; }
    set Speed(newValue)
    {
        let oldValue = this.speed;
        this.speed = newValue;

        if (this.animate && oldValue != newValue)
        {
            this.startUpdateLoop();
        }
    }

    /*******************************************
    *   INITIALIZATION
    *******************************************/
    /**
        Parameters to pass to the init function:
        - id                Identifier for this animation
        - isPlayer          If this animation is a player animation. If so, the frame orders has to be readjusted
        - atlases:          List of atlases required to run this animation
        - mesh:             Mesh object to render this animation
        - characterBuild:   (Optional)String representing the character build (ex. 100222_5002) to get the right textures in memory. Only required if this is a player animation.

    */
    init(meta)
    {
        this.id = meta.id;
        this.atlases = meta.atlases;
        this.isPlayer = meta.isPlayer;
        this.mesh = meta.mesh;
        this.characterBuild = meta.characterBuild;
        this.atlasIndex = 0;
        this.frameIndex = 0;
        this.framePositions = null;
        this.fps = this.DEFAULT_FPS;
        this.isLooping = false;
        this.speed = 1;
        this.isPaused = false;

        this.createClosure();
    }

    createClosure()
    {
        this.fctUpdate = this.update.bind(this);
    }

    destroy()
    {
        this.stopUpdateLoop();
    }

    /*******************************************
    *   UPDATE LOOP
    *******************************************/
    update()
    {
        this.updateAnimation();
    }

    updateAnimation()
    {
        this.nextFrame();
    }

    startUpdateLoop()
    {
        this.stopUpdateLoop();
        this.animate = setInterval(this.fctUpdate, 1/this.Fps * 1000);
    }

    stopUpdateLoop()
    {
        clearInterval(this.animate);
        this.animate = null;
    }

    /*******************************************
    *   PLAYBACK FUNCTIONS
    *******************************************/
    play(bLoop)
    {
        if (!this.animate)
        {
            this.atlasIndex = 0;
            this.frameIndex = 0;
            this.isLooping = bLoop;
            this.switchAtlas(this.atlasIndex);
            this.nextFrame();

            this.startUpdateLoop();
            this.isPaused = false;
        }
    }

    pause()
    {
        this.stopUpdateLoop();
        this.isPaused = true;
    }

    resume()
    {
        this.startUpdateLoop();
        this.isPaused = false;
    }

    stop()
    {
        this.atlasIndex = 0;
        this.frameIndex = 0;
        this.stopUpdateLoop();
    }

    /*******************************************
    *   TEXTURE MANAGEMENT
    *******************************************/
    getTexture(strTextureId)
    {
        let key = strTextureId;
        if (this.characterBuild)
        {
            key = this.characterBuild + "_" + strTextureId;
        }
        return Library.getTexture3D(key);
    }

    getFramePositions(iIndex)
    {
        let positions = [];
        if (this.atlases[iIndex].order)
        {
            positions = this.atlases[iIndex].order;
        }
        else
        {
            //Not sure why but for most player/npc/enemies animations, frames have to be manually reorganized
            if (this.atlases[iIndex].reverse)
            {
                return [15,14,13,12,11,7,3,10,9,8,6,2,5,4,1,0];
            }
            return [3,2,1,0,7,11,15,6,5,4,10,14,9,8,13,12];
        }

        return positions;
    }

    switchAtlas(iIndex)
    {
        let atlas = this.atlases[iIndex];
        let texture = this.getTexture(atlas.name);

        texture = this.createPartialTexture(texture, (atlas.mirror ? -1 : 1));
        this.mesh.material.map = texture;

        this.framePositions = this.getFramePositions(iIndex);
        this.fps = (atlas.fps ? atlas.fps : this.DEFAULT_FPS);
    }

    createPartialTexture (objTexture, xFlip, fctCallback = null)
    {
        objTexture.wrapS = objTexture.wrapT = RepeatWrapping;

        let fXRatio = 1 / this.SPRITE_PER_COLUMN;
        let fYRatio = 1 / this.SPRITE_PER_ROW;

        let fXRatioDir = fXRatio * xFlip;
        let fYRatioDir = fYRatio;

        objTexture.repeat.set(fXRatioDir, fYRatioDir);

        if (fctCallback)
        {
            fctCallback(objTexture);
        }

        return objTexture;
    }

    positionTexture(iFrameIndex)
    {
        let atlas = this.atlases[this.atlasIndex];
        let pos = this.framePositions[iFrameIndex];

        let perCol = this.SPRITE_PER_COLUMN;
        let perRow = this.SPRITE_PER_ROW;

        let x = pos / perCol >> 0;
        let y = pos % perRow;

        let size = {width: this.mesh.material.map.image.width, height: this.mesh.material.map.image.height};

        let flipX = (atlas.mirror ? -1: 1);
        let offsetX = (x * (size.width / perCol)) / size.width * flipX;
        let offsetY = (y * (size.height / perRow)) / size.height;

        if (atlas.offset)
        {
            if (atlas.offset.x)
            {
                offsetX += atlas.offset.x;
            }
            if (atlas.offset.y)
            {
                offsetY += atlas.offset.y;
            }
        }

        let texture = this.mesh.material.map;

        if (texture)
        {
            texture.offset.x = offsetX;
            texture.offset.y = offsetY - 2/this.mesh.material.map.image.height;

            
        }
    }

    nextFrame()
    {
        if (this.frameIndex < this.framePositions.length)
        {
            this.positionTexture(this.frameIndex);
            this.emit(this.EVENT_ANIMATION_NEXT_FRAME, this.frameIndex, this.atlasIndex);
            this.frameIndex++;
        }
        else if (this.atlasIndex < this.atlases.length - 1)
        {
            this.atlasIndex++;
            this.emit(this.EVENT_SWITCH_ATLAS, this, this.atlases[this.atlasIndex].name);
            this.switchAtlas(this.atlasIndex);
        }
        else
        {
            this.emit(this.EVENT_ANIMATION_CYCLE_DONE, this);
            
            this.atlasIndex = 0;
            this.frameIndex = 0;

            if (this.isLooping)
            {
                this.switchAtlas(this.atlasIndex);
                this.nextFrame();
            }
            else
            {
                this.stopUpdateLoop();
            }
        }
    }
}