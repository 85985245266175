export default class TriggerCodes
{
    //CONDITION CODES
    //--------------------------------------------------------------
    static get CONDITION_CHARACTER_STATE() { return "CST" };
    static get CONDITION_CRAFT_BACKPACK() { return "BAG" };
    static get CONDITION_BARN() { return "BAR" };
    static get CONDITION_CRAFT_ITEM() { return "CIT" };
    static get CONDITION_DISTANCE() { return "DIS" };
    static get CONDITION_TRIGGER() { return "EVT" };
    static get CONDITION_OWN_ITEM() { return "OIT" };
    static get CONDITION_OWN_TYPE_ITEM() { return "OTI" };
    static get CONDITION_LOCATION() { return "LOC" };
    static get CONDITION_MISSION() { return "MIS" };
    static get CONDITION_MUTATION() { return "MUT" };
    static get CONDITION_OBJECTIVE() { return "OBT" };
    static get CONDITION_STAT() { return "STA" };
    static get CONDITION_TILE_LOCATION() { return "TIL" };
    static get CONDITION_TIME() { return "TIM" };
    static get CONDITION_TILE_RANGE() { return "TRN" };
    static get CONDITION_TUTORIAL() { return "TUT" };
    static get CONDITION_USE_ITEM() { return "UIT" };
    static get CONDITION_CLICK_RADIO() { return "RAD" };
    static get CONDITION_STEPS_PUT_DOWN() { return "STP" };
    static get CONDITION_BROTHERS() { return "BRO" };
    static get CONDITION_OPEN_BAG() { return "OPEN_BAG" };
    static get CONDITION_NO_NPC() { return "NO_NPC" };

    //new in season 2
    static get CONDITION_LAUNCH_TRIGGER() { return "LAUNCHTRIG" };
    static get CONDITION_CLICK_ON_GRID() { return "CLICK_OBJ" };
    static get CONDITION_VARIABLE_VALUE() { return "VAR_VALUE" };
    static get CONDITION_CLICK_ON_OBJECT_INDOOR() { return "INDOORCLIK" };
    static get CONDITION_PLAYER_IS_INDOOR() { return "IS_INDOOR" };
    static get CONDITION_FOREST_CLICK_ON_OBJECT() { return "FOR_OB_TRG" };

    //REPEAT CODES
    //--------------------------------------------------------------
    static get REPEAT_ONCE() { return "ONC" };
    static get REPEAT_FINITE() { return "REP" };
    static get REPEAT_TIME() { return "TIM" };
    static get REPEAT_INFINITE() { return "INF" };

    //ACTION CODES
    //--------------------------------------------------------------
    static get ACTION_SHOW_DIALOG() { return "DIA" };
    static get ACTION_SHOW_OBJECTIVE_DIALOG() { return "ODI" };
    static get ACTION_GET_ITEM() { return "GIT" };
    static get ACTION_KILL() { return "KIL" };
    static get ACTION_UPDATE_MISSION() { return "MIS" };
    static get ACTION_END_MISSION() { return "ENDMISSION" };
    static get ACTION_CLEAR_OBJECTIVE() { return "OBJ" };
    static get ACTION_SAVE() { return "SAV" };
    static get ACTION_SPAWN_ITEM() { return "SIT" };
    static get ACTION_SPAWN_MONSTER() { return "SMO" };
    static get ACTION_PLAY_SOUND() { return "SND" };
    static get ACTION_STAT_CHANGE() { return "STA" };
    static get ACTION_TELEPORT() { return "TEL" };
    static get ACTION_SPAWN_NPC() { return "NPC" };
    static get ACTION_MOVE_NPC() { return "MOV" };
    static get ACTION_CAMERA_FOLLOW_NPC() { return "CAM" };
    static get ACTION_REMOVE_NPC() { return "RMN" };
    static get ACTION_HIDE_PLAYER() { return "HID" };
    static get ACTION_SHOW_PLAYER() { return "SHO" };
    static get ACTION_WAIT() { return "WAI" };
    static get ACTION_CAMERA_POSITION() { return "CPO" };
    static get ACTION_BARN_LEVEL() { return "BAR" };
    static get ACTION_PLAY_ANIMATION() { return "ANI" };
    static get ACTION_PLAYER_WALK() { return "WLK" };
    static get ACTION_MUTATION_CHOICE() { return "MUT" };
    static get ACTION_REMOVE_ITEM() { return "RMI" };
    static get ACTION_BEGIN_CINEMATIC_MODE() { return "BCM" };
    static get ACTION_END_CINEMATIC_MODE() { return "ECM" };
    static get ACTION_PLAY_MUSIC() { return "PLAY_MUSIC" };
    static get ACTION_CLICK_RADIO() { return "CLIC_RADIO" };


    //new from saison2
    static get ACTION_SHOW_INTERACTIVE_DIALOG() { return "INT_DIALOG" };
    static get ACTION_GOTO() { return "GOTO" };
    static get ACTION_START_A_MINIGAME() { return "MINIGAME" };
    static get ACTION_BRANCH() { return "BRANCH" };
    static get ACTION_GO_INDOOR() { return "GO_INDOOR" };
    static get ACTION_CHANGE_VARIABLE() { return "CHANGE_VAR" };
    static get ACTION_FORCE_TRIGGER() { return "FORCE_TRIG" };
    static get ACTION_CHANGE_ENVIRONMENT() { return "CHG_ENV" };
    static get ACTION_SPAWN_NPC_INDOOR() { return "PERSOINDOR" };
    static get ACTION_CHANGE_INDOOR_OBJECT_TEXTURE() { return "CHG_TEXTUR" };
    static get ACTION_CHANGE_OUTDOOR_OBJECT() { return "CHG_OUT_OB" };
    static get ACTION_CHANGE_WALKABLE_TILE() { return "CHANG_WALK" };

    //OPERATOR CODES
    //--------------------------------------------------------------
    static get OPERATOR_EQUALS() { return "EQ" };
    static get OPERATOR_NOT_EQUALS() { return "!EQ" };
    static get OPERATOR_GREATER_THAN() { return "GT" };
    static get OPERATOR_GREATER_THAN_EQUALS() { return "GTE" };
    static get OPERATOR_LESS_THAN() { return "LT" };
    static get OPERATOR_LESS_THAN_EQUALS() { return "LTE" };

    //LOCATION CODES
    //--------------------------------------------------------------
    static get LOCATION_BARN() { return "BRN" };
    static get LOCATION_FOREST() { return "FOR" };
    static get LOCATION_LABO() { return "LAB" };
    static get LOCATION_HOUSE() { return "HOU" };
    static get LOCATION_CHARACTER_CREATOR() { return "CRE" };

    //CHARACTER STATE CODES
    //--------------------------------------------------------------
    static get CHARACTER_STATE_DEAD() { return "DED" };
    static get CHARACTER_STATE_SLEEP() { return "SLP" };
    static get CHARACTER_STATE_RELAX() { return "RLX" };
    static get CHARACTER_STATE_NORMAL() { return "NOR" };

    //PLAYER STAT CODES
    //--------------------------------------------------------------
    static get CHARACTER_STAT_HUNGER() { return "FOO" };
    static get CHARACTER_STAT_STRESS() { return "STR" };
    static get CHARACTER_STAT_ENERGY() { return "ENR" };

    //BARN ZONES CODES
    //--------------------------------------------------------------
    static get BARN_ZONE_CRAFTING() { return "CFT" };
    static get BARN_ZONE_COOKING() { return "CKG" };
    static get BARN_ZONE_SLEEP() { return "SLP" };
    static get BARN_ZONE_RELAX() { return "RLX" };

    //MISSION STATE CODES
    //--------------------------------------------------------------
    static get MISSION_STATE_DONE() { return "DONE" };
    static get MISSION_STATE_TO_DO() { return "TODO" };
    static get MISSION_STATE_CURRENT() { return "CUR" };


    //UI CODE
    static get ACTION_TUTORIAL_SHOW ()      { return "SHOW_TUT"};
    static get ACTION_TUTORIAL_HIDE ()      { return "HIDE_TUT"};
    static get ACTION_DISABLE_UI_EXCEPT ()  { return "NO_UI"};
    static get ACTION_ENABLE_UI ()          { return "YES_UI"};
    static get ACTION_DISABLE_3D_EXCEPT ()  { return "NO_3D"};
    static get ACTION_ENABLE_3D ()          { return "YES_3D"};
    static get ACTION_BLINK_PIN ()          { return "CLIGNO_YES"};
    static get ACTION_UNBLINK_PIN ()        { return "CLIGNO_NO"};
    static get ACTION_RELOAD ()        { return "RELOAD"};

}