import {Howl, Howler} from 'howler';
import AudioManager from "./AudioManager.js";
import Lerp from "../utils/Lerp.js";

export default class AudioFade
{
    constructor(objHowler, iAudioId, fVolumeFrom, fVolumeTo, iDuration, fctCallback)
    {
        this.audio = objHowler;
        this.id = iAudioId;
        this.tween = {
            "from": fVolumeFrom,
            "to": fVolumeTo,
            "duration": iDuration
        };
        this.timeLeft = this.tween.duration;
        this.callback = fctCallback;


        this.fctUpdate = this.update.bind(this);
        AudioManager.instance.GameManager.on(AudioManager.instance.GameManager.EVENT_UPDATE, this.fctUpdate);
    }

    update (fDelta)
    {
        this.timeLeft -= fDelta;
        if (this.timeLeft <= 0)
        {
            AudioManager.instance.GameManager.off(AudioManager.instance.GameManager.EVENT_UPDATE, this.fctUpdate);
            this.callback();
        }
        else
        {
            let t = 1 - this.timeLeft / this.tween.duration;
            if (this.tween.from > this.tween.to)
            {
                t--;
                t = t * t * t + 1.0;
            }
            else
            {
                t = t * t * t;
            }


            this.audio.volume(Lerp.lerp(this.tween.from, this.tween.to, t), this.id);
        }
    }

    stop ()
    {
        AudioManager.instance.GameManager.off(AudioManager.instance.GameManager.EVENT_UPDATE, this.fctUpdate);
    }
}