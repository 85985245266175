import { string2hex }  from "@pixi/utils"; 
import { TextStyle } from "@pixi/text";
import { TextMetrics } from "@pixi/text";
import BasePopup from "../BasePopup.js";
import Display from "../../../utils/Display.js";
import GraphicsResponsive from "../responsive/GraphicsResponsive.js";
import TextResponsive from "../responsive/TextResponsive.js";

export default class DualTabPopup extends BasePopup
{
    constructor()
    {
        super();
    }

    get EVENT_TAB_CHANGE() { return "tab-change"; }

    get TabsDefinition() { return {}; }

    get BgWidth() { return "(ih * 90%)"; }
    get BgHeight() { return "(ih * 70%)"; }
    get BgX() { return 0; }
    get BgY() { return this.TabActiveHeight + " - " + this.TabActiveCornerRadius; }
    
    get TabActiveWidth() { return "(" + this.BgWidth + " * 37.5%)"; }
    get TabActiveHeight() { return "(ih * 10%)"; }
    get TabInactiveWidth() { return "(" + this.TabActiveWidth + " - " + this.valueFormula(this.UI.Values.modal.stroke.size) + ")"; }
    get TabInactiveHeight() { return "(" + this.TabActiveHeight + " - " + this.valueFormula(this.UI.Values.modal.stroke.size) + ")"; }

    get TabSpacing() { return "(ih * 3%)"; }

    get BackgroundColor() { return string2hex(this.UI.Values.modal.background.color);}
    get StrokeWidth() { return this.evaluate(this.valueFormula(this.UI.Values.modal.stroke.size), 0, 0, 0, 0);}
    get StrokeColor() { return string2hex("color" in this.UI.Values.modal.stroke ? this.UI.Values.modal.stroke.color : "#000000");}
    get CornerRadius() { return this.evaluate(this.valueFormula(this.UI.Values.modal.corner.radius), 0, 0, 0, 0);}

    get TabActiveBackgroundColor() { return string2hex(this.UI.Values.tabs.active.background.color);}
    get TabActiveStrokeWidth() { return this.evaluate(this.valueFormula(this.UI.Values.tabs.active.stroke.size), 0, 0, 0, 0);}
    get TabActiveStrokeColor() { return string2hex("color" in this.UI.Values.tabs.active.stroke ? this.UI.Values.tabs.active.stroke.color : "#000000");}
    get TabActiveCornerRadius() { return this.evaluate(this.valueFormula(this.UI.Values.tabs.active.corner.radius), 0, 0, 0, 0);}
    get TabActiveFontSize() { return this.evaluate(this.valueFormula(this.UI.Values.tabs.active.text.size), 0, 0, 0, 0);}

    get TabInactiveBackgroundColor() { return string2hex(this.UI.Values.tabs.inactive.background.color);}
    get TabInactiveStrokeWidth() { return this.evaluate(this.valueFormula(this.UI.Values.tabs.inactive.stroke.size), 0, 0, 0, 0);}
    get TabInactiveStrokeColor() { return string2hex("color" in this.UI.Values.tabs.inactive.stroke ? this.UI.Values.tabs.inactive.stroke.color : "#000000");}
    get TabInactiveCornerRadius() { return this.evaluate(this.valueFormula(this.UI.Values.tabs.inactive.corner.radius), 0, 0, 0, 0);}
    get TabInactiveFontSize() { return this.evaluate(this.valueFormula(this.UI.Values.tabs.inactive.text.size), 0, 0, 0, 0);}

    /*******************************************
    *   INITIALIZATION
    *******************************************/
    /**
        Parameters to pass to the init function:
        - ui:           UI section object where this component resides
        - clickToHide:  (Optional) If the popup should hide when the player clicks on the overlay behind. Default is TRUE
        - showOnStart   (Optional) If the popup should be shown on start. Default is TRUE
        - showClose:    (Optional) If the close button should be shown. Default is TRUE
        - createOverlay (Optional) If this popup should create it's own overlay for itself. If not, it will use the general UI overlay. Default is FALSE
        - sideBySide    (Optional) If this popup should be positioned side-by-side with other popups on screen. Default is TRUE
        - linked        (Optional) Linked popup to this one. If this one closes, the linked popup will close too. Default is NULL
    */
    init(meta)
    {
        this.tabs = this.TabsDefinition;
        this.selectedIndex = Object.keys(this.tabs)[0];
        this.tabPatch = null;

        return super.init(meta);
    }

    destroy(options)
    {
        super.destroy(options);
    }

    /*******************************************
    *   BUILD
    *******************************************/
    build()
    {
        super.build();

        this.buildTabs();
        this.buildBackground();

        this.tabs[this.selectedIndex].build();
    }

    buildBackground()
    {
        if (!this.graphics.background)
        {
            this.graphics.background = new GraphicsResponsive().init({"ui":this.UI});
            this.graphics.background.interactive = true;

            this.addChild(this.graphics.background);
        }
        else
        {
            this.graphics.background.clear();
        }

        let graphics = this.graphics.background;

        let width = this.evaluate(this.BgWidth, 0, 0, 0, 0, this);
        let height = this.evaluate(this.BgHeight, 0, 0, 0, 0, this);
        let x = 0;
        let y = 0;

        let bgColor = this.BackgroundColor;
        let strokeWidth = this.StrokeWidth;
        let strokeColor = this.StrokeColor;
        let cornerRadius = this.CornerRadius;

        if (strokeWidth > 0)
        {
            graphics.lineStyle({"color":strokeColor, "width":strokeWidth, "alignment":1});
        }

        graphics.beginFill(bgColor, 1);
        if (cornerRadius > 0)
        {
            graphics.drawRoundedRect(x, y, width, height, cornerRadius);
        }
        else
        {
            graphics.drawRect(x, y, width, height);
        }
        graphics.endFill();

        graphics.rX = [{on:"default", x: this.BgX}];
        graphics.rY = [{on:"default", y: this.BgY}];

        if (this.fctCreateTabPatch)
        {
            this.fctCreateTabPatch(this.graphics.background);
        }
    }

    buildTitle() 
    {
        
    }

    buildTabs()
    {
        let index = 0;
        let size = Display.getSize();
        let isNew = false;

        for(let key in this.tabs)
        {
            if (!this.graphics["tab"+key])
            {
                this.graphics["tab"+key] = new GraphicsResponsive().init({"ui": this.UI});
                this.graphics["tab"+key].interactive = true;
                this.graphics["tab"+key].buttonMode = true;

                this.tabs[key].fctTabClick = this.onTabClick.bind(this, key);
                this.graphics["tab"+key].on("pointerup", this.tabs[key].fctTabClick)//@

                this.addChild(this.graphics["tab"+key]);
                isNew = true;
            }

            let graphics = this.graphics["tab"+key];

            let active = key == this.selectedIndex;

            let width = (active ? this.TabActiveWidth : this.TabInactiveWidth);
            let calculatedWidth = this.evaluate(width, 0, 0, 0, 0, this);
            let height = (active ? this.TabActiveHeight : this.TabInactiveHeight);
            let calculatedHeight = this.evaluate(height, 0, 0, 0, 0, this);
            let strokeWidth = (active ? this.TabActiveStrokeWidth : this.TabInactiveStrokeWidth);
            let cornerRadius = (active ? this.TabActiveCornerRadius : this.TabInactiveCornerRadius);
            let spacing = this.TabSpacing;
            let bgY = this.BgY;
            let tabX = "(" + width + " * " + index + " + " + spacing + " * " + index + ")";
            let tabY = "(" + bgY + " - " + height + " + " + cornerRadius + ")";
            let bgColor = (active ? this.TabActiveBackgroundColor : this.TabInactiveBackgroundColor);

            graphics.rX = [{on:"default", x: tabX}];
            graphics.rY = [{on:"default", y: tabY}];

            if (active)
            {
                this.fctCreateTabPatch = function(x, y, width, height, bgColor, graphics)
                {
                    graphics.lineStyle({"color":bgColor, "width":0, "alignment":0});
                    graphics.beginFill(bgColor, 1);
                    graphics.drawRect(x, y, width, height);
                    graphics.endFill();
                }
                .bind(this, this.evaluate(tabX, 0, 0, 0, 0, this), -strokeWidth * 2, calculatedWidth, strokeWidth * 4, bgColor);
            }

            if (strokeWidth > 0)
            {
                graphics.lineStyle({"color":(active ? this.TabActiveStrokeColor : this.TabInactiveStrokeColor), "width":strokeWidth, "alignment":1});
            }

            graphics.beginFill(bgColor, 1);
            if (cornerRadius > 0)
            {
                graphics.drawRoundedRect(0, 0, calculatedWidth, calculatedHeight, cornerRadius);
            }
            else
            {
                graphics.drawRect(0, 0, calculatedWidth, calculatedHeight);
            }
            graphics.endFill();

            this.graphics["tab"+key].layout(size);

            this.buildTabText(
                key,
                this.tabs[key].label,
                tabX,
                tabY,
                calculatedWidth,
                calculatedHeight - this.TabActiveCornerRadius,
                active
            );

            index++;

        }

        if (isNew)
        {
            this.removeChild(this.graphics.background);
            this.addChild(this.graphics.background);

            if (this.closeButton)
            {
                this.removeChild(this.closeButton);
                this.addChild(this.closeButton);
            }
        }
    }

    buildTabText(strTabIndex, strText, tabX, tabY, tabWidth, tabHeight, bIsActive)
    {
        let activeKey = (bIsActive ? "active" : "inactive");
        let style = new TextStyle({
            fontFamily: this.UI.Values.tabs[activeKey].text.font,
            fontSize:   (bIsActive ? this.TabActiveFontSize : this.TabInactiveFontSize),
            fontWeight: this.UI.Values.tabs[activeKey].text.weight,
            fill: this.UI.Values.tabs[activeKey].text.color,
            align: "center"
        });

        if (!this.texts[strTabIndex])
        {
            this.texts[strTabIndex] = new TextResponsive().init({"ui": this.UI});
            this.addChild(this.texts[strTabIndex]);
        }

        let text = this.texts[strTabIndex];
        let metrics = TextMetrics.measureText(strText, style);

        let x = this.evaluate(tabX + " + (" + tabWidth + ") / 2" + " - " + metrics.width / 2, 0, 0, 0, 0, this);
        let y = this.evaluate(tabY + " + (" + tabHeight + ") / 2" + " - " + metrics.height / 2, 0, 0, 0, 0, this);

        text.text = strText;
        text.style = style;
        text.width = metrics.width;
        text.height = metrics.height;

        text.position.set(x, y);
    }

    /*******************************************
    *   ACTIONS
    *******************************************/
    show()
    {
        this.selectedIndex = Object.keys(this.tabs)[0];
        super.show();
    }

    /*******************************************
    *   EVENTS
    *******************************************/
    onTabClick(strTabIndex)
    {
        if (strTabIndex != this.selectedIndex)
        {
            this.selectedIndex = strTabIndex;
            this.emit(this.EVENT_TAB_CHANGE, strTabIndex);

            this.build();
        }
    }
}