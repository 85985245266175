import { Sprite } from "@pixi/sprite";
import { string2hex }  from "@pixi/utils";
import { TextStyle } from "@pixi/text";
import { TextMetrics } from "@pixi/text";
import ContainerDragger from "../../commons/ContainerDragger.js";
import ContainerResponsive from "../../commons/responsive/ContainerResponsive.js";
import GraphicsResponsive from "../../commons/responsive/GraphicsResponsive.js";
import Library from "../../../Library.js";
import SpriteResponsive from "../../commons/responsive/SpriteResponsive.js";
import TextButton from "../../commons/buttons/TextButton.js";
import TextResponsive from "../../commons/responsive/TextResponsive.js";

export default class CookingZoneContent extends ContainerResponsive
{
    constructor()
    {
        super();
    }

    get EVENT_COOK() { return "cook"; }
    get EVENT_DRAG_ANIMATION_END() { return "drag-animation-end"; }

    get IsShown() { return this.parent ? true : false; }
    
    get Opacity() { return this.opacity; }
    set Opacity(fNewValue) { this.opacity = fNewValue; this.build(); }

    get BgWidth() { return this.evaluate(this.buildWidth, 0, 0, 0, 0, this); }
    get BgHeight() { return this.evaluate(this.buildHeight, 0, 0, 0, 0, this); }

    get PaddingHorizontal() { return this.BgWidth * 0.125; }
    get PaddingVertical() { return this.BgHeight * 0.2; }

    get ItemsPerRow() { return 4;}

    get ItemZoneWidth() { return this.BgWidth * 0.7 - this.PaddingHorizontal / 2; }
    get ItemZoneHeight() { return this.BgHeight - this.PaddingVertical; }
    get ItemImageWidth() { return this.ItemZoneWidth / (this.ItemsPerRow * 1.25); }
    get ItemImageHeight() { return this.ItemImageWidth; }
    get RightZoneWidth() { return this.BgWidth - this.ItemZoneWidth - this.PaddingHorizontal / 2; }
    get RightZoneHeight() { return this.ItemZoneHeight; }

    get ImageBackgroundColor() { return string2hex(this.UI.Values.slot.background.color); }
    get ImageStrokeWidth() { return this.evaluate(this.valueFormula(this.UI.Values.slot.stroke.size), 0, 0, 0, 0, this); }
    get ImageStrokeColor() { return string2hex("color" in this.UI.Values.slot.stroke ? this.UI.Values.slot.stroke.color : "#000000"); }
    get ImageCornerRadius() { return this.evaluate(this.valueFormula(this.UI.Values.slot.corner.radius), 0, 0, 0, 0, this); }

    get DropZoneNormalColor() { return string2hex(this.UI.Values.drag.dropzone.background.normal.color); }
    get DropZoneHoverColor() { return string2hex(this.UI.Values.drag.dropzone.background.hover.color); }

    get ItemDisabledAlpha() { return this.UI.Values.general.disabled.opacity; }

    getItemList()
    {
        let list = {};
        let tmpList = [];

        let items = this.UI.ItemManager.getDiscoveredItems();
        for (let key in items)
        {
            let item = items[key];
            if (item.Type == this.UI.ItemManager.ITEM_CODES.TYPE_FOOD && item.IsCookable)
            {
                item.owned = this.UI.ItemManager.getItemQuantity(item, true, -1, true);
                tmpList.push(item)
            }
        }

        tmpList.sort(function(a, b)
        {
            return a.Name.localeCompare(b.Name);
        });

        for (let i = 0; i < tmpList.length; i++)
        {
            list[tmpList[i].Id] = tmpList[i];
        }

        return list;
    }

    /*******************************************
    *   INITIALIZATION
    *******************************************/
    /**
        Parameters to pass to the init function:
        - ui:       UI section object where this component resides
        - rWidth:   Responsive equation for the width of this content
        - rHeight:  Responsive equation for the height of this content
    */
    init(meta)
    {
        this.ui = meta.ui;

        this.buildWidth = meta.rWidth;
        this.buildHeight = meta.rHeight;

        this.opacity = 1;

        this.graphics = {};
        this.sprites = {};
        this.texts = {};

        this.drags = {};
        this.items = this.getItemList();

        return super.init(meta);
    }

    createClosure ()
    {
        super.createClosure();

        this.fctOnDragStart = this.onDragStart.bind(this);
        this.fctOnDragEnd = this.onDragEnd.bind(this);
        this.fctOnDragDrop = this.onDragDrop.bind(this);
        this.fctOnDragEnterDropZone = this.onDragEnterDropZone.bind(this);
        this.fctOnDragExitDropZone = this.onDragExitDropZone.bind(this);
        this.fctOnDragAnimationEnd = this.onDragAnimationEnd.bind(this);
        this.fctOnItemAdded = this.onItemAdded.bind(this);
    }

    bindEvents()
    {
        super.bindEvents();

        this.UI.ItemManager.on(this.UI.ItemManager.EVENT_ITEM_FOUND, this.fctOnItemAdded);//@
    }

    destroy(options)
    {
        for (let key in this.drags)
        {
            this.drags[key].off(this.drags[key].EVENT_DRAG_START, this.fctOnDragStart);
            this.drags[key].off(this.drags[key].EVENT_ENTER_DROP_ZONE, this.fctOnDragEnterDropZone);
            this.drags[key].off(this.drags[key].EVENT_EXIT_DROP_ZONE, this.fctOnDragExitDropZone);
            this.drags[key].off(this.drags[key].EVENT_DRAG_END, this.fctOnDragEnd);
            this.drags[key].off(this.drags[key].EVENT_DROP, this.fctOnDragDrop);
            this.drags[key].off(this.drags[key].EVENT_DRAG_END_ANIMATION_FINISHED, this.fctOnDragAnimationEnd);
            this.drags[key].destroy(options);
        }

        this.UI.ItemManager.off(this.UI.ItemManager.EVENT_ITEM_FOUND, this.fctOnItemAdded);//@

        super.destroy(options);
    }

    /*******************************************
    *   BUILD
    *******************************************/
    build()
    {
        super.build();

        if (!this.items)
        {
            this.items = this.getItemList(strCategoryId);
        }

        this.buildRightZone();

        let i = 0;
        for (let key in this.items)
        {
            this.buildItem(this.items[key], i, key);
            i++;
        }
    }

    clean()
    {
        for(let key in this.graphics)
        {
            this.graphics[key].clear();
        }

        super.clean();
    }

    buildRightZone()
    {
        if (!this.graphics.rightZone)
        {
            this.graphics.rightZone = new GraphicsResponsive().init({"ui": this.UI});
            this.graphics.rightZone.interactive = true;

            this.addChild(this.graphics.rightZone);
        }

        let graphics = this.graphics.rightZone;
        graphics.alpha = this.Opacity;

        let width = this.RightZoneWidth;
        let height = this.RightZoneHeight;
        let x = this.PaddingHorizontal / 2 + this.ItemZoneWidth;
        let y = this.BgHeight / 2 - height / 2;

        let iconBoxWidth = width * 0.85;
        let iconBoxHeight = height * 0.66;
        let iconBoxX = x + width / 2 - iconBoxWidth / 2;
        let iconBoxY = y + height / 2 - iconBoxHeight / 2;

        let iconBoxBgColor = this.ImageBackgroundColor;
        let iconBoxCornerRadius = this.ImageCornerRadius;
        let iconBoxStrokeWidth = this.ImageStrokeWidth;
        let iconBoxStrokeColor = this.ImageStrokeColor;

        if (iconBoxStrokeWidth > 0)
        {
            graphics.lineStyle({"color":iconBoxStrokeColor, "width": iconBoxStrokeWidth, "alignment": 1});
        }

        graphics.beginFill(iconBoxBgColor, 1);
        if (iconBoxCornerRadius > 0)
        {
            graphics.drawRoundedRect(iconBoxX, iconBoxY, iconBoxWidth, iconBoxHeight, iconBoxCornerRadius);
        }
        else
        {
            graphics.drawRect(iconBoxX, iconBoxY, iconBoxWidth, iconBoxHeight);
        }
        graphics.endFill();

        if (!this.sprites.rightSprite)
        {
            let texture = Library.getTextureFromAtlas("ui", "upgrade_cooking");
            this.sprites.rightSprite = new SpriteResponsive(texture).init({"ui": this.UI});

            this.addChild(this.sprites.rightSprite);
        }

        let sprite = this.sprites.rightSprite;

        let ratio = sprite.height / sprite.width;
        let spriteWidth = iconBoxWidth * 0.9;
        let spriteHeight = spriteWidth * ratio;
        let spriteX = iconBoxX + iconBoxWidth / 2 - spriteWidth / 2 + spriteWidth * 0.01;
        let spriteY = iconBoxY + iconBoxHeight / 2 - spriteHeight / 2;

        sprite.width = spriteWidth;
        sprite.height = spriteHeight;
        sprite.position.set(spriteX, spriteY);

        let zoneWidth = this.ItemImageWidth;
        let zoneHeight = this.ItemImageHeight;
        let zoneX = x + width / 2 - zoneWidth / 2;
        let zoneY = iconBoxY - zoneHeight * 0.75;

        this.buildDropZone(zoneX, zoneY, zoneWidth, zoneHeight);
    }

    buildDropZone(x, y, width, height)
    {
        if (!this.sprites.dropZone)
        {
            let texture = Library.getTextureFromAtlas("ui", "square_dashed");
            this.sprites.dropZone = new SpriteResponsive(texture).init({"ui": this.UI});
            this.sprites.dropZone.tint = this.DropZoneNormalColor;

            this.addChild(this.sprites.dropZone);
        }

        let sprite = this.sprites.dropZone;
        sprite.alpha = this.Opacity;
        sprite.tint = this.DropZoneNormalColor;

        this.dropRect = {"x": x, "y": y, "width": width, "height": height};

        sprite.width = width;
        sprite.height = height;
        sprite.position.set(x, y);

        if (!this.texts.dropZone)
        {
            this.texts.dropZone = new TextResponsive().init({"ui": this.UI});
            this.addChild(this.texts.dropZone);
        }

        let label = this.UI.LabelManager.translate("Cuis_Instruction");
        let text = this.texts.dropZone;
        text.alpha = this.Opacity;

        let style = new TextStyle({
            fontFamily: this.UI.Values.general.text.font,
            fontSize:   this.evaluate(this.valueFormula(this.UI.Values.general.text.size * 0.75)),
            fontWeight: this.UI.Values.general.text.weight,
            fill: this.UI.Values.general.text.color,
            align: "center",
            wordWrap: true,
            wordWrapWidth: width * 0.85
        });

        let metrics = TextMetrics.measureText(label, style);

        let textX = x + width / 2 - metrics.width / 2;
        let textY = y + height / 2 - metrics.height / 2;

        text.text = label;
        text.style = style;
        text.width = metrics.width;
        text.height = metrics.height;
        text.position.set(textX, textY);

        
    }


    buildItem(objItem, iIndex, strKey)
    {
        if (!this.graphics[strKey])
        {
            this.graphics[strKey] = new GraphicsResponsive().init({"ui": this.UI});
            this.graphics[strKey].interactive = true;

            this.drags[strKey] = new ContainerDragger().init({
                "ui": this.UI,
                "draggables": this.graphics[strKey],
                "dropZones": this.sprites.dropZone,
                "id": strKey
            })

            this.drags[strKey].on(this.drags[strKey].EVENT_DRAG_START, this.fctOnDragStart);//@
            this.drags[strKey].on(this.drags[strKey].EVENT_ENTER_DROP_ZONE, this.fctOnDragEnterDropZone)//@
            this.drags[strKey].on(this.drags[strKey].EVENT_EXIT_DROP_ZONE, this.fctOnDragExitDropZone);//@
            this.drags[strKey].on(this.drags[strKey].EVENT_DRAG_END, this.fctOnDragEnd);//@
            this.drags[strKey].on(this.drags[strKey].EVENT_DROP, this.fctOnDragDrop);//@
            this.drags[strKey].on(this.drags[strKey].EVENT_DRAG_END_ANIMATION_FINISHED, this.fctOnDragAnimationEnd);//@
        }

        this.graphics[strKey].buttonMode = objItem.owned > 0;
        this.graphics[strKey].alpha = this.Opacity * (objItem.owned > 0 ? 1 : this.ItemDisabledAlpha);

        this.drags[strKey].Id = strKey;
        this.drags[strKey].CanDrag = objItem.owned > 0;

        let graphics = this.graphics[strKey];
        let col = iIndex % this.ItemsPerRow;
        let row = Math.floor(iIndex / this.ItemsPerRow);

        let width = this.ItemImageWidth;
        let height = this.ItemImageHeight;
        let sidePadding = width / 4;
        let x = this.PaddingHorizontal / 2 + (width + sidePadding) * col;
        let y = this.PaddingVertical / 2 + (height + sidePadding * 3) * row;

        graphics.position.set(x, y);

        let imageBgColor = this.ImageBackgroundColor;
        let imageStrokeWidth = this.ImageStrokeWidth;
        let imageStrokeColor = this.ImageStrokeColor;
        let imageCornerRadius = this.ImageCornerRadius;

        if (imageStrokeWidth > 0)
        {
            graphics.lineStyle({"color":imageStrokeColor, "width": imageStrokeWidth, "alignment": 1});
        }

        graphics.beginFill(imageBgColor, 1);
        if (imageCornerRadius > 0)
        {
            graphics.drawRoundedRect(0, 0, width, height, imageCornerRadius);
        }
        else
        {
            graphics.drawRect(0, 0, width, height);
        }
        graphics.endFill();

        if (!this.graphics["placeholder" + strKey])
        {
            this.graphics["placeholder" + strKey] = new GraphicsResponsive().init({"ui": this.UI});
            this.graphics["placeholder" + strKey].interactive = true;
        }

        let placeholder = this.graphics["placeholder" + strKey];
        placeholder.alpha = this.Opacity * 0.5

        placeholder.position.set(x, y);
        placeholder.beginFill(imageBgColor, 1);
        if (imageCornerRadius > 0)
        {
            placeholder.drawRoundedRect(0, 0, width, height, imageCornerRadius);
        }
        else
        {
            placeholder.drawRect(0, 0, width, height);
        }
        placeholder.endFill();

        this.addChild(this.graphics["placeholder" + strKey]);
        this.addChild(this.graphics[strKey]);

        this.buildItemName(strKey, objItem, x, y, width, height);
        this.buildItemSprite(objItem, width, height, graphics);
        this.buildItemQuantity(objItem, width, height, graphics);
    }

    buildItemName(strKey, objItem, fItemX, fItemY, fItemWidth, fItemHeight)
    {
        if (!this.texts[strKey])
        {
            this.texts[strKey] = new TextResponsive().init({"ui": this.UI});
        }

        let text = this.texts[strKey];
        text.alpha = this.Opacity * (objItem.owned > 0 ? 1 : this.ItemDisabledAlpha);
        let label = objItem.Name;

        let style = new TextStyle({
            fontFamily: this.UI.Values.general.text.font,
            fontSize:   this.evaluate(this.valueFormula(this.UI.Values.general.text.size * 0.8)),
            fontWeight: this.UI.Values.general.text.weight,
            fill: this.UI.Values.general.text.color,
            align: "center",
            wordWrap: true,
            wordWrapWidth: fItemWidth
        });

        let metrics = TextMetrics.measureText(label, style);

        let x = fItemX + fItemWidth / 2 - metrics.width / 2;
        let y = fItemY + fItemHeight * 1.1;

        text.text = label;
        text.style = style;
        text.width = metrics.width;
        text.height = metrics.height;
        text.position.set(x, y);

        this.addChild(this.texts[strKey]);
    }

    buildItemSprite(objItem, fItemWidth, fItemHeight, objParent)
    {
        if (!objParent.itemSprite)
        {
            objParent.itemSprite = new Sprite(
                Library.getTextureFromAtlas(objItem.AtlasId, objItem.TextureId)
            );
            objParent.addChild(objParent.itemSprite);
        }

        let sprite = objParent.itemSprite;
        sprite.alpha = this.Opacity * (objItem.owned > 0 ? 1 : this.ItemDisabledAlpha);

        let ratio = sprite.height / sprite.width;
        let width = fItemWidth - this.ImageCornerRadius;
        let height = width * ratio;
        if (sprite.width < sprite.height)
        {
            ratio = sprite.width / sprite.height;
            height = fItemHeight - this.ImageCornerRadius;
            width = height * ratio;
        }

        let x = fItemWidth / 2 - width / 2;
        let y = fItemHeight / 2 - height / 2;

        sprite.width = width;
        sprite.height = height;
        sprite.position.set(x, y);
    }

    buildItemQuantity(objItem, fItemWidth, fItemHeight, objParent)
    {
        if (!objParent.textGraphics)
        {
            objParent.textGraphics = new GraphicsResponsive().init({"ui": this.UI});
        }

        if (!objParent.textQuantity)
        {
            objParent.textQuantity = new TextResponsive().init({"ui": this.UI});
        }

        objParent.removeChild(objParent.textGraphics);
        objParent.removeChild(objParent.textQuantity);
        if (objItem.owned > 0)
        {
            let boxBgColor = this.ImageStrokeColor;
            let boxCornerRadius = this.ImageCornerRadius * 0.66;

            let text = objParent.textQuantity;
            let label = Math.min(999, objItem.owned) + "";

            let style = new TextStyle({
                fontFamily: this.UI.Values.general.text.font,
                fontSize:   this.evaluate(this.valueFormula(this.UI.Values.general.text.size * 0.66)),
                fontWeight: this.UI.Values.general.text.weight,
                fill: this.UI.Values.general.text.color,
                align: "center"
            });

            let metrics = TextMetrics.measureText(label, style);

            text.text = label;
            text.style = style;
            text.width = metrics.width;
            text.height = metrics.height;

            let graphics = objParent.textGraphics;

            let padding = metrics.height * 0.25;
            let width = metrics.width + padding * 4;
            let height = metrics.height + padding * 2;

            let x = fItemWidth - width + boxCornerRadius * 0.45;
            let y = fItemHeight - height + boxCornerRadius * 0.45;

            graphics.position.set(x, y);


            graphics.beginFill(boxBgColor, 1);
            if (boxCornerRadius > 0)
            {
                graphics.drawRoundedRect(0, 0, width, height, boxCornerRadius);
            }
            else
            {
                graphics.drawRect(0, 0, width, height);
            }
            graphics.endFill();

            text.position.set(x + padding * 2, y + padding);

            objParent.addChild(objParent.textGraphics);
            objParent.addChild(objParent.textQuantity);
        }
    }

    reset()
    {
        this.items = this.getItemList();
        this.build();
    }

    //---------------------------------------------------
    //  EVENTS
    //---------------------------------------------------
    onDragStart(sender, objContainer, fctCancelDrag)
    {
        for (let key in this.drags)
        {
            if (sender.Id != key)
            {
                this.drags[key].CanDrag = false;
            }
        }

        //Done for the element to be above the window's close button
        let parent = this.parent;
        parent.removeChild(this);
        parent.addChild(this);

        this.removeChild(objContainer);
        this.addChild(objContainer);
    }

    onDragEnd(sender, objContainer)
    {
        for (let key in this.drags)
        {
            this.drags[key].CanDrag = this.items[key].owned > 0;
        }
    }

    onDragDrop(sender, objContainer, objDropZone, fctCancelDrop)
    {
        this.dropped = true;
        for (let key in this.drags)
        {
            this.drags[key].CanDrag = false;
        }
        this.emit(this.EVENT_COOK, this.items[sender.Id]);
    }

    onDragEnterDropZone(sender)
    {
        this.sprites.dropZone.tint = this.DropZoneHoverColor;
    }

    onDragExitDropZone(sender)
    {
        this.sprites.dropZone.tint = this.DropZoneNormalColor;
    }

    onDragAnimationEnd(sender)
    {
        this.emit(this.EVENT_DRAG_ANIMATION_END, this);
    }

    onItemAdded(strItemType, iAmount)
    {
        this.reset();
    }
}