import {Howl, Howler} from 'howler';
import EventEmitter from "eventemitter3";
import AudioFade from "./AudioFade.js";
import AudioManager from "./AudioManager.js";

export default class AudioItem
{
    constructor(objDefinition, type, fVolume)
    {
        this.definition = objDefinition;
        this.type = type;
        this.volume = Math.max(0, Math.min(1, fVolume));
        this.keys = [];
        this.playing = {};
        this.fades = {};
        this.currentID = null;
    }

    get Id() { return ("id" in this.definition ? this.definition.id : null); }
    get Type() { return this.type; }
    get SpriteKeys() { return this.keys; }
    get Duration () { return this.audio.duration(this.currentID); }
    get Volume() { return this.volume; }
    set Volume(fVolume) { 
        this.volume = Math.max(0, Math.min(1, fVolume));
        this.audio.volume(this.volume); 
    }

    load (fctCallback)
    {
        this.fctLoadCallback = fctCallback;

        let params = {
            src: this.definition.sources,
            html5: false,
            onload: function(){ this.fctLoadCallback(this); }.bind(this),
            onloaderror: (id, err) => console.log(id, err),
            onplay: function(id){ this.onItemPlayStart(id); }.bind(this),
            onend: function(id){ this.onItemPlayEnd(id); }.bind(this),
            onpause: function(id){ this.onItemPlayPause(id); }.bind(this),
            onfade: function(id){ this.onItemPlayFade(id); }.bind(this)
        };

        if ("sprite" in this.definition)
        {
            params.sprite = this.definition.sprite;
            this.keys = Object.keys(this.definition.sprite);
            // for (let key in this.definition.sprite)
            // {
            //     //params.sprite[key] = this.definition.sprite[key];
            //     this.keys.push(key);
            // }
        }

        this.audio = new Howl(params);
        this.audio.pos(0, 0, 0);
    }

    play (strKey, bLoop = false, iFadeInDuration = 0)
    {
        this.updatePlayingList(strKey);

        let audioId = null;
        if (this.keys.length > 0)
        {

            audioId = this.audio.play(strKey);
        }
        else
        {
            audioId = this.audio.play();
        }
        this.audio.loop(bLoop, audioId);

        if (iFadeInDuration > 0)
        {
            this.fades[strKey] = new AudioFade(
                this.audio,
                audioId,
                0,
                this.Volume,
                iFadeInDuration,
                this.onItemPlayFade.bind(this, audioId, strKey, true)
            );
        }

        this.addIdToPlayList(audioId, strKey);

        this.currentID = audioId;

        return audioId;
    }

    pause (iAudioId = null, strKey = null)
    {
        let objPause = {};
        if (strKey === null && iAudioId === null)
        {
            this.updatePlayingList();
            this.audio.pause();

            for (let key in this.playing)
            {
                objPause[key] = [];
                for (let i = 0; i < this.playing[key].length; i++)
                {
                    objPause[key].push(this.playing[key][i]);
                }
                if (key in this.fades)
                {
                    this.fades[key].stop();
                    this.audio.volume(this.fades[key].tween.to, this.fades[key].id);
                    delete this.fades[key];
                }
            }
        }
        else if (iAudioId === null)
        {
            if (strKey in this.playing)
            {
                objPause[strKey] = [];
                for (let i = 0; i < this.playing[strKey].length; i++)
                {
                    this.audio.pause(this.playing[strKey][i]);
                    objPause[strKey].push(this.playing[strKey][i]);
                }
            }
            if (strKey in this.fades)
            {
                this.fades[strKey].stop();
                this.audio.volume(this.fades[strKey].tween.to, this.fades[strKey].id);
                delete this.fades[strKey];
            }
        }
        else
        {
            this.audio.pause(iAudioId);
            objPause[this.getKeyFromId(iAudioId)] = [iAudioId];

            for (let key in this.fades)
            {
                if (this.fades[key].id == iAudioId)
                {
                    this.fades[key].stop();
                    delete this.fades[key];
                    break;
                }
            }
        }

        return objPause;
    }

    resume (iAudioId = null, strKey = null)
    {
        if (strKey === null && iAudioId === null)
        {
            for (let key in this.playing)
            {
                for (let i = 0; i < this.playing[key].length; i++)
                {
                    this.audio.play(this.playing[key][i]);
                }
            }
        }
        else if (iAudioId === null)
        {
            if (strKey in this.playing)
            {
                for (let i = 0; i < this.playing[strKey].length; i++)
                {
                    this.audio.play(this.playing[strKey][i]);
                }
            }
        }
        else
        {
            this.audio.play(iAudioId);
        }
    }

    getTypeVolume (strKey)
    {
        if (strKey === "SFX")
        {
            return AudioManager.instance.VolumeSFX;
        }
        else if (strKey === "BGM")
        {
            return AudioManager.instance.VolumeBGM;
        }
        else if (strKey === "Dialog")
        {
            return AudioManager.instance.VolumeDialog;
        }

        return 1;
    }
    stop (iAudioId = null, strKey = null, iFadeInDuration = 0, type = "" )
    {
        if (strKey === null && iAudioId === null)
        {
            this.audio.stop();
            for (let key in this.fades)
            {
                this.fades[key].stop();
            }
            this.fades = {};
        }
        else if (iAudioId === null)
        {
            if (strKey in this.playing)
            {
                if (iFadeInDuration === 0)
                {
                    for (let i = 0; i < this.playing[strKey].length; i++)
                    {
                        this.audio.stop(this.playing[strKey][i]);
                    }

                    if (strKey in this.fades)
                    {
                        this.fades[strKey].stop();
                        delete this.fades[strKey];
                    }
                }
                else
                {
                    let volumeStart = this.getTypeVolume(type);
                    for (let i = 0; i < this.playing[strKey].length; i++)
                    {
                        let iAudioId =this.playing[strKey][i];
                        this.fades[iAudioId] = new AudioFade(
                            this.audio,
                            iAudioId,
                            volumeStart,
                            0,
                            iFadeInDuration,
                            this.onItemPlayFade.bind(this, iAudioId, strKey, false)
                        );
                    }
                }

            }
        }
        else
        {
            if (iFadeInDuration === 0)
            {
                this.audio.stop(iAudioId);

                for (let key in this.fades)
                {
                    if (this.fades[key].id == iAudioId)
                    {
                        this.fades[key].stop();
                        delete this.fades[key];
                        break;
                    }
                }
            }
            else
            {
                this.fades[iAudioId] = new AudioFade(
                    this.audio,
                    iAudioId,
                    this.getTypeVolume(type),
                    0,
                    iFadeInDuration,
                    this.onItemPlayFade.bind(this, iAudioId, strKey, false)
                );
            }

        }

        this.updatePlayingList();
    }

    isPlaying (iAudioId = null, strKey = null)
    {
        let bPlaying = false;

        this.updatePlayingList();
        if (iAudioId === null && strKey === null)
        {
            for (let key in this.playing)
            {
                if (this.playing[key].length > 0)
                {
                    bPlaying = true;
                    break;
                }
            }
        }
        else if (iAudioId === null)
        {
            bPlaying = (strKey in this.playing) && (this.playing[strKey].length > 0);
        }
        else
        {
            bPlaying = this.audio.playing(iAudioId);
        }

        return bPlaying;
    }

    isFading (iAudioId = null, strKey = null)
    {
        let bFading = false;

        if (iAudioId === null && strKey === null)
        {
            bFading = Object.keys(this.fades).length > 0;
        }
        else if (iAudioId === null)
        {
            bFading = (strKey in this.fades);
        }
        else
        {
            for (let key in this.fades)
            {
                if (this.fades[key].id == iAudioId)
                {
                    bFading = true;
                    break;
                }
            }
        }

        return bFading;
    }

    fade (fFrom, fTo, iDuration, bIsFadeIn, bLoop = false, iAudioId = null, strKey = null)
    {
        this.updatePlayingList();

        let toFade = [];
        if (iAudioId === null && strKey === null)
        {
            if (bIsFadeIn)
            {
                if (this.SpriteKeys.length > 0)
                {
                    for (let i = 0; i < this.SpriteKeys.length; i++)
                    {
                        toFade.push(this.play(this.SpriteKeys[i], bLoop));
                    }
                }
                else
                {
                    toFade.push(this.play(this.Id, bLoop));
                }
            }
            else
            {
                for (let key in this.playing)
                {
                    for (let i = 0; i < this.playing[key].length; i++)
                    {
                        if (this.audio.playing(this.playing[key][i]))
                        {
                            toFade.push(this.playing[key][i]);
                        }
                    }
                }
            }
        }
        else if (iAudioId === null)
        {
            if (bIsFadeIn)
            {
                toFade.push(this.play(strKey, bLoop));
            }
            else if (strKey in this.playing)
            {
                for (let i = 0; i < this.playing[strKey]; i++)
                {
                    if (this.audio.playing(this.playing[strKey][i]))
                    {
                        toFade.push(this.playing[strKey][i]);
                    }
                }
            }
        }
        else if (this.audio.playing(iAudioId))
        {
            toFade.push(iAudioId);
        }

        for (let i = 0; i < toFade.length; i++)
        {
            let key = this.getKeyFromId(toFade[i]);
            if (!(key in this.fades))
            {
                this.fades[key] = new AudioFade(
                    this.audio,
                    toFade[i],
                    fFrom,
                    fTo,
                    iDuration,
                    this.onItemPlayFade.bind(this, toFade[i], key, bIsFadeIn)
                );
            }
        }
    }

    setSpatial(iAudioId, strKey, vectorDirection)
    {
        if (strKey in this.playing && this.playing[strKey].indexOf(iAudioId) >= 0)
        {
            this.audio.pos(vectorDirection.x, vectorDirection.y, vectorDirection.z, iAudioId);
        }
    }

    addIdToPlayList (iAudioId, strKey)
    {
        if (!(strKey in this.playing) || !this.playing[strKey])
        {
            this.playing[strKey] = [];
        }
        this.playing[strKey].push(iAudioId);
    }

    updatePlayingList (strKey = null)
    {
        let fctUpdate = function(strClipKey)
        {
            for (let i = this.playing[strClipKey].length - 1; i >= 0; i--)
            {
                if (!this.audio.playing(this.playing[strClipKey][i]))
                {
                    this.playing[strClipKey].splice(i, 1);
                }
            }
        }.bind(this);

        if (strKey == null || !(strKey in this.playing))
        {
            for (let key in this.playing)
            {
                fctUpdate(key);
            }
        }
        else
        {
            fctUpdate(strKey);
        }
    }

    getKeyFromId (iAudioId)
    {
        for (let key in this.playing)
        {
            for (let i = 0; i < this.playing[key].length; i++)
            {
                if (this.playing[key][i] == iAudioId)
                {
                    return key;
                }
            }
        }
        return null;
    }

    onItemPlayStart(iAudioId)
    {
        let strKey = this.getKeyFromId(iAudioId);
        AudioManager.instance.emit(AudioManager.instance.EVENT_ITEM_PLAY_START, iAudioId, strKey, this.type);
    }

    onItemPlayEnd(iAudioId)
    {
        let strKey = this.getKeyFromId(iAudioId);
        AudioManager.instance.emit(AudioManager.instance.EVENT_ITEM_PLAY_END, iAudioId, strKey, this.type);

        this.updatePlayingList();
    }

    onItemPlayPause(iAudioId)
    {
        let strKey = this.getKeyFromId(iAudioId);
        AudioManager.instance.emit(AudioManager.instance.EVENT_ITEM_PLAY_PAUSE, iAudioId, strKey, this.type);
    }

    onItemPlayFade(iAudioId, strKey, bIsFadeIn)
    {
        if (!bIsFadeIn)
        {
            this.audio.stop(iAudioId);
        }

        if (this.fades[iAudioId] !== undefined)
        {
            delete this.fades[iAudioId];
        }
        if (this.fades[strKey] !== undefined)
        {
            delete this.fades[strKey];
        }

        if (bIsFadeIn)
        {
            AudioManager.instance.emit(AudioManager.instance.EVENT_ITEM_FADE_IN_COMPLETED, iAudioId, strKey, this.type);
        }
        else
        {
            AudioManager.instance.emit(AudioManager.instance.EVENT_ITEM_FADE_OUT_COMPLETED, iAudioId, strKey, this.type);
        }
    }
}