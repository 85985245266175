import BaseEnvironment from "./BaseEnvironment.js";
import Direction from "../../utils/Direction.js";
import IndoorScene from "../scenes/IndoorScene.js";
import MapObstacles from "../map/MapObstacles.js";
import Library from "../../Library.js";

export default class IndoorEnvironment extends BaseEnvironment
{
    constructor(canvas, dependencies)
    {
        super(canvas);
    }

    get Id() { return "indoor"; }
    get IsHostile() { return true; }
    get IsIndoor() { return true; }
    get Data() { return this.data; }
    get RoomId() { return this.roomId; }
    get DoorId() { return this.doorId; }
    get Grid() { return this.scene.Grid; }
    get ObstacleParser() { return this.scene.parser;}

    /*******************************************
    *   INITIALIZATION
    *******************************************/
    /**
        Parameters to pass to the init function:
            none
    */
    init(params)
    {
        this.data = this.WorldManager.getIndoorEnvironmentData(this.Id);
        this.roomId = this.data.start.room;
        this.doorId = this.data.start.door;

        this.WorldManager.emit(this.WorldManager.EVENT_PLAYER_LOCATION_INDOOR_CHANGED, this.roomId);
        this.WorldManager.pauseTimeOfDay();



        super.init(!params ? {} : params);

        this.initPostProcessing();
    }

    createClosure()
    {
        super.createClosure();
        this.fctOnItemDrop = this.onItemDrop.bind(this);
        this.fctOnRoomChange = this.onRoomChange.bind(this);
        this.fctOnExitOutdoor = this.onExitOutdoor.bind(this);
    }

    bindEvents()
    {
        super.bindEvents();
        this.ItemManager.on(this.ItemManager.EVENT_ITEM_DROPPED, this.fctOnItemDrop);
    }

    destroy(options)
    {
        this.ItemManager.off(this.ItemManager.EVENT_ITEM_DROPPED, this.fctOnItemDrop);
        this.AudioManager.stopAll(this.AudioManager.AUDIO_TYPE_BGM, true, 0.5);
        this.AudioManager.stopAll(this.AudioManager.AUDIO_TYPE_SFX, true, 0.5);
        super.destroy(options);
    }

    createFloor()
    {

    }

    createObstacles()
    {
        
    }

    spawnObstacle(objItem, iX, iY, iQuantity, objObstacle = null)
    {
        let spawn = super.spawnObstacle(objItem, iX, iY, iQuantity, objObstacle);


        if (objItem.click)
        {
            //(strKey, objMesh, fWidth, fHeight, fctClickCallback, fOffsetX = 0, fOffsetZ = 0)
            let fct = () => this.TriggerManager.validators["world"].onIndoorObstacleClick(objItem.click);


            let random = (Math.random() * 0xFFFFFF) >> 0;

            let parameters = spawn.mesh.geometry.parameters;

            this.Scene.addClickableMesh(objItem.click.name, spawn.mesh, parameters.width, parameters.height, fct);
            let a = 1;
        }

        return spawn;
    }

    initUI()
    {
        this.UIManager.showHostileUI();
    }

    initPostProcessing()
    {
        if (this.Scene && this.Scene.PostProcessing)
        {
            this.Scene.PostProcessing.showColorMultiply = false;
        }   
    }

    /*******************************************
    *   UPDATE LOOP
    *******************************************/
    updateDisplay (iX, iY)
    {
        if (this.Scene)
        {
            this.Scene.updateDisplay(iX, iY);
        }
    }

    /*******************************************
    *   ROOM MANAGEMENT
    *******************************************/
    getRoomData(strRoomId)
    {
        let data = this.WorldManager.getIndoorRoomData(this.Id, strRoomId);

        if (data)
        {   
            if (!("camera" in data))
            {
                data.camera = {};
            }

            let defaultCam = this.WorldManager.getIndoorEnvironmentData(this.Id).camera;

            for (let key in defaultCam)
            {
                if (!(key in data.camera))
                {
                    data.camera[key] = defaultCam[key];
                }
            }
        }
        else
        {
            console.warn("Cannot find data for room #", strRoomId, "in environment", this.Id);
        }

        return data;
    }

    /*******************************************
    *   SCENE MANAGEMENT
    *******************************************/
    getScene ()
    {
        this.scene = new IndoorScene(this.canvas);
        this.scene.on(this.scene.EVENT_ROOM_CHANGE, this.fctOnRoomChange);
        this.scene.on(this.scene.EVENT_EXIT_OUTDOOR, this.fctOnExitOutdoor);

        let roomData = this.getRoomData(this.RoomId);
        this.scene.init({
            "environment": this,
            "roomId": this.RoomId,
            "data": roomData
        });

        this.preparePlayer(roomData);

        return this.scene;
    }

    unloadScene()
    {
        if (this.scene)
        {
            this.removeCharacters(null);

            if (this.mapMoveIcon)
            {
                this.mapMoveIcon.destroy();
                delete this.mapMoveIcon;
            }

            this.scene.destroy();
            delete this.scene;
        }
    }

    preparePlayer(objRoomData)
    {
        let doorPos = null;
        for (let tileId in objRoomData.doors)
        {
            if (objRoomData.doors[tileId].id == this.DoorId)
            {
                //doorPos = this.Grid.getPosFromTileId(tileId);
                doorPos = objRoomData.doors[tileId].pos;
                break;
            }
        }



        let playerPos = this.calculatePlayerStartPos();
        this.spawnPlayer(playerPos.x, playerPos.y);

        if (doorPos)
        {
            //The player look away from the door
            if (doorPos.y >= this.Scene.layout.Height - 1)
            {
                this.Player.changeDirection(Direction.North);
            }
            else if (doorPos.y == 0)
            {
                this.Player.changeDirection(Direction.South);
            }
            else if (doorPos.x == 0)
            {
                this.Player.changeDirection(Direction.East);
            }
            else
            {
                this.Player.changeDirection(Direction.West);
            }
        }

        this.updateDisplay(playerPos.x, playerPos.y);
    }

    calculatePlayerStartPos()
    {
        let pos;

        let data = this.getRoomData(this.RoomId);
        // let doorPos = null;
        // for (let tileId in data.doors)
        // {
        //     if (data.doors[tileId].id == this.doorId)
        //     {
        //         doorPos = tileId;
        //         break;
        //     }
        // }

        if (this.DoorId || this.DoorId == 0)
        {
            // pos = this.Grid.getPosFromTileId(doorPos);
            let doors = data.doors;
            let door = doors.find( door => door.id == this.DoorId);
            pos = door.pos;
        }
        else
        {
            //pos = this.Grid.getPosFromTileId(this.Data.start.pos);
            pos = this.Data.start.pos;
        }

        let nearest = this.Grid.getNearestWalkableTile(
            pos.x,
            pos.y,
            pos.x,
            pos.y,
            false,
            null,
            false,
            false,
            false,
            5
        );

        if (nearest)
        {
            pos = nearest;
        }

        return pos;
    }

    /*******************************************
    *   ACTIONS
    *******************************************/
    pickupObstacle(objObstacle)
    {
        super.pickupObstacle(objObstacle);
        
        if (!objObstacle.Item.IsObstacle || (objObstacle.Item.DespawnOnLoot || objObstacle.Item.HideOnLoot))
        {
            let gridPos = objObstacle.GridPos;
            this.obstacles.despawnObstacle(this.Grid.getTileId(gridPos.x, gridPos.y));
        }
    }

    changeRoom(iRoomId, iDoorId)
    {
        this.WorldManager.preventWorldClick();



        this.UIManager.showSceneTransition(
            function(iRoomId, iDoorId)
            {
                this.unloadScene();


                this.roomId = iRoomId;
                this.doorId = iDoorId;

                this.scene = this.getScene();
                this.createMapMoveIcon();

                this.WorldManager.emit(this.WorldManager.EVENT_PLAYER_LOCATION_INDOOR_CHANGED, iRoomId);

                //Give some time to load walls/floor a little bit before showing the screen
                setTimeout(function(iRoomId, iDoorId)
                {
                    this.GameManager.resume();
                    this.WorldManager.allowWorldClick();
                }
                .bind(this, iRoomId, iDoorId), 250);
            }
            .bind(this, iRoomId, iDoorId)
        );
    }

    exitOutside(iSpawnTileId)
    {
        this.WorldManager.preventWorldClick();

        this.UIManager.showSceneTransition(
            function(iSpawnTileId)
            {
                this.removeCharacters();
                this.unloadScene();

                this.GameManager.resume();
                this.WorldManager.changeEnvironment(this.WorldManager.ENVIRONMENT_FOREST, null, iSpawnTileId);
                this.WorldManager.allowWorldClick();
            }
            .bind(this, iSpawnTileId)
        );
    }


    changeIndoorObjectTexture (arrValues, fctCallback)
    {

        let meshes =  this.Scene.clickableMeshes;
        let strObject = arrValues[0];

        let item = meshes[strObject];

        if (item)
        {
            let strAtlas = arrValues[1];
            let strTexture = arrValues[2];

            let texture = Library.getTexture3D(strAtlas, strTexture);

            item.mesh.material.map = texture;
            item.mesh.material.needsUpdate = true;

        }


    }

    /*******************************************
    *   ITEM DROP
    *******************************************/
    dropItem(objItem, iQuantity, iSlot, bIsBackpack)
    {
        let playerPos = this.Player.GridPos;
        let tile = this.Grid.getNearestWalkableTile(
            playerPos.x,
            playerPos.y,
            playerPos.x,
            playerPos.y,
            false,
            null,
            false,
            true,
            true,
            5
        );

        if (tile)
        {
            this.WorldManager.updateCellContent(
                this.Grid.getTileId(tile.x, tile.y),
                objItem.Id, 
                iQuantity,
                false,
                this.Id,
                this.RoomId
            );
            this.updateDisplay(playerPos.x, playerPos.y);
        }
        else
        {
            //@TODO: Show some error feedback when an object cannot be dropped on the floor
            //This is a very rare situation but it can still happen
            this.ItemManager.addItem(objItem, iQuantity, iSlot, bIsBackpack);
        }
    }

    /*******************************************
    *   EVENTS
    *******************************************/
    onItemDrop(objItem, iQuantity, iSlot)
    {
        this.dropItem(objItem, iQuantity, iSlot);
    }

    onCameraTileChanged(params)
    {
        //No need to update display on every camera movement as the obstacles
        //are loaded per room instead of per camera view
    }

    onRoomChange(arrArguments)
    {
        this.changeRoom(arrArguments[1], arrArguments[2]);
    }

    onExitOutdoor(arrArguments)
    {
        this.exitOutside(arrArguments[2]);
    }
}