import { string2hex }  from "@pixi/utils"; 
import { TextStyle } from "@pixi/text";
import { TextMetrics } from "@pixi/text";
import BasePopup from "../BasePopup.js";
import GraphicsResponsive from "../responsive/GraphicsResponsive.js";
import SpriteResponsive from "../responsive/SpriteResponsive.js";
import TextResponsive from "../responsive/TextResponsive.js";

export default class DescriptionPopup extends BasePopup
{
    constructor()
    {
        super();
    }

    get IsDescription() { return true; }
    get ShowOverlay() { return false; }
    get PauseOnShow() { return false; }

    get Texture() { return this.texture; }
    get DescriptionLabel() { return this.description; }

    get ShowCloseButton() { return this.showClose; }
    set ShowCloseButton(bNewValue) { this.showClose = bNewValue; this.build(); }

    get Alpha() { return this.alpha; }
    set Alpha(fNewValue) { this.alpha = fNewValue; this.updateAlpha(); }

    get BgWidth() { return "(" + this.BgHeight + " * 3)"; }
    get BgHeight() { return this.UI.ResponsiveManager.ItemDescriptionHeight; }
    get BgX() { return "iw / 2 - (" + this.BgWidth + ") / 2"; }
    get BgY() { return "ih / 2 - (" + this.BgHeight + ") / 2"; }

    get BackgroundColor() { return string2hex(this.UI.Values.modal.background.color);}
    get StrokeWidth() { return this.evaluate(this.valueFormula(this.UI.Values.modal.stroke.size), 0, 0, 0, 0);}
    get StrokeColor() { return string2hex("color" in this.UI.Values.modal.stroke ? this.UI.Values.modal.stroke.color : "#000000");}
    get CornerRadius() { return this.evaluate(this.valueFormula(this.UI.Values.modal.corner.radius), 0, 0, 0, 0);}

    get ImageZoneWidth() { return this.ImageZoneHeight; }
    get ImageZoneHeight() { return this.BgHeight + " * 0.8"; }
    get ImageZoneBackgroundColor() { return string2hex(this.UI.Values.slot.background.color);}
    get ImageZoneStrokeWidth() { return this.evaluate(this.valueFormula(this.UI.Values.slot.stroke.size), 0, 0, 0, 0);}
    get ImageZoneStrokeColor() { return string2hex("color" in this.UI.Values.slot.stroke ? this.UI.Values.slot.stroke.color : "#000000");}
    get ImageZoneCornerRadius() { return this.evaluate(this.valueFormula(this.UI.Values.slot.corner.radius), 0, 0, 0, 0);}

    get MaxCharacterPerLine() { return 40;}

    getSize()
    {
        return {
            "width": this.evaluate(this.BgWidth, 0, 0, 0, 0, this),
            "height": this.evaluate(this.BgHeight, 0, 0, 0, 0, this)
        };
    }

    /*******************************************
    *   INITIALIZATION
    *******************************************/
    /**
        Parameters to pass to the init function:
        - ui:           UI section object where this component resides
        - title:        Text to display as the title
        - description:  Text to display as the description
        - texture:      Texture related to what to describe
        - clickToHide:  (Optional) If the popup should hide when the player clicks on the overlay behind. Default is TRUE
        - showOnStart   (Optional) If the popup should be shown on start. Default is TRUE
        - showClose:    (Optional) If the close button should be shown. Default is TRUE
        - createOverlay (Optional) If this popup should create it's own overlay for itself. If not, it will use the general UI overlay. Default is FALSE
        - sideBySide    (Optional) If this popup should be positioned side-by-side with other popups on screen. Default is TRUE
        - linked        (Optional) Linked popup to this one. If this one closes, the linked popup will close too. Default is NULL
    */
    init(meta)
    {
        this.description = meta.description;
        this.texture = meta.texture;
        this.alpha = 1;

        return super.init(meta);
    }

    destroy(options)
    {
        super.destroy(options);
    }

    /*******************************************
    *   BUILD
    *******************************************/
    build()
    {
        super.build();
        this.buildSprite();
    }

    buildTitle()
    {
        if (!this.texts.title)
        {
            this.texts.title = new TextResponsive().init({"ui": this.UI});
            this.addChild(this.texts.title);
        }

        let label = this.TitleLabel;

        let fontSize = this.evaluate(this.valueFormula(this.UI.Values.general.title.size));
        fontSize = Math.max(fontSize, this.UI.ResponsiveManager.TitleMinFontSize);

        let style = new TextStyle({
            fontFamily: this.UI.Values.general.title.font,
            fontSize,
            fontWeight: this.UI.Values.general.title.weight,
            fill: this.UI.Values.general.title.color,
            align: "left",
            wordWrap: true
        });

        let metrics = TextMetrics.measureText(label, style);

        let text = this.texts.title;
        let x = this.evaluate(this.BgX + " + " + this.ImageZoneWidth + " + " + this.BgHeight  + " * 0.25", 0, 0, 0, 0, this);
        let y = this.evaluate(this.BgY + " + " + this.BgHeight + " * 0.1", 0, 0, 0, 0);
        let width = this.evaluate(this.BgWidth + " - " + this.ImageZoneWidth + " - " + this.BgHeight  + " * 0.41", 0, 0, 0, 0, this);

        width = Math.max(width, metrics.width);

        text.text = label;
        text.style = style;
        text.width = metrics.width;
        text.height = metrics.height;
        text.position.set(x, y);

        if (!this.texts.desc)
        {
            this.texts.desc = new TextResponsive().init({"ui": this.UI});
            this.addChild(this.texts.desc);
        }

        y += metrics.height + this.evaluate(this.BgHeight + " * 0.1", 0, 0, 0, 0);

        text = this.texts.desc;
        label = this.DescriptionLabel.replace("\r\n", "\n");

        fontSize = this.evaluate(this.valueFormula(this.UI.Values.general.text.size * 0.8));
        fontSize = Math.max(fontSize, this.UI.ResponsiveManager.DialogMinFontSize);

        style = new TextStyle({
            fontFamily: this.UI.Values.general.text.font,
            fontSize  ,
            fontWeight: this.UI.Values.general.text.weight,
            fill: this.UI.Values.general.text.color,
            align: "left",
            wordWrap: true,
            wordWrapWidth: width
        });

        metrics = TextMetrics.measureText(label, style);

        text.text = label;
        text.style = style;
        text.width = metrics.width;
        text.height = metrics.height;
        text.position.set(x, y);
    }

    buildSprite()
    {
        if (!this.graphics.spriteFrame)
        {
            this.graphics.spriteFrame = new GraphicsResponsive().init({"ui": this.UI});
            this.addChild(this.graphics.spriteFrame);
        }

        if (!this.sprites.image)
        {
            this.sprites.image = new SpriteResponsive(this.texture).init({"ui": this.UI});
            this.addChild(this.sprites.image);
        }

        this.sprites.image.texture = this.texture;

        let imgWidth = this.texture.width;
        let imgHeight = this.texture.height;

        let width = this.evaluate(this.ImageZoneWidth, 0, 0, 0, 0, this);
        let height = this.evaluate(this.ImageZoneHeight, 0, 0, 0, 0, this);
        let x = this.evaluate(this.BgX + " + " + this.BgHeight + " * 0.1 ", 0, 0, 0, 0, this);
        let y = this.evaluate(this.BgY + " + " + this.BgHeight + " * 0.1", 0, 0, 0, 0, this);
        let cornerRadius = this.ImageZoneCornerRadius;

        this.graphics.spriteFrame.lineStyle({"color":this.ImageZoneStrokeColor, "width":this.ImageZoneStrokeWidth, "alignment":1});
        this.graphics.spriteFrame.beginFill(this.ImageZoneBackgroundColor, 1);
        if (cornerRadius > 0)
        {
            this.graphics.spriteFrame.drawRoundedRect(x, y, width, height, cornerRadius);
        }
        else
        {
            this.graphics.spriteFrame.drawRect(x, y, width, height);
        }
        this.graphics.spriteFrame.endFill();

        let ratio = this.sprites.image.height / this.sprites.image.width;
        let spriteWidth = width - cornerRadius;
        let spriteHeight = spriteWidth * ratio;

        if (this.sprites.image.width < this.sprites.image.height)
        {
            ratio = this.sprites.image.width / this.sprites.image.height;
            spriteHeight = height - cornerRadius;
            spriteWidth = spriteHeight * ratio;
        }

        spriteWidth *= 0.75;
        spriteHeight *= 0.75;

        let spriteX = x + (width - spriteWidth) / 2;
        let spriteY = y + (height - spriteHeight) / 2;

        this.sprites.image.width = spriteWidth;
        this.sprites.image.height = spriteHeight;
        this.sprites.image.position.set(spriteX, spriteY);
    }

    updateAlpha()
    {
        for(let key in this.graphics)
        {
            this.graphics[key].alpha = this.Alpha;
        }

        for(let key in this.sprites)
        {
            this.sprites[key].alpha = this.Alpha;
        }

        for(let key in this.texts)
        {
            this.texts[key].alpha = this.Alpha;
        }
    }

    /*******************************************
    *   ACTIONS
    *******************************************/
    updateContent(strTitle, strDescription, objTexture)
    {
        this.title = strTitle;
        this.description = strDescription;
        this.texture = objTexture;

        this.build();
    }
}