import { Point } from '@pixi/math';
import { Rectangle } from '@pixi/math';
import { string2hex }  from '@pixi/utils';
import { TextStyle } from '@pixi/text';
import { TextMetrics } from '@pixi/text';
import ActionButton from "../buttons/ActionButton.js";
import BasePopup from "../BasePopup.js";
import Constants from "../../../utils/Constants.js";
import Display from "../../../utils/Display.js";
import GraphicsResponsive from "../responsive/GraphicsResponsive.js";
import Lerp from "../../../utils/Lerp.js";
import Library from "../../../Library.js";
import MapArrow from "../map/MapArrow.js";
import MapObjective from "../map/MapObjective.js";
import MapPin from "../map/MapPin.js";
import SpriteResponsive from "../responsive/SpriteResponsive.js";
import TextResponsive from "../responsive/TextResponsive.js";
import SaveManager from "../../../save/SaveManager.js";
import Copydeck from "../../../utils/Copydeck.js";

export default class MapPopup extends BasePopup
{
    constructor()
    {
        super();
    }

    get DRAG_SMOOTHING() { return Constants.getValue("MAP_DRAG_SMOOTHING"); }
    get ZOOM_MIN_SCALE() { return Constants.getValue("MAP_ZOOM_MIN"); }
    get ZOOM_MAX_SCALE() { return Constants.getValue("MAP_ZOOM_MAX"); }
    get ZOOM_STEP_COUNT() { return Constants.getValue("MAP_ZOOM_STEP_COUNT"); }

    get CanDrag() { return this.zoom > this.ZOOM_MIN_SCALE; }
    get IsDragging() { return this.drag.identifier !== null; }
    get CellGridRatio() { return Constants.getValue("GRID_CELL_SIZE"); }
    get GridWidth() { return this.UI.WorldManager.Grid.Width; }
    get GridHeight() { return this.UI.WorldManager.Grid.Height; }
    get LastMission() { return this.UI.GameManager.LAST_MISSION; }
    get CurrentMission() { return Math.max(1, this.UI.GameManager.CurrentMission); }
    get CurrentObjective() { return this.UI.GameManager.CurrentObjective; }
    get ObjectiveLabel() { return this.UI.LabelManager.translate("CART_MISS" + this.CurrentMission); }
    get NoObjectiveLabel() { return this.UI.LabelManager.translate("CART_MISS15"); }
    get DisplayObjective() { return this.CurrentMission < this.LastMission; }
    get ZoomStep() { return (this.ZOOM_MAX_SCALE - this.ZOOM_MIN_SCALE) / this.ZOOM_STEP_COUNT; }

    get Padding() { return "(ih * 0.02)"; }

    get BgWidth() { return this.BgHeight; }
    get BgHeight() { return "(ih * " + this.UI.ResponsiveManager.MapHeight + ")"; }
    get BgX() { return 0; }
    get BgY() { return (this.DisplayObjective ? "(ih * 0.125)" : 0); }

    get MapWidth() { return "(" + this.BgWidth + " - " + this.Padding + " * 2)"; }
    get MapHeight() { return "(" + this.BgHeight + " - " + this.Padding + " * 2)"; }
    get MapX() { return "(" + this.mapObjective.BgWidth + " / 2 - " + this.BgWidth + " / 2 + " + this.Padding + ")" ; }
    get MapY() { return "(" + this.BgY + " + " + this.Padding + ")"; }

    get PinWidth() { return "(ih * 0.05)"; }
    get PinHeight() { return "(ih * 0.06)"; }

    get ArrowWidth() { return "(ih * 0.06)"; }
    get ArrowHeight() { return "(ih * 0.05)"; }

    get ObjectiveWidth() { return this.UI.ResponsiveManager.MapObjectiveWidth; }
    get ObjectiveHeight() { return "(ih * " + this.UI.ResponsiveManager.MapObjectiveHeight + ")"; }
    get ObjectiveX() { return 0; }
    get ObjectiveY() { return 0; }

    get ZoomButtonWidth() { return this.ZoomButtonHeight; }
    get ZoomButtonHeight() { return "(ih * 0.07)"; }
    get ZoomButtonX() { return this.CloseButtonX; }

    get CloseButtonX() { return "(" + this.mapObjective.BgWidth + " / 2 - " + this.BgWidth + " / 2 + " + this.Padding + " * 1.5 + " + this.MapWidth + ")"; }
    get CloseButtonY() { return "(" + this.MapY + " + " + this.ZoomButtonHeight + " * 0.5)"; }
    get CloseButtonScale() { return 1; }

    get CompassHeight() { return "(" + this.MapHeight + " / 7)"; }

    get BackgroundColor() { return string2hex(this.UI.Values.modal.background.color);}
    get StrokeWidth() { return this.evaluate(this.valueFormula(this.UI.Values.modal.stroke.size), 0, 0, 0, 0);}
    get StrokeColor() { return string2hex("color" in this.UI.Values.modal.stroke ? this.UI.Values.modal.stroke.color : "#000000");}
    get CornerRadius() { return this.evaluate(this.valueFormula(this.UI.Values.modal.corner.radius), 0, 0, 0, 0);}

    get FowBackgroundColor() { return string2hex(this.UI.Values.minimap.fogofwar.background.color);}

    /*******************************************
    *   INITIALIZATION
    *******************************************/
    /**
        Parameters to pass to the init function:
        - ui:           UI section object where this component resides
        - clickToHide:  (Optional) If the popup should hide when the player clicks on the overlay behind. Default is TRUE
        - showOnStart:  (Optional) If the popup should be shown on start. Default is TRUE
        - showClose:    (Optional) If the close button should be shown. Default is TRUE
        - createOverlay (Optional) If this popup should create it's own overlay for itself. If not, it will use the general UI overlay. Default is FALSE
        - sideBySide    (Optional) If this popup should be positioned side-by-side with other popups on screen. Default is TRUE
        - linked        (Optional) Linked popup to this one. If this one closes, the linked popup will close too. Default is NULL
    */
    init(meta)
    {
        this.isCursorDrag = false;
        this.zoom = this.ZOOM_MIN_SCALE;
        this.buttons = {};
        this.pins = {"cells": {}};
        this.drag = {"identifier": null, "start": null, "last": null, "mapPos": null};
        this.arrows = {"player": null, "objective": null};

        this.landmarks = meta.ui.WorldManager.getAllLandmarksPosition();
        this.barn = meta.ui.WorldManager.getBarnOnGrid();
        if (meta.ui.WorldManager.Environment.IsIndoor)
        {
            this.backpacks = meta.ui.WorldManager.getAllBackpackCells(
                meta.ui.WorldManager.Environment.Id,
                meta.ui.WorldManager.Environment.RoomId
            );
        }
        else
        {
            this.backpacks = meta.ui.WorldManager.getAllBackpackCells();
        }

        return super.init(meta);
    }

    createClosure()
    {
        super.createClosure();

        this.fctUpdate = this.update.bind(this);
        this.fctOnZoomIn = this.onZoomIn.bind(this);
        this.fctOnZoomOut = this.onZoomOut.bind(this);

        this.fctOnMouseDown = this.onMouseDown.bind(this);
        this.fctOnMouseMove = this.onMouseMove.bind(this);
        this.fctOnMouseUp = this.onMouseUp.bind(this);
        this.fctOnTouchStart = this.onTouchStart.bind(this);
        this.fctOnTouchMove = this.onTouchMove.bind(this);
        this.fctOnTouchEnd = this.onTouchEnd.bind(this);
    }

    bindEvents()
    {
        super.bindEvents();

        this.UI.GameManager.on(this.UI.GameManager.EVENT_UPDATE, this.fctUpdate);

        window.addEventListener("mousedown", this.fctOnMouseDown);
        window.addEventListener("mousemove", this.fctOnMouseMove);
        window.addEventListener("mouseup", this.fctOnMouseUp);

        window.addEventListener("touchstart", this.fctOnTouchStart);
        window.addEventListener("touchmove", this.fctOnTouchMove);
        window.addEventListener("touchend", this.fctOnTouchEnd);
    }

    destroy(options)
    {
        this.UI.GameManager.off(this.UI.GameManager.EVENT_UPDATE, this.fctUpdate);

        window.removeEventListener("mousedown", this.fctOnMouseDown);
        window.removeEventListener("mousemove", this.fctOnMouseMove);
        window.removeEventListener("mouseup", this.fctOnMouseUp);

        window.removeEventListener("touchstart", this.fctOnTouchStart);
        window.removeEventListener("touchmove", this.fctOnTouchMove);
        window.removeEventListener("touchend", this.fctOnTouchEnd);

        if (this.mapObjective)
        {
            this.removeChild(this.mapObjective);
            this.mapObjective.destroy(options);
        }
        delete this.mapObjective;

        for (let key in this.buttons)
        {
            this.removeChild(this.buttons[key]);
            this.buttons[key].destroy(options);
        }
        delete this.buttons;

        for (let key in this.pins.cells)
        {
            if (this.pins.cells[key].pin)
            {
                this.removeChild(this.pins.cells[key].pin);
                this.this.pins.cells[key].pin.destroy(options);
            }
        }
        delete this.pins;

        for (let key in this.arrows)
        {
            this.removeChild(this.arrows[key]);
            this.arrows[key].destroy(options);
        }
        delete this.arrows;

        if (this.isCursorDrag)
        {
            document.body.style.cursor = "default";
        }

        super.destroy(options);
    }

    /*******************************************
    *   BUILD
    *******************************************/
    build()
    {
        super.build();

        this.buildMap();
        this.buildZoomButtons();
    }

    clean()
    {
        super.clean();
    }

    buildBackground()
    {
        this.buildObjective();

        super.buildBackground();

        this.graphics.background.rX = [{on:"default", x: this.mapObjective.BgWidth + " / 2 - " + this.BgWidth + " / 2"}];
        this.graphics.background.layout(Display.getSize());
    }

    buildTitle()
    {

    }

    buildObjective()
    {


        if (this.mapObjective)
        {
            this.mapObjective.destroy();
            this.removeChild(this.mapObjective);

            this.mapObjective = undefined;

        }

        if (!this.mapObjective)
        {


            let strObjective = SaveManager .instance.get("CurrentObjective");

            if (!strObjective || strObjective === "")
            {
                strObjective = Copydeck.getText("NO_OBJECTIVE");
            }


            if (strObjective)
            {
                this.mapObjective = new MapObjective().init({
                    "ui": this.UI,
                    "description": strObjective,
                    "rMinWidth": this.BgWidth,
                    "rMaxWidth": this.ObjectiveWidth,
                    "rHeight": this.ObjectiveHeight
                });

                this.addChild(this.mapObjective);
            }
        }


        {
            this.mapObjective.build();

            this.mapObjective.rX = [{on: "default", x: 0}];
            this.mapObjective.rY = [{on: "default", y: 0}];
            this.mapObjective.layout(Display.getSize());
        }
    }

    buildMap()
    {
        if (!this.graphics.mapBackground)
        {
            this.graphics.mapBackground = new GraphicsResponsive().init({"ui": this.UI});
            this.graphics.mapBackground.mapContainer = new GraphicsResponsive().init({"ui": this.UI});
            this.graphics.mapBackground.maskGraphics = new GraphicsResponsive().init({"ui": this.UI});
            this.graphics.mapBackground.mapContainer.maskGraphics = new GraphicsResponsive().init({"ui": this.UI});

            this.graphics.mapBackground.addChild(this.graphics.mapBackground.mapContainer);
            this.graphics.mapBackground.addChild(this.graphics.mapBackground.maskGraphics);
            this.graphics.mapBackground.mapContainer.addChild(this.graphics.mapBackground.mapContainer.maskGraphics);
            this.graphics.mapBackground.mask = this.graphics.mapBackground.maskGraphics;
            this.graphics.mapBackground.mapContainer.mask = this.graphics.mapBackground.mapContainer.maskGraphics;

            this.addChild(this.graphics.mapBackground);
        }
        else
        {
            this.graphics.mapBackground.clear();
            this.graphics.mapBackground.mapContainer.clear();
            this.graphics.mapBackground.maskGraphics.clear();
        }

        let graphics = this.graphics.mapBackground;
        let graphicsContainer = this.graphics.mapBackground.mapContainer;

        let width = this.evaluate(this.MapWidth, 0, 0, 0, 0, this);
        let height = this.evaluate(this.MapHeight, 0, 0, 0, 0, this);

        graphics.mapSize = {width, height};

        let mask = this.graphics.mapBackground.maskGraphics;
        let cornerRadius = this.CornerRadius;

        graphics.beginFill(this.FowBackgroundColor, 1);
        graphicsContainer.beginFill(this.FowBackgroundColor, 1);
        mask.beginFill(0xFFFFFF, 1);
        if (cornerRadius > 0)
        {
            mask.drawRoundedRect(0, 0, width, height, cornerRadius);
            graphics.drawRoundedRect(0, 0, width, height, cornerRadius);
            graphicsContainer.drawRoundedRect(0, 0, width, height, cornerRadius);
        }
        else
        {
            mask.drawRect(0, 0, width, height);
            graphics.drawRect(0, 0, width, height);
            graphicsContainer.drawRect(0, 0, width, height);
        }
        mask.endFill();
        graphics.endFill();
        graphicsContainer.endFill();

        this.graphics.mapBackground.rX = [{on:"default", x: this.MapX}];
        this.graphics.mapBackground.rY = [{on:"default", y: this.MapY}];
        this.graphics.mapBackground.layout(Display.getSize());

        this.buildMapSprite();
        this.buildCompass();
        this.buildPins();
    }

    buildMapSprite()
    {
        if (!this.graphics.mapBackground.mapContainer.sprite)
        {
            let texture = Library.getTextureFromResources("minimap");
            this.graphics.mapBackground.mapContainer.sprite = new SpriteResponsive(texture).init({"ui": this.UI});
            this.graphics.mapBackground.mapContainer.addChild(this.graphics.mapBackground.mapContainer.sprite);
        }
        let sprite = this.graphics.mapBackground.mapContainer.sprite;

        let width = this.evaluate(this.MapWidth, 0, 0, 0, 0, this);
        let height = this.evaluate(this.MapHeight, 0, 0, 0, 0, this);

        sprite.width = width;
        sprite.height = height;

        this.updateFogOfWar();
    }

    buildCompass()
    {
        if (!this.sprites.compass)
        {
            let texture = Library.getTextureFromAtlas("ui", "compass");
            this.sprites.compass = new SpriteResponsive(texture).init({"ui": this.UI});
            this.sprites.compass.ratio = texture.orig.width / texture.orig.height;

            this.addChild(this.sprites.compass);
        }

        let sprite = this.sprites.compass;

        let height = this.evaluate(this.CompassHeight, 0, 0, 0, 0, this);
        let width = height * sprite.ratio;

        let x = this.MapX + " + " + this.MapWidth + " - " + this.CompassHeight + " * " + sprite.ratio + " - " + this.CompassHeight + " * 0.15";
        let y = this.MapY + " + " + this.MapHeight + " - " + this.CompassHeight + " - " + this.CompassHeight + " * 0.15";

        sprite.width = width;
        sprite.height = height;

        sprite.alpha = 0.75;

        sprite.rX = [{on:"default", x}];
        sprite.rY = [{on:"default", y}];
        sprite.layout(Display.getSize());
    }

    buildZoomButtons()
    {
        if (!this.buttons.zoomIn)
        {
            this.buttons.zoomIn = new ActionButton().init({
                "ui": this.UI,
                "textureName": "plus",
                "rWidth": this.ZoomButtonWidth,
                "rHeight": this.ZoomButtonHeight,
                "iconScale": 0.66
            })
            this.buttons.zoomIn.on(this.buttons.zoomIn.EVENT_CLICK, this.fctOnZoomIn);//@
            this.addChild(this.buttons.zoomIn);
        }

        if (!this.buttons.zoomOut)
        {
            this.buttons.zoomOut = new ActionButton().init({
                "ui": this.UI,
                "textureName": "minus",
                "rWidth": this.ZoomButtonWidth,
                "rHeight": this.ZoomButtonHeight,
                "iconScale": 0.66
            })
            this.buttons.zoomOut.on(this.buttons.zoomIn.EVENT_CLICK, this.fctOnZoomOut);//@
            this.addChild(this.buttons.zoomOut);
        }

        let strX = this.ZoomButtonX;

        this.buttons.zoomIn.IsDisabled = this.zoom >= this.ZOOM_MAX_SCALE;
        this.buttons.zoomOut.IsDisabled = this.zoom <= this.ZOOM_MIN_SCALE;

        let size = Display.getSize();
        this.buttons.zoomIn.rX = [{on:"default", x:strX}];
        this.buttons.zoomIn.rY = [{on:"default", y:this.BgY + " + " + this.BgHeight + " - " + this.ZoomButtonHeight + " * 3"}];
        this.buttons.zoomIn.layout(size);

        this.buttons.zoomOut.rX = [{on:"default", x:strX}];
        this.buttons.zoomOut.rY = [{on:"default", y:this.BgY + " + " + this.BgHeight + " - " + this.ZoomButtonHeight + " * 1.5"}];
        this.buttons.zoomOut.layout(size);
    }

    /*******************************************
    *   PINS
    *******************************************/
    buildPins()
    {
        let prevCells = this.pins.cells;
        this.pins.cells = {};

        this.calculateLandmarkPins();
        this.calculateBarnPin();
        this.calculateBackpackPins();
        this.calculatePlayerPin();

        let oldArr = this.pins.cells;
        let newArr = {};
        Object.keys(oldArr).sort().forEach(function(key) {
            newArr[key] = oldArr[key];
        });

        this.pins.cells = newArr;

        for (let key in prevCells)
        {
            if (!(key in this.pins.cells))
            {
                if (prevCells[key].pin)
                {
                    this.removeChild(prevCells[key].pin);
                    prevCells[key].pin.destroy({"children": true});
                }
            }
            else if (prevCells[key].pin)
            {
                this.pins.cells[key].pin = prevCells[key].pin;
            }
        }

        for (let cell in this.pins.cells)
        {
            if (!this.pins.cells[cell].pin)
            {
                this.pins.cells[cell].pin = new MapPin().init({
                    "ui": this.UI,
                    "cell": cell,
                    "definition": this.pins.cells[cell],
                    "rWidth": this.PinWidth,
                    "rHeight": this.PinHeight
                });
                this.addChild(this.pins.cells[cell].pin);
            }
            else
            {
                this.pins.cells[cell].pin.Definition = this.pins.cells[cell];
                this.pins.cells[cell].pin.build();
            }
        }

        this.positionPins();
    }

    positionPins()
    {
        let visibleCells = this.getVisibleCellsRect();

        let mapX = this.graphics.mapBackground.x;
        let mapY = this.graphics.mapBackground.y;
        let mapWidth = this.graphics.mapBackground.mapSize.width;
        let mapHeight = this.graphics.mapBackground.mapSize.height;
        let cellRatioH = mapWidth / visibleCells.width;
        let cellRatioV = mapHeight / visibleCells.height;

        for (let cell in this.pins.cells)
        {
            if (this.pins.cells[cell].pin)
            {
                let split = cell.split("_");
                let x = parseInt(split[0]);
                let y = parseInt(split[1]);

                this.pins.cells[cell].pin.positionAt(
                    mapX + (x - visibleCells.x) * cellRatioH,
                    mapY + (y - visibleCells.y) * cellRatioV
                );

                if (visibleCells.contains(x, y))
                {
                    if (!this.pins.cells[cell].pin.parent)
                    {
                        this.addChild(this.pins.cells[cell].pin);
                    }

                    if (this.pins.cells[cell].objective)
                    {
                        this.pins.cells[cell].pin.startBlinking();
                    }
                }
                else
                {
                    this.removeChild(this.pins.cells[cell].pin);
                    this.pins.cells[cell].pin.stopBlinking();
                }
            }
        }

        this.buildArrows(visibleCells);
    }

    updatePins(fDeltaTime)
    {
        for (let cell in this.pins.cells)
        {
            if (this.pins.cells[cell].pin)
            {
                this.pins.cells[cell].pin.update(fDeltaTime);
            }
        }
    }

    calculateLandmarkPins()
    {
        let ratio = this.CellGridRatio;
        let landmarkWithoutWater = this.landmarks.filter( l => l.def.id !== "WA"); //there is way too much stuff with landmark because of water

        for (let i = 0; i < landmarkWithoutWater.length; i++)
        {
            let landmark = landmarkWithoutWater[i];
            let isBlinking = this.landmarkIsBlinking(landmark);
            if (isBlinking || this.getIsExplored(landmark.x, landmark.z))
            {
                let cellId = Math.floor(landmark.x / ratio) + "_" + Math.floor(landmark.z / ratio);
                if (!this.pins.cells[cellId])
                {
                    this.pins.cells[cellId] = {};
                }
                this.pins.cells[cellId].landmark = {"def": landmark.def};
                if (isBlinking)
                {
                    this.pins.cells[cellId].objective = true;
                }
            }
        }
    }

    calculateBarnPin()
    {
        let ratio = this.CellGridRatio;
        let cellId = Math.floor(this.barn.x / ratio) + "_" + Math.floor(this.barn.z / ratio);
        if (!this.pins.cells[cellId])
        {
            this.pins.cells[cellId] = {};
        }
        if (this.barn.def)
        {
            this.pins.cells[cellId].barn = {"def": this.barn.def};
        
            if (this.UI.WorldManager.IsBarn)
            {
                this.pins.cells[cellId].player = true;
            }
        }
    }

    calculateBackpackPins()
    {
        let ratio = this.CellGridRatio;
        for (let cell in this.backpacks)
        {
            let z = Math.floor(cell / this.GridWidth);
            let x = cell - z * this.GridWidth;

            x /= ratio;
            z /= ratio;

            x = Math.round(x);
            z = Math.round(z);

            let cellId = x + "_" + z;
            if (!this.pins.cells[cellId])
            {
                this.pins.cells[cellId] = {};
            }
            this.pins.cells[cellId].backpack = true;
        }
    }

    calculatePlayerPin()
    {
        if (this.UI.WorldManager.IsForest)
        {
            let ratio = this.CellGridRatio;
            let pos = this.UI.WorldManager.Player.GridPos;
            let cellId = Math.floor(pos.x / ratio) + "_" + Math.floor(pos.y / ratio);
            if (!this.pins.cells[cellId])
            {
                this.pins.cells[cellId] = {};
            }
            this.pins.cells[cellId].player = true;
        }
    }

    /*******************************************
    *   ARROWS
    *******************************************/
    buildArrows(objVisibleCells)
    {
        if (!this.arrows.player)
        {
            this.arrows.player = new MapArrow().init({
                "ui": this.UI,
                "isPlayer": true,
                "rWidth": this.ArrowWidth,
                "rHeight": this.ArrowHeight,
            });
            this.addChild(this.arrows.player);
        }

        if (!this.arrows.objective)
        {
            this.arrows.objective = new MapArrow().init({
                "ui": this.UI,
                "isPlayer": false,
                "rWidth": this.ArrowWidth,
                "rHeight": this.ArrowHeight,
            });
            this.addChild(this.arrows.objective);
        }

        let arrows = {
            "player": {
                "arrow": this.arrows.player,
                "pos": null,
                "display": false
            },
            "objective": {
                "arrow": this.arrows.objective,
                "pos": null,
                "display": false
            }
        }

        for (let cell in this.pins.cells)
        {
            for (let key in arrows)
            {
                if (this.pins.cells[cell][key])
                {
                    arrows[key].pos = this.pins.cells[cell].pin.MapPosition;
                    arrows[key].display = (this.pins.cells[cell].pin.parent ? false : true);
                    if (key == "objective" && arrows[key].arrow.TextureId != this.pins.cells[cell].pin.TextureId)
                    {
                        arrows[key].arrow.TextureId = this.pins.cells[cell].pin.TextureId;
                        arrows[key].arrow.build();
                    }
                }
            }
        }

        for (let key in arrows)
        {
            if (arrows[key].display)
            {
                if (!arrows[key].arrow.parent)
                {
                    this.addChild(arrows[key].arrow);
                }
                arrows[key].arrow.positionOnMap(
                    arrows[key].pos,
                    this.graphics.mapBackground.position,
                    this.graphics.mapBackground.mapSize,
                );
            }
            else if (arrows[key].arrow.parent)
            {
                this.removeChild(arrows[key].arrow);
            }
        }
    }

    /*******************************************
    *   ZOOM
    *******************************************/
    updateZoom(iDirection)
    {
        let prevZoom = this.zoom;
        this.zoom = Math.max(this.ZOOM_MIN_SCALE, Math.min(this.ZOOM_MAX_SCALE, this.zoom + this.ZoomStep * iDirection));

        let width = this.graphics.mapBackground.mapSize.width;
        let height = this.graphics.mapBackground.mapSize.height;

        //We substract the normal center offset based on the previous zoom to the actual x/y positions of the map to get the amount of drag offset
        let dragX = (this.graphics.mapBackground.mapContainer.x + width / 2 * (prevZoom - this.ZOOM_MIN_SCALE)) / prevZoom * (this.zoom - this.ZOOM_MIN_SCALE);
        let dragY = (this.graphics.mapBackground.mapContainer.y + height / 2 * (prevZoom - this.ZOOM_MIN_SCALE)) / prevZoom * (this.zoom - this.ZOOM_MIN_SCALE);

        //We then move the map to keep it centered on screen based on the current zoom
        dragX -= width / 2 * (this.zoom - 1);
        dragY -= height / 2 * (this.zoom - 1);

        this.graphics.mapBackground.mapContainer.x = dragX;
        this.graphics.mapBackground.mapContainer.y = dragY;

        this.graphics.mapBackground.mapContainer.scale.x = this.zoom;
        this.graphics.mapBackground.mapContainer.scale.y = this.zoom;

        this.buildZoomButtons();
        this.buildPins();
    }

    getVisibleCellsRect()
    {
        if (this.graphics.mapBackground)
        {
            let mapWidth = this.graphics.mapBackground.mapSize.width;
            let mapHeight = this.graphics.mapBackground.mapSize.height;

            let ratio = mapWidth / (this.GridWidth / this.CellGridRatio);

            let globalMin = this.graphics.mapBackground.toGlobal(new Point(0, 0));
            let globalMax = this.graphics.mapBackground.toGlobal(new Point(mapWidth, mapHeight));

            let localMin = this.graphics.mapBackground.mapContainer.toLocal(globalMin);
            let localMax = this.graphics.mapBackground.mapContainer.toLocal(globalMax);

            return new Rectangle(
                localMin.x / ratio,
                localMin.y / ratio,
                (localMax.x - localMin.x) / ratio,
                (localMax.y - localMin.y) / ratio
            );
        }
        return new Rectangle();
    }

    /*******************************************
    *   EXPLORATION
    *******************************************/
    getIsExplored(iX, iZ)
    {
        let ratio = this.CellGridRatio;
        let cell = Math.floor(iZ / ratio) * Math.floor(this.GridWidth / ratio);
        cell += Math.floor(iX / ratio);

        return this.UI.WorldManager.getExploredCell(cell);
    }

    landmarkIsBlinking(landmark)
    {
        let strBlinking = this.UI.SaveManager.getFromSave("BlinkPin", "");
        let strCurrent = landmark.def.Landmark.ui;

        let shouldBlink = (strBlinking === strCurrent) || ((strBlinking + ".png") === strCurrent);

        return shouldBlink;
    }

    /*******************************************
    *   FOG OF WAR
    *******************************************/
    updateFogOfWar()
    {
        let mask = this.graphics.mapBackground.mapContainer.maskGraphics;
        mask.clear();

        let ratio = this.CellGridRatio;
        let width = this.evaluate(this.MapWidth, 0, 0, 0, 0, this);
        let height = this.evaluate(this.MapHeight, 0, 0, 0, 0, this);

        let gridWidth = (this.GridWidth / ratio);
        let maskRadius = width / gridWidth;
        let cells = this.UI.WorldManager.getAllExploredCell();

        let fctDraw = null;

        if (this.UI.GameManager.getSetting("fogofwar").shape.toLowerCase() == "circle")
        {
            fctDraw = function(mask, radius, x, z)
            { 
                mask.drawCircle(x, z, radius); 
            }
            .bind(this, mask, maskRadius);
        }
        else
        {
            fctDraw = function(mask, width, height, x, z)
            { 
                mask.drawRect(x, z, width, height);
            }
            .bind(this, mask, maskRadius, maskRadius);
        }

        mask.beginFill(0x000000, 1);
        for (let i = 0; i < cells.length; i++)
        {
            let cell = parseInt(cells[i]);
            let z = Math.floor(cell / this.GridWidth);
            let x = cell - z * this.GridWidth;

            x = x * maskRadius;
            z = z * maskRadius;

            fctDraw(x, z);
        }
        mask.endFill();
    }

    /*******************************************
    *   MAP DRAGGING
    *******************************************/
    startDrag(iX, iY, iIdentifier)
    {
        this.drag.identifier = iIdentifier;
        this.drag.start = new Point(iX, iY);
        this.drag.last = this.drag.start;
        this.drag.mapPos = new Point(this.graphics.mapBackground.mapContainer.position.x, this.graphics.mapBackground.mapContainer.position.y);
    }

    moveDrag(iX, iY)
    {
        this.drag.last = new Point(iX, iY);
    }

    endDrag()
    {
        this.drag.identifier = null;
        this.drag.start = null;
        this.drag.end = null;
        this.drag.mapPos = null;
    }

    calculateMapHover(iX, iY)
    {
        iX -= this.UI.ResponsiveManager.ScreenPadding;

        let map = this.graphics.mapBackground;
        let isIn = this.x + map.x <= iX && this.x + map.x + map.mapSize.width >= iX && 
                   this.y + map.y <= iY && this.y + map.y + map.mapSize.height >= iY;

        if (!this.isCursorDrag && isIn && this.CanDrag)
        {
            document.body.style.cursor = "move";
            this.isCursorDrag = true;
        }
        else if (this.isCursorDrag && (!isIn || !this.CanDrag))
        {
            document.body.style.cursor = "default";
            this.isCursorDrag = false;
        }
    }

    updateDrag(fDeltaTime)
    {
        if (this.IsDragging)
        {
            let map = this.graphics.mapBackground;
            let container = map.mapContainer;

            let width = map.mapSize.width;
            let height = map.mapSize.height;

            let startPos = this.drag.mapPos;
            let endPos = new Point(
                Math.min(0, startPos.x + (this.drag.last.x - this.drag.start.x)),
                Math.min(0, startPos.y + (this.drag.last.y - this.drag.start.y))
            );
            endPos.x = Math.max(-(width * this.zoom - width), endPos.x);
            endPos.y = Math.max(-(height * this.zoom - height), endPos.y);

            let prevX = container.x;
            let prevY = container.y;

            if (container.x != endPos.x || container.y != endPos.y)
            {
                if (Math.abs(container.x - endPos.x) <= 0.001 && Math.abs(container.y - endPos.y) <= 0.001)
                {
                    container.x = endPos.x;
                    container.y = endPos.y;
                }
                else
                {
                    container.x = Lerp.lerp(container.x, endPos.x, fDeltaTime * this.DRAG_SMOOTHING);
                    container.y = Lerp.lerp(container.y, endPos.y, fDeltaTime * this.DRAG_SMOOTHING);
                }

                this.positionPins();
            }
        }
    }

    /*******************************************
    *   UPDATE LOOP
    *******************************************/
    update(fDeltaTime)
    {
        this.updateDrag(fDeltaTime);
        this.updatePins(fDeltaTime);
    }

    /*******************************************
    *   ACTIONS
    *******************************************/
    show()
    {
        super.show();

        for (let cell in this.pins.cells)
        {
            if (this.pins.cells[cell].objective && this.pins.cells[cell].pin)
            {
                this.pins.cells[cell].pin.startBlinking();
            }
        }
    }

    hide()
    {
        for (let cell in this.pins.cells)
        {
            if (this.pins.cells[cell].objective && this.pins.cells[cell].pin)
            {
                this.pins.cells[cell].pin.stopBlinking();
            }
        }

        super.hide();
    }

    /*******************************************
    *   EVENTS
    *******************************************/
    onZoomIn()
    {
        this.updateZoom(1);
    }

    onZoomOut()
    {
        this.updateZoom(-1);
    }

    onMouseDown(e)
    {
        if (!this.IsDragging && this.CanDrag)
        {
            this.startDrag(e.clientX, e.clientY);
        }
    }

    onMouseMove(e)
    {
        if (this.IsDragging)
        {
            this.moveDrag(e.clientX, e.clientY);
        }
        else
        {
            this.calculateMapHover(e.clientX, e.clientY);
        }
    }

    onMouseUp(e)
    {
        if (this.IsDragging)
        {
            this.endDrag();
        }
    }

    onTouchStart(e)
    {
        if (!this.IsDragging && this.CanDrag)
        {
            let touch = e.touches[0];
            this.startDrag(touch.clientX, touch.clientY, touch.identifier)
        }
    }

    onTouchMove(e)
    {
        if (this.IsDragging)
        {
            for(let i = 0; i < e.touches.length; i++)
            {
                if (e.touches[i].identifier == this.drag.identifier)
                {
                    let touch = e.touches[i];
                    this.moveDrag(touch.clientX, touch.clientY);
                }
            }
        }
    }

    onTouchEnd(e)
    {
        if (this.IsDragging)
        {
            let isOk = false;
            if (e.touches.length > 0)
            {
                for(let i = 0; i < e.touches.length; i++)
                {
                    if (e.touches[i].identifier == this.drag.identifier)
                    {
                        isOk = true;
                        break;
                    }
                }
            }
            if (!isOk)
            {
                this.endDrag();
            }
        }
    }
}