import TriggerCodes from "./codes/TriggerCodes.js";
import {Vector3} from "three";

export default class TriggerDispatcher
{
    constructor (dependencies)
    {
        this.dependencies = dependencies;
        this.nextId = 1;
        this.completed = {};

        this.createSupportedActionList();
        this.createClosure();

    }

    //---------------------------------------------------------
    //  DEPENDENCIES
    //---------------------------------------------------------
    get AudioManager()      { return this.dependencies.get("AudioManager"); }
    get ItemManager()       { return this.dependencies.get("ItemManager"); }
    get CharacterManager()  { return this.dependencies.get("CharacterManager"); }
    get GameManager()       { return this.dependencies.get("GameManager"); }
    get LabelManager()      { return this.dependencies.get("LabelManager"); }
    get SaveManager()       { return this.dependencies.get("SaveManager"); }
    get TriggerManager()    { return this.dependencies.get("TriggerManager"); }
    get WorldManager()      { return this.dependencies.get("WorldManager"); }
    get UIManager()         { return this.dependencies.get("UIManager"); }
    //---------------------------------------------------------

    createSupportedActionList ()
    {
        this.fctClearLastObjective = this.clearLastObjective.bind(this);


        this.supportedActions = {};
        this.supportedActions[TriggerCodes.ACTION_SHOW_DIALOG] = this.showDialog.bind(this);
        this.supportedActions[TriggerCodes.ACTION_SHOW_INTERACTIVE_DIALOG] = this.showInteractiveDialog.bind(this);
        this.supportedActions[TriggerCodes.ACTION_SHOW_OBJECTIVE_DIALOG] = this.showObjectiveDialog.bind(this);
        this.supportedActions[TriggerCodes.ACTION_GET_ITEM] = this.givePlayerItem.bind(this);
        this.supportedActions[TriggerCodes.ACTION_KILL] = this.killPlayer.bind(this);
        this.supportedActions[TriggerCodes.ACTION_UPDATE_MISSION] = this.updateMission.bind(this);
        this.supportedActions[TriggerCodes.ACTION_CLEAR_OBJECTIVE] = this.clearObjective.bind(this);
        this.supportedActions[TriggerCodes.ACTION_SAVE] = this.saveGame.bind(this);
        this.supportedActions[TriggerCodes.ACTION_SPAWN_ITEM] = this.spawnItem.bind(this);
        this.supportedActions[TriggerCodes.ACTION_SPAWN_MONSTER] = this.spawnMonster.bind(this);
        this.supportedActions[TriggerCodes.ACTION_PLAY_SOUND] = this.playSound.bind(this);
        this.supportedActions[TriggerCodes.ACTION_STAT_CHANGE] = this.changeStatValue.bind(this);
        this.supportedActions[TriggerCodes.ACTION_TELEPORT] = this.teleportPlayer.bind(this);
        this.supportedActions[TriggerCodes.ACTION_SPAWN_NPC] = this.spawnNpc.bind(this);
        this.supportedActions[TriggerCodes.ACTION_MOVE_NPC] = this.moveNpc.bind(this);
        this.supportedActions[TriggerCodes.ACTION_CAMERA_FOLLOW_NPC] = this.cameraFollowNpc.bind(this);
        this.supportedActions[TriggerCodes.ACTION_REMOVE_NPC] = this.removeNpc.bind(this);
        this.supportedActions[TriggerCodes.ACTION_HIDE_PLAYER] = this.hidePlayer.bind(this);
        this.supportedActions[TriggerCodes.ACTION_SHOW_PLAYER] = this.showPlayer.bind(this);
        this.supportedActions[TriggerCodes.ACTION_WAIT] = this.wait.bind(this);
        this.supportedActions[TriggerCodes.ACTION_CAMERA_POSITION] = this.moveCameraTo.bind(this);
        this.supportedActions[TriggerCodes.ACTION_BARN_LEVEL] = this.setBarnLevel.bind(this);
        this.supportedActions[TriggerCodes.ACTION_PLAY_ANIMATION] = this.playAnimation.bind(this);
        this.supportedActions[TriggerCodes.ACTION_PLAYER_WALK] = this.playerWalk.bind(this);
        this.supportedActions[TriggerCodes.ACTION_MUTATION_CHOICE] = this.showMutationChoice.bind(this);
        this.supportedActions[TriggerCodes.ACTION_REMOVE_ITEM] = this.removeItem.bind(this);
        this.supportedActions[TriggerCodes.ACTION_BEGIN_CINEMATIC_MODE] = this.beginCinematicMode.bind(this);
        this.supportedActions[TriggerCodes.ACTION_END_CINEMATIC_MODE] = this.endCinematicMode.bind(this);
        this.supportedActions[TriggerCodes.ACTION_TUTORIAL_SHOW] = this.showTutorial.bind(this);
        this.supportedActions[TriggerCodes.ACTION_TUTORIAL_HIDE] = this.hideTutorial.bind(this);
        this.supportedActions[TriggerCodes.ACTION_DISABLE_UI_EXCEPT] = this.disableUIExcept.bind(this);
        this.supportedActions[TriggerCodes.ACTION_DISABLE_3D_EXCEPT] = this.disable3DExcept.bind(this);
        this.supportedActions[TriggerCodes.ACTION_ENABLE_UI] = this.enableUI.bind(this);
        this.supportedActions[TriggerCodes.ACTION_ENABLE_3D] = this.enable3D.bind(this);
        this.supportedActions[TriggerCodes.ACTION_BLINK_PIN] = this.enablePinBlink.bind(this);
        this.supportedActions[TriggerCodes.ACTION_UNBLINK_PIN] = this.disablePinBlink.bind(this);
        this.supportedActions[TriggerCodes.ACTION_PLAY_MUSIC] = this.playMusic.bind(this);
        this.supportedActions[TriggerCodes.ACTION_CLICK_RADIO] = this.clickOnRadio.bind(this);
        this.supportedActions[TriggerCodes.ACTION_RELOAD] = this.reloadPage.bind(this);

        //new in saison2
        this.supportedActions[TriggerCodes.ACTION_GOTO] = this.gotoAction.bind(this);
        this.supportedActions[TriggerCodes.ACTION_START_A_MINIGAME] = this.startAMinigame.bind(this);
        this.supportedActions[TriggerCodes.ACTION_BRANCH] = this.selectABranch.bind(this);
        this.supportedActions[TriggerCodes.ACTION_GO_INDOOR] = this.goIndoor.bind(this);
        this.supportedActions[TriggerCodes.ACTION_CHANGE_VARIABLE] = this.changeVariable.bind(this);
        this.supportedActions[TriggerCodes.ACTION_FORCE_TRIGGER] = this.forceTriggerStart.bind(this);
        this.supportedActions[TriggerCodes.ACTION_END_MISSION] = this.endMission.bind(this);
        this.supportedActions[TriggerCodes.ACTION_CHANGE_ENVIRONMENT] = this.changeEnvironment.bind(this);
        this.supportedActions[TriggerCodes.ACTION_SPAWN_NPC_INDOOR] = this.spawnNpcIndoor.bind(this);
        this.supportedActions[TriggerCodes.ACTION_CHANGE_INDOOR_OBJECT_TEXTURE] = this.changeIndoorObjectTexture.bind(this);
        this.supportedActions[TriggerCodes.ACTION_CHANGE_OUTDOOR_OBJECT] = this.changeOutdoorObject.bind(this);
        this.supportedActions[TriggerCodes.ACTION_CHANGE_WALKABLE_TILE] = this.changeWalkableTile.bind(this);

    }

    createClosure ()
    {
        this.fctShowInteractiveDialogCallback = this.showInteractiveDialogCallback.bind(this);
    }

    dispatchAction (strType, eventId, arrValues, bWait, previousArgs)
    {
        if (strType in this.supportedActions)
        {
            let fctCallback = this.onActionComplete.bind(this, this.nextId);
            this.completed[this.nextId] = eventId;
            this.nextId++;

            this.supportedActions[strType](eventId, arrValues, bWait, fctCallback, previousArgs);

            if (bWait)
                this.lastCallback = fctCallback;

            return true;
        }
        return false;
    }

    onActionComplete(iActionId, ...args)
    {
        if (iActionId in this.completed)
        {
            let eventId = this.completed[iActionId];
            delete this.completed[iActionId];


            let lastTriggerArgs = args;
            this.TriggerManager.onDispatchComplete(eventId, lastTriggerArgs)
        }
    }

    runLastWaitCallback ()
    {
        if (this.lastCallback === null)
            return;

        this.lastCallback();
        this.lastCallback = null;
    }

    //ACTION DISPATCH
    //--------------------------------------------------------------
    playMusic (eventId, arrValues, bWait, fctCallback)
    {
        let strMusicKey = arrValues[0];

        this.AudioManager.playBgm(strMusicKey, true, false);

        fctCallback();
    }


    clickOnRadio (eventId, arrValues, bWait, fctCallback)
    {
        this.WorldManager.environment.clickOnRadio();

        fctCallback();
    }

    reloadPage (eventId, arrValues, bWait, fctCallback)
    {
        this.SaveManager.save();
        window.location.reload();
    }

    gotoAction (eventId, arrValues, bWait, fctCallback)
    {
        this.TriggerManager.jumpToAction(eventId, arrValues[0])

        if (fctCallback)
        {
            fctCallback();
        }
    }


    startAMinigame (eventId, arrValues, bWait, fctCallback)
    {
        //this.TriggerManager.jumpToAction(eventId, arrValues[0])

        let strMinigame = arrValues[0].toLowerCase();
        let iDifficulty = arrValues.length > 1 ? parseInt(arrValues[1]) : 1;


        this.UIManager.startMinigame(strMinigame, iDifficulty, fctCallback);
        console.log("startAMiniGame", arguments)


    }

    selectABranch (eventId, arrValues, bWait, fctCallback, prevArgs)
    {

        if (prevArgs)
        {
            let branch = prevArgs[0][0][0];


            if (branch) //it's true
            {
                this.gotoAction(eventId, [arrValues[0]], bWait, fctCallback);
            }
            else
            {
                this.gotoAction(eventId, [arrValues[1]], bWait, fctCallback);
            }

        }
        console.log("selectABranch", prevArgs);
    }

    goIndoor (eventId, arrValues, bWait, fctCallback, prevArgs)
    {
        console.log("goIndoor", arguments);
        //TEMP CODE
        let wm = this.WorldManager;

        wm.changeEnvironment(arrValues[0]);

        if (fctCallback)
        {
            fctCallback ();
        }
    }


    changeVariable (eventId, arrValues, bWait, fctCallback, prevArgs)
    {
        console.log("changeVariable", arguments);
        this.SaveManager.createOrChange(arrValues[0], arrValues[1], arrValues[2]);
         fctCallback();
    }

    forceTriggerStart (eventId, arrValues, bEait, fctCallback)
    {
        console.log("forceTriggerStart", arguments);
        this.TriggerManager.forceTriggerStart(arrValues[0]);

        fctCallback();
    }




    showInteractiveDialog (eventId, arrValues, bWait, fctCallback)
    {
        let speakerName = this.LabelManager.translate(arrValues[0]);
        let content = this.LabelManager.translate(arrValues[1])
        let strAudioId = arrValues[2];

        //let objChoices = {}; //remove because we could not have the same action for two dialogs
        let arrChoices = [];

        for (let i = 3; i <= 10; i+=2)
        {
            let choice = arrValues[i];
            let action = arrValues[i + 1];

            if (choice && action)
            {
                //bjChoices[action] = choice;//remove because we could not have the same action for two dialogs
                arrChoices.push ({action,choice});
            }
        }

        //(strSpeaker, strContent, objChoices, strAudioId = null, fctChoiceCallback = null, fctCloseCallback = null)
        this.UIManager.showChoiceDialog(speakerName, content, arrChoices, strAudioId,  (nextAction) => this.showInteractiveDialogCallback(nextAction, eventId, fctCallback))
    }

    showInteractiveDialogCallback (nextAction, eventId, fctCallback)
    {
        this.TriggerManager.validators["misc"].onLaunchAction(nextAction, eventId);

        if (fctCallback)
        {
            fctCallback();
        }
    }

    showDialog (eventId, arrValues, bWait, fctCallback)
    {
        let speakerName = this.LabelManager.translate(arrValues[0]);
        let content = this.LabelManager.translate(arrValues[1]);
        if (arrValues[1] == "MUTA_Nouvelle")
        {
            let strBodyPart = arrValues[5];
            let nextChar = this.CharacterManager.getCharacterWithThisMutation(strBodyPart);
            content = content.replace("[Nom personnage]", this.CharacterManager.getCharacterName(nextChar));
        }
        let audioId = (arrValues[2] ? arrValues[2] : null);
        let strToFollow = (arrValues[3] ? arrValues[3] : null);
        let bTilt = (arrValues[4] ? arrValues[4] : false);

        this.UIManager.showDialog(speakerName, content, audioId, function(fctCallback)
        {
            if (fctCallback)
            {
                fctCallback();
            }
        }
        .bind(this, (bWait ? fctCallback : null)));

        if (!this.WorldManager.IsDialogMode)
        {
            this.WorldManager.startDialogMode(strToFollow, bTilt);
        }

        if (bWait && this.WorldManager.IsForest)
        {
            this.WorldManager.Player.stopMovement();
        }

        if (!bWait)
        {
            fctCallback();
        }
    }

    disableUIExcept (eventId, arrValues, bWait, fctCallback)
    {
        let arrExcept = (arrValues.length > 0) ? arrValues[0].split("|") : [];
        this.arrInteractive = this.UIManager.disableUIExcept(arrExcept, this.fctClearLastObjective);

        this.fctLastWaitCallback = this.runLastWaitCallback.bind(this);

        //@TODO: Do something about this when the UI has been reimported
        //------------------------
        /*for (let i = 0; i < this.arrInteractive.length; i++)
        {
            let sp = this.arrInteractive[i];
            sp.once(CharacterSelectionButton.EVENT_CLICK, this.fctLastWaitCallback);

        }*/
        //------------------------

        fctCallback();
    }

    disable3DExcept(eventId, arrValues, bWait, fctCallback)
    {
        this.GameManager.CanWorldClick = false;
        fctCallback();
    }

    enableUI (eventId, arrValues, bWait, fctCallback)
    {
        //@TODO: Do something about this when the UI has been reimported
        //------------------------
        /*for (let i = 0; i < this.arrInteractive.length; i++)
        {
            let sp = this.arrInteractive[i];
            sp.off(CharacterSelectionButton.EVENT_CLICK, this.fctLastWaitCallback);

        }*/
        //------------------------

        this.UIManager.enableUI();

        fctCallback();
    }

    enable3D (eventId, arrValues, bWait, fctCallback)
    {
        this.GameManager.CanWorldClick = true;
        fctCallback();
    }

    enablePinBlink (eventId, arrValues, bWait, fctCallback)
    {
        this.SaveManager.setFromSave("BlinkPin", arrValues[0]);
        fctCallback();
    }

    disablePinBlink (eventId, arrValues, bWait, fctCallback)
    {
        this.SaveManager.setFromSave("BlinkPin", "");
        fctCallback();
    }


    showTutorial (eventId, arrValues, bWait, fctCallback)
    {
        if (this.UIManager.CurrentUI && this.UIManager.CurrentUI.startTutorial)
        {
            this.UIManager.CurrentUI.startTutorial(fctCallback);
        }
        else
        {
            fctCallback();
        }

    }

    hideTutorial (eventId, arrValues, bWait, fctCallback)
    {
        if (this.UIManager.CurrentUI && this.UIManager.CurrentUI.stopTutorial)
        {
            this.UIManager.CurrentUI.stopTutorial(fctCallback);
        }
        fctCallback();
    }

    clearLastObjective  ()
    {
        this.fctClearCurrentObjective();
    }

    showObjectiveDialog (eventId, arrValues, bWait, fctCallback)
    {
        let content = this.LabelManager.translate(arrValues[0]);

        this.SaveManager.set("CurrentObjective", content);
        this.UIManager.Notifications.showObjectiveNotification(content, arrValues[1] == "1");

        fctCallback();
    }

    givePlayerItem (eventId, arrValues, bWait, fctCallback)
    {
        let result = this.ItemManager.addItem(arrValues[0], arrValues[1]);

        if (arrValues[1] > 0)
        {
            this.UIManager.Notifications.showItemGain(arrValues[0], arrValues[1]);
        }

        if (result.args.leftOver)
        {
            if (this.WorldManager.IsHostile)
            {
                this.UIManager.Notifications.showBackpackFull();

                this.WorldManager.Environment.playerDropItem(this.ItemManager.getItem(arrValues[0]), result.args.leftOver);
            }
            else if (!this.WorldManager.IsHostile)
            {
                this.ItemManager.addItem(arrValues[0], result.args.leftOver, -1, false);
            }
        }

        fctCallback();
    }

    killPlayer (eventId, arrValues, bWait, fctCallback)
    {
        if (this.WorldManager.IsHostile)
        {
            this.WorldManager.Player.die(null, fctCallback);
        }

        fctCallback();
    }

    updateMission (eventId, arrValues, bWait, fctCallback)
    {
        this.GameManager.startMission(arrValues[0]);

        fctCallback();
    }

    endMission (eventId, arrValues, bWait, fctCallback)
    {
        this.GameManager.endMission();

        fctCallback();
    }

    changeEnvironment (eventId, arrValues, bWait, fctCallback)
    {
        this.GameManager.resume();
        this.WorldManager.changeEnvironment(this.WorldManager.ENVIRONMENT_FOREST, null, 0);
        this.WorldManager.allowWorldClick();

        fctCallback();
    }

    clearObjective (eventId, arrValues, bWait, fctCallback)
    {
        this.GameManager.clearMissionObjective();

        fctCallback();
    }

    saveGame (eventId, arrValues, bWait, fctCallback)
    {
        this.SaveManager.save();
        fctCallback();
    }

    spawnItem (eventId, arrValues, bWait, fctCallback)
    {
        let pos = {"x": parseInt(arrValues[0]), "z": parseInt(arrValues[1])};
        let cell = pos.z * this.WorldManager.Grid.Width + pos.x;

        this.WorldManager.updateCellContent(cell, arrValues[2], arrValues[3]);

        if (this.WorldManager.IsHostile)
        {
            this.WorldManager.Environment.updateDisplay();
        }

        fctCallback();
    }

    spawnMonster (eventId, arrValues, bWait, fctCallback)
    {
        let distance = 0;

        let list = this.TriggerManager.triggerList;
        for (let i = 0; i < list[eventId].c.length; i++)
        {
            if (list[eventId].c[i].t == TriggerCodes.CONDITION_DISTANCE && list[eventId].c[i].v.length >= 4)
            {
                distance = list[eventId].c[i].v[3];
            }
        }

        this.WorldManager.Environment.spawnEnemy(arrValues[0], arrValues[1], arrValues[2], (bWait ? fctCallback : null), distance);

        if (!bWait)
        {
            fctCallback();
        }
    }

    playSound (eventId, arrValues, bWait, fctCallback)
    {
        if (arrValues[1] == this.AudioManager.AUDIO_TYPE_SFX)
        {
            this.AudioManager.playSfx(arrValues[0]);
        }
        else if (arrValues[1] == this.AudioManager.AUDIO_TYPE_BGM)
        {
            this.AudioManager.playBgm(arrValues[0]);
        }
        else if (arrValues[1] == this.AudioManager.AUDIO_TYPE_DIALOG)
        {
            this.AudioManager.playDialog(arrValues[0]);
        }

        fctCallback();
    }

    changeStatValue (eventId, arrValues, bWait, fctCallback)
    {
        let type = arrValues[0].toUpperCase();

        if (TriggerCodes.CHARACTER_STAT_ENERGY == type)
        {
            this.CharacterManager.setCharacterEnergy(this.CharacterManager.getCharacterEnergy() + arrValues[1]);
        }
        else if (TriggerCodes.CHARACTER_STAT_HUNGER == type)
        {
            this.CharacterManager.setCharacterHunger(this.CharacterManager.getCharacterHunger() + arrValues[1]);
        }
        else if (TriggerCodes.CHARACTER_STAT_STRESS == type)
        {
            this.CharacterManager.setCharacterStress(this.CharacterManager.getCharacterStress() + arrValues[1]);
        }

        fctCallback();
    }

    teleportPlayer (eventId, arrValues, bWait, fctCallback)
    {
        this.WorldManager.preventWorldClick();

        if (this.WorldManager.Player)
            this.WorldManager.Player.stopMovement();

        if (arrValues[0].toUpperCase() == TriggerCodes.LOCATION_CHARACTER_CREATOR)
        {
            this.UIManager.showSceneTransition(function(fctCallback) 
            {
                setTimeout(function(fctCallback)
                {   
                    this.WorldManager.destroyEnvironment();

                    this.UIManager.showCharacterCreatorUI();
                    this.WorldManager.allowWorldClick();

                    if (fctCallback)
                    {
                        fctCallback();
                    }
                }
                .bind(this, fctCallback) ,1);
            }
            .bind(this, (bWait ? fctCallback : null)));
        }
        else
        {

            let moveTo = null;
            if (arrValues.length >= 3)
            {
                moveTo = this.WorldManager.Grid.gridToWorldPosition(arrValues[1], arrValues[2]);
                let cellSize = this.WorldManager.Grid.CellSize;
                moveTo.x += cellSize / 2;
                moveTo.y += cellSize;
            }

            let loc = arrValues[0].toUpperCase();

            let bTransit = (arrValues.length == 3 || arrValues[3]);
            let changeScene = arrValues.length < 3 || (
                (loc == TriggerCodes.LOCATION_FOREST && this.WorldManager.Environment.Id != this.WorldManager.ENVIRONMENT_FOREST) ||
                (loc == TriggerCodes.LOCATION_BARN && this.WorldManager.Environment.Id != this.WorldManager.ENVIRONMENT_BARN) ||
                (loc == TriggerCodes.LOCATION_LABO && this.WorldManager.Environment.Id != this.WorldManager.ENVIRONMENT_LABO) ||
                (loc == TriggerCodes.LOCATION_HOUSE && this.WorldManager.Environment.Id != this.WorldManager.ENVIRONMENT_HOUSE)
            );

            if (changeScene)
            {
                this.WorldManager.preventWorldClick();
                this.WorldManager.Player.stopMovement();

                this.UIManager.showSceneTransition(function(fctCallback) 
                {
                    setTimeout(function(moveTo, fctCallback)
                    {   
                        let fctMove = null;
                        if (moveTo)
                        {
                            fctMove = function(moveTo, player)
                            {
                                let playerPos = player.WorldPos;
                                player.WorldPos = new Vector3(pos.x, playerPos.y, pos.z);
                            }
                            .bind(this, moveTo, this.WorldManager.Player);
                        }

                        this.WorldManager.changeEnvironment(this.WorldManager.ENVIRONMENT_BARN, fctMove);
                        this.WorldManager.allowWorldClick();
                        if (fctCallback)
                        {
                            fctCallback();
                        }
                    }
                    .bind(this, moveTo, fctCallback) ,1);
                }
                .bind(this, (bWait ? fctCallback : null)));
            }
            else
            {
                let fctEndTeleport = function(pos, bTransit, fctCallback) 
                {
                    let wm = this.WorldManager;

                    wm.allowWorldClick();

                    let playerPos = wm.Player.WorldPos;
                    this.WorldManager.Player.WorldPos = new Vector3(pos.x, playerPos.y, pos.y);
                    //this.WorldManager.Player.WorldPos = new Vector3(playerPos.x, playerPos.y, playerPos.z + 50);

                    if (bTransit)
                    {
                        //this.UIManager.showOverlay();
                        //this.WorldManager.Environment.updateDisplay(this.WorldManager.Player.GridPos.x, this.WorldManager.Player.GridPos.z);
                        wm.Environment.forceLoadOverlay();

                        let playerPos = wm.Player.WorldPos
                        wm.Environment.updateDisplay(Math.round(playerPos.x) / 10, Math.round(playerPos.z / 10));
                        wm.Environment.Scene.Camera.positionCameraOnPosition(playerPos, true);
                    }

                    if (fctCallback)
                    {
                        fctCallback();
                    }
                }
                .bind(this, moveTo, bTransit, (bWait ? fctCallback : null));

                if (bTransit)
                {
                    this.UIManager.showSceneTransition(fctEndTeleport);
                }
                else
                {
                    fctEndTeleport();
                }
            }
                
        }

        if (!bWait)
        {
            fctCallback();
        }
    }

    spawnNpc(eventId, arrValues, bWait, fctCallback)
    {
        if (!this.WorldManager.Environment.spawnNpc(arrValues[0], arrValues[1], arrValues[2], (bWait ? fctCallback : null)))
        {
            bWait = false;
        }

        if (!bWait)
        {
            fctCallback();
        }
    }

    spawnNpcIndoor(eventId, arrValues, bWait, fctCallback)
    {
        if (!this.WorldManager.Environment.spawnNpc(arrValues[1], arrValues[2], arrValues[3], (bWait ? fctCallback : null)))
        {
            bWait = false;
        }

        if (!bWait)
        {
            fctCallback();
        }
    }

    changeIndoorObjectTexture (eventId, arrValues, bWait, fctCallback)
    {
        if (!this.WorldManager.Environment.changeIndoorObjectTexture(arrValues, (bWait ? fctCallback : null)))
        {
            bWait = false;
        }

        if (!bWait)
        {
            fctCallback();
        }
    }

    changeOutdoorObject (eventId, arrValues, bWait, fctCallback)
    {
        if (!this.WorldManager.Environment.changeOutdoorObject(arrValues, (bWait ? fctCallback : null)))
        {
            bWait = false;
        }

        if (!bWait)
        {
            fctCallback();
        }
    }

    changeWalkableTile (eventId, arrValues, bWait, fctCallback)
    {
        if (!this.WorldManager.Environment.changeWalkableTile(arrValues, (bWait ? fctCallback : null)))
        {
            bWait = false;
        }

        if (!bWait)
        {
            fctCallback();
        }
    }

    moveNpc(eventId, arrValues, bWait, fctCallback)
    {
        let bHasMoved = false;
        let npcs = this.WorldManager.NPCs;

        let [strNpcID, iX, iY] = arrValues;

        if (npcs)
        {
            for (let i = 0; i < npcs.length; i++)
            {
                let npc = npcs[i];

                //if (npcs[i].CharacterId == arrValues[0]) //This is not working at all
                if (npc.definition.id == strNpcID)
                {
                    npc.goTo(iX * 10, iY * 10, false, (bWait ? fctCallback : null)); //need to muliply by 10 because reason! (dont ask me) - Martin
                    bHasMoved = true;
                    break;
                }
            }
        }

        if (!bHasMoved || !bWait)
        {
            fctCallback();
        }
    }

    cameraFollowNpc(eventId, arrValues, bWait, fctCallback)
    {
        if (arrValues[0] && arrValues[0] != "0")
        {
            let npcs = this.WorldManager.NPCs;
            if (npcs)
            {
                for (let i = 0; i < npcs.length; i++)
                {
                    if (npcs[i].CharacterId == arrValues[0])
                    {
                        this.WorldManager.Environment.Scene.Camera.startFollowing(npcs[i]);
                        break;
                    }
                }
            }
        }
        else
        {
            this.WorldManager.Environment.Scene.Camera.startFollowing(this.WorldManager.Player);
        }

        fctCallback();
    }

    removeNpc(eventId, arrValues, bWait, fctCallback)
    {
        this.WorldManager.Environment.removeNpc(arrValues[0], (bWait ? fctCallback : null));

        if (!bWait)
        {
            fctCallback();
        }
    }

    hidePlayer(eventId, arrValues, bWait, fctCallback)
    {
        this.WorldManager.Environment.hidePlayer(bWait ? fctCallback : null);

        if (!bWait)
        {
            fctCallback();
        }
    }

    showPlayer(eventId, arrValues, bWait, fctCallback)
    {
        this.WorldManager.Environment.showPlayer(bWait ? fctCallback : null);

        if (!bWait)
        {
            fctCallback();
        }
    }

    wait(eventId, arrValues, bWait, fctCallback)
    {
        if (bWait)
        {
            setTimeout(function(fctCallback) 
            { 
                fctCallback(); 
            }
            .bind(this, fctCallback), Math.round(arrValues[0] * 1000));
        }
        else
        {
            fctCallback();
        }
    }

    moveCameraTo(eventId, arrValues, bWait, fctCallback)
    {
        let worldPos = this.WorldManager.Grid.gridToWorldPosition(arrValues[0], arrValues[1]);

        this.WorldManager.Environment.Scene.Camera.moveTo(
            worldPos.x, 
            worldPos.y,
            !bWait
        );

        fctCallback();
    }

    setBarnLevel(eventId, arrValues, bWait, fctCallback)
    {
        let zone = null;

        if (arrValues[0] == TriggerCodes.BARN_ZONE_CRAFTING)
        {
            zone = this.WorldManager.BARN_ZONE_CRAFTING;
        }
        else if (arrValues[0] == TriggerCodes.BARN_ZONE_COOKING)
        {
            zone = this.WorldManager.BARN_ZONE_COOKING;
        }
        else if (arrValues[0] == TriggerCodes.BARN_ZONE_SLEEP)
        {
            zone = this.WorldManager.BARN_ZONE_SLEEP;
        }
        else if (arrValues[0] == TriggerCodes.BARN_ZONE_RELAX)
        {
            zone = this.WorldManager.BARN_ZONE_RELAX;
        }

        if (zone)
        {   
            let level = Math.max(0, Math.min(3, arrValues[1]));
            this.WorldManager.setBarnZoneLevel(zone, level);

            if (this.WorldManager.IsBarn)
            {
                this.WorldManager.Environment.onZoneUpgrade(zone, level);
            }
        }

        fctCallback();
    }

    playAnimation(eventId, arrValues, bWait, fctCallback)
    {
        let character = null;

        //Player
        if (!arrValues[0] || arrValues[0] == "0")
        {
            if (this.WorldManager.IsHostile)
            {
                character = this.WorldManager.Player;
            }
        }
        //NPC/Enemies
        else
        {
            if (this.WorldManager.IsHostile)
            {
                let npcs = this.WorldManager.NPCs;
                for (let key in npcs)
                {
                    if (npcs[key].CharacterId == arrValues[0])
                    {
                        character = npcs[key];
                        break;
                    }
                }
                if (!character)
                {
                    let enemies = this.WorldManager.Enemies;
                    for (let key in enemies)
                    {
                        if (enemies[key].Type == arrValues[0])
                        {
                            character = enemies[key];
                            break;
                        }
                    }
                }
            }
        }

        if (character)
        {
            //@TODO: Do something about this when the character animations have been reimported
            //----------------------------
            /*if (bWait)
            {
                this.fctAnimDone = function(strName, fctCallback, sender, strAnimation)
                {
                    if (strName == strAnimation)
                    {
                        sender.off(AnimatedCharacter.EVENT_ANIMATION_DONE, this.fctAnimDone);
                        sender.off(AnimatedCharacter.EVENT_ANIMATION_LOOP_DONE, this.fctAnimDone);

                        fctCallback();
                    }
                }
                .bind(this, arrValues[1], fctCallback);

                character.on(AnimatedCharacter.EVENT_ANIMATION_DONE, this.fctAnimDone);//@??
                character.on(AnimatedCharacter.EVENT_ANIMATION_LOOP_DONE, this.fctAnimDone);//@??
            }

            setTimeout( () => character.startAnimation(arrValues[1]), 10);*/
            //----------------------------
        }

        if (!character || !bWait)
        {
            fctCallback();
        }
    }

    playerWalk(eventId, arrValues, bWait, fctCallback)
    {
        if (this.WorldManager.IsHostile)
        {
            let player = this.WorldManager.Player;
            let playerPos = player.GridPos;

            let tile = this.WorldManager.Grid.getNearestWalkableTile(arrValues[0], arrValues[1], playerPos.x, playerPos.z);
            if (tile)
            {
                player.stopMovement();
                player.preventMovement();
                this.WorldManager.preventWorldClick();

                let direction = arrValues[2];
                
                player.goTo(tile.x * 10, tile.y * 10, false, function(player, fctCallback)
                {
                    player.enableMovement();

                    if (direction || direction === 0)
                    {
                        player.changeDirection(direction, true);
                    }

                    this.WorldManager.allowWorldClick();
                    if (fctCallback)
                    {
                        fctCallback()
                    }
                }
                .bind(this, player, (bWait ? fctCallback : null)));
            }
            else
            {
                bWait = false;
            }
        }

        if (!bWait)
        {
            fctCallback();
        }
    }

    showMutationChoice(eventId, arrValues, bWait, fctCallback)
    {
        let [strBodyPart, strMutation] = arrValues;
        let character = this.CharacterManager.getCharacterWithThisMutation(strBodyPart);

        if (character !== null)
        {
            let mutation = this.CharacterManager.getMutation(strMutation);
            this.UIManager.showMutationWindow(character, mutation, (bWait ? fctCallback : null));
        }

        if (character === null || !bWait)
        {
            fctCallback();
        }
    }

    removeItem(eventId, arrValues, bWait, fctCallback)
    {
        let requirements = [{
            "item": this.ItemManager.getItem(arrValues[0]),
            "quantity": parseInt(arrValues[1])
        }];
        
        this.ItemManager.removeRequiredItems(requirements, true, -1, true);

        fctCallback();
    }

    beginCinematicMode(eventId, arrValues, bWait, fctCallback)
    {
        this.WorldManager.startCinematicMode();

        fctCallback();
    }

    endCinematicMode(eventId, arrValues, bWait, fctCallback)
    {
        this.WorldManager.stopCinematicMode();

        fctCallback();
    }
}