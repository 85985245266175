import TriggerCodes from "../codes/TriggerCodes.js";

export default class WorldValidator
{
    constructor (dependencies)
    {
        this.dependencies = dependencies;
        this.createSupportedConditionList();
        this.createCompareConditionList();
        this.createClosure();
        this.bindEvents();
    }

    //---------------------------------------------------------
    //  DEPENDENCIES
    //---------------------------------------------------------
    get GameManager() { return this.dependencies.get("GameManager"); }
    get TriggerManager() { return this.dependencies.get("TriggerManager"); }
    get WorldManager() { return this.dependencies.get("WorldManager"); }
    get SaveManager() { return this.dependencies.get("SaveManager"); }
    //---------------------------------------------------------

    get TileArgumentType() { return this.TriggerManager.ARGUMENT_TYPES.TILE; };
    get LocationArgumentType() { return this.TriggerManager.ARGUMENT_TYPES.LOCATION; };
    get BarnZoneArgumentType() { return this.TriggerManager.ARGUMENT_TYPES.BARN_ZONE; };
    get RadioClickArgumentType() { return this.TriggerManager.ARGUMENT_TYPES.RADIO_CLICK; };
    get StepsDownArgumentType() { return this.TriggerManager.ARGUMENT_TYPES.STEPS_DOWN; };
    get BrothersArgumentType() { return this.TriggerManager.ARGUMENT_TYPES.BROTHERS; };
    get ClickOnMapType() { return this.TriggerManager.ARGUMENT_TYPES.CLICK_ON_MAP; };
    get ClickIndoorObstacle() { return this.TriggerManager.ARGUMENT_TYPES.CLICK_INDOOR_OBSTACLE; };
    get ClickOutdoorObstacle() { return this.TriggerManager.ARGUMENT_TYPES.CLICK_OUTDOOR_OBSTACLE; };
    get IndoorRoomIdArgumentType() { return this.TriggerManager.ARGUMENT_TYPES.INDOOR_ROOM_ID; };

    createSupportedConditionList ()
    {
        this.supportedConditions = {};

        this.supportedConditions[TriggerCodes.CONDITION_BARN] = this.validateBarn.bind(this);
        this.supportedConditions[TriggerCodes.CONDITION_DISTANCE] = this.validateTileDistance.bind(this);
        this.supportedConditions[TriggerCodes.CONDITION_LOCATION] = this.validateLocation.bind(this);
        this.supportedConditions[TriggerCodes.CONDITION_TILE_LOCATION] = this.validateTileLocation.bind(this);
        this.supportedConditions[TriggerCodes.CONDITION_TILE_RANGE] = this.validateTileRange.bind(this);
        this.supportedConditions[TriggerCodes.CONDITION_CLICK_RADIO] = this.validateClickRadio.bind(this);
        this.supportedConditions[TriggerCodes.CONDITION_STEPS_PUT_DOWN] = this.validateStepsPutDown.bind(this);
        this.supportedConditions[TriggerCodes.CONDITION_BROTHERS] = this.validateBrothers.bind(this);
        this.supportedConditions[TriggerCodes.CONDITION_CLICK_ON_GRID] = this.validateClickOnGrid.bind(this);
        this.supportedConditions[TriggerCodes.CONDITION_VARIABLE_VALUE] = this.validateVariableValue.bind(this);
        this.supportedConditions[TriggerCodes.CONDITION_CLICK_ON_OBJECT_INDOOR] = this.validateClickOnObjectIndoor.bind(this);
        this.supportedConditions[TriggerCodes.CONDITION_PLAYER_IS_INDOOR] = this.validatePlayerIsIndoor.bind(this);
        this.supportedConditions[TriggerCodes.CONDITION_FOREST_CLICK_ON_OBJECT] = this.validateClickOnObjectOutdoor.bind(this);

    }

    createCompareConditionList ()
    {
        this.compareConditions = {};

        // this.supportedConditions[TriggerCodes.CONDITION_BARN] = this.validateBarn.bind(this);
         this.compareConditions[TriggerCodes.CONDITION_DISTANCE] = this.compareTileDistance.bind(this);
        // this.supportedConditions[TriggerCodes.CONDITION_LOCATION] = this.validateLocation.bind(this);
        // this.supportedConditions[TriggerCodes.CONDITION_TILE_LOCATION] = this.validateTileLocation.bind(this);
        // this.supportedConditions[TriggerCodes.CONDITION_TILE_RANGE] = this.validateTileRange.bind(this);
        // this.supportedConditions[TriggerCodes.CONDITION_CLICK_RADIO] = this.validateClickRadio.bind(this);
        // this.supportedConditions[TriggerCodes.CONDITION_STEPS_PUT_DOWN] = this.validateStepsPutDown.bind(this);
        // this.supportedConditions[TriggerCodes.CONDITION_BROTHERS] = this.validateBrothers.bind(this);
        // this.supportedConditions[TriggerCodes.CONDITION_CLICK_ON_GRID] = this.validateClickOnGrid.bind(this);
         this.compareConditions[TriggerCodes.CONDITION_VARIABLE_VALUE] = this.compareVariableValue.bind(this);
         this.compareConditions[TriggerCodes.CONDITION_PLAYER_IS_INDOOR] = this.compareValidatePlayerIsIndoor.bind(this);

    }

    createClosure ()
    {
        this.fctOnMapCharacterTileChanged = this.onMapCharacterTileChanged.bind(this);
        this.fctOnPlayerLocationChanged = this.onPlayerLocationChanged.bind(this);
        this.fctOnPlayerLocationIndoorChanged = this.onPlayerLocationIndoorChanged.bind(this);
        this.fctOnBarnZoneUpgraded = this.onBarnZoneUpgraded.bind(this);
        this.fctOnPlayerClickRadio = this.onPlayerClickRadio.bind(this);
        this.fctOnPlayerStepsPutDown = this.onPlayerStepsPutDown.bind(this);
        this.fctOnPlayerScaredBrothers = this.onPlayerScaredBrothers.bind(this);
    }

    bindEvents ()
    {
        this.WorldManager.on(this.WorldManager.EVENT_PLAYER_LOCATION_INDOOR_CHANGED, this.fctOnPlayerLocationIndoorChanged);//@!
        this.WorldManager.on(this.WorldManager.EVENT_CHARACTER_TILE_CHANGED, this.fctOnMapCharacterTileChanged);//@!
        this.WorldManager.on(this.WorldManager.EVENT_PLAYER_LOCATION_CHANGED, this.fctOnPlayerLocationChanged);//@!
        this.WorldManager.on(this.WorldManager.EVENT_BARN_ZONE_UPGRADED, this.fctOnBarnZoneUpgraded);//@!
        this.WorldManager.on(this.WorldManager.EVENT_RADIO_CLICK, this.fctOnPlayerClickRadio);//@!
        this.WorldManager.on(this.WorldManager.EVENT_STEPS_PUT_DOWN, this.fctOnPlayerStepsPutDown);//@!
        this.WorldManager.on(this.WorldManager.EVENT_BROTHERS_SCARED, this.fctOnPlayerScaredBrothers);//@!

    }

    fetchCharacterPositions (strCharacterType = "", objNewValues = null)
    {

        // let objNewValuesValues = objNewValues && objNewValues.values && Array.isArray(objNewValues.values) && objNewValues.values.length ? objNewValues.values : [];
        // let objValue = objNewValuesValues.length > 0 ? objNewValuesValues[0] : undefined;
        //
        // let strValueType = objValue && objValue.Type ? objValue.Type : "";
        //
        // let arrPos = [];
        // if (objNewValues && objNewValues.type == this.TileArgumentType && strCharacterType && strCharacterType.toLowerCase() == strValueType.toLowerCase())
        // {
        //     arrPos.push({"x": objNewValues.values[1], "z": objNewValues.values[2]});
        // }

        let arrPos = [];
        let canReadType = Array.isArray( objNewValues.values) &&
            objNewValues.values.length > 0 &&
            objNewValues.values[0].Type !== undefined;

        if (objNewValues &&
            objNewValues.type == this.TileArgumentType &&
            strCharacterType &&
            canReadType &&
            (strCharacterType.toLowerCase() == objNewValues.values[0].Type.toLowerCase()))
        {
            arrPos.push({"x": objNewValues.values[1], "z": objNewValues.values[2]});
        }

        if (!objNewValues ||
            objNewValues.type != this.TileArgumentType ||
            !strCharacterType ||
            (strCharacterType && canReadType && strCharacterType.toLowerCase() != objNewValues.values[0].Type.toLowerCase()) ||
            (strCharacterType && strCharacterType.toLowerCase() != "player"))
        {
            if (!strCharacterType || strCharacterType == "0" || (strCharacterType && strCharacterType.toLowerCase() == "player"))
            {
                if (this.WorldManager.Player)
                {
                    arrPos.push(this.WorldManager.Player.GridPos);
                }
            }
            else
            {
                if (this.WorldManager.Enemies)
                {
                    let enemies = this.WorldManager.Enemies;
                    for (let i = 0; i < enemies.length; i++)
                    {
                        if (enemies[i].Type.toLowerCase() == strCharacterType.toLowerCase())
                        {
                            arrPos.push(enemies[i].GridPos);
                        }
                    }
                }

                if (this.WorldManager.NPCs)
                {
                    let npcs = this.WorldManager.NPCs;
                    for (let i = 0; i < npcs.length; i++)
                    {
                        if (npcs[i].Type.toLowerCase() == strCharacterType.toLowerCase())
                        {
                            arrPos.push(npcs[i].GridPos);
                        }
                    }
                }
            }
        }
        return arrPos;
    }

    //EVENTS MAPPING
    //--------------------------------------------------------------
    onIndoorObstacleClick (strItem)
    {
        this.TriggerManager.findTriggersAndExecute(
            this.supportedConditions,
            this.ClickIndoorObstacle,
            [strItem]
        );
    }


    onOutdoorObstacleClick (strItem)
    {
        this.TriggerManager.findTriggersAndExecute(
            this.supportedConditions,
            this.ClickOutdoorObstacle,
            [strItem]
        );
    }

    onMapCharacterTileChanged (objCharacter, iTileX, iTileZ)
    {
        this.TriggerManager.findTriggersAndExecute(
            this.supportedConditions,
            this.TileArgumentType,
            [objCharacter, iTileX, iTileZ]
        );
    }

    onPlayerLocationChanged (strNewLocation)
    {
        //waiting a little bit
        setTimeout( () =>
        this.TriggerManager.findTriggersAndExecute(
            this.supportedConditions,
            this.LocationArgumentType,
            [strNewLocation]
        ), 400); //this is sketchy as fuck
    }

    onPlayerLocationIndoorChanged (iIdRoom)
    {
        //waiting a little bit
        setTimeout( () =>
            this.TriggerManager.findTriggersAndExecute(
                this.supportedConditions,
                this.IndoorRoomIdArgumentType,
                [iIdRoom]
            ), 400); //this is sketchy as fuck
    }

    onBarnZoneUpgraded (strBarnZoneId, iUpgradeLevel)
    {
        this.TriggerManager.findTriggersAndExecute(
            this.supportedConditions,
            this.BarnZoneArgumentType,
            [strBarnZoneId, iUpgradeLevel]
        );
    }

    onPlayerClickRadio()
    {
        this.TriggerManager.findTriggersAndExecute(
            this.supportedConditions,
            this.RadioClickArgumentType,
            []
        );
    }

    onPlayerStepsPutDown(iCount)
    {
        this.TriggerManager.findTriggersAndExecute(
            this.supportedConditions,
            this.StepsDownArgumentType,
            [iCount]
        );
    }

    onPlayerScaredBrothers()
    {
        this.TriggerManager.findTriggersAndExecute(
            this.supportedConditions,
            this.BrothersArgumentType,
            [true]
        );
    }


    onCheckClick (pos)
    {
        this.TriggerManager.findTriggersAndExecute(
            this.supportedConditions,
            this.ClickOnMapType,
            [pos, true]
        );
    }

    //CONDITION VALIDATION
    //--------------------------------------------------------------
    validateCondition (strType, arrValues, objNewValues = null)
    {
        if (strType in this.supportedConditions)
        {
            return this.supportedConditions[strType](arrValues, objNewValues);
        }
        return true;
    }

    validateTileLocation (arrValues, objNewValues = null)
    {
        let positions = this.fetchCharacterPositions(arrValues[0], objNewValues);
        let bOk = false;

        for (let i = 0; i < positions.length; i++)
        {
            if (arrValues[1] == positions[i].x && arrValues[2] == positions[i].y)
            {
                bOk = true;
                break;
            }
        }

        return bOk;
    }

    validateTileRange (arrValues, objNewValues = null)
    {
        let positions = this.fetchCharacterPositions(arrValues[0], objNewValues);
        let bOk = false;

        for (let i = 0; i < positions.length; i++)
        {
            if (positions[i].x >= arrValues[1] && positions[i].x <= arrValues[1] + arrValues[3] &&
               positions[i].y >= arrValues[2] && positions[i].y <= arrValues[2] + arrValues[4])
            {
                bOk = true;
                break;
            }
        }

        return bOk;
    }

    validateTileDistance (arrValues, objNewValues = null)
    {
        let positions = this.fetchCharacterPositions(arrValues[0], objNewValues);
        let bOk = false;

        for (let i = 0; i < positions.length && !bOk; i++)
        {
            let diffX = arrValues[1] - positions[i].x;
            let diffY = arrValues[2] - positions[i].y
            let sqrMagnitude = Math.sqrt((diffX * diffX) + (diffY * diffY));
            let distance = parseInt(arrValues[3]);
            let strOperator = arrValues[4]

            if ((strOperator === "" || strOperator === "<=")  && sqrMagnitude <= distance)
            {
                bOk = true;
            }
            else if (strOperator === ">=" && sqrMagnitude >= distance)
            {
                bOk = true;;
            }
            else if ((strOperator === "<")  && sqrMagnitude < distance)
            {
                bOk = true;
            }
            else if (strOperator === ">" && sqrMagnitude > distance)
            {
                bOk = true;;
            }
        }
        return bOk;
    }

    compareTileDistance (arrValues)
    {

        let positions = this.WorldManager.Player ? [this.WorldManager.Player.GridPos] : [{x:-1, y:-1}];
        let bOk = false;

        let diffX = 0;
        let diffY = 0
        let sqrMagnitude = 0;
        let distance = 0;
        let strOperator = "="

        for (let i = 0; i < positions.length && !bOk; i++)
        {
            diffX = arrValues[1] - positions[i].x;
            diffY = arrValues[2] - positions[i].y
            sqrMagnitude = Math.sqrt((diffX * diffX) + (diffY * diffY));
            distance = parseInt(arrValues[3]);
            strOperator = arrValues[4]

            if ((strOperator === "" || strOperator === "<=")  && sqrMagnitude <= distance)
            {
                bOk = true;
            }
            else if (strOperator === ">=" && sqrMagnitude >= distance)
            {
                bOk = true;;
            }
            else if ((strOperator === "<")  && sqrMagnitude < distance)
            {
                bOk = true;
            }
            else if (strOperator === ">" && sqrMagnitude > distance)
            {
                bOk = true;;
            }
        }

        let humanReadable = {};

        humanReadable[">"] = "plus grande que";
        humanReadable[">="] = "plus grande ou égale à";
        humanReadable["<"] = "plus petite que";
        humanReadable["<="] = "plus petite ou égale à";

        let strOperationReadable = humanReadable[strOperator];

        return {
            method: {name: "TileDistance", shorthand:"DIS"},
            result: bOk,
            explanation: [
                `On doit être à une distance ${strOperationReadable} ${distance} de la case ${arrValues[1]},${arrValues[2]}.`,
                `On est à une distance de ${sqrMagnitude} à la case  ${positions[0].x},${positions[0].y}`
            ]
        };
    }

    validatePlayerIsIndoor (arrValues, objNewValues = null)
    {

        if (objNewValues && objNewValues.type == this.IndoorRoomIdArgumentType)
        {
            let currentRoomId = parseInt(arrValues[0]);
            let goalRoomId = parseInt(objNewValues.values[0]);

            return currentRoomId === goalRoomId;
        }

        return false;
    }

    compareValidatePlayerIsIndoor (goalRoom)
    {

        let result = false;
        let strRoom = this.WorldManager.Environment.Id;

        if (strRoom === "house" || strRoom === "labo")
        {
            let currentRoomId = parseInt(this.WorldManager.Environment.roomId);
            let goalRoomId = parseInt(goalRoom[0]);

            result =  currentRoomId === goalRoomId;

            return {
                method: {name: "PlayerIsIndoor", shorthand:"VAR_VALUE"},
                result,
                explanation: [
                    `Le joueur est dans la piece ${currentRoomId} et il doit etre dans la piece ${goalRoomId} .`
                ]
            };
        }



        return {
            method: {name: "PlayerIsIndoor", shorthand:"VAR_VALUE"},
            result,
            explanation: [
                `Le joueur n'est pas dans la maison ou le labo. Il est dans ${strRoom}`
            ]
        };
    }

    validateClickOnObjectOutdoor (arrValues, objNewValues = null)
    {
        let iValueType = _.get(objNewValues, "type", -1);

        if (iValueType === this.ClickOutdoorObstacle)
        {
            let goalClickX = parseInt(arrValues[0]);
            let goalClickY = parseInt(arrValues[1]);

            let goalClickItem = objNewValues.values[0];

            let currentClickX = goalClickItem.x / 10;
            let currentClickY = goalClickItem.y / 10;

            return goalClickX === currentClickX && goalClickY === currentClickY;
        }

        return false;

    }

    validateClickOnObjectIndoor (arrValues, objNewValues = null)

    {
        let iValueType = _.get(objNewValues, "type", -1);

        if (iValueType === this.ClickIndoorObstacle)
        {
            let currentClickItem = arrValues[0];
            let goalClickItem = objNewValues.values[0].name;

            return currentClickItem === goalClickItem;
        }

        return false;

    }

    validateLocation (arrValues, objNewValues = null)
    {
        let location = null;
        if (objNewValues && objNewValues.type == this.LocationArgumentType)
        {
            location = objNewValues.values[0];
        }
        else
        {
            if (this.WorldManager.IsForest)
            {
                location = this.WorldManager.ENVIRONMENT_FOREST;
            }
            else if (this.WorldManager.IsLabo)
            {
                location = this.WorldManager.ENVIRONMENT_LABO;
            }
            else if (this.WorldManager.IsHouse)
            {
                location = this.WorldManager.ENVIRONMENT_HOUSE;
            }
            else
            {
                location = this.WorldManager.ENVIRONMENT_BARN;
            }
        }

        return (arrValues[0] == TriggerCodes.LOCATION_FOREST && location == this.WorldManager.ENVIRONMENT_FOREST) ||
                 (arrValues[0] == TriggerCodes.LOCATION_BARN && location == this.WorldManager.ENVIRONMENT_BARN) ||
                 (arrValues[0] == TriggerCodes.LOCATION_LABO && location == this.WorldManager.ENVIRONMENT_LABO) ||
                 (arrValues[0] == TriggerCodes.LOCATION_HOUSE && location == this.WorldManager.ENVIRONMENT_HOUSE);
    }

    validateBarn (arrValues, objNewValues = null)
    {
        let upgradeLevel = -1;
        if (objNewValues && objNewValues.type == this.BarnZoneArgumentType && objNewValues.values[0].toLowerCase() == arrValues[0].toLowerCase())
        {
            upgradeLevel = objNewValues.values[1];
        }
        else
        {
            switch (arrValues[0])
            {
                case TriggerCodes.BARN_ZONE_SLEEP:
                    upgradeLevel = this.WorldManager.BarnSleepZoneLevel;
                    break;
                case TriggerCodes.BARN_ZONE_RELAX:
                    upgradeLevel = this.WorldManager.BarnRelaxZoneLevel;
                    break;
                case TriggerCodes.BARN_ZONE_COOKING:
                    upgradeLevel = this.WorldManager.BarnCookingZoneLevel;
                    break;
                case TriggerCodes.BARN_ZONE_CRAFTING:
                    upgradeLevel = this.WorldManager.BarnCraftingZoneLevel;
                    break;
            }
        }
        return upgradeLevel >= arrValues[1];
    }

    validateClickRadio (arrValues, objNewValues = null)
    {
        return objNewValues && objNewValues.type == this.RadioClickArgumentType;
    }

    validateStepsPutDown(arrValues, objNewValues = null)
    {
        let stepsCount = 0;
        if (objNewValues && objNewValues.type == this.StepsDownArgumentType)
        {
            stepsCount = objNewValues.values[0];
        }
        else
        {
            stepsCount = this.WorldManager.StepsPutDownCount;
        }
        return stepsCount >= arrValues[0];
    }

    validateBrothers(arrValues, objNewValues = null)
    {
        let bHasScared = false;
        if (objNewValues && objNewValues.type == this.BrothersArgumentType)
        {
            bHasScared = objNewValues.values[0];
        }
        else
        {
            bHasScared = this.GameManager.HasScaredBrothers;
        }
        return (arrValues[0] == "1") == bHasScared;
    }


    validateClickOnGrid (arrValues, objNewValues= null)
    {
        if (objNewValues && objNewValues.type == this.ClickOnMapType)
        {

            if (arrValues && arrValues.length > 0)
            {
                let a = arrValues[0].split(",");
                let b = arrValues[0].split(",").map(n => parseInt(n));
                let [x, y] = arrValues[0].split(",").map(n => parseInt(n));


                let goalPos = (objNewValues.values[0]);

                if (goalPos.x === x && goalPos.y === y)
                {
                    return true;
                }
            }
        }


        return false;
    }


    validateVariableValue(arrValues, objNewValues= null)
    {
        if (arrValues && arrValues.length === 3)
        {
            let strVariableKey = arrValues[0];
            let strOperation = arrValues[1];
            let strGoalValue = arrValues[2];

            let strCurrentValue = this.SaveManager.getFromSave(strVariableKey);

            if (strOperation === "equal")
            {
                return strGoalValue == strCurrentValue;
            }
            else if (strOperation === "not_equal")
            {
                return strGoalValue != strCurrentValue;
            }
            else if (strOperation === "smaller")
            {
                return strCurrentValue < strGoalValue ;
            }
            else if (strOperation === "smaller_or_equal")
            {
                return strCurrentValue <= strGoalValue ;
            }
            else if (strOperation === "greater")
            {
                return strCurrentValue > strGoalValue ;
            }
            else if (strOperation === "greater_or_equal")
            {
                return strCurrentValue >= strGoalValue ;
            }

            // console.log("validateVariableValue")
            // console.log(arguments);
            // console.log("strVariableKey", strVariableKey);
            // console.log("strOperation", strOperation);
            // console.log("strGoalValue", strGoalValue);
            // console.log("strCurrentValue", strCurrentValue);
        }


        return false;
    }

    compareVariableValue(arrValues)
    {

            let strVariableKey = arrValues[0];
            let strOperation = arrValues[1];
            let strGoalValue = arrValues[2];
            let result =  false;

            let strCurrentValue = this.SaveManager.getFromSave(strVariableKey);

            if (strOperation === "equal")
            {
                result =  strGoalValue == strCurrentValue;
            }
            else if (strOperation === "not_equal")
            {
                result =  strGoalValue != strCurrentValue;
            }
            else if (strOperation === "smaller")
            {
                result =  strCurrentValue < strGoalValue ;
            }
            else if (strOperation === "smaller_or_equal")
            {
                result =  strCurrentValue <= strGoalValue ;
            }
            else if (strOperation === "greater")
            {
                result =  strCurrentValue > strGoalValue ;
            }
            else if (strOperation === "greater_or_equal")
            {
                result =  strCurrentValue >= strGoalValue ;
            }


        let humanReadable = {};
        humanReadable["equal"] = "égale à";
        humanReadable["not_equal"] = "pas égale à ";
        humanReadable["greater"] = "plus grande que";
        humanReadable["greater_or_equal"] = "plus grande ou égale à";
        humanReadable["smaller"] = "plus petite que";
        humanReadable["smaller_or_equal"] = "plus petite ou égale à";

        let strHumanReadable = "";

        return {
            method: {name: "VariableValue", shorthand:"VAR_VALUE"},
            result,
            explanation: [
                `La variable ${strVariableKey} devrait etre ${strHumanReadable} à '${strGoalValue}'.`,
                `La valeur actuel de ${strVariableKey} est à '${strCurrentValue}'`
            ]
        };
    }
}