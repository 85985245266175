import BaseCheat from "./BaseCheat.js";

export default class Barn extends BaseCheat
{
    constructor(dependencies)
    {
        super(dependencies);
    }

    get CommandUse() { return "barn [show/upgrade/downgrade] [sleep/cooking/crafting/relax]"; }
    get CommandDesc() { return "Show will display the popup. Upgrade and downgrade will increase or decrease the zone level"; }

    //---------------------------------------------------------
    //  DEPENDENCIES
    //---------------------------------------------------------
    get UIManager() { return this.dependencies.get("UIManager"); }
    get WorldManager() { return this.dependencies.get("WorldManager"); }
    //---------------------------------------------------------

    parseCommand(strCmd, arrArguments)
    {
        if (strCmd == "barn" && arrArguments.length >= 2)
        {
            let action = arrArguments[0].toLowerCase();
            let zone = arrArguments[1].toLowerCase();
            if (action == "show" && this.UIManager.CurrentUI.showZonePopup(zone))
            {
                this.log("Showed the barn zone " + zone + " on screen");
                return;
            }
            else if (action == "upgrade" || action == "downgrade")
            {
                let newLevel = this.WorldManager.getBarnZoneLevel(zone) + 1;
                let requirements = this.getBarnZoneUpgradeRequirements(zone, newLevel);

                if (requirements.length > 0)
                {
                    this.setBarnZoneLevel(strZone, newLevel);
                    this.WorldManager.emit(this.WorldManager.EVENT_BARN_ZONE_UPGRADED, zone, newLevel);

                    this.log((action == "upgrade" ? "Increased " : "Decreased ") + zone + " level to " + newLevel);
                    return;
                }
            }

            this.warn("Cannot apply the action " + arrArguments[0] + " to the barn zone " + arrArguments[1]);
        }
    }
}