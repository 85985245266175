import {Color} from "three";
import {Group} from "three";
import {Mesh} from "three";
import {MeshBasicMaterial} from "three";
import {PlaneGeometry} from "three";
import {Raycaster} from "three";
import {RingGeometry} from "three";
import {Vector2} from "three";
import AnimationController from "./animations/AnimationController.js";
import Character from "./Character.js";
import Constants from "../../utils/Constants.js";
import Direction from "../../utils/Direction.js";
import EnemyAI from "../ai/EnemyAI.js";
import Lerp from "../../utils/Lerp.js";
import Library from "../../Library.js";
import PathAI from "../ai/PathAI.js";
import ScareAI from "../ai/ScareAI.js";
import _ from "lodash";

export default class Enemy extends Character
{
    constructor()
    {
        super();
    }

    get EVENT_FOV_COLLISION() { return "fov-collision-player"; }
    get EVENT_ON_SCARE() { return "on-scare"; }

    get SPRITESHEET_COLUMN_COUNT() { return 4; }
    get FOV_ROTATION_UP() { return -Math.PI  * 0.657 + Math.PI; }
    get FOV_ROTATION_ANIM_TIME() { return 0.75; }
    get FOV_NORMAL_COLOR() { return parseInt(Constants.getValue("ENEMY_FOV_NORMAL_COLOR")); }
    get FOV_NORMAL_OPACITY() { return Constants.getValue("ENEMY_FOV_NORMAL_OPACITY"); }
    get FOV_ATTACK_COLOR() { return parseInt(Constants.getValue("ENEMY_FOV_ATTACK_COLOR")); }
    get FOV_ATTACK_OPACITY() { return Constants.getValue("ENEMY_FOV_ATTACK_OPACITY"); }

    get Definition() { return this.meta.definition; }
    get FOVMesh() { return this.fovMesh; }
    get FOVPivot() { return this.fovPivot; }
    get PlaneSize() { return this.planeSize; }
    get IsBrother() { return this.Definition.isBrother;}
    get MovementSpeed(){ return this.ai.MovementSpeed; }
    get EnemyId(){ return this.meta.definition.id; }

    get Player() { return this.Environment.Player; }
    get Camera() { return this.Environment.Scene.Camera; }

    get CollisionRect()
    {
        let origin = this.getMeshOrigin();
        let collider = (this.meta.definition.mesh && this.meta.definition.mesh.collider ? this.meta.definition.mesh.collider : {"width": 1, "height": 1});

        return {
            "min": new Vector2(
                this.Mesh.position.x - this.PlaneSize.width * collider.width * origin.x, 
                this.Mesh.position.z - this.PlaneSize.height * collider.height * origin.y
            ),
            "max": new Vector2(
                this.Mesh.position.x + this.PlaneSize.width * collider.width * origin.x,
                this.Mesh.position.z + this.PlaneSize.height * collider.height * origin.y
            )
        };
    }

    /*******************************************
    *   INITIALIZATION
    *******************************************/
    /**
        Parameters to pass to the init function:
        - id:           Logic id of this object instance. Usually managed by some sort of global manager
        - definition    Definition of this enemy (from the settings.json file)
        - spawnPos      Vector2 containing the world position to spawn this object in ThreeJS
        - direction     Initial direction where the player will look at. Use the Direction class at utils/Direction.js
        - environment   Environment instance where the player lives
        - pathing       (Optional) Array containing the path for the AI to follow. Default is NULL
        - params        JSON object containing additionnal parameters to pass to the player

    */
    init(meta)
    {
        this.fovAnimation = {"current": null, "next": null};
        meta.type = "enemy";
        this.pathing = ("pathing" in meta ? meta.pathing : null);

        meta.atlasName = meta.definition.spritesheet;
        meta.textureName = (!meta.textureName ? undefined : meta.textureName);

        if (meta.definition.mesh && meta.definition.mesh.origin)
        {
            meta.meshOrigin = meta.definition.mesh.origin;
        }

        super.init(meta);

        this.createAI();
    }

    createClosure()
    {
        super.createClosure();
        this.fctOnMovementTileChanged = this.onMovementTileChanged.bind(this);
    }

    bindEvents()
    {
        super.bindEvents();
        this.on(this.EVENT_MOVEMENT_TILE_CHANGED, this.fctOnMovementTileChanged);
    }

    createGeometry(objSize)
    {
        let divider = this.ResponsiveManager.AssetDivider * 0.85;

        //@TODO: We should do something about this instead of hardcoding
        //------------------------------------------------------
        let colCount = this.SPRITESHEET_COLUMN_COUNT;
        let rowCount = Math.ceil(
            Library.getData("animation_atlas")[this.Definition.atlas]["idle"][Direction.getAtlasName(Direction.South)][0].frameCount / colCount
        );
        //------------------------------------------------------

        this.planeSize = {
            "width": objSize.w / divider / colCount,
            "height": objSize.h / divider / rowCount
        };

        let widthMultiplier = _.get(this.meta.definition, "mesh.scaleMultiplier.width", 1);
        let heightMultiplier = _.get(this.meta.definition, "mesh.scaleMultiplier.height", 1);


        let geometry = new PlaneGeometry(this.planeSize.width * widthMultiplier, this.planeSize.height* heightMultiplier, 10, 10);
        geometry.translate(0, (objSize.h / divider / rowCount / 2) * heightMultiplier, 0);


        geometry.verticesNeedUpdate = true;

        return geometry;
    }

    createAnimationController()
    {
        this.animationController = new AnimationController();
        this.animationController.init({
            "atlasId":        this.Definition.atlas,
            "mesh":           this.mesh
        });
    }

    createAI()
    {
        let initParams = {
            "character": this,
            "spawnPos": this.meta.spawnPos,
            "definition": this.Definition.ai
        };

        if (this.pathing)
        {
            this.ai = new PathAI();
            initParams.pathing = this.pathing;
        }
        else
        {
            if (this.Definition.ai.scare)
            {
                this.ai = new ScareAI();
            }
            else
            {
                this.ai = new EnemyAI();
            }
        }

        this.ai.init(initParams);
    }

    destroy()
    {
        this.ai.destroy();
        delete this.ai;

        if (this.Scene && this.FOVPivot)
        {
            if (this.FOVMesh)
            {
                this.FOVPivot.remove(this.FOVMesh);
                this.FOVMesh.geometry.dispose();
                this.FOVMesh.material.dispose();
                this.fovMesh = null;
            }

            this.Scene.remove(this.FOVPivot);
            delete this.FOVPivot;
        }

        super.destroy();
    }

    /*******************************************
    *   FOV
    *******************************************/
    createFOVGeometry(iLength)
    {
        let geometry = new RingGeometry(this.Grid.CellSize, iLength, 7, 7, 0, 1);
        geometry.verticesNeedUpdate = true;

        geometry.computeBoundingSphere();
        geometry.computeBoundingBox();

        return geometry;
    }

    createFOVMaterial()
    {
        return new MeshBasicMaterial({
            color:this.FOV_NORMAL_COLOR,
            transparent: true,
            opacity:this.FOV_NORMAL_OPACITY,
            depthWrite: false
        });
    }

    createFOVMesh(iLength, iPosX, iPosZ)
    {
        let cellSize = this.Grid.CellSize;

        let fovMesh = new Mesh(
            this.createFOVGeometry((iLength + 1) * cellSize),
            this.createFOVMaterial()
        );

        fovMesh.rotation.z = this.FOV_ROTATION_UP;
        fovMesh.renderOrder = 5;

        var pivot = new Group();
        pivot.position.x = iPosX + cellSize * 0.5;
        pivot.position.y = 1;
        pivot.position.z = iPosZ + cellSize * 0.5;

        pivot.rotation.x = -Math.PI / 2;
        pivot.add(fovMesh);

        fovMesh.position.x = 0;
        fovMesh.position.y = -cellSize * 0.5;
        fovMesh.position.z = 0;

        pivot.rotation.z = Math.PI;

        this.fovMesh = fovMesh;
        this.fovPivot = pivot;

        this.Scene.add(pivot);
    }

    setFOVState(bIsAttacking)
    {
        if (bIsAttacking)
        {
            this.FOVMesh.material.color = new Color(this.FOV_ATTACK_COLOR);
            this.FOVMesh.material.opacity = this.FOV_ATTACK_OPACITY;
        }
        else
        {
            this.FOVMesh.material.color = new Color(this.FOV_NORMAL_COLOR);
            this.FOVMesh.material.opacity = this.FOV_NORMAL_OPACITY;
        }

        this.FOVMesh.material.needsUpdate = true;
    }

    rotateFOV(rotation)
    {
        let old = (this.fovAnimation.current ? this.fovAnimation.current.end : this.fovPivot.rotation.z);
        rotation = this.calculateNewRotation(
            old,
            (rotation < 0 ? rotation : rotation - 2*Math.PI),
            (rotation < 0 ? 2*Math.PI + rotation : rotation)
        );

        let animTime = this.calculateFOVAnimTime(this.FOV_ROTATION_ANIM_TIME, rotation.diff);

        if (this.FOVPivot.rotation.z != rotation)
        {
            this.fovAnimation.next = {
                "timeLeft": animTime,
                "totalTime": animTime,
                "end": rotation.value
            }
        }
    }

    calculateNewRotation(fOld, fNew1, fNew2)
    {
        let rotation = fNew1;
        if (Math.abs(fNew1 - fOld) > Math.abs(fNew2 - fOld))
        {
            rotation = fNew2
        }
        return {"value": rotation, "diff": Math.abs(rotation - fOld)};
    }

    calculateFOVAnimTime(fMaxTime, fRotationDiff)
    {
        return fMaxTime * Math.max(0.5, Math.min(1, fRotationDiff / Math.PI));
    }

    calculateFOVAngle(iX, iY)
    {
        let rotation = 0;
            
        let pos = this.GridPos;
        let diffX = iX - pos.x * this.Grid.CellSize;
        let diffY = iY - pos.y * this.Grid.CellSize;

        if (diffX != 0 && diffY == 0)
        {
            rotation = Math.PI / 2 * (diffX < 0 ? 1 : -1);
        }
        else if (diffX == 0 && diffY != 0)
        {
            rotation = Math.PI * (diffY < 0 ? 0 : 1);
        }
        else if (diffX != 0 && diffY != 0)
        {
            if (diffX < 0 && diffY > 0)
            {
                rotation = Math.PI * 0.75;
            }
            else if (diffX < 0 && diffY < 0)
            {
                rotation = Math.PI / 4;
            }
            else if (diffX > 0 && diffY < 0)
            {
                rotation = -Math.PI / 4;
            }
            else if (diffX > 0 && diffY > 0)
            {
                rotation = -Math.PI * 0.75;
            }
        }

        return rotation;
    }

    /*******************************************
    *   UPDATE LOOP
    *******************************************/
    update(fDeltaTime)
    {
        super.update(fDeltaTime);

        this.updateFOVPosition(fDeltaTime);
        this.updateFOVRotation(fDeltaTime);
        this.updateFovCollision(fDeltaTime);
        this.updateAI(fDeltaTime);
    }

    updateFOVPosition(fDeltaTime)
    {
        this.FOVPivot.position.x = this.Mesh.position.x;
        this.FOVPivot.position.z = this.Mesh.position.z - this.Grid.CellSize * 0.5;
    }

    updateFOVRotation(fDeltaTime)
    {
        if (!this.fovAnimation.current && this.fovAnimation.next)
        {
            this.fovAnimation.current = this.fovAnimation.next;

            this.fovAnimation.current.start = this.FOVPivot.rotation.z;
            let endRot = this.calculateNewRotation(
                this.FOVPivot.rotation.z,
                (this.fovAnimation.current.end < 0 ? this.fovAnimation.current.end : this.fovAnimation.current.end - 2*Math.PI),
                (this.fovAnimation.current.end < 0 ? 2*Math.PI + this.fovAnimation.current.end : this.fovAnimation.current.end)
            );
            this.fovAnimation.current.end = endRot.value;
            this.fovAnimation.current.totalTime = this.calculateFOVAnimTime(this.FOV_ROTATION_ANIM_TIME, endRot.diff);
            this.fovAnimation.current.timeLeft = this.fovAnimation.current.totalTime;
            this.fovAnimation.next = null;
        }

        if (this.fovAnimation.current)
        {
            this.fovAnimation.current.timeLeft -= fDeltaTime;
            let t = 1 - this.fovAnimation.current.timeLeft / this.fovAnimation.current.totalTime;
            t = t*t*t * (t * (6*t - 15) + 10);

            this.FOVPivot.rotation.z = Lerp.lerp(this.fovAnimation.current.start, this.fovAnimation.current.end, t);

            if (this.fovAnimation.current.timeLeft <= 0)
            {
                this.fovAnimation.current = null;
            }
        }
    }

    updateFovCollision(fDeltaTime)
    {
        if (this.FOVMesh && !this.WorldManager.IsDialogMode && !this.WorldManager.IsCinematicMode && this.Player && !this.Player.IsInvisible && !this.IsStun)
        {
            let ratio = this.WorldManager.PlayerCollisionWidthRatio / 2;
            let positions = [
                {"x": this.Player.Mesh.position.x - this.Player.PlaneSize.width * ratio, "z": this.Player.Mesh.position.z},
                {"x": this.Player.Mesh.position.x + this.Player.PlaneSize.width * ratio, "z": this.Player.Mesh.position.z}
                //{"x": this.Player.mesh.position.x, "z": this.Player.mesh.position.z}
            ];

            if (!this.raycaster)
            {
                this.raycaster = new Raycaster();
            }

            this.Camera.updateProjectionMatrix();
            for (let i = 0; i < positions.length; i++)
            {
                let playerPos = this.Camera.worldToScreenPosition(positions[i].x, this.Player.mesh.position.y, positions[i].z, true);
                this.raycaster.setFromCamera(playerPos, this.Camera);

                let results = this.raycaster.intersectObjects([this.FOVMesh]);

                if (results.length > 0)
                {
                    this.emit(this.EVENT_FOV_COLLISION, this.Player);
                    break;
                }
            }
        }
    }

    updateAI(fDeltaTime)
    {
        if (this.ai)
        {
            this.ai.update(fDeltaTime);
        }
    }

    /*******************************************
    *   MOVEMENT
    *******************************************/
    goTo(iX, iY, bSkipDiagonals = false, fctCallback = null)
    {
        if (!this.ai.IsTriggered)
        {
            let rotation = this.calculateFOVAngle(iX, iY);
            this.rotateFOV(rotation);
        }

        return super.goTo(iX, iY, bSkipDiagonals, fctCallback);
    }

     /*******************************************
    *   SPAWN MANAGEMENT
    *******************************************/
    /**
        Spawns the object on the field
        @param objPos Position where to spawn the object. Should be in grid coordinates (not ThreeJS coordinates)
    */
    spawn(objPos)
    {
        super.spawn(objPos);

        if (!this.FOVPivot)
        {
            this.createFOVMesh(this.Definition.fov, objPos.x, objPos.y);
        }
        else
        {
            let cellSize = this.Grid.CellSize;

            this.FOVPivot.position.x = objPos.x * cellSize;
            this.FOVPivot.position.z = objPos.y * cellSize;
            this.Scene.add(this.FOVPivot);
        }
    }

    despawn()
    {
        super.despawn();
        if (this.FOVPivot)
        {
            this.Scene.remove(this.FOVPivot);
        }
    }

    /*******************************************
    *   EVENTS
    *******************************************/
    /**
        Externally called by ScareAI when they get triggered and they should notify other enemy instances
    */
    onEnemyScareStart(sender, target)
    {
        if (this.ai.onEnemyScareStart)
        {
            this.ai.onEnemyScareStart(sender, target);
        }
    }

    onMoveEnd()
    {
        if (!this.ai.IsTriggered || this.ai.target.IsSwimming || this.ai.IsStun)
        {
            super.onMoveEnd();
        }
    }

    onMovementTileChanged(sender)
    {
        if (!this.ai.IsTriggered && this.CharacterAction.TileToReach)
        {
            let rotation = this.calculateFOVAngle(
                this.CharacterAction.TileToReach.x,
                this.CharacterAction.TileToReach.y
            );
            this.rotateFOV(rotation);
        }
    }
}