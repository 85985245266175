import ContainerResponsive from "./responsive/ContainerResponsive.js";
import Display from "../../utils/Display.js";
import GraphicsResponsive from "./responsive/GraphicsResponsive.js";
import Lerp from "../../utils/Lerp.js";

export default class ScreenTransition extends ContainerResponsive
{
    constructor ()
    {
        super();
    }

    get TRANSITION_TIME() { return 1000; }

    /*******************************************
    *   INITIALIZATION
    *******************************************/
    /**
        Parameters to pass to the init function:
        - ui:  UI section object where this component resides
    */
    init(meta)
    {
        this.alpha = 0;
        this.transitionTime = this.TRANSITION_TIME;

        return super.init(meta);
    }

    destroy(options)
    {
        this.timeLeft = 0;
        super.destroy(options);
    }

    /*******************************************
    *   BUILD
    *******************************************/
    build()
    {
        if (!this.graphics)
        {
            this.graphics = new GraphicsResponsive().init({"ui": this.UI});
            this.addChild(this.graphics);
        }

        this.graphics.beginFill(0, this.alpha);
        this.graphics.drawRect(0, 0, this.UI.ResponsiveManager.Width, this.UI.ResponsiveManager.Height);

        this.graphics.rX =      [{on: "default", x:"0"}];
        this.graphics.rY =      [{on: "default", y:"0"}];
        this.graphics.rWidth =  [ {on: "default", width: "iw"}];
        this.graphics.rHeight =  [ {on: "default", height: "ih"}];

        this.graphics.alpha = this.alpha;

        this.graphics.layout(Display.getSize());
    }

    clean()
    {
        if (this.graphics)
        {
            this.graphics.clear();
        }
        super.clean();
    }

    startTransition(fctCallbackMiddle, fctCallbackEnd)
    {
        this.callbackMiddle = fctCallbackMiddle;
        this.callbackEnd = fctCallbackEnd;
        this.isHiding = true;

        this.lastTime = new Date().getTime();
        this.timeLeft = this.transitionTime / 2;

        this.executeTransition();
    }

    executeTransition()
    {
        if (this.timeLeft > 0)
        {
            let time = new Date().getTime();
            let delta = time - this.lastTime;
            this.lastTime = time;

            this.timeLeft -= delta;
            let t = 1 - this.timeLeft / (this.transitionTime / 2);

            let from = 0;
            let to = 1;
            if (this.isHiding)
            {
                t--;
                t = t * t * t + 1;
            }
            else
            {
                from = 1;
                to = 0;
                t = t * t * t;
            }

            this.t = t;

            this.alpha = Lerp.lerp(from, to, t);
            this.build();

            if (this.timeLeft <= 0 && this.isHiding)
            {
                this.isHiding = false;
                this.timeLeft = this.transitionTime / 2;
                if (this.callbackMiddle)
                {
                    this.callbackMiddle();
                }
            }

            if (this.timeLeft > 0)
            {
               window.requestAnimationFrame(this.executeTransition.bind(this));
            }
            else
            {
                if (this.callbackEnd)
                {
                    this.callbackEnd();
                }
            }
        }
    }
}