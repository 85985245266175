import MutationCodes from "./codes/MutationCodes.js";
import Item from "./Item.js";
import MutationPower from "./MutationPower.js";

export default class MutationItem extends Item
{
    constructor(strId, objDefinition)
    {
        super(strId, objDefinition);
        this.mutationDefinition = objDefinition.mutation;
        this.power = null;
        if ("power" in this.mutationDefinition)
        {
            this.power = new MutationPower(strId, this.Code, this.mutationDefinition.power);
        }
    }

    get IsMutation() { return true; }
    get IsDroppable() { return false; }

    get EquipLocation() { return this.mutationDefinition.location; }
    get IsHead() { return this.EquipLocation.toUpperCase() == MutationCodes.LOCATION_HEAD;}
    get IsArm() { return this.EquipLocation.toUpperCase() == MutationCodes.LOCATION_ARMS;}
    get IsLeg() { return this.EquipLocation.toUpperCase() == MutationCodes.LOCATION_LEGS;}

    get Code() { return this.definition.mutation.code;}
    get MutationPower() { return this.power; }
    //Stat bonuses
    get EnergyLossBonus() { return ("energyloss" in this.mutationDefinition ? this.mutationDefinition.energyloss : 0); }
    get StressLossBonus() { return ("stressloss" in this.mutationDefinition ? this.mutationDefinition.stressloss : 0); }
    get SearchSpeedBonus() { return ("searchspeed" in this.mutationDefinition ? this.mutationDefinition.searchspeed : 0); }
    get ExtraMaterialBonus() { return ("extramat" in this.mutationDefinition ? this.mutationDefinition.extramat : 0); }
    get SprintDurationBonus() { return ("sprint" in this.mutationDefinition ? this.mutationDefinition.sprint : 0); }
    get WalkSpeedBonus() { return ("walkspeed" in this.mutationDefinition ? this.mutationDefinition.walkspeed : 0); }
    get WalkSpeedMultiplier() { return ("walkspeedMultiplier" in this.mutationDefinition ? this.mutationDefinition.walkspeedMultiplier : 1); }
    get MaxEnergyBonus() { return ("maxenergy" in this.mutationDefinition ? this.mutationDefinition.maxenergy : 0); }
    get MaxHungerBonus() { return ("maxhunger" in this.mutationDefinition ? this.mutationDefinition.maxhunger : 0); }
    get MaxStressBonus() { return ("maxstress" in this.mutationDefinition ? this.mutationDefinition.maxstress : 0); }
    get SprintCooldownReduction() { return ("sprintcooldown" in this.mutationDefinition ? this.mutationDefinition.sprintcooldown : 0); }
}