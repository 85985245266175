import IndoorEnvironment from "./IndoorEnvironment.js";
import MapObstacles from "../map/MapObstacles.js";

export default class LaboEnvironment extends IndoorEnvironment
{
    constructor(canvas, dependencies)
    {
        super(canvas);
    }

    init(params)
    {
        super.init(!params ? {} : params);

        this.AudioManager.playSfx("ambiance_labo", true, 2);
    }

    get Id() { return "labo"; }
}