import { string2hex }  from "@pixi/utils"; 
import { TextStyle } from "@pixi/text";
import { TextMetrics } from "@pixi/text";
import ContainerResponsive from "../../commons/responsive/ContainerResponsive.js";
import GraphicsResponsive from "../../commons/responsive/GraphicsResponsive.js";
import Library from "../../../Library.js";
import SpriteResponsive from "../../commons/responsive/SpriteResponsive.js";
import TextResponsive from "../../commons/responsive/TextResponsive.js";

export default class CraftingCategoriesContent extends ContainerResponsive
{
    constructor()
    {
        super();
    }

    get EVENT_SELECT() { return "select"; }

    get Opacity() { return this.opacity; }
    set Opacity(fNewValue) { this.opacity = fNewValue; this.build(); }

    get BgWidth() { return this.evaluate(this.drawWidth, 0, 0, 0, 0, this); }
    get BgHeight() { return this.evaluate(this.drawHeight, 0, 0, 0, 0, this); }

    get CategoryPerRow() { return 3; }
    get RowPerPage() { return 2; }

    get PaddingHorizontal() { return this.BgWidth * 0.125; }
    get PaddingVertical() { return this.BgHeight * 0.25; }
    get CategoryWidth() { return (this.BgWidth - this.PaddingHorizontal) / this.CategoryPerRow; }
    get CategoryHeight() { return (this.BgHeight - this.PaddingVertical) / this.RowPerPage; }
    get ImageWidth() { return this.ImageHeight; }
    get ImageHeight() { return this.CategoryHeight * 0.66; }

    get BackgroundColor() { return string2hex(this.UI.Values.modal.background.color);}
    get ImageBackgroundColor() { return string2hex(this.UI.Values.slot.background.color); }
    get ImageStrokeWidth() { return 0; }
    get ImageStrokeColor() { return string2hex("color" in this.UI.Values.slot.stroke ? this.UI.Values.slot.stroke.color : "#000000"); }
    get ImageCornerRadius() { return this.evaluate(this.valueFormula(this.UI.Values.slot.corner.radius), 0, 0, 0, 0, this); }
    get SpriteTint() { return string2hex("#52f1e6"); }

    /*******************************************
    *   INITIALIZATION
    *******************************************/
    /**
        Parameters to pass to the init function:
        - ui:       UI section object where this component resides
        - rWidth:   Responsive equation for the width of this content
        - rHeight:  Responsive equation for the height of this content
    */
    init(meta)
    {
        this.ui = meta.ui;

        this.drawWidth = meta.rWidth;
        this.drawHeight = meta.rHeight;

        this.graphics = {};
        this.sprites = {};
        this.texts = {};

        this.opacity = 1;

        this.categories = [
            {"id": this.UI.ItemManager.ITEM_CODES.EQUIPMENT_BODY,        "label": this.UI.LabelManager.translate("Fabr_Manteaux"),  "sprite": "coat"},
            {"id": this.UI.ItemManager.ITEM_CODES.EQUIPMENT_HANDS,       "label": this.UI.LabelManager.translate("Fabr_Gants"),     "sprite": "gloves"},
            {"id": this.UI.ItemManager.ITEM_CODES.EQUIPMENT_FEET,        "label": this.UI.LabelManager.translate("Fabr_Bottes"),    "sprite": "boots"},
            {"id": this.UI.ItemManager.ITEM_CODES.EQUIPMENT_BACKPACK,    "label": this.UI.LabelManager.translate("Fabr_Sacados"),   "sprite": "backpack"},
            {"id": this.UI.ItemManager.ITEM_CODES.EQUIPMENT_TOOL,        "label": this.UI.LabelManager.translate("Fabr_Outils"),    "sprite": "tool"},
            {"id": this.UI.ItemManager.ITEM_CODES.EQUIPMENT_TOY,         "label": this.UI.LabelManager.translate("Fabr_Jouets"),    "sprite": "toy"}
        ];

        return super.init(meta);
    }

    createClosure()
    {
        super.createClosure();
        this.fctOnClick = {};
    }

    destroy(options)
    {
        for(let key in this.graphics)
        {
            this.graphics[key].clear();
            this.graphics[key].off("pointerup", this.fctOnClick[key]);
            this.graphics[key].destroy(options);
        }

        super.destroy(options);
    }

    /*******************************************
    *   BUILD
    *******************************************/
    build()
    {
        super.build();

        for (let row = 0; row < this.RowPerPage; row++)
        {
            for (let col = 0; col < this.CategoryPerRow; col++)
            {
                this.buildCategory(this.categories[row * this.CategoryPerRow + col], col, row);
            }
        }
    }

    clean()
    {
        for(let key in this.graphics)
        {
            this.graphics[key].clear();
        }
        super.clean();
    }

    buildCategory(objDefinition, iCol, iRow)
    {
        if (!this.graphics[objDefinition.id])
        {
            this.fctOnClick[objDefinition.id] = this.onClick.bind(this, objDefinition.id);

            this.graphics[objDefinition.id] = new GraphicsResponsive().init({"ui": this.UI});
            this.graphics[objDefinition.id].interactive = true;
            this.graphics[objDefinition.id].buttonMode = true;
            this.graphics[objDefinition.id].on("pointerup", this.fctOnClick[objDefinition.id]);

            this.addChild(this.graphics[objDefinition.id]);
        }

        let graphics = this.graphics[objDefinition.id];
        graphics.alpha = this.Opacity;

        let width = this.CategoryWidth;
        let height = this.CategoryHeight;
        let paddingHorizontal = this.PaddingHorizontal;
        let paddingVertical = this.PaddingVertical;

        let x = paddingHorizontal / 2 + width * iCol;
        let y = paddingVertical / 2 + height * iRow;

        let bgColor = this.BackgroundColor;

        graphics.beginFill(bgColor, 1);
        graphics.drawRect(x, y, width, height);
        graphics.endFill();

        this.buildSprite(objDefinition, x, y, width, height, graphics);
        this.buildText(objDefinition, x, y, width, height);
    }

    buildSprite(objDefinition, fCategoryX, fCategoryY, fCategoryWidth, fCategoryHeight, graphics)
    {
        if (!this.sprites[objDefinition.id])
        {
            let texture = objDefinition.sprite;
            let visual = Library.getTextureFromAtlas("ui", texture);
            this.sprites[objDefinition.id] = new SpriteResponsive(visual).init({"ui": this.UI});

            this.addChild(this.sprites[objDefinition.id]);
        }

        let width = this.ImageWidth;
        let height = this.ImageHeight;
        let x = fCategoryX + (fCategoryWidth - width) / 2;
        let y = fCategoryY;

        let bgColor = this.ImageBackgroundColor;
        let strokeWidth = this.ImageStrokeWidth;
        let strokeColor = this.ImageStrokeColor;
        let cornerRadius = this.ImageCornerRadius;

        if (strokeWidth > 0)
        {
            graphics.lineStyle({"color":strokeColor, "width": strokeWidth, "alignment": 1});
        }
        graphics.beginFill(bgColor, 1);

        if (cornerRadius > 0)
        {
            graphics.drawRoundedRect(x, y, width, height, cornerRadius);
        }
        else
        {
            graphics.drawRect(x, y, width, height);
        }

        let sprite = this.sprites[objDefinition.id];
        sprite.alpha = this.Opacity;

        let spriteRatio = sprite.width / sprite.height;

        let spriteHeight = height * 0.8;
        let spriteWidth = spriteHeight * spriteRatio;
        let spriteX = x + (width - spriteWidth) / 2;
        let spriteY = y + (height - spriteHeight) / 2;

        sprite.width = spriteWidth;
        sprite.height = spriteHeight;
        sprite.position.set(spriteX, spriteY);
        sprite.tint = this.SpriteTint;
        sprite.alpha = this.Opacity;
    }

    buildText(objDefinition, fCategoryX, fCategoryY, fCategoryWidth, fCategoryHeight)
    {
        if (!this.texts[objDefinition.id])
        {
            this.texts[objDefinition.id] = new TextResponsive().init({"ui": this.UI});
            this.addChild(this.texts[objDefinition.id]);
        }

        let text = this.texts[objDefinition.id];
        text.alpha = this.Opacity;

        let style = new TextStyle({
            fontFamily: this.UI.Values.general.title.font,
            fontSize:   this.evaluate(this.valueFormula(this.UI.Values.general.title.size)),
            fontWeight: this.UI.Values.general.title.weight,
            fill: this.UI.Values.general.text.color,
            align: "center"
        });

        let metrics = TextMetrics.measureText(objDefinition.label, style);

        let x = fCategoryX + fCategoryWidth / 2 - metrics.width / 2;
        let y = fCategoryY + this.ImageHeight + ((fCategoryHeight - this.ImageHeight) / 2) - metrics.height / 2;

        text.text = objDefinition.label;
        text.style = style;
        text.width = metrics.width;
        text.height = metrics.height;
        text.position.set(x, y);
    }

    /*******************************************
    *   EVENTS
    *******************************************/
    onClick(strCategoryId)
    {
        this.emit(this.EVENT_SELECT, strCategoryId);
    }

}