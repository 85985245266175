export default class HackingSequence
{
    constructor(objBalancing)
    {
        this.balancing = objBalancing;
        this.cells = [];
        this.answers = [];
    }

    get Balancing() { return this.balancing; }
    get Cells() { return this.cells; }
    get Answers() { return this.answers; }

    randomize(iRowCount, iColPerRow, iAnswerCount)
    {
        this.cells = [];
        this.answers = [];

        let answerPool = [];
        for (let i = 0; i < iRowCount; i++)
        {
            let row = [];
            for (let j = 0; j < iColPerRow; j++)
            {
                row.push(this.generateCell());
                answerPool.push(i * iColPerRow + j);
            }
            this.cells.push(row);
        }

        for (let i = 0; i < iAnswerCount; i++)
        {
            let index = Math.floor(answerPool.length * Math.random());
            let pos = answerPool.splice(index, 1)[0];

            let row = Math.floor(pos / iColPerRow);
            let col = pos - row * iColPerRow;

            this.answers.push([col, row]);
        }
    }

    generateCell()
    {
        let lineCount = Math.round((this.Balancing.linesPerBlock.max - this.Balancing.linesPerBlock.min) * Math.random()) + this.Balancing.linesPerBlock.min;
        let glowCount = this.Balancing.glowCount;
        let lines = [];

        let lineIndexes = [];
        for (let i = 0; i < lineCount; i++)
        {
            let charCount = Math.round((this.Balancing.charactersPerLine.max - this.Balancing.charactersPerLine.min) *Math.random()) + this.Balancing.charactersPerLine.min;
            let spaceCount = Math.floor(charCount / 6 * Math.random());

            let text = " " + this.generateText(charCount, spaceCount);
            lines.push(text);

            lineIndexes.push(i);
        }

        for (let i = 0; i < glowCount; i++)
        {
            let rand = Math.floor(lineIndexes.length * Math.random());
            let index = lineIndexes.splice(rand, 1)[0];

            lines[index] = (i + 2) + lines[index].substring(1);
        }

        return lines;
    }

    generateText(iCharCount, iSpaceCount)
    {
        let spaces = [];
        let charLeft = iCharCount - iSpaceCount * 2;
        let index = 0;

        for (let i = 0; i < iSpaceCount; i++)
        {
            let count = Math.max(2, Math.floor(charLeft * Math.random()));
            index += count;
            spaces.push(index);
            charLeft -= count;
        }

        let text = "";
        for (let i = 0; i < iCharCount; i++)
        {
            if (spaces.length > 0 && spaces[0] == i)
            {
                spaces.shift();
                text += " ";
            }
            else
            {
                text += (Math.random() >= 0.5 ? "1" : "0");
            }
        }
        return text;
    }
}