
import Mutator from "./Mutator.js";
import Library from "../Library.js";

export default class Constants
{
    constructor ()
    {
        if (Constants.instance)
        {
            throw("Do not instantiate Style directly");
            return;
        }

        Constants.instance = this;
    }

    static init ()
    {
        Constants.instance.initData();
    }

    initData ()
    {
        //All values are based on a width of BASE_WIDTH  (1920px)
        //Loaded from the constants.json file
        this.objStyles = Library.getData("constants");

    }


    static getValue (strKey, strMutator)
    {
        let value =  Constants.instance.objStyles[strKey] !== undefined ? Constants.instance.objStyles[strKey] : null;

        return strMutator ? Mutator.mutate(strMutator, value) : value;
    }


}


let __style = new Constants();