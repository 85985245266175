import ItemManager from "./ItemManager.js";
import ItemCodes from "./codes/ItemCodes.js";
import MutationCodes from "./codes/MutationCodes.js";

export default class CharacterEquipment
{
    constructor(iCharacterId, objInventorySettings)
    {
        this.settings = objInventorySettings;
        this.inventorySize = -1;
        this.energyLoss = -1;
        this.stressLoss = -1;
        this.searchSpeed = -1;
        this.extraMaterialChances = -1;
        this.sprintDuration = -1;
        this.walkSpeed = -1;
        this.maxEnergy = -1;
        this.maxHunger = -1;
        this.maxStress = -1;
        this.sprintCooldown = -1;
        this.powers = null;
        this.invisible = -1;
        this.jump = -1;
        this.push = -1;
        this.spit = -1;
        this.stomp = -1;
        this.swim = -1;
        this.teleport = -1;
        this.powerDuration = -1;
        this.powerCooldown = -1;

        this.equipSlots = {
            "body": ItemCodes.EQUIPMENT_BODY,
            "hands": ItemCodes.EQUIPMENT_HANDS,
            "feet": ItemCodes.EQUIPMENT_FEET,
            "tool": ItemCodes.EQUIPMENT_TOOL,
            "toy": ItemCodes.EQUIPMENT_TOY,
            "mutation_head": MutationCodes.LOCATION_HEAD,
            "mutation_arm": MutationCodes.LOCATION_ARMS,
            "mutation_leg": MutationCodes.LOCATION_LEGS
        };

        this.character = iCharacterId;
        this.calculateMutations();
    }

    //Fishing Rod
    get CanFish() { return (this.ToolEquipment ? this.ToolEquipment.CanFish : false); }
    get FishChances() { return (this.ToolEquipment ? this.ToolEquipment.FishChances : 0); }
    get FishQuantity() { return (this.ToolEquipment ? this.ToolEquipment.FishQuantity : 0); }
    get FishItem() { return (this.ToolEquipment ? this.ToolEquipment.FishItem : null); }

    //Animal Trap
    get CanTrap() { return (this.ToolEquipment ? this.ToolEquipment.CanTrap : false); }

    //Anti-stress tools
    get CanDropStress() { return (this.ToolEquipment ? this.ToolEquipment.CanDropStress : false); }
    get StressDropValue() { return (this.ToolEquipment ? this.ToolEquipment.StressDropValue : false); }

    //Mutation powers
    get CanInvisible() { if (this.invisible === -1) { this.calculatePowers(); } return this.invisible; }
    get CanJump() { if (this.jump === -1) { this.calculatePowers(); } return this.jump; }
    get CanPush() { if (this.push === -1) { this.calculatePowers(); } return this.push; }
    get CanSpit() { if (this.spit === -1) { this.calculatePowers(); } return this.spit; }
    get CanStomp() { if (this.stomp === -1) { this.calculatePowers(); } return this.stomp; }
    get CanSwim() { if (this.swim === -1) { this.calculatePowers(); } return this.swim; }
    get CanTeleport() { if (this.teleport === -1) { this.calculatePowers(); } return this.teleport; }

    get PowerDuration() { if (this.powerDuration === -1) { this.calculatePowers(); } return this.powerDuration; }
    get PowerCooldown() { if (this.powerCooldown === -1) { this.calculatePowers(); } return this.powerCooldown + this.powerDuration; }

    //Stat bonuses
    get EnergyLossBonus() { if (this.energyLoss < 0) { this.calculateEnergyLossBonus(); } return this.energyLoss; }
    get StressLossBonus() { if (this.stressLoss < 0) { this.calculateStressLossBonus(); } return this.stressLoss; }
    get SearchSpeedBonus() { if (this.searchSpeed < 0) { this.calculateSearchSpeedBonus(); } return this.searchSpeed; }
    get ExtraMaterialChancesBonus()
    {
        if (this.extraMaterialChances < 0)
        {
            this.calculateExtraMaterialBonus();
        }
        else
        {

            this.calculateExtraMaterialBonus();
        }
        return this.extraMaterialChances;
    }

    get SprintDurationBonus() { if (this.sprintDuration < 0) { this.calculateSprintDurationBonus(); } return this.sprintDuration; }
    get WalkSpeedBonus() { if (this.walkSpeed < 0) { this.calculateWalkSpeedBonus(); } return this.walkSpeed; }
    get MaxEnergyRatio() { if (this.maxEnergy < 0) { this.calculateMaxEnergyBonus(); } return this.maxEnergy; }
    get MaxHungerRatio() { if (this.maxHunger < 0) { this.calculateMaxHungerBonus(); } return this.maxHunger; }
    get MaxStressRatio() { if (this.maxStress < 0) { this.calculateMaxStressBonus(); } return this.maxStress; }
    get SprintCooldownRatio() { if (this.sprintCooldown < 0) { this.calculateSprintCooldownReduction(); } return this.sprintCooldown; }
    get MutationPowers() { if (this.powers == null) { this.calculatePowers(); } return this.powers; }

    get BodyEquipment() { return this.getEquipment(this.equipSlots.body); }
    get HandsEquipment() { return this.getEquipment(this.equipSlots.hands); }
    get FeetEquipment() { return this.getEquipment(this.equipSlots.feet); }
    get BagEquipment() { return this.getEquipment(this.equipSlots.bag); }
    get ToolEquipment() { return this.getEquipment(this.equipSlots.tool); }
    get ToyEquipment() { return this.getEquipment(this.equipSlots.toy); }

    get MutationHead() { return this.getEquipment(this.equipSlots.mutation_head); }
    get MutationArm() { return this.getEquipment(this.equipSlots.mutation_arm); }
    get MutationLeg() { return this.getEquipment(this.equipSlots.mutation_leg); }

    getEquipment (strLocation)
    {
        let item = ItemManager.instance.SaveManager.getFromSave("equip_" + this.character + "_" + strLocation, null);
        if (item)
        {
            item = ItemManager.instance.getItem(item);
        }
        return item;
    }

    setEquipment (strLocation, objItem)
    {
        let oldItem = null;
        if (objItem && objItem.EquipLocation == strLocation)
        {
            oldItem = this.getEquipment(strLocation);
            ItemManager.instance.SaveManager.setFromSave("equip_" + this.character + "_" + strLocation, objItem.Id);

            this.updateEquipement();
        }
        else if (objItem == null)
        {
            ItemManager.instance.SaveManager.setFromSave("equip_" + this.character + "_" + strLocation, null);
            this.updateEquipement();
        }
        else
        {
            console.warn("You cannot equip a " + objItem.EquipLocation + " equipment to the " + strLocation + " slot!");
            oldItem = false;
        }
        return oldItem;
    }

    updateEquipement ()
    {
        this.calculateEnergyLossBonus();
        this.calculateStressLossBonus();
        this.calculateSearchSpeedBonus();
        this.calculateExtraMaterialBonus();
        this.calculateSprintDurationBonus();
        this.calculateWalkSpeedBonus();
        this.calculateMaxEnergyBonus();
        this.calculateMaxHungerBonus();
        this.calculateMaxStressBonus();
        this.calculateSprintCooldownReduction();
        this.calculatePowers();
    }

    calculateEnergyLossBonus ()
    {
        let energyLoss = 0;
        for (var key in this.equipSlots)
        {
            let item = this.getEquipment(this.equipSlots[key]);
            if (item)
            {
                energyLoss += item.EnergyLossBonus;
            }
        }

        this.energyLoss = energyLoss;
        return energyLoss;
    }

    calculateStressLossBonus ()
    {
        let stressLoss = 0;
        for (var key in this.equipSlots)
        {
            let item = this.getEquipment(this.equipSlots[key]);
            if (item)
            {
                stressLoss += item.StressLossBonus;
            }
        }

        this.stressLoss = stressLoss;
        return stressLoss;
    }

    calculateSearchSpeedBonus ()
    {
        let searchSpeed = 0;
        for (var key in this.equipSlots)
        {
            let item = this.getEquipment(this.equipSlots[key]);
            if (item)
            {
                searchSpeed += item.SearchSpeedBonus;
            }
        }

        this.searchSpeed = searchSpeed;
        return searchSpeed;
    }

    calculateExtraMaterialBonus ()
    {
        let extraMaterial = 0;
        for (var key in this.equipSlots)
        {
            let item = this.getEquipment(this.equipSlots[key]);
            if (item)
            {
                extraMaterial += item.ExtraMaterialBonus;
            }
        }

        this.extraMaterialChances = extraMaterial;
        return extraMaterial;
    }

    calculateSprintDurationBonus ()
    {
        let sprintDuration = 1;
        for (var key in this.equipSlots)
        {
            let item = this.getEquipment(this.equipSlots[key]);
            if (item)
            {
                sprintDuration += item.SprintDurationBonus;
            }
        }

        this.sprintDuration = sprintDuration;
        return sprintDuration;
    }

    calculateWalkSpeedBonus ()
    {
        let walkSpeed = 0;
        for (var key in this.equipSlots)
        {
            let item = this.getEquipment(this.equipSlots[key]);
            if (item)
            {
                walkSpeed += item.WalkSpeedBonus;
            }
        }

        this.walkSpeed = walkSpeed;
        return walkSpeed;
    }

    calculateMaxEnergyBonus ()
    {
        let maxEnergy = 1;
        for (var key in this.equipSlots)
        {
            let item = this.getEquipment(this.equipSlots[key]);
            if (item)
            {
                maxEnergy += item.MaxEnergyBonus;
            }
        }
        this.maxEnergy = maxEnergy;
        return maxEnergy;
    }

    calculateMaxHungerBonus ()
    {
        let maxHunger = 1;
        for (var key in this.equipSlots)
        {
            let item = this.getEquipment(this.equipSlots[key]);
            if (item)
            {
                maxHunger += item.MaxHungerBonus;
            }
        }
        this.maxHunger = maxHunger;
        return maxHunger;
    }

    calculateMaxStressBonus ()
    {
        let maxStress = 1;
        for (var key in this.equipSlots)
        {
            let item = this.getEquipment(this.equipSlots[key]);
            if (item)
            {
                maxStress += item.MaxStressBonus;
            }
        }
        this.maxStress = maxStress;
        return maxStress;
    }

    calculateSprintCooldownReduction ()
    {
        let sprintCooldown = 1;
        for (var key in this.equipSlots)
        {
            let item = this.getEquipment(this.equipSlots[key]);
            if (item)
            {
                sprintCooldown -= item.SprintCooldownReduction;
            }
        }
        sprintCooldown = Math.max(0, sprintCooldown);
        this.sprintCooldown = sprintCooldown;
        return sprintCooldown;
    }

    calculateMutations()
    {
        let build = ItemManager.instance.CharacterManager.getCharacterBuild(this.character);

        let index = parseInt(build[8]);
        if (index > 0)
        {
            this.setEquipment(this.equipSlots.mutation_head, ItemManager.instance.getMutationFromCode(MutationCodes.MUTATION_HEAD[index - 1]));
        }
        index = parseInt(build[9]);
        if (index > 0)
        {
            this.setEquipment(this.equipSlots.mutation_arm, ItemManager.instance.getMutationFromCode(MutationCodes.MUTATION_ARM[index - 1]));
        }
        index = parseInt(build[10]);
        if (index > 0)
        {
            this.setEquipment(this.equipSlots.mutation_leg, ItemManager.instance.getMutationFromCode(MutationCodes.MUTATION_LEGS[index - 1]));
        }

        this.calculatePowers();
    }

    calculatePowers ()
    {
        let powers = [];

        if (this.MutationHead && this.MutationHead.MutationPower)
        {
            let power = this.MutationHead.MutationPower;
            power.code = this.MutationHead.Code;
            powers.push(power);
        }
        if (this.MutationArm && this.MutationArm.MutationPower)
        {
            let power = this.MutationArm.MutationPower;
            power.code = this.MutationArm.Code;
            powers.push(power);
        }
        if (this.MutationLeg && this.MutationLeg.MutationPower)
        {
            let power = this.MutationLeg.MutationPower;
            power.code = this.MutationLeg.Code;
            powers.push(power);
        }

        this.powers = powers;

        this.invisible = false;
        this.jump = false;
        this.push = false;
        this.spit = false;
        this.stomp = false;
        this.swim = false;
        this.teleport = false;
        this.powerDuration = 0;
        this.powerCooldown = 0;

        for (let i = 0; i < powers.length; i++)
        {
            let power = powers[i];
            let t = power.CanTeleport;

            this.invisible = this.invisible || power.CanInvisible;
            this.jump = this.jump || power.CanJump;
            this.push = this.push || power.CanPush;
            this.spit = this.spit || power.CanSpit;
            this.stomp = this.stomp || power.CanStomp;
            this.swim = this.swim || power.CanSwim;
            this.teleport = this.teleport || power.CanTeleport;

            let b = 2;
        }

        return powers;
    }
}