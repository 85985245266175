"use strict";



import LesMutantsSaison2 from "./LesMutantsSaison2.js";
import Console from "./utils/Console.js";



function onReady ()
{
	Console.color("Les Mutants - Saison 2", "Par Les Affranchis", "#000000", "#173e09", "#bcc6bb",  "#FFFFFF", "#000098");
	Console.color("Date de compilation   ", __COMPILATION_DATE__, "#000000", "#f52d14", "#BBBBBB",  "#FFFFFF", "#FFFFFF");//__COMPILATION_DATE__ is generated by webpack at each build

	let canvas2D = document.getElementById("2D");
	let canvas3D = document.getElementById("3D");
	
	window.game = new LesMutantsSaison2(canvas2D, canvas3D);

	//window.addEventListener("keyup", onCheckMemory)

}

function onCheckMemory (e)
{
	let code = e.code;
	console.log(code);

	if (code === "Space")
	{
		if (!window.glMemory)
		{
			let canvas = document.getElementById("2D");
			let ctx = canvas.getContext();

			console.log("canvas", canvas);
			console.log("ctx", ctx);

			window.glMemory = ctx.getExtension('GMAN_webgl_memory');
		}

		const info = window.glMemory.getMemoryInfo();

		console.log(info)
	}
}
document.addEventListener('DOMContentLoaded', onReady);