export default class BaseAI 
{
    constructor()
    {
        
    }

    get DeltaTime() { return this.deltaTime; }
    get SpawnPosition() { return this.spawnPos; }
    get Character() { return this.character; }
    get IsTriggered() { return false; }

    /*******************************************
    *   INITIALIZATION
    *******************************************/
    /**
        Parameters to pass to the init function:
        - character     Character object this AI should have governance over
        - spawnPos      Vector2 containing the world position where the character is supposed to spawn on the map
        - definition    Behaviour definition from the settings.json file (only the sub part of a definition called "ai")

    */
    init(meta)
    {
        this.character = meta.character;
        this.spawnPos = meta.spawnPos;

        this.parseDefinition(meta.definition);

        this.createClosure();
        this.bindEvents();
    }

    destroy()
    {
        this.character = null;
    }

    createClosure()
    {

    }

    bindEvents()
    {

    }

    parseDefinition(objDefinition)
    {
        this.params = {};
    }

    /*******************************************
    *   UPDATE LOOP
    *******************************************/
    update(fDeltaTime)
    {
        this.deltaTime = fDeltaTime;
    }
}