import { Graphics } from '@pixi/graphics';
import { TextStyle } from "@pixi/text";
import { TextMetrics } from "@pixi/text";
import ContainerResponsive from "../../../commons/responsive/ContainerResponsive.js";
import Display from "../../../../utils/Display.js";
import GraphicsResponsive from "../../../commons/responsive/GraphicsResponsive.js";
import TextResponsive from "../../../commons/responsive/TextResponsive.js";

export default class GadgetCell extends ContainerResponsive
{
    constructor()
    {
        super();
    }

    get EVENT_CLICK() { return "self-click"; }

    get CanClick() { return this.canClick; }
    set CanClick(bNewValue)
    { 
        this.canClick = (bNewValue ? true : false);
        if (this.background)
        {
            this.background.buttonMode = this.canClick;
        }
    }

    get Position() { return this.position; }
    get Number() { return this.number; }
    get IsActivated() { return this.number !== null; }
    get IsNumberShown() { return this.numberShown; }
    get IsWrong() { return this.wrongTimeLeft > 0; }

    get BgX() { return 0; }
    get BgY() { return 0; }
    get BgWidth() { return "(" + this.bgWidth + ")"; }
    get BgHeight() { return "(" + this.bgHeight + ")"; }
    get CornerRadius() { return "(" + this.cornerRadius + ")"; }
    get CellMargin() { return "(" + this.BgWidth + "* 0.015)"; }

    get FontColor() { return 0xFFAB36; }
    get FontFamily() { return "Orbitron"; }
    get FontSize() { return this.BgHeight + " * 0.8"; }
    get FontWeight() { return this.UI.Values.general.title.weight; }

    getBackgroundColor(isLightColor)
    {
        if (this.IsWrong)
        {
            return (isLightColor ? 0x97443D : 0x702B25);
        }
        else if (this.IsNumberShown)
        {
            return (isLightColor ? 0x7E5B2F : 0x644B2E);
        }
        else if (this.IsActivated)
        {
            return (isLightColor ? 0x5B4425 : 0x4D3B25);
        }
        return (isLightColor ? 0x43341F : 0x352B1D);
    }

    /*******************************************
    *   INITIALIZATION
    *******************************************/
    /**
        Parameters to pass to the init function:
        - ui:       UI section object where this component resides
        - position: Logical position of this cell on the grid 
        - rWidth:   Responsive equation representing the width of this component
        - rHeight:  Responsive equation representing the height of this component
        - rCorner:  Responsive equation representing the corner radius of this component
    */
    init(meta)
    {
        this.position = meta.position;
        this.bgWidth = meta.rWidth;
        this.bgHeight = meta.rHeight;
        this.cornerRadius = meta.rCorner;

        this.number = null;
        this.numberShown = false;
        this.wrongTimeLeft = 0;

        return super.init(meta);
    }

    createClosure()
    {
        super.createClosure();
        this.fctOnSelfClick = this.onSelfClick.bind(this);
    }

    destroy(options)
    {
        if (this.background)
        {
            this.removeChild(this.background);
            this.background.destroy(options);
        }
        delete this.background;

        if (this.numberText)
        {
            this.removeChild(this.numberText);
            this.numberText.destroy(options);
        }
        delete this.numberText;

        super.destroy(options);
    }

    /*******************************************
    *   BUILD
    *******************************************/
    build()
    {
        super.build();

        this.buildBackground();
        this.buildNumber();
    }

    clean()
    {
        if (this.background)
        {
            this.background.clear();
        }
        super.clean();
    }

    buildBackground()
    {
        if (!this.background)
        {
            this.background = new GraphicsResponsive().init({"ui": this.UI});
            this.background.on("pointerup", this.fctOnSelfClick);
            this.background.cornerMask = new Graphics();
            this.background.mask = this.background.cornerMask;

            this.background.interactive = true;

            this.background.addChild(this.background.cornerMask);
            this.addChild(this.background);
        }

        let graphics = this.background;
        let mask = this.background.cornerMask;

        mask.clear();

        let width = this.evaluate(this.BgWidth, 0, 0, 0, 0);
        let height = this.evaluate(this.BgWidth, 0, 0, 0, 0);
        let cornerRadius = this.evaluate(this.CornerRadius, 0, 0, 0, 0);
        let margin = this.evaluate(this.CellMargin, 0, 0, 0, 0);
        let x = margin / 2;
        let y = margin / 2;
        width -= margin;
        height -= margin;

        let lightColor = this.getBackgroundColor(true);
        let darkColor = this.getBackgroundColor(false);

        mask.beginFill(1, 1);
        mask.drawRoundedRect(x, y, width, height, cornerRadius);
        mask.endFill();

        graphics.beginFill(lightColor);
        graphics.drawRect(x, y, width, height);
        graphics.endFill();

        let stripesCount = 12;
        let stripeHeight = (height * 0.75) / stripesCount;
        let spacing = (height - (stripeHeight * stripesCount)) / stripesCount;

        y -= stripeHeight / 2;

        graphics.beginFill(darkColor);
        for (let i = 0; i <= stripesCount; i++)
        {
            graphics.drawRect(x, y, width, stripeHeight);
            y += stripeHeight + spacing;
        }
        graphics.endFill();
    }

    buildNumber()
    {
        if (!this.numberText)
        {
            this.numberText = new TextResponsive().init({"ui": this.UI});
        }
        this.removeChild(this.numberText);

        if (this.IsNumberShown)
        {
            let text = this.numberText;
            let label = this.number.toString();

            let style = new TextStyle({
                fontFamily: this.FontFamily,
                fontSize:   this.evaluate(this.FontSize, 0, 0, 0, 0),
                fontWeight: this.FontWeight,
                fill: this.FontColor,
                align: "center"
            });

            let metrics = TextMetrics.measureText(label, style);

            let x = this.evaluate(this.BgX + " + " + this.BgWidth + " / 2 - " + metrics.width + " / 2", 0, 0, 0, 0);
            let y = this.evaluate(this.BgY + " + " + this.BgHeight + " / 2 - " + metrics.height + " / 2", 0, 0, 0, 0);

            text.text = label;
            text.style = style;
            text.width = metrics.width;
            text.height = metrics.height;
            text.position.set(x, y);

            this.addChild(this.numberText);
        }
    }

    /*******************************************
    *   UPDATE LOOP
    *******************************************/
    update(fDeltaTime)
    {
        if (this.wrongTimeLeft > 0)
        {
            this.updateWrongDisplay(fDeltaTime);
        }
    }

    updateWrongDisplay(fDeltaTime)
    {
        this.wrongTimeLeft -= fDeltaTime;
        if (this.wrongTimeLeft <= 0)
        {
            this.build();
        }
    }

    /*******************************************
    *   ACTIONS
    *******************************************/
    activate(iNumber)
    {
        this.number = iNumber;
        this.build();
    }

    deactivate()
    {
        this.number = null;
        this.build();
    }

    setWrong(fWrongTime)
    {
        let isWrong = this.IsWrong;
        this.wrongTimeLeft = fWrongTime;

        if (isWrong != this.IsWrong)
        {
            this.build();
        }
    }

    showNumber()
    {
        if (!this.IsNumberShown)
        {
            this.numberShown = true;
            this.build();
        }
    }

    hideNumber()
    {
        if (this.IsNumberShown)
        {
            this.numberShown = false;
            this.build();
        }
    }

    /*******************************************
    *   ACTIONS
    *******************************************/
    onSelfClick()
    {
        if (this.CanClick)
        {
            this.emit(this.EVENT_CLICK, this);
        }
    }
}