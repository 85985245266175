export default class Console
{

    static color (_strTitle, _strText, _strColor1, _strColor2, _strColor3, _strTextColor1 = "#FFFFFF", _strTextColor2 = "#FFFFFF")
    {
        let display = [
            (`\n %c %c %c ${_strTitle}   %c %c %c  ${_strText} %c %c  %c\n`),
            `background: ${_strColor2}; padding:5px 0;`,
            `background: ${_strColor2}; padding:5px 0;`,
            `color: ${_strTextColor1}; background: ${_strColor1}; padding:5px 0;`,
            `background: ${_strColor2}; padding:5px 0;`,
            `background: ${_strColor2}; padding:5px 0;`,
            `background: ${_strColor3}; padding:5px 0;`,
            `background: ${_strColor3}; padding:5px 0;`,
            `background: ${_strColor2}; padding:5px 0;`,
            `color: ${_strTextColor2}; background: #fff; padding:5px 0;` ];

        window.console.log.apply(console, display);

    }
}