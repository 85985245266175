import { EventEmitter } from "@pixi/utils";
import { Loader } from "@pixi/loaders";
import "pixi-tps-loader";
import Library from "../Library.js";
import ProjectLoader from "./Loader.js";

export default class PIXILoader extends EventEmitter
{
    constructor()
    {
        super();
        this.loader = new Loader();
        this.loader.onProgress.add ( (a,b) => this.onProgress(a,b))
        this.loader.onLoad.add ( (a, b) => this.onLoad(a,b))
        this.loader.onStart.add ( (a, b) => this.onStart(a,b))

        this.loader.use(this.parsingMiddleware.bind(this));

        this.loadedCache = {};

       // this.loadAsset("assets", _strWhatToLoad)

    }



    loadAsset(key, url, callback = null)
    {

        this.loader.add(key, url);

        if (callback)
        {
            this.loader.load(callback);
        }
        else
        {
            this.loader.load(this.onAssetsLoaded.bind(this));
        }
    }

    loadGroup (arrAssets, strBasePath = "", group)
    {
        for (let i  = 0; i < arrAssets.length; i++)
        {
            let asset = arrAssets[i];
            let strURL = strBasePath + asset.url;
            let strKey = asset.key;

            if (asset.basepath)
            {
                if (asset.basepath === "direct")
                {
                    strURL = asset.url;
                }
            }
            else if (asset.url.substr(0,5) === "https")
            {
                strURL = asset.url;
            }

            strURL = ProjectLoader.addVersionToURL(strURL);

            this.loader.add(strKey, strURL);
        }


        this.loader.load(this.onGroupLoaded.bind(this, arrAssets, group))
    }

    mergeMultipack (asset, resources, strAnim = "")
    {
        let strNewKey = asset.newKey;
        let strOldKey = asset.key;

        if (!strNewKey)
        {
            strNewKey = strOldKey;
        }

        let bIsAnim = strAnim != "";

        Object.defineProperty(resources, strNewKey,
        Object.getOwnPropertyDescriptor(resources, strOldKey));

        let resource = resources[strOldKey];

        delete resources[strOldKey];
        delete resources[strOldKey + "_image"];

        if (resource.data.meta.related_multi_packs) //there is some related asset to load
        {
            let arrRelateds = resource.data.meta.related_multi_packs;

            for (let i = 0; i < arrRelateds.length; i++)
            {
                let strRelated = arrRelateds[i].replace(".json", "");

                let assets = resources[strRelated];

                let objTextures = assets.textures;
                Object.assign(resource.textures, objTextures);

                if (bIsAnim)
                {
                    let arrAnimations = assets.spritesheet.animations[strAnim];
                    resource.spritesheet.animations[strAnim] = [...resource.spritesheet.animations[strAnim], ...arrAnimations];

                    let objTextures = assets.spritesheet.textures;
                    Object.assign(resource.spritesheet.textures, objTextures);

                    let objFrames = assets.spritesheet._frames;
                    Object.assign(resource.spritesheet._frames, objFrames);

                    let framesKeys = assets.spritesheet._frameKeys;
                    resource.spritesheet._frameKeys = [...resource.spritesheet._frameKeys, ...framesKeys]
                }

                delete resources[strRelated];
                delete resources[strRelated + "_image"];
            }
        }

        resources[strNewKey] = resource;
    }


    onAssetsLoaded (loader, resources)
    {

        
        this.resources = resources;
        this.arrGroups = ["images","atlas","data"];


        this.loadGroup();
    }

    onGroupLoadedAction (arrAssets, resources)
    {
        for (let i = 0; i < arrAssets.length; i++)
        {
            let asset = arrAssets[i];

            if (asset.type)
            {
                if (asset.type === "multipack")
                {
                    this.mergeMultipack (asset, resources);
                }
                else if (asset.type === "multipack-animation")
                {
                   this.mergeMultipack (asset, resources, asset.anim);
                }
            }
        }
    }

    onGroupLoaded (arrAssets, group, loader, resources)
    {
        this.onGroupLoadedAction(arrAssets, resources);


        Library.setResources(resources);
        this.emit("group-loaded", resources, group);


    }

    onStart (loader)
    {
        this.emit("start-load-asset")
    }

    onLoad (loader, loaded)
    {
        this.emit("end-load-asset")

    }

    onProgress (loader)
    {
        
    }

    parsingMiddleware(resource, next)
    {
        for (let key in this.loader.resources)
        {
            let res = this.loader.resources[key];
            if (res.extension == "json")
            {
                if (res.data && res.data.frames && Array.isArray(res.data.frames))
                {
                    //Setting the filenames as indexes for ThreeJS spritesheets loaded in PIXI.js
                    let newFrames = {};
                    for (let i = 0; i < res.data.frames.length; i++)
                    {
                        newFrames[res.data.frames[i].filename] = res.data.frames[i];
                    }
                    this.loader.resources[key].data.frames = newFrames;

                    //Ajusting the multipack to prevent duplicates when the files are referencing each others
                    if (res.data.meta.related_multi_packs)
                    {
                        let newMultipack = [];
                        for (let i = 0; i < res.data.meta.related_multi_packs.length; i++)
                        {
                            let filename = res.data.meta.related_multi_packs[i].replace(/\.[^/.]+$/, "");

                            let isOk = true;
                            for (let r in this.loader.resources)
                            {
                                let resObj = this.loader.resources[r];
                                if (resObj.data && resObj.data.meta && resObj.data.meta.image.replace(/\.[^/.]+$/, "") == filename)
                                {
                                    isOk = false;
                                    break;
                                }
                            }

                            if (isOk)
                            {
                                newMultipack.push(res.data.meta.related_multi_packs[i]);
                            }
                        }
                        this.loader.resources[key].data.meta.related_multi_packs = newMultipack
                    }
                }
            }
        }

        next();
    }
}