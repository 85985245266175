import { string2hex }  from "@pixi/utils"; 
import { Graphics } from '@pixi/graphics';
import { Point } from '@pixi/math';
import { Rectangle } from '@pixi/math';
import BaseMinigame from "../BaseMinigame.js";
import GraphicsResponsive from "../../commons/responsive/GraphicsResponsive.js";
import Library from "../../../Library.js";
import SpriteResponsive from "../../commons/responsive/SpriteResponsive.js";
import UnlockControls from "./components/UnlockControls.js";
import UnlockPath from "./components/UnlockPath.js";

export default class UnlockMinigame extends BaseMinigame
{


    get GRID_WIDTH() { return 6; }
    get GRID_HEIGHT() { return 6; }
    get MIN_CIRCLE_COUNT() { return 8; }
    get MAX_CIRCLE_COUNT() { return 16; }

    get Id() { return UnlockMinigame.TYPE_UNLOCK;}
    get PinsDefinition() { return this.UI.UIManager.MinigameValues[this.Id].pins;}
    get Patterns() { return this.balancing.patterns;}
    get Controls() { return this.controls; }
    get CellHover() { return this.Controls.CellHover; }

    get Cells() { return this.Controls.Cells; }
    set Cells(arrNewValue) { this.Controls.Cells = arrNewValue; }

    get GridWidth() { return "(" + this.BgWidth + " * 0.8)"; }
    get GridHeight() { return "(" + this.BgHeight + " * 0.8)"; }
    get GridX() { return "(" + this.BgX + " + (" + this.BgWidth + " - " + this.GridWidth + ") / 2)";}
    get GridY() { return "(" + this.BgY + " + (" + this.BgHeight + " - " + this.GridHeight + ") / 2)";}
    get GridCornerRadius() { return "(ih * 0.01)"; }
    get GridStrokeWidth() { return "(ih * 0.003)"; }

    get GridCellWidth() { return "(" + this.GridWidth + "/" + this.GRID_WIDTH + ")"; }
    get GridCellHeight() { return "(" + this.GridWidth + "/" + this.GRID_HEIGHT + ")"; }

    get GridCircleSize() { return this.GridCellWidth + " * 0.15"; }

    get CloseButtonX() { return this.BgX + " + " + this.BgWidth + " * 0.75"; }
    get CloseButtonY() { return (this.BgY + " - " + this.closeButton.ButtonHeight + " / 2.5"); }

    get TimerY() { return "(" + this.BgY + " - " + this.TimerHeight + " * 0.4)"; }

    get GridColorLight() { return 0x464646; }
    get GridColorDark() { return 0x242627; }
    get GridCircleColor() { return 0x1f2124; }
    get CellHoverColor() { return 0x464646; }
    get CellHoverOpacity() { return 0.35; }

    /*******************************************
    *   INITIALIZATION
    *******************************************/
    /**
        Parameters to pass to the init function:
        - ui:           UI section object where this component resides
        - difficulty:   Difficulty to set the game to. The value should fit the difficulty levels set in the settings
        - showOnStart   (Optional) If the popup should be shown on start. Default is TRUE
        - createOverlay (Optional) If this popup should create it's own overlay for itself. If not, it will use the general UI overlay. Default is FALSE
        - linked        (Optional) Linked popup to this one. If this one closes, the linked popup will close too. Default is NULL
    */
    init(meta)
    {
        this.ui = meta.ui;
        this.patterns = {};
        this.picked = [];
        this.cellHover = null;
        this.paths = [];
        this.tmpPath = null;
        this.isPointer = false;
        this.actionDelay = null;

        this.initControls();

        super.init(meta);

        this.selectPattern();

        this.build();

        this.showMessage(
            this.UI.LabelManager.translate("MINIGAME_UNLOCK_INTRO"),
            this.UI.LabelManager.translate("MINIGAME_BTN_START")
        );

        return this;
    }

    initControls()
    {
        this.controls = new UnlockControls().init({"ui": this.UI});

        this.fctOnCellHoverChanged = this.onCellHoverChanged.bind(this);
        this.fctOnPathStart = this.onPathStart.bind(this);
        this.fctOnPathMove = this.onPathMove.bind(this);
        this.fctOnPathEnd = this.onPathEnd.bind(this);

        this.Controls.on(this.Controls.EVENT_CELL_HOVER_CHANGED, this.fctOnCellHoverChanged);
        this.Controls.on(this.Controls.EVENT_PATH_START, this.fctOnPathStart);
        this.Controls.on(this.Controls.EVENT_PATH_MOVE, this.fctOnPathMove);
        this.Controls.on(this.Controls.EVENT_PATH_END, this.fctOnPathEnd);

        this.controls.start();
    }

    createClosure()
    {
        super.createClosure();
        this.fctUpdate = this.update.bind(this);
    }

    bindEvents()
    {
        super.bindEvents();
        this.UI.GameManager.on(this.UI.GameManager.EVENT_UPDATE, this.fctUpdate);
    }

    destroy(options)
    {
        this.UI.GameManager.off(this.UI.GameManager.EVENT_UPDATE, this.fctUpdate);

        this.controls.off(this.Controls.EVENT_CELL_HOVER_CHANGED, this.fctOnCellHoverChanged);
        this.controls.off(this.Controls.EVENT_PATH_START, this.fctOnPathStart);
        this.controls.off(this.Controls.EVENT_PATH_MOVE, this.fctOnPathMove);
        this.controls.off(this.Controls.EVENT_PATH_END, this.fctOnPathEnd);

        this.controls.destroy(options);
        delete this.controls;

        if (this.isPointer)
        {
            document.body.style.cursor = "default";
        }

        super.destroy(options);
    }

    /*******************************************
    *   BUILD
    *******************************************/
    build()
    {
        super.build();

        this.buildBackgroundComponents();
        this.buildGrid();
        this.buildPaths();
        this.buildPins();
    }

    buildBackgroundComponents()
    {
        this.buildSideWires();
        this.buildResistor(true);
        this.buildResistor(false);
        this.buildCapacitor();
    }

    buildSideWires()
    {
        if (!this.sprites.sideWires)
        {
            let texture = Library.getTextureFromAtlas(this.AtlasId, this.Id + "_component_2.png");
            this.sprites.sideWires = new SpriteResponsive(texture).init({"ui": this.UI});

            this.sprites.sideWires.ratio = texture.orig.width / texture.orig.height;

            this.addChild(this.sprites.sideWires);
        }

        let sprite = this.sprites.sideWires;

        let bgX = this.evaluate(this.BgX, 0, 0, 0, 0);
        let bgY = this.evaluate(this.BgY, 0, 0, 0, 0);
        let bgWidth = this.evaluate(this.BgWidth, 0, 0, 0, 0);
        let bgHeight = this.evaluate(this.BgHeight, 0, 0, 0, 0);

        let height = bgHeight * 0.275;
        let width = height * sprite.ratio;

        let x = bgX + bgWidth - width * 0.4;
        let y = bgY + bgHeight * 0.05;

        sprite.width = width;
        sprite.height = height;
        sprite.x = x;
        sprite.y = y;
    }

    buildResistor(bIsLeft)
    {
        let key = (bIsLeft ? "left" : "right") + "Resistor";
        if (!this.sprites[key])
        {
            let texture = Library.getTextureFromAtlas(this.AtlasId, this.Id + "_component_3.png");
            this.sprites[key] = new SpriteResponsive(texture).init({"ui": this.UI});

            this.sprites[key].ratio = texture.orig.width / texture.orig.height;

            this.addChild(this.sprites[key]);
        }

        let sprite = this.sprites[key];
        sprite.scale.x = (bIsLeft ? 1 : -1);

        let bgX = this.evaluate(this.BgX, 0, 0, 0, 0);
        let bgY = this.evaluate(this.BgY, 0, 0, 0, 0);
        let bgWidth = this.evaluate(this.BgWidth, 0, 0, 0, 0);
        let bgHeight = this.evaluate(this.BgHeight, 0, 0, 0, 0);

        let height = bgHeight * 0.15;
        let width = height * sprite.ratio;

        let x = bgX + (bIsLeft ? bgWidth * 0.025 - width : bgWidth * 0.97 + width);
        let y = bgY + bgHeight * (bIsLeft ? 0.275 : 0.55);

        sprite.width = width;
        sprite.height = height;
        sprite.x = x;
        sprite.y = y;
    }

    buildCapacitor()
    {
        if (!this.sprites.capacitor)
        {
            let texture = Library.getTextureFromAtlas(this.AtlasId, this.Id + "_component_1.png");
            this.sprites.capacitor = new SpriteResponsive(texture).init({"ui": this.UI});

            this.sprites.capacitor.ratio = texture.orig.width / texture.orig.height;

            this.addChild(this.sprites.capacitor);
        }

        let sprite = this.sprites.capacitor;

        let bgX = this.evaluate(this.BgX, 0, 0, 0, 0);
        let bgY = this.evaluate(this.BgY, 0, 0, 0, 0);
        let bgWidth = this.evaluate(this.BgWidth, 0, 0, 0, 0);
        let bgHeight = this.evaluate(this.BgHeight, 0, 0, 0, 0);

        let height = bgHeight * 0.0675;
        let width = height * sprite.ratio;

        let x = bgX + bgWidth * 0.015 - width;
        let y = bgY + bgHeight * 0.575;

        sprite.width = width;
        sprite.height = height;
        sprite.x = x;
        sprite.y = y;
    }

    buildGrid()
    {
        if (!this.graphics.gridBack)
        {
            this.graphics.gridBack = new GraphicsResponsive().init({"ui": this.UI});
            this.graphics.gridBack.cornerMask = new Graphics();
            this.graphics.gridBack.mask = this.graphics.gridBack.cornerMask;

            this.graphics.gridBack.addChild(this.graphics.gridBack.cornerMask);
            this.addChild(this.graphics.gridBack);
        }

        if (!this.graphics.grid)
        {
            this.graphics.grid = new GraphicsResponsive().init({"ui": this.UI});
            this.addChild(this.graphics.grid);
        }

        let graphics = this.graphics.grid;
        let mask = this.graphics.gridBack.cornerMask;

        mask.clear();

        let x = this.evaluate(this.GridX, 0, 0, 0, 0);
        let y = this.evaluate(this.GridY, 0, 0, 0, 0);
        let width = this.evaluate(this.GridWidth, 0, 0, 0, 0);
        let height = this.evaluate(this.GridHeight, 0, 0, 0, 0);
        let corner = this.evaluate(this.GridCornerRadius, 0, 0, 0, 0);
        let strokeWidth = this.evaluate(this.GridStrokeWidth, 0, 0, 0, 0);

        let cellWidth = this.evaluate(this.GridCellWidth, 0, 0, 0, 0);
        let cellHeight = this.evaluate(this.GridCellHeight, 0, 0, 0, 0);

        let circleSize = this.evaluate(this.GridCircleSize, 0, 0, 0, 0);

        let colorLight = this.GridColorLight;
        let colorDark = this.GridColorDark;

        for (let i = 0; i < this.GRID_HEIGHT; i++)
        {
            let row = (this.Cells.length <= i ? [] : this.Cells[i]);
            for (let j = 0; j < this.GRID_WIDTH; j++)
            {
                //Generating the cell rect on screen for the game controls
                let cellData = (this.Cells.length > i ? this.Cells[i][j] : {"pin": null, "position": new Point(j, i)});
                cellData.rect = new Rectangle(
                    x + cellWidth * j,
                    y + cellHeight * i,
                    cellWidth,
                    cellHeight
                );
                if (this.Cells.length <= i)
                {
                    row.push(cellData);
                }

                //Drawing the vertical lines dark of the grid
                if (i == 0)
                {
                    graphics.beginFill(colorDark, 1);
                    graphics.drawRect(x + cellWidth * (j + 0.5) - strokeWidth / 2, y, strokeWidth, height);
                    graphics.endFill();
                }
            }

            if (this.Cells.length <= i)
            {
                this.Cells.push(row);
            }

            //Drawing the horizontal dark lines of the grid
            graphics.beginFill(colorDark, 1);
            graphics.drawRect(x, y + cellHeight * (i + 0.5) - strokeWidth / 2, width, strokeWidth);
            graphics.endFill();
        }

        for (let i = 0; i < this.GRID_HEIGHT; i++)
        {
            for (let j = 0; j < this.GRID_WIDTH; j++)
            {
                //Drawing the vertical light lines of the grid
                if (i == 0 && j > 0)
                {
                    graphics.beginFill(colorLight, 1);
                    graphics.drawRect(x + cellWidth * j - strokeWidth / 2, y, strokeWidth, height);
                    graphics.endFill();
                }
            }

            //Drawing the horizontal light lines of the grid
            if (i > 0)
            {
                graphics.beginFill(colorLight, 1);
                graphics.drawRect(x, y + cellHeight * i - strokeWidth / 2, width, strokeWidth);
                graphics.endFill();
            }
        }

        //Drawing the grid outer frame
        graphics.lineStyle({"color":colorLight, "width":strokeWidth * 1.5, "alignment":0.5});
        graphics.drawRoundedRect(x, y, width, height, corner);

        mask.beginFill(0, 1);
        mask.drawRoundedRect(x, y, width, height, corner);
        mask.endFill();

        //Drawing random circle on cell corners
        let choices = [];
        for (let i = 0; i < (this.GRID_WIDTH + 1) * (this.GRID_HEIGHT + 1) - 1; i++)
        {
            //We try to avoid drawing a circle in the grids rounded corners
            if (i != 0 && i != this.GRID_WIDTH && i != (this.GRID_WIDTH) * (this.GRID_HEIGHT) + 1)
            {
                choices.push(i);
            }
        }
        if (!this.circlePlacements)
        {
            this.circleAmount = Math.floor((this.MAX_CIRCLE_COUNT - this.MIN_CIRCLE_COUNT) * Math.random()) + this.MIN_CIRCLE_COUNT;
            this.circlePlacements = [];

            for (let i = 0; i < this.circleAmount; i++)
            {
                let index = Math.floor(choices.length * Math.random());
                this.circlePlacements.push(choices[index]);

                choices.splice(index, 1);
            }
        }
        graphics.beginFill(this.GridCircleColor, 1);
        for (let i = 0; i < this.circlePlacements.length; i++)
        {
            let pos = this.circlePlacements[i];
            let y = Math.floor(pos / (this.GRID_HEIGHT + 1));
            let x = pos - y * (this.GRID_HEIGHT + 1);

            let rect = this.Cells[(y >= this.GRID_HEIGHT) ? this.GRID_HEIGHT - 1 : y][(x >= this.GRID_WIDTH) ? this.GRID_WIDTH - 1 : x].rect;
            x = rect.x + (x >= this.GRID_WIDTH ? rect.width : 0);
            y = rect.y + (y >= this.GRID_HEIGHT ? rect.height : 0);

            graphics.drawCircle(x, y, circleSize / 2);
        }
        graphics.endFill();

        graphics.lineStyle(null);
        this.buildCellHover();
    }

    buildPaths()
    {
        if (!this.graphics.paths)
        {
            this.graphics.paths = new GraphicsResponsive().init({"ui": this.UI});
            this.addChild(this.graphics.paths);
        }

        let graphics = this.graphics.paths;
        graphics.clear();

        for (let i = 0; i < this.paths.length; i++)
        {
            this.paths[i].buildPath(this.Cells);
        }

        if (this.tmpPath)
        {
            this.tmpPath.buildPath(this.Cells);
        }
    }

    buildPins()
    {
        let maxPinCount = 0;

        for (let pinId in this.pattern)
        {
            if (maxPinCount < this.pattern[pinId].length)
            {
                maxPinCount = this.pattern[pinId].length;
            }

            for (let i = 0; i < this.pattern[pinId].length; i++)
            {
                let def = this.PinsDefinition[pinId];
                let key = "pin_" + pinId + "_" + i;

                if (!this.sprites[key])
                {
                    let texture = Library.getTextureFromAtlas(this.AtlasId, def.frame);
                    this.sprites[key] = new SpriteResponsive(texture).init({"ui": this.UI});
                    this.sprites[key].ratio = texture.orig.width / texture.orig.height;

                    this.addChild(this.sprites[key])
                }

                let sprite = this.sprites[key];
                let cell = this.Cells[this.pattern[pinId][i][1]][this.pattern[pinId][i][0]];

                let height = cell.rect.height * 0.75;
                let width = height * sprite.ratio;
                let x = cell.rect.x + cell.rect.width / 2 - width / 2;
                let y = cell.rect.y + cell.rect.height / 2 - height / 2;

                sprite.width = width;
                sprite.height = height;
                sprite.x = x;
                sprite.y = y;
            }

        }

        for (let pinId in this.PinsDefinition)
        {
            for (let i = 0; i < maxPinCount; i++)
            {
                let key = "pin_" + pinId + "_" + i;
                if (!(pinId in this.pattern) && this.sprites[key] && this.sprites[key].parent)
                {
                    this.removeChild(this.sprites[key]);
                }
            }
        }
    }

    buildCellHover()
    {
        if (this.graphics.gridBack)
        {
            let graphics = this.graphics.gridBack;
            graphics.clear();

            if (this.CellHover)
            {
                let rect = this.CellHover.rect;

                graphics.beginFill(this.CellHoverColor, this.CellHoverOpacity);
                graphics.drawRect(rect.x, rect.y, rect.width, rect.height);
                graphics.endFill();
            }
        }
    }

    /*******************************************
    *   ACTIONS
    *******************************************/
    selectPattern()
    {
        let choices = [];
        for (let i = 0; i < this.Patterns.length; i++)
        {
            if (!this.picked.includes(i))
            {
                choices.push(i);
            }
        }

        let index = choices[Math.floor(choices.length * Math.random())];

        this.pattern = this.Patterns[index];
        this.picked.push(index);

        for (let i = 0; i < this.Cells.length; i++)
        {
            for (let j = 0; j < this.Cells[i].length; j++)
            {
                this.Cells[i][j].pin = null;
            }
        }

        for (let pinId in this.pattern)
        {
            for (let i = 0; i < this.pattern[pinId].length; i++)
            {
                this.Cells[this.pattern[pinId][i][1]][this.pattern[pinId][i][0]].pin = pinId;
            }
        }
    }

    startPath(cell)
    {
        let def = this.PinsDefinition[cell.pin];
        this.tmpPath = new UnlockPath().init({
            "ui": this.UI,
            "id": cell.pin,
            "graphics": this.graphics.paths,
            "color": string2hex(def.color)
        });

        this.tmpPath.addCell(cell.position.x, cell.position.y);

        for (let i = 0; i < this.paths.length; i++)
        {
            if (this.paths[i].Id == cell.pin)
            {
                this.paths[i].hide();
            }
        }

        this.buildPaths();
    }

    movePath(cell)
    {
        if (this.tmpPath)
        {
            let lastPos = this.tmpPath.Path[this.tmpPath.Path.length - 1];
            let firstPos = this.tmpPath.Path[0];

            if (
                (Math.abs(lastPos.x - cell.position.x) + Math.abs(lastPos.y - cell.position.y) <= 1) && 
                (!cell.pin || cell.pin == this.tmpPath.Id) && 
                (cell.position.x != firstPos.x || cell.position.y != firstPos.y || this.tmpPath.Path.length <= 2)
            )
            {
                if (this.tmpPath.Path.length > 2 && this.Cells[lastPos.y][lastPos.x].pin)
                {
                    let prevLastPos = this.tmpPath.Path[this.tmpPath.Path.length - 2];
                    if (prevLastPos.x == cell.position.x && prevLastPos.y == cell.position.y)
                    {
                        this.tmpPath.addCell(cell.position.x, cell.position.y);
                    }
                }
                else
                {
                    this.tmpPath.addCell(cell.position.x, cell.position.y);
                }
            }
        }

        for (let j = 0; j < this.paths.length; j++)
        {
            let show = true;
            for (let i = 0; i < this.tmpPath.Path.length; i++)
            {
                if (this.paths[j].containsCell(this.tmpPath.Path[i].x, this.tmpPath.Path[i].y) || (this.paths[j].Id == this.tmpPath.Id))
                {
                    show = false;
                    break;
                }
            }

            if (show)
            {
                this.paths[j].show();
            }
            else
            {
                this.paths[j].hide();
            }
        }

        this.buildPaths();
    }

    endPath()
    {
        let firstCell = this.Cells[this.tmpPath.Path[0].y][this.tmpPath.Path[0].x];

        let lastPos = this.tmpPath.Path[this.tmpPath.Path.length - 1];
        let lastCell = this.Cells[lastPos.y][lastPos.x];

        if (lastCell.pin == this.tmpPath.Id && 
            firstCell.pin == this.tmpPath.Id && 
            (firstCell.position.x != lastCell.position.x || firstCell.position.y != lastCell.position.y) &&
            this.tmpPath.Path.length > 1)
        {
            for (let i = this.paths.length - 1; i >= 0; i--)
            {
                if (this.paths[i].Id == lastCell.pin || !this.paths[i].IsShown)
                {
                    this.paths.splice(i, 1);
                }
            }

            this.paths.push(this.tmpPath);
        }
        else
        {
            for (let i = this.paths.length - 1; i >= 0; i--)
            {
                if (this.paths[i].Id == this.tmpPath.Id)
                {
                    this.paths.splice(i, 1);
                }
                else if (!this.paths[i].IsShown)
                {
                    this.paths[i].show();
                }
            }
        }

        this.tmpPath = null;
        this.buildPaths();

        let isWin = true;
        for (let pinId in this.pattern)
        {
            let isIn = false;
            for (let i = 0; i < this.paths.length; i++)
            {
                if (this.paths[i].Id == pinId)
                {
                    isIn = true;
                    break;
                }
            }
            if (!isIn)
            {
                isWin = false;
                break;
            }
        }

        if (isWin)
        {
            if (this.Balancing.patternCount <= this.picked.length)
            {
                this.winGame();
            }
            else
            {
                this.timer.pauseTimer();
                this.Controls.stop();

                this.actionDelay = {
                    "timeLeft": 1,
                    "fct": function()
                    {
                        this.paths = [];
                        this.selectPattern();

                        this.build();

                        this.actionDelay = {
                            "timeLeft": 0.5,
                            "fct": function()
                            {
                                this.timer.resumeTimer();
                                this.Controls.start();
                            }
                            .bind(this)
                        };
                    }
                    .bind(this)
                };
            }
        }
    }

    startPlay()
    {
        this.timer.startTimer(this.TotalTime);
        this.Controls.start();
    }

    winGame()
    {
        this.Controls.stop();

        this.success = true;
        this.actionDelay = {
            "fct": this.onCloseClick.bind(this),
            "timeLeft": 1
        };
    }

    looseGame()
    {
        this.Controls.stop();

        this.graphics.grid.alpha = 0.5;
        for (let id in this.sprites)
        {
            this.sprites[id].tint = 0x999999;
        }

        this.actionDelay = {
            "fct": this.onCloseClick.bind(this),
            "timeLeft": 1
        };
    }

    /*******************************************
    *   UPDATE LOOP
    *******************************************/
    update(fDeltaTime)
    {
        super.update(fDeltaTime);
        this.updateActionDelay(fDeltaTime);
    }

    updateActionDelay(fDeltaTime)
    {
        if (this.actionDelay && this.actionDelay.timeLeft > 0)
        {
            this.actionDelay.timeLeft -= fDeltaTime;
            if (this.actionDelay.timeLeft <= 0)
            {
                this.actionDelay.fct();
            }
        }
    }

    /*******************************************
    *   EVENTS
    *******************************************/
    onCellHoverChanged(objCell)
    {
        this.buildCellHover();

        let isPointer = (this.tmpPath ? true : false);
        if (this.CellHover)
        {
            if (this.CellHover.pin)
            {
                isPointer = true;
            }
        }

        this.isPointer = isPointer;
        document.body.style.cursor = (isPointer ? "pointer": "default");
    }

    onPathStart(cell)
    {
        this.startPath(cell);
    }

    onPathMove(cell)
    {
        this.movePath(cell);
    }

    onPathEnd()
    {
        this.endPath();
    }

    onMessageButtonClick()
    {
        super.onMessageButtonClick();
        this.startPlay();
    }

    onTimerEnd(sender)
    {
        super.onTimerEnd();
        this.looseGame();
    }
}