export default class Lerp
{
    static lerpColorHex(a, b, amount)
    {
        let ah = a,
            ar = ah >> 16,
            ag = ah >> 8 & 0xff,
            ab = ah & 0xff,

            bh = b,
            br = bh >> 16,
            bg = bh >> 8 & 0xff,
            bb = bh & 0xff,

            rr = ar + amount * (br - ar),
            rg = ag + amount * (bg - ag),
            rb = ab + amount * (bb - ab);

        return parseInt("0x" + ((1 << 24) + (rr << 16) + (rg << 8) + rb | 0).toString(16).slice(1));
    }

    static lerp (fStart, fEnd, fAmount)
    {
        fAmount = Math.max(0, Math.min(1, fAmount));
        return (1 - fAmount) * fStart + fAmount * fEnd;
    }
}