/**
 * Respect is a HTML5 Responsive Helper class. It can be used "manually" but it is meant to be used with RespectEditor.
 *
 * @author: Martin Tremblay - martin@lesaffranchis.ca
 */
import EventEmitter from "eventemitter3";

export default class Respect extends EventEmitter
{
    constructor ()
    {
        if (Respect.instance)
        {
            throw("Don't instantiate Respect");
        }

        super();

        Respect.instance = this;

        Respect.a11yMultiplier = 1;

        window.onresize = () => this.onResize();

        this.arrResponsives = [];
    }

    addNewResponsive (obj)
    {
        this.arrResponsives.push(obj);
    }

    onResize ()
    {
        for (let i = 0; i < this.arrResponsives.length; i++)
        {
            let obj = this.arrResponsives[i];

            if (obj.callback)
            {
                obj.callback(obj.display, obj.responsive);
            }

        }
    }


    static addResponsive (display, responsive, callback)
    {
        let obj = {display, responsive, callback};
        Respect.instance.addNewResponsive(obj);
    }
}


let __respect__ = new Respect();