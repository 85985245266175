import { Sprite } from "@pixi/sprite";
import BaseParser from "./BaseParser.js";
import Library from "../../../Library.js";

export default class OutdoorImageParser extends BaseParser
{
    constructor(objPixiApp)
    {
        super();
        this.pixiApp = objPixiApp;
    }

    /*******************************************
    *   INITIALIZATION
    *******************************************/
    /**
        Parameters to pass to the init function:
        - width         Width of the map grid
        - enemies       List of enemy definitions, obtainable from the settings
        - items         List of item definitions, obtainable from the settings
        - dependencies  DependencyContainer instance
    */
    init(meta)
    {
        super.init(meta);

        //this.data = [];
        this.json_data = [];
        //this.fctFind = (index) => (ob) => ob.p === index;
        this.locations = [];
        this.mapGridWidth = meta.width;

        this.createIndexCatalog(meta.enemies, meta.items);

        return this;
    }

    createIndexCatalog(enemies, items)
    {
        this.catalog = {};

        let toSearch = {};
        toSearch[this.TYPE_ENEMY] = enemies;
        toSearch[this.TYPE_ITEM] = items;

        for (let type in toSearch)
        {
            for (let id in toSearch[type])
            {
                if (toSearch[type][id].map && "index" in toSearch[type][id].map)
                {
                    this.catalog[toSearch[type][id].map.index] = {
                        "type": type,
                        "id":   id,
                        "data": toSearch[type][id]
                    };
                }
            }
        }
    }

    /*******************************************
    *   CALCULATIONS
    *******************************************/
    /**
        Retrives the content that should be spawned at a specific tile
        @param iX   X position of the tile (in grid coordinates)
        @param iY   Y position of the tile (in grid coordinates)
        @param type (Optional) Type of content that should be retrieved. If set, it converts the data in the right 
                    object instance. Otherwise it returns the raw data. The value to use should be the constants
                    defined in this class (TYPE_ITEM and TYPE_ENEMY) Default is NULL
        @return     Data found or returns NULL if nothing is on the tile
    */
    getAt(iX, iY, type = null)
    {   
        let worldIndex = (iY * this.mapGridWidth + iX);
        //let index = worldIndex* 4;
        let index = worldIndex;

        let cell = this.WorldManager.getCellContent(worldIndex);

        //if something is one the floor, it more important than what in the data
        if (cell)
        {
            let arrCatalog = Object.values(this.catalog); //why is this.catalog a fucking Object!!!
            let item = arrCatalog.find( i => i.id === cell.id);

            if (item)
            {
                return item;
            }

        }

        let valueIndex = this.json_data[index];
        //if (this.data[index + 3])
        if (valueIndex)
        {
            //let pixelIndex = 255-this.data[index + 2];
            let pixelIndex = 255- valueIndex.i;
            if (this.catalog[pixelIndex])
            {
                if (type && type == this.TYPE_ITEM)
                {
                    return this.ItemManager.getItem(this.catalog[pixelIndex].id)
                }
                else
                {
                    return this.catalog[pixelIndex];
                }
            }
        }

        return null;
    }


    /**
        Gets the closest distance of an object from a specific point on the grid
        @param iX           X position of the start point on the grid to begin searching
        @param iY           Y position of the start point on the grid to begin searching
        @param strObjectId  Id of the object to look for

        @return             Distance of the closest object in grid tiles. Returns -1 if the object couldn't be found
    */
    getClosestObjectDistance(iX, iY, strObjectId)
    {
        for (let col = 0; col < this.mapGridWidth; col++)
        {
            for (let row = 0; row < this.mapGridWidth; row++)
            {
                if (col != 0 || row != 0)
                {
                    for (let i = 0; i < 2; i++)
                    {
                        let x = Math.min(this.mapGridWidth - 1, iX + col * (i < 1 ? -1 : 1));
                        let y = Math.min(this.mapGridWidth - 1, iY + row * (i < 1 ? -1 : 1));

                        //let index = (y * this.mapGridWidth + x) * 4;
                        let index = (y * this.mapGridWidth + x);
                        let valueIndex = this.json_data[index];

                        //if (this.data[index + 3])
                        if (valueIndex)
                        {
                           // let pixelIndex = 255-this.data[index + 2];
                            let pixelIndex = 255 - valueIndex.i;
                            if (this.catalog[pixelIndex] && this.catalog[pixelIndex].id == strObjectId)
                            {
                                return Math.sqrt(Math.pow(iX - x, 2) + Math.pow(iY - y, 2));
                            }
                        }
                    }
                }
            }
        }

        return -1;
    }

    /*******************************************
    *   DATA PARSING
    *******************************************/
    parseImage(texture)
    {
        //let container = new Sprite(texture);
        //let e = this.pixiApp.renderer.plugins.extract;

       // this.data = e.pixels(container);

        const data = Library.getData("map_obstacles_data");

        for (let i = 0; i < data.length; i++)
        {
            this.json_data[data[i].p] = data[i];
        }

        // let json = [];
        //
        // for (let i = 0; i < this.data.length; i+=4)
        // {
        //     if (i === 507012)
        //     {
        //         let a = 1;
        //     }
        //     let index = i / 4;
        //     let x = index % 500;
        //     let y = (index / 500) >> 0;
        //
        //     if (x === 253 && y === 253)
        //     {
        //
        //     }
        //
        //     let p1 = this.data[i];
        //     let p2 = this.data[i + 1];
        //     let p3 = this.data[i + 2];
        //     let p4 = this.data[i + 3];
        //
        //     if (p3 !== 0)
        //     {
        //         json.push({p:index, i:p3})
        //     }
        // }
        //
        // let strJSON = JSON.stringify(json);
        //  console.log(strJSON);
        //
        // container.destroy();
        // container = null;
        texture.destroy();
        texture = null;
    }

    calculateImportantLocations(objAllItems)
    {
        this.locations[this.TYPE_LANDMARK] = [];
        this.locations[this.TYPE_BARN] = [];
        this.locations[this.TYPE_SPAWN] = [];
        this.locations[this.TYPE_OTHERS] = {};

        let landmarks = [];
        let barn = null;
        let spawn = null;
        let others = [];

        for (let id in objAllItems)
        {
            let item = objAllItems[id];

            if (item.map && item.map.landmark)
            {
                if (!item.map.landmark.type || item.map.landmark.type == this.TYPE_LANDMARK || item.map.landmark.type == "indoor")
                {
                    landmarks.push({"code": id, "index": item.map.index});
                }
                else if (item.map.landmark.type == this.TYPE_BARN)
                {
                    barn = {"code": id, "index": item.map.index};
                }
                else if (item.map.landmark.type == this.TYPE_SPAWN)
                {
                    spawn = {"code": id, "index": item.map.index};
                }
                else if (item.map.landmark.type == this.TYPE_OTHERS)
                {
                    others.push ( {"code": id, "index": item.map.index} );
                }
            }
        }

        //for (let i = 0; i < this.json_data.length; i++)
        for (let i in this.json_data)
        {
            let ob = this.json_data[i];
            let index = ob.p; //i / 4;
            let x = index % 500;
            let y = (index / 500) >> 0;

            if (index === 126753)
            {
                let a = 1;
            }

            let ob_index = ob.i;

            //if (this.data[i + 3] > 0)
            if (ob_index > 0)
            {
                //let value = (255 - this.data[i + 2]);
                let value = (255 - ob_index);

                if (barn && barn.index == value)
                {
                    //let def = this.getCoordinates(i/4);
                    let def = this.getCoordinates(index);
                    def.id = barn.code;

                    this.locations[this.TYPE_BARN].push(def);
                }
                else if (spawn && spawn.index == value)
                {
                    //let def = this.getCoordinates(i / 4);
                    let def = this.getCoordinates(index);
                    def.id = spawn.code;

                    this.locations[this.TYPE_SPAWN].push(def);
                }

                if (landmarks.length > 0)
                {
                    for (let j = 0; j < landmarks.length; j++)
                    {
                        let landmark = landmarks[j];

                        if (landmark.index == value)
                        {
                           // let def = this.getCoordinates(i/4);
                            let def = this.getCoordinates(index);
                            def.id = landmark.code;

                            this.locations[this.TYPE_LANDMARK].push(def);
                            break;
                        }
                    }
                }

                if (others.length > 0)
                {
                    for (let j = 0; j < others.length; j++)
                    {
                        let other = others[j];

                        if (other.index == value)
                        {
                            let def = this.getCoordinates(i/4);
                            def.id = other.code;

                            if (!this.locations[this.TYPE_OTHERS])
                            {
                                this.locations[this.TYPE_OTHERS] = {};
                            }
                            this.locations[this.TYPE_OTHERS][other.type].push(def)
                            break;
                        }
                    }
                }
            }
        }
    }

    getCoordinates(index)
    {
        let x = index % this.mapGridWidth;
        let z = (index / this.mapGridWidth) >> 0;

        return {"x": x, "z": z};
    }

    getCoordsByType (type)
    {
        if (this.locations[type])
        {
            return this.locations[type];
        }
        return [];
    }
}