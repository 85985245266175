import { Sprite } from "@pixi/sprite";
import { Texture } from "@pixi/core";
import Responsive from "./Responsive.js";
import Library from "../../../Library.js";

export default class SpriteResponsive extends Sprite
{
    constructor(objTexture)
    {
        super(objTexture instanceof Texture ? objTexture :  Library.getTextureFromAtlas("ui", objTexture));
    }

    get UI() { return this.ui; }

    /*******************************************
    *   INITIALIZATION
    *******************************************/
    /**
        Parameters to pass to the init function:
        - ui:  UI section object where this component resides
    */
    init(meta)
    {
        this.ui = meta.ui;
        this.responsive = new Responsive().init({"ui": meta.ui});

        this.ui.addToLayoutable(this);

        this.createClosure();
        this.bindEvents();

        return this;
    }

    createClosure ()
    {
        this.fctLayout = this.layout.bind(this)
        
        this.fctOnAdded = this.onAdded.bind(this);
        this.fctOnRemoved = this.onRemoved.bind(this);
    }

    bindEvents ()
    {
        this.on("added", this.fctLayout );//@

        this.on("added", this.fctOnAdded);
        this.on("removed", this.fctOnRemoved);
    }

    destroy (options)
    {
        this.UI.removeFromLayoutable(this);
        
        this.off("added", this.fctOnAdded);
        this.off("removed", this.fctOnRemoved);

        this.fctOnAdded = null;
        this.fctOnRemoved = null;

        if (this.responsive)
        {
            this.responsive.destroy();
            //this.responsive = null;
        }

        super.destroy(options);
    }


    /*******************************************
    *   RESPONSIVE
    *******************************************/
    layout (objSize)
    {
        this.responsive.placeLayout(this);
    }

    evaluate(expr, width, height, x, y, self, el)
    {
        return this.responsive.evaluate(expr, width, height, x, y, self, el);
    }

    valueFormula(strValue)
    {
        return this.UI.valueFormula(strValue);
    }

    //RESPONSIVE HANDLER
    set rOrder (responsives)
    {
        this.responsive.setResponsiveOrder(responsives);
    }

    setResponsiveOrder (responsives, strOn = "")
    {
        this.responsive.setResponsiveOrder(responsives);
    }

    set rX (responsives)
    {
        this.responsive.setResponsiveX(responsives);
    }

    setResponsiveX (responsives, strOn = "")
    {
        this.responsive.setResponsiveX(responsives);
    }

    set rY (responsives)
    {
        this.responsive.setResponsiveY(responsives);
    }

    setResponsiveY (responsives, strOn = "")
    {
        this.responsive.setResponsiveY(responsives);
    }


    set rWidth (responsives)
    {
        this.responsive.setResponsiveWidth(responsives);
    }

    setResponsiveWidth (responsives, strOn = "")
    {
        this.responsive.setResponsiveWidth(responsives);
    }

    set rHeight (responsives)
    {
        this.responsive.setResponsiveHeight(responsives);
    }

    setResponsiveHeight (responsives, strOn = "")
    {
        this.responsive.setResponsiveHeight(responsives);
    }

    set rAnchorX (responsives)
    {
        this.responsive.setResponsiveAnchorX(responsives);
    }

    setResponsiveAnchorX (responsives, strOn = "")
    {
        this.responsive.setResponsiveAnchorX(responsives);
    }

    set rAnchorY (responsives)
    {
        this.responsive.setResponsiveAnchorY(responsives);
    }

    setResponsiveAnchorY (responsives, strOn = "")
    {
        this.responsive.setResponsiveAnchorY(responsives);
    }

    set rScaleX (responsives)
    {
        this.responsive.setResponsiveScaleX(responsives);
    }

    setResponsiveScaleX (responsives, strOn = "")
    {
        this.responsive.setResponsiveScaleX(responsives);
    }

    set rScaleY (responsives)
    {
        this.responsive.setResponsiveScaleY(responsives);
    }

    setResponsiveScaleY (responsives, strOn = "")
    {
        this.responsive.setResponsiveScaleY(responsives);
    }

    set rClosure (responsives)
    {
        this.responsive.setResponsiveClosure(responsives);
    }

    setResponsiveClosure (responsives, strOn = "")
    {
        this.responsive.setResponsiveClosure(responsives);
    }

    containsPoint (point)
    {
        let bounds = this.getBounds();
        return (point.x >= bounds.x) && (point.x <= (bounds.x + bounds.width)) && (point.y >= bounds.y) && (point.y <= (bounds.y + bounds.height)) ;
    }

    /*******************************************
    *   EVENTS
    *******************************************/
    onAdded ()
    {
        
    }

    onRemoved ()
    {
        
    }
}
