import { Graphics } from '@pixi/graphics';
import ActionButton from "../commons/buttons/ActionButton.js";
import GameUI from "../GameUI.js";
import Display from "../../utils/Display.js";


export default class HostileUI extends GameUI
{
    constructor()
    {
        super();
    }

    get Id() { return "hostile"; }

    /*******************************************
    *   INITIALIZATION
    *******************************************/
    /**
        Parameters to pass to the init function:
        - dependencies:     Dependency injector object
    */
    init(meta)
    {
        return super.init(meta);
    }

    createClosure()
    {
        super.createClosure();

        this.fctOnEquipmentChanged = this.onEquipmentChanged.bind(this);

        //BUTTONS
        this.fctOnAntiStressClick = this.onAntiStressClick.bind(this);
        this.fctOnToolClick = this.onToolClick.bind(this);
    }

    bindEvents()
    {
        super.bindEvents();
        this.ItemManager.on(this.ItemManager.EVENT_EQUIPMENT_CHANGED, this.fctOnEquipmentChanged);
    }

    reset ()
    {
        this.cleanUp();
        this.build();
    }

    cleanUp (options)
    {
        super.cleanUp(options);

        this.ItemManager.off(this.ItemManager.EVENT_EQUIPMENT_CHANGED, this.fctOnEquipmentChanged);

        let keys = Object.keys(this.gauges);
        for (let i = 0; i < keys.length; i++)
        {
            let key = keys[i];
            if (this.gauges[key] in this)
            {
                this.removeMouseEating(this.gauges[key]);
                this.removeFromLayoutable(this.gauges[key]);
                this.removeChild(this.gauges[key]);
                this.gauges[key].destroy(options);
                delete this.gauges[key];
            }
        }

        if (this.buttons.antiStress)
        {
            this.removeMouseEating(this.buttons.antiStress);
            this.removeChild(this.buttons.antiStress);

            this.buttons.antiStress = [];
        }

        for (let i = 0; i < this.toolButtons.length; i++)
        {
            if (this.toolButtons[i].parent)
            {
                this.removeMouseEating(this.toolButtons[i]);
                this.removeChild(this.toolButtons[i]);
            }
        }

        for (let strKey in this.buttons)
        {
            if (this.buttons[strKey].parent)
            {
                this.removeMouseEating(this.buttons[strKey]);
                this.removeChild(this.buttons[strKey]);
            }
        }
    }
    destroy(options)
    {
        super.destroy(options);
    }




    /*******************************************
    *   UPDATE LOOP
    *******************************************/
    update(fDeltaTime)
    {
        super.update(fDeltaTime);
        this.updateCooldowns(fDeltaTime);
    }

    updateCooldowns(fDeltaTime)
    {
        for (let id in this.buttons)
        {
            let btn = this.buttons[id];
            if (btn.power)
            {
                let isDisabled = !this.CharacterManager.powerIsActive(btn.power.Code);
                if (btn.IsDisabled != isDisabled)
                {
                    btn.IsDisabled = isDisabled;
                }

                if (btn.IsDisabled)
                {
                    let ratio = this.CharacterManager.powerCooldownRatio(btn.power.Code);
                    if (btn.CooldownRatio != ratio)
                    {
                        btn.CooldownRatio = ratio;
                    }
                }
            }
        }
    }

    /*******************************************
    *   BUILD
    *******************************************/
    //-----------------------------------------
    //  ACTION BUTTONS
    //-----------------------------------------
    buildActionButtons()
    {
        super.buildActionButtons();

        this.buildAntistressButton();
        this.buildToolButton();
        this.buildMutationButton();
    }

    buildAntistressButton()
    {
        if (!this.buttons.antiStress)
        {
            let playerToy =  this.ItemManager.ToyEquipment;

            let icon = "icon_meditation";
            if (playerToy && playerToy.Icon && playerToy.Icon.ui)
            {
                icon = playerToy.Icon.ui;
            }

            this.buttons.antiStress = new ActionButton().init({
                "ui": this,
                "textureName": icon,
                "rWidth": this.rButtonWidth,
                "rHeight": this.rButtonHeight,
                "iconScale": 0.85
            });
            this.buttons.antiStress.rX = [{on: "default", x:`iw - ${this.rButtonWidth} - (${this.rButtonWidth} / 2)`}];
            this.buttons.antiStress.rY = [{on: "default", y:`ih - ${this.rButtonWidth} - ${this.rButtonWidth} / 2`}];
            this.buttons.antiStress.rAnchorX = [{on: "default", anchor: "0"}];
            this.buttons.antiStress.rAnchorY = [{on: "default", anchor: "1"}];

            this.buttons.antiStress.on(this.buttons.antiStress.EVENT_CLICK, this.fctOnAntiStressClick);
            this.buttons.antiStress.fctClick = this.fctOnAntiStressClick;

            this.addChild(this.buttons.antiStress);

            this.buttons.antiStress.name = "antiStress";
            this.addMouseEating(this.buttons.antiStress);
        }
    }

    updateAntistressButton ()
    {
        if (this.buttons.antiStress)
        {
            let playerToy =  this.ItemManager.ToyEquipment;

            let icon = "icon_meditation";
            if (playerToy && playerToy.Icon && playerToy.Icon.ui)
            {
                icon = playerToy.Icon.ui;
            }

            this.buttons.antiStress.changeTexture(icon);
        }
    }

    getToolButtonId(objPlayerTool)
    {
        return "tool" + (objPlayerTool.CanTrap ? "Trap" : "Fishing");
    }

    buildToolButton ()
    {
        let playerTool = this.ItemManager.ToolEquipment;

        if (!this.toolButtons)
        {
            this.toolButtons = [];
        }

        for (let i = 0; i < this.toolButtons.length; i++)
        {
            if (this.toolButtons[i].parent)
            {
                this.removeMouseEating(this.toolButtons[i]);
                this.removeChild(this.toolButtons[i]);
            }
        }

        if (playerTool && playerTool.Icon && playerTool.Icon.ui)
        {
            let id = this.getToolButtonId(playerTool);

            if (!this.buttons[id])
            {
                this.buttons[id] = new ActionButton().init({
                    "ui": this,
                    "textureName": playerTool.Icon.ui,
                    "rWidth": this.rButtonWidth,
                    "rHeight": this.rButtonHeight,
                    "iconScale": 0.85
                });
                this.buttons[id].on(this.buttons[id].EVENT_CLICK, this.fctOnToolClick);
                this.buttons[id].fctClick = this.fctOnToolClick;

                this.buttons[id].name = id;
                this.toolButtons.push(this.buttons[id]);
            }

            this.buttons[id].rX = [{on: "default", x: `iw - (${this.rButtonWidth} * 2) - (${this.rButtonWidth})`}];
            this.buttons[id].rY = [{on: "default", y: `ih - ${this.rButtonWidth} - ${this.rButtonWidth} / 2`}];
            this.buttons[id].rAnchorX = [{on: "default", anchor: "0"}];
            this.buttons[id].rAnchorY = [{on: "default", anchor: "1"}];
            this.buttons[id].layout(Display.getSize());

            //We can't trap inside
            this.buttons[id].IsDisabled = this.WorldManager.Environment.IsIndoor && playerTool.CanTrap;
            
            this.addChild(this.buttons[id]);
            this.addMouseEating(this.buttons[id]);
        }
    }




    buildMutationButton()
    {
        let playerActiveMutation =  this.ItemManager.MutationPowers;
        let arrMutations = [];

        for (let i = 0; i < playerActiveMutation.length; i++)
        {
            let power = playerActiveMutation[i];
            if (power.IsActive)
            {
                let id = "mutation" + power.Id; 
                if (!this.buttons[id])
                {
                    this.buttons[id] = new ActionButton().init({
                        "ui": this,
                        "textureName": "mutation_" + power.Code,
                        "rWidth": this.rButtonWidth,
                        "rHeight": this.rButtonHeight,
                        "iconScale": 0.85,
                        "tint": 0x2D2735
                    });

                    let fctClick = this.onMutationPowerClick.bind(this, power);
                    this.buttons[id].on(this.buttons[id].EVENT_CLICK, fctClick);
                    this.buttons[id].fctClick = fctClick;

                    this.buttons[id].name = power.Id;
                    this.buttons[id].power = power;

                    this.addMouseEating(this.buttons[id]);
                    this.addChild(this.buttons[id]);
                }

                this.buttons[id].rX = [{on: "default", x:`iw - ${this.rButtonWidth} - (${this.rButtonWidth} / 2)`}];
                this.buttons[id].rY = [{on: "default", y:`ih - (${this.rButtonWidth} * 2) - (${this.rButtonWidth}) - ((${this.rButtonWidth} * 1.5) * ${arrMutations.length})`}];
                this.buttons[id].rAnchorX = [{on: "default", anchor: "0"}];
                this.buttons[id].rAnchorY = [{on: "default", anchor: "1"}];
                this.buttons[id].layout(Display.getSize());

                arrMutations.push(this.buttons[id]);
            }
        }
    }

    /*******************************************
    *   UTILITIES
    *******************************************/
    canWorldClick(x, y)
    {
        for (let key in this.popups)
        {
            if (this.popups[key].IsShown)
            {
                return false;
            }
        }
        return super.canWorldClick(x, y);
    }

    /*******************************************
    *   EVENTS
    *******************************************/
    onEquipmentChanged()
    {
        this.buildToolButton();

        if (this.toolButtons)
        {
            for (let i = 0; i < this.toolButtons.length; i++)
            {
                this.removeChild(this.toolButtons[i]);
            }
        }

        let playerTool = this.ItemManager.ToolEquipment;

        if (playerTool && playerTool.Icon && playerTool.Icon.ui)
        {
            let id = this.getToolButtonId(playerTool);
            this.addChild(this.buttons[id]);
        }
    }

    onAntiStressClick()
    {
        if (!this.GameManager.IsPaused && !this.WorldManager.IsDialogMode)
        {
            if (this.WorldManager.Player.IsAntiStress)
            {
                this.WorldManager.Player.stopAntiStress();
            }
            else
            {
                this.WorldManager.Player.startAntiStress();
            }
        }
    }

    onToolClick()
    {
        if (!this.GameManager.IsPaused && !this.WorldManager.IsDialogMode)
        {
            let playerTool = this.ItemManager.ToolEquipment;

            if (playerTool)
            {
                if (playerTool.CanTrap)
                {
                    this.WorldManager.Player.putTrap();
                }
                else if (playerTool.CanFish)
                {
                    this.WorldManager.Player.startFishing();
                }
            }
        }
    }

    onMutationPowerClick(objPower)
    {
        if (!this.GameManager.IsPaused && !this.WorldManager.IsDialogMode)
        {
            let player = this.WorldManager.Player;

            if (objPower.CanInvisible)
            {
                player.renderInvisible();
            }
            else if (objPower.CanTeleport)
            {
                player.teleport();
            }
            else if (objPower.CanSpit)
            {
                player.spit();
            }
            else if (objPower.CanStomp)
            {
                player.stomp();
            }
        }
    }
}