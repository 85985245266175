export default class ColorTheme
{
    static get HAIR() { return [0x774f38,0x4e4748,0xc89641, 0xe9b55e, 0x503876, 0xd48866, 0x3f3e6d, 0x763843  ] };
    static get LEG() { return [0xde5f39,  0x31a675, 0xfebc2c, 0x4f7dad, 0x8b2431, 0xb67ec0, 0x3e3933, 0x262b47  ] };
    static get BOOTS() { return [0x5381e4, 0x684c37, 0x48453d , 0x40614a, 0x262b47, 0xfe9023, 0xb8ab9c, 0x6f3785  ] };
    static get SKIN() { return [ 0xe5b6a4, /*0xcf9b86*/0xcf9b86,0xb8846f, 0x8e6257, 0x69483d, 0x3e2b26, 0xe4c0a0,  0xefd0b3 ] };
    static get NECK() { return [  0xc68b74, 0xad7964, 0x9d6b58, 0x795649, 0x594036, 0x32241f, 0xbd9e81, 0xd2b092] }; //Neck should use the same index has skin
    static get IRIS() { return [ 0x302d62, 0xe28441, 0xa3b831, 0x62418e, 0xe34d2f, 0xeab630, 0x5a3227, 0x57edb0  ] };
    static get SWEATER() { return [ 0xb86fff, 0xffac29, 0x2d2e2e , 0x45d1ff, 0x665c46, 0x8ba500,0x5b38df, 0xbb2b24] }

    static get EYES() { return  [0xFFFFFF];}
    static get NONE() { return  0xFFFFFF;}

    static get NOSE() { return [  [0xe5b6a4, 0xdf8472], [0xd2725f, 0xd2725f],[0xb9846f,0xc2634f], [0x8c6354, 0xA14A3B], [0x69483d, 0x553B33], [0x3e2b26, 0x31201D], [0xe5c09e, 0xE48562],  [0xf0d0b3, 0xF4A78B] ] }
    static get MOUTH() { return [ [0xe5b6a4, 0xdf8472], [0xd2725f, 0xd2725f],[0xb9846f,0xc2634f], [0x8c6354, 0xA04A3C], [0x69483d, 0x553B33], [0x3e2b26, 0x31211E], [0xe5c09e, 0xE68662],  [0xf0d0b3, 0xF5A78A] ] }


    static get MUTATION_HEAD() { return [ "cameleon", "cat", "eagle", "toad"]};
    static get MUTATION_ARM() { return [ "bear", "octopus", "raccoon", "sloth"]};
    static get MUTATION_LEGS() { return [ "elephant", "ostrich", "rabbit", "tiger"]};

    static getNoseFromSkin (skin)
    {
        let color = this.NOSE.filter( a => a[0] === skin);

        if (color.length === 0)
            return skin;

        return color[0][1];
    }

    static getMouthFromSkin (skin)
    {
        let color = this.MOUTH.filter( a => a[0] === skin);

        if (color.length === 0)
            return skin;

        return color[0][1];
    }


    static getRandom ()
    {
        let hair = (Math.random() * this.HAIR.length) >> 0;
        let skin = (Math.random() * this.SKIN.length) >> 0;
        let iris = (Math.random() * this.IRIS.length) >> 0;
        let sweater = (Math.random() * this.SWEATER.length) >> 0;
        let legs = (Math.random() * this.LEG.length) >> 0;
        let boots = legs;

        let nose = this.NOSE[skin][1];
        let mouth = this.MOUTH[skin][1];

        let type_hair =  (Math.random() * (8)) >> 0;
        let mutation_head = Math.random() > 0.5 ? (Math.random() * (this.MUTATION_HEAD.length + 1)) >> 0 : 0;
        let mutation_arm = Math.random() > 0.5 ? (Math.random() * (this.MUTATION_ARM.length + 1)) >> 0 : 0;
        let mutation_legs = Math.random() > 0.5 ? (Math.random() * (this.MUTATION_LEGS.length + 1)) >> 0 : 0;


        let strColor = hair.toString() + skin.toString() + iris.toString() + sweater.toString() + legs.toString() + boots.toString();
        let strType = type_hair.toString() + mutation_head.toString() + mutation_arm.toString() + mutation_legs.toString();

        return strColor + "_" + strType;



    }
}