import { string2hex }  from "@pixi/utils"; 
import { TextStyle } from "@pixi/text";
import { TextMetrics } from "@pixi/text";
import ContainerDragger from "../../commons/ContainerDragger.js";
import ContainerResponsive from "../../commons/responsive/ContainerResponsive.js";
import DoubleTapDetector from "../../commons/DoubleTapDetector.js";
import GraphicsResponsive from "../../commons/responsive/GraphicsResponsive.js";
import Library from "../../../Library.js";
import SpriteResponsive from "../../commons/responsive/SpriteResponsive.js";
import TextResponsive from "../../commons/responsive/TextResponsive.js";

export default class CraftingItemContent extends ContainerResponsive
{
    constructor()
    {
        super();
    }

    get EVENT_CRAFT() { return "craft"; }
    get EVENT_BACK() { return "back"; }
    get EVENT_DRAG_ANIMATION_END() { return "drag-animation-end"; }

    get Category() { return this.category; }
    set Category(strNewValue)
    { 
        this.category = strNewValue; 
        this.items = this.getItemList(this.category);
        this.reset();
    }

    get Opacity() { return this.opacity; }
    set Opacity(fNewValue) { this.opacity = fNewValue; this.build(); }

    get BgWidth() { return this.evaluate(this.drawWidth, 0, 0, 0, 0, this); }
    get BgHeight() { return this.evaluate(this.drawHeight, 0, 0, 0, 0, this); }

    get PaddingHorizontal() { return this.BgWidth * 0.125; }
    get PaddingVertical() { return this.BgHeight * 0.2; }
    get ItemWidth() { return this.BgWidth * 0.7 - this.PaddingHorizontal / 2; }
    get ItemHeight() { return (this.BgHeight - this.PaddingVertical) / 3; }
    get ItemImageWidth() { return this.ItemImageHeight; }
    get ItemImageHeight() { return this.ItemHeight * 0.8; }
    get RightZoneWidth() { return this.BgWidth - this.ItemWidth - this.PaddingHorizontal / 2; }
    get RightZoneHeight() { return this.BgHeight - this.PaddingVertical; }

    get ImageBackgroundColor() { return string2hex(this.UI.Values.slot.background.color); }
    get ImageStrokeWidth() { return this.evaluate(this.valueFormula(this.UI.Values.slot.stroke.size), 0, 0, 0, 0, this); }
    get ImageStrokeColor() { return string2hex("color" in this.UI.Values.slot.stroke ? this.UI.Values.slot.stroke.color : "#000000"); }
    get ImageCornerRadius() { return this.evaluate(this.valueFormula(this.UI.Values.slot.corner.radius), 0, 0, 0, 0, this); }

    get SlotBackgroundColor() { return string2hex(this.UI.Values.slot.background.color); }
    get SlotStrokeWidth() { return this.evaluate(this.valueFormula(this.UI.Values.slot.stroke.size), 0, 0, 0, 0, this); }
    get SlotStrokeColor() { return string2hex("color" in this.UI.Values.slot.stroke ? this.UI.Values.slot.stroke.color : "#000000"); }
    get SlotCornerRadius() { return this.evaluate(this.valueFormula(this.UI.Values.slot.corner.radius), 0, 0, 0, 0, this); }

    get DropZoneNormalColor() { return string2hex(this.UI.Values.drag.dropzone.background.normal.color); }
    get DropZoneHoverColor() { return string2hex(this.UI.Values.drag.dropzone.background.hover.color); }

    get MissingQuantityColor() { return string2hex(this.UI.Values.general.error.color); }
    get ItemDisabledAlpha() { return this.UI.Values.general.disabled.opacity; }

    /*******************************************
    *   INITIALIZATION
    *******************************************/
    /**
        Parameters to pass to the init function:
        - ui:       UI section object where this component resides
        - category: Id of the category where this content should display items for
        - rWidth:   Responsive equation for the width of this content
        - rHeight:  Responsive equation for the height of this content
    */
    init(meta)
    {
        this.ui = meta.ui;

        this.drawWidth = meta.rWidth;
        this.drawHeight = meta.rHeight;

        this.graphics = {};
        this.sprites = {};
        this.texts = {};

        this.category = meta.category;

        this.opacity = 1;

        this.items = this.getItemList(meta.category);

        this.fctOnMouseDown = {};
        this.fctOnTouchStart = {};

        this.dropRect = {"x": 0, "y": 0, "width": 0, "height": 0};
        this.dragKey = null;
        this.dragInput = null;
        this.dragStartRatio = {"x": 0, "y": 0};
        this.dropped = false;

        this.drags = {};

        return super.init(meta);
    }

    createClosure()
    {
        super.createClosure();

        this.fctOnBackClick = this.onBackClick.bind(this);
        this.fctOnDragStart = this.onDragStart.bind(this);
        this.fctOnDragEnd = this.onDragEnd.bind(this);
        this.fctOnDragDrop = this.onDragDrop.bind(this);
        this.fctOnDragEnterDropZone = this.onDragEnterDropZone.bind(this);
        this.fctOnDragExitDropZone = this.onDragExitDropZone.bind(this);
        this.fctOnDragAnimationEnd = this.onDragAnimationEnd.bind(this);
        this.fctOnItemAdded = this.onItemAdded.bind(this);
    }

    bindEvents()
    {
        super.bindEvents();
        this.UI.ItemManager.on(this.UI.ItemManager.EVENT_ITEM_FOUND, this.fctOnItemAdded);
    }

    destroy(options)
    {
        for (let key in this.drags)
        {
            this.drags[key].off(this.drags[key].EVENT_DRAG_START, this.fctOnDragStart);
            this.drags[key].off(this.drags[key].EVENT_ENTER_DROP_ZONE, this.fctOnDragEnterDropZone);
            this.drags[key].off(this.drags[key].EVENT_EXIT_DROP_ZONE, this.fctOnDragExitDropZone);
            this.drags[key].off(this.drags[key].EVENT_DRAG_END, this.fctOnDragEnd);
            this.drags[key].off(this.drags[key].EVENT_DROP, this.fctOnDragDrop);
            this.drags[key].off(this.drags[key].EVENT_DRAG_END_ANIMATION_FINISHED, this.fctOnDragAnimationEnd);
            this.drags[key].destroy(options);
        }
        delete this.drags;
        
        for (let key in this.graphics)
        {
            if (this.graphics[key].detector)
            {
                this.graphics[key].detector.destroy(options);
            }
        }

        this.UI.ItemManager.off(this.UI.ItemManager.EVENT_ITEM_FOUND, this.fctOnItemAdded);
        super.destroy(options);
    }

    /*******************************************
    *   BUILD
    *******************************************/
    build()
    {
        super.build();

        if (!this.items)
        {
            this.items = this.getItemList(strCategoryId);
        }

        this.buildRightZone();

        let i = 0;
        for (let key in this.items)
        {
            this.buildItem(this.items[key], i, key);
            i++;
        }

        this.buildBackButton();
    }

    clean()
    {
        for (let key in this.graphics)
        {
            this.graphics[key].clear();
        }
        super.clean();
    }

    buildRightZone()
    {
        if (!this.graphics.rightZone)
        {
            this.graphics.rightZone = new GraphicsResponsive().init({"ui": this.UI});
            this.graphics.rightZone.interactive = true;

            this.addChild(this.graphics.rightZone);
        }

        let graphics = this.graphics.rightZone;
        graphics.alpha = this.Opacity;

        let width = this.RightZoneWidth;
        let height = this.RightZoneHeight;
        let x = this.PaddingHorizontal / 2 + this.ItemWidth;
        let y = this.PaddingVertical / 2;

        let iconBoxWidth = width * 0.85;
        let iconBoxHeight = height * 0.66;
        let iconBoxX = x + width / 2 - iconBoxWidth / 2;
        let iconBoxY = y + height / 2 - iconBoxHeight / 2;

        let iconBoxBgColor = this.ImageBackgroundColor;
        let iconBoxCornerRadius = this.ImageCornerRadius;
        let iconBoxStrokeWidth = this.ImageStrokeWidth;
        let iconBoxStrokeColor = this.ImageStrokeColor;

        if (iconBoxStrokeWidth > 0)
        {
            graphics.lineStyle({"color":iconBoxStrokeColor, "width": iconBoxStrokeWidth, "alignment": 1});
        }

        graphics.beginFill(iconBoxBgColor, 1);
        if (iconBoxCornerRadius > 0)
        {
            graphics.drawRoundedRect(iconBoxX, iconBoxY, iconBoxWidth, iconBoxHeight, iconBoxCornerRadius);
        }
        else
        {
            graphics.drawRect(iconBoxX, iconBoxY, iconBoxWidth, iconBoxHeight);
        }
        graphics.endFill();

        if (!this.sprites.rightSprite)
        {
            let texture = "upgrade_material";
            let visual = Library.getTextureFromAtlas("ui", texture);
            this.sprites.rightSprite = new SpriteResponsive(visual).init({"ui": this.UI});

            this.addChild(this.sprites.rightSprite);
        }

        let sprite = this.sprites.rightSprite;
        sprite.alpha = this.Opacity;

        let ratio = sprite.height / sprite.width;
        let spriteWidth = iconBoxWidth;
        let spriteHeight = spriteWidth * ratio;
        let spriteX = iconBoxX + iconBoxWidth / 2 - spriteWidth / 2 + spriteWidth * 0.125;
        let spriteY = iconBoxY + iconBoxHeight / 2 - spriteHeight / 2;

        sprite.width = spriteWidth;
        sprite.height = spriteHeight;
        sprite.position.set(spriteX, spriteY);

        let zoneWidth = this.ItemImageWidth;
        let zoneHeight = this.ItemImageHeight;
        let zoneX = x + width / 2 - zoneWidth / 2;
        let zoneY = iconBoxY - zoneHeight * 0.75;

        this.buildDropZone(zoneX, zoneY, zoneWidth, zoneHeight);
    }

    buildDropZone(x, y, width, height)
    {
        if (!this.sprites.dropZone)
        {
            let texture = "square_dashed";
            let visual = Library.getTextureFromAtlas("ui", texture);
            this.sprites.dropZone = new SpriteResponsive(visual).init({"ui": this.UI});
            this.sprites.dropZone.tint = this.DropZoneNormalColor;

            this.addChild(this.sprites.dropZone);
        }

        let sprite = this.sprites.dropZone;
        sprite.alpha = this.Opacity;
        sprite.tint = this.DropZoneNormalColor;

        this.dropRect = {"x": x, "y": y, "width": width, "height": height};

        sprite.width = width;
        sprite.height = height;
        sprite.position.set(x, y);

        if (!this.texts.dropZone)
        {
            this.texts.dropZone = new TextResponsive().init({"ui": this.UI});
            this.addChild(this.texts.dropZone);
        }

        let label = this.UI.LabelManager.translate("Fab2_Glisser");
        let text = this.texts.dropZone;
        text.alpha = this.Opacity;

        let style = new TextStyle({
            fontFamily: this.UI.Values.general.text.font,
            fontSize:   this.evaluate(this.valueFormula(this.UI.Values.general.text.size * 0.75)),
            fontWeight: this.UI.Values.general.text.weight,
            fill: this.UI.Values.general.text.color,
            align: "center",
            wordWrap: true,
            wordWrapWidth: width * 0.85
        });

        let metrics = TextMetrics.measureText(label, style);

        let textX = x + width / 2 - metrics.width / 2;
        let textY = y + height / 2 - metrics.height / 2;

        text.text = label;
        text.style = style;
        text.width = metrics.width;
        text.height = metrics.height;
        text.position.set(textX, textY);
    }

    buildItem(objItem, iIndex, strKey)
    {
        if (!this.graphics["item_" + strKey])
        {
            this.graphics["item_" + strKey] = new GraphicsResponsive().init({"ui": this.UI});
            this.graphics["item_" + strKey].interactive = true;

            this.drags["item_" + strKey] = new ContainerDragger().init({
                "ui": this.UI,
                "draggables": this.graphics["item_" + strKey],
                "dropZones": this.sprites.dropZone,
                "id": objItem
            });
            this.drags["item_" + strKey].on(this.drags["item_" + strKey].EVENT_DRAG_START, this.fctOnDragStart);//@
            this.drags["item_" + strKey].on(this.drags["item_" + strKey].EVENT_ENTER_DROP_ZONE, this.fctOnDragEnterDropZone);//@
            this.drags["item_" + strKey].on(this.drags["item_" + strKey].EVENT_EXIT_DROP_ZONE, this.fctOnDragExitDropZone);//@
            this.drags["item_" + strKey].on(this.drags["item_" + strKey].EVENT_DRAG_END, this.fctOnDragEnd);//@
            this.drags["item_" + strKey].on(this.drags["item_" + strKey].EVENT_DROP, this.fctOnDragDrop);//@
            this.drags["item_" + strKey].on(this.drags["item_" + strKey].EVENT_DRAG_END_ANIMATION_FINISHED, this.fctOnDragAnimationEnd);//@

            this.addChild(this.graphics["item_" + strKey]);
        }

        if (!this.graphics["itemline_" + strKey])
        {
            this.graphics["itemline_" + strKey] = new GraphicsResponsive().init({"ui": this.UI});
            this.addChild(this.graphics["itemline_" + strKey]);
        }

        this.drags["item_" + strKey].Id = objItem;
        this.drags["item_" + strKey].CanDrag = objItem.canCraft;
        this.graphics["item_" + strKey].buttonMode = objItem.canCraft;

        let itemGraphics = this.graphics["item_" + strKey];
        let lineGraphics = this.graphics["itemline_" + strKey];

        itemGraphics.alpha = this.Opacity * (objItem.canCraft ? 1 : this.ItemDisabledAlpha);
        lineGraphics.alpha = this.Opacity * 0.5;

        let lineHeight = this.evaluate(this.valueFormula(3));
        let width = this.ItemWidth * 0.975;
        let height = this.ItemHeight;
        let x = this.PaddingHorizontal / 2;
        let y = this.PaddingVertical / 2 + height * iIndex;

        let imageWidth = this.ItemImageWidth;
        let imageHeight = this.ItemImageHeight;
        let imagePadding = height - imageHeight;
        let imageX = x + imagePadding / 2;
        let imageY = y + imagePadding / 2;

        itemGraphics.position.set(imageX, imageY);

        let imageBgColor = this.ImageBackgroundColor;
        let imageStrokeWidth = this.ImageStrokeWidth;
        let imageStrokeColor = this.ImageStrokeColor;
        let imageCornerRadius = this.ImageCornerRadius;

        if (imageStrokeWidth > 0)
        {
            itemGraphics.lineStyle({"color":imageStrokeColor, "width": imageStrokeWidth, "alignment": 1});
        }
        
        itemGraphics.beginFill(imageBgColor, 1);

        if (imageCornerRadius > 0)
        {
            itemGraphics.drawRoundedRect(0, 0, imageWidth, imageHeight, imageCornerRadius);
        }
        else
        {
            itemGraphics.drawRect(0, 0, imageWidth, imageHeight);
        }

        itemGraphics.endFill();

        if (iIndex > 0)
        {
            lineGraphics.beginFill(0xFFFFFF, 1);
            lineGraphics.drawRect(x, y - lineHeight / 2, width, lineHeight);
            lineGraphics.endFill();
        }

        if (!this.graphics["item_placeholder" + strKey])
        {
            this.graphics["item_placeholder" + strKey] = new GraphicsResponsive().init({"ui": this.UI});
            this.graphics["item_placeholder" + strKey].interactive = true;

            this.addChild(this.graphics["item_placeholder" + strKey]);
        }

        let placeholder = this.graphics["item_placeholder" + strKey];
        placeholder.alpha = this.Opacity * 0.5

        placeholder.position.set(imageX, imageY);
        placeholder.beginFill(imageBgColor, 1);
        if (imageCornerRadius > 0)
        {
            placeholder.drawRoundedRect(0, 0, imageWidth, imageHeight, imageCornerRadius);
        }
        else
        {
            placeholder.drawRect(0, 0, imageWidth, imageHeight);
        }
        placeholder.endFill();

        this.buildItemSprite(strKey, objItem, imageWidth, imageHeight, itemGraphics);
        this.buildItemText(strKey, objItem, imageX, imageY, imageWidth, imageHeight);
        this.buildItemRequirements(strKey, objItem.requirements, x, y, width, height);
    }

    buildItemSprite(strKey, objItem, fBoxWidth, fBoxHeight, parent)
    {
        if (!parent.spriteObj)
        {
            parent.spriteObj = this.getItemImage(objItem);
            parent.addChild(parent.spriteObj);
        }

        let sprite = parent.spriteObj;
        sprite.alpha = this.Opacity * (objItem.canCraft ? 1 : this.ItemDisabledAlpha);

        let ratio = sprite.height / sprite.width;
        let width = fBoxWidth - this.ImageCornerRadius * 2;
        let height = width * ratio;

        if (sprite.width < sprite.height)
        {
            ratio = sprite.width / sprite.height;
            height = fBoxHeight - this.ImageCornerRadius * 2;
            width = height * ratio;
        }

        let x = fBoxWidth / 2 - width / 2;
        let y = fBoxHeight / 2 - height / 2;

        sprite.width = width;
        sprite.height = height;
        sprite.position.set(x, y);
    }

    buildItemText(strKey, objItem, fBoxX, fBoxY, fBoxWidth, fBoxHeight)
    {
        if (!this.texts[strKey + "_title"])
        {
            this.texts[strKey + "_title"] = new TextResponsive().init({"ui": this.UI});
            this.addChild(this.texts[strKey + "_title"]);
        }

        let text = this.texts[strKey + "_title"];
        text.alpha = this.Opacity * (objItem.canCraft ? 1 : this.ItemDisabledAlpha);

        let label = objItem.Name;

        let style = new TextStyle({
            fontFamily: this.UI.Values.general.title.font,
            fontSize:   this.evaluate(this.valueFormula(this.UI.Values.general.text.size * 1.3)),
            fontWeight: this.UI.Values.general.title.weight,
            fill: this.UI.Values.general.text.color,
            align: "left",
            wordWrap: true,
            wordWrapWidth: fBoxWidth * 1.5
        });

        let metrics = TextMetrics.measureText(label, style);

        let titleHeight = metrics.height;
        let x = fBoxX + fBoxWidth * 1.15;
        let y = fBoxY + titleHeight * 0.25;

        text.text = label;
        text.style = style;
        text.width = metrics.width;
        text.height = metrics.height;
        text.position.set(x, y);


        if (!this.texts[strKey + "_desc"])
        {
            this.texts[strKey + "_desc"] = new TextResponsive().init({"ui": this.UI});
            this.addChild(this.texts[strKey + "_desc"]);
        }

        text = this.texts[strKey + "_desc"];
        text.alpha = this.Opacity * (objItem.canCraft ? 1 : this.ItemDisabledAlpha);

        let hasLevel = objItem.CraftLevel <= this.UI.WorldManager.getBarnZoneLevel(this.UI.WorldManager.BARN_ZONE_CRAFTING);
        let backpackLevelLow = this.Category == this.UI.ItemManager.ITEM_CODES.EQUIPMENT_BACKPACK && objItem.CraftLevel <= this.UI.ItemManager.BackpackLevel;

        label = objItem.Description.replace(", ", " \n").replace(",", "\n");
        if (!hasLevel)
        {
            label = this.getLockedLevelLabel(objItem.CraftLevel);
        }

        if (backpackLevelLow)
        {
            if (objItem.CraftLevel == this.UI.ItemManager.BackpackLevel)
            {
                label = this.UI.LabelManager.translate("Fab2_Sacados_Deja");
            }
            else
            {
                label = this.UI.LabelManager.translate("Fab2_Sacados_Meilleur");
            }
        }

        style = new TextStyle({
            fontFamily: this.UI.Values.general.text.font,
            fontSize:   this.evaluate(this.valueFormula(this.UI.Values.general.text.size * 0.75)),
            fontWeight: this.UI.Values.general.text.weight,
            fill: (hasLevel && !backpackLevelLow ? this.UI.Values.general.text.color : this.UI.Values.general.error.color),
            align: "left",
            wordWrap: true,
            wordWrapWidth: fBoxWidth * 1.5
        });

        metrics = TextMetrics.measureText(label, style);

        let descY = y + titleHeight + fBoxHeight * 0.1;

        text.text = label;
        text.style = style;
        text.width = metrics.width;
        text.height = metrics.height;
        text.position.set(x, descY);
    }

    buildItemRequirements(strKey, objRequirements, fItemX, fItemY, fItemWidth, fItemHeight)
    {
        let bgColor = this.SlotBackgroundColor;
        let strokeWidth = this.SlotStrokeWidth;
        let strokeColor = this.SlotStrokeColor;
        let cornerRadius = this.SlotCornerRadius / 2;

        let height = fItemHeight / 5;
        let width = height;
        let padding = height / 2;
        let sidePadding = (fItemHeight - (height * objRequirements.length) - (padding * (objRequirements.length - 1))) / 2;

        let x = fItemX + fItemWidth - fItemWidth * 0.2;

        let textStyle = {
            fontFamily: this.UI.Values.general.text.font,
            fontSize:   this.evaluate(this.valueFormula(this.UI.Values.general.text.size * 0.75)),
            fontWeight: 400,
            fill: this.UI.Values.general.text.color,
            align: "center"
        };

        for (let i = 0; i < objRequirements.length; i++)
        {
            //Slot background
            if (!this.graphics["requirements_" + strKey + "_" + i])
            {
                this.graphics["requirements_" + strKey + "_" + i] = new GraphicsResponsive().init({"ui": this.UI});
                this.graphics["requirements_" + strKey + "_" + i].interactive = true;
                this.graphics["requirements_" + strKey + "_" + i].buttonMode = true;

                let doubleTapDetector = new DoubleTapDetector().init({
                    "ui": this.UI,
                    "target": this.graphics["requirements_" + strKey + "_" + i],
                    "callback": function(objItem)
                    {
                        this.UI.showItemDescription(objItem);
                    }
                    .bind(this, objRequirements[i].item)
                });
                doubleTapDetector.startDetection();
                this.graphics["requirements_" + strKey + "_" + i].detector = doubleTapDetector;

                this.addChild(this.graphics["requirements_" + strKey + "_" + i]);
            }

            let graphics = this.graphics["requirements_" + strKey + "_" + i];
            let y = fItemY + sidePadding + height * i + padding * i;

            if (strokeWidth > 0)
            {
                graphics.lineStyle({"color":strokeColor, "width": strokeWidth, "alignment": 1});
            }
            graphics.beginFill(bgColor, 1);
            if (cornerRadius > 0)
            {
                graphics.drawRoundedRect(x, y, width, height, cornerRadius);
            }
            else
            {
                graphics.drawRect(x, y, width, height);
            }

            //Item sprite
            if (!("requirement_" + i + "_" + strKey in this.sprites))
            {
                this.sprites["requirement_" + i + "_" + strKey] = this.getItemImage(objRequirements[i].item, true);
                this.addChild(this.sprites["requirement_" + i + "_" + strKey]);
            }

            let sprite = this.sprites["requirement_" + i + "_" + strKey];

            let spriteRatio = sprite.height / sprite.width;
            let spriteWidth = width - cornerRadius;
            let spriteHeight = spriteWidth * spriteRatio;
            if (sprite.width < sprite.height)
            {
                spriteRatio = sprite.width / sprite.height;
                spriteHeight = height - cornerRadius;
                spriteWidth = spriteHeight * spriteRatio;
            }

            sprite.width = spriteWidth;
            sprite.height = spriteHeight;
            sprite.position.set(x + width / 2 - spriteWidth / 2, y + height / 2 - spriteHeight / 2);

            //Item owned/quantity
            if (!("requirement_" + i + "_" + strKey in this.texts))
            {
                this.texts["requirement_" + i + "_" + strKey] = new TextResponsive().init({"ui": this.UI});
                this.addChild(this.texts["requirement_" + i + "_" + strKey]);
            }

            let text = this.texts["requirement_" + i + "_" + strKey];
            let label = this.getRequirementLabel(Math.min(999, objRequirements[i].owned), objRequirements[i].quantity);

            textStyle.fill = this.UI.Values.general.text.color;
            if (objRequirements[i].owned < objRequirements[i].quantity)
            {
                textStyle.fill = this.MissingQuantityColor;
            }
            let style = new TextStyle(textStyle);

            let metrics = TextMetrics.measureText(label, style);

            let textX = x + width + width * 0.33;
            let textY = y + height / 2 - metrics.height / 2;

            text.text = label;
            text.style = style;
            text.width = metrics.width;
            text.height = metrics.height;
            text.position.set(textX, textY);
        }
    }

    buildBackButton()
    {
        if (!this.sprites.backButton) //not sure if this is suffisant
        {
            let visual = Library.getTextureFromAtlas("ui", "next");
            this.sprites.backButton = new SpriteResponsive(visual).init({"ui": this.UI});
            this.sprites.backButton.on("pointerup", this.fctOnBackClick);
            this.sprites.backButton.interactive = true;
            this.sprites.backButton.buttonMode = true;
            this.sprites.backButton.scale.set(-1, 1);

            this.addChild(this.sprites.backButton);
        }

        let sprite = this.sprites.backButton;
        let ratio = sprite.width / sprite.height;

        let height = this.BgHeight * 0.085;
        let width = height * ratio;
        let x = width / 3;
        let y = height;

        sprite.width = width;
        sprite.height = height;
        sprite.position.set(x, y);
    }

    /*******************************************
    *   ITEMS & LABELS
    *******************************************/
    getItemList(strEquipLocation)
    {
        let list = {};

        let itemIds = [];
        if (strEquipLocation == this.UI.ItemManager.ITEM_CODES.EQUIPMENT_BODY)
        {
            itemIds.push("EQ-MAN0");
            itemIds.push("EQ-MAN1");
            itemIds.push("EQ-MAN2");
        }
        else if (strEquipLocation == this.UI.ItemManager.ITEM_CODES.EQUIPMENT_HANDS)
        {
            itemIds.push("EQ-GAN0");
            itemIds.push("EQ-GAN1");
            itemIds.push("EQ-GAN2");
        }
        else if (strEquipLocation == this.UI.ItemManager.ITEM_CODES.EQUIPMENT_FEET)
        {
            itemIds.push("EQ-BOT0");
            itemIds.push("EQ-BOT1");
            itemIds.push("EQ-BOT2");
        }
        else if (strEquipLocation == this.UI.ItemManager.ITEM_CODES.EQUIPMENT_TOOL)
        {
            itemIds.push("EQ-TRA");
            itemIds.push("EQ-CAN0");
            itemIds.push("EQ-CAN1");
        }
        else if (strEquipLocation == this.UI.ItemManager.ITEM_CODES.EQUIPMENT_TOY)
        {
            itemIds.push("EQ-MAR");
            itemIds.push("EQ-BAL");
        }
        else if (strEquipLocation == this.UI.ItemManager.ITEM_CODES.EQUIPMENT_BACKPACK)
        {
            list[1] = {"Id": "EQ-SAC0", "Asset":1, "Name": this.UI.LabelManager.translate("Fab2_Sacados1"), "Description": this.UI.LabelManager.translate("Fab2_Sacados1Bonus"), "requirements": this.getRequirements("1"), "CraftLevel": 1};
            list[2] = {"Id": "EQ-SAC1", "Asset":2, "Name": this.UI.LabelManager.translate("Fab2_Sacados2"), "Description": this.UI.LabelManager.translate("Fab2_Sacados2Bonus"), "requirements": this.getRequirements("2"), "CraftLevel": 2};
            list[3] = {"Id": "EQ-SAC2", "Asset":3, "Name": this.UI.LabelManager.translate("Fab2_Sacados3"), "Description": this.UI.LabelManager.translate("Fab2_Sacados3Bonus"), "requirements": this.getRequirements("3"), "CraftLevel": 3};
        }

        for (let i = 0; i < itemIds.length; i++)
        {
            list[itemIds[i]] = this.UI.ItemManager.getItem(itemIds[i]);
            list[itemIds[i]].requirements = this.getRequirements(itemIds[i]);
        }

        for (let key in list)
        {
            let bCanCraft = list[key].CraftLevel <= this.UI.WorldManager.getBarnZoneLevel(this.UI.WorldManager.BARN_ZONE_CRAFTING);
            if (strEquipLocation == this.UI.ItemManager.ITEM_CODES.EQUIPMENT_BACKPACK)
            {
                bCanCraft = bCanCraft && list[key].CraftLevel > this.UI.ItemManager.BackpackLevel;
            }
            for (let i = 0; i < list[key].requirements.length; i++)
            {
                if (list[key].requirements[i].owned < list[key].requirements[i].quantity)
                {
                    bCanCraft = false;
                    break;
                }
            }
            list[key].canCraft = bCanCraft;
        }

        return list;
    }

    getItemImage(item, bIsMaterial = false)
    {
        let img = null;

        if (this.Category != this.UI.ItemManager.ITEM_CODES.EQUIPMENT_BACKPACK || bIsMaterial)
        {
            let src = null;
            if (item.Asset)
            {
                let texture = Library.getTextureFromAtlas(item.AtlasId, item.TextureId);
                if (texture)
                {
                    img = new SpriteResponsive(texture).init({"ui": this.UI});
                }
            }

            if (!img)
            {
                console.error("Cannot find icon for item", item.Id)
            }
        }
        else
        {
            let texture = Library.getTextureFromAtlas("items3D", "bag" + item.Asset + ".png");
            if (texture)
            {
                img = new SpriteResponsive(texture).init({"ui": this.UI});
            }
        }

        return img;
    }

    getRequirements(strItemId)
    {
        let reqs = [];
        if (this.Category != this.UI.ItemManager.ITEM_CODES.EQUIPMENT_BACKPACK)
        {
            reqs = this.UI.ItemManager.getItem(strItemId).CraftRequirements;
        }
        else
        {
            reqs = this.UI.ItemManager.getBackpackCraftRequirements(strItemId);
        }

        for (let i = 0; i < reqs.length; i++)
        {
            reqs[i].owned = this.UI.ItemManager.getItemQuantity(reqs[i].item, true, -1, true);
        }

        return reqs;
    }

    getRequirementLabel(iOwned, iRequired)
    {
        return iOwned + "/" + iRequired;
    }

    getLockedLevelLabel(level)
    {
        return this.UI.LabelManager.translate("Fabr_Amelior_Titre") + " - " + this.UI.LabelManager.translate("Fabr_Amelior_Niv" + level) + " requis";
    }

    /*******************************************
    *   ACTIONS
    *******************************************/
    reset()
    {
        this.dropped = false;
        let options = {"children": true};

        for(let key in this.drags)
        {
            this.drags[key].destroy(options);
        }
        this.drags = {};

        for(let key in this.graphics)
        {
            if (this.graphics[key].detector)
            {
                this.graphics[key].detector.destroy(options);
            }
            this.removeChild(this.graphics[key]);
            this.graphics[key].destroy(options);
        }
        this.graphics = {};

        for (let key in this.sprites)
        {
            this.removeChild(this.sprites[key]);
            this.sprites[key].destroy(options);
        }
        this.sprites = {};

        for (let key in this.texts)
        {
            this.removeChild(this.texts[key]);
            this.texts[key].destroy(options);
        }
        this.texts = {};

        this.items = this.getItemList(this.category);
    }

    /*******************************************
    *   EVENTS
    *******************************************/
    onBackClick()
    {
        this.emit(this.EVENT_BACK, this);
    }

    onDragStart(sender, objContainer, fctCancelDrag)
    {
        for (let i = 0; i < this.UI.ShownPopups.length; i++)
        {
            if (this.UI.ShownPopups[i].IsDescription)
            {
                fctCancelDrag();
                return;
            }
        }

        for (let key in this.drags)
        {
            if (sender.Id != key)
            {
                this.drags[key].CanDrag = false;
            }
        }

        //Done for the element to be above the window's close button
        let parent = this.parent;
        parent.removeChild(this);
        parent.addChild(this);

        this.removeChild(objContainer);
        this.addChild(objContainer);
    }

    onDragEnd(sender, objContainer)
    {
        for (let key in this.drags)
        {
            this.drags[key].CanDrag = this.drags[key].Id.canCraft;
        }
    }

    onDragDrop(sender, objContainer, objDropZone, fctCancelDrop)
    {
        this.dropped = true;
        for (let key in this.drags)
        {
            this.drags[key].CanDrag = false;
        }
        this.emit(this.EVENT_CRAFT, sender.Id);
    }

    onDragEnterDropZone(sender)
    {
        this.sprites.dropZone.tint = this.DropZoneHoverColor;
    }

    onDragExitDropZone(sender)
    {
        this.sprites.dropZone.tint = this.DropZoneNormalColor;
    }

    onDragAnimationEnd(sender)
    {
        this.emit(this.EVENT_DRAG_ANIMATION_END, this);
    }

    onItemAdded(strItemType, iAmount)
    {
        this.reset();
        this.build();
    }
}