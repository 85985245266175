import { gtag } from "ga-gtag";
import { string2hex }  from "@pixi/utils"; 
import { TextStyle } from "@pixi/text";
import { TextMetrics } from "@pixi/text";
import CharacterFace from "../../commons/characters/CharacterFace.js";
import CloseButton from "../../commons/buttons/CloseButton.js";
import ColorTheme from "../../ColorTheme.js";
import ContainerResponsive from "../../commons/responsive/ContainerResponsive.js";
import Display from "../../../utils/Display.js";
import GraphicsResponsive from "../../commons/responsive/GraphicsResponsive.js";
import TextButton from "../../commons/buttons/TextButton.js";
import TextResponsive from "../../commons/responsive/TextResponsive.js";

export default class SaveManagementDisplay extends ContainerResponsive
{
    constructor()
    {
        super();
    }

    get EVENT_BACK() { return "back"; }
    get EVENT_CHOOSE_SAVE() { return "choose-save"; }
    get EVENT_DELETE_SAVE() { return "delete-save"; }

    get BgWidth() { return this.evaluate(this.strWidth, 0, 0, 0, 0, this); }
    get BgHeight() { return this.evaluate(this.strHeight, 0, 0, 0, 0, this); }

    get TitleLabel() { return this.UI.LabelManager.translate("Menu_Choix_Choisir").toUpperCase(); }
    get NewSaveLabel() { return this.UI.LabelManager.translate("Menu_Choix_New"); }
    get BackLabel() { return this.UI.LabelManager.translate("Menu_Choix_Back"); }
    get DeleteLabel() { return this.UI.LabelManager.translate("Menu_Efface_Effacer"); }
    get YesLabel() { return this.UI.LabelManager.translate("Menu_Efface_Oui"); }
    get NoLabel() { return this.UI.LabelManager.translate("Menu_Efface_Non"); }

    get SaveSlotZoneWidth() { return this.BgWidth; }
    get SaveSlotZoneHeight() { return this.BgHeight * 0.275; }
    get SaveSlotChooseWidth() { return this.SaveSlotZoneWidth - this.SaveSlotZoneHeight * 1.1; }
    get SaveSlotChooseHeight() { return this.SaveSlotZoneHeight; }
    get SaveSlotDeleteWidth() { return this.SaveSlotDeleteHeight; }
    get SaveSlotDeleteHeight() { return this.SaveSlotZoneHeight; }

    get BackgroundColor() { return string2hex(this.UI.Values.modal.background.color);}
    get StrokeWidth() { return this.evaluate(this.valueFormula(this.UI.Values.modal.stroke.size), 0, 0, 0, 0);}
    get StrokeColor() { return string2hex("color" in this.UI.Values.modal.stroke ? this.UI.Values.modal.stroke.color : "#000000");}
    get CornerRadius() { return this.evaluate(this.valueFormula(this.UI.Values.modal.corner.radius), 0, 0, 0, 0);}

    get SaveSlotBackgroundColor() { return string2hex(this.UI.Values.textbutton.background.color); }
    get SaveSlotStrokeWidth() { return this.evaluate(this.valueFormula(this.UI.Values.textbutton.stroke.size), 0, 0, 0, 0, this); }
    get SaveSlotStrokeColor() { return string2hex("color" in this.UI.Values.slot.stroke ? this.UI.Values.textbutton.stroke.color : "#000000"); }
    get SaveSlotCornerRadius() { return this.evaluate(this.valueFormula(this.UI.Values.textbutton.corner.radius), 0, 0, 0, 0, this); }

    getFormattedTimeLabel(iIndex)
    {
        let time = this.UI.GameManager.getGameTime(iIndex);

        let hours = Math.floor(time / 3600);
        let minutes = Math.floor(time / 60) - hours * 60;

        return hours + "h" + (minutes < 10 ? "0" : "") + minutes + "m";
    }

    /*******************************************
    *   INITIALIZATION
    *******************************************/
    /**
        Parameters to pass to the init function:
        - ui:       UI section object where this component resides
        - rWidth:   Responsive equation representing the width of this component
        - rHeight:  Responsive equation representing the height of this component
    */
    init(meta)
    {
        this.strWidth = meta.rWidth;
        this.strHeight = meta.rHeight;

        this.graphics = {};
        this.texts = {};
        this.sprites = {};
        this.buttons = {};
        this.scales = {};
        this.deleteId = null;

        return super.init(meta);
    }

    createClosure()
    {
        super.createClosure();

        this.fctOnDeleteClick = this.onDeleteClick.bind(this);
        this.fctOnDeletePositive = this.onDeletePositive.bind(this);
        this.fctOnDeleteNegative = this.onDeleteNegative.bind(this);
        this.fctOnSaveClick = this.onSaveClick.bind(this);
        this.fctOnBackClick = this.onBackClick.bind(this);
        this.fctOnWindowMouseUp = this.onWindowMouseUp.bind(this);
        this.fctOnWindowTouchEnd = this.onWindowTouchEnd.bind(this);
    }

    bindEvents()
    {
        super.bindEvents();

        window.addEventListener("mouseup", this.fctOnWindowMouseUp);
        window.addEventListener("touchend", this.fctOnWindowTouchEnd);
    }

    destroy(options)
    {
        if (this.buttons && this.buttons.deletePositive)
        {
            this.buttons.deletePositive.off(this.buttons.deletePositive.EVENT_CLICK, this.fctOnDeletePositive);
        }

        if (this.buttons && this.buttons.deleteNegative)
        {
            this.buttons.deleteNegative.off(this.buttons.deletePositive.EVENT_CLICK, this.fctOnDeleteNegative);
        }

        for (let key in this.buttons)
        {
            this.buttons[key].off(this.buttons[key].EVENT_CLICK, this.fctOnDeleteClick);
            this.buttons[key].destroy(options);
        }
        delete this.buttons;

        for (let key in this.graphics)
        {
            this.graphics[key].off("pointerdown", this.graphics[key].fctDown);
            this.graphics[key].off("pointerup", this.graphics[key].fctUp);
            this.graphics[key].clear();
            this.graphics[key].destroy(options);
        }
        delete this.graphics;

        window.removeEventListener("mouseup", this.fctOnWindowMouseUp);
        window.removeEventListener("touchend", this.fctOnWindowTouchEnd);

        super.destroy(options);
    }

    /*******************************************
    *   BUILD
    *******************************************/
    build()
    {
        super.build();

        
        let titleValues = this.buildTitle();
        let saveSlotsValues = this.buildSaveSlots(titleValues.y, titleValues.height);
        this.buildBackButton(saveSlotsValues.y, saveSlotsValues.height);
        this.buildDeleteWindow(this.deleteId);
    }

    clean()
    {
        for (let key in this.graphics)
        {
            this.graphics[key].clear();
        }
        super.clean();
    }

    buildTitle()
    {
        if (!this.texts.title)
        {
            this.texts.title = new TextResponsive().init({"ui": this.UI});
            this.addChild(this.texts.title);
        }

        if (this.deleteId !== null)
        {
            this.removeChild(this.texts.title);
        }
        else if (!this.texts.title.parent)
        {
            this.addChild(this.texts.title);
        }

        let text = this.texts.title;
        let label = this.TitleLabel;

        let fontSize = Math.max(this.evaluate(this.valueFormula(this.UI.Values.general.title.size)), 25);
        let style = new TextStyle({
            fontFamily: this.UI.Values.general.title.font,
            fontSize:  fontSize ,
            fontWeight: this.UI.Values.general.title.weight,
            fill: this.UI.Values.general.text.color,
            align: "center"
        });

        let metrics = TextMetrics.measureText(label, style);

        let x = this.BgWidth / 2 - metrics.width / 2;
        let y = 0;

        text.text = label;
        text.style = style;
        text.width = metrics.width;
        text.height = metrics.height;
        text.position.set(x, y);

        return {"y":0, "height": metrics.height};
    }

    buildSaveSlots(fTitleY, fTitleHeight)
    {
        let slotCount = this.UI.SaveManager.SAVE_SLOT_COUNT;

        let paddingVertical = this.BgHeight * 0.125;

        let slotWidth = this.SaveSlotZoneWidth;
        let slotHeight = this.SaveSlotZoneHeight;
        let slotX = 0;
        let slotY = fTitleY + fTitleHeight + slotHeight / 4 + paddingVertical;

        let chooseWidth = this.SaveSlotChooseWidth;
        let chooseHeight = this.SaveSlotChooseHeight;
        let chooseX = slotX;

        let deleteWidth = this.SaveSlotDeleteWidth;
        let deleteHeight = this.SaveSlotDeleteHeight;
        let deleteX = slotX + slotWidth - deleteWidth;

        for (let i = 0; i < slotCount; i++)
        {
            if (i > 0)
            {
                slotY += paddingVertical + slotHeight;
            }

            this.buildSaveSlotChoose(i, chooseX, slotY, chooseWidth, chooseHeight);
            this.buildSaveSlotDelete(i, deleteX, slotY, deleteWidth, deleteHeight);
        }

        return {"y":slotY, "height": slotHeight};
    }

    buildSaveSlotChoose(iIndex, fX, fY, fWidth, fHeight, bIsClickable = true, bBuildForDelete = false)
    {
        if (!this.graphics["choose_" + iIndex])
        {
            this.graphics["choose_" + iIndex] = new GraphicsResponsive().init({"ui":this.UI});
            this.graphics["choose_" + iIndex].id = iIndex;
            this.graphics["choose_" + iIndex].interactive = true;
            this.graphics["choose_" + iIndex].fctDown = this.onSavePointerDown.bind(this, "choose_" + iIndex);
            this.graphics["choose_" + iIndex].fctUp = this.onSaveClick.bind(this, this.graphics["choose_" + iIndex]);
            this.graphics["choose_" + iIndex].on("pointerdown", this.graphics["choose_" + iIndex].fctDown)//@
            this.graphics["choose_" + iIndex].on("pointerup", this.graphics["choose_" + iIndex].fctUp);//@
            this.graphics["choose_" + iIndex].pivot.set(0.5, 0.5);
            this.scales["choose_" + iIndex] = {"x": this.UI.Values.textbutton.scale.normal, "y":this.UI.Values.textbutton.scale.normal};

            this.graphics["choose_" + iIndex].sprites = {};
            this.graphics["choose_" + iIndex].texts = {};

            this.addChild(this.graphics["choose_" + iIndex]);
        }
        else
        {
            for (let key in this.graphics["choose_" + iIndex].sprites)
            {
                this.graphics["choose_" + iIndex].removeChild(this.graphics["choose_" + iIndex].sprites[key]);
            }

            for (let key in this.graphics["choose_" + iIndex].texts)
            {
                this.graphics["choose_" + iIndex].removeChild(this.graphics["choose_" + iIndex].texts[key]);
            }
        }

        if (this.deleteId !== null && !bBuildForDelete)
        {
            this.removeChild(this.graphics["choose_" + iIndex]);
        }
        else if (!this.graphics["choose_" + iIndex].parent)
        {
            this.addChild(this.graphics["choose_" + iIndex]);
        }

        let graphics = this.graphics["choose_" + iIndex];
        let scale = this.scales["choose_" + iIndex];

        graphics.buttonMode = bIsClickable;
        graphics.isClickable = bIsClickable;

        let bgColor = this.SaveSlotBackgroundColor;
        let strokeWidth = this.SaveSlotStrokeWidth;
        let strokeColor = this.SaveSlotStrokeColor;
        let cornerRadius = this.SaveSlotCornerRadius;

        let x = fX + fWidth * (1 - scale.x) / 2;
        let y = fY + fHeight * (1 - scale.y) / 2;

        graphics.scale.set(scale.x, scale.y);
        graphics.position.set(x, y);

        if (strokeWidth > 0)
        {
            graphics.lineStyle({"color":strokeColor, "width":strokeWidth, "alignment":1});
        }

        graphics.beginFill(bgColor, 1);
        if (cornerRadius > 0)
        {
            graphics.drawRoundedRect(0, 0, fWidth, fHeight, cornerRadius);
        }
        else
        {
            graphics.drawRect(0, 0, fWidth, fHeight);
        }
        graphics.endFill();

        if (!this.UI.SaveManager.getIsSlotEmpty(iIndex))
        {
            this.buildSaveSlotChooseTimeText(iIndex, fWidth, fHeight, graphics);
            this.buildSaveSlotChooseSprites(iIndex, fWidth, fHeight, graphics);
        }
        else
        {
            this.buildSaveSlotChooseNewGameText(iIndex, fWidth, fHeight, graphics);
        }
    }

    buildSaveSlotChooseTimeText(iIndex, fWidth, fHeight, objParent)
    {
        if (!objParent.texts.timeLabel)
        {
            objParent.texts.timeLabel = new TextResponsive().init({"ui": this.UI});
        }

        let text = objParent.texts.timeLabel;
        let label = this.getFormattedTimeLabel(iIndex);

        let fontSize = Math.max(this.evaluate(this.valueFormula(this.UI.Values.textbutton.text.size)), 25);

        let style = new TextStyle({
            fontFamily: this.UI.Values.textbutton.text.font,
            fontSize:   fontSize,
            fontWeight: this.UI.Values.textbutton.text.weight,
            fill: this.UI.Values.textbutton.text.color,
            align: "center"
        });

        let metrics = TextMetrics.measureText(label, style);

        let x = fWidth * 0.95 - metrics.width;
        let y = fHeight / 2 - metrics.height / 2;

        text.text = label;
        text.style = style;
        text.width = metrics.width;
        text.height = metrics.height;
        text.position.set(x, y);

        objParent.addChild(objParent.texts.timeLabel);
    }

    buildSaveSlotChooseNewGameText(iIndex, fWidth, fHeight, objParent)
    {
        if (!objParent.texts.newGameLabel)
        {
            objParent.texts.newGameLabel = new TextResponsive().init({"ui": this.UI});
        }

        let text = objParent.texts.newGameLabel;
        let label = this.NewSaveLabel;

        let fontSize = Math.max(this.evaluate(this.valueFormula(this.UI.Values.textbutton.text.size)), 25);

        let style = new TextStyle({
            fontFamily: this.UI.Values.textbutton.text.font,
            fontSize:   fontSize,
            fontWeight: this.UI.Values.textbutton.text.weight,
            fill: this.UI.Values.textbutton.text.color,
            align: "center"
        });

        let metrics = TextMetrics.measureText(label, style);

        let x = fWidth * 0.1;
        let y = fHeight / 2 - metrics.height / 2;

        text.text = label;
        text.style = style;
        text.width = metrics.width;
        text.height = metrics.height;
        text.position.set(x, y);

        objParent.addChild(objParent.texts.newGameLabel);
    }

    buildSaveSlotChooseSprites(iIndex, fWidth, fHeight, objParent)
    {
        let x = fWidth * 0.05;
        let rHeight = "(((" + this.strHeight + ") * 0.275) * 1.75)";
        let spriteHeight = this.evaluate(rHeight, 0, 0, 0, 0, this);
        for (let i = 0; i < 3; i++)
        {
            let key = "charSprite_" + iIndex + "_" + i;
            if (!objParent.sprites[key])
            {
                let oldSave = this.UI.SaveManager.SaveSlot;
                this.UI.SaveManager.SaveSlot = iIndex;

                let build = this.UI.CharacterManager.getCharacterBuild(i);
                objParent.sprites[key] = new CharacterFace().init({"ui": this.UI, rHeight, build});

                this.UI.SaveManager.SaveSlot = oldSave;
            }

            objParent.sprites[key].rWidth =  [{on:"default", width: rHeight + " * 0.85"}];
            objParent.sprites[key].rHeight =  [{on:"default", height: rHeight}];

            let sprite = objParent.sprites[key];
            sprite.position.set(
                x,
                fHeight / 2 - spriteHeight / 4.33
            );

            x += spriteHeight * 0.45;

            objParent.addChild(objParent.sprites[key]);
        }
    }

    buildSaveSlotDelete(iIndex, fX, fY, fWidth, fHeight)
    {
        if (!this.buttons["delete_" + iIndex])
        {
            this.buttons["delete_" + iIndex] = new CloseButton().init({"ui": this.UI, "scale": 0.9});
            this.buttons["delete_" + iIndex].id = iIndex;
            this.buttons["delete_" + iIndex].on(this.buttons["delete_" + iIndex].EVENT_CLICK, this.fctOnDeleteClick);//@

            this.addChild(this.buttons["delete_" + iIndex]);
        }

        if (this.deleteId !== null)
        {
            this.removeChild(this.buttons["delete_" + iIndex]);
        }
        else if (!this.buttons["delete_" + iIndex].parent)
        {
            this.addChild(this.buttons["delete_" + iIndex]);
        }

        let button = this.buttons["delete_" + iIndex];
        button.IsDisabled = this.UI.SaveManager.getIsSlotEmpty(iIndex);
        button.rX = [{on:"default", x: fX + " + " + fWidth + " - " + this.SaveSlotDeleteWidth}];
        button.rY = [{on:"default", y: fY + " + " + fHeight + " / 2 -  " + this.buttons["delete_" + iIndex].ButtonHeight + " / 2"}];

        button.layout(Display.getSize());
    }

    buildBackButton(fSlotsY, fSlotsHeight)
    {
        if (!this.buttons.back)
        {
            this.buttons.back = new TextButton().init({
                "ui": this.UI,
                "text": this.BackLabel,
                "fontSize": 45,
                "padding": {
                    "horizontal": 75
                }
            });
            this.buttons.back.on(this.buttons.back.EVENT_CLICK, this.fctOnBackClick);//@
            this.addChild(this.buttons.back);
        }

        if (this.deleteId !== null)
        {
            this.removeChild(this.buttons.back);
        }
        else if (!this.buttons.back.parent)
        {
            this.addChild(this.buttons.back);
        }

        let button = this.buttons.back;
        let size = button.getButtonSize();

        button.rX = [{on:"default", x: this.BgWidth / 2 - size.width / 2}];
        button.rY = [{on:"default", y: fSlotsY + fSlotsHeight * 1.5}];

        button.layout(Display.getSize());
    }

    buildDeleteWindow(iIndex)
    {
        if (this.deleteId === null)
        {
            if (this.graphics.deleteWindowBg && this.graphics.deleteWindowBg.parent)
            {
                this.removeChild(this.graphics.deleteWindowBg);
            }
            if (this.texts.deleteSlotTitle && this.texts.deleteSlotTitle.parent)
            {
                this.removeChild(this.texts.deleteSlotTitle);
            }
            if (this.buttons.deletePositive && this.buttons.deletePositive.parent)
            {
                this.removeChild(this.buttons.deletePositive);
            }
            if (this.buttons.deleteNegative && this.buttons.deleteNegative.parent)
            {
                this.removeChild(this.buttons.deleteNegative);
            }
        }
        else
        {
            let bgValues = this.buildDeleteWindowBackground();
            this.buildDeleteWindowText(bgValues.x, bgValues.y, bgValues.width, bgValues.height);
            this.buildSaveSlotChoose(
                iIndex,
                bgValues.x + bgValues.width / 2 - this.SaveSlotChooseWidth / 2,
                bgValues.y + bgValues.height / 2 - this.SaveSlotChooseHeight / 2 - bgValues.height * 0.0275 ,
                this.SaveSlotChooseWidth,
                this.SaveSlotChooseHeight,
                false,
                true
            );
            this.buildDeleteWindowButtons(bgValues.x, bgValues.y, bgValues.width, bgValues.height);
        }
    }

    buildDeleteWindowBackground()
    {
        if (!this.graphics.deleteWindowBg)
        {
            this.graphics.deleteWindowBg = new GraphicsResponsive().init({"ui":this.UI});
            this.graphics.deleteWindowBg.interactive = true;

            this.addChild(this.graphics.deleteWindowBg);
        }
        else if (!this.graphics.deleteWindowBg.parent)
        {
            this.addChild(this.graphics.deleteWindowBg);
        }

        let graphics = this.graphics.deleteWindowBg;

        let width = this.BgWidth;
        let height = this.BgHeight * 1.25;
        let x = this.BgWidth / 2 - width / 2;
        let y = this.BgHeight * 0.175;

        graphics.position.set(x, y);

        let bgColor = this.BackgroundColor;
        let strokeWidth = this.StrokeWidth;
        let strokeColor = this.StrokeColor;
        let cornerRadius = this.CornerRadius;

        if (strokeWidth > 0)
        {
            graphics.lineStyle({"color":strokeColor, "width":strokeWidth, "alignment":1});
        }

        graphics.beginFill(bgColor, 1);
        if (cornerRadius > 0)
        {
            graphics.drawRoundedRect(0, 0, width, height, cornerRadius);
        }
        else
        {
            graphics.drawRect(0, 0, width, height);
        }
        graphics.endFill();

        return {"x": x, "y": y, "width": width, "height": height};
    }

    buildDeleteWindowText(fBgX, fBgY, fBgWidth, fBgHeight)
    {
        if (!this.texts.deleteSlotTitle)
        {
            this.texts.deleteSlotTitle = new TextResponsive().init({"ui": this.UI});
            this.addChild(this.texts.deleteSlotTitle);
        }
        else if (!this.texts.deleteSlotTitle.parent)
        {
            this.addChild(this.texts.deleteSlotTitle);
        }

        let text = this.texts.deleteSlotTitle;
        let label = this.DeleteLabel;

        let fontSize = Math.max(this.evaluate(this.valueFormula(this.UI.Values.general.title.size)), 25);

        let style = new TextStyle({
            fontFamily: this.UI.Values.general.title.font,
            fontSize:   fontSize,
            fontWeight: this.UI.Values.general.title.weight,
            fill: this.UI.Values.general.text.color,
            align: "center"
        });

        let metrics = TextMetrics.measureText(label, style);

        let x = fBgX + fBgWidth / 2 - metrics.width / 2;
        let y = fBgY + fBgHeight * 0.1;

        text.text = label;
        text.style = style;
        text.width = metrics.width;
        text.height = metrics.height;
        text.position.set(x, y);
    }

    buildDeleteWindowButtons(fBgX, fBgY, fBgWidth, fBgHeight)
    {
        if (!this.buttons.deletePositive)
        {
            this.buttons.deletePositive = new TextButton().init({
                "ui": this.UI,
                "text": this.YesLabel,
                "fontSize": 40
            });
            this.buttons.deletePositive.on(this.buttons.deletePositive.EVENT_CLICK, this.fctOnDeletePositive);//@
            this.addChild(this.buttons.deletePositive);
        }

        if (!this.buttons.deleteNegative)
        {
            this.buttons.deleteNegative = new TextButton().init({
                "ui": this.UI,
                "text": this.NoLabel,
                "fontSize": 40
            });
            this.buttons.deleteNegative.on(this.buttons.deleteNegative.EVENT_CLICK, this.fctOnDeleteNegative);//@
            this.addChild(this.buttons.deleteNegative);
        }

        let positive = this.buttons.deletePositive;
        let positiveSize = positive.getButtonSize();

        let negative = this.buttons.deleteNegative;
        let negativeSize = negative.getButtonSize();

        positive.rX = [{on:"default", x: fBgX + fBgWidth / 2 - fBgWidth * 0.05 - positiveSize.width}];
        positive.rY = [{on:"default", y: fBgY + fBgHeight * 0.9 - positiveSize.height}];

        negative.rX = [{on:"default", x: fBgX + fBgWidth / 2 + fBgWidth * 0.05}];
        negative.rY = [{on:"default", y: fBgY + fBgHeight * 0.9 - negativeSize.height}];

        this.removeChild(positive);
        this.addChild(positive);

        this.removeChild(negative);
        this.addChild(negative);
    }

    //---------------------------------------------------
    //  EVENTS
    //---------------------------------------------------
    onDeleteClick(sender)
    {
        this.deleteId = sender.id;
        this.build();
    }

    onDeletePositive()
    {
        this.emit(this.EVENT_DELETE_SAVE, this, this.deleteId);
        this.deleteId = null;
        this.build();
    }

    onDeleteNegative()
    {
        this.deleteId = null;
        this.build();
    }

    onSaveClick(sender)
    {
        if (sender.isClickable)
        {
            if (gtag)
            {
                gtag('event', 'startGame');
            }

            this.emit(this.EVENT_CHOOSE_SAVE, this, sender.id);
        }
    }

    onBackClick(sender)
    {
        this.emit(this.EVENT_BACK, this);
    }

    onSavePointerDown(strId)
    {
        if (this.graphics[strId].isClickable)
        {
            this.scales[strId] = {"x": this.UI.Values.textbutton.scale.down, "y": this.UI.Values.textbutton.scale.down};
            this.build();
        }
    }

    onWindowMouseUp(e)
    {
        for(let key in this.graphics)
        {
            if (key.indexOf("choose_") >= 0)
            {
                this.scales[key] = {"x": this.UI.Values.textbutton.scale.normal, "y": this.UI.Values.textbutton.scale.normal};
            }
        }
        this.build();
    }

    onWindowTouchEnd(e)
    {
        for(let key in this.graphics)
        {
            if (key.indexOf("choose_") >= 0)
            {
                this.scales[key] = {"x": this.UI.Values.textbutton.scale.normal, "y": this.UI.Values.textbutton.scale.normal};
            }
        }
        this.build();
    }
}