import { Point } from '@pixi/math';
import EventEmitter from "eventemitter3";

export default class UnlockPath extends EventEmitter
{
    constructor()
    {
        super();
    }

    get UI() { return this.ui; }
    get Id() { return this.id; }
    get IsShown() { return this.isShown; }
    get Path() { return this.path; }

    get Graphics() { return this.graphics; }
    get LineWidth() { return "(ih * 0.0185)"; }
    get LineColor() { return this.color; }

    /*******************************************
    *   INITIALIZATION
    *******************************************/
    /**
        Parameters to pass to the init function:
        - ui:       UI section object where this component resides
        - id:       Id to associate with this path
        - graphics: Graphics components to use to draw the path on screen
        - color:    Hex color that should be used to draw the path on screen
    */
    init(meta)
    {
        this.ui = meta.ui;
        this.id = meta.id;
        this.graphics = meta.graphics;
        this.color = meta.color;

        this.path = [];
        this.isShown = true;

        return this;
    }

    /*******************************************
    *   BUILD
    *******************************************/
    buildPath(arrCells)
    {
        if (this.IsShown && this.path.length > 1)
        {
            let lineWidth = this.UI.evaluate(this.LineWidth, 0, 0, 0, 0);

            this.Graphics.lineStyle({"color":this.LineColor, "width":lineWidth, "alignment":0.5});

            for (let i = 0; i < this.path.length; i++)
            {
                let rect = arrCells[this.path[i].y][this.path[i].x].rect;

                if (i == 0)
                {
                    this.Graphics.moveTo(rect.x + rect.width / 2, rect.y + rect.height / 2);

                    let point = this.calculateLinePoint(arrCells[this.path[i+1].y][this.path[i+1].x].rect, this.path[i], this.path[i+1]);
                    this.Graphics.lineTo(point.x, point.y);
                }
                else if (i == this.path.length - 1)
                {
                    this.Graphics.lineTo(rect.x + rect.width / 2, rect.y + rect.height / 2);
                }
                else if (this.path[i-1].x == this.path[i+1].x || this.path[i-1].y == this.path[i+1].y)
                {
                    let point = this.calculateLinePoint(arrCells[this.path[i+1].y][this.path[i+1].x].rect, this.path[i], this.path[i+1]);
                    this.Graphics.lineTo(point.x, point.y);
                }
                else
                {  
                    let prev =  arrCells[this.path[i-1].y][this.path[i-1].x].rect;
                    let next = arrCells[this.path[i+1].y][this.path[i+1].x].rect;

                    let controlPoint = this.calculateControlPoint(
                        new Point(prev.x, prev.y), 
                        new Point(rect.x, rect.y), 
                        new Point(next.x, next.y)
                    );

                    let point = this.calculateLinePoint(arrCells[this.path[i+1].y][this.path[i+1].x].rect, this.path[i], this.path[i+1]);
                    this.Graphics.quadraticCurveTo(controlPoint.x, controlPoint.y, point.x, point.y);
                }
            }

            this.Graphics.lineStyle(null);
        }
    }

    /*******************************************
    *   ACTIONS
    *******************************************/
    addCell(x, y)
    {
        let newPath = [];
        for (let i = 0; i < this.path.length; i++)
        {
            if (this.path[i].x == x && this.path[i].y == y)
            {
                break;
            }
            newPath.push(this.path[i]);
        }

        newPath.push(new Point(x, y));
        this.path = newPath;
    }

    contains(x, y)
    {
        for (let i = 0; i < this.path.length; i++)
        {
            if (this.path[i].x == x && this.path[i].y == y)
            {
                return true;
            }
        }
        return false;
    }

    show()
    {
        this.isShown = true;
    }

    hide()
    {
        this.isShown = false;
    }

    /*******************************************
    *   UTILITIES
    *******************************************/
    containsCell(iX, iY)
    {
        for (let i = 0; i < this.path.length; i++)
        {
            if (this.path[i].x == iX && this.path[i].y == iY)
            {
                return true;
            }
        }
        return false;
    }

    calculateLinePoint(objRect, objPrev, objNext)
    {
        let point = new Point(objRect.x, objRect.y);

        if (objPrev.x == objNext.x)
        {
            point.x += objRect.width / 2;
            if (objPrev.y > objNext.y)
            {
                point.y += objRect.height;
            }
        }
        else
        {
            point.y += objRect.width / 2;
            if (objPrev.x > objNext.x)
            {
                point.x += objRect.width;
            }
        }
        return point;
    }

    calculateControlPoint(objPrev, objCurrent, objNext)
    {
        let diff = {"x": objNext.x - objPrev.x, "y": objNext.y - objPrev.y};
        let controlPoint = new Point();

        for (let key in diff)
        {
            if (diff[key] > 0)
            {
                if (objCurrent[key] - objPrev[key] > 0)
                {
                    controlPoint[key] = objNext[key] + Math.abs(diff[key]) / 2;
                }
                else
                {
                    controlPoint[key] = objPrev[key] + Math.abs(diff[key]) / 2;
                }
            }
            else
            {
                if (objCurrent[key] - objPrev[key] < 0)
                {
                    controlPoint[key] = objNext[key] + Math.abs(diff[key]) / 2;
                }
                else
                {
                    controlPoint[key] = objPrev[key] + Math.abs(diff[key]) / 2;
                }
            }
        }

        return controlPoint;
    }
}