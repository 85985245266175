import { string2hex }  from '@pixi/utils'; 
import ContainerResponsive from "../responsive/ContainerResponsive.js";
import GraphicsResponsive from "../responsive/GraphicsResponsive.js";

export default class SliderControl extends ContainerResponsive
{
    constructor ()
    {
        super();
    }

    get EVENT_VALUE_CHANGED() { return "value-changed"; }
    get EVENT_DRAG_END() { return "drag-end"; }

    get Id() { return this.id; }
    set Id(strNewValue) { this.id = strNewValue; }

    get Value() { return this.value; }
    set Value(fNewValue) { this.value = Math.max(0, Math.min(1, fNewValue)); this.build(); }

    get CanDrag() { return this.canDrag; }
    set CanDrag(bNewValue) { this.canDrag = bNewValue; }

    get IsDragging() { return this.dragInput !== null; }
    get BarWidth() { return this.barSize.width; }
    get BarHeight() { return this.evaluate(this.valueFormula(20), 0, 0, 0, 0); }
    get HandleWidth() { return this.BarHeight; }
    get HandleHeight() { return this.BarHeight * 2.33; }

    get BarBackgroundColor() { return string2hex(this.UI.Values.slider.bar.background.color);}
    get BarCornerRadius() { return this.evaluate(this.valueFormula(this.UI.Values.slider.bar.corner.radius), 0, 0, 0, 0);}
    get HandleBackgroundColor() { return string2hex(this.UI.Values.slider.handle.background.color);}
    get HandleCornerRadius() { return this.evaluate(this.valueFormula(this.UI.Values.slider.handle.corner.radius), 0, 0, 0, 0);}

    get Rect()
    {
        let rect = this.toGlobal({"x": 0, "y": this.BarHeight / 2 - this.HandleHeight / 2});
        rect.x += this.UI.ResponsiveManager.ScreenPadding;
        
        rect.width = this.BarWidth;
        rect.height = this.HandleHeight;
        rect.xMax = rect.x + rect.width;
        rect.yMax = rect.y + rect.height;

        return rect;
    }

    get Size()
    {
        return {"width": this.BarWidth, "height": this.HandleHeight};
    }

    /*******************************************
    *   INITIALIZATION
    *******************************************/
    /**
        Parameters to pass to the init function:
        - ui:       UI section object where this component resides
        - value:    Initial value of the slider (between 0 and 1)
        - id:       (Optional) Identifier for this object. Default is NULL
    */
    init(meta)
    {
        this.value = Math.max(0, Math.min(1, meta.value));
        this.id = ("id" in meta ? meta.id : null);
        this.canDrag = true;
        this.barSize = {"width": 0, "height": 0};
        this.dragInput = null;

        this.graphics = {};

        return super.init(meta);
    }

    createClosure()
    {
        super.createClosure();

        this.fctOnMouseDown = this.onMouseDown.bind(this);
        this.fctOnMouseMove = this.onMouseMove.bind(this);
        this.fctOnMouseUp = this.onMouseUp.bind(this);

        this.fctOnTouchStart = this.onTouchStart.bind(this);
        this.fctOnTouchMove = this.onTouchMove.bind(this);
        this.fctOnTouchEnd = this.onTouchEnd.bind(this);
    }

    bindEvents()
    {
        super.bindEvents();

        window.addEventListener("mousedown", this.fctOnMouseDown);
        window.addEventListener("mousemove", this.fctOnMouseMove);
        window.addEventListener("mouseup", this.fctOnMouseUp);

        window.addEventListener("touchstart", this.fctOnTouchStart);
        window.addEventListener("touchmove", this.fctOnTouchMove);
        window.addEventListener("touchend", this.fctOnTouchEnd);
    }

    destroy(options)
    {
        window.removeEventListener("mousedown", this.fctOnMouseDown);
        window.removeEventListener("mousemove", this.fctOnMouseMove);
        window.removeEventListener("mouseup", this.fctOnMouseUp);

        window.removeEventListener("touchstart", this.fctOnTouchStart);
        window.removeEventListener("touchmove", this.fctOnTouchMove);
        window.removeEventListener("touchend", this.fctOnTouchEnd);

        for (let key in this.graphics)
        {
            this.removeChild(this.graphics[key]);
            this.graphics[key].destroy(options);
        }
        this.graphics = null;

        super.destroy(options);
    }

    /*******************************************
    *   BUILD
    *******************************************/
    build()
    {
        super.build();

        this.buildBar();
        this.buildHandle();
    }

    clean()
    {
        for (let key in this.graphics)
        {
            this.graphics[key].clear();
        }
        super.clean();
    }

    buildBar()
    {
        if (!this.graphics.bar)
        {
            this.graphics.bar = new GraphicsResponsive().init({"ui": this.UI});
            this.graphics.bar.interactive = true;
            this.addChild(this.graphics.bar);
        }

        let graphics = this.graphics.bar;

        let bgColor = this.BarBackgroundColor;
        let cornerRadius = this.BarCornerRadius;

        graphics.beginFill(bgColor, 1);
        if (cornerRadius > 0)
        {
            graphics.drawRoundedRect(0, 0, this.BarWidth, this.BarHeight, cornerRadius);
        }
        else
        {
            graphics.drawRect(0, 0, this.BarWidth, this.BarHeight);
        }
        graphics.endFill();
    }

    buildHandle()
    {
        if (!this.graphics.handle)
        {
            this.graphics.handle = new GraphicsResponsive().init({"ui": this.UI});
            this.graphics.handle.interactive = true;
            this.graphics.handle.buttonMode = true;
            this.addChild(this.graphics.handle);
        }

        let graphics = this.graphics.handle;

        let handleWidth = this.HandleWidth;
        let handleHeight = this.HandleHeight;
        let handleX = this.BarWidth * this.Value - handleWidth / 2;
        let handleY = this.BarHeight / 2 - handleHeight / 2;

        graphics.position.set(handleX, handleY);

        let bgColor = this.HandleBackgroundColor;
        let cornerRadius = this.HandleCornerRadius;

        graphics.beginFill(bgColor, 1);
        if (cornerRadius > 0)
        {
            graphics.drawRoundedRect(0, 0, handleWidth, handleHeight, cornerRadius);
        }
        else
        {
            graphics.drawRect(0, 0, handleWidth, handleHeight);
        }
        graphics.endFill();
    }

    startDrag(dragInput, iX, iY)
    {
        let rect = this.Rect;
        if (iX >= rect.x && iX <= rect.xMax && iY >= rect.y && iY <= rect.yMax)
        {
            this.dragInput = dragInput;
            this.lastInput = {"x": iX, "y": iY};

            this.moveDrag(iX, iY);
            return true;
        }

        return false;
    }

    moveDrag(iX, iY)
    {
        this.lastInput = {"x": iX, "y": iY};
        
        let rect = this.Rect;

        let x = Math.max(rect.x, Math.min(rect.xMax, iX)) - rect.x;
        let newValue = x / rect.width;

        this.graphics.handle.position.set(x - this.HandleWidth / 2, this.graphics.handle.position.y);

        if (newValue != this.Value)
        {
            this.value = newValue;
            this.emit(this.EVENT_VALUE_CHANGED, this, this.Value);
        }
    }

    endDrag()
    {
        this.dragInput = null;
        this.emit(this.EVENT_DRAG_END, this);
    }

    /*******************************************
    *   EVENTS
    *******************************************/
    onMouseDown(e)
    {
        if (!this.IsDragging && this.CanDrag)
        {
            this.startDrag(1, e.clientX, e.clientY);
        }
    }

    onMouseMove(e)
    {
        if (this.IsDragging)
        {
            this.moveDrag(e.clientX, e.clientY);
        }
    }

    onMouseUp(e)
    {
        if (this.IsDragging)
        {
            this.endDrag();
        }
    }

    onTouchStart(e)
    {
        if (!this.IsDragging && this.CanDrag)
        {
            let touch = e.touches[0];
            this.startDrag(touch.identifier, touch.clientX, touch.clientY)
        }
    }

    onTouchMove(e)
    {
        if (this.IsDragging)
        {
            for(let i = 0; i < e.touches.length; i++)
            {
                if (e.touches[i].identifier == this.dragInput)
                {
                    let touch = e.touches[i];
                    this.moveDrag(touch.clientX, touch.clientY);
                }
            }
        }
    }

    onTouchEnd(e)
    {
        if (this.IsDragging)
        {
            let isOk = false;
            if (e.touches.length > 0)
            {
                for(let i = 0; i < e.touches.length; i++)
                {
                    if (e.touches[i].identifier == this.dragInput)
                    {
                        isOk = true;
                        break;
                    }
                }
            }
            if (!isOk)
            {
                this.endDrag();
            }
        }
    }
}