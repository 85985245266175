import { gtag } from "ga-gtag";
import CraftingCategoriesContent from "./CraftingCategoriesContent.js";
import CraftingItemContent from "./CraftingItemContent.js";
import DualTabPopup from "../../commons/popups/DualTabPopup.js";
import Library from "../../../Library.js";
import SpriteResponsive from "../../commons/responsive/SpriteResponsive.js";
import UpgradeZoneContent from "./UpgradeZoneContent.js";

export default class CraftingZonePopup extends DualTabPopup
{
    constructor()
    {
        super();
    }

    get EVENT_CLOSE() { return "crafting-zone-window-close"; }

    get TabsDefinition()
    {
        let def = {
            "main": {
                "label": this.TabMainName,
                "build": this.buildCraftingTabContent.bind(this),
            },
            "upgrade": {
                "label": this.TabUpgradeName,
                "build": this.buildUpgradeTabContent.bind(this),
            }
        };
        return def;
    }

    get PauseOnShow() { return false; }
    get ZoneId() { return this.UI.WorldManager.BARN_ZONE_CRAFTING; }
    get ZoneName() { return this.UI.LabelManager.translate("Fabr_Amelior_Titre"); }
    get ZoneLevel() { return this.UI.WorldManager.getBarnZoneLevel(this.ZoneId); }
    get TabMainName() { return this.UI.LabelManager.translate("Fabr_Fabriquer"); }
    get TabUpgradeName() { return this.UI.LabelManager.translate("Fabr_Amelior"); }

    getLevelImage(level)
    {
        let img = null;

        if (level >= 1 && level <= 3)
        {
            img = new SpriteResponsive(
                Library.getTextureFromAtlas("barn", this.UI.getZoneTextureId(this.ZoneId, level))
            )
            .init({"ui": this.UI});
        }

        return img;
    }

    /*******************************************
    *   INITIALIZATION
    *******************************************/
    /**
        Parameters to pass to the init function:
        - ui:           UI section object where this component resides
        - clickToHide:  (Optional) If the popup should hide when the player clicks on the overlay behind. Default is TRUE
        - showOnStart   (Optional) If the popup should be shown on start. Default is TRUE
        - showClose:    (Optional) If the close button should be shown. Default is TRUE
        - createOverlay (Optional) If this popup should create it's own overlay for itself. If not, it will use the general UI overlay. Default is FALSE
        - sideBySide    (Optional) If this popup should be positioned side-by-side with other popups on screen. Default is TRUE
        - linked        (Optional) Linked popup to this one. If this one closes, the linked popup will close too. Default is NULL
    */
    init(meta)
    {
        this.ui = meta.ui;
        this.categoryId = null;

        return super.init(meta);
    }


    createClosure ()
    {
        super.createClosure();

        this.fctOnUpgrade = this.onUpgrade.bind(this);
        this.fctOnCategorySelect = this.onCategorySelect.bind(this);
        this.fctOnCraftItem = this.onCraftItem.bind(this);
        this.fctOnCraftBack = this.onCraftBack.bind(this);
        this.fctOnCraftAnimationEnd = this.onCraftAnimationEnd.bind(this);
    }

    bindEvents()
    {
        super.bindEvents();
    }

    destroy(options)
    {
        if (this.categoriesList)
        {
            this.categoriesList.off(this.categoriesList.EVENT_SELECT, this.fctOnCategorySelect);
            this.categoriesList.destroy(options);
        }

        if (this.craftingItem)
        {
            this.craftingItem.off(this.craftingItem.EVENT_CRAFT, this.fctOnCraftItem);
            this.craftingItem.off(this.craftingItem.EVENT_BACK, this.fctOnCraftBack);
            this.craftingItem.off(this.craftingItem.EVENT_DRAG_ANIMATION_END, this.fctOnCraftAnimationEnd);
            this.craftingItem.destroy(options);
        }

        if (this.upgrade)
        {
            this.upgrade.off(this.upgrade.EVENT_UPGRADE, this.fctOnUpgrade);
            this.upgrade.destroy(options);
        }

        super.destroy();
    }

    /*******************************************
    *   BUILD
    *******************************************/
    clean()
    {
        if (this.categoriesList)
        {
            this.categoriesList.clean();
        }

        if (this.craftingItem)
        {
            this.craftingItem.clean();
        }

        if (this.upgrade)
        {
            this.upgrade.clean();
        }

        super.clean();
    }

    buildCraftingTabContent()
    {
        if (this.categoryId)
        {
            if (!this.craftingItem)
            {
                this.craftingItem = new CraftingItemContent().init({
                    "ui": this.UI,
                    "category": this.categoryId,
                    "rWidth": this.BgWidth,
                    "rHeight": this.BgHeight
                });
                this.craftingItem.on(this.craftingItem.EVENT_CRAFT, this.fctOnCraftItem);//@
                this.craftingItem.on(this.craftingItem.EVENT_BACK, this.fctOnCraftBack);//@
                this.craftingItem.on(this.craftingItem.EVENT_DRAG_ANIMATION_END, this.fctOnCraftAnimationEnd);//@
            }
            else
            {
                this.craftingItem.Category = this.categoryId;
                this.removeChild(this.craftingItem);
            }

            this.craftingItem.rX = [{on:"default", x: this.BgX}];
            this.craftingItem.rY = [{on:"default", y: this.BgY}];

            this.addChild(this.craftingItem);
            this.removeChild(this.categoriesList);
        }
        else
        {
            if (!this.categoriesList)
            {
                this.categoriesList = new CraftingCategoriesContent().init({
                    "ui": this.UI,
                    "rWidth": this.BgWidth,
                    "rHeight": this.BgHeight
                });
                this.categoriesList.on(this.categoriesList.EVENT_SELECT, this.fctOnCategorySelect);//@
            }
            else
            {
                this.removeChild(this.categoriesList);
            }

            this.categoriesList.rX = [{on:"default", x: this.BgX}];
            this.categoriesList.rY = [{on:"default", y: this.BgY}];

            this.addChild(this.categoriesList);
            if (this.craftingItem)
            {
                this.removeChild(this.craftingItem);
            }
        }

        if (this.upgrade)
        {
            this.removeChild(this.upgrade);
        }
    }

    buildUpgradeTabContent()
    {
        if (!this.upgrade)
        {
            this.upgrade = new UpgradeZoneContent().init({
                "ui": this.UI,
                "zone": this.ZoneId, 
                "rWidth": this.BgWidth, 
                "rHeight": this.BgHeight
            });
            this.upgrade.on(this.upgrade.EVENT_UPGRADE, this.fctOnUpgrade);//@
        }
        else
        {
            this.removeChild(this.upgrade);
        }

        this.upgrade.rX = [{on:"default", x: this.BgX}];
        this.upgrade.rY = [{on:"default", y: this.BgY}];

        this.addChild(this.upgrade);

        if (this.craftingItem)
        {
            this.removeChild(this.craftingItem);
        }
        this.removeChild(this.categoriesList);
    }

    upgradeZone()
    {
        setTimeout(function()
        {
            this.UI.Notifications.showZoneUpgrade(this.ZoneId, this.ZoneLevel, this.ZoneLevel + 1);
            this.UI.WorldManager.upgradeBarnZone(this.ZoneId);

            this.hide();
        }
        .bind(this), 1);
    }

    craftItem(objItem)
    {
        this.dropped = null;
        setTimeout(function(objItem)
        {
            let bOk = true;

            if (this.categoryId != this.UI.ItemManager.ITEM_CODES.EQUIPMENT_BACKPACK)
            {
                let result = this.UI.ItemManager.craftItem(objItem);
                bOk = result.status;
            }
            else
            {
                //reset backpack popup
                this.UI.removeBackpackPopup();
                let result = this.UI.ItemManager.upgradeBackpack(objItem.CraftLevel);
                bOk = result.status;

                objItem.TextureId = "bag" + objItem.Asset + ".png";
                objItem.AtlasId = "items3D";
            }

            if (bOk)
            {
                if (gtag)
                {
                    gtag('event', 'craft', {
                        'event_category': "created",
                        'event_label': objItem.id,

                    });
                }
                this.UI.AudioManager.playSfx("objet_fabrique");
                this.UI.Notifications.showItemGain(objItem, 1, false);
                this.craftingItem.reset();
                this.craftingItem.build();
            }
        }
        .bind(this, objItem), 1);
    }

    /*******************************************
    *   ACTIONS
    *******************************************/
    hide()
    {
        //It prevents a lot of problems to just destroy the popup instead of just hiding it
        setTimeout(() => {
            super.hide();
            this.UI.destroyZonePopup(this)
        }, 1);
    }

    /*******************************************
    *   EVENTS
    *******************************************/
    onUpgrade(sender)
    {
        this.upgradeZone();
    }

    onCategorySelect(strCategoryId)
    {
        this.categoryId = strCategoryId;
        this.buildCraftingTabContent();
    }

    onCraftItem(objItem)
    {
        this.dropped = objItem;
    }

    onCraftBack(sender)
    {
        this.categoryId = null;
        this.buildCraftingTabContent();
    }

    onCraftAnimationEnd()
    {
        if (this.dropped)
        {
            this.craftItem(this.dropped);
        }
    }
}