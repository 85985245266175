import EventEmitter from "eventemitter3";
import gtag from "ga-gtag";
import BarnEnvironment from "./environments/BarnEnvironment.js";
import Constants from "../utils/Constants.js";
import ForestEnvironment from "./environments/ForestEnvironment.js";
import HouseEnvironment from "./environments/HouseEnvironment.js";
import LaboEnvironment from "./environments/LaboEnvironment.js";
import Library from "../Library.js";
import OutdoorImageParser from "./map/parsers/OutdoorImageParser.js";
import {Vector3} from "three";



export default class WorldManager extends EventEmitter
{
    constructor( canvas )
    {
        super();

        this.canvas = canvas;

        WorldManager.instance = this;

        this.explorationKey = "exploration";
        this.cellContentKey = "cellcontent";
        this.backpackCellKey = "backpackcell";
        this.stepsPutDownCountKey = "stepsputdown";
        this.obstacleStatesKey = "obstaclestate";
        this.dayTimeKey = "daytime";
        this.canWorldClick = false;
        this.isCinematicMode = false;
        this.time = 0.75;
        this.timeOfDayMultiplier = 1;
        this.parsers = {};

        document.addEventListener("keyup", this.onCheckMemory.bind(this));
    }

    onCheckMemory (e)
    {
        let code = e.code;
        console.log("onCheckMemory", e);

        if (code === "Space")
        {
            if (this.renderer)
            {
                if (!this.memoryChecker)
                {
                    let ctx = this.renderer.getContext();
                    this.memoryChecker = ctx.getExtension('GMAN_webgl_memory');
                    this.arrMemory = [];

                }

                const info = this.memoryChecker.getMemoryInfo();

                this.arrMemory.push(info)

                console.log("********")
                console.log(info);

                if (this.arrMemory.length > 1)
                {

                    let last = this.arrMemory[this.arrMemory.length - 2];

                    console.log("Memory buffer", info.memory.buffer, info.memory.buffer - last.memory.buffer)
                    console.log("Memory drawingbuffer", info.memory.drawingbuffer, info.memory.drawingbuffer - last.memory.drawingbuffer)
                    console.log("Memory renderbuffer", info.memory.renderbuffer, info.memory.renderbuffer - last.memory.renderbuffer)
                    console.log("Memory texture", info.memory.texture, info.memory.texture - last.memory.texture)
                    console.log("Resources buffer", info.resources.buffer, info.resources.buffer - last.resources.buffer)
                    console.log("Resources framebuffer", info.resources.framebuffer, info.resources.framebuffer - last.resources.framebuffer)
                    console.log("Resources program", info.resources.program, info.resources.program - last.resources.program)
                    console.log("Resources query", info.resources.query, info.resources.query - last.resources.query)
                    console.log("Resources renderbuffer", info.resources.renderbuffer, info.resources.renderbuffer - last.resources.renderbuffer)
                    console.log("Resources sampler", info.resources.sampler, info.resources.sampler - last.resources.sampler)
                    console.log("Resources sync", info.resources.sync, info.resources.sync - last.resources.sync)
                    console.log("Resources texture", info.resources.texture, info.resources.texture - last.resources.texture)
                    console.log("Resources transformFeedback", info.resources.transformFeedback, info.resources.transformFeedback - last.resources.transformFeedback)
                    console.log("Resources vertexArray", info.resources.vertexArray, info.resources.vertexArray - last.resources.vertexArray)

                }
                else
                {
                    console.log("Memory buffer", info.memory.buffer)
                    console.log("Memory drawingbuffer", info.memory.drawingbuffer)
                    console.log("Memory renderbuffer", info.memory.renderbuffer)
                    console.log("Memory texture", info.memory.texture)
                    console.log("Resources buffer", info.resources.buffer)
                    console.log("Resources framebuffer", info.resources.framebuffer)
                    console.log("Resources program", info.resources.program)
                    console.log("Resources query", info.resources.query)
                    console.log("Resources renderbuffer", info.resources.renderbuffer)
                    console.log("Resources sampler", info.resources.sampler)
                    console.log("Resources sync", info.resources.sync)
                    console.log("Resources texture", info.resources.texture)
                    console.log("Resources transformFeedback", info.resources.transformFeedback)
                    console.log("Resources vertexArray", info.resources.vertexArray)
                }
            }
            else
            {

            }
        }
    }

    get EVENT_BROTHERS_SCARED() { return "brothers-scared"; }
    get EVENT_BARN_ZONE_UPGRADED() { return "barn-zone-upgraded"; }
    get EVENT_CHARACTER_TILE_CHANGED() { return "character-tile-changed"; }
    get EVENT_CINEMATIC_MODE_START() { return "cinematic-mode-start"; }
    get EVENT_CINEMATIC_MODE_END() { return "cinematic-mode-end"; }
    get EVENT_DIALOG_MODE_START() { return "dialog-mode-start"; }
    get EVENT_DIALOG_MODE_END() { return "dialog-mode-end"; }
    get EVENT_MINIMAP_OPEN() { return "minimap-open"; }
    get EVENT_MINIMAP_CLOSE() { return "minimap-close"; }
    get EVENT_PLAYER_LOCATION_CHANGED() { return "player-location-changed"; }
    get EVENT_PLAYER_LOCATION_INDOOR_CHANGED() { return "player-location-indoor-changed"; }
    get EVENT_RADIO_CLICK() { return "radio-click"; }
    get EVENT_PLAYER_START_TRAVELING() { return "player-start-traveling"; }
    get EVENT_STEPS_PUT_DOWN() { return "steps-put-down"; }
    get EVENT_TIME_OF_DAY_CHANGED() { return "time-of-day-changed"; }

    get ENVIRONMENT_FOREST() { return "forest"; }
    get ENVIRONMENT_BARN() { return "barn"; }
    get ENVIRONMENT_HOUSE() { return "house"; }
    get ENVIRONMENT_LABO() { return "labo"; }

    get BARN_ZONE_SLEEP() { return "sleep"; }
    get BARN_ZONE_RELAX() { return "relax"; }
    get BARN_ZONE_COOKING() { return "cooking"; }
    get BARN_ZONE_CRAFTING() { return "crafting"; }

    get TIME_OF_DAY_MORNING() { return "morning"; }
    get TIME_OF_DAY_AFTERNOON() { return "day"; }
    get TIME_OF_DAY_EVENING() { return "evening"; }
    get TIME_OF_DAY_NIGHT() { return "night"; }

    get MAP_WIDTH() { return 1600; }
    get MAP_HEIGHT() { return 1600; }

    //---------------------------------------------------------
    //  DEPENDENCIES
    //---------------------------------------------------------
    get AudioManager() { return this.dependencies.get("AudioManager"); }
    get CharacterManager() { return this.dependencies.get("CharacterManager"); }
    get GameManager() { return this.dependencies.get("GameManager"); }
    get ItemManager() { return this.dependencies.get("ItemManager"); }
    get LabelManager() { return this.dependencies.get("LabelManager"); }
    get ResponsiveManager() { return this.dependencies.get("ResponsiveManager"); }
    get SaveManager() { return this.dependencies.get("SaveManager"); }
    get UIManager() { return this.dependencies.get("UIManager"); }
    //---------------------------------------------------------

    get CanWorldClick() { return this.canWorldClick; }
    set CanWorldClick(newValue) { this.canWorldClick = newValue; }

    get IsBarn() { return this.Environment && this.Environment.Id == this.ENVIRONMENT_BARN; }
    get IsForest() { return this.Environment && this.Environment.Id == this.ENVIRONMENT_FOREST; }
    get IsLabo() { return this.Environment && this.Environment.Id == this.ENVIRONMENT_LABO; }
    get IsHouse() { return this.Environment && this.Environment.Id == this.ENVIRONMENT_HOUSE; }
    get IsDialogMode() { return this.Environment && this.Environment.IsDialogMode; }
    get PostProcessing() { return this.Environment.Scene.PostProcessing; }

    //@TODO: Implement these properly when the different environments are in place
    //----------
    get IsCinematicMode() { return this.isCinematicMode; }
    set IsCinematicMode(mode) { this.isCinematicMode = mode; }
    get IsPlayerHidden() { return false; }
    //----------

    get IsInGameScene() { return this.IsBarn || this.IsForest || this.IsLabo || this.IsHouse; }
    get Player() { return this.environment.Player; }
    get Enemies() { return this.environment.Enemies; }
    get NPCs() { return this.environment.NPCs; }
    get Environment() { return this.environment; }
    get Grid() { return this.environment.Grid; }
    get IsHostile() { return this.Environment && this.Environment.IsHostile; }

    get LastDeath() { return this.lastDeath; }
    set LastDeath(newValue) { this.lastDeath = newValue; }

    get StepsPutDownCount() { return this.SaveManager.getFromSave(this.stepsPutDownCountKey, 0); }
    get PlayerCollisionWidthRatio() { return this.playerCollisionRatio; }

    get BarnSleepZoneLevel() { return this.getBarnZoneLevel(this.BARN_ZONE_SLEEP); }
    get BarnRelaxZoneLevel() { return this.getBarnZoneLevel(this.BARN_ZONE_RELAX); }
    get BarnCookingZoneLevel() { return this.getBarnZoneLevel(this.BARN_ZONE_COOKING); }
    get BarnCraftingZoneLevel() { return this.getBarnZoneLevel(this.BARN_ZONE_CRAFTING); }

    get BarnSleepRatio() { return (this.BarnSleepZoneLevel in this.barnSettings[this.BARN_ZONE_SLEEP] ? this.barnSettings[this.BARN_ZONE_SLEEP][this.BarnSleepZoneLevel].value : 1); }
    get BarnRelaxRatio() { return (this.BarnRelaxZoneLevel in this.barnSettings[this.BARN_ZONE_RELAX] ? this.barnSettings[this.BARN_ZONE_RELAX][this.BarnRelaxZoneLevel].value : 1); }
    get BarnCraftingItemLevel() { return (this.BarnCraftingZoneLevel in this.barnSettings[this.BARN_ZONE_CRAFTING] ? this.barnSettings[this.BARN_ZONE_CRAFTING][this.BarnCraftingZoneLevel].value : 1); }

    get DayStart() { return this.timeOfDayDef.night.duration / 2 + 1; }

    get TimeOfDay() { return this.time % this.dayLength / this.dayLength; }
    get DeltaTime() { return this.delta; }
    get Time() { return this.time; }

    /**
     Midnight       Noon         Midnight
     |-------------|-------------|
     0            0.5            1
     */
    get IsNight()
    {
        if (!this.timeOfDayDef)
            return false;

        let nightRatio = this.timeOfDayDef.night.duration / this.dayLength;
        let timeRatio = this.TimeOfDay;

        return timeRatio % 1 <= nightRatio / 2.0 || timeRatio % 1 >= (1 - nightRatio / 2.0);
    }

    get CurrentTimeOfDay ()
    {
        if (!this.timeOfDayDef) //if no definition, default to afternoon
            return this.TIME_OF_DAY_AFTERNOON;

        let tod = this.TimeOfDay;

        let morning = this.timeOfDayDef.day.morning.duration / this.dayLength;

        let evening = this.timeOfDayDef.day.evening.duration / this.dayLength;
        let night = this.timeOfDayDef.night.duration / this.dayLength;

        let day = (this.timeOfDayDef.day.duration / this.dayLength) - morning - evening;
        let fullDay = day + morning + evening

        let isNight = tod > (fullDay + (night / 2)) || tod < (night / 2);
        let isMorning = false;
        let isEvening = false;
        let isDay = false;

        if (!isNight)
        {

            if (tod < (night / 2) + morning)
            {
                isMorning = true;
            }
            else if (tod > ((night / 2) +  day))
            {
                isEvening = true;
            }
            else
            {
                isDay = true;
            }
        }

        let strTimeOfDay = "";

        if (isNight)
        {
            strTimeOfDay = this.TIME_OF_DAY_NIGHT;
        }
        else if (isMorning)
        {
            strTimeOfDay = this.TIME_OF_DAY_MORNING;
        }
        else if (isEvening)
        {
            strTimeOfDay = this.TIME_OF_DAY_EVENING;
        }
        else
        {
            strTimeOfDay = this.TIME_OF_DAY_AFTERNOON;
        }

        return strTimeOfDay;
    }

    /**
        Add a number of hours to the ingame time
        @param iQty Number of hours to add (or substract if the number is negative)
    */
    addHours(iQty)
    {
        this.time += iQty * (this.dayLength / 24);
        if (this.time < 0)
        {
            this.time += this.dayLength;
        }
        this.SaveManager.setFromSave(this.dayTimeKey, this.time);

        let strCurrentTimeOfDaY = (this.CurrentTimeOfDay);

        if (strCurrentTimeOfDaY !== this.prevTimeOfDay)
        {
            this.emit(this.EVENT_TIME_OF_DAY_CHANGED, strCurrentTimeOfDaY, this.prevTimeOfDay);
            this.prevTimeOfDay = strCurrentTimeOfDaY;
        }
    }

    getCookingRatio (iLevel)
    {
        //Small hack to fit the cooking level of all items with the current zone
        iLevel = this.getBarnZoneLevel(this.BARN_ZONE_COOKING);

        let ratio = 1; 
        if (iLevel in this.barnSettings[this.BARN_ZONE_COOKING])
        {
            ratio = this.barnSettings[this.BARN_ZONE_COOKING][iLevel].value;
        }
        return ratio;
    }

    getBarnZoneSaveKey (strZone)
    {
        return "barn-" + strZone;
    }

    getCharacterZoneSaveKey (iCharacter)
    {
        return "character-barn-zone-" + iCharacter;
    }

    getBarnZoneLevel (strZone)
    {
        return this.SaveManager.getFromSave(this.getBarnZoneSaveKey(strZone), 1);
        //return this.SaveManager.getFromSave(this.getBarnZoneSaveKey(strZone), 0);
    }

    setBarnZoneLevel (strZone, iNewZoneLevel)
    {
        return this.SaveManager.setFromSave(this.getBarnZoneSaveKey(strZone), iNewZoneLevel);
    }

    getZoneMaxLevel(strZone)
    {
        if (strZone in this.barnSettings)
        {
           let keys = Object.keys(this.barnSettings[strZone]);
           return parseInt(keys[keys.length - 1]);
        }
        return 1;
    }

    /**
        Gets the required item to upgrade the level of a zone in the barn

        @param strZone      Which zone should be checked for requirements. Use the BARN_ZONE_XXX static values of this class
        @param iLevel       Level to get the upgrade requirements
        @return             An array containing the list of items with their quantity. If the upgrade requirements could not be found, an empty array will be returned
    */
    getBarnZoneUpgradeRequirements (strZone, iLevel)
    {
        let requirements = [];

        if (strZone in this.barnSettings && iLevel in this.barnSettings[strZone] && "craft" in this.barnSettings[strZone][iLevel])
        {
            for (let i = 0; i < this.barnSettings[strZone][iLevel].craft.req.length; i++)
            {
                requirements.push({
                    "item": this.ItemManager.getItem(this.barnSettings[strZone][iLevel].craft.req[i].id),
                    "quantity": this.barnSettings[strZone][iLevel].craft.req[i].qty
                });
            }
        }

        return requirements;
    }

    init(dependencies)
    {
        this.dependencies = dependencies;

        this.createClosure();
        this.bindEvents();
    }

    createClosure ()
    {
        this.fctUpdate = this.update.bind(this);
        this.fctOnStartGame = this.onStartGame.bind(this);
        this.fctOnQuitToHome = this.onQuitToHome.bind(this);
        this.fctOnMiniMapOpen = this.onMiniMapOpen.bind(this);
        this.fctOnMiniMapClose = this.onMiniMapClose.bind(this);
        this.fctOnStartGameLoadingDone = this.onStartGameLoadingDone.bind(this);
    }

    bindEvents()
    {
        this.GameManager.on(this.GameManager.EVENT_UPDATE, this.fctUpdate);
        this.GameManager.on(this.GameManager.EVENT_QUIT_TO_HOME, this.fctOnQuitToHome);
        this.GameManager.on(this.GameManager.EVENT_START_GAME_LOADING_DONE, this.fctOnStartGameLoadingDone);
        this.on(this.EVENT_MINIMAP_OPEN, this.fctOnMiniMapOpen);
        this.on(this.EVENT_MINIMAP_CLOSE, this.fctOnMiniMapClose);
    }

    update(fDeltaTime)
    {
        if (!this.GameManager.IsPaused && this.shouldUpdateTimeOfDay && !this.IsCinematicMode && this.IsHostile)
        {
            this.time += fDeltaTime * this.timeOfDayMultiplier;
            this.SaveManager.setFromSave(this.dayTimeKey, this.time);

            let strCurrentTimeOfDaY = (this.CurrentTimeOfDay);

            if (strCurrentTimeOfDaY !== this.prevTimeOfDay)
            {
                this.emit(this.EVENT_TIME_OF_DAY_CHANGED, strCurrentTimeOfDaY, this.prevTimeOfDay);
                this.prevTimeOfDay = strCurrentTimeOfDaY;
            }
        }
    }

    showLabo ()
    {
        //@TODO: Code this properly
        //TEMP
        //-----------
        
        /*----------------------------------------
            TEST BUILDS
                - 463555_3011 (Elephant + Bear)
                - 463555_3120 (Squid + Chameleon)
                - 746000_4002 (Ostrich)
                - 463555_3403 (Frog + Rabbit)
                - 463555_3203 (Rabbit + Cat)

        ------------------------------------------*/
        this.SaveManager.SaveSlot = 0;
        this.SaveManager.setFromSave(this.CharacterManager.characterBuildKey + "0", "463555_3010");
        this.SaveManager.setFromSave(this.CharacterManager.characterBuildKey + "1", "746000_4003");
        this.SaveManager.setFromSave(this.CharacterManager.characterBuildKey + "2", "130666_1100");

        this.SaveManager.setFromSave(this.CharacterManager.nameKey + "0", "Philippe");
        this.SaveManager.setFromSave(this.CharacterManager.nameKey + "1", "Shae");
        this.SaveManager.setFromSave(this.CharacterManager.nameKey + "2", "Carey");

        this.SaveManager.setFromSave(this.SaveManager.newGameKey, true);

        this.SaveManager.save();

        this.GameManager.playGameOnSlot(0, this.ENVIRONMENT_LABO);
        //-----------
    }

    showFarm ()
    {

    }

    showBarn ()
    {
        //@TODO: Code this properly
        //TEMP
        //-----------
        
        /*----------------------------------------
            TEST BUILDS
                - 463555_3011 (Elephant + Bear)
                - 463555_3120 (Squid + Chameleon)
                - 746000_4002 (Ostrich)
                - 463555_3403 (Frog + Rabbit)
                - 463555_3203 (Rabbit + Cat)

        ------------------------------------------*/
        this.SaveManager.SaveSlot = 0;
        this.SaveManager.setFromSave(this.CharacterManager.characterBuildKey + "0", "463555_3120");
        this.SaveManager.setFromSave(this.CharacterManager.characterBuildKey + "1", "746000_4003");
        this.SaveManager.setFromSave(this.CharacterManager.characterBuildKey + "2", "130666_1100");

        this.SaveManager.setFromSave(this.CharacterManager.nameKey + "0", "Philippe");
        this.SaveManager.setFromSave(this.CharacterManager.nameKey + "1", "Shae");
        this.SaveManager.setFromSave(this.CharacterManager.nameKey + "2", "Carey");

        this.SaveManager.setFromSave(this.SaveManager.newGameKey, true);

        this.SaveManager.save();

        this.GameManager.playGameOnSlot(0, this.ENVIRONMENT_BARN);
        //-----------
    }

    /**
        Unloads the current environment and loads another one. If the request environment is the same as the current one, it acts as a reload

        @param strEnvironment   Id of the environment to load. Use the static values of ENVIRONMENT_XXX of this class
        @param fctCallback      (Optional) Callback to execute when the environment has been changed. Default is NULL
        @param iSpawnTileId     (Optional) Position of the tile where to spawn the player. Used in the forest. Default is NULL
    */
    changeEnvironment(strEnvironment, fctCallback = null, iSpawnTileId = null)
    {
        this.resumeTimeOfDay();
        let old = this.destroyEnvironment();

        if (old == this.ENVIRONMENT_FOREST && strEnvironment === this.ENVIRONMENT_BARN)
        {
            if (this.IsPlayerHidden)
            {
                this.playerHidden = false;
            }
        }
        //Actions to execute when transition is:  Barn -> Forest
        else if (old === this.ENVIRONMENT_BARN && strEnvironment === this.ENVIRONMENT_FOREST)
        {
            let currentChar = this.CharacterManager.CurrentCharacter;
            if (this.CharacterManager.isCharacterSleeping(currentChar))
            {
                this.CharacterManager.removeCharacterFromSleep(currentChar);
            }

            if (this.CharacterManager.isCharacterRelaxing(currentChar))
            {
                this.CharacterManager.removeCharacterFromRelax(currentChar);
            }
        }

        if (strEnvironment === this.ENVIRONMENT_FOREST)
        {
            this.environment = new ForestEnvironment(this.canvas);
            this.environment.init({
                "parser": this.parsers[this.ENVIRONMENT_FOREST],
                "spawnTile": iSpawnTileId
            });
        }
        else if (strEnvironment === this.ENVIRONMENT_BARN)
        {
            this.environment = new BarnEnvironment(this.canvas);
            this.environment.init({
                "positioning": this.barnPositioning
            });
        }
        else if (strEnvironment === this.ENVIRONMENT_HOUSE)
        {
            this.environment = new HouseEnvironment(this.canvas);
            this.environment.init();
        }
        else if (strEnvironment === this.ENVIRONMENT_LABO)
        {
            this.environment = new LaboEnvironment(this.canvas);
            this.environment.init();
        }

        this.emit(this.EVENT_PLAYER_LOCATION_CHANGED, strEnvironment);
        this.SaveManager.save();
    }

    /**
        Destroys the current environment and removes it from the game
        @return     Type of the environment destroyed. Returns an empty string if no environment was loaded
    */
    destroyEnvironment()
    {
        let old = "";

        if (this.environment)
        {
            old = this.environment.Type;
            this.environment.destroy();

            delete this.environment;
            this.UIManager.purgeUI();
        }

        return old;
    }

    /**
        Tries to upgrade the next level of a barn zone with the current materials

        @param strZone          Which zone should be upgraded. Use the BARN_ZONE_XXX static values of this class
        @param bAllowStorage    (Optional) If the algorithm should also use items in the storage or not. Default is to allow storage
        @param iCharacter       (Optional) Specifies which character inventory should be used to check for the resources. If the ID is less than 0 then the current 
                                    character's inventory is used.

        @return                 An object describing the result of the operation :
                                "status": boolean describing if the opration was a success or not
                                "inventory": the new state of the inventories:
                                    - backpack: Backpack inventory state if the backpack was edited during the operation
                                    - storage: If the storage is allowed and was edited, the state of the storage will also be returned here
                                "args": List of values describing why the operation is as is it:
                                    - missingResources: The doesn't have the required resources to upgrade the new zone
                                    - notCraftable: The zone is already at its highest level

    */
    upgradeBarnZone (strZone, bAllowStorage = true, iCharacter = -1)
    {

        let result = { "status": true, "inventory": {}, "args": {} };
        let newLevel = this.getBarnZoneLevel(strZone) + 1;
        let requirements = this.getBarnZoneUpgradeRequirements(strZone, newLevel);

        if (requirements.length > 0)
        {
            let bOk = true;
            for (let i = 0; i < requirements.length; i++)
            {
                if (this.ItemManager.getItemQuantity(requirements[i].item.Id, bAllowStorage, iCharacter, true) < requirements[i].quantity)
                {
                    bOk = false;
                    break;
                }
            }

            if (bOk)
            {
                let editResult = this.ItemManager.removeRequiredItems(requirements, bAllowStorage, iCharacter, true);

                if (editResult.backpack) 
                {
                    result.inventory.backpack = this.ItemManager.getAllItems(true, iCharacter);
                }
                if (editResult.storage) 
                {
                    result.inventory.storage = this.ItemManager.getAllItems(false);
                }

                if (gtag)
                {
                    gtag('event', 'upgrade', {
                        'event_category': strZone,
                        'event_label': newLevel,
                    });
                }

                this.setBarnZoneLevel(strZone, newLevel);

                this.emit(this.EVENT_BARN_ZONE_UPGRADED, strZone, newLevel);
            }
            else
            {
                result.status = false;
                result.args.missingResources = true;
            }
        }
        else
        {
            result.status = false;
            result.args.notCraftable = true;
        }

        return result;
    }

    /**
        Updates the map exploration based on a cell
        @param strCell  Number of the cell explored
    */
    updateExploration(strCell)
    {
        let exploration = this.SaveManager.getFromSave(this.explorationKey, []);
        if (!exploration.includes(strCell))
        {
            exploration.push(strCell + "");
            this.SaveManager.setFromSave(this.explorationKey, exploration);
        }
    }

    /**
        Gets if a cell has been explored on the map
        @param strCell  Number of the cell to check
        @return         Boolean indicating if the cell was explored or not
    */
    getExploredCell(strCell)
    {
        let arrExplored = this.SaveManager.getFromSave(this.explorationKey, []);
        return arrExplored.includes(strCell.toString());
    }

    /**
        Gets a list of all cell that has been explored
        @return         List of explored cell ids
    */
    getAllExploredCell()
    {
        return this.SaveManager.getFromSave(this.explorationKey, []);
    }

    getCellContentKey(strEnvironment = null, strRoomId = null, bIsBackpack = false)
    {
        let contentKey = (bIsBackpack ? this.backpackCellKey : this.cellContentKey);
        if (strEnvironment && strRoomId)
        {
            contentKey += "_" + strEnvironment + "_" + strRoomId;
        }

        return contentKey;
    }


    getCellContent (strCell, strEnvironment = null, strRoomId = null)
    {
        let contentKey = this.getCellContentKey(strEnvironment, strRoomId);
        let contents = this.SaveManager.getFromSave(contentKey, {});
        let gridPos = this.Grid.getPosFromTileId(strCell);

        return content[strCell];
    }

    /**
        Updates and persists the content of a cell
        @param strCell          Number of the cell to update
        @param strContentId     (Optional) Id of the content (item, obstacle, etc.) that should be on the cell. If left empty, the content will be cleared 
        @param iQuantity        (Optional) Quantity of the content on the cell. Default is 0
        @param bIsBlocking      (Optional) If the content is blocking moving characters. Default is FALSE
        @param strEnvironment   (Optional) If the cell content is located indoor. This is the ID of the environment. Default NULL
        @param strRoomId        (Optional) If the cell content is located indoor. This is the ID of the room. Default NULL
    */
    updateCellContent(strCell, strContentId = null, iQuantity = 0, bIsBlocking = false, strEnvironment = null, strRoomId = null)
    {
        let contentKey = this.getCellContentKey(strEnvironment, strRoomId);
        let contents = this.SaveManager.getFromSave(contentKey, {});
        let gridPos = this.Grid.getPosFromTileId(strCell);

        if (strContentId != null)
        {   
            contents[strCell] = {
                "id": strContentId,
                "qty": iQuantity,
                "ts": Math.floor(new Date().getTime() / 1000)
            };

            this.Grid.addTileContent(
                gridPos.x,
                gridPos.y,
                [[(bIsBlocking ? 0 : 1)]],
                {"qty": iQuantity},
                bIsBlocking
            );
        }
        else if (contents[strCell])
        {
            delete contents[strCell];
            this.Grid.removeTileContent(gridPos.x, gridPos.y);
        }
        
        this.SaveManager.setFromSave(contentKey, contents);
    }

    /**
     Add to and persists the content of a cell
     @param strCell         Number of the cell to update
     @param strParam        The name of the new param
     @param value           The value to set to the cell content
     @param strEnvironment  (Optional) If the cell content is located indoor. This is the ID of the environment. Default NULL
     @param strRoomId       (Optional) If the cell content is located indoor. This is the ID of the room. Default NULL
     */
    addToCellContent(strCell,strParam, value, strEnvironment = null, strRoomId = null)
    {
        let contentKey = this.getCellContentKey(strEnvironment, strRoomId);
        let contents = this.SaveManager.getFromSave(contentKey, {});

        if (contents[strCell])
        {
            contents[strCell][strParam] = value;
            this.SaveManager.setFromSave(contentKey, contents);

            let gridPos = this.Grid.getPosFromTileId(strCell);
            let content = this.Grid.getTileContent(gridPos.x, gridPos.y);
            if (content)
            {
                content.params[strParam] = value;
                this.Grid.updateTileContent(content.blockMatrix, content.params);
            }
        }
    }

    /**
        Gets the content of cell on the map
        @param strCell          Number of the cell to check
        @param strEnvironment   (Optional) If the cell content is located indoor. This is the ID of the environment. Default NULL
        @param strRoomId        (Optional) If the cell content is located indoor. This is the ID of the room. Default NULL
        @return                 Object containing the infos regarding the content. Returns NULL if no content was placed on the cell
    */
    getCellContent(strCell, strEnvironment = null, strRoomId = null)
    {
        let contentKey = this.getCellContentKey(strEnvironment, strRoomId);
        let contents = this.SaveManager.getFromSave(contentKey, {});

        if (contents[strCell])
        {
            return contents[strCell];
        }

        return null;
    }

    /**
        Gets the content of all cell on the map that have registered content
        @param strEnvironment   (Optional) If the cell content is located indoor. This is the ID of the environment. Default NULL
        @param strRoomId        (Optional) If the cell content is located indoor. This is the ID of the room. Default NULL
        @return                 Object containing the infos of all cells on the map
    */
    getAllCellContents(strEnvironment = null, strRoomId = null)
    {
        return this.SaveManager.getFromSave(this.getCellContentKey(strEnvironment, strRoomId), {});
    }

     /**
        Saves the position on the backpack on the ground when the player dies
        @param strCell          Position on the grid where the backpack is located
        @param objItems         Key/Value object where the keys are the IDs of items and the values are their quantity
        @param backpackLevel    (Optional) The current level of the backpack dropped on a cell. For display purposes. Default is 1
        @param strEnvironment   (Optional) If the cell content is located indoor. This is the ID of the environment. Default NULL
        @param strRoomId        (Optional) If the cell content is located indoor. This is the ID of the room. Default NULL
    */
    setBackpackOnCell(strCell, objItems, backpackLevel = 1, strEnvironment = null, strRoomId = null)
    {
        let contentKey = this.getCellContentKey(strEnvironment, strRoomId, true);
        let cellContent = this.SaveManager.getFromSave(contentKey, {});

        cellContent[strCell] = {"items": {}, "level": backpackLevel};

        for (let key in objItems)
        {
            cellContent[strCell]["items"][key] = objItems[key];
        }

        this.SaveManager.setFromSave(contentKey, cellContent);

        let gridPos = this.Grid.getPosFromTileId(strCell);
        this.Grid.addTileContent(
            gridPos.x,
            gridPos.y,
            [[1]],
            cellContent[strCell]
        );
    }

    /**
        Removes a previously set backpack from a cell on the grid
        @param strCell          Position on the grid where the backpack is located
        @param strEnvironment   (Optional) If the cell content is located indoor. This is the ID of the environment. Default NULL
        @param strRoomId        (Optional) If the cell content is located indoor. This is the ID of the room. Default NULL
        @return                 The content that was on that cell before being cleared. Returns NULL if there wasn't previously any backpack 
    */
    clearBackpackOnCell(strCell, strEnvironment = null, strRoomId = null)
    {
        let contentKey = this.getCellContentKey(strEnvironment, strRoomId, true);
        let cellContent = this.SaveManager.getFromSave(contentKey, {});
        let oldContent = null;

        if (cellContent[strCell])
        {
            oldContent = cellContent[strCell];
            delete cellContent[strCell];

            let gridPos = this.Grid.getPosFromTileId(strCell);
            this.Grid.removeTileContent(
                gridPos.x,
                gridPos.y
            );
        }

        this.SaveManager.setFromSave(contentKey, cellContent);
        return oldContent;
    }

    /**
        Gets the backpack that has been set on a cell
        @param strCell          Position on the grid where the backpack is located
        @param strEnvironment   (Optional) If the cell content is located indoor. This is the ID of the environment. Default NULL
        @param strRoomId        (Optional) If the cell content is located indoor. This is the ID of the room. Default NULL
        @return                 Key/Value object where the keys are the object IDs and the values are their quantity. Returns NULL if nothing is set on the cell
    */
    getBackpackOnCell(strCell, strEnvironment = null, strRoomId = null)
    {
        let contentKey = this.getCellContentKey(strEnvironment, strRoomId, true);
        let cellContent = this.SaveManager.getFromSave(contentKey, {});

        if (cellContent[strCell])
        {
            return cellContent[strCell];
        }
        return null;
    }

    /**
        Gets all the cells where a backpack has been set on the grid
        @param strEnvironment   (Optional) If the cell content is located indoor. This is the ID of the environment. Default NULL
        @param strRoomId        (Optional) If the cell content is located indoor. This is the ID of the room. Default NULL
        @return                 Key/Value object where the keys are the cells and the values is their backpack's content
    */
    getAllBackpackCells(strEnvironment = null, strRoomId = null)
    {
        let contentKey = this.getCellContentKey(strEnvironment, strRoomId, true);
        let cellContent = this.SaveManager.getFromSave(contentKey, {});

        let cells = {};

        for (let key in cellContent)
        {
            cells[key] = {};
            for (let id in cellContent[key])
            {
                cells[key][id] = cellContent[key][id];
            }
        }

        return cells;
    }

    /**
        Fetches from the grid every landmarks and returns them with their position on the grid
        @return     List of every landmark found with their position
    */
    getAllLandmarksPosition()
    {
        let landmarks = [];

        let list = this.parsers[this.ENVIRONMENT_FOREST].getCoordsByType(this.parsers[this.ENVIRONMENT_FOREST].TYPE_LANDMARK);

        if (list && list.length > 0)
        {
            for (let i = 0; i < list.length; i++)
            {
                let item = this.ItemManager.getItem(list[i].id);
                if (item)
                {
                    landmarks.push({
                        "def": item, 
                        "index": list[i].id.split('-')[1], 
                        "x": list[i].x, 
                        "z": list[i].z
                    });
                }
            }
        }

        return landmarks;
    }

    /**
        Finds the location of the barn on the grid
        @return     Object containing the position of the barn on the grid
    */
    getBarnOnGrid()
    {
        let list = this.parsers[this.ENVIRONMENT_FOREST].getCoordsByType(this.parsers[this.ENVIRONMENT_FOREST].TYPE_BARN);
        if (list && list.length > 0)
        {
            let split = list[0].id.split("-");
            let id = split[0];
            let item = this.ItemManager.getItem(list[0].id);
            if (item)
            {
                return {
                    "def": item, 
                    "index": split[1], 
                    "x": list[0].x,
                    "z": list[0].z
                };
            }
        }

        let item = this.ItemManager.getItem("BA-0");

        return {
            "def": item,
            "index": "0",
            "x": 75, //list[0].x,
            "z": 39, //list[0].z
        };
    }

    /**
        Gets the current state of an obstacle on the map
        @param strTileId        Id of the tile where the obstacle sits on
        @param strEnvironment   (Optional) If the obstacle is located indoor. This is the ID of the environment. Default NULL
        @param strRoomId        (Optional) If the obstacle is located indoor. This is the ID of the room. Default NULL
        @return                 Object containing the infos regarding the state of the obstacle
                                Return NULL if nothing could be found for the tile
    */
    getObstacleState(strTileId, strEnvironment = null, strRoomId = null)
    {
        let obstacleKey = this.obstacleStatesKey;
        if (strEnvironment && strRoomId)
        {
            obstacleKey += strEnvironment + "_" + strRoomId;
        }
        let states = this.SaveManager.getFromSave(obstacleKey, {});
        return states[strTileId]
    }

    /**
        Sets the current state of an obstacle on the map
        @param strTileId        Id of the tile where the obstacle sits on
        @param objParams        JSON object containing the state to apply. The state should be defined with these parameters:
                                    - lastPickup:   (Optional) Last time the obstacle was picked up. Default is NULL
                                    - nextPickup:   (Optional) In how much time the obstacle will be available for pickup. Default is NULL
                                    - broken:       (Optional) If the obstacle is broken and shoud never be shown again. Default is FALSE
        @param iLastPickUpTime  Time where the obstacle was picked up last time
        @param iNextTime        Time when the obstacle will be lootable again
        @param strEnvironment   (Optional) If the obstacle is located indoor. This is the ID of the environment. Default NULL
        @param strRoomId        (Optional) If the obstacle is located indoor. This is the ID of the room. Default NULL
    */
    setObstacleState(strTileId, objParams, strEnvironment = null, strRoomId = null)
    {
        let obstacleKey = this.obstacleStatesKey;
        if (strEnvironment && strRoomId)
        {
            obstacleKey += strEnvironment + "_" + strRoomId;
        }
        let states = this.SaveManager.getFromSave(obstacleKey, {});

        let state = states[strTileId];
        if (!state)
        {
            state = {
                "pickup": {
                    "last": 0,
                    "next": 0
                },
                "broken": false
            };
        }

        state.pickup.last = ("lastPickup" in objParams ? objParams.lastPickup : state.pickup.last);
        state.pickup.next = ("nextPickup" in objParams ? objParams.nextPickup : state.pickup.next) + state.pickup.last;
        state.broken = ("broken" in objParams ? objParams.broken : state.broken);

        states[strTileId] = state;
        this.SaveManager.setFromSave(obstacleKey, states);
    }

    clearObstacleState(strTileId, strEnvironment = null, strRoomId = null)
    {
        let obstacleKey = this.obstacleStatesKey;
        if (strEnvironment && strRoomId)
        {
            obstacleKey += strEnvironment + "_" + strRoomId;
        }

        let states = this.SaveManager.getFromSave(obstacleKey, {});
        delete states[strTileId];

        this.SaveManager.setFromSave(obstacleKey, states);
    }

    startDialogMode (strCharacterToFollow = null, bTiltCamera = false)
    {
        this.Environment.startDialogMode();
    }

    stopDialogMode()
    {
        this.Environment.stopDialogMode();
    }

    startCinematicMode ()
    {
        this.Environment.startCinematicMode();
    }

    stopCinematicMode()
    {
        this.Environment.stopCinematicMode();
    }

    preventWorldClick()
    {
        this.canWorldClick = false;
    }

    allowWorldClick()
    {
        this.canWorldClick = true;
    }

    getEnemyDefinition(strEnemyId)
    {
        if (this.enemies[strEnemyId])
        {
            return this.enemies[strEnemyId];
        }
    }

    getNpcDefinition(strNpcType)
    {
        if (this.npcs[strNpcType])
        {
            return this.npcs[strNpcType];
        }
    }

    applyEmptyPadding()
    {
     /*   for (let env in this.indoorData.environments)
        {
            for (let roomId in this.indoorData.environments[env].rooms)
            {
                let size = this.indoorData.environments[env].rooms[roomId].size;
                let walls = this.indoorData.environments[env].camera.walls;
                if (this.indoorData.environments[env].rooms[roomId].camera && this.indoorData.environments[env].rooms[roomId].camera.walls)
                {
                    walls = this.indoorData.environments[env].rooms[roomId].camera.walls;
                }

                for (let i = 0; i < walls.length; i++)
                {
                    let isX = walls[i] == "left" || walls[i] == "right";
                    for (let j = 0; j < (isX ? size.height : size.width); j++)
                    {
                        for (let k = 0; k < size.emptyPadding; k++)
                        {
                            let x = (isX ? (walls[i] == "right" ? size.width - (k + 1) : k) : j);
                            let y = (isX ? j : (walls[i] == "bottom" ? size.height - (k + 1) : k));

                            let tileId = y * size.width + x;
                            this.indoorData.environments[env].rooms[roomId].obstacles[tileId] = "empty";
                        }
                    }
                }
            }
        }*/
    }

    /*******************************************
    *   TIME OF DAY
    *******************************************/
    pauseTimeOfDay ()
    {
        this.shouldUpdateTimeOfDay = false;
    }

    resumeTimeOfDay()
    {
        this.shouldUpdateTimeOfDay = true;
    }

    /*******************************************
    *   INDOOR
    *******************************************/
    getIndoorEnvironmentData(strEnvironmentId)
    {
        if (strEnvironmentId in this.indoorData.environments)
        {
            return this.indoorData.environments[strEnvironmentId];

        }
        return null;
    }

    getIndoorRoomData(strEnvironmentId, strRoomId)
    {
        if (strEnvironmentId in this.indoorData.environments)
        {
            //return this.indoorData.environments[strEnvironmentId].rooms[strRoomId];
            let rooms = this.indoorData.environments[strEnvironmentId].rooms;
            rooms = Object.values(rooms);
            let room = rooms.find( r => r.id == strRoomId);

            return room;
        }
        return null;
    }

    /*******************************************
    *   EVENTS
    *******************************************/
    onLoadComplete(objSettings)
    {
        //SETTINGS
        let gameSettings = objSettings.gameSettings;

        this.barnSettings = gameSettings.map.barn;
        this.mapSettings = gameSettings.map;
        this.playerCollisionRatio = gameSettings.character.collision.widthRatio;

        this.timeOfDayDef = gameSettings.timeOfDay;
        this.dayLength = this.timeOfDayDef.day.duration + this.timeOfDayDef.night.duration;
        this.time = this.DayStart;
        this.prevTimeOfDay = this.CurrentTimeOfDay;

        this.mapItems = objSettings.items;
        this.enemies = objSettings.enemies;
        this.npcs = objSettings.npcs;

        //PARSERS
        this.parsers[this.ENVIRONMENT_FOREST] = new OutdoorImageParser(this.UIManager.PixiApp);
        this.parsers[this.ENVIRONMENT_FOREST].init({
            "width": Constants.getValue("MAP_WIDTH"),
            "enemies": this.enemies,
            "items": this.mapItems,
            "dependencies": this.dependencies
        });

        let mapObstacles = Library.getTextureFromResources("map_obstacles");
        this.parsers[this.ENVIRONMENT_FOREST].parseImage(mapObstacles);
        this.parsers[this.ENVIRONMENT_FOREST].calculateImportantLocations(this.mapItems);

        this.barnPositioning = Library.getData("barn_scene");

        this.indoorData = Library.getData("indoor");
        this.applyEmptyPadding();
    }

    onGridLoaded (objMapEnemies, objMapObstacles)
    {
        this.mapEnemiesDefinition = objMapEnemies;
        this.mapObstaclesDefinition = objMapObstacles;
    }

    onEnemiesReady (objEnemiesDefinition)
    {
        this.enemiesDefinition = objEnemiesDefinition;
    }

    onObstaclesAssetsReady (strKey, objObstacleAssets)
    {
        this.obstacleAssets = objObstacleAssets;
        this.obstacleAssetKey = strKey;
    }

    onStartGame(iSlotIndex, bIsEmpty)
    {
        this.shouldUpdateTimeOfDay = true;

        let dayStart = this.DayStart;
        if (bIsEmpty)
        {
            this.pauseTimeOfDay();
            this.time = dayStart;

            this.SaveManager.setFromSave(this.dayTimeKey, this.time);
        }
    }

    onStartGameLoadingDone()
    {
        this.time = this.SaveManager.getFromSave(this.dayTimeKey, this.DayStart);
    }

    onQuitToHome()
    {
        this.destroyEnvironment();
    }

    onMiniMapOpen()
    {
        this.GameManager.pause();
    }

    onMiniMapClose()
    {
        this.GameManager.resume();
    }


    teleportTo (x, y)
    {
        let moveTo = this.Grid.gridToWorldPosition(x, y);
        let cellSize = this.Grid.CellSize;
        moveTo.x += cellSize / 2;
        moveTo.y += cellSize;

        let wm = this;

        let fctEndTeleport = () =>
        {
            let playerPos = wm.Player.WorldPos;
            wm.Player.WorldPos = new Vector3(moveTo.x, playerPos.y, moveTo.y);

            wm.Environment.forceLoadOverlay();

            {
                let playerPos = wm.Player.WorldPos
                wm.Environment.updateDisplay(Math.round(playerPos.x) / 10, Math.round(playerPos.z / 10));
                wm.Environment.Scene.Camera.positionCameraOnPosition(playerPos, true);
            }
        }
        this.UIManager.showSceneTransition(fctEndTeleport);


    }
}