import { string2hex }  from '@pixi/utils'; 
import ContainerResponsive from "../responsive/ContainerResponsive.js";
import Display from "../../../utils/Display.js";
import GraphicsResponsive from "../responsive/GraphicsResponsive.js";
import SpriteResponsive from "../responsive/SpriteResponsive.js";

export default class CloseButton extends ContainerResponsive
{
    constructor ()
    {
        super();
    }

    get EVENT_CLICK() { return "self-click"; }

    get IsDisabled() { return this.disabled; }
    set IsDisabled(bNewValue) { this.disabled = (bNewValue) ? true : false; this.build(); }

    get ButtonWidth() { return this.valueFormula(this.UI.Values.modal.close.size.width); }
    get ButtonHeight() { return this.valueFormula(this.UI.Values.modal.close.size.height); }
    get BackgroundColor() { return string2hex(this.UI.Values.modal.close.background.color); }
    get StrokeWidth() { return this.evaluate(this.valueFormula(this.UI.Values.modal.close.stroke.size), 0, 0, 0, 0, this); }
    get StrokeColor() { return string2hex("color" in this.UI.Values.modal.close.stroke ? this.UI.Values.modal.close.stroke.color : "#000000"); }
    get CornerRadius() { return this.evaluate(this.valueFormula(this.UI.Values.modal.close.corner.radius), 0, 0, 0, 0, this); }

    get TextFont() { return this.UI.Values.modal.close.text.font; }
    get TextSize() { return this.evaluate(this.valueFormula(this.UI.Values.modal.close.text.size), 0, 0, 0, 0, this); }
    get TextWeight() { return this.UI.Values.modal.close.text.weight; }
    get TextColor() { return string2hex(this.UI.Values.modal.close.text.color); }

    get DisabledOpacity() { return this.UI.Values.textbutton.disabled.opacity; }

    /*******************************************
    *   INITIALIZATION
    *******************************************/
    /**
        Parameters to pass to the init function:
        - ui:       UI section object where this component resides
        - scale:    (Optional) Scale ratio of this button. Default is 1
    */
    init(meta)
    {
        this.baseScale = ("scale" in meta ? meta.scale : 1);
        this.buttonScale = {"x": this.baseScale, "y": this.baseScale};
        this.disabled = false;

        return super.init(meta);
    }

    createClosure()
    {
        super.createClosure();
        this.fctOnClick = this.onClick.bind(this);
        this.fctOnPointerDown = this.onPointerDown.bind(this);
        this.fctOnWindowMouseUp = this.onWindowMouseUp.bind(this);
        this.fctOnWindowTouchEnd = this.onWindowTouchEnd.bind(this);
    }

    bindEvents()
    {
        super.bindEvents();
        window.addEventListener("mouseup", this.fctOnWindowMouseUp);
        window.addEventListener("touchend", this.fctOnWindowTouchEnd);
    }

    destroy(options)
    {
        window.removeEventListener("mouseup", this.fctOnWindowMouseUp);
        window.removeEventListener("touchend", this.fctOnWindowTouchEnd);

        if (!this.graphics)
        {
            this.graphics.off("pointerdown", this.fctOnPointerDown);
            this.graphics.off("pointerup", this.fctOnClick);

            this.graphics.clear();
            this.graphics.destroy(options);
        }

        super.destroy(options);
    }

    /*******************************************
    *   BUILD
    *******************************************/
    build()
    {
        super.build();

        if (!this.graphics)
        {
            this.graphics = new GraphicsResponsive().init({"ui": this.UI});
            this.graphics.interactive = true;
            this.graphics.on("pointerdown", this.fctOnPointerDown); //@
            this.graphics.on("pointerup", this.fctOnClick); //@

            this.addChild(this.graphics);
        }

        this.graphics.buttonMode = !this.IsDisabled;

        let width = this.ButtonWidth + " * " + this.buttonScale.x;
        let calculatedWidth = this.evaluate(width, 0, 0, 0, 0, this);
        let height = this.ButtonHeight + " * " + this.buttonScale.y;
        let calculatedHeight = this.evaluate(width, 0, 0, 0, 0, this);

        let x = this.ButtonWidth + " * (1 - " + this.buttonScale.x + ") / 2";
        let y = this.ButtonHeight + " * (1 - " + this.buttonScale.y + ") / 2";

        this.graphics.clear();
        this.graphics.alpha = (this.IsDisabled ? this.DisabledOpacity : 1);

        this.graphics.rX = [{on:"default", "x": x}];
        this.graphics.rY = [{on:"default", "y": y}];

        if (this.StrokeWidth > 0)
        {
            this.graphics.lineStyle({"color":this.StrokeColor, "width":this.StrokeWidth, "alignment":1});
        }
        this.graphics.beginFill(this.BackgroundColor, 1);

        if (this.CornerRadius > 0)
        {
            this.graphics.drawRoundedRect(0, 0, calculatedWidth, calculatedHeight, this.CornerRadius);
        }
        else
        {
            this.graphics.drawRect(0, 0, calculatedWidth, calculatedHeight, this.CornerRadius);
        }

        this.buildSprite(calculatedWidth, calculatedHeight, this.graphics);

        this.graphics.layout(Display.getSize());
    }

    buildSprite(fWidth, fHeight, objParent)
    {
        if (!objParent.closeSprite)
        {
            objParent.closeSprite = new SpriteResponsive("icon_close").init({"ui": this.UI});
            objParent.addChild(objParent.closeSprite);
        }

        let sprite = objParent.closeSprite;

        let size = fWidth * 0.7;
        let ratio = sprite.height / sprite.width;
        let width = size;
        let height = width * ratio;

        if (sprite.width < sprite.height)
        {
            ratio = sprite.width / sprite.height;
            height = size;
            width = height * ratio;
        }

        let x = fWidth / 2 - width / 2;
        let y = fHeight / 2 - height / 2;

        objParent.closeSprite.width = width;
        objParent.closeSprite.height = height;

        objParent.closeSprite.position.set(x, y);
    }

    clean()
    {
        if (this.graphics)
        {
            this.graphics.clear();
        }
        super.clean();
    }

    //---------------------------------------------------
    //  EVENTS
    //---------------------------------------------------
    onClick()
    {
        if (!this.IsDisabled)
        {
            this.buttonScale.x = this.baseScale;
            this.buttonScale.y = this.baseScale;
            this.build();

            this.UI.AudioManager.playSfx(this.UI.Values.audio.click);
            this.emit(this.EVENT_CLICK, this);
        }
    }

    onPointerDown()
    {
        if (!this.IsDisabled)
        {
            this.buttonScale.x = this.baseScale * this.UI.Values.textbutton.scale.down;
            this.buttonScale.y = this.baseScale * this.UI.Values.textbutton.scale.down;
            this.build();
        }
    }

    onWindowMouseUp(e)
    {
        this.buttonScale.x = this.baseScale;
        this.buttonScale.y = this.baseScale;
        this.build();
    }

    onWindowTouchEnd(e)
    {
        if (e.touches.length == 0)
        {
            this.buttonScale.x = this.baseScale;
            this.buttonScale.y = this.baseScale;
            this.build();
        }
    }
}