import BaseCheat from "./BaseCheat.js";

export default class Hunger extends BaseCheat
{
    constructor(dependencies)
    {
        super(dependencies);
    }

    get CommandUse() { return "hunger +/-[qty]"; }
    get CommandDesc() { return "Adds or substracts hunger to the character's stat"; }

    //---------------------------------------------------------
    //  DEPENDENCIES
    //---------------------------------------------------------
    get CharacterManager() { return this.dependencies.get("CharacterManager"); }
    //---------------------------------------------------------

    parseCommand(strCmd, arrArguments)
    {
        if (strCmd == "hunger" && arrArguments.length >= 1)
        {
            let qty = parseInt(arrArguments[0]);
            if (qty)
            {
                this.CharacterManager.setCharacterHunger(this.CharacterManager.getCharacterHunger() + qty);
                this.log(Math.abs(qty) + " hunger point" + (Math.abs(qty) > 1 ? "s were " : " was ") + (qty < 0 ? "substracted" : "added") + " from the character stats");
            }
            else
            {
                this.warn("Cannot add that amount of hunger");
            }
        }
    }
}