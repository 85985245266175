import { TextStyle } from "@pixi/text";
import { TextMetrics } from "@pixi/text";
import ContainerResponsive from "../../../commons/responsive/ContainerResponsive.js";
import Display from "../../../../utils/Display.js";
import GraphicsResponsive from "../../../commons/responsive/GraphicsResponsive.js";
import Library from "../../../../Library.js";
import SpriteResponsive from "../../../commons/responsive/SpriteResponsive.js";
import TextResponsive from "../../../commons/responsive/TextResponsive.js";

export default class HackingCell extends ContainerResponsive
{
    constructor()
    {
        super();
    }

    get EVENT_CLICK() { return "self-click"; }

    get BAD_TIME() { return 0.5; }
    get GOOD_TIME() { return 0.5; }
    get ANIMATION_BUILD_INTERVAL() { return 0.05; }

    get MinigameId() { return this.minigameId; }
    get AtlasId() { return this.atlasId; }
    get Position() { return this.pos; }

    get CanClick() { return this.canClick && this.badTimeLeft <= 0 && !this.IsAnimating; }
    set CanClick(bNewValue)
    { 
        this.canClick = (bNewValue ? true : false);
        if (this.background)
        {
            this.background.buttonMode = this.canClick;
        }
    }

    get Lines() { return this.lines; }
    set Lines(arrNewValue)
    { 
        this.lines = arrNewValue;

        for (let key in this.texts)
        {
            this.removeChild(this.texts[key]);
            this.texts[key].destroy({"children": true});
        }
        this.texts = {};

        this.build();
    }

    get IsDone() { return this.done; }
    set IsDone(bNewValue) { this.done = (bNewValue ? true : false); }

    get IsSelected() { return this.selected; }
    set IsSelected(bNewValue) { this.selected = (bNewValue ? true : false); }

    get IsGood() { return this.good; }
    set IsGood(bNewValue) { this.good = (bNewValue ? true : false); }

    get IsBad() { return this.badTimeLeft > 0; }

    get IsAnimating() { return this.animationTimeLeft > 0; }

    get BgX() { return 0; }
    get BgY() { return 0; }
    get BgWidth() { return "(" + this.bgWidth + ")"; }
    get BgHeight() { return "(" + this.bgHeight + ")"; }
    get CornerRadius() { return "(" + this.cornerRadius + ")"; }
    get CellMargin() { return "(" + this.BgWidth + " * 0.015)"; }

    get TextZoneWidth() { return "(" + this.BgWidth + " - (" + this.BgHeight + " - " + this.TextZoneHeight + "))"; }
    get TextZoneHeight() { return "(" + this.BgHeight + " * 0.8)"; }
    get TextZoneX() { return this.BgX + " + " + this.BgWidth + " / 2 - " + this.TextZoneWidth + " / 2"; }
    get TextZoneY() { return this.BgY + " + " + this.BgHeight + " / 2 - " + this.TextZoneHeight + " / 2"; }

    get CornerSpriteHeight() { return this.BgHeight + " * 0.15"; }

    get BorderColor() { return 0x0E0E0E;}
    get BackgroundColor() { return 0x2C6247; }
    get BackgroundGoodColor() { return 0x62F8AC; }
    get BackgroundBadColor() { return 0x622C2C; }
    get BackgroundDoneColor() { return 0x211F28; }
    get BackgroundOpacity() { return 0.6; }
    get BackgroundGoodOpacity() { return 0.5; }
    get BackgroundBadOpacity() { return 0.5; }
    get BackgroundDoneOpacity() { return 0.3; }
    get TextOpacity() { return 1; }
    get TextDoneOpacity() { return 0.3; }

    get FontFamily() { return "Orbitron"; }
    get FontSize() { return this.evaluate("ih * 0.0155", 0, 0, 0, 0); }
    get FontWeight() { return this.UI.Values.general.title.weight; }
    get FontColor() { return 0x62F8AC; }

    getGlowColor(iIndex)
    {
        switch (iIndex % 4)
        {
            case 0:
                return 0x8BD9EB;
            case 1:
                return 0xF8EC62;
            case 2:
                return 0xFCD19A;
            case 3:
                return 0xFFFFFF;
        }
    }

    /*******************************************
    *   INITIALIZATION
    *******************************************/
    /**
        Parameters to pass to the init function:
        - ui:           UI section object where this component resides
        - minigameId:   Id of the current minigame
        - atlasId:      Id of the texture atlas to fetch textures for sprites
        - position:     Logical position of this cell in the grid
        - lines:        Text lines to display inside the cell
        - maxLines:     Maximum number of lines the cell can contain
        - maxChars:     Maximum number of chracters per line of the cell
        - rWidth:       Responsive equation representing the width of this component
        - rHeight:      Responsive equation representing the height of this component
        - rCorner:      Responsive equation representing the corner radius of this component
        - border:       (Optional)If a border should be drawned around the background of the cell. Default is FALSE
    */
    init(meta)
    {
        this.minigameId = meta.minigameId;
        this.atlasId = meta.atlasId;
        this.pos = meta.position;
        this.lines = meta.lines;
        this.maxLines = meta.maxLines;
        this.maxChars = meta.maxChars;
        this.bgWidth = meta.rWidth;
        this.bgHeight = meta.rHeight;
        this.cornerRadius = meta.rCorner;
        this.drawBorder = ("border" in meta ? meta.border : false);

        this.canClick = false;
        this.selected = false;
        this.good = false;
        this.done = false;
        this.badTimeLeft = 0;
        this.sprites  = {};
        this.texts = {};

        return super.init(meta);
    }

    createClosure()
    {
        super.createClosure();
        this.fctOnSelfClick = this.onSelfClick.bind(this);
    }

    destroy(options)
    {
        if (this.background)
        {
            this.removeChild(this.background);
            this.background.destroy(options);
        }
        delete this.background;

        for (let key in this.sprites)
        {
            this.removeChild(this.sprites[key]);
            this.sprites[key].destroy(options);
        }
        delete this.sprites;

        for (let key in this.texts)
        {
            this.removeChild(this.texts[key]);
            this.texts[key].destroy(options);
        }
        delete this.texts;

        if (this.animationText)
        {
            this.removeChild(this.animationText);
            this.animationText.destroy(options);
            delete this.animationText;
        }

        super.destroy(options);
    }

    /*******************************************
    *   BUILD
    *******************************************/
    build()
    {
        super.build();

        this.buildBackground();
        this.buildTextLines();
        this.buildCorners();
    }

    clean()
    {
        if (this.background)
        {
            this.background.clear();
        }
        super.clean();
    }

    buildBackground()
    {
        if (!this.background)
        {
            this.background = new GraphicsResponsive().init({"ui": this.UI});
            this.background.on("pointerup", this.fctOnSelfClick);
            this.addChild(this.background);
        }

        this.background.interactive = this.CanClick;
        this.background.buttonMode = this.CanClick;

        let graphics = this.background;

        let margin = this.evaluate(this.CellMargin, 0, 0, 0, 0);
        let width = this.evaluate(this.BgWidth, 0, 0, 0, 0) - margin;;
        let height = this.evaluate(this.BgHeight, 0, 0, 0, 0) - margin;
        let x = this.evaluate(this.BgX, 0, 0, 0, 0) + margin / 2;
        let y = this.evaluate(this.BgY, 0, 0, 0, 0) + margin / 2;
        let cornerRadius = this.evaluate(this.CornerRadius, 0, 0, 0, 0);

        let color = this.BackgroundColor;
        let opacity = this.BackgroundOpacity;

        if (this.IsDone)
        {
            color = this.BackgroundDoneColor;
            opacity = this.BackgroundDoneOpacity;
        }
        else if (this.IsBad)
        {
            color = this.BackgroundBadColor;
            opacity = this.BackgroundBadOpacity;
        }
        else if (this.IsGood)
        {
            color = this.BackgroundGoodColor;
            opacity = this.BackgroundGoodOpacity;
        }

        if (this.drawBorder)
        {
            graphics.lineStyle({"color":this.BorderColor, "width":height * 0.03, "alignment":0.5});
        }

        graphics.beginFill(color, opacity);
        if (cornerRadius)
        {
            graphics.drawRoundedRect(x, y, width, height, cornerRadius);
        }
        else
        {
            graphics.drawRect(x, y, width, height, cornerRadius);
        }
        graphics.endFill();
    }

    buildTextLines()
    {
        let zoneX = this.evaluate(this.TextZoneX, 0, 0, 0, 0);
        let zoneY = this.evaluate(this.TextZoneY, 0, 0, 0, 0);

        let lineHeight = 0;
        let textAlpha = (this.IsDone ? this.TextDoneOpacity : this.TextOpacity);

        for (let i = 0; i < this.lines.length; i++)
        {
            let key = "line_" + i;
            if (!this.texts[key])
            {
                this.texts[key] = new TextResponsive().init({"ui": this.UI});
                this.addChild(this.texts[key]);
            }
            else if (!this.texts[key].parent)
            {
                this.addChild(this.texts[key]);
            }

            let text = this.texts[key];
            let label = this.lines[i].substr(1);
            let textColor = this.lines[i].substr(0, 1);
            if (textColor === " ")
            {
                textColor = this.FontColor;
            }
            else
            {
                textColor = this.getGlowColor(parseInt(textColor) - 2);
            }

            let style = new TextStyle({
                fontFamily: this.FontFamily,
                fontSize:   this.FontSize,
                fontWeight: this.FontWeight,
                fill: textColor,
                align: "left"
            });

            let metrics = TextMetrics.measureText(label, style);
            lineHeight = this.FontSize * 1.25;

            text.text = label;
            text.style = style;
            text.width = metrics.width;
            text.height = metrics.height;
            text.x = zoneX;
            text.y = zoneY + i * lineHeight;
            text.alpha = textAlpha;
        }
    }

    buildCorners()
    {
        if (this.IsSelected)
        {
            let vertical = ["top", "bottom"];
            let horizontal = ["left", "right"];
            let state = (this.IsDone ? "bad" : "good");

            let height = this.evaluate(this.CornerSpriteHeight, 0, 0, 0, 0);
            let bgWidth = this.evaluate(this.BgWidth, 0, 0, 0, 0);
            let bgHeight = this.evaluate(this.BgHeight, 0, 0, 0, 0);
            let bgX = this.evaluate(this.BgX, 0, 0, 0, 0) - bgWidth * 0.016;
            let bgY = this.evaluate(this.BgY, 0, 0, 0, 0) - bgWidth * 0.016;
            bgHeight += bgWidth * 0.032;
            bgWidth *= 1.032;

            for (let key in this.sprites)
            {
                if (this.sprites[key].parent && key.indexOf(state) < 0)
                {
                    this.removeChild(this.sprites[key]);
                }
            }

            for (let i = 0; i < vertical.length; i++)
            {
                for (let j = 0; j < horizontal.length; j++)
                {
                    let key = this.MinigameId + "_aim_" + state + "_" + vertical[i] + "_" + horizontal[j];
                    if (!this.sprites[key])
                    {
                        let texture = Library.getTextureFromAtlas(this.AtlasId, key + ".png");
                        this.sprites[key] = new SpriteResponsive(texture).init({"ui": this.UI});
                        this.sprites[key].ratio = texture.orig.width / texture.orig.height;
                        this.addChild(this.sprites[key]);
                    }
                    else if (!this.sprites[key].parent)
                    {
                        this.addChild(this.sprites[key]);
                    }

                    let sprite = this.sprites[key];

                    let width = height * sprite.ratio;
                    let x = bgX + (j ? bgWidth - width : 0);
                    let y = bgY + (i ? bgHeight - height : 0);

                    sprite.width = width;
                    sprite.height = height;
                    sprite.position.set(x, y);
                }
            }
        }
        else
        {
            for (let key in this.sprites)
            {
                if (this.sprites[key].parent)
                {
                    this.removeChild(this.sprites[key]);
                }
            }
        }
    }

    buildAnimation(bTextOnly = false)
    {
        for(let key in this.texts)
        {
            if (this.texts[key].parent)
            {
                this.removeChild(this.texts[key])
            }
        }

        if (!this.animationText)
        {
            this.animationText = new TextResponsive().init({"ui": this.UI});
            this.addChild(this.animationText);
        }
        else if (!this.animationText.parent)
        {
            this.addChild(this.animationText);
        }

        let text = this.animationText;

        let lineCount = Math.ceil(this.maxLines * Math.random());
        let str = "";
        for (let i = 0; i < lineCount; i++)
        {
            let charCount = Math.ceil(this.maxChars * Math.random());
            for (let j = 0; j < charCount; j++)
            {
                let rand = Math.random();
                str += (rand <= 0.1 && j > 1 ? " " : (rand <= 0.55 ?  "1" : "0"));
            }
            str += "\r\n";
        }

        if (!text.style || !bTextOnly)
        {
            text.style = new TextStyle({
                fontFamily: this.FontFamily,
                fontSize:   this.FontSize,
                fontWeight: this.FontWeight,
                fill: this.FontColor,
                lineHeight: this.FontSize * 1.25,
                align: "left"
            });
        }

        let metrics = TextMetrics.measureText(str, text.style);

        text.text = str;
        text.width = metrics.width;
        text.height = metrics.height;

        if (!bTextOnly)
        {
            text.position.set(
                this.evaluate(this.TextZoneX, 0, 0, 0, 0),
                this.evaluate(this.TextZoneY, 0, 0, 0, 0)
            )
        }
    }

    /*******************************************
    *   ACTIONS
    *******************************************/
    setBad(fBadTime = -1)
    {
        this.badTimeLeft = (fBadTime <= 0 ? this.BAD_TIME : fBadTime);
        this.build();
    }

    animate(fAnimationTime)
    {
        if (!this.IsAnimating)
        {
            this.animationTimeLeft = fAnimationTime;
            this.lastChange = fAnimationTime;
            this.buildAnimation();            
        }

    }

    /*******************************************
    *   UPDATE LOOP
    *******************************************/
    update(fDeltaTime)
    {
        this.updateBadTime(fDeltaTime);
        this.updateAnimation(fDeltaTime);
    }

    updateBadTime(fDeltaTime)
    {
        if (this.badTimeLeft > 0)
        {
            this.badTimeLeft -= fDeltaTime;
            if (this.badTimeLeft < 0)
            {
                this.build();
            }
        }
    }

    updateAnimation(fDeltaTime)
    {
        if (this.animationTimeLeft > 0)
        {
            this.animationTimeLeft -= fDeltaTime;

            if (this.animationTimeLeft <= 0)
            {
                this.removeChild(this.animationText);
                this.build();
            }
            else if (this.lastChange - this.animationTimeLeft >= this.ANIMATION_BUILD_INTERVAL)
            {
                this.lastChange = this.animationTimeLeft;
                this.buildAnimation();
            }
        }
    }

    /*******************************************
    *   EVENTS
    *******************************************/
    onSelfClick()
    {
        this.emit(this.EVENT_CLICK, this);
    }
}