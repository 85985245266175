import { Graphics } from '@pixi/graphics';
import { Sprite } from '@pixi/sprite';
import ColorTheme from "../../ColorTheme.js";
import ContainerResponsive from "../responsive/ContainerResponsive.js";
import Library from "../../../Library.js";

export default class Character extends ContainerResponsive
{
    constructor()
    {
        super();
    }

    get COLOR_HAIR () { return "color-hair"};
    get COLOR_SKIN () { return "color-skin"};
    get COLOR_IRIS () { return "color-iris"};
    get COLOR_SWEATER () { return "color-sweater"};
    get COLOR_PANTS () { return "color-pants"};
    get TYPE_HAIR () { return "type-hair"};
    get MUTATION_HEAD () { return "mutation-head"};
    get MUTATION_ARMS () { return "mutation-arms"};
    get MUTATION_LEGS () { return "mutation-legs"};

    get Build() { return this.buildId; }

    /*******************************************
    *   INITIALIZATION
    *******************************************/
    /**
        Parameters to pass to the init function:
        - ui:    UI section object where this component resides
        - build: A list of color and type = first 6 digits are colors and 4 last are part type
                 ex: 074353_1011
                 Digits are HAIR-SKIN-IRIS-SWEATER-LEGS-BOOTS  _   HAIR-HEAD-ARMS-LEGS
                 IMPORTANT NOTE FOR MUTATION (HEAD, ARMS AND LEGS): 0 means NO MUTATION, BUT 1 mean index 0 in ColorTheme.MUTATIONS array,
                 So if we have  074353_1011 means that we have NO head mutation, the BEAR arm and ELEPHANT leg
    */
    init(meta)
    {
        this.decomposeIndex(meta.build);
        this.isGeneratingAnimation = false;

        return super.init(meta);
    }

    /*******************************************
    *   BUILD
    *******************************************/
    build ()
    {
        super.build();
        
        if (this.children.length == 0)
        {
            let textures = Library.getTextureListFromAtlas("character_1frame");
            
            let arrLayers = this.getLayers();
            let arrColors = this.getColors();

            for (let i = 0; i < arrLayers.length; i++)
            {
                let strKey = arrLayers[i];
                let texture = textures[strKey];

                let layer = new Sprite(texture);
                layer.tint = arrColors[i];
                this.addChild(layer);

                layer.name = strKey;
            }
        }
    }

    /*******************************************
    *   PARTS
    *******************************************/
    updatePart (type, index)
    {
        if (type === this.COLOR_HAIR)
        {
            this.hair = index;
        }
        else if (type === this.COLOR_SKIN)
        {
            this.skin = index;
        }
        else if (type === this.COLOR_IRIS)
        {
            this.iris = index;
        }
        else if (type === this.COLOR_SWEATER)
        {
            this.sweater = index;
        }
        else if (type === this.COLOR_PANTS)
        {
            this.legs = index;
            this.boots = index;
        }
        else if (type === this.TYPE_HAIR)
        {
            this.type_hair = index;
        }
        else if (type === this.MUTATION_HEAD)
        {
            this.mutation_head = index;
        }
        else if (type === this.MUTATION_ARMS)
        {
            this.mutation_arm = index;
        }
        else if (type === this.MUTATION_LEGS)
        {
            this.mutation_legs = index;
        }

        this.build();
    }

    decomposeIndex (_strItem)
    {
        if (_strItem.length !== 11)
        {
            throw ("Unable to create character. Character color string must be exactly 10 in length. We receive '" + _strItem + "'. It has a length of " +  _strItem.length );
        }

        this.buildId = _strItem;

        this.hair = _strItem[0];
        this.skin = _strItem[1];
        this.iris = _strItem[2];
        this.sweater = _strItem[3];
        this.legs = _strItem[4];
        this.boots = _strItem[5];

        this.type_hair = parseInt(_strItem[7]) + 1;
        this.mutation_head = _strItem[8];
        this.mutation_arm = _strItem[9];
        this.mutation_legs = _strItem[10];

    }

    /*******************************************
    *   LAYERS & COLORS
    *******************************************/
    getLayers ()
    {
        let hasHeadMutation = this.mutation_head !== "0";
        let hasArmMutation = this.mutation_arm !== "0";
        let hasLegsMutation = this.mutation_legs !== "0";

        let strMutationHead = hasHeadMutation ? ColorTheme.MUTATION_HEAD[this.mutation_head - 1] : "";
        let strMutationArm = hasArmMutation ? ColorTheme.MUTATION_ARM[this.mutation_arm - 1] : "";
        let strMutationLegs = hasLegsMutation ? ColorTheme.MUTATION_LEGS[this.mutation_legs - 1] : "";

        let hasBackHair = this.type_hair == "6";

        let arrLayers = [];

        if (hasBackHair) //back hair must go behind everything
        {
            arrLayers.push("18_hair_6_back.png");
        }

        if (!hasArmMutation)
        {
            arrLayers.push("0_righthand.png");
            arrLayers.push("1_rightarm.png");
        }
        else
        {
            arrLayers.push(`1_${strMutationArm}.png`);
        }




        if (!hasLegsMutation)
        {
            arrLayers.push("2_rightleg.png")
            arrLayers.push("3_rightfoot.png");
            arrLayers.push("4_hips.png");
            //arrLayers.push("5_neck.png");
            arrLayers.push("6_leftleg.png");
            arrLayers.push("7_leftfoot.png");
        }
        else
        {
            arrLayers.push(`2_${strMutationLegs}.png`);
            arrLayers.push(`3_${strMutationLegs}.png`);
            arrLayers.push(this.mutation_legs !== "rabbit" ? "4_hips.png" : "4_rabbit.png");

            arrLayers.push(`6_${strMutationLegs}.png`);
            arrLayers.push(`7_${strMutationLegs}.png`);
        }

        arrLayers.push("8_torso.png");
        arrLayers.push("5_neck.png");


        if (!hasArmMutation)
        {
            arrLayers.push("9_lefthand.png");
            arrLayers.push("10_leftarm.png");
        }
        else
        {
            arrLayers.push(`10_${strMutationArm}.png`);
        }


        if (!hasHeadMutation)
        {
            arrLayers.push("11_face.png");
            arrLayers.push("12_mouth.png");
            arrLayers.push("13_nose.png");
            arrLayers.push("14_eyes.png");
            arrLayers.push("15_eyelashes.png");
            arrLayers.push("16_eyebrows.png");
            arrLayers.push("17_iris.png");

        }
        else
        {
            arrLayers.push(`11_${strMutationHead}.png`);
        }

        arrLayers.push(`18_hair_${this.type_hair}.png`);

        return arrLayers;
    }

    getColors()
    {
        let hair = ColorTheme.HAIR[this.hair];
        let skin = ColorTheme.SKIN[this.skin];
        let neck = ColorTheme.NECK[this.skin];
        let mouth = ColorTheme.getMouthFromSkin(skin)
        let nose = ColorTheme.getNoseFromSkin(skin);
        let sweater = ColorTheme.SWEATER[this.sweater];
        let legs = ColorTheme.LEG[this.legs];
        let boots = ColorTheme.BOOTS[this.boots];
        let eyes = 0xdceff3;
        let iris = ColorTheme.IRIS[this.iris];
        let none = 0xFFFFFF;

        let hasHeadMutation = this.mutation_head !== "0";
        let hasArmMutation = this.mutation_arm !== "0";
        let hasLegsMutation = this.mutation_legs !== "0";
        let hasBackHair = this.type_hair == "6";

        let arrColors = [];

        if (hasBackHair) //back hair must go behind everything
        {
            arrColors.push(hair);
        }

        if (!hasArmMutation)
        {
            arrColors.push(skin);
            arrColors.push(sweater);
        }
        else
        {
            arrColors.push(none);
        }

        if (!hasLegsMutation)
        {
            arrColors.push(legs)
            arrColors.push(boots);
            arrColors.push(legs);

            arrColors.push(legs);
            arrColors.push(boots);
        }
        else
        {
            arrColors.push(none);
            arrColors.push(legs);
            arrColors.push(this.mutation_legs !== "rabbit" ? legs : none);
            arrColors.push(none);
            arrColors.push(legs);
        }

        arrColors.push(sweater);
        arrColors.push(skin);


        if (!hasArmMutation)
        {
            arrColors.push(skin);
            arrColors.push(sweater);
        }
        else
        {
            arrColors.push(none);
        }


        if (!hasHeadMutation)
        {
            arrColors.push(skin);
            arrColors.push(mouth);
            arrColors.push(nose);
            arrColors.push(eyes);
            arrColors.push(hair);
            arrColors.push(hair)
            arrColors.push(iris);
        }
        else
        {
            arrColors.push(none);
        }

        arrColors.push(hair);

        return arrColors;
    }

    getTheme()
    {
        let strColor = this.hair.toString() + this.skin.toString() + this.iris.toString() + this.sweater.toString() + this.legs.toString() + this.boots.toString();
        let strType = (this.type_hair - 1).toString() + this.mutation_head.toString() + this.mutation_arm.toString() + this.mutation_legs.toString();

        return strColor + "_" + strType;
    }
}

