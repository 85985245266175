import {Vector2} from "three";
import Direction from "../../utils/Direction.js";
import RoamingAI from "./RoamingAI.js";
import WorldManager from "../WorldManager.js";

export default class EnemyAI extends RoamingAI
{
    constructor()
    {
        super();
    }

    get COOLDOWN_TIME() { return 1.0; }
    get ATTACKING_TIME() { return 18.0; }
    get ATTACKING_MAX_DISTANCE_RATIO() { return 2.0; }
    get MAX_ATTACK_RANGE() { return 1.25 * this.Character.Grid.CellSize; }

    get AttackSpeed() { return this.params.attack.speed; }
    get AttackEnergyLoss() { return this.params.attack.energyLoss; }
    get AttackCooldown() { return this.params.attack.cooldown; }
    get AttackStrikeTime() { return this.params.attack.strikeTime; }
    get StunCooldown() { return this.Character.stunCooldown;}

    get IsTriggered() { return (this.target ? true : false);}
    get IsStun() { return this.StunCooldown > 0;}
    get CurrentPath() { return this.Character.CharacterAction.CurrentPath; }
    get TileToReach() { return this.Character.CharacterAction.TileToReach; }

    get MovementSpeed()
    {
        if (this.IsTriggered)
        {
            return this.AttackSpeed / 2;
        } 
        return this.RoamSpeed / 2;
    }

    /*******************************************
    *   INITIALIZATION
    *******************************************/
    /**
        Parameters to pass to the init function:
        - character     Character object this AI should have governance over
        - spawnPos      Vector2 containing the world position where the character is supposed to spawn on the map
        - definition    Behaviour definition from the settings.json file (only the sub part of a definition called "ai")
    */
    init(meta)
    {
        super.init(meta);

        this.timeLeftAttacking = 0;
        this.timeLeftCooldown = 0;
        this.attackChargeTime = 0;
        this.isComingBack = true;
    }

    createClosure()
    {
        super.createClosure();
        this.fctOnFOVCollision = this.onFOVCollision.bind(this);
        this.fctOnStunStart = this.onStunStart.bind(this);
        this.fctOnStunEnd = this.onStunEnd.bind(this);
    }

    bindEvents()
    {
        super.bindEvents();
        this.Character.on(this.Character.EVENT_FOV_COLLISION, this.fctOnFOVCollision);
        this.Character.on(this.Character.EVENT_STUN_START, this.fctOnStunStart);
        this.Character.on(this.Character.EVENT_STUN_END, this.fctOnStunEnd);
    }

    destroy()
    {
        if (this.Character)
        {
            this.Character.off(this.Character.EVENT_FOV_COLLISION, this.fctOnFOVCollision);
        }
        super.destroy();
    }

    parseDefinition(objDefinition)
    {
        super.parseDefinition(objDefinition);

        //Default parameters
        this.params.attack = {
            "speed": 1,
            "energyLoss": 0.1,
            "cooldown": 1.5,
            "strikeTime": 0.33
        };

        if ("attack" in objDefinition)
        {
            if ("speed" in objDefinition.attack)
            {
                this.params.attack.speed = objDefinition.attack.speed;
            }
            if ("energyLoss" in objDefinition.attack)
            {
                this.params.attack.energyLoss = objDefinition.attack.energyLoss;
            }
            if ("cooldown" in objDefinition.attack)
            {
                this.params.attack.cooldown = objDefinition.attack.cooldown;
            }
            if ("strikeTime" in objDefinition.attack)
            {
                this.params.attack.strikeTime = objDefinition.attack.strikeTime;
            }
        }
    }

    /*******************************************
    *   UPDATE LOOP
    *******************************************/
    update(fDeltaTime)
    {
        super.update(fDeltaTime);

        this.updateAttack();
        this.updatePathing();

        if (!this.IsStun)
        {
            if (this.isComingBack)
            {
                if ((!this.CurrentPath || this.CurrentPath.length == 0) && !this.Character.IsMoving)
                {
                    this.isComingBack = false;
                    this.startRoaming();
                }
            }

            if (this.timeLeftCooldown > 0)
            {
                this.timeLeftCooldown -= fDeltaTime;
            }
        }
    }

    updateAttack ()
    {
        if (WorldManager.instance.IsCinematicMode)
            return;

        if (this.target)
        {
            if (this.target.IsDead || this.target.IsInvisible || this.target.IsStun)
            {
                this.stopAttacking();
                this.Character.stopMovement();
            }
            else
            {
                this.timeLeftAttacking -= this.DeltaTime;
                if (this.timeLeftAttacking <= 0)
                {
                    this.stopAttacking();
                }

                if (!this.IsStun)
                {
                    if (this.timeLeftAttacking > 0)
                    {
                        let pos = this.Character.GridPos;
                        let sqrDist = Math.pow(pos.x - this.SpawnPosition.x, 2) + Math.pow(pos.y - this.SpawnPosition.y, 2);
                        if (sqrDist >= Math.pow(this.RoamRange * this.ATTACKING_MAX_DISTANCE_RATIO, 2))
                        {
                            this.stopAttacking();
                        }
                    }

                    if (!this.isComingBack)
                    {
                        let pos = this.Character.Mesh.position;
                        let targetPos = this.target.Mesh.position;

                        let sqrDist = Math.pow(pos.x - targetPos.x, 2) + Math.pow(pos.z - targetPos.z, 2);

                        //If a tile away, tries to attack
                        if (sqrDist <= Math.pow(this.MAX_ATTACK_RANGE, 2))
                        {
                            this.attackChargeTime += this.DeltaTime;
                            if (this.attackChargeTime >= this.AttackStrikeTime)
                            {
                                this.hitTarget(this.target);
                            }
                        }
                        else if (!this.target.IsInvincible && this.timeLeftCooldown <= 0)
                        {
                            this.attackChargeTime = 0;
                            if (!this.Character.IsMoving)
                            {
                                let p = this.target.GridPos;
                                let cellSize = this.Character.Grid.CellSize;

                                let pos = this.Character.goTo(p.x * cellSize, p.y * cellSize);
                            }
                        }
                    }

                    this.updateFOVAngle();
                }
            }
        }
    }

    updatePathing()
    {
        if (WorldManager.instance.IsCinematicMode)
            return;

        if (this.target && this.CurrentPath && this.CurrentPath.length > 0)
        {
            let pos = this.character.GridPos;
            let targetPos = this.target.GridPos;
            let currentSqrDist = Math.pow(targetPos.x - pos.x, 2) + Math.pow(targetPos.y - pos.y, 2);

            if (this.CurrentPath)
            {
                for (let i = this.CurrentPath.length - 1; i >= 0; i--)
                {
                    let sqrDist = Math.pow(targetPos.x - this.CurrentPath[i][0], 2) + Math.pow(targetPos.y - this.CurrentPath[i][1], 2);
                    if (sqrDist > currentSqrDist)
                    {
                        this.CurrentPath.splice(i, 1);
                    }
                }
            }

            let charPos = this.Character.GridPos;
            if (targetPos.x == charPos.x && targetPos.y == charPos.y)
            {
                this.Character.CharacterAction.movement.toReach = new Vector2(
                    this.target.Mesh.position.x,
                    this.target.Mesh.position.z
                );
            }
        }
    }

    updateFOVAngle()
    {
        if (WorldManager.instance.IsCinematicMode)
            return;

        if (this.target && !this.target.IsInvincible)
        {
            let angle = Math.atan2(
                this.Character.FOVPivot.position.x - this.target.Mesh.position.x, 
                this.Character.FOVPivot.position.z - this.target.Mesh.position.z
            );

            this.Character.FOVPivot.rotation.z = angle;
        }
    }

    /*******************************************
    *   ACTIONS
    *******************************************/
    startAttacking(objCharacter)
    {
        if (this.timeLeftCooldown <= 0 && !this.IsStun && !objCharacter.IsInvincible && !objCharacter.IsDead && !objCharacter.IsInvisible)
        {
            this.target = objCharacter;

            this.Character.setFOVState(true);
            this.Character.fovAnimation.current = null;
            this.Character.fovAnimation.next = null;
            this.Character.stopMovement();
            this.isComingBack = false;

            let posOnGrid = this.target.GridPos;
            let cellSize = this.Character.Grid.CellSize;

            this.Character.goTo(
                posOnGrid.x * cellSize, 
                posOnGrid.y * cellSize
            );
            this.updatePathing();

            this.timeLeftAttacking = this.ATTACKING_TIME;
            this.stopRoaming();

            return true;
        }
        return false;
    }

    stopAttacking()
    {
        if (!this.IsStun)
        {
            this.target = null;

            this.Character.setFOVState(false);
            this.isComingBack = true;
            this.timeLeftCooldown = this.COOLDOWN_TIME;

            let cellSize = this.Character.Grid.CellSize;
            this.character.goTo(
                this.SpawnPosition.x * cellSize, 
                this.SpawnPosition.y * cellSize
            );
        }
    }

    hitTarget(objTarget)
    {
        if (!this.IsStun && !objTarget.IsInvincible && !objTarget.IsStun && !objTarget.IsInvisible)
        {
            objTarget.getHit(this.AttackEnergyLoss);
            this.Character.setStun(this.AttackCooldown);

            let targetPos = objTarget.Mesh.position;
            let charPos = this.Character.Mesh.position;

            let diff = new Vector2(charPos.x - targetPos.x, charPos.z - targetPos.z);
            let newDirection = this.Character.Direction;
            if (Math.abs(diff.x) > Math.abs(diff.y))
            {
                newDirection = (diff.x > 0 ? Direction.West : Direction.East);
            }
            else
            {
                newDirection = (diff.y > 0 ? Direction.North : Direction.South);
            }

            if (this.Character.Direction != newDirection)
            {
                this.Character.changeDirection(newDirection, true);                
            }

            return true;
        }
        return false;
    }

    /*******************************************
    *   EVENTS
    *******************************************/
    onCharacterTileChange(objCharacter, iTileX, iTileY)
    {
        if (this.target && objCharacter == this.target && !objCharacter.IsInvincible && !objCharacter.IsInvisible && !objCharacter.IsStun && !this.IsStun)
        {
            let targetPos = objCharacter.GridPos;
            let cellSize = this.Character.Grid.CellSize;

            this.Character.goTo(
                targetPos.x * cellSize, 
                targetPos.y * cellSize
            );

            this.updatePathing();
        }
        else if (this.target && objCharacter == this.target)
        {
            this.stopAttacking();
        }
    }

    onFOVCollision(target)
    {
        if (!this.IsTriggered && !target.IsDead && !target.IsInvincible && !target.IsInvisible && target.Type == "player")
        {
            this.startAttacking(target);
        }
    }

    onStunStart()
    {
        this.Character.fovAnimation.current = null;
        this.Character.fovAnimation.next = null;
        this.target = null;
        this.Character.setFOVState(false);
        this.Character.onMoveEnd();
    }

    onStunEnd()
    {
        this.Character.Mesh.material.opacity = 1;
                this.isComingBack = true;
    }
}