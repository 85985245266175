import { string2hex }  from "@pixi/utils"; 
import { TextStyle } from "@pixi/text";
import { TextMetrics } from "@pixi/text";
import ContainerResponsive from "../commons/responsive/ContainerResponsive.js";
import Display from "../../utils/Display.js";
import GraphicsResponsive from "../commons/responsive/GraphicsResponsive.js";
import TextButton from "../commons/buttons/TextButton.js";
import TextResponsive from "../commons/responsive/TextResponsive.js";

export default class Message extends ContainerResponsive
{
    constructor()
    {
        super();
    }

    get EVENT_BUTTON_CLICK() { return "button-click"; }

    get BgWidth() { return "(ih * 0.6)"; }
    get BgHeight() { return "(" + this.MessageHeight + " + ih * 0.15)"; }
    get BgX() { return "(iw / 2 - " + this.BgWidth + " / 2)"; }
    get BgY() { return "(ih / 2 - " + this.BgHeight + " / 2)"; }

    get BackgroundColor() { return string2hex(this.UI.Values.modal.background.color);}
    get StrokeWidth() { return this.evaluate(this.valueFormula(this.UI.Values.modal.stroke.size), 0, 0, 0, 0);}
    get StrokeColor() { return string2hex("color" in this.UI.Values.modal.stroke ? this.UI.Values.modal.stroke.color : "#000000");}
    get CornerRadius() { return this.evaluate(this.valueFormula(this.UI.Values.modal.corner.radius), 0, 0, 0, 0);}

    get MessageLabel() { return this.message; }

    get MessageWidth() { return "(" + this.BgWidth + " * 0.75)"; }
    get MessageHeight()
    {
        let style = this.MessageStyle;
        let metrics = TextMetrics.measureText(this.MessageLabel, style);

        return metrics.height;
    }

    get MessageFont() { return this.UI.Values.general.title.font; }
    get MessageFontSize() { return this.evaluate(this.valueFormula(this.UI.Values.general.title.size)); }
    get MessageFontWeight() { return this.UI.Values.general.title.weight; }
    get MessageColor() { return this.UI.Values.general.text.color; }

    get MessageStyle()
    {
        return new TextStyle({
            fontFamily: this.MessageFont,
            fontSize:   this.MessageFontSize,
            fontWeight: this.MessageFontWeight,
            fill: this.MessageColor,
            align: "center",
            wordWrap: true,
            wordWrapWidth: this.evaluate(this.MessageWidth, 0, 0, 0, 0)
        });
    }

    /*******************************************
    *   INITIALIZATION
    *******************************************/
    /**
        Parameters to pass to the init function:
        - ui:           UI section object where this component resides
        - message:      Message to display in the component
        - buttonLabel:  Label to display in the button
    */
    init(meta)
    {
        this.message = meta.message;
        this.buttonLabel = meta.buttonLabel;

        this.graphics = {};
        this.texts = {};
        this.button = null;

        return super.init(meta);
    }

    createClosure()
    {
        super.createClosure();
        this.fctOnButtonClick = this.onButtonClick.bind(this);
    }

    destroy(options)
    {
        for (let key in this.graphics)
        {
            this.removeChild(this.graphics[key]);
            this.graphics[key].destroy(options);
        }
        delete this.graphics;

        for (let key in this.texts)
        {
            this.removeChild(this.texts[key]);
            this.texts[key].destroy(options);
        }
        delete this.texts;

        if (this.button)
        {
            this.removeChild(this.button);
            this.button.off(this.button.EVENT_CLICK, this.fctOnButtonClick);
            this.button.destroy(options);
        }
        delete this.button;

        super.destroy(options);
    }

    /*******************************************
    *   BUILD
    *******************************************/
    build()
    {
        super.build();

        this.buildOverlay();
        this.buildBackground();
        this.buildButton();
        this.buildMessage();
    }

    clean()
    {
        for (let key in this.graphics)
        {
            this.graphics[key].clear();
        }
        super.clean();
    }

    buildOverlay()
    {
        if (!this.graphics.overlay)
        {
            this.graphics.overlay = new GraphicsResponsive().init({"ui": this.UI});
            this.addChild(this.graphics.overlay);
        }
        
        let graphics = this.graphics.overlay;

        let x = 0;
        let y = 0;
        let width = this.evaluate("iw", 0, 0, 0, 0);
        let height = this.evaluate("ih", 0, 0, 0, 0);

        graphics.beginFill(0, this.UI.Values.modal.shadow.opacity);
        graphics.drawRect(x, y, width, height);
        graphics.endFill();

        graphics.interactive = true;
        graphics.buttonMode = false;
    }

    buildBackground()
    {
        if (!this.graphics.background)
        {
            this.graphics.background = new GraphicsResponsive().init({"ui":this.UI});
            this.graphics.background.interactive = true;

            this.addChild(this.graphics.background);
        }

        let graphics = this.graphics.background;

        let width = this.evaluate(this.BgWidth, 0, 0, 0, 0);
        let height = this.evaluate(this.BgHeight, 0, 0, 0, 0);
        let x = this.evaluate(this.BgX, 0, 0, 0, 0);
        let y = this.evaluate(this.BgY, 0, 0, 0, 0);

        let bgColor = this.BackgroundColor;
        let strokeWidth = this.StrokeWidth;
        let strokeColor = this.StrokeColor;
        let cornerRadius = this.CornerRadius;

        if (strokeWidth > 0)
        {
            graphics.lineStyle({"color":strokeColor, "width":strokeWidth, "alignment":1});
        }

        graphics.beginFill(bgColor, 1);
        if (cornerRadius > 0)
        {
            graphics.drawRoundedRect(x, y, width, height, cornerRadius);
        }
        else
        {
            graphics.drawRect(x, y, width, height);
        }
        graphics.endFill();
    }

    buildButton()
    {
        if (!this.button)
        {
            this.button = new TextButton().init({
                "ui": this.UI,
                "text": this.buttonLabel
            });
            this.button.on(this.button.EVENT_CLICK, this.fctOnButtonClick);
        }

        let bgHeight = this.evaluate(this.BgHeight, 0, 0, 0, 0);
        let messageHeight = this.MessageHeight;

        let zoneHeight = messageHeight + this.button.ButtonHeight * 1.5;
        let y = bgHeight / 2 - zoneHeight / 2 + messageHeight + this.button.ButtonHeight / 2;

        this.button.rX = [{on: "default", x:this.BgX + " + " + this.BgWidth + " / 2" + " - " + this.button.ButtonWidth + " / 2"}];
        this.button.rY = [{on: "default", y: this.BgY + " + " + y}];

        this.button.layout(Display.getSize());
    }

    buildMessage()
    {
        if (!this.texts.message)
        {
            this.texts.message = new TextResponsive().init({"ui": this.UI});
            this.addChild(this.texts.message);
        }

        let text = this.texts.message;
        let label = this.MessageLabel;

        let style = this.MessageStyle;
        let metrics = TextMetrics.measureText(label, style);

        let bgWidth = this.evaluate(this.BgWidth, 0, 0, 0, 0);
        let bgHeight = this.evaluate(this.BgHeight, 0, 0, 0, 0);

        let zoneHeight = metrics.height + this.button.ButtonHeight * 1.5;
        let x = this.evaluate(this.BgX, 0, 0, 0, 0) + bgWidth / 2 - metrics.width / 2;
        let y = this.evaluate(this.BgY, 0, 0, 0, 0) + bgHeight / 2 - zoneHeight / 2;

        text.text = label;
        text.style = style;
        text.width = metrics.width;
        text.height = metrics.height;
        text.position.set(x, y);
    }

    /*******************************************
    *   EVENTS
    *******************************************/
    onButtonClick()
    {
        this.emit(this.EVENT_BUTTON_CLICK);
    }

}