import BaseCheat from "./BaseCheat.js";

export default class Teleport extends BaseCheat
{
    constructor(dependencies)
    {
        super(dependencies);
    }

    get CommandUse() { return "teleport [x,y]"; }
    get CommandDesc() { return "Teleport to a specific place on the map"; }

    //---------------------------------------------------------
    //  DEPENDENCIES
    //---------------------------------------------------------
    get WorldManager() { return this.dependencies.get("WorldManager"); }
    //---------------------------------------------------------

    parseCommand(strCmd, arrArguments)
    {
        if (strCmd == "teleport")
        {
            this.WorldManager.teleportTo(arrArguments[0], arrArguments[1])
        }
    }
}