import axios from "axios";
import EventEmitter from "eventemitter3";
import Constants from "./Constants.js";
import Library from "../Library.js";

export default class AnimationsCreator extends EventEmitter
{
    constructor(dependencies)
    {
        super();

        AnimationsCreator.instance = this;
        this.dependencies = dependencies;

        this.isGenerating = false;
        this.arrCharacters = [];

        this.arrAtlasToLoad = [];

        this.fctOnAtlasReady = this.onAtlasReady.bind(this);
        this.fctGenerationComplete = this.onGenerationComplete.bind(this);
        this.fctOnAtlasAssetLoaded = this.onAtlasAssetLoaded.bind(this);
    }

    //Triggered when the loading process has progressed. Provides the progress in a value from 0 to 1
    get EVENT_PROGRESSION() { return "progression"; }
    //Triggered when the loading process has finished
    get EVENT_DONE() { return "done"; }
    //Triggered when an animation has finished loading. Provides the name of the animation
    get EVENT_ANIMATION_LOADED() { return "animation-loaded"; }

    get EVENT_GENERATION_COMPLETE() { return "generation-completed"; }

    get AnimationsListUrl() { return Constants.getValue("HERO_ANIMATIONS_LIST_URL"); }
    get AnimationCreationUrl() { return Constants.getValue("CREATE_ANIMATION_URL"); }

    prepareForGeneration(colors, iCharacter = 0)
    {
        let layers = [
            "lateral_idle_", "front_idle_", "back_idle_",
            "lateral_run_", "front_run_", "back_run_",
            "lateral_collect_", "front_collect_", "back_collect_",
            "lateral_use_object_", "front_use_object_", "back_use_object_",
            "front_fishing_idle_", "back_fishing_idle_", "lateral_fishing_idle_",
             "lateral_push_", "front_push_", "back_push_",
             "lateral_stomp_", "front_stomp_", "back_stomp_",
             "lateral_spit_", "front_spit_", "back_spit_",
             "lateral_swim_", "front_swim_", "back_swim_",
             "lateral_antistress_",
             "lateral_stressball_",
             "lateral_doll_",
             "lateral_music_"
            // "lateral_trap_",

            // ["lateral_fishing_start_", "lateral_fishing_start_"], ["front_fishing_start_", "front_fishing_start_"], ["back_fishing_start_", "back_fishing_start_"]
        ];

        let character = {colors, layers, id: iCharacter};

        this.arrCharacters.push(character);

        return layers.length;
    }

    /**
        Generates multiple character at once
        @param objDefinition    Key/value object where the key is the ID of the character and the value is a string representing the build
    */
    generateMultiple(objDefinition)
    {
        this.on(this.EVENT_ANIMATION_LOADED,  this.fctOnAtlasReady);//@
        this.on(this.EVENT_GENERATION_COMPLETE,  this.fctGenerationComplete);//@

        
        let count = 0;
        for (let id in objDefinition)
        {
            count += this.prepareForGeneration(objDefinition[id], id);
        }
        count *= 2;

        if (!this.totalToLoad)
        {
            this.totalToLoad = 0;
        }
        this.totalToLoad += count;

        if (!this.isGenerating)
        {
            this.generateNext();
        }
    }

    /**
        Generates a single character's spritesheets
    */
    generate (colors)
    {
        this.prepareForGeneration(colors);

        if (this.isGenerating)
            return;

        this.totalToLoad = layers.length;

        this.generateNext();
    }

    async generateNext ()
    {
        this.isGenerating = true;

        let arrGet = [];
        let arrAlreadyExist = [];


        for (let i = 0; i < this.arrCharacters.length; i++)
        {
            let character = this.arrCharacters[i];

            let color = this.arrCharacters[i].colors;
            let id = this.arrCharacters[i].id;


            let response = await axios.get(this.AnimationsListUrl + "?b=" + color);
            let arrMissing = response.data.missing;
            let arrExisting = response.data.existing;


            let baseCreationUrl = this.AnimationCreationUrl;
            for (let j = 0; j < character.layers.length; j++)
            {
                let layer = character.layers[j];

                let arrSplit = layer.split("/");
                layer = arrSplit[0];
                let page = arrSplit.length > 1 ? "&page=" + arrSplit[1] : "";
                layer = layer.substr(0, layer.length - 1);

                let isMissing = arrMissing.includes(layer) || arrMissing.includes(character.layers[j]);
                let bExist = arrExisting.includes(layer) || arrExisting.includes(character.layers[j]);

                if (isMissing)
                {
                    let url = `?color=${color}&anim=${layer}&index=${id}${page}`;

                    arrGet.push(baseCreationUrl + url);

                }
                else if (bExist)
                {
                    arrAlreadyExist.push(layer)
                }

                if (arrSplit.length > 1)
                {
                    layer += "_page_" + arrSplit[1];
                }

                this.arrAtlasToLoad.push({color, layer});
            }
        }



        let iCpt = 0;
        let iTotal = arrGet.length + arrAlreadyExist.length;


        for (let i = 0; i < arrAlreadyExist.length; i++)
        {
            iCpt++;

            let ratio = iCpt / (iTotal * 2);
            this.emit(this.EVENT_PROGRESSION, ratio);
        }

        if (arrGet.length > 0) //we have some animation missing
        {

            axios.all(
                arrGet.map((url) => axios.get(url).then((result) =>
                    {
                        iCpt++;

                        let ratio = iCpt / (iTotal * 2);
                        this.emit(this.EVENT_PROGRESSION, ratio);

                    })
                ))

                .then(axios.spread(() =>  //all missing animations have been loaded
                {

                    this.isGenerating = false;
                    this.totalToLoad = 0;
                    this.emit(this.EVENT_GENERATION_COMPLETE);
                    return;
                }));
        }
        else //we don't have anu missing animations
        {
            this.isGenerating = false;
            this.totalToLoad = 0;
            this.emit(this.EVENT_GENERATION_COMPLETE);
        }


    }


    onAtlasReady (layer, color)
    {
        layer = layer.replace(/_$/, "");
        layer = layer.replace(/\//, "");

        this.arrAtlasToLoad.push ({color, layer});

    }

    createAssetsLoader ()
    {
        // {"path":"/_/images/animations/hero_merge/",
        //     "objects":[
        //     "back_idle.png","front_idle.png", "lateral_idle.png",
        //     "back_run.png", "front_run.png",  "lateral_run.png",
        //     "back_use_object.png", "front_use_object.png", "lateral_use_object.png",
        //     "lateral_collect.png", "front_collect.png", "back_collect.png",
        //     "lateral_stressball.png", "lateral_doll_1.png", "lateral_doll_2.png", "lateral_game_1.png", "lateral_game_2.png"]}

        let dictAssets = {};

        let strImagePath = this.dependencies.get("ResponsiveManager").ImagesFolder;

        for (let i = 0; i < this.arrAtlasToLoad.length; i++)
        {
            let atlas = this.arrAtlasToLoad[i];

            if (dictAssets[atlas.color] === undefined)
            {
                dictAssets[atlas.color] = {path: strImagePath + "hero/" + atlas.color + "/", prefix: atlas.color, objects:[]};
            }

            dictAssets[atlas.color].objects.push({"key": atlas.color + "_" + atlas.layer, "name": atlas.layer + ".png", "texture": atlas.layer + ".png"});
        }

        let arrAssets = [];
        for (let strKey in dictAssets)
        {
            arrAssets.push(dictAssets[strKey]);
        }

        return arrAssets;


    }

    onGenerationComplete ()
    {
        this.off(this.EVENT_ANIMATION_LOADED,  this.fctOnAtlasReady);
        this.off(this.EVENT_GENERATION_COMPLETE,  this.fctGenerationComplete);

        let arrAssets = this.createAssetsLoader();

        for (let i = 0; i < arrAssets.length; i++)
        {
            this.totalToLoad += arrAssets[i].objects.length;
        }

        this.loadCount = 0;

        let loader = this.dependencies.get("Loader");
        loader.on("end-load-atlas-asset", this.fctOnAtlasAssetLoaded);

        this.loadAtlases(arrAssets);

    }

    loadAtlases (arrAssets)
    {
        if (arrAssets.length === 0)
        {
            let loader = this.dependencies.get("Loader");
            loader.off("end-load-atlas-asset", this.fctOnAtlasAssetLoaded);

            this.emit(this.EVENT_DONE);
            return;
        }
        let objAssets = arrAssets.shift();

        let loader = this.dependencies.get("Loader");
        loader.once("end-load-atlas", (objAtlases) => this.loadAtlases(arrAssets));
        loader.loadCharacterAtlases(objAssets);
    }

    onAtlasAssetLoaded(strKey)
    {
        this.loadCount++;

        let ratio = Math.min(1, this.loadCount / this.totalToLoad) / 2 + 0.5;
        this.emit(this.EVENT_PROGRESSION, ratio);
    }
}
