import { Graphics } from '@pixi/graphics';
import { Sprite } from '@pixi/sprite';
import { string2hex }  from '@pixi/utils'; 
import ContainerResponsive from "../responsive/ContainerResponsive.js";
import Display from "../../../utils/Display.js";
import GraphicsResponsive from "../responsive/GraphicsResponsive.js";
import Library from "../../../Library.js";
import SpriteResponsive from "../responsive/SpriteResponsive.js";
import GameManager from "../../../GameManager.js";

export default class Gauge extends ContainerResponsive
{
    constructor()
    {
        super();
    }

    get DrawWidth() { return "(" + this.drawWidth + ")"; }
    set DrawWidth(strNewValue) { this.drawWidth = strNewValue; this.build(); }

    get DrawHeight() { return "(" + this.drawHeight + ")"; }
    set DrawHeight(strNewValue) { this.drawHeight = strNewValue; this.build(); }

    get BarX () { return "(" + this.DrawWidth + " / 2 - " + this.BarWidth + " / 2)"; }
    get BarY () { return " - " + this.BarHeight + " / 2"; }
    get BarWidth() { return "(" + this.DrawWidth + " - " + this.CircleRadius + " * 4.5)"; }
    get BarHeight() { return "(" + this.DrawHeight + " * 0.4)"; }

    get CircleRadius() { return "(" + this.DrawHeight + " / 2)"; }

    get BackgroundColor() { return this.values.bgColor;}
    get StrokeWidth() { return this.values.strokeWidth;}
    get StrokeColor() { return this.values.strokeColor;}
    get CornerRadius() { return this.values.cornerRadius;}

    get CursorWidth() { return this.StrokeWidth; }
    get CursorHeight() { return "(" + this.BarHeight + " * 1.66)"; }
    get CursorColor() { return this.values.cursorColor;}

    get DayIcon() { return Library.getTextureFromAtlas("ui", "icon_sun"); }
    get DayTexture() { return Library.getTextureFromAtlas("ui", "bg_sun"); }

    get NightIcon() { return Library.getTextureFromAtlas("ui", "icon_moon"); }
    get NightTexture() { return Library.getTextureFromAtlas("ui", "bg_moon"); }

    /*******************************************
    *   INITIALIZATION
    *******************************************/
    /**
        Parameters to pass to the init function:
        - ui:       UI section object where this component resides
        - rWidth    Responsive width equation to set for the ContainerResponsive functionnalities
        - rHeight   Responsive height equation to set for the ContainerResponsive functionnalities
    */
    init(meta)
    {
        this.drawWidth = meta.rWidth;
        this.drawHeight = meta.rHeight;

        this.graphics = {};
        this.sprites = {};
        this.values = {};

        let gm = GameManager.instance;
        gm.on(gm.EVENT_UPDATE, (e) => this.update(e));

        return super.init(meta);
    }

    /*******************************************
    *   UPDATE LOOP
    *******************************************/
    update(fDeltaTime)
    {
        this.positionCursor(this.UI.WorldManager.TimeOfDay);
    }

    /*******************************************
    *   BUILD
    *******************************************/
    build()
    {
        super.build();

        this.calculateResponsiveValues();
        this.drawBar();
        this.drawCircles();
        this.drawCursor();
    }

    clean()
    {
        super.clean();

        for (let key in this.graphics)
        {
            this.graphics[key].clear();
        }
    }

    /*******************************************
    *   RESPONSIVE
    *******************************************/
    calculateResponsiveValues()
    {
        this.values.bgColor = string2hex(this.UI.Values.timeofday.background.color);
        this.values.strokeColor = string2hex("color" in this.UI.Values.timeofday.stroke ? this.UI.Values.timeofday.stroke.color : "#000000");
        this.values.strokeWidth = this.evaluate(this.valueFormula(this.UI.Values.timeofday.stroke.size), 0, 0, 0, 0);
        this.values.cornerRadius = this.evaluate(this.valueFormula(this.UI.Values.timeofday.corner.radius), 0, 0, 0, 0);
        this.values.cursorColor = string2hex("color" in this.UI.Values.timeofday.cursor ? this.UI.Values.timeofday.cursor.color : "#FFFFFF");
        this.values.barX = this.evaluate(this.BarX, 0, 0, 0, 0);
        this.values.barWidth = this.evaluate(this.BarWidth, 0, 0, 0, 0);
    }

    /*******************************************
    *   GAUGE VISUALS
    *******************************************/
    drawBar()
    {
        let size = Display.getSize();

        if (!this.graphics.bar)
        {
            this.graphics.bar = new GraphicsResponsive().init({"ui": this.UI}).init({"ui": this.UI});
            this.graphics.barOutline = new GraphicsResponsive().init({"ui": this.UI}).init({"ui": this.UI});

            this.graphics.bar.maskBg = new GraphicsResponsive().init({"ui": this.UI}).init({"ui": this.UI});
            this.graphics.bar.barDay = new SpriteResponsive(this.DayTexture).init({"ui": this.UI});
            this.graphics.bar.barNight = new SpriteResponsive(this.NightTexture).init({"ui": this.UI});

            this.graphics.bar.addChild(this.graphics.bar.maskBg);
            this.graphics.bar.addChild(this.graphics.bar.barDay);
            this.graphics.bar.addChild(this.graphics.bar.barNight);
            this.graphics.bar.mask = this.graphics.bar.maskBg;

            this.addChild(this.graphics.bar);
            this.addChild(this.graphics.barOutline);
        }

        let width = this.evaluate(this.BarWidth, 0, 0, 0, 0, this);
        let height = this.evaluate(this.BarHeight, 0, 0, 0, 0, this);

        let x = this.BarX;
        let y = this.BarY;

        let bgColor = this.BackgroundColor;
        let strokeWidth = this.StrokeWidth;
        let strokeColor = this.StrokeColor;
        let cornerRadius = this.CornerRadius;

        let bar = this.graphics.bar;
        let outline = this.graphics.barOutline;
        let mask = this.graphics.bar.maskBg;
        let day = bar.barDay;
        let night = bar.barNight;

        if (strokeWidth > 0)
        {
            bar.lineStyle({"color":strokeColor, "width":strokeWidth, "alignment":1});
            outline.lineStyle({"color":strokeColor, "width":strokeWidth, "alignment":1});
            mask.lineStyle({"color":strokeColor, "width":strokeWidth, "alignment":1});
        }

        bar.beginFill(bgColor, 1);
        mask.beginFill(bgColor, 1);

        if (cornerRadius > 0)
        {
            bar.drawRoundedRect(0, 0, width, height, cornerRadius);
            outline.drawRoundedRect(0, 0, width, height, cornerRadius);
            mask.drawRoundedRect(0, 0, width, height, cornerRadius);
        }
        else
        {
            bar.drawRect(0, 0, width, height);
            outline.drawRect(0, 0, width, height);
            mask.drawRect(0, 0, width, height);
        }

        bar.endFill();
        mask.endFill();

        let nightRatio = this.UI.WorldManager.timeOfDayDef.night.duration / this.UI.WorldManager.dayLength;

        day.width = width * (1 - nightRatio);
        day.height = height;

        let ratio = night.height / night.width;
        night.width = width * nightRatio;
        night.height = night.width * ratio;

        night.position.set(day.width,  - night.height / 8);

        bar.rX = [{on:"default", x: x}];
        bar.rY = [{on:"default", y: y}];

        outline.rX = [{on:"default", x: x}];
        outline.rY = [{on:"default", y: y}];

        bar.layout(size);
        outline.layout(size);
        mask.layout(size);
        day.layout(size);
        night.layout(size);

    }

    drawCircles()
    {
        this.drawCircle(true);
        this.drawCircle(false);
    }

    drawCircle(bIsLeft)
    {
        let size = Display.getSize();

        let key = (bIsLeft ? "left" : "right") + "Circle";
        if (!this.graphics[key])
        {
            this.graphics[key] = new GraphicsResponsive().init({"ui": this.UI}).init({"ui": this.UI});
            this.graphics[key + "Outline"] = new GraphicsResponsive().init({"ui": this.UI}).init({"ui": this.UI});

            this.graphics[key].timeIcon = new SpriteResponsive((bIsLeft ? this.DayIcon : this.NightIcon)).init({"ui": this.UI});
            this.graphics[key].timeBg = new GraphicsResponsive().init({"ui": this.UI}).init({"ui": this.UI});
            this.graphics[key].maskBg = new GraphicsResponsive().init({"ui": this.UI}).init({"ui": this.UI});
            this.graphics[key].addChild(this.graphics[key].maskBg);
            this.graphics[key].addChild(this.graphics[key].timeBg);
            this.graphics[key].addChild(this.graphics[key].timeIcon);
            this.graphics[key].mask = this.graphics[key].maskBg;

            this.addChild(this.graphics[key]);
            this.addChild(this.graphics[key + "Outline"]);
        }

        let graphics = this.graphics[(bIsLeft ? "left" : "right") + "Circle"];
        let graphicsBg = this.graphics[key].timeBg;
        let graphicsOutline = this.graphics[key + "Outline"];

        let radius = this.evaluate(this.CircleRadius, 0, 0, 0, 0, this);

        let x = (bIsLeft ? this.CircleRadius + " / 2" : this.DrawWidth + " - " + this.CircleRadius + " / 2");
        let y = 0;

        let bgColor = this.BackgroundColor;
        let strokeWidth = this.StrokeWidth;
        let strokeColor = this.StrokeColor;
        let cornerRadius = this.CornerRadius;

        if (strokeWidth > 0)
        {
            graphics.lineStyle({"color":strokeColor, "width":strokeWidth, "alignment":1});
            graphicsOutline.lineStyle({"color":strokeColor, "width":strokeWidth, "alignment":1});
        }

        graphics.beginFill(this.BackgroundColor);
        this.graphics[key].maskBg.beginFill(this.BackgroundColor);

        graphics.drawCircle (0, 0, radius);
        this.graphics[key].maskBg.drawCircle (0, 0, radius);
        graphicsOutline.drawCircle (0, 0, radius);

        graphics.endFill();
        this.graphics[key].maskBg.endFill();

        graphics.rX = [{on:"default", x: x}];
        graphics.rY = [{on:"default", y: y}];
        graphics.layout(size);

        graphicsOutline.rX = [{on:"default", x: x}];
        graphicsOutline.rY = [{on:"default", y: y}];
        graphicsOutline.layout(size);

        graphicsBg.beginTextureFill({"texture": (bIsLeft ? this.DayTexture : this.NightTexture)});
        graphicsBg.drawRect (0, 0, radius * 2.5, radius * 2.5);
        graphicsBg.endFill();
        graphicsBg.position.set(-radius, -radius);
        graphicsBg.layout(size);

        let sprite = this.graphics[key].timeIcon;

        let iconWidth = "(" + this.CircleRadius + " * 2 * 0.9)";
        let iconHeight = iconWidth;

        let ratio = sprite.height / sprite.width;
        let spriteWidth = this.evaluate(iconWidth, 0, 0, 0, 0, this);
        let spriteHeight = spriteWidth * ratio;

        if (sprite.width < sprite.height)
        {
            ratio = sprite.width / sprite.height;
            spriteHeight = this.evaluate(iconHeight, 0, 0, 0, 0, this);
            spriteWidth = spriteHeight * ratio;
        }

        sprite.width = spriteWidth;
        sprite.height = spriteHeight;
        sprite.position.set(
            -spriteWidth / 2,
            -spriteHeight / 2
        );

        if (strokeWidth > 0)
        {
            if (!this.graphics[key + "Line"])
            {
                this.graphics[key + "Line"] = new GraphicsResponsive().init({"ui": this.UI}).init({"ui": this.UI});
                this.addChild(this.graphics[key + "Line"]);
            }

            let graphicsCircle = this.graphics[key + "Line"];

            let rWidth = "((" + this.DrawWidth + " - (" + this.BarWidth + " + " + this.CircleRadius + " * 4)) + " + strokeWidth + " / 2)";
            let width = this.evaluate(rWidth, 0, 0, 0, 0, this);
            let height = strokeWidth;

            let barX = x + (bIsLeft ? " + " + this.CircleRadius : " - " + this.CircleRadius + " - " + rWidth);
            let barY = y + " - " + height + " / 2";

            graphicsCircle.beginFill(strokeColor);
            graphicsCircle.drawRect(0, 0, width, height);
            graphicsCircle.endFill();

            graphicsCircle.rX = [{on:"default", x: barX}];
            graphicsCircle.rY = [{on:"default", y: barY}];

            graphicsCircle.layout(size);
        }
    }

    drawCursor()
    {
        if (!this.graphics.cursor)
        {
            this.graphics.cursor = new GraphicsResponsive().init({"ui": this.UI});
            this.addChild(this.graphics.cursor);
        }

        let graphics = this.graphics.cursor;

        let width = this.evaluate(this.CursorWidth, 0, 0, 0, 0, this);
        let height = this.evaluate(this.CursorHeight, 0, 0, 0, 0, this);

        let bgColor = this.CursorColor;
        let cornerRadius = width / 2;

        graphics.beginFill(bgColor, 1);
        graphics.drawRoundedRect(0, 0, width, height, cornerRadius);
        graphics.endFill();

        let y = this.BarY + " + " + this.BarHeight + " / 2 - " + this.CursorHeight + " / 2";
        graphics.rY = [{on:"default", y: y}];

        graphics.position.set(graphics.position.x, this.evaluate(y, 0, 0, 0, 0, this));

        this.positionCursor(this.UI.WorldManager.TimeOfDay);

        graphics.layout(Display.getSize());
    }

    positionCursor(fTimeOfDay)
    {
        if (this.graphics.cursor)
        {
            let cursor = this.graphics.cursor;

            let nightDuration = this.UI.WorldManager.timeOfDayDef.night.duration / this.UI.WorldManager.dayLength;

            if (fTimeOfDay - nightDuration / 2 < 0)
            {
                fTimeOfDay = 1 - (nightDuration / 2 - fTimeOfDay);
            }
            else
            {
                fTimeOfDay -= nightDuration / 2;
            }

            let x = this.values.barX + this.StrokeWidth + (this.values.barWidth - this.StrokeWidth * 2) * fTimeOfDay;

            if (cursor.transform)
                cursor.position.x = x;
        }
    }
}