import InventorySlot from "./InventorySlot.js";
import SpriteResponsive from "../responsive/SpriteResponsive.js";
import Library from "../../../Library.js";


export default class EquipmentSlot extends InventorySlot
{
    get EQUIPMENT_BODY() { return "BODY" };
    get EQUIPMENT_HANDS() { return "HANDS" };
    get EQUIPMENT_FEET() { return "FEET" };
    get EQUIPMENT_TOOL() { return "TOOL" };
    get EQUIPMENT_BACKPACK() { return "BACKPACK" };
    get EQUIPMENT_TOY() { return "TOY" };

    constructor()
    {
        super();
    }

    /*******************************************
    *   INITIALIZATION
    *******************************************/
    /**
        Parameters to pass to the init function:
        - ui:           UI section object where this slot resides
        - rWidth:       Reponsive width equation for this slot
        - rHeight       Reponsive height equation for this slot
        - type:         Type identifying this slot
        - index:        Numeric index representing where this slot is positionned in a grid of slots
        - strokeWidth:  (Optional) Size of the stroke for this slot. Default is 3
        - accept:       (Optional) Restriction to apply on what this slot can accept to hold. Use the TYPE_* constants. Default is TYPE_ALL

    */
    init(meta)
    {
        super.init(meta);

        this.addIcon();

        return this;
    }

    /*******************************************
    *   ITEM MANAGEMENT
    *******************************************/
    addItem(item)
    {
        if (this.spIcon !== undefined)
        {
            this.removeChild(this.spIcon);
        }

        super.addItem(item)

    }

    removeItem (duration = 0.1)
    {
        super.removeItem(duration);

        this.addIcon();
    }

    /*******************************************
    *   ICON
    *******************************************/
    addIcon ()
    {
        if (this.spIcon !== undefined)
        {
            this.addChild(this.spIcon);
            return;
        }

        let texture = this.getTextureFromType();
        this.spIcon = new SpriteResponsive(texture).init({"ui": this.UI});

        let orig = texture.orig;
        let ratio = orig.width / orig.height;

        if (ratio >= 1) //width is greater
        {
            this.spIcon.rWidth = [{on: "default", width: this.strWidth + " * 80%"}];
            this.spIcon.rScaleY = [{on: "default", scale: "self.scale.x"}];
        }
        else //height is greater
        {
            this.spIcon.rHeight = [{on: "default", height: this.strHeight + " * 80%"}];
            this.spIcon.rScaleX = [{on: "default", scale: "self.scale.y"}];
        }

        this.spIcon.anchor.x = this.spIcon.anchor.y = 0.5;
        this.spIcon.rX = [{on: "default", x: `(${this.strWidth} / 2)`}];
        this.spIcon.rY = [{on: "default", y: `(${this.strHeight} / 2)`}];
        this.spIcon.tint = 0xEEEEEE;

        this.addChild(this.spIcon);

    }

    getTextureFromType ()
    {
        let strIcon;
        if (this.type === this.EQUIPMENT_BODY)
        {
            strIcon = "coat";
        }
        else if (this.type === this.EQUIPMENT_FEET)
        {
            strIcon = "boots";
        }
        else if (this.type === this.EQUIPMENT_TOOL)
        {
            strIcon = "tool";
        }
        else if (this.type === this.EQUIPMENT_HANDS)
        {
            strIcon = "gloves";
        }
        else if (this.type === this.EQUIPMENT_TOY)
        {
            strIcon = "toy";
        }

        let texture = Library.getTextureFromAtlas("ui", strIcon);

        return texture;
    }

    /*******************************************
    *   UTILITIES
    *******************************************/
    clone ()
    {
        return new EquipmentSlot().init({
            "ui": this.UI,
            "rWidth": this.strWidth,
            "rHeight": this.strHeight,
            "accept": this.acceptedType,
            "type": this.type,
            "index": this.index
        });
    }


}