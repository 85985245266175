import {Raycaster} from "three";
import {Vector2} from "three";

export default class CameraRaycaster
{
    constructor (camera)
    {
        this.camera = camera;
        this.raycaster = new Raycaster();

    }

    //---------------------------------------------------------
    //  DEPENDENCIES
    //---------------------------------------------------------
    get ResponsiveManager() { return this.camera.Dependencies.get("ResponsiveManager"); }
    //---------------------------------------------------------

    destroy()
    {
        
    }

    intersectsFromScreen (iScreenX, iScreenY, objList)
    {
        let screenPos = new Vector2();
        screenPos.x = (iScreenX / this.ResponsiveManager.Width) * 2 - 1;
        screenPos.y = - (iScreenY / this.ResponsiveManager.Height) * 2 + 1;

        this.raycaster.setFromCamera(screenPos, this.camera);

        return this.raycaster.intersectObjects(objList, true);
    }
}