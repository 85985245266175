import {Vector2} from "three";
import Constants from "../../utils/Constants.js";
import Player from "../objects/Player.js";

export default class PlayerControl
{
    constructor(objPlayer)
    {
        this.player = objPlayer;
        this.canMove = true;
        this.toReach = null;
        this.holdToMove = Constants.getValue("HOLD_TO_MOVE");
        this.lastUp = 0;
    }

    get DOUBLE_CLICK_TIME() { return Constants.getValue("DOUBLE_CLICK_TIME") * 1000; }

    //---------------------------------------------------------
    //  DEPENDENCIES
    //---------------------------------------------------------
    get CharacterManager() { return this.player.Dependencies.get("CharacterManager"); }
    get GameManager() { return this.player.Dependencies.get("GameManager"); }
    get ResponsiveManager() { return this.player.Dependencies.get("ResponsiveManager"); }
    get UIManager() { return this.player.Dependencies.get("UIManager"); }
    //---------------------------------------------------------

    get IsPaused() { return this.GameManager.IsPaused; }
    get CanMove() { return this.canMove && this.player.CanMove && this.player.Focus && !this.GameManager.IsPaused; }
    set CanMove(newValue) { return this.canMove = newValue; }


    /*******************************************
    *   INITIALIZATION
    *******************************************/
    init()
    {
        this.lastInputId = null;
        this.lastInputPos = null;

        this.createClosure();
        this.bindEvents();
    }

    createClosure()
    {
        this.fctMouseDown = this.onMouseDown.bind(this);
        this.fctMouseMove =  this.onMouseMove.bind(this);
        this.fctMouseUp =  this.onMouseUp.bind(this);
        this.fctTouchStart =  this.onTouchStart.bind(this);
        this.fctTouchMove =  this.onTouchMove.bind(this);
        this.fctTouchEnd =  this.onTouchEnd.bind(this);

        this.fctOnMoveEnd = this.onMoveEnd.bind(this);
    }

    bindEvents()
    {
        //Mouse
        window.addEventListener("mousedown", this.fctMouseDown);
        window.addEventListener("mousemove", this.fctMouseMove);
        window.addEventListener("mouseup", this.fctMouseUp);

        //Mobile touches
        window.addEventListener("touchstart", this.fctTouchStart);
        window.addEventListener("touchmove", this.fctTouchMove);
        window.addEventListener("touchend", this.fctTouchEnd);

        this.player.on(this.player.EVENT_MOVEMENT_END, this.fctOnMoveEnd);
    }

    destroy()
    {
        window.removeEventListener("mousedown", this.fctMouseDown);
        window.removeEventListener("mousemove", this.fctMouseMove);
        window.removeEventListener("mouseup", this.fctMouseUp);
        window.removeEventListener("touchstart", this.fctTouchStart);
        window.removeEventListener("touchmove", this.fctTouchMove);
        window.removeEventListener("touchend", this.fctTouchEnd);

        this.player.off(this.player.EVENT_MOVEMENT_END, this.fctOnMoveEnd);
    }

    /*******************************************
    *   UPDATE LOOP
    *******************************************/
    update(deltaTime)
    {
        //@TODO: The update loop isn't required right now. Might not be in the future, let's remove it then if not
    }

    updateInput(bIsActive, fX = 0, fY = 0, iInputId = 1)
    {
        let screenPadding = this.ResponsiveManager.ScreenPadding;
        fX -= screenPadding;

        if (bIsActive)
        {
            this.lastInputPos = new Vector2(fX, fY);
            this.lastInputId = iInputId;

            if (this.holdToMove && this.CanMove && this.UIManager.canWorldClick(fX, fY))
            {
                this.holdingDown = true;

                if (!this.getIsClickable(fX, fY))
                {
                    let worldPos = this.player.Camera.screenToWorldPosition(fX, fY, true);
                    let gridPos = this.player.Grid.worldToGridPosition(worldPos.x, worldPos.z);

                    let destination = this.player.Destination;
                    if (!destination || destination.x != gridPos.x || destination.y != gridPos.y)
                    {
                        let pos = this.player.goTo(worldPos.x, worldPos.z);

                        if (pos)
                        {
                            this.player.Environment.MapMoveIcon.moveTo(pos.x, pos.y);
                            this.player.Environment.MapMoveIcon.setVisible(true);
                        }
                    }
                }
            }
        }
        else
        {
            let now = new Date().getTime();
            this.holdingDown = false;
            if ((!this.holdToMove || now - this.lastUp <= this.DOUBLE_CLICK_TIME) && this.lastInputPos && this.CanMove && this.UIManager.canWorldClick(this.lastInputPos.x, this.lastInputPos.y))
            {
                let worldPos = this.player.Camera.screenToWorldPosition(this.lastInputPos.x, this.lastInputPos.y, true);
                if (!this.getIsClickable(this.lastInputPos.x, this.lastInputPos.y))
                {
                    let pos = this.player.goTo(worldPos.x, worldPos.z);

                    if (pos)
                    {
                        this.player.Environment.MapMoveIcon.moveTo(pos.x, pos.y);
                        this.player.Environment.MapMoveIcon.setVisible(true);
                    }
                    //Double click time!
                    if (this.player.IsMoving && now - this.lastUp <= this.DOUBLE_CLICK_TIME)
                    {
                        this.CharacterManager.sprint();
                    }
                }
            }

            this.lastInputPos = null;
            this.lastInputId = null;
            this.lastUp = now;
        }
    }

    /*******************************************
    *   CELL CONTENT
    *******************************************/
    getIsClickable(iX, iY)
    {
        let zone = this.player.Scene.getClickableZone(iX, iY);
        if (!zone)
        {
            let mesh = null;
            let isIndoor = this.player.WorldManager.Environment.IsIndoor;
            let worldPos = this.player.Camera.screenToWorldPosition(this.lastInputPos.x, this.lastInputPos.y, false);

            if (isIndoor)
            {
                this.player.Scene.getClickableMesh(iX, iY, true);
            }
            else
            {
                mesh = this.player.Scene.getClickableMesh(worldPos.x, worldPos.z);
            }
            if (!mesh)
            {
                let matrix = null;
                if (isIndoor)
                {
                    matrix = this.player.Scene.getClickableMatrix(iX, iY, true);
                }
                else
                {
                    matrix = this.player.Scene.getClickableMatrix(worldPos.x, worldPos.z);
                }
                
                if (!matrix)
                {
                    return false;
                }
            }
        }

        return true;
    }

    /*******************************************
    *   EVENTS
    *******************************************/
    onMouseDown(e)
    {
        if (this.CanMove && this.UIManager.canWorldClick(e.clientX, e.clientY))
        {
            this.updateInput(true, e.clientX, e.clientY);
        }
    }

    onMouseMove(e)
    {
        if (this.CanMove && this.UIManager.canWorldClick(e.clientX, e.clientY) && this.lastInputId !== null)
        {
            this.updateInput(true, e.clientX, e.clientY);
        }
    }

    onMouseUp(e)
    {
        this.updateInput(false);
    }

    onTouchStart(e)
    {
        if (this.lastInputId === null)
        {
            let touch = e.touches[0];
            if (this.CanMove && this.UIManager.canWorldClick(touch.clientX, touch.clientY))
            {
                this.updateInput(true, touch.clientX, touch.clientY, touch.identifier);
            }
        }
    }

    onTouchMove(e)
    {
        let touch = null;
        for(let i = 0; i < e.touches.length; i++)
        {
            if (e.touches[i].identifier == this.lastInputId)
            {
                touch = e.touches[i];
                break;
            }
        }

        if (touch && this.CanMove && this.UIManager.canWorldClick(touch.clientX, touch.clientY))
        {
            this.updateInput(true, touch.clientX, touch.clientY, touch.identifier);
        }
    }

    onTouchEnd(e)
    {
        let isIn = false;
        for(let i = 0; i < e.touches.length; i++)
        {
            if (e.touches[i].identifier == this.lastInputId)
            {
                isIn = true;
                break;
            }
        }

        if (!isIn)
        {
            this.updateInput(false);
        }
    }

    onMoveEnd()
    {
        this.player.Environment.MapMoveIcon.setVisible(false);
    }

    onCameraTileChanged(newPos)
    {
        if (this.holdingDown && this.holdToMove && this.lastInputId !== null)
        {
            if (this.CanMove && this.UIManager.canWorldClick(this.lastInputPos.x, this.lastInputPos.y))
            {
                this.updateInput(true, this.lastInputPos.x, this.lastInputPos.y, this.lastInputId);
            }
        }
    }
}