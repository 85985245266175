import { Sprite } from "@pixi/sprite";
import { string2hex }  from "@pixi/utils";
import { TextStyle } from "@pixi/text";
import { TextMetrics } from "@pixi/text";
import ContainerResponsive from "../../commons/responsive/ContainerResponsive.js";
import Display from "../../../utils/Display.js";
import DoubleTapDetector from "../../commons/DoubleTapDetector.js";
import GraphicsResponsive from "../../commons/responsive/GraphicsResponsive.js";
import Library from "../../../Library.js";
import SpriteResponsive from "../../commons/responsive/SpriteResponsive.js";
import TextButton from "../../commons/buttons/TextButton.js";
import TextResponsive from "../../commons/responsive/TextResponsive.js";

export default class UpgradeZoneContent extends ContainerResponsive
{
    constructor()
    {
        super();
    }

    get EVENT_UPGRADE() { return "upgrade"; }

    get IsShown() { return this.parent ? true : false; }

    get Padding() { return {
        "top": (this.BgHeight * 0.1), 
        "right": (this.BgHeight * 0.1), 
        "bottom": (this.BgHeight * 0.05), 
        "left": (this.BgHeight * 0.1)
    }; }

    get NoMoreUpgradeLabel() { return this.UI.LabelManager.translate("Grang_Amelior_Max"); }

    get BgWidth() { return this.evaluate(this.buildWidth, 0, 0, 0, 0, this); }
    get BgHeight() { return this.evaluate(this.buildHeight, 0, 0, 0, 0, this); }

    get ZoneImageWidth() { return this.ZoneImageHeight; }
    get ZoneImageHeight() { return this.BgHeight * 0.4; }
    get ZoneImageBackgroundColor() { return string2hex(this.UI.Values.slot.background.color); }
    get ZoneImageStrokeWidth() { return this.evaluate(this.valueFormula(this.UI.Values.slot.stroke.size), 0, 0, 0, 0, this); }
    get ZoneImageStrokeColor() { return string2hex("color" in this.UI.Values.slot.stroke ? this.UI.Values.slot.stroke.color : "#000000"); }
    get ZoneImageCornerRadius() { return this.evaluate(this.valueFormula(this.UI.Values.slot.corner.radius), 0, 0, 0, 0, this); }
    get ArrowHeight() { return this.BgHeight * 0.075; }
    get SlotStrokeWidth() { return this.evaluate(this.valueFormula(this.UI.Values.slot.stroke.size / 1.5), 0, 0, 0, 0, this); }
    get SlotCornerRadius() { return this.evaluate(this.valueFormula(this.UI.Values.slot.corner.radius / 1.5), 0, 0, 0, 0, this); }
    get MissingQuantityColor() { return string2hex(this.UI.Values.general.error.color); }

    /*******************************************
    *   INITIALIZATION
    *******************************************/
    /**
        Parameters to pass to the init function:
        - ui:       UI section object where this component resides
        - zone:     Id of the barn zone this content will be tied to
        - rWidth:   Responsive equation for the width of this content
        - rHeight:  Responsive equation for the height of this content
    */
    init(meta)
    {
        this.zoneId = meta.zone;
        this.buildWidth = meta.rWidth;
        this.buildHeight = meta.rHeight;

        this.graphics = {};
        this.sprites = {};
        this.texts = {};

        this.requirements = null;

        return super.init(meta);
    }

    createClosure ()
    {
        super.createClosure();

        this.fctOnUpgradeButtonClick = this.onUpgradeButtonClick.bind(this);
        this.fctOnItemAdded = this.onItemAdded.bind(this);
    }

    bindEvents()
    {
        super.bindEvents();
        this.UI.ItemManager.on(this.UI.ItemManager.EVENT_ITEM_FOUND, this.fctOnItemAdded);//@
    }

    destroy(options)
    {
        this.UI.ItemManager.off(this.UI.ItemManager.EVENT_ITEM_FOUND, this.fctOnItemAdded);//@

        if (this.upgradeButton)
        {
            this.upgradeButton.off(this.upgradeButton.EVENT_CLICK, this.fctOnUpgradeButtonClick);
            this.removeChild(this.upgradeButton);
            this.upgradeButton.destroy(options);
        }

        for (let key in this.graphics)
        {
            if (this.graphics[key].detector)
            {
                this.graphics[key].detector.destroy(options);
            }
        }

        super.destroy(options);
    }

    /*******************************************
    *   BUILD
    *******************************************/
    build()
    {
        super.build();

        let objZones = this.buildZoneContainers();
        this.buildArrowSprite(objZones.positions[0].y + objZones.size.height / 2);
        this.buildZoneSprites(objZones);
        let desc = this.buildZoneLabels(objZones);
        let isOk = this.buildRequirements(objZones, desc.y, desc.height);
        this.buildUpgradeButton(isOk);
    }

    clean()
    {
        for(let key in this.graphics)
        {
            this.graphics[key].clear();
        }

        super.clean();
    }

    buildZoneContainers()
    {
        let level = this.UI.WorldManager.getBarnZoneLevel(this.zoneId);
        let padding = this.Padding;
        let titleLabel = this.getTitleLabel();

        if (!this.texts.titleLabel)
        {
            this.texts.titleLabel = new TextResponsive().init({"ui": this.UI}).init({"ui": this.UI});
            this.addChild(this.texts.titleLabel);
        }

        let style = new TextStyle({
            fontFamily: this.UI.Values.general.title.font,
            fontSize:   this.evaluate(this.valueFormula(this.UI.Values.general.text.size * 1.66)),
            fontWeight: 700,
            fill: this.UI.Values.general.text.color,
            align: "center"
        });

        let text = this.texts.titleLabel;

        let metrics = TextMetrics.measureText(titleLabel, style);

        let textX = this.BgWidth / 2 - metrics.width / 2;
        let textY = padding.top;

        text.text = titleLabel;
        text.style = style;
        text.width = metrics.width;
        text.height = metrics.height;
        text.position.set(textX, textY);

        if (!this.graphics.box)
        {
            this.graphics.box = new GraphicsResponsive().init({"ui": this.UI});
            this.addChild(this.graphics.box);
        }

        let width = this.ZoneImageWidth;
        let height = this.ZoneImageHeight;
        let x = padding.left;
        let x2 = this.BgWidth - padding.right - width;
        let y = textY + metrics.height * 3.5;
        let zoneBgColor = this.ZoneImageBackgroundColor;
        let zoneStrokeWidth = this.ZoneImageStrokeWidth;
        let zoneStrokeColor = this.ZoneImageStrokeColor;
        let zoneCornerRadius = this.ZoneImageCornerRadius;

        if (zoneStrokeWidth > 0)
        {
            this.graphics.box.lineStyle({"color":zoneStrokeColor, "width": zoneStrokeWidth, "alignment": 1});
        }
        this.graphics.box.beginFill(zoneBgColor, 1);
        if (zoneCornerRadius > 0)
        {
            this.graphics.box.drawRoundedRect(x, y, width, height, zoneCornerRadius);
            if (level < 3)
            {
                this.graphics.box.drawRoundedRect(x2, y, width, height, zoneCornerRadius);
            }
        }
        else
        {
            this.graphics.box.drawRect(x, y, width, height);
            if (level < 3)
            {
                this.graphics.box.drawRect(x2, y, width, height);
            }
        }

        if (level >= 3)
        {
            this.buildNoMoreUpgradeLabel(x2, y, width, height);
        }

        this.graphics.box.endFill(zoneBgColor, 1);

        return {
            "positions": [
                {"x": x, "y": y},
                {"x": x2, "y": y}
            ],
            "size": {"width": width, "height": height}
        };
    }

    buildNoMoreUpgradeLabel(fZoneX, fZoneY, fZoneWidth, fZoneHeight)
    {
        if (!this.texts.noMoreUpgrade)
        {
            this.texts.noMoreUpgrade = new TextResponsive().init({"ui": this.UI});
            this.addChild(this.texts.noMoreUpgrade);
        }

        let text = this.texts.noMoreUpgrade;
        let label = this.NoMoreUpgradeLabel;

        let style = new TextStyle({
            fontFamily: this.UI.Values.general.text.font,
            fontSize:   this.evaluate(this.valueFormula(this.UI.Values.general.text.size)),
            fontWeight: 400,
            fill: this.UI.Values.general.text.color,
            align: "center",
            wordWrap: true,
            wordWrapWidth: fZoneWidth * 0.75
        });

        let metrics = TextMetrics.measureText(label, style);

        let textX = fZoneX + fZoneWidth / 2 - metrics.width / 2;
        let textY = fZoneY + fZoneHeight / 2 - metrics.height / 2;

        text.text = label;
        text.style = style;
        text.width = metrics.width;
        text.height = metrics.height;
        text.position.set(textX, textY);
    }

    buildArrowSprite(iY)
    {
        if (!this.sprites.arrow)
        {
            this.sprites.arrow = new SpriteResponsive(
                Library.getTextureFromAtlas("ui", "next")
            )
            .init({"ui": this.UI});

            this.addChild(this.sprites.arrow);
        }

        let arrowHeight = this.ArrowHeight;
        let arrowWidth = arrowHeight * (this.sprites.arrow.width / this.sprites.arrow.height);

        this.sprites.arrow.width = arrowWidth;
        this.sprites.arrow.height = arrowHeight;
        this.sprites.arrow.position.set(this.BgWidth / 2 - arrowWidth / 2, iY - arrowHeight / 2);
    }

    buildZoneSprites(objZones)
    {
        let level = this.UI.WorldManager.getBarnZoneLevel(this.zoneId);
        if (!this.sprites.currentLevel)
        {
            let texture = Library.getTextureFromAtlas(
                "barn", 
                this.UI.getZoneTextureId(this.zoneId, level)
            );
            if (texture)
            {
                this.sprites.currentLevel = new Sprite(texture);
                this.addChild(this.sprites.currentLevel);
            }
        }

        if (!this.sprites.nextLevel)
        {
            let texture = Library.getTextureFromAtlas(
                "barn", 
                this.UI.getZoneTextureId(this.zoneId, level + 1)
            );
            if (texture)
            {
                this.sprites.nextLevel = new Sprite(texture);
                this.addChild(this.sprites.nextLevel);
            }
        }

        if (this.sprites.currentLevel)
        {
            let padding = 0.05;
            let sprites = [this.sprites.currentLevel];
            if (this.sprites.nextLevel)
            {
                sprites.push(this.sprites.nextLevel);
            }

            for (let i = 0; i < sprites.length; i++)
            {
                let sprite = sprites[i];
                let width = sprite.width;
                let height = sprite.height;

                if (sprite.width > sprite.height)
                {
                    width = objZones.size.width * (1-padding);
                    height = width * (sprite.height / sprite.width);
                }
                else
                {
                    height = objZones.size.height * (1-padding);
                    width = height * (sprite.width / sprite.height);
                }

                sprite.width = width;
                sprite.height = height;

                sprite.position.set(
                    objZones.positions[i].x + objZones.size.width / 2 - width / 2,
                    objZones.positions[i].y + objZones.size.height / 2 - height / 2
                );
            }
        }
    }

    buildZoneLabels(objZones)
    {
        let labelCurrentDesc = this.getDescLabel(this.UI.WorldManager.getBarnZoneLevel(this.zoneId), true);
        let labelCurrentLevel = this.getLevelLabel(this.UI.WorldManager.getBarnZoneLevel(this.zoneId), true);
        let labelNextDesc = this.getDescLabel(this.UI.WorldManager.getBarnZoneLevel(this.zoneId) + 1, true);
        let labelNextLevel = this.getLevelLabel(this.UI.WorldManager.getBarnZoneLevel(this.zoneId) + 1, true);

        if (!this.texts.currentLevelDesc)
        {
            this.texts.currentLevelDesc = new TextResponsive().init({"ui": this.UI});
            this.texts.currentLevelLevel = new TextResponsive().init({"ui": this.UI});

            this.addChild(this.texts.currentLevelDesc);
            this.addChild(this.texts.currentLevelLevel);
        }
        

        if (!this.texts.nextLevelDesc)
        {
            this.texts.nextLevelDesc = new TextResponsive().init({"ui": this.UI});
            this.texts.nextLevelLevel = new TextResponsive().init({"ui": this.UI});

            this.addChild(this.texts.nextLevelDesc);
            this.addChild(this.texts.nextLevelLevel);
        }

        let normalStyle = new TextStyle({
            fontFamily: this.UI.Values.general.text.font,
            fontSize:   this.evaluate(this.valueFormula(this.UI.Values.general.text.size * 0.8)),
            fontWeight: 400,
            fill: this.UI.Values.general.text.color,
            align: "center"
        });
        let boldStyle = new TextStyle({
            fontFamily: this.UI.Values.general.text.font,
            fontSize:   this.evaluate(this.valueFormula(this.UI.Values.general.text.size*1.15)),
            fontWeight: 700,
            fill: this.UI.Values.general.text.color,
            align: "center"
        });

        let texts = [
            {
                "desc":
                    {"text":this.texts.currentLevelDesc, "label":labelCurrentDesc, "style":normalStyle}, 
                "level":
                    {"text": this.texts.currentLevelLevel, "label":labelCurrentLevel, "style":boldStyle}
            },
            {
                "desc":
                    {"text":this.texts.nextLevelDesc, "label":labelNextDesc, "style":normalStyle}, 
                "level":
                    {"text": this.texts.nextLevelLevel, "label":labelNextLevel, "style":boldStyle}
            }
        ];

        let descY = 0;
        let descHeight = 0;
        for (let i = 0; i < texts.length; i++)
        {
            let text = texts[i].desc.text;
            let label = texts[i].desc.label;
            let style = texts[i].desc.style;

            let metrics = TextMetrics.measureText(label, style);

            let x = objZones.positions[i].x + objZones.size.width / 2 - metrics.width / 2;
            let y = objZones.positions[i].y + objZones.size.height + objZones.size.height / 12;

            text.text = label;
            text.style = style;
            text.width = metrics.width;
            text.height = metrics.height;
            text.position.set(x, y);

            descY = y;
            descHeight = metrics.height;

            text = texts[i].level.text;
            label = texts[i].level.label;
            style = texts[i].level.style;

            metrics = TextMetrics.measureText(label, style);

            x = objZones.positions[i].x + objZones.size.width / 2 - metrics.width / 2;
            y = objZones.positions[i].y - metrics.height * 1.75;

            text.text = label;
            text.style = style;
            text.width = metrics.width;
            text.height = metrics.height;
            text.position.set(x, y);
        }

        return {"y": descY, "height": descHeight};
    }

    buildRequirements(objZones, descY, descHeight)
    {
        let level = this.UI.WorldManager.getBarnZoneLevel(this.zoneId) + 1;
        let isOk = false;
        if (level > 0 && level <= 3)
        {
            isOk = true;
            this.requirements = this.UI.WorldManager.getBarnZoneUpgradeRequirements(
                this.zoneId,
                level
            );

            for (let i = 0; i < this.requirements.length; i++)
            {
                this.requirements[i].owned = this.UI.ItemManager.getItemQuantity(this.requirements[i].item, true, -1, true);
            }

            let zoneBgColor = this.ZoneImageBackgroundColor;
            let zoneStrokeWidth = this.SlotStrokeWidth;
            let zoneStrokeColor = this.ZoneImageStrokeColor;
            let zoneCornerRadius = this.SlotCornerRadius;

            let width = objZones.size.width / 6.0;
            let height = width;
            let padding = width / 2;
            let sidePadding = (objZones.size.width - (width * this.requirements.length) - (padding * (this.requirements.length - 1))) / 2;
            let y = descY + descHeight + objZones.size.height / 12;

            let textStyle = {
                fontFamily: this.UI.Values.general.text.font,
                fontSize:   this.evaluate(this.valueFormula(this.UI.Values.general.text.size * 0.75)),
                fontWeight: 400,
                fill: this.UI.Values.general.text.color,
                align: "center"
            };

            for (let i = 0; i < this.requirements.length; i++)
            {
                //Slot background
                if (!this.graphics["requirements_" + i])
                {
                    this.graphics["requirements_" + i] = new GraphicsResponsive().init({"ui": this.UI});
                    this.graphics["requirements_" + i].interactive = true;
                    this.graphics["requirements_" + i].buttonMode = true;
                    this.addChild(this.graphics["requirements_" + i]);

                    let detector = new DoubleTapDetector().init({
                        "ui": this.UI,
                        "target": this.graphics["requirements_" + i],
                        "callback": function(objItem)
                        {
                            this.UI.showItemDescription(objItem);
                        }
                        .bind(this, this.requirements[i].item)
                    });

                    this.graphics["requirements_" + i].detector = detector;
                    detector.startDetection();
                }

                let graphics = this.graphics["requirements_" + i];
                let x = objZones.positions[1].x + sidePadding + width * i + padding * i;

                if (zoneStrokeWidth > 0)
                {
                    graphics.lineStyle({"color":zoneStrokeColor, "width": zoneStrokeWidth, "alignment": 1});
                }

                graphics.beginFill(zoneBgColor, 1);
                if (zoneCornerRadius > 0)
                {
                    graphics.drawRoundedRect(x, y, width, height, zoneCornerRadius);
                }
                else
                {
                    graphics.drawRect(x, y, width, height);
                }

                //Item sprite
                if (!("requirement" + i in this.sprites))
                {
                    this.sprites["requirement" + i] = new Sprite(
                        Library.getTextureFromAtlas(
                            this.requirements[i].item.AtlasId,
                            this.requirements[i].item.TextureId
                        )
                    );

                    this.addChild(this.sprites["requirement" + i]);
                }

                let sprite = this.sprites["requirement" + i];

                let spriteRatio = sprite.height / sprite.width;
                let spriteWidth = width - zoneCornerRadius;
                let spriteHeight = spriteWidth * spriteRatio;
                if (sprite.width < sprite.height)
                {
                    spriteRatio = sprite.width / sprite.height;
                    spriteHeight = height - zoneCornerRadius;
                    spriteWidth = spriteHeight * spriteRatio;
                }

                sprite.width = spriteWidth;
                sprite.height = spriteHeight;
                sprite.position.set(x + width / 2 - spriteWidth / 2, y + height / 2 - spriteHeight / 2);

                

                //Item owned/quantity
                if (!("requirement" + i in this.texts))
                {
                    this.texts["requirement" + i] = new TextResponsive().init({"ui": this.UI});
                    this.addChild(this.texts["requirement" + i]);
                }

                let text = this.texts["requirement" + i];
                let label = this.getRequirementLabel(Math.min(999, this.requirements[i].owned), this.requirements[i].quantity);

                textStyle.fill = this.UI.Values.general.text.color;
                if (this.requirements[i].owned < this.requirements[i].quantity)
                {
                    textStyle.fill = this.MissingQuantityColor;
                }
                let style = new TextStyle(textStyle);

                let metrics = TextMetrics.measureText(label, style);

                let textX = x + width / 2 - metrics.width / 2;
                let textY = y + height + metrics.height * 0.7;

                text.text = label;
                text.style = style;
                text.width = metrics.width;
                text.height = metrics.height;
                text.position.set(textX, textY);

                if (this.requirements[i].owned < this.requirements[i].quantity)
                {
                    isOk = false;
                }
            }
        }

        return isOk;
    }

    buildUpgradeButton(bIsOk)
    {
        if (!this.upgradeButton)
        {
            this.upgradeButton = new TextButton().init({
                "ui": this.UI,
                "text": this.getUpgradeLabel(),
                "disabled": !bIsOk
            });
            this.upgradeButton.on(this.upgradeButton.EVENT_CLICK, this.fctOnUpgradeButtonClick);//@
            this.addChild(this.upgradeButton);
        }

        this.upgradeButton.rX = [{on:"default", x: this.BgWidth / 2 - this.upgradeButton.ButtonWidth / 2}];
        this.upgradeButton.rY = [{on:"default", y: "(" + this.BgHeight + ") * 0.9"}];

        this.upgradeButton.IsDisabled = !bIsOk;

        this.upgradeButton.layout(Display.getSize());
    }

    /*******************************************
    *   LABELS
    *******************************************/
    getTitleLabel()
    {
        let label = "";

        if (this.zoneId == this.UI.WorldManager.BARN_ZONE_SLEEP)
        {
            label = this.UI.LabelManager.translate("Somm_Amelior_Titre");
        }
        else if (this.zoneId == this.UI.WorldManager.BARN_ZONE_RELAX)
        {
            label = this.UI.LabelManager.translate("Dete_Amelior_Titre");
        }
        else if (this.zoneId == this.UI.WorldManager.BARN_ZONE_COOKING)
        {
            label = this.UI.LabelManager.translate("Cuis_Amelior_Titre");
        }
        else if (this.zoneId == this.UI.WorldManager.BARN_ZONE_CRAFTING)
        {
            label = this.UI.LabelManager.translate("Fabr_Amelior_Titre");
        }

        return label;
    }

    getDescLabel(level)
    {
        let label = "";

        if (level >= 1 && level <= 3)
        {
            if (this.zoneId == this.UI.WorldManager.BARN_ZONE_SLEEP)
            {
                label = this.UI.LabelManager.translate("Somm_Amelior_Bonus" + level);
            }
            else if (this.zoneId == this.UI.WorldManager.BARN_ZONE_RELAX)
            {
                label = this.UI.LabelManager.translate("Dete_Amelior_Bonus" + level);
            }
            else if (this.zoneId == this.UI.WorldManager.BARN_ZONE_COOKING)
            {
                label = this.UI.LabelManager.translate("Cuis_Amelior_Bonus" + level);
            }
            else if (this.zoneId == this.UI.WorldManager.BARN_ZONE_CRAFTING)
            {
                label = this.UI.LabelManager.translate("Fabr_Amelior_Bonus" + level);
            }
        }

        return label;
    }

    getLevelLabel(level)
    {
        let label = "";

        if (level >= 1 && level <= 3)
        {
            if (this.zoneId == this.UI.WorldManager.BARN_ZONE_SLEEP)
            {
                label = this.UI.LabelManager.translate("Somm_Amelior_Niv" + level);
            }
            else if (this.zoneId == this.UI.WorldManager.BARN_ZONE_RELAX)
            {
                label = this.UI.LabelManager.translate("Dete_Amelior_Niv" + level);
            }
            else if (this.zoneId == this.UI.WorldManager.BARN_ZONE_COOKING)
            {
                label = this.UI.LabelManager.translate("Cuis_Amelior_Niv" + level);
            }
            else if (this.zoneId == this.UI.WorldManager.BARN_ZONE_CRAFTING)
            {
                label = this.UI.LabelManager.translate("Fabr_Amelior_Niv" + level);
            }
        }

        return label;
    }

    getUpgradeLabel()
    {
        let label = "";

        if (this.zoneId == this.UI.WorldManager.BARN_ZONE_SLEEP)
        {
            label = this.UI.LabelManager.translate("Somm_Amelior_Amelior");
        }
        else if (this.zoneId == this.UI.WorldManager.BARN_ZONE_RELAX)
        {
            label = this.UI.LabelManager.translate("Dete_Amelior_Amelior");
        }
        else if (this.zoneId == this.UI.WorldManager.BARN_ZONE_COOKING)
        {
            label = this.UI.LabelManager.translate("Cuis_Amelior_Amelior");
        }
        else if (this.zoneId == this.UI.WorldManager.BARN_ZONE_CRAFTING)
        {
            label = this.UI.LabelManager.translate("Fabr_Amelior_Amelior");
        }

        return label;
    }

    getRequirementLabel(iOwned, iRequired)
    {
        return iOwned + "/" + iRequired;
    }

    /*******************************************
    *   EVENTS
    *******************************************/
    onUpgradeButtonClick(target)
    {
        this.emit(this.EVENT_UPGRADE, this);
    }

    onItemAdded(strItemType, iAmount)
    {
        this.build();
    }
}
