import EventEmitter from "eventemitter3";
import Lerp from "../../utils/Lerp.js";

export default class ContainerDragger extends EventEmitter
{
    constructor()
    {
        super();
    }

    //Triggered as soon as a new drag starts
    get EVENT_DRAG_START() { return "drag-start"; }
    //Triggered when the drag enters the dropzone range on the screen
    get EVENT_ENTER_DROP_ZONE() { return "enter-dropzone"; }
    //Triggered when the drag exits the dropzone range on the screen
    get EVENT_EXIT_DROP_ZONE() { return "exit-dropzone"; }
    //Triggered when the current drag ends
    get EVENT_DRAG_END() { return "drag-end"; }
    //Triggered when the current drag has ended and the dragged container has been put back in its place. If the container was dropped on the drop zone
    //then the animation will place it automatically over the drop zone
    get EVENT_DRAG_END_ANIMATION_FINISHED() { return "drag-end-animation-finished"; }
    //Triggered when the current drag ends over the drop zone
    get EVENT_DROP() { return "drop"; }

    get Id() { return this.id; }
    set Id(strNewValue) { this.id = strNewValue; }

    get UI() { return this.ui; }

    get CanDrag() { return this.canDrag; }
    set CanDrag(bNewValue) { this.canDrag = bNewValue; }

    get DraggableContainers() { return this.containers; }
    set DraggableContainers(arrNewContainers)
    {
        this.containers = arrNewContainers;
        if (!Array.isArray(arrNewContainers))
        {
            this.containers = [arrNewContainers];
        }
    }

    get DropZoneContainers() { return this.dropZones; }
    set DropZoneContainers(arrNewZones)
    {
        this.dropZones = arrNewZones;
        if (!Array.isArray(arrNewZones))
        {
            this.dropZones = [arrNewZones];
        }
    }

    get IsDragging() { return this.dragInput !== null; }
    get IsAnimating() { return this.timeLeft > 0; }

    /*******************************************
    *   INITIALIZATION
    *******************************************/
    /**
        Parameters to pass to the init function:
        - ui:           UI section object where this component resides
        - draggables:   List of containers that are drag-enabled. If only one, can be passed directly
        - dropZones:    List of containers that could be used as drop zones If only one, can be passed directly
        - id:           (Optional) String identifying this object. Default is NULL
    */
    init(meta)
    {
        this.ui = meta.ui;
        this.id = ("id" in meta ? meta.id : null);
        this.containers = meta.draggables;
        this.dropZones = meta.dropZones;

        if (!Array.isArray(meta.draggables))
        {
            this.containers = [meta.draggables];
        }

        if (!Array.isArray(meta.dropZones))
        {
            this.dropZones = [meta.dropZones];
        }

        this.canDrag = true;
        this.dragInput = null;
        this.timeLeft = 0;

        this.createClosure();
        this.bindEvents();

        return this;
    }


    createClosure()
    {
        this.fctOnMouseDown = this.onMouseDown.bind(this);
        this.fctOnMouseMove = this.onMouseMove.bind(this);
        this.fctOnMouseUp = this.onMouseUp.bind(this);

        this.fctOnTouchStart = this.onTouchStart.bind(this);
        this.fctOnTouchMove = this.onTouchMove.bind(this);
        this.fctOnTouchEnd = this.onTouchEnd.bind(this);
    }

    bindEvents()
    {
        window.addEventListener("mousedown", this.fctOnMouseDown);
        window.addEventListener("mousemove", this.fctOnMouseMove);
        window.addEventListener("mouseup", this.fctOnMouseUp);

        window.addEventListener("touchstart", this.fctOnTouchStart);
        window.addEventListener("touchmove", this.fctOnTouchMove);
        window.addEventListener("touchend", this.fctOnTouchEnd);
    }

    destroy(options)
    {
        window.removeEventListener("mousedown", this.fctOnMouseDown);
        window.removeEventListener("mousemove", this.fctOnMouseMove);
        window.removeEventListener("mouseup", this.fctOnMouseUp);

        window.removeEventListener("touchstart", this.fctOnTouchStart);
        window.removeEventListener("touchmove", this.fctOnTouchMove);
        window.removeEventListener("touchend", this.fctOnTouchEnd);
    }

    /*******************************************
    *   CALCULATIONS
    *******************************************/
    isOverlapping(rect1, rect2)
    {
        return !(rect1.xMax < rect2.x || rect1.x > rect2.xMax || 
                 rect1.yMax < rect2.y || rect1.y > rect2.yMax);
    }

    calculateRect(container)
    {
        let rect = container.toGlobal({"x": 0, "y": 0});
        rect.x += this.UI.ResponsiveManager.ScreenPadding;

        rect.width = container.onlyIconWidth !== undefined ? container.onlyIconWidth : container.width;
        rect.height = container.onlyIconHeight !== undefined ? container.onlyIconHeight : container.height;

        rect.xMax = rect.x + rect.width;
        rect.yMax = rect.y + rect.height;

        return rect;
    }

    calculateHover(container, rect = null)
    {
        if (!rect)
        {
            this.calculateRect(container);
        }

        let overlapping = [];
        for(let i = 0; i < this.dropZones.length; i++)
        {
            if (this.dropZones[i] != container)
            {
                let dropZoneRect = this.calculateRect(this.dropZones[i]);
                let overlaps = this.isOverlapping(rect, dropZoneRect);

                if (overlaps)
                {
                    overlapping.push({"zone": this.dropZones[i], "rect": dropZoneRect});
                }
            }
        }

        let nearest = null;
        let dist = Number.MAX_SAFE_INTEGER;

        for (let i = 0; i < overlapping.length; i++)
        {
            let sqrDist = Math.pow((overlapping[i].rect.x + overlapping[i].rect.width / 2) - (rect.x + rect.width / 2), 2);
            sqrDist += Math.pow((overlapping[i].rect.y + overlapping[i].rect.height / 2) - (rect.y + rect.height / 2), 2);

            if (sqrDist < dist)
            {
                nearest = overlapping[i].zone;
                dist = sqrDist;
            }
        }

        return nearest;
    }

    calculateAnimationTime(fFromX, fFromY, fToX, fToY)
    {
        let sqrDist = Math.pow(fToX - fFromX, 2) + Math.pow(fToY - fFromY, 2);
        let maxDist = Math.pow(this.UI.evaluate(this.UI.valueFormula(this.UI.Values.drag.endanimation.maxdist), 0, 0, 0, 0, this), 2);
        sqrDist = Math.min(sqrDist, maxDist);

        let ratio = sqrDist / maxDist;

        return Math.max(100, this.UI.Values.drag.endanimation.time * ratio);
    }

    /*******************************************
    *   DRAG/DROP LOGIC
    *******************************************/
    startDrag(dragInput, iX, iY)
    {
        for (let i = 0; i < this.containers.length; i++)
        {
            let rect = this.calculateRect(this.containers[i]);
            if (iX >= rect.x && iX <= rect.xMax && iY >= rect.y && iY <= rect.yMax)
            {
                this.cancelDrag = false;

                let fctCancelDrag = function()
                {
                    this.cancelDrag = true;

                }.bind(this);

                this.emit(this.EVENT_DRAG_START, this, this.containers[i], fctCancelDrag);

                if (!this.cancelDrag)
                {
                    this.startPos = {"x": this.containers[i].position.x, "y": this.containers[i].position.y};
                    this.dragContainer = this.containers[i];
                    this.dragInput = dragInput;
                    this.lastInput = {"x": iX, "y": iY};

                    this.dragStartRatio = {
                        "x": (iX - rect.x) / rect.width,
                        "y": (iY - rect.y) / rect.height
                    };


                    this.moveDrag(iX, iY);

                    return true;
                }
            }
        }

        return false;
    }

    moveDrag(iX, iY)
    {
        if (this.dragContainer)
        {
            this.lastInput = {"x": iX, "y": iY};
            
            let rect = this.calculateRect(this.dragContainer);
            let globalPos = this.dragContainer.parent.toGlobal({"x": 0, "y": 0});
            globalPos.x += this.UI.ResponsiveManager.ScreenPadding;

            this.dragContainer.position.set(
                iX - globalPos.x - rect.width * this.dragStartRatio.x,
                iY - globalPos.y - rect.height * this.dragStartRatio.y
            );

            let hover = this.calculateHover(this.dragContainer, rect);

            if (this.hover && hover != this.hover)
            {
                this.emit(this.EVENT_EXIT_DROP_ZONE, this, this.dragContainer, this.hover);
                this.hover = null;
            }

            if (hover && hover != this.hover)
            {
                this.hover = hover;
                this.emit(this.EVENT_ENTER_DROP_ZONE, this, this.dragContainer, this.hover);
            }
        }
    }

    endDrag()
    {
        if (this.dragContainer)
        {
            let rect = this.calculateRect(this.dragContainer);

            this.endPos = this.startPos;
            this.cancelEndPos = this.startPos;
            this.startPos = {"x": this.dragContainer.position.x, "y": this.dragContainer.position.y};

            let hover = this.calculateHover(this.dragContainer, rect);
            if (hover)
            {
                let globalHoverPos = hover.parent.toGlobal(hover.position);
                let hoverPos = this.dragContainer.parent.toLocal(globalHoverPos);
                this.endPos = {"x": hoverPos.x, "y": hoverPos.y};
            }

            let fctCancelDrop = function()
            {
                this.endPos = this.cancelEndPos;
            }
            .bind(this);

            let fctOverrideEndPos = function(x, y)
            {
                this.endPos = {"x": x, "y": y};
            }
            .bind(this);

            this.emit(this.EVENT_DRAG_END, this, this.dragContainer, fctCancelDrop, fctOverrideEndPos);
            
            if (hover)
            {
                this.emit(this.EVENT_DROP, this, this.dragContainer, hover, fctCancelDrop, fctOverrideEndPos);
            }

            this.totalTime = this.calculateAnimationTime(this.startPos.x, this.startPos.y, this.endPos.x, this.endPos.y);
            this.timeLeft = this.totalTime;

            this.lastTime = new Date().getTime();

            this.endDragAnimation();
        }
    }

    endDragAnimation()
    {
        let time = new Date().getTime();
        let delta = time - this.lastTime;
        this.lastTime = time;

        this.timeLeft -= delta;
        let t = 1 - (this.timeLeft / this.totalTime);
        t = t*t*t * (t * (6*t - 15) + 10);

        this.dragContainer.position.set(
            Lerp.lerp(this.startPos.x, this.endPos.x, t),
            Lerp.lerp(this.startPos.y, this.endPos.y, t)
        );

        if (this.timeLeft > 0)
        {
            window.requestAnimationFrame(this.endDragAnimation.bind(this));
        }
        else
        {
            let container = this.dragContainer;

            this.dragContainer = null;
            this.dragInput = null;
            this.timeLeft = 0;

            this.emit(this.EVENT_DRAG_END_ANIMATION_FINISHED, this, container);
        }
    }

    /*******************************************
    *   EVENTS
    *******************************************/
    onMouseDown(e)
    {
        if (!this.IsDragging && !this.IsAnimating && this.CanDrag)
        {
            this.startDrag(1, e.clientX, e.clientY);
        }
    }

    onMouseMove(e)
    {
        if (this.IsDragging && !this.IsAnimating)
        {
            this.moveDrag(e.clientX, e.clientY);
        }
    }

    onMouseUp(e)
    {
        if (this.IsDragging && !this.IsAnimating)
        {
            this.endDrag();
        }
    }

    onTouchStart(e)
    {
        if (!this.IsDragging && !this.IsAnimating && this.CanDrag)
        {
            let touch = e.touches[0];
            this.startDrag(touch.identifier, touch.clientX, touch.clientY)
        }
    }

    onTouchMove(e)
    {
        if (this.IsDragging && !this.IsAnimating)
        {
            for(let i = 0; i < e.touches.length; i++)
            {
                if (e.touches[i].identifier == this.dragInput)
                {
                    let touch = e.touches[i];
                    this.moveDrag(touch.clientX, touch.clientY);
                }
            }
        }
    }

    onTouchEnd(e)
    {
        if (this.IsDragging && !this.IsAnimating)
        {
            let isOk = false;
            if (e.touches.length > 0)
            {
                for(let i = 0; i < e.touches.length; i++)
                {
                    if (e.touches[i].identifier == this.dragInput)
                    {
                        isOk = true;
                        break;
                    }
                }
            }
            if (!isOk)
            {
                this.endDrag();
            }
        }
    }
}