import {Vector2} from "three";
import Direction from "../../utils/Direction.js";
import EnemyAI from "./EnemyAI.js";

export default class PathAI extends EnemyAI
{
    constructor()
    {
        super();
    }

    get WAIT_ACTION() { return "wait"; }
    get TURN_ACTION() { return "turn"; }
    IsNotMoveAction(type) { return [this.WAIT_ACTION, this.TURN_ACTION].includes(type); };

    get Pathing() { return this.pathing; }
    get Grid() { return this.Character.Grid; }

    /*******************************************
    *   INITIALIZATION
    *******************************************/
    /**
        Parameters to pass to the init function:
        - character     Character object this AI should have governance over
        - spawnPos      Vector2 containing the world position where the character is supposed to spawn on the map
        - definition    Behaviour definition from the settings.json file (only the sub part of a definition called "ai")
        - pathing       Array containing the path to follow during the roaming phase
    */
    init(meta)
    {
        this.pathing = meta.pathing;

        this.isForward = true;
        this.pathIndex = 0;
        this.waitTimeout = null;
        this.toExecuteWhenCanMove = null;

        super.init(meta);

        this.preparePath();
        this.applyPath();
    }

    createClosure()
    {
        super.createClosure();

        this.fctApplyPath = this.applyPath.bind(this);
        this.fctOnPathPartEnd = this.onPathPartEnd.bind(this);
    }

    bindEvents()
    {
        super.bindEvents();
    }

    destroy()
    {
        super.destroy();
    }

    /**
        Calculates start and end positions for each step to avoid having to do it every time
    */
    preparePath()
    {
        let spawnPos = this.Character.SpawnPos;
        let currentPos = new Vector2(spawnPos.x, spawnPos.y);

        let pathing = [];

        for (let i = 0; i < this.Pathing.length; i++)
        {
            let step = [];
            //Direction or wait
            step.push(this.Pathing[i][0]);
            //Distance or time
            step.push(this.Pathing[i][1]);
            //Start position on forward
            step.push(new Vector2(currentPos.x, currentPos.y));

            let dir = this.Pathing[i][0];
            if (dir == Direction.North)
            {
                currentPos.y -= this.Pathing[i][1];
            }
            else if (dir == Direction.South)
            {
                currentPos.y += this.Pathing[i][1];
            }
            else if (dir == Direction.West)
            {
                currentPos.x -= this.Pathing[i][1];
            }
            else if (dir == Direction.East)
            {
                currentPos.x += this.Pathing[i][1];
            }

            //End position on forward
            step.push(new Vector2(currentPos.x, currentPos.y));
            pathing.push(step);
        }

        this.pathing = pathing;

        console.log(this.Pathing);
    }

    /*******************************************
    *   UPDATE LOOP
    *******************************************/
    update(fDeltaTime)
    {
        super.update(fDeltaTime);

        if (this.toExecuteWhenCanMove && this.Character.CanMove)
        {
            let fct = this.toExecuteWhenCanMove;
            delete this.toExecuteWhenCanMove;

            fct();
        }
    }

    updateRoaming(fDeltaTime)
    {
        
    }

    /*******************************************
    *   PATHING
    *******************************************/
    applyPath()
    {
        if (!this.IsTriggered && !this.IsStun)
        {
            if (this.Pathing.length > 0 && this.pathIndex >= 0 && this.pathIndex < this.Pathing.length)
            {
                let path = this.Pathing[this.pathIndex];
                console.log(path);

                //WAIT
                if (path[0] == this.WAIT_ACTION)
                {
                    if (this.waitTimeout === null)
                    {
                        this.waitTimeout = setTimeout(function()
                        {
                            this.waitTimeout = null;
                            this.fctOnPathPartEnd();
                        }
                        .bind(this), path[1] * 1000);
                    }
                }
                else if (path[0] == this.TURN_ACTION)
                {
                    this.Character.changeDirection(path[1])
                    this.fctOnPathPartEnd();
                }
                //MOVE
                else
                {
                    let start = (this.isForward ? path[2] : path[3]);
                    let end = (this.isForward ? path[3] : path[2]);

                    let nearest = this.Grid.getNearestWalkableTile(end.x, end.y, start.x, start.y);

                    if (nearest/* && nearest.x == end.x && nearest.y == end.y*/)
                    {
                        let worldPos = this.Grid.gridToWorldPosition(nearest.x, nearest.y);
                        this.Character.goTo(worldPos.x, worldPos.y, false, this.fctOnPathPartEnd);
                    }
                    //In case the enemy cannot reach the distination we wait a bit and invert the movement sequence
                    else
                    {
                        this.isForward = !this.isForward;
                        if (this.waitTimeout === null)
                        {
                            this.waitTimeout = setTimeout(function()
                            {
                                this.waitTimeout = null;
                                this.fctOnPathPartEnd();
                            }
                            .bind(this), 1000);
                        }
                    }
                }
            }
        }
    }

    /*******************************************
    *   ACTIONS
    *******************************************/
    stopRoaming()
    {
        super.stopRoaming();

        if (this.waitTimeout !== null)
        {
            clearTimeout(this.waitTimeout);
            this.waitTimeout = null;
        }
    }

    startAttacking(objCharacter)
    {
        if (super.startAttacking(objCharacter) && this.waitTimeout !== null)
        {
            clearTimeout(this.waitTimeout);
            this.waitTimeout = null;
        }
    }

    stopAttacking()
    {
        if (!this.IsStun)
        {
            this.target = null;

            this.Character.setFOVState(false);
            this.isComingBack = true;
            this.timeLeftCooldown = this.COOLDOWN_TIME;

            this.continuePathing();
        }
    }

    hitTarget(objTarget)
    {
        if (super.hitTarget(objTarget))
        {
            this.continuePathing();
        }
    }

    continuePathing()
    {
        this.toExecuteWhenCanMove = this.fctApplyPath;

        //In case the current step is a wait, skip to the next one
        if (this.pathIndex >= 0 && this.pathIndex < this.Pathing.length && this.IsNotMoveAction( this.Pathing[this.pathIndex][0]))
        {
            this.toExecuteWhenCanMove = this.fctOnPathPartEnd;
        }
    }

    /*******************************************
    *   EVENTS
    *******************************************/
    onPathPartEnd()
    {
        this.pathIndex += (this.isForward ? 1 : -1);

        if (this.pathIndex < 0)
        {
            this.isForward = true;
            this.pathIndex = 0;
            if (this.pathIndex + 1 < this.Pathing.length && this.IsNotMoveAction( this.Pathing[this.pathIndex][0]))
            {
                this.pathIndex++;
            }
        }
        else if (this.pathIndex >= this.pathing.length)
        {
            this.isForward = false;
            this.pathIndex = this.Pathing.length - 1;
            if (this.pathIndex - 1 >= 0 && this.IsNotMoveAction( this.Pathing[this.pathIndex][0]))
            {
                this.pathIndex--;
            }
        }

        this.applyPath();
    }
}