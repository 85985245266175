import EventEmitter from "eventemitter3";
import Constants from "./utils/Constants.js";
import gtag from "ga-gtag";
import SaveManager from "./save/SaveManager.js";

export default class GameManager extends EventEmitter
{
    constructor()
    {
        super();

        GameManager.instance = this;

        this.isPaused = false;
        this.shouldUpdateGameTime = false;
        this.currentMissionKey = "currentmission";
        this.currentObjectiveKey = "currentobjective";
        this.scaredBrothersKey = "scaredbrothers";
        this.gameTimeKey = "gametime";
    }

    get EVENT_PAUSE() { return "pause"; }
    get EVENT_QUIT_TO_HOME() { return "quit-to-home"; }
    get EVENT_START_GAME() { return "start-game"; }
    get EVENT_START_GAME_LOADING_DONE() { return "start-game-loading-done"; }
    get EVENT_UPDATE() { return "update"; }
    get EVENT_TUTORIAL_STEP_COMPLETED() { return "tutorial-step-completed"; }

    get LAST_MISSION() { return Constants.getValue("LAST_MISSION"); }

    //---------------------------------------------------------
    //  DEPENDENCIES
    //---------------------------------------------------------
    get AnimationsCreator() { return this.dependencies.get("AnimationsCreator"); }
    get AudioManager() { return this.dependencies.get("AudioManager"); }
    get CharacterManager() { return this.dependencies.get("CharacterManager"); }
    get SaveManager() { return this.dependencies.get("SaveManager"); }
    get UIManager() { return this.dependencies.get("UIManager"); }
    get WorldManager() { return this.dependencies.get("WorldManager"); }
    //---------------------------------------------------------

    get DeltaTime() { return this.deltaTime; }
    get CurrentMission() { return this.SaveManager.getFromSave(this.currentMissionKey, 0); }
    get CurrentObjective() { return this.SaveManager.getFromSave(this.currentObjectiveKey, 0); }
    get HasScaredBrothers() { return this.SaveManager.getFromSave(this.scaredBrothersKey, false); }
    set HasScaredBrothers(bNewValue) { this.SaveManager.setFromSave(this.scaredBrothersKey, (bNewValue ? true : false)); }


    get IsPaused()
    { 
        return this.isPaused || this.WorldManager.IsDialogMode;
    }

    /*******************************************
    *   INITIALIZATION
    *******************************************/
    init(dependencies)
    {
        this.dependencies = dependencies;

        this.createClosure();
        this.bindEvents();

        this.update();
    }

    createClosure()
    {
        this.fctUpdate = this.update.bind(this);
        this.fctVisibilityChange = this.onVisibilityChange.bind(this);
    }

    bindEvents()
    {
        //VISIBILITY
        //-----------------------------
        let hidden, visibilityChange;
        if (typeof document.hidden !== "undefined") { // Opera 12.10 and Firefox 18 and later support
            hidden = "hidden";
            visibilityChange = "visibilitychange";
        } else if (typeof document.msHidden !== "undefined") {
            hidden = "msHidden";
            visibilityChange = "msvisibilitychange";
        } else if (typeof document.webkitHidden !== "undefined") {
            hidden = "webkitHidden";
            visibilityChange = "webkitvisibilitychange";
        }

        window.addEventListener(visibilityChange, this.fctVisibilityChange, false)
        window.addEventListener("pagehide", this.fctVisibilityChange, false) //for ios
    }

    /*******************************************
    *   UPDATE LOOP
    *******************************************/
    update(iNow)
    {
        let delta = (iNow - this.elapsed) / 1000;
        this.elapsed = iNow;
        this.deltaTime = delta;

        this.emit(this.EVENT_UPDATE, delta);



        if (this.shouldUpdateGameTime && !this.IsPaused)
        {
            this.calculateGameTime(delta);
        }

        requestAnimationFrame(this.fctUpdate);
    }

    /*******************************************
    *   PAUSE FUNCTIONS
    *******************************************/
    pause()
    {
        if (!this.isPaused)
        {
            this.isPaused = true;
            this.emit(this.EVENT_PAUSE, this.isPaused);
        }
    }

    resume()
    {
        if (this.isPaused)
        {
            this.isPaused = false;
            this.emit(this.EVENT_PAUSE, this.isPaused);
        }
    }

    /*******************************************
    *   SETTINGS
    *******************************************/
    getSetting(strSettingKey)
    {
        if (!this.gameSettings)
            return null;

        if (strSettingKey in this.gameSettings)
        {
            return this.gameSettings[strSettingKey];
        }
        return null;
    }

    /*******************************************
    *   GAME TIME
    *******************************************/
    getGameTime(iSlotIndex = null)
    {
        let time = 0;
        if (iSlotIndex === null)
        {
            time = this.SaveManager.getFromSave(this.gameTimeKey, 0);
        }
        else
        {
            let currentSlot = this.SaveManager.SaveSlot;

            this.SaveManager.SaveSlot = iSlotIndex;
            time = this.SaveManager.getFromSave(this.gameTimeKey, 0);
            this.SaveManager.SaveSlot = currentSlot;
        }

        return time;
    }

    pauseGameTime ()
    {
        this.shouldUpdateGameTime = false;
    }

    resumeGameTime()
    {
        this.shouldUpdateGameTime = true;
    }

    calculateGameTime(fDeltaTime)
    {
        let gameTime = this.SaveManager.getFromSave(this.gameTimeKey, 0);
        gameTime += fDeltaTime;

        this.SaveManager.setFromSave(this.gameTimeKey, gameTime);
    }

    /*******************************************
    *   MISSION
    *******************************************/
    startMission(iMission)
    {
        if (gtag)
        {
            gtag('event', 'mission', {
                'event_category': "start",
                'event_label': iMission,

            });
        }

        this.SaveManager.setFromSave(this.currentMissionKey, Math.max(0, iMission));
        this.SaveManager.setFromSave(this.currentObjectiveKey, 0);
    }

    endMission()
    {
        if (gtag)
        {
            // gtag('event', 'mission', {
            //     'event_category': "start",
            //     'event_label': iMission,
            //
            // });
        }

        this.SaveManager.set("CurrentObjective", "");
        this.SaveManager.setFromSave(this.currentMissionKey, 0);
        this.SaveManager.setFromSave(this.currentObjectiveKey, 0);
    }

    clearMissionObjective()
    {
        this.SaveManager.setFromSave(this.currentObjectiveKey, this.CurrentObjective + 1);
    }

    /*******************************************
    *   GAMEPLAY
    *******************************************/
    playGameOnSlot(iSlotIndex, strForceEnvironment = null)
    {
        this.UIManager.showSceneTransition(() =>
        {
            this.SaveManager.SaveSlot = iSlotIndex;

            let bIsEmpty = !this.SaveManager.getFromSave(this.SaveManager.newGameKey, false);

            this.resumeGameTime();
            this.emit(this.EVENT_START_GAME, iSlotIndex, bIsEmpty);

            if (bIsEmpty)
            {
                this.SaveManager.deleteSaveSlot(iSlotIndex);
                this.SaveManager.save();

                this.resume();

                this.AudioManager.stopBgm(null, "theme", 2);
                this.resumeGameTime();

                if (Constants.getValue("NEW_GAME_START_CHARACTER_CREATOR"))
                {
                    this.UIManager.showCharacterCreatorUI();
                }
                else
                {
                    let env = strForceEnvironment;
                    if (!env)
                    {
                        env = this.WorldManager.ENVIRONMENT_FOREST;
                    }
                    this.WorldManager.changeEnvironment(env);
                }
            }
            else
            {
                this.UIManager.showCharacterLoadingUI(false);

                let builds = {};
                for (let i = 0; i < 3; i++)
                {
                    builds[i] = this.CharacterManager.getCharacterBuild(i);
                }

                this.AnimationsCreator.once(this.AnimationsCreator.EVENT_DONE, () =>
                {
                    this.UIManager.showSceneTransition(() =>
                    {
                        this.resumeGameTime();
                        this.WorldManager.resumeTimeOfDay();

                        this.AudioManager.stopBgm(null, null, 3);

                        let env = strForceEnvironment;
                        if (!env)
                        {
                            env = this.WorldManager.ENVIRONMENT_BARN;
                        }
                        this.WorldManager.changeEnvironment(env);
                        this.WorldManager.allowWorldClick();

                        this.emit(this.EVENT_START_GAME_LOADING_DONE);
                    });
                });

                this.AnimationsCreator.generateMultiple(builds);
            }

            this.resume();
        });
    }

    quitCurrentGame()
    {
        this.pause();
        this.pauseGameTime();
        //Binder.instance.misc.onQuitToHome();

        this.WorldManager.preventWorldClick();

        window.location.reload();
    }

    /*******************************************
    *   EVENTS
    *******************************************/
    onLoadComplete(objSettings)
    {
        this.gameSettings = objSettings.gameSettings
    }

    onVisibilityChange ()
    {
        if (document.hidden)
        {
            if (!this.prevPause && this.UIManager.CurrentUI && this.UIManager.CurrentUI.showPausePopup)
            {
                if (this.UIManager.CurrentUI.ShownPopups.length == 0)
                {
                    this.UIManager.CurrentUI.showPausePopup();
                }
            }
        }
    }
}