import {PlaneGeometry} from "three";
import {Vector2} from "three";
import BaseProjectile from "./BaseProjectile.js";
import Direction from "../../../utils/Direction.js";

export default class Spit extends BaseProjectile
{
    constructor()
    {
        super();
    }

    get ATLAS_ID() { return "misc3D"; }
    get TEXTURE_ID() { return "spit_{0}.png"; }

    get StunTime() { return this.meta.stunTime; }

    /*******************************************
    *   INITIALIZATION
    *******************************************/
    /**
        Parameters to pass to the init function:
        - id:           Logic id of this object instance. Usually managed by some sort of global manager
        - spawnPos      Vector2 containing the position to spawn this object on the field. Should be in ThreeJS world coordinates (not grid)
        - targetPos     Vector2 containing the position to aim the spit to. Should be in ThreeJS world coordinates (not grid)
        - distance      Maximum number of tiles before the spit is despawned
        - stunTime      Time (in seconds) a target is stunned when hit by this projectile
        - environment   Environment instance where this object lives
        - spawnOnCreate (Optional)If this object should be spawned as soon as the instance is created. Default is TRUE
        - params        JSON object containing additionnal parameters to pass to this object

    */
    init(meta)
    {
        meta.targetType = meta.environment.TYPE_ENEMY;
        super.init(meta);
    }

    initVisuals(meta)
    {
        meta.atlasName = this.ATLAS_ID;
        meta.textureName = this.TEXTURE_ID.replace("{0}", Direction.getSpriteName(this.Direction));

        return meta;
    }

    getMeshOrigin()
    {   
        return new Vector2(0, 0.5);
    }

    /*******************************************
    *   ACTION
    *******************************************/
    collide(objTarget)
    {
        objTarget.setStun(this.StunTime);
        this.removeProjectile();
    }

    removeProjectile()
    {
        this.Environment.removeSpit(this);
    }
}
