import { install } from "ga-gtag";
import MutantsLoader from "./loaders/MutantsLoader.js";
import AnimationsCreator from "./utils/AnimationsCreator.js";
import AudioManager from "./audio/AudioManager.js";
import CharacterManager from "./characters/CharacterManager.js";
import CheatManager from "./cheats/CheatManager.js";
import Constants from "./utils/Constants.js";
import Copydeck from "./utils/Copydeck.js";
import GameManager from "./GameManager.js";
import ItemManager from "./items/ItemManager.js";
import LabelManager from "./labels/LabelManager.js";
import ResponsiveManager from "./responsive/ResponsiveManager.js";
import SaveManager from "./save/SaveManager.js";
import TriggerManager from "./triggers/TriggerManager.js";
import UIManager from "./ui/UIManager.js";
import WorldManager from "./world/WorldManager.js";
import Library from "./Library.js";
import DependencyContainer from "./utils/DependencyContainer.js";

export default class LesMutantsSaison2
{
    constructor (canvas2D, canvas3D)
    {
        LesMutantsSaison2.instance = this;

        this.isFullscreen = false;

        this.createClosure();
        this.initGoogleAnalytics();
        this.buildApp(canvas2D, canvas3D);
        this.loadAssets();
    }

    createClosure ()
    {
        this.fctOnAssetsLoaded = this.onAssetsLoaded.bind(this);
        //this.fctTempLaunchLabo = this.onTempLaunchLabo.bind (this);
    }

    initGoogleAnalytics()
    {
        //@TODO: Put the real Google Analytics ID here for gtag to work
        install("G-Z63YFPKMDY");
    }

    buildApp (canvas2D, canvas3D)
    {
        this.dependencies = DependencyContainer.getInstance();

        this.dependencies.add("AudioManager",       new AudioManager());
        this.dependencies.add("CharacterManager",   new CharacterManager());
        this.dependencies.add("GameManager",        new GameManager());
        this.dependencies.add("ItemManager",        new ItemManager());
        this.dependencies.add("LabelManager",       new LabelManager());
        this.dependencies.add("ResponsiveManager",  new ResponsiveManager());
        this.dependencies.add("SaveManager",        new SaveManager());
        this.dependencies.add("TriggerManager",     new TriggerManager());
        this.dependencies.add("UIManager",          new UIManager(canvas2D));
        this.dependencies.add("WorldManager",       new WorldManager(canvas3D));

        if (process.env.NODE_ENV == "development")
        {
            this.dependencies.add("CheatManager", new CheatManager());
        }

        let managers = this.dependencies.getAll();
        for(let key in managers)
        {
            managers[key].init(this.dependencies);
        }



        this.dependencies.add("AnimationsCreator", new AnimationsCreator(this.dependencies));
    }


    loadAssets ()
    {
        this.loader = new MutantsLoader("/data/loading.json", BASE_PATH); //BASE_PATH will be injected in the webpage
        this.dependencies.add("Loader", this.loader);

        this.loader.on("load-complete", this.fctOnAssetsLoaded);


        this.loader.start(); //we want to wait for the ui to be set before we start loading
    }

    onAssetsLoaded ()
    {
        this.loader.off("load-complete", this.fctOnAssetsLoaded);
        this.build();
    }

    build ()
    {
        Constants.init();
        Copydeck.init();

        let objSettings = Library.getData("settings");

        let managers = this.dependencies.getAll();
        for (let key in managers)
        {
            if (managers[key].onLoadComplete)
            {
                managers[key].onLoadComplete(objSettings);
            }
        }

        managers.AudioManager.setVolumeInitialValue();

        this.addEvents();

        this.dependencies.get("UIManager").showSplashUI();
    }

    addEvents ()
    {
        //theses events are handle through out the app. Since we dont remove them, no need for a closure
        this.dependencies.get("UIManager").on("launch-barn", () => this.startBarn());
        this.dependencies.get("UIManager").on("launch-labo", () => this.startLabo());
    }


    startLabo ()
    {
        this.dependencies.get("WorldManager").showLabo();
    }

    startBarn ()
    {
        this.dependencies.get("WorldManager").showBarn();
    }


    // async onFullscreen ()
    // {
    //
    //     if (!document.fullscreenElement)
    //     {
    //         await this.canvas.requestFullscreen();
    //     }
    //     else
    //     {
    //         await document.exitFullscreen();
    //     }
    // }


}


