import { TextStyle } from "@pixi/text";
import { TextMetrics } from "@pixi/text";
import BaseCreationContent from "./BaseCreationContent.js";
import ColorTheme from "../../ColorTheme.js";
import Display from "../../../utils/Display.js";
import DualColorButton from "./DualColorButton.js";
import GraphicsResponsive from "../../commons/responsive/GraphicsResponsive.js";
import Library from "../../../Library.js";
import SpriteResponsive from "../../commons/responsive/SpriteResponsive.js";
import TextButton from "../../commons/buttons/TextButton.js";
import TextResponsive from "../../commons/responsive/TextResponsive.js";

export default class CreationCategoryContent extends BaseCreationContent
{
    constructor()
    {
        super();
    }

    get EVENT_BACK() { return "back"; }
    get EVENT_HAIRCUT_BACK() { return "haircut-back"; }
    get EVENT_HAIRCUT_NEXT() { return "haircut-next"; }
    get EVENT_COLOR_PICK() { return "color-pick"; }

    get Section() { return this.section; }
    set Section(strNewValue) { this.section = strNewValue; this.build(); }

    get HaircutLabel() { return this.UI.LabelManager.translate("Coiffure"); }

    get ButtonWidth() { return this.ButtonHeight; }
    get ButtonHeight() { return "(" + this.BgHeight + " * 27%)"; }

    get HaircutWidth() { return "(" + this.BgHeight + " * 90%)"; }
    get HaircutHeight() { return "(" + this.BgHeight + " * 70%)"; }
    get HaircutX() { return "(" + this.BgWidth + " - " + this.HaircutWidth + " - " + this.BgHeight + " * 7.5%)"; }
    get HaircutY() { return "(" + this.BgHeight + " * 22.5%)"; }

    get HairColorsWidth() { return "(" + this.BgWidth + " - (" + this.BgWidth + " - " + this.HaircutX + ") - " + this.BgHeight + " * 7.5% - " + this.HairColorsX + ")"; }
    get HairColorsHeight() { return "(" + this.BgHeight + " * 68%)"; }
    get HairColorsX() { return "(" + this.BgHeight + " * 15%)"; }
    get HairColorsY() { return "(" + this.BgHeight + " * 22.5% + " + this.BgHeight + " * 1%)"; }

    get CurrentBuild() { return this.UI.CharacterManager.getCharacterBuild(this.Character); }
    get SelectedIndex() 
    {
        let index = -1;

        if (this.Section == this.CharacterCreator.SECTION_HAIR)
        {
            index = parseInt(this.CurrentBuild.substring(0, 1));
        }
        else if (this.Section == this.CharacterCreator.SECTION_SKIN)
        {
            index = parseInt(this.CurrentBuild.substring(1, 2));
        }
        else if (this.Section == this.CharacterCreator.SECTION_EYES)
        {
            index = parseInt(this.CurrentBuild.substring(2, 3));
        }
        else if (this.Section == this.CharacterCreator.SECTION_BODY)
        {
            index = parseInt(this.CurrentBuild.substring(3, 4));
        }
        else if (this.Section == this.CharacterCreator.SECTION_PANTS)
        {
            index = parseInt(this.CurrentBuild.substring(4, 5));
        }

        return index;
    }

    get HaircutSelectedIndex() 
    {
        return parseInt(this.CurrentBuild.substring(7, 8));
    }

    getHairFromType()
    {
        let build = this.CurrentBuild.split("_");
        let hairType = build[0].substring(0, 1);

        //return "category_type_hair_" + hairType;
        return "category_hair";
    }

    getHaircutsFromType()
    {
        return [
            "category_type_hair_1",
            "category_type_hair_2",
            "category_type_hair_3",
            "category_type_hair_4",
            "category_type_hair_5",
            "category_type_hair_6",
            "category_type_hair_7",
            "category_type_hair_0"
        ];
    }

    /*******************************************
    *   INITIALIZATION
    *******************************************/
    /**
        Parameters to pass to the init function:
        - ui:       UI section object where this component resides
        - creator:  Character creator owning this content
        - character Id of the currently selected character
        - section   Currenlty active section of the CharacterCreator
        - rWidth:   Responsive equation representing the width of this component
        - rHeight:  Responsive equation representing the height of this component
    */
    init(meta)
    {
        this.section = meta.section;
        this.scales = {};
        this.buttons = {};

        return super.init(meta);
    }

    createClosure()
    {
        super.createClosure();

        this.fctOnBackClick = this.onBackClick.bind(this);
        this.fctOnBackDown = this.onBackDown.bind(this);
        this.fctOnHaircutBackClick = this.onHaircutBackClick.bind(this);
        this.fctOnHaircutBackDown = this.onHaircutBackDown.bind(this);
        this.fctOnHaircutNextClick = this.onHaircutNextClick.bind(this);
        this.fctOnHaircutNextDown = this.onHaircutNextDown.bind(this);
        this.fctOnColorClick = this.onColorClick.bind(this);
    }

    bindEvents()
    {
        super.bindEvents();
    }

    destroy(options)
    {
        for (let key in this.buttons)
        {
            this.removeChild(this.buttons[key]);
            this.buttons[key].destroy(options);
        }
        delete this.buttons;

        if (this.sprites.arrow)
        {
            this.sprites.arrow.off("pointerup", this.fctOnBackClick);
            this.sprites.arrow.off("pointerdown", this.fctOnBackDown);
            this.removeChild(this.sprites.arrow);
            this.sprites.arrow.destroy(options);

            delete this.sprites.arrow;
        }


        if (this.sprites.haircutBack)
        {
            this.sprites.haircutBack.off("pointerup", this.fctOnHaircutBackClick);
            this.sprites.haircutBack.off("pointerdown", this.fctOnHaircutBackDown);
            this.removeChild(this.sprites.haircutBack);
            this.sprites.haircutBack.destroy(options);

            delete this.sprites.haircutBack;
        }

        if (this.sprites.haircutNext)
        {
            this.sprites.haircutNext.off("pointerup", this.fctOnHaircutNextClick);
            this.sprites.haircutNext.off("pointerdown", this.fctOnHaircutNextDown);
            this.removeChild(this.sprites.haircutNext);
            this.sprites.haircutNext.destroy(options);

            delete this.sprites.haircutNext;
        }

        super.destroy(options);
    }

    /*******************************************
    *   BUILD
    *******************************************/
    build()
    {
        super.build();

        this.buildTitle();

        if (this.Section == this.CharacterCreator.SECTION_HAIR)
        {
            this.buildHairSection();
        }
        else if (this.Section == this.CharacterCreator.SECTION_SKIN)
        {
            this.buildSkinSection();
        }
        else if (this.Section == this.CharacterCreator.SECTION_EYES)
        {
            this.buildEyesSection();
        }
        else if (this.Section == this.CharacterCreator.SECTION_BODY)
        {
            this.buildBodySection();
        }
        else if (this.Section == this.CharacterCreator.SECTION_PANTS)
        {
            this.buildPantsSection();
        }

        this.buildBackArrow();
    }

    clean()
    {
        if (this.sprites.arrow)
        {
            this.removeChild(this.sprites.arrow);
        }
        if (this.sprites.haircutStyle)
        {
            this.removeChild(this.sprites.haircutStyle);
        }
        if (this.sprites.haircutNext)
        {
            this.removeChild(this.sprites.haircutNext);
        }
        if (this.sprites.haircutBack)
        {
            this.removeChild(this.sprites.haircutBack);
        }
        if (this.texts.haircutTitle)
        {
            this.removeChild(this.texts.haircutTitle);
        }
        if (this.graphics.haircutBg)
        {
            this.removeChild(this.graphics.haircutBg);
        }
        for (let key in this.buttons)
        {
            this.removeChild(this.buttons[key]);
        }
        super.clean();
    }

    buildTitle()
    {
        if (!this.texts.title)
        {
            this.texts.title = new TextResponsive().init({"ui": this.UI});
            this.addChild(this.texts.title);
        }

        let text = this.texts.title;
        let label = this.CharacterCreator.getSectionDescriptionLabel(this.Section);

        let style = new TextStyle({
            fontFamily: this.UI.Values.general.title.font,
            fontSize:   this.evaluate(this.valueFormula(this.UI.Values.general.title.size * 1.1)),
            fontWeight: this.UI.Values.general.title.weight,
            fill: this.UI.Values.general.title.color,
            align: "center"
        });

        let metrics = TextMetrics.measureText(label, style);

        let x = this.evaluate(this.BgWidth + " / 2 - " + metrics.width + " / 2", 0, 0, 0, 0, this);
        let y = this.evaluate(this.BgHeight + " * 5%", 0, 0, 0, 0, this);

        text.text = label;
        text.style = style;
        text.width = metrics.width;
        text.height = metrics.height;
        text.position.set(x, y);
    }

    buildHairSection()
    {
        this.buildHairColors();
        this.buildHaircutSection();
    }

    buildSkinSection()
    {
        this.buildColorChoices(
            ColorTheme.SKIN
        );
    }

    buildEyesSection()
    {
        this.buildColorChoices(
            ColorTheme.IRIS,
            null,
            "category_iris"
        );
    }

    buildBodySection()
    {
        this.buildColorChoices(
            ColorTheme.SWEATER
        );
    }

    buildPantsSection()
    {
        this.buildColorChoices(
            ColorTheme.LEG,
            ColorTheme.BOOTS
        );
    }

    buildColorChoices(arrColors1, arrColors2 = null, strIcon = null)
    {
        let selectedIndex = this.SelectedIndex;
        let width = this.ButtonWidth;
        let height = this.ButtonHeight;
        let perRow = Math.max(Math.round(arrColors1.length / 2), 4);
        let padding = "(" + this.BgWidth + " - " + width + " * " + perRow + ") / " + (perRow + 1);

        for (let i = 0; i < arrColors1.length; i++)
        {
            if (!this.buttons[this.Section + "_" + i])
            {
                let color1 = arrColors1[i];
                let color2 = (arrColors2 ? arrColors2[i] : null);
                if (color2)
                {
                    color1 = color2;
                    color2 = arrColors1[i];
                }
                this.buttons[this.Section + "_" + i] = new DualColorButton().init({
                    "ui": this.UI,
                    "index": i,
                    "color": color1,
                    "color2": color2,
                    "icon": strIcon,
                    "rWidth": width,
                    "rHeight": height
                });
                this.buttons[this.Section + "_" + i].on(this.buttons[this.Section + "_" + i].EVENT_CLICK, this.fctOnColorClick);//@
            }
            else
            {
                this.buttons[this.Section + "_" + i].setSize(width, height);
            }

            let button = this.buttons[this.Section + "_" + i];
            button.IsSelected = selectedIndex === i;

            let x = padding + " * " + (i % perRow + 1) + " + " + width + " * " + (i % perRow);
            let y = "(" + this.BgHeight + " * 22.5%) + " + height + " * " + Math.floor(i / perRow) + " + (ih * 5%) * " + Math.floor(i / perRow);

            button.rX = [{on: "default", x: x}];
            button.rY = [{on: "default", y: y}];
            button.layout(Display.getSize());

            this.addChild(this.buttons[this.Section + "_" + i]);
        }
    }

    buildBackArrow()
    {
        if (!this.sprites.arrow)
        {
            let texture = Library.getTextureFromAtlas("ui", "arrow_rtl");
            this.sprites.arrow = new SpriteResponsive(texture).init({"ui": this.UI});
            this.sprites.arrow.interactive = true;
            this.sprites.arrow.buttonMode = true;
            this.sprites.arrow.on("pointerup", this.fctOnBackClick);//@
            this.sprites.arrow.on("pointerdown", this.fctOnBackDown);//@
            this.scales.back = {"x": 1, "y": 1};
        }

        let sprite = this.sprites.arrow;
        let scale = this.scales.back;

        let ratio = sprite.width / sprite.height;
        let height = this.evaluate(this.BgHeight + " * 12.5%", 0, 0, 0, 0, this);
        let width = height * ratio * scale.x;
        height *= scale.y;
        let x = - width / 1.5 + width * (1 - scale.x) / 2;
        let y = height + height * (1 - scale.y) / 2;

        sprite.width = width;
        sprite.height = height;
        sprite.position.set(x, y);

        this.addChild(this.sprites.arrow);
    }

    buildHairColors()
    {
        //let strIcon = this.getHairFromType();
        let strIcon = null;
        let arrColors1 = ColorTheme.HAIR;
        let perRow = Math.max(Math.round(arrColors1.length / 2), 4);
        let selectedIndex = this.SelectedIndex;
        let height = "(" + this.HairColorsHeight + " * 40%)";
        let width = height ;
        let padding = "((" + this.HairColorsWidth + " - " + width + " * " + perRow + ") / (" + perRow + "-1))";

        for (let i = 0; i < arrColors1.length; i++)
        {
            if (!this.buttons[this.Section + "_" + i])
            {
                this.buttons[this.Section + "_" + i] = new DualColorButton().init({
                    "ui": this.UI,
                    "index": i,
                    "color": arrColors1[i],
                    "icon": strIcon,
                    "rWidth": width,
                    "rHeight": height
                });
                this.buttons[this.Section + "_" + i].on(this.buttons[this.Section + "_" + i].EVENT_CLICK, this.fctOnColorClick);//@
            }
            else
            {
                this.buttons[this.Section + "_" + i].setSize(width, height);
            }

            let button = this.buttons[this.Section + "_" + i];
            button.IsSelected = selectedIndex === i;

            let x = this.HairColorsX + " + " + padding + " * " + (i % perRow) + " + " + width + " * " + (i % perRow);
            let y = this.HairColorsY + " + (" + height + " + (" + this.HairColorsHeight + " - " + height + " * 2)) * " + Math.floor(i / perRow);

            button.rX = [{on: "default", x: x}];
            button.rY = [{on: "default", y: y}];
            button.layout(Display.getSize());

            this.addChild(this.buttons[this.Section + "_" + i]);
        }
    }

    buildHaircutSection()
    {
        if (!this.graphics.haircutBg)
        {
            this.graphics.haircutBg = new GraphicsResponsive().init({"ui": this.UI});
        }

        let graphics = this.graphics.haircutBg

        let bgColor = this.StrokeColor;
        let cornerRadius = this.CornerRadius;

        let x = this.HaircutX;
        let y = this.HaircutY;
        let width = this.evaluate(this.HaircutWidth, 0, 0, 0, 0);
        let height = this.evaluate(this.HaircutHeight, 0, 0, 0, 0);

        graphics.beginFill(bgColor, 1);
        if (cornerRadius > 0)
        {
            graphics.drawRoundedRect(0, 0, width, height, cornerRadius);
        }
        else
        {
            graphics.drawRect(0, 0, width, height);
        }
        graphics.endFill();

        graphics.rX = [{on:"default", x: x}];
        graphics.rY = [{on:"default", y: y}];

        this.addChild(this.graphics.haircutBg);

        this.buildHaircutTitle();
        this.buildHaircutArrows();
        this.buildHaircutStyle();
    }

    buildHaircutTitle()
    {
        if (!this.texts.haircutTitle)
        {
            this.texts.haircutTitle = new TextResponsive().init({"ui": this.UI});
        }

        let text = this.texts.haircutTitle;
        let label = this.HaircutLabel;

        let style = new TextStyle({
            fontFamily: this.UI.Values.general.title.font,
            fontSize:   this.evaluate(this.valueFormula(this.UI.Values.general.title.size)),
            fontWeight: this.UI.Values.general.title.weight,
            fill: this.UI.Values.general.title.color,
            align: "center"
        });

        let metrics = TextMetrics.measureText(label, style);

        let x = this.HaircutX + " + " + this.HaircutWidth + " / 2 - " + metrics.width + " / 2";
        let y = this.HaircutY + " + " + this.HaircutHeight + " * 5%";

        text.text = label;
        text.style = style;
        text.width = metrics.width;
        text.height = metrics.height;

        text.rX = [{on:"default", x: x}];
        text.rY = [{on:"default", y: y}];
        text.layout(Display.getSize());

        this.addChild(text);
    }

    buildHaircutArrows()
    {
        if (!this.sprites.haircutBack)
        {
            let texture = Library.getTextureFromAtlas("ui", "arrow_rtl");
            this.sprites.haircutBack = new SpriteResponsive(texture).init({"ui": this.UI});
            this.sprites.haircutBack.interactive = true;
            this.sprites.haircutBack.buttonMode = true;
            this.sprites.haircutBack.on("pointerup", this.fctOnHaircutBackClick);//@
            this.sprites.haircutBack.on("pointerdown", this.fctOnHaircutBackDown);//@
            this.scales.haircutBack = {"x": 1, "y": 1};
        }

        let sprite = this.sprites.haircutBack;
        let scale = this.scales.haircutBack;

        let ratio = sprite.width / sprite.height;
        let height = this.evaluate(this.BgHeight + " * 10%", 0, 0, 0, 0, this);
        let width = height * ratio * scale.x;
        height *= scale.y;
        let x = this.evaluate(this.HaircutX + " + " + this.HaircutHeight + " * 5%", 0, 0, 0, 0, this) + width * (1 - scale.x) / 2;
        let y = this.evaluate(this.HaircutY + " + " + this.HaircutHeight + "  / 2 - " + height + " / 2", 0, 0, 0, 0, this) + height * (1 - scale.y) / 2;

        sprite.width = width;
        sprite.height = height;
        sprite.position.set(x, y);

        this.addChild(this.sprites.haircutBack);

        if (!this.sprites.haircutNext)
        {
            let texture = Library.getTextureFromAtlas("ui", "arrow_ltr");
            this.sprites.haircutNext = new SpriteResponsive(texture).init({"ui": this.UI});
            this.sprites.haircutNext.interactive = true;
            this.sprites.haircutNext.buttonMode = true;
            this.sprites.haircutNext.on("pointerup", this.fctOnHaircutNextClick);//@
            this.sprites.haircutNext.on("pointerdown", this.fctOnHaircutNextDown);//@
            this.scales.haircutNext = {"x": 1, "y": 1};
        }

        sprite = this.sprites.haircutNext;
        scale = this.scales.haircutNext;

        height = this.evaluate(this.BgHeight + " * 10%", 0, 0, 0, 0, this);
        let nextWidth = height * ratio * scale.x;
        height *= scale.y
        let nextX = this.evaluate(this.HaircutX + " + " + this.HaircutWidth + " - " + this.HaircutHeight + " * 5% - " + nextWidth, 0, 0, 0, 0, this) + nextWidth * (1 - scale.x) / 2;
        y = this.evaluate(this.HaircutY + " + " + this.HaircutHeight + "  / 2 - " + height + " / 2", 0, 0, 0, 0, this) + height * (1 - scale.y) / 2;

        sprite.width = nextWidth;
        sprite.height = height;
        sprite.position.set(nextX, y);

        this.addChild(this.sprites.haircutNext);
    }

    buildHaircutStyle()
    {
        if (!this.sprites.haircutStyle)
        {
            this.sprites.haircutStyle = new SpriteResponsive().init({"ui": this.UI});
        }

        let haircutTextureName = this.getHaircutsFromType()[this.HaircutSelectedIndex];
        let texture = Library.getTextureFromAtlas("ui", haircutTextureName);

        let sprite = this.sprites.haircutStyle;
        sprite.texture = texture;

        let ratio = texture.height / texture.width;
        let maxWidth = this.evaluate(this.HaircutWidth + " * 50%", 0, 0, 0, 0, this);

        let width = maxWidth;
        let height = width * ratio;

        if (texture.width < texture.height)
        {
            ratio = texture.width / texture.height;
            height = maxWidth;
            width = height * ratio;
        }

        let x = this.evaluate(this.HaircutX + " + " + this.HaircutWidth + "  / 2 - " + width + " / 2", 0, 0, 0, 0, this);
        let y = this.evaluate(this.HaircutY + " + " + this.HaircutHeight + "  / 2 - " + height + " / 2 + " + this.HaircutHeight + " * 2.5%", 0, 0, 0, 0, this);

        sprite.width = width;
        sprite.height = height;
        sprite.position.set(x, y);

        this.addChild(this.sprites.haircutStyle);
    }


    /*******************************************
    *   EVENTS
    *******************************************/
    onBackClick(iIndex)
    {
        for (let key in this.scales)
        {
            this.scales[key].x = this.UI.Values.textbutton.scale.normal;
            this.scales[key].y = this.UI.Values.textbutton.scale.normal;
        }
        this.build();

        this.UI.AudioManager.playSfx(this.UI.Values.audio.click);
        this.emit(this.EVENT_BACK, this);
    }

    onBackDown(iIndex)
    {
        this.scales.back.x = this.UI.Values.textbutton.scale.down;
        this.scales.back.y = this.UI.Values.textbutton.scale.down;
        this.build();
    }

    onHaircutBackClick(iIndex)
    {
        for (let key in this.scales)
        {
            this.scales[key].x = this.UI.Values.textbutton.scale.normal;
            this.scales[key].y = this.UI.Values.textbutton.scale.normal;
        }

        this.UI.AudioManager.playSfx(this.UI.Values.audio.click);
        this.emit(this.EVENT_HAIRCUT_BACK, this);
        this.build();
    }

    onHaircutBackDown(iIndex)
    {
        this.scales.haircutBack.x = this.UI.Values.textbutton.scale.down;
        this.scales.haircutBack.y = this.UI.Values.textbutton.scale.down;
        this.build();
    }

    onHaircutNextClick(iIndex)
    {
        for (let key in this.scales)
        {
            this.scales[key].x = this.UI.Values.textbutton.scale.normal;
            this.scales[key].y = this.UI.Values.textbutton.scale.normal;
        }

        this.UI.AudioManager.playSfx(this.UI.Values.audio.click);
        this.emit(this.EVENT_HAIRCUT_NEXT, this);
        this.build();
    }

    onHaircutNextDown(iIndex)
    {
        this.scales.haircutNext.x = this.UI.Values.textbutton.scale.down;
        this.scales.haircutNext.y = this.UI.Values.textbutton.scale.down;
        this.build();
    }

    onWindowMouseUp(e)
    {
        for (let key in this.scales)
        {
            this.scales[key].x = this.UI.Values.textbutton.scale.normal;
            this.scales[key].y = this.UI.Values.textbutton.scale.normal;
        }
        this.build();
    }

    onWindowTouchEnd(e)
    {
        if (e.touches.length == 0)
        {
            for (let key in this.scales)
            {
                this.scales[key].x = this.UI.Values.textbutton.scale.normal;
                this.scales[key].y = this.UI.Values.textbutton.scale.normal;
            }
            this.build();
        }
    }

    onColorClick(sender)
    {
        this.emit(this.EVENT_COLOR_PICK, sender.index);
    }
}