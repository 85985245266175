import BasePopup from "../BasePopup.js";
import CharacterViewer from "../characters/CharacterViewer.js";
import Display from "../../../utils/Display.js";
import GraphicsResponsive from "../responsive/GraphicsResponsive.js";
import InventoryGrid from "../inventory/InventoryGrid.js";
import InventorySlot from "../inventory/InventorySlot.js";
import ResourceFilterControl from "../controls/ResourceFilterControl.js";

export default class ClosetPopup extends BasePopup
{
    constructor()
    {
        super();
    }

    get Grid() { return this.inventory; }

    get CanSwitchFilter() { return this.canSwitch; }
    set CanSwitchFilter(bNewValue) { this.canSwitch = (bNewValue) ? true : false; }

    get CurrentFilter() { return this.currentFilter; }

    /*******************************************
    *   INITIALIZATION
    *******************************************/
    /**
        Parameters to pass to the init function:
        - ui:  UI section object where this component resides
        - clickToHide:  (Optional) If the popup should hide when the player clicks on the overlay behind. Default is FALSE
        - showOnStart   (Optional) If the popup should be shown on start. Default is TRUE
        - showClose:    (Optional) If the close button should be shown. Default is TRUE
        - createOverlay (Optional) If this popup should create it's own overlay for itself. If not, it will use the general UI overlay. Default is FALSE
        - sideBySide    (Optional) If this popup should be positioned side-by-side with other popups on screen. Default is TRUE
        - linked        (Optional) Linked popup to this one. If this one closes, the linked popup will close too. Default is NULL
    */
    init(meta)
    {
        meta.showClose = ("showClose" in meta ? meta.showClose : false);
        this.equipSlots = [];
        this.graphics = {};

        this.canSwitch = true;
        this.currentFilter = null;

        meta.title = meta.ui.LabelManager.translate("INVE_PLACARD").toUpperCase();

        return super.init(meta);
    }

    createClosure ()
    {
        super.createClosure();

        this.fctApplyFilter = this.applyFilter.bind(this);
        this.fctOnItemAdded = this.onItemAdded.bind(this);
    }

    bindEvents()
    {
        this.UI.ItemManager.on(this.UI.ItemManager.EVENT_ITEM_FOUND, this.fctOnItemAdded);//@
    }

    destroy(options)
    {
        this.UI.ItemManager.off(this.UI.ItemManager.EVENT_ITEM_FOUND, this.fctOnItemAdded);//@

        if (this.closeButton)
        {
            this.closeButton.destroy();
        }

        if (this.inventory)
        {
            this.inventory.destroy(options);
        }

        super.destroy(options);
    }

    /*******************************************
    *   BUILD
    *******************************************/
    build ()
    {
        super.build();

        this.buildInventory();
        this.buildFilter();
        // this.buildEquipment();
    }

    buildInventory ()
    {
        let strPadding = "(ih / 940 * 10)";
        let strOneSlotWidth = `(((${this.BgWidth} - (${strPadding} * 4)) / 6) - ${strPadding})`;
        let strInventoryHeight  = `((${strOneSlotWidth} * 6.5) + ${strPadding})`

        if (!this.inventory)
        {
            this.inventory = new InventoryGrid();
            this.inventory.init({
                "ui": this.UI,
                "itemsByRow": [6,6,6,6,6,6],
                "rWidth": strOneSlotWidth,
                "rPadding": strPadding,
                "type": this.inventory.TYPE_CLOSET
            });

            this.addChild(this.inventory);
        }

        this.strInventoryY = `${this.BgHeight} - ${strInventoryHeight} - (${strPadding} * 2)`;

        this.inventory.rX = [{on:"default", x: `(${strPadding} * 2)`}];
        this.inventory.rY = [{on:"default", y: this.strInventoryY}];

        this.inventory.layout(Display.getSize());
    }

    buildFilter ()
    {
        let size = Display.getSize();

        let strOneFilterWidth = `(${this.BgWidth} * 29%)`;
        let strOneFilterHeight = `159 * (${strOneFilterWidth}) / 221`
        let strPadding = `((${this.BgWidth} * 11%) / 4)`;

        let strPosY = `(${this.BgHeight}) * 12.5%`;

        if (!this.material)
        {
            this.material =  new ResourceFilterControl();
            this.material.init({
                "ui": this.UI,
                "rWidth": strOneFilterWidth,
                "rHeight": strOneFilterHeight,
                "type": this.UI.ItemManager.ITEM_CODES.TYPE_MATERIAL
            });

            this.material.on(this.material.EVENT_CLICK, this.fctApplyFilter);//@
            this.addChild(this.material);
        }

        this.material.rX = [{on:"default", x: strPadding}];
        this.material.rY = [{on:"default", y: strPosY}];
        this.material.layout(size);

        if (!this.food)
        {
            this.food =  new ResourceFilterControl();
            this.food.init({
                "ui": this.UI,
                "rWidth": strOneFilterWidth,
                "rHeight": strOneFilterHeight,
                "type": this.UI.ItemManager.ITEM_CODES.TYPE_FOOD
            });

            this.food.on(this.food.EVENT_CLICK, this.fctApplyFilter);//@
            this.addChild(this.food);
        }
        this.food.rX = [{on:"default", x: `${strPadding} * 2 + ${strOneFilterWidth}`}];
        this.food.rY = [{on:"default", y: strPosY}];
        this.food.layout(size);

        if (!this.equipment)
        {
            this.equipment =  new ResourceFilterControl();
            this.equipment.init({
                "ui": this.UI,
                "rWidth": strOneFilterWidth,
                "rHeight": strOneFilterHeight,
                "type": this.UI.ItemManager.ITEM_CODES.TYPE_EQUIPMENT
            });

            this.equipment.on(this.equipment.EVENT_CLICK, this.fctApplyFilter);//@
            this.addChild(this.equipment);
        }
        this.equipment.rX = [{on:"default", x: `${strPadding} * 3 + ${strOneFilterWidth} * 2`}];
        this.equipment.rY = [{on:"default", y: strPosY}];
        this.equipment.layout(size);

        if (!this.filters || this.filters.length == 0)
        {
            this.filters = [this.material, this.food, this.equipment];
        }

        this.food.onClick(null);

    }

    /*******************************************
    *   FILTER MANAGEMENT
    *******************************************/
    getFilter(type)
    {
        for (let i = 0; i < this.filters.length; i++)
        {
            if (this.filters[i].Type == type)
            {
                return this.filters[i];
            }
        }
        return null;
    }
    
    switchFilter(type)
    {
        if (this.CanSwitchFilter)
        {
            if (!this.currentFilter || this.currentFilter.type != type)
            {
                for (let i = 0; i < this.filters.length; i++)
                {
                    if (this.filters[i].type == type)
                    {
                        this.filters[i].onClick();
                    }
                }
            }
        }
    }

    changeFilterTo (type)
    {
        if (this.CanSwitchFilter)
        {
            let btnFilter;

            if (type === this.UI.ItemManager.ITEM_CODES.TYPE_MATERIAL)
            {
                btnFilter = this.material;
            }
            else if (type === this.UI.ItemManager.ITEM_CODES.TYPE_FOOD)
            {
                btnFilter = this.food;
            }
            else
            {
                btnFilter = this.equipment;
            }

            btnFilter.onClick(null);
         }

    }

    applyFilter (e)
    {
        if (this.CanSwitchFilter)
        {
            if (!e.active) //we unselect the filter
            {
                this.currentFilter = null;
            }
            else
            {
                if (this.currentFilter !== null)
                {
                    this.currentFilter.toggle();
                }
                this.currentFilter = e.filter;
            }

            this.applyFilterToInventory();
        }
    }

    applyFilterToInventory ()
    {
        let type = this.currentFilter !== null ? this.currentFilter.type : "";
        this.inventory.showPage(type);

    }

    /*******************************************
    *   INVENTORY MANAGEMENT
    *******************************************/
    populate ()
    {
        let items = this.UI.ItemManager.getAllItems(false);

        for (let index in items)
        {
            let slot = items[index];
            this.inventory.addItem(index, slot);
        }

        this.applyFilterToInventory();

    }

    updateInventory ()
    {
        this.inventory.removeAllItems();

        let items = this.UI.ItemManager.getAllItems(false);

        for (let index in items)
        {
            let slot = items[index];
            this.inventory.updateAt(index, slot);
        }
    }

    /*******************************************
    *   ACTIONS
    *******************************************/
    show()
    {
        this.populate();
        super.show();
    }

    /*******************************************
    *   EVENTS
    *******************************************/
    onItemAdded()
    {
        this.updateInventory();
    }
}