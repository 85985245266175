import BaseCheat from "./BaseCheat.js";

export default class ToolUse extends BaseCheat
{
    constructor(dependencies)
    {
        super(dependencies);
    }

    get CommandUse() { return "tool use"; }
    get CommandDesc() { return "Uses a tool equiped on the character"; }

    //---------------------------------------------------------
    //  DEPENDENCIES
    //---------------------------------------------------------
    get ItemManager() { return this.dependencies.get("ItemManager"); }
    get WorldManager() { return this.dependencies.get("WorldManager"); }
    //---------------------------------------------------------

    parseCommand(strCmd, arrArguments)
    {
        if (strCmd == "tool" && arrArguments.length >= 1 && arrArguments[0].toLowerCase() == "use")
        {
            if (this.ItemManager.CanTrap)
            {
                if (this.WorldManager.Player.putTrap())
                {
                    this.log("Put a trap on the ground");
                    return;
                }
                else
                {
                    this.warn("Cannot put a trap here");
                    return;
                }
            }
            else if (this.ItemManager.CanFish)
            {
                if (this.WorldManager.Player.startFishing())
                {
                    this.log("You start fishing");
                    return;
                }
                else
                {
                    this.warn("Cannot fish here");
                    return;
                }
            }

            this.warn("Cannot use a tool");
        }
    }
}