import Constants from "../../utils/Constants.js";
import ContainerResponsive from "../commons/responsive/ContainerResponsive.js";
import GraphicsResponsive from "../commons/responsive/GraphicsResponsive.js";
import Copydeck from "../../utils/Copydeck.js";

export default class BarnTutorial extends ContainerResponsive
{
    constructor()
    {
        super();
    }

    get LAST_TUTORIAL_STEP() { return parseInt(Constants.getValue("LAST_TUTORIAL_STEP")); }

    get Step() { return this.step; }
    get IsRunning() { return this.isRunning; }

    /*******************************************
    *   INITIALIZATION
    *******************************************/
    /**
        Parameters to pass to the init function:
        - ui:           UI section object where this component resides
        - callback      (Optional) Function to call when the tutorial has been cleared by the player
    */
    init(meta)
    {
        this.fctCallback = meta.callback;
        this.graphics = {};
        this.step = 1;
        this.isRunning = false;

        return super.init(meta);
    }

    createClosure()
    {
        super.createClosure();
        this.fctOnNotificationClose = this.onNotificationClose.bind(this);
    }

    bindEvents()
    {
        super.bindEvents();
    }

    destroy(options)
    {
        for (let key in this.graphics)
        {
            this.removeChild(this.graphics[key]);
            this.graphics[key].destroy(options);
        }
        delete this.graphics;

        if (this.notificationWindow)
        {
            this.notificationWindow.parent.removeChild(this.notificationWindow);
            this.notificationWindow.destroy(options);
            delete this.notificationWindow;
        }

        super.destroy(options);
    }

    /*******************************************
    *   BUILD
    *******************************************/
    build()
    {
        super.build();

        if (this.isRunning)
        {
            this.buildOverlay();
        }
    }

    clean()
    {
        for (let key in this.graphics)
        {
            this.graphics[key].clear();
        }
        super.clean();
    }

    buildOverlay()
    {
        if (!this.graphics.overlay)
        {
            this.graphics.overlay = new GraphicsResponsive().init({"ui": this.UI});
            this.addChildAt(this.graphics.overlay, 0);
        }

        this.graphics.overlay.beginFill(0, this.UI.Values.tutorial.overlay.opacity);
        this.graphics.overlay.drawRect(0, 0, this.UI.ResponsiveManager.Width, this.UI.ResponsiveManager.Height);
        this.graphics.overlay.endFill();

        this.graphics.overlay.interactive = true;
        this.graphics.overlay.buttonMode = false;
    }

    /*******************************************
    *   FLOW
    *******************************************/
    start()
    {
        this.isRunning = true;
        this.build();
        this.playStep();
    }

    stop()
    {
        this.isRunning = false;
        this.build();
    }

    /*******************************************
    *   STEPS
    *******************************************/
    playStep()
    {
        if (this.IsRunning)
        {
            this.UI.removeChild(this);
            this.UI.addChild(this);

            let explanations = Copydeck.getText( "TUTO_STEP_" + this.Step);

            if (this.Step == 1)
            {
                for (let i = 0; i < 3; i++)
                {
                    let id = "character" + i;

                    if (this.UI.buttons[id])
                    {
                        this.UI.removeChild(this.UI.buttons[id]);
                        this.UI.addChild(this.UI.buttons[id]);
                    }
                }
            }
            else if (this.Step == 2)
            {
                let gauges = [this.UI.gauges.food, this.UI.gauges.energy, this.UI.gauges.stress];

                for (let i = 0; i < gauges.length; i++)
                {
                    this.UI.removeChild(gauges[i]);
                    this.UI.addChild(gauges[i]);
                }
            }
            else if (this.Step == 3)
            {
                this.UI.removeChild(this.UI.gauges.food);
                this.UI.addChild(this.UI.gauges.food);
            }
            else if (this.Step == 4)
            {
                this.UI.removeChild(this.UI.gauges.energy);
                this.UI.addChild(this.UI.gauges.energy);
            }
            else if (this.Step == 5)
            {
                this.UI.removeChild(this.UI.gauges.stress);
                this.UI.addChild(this.UI.gauges.stress);
            }
            else if (this.Step == 6)
            {
                this.removeChild(this.graphics.overlay);
            }

            if (!this.notificationWindow)
            {
                this.notificationWindow = this.UI.Notifications.createMessageNotification(
                    this.UI.LabelManager.translate(explanations),
                    this.UI.Notifications.BackpackWindowWidth,
                    true
                );
                this.notificationWindow.on(this.notificationWindow.EVENT_CLOSE, this.fctOnNotificationClose);
            }
            this.notificationWindow.Message = this.UI.LabelManager.translate(explanations);
            this.positionNotificationWindow(this.notificationWindow);
        }
    }

    positionNotificationWindow(msgWindow)
    {
        let rX = "iw / 2 - " + this.UI.Notifications.BackpackWindowWidth + " / 2";
        let rY = "(" + this.UI.Notifications.WindowSpacing + " + " + msgWindow.BgHeight + ")";

        msgWindow.rX = [{on:"default", x: rX}];
        msgWindow.rY = [{on:"default", y: rY}];

        msgWindow.position.set(
            this.UI.evaluate(rX, 0, 0, 0, 0, msgWindow), 
            this.UI.evaluate(rY, 0, 0, 0, 0, msgWindow)
        );
    }

    /*******************************************
    *   EVENTS
    *******************************************/
    onNotificationClose()
    {
        this.UI.GameManager.emit(this.UI.GameManager.EVENT_TUTORIAL_STEP_COMPLETED, this.step);
        this.step++;

        if (this.step > this.LAST_TUTORIAL_STEP)
        {
            if (this.fctCallback)
            {
                this.fctCallback();
            }
        }
        else
        {
            this.playStep();
        }
    }
}