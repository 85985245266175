import { string2hex }  from '@pixi/utils'; 
import ContainerResponsive from "../responsive/ContainerResponsive.js";
import GraphicsResponsive from "../responsive/GraphicsResponsive.js";
import Library from "../../../Library.js";
import Responsive from "../responsive/Responsive.js";
import SpriteResponsive from "../responsive/SpriteResponsive.js";

export default class ActionButton extends ContainerResponsive
{
    constructor()
    {
        super();
    }

    get EVENT_CLICK() { return "self-click"; }

    get IsDisabled() { return this.disabled; }
    set IsDisabled(bNewValue) { this.disabled = (bNewValue) ? true : false; this.build(); }

    get CooldownRatio() { return this.cooldownRatio; }
    set CooldownRatio(fNewRatio) { this.cooldownRatio = Math.max(0, Math.min(1, fNewRatio)); this.buildBackgroundFill(); }

    get ButtonWidth() { return "(" + this.drawWidth + ")"; }
    set ButtonWidth(strNewWidth) { this.drawWidth = strNewWidth; this.build(); }

    get ButtonHeight() { return "(" + this.drawHeight + ")"; }
    set ButtonHeight(strNewHeight) { this.drawHeight = strNewHeight; this.build(); }

    get Icon() { return this.icon; }
    set Icon(strNewIcon) { this.icon = strNewIcon; this.build(); }

    get IconScale() { return this.iconScale; }
    set IconScale(fNewScale) { this.iconScale = fNewScale; this.build(); }

    get BackgroundColor() { return this.values.bgColor; }
    get StrokeWidth() { return this.values.strokeWidth; }
    get StrokeColor() { return this.values.strokeColor; }
    get CornerRadius() { return this.values.cornerRadius; }

    get DisabledOpacity() { return this.UI.Values.textbutton.disabled.opacity; }

    /*******************************************
    *   INITIALIZATION
    *******************************************/
    /**
        Parameters to pass to the init function:
        - ui:           UI section object where this component resides
        - textureName:  String ID of the texture to display inside the button
        - rWidth:       Responsive width equation to set for the ContainerResponsive functionnalities
        - rHeight:      Responsive height equation to set for the ContainerResponsive functionnalities
        - iconScale:    Scale to apply to the icon inside the button
        - disabled:     (Optional) If this button is disabled at start. Default is FALSE
        - tint:         (Optional) Tint to apply to this button. Default is 0xFFFFFF
    */
    init(meta)
    {
        this.drawWidth = meta.rWidth;
        this.drawHeight = meta.rWidth;
        this.icon = meta.textureName;
        this.iconScale = meta.iconScale;
        this.cooldownRatio = 0;
        this.values = {};
        this.colorTint = ("tint" in meta ? meta.tint : 0xFFFFFF);

        this.buttonScale = {"x": meta.ui.Values.textbutton.scale.normal, "y": meta.ui.Values.textbutton.scale.normal};
        this.disabled = ("disabled" in meta ? meta.disabled : false);

        return super.init(meta);
    }

    createClosure()
    {
        super.createClosure();

        this.fctOnClick = this.onClick.bind(this);
        this.fctOnPointerDown = this.onPointerDown.bind(this);
        this.fctOnWindowMouseUp = this.onWindowMouseUp.bind(this);
        this.fctOnWindowTouchEnd = this.onWindowTouchEnd.bind(this);
    }

    bindEvents()
    {
        super.bindEvents();

        window.addEventListener("mouseup", this.fctOnWindowMouseUp);
        window.addEventListener("touchend", this.fctOnWindowTouchEnd);
    }

    destroy(options)
    {
        window.removeEventListener("mouseup", this.fctOnWindowMouseUp);
        window.removeEventListener("touchend", this.fctOnWindowTouchEnd);

        this.graphics.off("pointerdown", this.fctOnPointerDown);
        this.graphics.off("pointerup", this.fctOnClick);

        super.destroy(options);
    }

    /*******************************************
    *   BUILD
    *******************************************/
    build()
    {
        super.build();
        this.calculateResponsiveValues();

        if (!this.graphics)
        {
            this.graphics = new ContainerResponsive().init({"ui": this.UI});
            this.graphics.interactive = true;
            this.graphics.buttonMode = true;
            this.graphics.on("pointerdown", this.fctOnPointerDown);//@
            this.graphics.on("pointerup", this.fctOnClick);//@

            this.graphics.bg = new GraphicsResponsive().init({"ui": this.UI});
            this.graphics.addChild(this.graphics.bg);

            this.graphics.fill = new GraphicsResponsive().init({"ui": this.UI});
            this.graphics.addChild(this.graphics.fill);

            this.graphics.maskGraphics = new GraphicsResponsive().init({"ui": this.UI});
            this.graphics.addChild(this.graphics.maskGraphics);
            this.graphics.mask = this.graphics.maskGraphics;

            this.addChild(this.graphics);
        }

        let bg = this.graphics.bg;
        let mask = this.graphics.maskGraphics;

        this.graphics.buttonMode = !this.IsDisabled;

        let width = this.ButtonWidth + " * " + this.buttonScale.x;
        let calculatedWidth = this.evaluate(width, 0, 0, 0, 0, this);
        let height = this.ButtonHeight + " * " + this.buttonScale.y;
        let calculatedHeight = this.evaluate(height, 0, 0, 0, 0, this);

        this.values.bgColor = this.BackgroundColor;
        this.values.width = calculatedWidth;
        this.values.height = calculatedHeight;

        let x = this.ButtonWidth + " * (1 - " + this.buttonScale.x + ") / 2";
        let y = this.ButtonHeight + " * (1 - " + this.buttonScale.y + ") / 2";

        mask.clear();
        bg.clear();
        bg.alpha = (this.IsDisabled ? this.DisabledOpacity : 1);

        this.graphics.rX = [{on:"default", "x": x}];
        this.graphics.rY = [{on:"default", "y": y}];

        if (this.StrokeWidth > 0)
        {
            bg.lineStyle({"color":this.StrokeColor, "width":this.StrokeWidth, "alignment":1});
            mask.lineStyle({"color":this.StrokeColor, "width":this.StrokeWidth, "alignment":1});
        }
        bg.beginFill(this.values.bgColor, 1);
        mask.beginFill(this.values.bgColor, 1);

        if (this.CornerRadius > 0)
        {
            bg.drawRoundedRect(0, 0, calculatedWidth, calculatedHeight, this.CornerRadius);
            mask.drawRoundedRect(0, 0, calculatedWidth, calculatedHeight, this.CornerRadius);
        }
        else
        {
            bg.drawRect(0, 0, calculatedWidth, calculatedHeight);
            mask.drawRect(0, 0, calculatedWidth, calculatedHeight);
        }

        bg.endFill();
        mask.endFill();

        this.buildBackgroundFill();
        this.buildSprite(calculatedWidth, calculatedHeight, this.graphics);
    }

    buildBackgroundFill()
    {
        let fill = this.graphics.fill;

        fill.clear();
        fill.beginFill(this.values.bgColor, 1);


        let y = this.values.height * this.CooldownRatio;
        let height = this.values.height * (1 - this.CooldownRatio);

        fill.drawRect(0, y, this.values.width, height);
        fill.endFill();
    }

    buildSprite(fWidth, fHeight, objParent)
    {
        if (!objParent.iconSprite)
        {
            objParent.iconSprite = new SpriteResponsive(Library.getTextureFromAtlas("ui", this.Icon)).init({"ui": this.UI});
            objParent.iconSprite.tint = this.colorTint;
            objParent.addChild(objParent.iconSprite);
        }

        let sprite = objParent.iconSprite;

        let size = fWidth * this.IconScale;
        let ratio = sprite.height / sprite.width;
        let width = size;
        let height = width * ratio;

        if (sprite.width < sprite.height)
        {
            size = fHeight * this.IconScale;
            ratio = sprite.width / sprite.height;
            height = size;
            width = height * ratio;
        }

        let x = fWidth / 2 - width / 2;
        let y = fHeight / 2 - height / 2;

        sprite.width = width;
        sprite.height = height
        sprite.alpha = (this.IsDisabled ? this.DisabledOpacity : 1);

        sprite.position.set(x, y);
    }

    clean()
    {
        super.clean();
        if (this.graphics)
        {
            this.graphics.bg.clear();
            this.graphics.fill.clear();
            this.graphics.maskGraphics.clear();
        }
    }

    changeTexture (strTexture)
    {
        let texture = Library.getTextureFromAtlas("ui", strTexture);
        this.graphics.iconSprite.texture = texture;
    }

    updateVisuals()
    {
        this.build();

        this.removeChild(this.graphics);
        this.addChild(this.graphics);
    }

    /*******************************************
    *   RESPONSIVE
    *******************************************/
    calculateResponsiveValues()
    {
        this.values.bgColor =  string2hex(this.UI.Values.modal.close.background.color);
        this.values.strokeWidth =  this.evaluate(this.valueFormula(this.UI.Values.modal.close.stroke.size), 0, 0, 0, 0, this);
        this.values.strokeColor =  string2hex("color" in this.UI.Values.modal.close.stroke ? this.UI.Values.modal.close.stroke.color : "#000000");
        this.values.cornerRadius = this.evaluate(this.valueFormula(this.UI.Values.modal.close.corner.radius), 0, 0, 0, 0, this);
    }

    /*******************************************
    *   EVENTS
    *******************************************/
    onClick()
    {
        if (!this.IsDisabled)
        {
            this.buttonScale.x = this.UI.Values.textbutton.scale.normal;
            this.buttonScale.y = this.UI.Values.textbutton.scale.normal;
            this.updateVisuals();

            this.emit(this.EVENT_CLICK, this);
        }
    }

    onPointerDown()
    {
        if (!this.IsDisabled)
        {
            this.buttonScale.x = this.UI.Values.textbutton.scale.down;
            this.buttonScale.y = this.UI.Values.textbutton.scale.down;
            this.updateVisuals();
        }
    }

    onWindowMouseUp(e)
    {
        this.buttonScale.x = this.UI.Values.textbutton.scale.normal;
        this.buttonScale.y = this.UI.Values.textbutton.scale.normal;
        this.updateVisuals();
    }

    onWindowTouchEnd(e)
    {
        if (e.touches.length == 0)
        {
            this.buttonScale.x = this.UI.Values.textbutton.scale.normal;
            this.buttonScale.y = this.UI.Values.textbutton.scale.normal;
            this.updateVisuals();
        }
    }
}