import { Point } from '@pixi/math';
import { TextStyle } from "@pixi/text";
import { TextMetrics } from "@pixi/text";
import BaseMinigame from "../BaseMinigame.js";
import Display from "../../../utils/Display.js";
import GraphicsResponsive from "../../commons/responsive/GraphicsResponsive.js";
import HackingCell from "./components/HackingCell.js";
import HackingSequence from "./components/HackingSequence.js";
import Library from "../../../Library.js";
import SpriteResponsive from "../../commons/responsive/SpriteResponsive.js";
import TextResponsive from "../../commons/responsive/TextResponsive.js";

export default class HackingMinigame extends BaseMinigame
{


    get GRID_WIDTH() { return 3; }
    get GRID_HEIGHT() { return 3; }

    get Id() { return HackingMinigame.TYPE_HACKING;}
    get BoardTextureId() { return this.Id + "_board_top.png"; }
    get BoardBottomTextureId() { return this.Id + "_board_bottom.png"; }
    get TotalCount() { return Math.max(1, Math.min(this.GRID_WIDTH * this.GRID_HEIGHT, this.Balancing.segmentCount)); }
    get RemainingCount() { return Math.max(0, this.TotalCount - this.answerCount); }
    get TimePenalty() { return this.Balancing.timePenalty; }

    get BgWidth() { return "(" + this.BgHeight + " * 1.2)"; }
    get TopBgHeight() { return this.topBgHeight; }
    get BottomBgHeight() { return this.bottomBgHeight; }
    get HackingZoneHeight() { return this.hackingZoneHeight; }
    get HackingZoneY() { return "(" + this.BgY + " + " + this.TopBgHeight + ")"; }

    get TargetTitleWidth() { return "(" + this.BgWidth + " * 0.2)"; }
    get TargetTitleX() { return this.BgX; }
    get TargetCellWidth() { return this.TitleZoneHeight + " * (" + this.CellWidth + " / " + this.CellHeight + ")"; }
    get TargetCellX() { return "(" + this.BgX + " + " + this.BgWidth + " * 0.225)"; }
    get RemainingTitleWidth() { return "(" + this.BgWidth + " * 0.2)";}
    get RemainingTitleX() { return "(" + this.BgX + " + " + this.BgWidth + " * 0.475)";}
    get RemainingCellWidth() { return "(" + this.TargetCellWidth + " * 0.85)"; }
    get RemainingCellX() { return "(" + this.RemainingTitleX + " + " + this.BgWidth + " * 0.225)"; }

    get TitleZoneY() { return "(" + this.TimerY + " + " + this.TimerHeight + ")"; }
    get TitleZoneHeight() { return "(" + this.TopBgHeight + " - " + "(" + this.BgY + " - " + this.TimerY + ")" + ")"; }
    get TitleCellHeight() { return "(" + this.TitleZoneHeight + " * 0.8)"; }

    get CellWidth() { return "(" + this.BgWidth + " / " + this.GRID_WIDTH + ")"; }
    get CellHeight() { return "(" + this.HackingZoneHeight + " / " + this.GRID_HEIGHT + ")"; }
    get CellCornerRadius() { return this.CornerRadius; }

    get CloseButtonX() { return this.BgX + " + " + this.BgWidth + " - " + this.closeButton.ButtonWidth + " / 2"; }
    get CloseButtonY() { return (this.BgY + " + " + this.closeButton.ButtonHeight + " / 2"); }
    get CloseButtonScale() { return 0.66; }

    get TimerY() { return "(" + this.BgY + " - " + this.TimerHeight + " * 0.4)"; }

    get TitleFontSize() { return this.evaluate(this.valueFormula(this.UI.Values.general.title.size * 1.25)); }
    get RemainingBackColor() { return 0x0E0E0E; }
    get RemainingCellColor() { return 0x2C6247; }
    get RemainingCellLightColor() { return 0x62F8AC; }

    /*******************************************
    *   INITIALIZATION
    *******************************************/
    /**
        Parameters to pass to the init function:
        - ui:           UI section object where this component resides
        - difficulty:   Difficulty to set the game to. The value should fit the difficulty levels set in the settings
        - showOnStart   (Optional) If the popup should be shown on start. Default is TRUE
        - createOverlay (Optional) If this popup should create it's own overlay for itself. If not, it will use the general UI overlay. Default is FALSE
        - linked        (Optional) Linked popup to this one. If this one closes, the linked popup will close too. Default is NULL
    */
    init(meta)
    {
        this.answerCount = 0;
        this.answerIndex = 0;
        this.actionDelay = [];
        this.sequence = null;
        this.cells = [];

        super.init(meta);


        this.showMessage(
            this.UI.LabelManager.translate("MINIGAME_HACKING_INTRO"),
            this.UI.LabelManager.translate("MINIGAME_BTN_START")
        );

        return this;
    }

    createClosure()
    {
        super.createClosure();
        this.fctOnCellClick = this.onCellClick.bind(this);
    }

    destroy(options)
    {
        for (let i = 0; i < this.cells.length; i++)
        {
            for (let j = 0; j < this.cells[i].length; j++)
            {
                this.removeChild(this.cells[i][j]);
                this.cells[i][j].off(this.cells[i][j].EVENT_CLICK, this.fctOnCellClick);
                this.cells[i][j].destroy(options);
            }
        }
        delete this.cells;

        if (this.targetCell)
        {
            this.removeChild(this.targetCell);
            this.targetCell.destroy(options);
            delete this.targetCell;
        }

        super.destroy(options);
    }

    /*******************************************
    *   BUILD
    *******************************************/
    build()
    {
        super.build();

        this.buildCells();
        this.buildTargetTitle();
        this.buildTargetCell();
        this.buildRemainingTitle();
        this.buildRemainingCells();
    }

    buildBackground ()
    {
        if (!this.sprites.background)
        {
            let texture = Library.getTextureFromAtlas(this.AtlasId, this.BoardTextureId);
            this.sprites.background = new SpriteResponsive(texture).init({"ui": this.UI});
            this.sprites.background.ratio = texture.orig.height / texture.orig.width;

            this.addChild(this.sprites.background);
        }

        if (!this.sprites.backgroundBottom)
        {
            let texture = Library.getTextureFromAtlas(this.AtlasId, this.BoardBottomTextureId);
            this.sprites.backgroundBottom = new SpriteResponsive(texture).init({"ui": this.UI});
            this.sprites.backgroundBottom.ratio = texture.orig.height / texture.orig.width;

            this.addChild(this.sprites.backgroundBottom);
        }

        let background = this.sprites.background;
        let backgroundBottom = this.sprites.backgroundBottom;

        let width = this.evaluate(this.BgWidth, 0, 0, 0, 0);
        let rTopHeight = "(" + this.BgWidth + " * " + background.ratio + ")";
        let topHeight = this.evaluate(rTopHeight, 0, 0, 0, 0);
        let rBottomHeight = "(" + this.BgWidth + " * " + backgroundBottom.ratio + ")";
        let bottomHeight = this.evaluate(rBottomHeight, 0, 0, 0, 0);
        let x = this.evaluate(this.BgX, 0, 0, 0, 0);
        let y = this.evaluate(this.BgY, 0, 0, 0, 0);

        this.hackingZoneHeight = "(" + this.BgHeight + " - (" + rTopHeight + " + " + rBottomHeight + "))";
        this.topBgHeight = rTopHeight;
        this.bottomBgHeight = rBottomHeight;

        background.width = width;
        background.height = topHeight;
        background.x = x;
        background.y = y;

        backgroundBottom.width = width;
        backgroundBottom.height = bottomHeight;
        backgroundBottom.x = x;
        backgroundBottom.y = y + this.evaluate(this.BgHeight, 0, 0, 0, 0) - bottomHeight;
    }

    buildCells()
    {
        let cellWidth = this.CellWidth;
        let cellHeight = this.CellHeight;
        let cellCorner = this.CellCornerRadius;

        if (this.cells.length == 0)
        {
            for (let i = 0; i < this.GRID_HEIGHT; i++)
            {
                let row = [];
                for (let j = 0; j < this.GRID_WIDTH; j++)
                {
                    let lines = (this.sequence ? this.sequence.Cells[i][j] : []);

                    let newCell = new HackingCell().init({
                        "ui": this.UI,
                        "minigameId": this.Id,
                        "atlasId": this.AtlasId,
                        "position": new Point(j, i),
                        "lines": lines,
                        "maxLines": this.Balancing.linesPerBlock.max,
                        "maxChars": this.Balancing.charactersPerLine.max,
                        "rWidth": cellWidth,
                        "rHeight": cellHeight,
                        "rCorner": cellCorner
                    });
                    newCell.CanClick = lines.length > 0;
                    newCell.on(newCell.EVENT_CLICK, this.fctOnCellClick);
                    this.addChild(newCell);

                    if (lines.length > 0)
                    {
                        if ((this.answerCount == 0 && this.Balancing.animateOnStart) || (this.answerCount > 0 && this.Balancing.animateOnScreenChange))
                        {
                            newCell.animate(Math.random() * 0.66 + 0.5);
                        }
                    }

                    row.push(newCell);

                }
                this.cells.push(row);
            }
        }

        let size = Display.getSize();
        for (let i = 0; i < this.cells.length; i++)
        {
            for (let j = 0; j < this.cells[i].length; j++)
            {   
                this.cells[i][j].rX = [{on:"default", x: this.BgX + " + " + cellWidth + " * " + j}];
                this.cells[i][j].rY = [{on:"default", y: this.HackingZoneY + " + " + cellHeight + " * " + i}];
                this.cells[i][j].layout(size);
            }
        }
    }

    buildTargetTitle()
    {
        if (!this.texts.targetTitle)
        {
            this.texts.targetTitle = new TextResponsive().init({"ui": this.UI});
            this.addChild(this.texts.targetTitle);
        }

        let text = this.texts.targetTitle;
        let label = this.UI.LabelManager.translate("MINIGAME_HACKING_TARGET").toUpperCase();

        let width = this.evaluate(this.TargetTitleWidth, 0, 0, 0, 0);
        let height = this.evaluate(this.TitleZoneHeight, 0, 0, 0, 0);
        let x = this.evaluate(this.TargetTitleX, 0, 0, 0, 0);
        let y = this.evaluate(this.TitleZoneY, 0, 0, 0, 0);

        let style = new TextStyle({
            fontFamily: this.TitleFont,
            fontSize:   this.TitleFontSize,
            fontWeight: this.TitleFontWeight,
            fill: this.TitleColor,
            align: "right",
            wordWrap: true,
            wordWrapWidth: width,
            lineHeight: this.TitleFontSize * 1.25
        });

        let metrics = TextMetrics.measureText(label, style);

        text.text = label;
        text.style = style;
        text.width = metrics.width;
        text.height = metrics.height;
        text.x = x + width - metrics.width;
        text.y = y + height / 2 - metrics.height / 2;
    }

    buildTargetCell()
    {
        let lines = (this.sequence ? this.sequence.Answers[this.answerIndex] : null);
        lines = (lines ? this.sequence.Cells[lines[1]][lines[0]] : []);

        let height = this.evaluate(this.TitleCellHeight, 0, 0, 0, 0);
        let cellHeight = this.evaluate(this.CellHeight, 0, 0, 0, 0);
        let scale = height / cellHeight;

        if (!this.targetCell)
        {
            this.targetCell = new HackingCell().init({
                "ui": this.UI,
                "minigameId": this.Id,
                "atlasId": this.AtlasId,
                "position": new Point(-1, -1),
                "lines": lines,
                "maxLines": this.Balancing.linesPerBlock.max,
                "maxChars": this.Balancing.charactersPerLine.max,
                "rWidth": this.CellWidth,
                "rHeight": this.CellHeight,
                "rCorner": this.CellCornerRadius,
                "border": true
            });
            this.addChild(this.targetCell);
        }
        else
        {
            this.targetCell.Lines = lines;
        }

        this.targetCell.rX = [{on:"default", x: this.TargetCellX}];
        this.targetCell.rY = [{on:"default", y: this.TitleZoneY + " + " + this.TitleZoneHeight + " / 2 * " + scale + " - " + this.TitleCellHeight + " / 2 * " + scale}];
        this.targetCell.layout(Display.getSize());

        this.targetCell.scale.x = scale;
        this.targetCell.scale.y = scale;
    }

    buildRemainingTitle()
    {
        if (!this.texts.remaining)
        {
            this.texts.remaining = new TextResponsive().init({"ui": this.UI});
            this.addChild(this.texts.remaining);
        }

        let remaining = this.RemainingCount;
        let text = this.texts.remaining;
        let label = this.UI.LabelManager.translate("MINIGAME_HACKING_REMAINING_" + (remaining <= 1 ? "SINGULAR" : "PLURAL"));
        label = label.replace("{0}", remaining).toUpperCase();

        let width = this.evaluate(this.RemainingTitleWidth, 0, 0, 0, 0);
        let height = this.evaluate(this.TitleZoneHeight, 0, 0, 0, 0);
        let x = this.evaluate(this.RemainingTitleX, 0, 0, 0, 0);
        let y = this.evaluate(this.TitleZoneY, 0, 0, 0, 0);

        let style = new TextStyle({
            fontFamily: this.TitleFont,
            fontSize:   this.TitleFontSize,
            fontWeight: this.TitleFontWeight,
            fill: this.TitleColor,
            align: "right",
            wordWrap: true,
            wordWrapWidth: width,
            lineHeight: this.TitleFontSize * 1.25
        });

        let metrics = TextMetrics.measureText(label, style);

        text.text = label;
        text.style = style;
        text.width = metrics.width;
        text.height = metrics.height;
        text.x = x + width - metrics.width;
        text.y = y + height / 2 - metrics.height / 2;
    }

    buildRemainingCells()
    {
        if (!this.graphics.remaining)
        {
            this.graphics.remaining = new GraphicsResponsive().init({"ui": this.UI});
            this.addChild(this.graphics.remaining);
        }

        let graphics = this.graphics.remaining;

        let scale = this.evaluate(this.TitleCellHeight + " / " + this.CellHeight, 0, 0, 0, 0);
        let width = this.evaluate(this.RemainingCellWidth, 0, 0, 0, 0);
        let height = this.evaluate(this.TitleCellHeight, 0, 0, 0, 0);
        let x = this.RemainingCellX;
        let y = this.TitleZoneY + " + " + this.TitleZoneHeight + " / 2 * " + scale + " - " + this.TitleCellHeight + " / 2 * " + scale;

        let margin = height * 0.025;
        let cellWidth = width / this.GRID_WIDTH;
        let cellHeight = height / this.GRID_HEIGHT;

        let cornerRadius = this.evaluate(this.CornerRadius + " * " + scale, 0, 0, 0, 0);
        let backColor = this.RemainingBackColor;
        let cellColor = this.RemainingCellColor;
        let lightColor = this.RemainingCellLightColor;

        graphics.beginFill(backColor, 1);
        if (cornerRadius > 0)
        {
            graphics.drawRoundedRect(0, 0, width, height, cornerRadius);
        }
        else
        {
            graphics.drawRect(0, 0, width, height);
        }
        graphics.endFill();

        for (let i = 0; i < this.GRID_HEIGHT; i++)
        {
            for (let j = 0; j < this.GRID_WIDTH; j++)
            {
                if (i * this.GRID_HEIGHT + j < this.TotalCount)
                {
                    graphics.beginFill((i * this.GRID_HEIGHT + j < this.answerCount ? lightColor : cellColor));
                    if (cornerRadius > 0)
                    {
                        graphics.drawRoundedRect(
                            j * cellWidth + margin / 2,
                            i * cellHeight + margin / 2,
                            cellWidth - margin,
                            cellHeight - margin,
                            cornerRadius
                        );
                    }
                    else if (cornerRadius > 0)
                    {
                        graphics.drawRect(
                            j * cellWidth + margin / 2,
                            i * cellHeight + margin / 2,
                            cellWidth - margin,
                            cellHeight - margin
                        );
                    }
                    graphics.endFill();
                }
            }
        }

        graphics.rX = [{on:"default", x}];
        graphics.rY = [{on:"default", y}];
        graphics.layout(Display.getSize());
    }

    /*******************************************
    *   ACTIONS
    *******************************************/
    clickOnCell(objCell)
    {
        for (let i = 0; i < this.cells.length; i++)
        {
            for (let j = 0; j < this.cells[i].length; j++)
            {   
                this.cells[i][j].IsSelected = false;
                this.cells[i][j].CanClick = false;
            }
        }
        objCell.IsSelected = true;

        let answer = this.sequence.Answers[this.answerIndex];
        let waitTime = 0;

        if (answer[0] == objCell.Position.x && answer[1] == objCell.Position.y)
        {
            objCell.IsGood = true;
            waitTime = objCell.GOOD_TIME;
            this.answerCount++;
        }
        else
        {
            objCell.setBad();
            waitTime = objCell.BAD_TIME;
            this.timer.substractTime(this.TimePenalty);
        }

        this.build();

        this.actionDelay.push({
            "timeLeft": waitTime,
            "fct": function(objCell)
            {
                if (objCell.IsGood)
                {
                    this.answerIndex++;
                    if (this.RemainingCount <= 0)
                    {
                        this.winGame();
                        return;
                    }
                    else if (this.answerIndex >= this.Balancing.answerPerScreen)
                    {
                        this.answerIndex = 0;
                        this.sequence = this.generateNewSequence(Math.min(this.RemainingCount, this.Balancing.answerPerScreen));
                    }
                    else
                    {
                        objCell.IsDone = true;
                    }
                }

                for (let i = 0; i < this.cells.length; i++)
                {
                    for (let j = 0; j < this.cells[i].length; j++)
                    {   
                        this.cells[i][j].CanClick = true;
                    }
                }

                this.build();
            }
            .bind(this, objCell)
        });
    }

    winGame()
    {
        this.timer.stopTimer();
        for (let i = 0; i < this.cells.length; i++)
        {
            for (let j = 0; j < this.cells[i].length; j++)
            {   
                this.cells[i][j].CanClick = false;
                this.cells[i][j].IsSelected = false;
                this.cells[i][j].IsDone = false;
                this.cells[i][j].IsGood = true;
            }
        }
        this.build();

        this.actionDelay.push({
            "timeLeft": 1,
            "fct": function()
            {
                this.success = true;
                this.onCloseClick();
            }
            .bind(this),
        });
    }

    looseGame()
    {
        this.actionDelay = [];
        for (let i = 0; i < this.cells.length; i++)
        {
            for (let j = 0; j < this.cells[i].length; j++)
            {   
                this.cells[i][j].CanClick = false;
                this.cells[i][j].IsSelected = false;
                this.cells[i][j].IsDone = false;
                this.cells[i][j].IsGood = false;
                this.cells[i][j].setBad(1);
            }
        }
        this.build();

        this.actionDelay.push({
            "fct": this.onCloseClick.bind(this),
            "timeLeft": 1
        });
    }

    /*******************************************
    *   SEQUENCE MANAGEMENT
    *******************************************/
    generateNewSequence(iAnswerCount)
    {
        for (let i = 0; i < this.cells.length; i++)
        {
            for (let j = 0; j < this.cells[i].length; j++)
            {   
                this.removeChild(this.cells[i][j]);
                this.cells[i][j].destroy({"children": true});
            }
        }
        this.cells = [];

        let sequence = new HackingSequence(this.Balancing);
        sequence.randomize(this.GRID_HEIGHT, this.GRID_WIDTH, iAnswerCount);

        return sequence;
    }

    /*******************************************
    *   UPDATE LOOP
    *******************************************/
    update(fDeltaTime)
    {
        super.update(fDeltaTime);

        this.updateCells(fDeltaTime);
        this.updateActionDelay(fDeltaTime);
    }

    updateCells(fDeltaTime)
    {
        for (let i = 0; i < this.cells.length; i++)
        {
            for (let j = 0; j < this.cells[i].length; j++)
            {   
                this.cells[i][j].update(fDeltaTime);
            }
        }
    }

    updateActionDelay(fDeltaTime)
    {
        for (let i = this.actionDelay.length - 1; i >= 0; i--)
        {
            this.actionDelay[i].timeLeft -= fDeltaTime;
            if (this.actionDelay[i].timeLeft <= 0)
            {
                this.actionDelay[i].fct();
                this.actionDelay.splice(i, 1);
            }
        }
    }

    /*******************************************
    *   EVENTS
    *******************************************/
    onMessageButtonClick()
    {
        super.onMessageButtonClick();
        this.sequence = this.generateNewSequence(this.Balancing.answerPerScreen);
        this.build();

        this.actionDelay.push({
            "timeLeft": 1,
            "fct": function()
            {
                this.timer.startTimer(this.TotalTime);
            }
            .bind(this),
        });
    }

    onCellClick(sender)
    {
        this.clickOnCell(sender);
    }

    onTimerEnd(sender)
    {
        super.onTimerEnd();
        this.looseGame();
    }
}