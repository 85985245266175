import { Graphics } from '@pixi/graphics';
import { Text } from '@pixi/text';
import { TextStyle } from '@pixi/text';
import { TextMetrics } from '@pixi/text';
import BaseComponent from "./BaseComponent.js";
import Constants from "../../utils/Constants.js";
import Display from "../../utils/Display.js";

export default class Button extends BaseComponent
{
    constructor()
    {
        super();
    }


    /*******************************************
    *   INITIALIZATION
    *******************************************/
    /**
        Parameters to pass to the init function:
        - ui:   UI section object where this component resides
        - text: Text to display inside the button
    */
    init(meta)
    {
        this.strText = meta.text;
        this.interactive = this.buttonMode = this.accessible = true;

        return super.init(meta);
    }

    destroy(options)
    {
        this.removeChild(this.bg);
        this.removeChild(this.label);

        this.bg.destroy(options);
        this.label.destroy(options);

        super.destroy(options);
    }


    /*******************************************
    *   BUILD
    *******************************************/
    build ()
    {
        super.build();

        this.buildStyle();

        if (!this.bg)
        {
            this.bg = new Graphics();
            this.addChild(this.bg);
        }
        else
        {
            this.bg.clear();
        }

        if (!this.label)
        {
            this.label = new Text(this.strText, this.style);
            this.addChild(this.label);
        }
        else
        {
            this.label.text = this.strText;
            this.label.style = this.style;
        }
        this.bg.beginFill(Constants.getValue("BUTTON_BG_COLOR", "parseHex"));

        this.bg.drawRect(0,0, 50, 50);
        this.bg.endFill();

        this.layout();
    }

    buildStyle ()
    {
        this.styleOptions = { fontSize : Constants.getValue("BUTTON_FONT_SIZE", "parseAsInt") }; //we might want to change this value responsively

        this.style = new TextStyle({
            fill: Constants.getValue("BUTTON_TEXT_COLOR", "parseHex"),
            fontFamily: Constants.getValue("BUTTON_TEXT_FONT_FAMILY"),
            fontSize: this.styleOptions.fontSize,
            fontWeight: Constants.getValue("BUTTON_TEXT_FONT_WEIGHT", "parseAsInt")
        });
    }

    /*******************************************
    *   RESPONSIVE
    *******************************************/
    layout (objSize)
    {
        super.layout(objSize);

        if (!objSize)
        {
            objSize = this.UI.Size;
        }

        //@TODO Clamp value. Le font size devrait pas devenir trop petit, ni trop grande
        let textMetrics = TextMetrics.measureText(this.strText, this.style)

        let padding = Constants.getValue("BUTTON_TEXT_PADDING", "parseAsFloat");
        this.bg.width = textMetrics.width + ( padding* 2);
        this.bg.height = textMetrics.height + (padding * 2);

        this.label.x = padding;
        this.label.y = padding;
    }
}