import Library from "../Library.js";

export default class Copydeck
{
    constructor ()
    {
        if (Copydeck.instance)
        {
            throw("You can't instantiate Copydeck")
        }

        Copydeck.instance = this;
    }

    static init ()
    {
        Copydeck.instance.initData();
    }

    initData ()
    {
        //All values are based on a width of BASE_WIDTH  (1920px)
        //Loaded from the constants.json file
        this.copydeck = Library.getData("copydeck");

    }

    static getText (strKey)
    {
        strKey = strKey.toUpperCase();
        return Copydeck.instance.copydeck[strKey] ? Copydeck.instance.copydeck[strKey] : "__" + strKey + "__";
    }

}

let __copydeck__ = new Copydeck();