import {Mesh} from "three";
import {MeshBasicMaterial} from "three";
import {PlaneGeometry} from "three";
import {TextureLoader} from "three";
import Library from "../../Library.js";
import Lerp from "../../utils/Lerp.js";
import DependencyContainer from "../../utils/DependencyContainer.js";

export default class MapMoveIcon
{
    constructor(objEnvironment)
    {
        this.environment = objEnvironment;
        this.dependencies = DependencyContainer.getInstance();
        this.time = 0;
    }

    static get ANIMATION_TIME() { return 1.25; }
    static get ATLAS_NAME() { return "misc3D"; }
    static get CIRCLE_SPRITE_NAME() { return "move_icon_circle.png"; }
    static get CIRCLE_SIZE_DIVIDER() { return 20; }
    static get ARROW_SPRITE_NAME() { return "move_icon_arrow.png"; }
    static get ARROW_SIZE_DIVIDER() { return 17; }

    //---------------------------------------------------------
    //  DEPENDENCIES
    //---------------------------------------------------------
    get ResponsiveManager() { return this.Dependencies.get("ResponsiveManager"); }
    //---------------------------------------------------------

    get Dependencies() { return this.dependencies; }
    get Environment() { return this.environment; }
    get Scene() { return this.Environment.Scene; }
    get Camera() { return this.Scene.Camera; }

    /*******************************************
    *   INITIALIZATION
    *******************************************/
    init()
    {
        this.createClosure();
        this.bindEvents();

        this.createVisuals();
    }

    createClosure()
    {

    }

    bindEvents()
    {

    }

    destroy()
    {
        for (let key in this.meshes)
        {
            this.Scene.remove(this.meshes[key]);
        }
        this.meshes = null;
    }

    createVisuals()
    {
        let assetSize = this.ResponsiveManager.AssetSize;

        this.meshes = {};
        this.createMesh("circle", MapMoveIcon.CIRCLE_SPRITE_NAME, MapMoveIcon.CIRCLE_SIZE_DIVIDER * assetSize);
        this.createMesh("arrow", MapMoveIcon.ARROW_SPRITE_NAME, MapMoveIcon.ARROW_SIZE_DIVIDER * assetSize);
    }

    createMesh(strKey, strTextureName, iDivider)
    {
        let atlasName = MapMoveIcon.ATLAS_NAME;
        let texture = Library.getTexture3D(atlasName, strTextureName);
        let size = Library.getTexture3DSize(atlasName, strTextureName);
        size = {width: size.w / iDivider, height: size.h / iDivider};

        let material = this.createMaterial(texture);
        let geometry = this.createGeometry(size.width, size.height);

        let mesh = new Mesh(geometry, material);
        mesh.size = size;

        this.Scene.add(mesh);
        this.meshes[strKey] = mesh;

        if (strKey == "circle")
        {
            mesh.rotation.x = -Math.PI / 2;
        }

        this.setVisible(false);

    }

    createMaterial(objTexture)
    {
        return new MeshBasicMaterial({
            map:         objTexture, 
            transparent: true, 
            wireframe:   false, 
            depthWrite:  false
        });
    }

    createGeometry(width, height) 
    {
        let geometry = new PlaneGeometry(width, height, 10, 10);
        geometry.translate(0, height / 2, 0);
        geometry.verticesNeedUpdate = true;

        return geometry;
    }

    /*******************************************
    *   UPDATE LOOP
    *******************************************/
    update(fDeltaTime)
    {
        this.updateCameraRotation();
        this.updateAnimation(fDeltaTime);
    }

    updateCameraRotation()
    {
        if (this.meshes && this.meshes.arrow)
        {
            let cameraRotation = this.Camera.rotation;

            this.meshes.arrow.rotation.x = cameraRotation.x;
            this.meshes.arrow.rotation.y = cameraRotation.y;
            this.meshes.arrow.rotation.z = cameraRotation.z;
        }
    }

    updateAnimation(fDeltaTime)
    {
        if (this.meshes && this.meshes.circle && this.meshes.arrow && this.meshes.arrow.visible)
        {
            this.time += fDeltaTime;
            let t = (this.time % MapMoveIcon.ANIMATION_TIME) / MapMoveIcon.ANIMATION_TIME;
            t = Math.sin(t * Math.PI);

            this.meshes.arrow.position.set(
                this.meshes.arrow.position.x,
                this.meshes.arrow.position.y,
                Lerp.lerp(this.arrowPos.z, this.arrowPos.z - 1, t)
            );
        }
    }

    /*******************************************
    *   MANIPULATIONS ON SCREEN
    *******************************************/
    setVisible(bIsVisible)
    {
        bIsVisible = bIsVisible && !this.PreventDisplay;
        for (let key in this.meshes)
        {
            this.meshes[key].visible = bIsVisible;
        }
    }

    moveTo(iX, iZ)
    {
        if (this.meshes.circle && this.meshes.arrow)
        {
            this.arrowPos = {
                "x": iX,
                "z": iZ - this.meshes.circle.size.height / 2
            };

            this.meshes.circle.position.set(iX, 0.2, iZ);
            this.meshes.arrow.position.set(this.arrowPos.x, 0.1, this.arrowPos.z);
        }
    }
}