import { string2hex }  from '@pixi/utils'; 
import ActionButton from "../buttons/ActionButton.js";
import BasePopup from "../BasePopup.js";
import CharacterViewer from "../characters/CharacterViewer.js";
import CloseButton from "../buttons/CloseButton.js";
import ColoredActionButton from "../buttons/ColoredActionButton.js";
import ContainerDragger from "../ContainerDragger.js";
import Display from "../../../utils/Display.js";
import EquipmentSlot from "../inventory/EquipmentSlot.js";
import GraphicsResponsive from "../responsive/GraphicsResponsive.js";
import InventoryGrid from "../inventory/InventoryGrid.js";
import Lerp from "../../../utils/Lerp.js";

export default class BackpackPopup extends BasePopup
{
    constructor()
    {
        super();
    }

    /*******************************************
    *   INITIALIZATION
    *******************************************/
    /**
        Parameters to pass to the init function:
        - ui:           UI section object where this component resides
        - clickToHide:  (Optional) If the popup should hide when the player clicks on the overlay behind. Default is TRUE
        - showOnStart   (Optional) If the popup should be shown on start. Default is TRUE
        - showClose:    (Optional) If the close button should be shown. Default is TRUE
        - createOverlay (Optional) If this popup should create it's own overlay for itself. If not, it will use the general UI overlay. Default is FALSE
        - sideBySide    (Optional) If this popup should be positioned side-by-side with other popups on screen. Default is TRUE
        - linked        (Optional) Linked popup to this one. If this one closes, the linked popup will close too. Default is NULL
    */
    init(meta)
    {
        this.showClose = ("showClose" in meta ? meta.showClose : true);
        this.equipSlots = [];
        this.graphics = {};

        meta.title = meta.ui.LabelManager.translate("INVE_SACADOS").toUpperCase();

        return super.init(meta);
    }

    createClosure ()
    {
        super.createClosure();

        this.fctOnCloseClick = this.onCloseClick.bind(this);
        this.fctOnItemAdded = this.onItemAdded.bind(this);
        this.fctOnDisplaySlotsChanged = this.onDisplaySlotsChanged.bind(this);
        this.fctOnDragStart = this.onDragStart.bind(this);
        this.fctOnDragEnd = this.onDragEnd.bind(this);
        this.fctOnDragEnterDropZone = this.onDragEnterDropZone.bind(this);
        this.fctOnDragExitDropZone = this.onDragExitDropZone.bind(this);
        this.fctOnDragDrop = this.onDragDrop.bind(this);
        this.fctOnDragAnimationEnd = this.onDragAnimationEnd.bind(this);

        this.fctOnShowMutationDesc = this.onShowMutationDescription.bind(this)
    }

    bindEvents ()
    {
        super.bindEvents();
        this.UI.ItemManager.on(this.UI.ItemManager.EVENT_ITEM_FOUND, this.fctOnItemAdded);
    }

    destroy(options)
    {
        this.UI.ItemManager.off(this.UI.ItemManager.EVENT_ITEM_FOUND, this.fctOnItemAdded);

        if (this.closeButton)
        {
            this.closeButton.off(this.closeButton.EVENT_CLICK, this.fctOnCloseClick);
            this.closeButton.destroy(options);
        }
        delete this.closeButton;

        if (this.dragger)
        {
            this.dragger.off(this.dragger.EVENT_DRAG_START, this.fctOnDragStart);
            this.dragger.off(this.dragger.EVENT_DRAG_END, this.fctOnDragEnd);
            this.dragger.off(this.dragger.EVENT_ENTER_DROP_ZONE, this.fctOnDragEnterDropZone);
            this.dragger.off(this.dragger.EVENT_EXIT_DROP_ZONE, this.fctOnDragExitDropZone);
            this.dragger.off(this.dragger.EVENT_DROP, this.fctOnDragDrop);
            this.dragger.off(this.dragger.EVENT_DRAG_END_ANIMATION_FINISHED, this.fctOnDragAnimationEnd)
        
            this.dragger.destroy(options);
        }
        delete this.dragger;

        if (this.linked)
        {
            this.linked.Grid.off(this.LinkedPopup.Grid.EVENT_DISPLAYED_SLOTS_CHANGE, this.fctOnDisplaySlotsChanged);
        }
        delete this.linked;

        if (this.mutation1)
        {
            this.mutation1.off("pointerup", this.fctOnShowMutationDesc);//
            this.removeChild(this.mutation1);
            this.mutation1.destroy(options);
        }
        delete this.mutation1;

        if (this.mutation2)
        {
            this.mutation2.off("pointerup", this.fctOnShowMutationDesc);//
            this.removeChild(this.mutation2);
            this.mutation2.destroy(options);
        }
        delete this.mutation2;

        if (this.inventory)
        {
            this.inventory.off(this.inventory.EVENT_DISPLAYED_SLOTS_CHANGE, this.fctOnDisplaySlotsChanged);
            this.removeChild(this.inventory);
            this.inventory.destroy(options);
        }
        delete this.inventory;

        for (let i = 0; i < this.equipSlots.length; i++)
        {
            this.removeChild(this.equipSlots[i]);
            this.equipSlots[i].destroy(options);
        }
        delete this.equipSlots;
        delete this.arrSlots;

        super.destroy(options);
    }

    show()
    {
        this.populate();
        this.enableDrag();

        super.show();
    }

    hide()
    {
        this.disableDrag();
        super.hide();
    }

    /*******************************************
    *   BUILD
    *******************************************/
    build ()
    {
        super.build();

        this.buildInventory();
        this.buildCharacter();
        this.buildEquipment();
    }

    buildInventory ()
    {
        let strPadding = "(ih / 940 * 10)";
        let strOneSlotWidth = `(((${this.BgWidth} - (${strPadding} * 4)) / 6) - ${strPadding})`;
        let strInventoryHeight  = `((${strOneSlotWidth} * 2) + ${strPadding})`

        if (!this.inventory)
        {
            this.inventory = new InventoryGrid();
            this.inventory.init({
                "ui": this.UI,
                "itemsByRow": this.getAvailableSlotsCount(),
                "rWidth": strOneSlotWidth,
                "rPadding": strPadding,
                "type": this.inventory.TYPE_BACKPACK
            });

            this.addChild(this.inventory);
        }

        this.inventory.rX = [{on:"default", x: `(${strPadding} * 2)`}];
        this.inventory.rY = [{on:"default", y: `${this.BgHeight} - ${strInventoryHeight} - (${strPadding} * 2)`}];

        this.inventory.layout(Display.getSize());
    }

    buildCharacter ()
    {
        if (!this.character)
        {
            this.character_width  = `(${this.BgWidth} * 52.3%)`;
            this.character_height  = `(${this.BgHeight} * 60%)`;

            this.character = new CharacterViewer().init({
                "ui": this.UI,
                "rWidth": this.character_width,
                "rHeight": this.character_height
            });

            this.addChild(this.character);
        }

        this.character_y = `(${this.BgHeight} * 8.25%)`;
        this.character.rX = [{on:"default", x: `(${this.BgWidth} * 23.6%)`}];
        this.character.rY = [{on:"default", y: this.character_y}];

        this.character.layout(Display.getSize());
    }

    buildEquipment ()
    {
        let size = Display.getSize();
        let strWidth = `((${this.BgWidth}) * 17.48%)`;
        let strX1 = `(((${this.BgWidth}) - (${this.character_width})) / 4 - (width / 2))`;
        let strX2 = `${this.BgWidth} - ${strX1} - (width)`;
        let strHeight = `((${this.BgHeight}) * 14.46%)`;
        let strY1 = `((${this.BgHeight}) * 23.35%)`;
        let strY2 = `((${this.BgHeight}) * 51.44%)`;

        let strHeightOffset = `(${this.character_height}) / 4`;

        this.arrSlots = []

        let arrMutations = this.UI.CharacterManager.getCharacterMutations(this.UI.CharacterManager.CurrentCharacter);

        if (arrMutations.length > 0)
        {
            let strMutation1Y = `(${this.character_y})`;
            let icon = this.UI.getMutationIcon(arrMutations[0]);
            if (!this.mutation1)
            {
                this.mutation1 = new ColoredActionButton().init({
                    "ui": this.UI,
                    "textureName": icon,
                    "rWidth": strWidth,
                    "rHeight": strWidth,
                    "iconScale": 0.75,
                    "bgColor": 0xFF0000,
                    "strokeColor": 0x00FF00,
                    "iconColor": 0x222222
                });
                this.mutation1.on(this.mutation1.EVENT_CLICK, this.fctOnShowMutationDesc);//
                this.addChild(this.mutation1);
            }

            this.mutation1.rX = [{on: "default", x: strX1}];
            this.mutation1.rY = [{on: "default", y: strMutation1Y}];
            this.mutation1.name = arrMutations[0];
            this.mutation1.layout(size);
        }

        let strCoatY = `(${this.character_y})  +  (${strHeightOffset})`;

        if (!this.coat)
        {
            this.coat = new EquipmentSlot();
            this.coat.init({
                "ui": this.UI,
                "rWidth": strWidth,
                "rHeight": strWidth,
                "type": this.coat.EQUIPMENT_BODY,
                "strokeWidth": 2
            });
            this.equipSlots.push(this.coat);
            this.arrSlots.push(this.coat);

            this.addChild(this.coat);
        }
        this.coat.rX = [{on:"default", x: strX1}];
        this.coat.rY = [{on:"default", y: strCoatY }];
        this.coat.isEquipment = true;
        this.coat.layout(size);

        let strGlovesY = `(${this.character_y})  +  (${strHeightOffset} * 2)`;

        if (!this.gloves)
        {
            this.gloves = new EquipmentSlot();
            this.gloves.init({
                "ui": this.UI,
                "rWidth": strWidth,
                "rHeight": strWidth,
                "type": this.gloves.EQUIPMENT_HANDS,
                "strokeWidth": 2
            });
            this.equipSlots.push(this.gloves);
            this.arrSlots.push(this.gloves);

            this.addChild(this.gloves);
        }
        this.gloves.rX = [{on:"default", x: strX1}];
        this.gloves.rY = [{on:"default", y: strGlovesY}];
        this.gloves.isEquipment = true;
        this.gloves.layout(size);

        let strBootY = `(${this.character_y})  +  (${strHeightOffset} * 3)`;
        if (!this.boots)
        {
            this.boots = new EquipmentSlot();
            this.boots.init({
                "ui": this.UI,
                "rWidth": strWidth,
                "rHeight": strWidth,
                "type": this.boots.EQUIPMENT_FEET,
                "strokeWidth": 2
            });
            this.equipSlots.push(this.boots);
            this.arrSlots.push(this.boots);

            this.addChild(this.boots);
        }
        this.boots.rX = [{on:"default", x: strX1}];
        this.boots.rY = [{on:"default", y: strBootY}];
        this.boots.isEquipment = true;
        this.boots.layout(size);

        if (arrMutations.length > 1)
        {
            let strMutation2Y = `(${this.character_y})`;
            let icon = this.UI.getMutationIcon(arrMutations[1]);

            if (!this.mutation2)
            {
                this.mutation2 = new ColoredActionButton().init({
                    "ui": this.UI,
                    "textureName": icon,
                    "rWidth": strWidth,
                    "rHeight": strWidth,
                    "iconScale": 0.75,
                    "bgColor": 0xFF0000,
                    "strokeColor": 0x00FF00,
                    "iconColor": 0x222222
                });
                this.mutation2.on(this.mutation2.EVENT_CLICK, this.fctOnShowMutationDesc);//
                this.addChild(this.mutation2);
            }
            this.mutation2.rX = [{on: "default", x: strX2}];
            this.mutation2.rY = [{on: "default", y: strMutation2Y}];
            this.mutation2.name = arrMutations[1];
            this.mutation2.layout(size);
        }

        let strToolY = `(${this.character_y})  +  (${strHeightOffset} * 1)`;
        if (!this.tool)
        {
            this.tool = new EquipmentSlot();
            this.tool.init({
                "ui": this.UI,
                "rWidth": strWidth,
                "rHeight": strWidth,
                "type": this.tool.EQUIPMENT_TOOL,
                "strokeWidth": 2
            });
            this.equipSlots.push(this.tool);
            this.arrSlots.push(this.tool);

            this.addChild(this.tool);
        }
        this.tool.rX = [{on:"default", x: strX2}];
        this.tool.rY = [{on:"default", y: `((${this.BgHeight}) * 22%)`}];
        this.tool.isEquipment = true;
        this.tool.layout(size);

        let strToyY = `(${this.character_y})  +  (${strHeightOffset} * 2)`;
        if (!this.toy)
        {
            this.toy = new EquipmentSlot();
            this.toy.init({
                "ui": this.UI,
                "rWidth": strWidth,
                "rHeight": strWidth,
                "type": this.toy.EQUIPMENT_TOY,
                "strokeWidth": 2
            });
            this.equipSlots.push(this.toy);
            this.arrSlots.push(this.toy);

            this.addChild(this.toy);
        }

        this.toy.rX = [{on:"default", x: strX2}];
        this.toy.rY = [{on:"default", y: strToyY}];
        this.toy.isEquipment = true;
        this.toy.layout(size);
    }

    /*******************************************
    *   INVENTORY MANAGEMENT
    *******************************************/
    getAvailableSlotsCount ()
    {
        let perRow = 6;
        let slotCount = this.UI.ItemManager.InventorySlotCount;
        let rows = [];

        while(slotCount > 0)
        {
            rows.push(Math.min(slotCount, perRow));
            slotCount -= perRow;
        }
        
        return rows;
    }

    populate ()
    {
        let items = this.UI.ItemManager.getAllItems();

        for (let index in items)
        {
            let slot = items[index];
            this.inventory.addItem(index, slot);
        }

        if (this.inventory)
        {
            this.inventory.showPage();
        }

        let bodyEquipment = this.UI.ItemManager.BodyEquipment;

        if (bodyEquipment !== undefined && bodyEquipment !== null)
        {
            this.coat.addItem({item: bodyEquipment});
        }

        let handEquipment = this.UI.ItemManager.HandsEquipment;
        if (handEquipment !== undefined && handEquipment !== null)
        {
            this.gloves.addItem({item: handEquipment});
        }

        let feetEquipment = this.UI.ItemManager.FeetEquipment;
        if (feetEquipment !== undefined && feetEquipment !== null)
        {
            this.boots.addItem({item: feetEquipment});
        }

        let toolEquipment = this.UI.ItemManager.ToolEquipment;
        if (toolEquipment !== undefined && toolEquipment !== null)
        {
            this.tool.addItem({item: toolEquipment});
        }

        let toyEquipment = this.UI.ItemManager.ToyEquipment;
        if (toyEquipment !== undefined && toyEquipment !== null)
        {
            this.toy.addItem({item: toyEquipment});
        }

    }

    updateInventory ()
    {
        this.inventory.removeAllItems();
        this.coat.removeItem(0);
        this.gloves.removeItem(0);
        this.boots.removeItem(0);
        this.tool.removeItem(0);
        this.toy.removeItem(0);

        let items = this.UI.ItemManager.getAllItems();

        for (let index in items)
        {
            let slot = items[index];
            this.inventory.updateAt(index, slot);
        }

        let bodyEquipment = this.UI.ItemManager.BodyEquipment;
        if (bodyEquipment !== undefined && bodyEquipment !== null)
        {
            this.coat.updateItem({item: bodyEquipment});
        }

        let handEquipment = this.UI.ItemManager.HandsEquipment;
        if (handEquipment !== undefined && handEquipment !== null)
        {
            this.gloves.updateItem({item: handEquipment});
        }

        let feetEquipment = this.UI.ItemManager.FeetEquipment;
        if (feetEquipment !== undefined && feetEquipment !== null)
        {
            this.boots.updateItem({item: feetEquipment});
        }

        let toolEquipment = this.UI.ItemManager.ToolEquipment;
        if (toolEquipment !== undefined && toolEquipment !== null)
        {
            this.tool.updateItem({item: toolEquipment});
        }

        let toyEquipment = this.UI.ItemManager.ToyEquipment;
        if (toyEquipment !== undefined && toyEquipment !== null)
        {
            this.toy.updateItem({item: toyEquipment});

        }

        this.UI.updateAntistressButton();
        this.UI.buildToolButton();

    }

    getSlotFromEquip(strLocation)
    {
        if (strLocation == this.coat.EQUIPMENT_BODY)
        {
            return this.coat;
        }
        else if (strLocation == this.gloves.EQUIPMENT_HANDS)
        {
            return this.gloves;
        }
        else if (strLocation == this.boots.EQUIPMENT_FEET)
        {
            return this.boots;
        }
        else if (strLocation == this.tool.EQUIPMENT_TOOL)
        {
            return this.tool;
        }
        else if (strLocation == this.toy.EQUIPMENT_TOY)
        {
            return this.toy;
        }

        return null;
    }

    applyAutoStackOperation(objFrom, objTo, result)
    {
        this.dragger.CanDrag = false;
        if (this.LinkedPopup)
        {
            this.LinkedPopup.CanSwitchFilter = false;
        }

        let item = objFrom.Item.item;
        let objClone = objFrom.clone();
        this.UI.addChild(objClone);
        objClone.addItem(objFrom.Item);
        objClone.position.set(this.dragEndPos.x, this.dragEndPos.y);

        objFrom.removeItem(0);

        let fctCallback = function(objClone)
        {
            this.UI.removeChild(objClone);
            this.updateInventory();
            if (this.LinkedPopup)
            {
                this.LinkedPopup.updateInventory();
            }
            this.dragger.CanDrag = true;
            if (this.LinkedPopup)
            {
                this.LinkedPopup.CanSwitchFilter = true;
            }
        }
        .bind(this, objClone);

        let startPos = {"x": objClone.position.x, "y": objClone.position.y};

        let stack = result.args.autoStacked[0];
        let slot = null;
        let globalPos = null;

        if (stack.isBackpack)
        {
            slot = this.inventory.getSlotAt(stack.slot);
            globalPos = this.inventory.toGlobal(slot.position);
        }
        else
        {
            globalPos = null;
            let current = this.LinkedPopup.CurrentFilter;
            let destination = null;

            if (
                (item.Type != current.Type) ||
                (item.Type == this.UI.ItemManager.ITEM_CODES.TYPE_KEY && current.Type != this.UI.ItemManager.ITEM_CODES.TYPE_EQUIPMENT)
            )
            {
                destination = this.LinkedPopup.getFilter(item.Type);
            }

            if (destination)
            {
                globalPos = destination.toGlobal({
                    "x": this.evaluate(destination.BgWidth) / 2 - this.evaluate(objClone.BgWidth) / 2, 
                    "y": this.evaluate(destination.BgHeight) / 2 - this.evaluate(objClone.BgHeight) / 2
                });
            }
            else
            {
                slot = this.LinkedPopup.Grid.getSlotAt(stack.slot);
                globalPos = this.LinkedPopup.Grid.toGlobal(slot.position);
            }
        }

        let endPos = this.UI.toLocal(globalPos);

        let animTime = this.dragger.calculateAnimationTime(startPos.x, startPos.y, endPos.x, endPos.y);

        this.lastTime = new Date().getTime();
        this.animateEndDrag(objClone, animTime, animTime, startPos.x, startPos.y, endPos.x, endPos.y, fctCallback);
    }

    getAllSlots()
    {
        let slots = this.inventory.DisplayedSlots;

        if (this.LinkedPopup)
        {
            slots = slots.concat(this.LinkedPopup.Grid.DisplayedSlots);
        }

        for (let i = 0; i < slots.length; i++)
        {
            slots[i].isInventory = true;
        }

        slots = slots.concat(this.equipSlots);

        this.character.isCharacter = true;
        slots.push(this.character);

        return slots;
    }


    /*******************************************
    *   ITEM DROPPING
    *******************************************/
    canDrop(objFrom, objTo)
    {
        if (objFrom.Item.item)
        {
            //Can drop anything on an inventory slot
            if (objTo.isInventory)
            {
                return true;
            }

            let item = objFrom.Item.item;

            //Can drop food or equipment on the character viewer
            if ((item.Type == objFrom.TYPE_FOOD || item.Type == objFrom.TYPE_EQUIPMENT) && objTo.isCharacter)
            {
                let bOk = item.Type != objFrom.TYPE_FOOD;
                if (!bOk)
                {
                    let current = this.UI.CharacterManager.CurrentCharacter;
                    bOk = this.UI.CharacterManager.getCharacterHunger(current) < this.UI.CharacterManager.getCharacterMaxHunger(current);
                }

                return bOk;
            }

            if (item.Type == objTo.TYPE_EQUIPMENT && objTo.isEquipment)
            {
                return item.EquipLocation == objTo.type;
            }
        }

        return false;
    }

    dropItem(objFrom, objTo)
    {
        if (objFrom.isEquipment && objTo.isInventory)
        {
            let result = null;
            if (this.UI.ItemManager.removeEquipment(objFrom.Item.item.EquipLocation))
            {
                result = this.UI.ItemManager.addItem(objFrom.Item.item, 1, objTo.index, objTo.LocationType == this.inventory.TYPE_BACKPACK, -1, false);
            }

            if (this.LinkedPopup && objTo.type == this.inventory.TYPE_CLOSET)
            {
                this.LinkedPopup.switchFilter(objFrom.Item.item.Type);
            }

            if (result && result.args.autoStacked && result.args.autoStacked.length > 0)
            {
                this.applyAutoStackOperation(objFrom, objTo, result);
            }
            else
            {
                objFrom.removeItem(0);

                this.updateInventory();

                if (this.LinkedPopup)
                {
                    this.LinkedPopup.updateInventory();
                }


            }
        }
        else if (objTo.isInventory)
        {
            if (this.LinkedPopup && objTo.type == this.inventory.TYPE_CLOSET)
            {
                this.LinkedPopup.switchFilter(objFrom.Item.item.Type);
            }

            let result = this.UI.ItemManager.dragItem(objFrom.index, objFrom.LocationType == this.inventory.TYPE_BACKPACK, objTo.index, objTo.LocationType == this.inventory.TYPE_BACKPACK);

            if (result.args.autoStacked && result.args.autoStacked.length > 0)
            {
                this.applyAutoStackOperation(objFrom, objTo, result);
            }
            else
            {
                objFrom.removeItem(0);
                this.updateInventory();
                if (this.LinkedPopup)
                {
                    this.LinkedPopup.updateInventory();
                }
            }
        }
        else if (objFrom.isInventory && (objTo.isEquipment || objTo.isCharacter))
        {
            let result = null;
            if (objFrom.Item.item.Type == objFrom.TYPE_EQUIPMENT)
            {
                result = this.UI.ItemManager.equipAtSlot(objFrom.index, objFrom.LocationType == this.inventory.TYPE_BACKPACK);
            }
            else if (objFrom.Item.item.Type == objFrom.TYPE_FOOD && objTo.isCharacter)
            {
                result = this.UI.ItemManager.eatAtSlot(objFrom.index, objFrom.LocationType == this.inventory.TYPE_BACKPACK);
                this.character.displayGaugeFor(result.eat);
                this.UI.AudioManager.playSfx("manger");
            }

            if (result && objTo.isCharacter && objFrom.Item.item.Type == objFrom.TYPE_EQUIPMENT)
            {
                this.dragger.CanDrag = false;
                if (this.LinkedPopup)
                {
                    this.LinkedPopup.CanSwitchFilter = false;
                }

                let objClone = objFrom.clone();
                this.UI.addChild(objClone);
                objClone.addItem(objFrom.Item);
                objClone.position.set(this.dragEndPos.x, this.dragEndPos.y);

                let fctCallback = function(objClone)
                {
                    this.UI.removeChild(objClone);
                    this.updateInventory();
                    if (this.LinkedPopup)
                    {
                        this.LinkedPopup.updateInventory();
                    }
                    this.dragger.CanDrag = true;
                    if (this.LinkedPopup)
                    {
                        this.LinkedPopup.CanSwitchFilter = true;
                    }
                }
                .bind(this, objClone);

                let equipSlot = this.getSlotFromEquip(objFrom.Item.item.EquipLocation);

                let startPos = {"x": objClone.position.x, "y": objClone.position.y};
                let equipPos = {"x": equipSlot.position.x + equipSlot.width / 2 - objFrom.width / 2, "y": equipSlot.position.y + equipSlot.height / 2 - objFrom.height / 2};
                let endPos = this.UI.toLocal(this.toGlobal(equipPos));

                objFrom.removeItem(0);

                let animTime = this.dragger.calculateAnimationTime(startPos.x, startPos.y, endPos.x, endPos.y);

                this.lastTime = new Date().getTime();
                this.animateEndDrag(objClone, animTime, animTime, startPos.x, startPos.y, endPos.x, endPos.y, fctCallback);

            }
            else
            {
                objFrom.removeItem(0);

                this.updateInventory();
                if (this.LinkedPopup)
                {
                    this.LinkedPopup.updateInventory();
                }
            }


        }

        if (objFrom)
        {
            objFrom.layout(Display.getSize());
        }

        if (objTo)
        {
            objTo.layout(Display.getSize());
        }
    }

    dropItemOnFloor(objItem, iQuantity, iSlot, bIsEquipment, bIsBackpack)
    {
        if (this.UI.WorldManager.IsHostile)
        {
            if (bIsEquipment)
            {
                this.UI.ItemManager.removeEquipment(objItem.EquipLocation);
            }
            else
            {
                this.UI.ItemManager.removeItemFromSlot(iQuantity, iSlot, bIsBackpack);
            }

            this.UI.ItemManager.emit(this.UI.ItemManager.EVENT_ITEM_DROPPED, objItem, (bIsEquipment ? 1 : iQuantity), iSlot, bIsBackpack);
            this.hide();

            return true;
        }
        return false;
    }
    
    /*******************************************
    *   DRAG & DROP
    *******************************************/
    enableDrag()
    {
        let slots = this.getAllSlots();

        if (!this.dragger)
        {
            this.inventory.on(this.inventory.EVENT_DISPLAYED_SLOTS_CHANGE, this.fctOnDisplaySlotsChanged);//@

            if (this.LinkedPopup)
            {
                this.LinkedPopup.Grid.on(this.inventory.EVENT_DISPLAYED_SLOTS_CHANGE, this.fctOnDisplaySlotsChanged);//@
            }

            this.dragger = new ContainerDragger().init({
                "ui": this.UI,
                "draggables": slots,
                "dropZones": slots
            });
            this.dragger.on(this.dragger.EVENT_DRAG_START, this.fctOnDragStart);//@
            this.dragger.on(this.dragger.EVENT_DRAG_END, this.fctOnDragEnd);//@
            this.dragger.on(this.dragger.EVENT_ENTER_DROP_ZONE, this.fctOnDragEnterDropZone);//@
            this.dragger.on(this.dragger.EVENT_EXIT_DROP_ZONE, this.fctOnDragExitDropZone);//@
            this.dragger.on(this.dragger.EVENT_DROP, this.fctOnDragDrop);//@
            this.dragger.on(this.dragger.EVENT_DRAG_END_ANIMATION_FINISHED, this.fctOnDragAnimationEnd);//@

            this.UI.UIManager.emit(this.UI.UIManager.EVENT_OPEN_BACKPACK);
        }
        else
        {
            this.dragger.DraggableContainers = slots;
            this.dragger.DropZoneContainers = slots;

            this.dragger.CanDrag = true;
        }
    }

    disableDrag()
    {
        this.dragger.CanDrag = false;
    }

    animateEndDrag(objToAnimate, iTotalTime, iTimeLeft, fFromX, fFromY, fToX, fToY, fctCallback)
    {
        let time = new Date().getTime();
        let delta = time - this.lastTime;
        this.lastTime = time;

        iTimeLeft -= delta;
        let t = 1 - iTimeLeft / iTotalTime;
        t = t * t * t * (t * (6 * t - 15) + 10);

        objToAnimate.position.set(
            Lerp.lerp(fFromX, fToX, t),
            Lerp.lerp(fFromY, fToY, t)
        );

        if (iTimeLeft > 0)
        {
            window.requestAnimationFrame(this.animateEndDrag.bind(this, objToAnimate, iTotalTime, iTimeLeft, fFromX, fFromY, fToX, fToY, fctCallback));
        }
        else
        {
            fctCallback();
        }
    }

    //---------------------------------------------------
    //  EVENTS
    //---------------------------------------------------
    onItemAdded()
    {
        this.updateInventory();
    }

    onDisplaySlotsChanged()
    {
        if (this.dragger)
        {
            let slots = this.getAllSlots();

            this.dragger.DraggableContainers = slots;
            this.dragger.DropZoneContainers = slots;
        }
    }

    onDragStart(sender, container, fctCancelDrag)
    {
        for (let i = 0; i < this.UI.ShownPopups.length; i++)
        {
            if (this.UI.ShownPopups[i].IsDescription)
            {
                fctCancelDrag();
                return;
            }
        }
        
        this.fctApplyDrop = null;

        if (!container.Enabled || !container.item || this.UI.Notifications.IsNotificationShown)
        {
            fctCancelDrag();
        }
        else
        {
            let pos = container.toGlobal(container.position)
            container.showTitle(container.Item.item.Name + ":\r\n" + container.Item.item.Description );

            container.startDragPos = {"x": container.position.x, "y": container.position.y};

            this.dragParent = container.parent;
            this.dragClone = container.clone();
            this.dragParent.addChild(this.dragClone);
            this.dragClone.position.set(container.position.x, container.position.y);

            this.dragParent.removeChild(container);

            this.UI.addChild(container);

            let localPos = container.toLocal(pos);
            container.position.set(localPos.x, localPos.y);
        }
    }

    onDragEnd(sender, container, fctCancelDrop, fctOverrideEndPos)
    {
        let slots = this.getAllSlots();

        for (let i = 0; i < slots.length; i++)
        {
            slots[i].unhighlight();
        }

        let inputPos = this.dragger.lastInput;
        let modalPos = this.toGlobal({"x": 0, "y": 0});
        let xZone = {
            "min": modalPos.x + this.UI.ResponsiveManager.ScreenPadding,
            "max": modalPos.x + this.UI.ResponsiveManager.ScreenPadding + this.evaluate(this.BgWidth, 0, 0, 0, 0, this)
        };

        let xLinkedZone = xZone;
        if (this.LinkedPopup)
        {
            modalPos = this.LinkedPopup.toGlobal({"x": 0, "y": 0});
            xLinkedZone = {
                "min": modalPos.x + this.UI.ResponsiveManager.ScreenPadding,
                "max": modalPos.x + this.UI.ResponsiveManager.ScreenPadding + this.evaluate(this.LinkedPopup.BgWidth, 0, 0, 0, 0, this)
            };
        }

        if (container.Item.item.IsDroppable && (inputPos.x < xZone.min || inputPos.x > xZone.max) && (inputPos.x < xLinkedZone.min || inputPos.x > xLinkedZone.max))
        {
            if (this.dropItemOnFloor(container.Item.item, container.Item.quantity, container.Item.slot, container.isEquipment, container.LocationType == this.inventory.TYPE_BACKPACK))
            {
                container.removeItem();
            }

            this.UI.removeChild(container);
            this.dragParent.addChild(container);

            container.position.set(container.startDragPos.x, container.startDragPos.y);

            this.dragClone.parent.removeChild(this.dragClone);
            this.dragClone.destroy({"children": true});
            delete this.dragClone;
        }

        container.removeTitle();
    }

    onDragEnterDropZone(sender, container, dropZone)
    {
        if (this.canDrop(container, dropZone))
        {
            dropZone.highlight();
        }
    }

    onDragExitDropZone(sender, container, dropZone)
    {
        dropZone.unhighlight();
    }

    onDragDrop(sender, objContainer, objDropZone, fctCancelDrop, fctOverrideEndPos)
    {
        if (this.canDrop(objContainer, objDropZone))
        {
            this.fctApplyDrop = this.dropItem.bind(this, objContainer, objDropZone);
            
            if (objDropZone.isCharacter || objDropZone.isEquipment)
            {
                fctOverrideEndPos(objContainer.position.x, objContainer.position.y);
            }
        }
        else
        {
            //In case the player tries to feed the character when its hunger is full
            if (objContainer.Item.item && objContainer.Item.item.Type == objContainer.TYPE_FOOD && objDropZone.isCharacter)
            {
                let current = this.UI.CharacterManager.CurrentCharacter;
                let maxHunger = this.UI.CharacterManager.getCharacterMaxHunger(current);
                
                if (this.UI.CharacterManager.getCharacterHunger(current) >= maxHunger)
                {
                    this.character.displayGaugeFor({
                        "affected": ["hunger"],
                        "hunger": {"from": maxHunger, "to": maxHunger},
                        "energy": {"from": 1, "to": 1}
                    });
                }
            }

            fctCancelDrop();
        }
    }

    onDragAnimationEnd(sender, container)
    {
        let pos = container.toGlobal(container.position);

        this.dragEndPos = {"x": container.position.x, "y": container.position.y};

        this.UI.removeChild(container);
        this.dragParent.addChild(container);

        container.position.set(container.startDragPos.x, container.startDragPos.y);

        if (this.dragClone)
        {
            this.dragClone.parent.removeChild(this.dragClone);
            this.dragClone.destroy({"children": true});
            delete this.dragClone;
        }

        if (this.fctApplyDrop)
        {
            this.fctApplyDrop();
        }
    }

    onShowMutationDescription(target)
    {
        let arg = arguments;

        let strItem = target.name;

        let objItem = this.UI.ItemManager.getMutationFromCode(strItem);
        this.UI.showMutationDescription(objItem)
    }

}