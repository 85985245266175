import _ from "lodash";
import ActionButton from "./commons/buttons/ActionButton.js";
import BackpackPopup from "./commons/popups/BackpackPopup.js";
import BaseUI from "./BaseUI.js";
import ChoiceDialog from "./commons/dialogs/ChoiceDialog.js";
import ChoicePopup from "./commons/popups/ChoicePopup.js";
import ClosetPopup from "./commons/popups/ClosetPopup.js";
import DescriptionPopup from "./commons/popups/DescriptionPopup.js";
import Dialog from "./commons/dialogs/Dialog.js";
import Display from "../utils/Display.js";
import GadgetMinigame from "./minigames/gadget/GadgetMinigame.js";
import Gauge from "./commons/gauges/Gauge.js";
import GraphicsResponsive from "./commons/responsive/GraphicsResponsive.js";
import HackingMinigame from "./minigames/hacking/HackingMinigame.js";
import Library from "../Library.js";
import MapPopup from "./commons/popups/MapPopup.js";
import Notifications from "./notifications/Notifications.js";
import PausePopup from "./commons/popups/PausePopup.js";
import ScreenTransition from "./commons/ScreenTransition.js";
import TimeOfDayGauge from "./commons/gauges/TimeOfDayGauge.js";
import UnlockMinigame from "./minigames/unlock/UnlockMinigame.js";

export default class GameUI extends BaseUI
{
    constructor()
    {
        super();
    }

    get IsHostile() { return this.WorldManager.IsHostile; }
    get Notifications() { return this.notifications; }
    get Minigame() { return this.minigame; }

    get ShownPopups() { return this.arrShownPopups; }

    /*******************************************
    *   INITIALIZATION
    *******************************************/
    /**
        Parameters to pass to the init function:
        - dependencies:     Dependency injector object
    */
    init(meta)
    {
        this.buttons = {};
        this.gauges = {};
        this.popups = {};
        this.arrShownPopups = [];
        this.dialogHidden = [];
        this.minigame = null;

        super.init(meta);

        this.initNotifications();
        this.notifications.onInGameDisplayed();

        return this;
    }

    createClosure ()
    {
        super.createClosure();

        this.fctStatChanged = this.onStatChanged.bind(this);
        this.fctOnOverlayClick = this.onOverlayClick.bind(this);
        this.fctDialogClose = this.onDialogClose.bind(this);

        //BUTTONS
        this.fctOnPauseClick = this.onPauseClick.bind(this);
        this.fctOnBackpackClick = this.onBackpackClick.bind(this);
        this.fctOnMapClick = this.onMapClick.bind(this);

        //POPUPS
        this.fctOnPopupShow = this.onPopupShow.bind(this);
        this.fctOnPopupHide = this.onPopupHide.bind(this);

        //MINIGAMES
        this.fctOnMinigameSuccess = this.onMinigameSuccess.bind(this);
        this.fctOnMinigameFailure = this.onMinigameFailure.bind(this);
    }

    bindEvents()
    {
        super.bindEvents()
        this.CharacterManager.on(this.CharacterManager.EVENT_STAT_VALUE_CHANGED, this.fctStatChanged);
    }

    cleanUp (options)
    {
        //BUTTONS
        let keys = Object.keys(this.buttons);
        for (let i = 0; i < keys.length; i++)
        {
            let key = keys[i];
            if (this.buttons[key] in this)
            {
                this.removeMouseEating(this.buttons[key]);
                this.removeFromLayoutable(this.buttons[key]);
                this.removeChild(this.buttons[key]);
                this.buttons[key].off(this.buttons[key].EVENT_CLICK, this.buttons[key].fctClick);
                this.buttons[key].destroy(options);
                delete this.buttons[key];
            }
        }

        //POPUPS
        keys = Object.keys(this.popups);
        for (let i = 0; i < keys.length; i++)
        {
            let key = keys[i];
            if (this.popups[key] in this)
            {
                this.removeChild(this.popups[key]);
                this.popups[key].destroy(options);
                delete this.popups[key];
            }
        }

        if (this.dialog)
        {
            this.removeChild(this.dialog);
            this.dialog.destroy(options);
            delete this.dialog;
        }

        if (this.notifications)
        {
            this.notifications.destroy(options);
            delete this.notifications;
        }
    }

    destroy(options)
    {
        this.CharacterManager.off(this.CharacterManager.EVENT_STAT_VALUE_CHANGED, this.fctStatChanged);

        this.cleanUp(options);

        super.destroy(options);
    }

    initNotifications()
    {
        this.notifications = new Notifications().init({"ui": this});
    }

    /*******************************************
    *   BUILD
    *******************************************/
    build()
    {
        super.build();
        this.buildActionButtons();
        this.buildGauges();
    }

    buildToolButton ()
    {

    }

    buildActionButtons()
    {
        this.buildPauseButton();
        this.buildBackPackButton();
        this.buildMapButton();
    }

    buildPauseButton()
    {
        if (!this.buttons.pause)
        {
            this.buttons.pause = new ActionButton().init({
                "ui": this,
                "textureName": "icon_pause",
                "rWidth": this.rButtonWidth,
                "rHeight": this.rButtonHeight,
                "iconScale": 0.57
            });
            this.buttons.pause.rX = [{on: "default", x:`${this.rButtonWidth} / 2`}];
            this.buttons.pause.rY = [{on: "default", y:`${this.rButtonWidth} / 2`}];
            this.buttons.pause.rAnchorX = [{on: "default", anchor: "0"}];
            this.buttons.pause.rAnchorY = [{on: "default", anchor: "0"}];

            this.buttons.pause.on(this.buttons.pause.EVENT_CLICK, this.fctOnPauseClick);
            this.buttons.pause.fctClick = this.fctOnPauseClick;

            this.addChild(this.buttons.pause);

            this.buttons.pause.name = "pause_btn";
            this.addMouseEating(this.buttons.pause);
        }
    }

    buildBackPackButton()
    {
        if (!this.buttons.backpack)
        {
            this.buttons.backpack = new ActionButton().init({
                "ui": this,
                "textureName": "icon_backpack",
                "rWidth": this.rButtonWidth,
                "rHeight": this.rButtonHeight,
                "iconScale": 0.85
            });
            this.buttons.backpack.rX = [{on: "default", x:`${this.rButtonWidth} / 2`}];
            this.buttons.backpack.rY = [{on: "default", y:`ih - ${this.rButtonWidth} - ${this.rButtonWidth} / 2`}];
            this.buttons.backpack.rAnchorX = [{on: "default", anchor: "0"}];
            this.buttons.backpack.rAnchorY = [{on: "default", anchor: "1"}];

            this.buttons.backpack.on(this.buttons.backpack.EVENT_CLICK, this.fctOnBackpackClick);
            this.buttons.backpack.fctClick = this.fctOnBackpackClick;

            this.addChild(this.buttons.backpack);

            this.buttons.backpack.name = "backpack";
            this.addMouseEating(this.buttons.backpack);
        }
    }

    buildMapButton()
    {
        if (!this.buttons.map)
        {
            this.buttons.map = new ActionButton().init({
                "ui": this,
                "textureName": "icon_map",
                "rWidth": this.rButtonWidth,
                "rHeight": this.rButtonHeight,
                "iconScale": 0.85
            });
            this.buttons.map.rX = [{on: "default", x:`(${this.rButtonWidth} / 2) * 2 + ${this.rButtonWidth}`}];
            this.buttons.map.rY = [{on: "default", y:`ih - ${this.rButtonWidth} - ${this.rButtonWidth} / 2`}];
            this.buttons.map.rAnchorX = [{on: "default", anchor: "0"}];
            this.buttons.map.rAnchorY = [{on: "default", anchor: "1"}];

            this.buttons.map.on(this.buttons.map.EVENT_CLICK, this.fctOnMapClick);
            this.buttons.map.fctClick = this.fctOnMapClick;

            this.addChild(this.buttons.map);

            this.buttons.map.name = "map";
            this.addMouseEating(this.buttons.map);
        }
    }

    updateAntistressButton ()
    {

    }
    //-----------------------------------------
    //  GAUGES
    //-----------------------------------------
    buildGauges()
    {
        this.buildTimeOfDay();
        this.buildStatGauges();
    }

    buildTimeOfDay()
    {
        if (!this.gauges.timeOfDay)
        {
            let rWidth = "(ih * 0.375)";
            let rHeight = "(ih * 0.065)";

            let x = "(iw / 2) - (" + rWidth + " / 2)";
            let y = this.rButtonHeight + " * 0.75";

            this.gauges.timeOfDay = new TimeOfDayGauge().init({"ui":this, rWidth, rHeight});
            this.gauges.timeOfDay.rX = [{on:"default", x: x}];
            this.gauges.timeOfDay.rY = [{on:"default", y: y}];

            this.gauges.timeOfDay.position.set(
                this.evaluate(x, 0, 0, 0, 0, this),
                this.evaluate(y, 0, 0, 0, 0, this)
            );

            this.addChild(this.gauges.timeOfDay);
            this.gauges.timeOfDay.name = "timeOfDay";

            this.addMouseEating(this.gauges.timeOfDay);
        }
    }

    buildStatGauges()
    {
        let rWidth = "((ih * 0.95) / 3 * 0.8) * 1.5";

        if (!this.gauges.food)
        {
            this.gauges.food = new Gauge();
            this.gauges.food.init({
                "ui": this,
                "type": this.gauges.food.FOOD,
                rWidth
            });
            this.gauges.food.rX = [{on:"default", x: "iw - width - " + this.rButtonWidth + " / 2"}];
            this.gauges.food.rY = [{on:"default", y: "height / 2"}];
            this.gauges.food.Value = this.CharacterManager.getCharacterHunger() / this.CharacterManager.getCharacterMaxHunger();

            this.addChild(this.gauges.food);
            this.addMouseEating(this.gauges.food);
        }

        if (!this.gauges.energy)
        {
            this.gauges.energy = new Gauge();
            this.gauges.energy.init({
                "ui": this,
                "type": this.gauges.energy.ENERGY,
                rWidth
            });
            this.gauges.energy.rX = [{on:"default", x: "iw - width - " + this.rButtonWidth + " / 2"}];
            this.gauges.energy.rY = [{on:"default", y: "el.prev.y + el.prev.height + (height / 6)", el:{prev: this.gauges.food}}];
            this.gauges.energy.Value = this.CharacterManager.getCharacterEnergy() / this.CharacterManager.getCharacterMaxEnergy();

            this.addChild(this.gauges.energy);
            this.addMouseEating(this.gauges.energy);
        }
        

        if (!this.gauges.stress)
        {
            this.gauges.stress = new Gauge();
            this.gauges.stress.init({
                "ui": this,
                "type": this.gauges.stress.STRESS,
                rWidth
            });
            this.gauges.stress.rX = [{on:"default", x: "iw - width - " + this.rButtonWidth + " / 2"}];
            this.gauges.stress.rY = [{on:"default", y: "el.prev.y + el.prev.height + (height / 6)", el:{prev: this.gauges.energy}}];
            this.gauges.stress.Value = this.CharacterManager.getCharacterStress() / this.CharacterManager.getCharacterMaxStress();

            this.addChild(this.gauges.stress);
            this.addMouseEating(this.gauges.stress);
        }
    }

    //-----------------------------------------
    //  OVERLAY
    //-----------------------------------------
    buildOverlay()
    {
        this.overlay = new GraphicsResponsive().init({"ui": this});
        this.overlay.beginFill(0, this.Values.modal.shadow.opacity);
        this.overlay.drawRect(0,0, 100, 100);
        this.overlay.endFill();

        this.overlay.interactive = true;
        this.overlay.on("click", this.fctOnOverlayClick);

        this.overlay.rX =      [{on: "default", x:"0"}];
        this.overlay.rY =      [{on: "default", y:"0"}];
        this.overlay.rWidth =  [{on: "default", width: "iw"}];
        this.overlay.rHeight = [{on: "default", height: "ih"}];

        this.overlay.interactive = true;
        this.overlay.buttonMode = false;

        this.removeChild(this.overlay);
    }

    /*******************************************
    *   POPUPS
    *******************************************/
    //-----------------------------------------
    //  PAUSE POPUP
    //-----------------------------------------
    showPausePopup()
    {
        if (!this.popups.pause)
        {
            this.popups.pause = new PausePopup();

            this.popups.pause.on(this.popups.pause.EVENT_POPUP_SHOW, this.fctOnPopupShow);
            this.popups.pause.on(this.popups.pause.EVENT_POPUP_HIDE, this.fctOnPopupHide);

            this.popups.pause.init({"ui": this});
        }
        else
        {
            this.popups.pause.show();
        }
    }

    closePausePopup()
    {
        if (this.popups.pause)
        {
            this.popups.pause.hide();
        }
    }

    //-----------------------------------------
    //  CHOICE POPUP
    //-----------------------------------------
    showChoicePopup(strTitle, strDescription, fctPositiveCallback, fctNegativeCallback, bShowCloseButton = true, fctCloseCallback = null)
    {
        if (!this.popups.choice)
        {
            this.popups.choice = new ChoicePopup();

            this.popups.choice.on(this.popups.choice.EVENT_POPUP_SHOW, this.fctOnPopupShow);
            this.popups.choice.on(this.popups.choice.EVENT_POPUP_HIDE, this.fctOnPopupHide);

            if (fctCloseCallback)
            {
                this.popups.choice.once(this.popups.choice.EVENT_POPUP_HIDE, fctCloseCallback);
            }
            
            this.popups.choice.once(this.popups.choice.EVENT_POSITIVE, fctPositiveCallback);
            this.popups.choice.once(this.popups.choice.EVENT_NEGATIVE, fctNegativeCallback);

            this.popups.choice.init({
                "ui": this,
                "title": strTitle,
                "description": strDescription,
                "showClose": bShowCloseButton
            });
        }
        else
        {
            if (fctCloseCallback)
            {
                this.popups.choice.once(this.popups.choice.EVENT_POPUP_HIDE, fctCloseCallback);
            }
            
            this.popups.choice.once(this.popups.choice.EVENT_POSITIVE, fctPositiveCallback);
            this.popups.choice.once(this.popups.choice.EVENT_NEGATIVE, fctNegativeCallback);

            this.popups.choice.show();
        }
    }

    closeChoicePopup()
    {
        if (this.popups.choice)
        {
            this.popups.choice.hide();
        }
    }

    //-----------------------------------------
    //  BACKPACK POPUP
    //-----------------------------------------
    showBackpackPopup()
    {
        if (!this.IsHostile)
        {
            if (!this.popups.closet)
            {
                this.popups.closet = new ClosetPopup();

                this.popups.closet.on(this.popups.closet.EVENT_POPUP_SHOW, this.fctOnPopupShow);
                this.popups.closet.on(this.popups.closet.EVENT_POPUP_HIDE, this.fctOnPopupHide);

                this.popups.closet.init({
                    "ui": this, 
                    "showOnStart": false
                });
            }
        }

        if (!this.popups.backpack)
        {
            this.popups.backpack = new BackpackPopup();

            this.popups.backpack.on(this.popups.backpack.EVENT_POPUP_SHOW, this.fctOnPopupShow);
            this.popups.backpack.on(this.popups.backpack.EVENT_POPUP_HIDE, this.fctOnPopupHide);

            this.popups.backpack.init({
                "ui": this,
                "linked": (this.popups.closet ? this.popups.closet : null)
            });
        }
        else
        {
            this.popups.backpack.show();
        }

        if (this.popups.closet)
        {
            this.popups.closet.show();
            this.updatePopupPositions();
        }
    }

    closeBackpackPopup()
    {
        if (this.popups.backpack)
        {
            this.popups.backpack.hide();
        }

        if (this.popups.closet && this.popups.closet.IsShown)
        {
            this.popups.closet.hide();
        }
    }

    removeBackpackPopup ()
    {
        if (this.popups.backpack)
        {
            this.popups.backpack.destroy();
            this.popups.backpack = null;
        }
    }

    //-----------------------------------------
    //  MAP POPUP
    //-----------------------------------------
    showMapPopup()
    {
        if (!this.popups.map)
        {
            this.popups.map = new MapPopup();

            this.popups.map.on(this.popups.map.EVENT_POPUP_SHOW, this.fctOnPopupShow);
            this.popups.map.on(this.popups.map.EVENT_POPUP_HIDE, this.fctOnPopupHide);

            this.popups.map.init({"ui": this});
        }
        else
        {
            this.popups.map.show();
        }
    }

    closeMapPopup()
    {
        if (this.popups.map)
        {
            this.popups.map.hide();
        }
    }

    //-----------------------------------------
    //  ITEM & MUTATION DESCRIPTION POPUP
    //-----------------------------------------
    showItemDescription(objItem)
    {
        this.showDescriptionPopup(
            objItem.Name,
            objItem.Description,
            Library.get2DTextureFromAtlas3D(objItem.AtlasId, objItem.TextureId)
        );
    }

    showMutationDescription(objMutation)
    {
        this.showDescriptionPopup(
            objMutation.Name,
            objMutation.Description,
            Library.getTextureFromAtlas("ui", this.getMutationIcon(objMutation.Code))
        );
    }

    showDescriptionPopup(strTitle, strDescription, objTexture)
    {
        if (!this.popups.description)
        {
            this.popups.description = new DescriptionPopup();

            this.popups.description.on(this.popups.description.EVENT_POPUP_SHOW, this.fctOnPopupShow);
            this.popups.description.on(this.popups.description.EVENT_POPUP_HIDE, this.fctOnPopupHide);

            this.popups.description.init({
                "ui": this,
                "createOverlay": true,
                "sideBySide": false,
                "title": strTitle,
                "description": strDescription,
                "texture": objTexture
            });
        }
        else
        {
            this.popups.description.updateContent(strTitle, strDescription, objTexture);
            this.popups.description.show();
        }
    }

    updatePopupPositions()
    {
        let objSize = Display.getSize();
        for (let i = 0; i < this.arrShownPopups.length; i++)
        {
            if (this.arrShownPopups[i].IsShown)
            {
                this.arrShownPopups[i].setWindowPosition();
                this.arrShownPopups[i].layout(objSize);
            }
        }
    }

    /*******************************************
    *   OVERLAY
    *******************************************/
    showOverlay()
    {
        if (!this.overlay)
        {
            this.buildOverlay();
        }
        if (!this.overlay.parent)
        {
            this.addToLayoutable(this.overlay);
            this.overlay.layout(Display.getSize());

            return true;
        }
        return false;
    }

    hideOverlay()
    {
        if (this.overlay && this.overlay.parent)
        {
            this.removeFromLayoutable(this.overlay);
            return true;
        }
        return !this.overlay;
    }

    /*******************************************
    *   DIALOGS
    *******************************************/
    showDialog(strSpeaker, strContent, strAudioId = null, fctCloseCallback = null)
    {
        if (!this.dialog)
        {
            this.dialog = new Dialog();

            this.dialog.fctCloseCallback = fctCloseCallback;
            this.dialog.on(this.dialog.EVENT_CLOSE, this.fctDialogClose);

            this.dialog.init({
                "ui": this,
                "title": strSpeaker,
                "content": strContent,
                "audioId": strAudioId
            });

            this.dialog.rX = [{on:"default", x: "iw * 2.5%"}];
            this.dialog.rY = [{on:"default", y: "ih - height - (iw * 2.5%) + (el.title.height / 2)", el:{title:this.dialog.texts.title}}];

            this.dialog.layout(Display.getSize());

            if (!this.WorldManager.IsDialogMode)
            {
                this.WorldManager.startDialogMode();
            }

            return true;
        }
        return false;
    }

    showChoiceDialog(strSpeaker, strContent, arrChoices, strAudioId = null, fctChoiceCallback = null, fctCloseCallback = null)
    {
        if (!this.choiceDialog)
        {
            this.dialog = new ChoiceDialog();

            this.dialog.fctCloseCallback = fctCloseCallback;
            this.dialog.on(this.dialog.EVENT_CLOSE, this.fctDialogClose);
            if (fctChoiceCallback)
            {
                this.dialog.once(this.dialog.EVENT_CHOICE_PICKED, fctChoiceCallback);
            }

            this.dialog.init({
                "ui": this,
                "title": strSpeaker,
                "content": strContent,
                "choices": arrChoices,
                "audioId": strAudioId
            });

            this.dialog.rX = [{on:"default", x: "iw * 2.5%"}];
            this.dialog.rY = [{on:"default", y: "ih - height - (iw * 2.5%) + (el.title.height / 2)", el:{title:this.dialog.texts.title}}];

            this.dialog.layout(Display.getSize());

            if (!this.WorldManager.IsDialogMode)
            {
                this.WorldManager.startDialogMode();
            }

            return true;
        }
        return false;
    }

    /*******************************************
    *   MINIGAMES
    *******************************************/
    startMinigame(strType, iDifficulty, fctCallback)
    {
        if (!this.minigame)
        {
            if (strType == this.UIManager.MINIGAME_TYPE_GADGET)
            {
                this.minigame = new GadgetMinigame(fctCallback);
            }
            else if (strType == this.UIManager.MINIGAME_TYPE_HACKING)
            {
                this.minigame = new HackingMinigame(fctCallback);
            }
            else if (strType == this.UIManager.MINIGAME_TYPE_UNLOCK)
            {
                this.minigame = new UnlockMinigame(fctCallback);
            }
            else
            {
                return false;
            }

            this.minigame.on(this.minigame.EVENT_POPUP_SHOW, this.fctOnPopupShow);
            this.minigame.on(this.minigame.EVENT_POPUP_HIDE, this.fctOnPopupHide);
            this.minigame.on(this.minigame.EVENT_SUCCESS, this.fctOnMinigameSuccess);
            this.minigame.on(this.minigame.EVENT_FAILURE, this.fctOnMinigameFailure);

            this.minigame.init({
                "ui": this,
                "difficulty": iDifficulty
            });

            this.updatePopupPositions();

            return true;
        }
        return false;
    }

    /*******************************************
    *   UPDATE LOOP
    *******************************************/
    update(fDeltaTime)
    {
        super.update(fDeltaTime);
        this.updateGauges(fDeltaTime);
    }

    updateGauges(fDeltaTime)
    {
        if (this.timeOfDay)
        {
            this.timeOfDay.update(fDeltaTime);
        }
    }

    /*******************************************
    *   UTILITIES
    *******************************************/
    canWorldClick(x, y)
    {
        if (this.ShownPopups.length == 0 && !this.minigame)
        {
            return super.canWorldClick(x, y);
        }
        return false;
    }

    showSceneTransition(fctCallbackMiddle = null, fctCallback = null)
    {
        if (!this.screenTransition)
        {
            this.screenTransition = new ScreenTransition().init({"ui": this});
        }

        this.addChild(this.screenTransition);

        this.screenTransition.startTransition(
            fctCallbackMiddle, 
            function(fctCallback) 
            { 
                //this.screenTransition = undefined;
                if (fctCallback)
                {
                    fctCallback();
                }

                this.removeChild(this.screenTransition);
                this.screenTransition.destroy({"children": true});
                delete this.screenTransition;

            }.bind(this, fctCallback)
        );
    }

    /*******************************************
    *   CINEMATICS & DIALOGS
    *******************************************/
    startCinematicMode()
    {
        this.WorldManager.IsCinematicMode = true;
        this.startDialogMode();
    }

    stopCinematicMode()
    {
        this.stopDialogMode();
        this.WorldManager.IsCinematicMode = false;
    }

    startDialogMode(strCharacterToFollow = null, bTiltCamera = false)
    {
        for (let key in this.buttons)
        {
            if (!this.dialogHidden.includes(this.buttons[key]))
            {
                this.removeChild(this.buttons[key]);
                this.removeMouseEating(this.buttons[key]);
                this.dialogHidden.push(this.buttons[key]);
            }
        }
    }

    stopDialogMode()
    {
        for (let i = 0; i < this.dialogHidden.length; i++)
        {
            if (!this.dialogHidden[i].parent)
            {
                this.addChild(this.dialogHidden[i]);
                this.addMouseEating(this.dialogHidden[i]);
            }
        }
        this.dialogHidden = [];
    }

    /*******************************************
    *   TUTORIAL
    *******************************************/
    startTutorial(fctCallback = null)
    {

    }

    stopTutorial()
    {
        
    }

    /*******************************************
    *   EVENTS
    *******************************************/
    onStatChanged(strType, fNewValue, fOldValue)
    {
        if (strType == this.CharacterManager.STAT_HUNGER && this.gauges.food)
        {
            this.gauges.food.Value = this.CharacterManager.getCharacterHunger() / this.CharacterManager.getCharacterMaxHunger();
        }
        else if (strType == this.CharacterManager.STAT_ENERGY && this.gauges.energy)
        {
            this.gauges.energy.Value = this.CharacterManager.getCharacterEnergy() / this.CharacterManager.getCharacterMaxEnergy();
        }
        else if (strType == this.CharacterManager.STAT_STRESS && this.gauges.stress)
        {
            this.gauges.stress.Value = this.CharacterManager.getCharacterStress() / this.CharacterManager.getCharacterMaxStress();
        }
    }

    onPauseClick()
    {
        if (!this.GameManager.IsPaused && !this.WorldManager.IsDialogMode)
        {
            this.showPausePopup();
        }
    }

    onBackpackClick()
    {
        if (!this.GameManager.IsPaused && !this.WorldManager.IsDialogMode)
        {
            this.showBackpackPopup();
        }
    }

    onMapClick()
    {
        if (!this.GameManager.IsPaused && !this.WorldManager.IsDialogMode)
        {
            this.showMapPopup();
        }
    }

    onOverlayClick()
    {
        for (let key in this.popups)
        {
            if (this.popups[key].IsShown)
            {
                this.popups[key].onOverlayClick();
            }
        }
    }

    onPopupShow(sender)
    {
        if (this.arrShownPopups.length == 0 && sender.ShowOverlay)
        {
            this.showOverlay();
        }

        if (sender.PauseOnShow)
        {
            this.GameManager.pause();
        }
        if (_.indexOf(this.arrShownPopups, sender) < 0)
        {
            this.arrShownPopups.push(sender);
        }
    }

    onPopupHide(sender)
    {
        _.remove(this.arrShownPopups, (l) => l === sender);
        if (sender.PauseOnShow)
        {
            let popups = this.ShownPopups;
            let isOk = true;

            for (let i = 0; i < popups.length; i++)
            {
                if (popups[i].PauseOnShow)
                {
                    isOk = false;
                }
            }

            if (isOk)
            {
                this.GameManager.resume();
            }
        }
        this.updatePopupPositions();

        if (this.arrShownPopups.length == 0)
        {
            this.hideOverlay();
        }
    }

    onDialogClose()
    {
        if (this.dialog)
        {
            let callback = null;

            if (this.dialog.fctCloseCallback)
            {
                callback = this.dialog.fctCloseCallback;
            }

            this.removeChild(this.dialog);
            this.dialog.destroy({"children": true});
            delete this.dialog;

            setTimeout(function()
            {
                if (!this.dialog)
                {
                    this.WorldManager.stopDialogMode();
                }
            }
            .bind(this), 1);

            if (callback)
            {
                callback();
            }
        }
    }

    onMinigameSuccess(sender)
    {
        console.log("The", sender.Id, "minigame was a success");
        this.onMinigameEnd(sender);
    }

    onMinigameFailure(sender)
    {
        console.log("The", sender.Id, "minigame ended up in a failure");
        this.onMinigameEnd(sender);
    }

    onMinigameEnd(sender)
    {
        setTimeout(function(sender)
        {
            delete this.minigame;
            sender.closeCallback(sender.success);
            sender.destroy({"children": true});
        }
        .bind(this, sender), 1);
    }

    reset ()
    {

    }
}