import { Point } from '@pixi/math';
import { Rectangle } from '@pixi/math';
import EventEmitter from "eventemitter3";

export default class UnlockControls extends EventEmitter
{
    constructor()
    {
        super();
    }

    get EVENT_CELL_HOVER_CHANGED() { return "cell-hover-changed"; }
    get EVENT_PATH_START() { return "path-start"; }
    get EVENT_PATH_MOVE() { return "path-move"; }
    get EVENT_PATH_END() { return "path-end"; }

    get UI() { return this.ui; }
    get IsRunning() { return this.isRunning; }
    get IsPath() { return this.downId !== null; }

    get Cells() { return this.cells; }
    set Cells(arrNewValue) { this.cells = arrNewValue; }

    get CellHover()
    { 
        if (this.cellHover)
        {
            return this.cells[this.cellHover.y][this.cellHover.x];
        }
        return null;
    }

    /*******************************************
    *   INITIALIZATION
    *******************************************/
    /**
        Parameters to pass to the init function:
        - ui:   UI section object where this component resides
    */
    init(meta)
    {
        this.ui = meta.ui;
        this.cells = [];
        this.cellHover = null;
        this.isRunning = false;
        this.downId = null;

        this.createClosure();
        this.bindEvents();

        return this;
    }

    createClosure()
    {
        this.fctOnMouseDown = this.onMouseDown.bind(this);
        this.fctOnMouseMove = this.onMouseMove.bind(this);
        this.fctOnMouseUp = this.onMouseUp.bind(this);

        this.fctOnTouchStart = this.onTouchStart.bind(this);
        this.fctOnTouchMove = this.onTouchMove.bind(this);
        this.fctOnTouchEnd = this.onTouchEnd.bind(this);
    }

    bindEvents()
    {
        window.addEventListener("mousedown", this.fctOnMouseDown);
        window.addEventListener("mousemove", this.fctOnMouseMove);
        window.addEventListener("mouseup", this.fctOnMouseUp);

        window.addEventListener("touchstart", this.fctOnTouchStart);
        window.addEventListener("touchmove", this.fctOnTouchMove);
        window.addEventListener("touchend", this.fctOnTouchEnd);
    }

    destroy(options)
    {
        window.removeEventListener("mousedown", this.fctOnMouseDown);
        window.removeEventListener("mousemove", this.fctOnMouseMove);
        window.removeEventListener("mouseup", this.fctOnMouseUp);

        window.removeEventListener("touchstart", this.fctOnTouchStart);
        window.removeEventListener("touchmove", this.fctOnTouchMove);
        window.removeEventListener("touchend", this.fctOnTouchEnd);
    }

    /*******************************************
    *   ACTIONS
    *******************************************/
    start()
    {
        this.isRunning = true;
    }

    stop()
    {
        this.isRunning = false;
    }

    /*******************************************
    *   UTILITIES
    *******************************************/
    adjustHorizontalPosition(iX)
    {
        return iX - this.UI.ResponsiveManager.ScreenPadding;
    }

    /*******************************************
    *   HOVER DETECTION
    *******************************************/
    detectHover(iX, iY)
    {
        let prevHover = this.cellHover;
        let hoverCell = null;

        let smallest = new Point(Number.MAX_SAFE_INTEGER, Number.MAX_SAFE_INTEGER);
        let biggest = new Point(0, 0);

        for (let i = 0; i < this.cells.length; i++)
        {
            for (let j = 0; j < this.cells[i].length; j++)
            {
                let rect = this.cells[i][j].rect;
                if (rect.x < smallest.x)
                {
                    smallest.x = rect.x;
                }
                if (rect.y < smallest.y)
                {
                    smallest.y = rect.y;
                }
                if (rect.x + rect.width > biggest.x)
                {
                    biggest.x = rect.x + rect.width;
                }
                if (rect.y + rect.height > biggest.y)
                {
                    biggest.y = rect.y + rect.height;
                }
            }
        }

        //If the user is hovering outside the gameboard rect, we detect the cell that makes most sense and
        //sends it as the hover cell
        let rect = new Rectangle(smallest.x, smallest.y, biggest.x - smallest.x, biggest.y - smallest.y);

        if (rect.contains(iX, iY))
        {
            for (let i = 0; i < this.cells.length; i++)
            {
                for (let j = 0; j < this.cells[i].length; j++)
                {
                    if (this.cells[i][j].rect.contains(iX, iY))
                    {
                        if (!prevHover || prevHover.x != i || prevHover.y != j)
                        {
                            hoverCell = new Point(j, i);
                            break;
                        }
                    }
                }
                if (hoverCell)
                {
                    break;
                }
            }
        }
        else if (this.IsPath)
        {
            if (iX < rect.x || iX > rect.x + rect.width)
            {
                let index = (iX < rect.x ? 0 : (this.cells[0].length - 1));

                for (let i = 0; i < this.cells.length; i++)
                {
                    let cellRect = this.cells[i][index].rect;
                    if (cellRect.contains(cellRect.x, iY))
                    {
                        hoverCell = new Point(index, i);
                        break;
                    }
                }
            }
            else
            {
                let index = (iY < rect.y ? 0 : (this.cells.length - 1));

                for (let i = 0; i < this.cells[index].length; i++)
                {
                    let cellRect = this.cells[index][i].rect;
                    if (cellRect.contains(iX, cellRect.y))
                    {
                        hoverCell = new Point(i, index);
                        break;
                    }
                }
            }
        }

        this.cellHover = hoverCell;
        if (hoverCell)
        {
            if (this.IsPath)
            {
                this.updatePath(this.cells[hoverCell.y][hoverCell.x]);
            }
            this.emit(this.EVENT_CELL_HOVER_CHANGED, this.cells[hoverCell.y][hoverCell.x]);
        }
        else
        {
            if (prevHover && !this.IsPath)
            {
                this.emit(this.EVENT_CELL_HOVER_CHANGED, null);
            }
        }
    }

    startPath(iX, iY, iIdentifier)
    {
        let cell = null;

        for (let i = 0; i < this.cells.length; i++)
        {
            for (let j = 0; j < this.cells[i].length; j++)
            {
                if (this.cells[i][j].rect.contains(iX, iY))
                {
                    cell = this.cells[i][j];
                    break;
                }
            }
            if (cell)
            {
                break;
            }
        }

        if (cell && cell.pin)
        {
            this.downId = iIdentifier;
            this.emit(this.EVENT_PATH_START, cell);
        }
    }

    updatePath(cell)
    {
        this.emit(this.EVENT_PATH_MOVE, cell);
    }

    endPath()
    {
        this.downId = null;
        this.emit(this.EVENT_PATH_END);
    }

    /*******************************************
    *   EVENTS
    *******************************************/
    onMouseDown(e)
    {
        if (this.IsRunning && !this.IsPath)
        {
            this.startPath(
                this.adjustHorizontalPosition(e.clientX), 
                e.clientY, 
                1
            );
        }
    }

    onMouseMove(e)
    {
        if (this.IsRunning)
        {
            this.detectHover(
                this.adjustHorizontalPosition(e.clientX), 
                e.clientY
            );
        }
    }

    onMouseUp(e)
    {
        if (this.IsRunning && this.IsPath)
        {
            this.endPath();
        }
    }

    onTouchStart(e)
    {
        if (this.IsRunning && !this.IsPath)
        {
            let touch = e.touches[0];
            this.startPath(
                this.adjustHorizontalPosition(touch.clientX), 
                touch.clientY, 
                touch.identifier
            );
        }
    }

    onTouchMove(e)
    {
        if (this.IsRunning && this.IsPath)
        {
            for(let i = 0; i < e.touches.length; i++)
            {
                if (e.touches[i].identifier == this.downId)
                {
                    let touch = e.touches[i];
                    this.detectHover(
                        this.adjustHorizontalPosition(touch.clientX), 
                        touch.clientY
                    );
                }
            }
        }
    }

    onTouchEnd(e)
    {
        if (this.IsRunning && this.IsPath)
        {
            let isIn = false;
            for(let i = 0; i < e.touches.length; i++)
            {
                if (e.touches[i].identifier == this.downId)
                {
                    isIn = true;
                    break;
                }
            }

            if (!isIn)
            {
                this.endPath();
            }
        }
    }
}