import MapItem from "./MapItem.js";
import ItemCodes from "./codes/ItemCodes.js";
import ItemManager from "./ItemManager.js";

export default class EquipmentItem extends MapItem
{
    constructor(strId, objDefinition)
    {
        super(strId, objDefinition);

        this.type = ItemCodes.TYPE_EQUIPMENT;
        this.equipDefinition = this.definition.equip;
    }

    get BGColor() { return ItemManager.instance.GameManager.getSetting("character").backpack.itemColor.equipment; }

    get EquipLocation() { return this.equipDefinition.location; }
    get IsMutation() { return false; }
    get Icon() { return "icon" in this.equipDefinition ? this.equipDefinition.icon : null; }

    //Fishing Rod
    get CanFish() { return "fish" in this.equipDefinition; }
    get FishChances() { return (this.CanFish ? this.equipDefinition.fish.chances : 0); }
    get FishQuantity() { return (this.CanFish ? this.equipDefinition.fish.qty : 0); }
    get FishItem() { return (this.CanFish ? this.equipDefinition.fish.item : null); }

    //Animal Trap
    get CanTrap() { return "trap" in this.equipDefinition && this.equipDefinition.trap; }
    get TrapObstacle() { return (this.CanTrap ? this.equipDefinition.trap : null); }

    //Anti-stress tools
    get CanDropStress() { return "stress" in this.equipDefinition; }
    get StressDropAmount() { return (this.CanDropStress ? this.equipDefinition.stress.amount : false); }
    get StressDropInterval() { return (this.CanDropStress ? this.equipDefinition.stress.interval : false); }

    //Stat bonuses
    get EnergyLossBonus() { return ("energyloss" in this.equipDefinition ? this.equipDefinition.energyloss : 0); }
    get StressLossBonus() { return ("stressloss" in this.equipDefinition ? this.equipDefinition.stressloss : 0); }
    get SearchSpeedBonus() { return ("searchspeed" in this.equipDefinition ? this.equipDefinition.searchspeed : 0); }
    get ExtraMaterialBonus() { return ("extramat" in this.equipDefinition ? this.equipDefinition.extramat : 0); }
    get SprintDurationBonus() { return ("sprint" in this.equipDefinition ? this.equipDefinition.sprint : 0); }
    get WalkSpeedBonus() { return ("walkspeed" in this.equipDefinition ? this.equipDefinition.walkspeed : 0); }
    get MaxEnergyBonus() { return ("maxenergy" in this.equipDefinition ? this.equipDefinition.maxenergy : 0); }
    get MaxHungerBonus() { return ("maxhunger" in this.equipDefinition ? this.equipDefinition.maxhunger : 0); }
    get MaxStressBonus() { return ("maxstress" in this.equipDefinition ? this.equipDefinition.maxstress : 0); }
    get SprintCooldownReduction() { return ("sprintcooldown" in this.equipDefinition ? this.equipDefinition.sprintcooldown : 0); }

    //Crafting
    get IsCraftable() { return "craft" in this.equipDefinition; }
    get CraftRequirements() { if (this.craftReq === -1) { this.calculateCraftRequirements(this.equipDefinition.craft); } return this.craftReq;}
    get CraftResultAmount() { return (this.IsCraftable ? this.equipDefinition.craft.qty : 1); }
    get CraftLevel() { return (this.IsCraftable ? this.definition.level : 1); }

    use ()
    {
        if (this.CanDropStress)
        {
            ItemManager.instance.CharacterManager.startStressTool(this.StressDropAmount, this.StressDropInterval);
        }
    }

    stopUsing ()
    {
        if (this.CanDropStress)
        {
            ItemManager.instance.CharacterManager.stopStressTool(this.StressDropAmount, this.StressDropInterval);
        }
    }
}