import BaseMinigame from "../BaseMinigame.js";
import Display from "../../../utils/Display.js";
import GadgetCell from "./components/GadgetCell.js";
import GraphicsResponsive from "../../commons/responsive/GraphicsResponsive.js";
import Library from "../../../Library.js";
import SpriteResponsive from "../../commons/responsive/SpriteResponsive.js";

export default class GadgetMinigame extends BaseMinigame
{


    get GRID_WIDTH() { return 4; }
    get GRID_HEIGHT() { return 4; }

    get WRONG_ANIMATION_TIME() { return 2; }

    get Id() { return GadgetMinigame.TYPE_GADGET;}
    get Sequences() { return this.Balancing.sequences; }
    get TimeBetweenNumbers() { return this.Balancing.timeBetweenNumbersShow; }
    get TimeBeforeNumbersHide() { return this.Balancing.timeBeforeNumbersHide; }
    get TimePenalty() { return this.Balancing.timePenalty; }
    get IsWrong() { return this.wrongTimeLeft > 0; }

    get GridWidth() { return this.GridHeight; }
    get GridHeight() { return "(" + this.BgHeight + " * 0.825)"; }
    get GridX() { return "(" + this.BgX + " + " + this.BgWidth + " / 2 - " + this.GridWidth + " / 2)"; }
    get GridY() { return "(" + this.BgY + " + " + this.BgHeight + " / 2 - " + this.GridHeight + " / 2)"; }
    get GridCornerRadius() { return "(ih * 0.01)"; }

    get CellWidth() { return "(" + this.GridWidth + " / " + this.GRID_WIDTH + ")"; }
    get CellHeight() { return "(" + this.GridHeight + " / " + this.GRID_HEIGHT + ")"; }
    get CellCornerRadius() { return "(" + this.GridCornerRadius + ") * (2/3)"; }

    get CloseButtonX() { return this.BgX + " + " + this.BgWidth + " - " + this.closeButton.ButtonWidth + " * 1.15"; }
    get CloseButtonY() { return (this.BgY + " + " + this.closeButton.ButtonHeight + " * 1.4"); }

    get TimerY() { return "(" + this.BgY + ")"; }

    get GridBackroundColor() { return 0x0E0E0E; }

    /*******************************************
    *   INITIALIZATION
    *******************************************/
    /**
        Parameters to pass to the init function:
        - ui:           UI section object where this component resides
        - difficulty:   Difficulty to set the game to. The value should fit the difficulty levels set in the settings
        - showOnStart   (Optional) If the popup should be shown on start. Default is TRUE
        - createOverlay (Optional) If this popup should create it's own overlay for itself. If not, it will use the general UI overlay. Default is FALSE
        - linked        (Optional) Linked popup to this one. If this one closes, the linked popup will close too. Default is NULL
    */
    init(meta)
    {
        this.grid = [];
        this.sequenceIndex = 0;
        this.currentSquence = null;
        this.wrongTimeLeft = 0;
        this.actionDelay = null;

        super.init(meta);

        this.showMessage(
            this.UI.LabelManager.translate("MINIGAME_GADGET_INTRO"),
            this.UI.LabelManager.translate("MINIGAME_BTN_START")
        );

        return this;
    }

    createClosure()
    {
        super.createClosure();
        this.fctOnGridCellClick = this.onGridCellClick.bind(this);
    }

    destroy(options)
    {
        for (let i = 0; i < this.grid.length; i++)
        {
            for (let j = 0; j < this.grid[i].length; j++)
            {
                this.grid[i][j].off(this.grid[i][j].EVENT_CLICK, this.fctOnGridCellClick);
                this.removeChild(this.grid[i][j]);
                this.grid[i][j].destroy(options);
            }
        }
        delete this.grid;

        super.destroy(options);
    }

    /*******************************************
    *   BUILD
    *******************************************/
    build()
    {
        super.build();

        this.buildLights();
        this.buildTimer();
        this.buildGrid();

        if (this.message)
        {
            this.removeChild(this.message);
            this.addChild(this.message);
        }
    }

    buildLights()
    {
        for (let i = 0; i < 4; i++)
        {
            let lightId = this.Id + "_sidelight";
            if (i % 2)
            {
                lightId += "_wrong";
            }

            let key = (i < 2 ? "left_" : "right_") + lightId; 

            if (!this.sprites[key])
            {
                let texture = Library.getTextureFromAtlas(this.AtlasId, lightId + ".png");
                this.sprites[key] = new SpriteResponsive(texture).init({"ui": this.UI});

                this.sprites[key].width = texture.orig.width;
                this.sprites[key].height = texture.orig.height;

                this.addChild(this.sprites[key]);
            }
            this.removeChild(this.sprites[key]);
        }

        let left = this.sprites["left_" + this.Id + "_sidelight" + (this.IsWrong ? "_wrong" : "")];
        let right = this.sprites["right_" + this.Id + "_sidelight" + (this.IsWrong ? "_wrong" : "")];

        let zoneWidth = "((" + this.BgWidth + " - " + this.GridWidth + ") / 2)";

        let ratio = left.width / left.height;
        let spriteHeight = this.evaluate(this.BgHeight, 0, 0, 0, 0) * 0.125;
        let spriteWidth = spriteHeight * ratio;

        left.width = spriteWidth;
        left.height = spriteHeight;
        right.width = spriteWidth;
        right.height = spriteHeight;

        left.rX = [{on:"default", x: this.BgX + " + " + zoneWidth + " * 0.825 - " + spriteWidth + "/ 2"}];
        left.rY = [{on:"default", y: this.BgY + " + " + this.BgHeight + " / 2 - " + spriteHeight + "/ 2"}];

        right.rX = [{on:"default", x: this.BgX + " + " + this.BgWidth + " - " + zoneWidth + " * 0.825 - " + spriteWidth + "/ 2"}];
        right.rY = [{on:"default", y: this.BgY + " + " + this.BgHeight + " / 2 - " + spriteHeight + "/ 2"}];

        this.addChild(left);
        this.addChild(right);

    }

    buildGrid()
    {
        this.buildGridCells();
        this.buildGridBackground();
    }

    buildGridBackground()
    {
        if (!this.graphics.gridBackground)
        {
            this.graphics.gridBackground = new GraphicsResponsive().init({"ui": this.UI});
            this.addChildAt(this.graphics.gridBackground, 1);
        }

        let cellMargin = this.grid[0][0].CellMargin;
        let width = this.evaluate(this.GridWidth + " + " + cellMargin, 0, 0, 0, 0);
        let height = this.evaluate(this.GridHeight + " + " + cellMargin, 0, 0, 0, 0);
        let corner = this.evaluate(this.GridCornerRadius, 0, 0, 0, 0);

        let graphics = this.graphics.gridBackground;

        graphics.beginFill(this.GridBackroundColor, 1);
        graphics.drawRoundedRect(0, 0, width, height, corner);
        graphics.endFill();

        graphics.rX = [{on:"default", x: this.GridX + " - " + cellMargin + " * 0.666"}];
        graphics.rY = [{on:"default", y: this.GridY + " - " + cellMargin + " / 2"}];
        graphics.layout(Display.getSize());
    }

    buildGridCells()
    {
        if (this.grid.length == 0)
        {
            let rWidth = this.CellWidth;
            let rHeight = this.CellHeight;
            let rCorner = this.CellCornerRadius;

            for (let i = 0; i < this.GRID_HEIGHT; i++)
            {
                let row = [];
                for (let j = 0; j < this.GRID_WIDTH; j++)
                {
                    let cell = new GadgetCell().init({
                        "ui": this.UI,
                        "position": {"x": j, "y": i},
                        rWidth,
                        rHeight,
                        rCorner
                    });
                    cell.on(cell.EVENT_CLICK, this.fctOnGridCellClick);
                    this.addChild(cell);

                    row.push(cell);
                }
                this.grid.push(row);
            }
        }

        let size = Display.getSize();
        for (let i = 0; i < this.grid.length; i++)
        {
            for (let j = 0; j < this.grid[i].length; j++)
            {
                let cell = this.grid[i][j];

                cell.rX = [{on:"default", x: this.GridX + " + " + this.CellWidth + " * " + j}];
                cell.rY = [{on:"default", y: this.GridY + " + " + this.CellHeight + " * " + i}];

                cell.layout(size);
            }
        }
    }

    /*******************************************
    *   ACTIONS
    *******************************************/
    startNewSequence()
    {
        let amount = this.Sequences[this.sequenceIndex];
        let picks = this.pickSequenceNumbers(amount);

        this.deactivateAllCells();

        let cells = [];
        for (let i = 0; i < picks.length; i++)
        {
            let y = Math.floor(picks[i] / this.GRID_WIDTH);
            let x = picks[i] - y * this.GRID_WIDTH;

            this.grid[y][x].activate(i + 1);
            cells.push(this.grid[y][x]);
        }

        this.sequence = {
            cells,
            "clickIndex": 0,
            "animation": {
                "totalTime": this.TimeBetweenNumbers,
                "timeLeft": this.TimeBetweenNumbers / 2,
                "endTime": this.TimeBeforeNumbersHide,
                "index": -1
            }
        };

        if (this.sequenceIndex == 0)
        {
            this.timer.startTimer(this.TotalTime);
            this.timer.pauseTimer();
        }
    }

    endSequence()
    {
        this.sequenceIndex++;
        this.timer.pauseTimer();

        if (this.sequenceIndex < this.Sequences.length)
        {
            this.actionDelay = {
                "fct": this.startNewSequence.bind(this),
                "timeLeft": 1
            };
        }
        else
        {
            for (let i = 0; i < this.grid.length; i++)
            {
                for (let j = 0; j < this.grid[i].length; j++)
                {
                    if (!this.grid[i][j].IsNumberShown)
                    {
                        this.grid[i][j].activate(0);
                    }
                }
            }
            this.actionDelay = {
                "fct": this.winGame.bind(this),
                "timeLeft": 1
            };
        }
    }

    deactivateAllCells()
    {
        for (let i = 0; i < this.grid.length; i++)
        {
            for (let j = 0; j < this.grid[i].length; j++)
            {
                this.grid[i][j].hideNumber();
                this.grid[i][j].CanClick = false;
                this.grid[i][j].deactivate();
            }
        }
    }

    startPlay()
    {
        for (let i = 0; i < this.sequence.cells.length; i++)
        {
            this.sequence.cells[i].hideNumber();
            this.sequence.cells[i].CanClick = true;
            this.timer.resumeTimer();
        }
    }

    validateCellChoice(cell)
    {
        if (this.sequence && this.sequence.cells.length > this.sequence.clickIndex && !cell.IsNumberShown)
        {
            let goodAnswer = this.sequence.cells[this.sequence.clickIndex];
            //Good choice
            if (cell.Position.x == goodAnswer.Position.x && cell.Position.y == goodAnswer.Position.y)
            {
                this.sequence.clickIndex++;
                cell.showNumber();

                if (this.sequence.clickIndex >= this.sequence.cells.length)
                {
                    this.endSequence();
                }
            }
            //Wrong choice
            else
            {
                this.wrongTimeLeft = this.WRONG_ANIMATION_TIME;
                cell.setWrong(this.wrongTimeLeft);
                this.timer.substractTime(this.TimePenalty);

                this.buildLights();
            }
        }
    }

    winGame()
    {
        this.success = true;
        this.onCloseClick();
    }

    looseGame()
    {
        for (let i = 0; i < this.grid.length; i++)
        {
            for (let j = 0; j < this.grid[i].length; j++)
            {
                this.grid[i][j].hideNumber();
                this.grid[i][j].CanClick = false;
                this.grid[i][j].setWrong(2);
            }
        }

        this.actionDelay = {
            "fct": this.onCloseClick.bind(this),
            "timeLeft": 1
        };
    }

    /*******************************************
    *   SEQUENCE MANAGEMENT
    *******************************************/
    pickSequenceNumbers(iAmount)
    {
        let pool = [];
        let picks = [];

        for (let i = 0; i < this.GRID_WIDTH * this.GRID_HEIGHT; i++)
        {
            pool.push(i);
        }

        for (let i = 0; i < iAmount; i++)
        {
            let index = Math.floor(Math.random() * pool.length);
            picks.push(pool[index]);

            pool.splice(index, 1);
        }

        return picks;
    }

    /*******************************************
    *   UPDATE LOOP
    *******************************************/
    update(fDeltaTime)
    {
        super.update(fDeltaTime);

        this.updateGridCells(fDeltaTime);
        this.updateSequenceAnimation(fDeltaTime);
        this.updateWrongLights(fDeltaTime);
        this.updateActionDelay(fDeltaTime);
    }

    updateGridCells(fDeltaTime)
    {
        for (let i = 0; i < this.grid.length; i++)
        {
            for (let j = 0; j < this.grid[i].length; j++)
            {
                this.grid[i][j].update(fDeltaTime);
            }
        }
    }

    updateSequenceAnimation(fDeltaTime)
    {
        if (this.sequence && this.sequence.animation && this.sequence.animation.timeLeft > 0)
        {
            this.sequence.animation.timeLeft -= fDeltaTime;
            if (this.sequence.animation.timeLeft <= 0)
            {
                this.sequence.animation.index++;
                if (this.sequence.animation.index < this.sequence.cells.length)
                {
                    this.sequence.cells[this.sequence.animation.index].showNumber();
                    this.sequence.animation.timeLeft = this.sequence.animation.totalTime;
                }
                else if (this.sequence.animation.index >= this.sequence.cells.length + 1)
                {
                    this.startPlay();
                }
                else
                {
                    this.sequence.animation.timeLeft = this.sequence.animation.endTime;
                    if (this.sequence.animation.timeLeft <= 0)
                    {
                        this.startPlay();
                    }
                }
            }
        }
    }

    updateWrongLights(fDeltaTime)
    {
        if (this.wrongTimeLeft > 0)
        {
            this.wrongTimeLeft -= fDeltaTime;
            if (this.wrongTimeLeft <= 0)
            {
                this.buildLights();
            }
        }
    }

    updateActionDelay(fDeltaTime)
    {
        if (this.actionDelay && this.actionDelay.timeLeft > 0)
        {
            this.actionDelay.timeLeft -= fDeltaTime;
            if (this.actionDelay.timeLeft <= 0)
            {
                this.actionDelay.fct();
            }
        }
    }

    /*******************************************
    *   EVENTS
    *******************************************/
    onMessageButtonClick()
    {
        super.onMessageButtonClick();
        this.startNewSequence();
    }

    onGridCellClick(sender)
    {
        this.validateCellChoice(sender);
    }

    onTimerEnd(sender)
    {
        super.onTimerEnd();
        this.looseGame();
    }
}