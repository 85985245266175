import { TextStyle } from '@pixi/text';
import { TextMetrics } from '@pixi/text';
import BasePopup from "../BasePopup.js";
import Constants from "../../../utils/Constants.js";
import GraphicsResponsive from "../responsive/GraphicsResponsive.js";
import Lerp from "../../../utils/Lerp.js";
import Library from "../../../Library.js";
import SpriteResponsive from "../responsive/SpriteResponsive.js";
import TextResponsive from "../responsive/TextResponsive.js";

export default class Dialog extends BasePopup
{
    constructor()
    {
        super();
    }

    get EVENT_CLOSE() { return "close"; }
    get ANIMATION_TIME() { return 3; }
    get WAIT_TIME_ON_OPEN() { return 0.5; }

    get IsActive () { return this.isActive; }

    get BgWidth() { return `(iw * 95%)`; }
    get BgHeight() { return `ih * 15%`; }

    get StrokeWidth() { return this.evaluate("5 * (ih/1080)", 0,0,0,0, this); }

    get DialogFontSize()
    {
        let fontSize = this.evaluate(this.valueFormula(this.UI.Values.general.text.size), 0, 0, 0, 0, this);
        fontSize = Math.max(fontSize, this.UI.ResponsiveManager.DialogMinFontSize);

        return fontSize;
    }

    /*******************************************
    *   INITIALIZATION
    *******************************************/
    /**
        Parameters to pass to the init function:
        - ui:           UI section object where this dialog resides
        - title:        Speaker's name to display in the dialog
        - content:      Message to display in the dialog
        - audioId:      (Optional) Id of the audio sound to play when displaying this dialog. Default is NULL
        - showOnStart   (Optional) If the dialog should be shown on start. Default is TRUE
    */
    init(meta)
    {
        meta.showClose = false;

        this.content = meta.content;
        this.audioId = ("audioId" in meta ? meta.audioId : null);

        this.animTime = 0;
        this.timeBeforeClick = this.WAIT_TIME_ON_OPEN;

        this.isActive = true;
        this.canClose = false;
        this.values = {};

        return super.init(meta);
    }

    createClosure ()
    {
        super.createClosure();

        this.fctOnPointerDown = this.onPointerDown.bind(this);
        this.fctOnNextButtonClick = this.onNextClick.bind(this);
        this.fctUpdate = this.update.bind(this);
    }

    bindEvents()
    {
        super.bindEvents();

        this.UI.GameManager.on(this.UI.GameManager.EVENT_UPDATE, this.fctUpdate);

        window.addEventListener("pointerdown", this.fctOnPointerDown);
        window.addEventListener("pointerup", this.fctOnNextButtonClick);
    }

    destroy(options)
    {
        this.UI.GameManager.off(this.UI.GameManager.EVENT_UPDATE, this.fctUpdate);

        window.removeEventListener("pointerdown", this.fctOnPointerDown);
        window.removeEventListener("pointerup", this.fctOnNextButtonClick);

        if (this.texts.textbox)
        {
            this.removeChild(this.texts.textbox);
            this.texts.textbox.destroy(options);
            delete this.texts.textbox;
        }

        if (this.btnNext)
        {
            this.removeChild(this.btnNext);
            this.btnNext.destroy(options);
            delete this.btnNext;
        }

        super.destroy(options);
    }

    /*******************************************
    *   BUILD
    *******************************************/
    build ()
    {
        this.calculateResponsiveValues();
        super.build();

        this.buildText();
        this.buildContinueButton();
    }

    buildBackground()
    {
        super.buildBackground();

        this.graphics.background.interactive = true;
        this.graphics.background.buttonMode = true;
    }

    buildTitle(fPaddingHorizontalRatio = -1, fPaddingVerticalRadio = 0.5)
    {
        super.buildTitle(fPaddingHorizontalRatio, fPaddingVerticalRadio);

        let bg = this.graphics.title;
        let text = this.texts.title;

        let height = text.height * (1 + fPaddingVerticalRadio);
        let x = height / 2 + this.evaluate(this.BgX, 0, 0, 0, 0, this);
        let y = -height / 2 + this.evaluate(this.BgY, 0, 0, 0, 0, this);

        bg.position.set(x, y);
        text.position.set(
            x + bg.width / 2 - text.width / 2,
            y + height / 2 - text.height / 2
        );
    }

    buildText()
    {
        let fontSize = this.DialogFontSize;

        let width = this.evaluate(`${this.BgWidth} - (${this.texts.title.x} * 2)`, 0,0,0,0, this);
        let bgHeight = this.evaluate(this.BgHeight, 0, 0, 0, 0, this);

        let style = new TextStyle({
            fontFamily: this.UI.Values.general.text.font,
            fontSize,
            fontWeight: this.UI.Values.general.text.weight,
            fill: this.UI.Values.general.text.color,
            align: "left",
            wordWrap: true,
            wordWrapWidth: width * 0.8
        });
        if (!this.texts.textbox)
        {
            this.texts.textbox = new TextResponsive(this.content, style).init({"ui": this.UI});
            this.addChild(this.texts.textbox);
        }

        let metrics = TextMetrics.measureText(this.content, style);

        this.texts.textbox.style = style;
        this.texts.textbox.x = this.texts.title.x;
        this.texts.textbox.y = (this.texts.title.y + this.texts.title.height / 2)  + bgHeight / 2 - metrics.height / 2;
    }

    buildContinueButton ()
    {
        let texture = Library.getTextureFromAtlas("ui", "arrow_ltr");

        if (!this.btnNext)
        {
            this.btnNext = new SpriteResponsive(texture).init({"ui": this.UI});
            this.addChild(this.btnNext);
        }

        let ratio = this.btnNext.width / this.btnNext.height;
        let height = this.evaluate("(ih * 3.5%)", 0, 0, 0, 0, this);
        let width = height * ratio;
        let x = this.evaluate(this.BgWidth + " - ih * 1% + (" + this.BgX + ")", 0, 0, 0, 0, this) - width;
        let y = this.evaluate("(" + this.BgHeight + ") - (ih * 3.5%) / 2 + (" + this.BgY + ")", 0, 0, 0, 0, this) - height;

        this.btnNext.width = width;
        this.btnNext.height = height;
        this.btnNext.position.set(x, y);
    }

    /*******************************************
    *   RESPONSIVE
    *******************************************/
    calculateResponsiveValues()
    {
        this.values.continueHeight = this.evaluate("(ih * 3.5%)", 0, 0, 0, 0, this);
        this.values.continueX = this.evaluate(this.BgWidth + " - ih * 1% + (" + this.BgX + ")", 0, 0, 0, 0, this);
        this.values.continueY = this.evaluate("(" + this.BgHeight + ")  - (ih * 3.5%) / 2 + (" + this.BgY + ")", 0, 0, 0, 0, this);
    }

    /*******************************************
    *   UPDATE LOOP
    *******************************************/
    update(fDeltaTime)
    {
        if (this.btnNext && !this.btnNext._destroyed)
        {
            if (this.timeBeforeClick > 0)
            {
                this.timeBeforeClick -= fDeltaTime;
                if (this.timeBeforeClick <= 0)
                {
                    this.canClose = true;
                }
            }

            this.animTime -= fDeltaTime;
            let t = (this.animTime % this.ANIMATION_TIME) / this.ANIMATION_TIME;
            t = Math.sin(t * Math.PI) * -1;

            let scale = Lerp.lerp(0.9, 1, t);

            let ratio = this.btnNext.width / this.btnNext.height;
            let height = this.values.continueHeight;
            let width = height * ratio;
            let x = this.values.continueX - width + width * (1 - scale) / 2;
            let y = this.values.continueY - height + height * (1 - scale) / 2;

            this.btnNext.width = width * scale;
            this.btnNext.height = height * scale;
            this.btnNext.position.set(x, y);
        }
    }

    /*******************************************
    *   ACTIONS
    *******************************************/
    show()
    {
        if (!this.IsShown)
        {
            this.UI.addToLayoutable(this);
            if (this.audioId)
            {
                this.UI.AudioManager.playDialog(this.audioId);
            }

            setTimeout(function()
            {
                this.canClose = true;
            }
            .bind(this), Constants.getValue("DIALOG_TIME_BEFORE_ALLOW_CLOSE") * 1000);
        }
    }

    hide()
    {
        if (this.IsShown)
        {
            if (this.LinkedPopup)
            {
                this.LinkedPopup.hide();
            }
            this.UI.removeFromLayoutable(this);

            this.UI.AudioManager.stopDialog();
            this.emit(this.EVENT_CLOSE, this);
            
        }
    }

    /*******************************************
    *   EVENTS
    *******************************************/
    onPointerDown()
    {
        this.isDown = true;
    }

    onNextClick()
    {
        if (this.canClose && this.isDown && !this.hasEmit)
        {
            setTimeout(function()
            {
                this.hasEmit = true;
                this.hide();
            }
            .bind(this), 1);
        }
    }
}