import { Graphics } from '@pixi/graphics';
import ActionButton from "../commons/buttons/ActionButton.js";
import BarnTutorial from "../tutorial/BarnTutorial.js";
import CharacterSelectionButton from "./components/CharacterSelectionButton.js";
import CookingZonePopup from "./components/CookingZonePopup.js";
import CraftingZonePopup from "./components/CraftingZonePopup.js";
import GameUI from "../GameUI.js";
import Display from "../../utils/Display.js";
import Gauge from "../commons/gauges/Gauge.js";
import RelaxZonePopup from "./components/RelaxZonePopup.js";
import SleepZonePopup from "./components/SleepZonePopup.js";
import TimeOfDayGauge from "../commons/gauges/TimeOfDayGauge.js";

export default class BarnUI extends GameUI
{
    constructor()
    {
        super();
    }

    get Id() { return "barn"; }

    /*******************************************
    *   INITIALIZATION
    *******************************************/
    /**
        Parameters to pass to the init function:
        - dependencies:     Dependency injector object
    */
    init(meta)
    {
        return super.init(meta);
    }

    createClosure()
    {
        super.createClosure();
        this.fctOnCharacterSelectClick = this.onCharacterSelectClick.bind(this);
    }

    /*******************************************
    *   BUILD
    *******************************************/
    buildActionButtons()
    {
        super.buildActionButtons();
        this.buildCharacterButtons();
    }

    buildCharacterButtons()
    {
        for (let i = 0; i < 3; i++)
        {
            let id = "character" + i;

            if (!this.buttons[id])
            {
                let button = new CharacterSelectionButton().init({
                    "ui": this,
                    "character": i
                });
                button.on(button.EVENT_CLICK, this.fctOnCharacterSelectClick);//@

                button.rX = [{on: "default", x:"iw - " + button.ButtonWidth + " - " + this.rButtonWidth  + "/ 2"}];
                button.rY = [{on: "default", y:"ih * 33% + (" + button.ButtonHeight + ") * 1.1 * " + i}];

                this.buttons[id] = button;
                
                this.addChild(this.buttons[id]);

                this.buttons[id].name = id;
                this.addMouseEating(this.buttons[id])
            }
        }
    }

    /*******************************************
    *   POPUPS
    *******************************************/
    //-----------------------------------------
    //  ZONE POPUP
    //-----------------------------------------
    showZonePopup(strZoneId)
    {
        if (!this.popups[strZoneId])
        {
            if (strZoneId == this.WorldManager.BARN_ZONE_SLEEP)
            {
                this.popups[strZoneId] = new SleepZonePopup();
            }
            else if (strZoneId == this.WorldManager.BARN_ZONE_RELAX)
            {
                this.popups[strZoneId] = new RelaxZonePopup();
            }
            else if (strZoneId == this.WorldManager.BARN_ZONE_COOKING)
            {
                this.popups[strZoneId] = new CookingZonePopup();
            }
            else if (strZoneId == this.WorldManager.BARN_ZONE_CRAFTING)
            {
                this.popups[strZoneId] = new CraftingZonePopup();
            }
            
            if (!this.popups[strZoneId])
            {
                return false;
            }

            this.popups[strZoneId].on(this.popups[strZoneId].EVENT_POPUP_SHOW, this.fctOnPopupShow);
            this.popups[strZoneId].on(this.popups[strZoneId].EVENT_POPUP_HIDE, this.fctOnPopupHide);

            this.popups[strZoneId].init({"ui": this});
        }
        else
        {
            this.popups[strZoneId].show();
        }

        return true;
    }

    destroyZonePopup(sender)
    {
        for (let key in this.popups)
        {
            if (this.popups[key] == sender)
            {
                sender.hide();
                sender.destroy({"children": true});
                delete this.popups[key];
                break;
            }
        }
    }

    showWakeUpWindow(iCharacter, strStatType)
    {
        
    }

    /*******************************************
    *   ZONES
    *******************************************/
    getZoneTextureId(strId, iLevel)
    {
        return this.WorldManager.Environment.getZoneTextureId(strId, iLevel);
    }

    /*******************************************
    *   TUTORIAL
    *******************************************/
    startTutorial(fctCallback = null)
    {
        if (!this.tutorial)
        {
            this.tutorial = new BarnTutorial().init({
                "ui": this,
                "callback": function(fctCallback)
                {
                    this.tutorial.destroy({"children": true});
                    delete this.tutorial;

                    fctCallback();
                }
                .bind(this, fctCallback)
            });
            this.addChild(this.tutorial);
            this.tutorial.start();
        }
    }

    stopTutorial()
    {
        if (this.tutorial)
        {
            this.tutorial.destroy({"children": true});
            delete this.tutorial;
        }
    }

    /*******************************************
    *   EVENTS
    *******************************************/
    onCharacterSelectClick(sender)
    {
        this.CharacterManager.switchCurrentCharacter(sender.Character);

        let foodMax =  this.CharacterManager.getCharacterMaxHunger();
        let energyMax =  this.CharacterManager.getCharacterMaxEnergy();
        let stressMax =  this.CharacterManager.getCharacterMaxStress();

        let hunger =  this.CharacterManager.getCharacterHunger();
        let energy =  this.CharacterManager.getCharacterEnergy();
        let stress =  this.CharacterManager.getCharacterStress();

        this.gauges.food.Value =  hunger / foodMax;
        this.gauges.energy.Value = energy / energyMax;
        this.gauges.stress.Value = stress / stressMax;

        //We delete the backpack popup on a character switch to prevent a lot
        //of possible cases that might cause us problems especially regarding the
        //backpack as it is one of the more complex thing from the first season
        if (this.popups.backpack)
        {
            if (this.popups.backpack.IsShown)
            {
                this.popups.backpack.hide();
            }

            this.popups.backpack.destroy({"children": true});

            this.popups.backpack.off(this.popups.backpack.EVENT_POPUP_SHOW, this.fctOnPopupShow);
            this.popups.backpack.off(this.popups.backpack.EVENT_POPUP_HIDE, this.fctOnPopupHide);

            delete this.popups.backpack;
        }
    }
}