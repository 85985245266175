import { string2hex }  from '@pixi/utils'; 
import { TextStyle } from '@pixi/text';
import { TextMetrics } from '@pixi/text';
import CloseButton from "../../commons/buttons/CloseButton.js";
import ContainerResponsive from "../../commons/responsive/ContainerResponsive.js";
import Display from "../../../utils/Display.js";
import GraphicsResponsive from "../../commons/responsive/GraphicsResponsive.js";
import Library from "../../../Library.js";
import SpriteResponsive from "../../commons/responsive/SpriteResponsive.js";
import TextResponsive from "../../commons/responsive/TextResponsive.js";

export default class ResultNotificationPopup extends ContainerResponsive
{
    constructor()
    {
        super();
    }

    static get WINDOW_WIDTH() { return "(" + ResultNotificationPopup.WINDOW_HEIGHT + " * 1.7)"; }
    static get WINDOW_HEIGHT() { return "(ih * 30%)"; }

    get EVENT_CLOSE() { return "result-notification-window-close"; }
    get EVENT_CLICK() { return "result-notification-click"; }

    get AtlasName() { return this.atlasName; }
    set AtlasName(strNewValue) { this.atlasName = strNewValue; this.build(); }

    get TextureName() { return this.textureName; }
    set TextureName(strNewValue) { this.textureName = strNewValue; this.build(); }

    get Description() { return this.description; }
    set Description(strNewValue) { this.description = strNewValue; this.build(); }

    get ShowCloseButton() { return this.showClose; }
    set ShowCloseButton(bNewValue) { this.showClose = bNewValue; this.build(); }

    get Alpha() { return this.alpha; }
    set Alpha(fNewValue) { this.alpha = fNewValue; this.updateAlpha(); }

    get BgWidth() { return ResultNotificationPopup.WINDOW_WIDTH; }
    get BgHeight() { return ResultNotificationPopup.WINDOW_HEIGHT; }
    get BgX() { return 0; }
    get BgY() { return 0; }

    get BackgroundColor() { return string2hex(this.UI.Values.modal.background.color);}
    get StrokeWidth() { return this.evaluate(this.valueFormula(this.UI.Values.modal.stroke.size), 0, 0, 0, 0);}
    get StrokeColor() { return string2hex("color" in this.UI.Values.modal.stroke ? this.UI.Values.modal.stroke.color : "#000000");}
    get CornerRadius() { return this.evaluate(this.valueFormula(this.UI.Values.modal.corner.radius), 0, 0, 0, 0);}

    get TextPadding() { return "(ih * 2.5%)"; }

    get ImageZoneWidth() { return this.ImageZoneHeight; }
    get ImageZoneHeight() { return this.BgHeight + " * 0.45"; }
    get ImageZoneBackgroundColor() { return string2hex(this.UI.Values.slot.background.color);}
    get ImageZoneStrokeWidth() { return this.evaluate(this.valueFormula(this.UI.Values.slot.stroke.size), 0, 0, 0, 0);}
    get ImageZoneStrokeColor() { return string2hex("color" in this.UI.Values.slot.stroke ? this.UI.Values.slot.stroke.color : "#000000");}
    get ImageZoneCornerRadius() { return this.evaluate(this.valueFormula(this.UI.Values.slot.corner.radius), 0, 0, 0, 0);}

    get MaxCharacterPerLine() { return 40;}

    getSize()
    {
        return {
            "width": this.evaluate(this.BgWidth, 0, 0, 0, 0, this),
            "height": this.evaluate(this.BgHeight, 0, 0, 0, 0, this)
        };
    }

    /*******************************************
    *   INITIALIZATION
    *******************************************/
    /**
        Parameters to pass to the init function:
        - ui:           UI section object where this component resides
        - atlasName:    Atlas name of the texture to display in the box
        - textureName:  Name of the texture of the image to display in the box
        - message:      Message to display in the notification
        - showClose:    (Optional) If the close button should be shown. Default is TRUE
    */
    init(meta)
    {
        this.atlasName = meta.atlasName;
        this.textureName = meta.textureName;
        this.description = meta.message;
        this.showClose = ("showClose" in meta ? meta.showClose : true);

        this.alpha = 1;

        this.graphics = {};
        this.texts = {};
        this.sprites = {};

        return super.init(meta);
    }

    createClosure ()
    {
        super.createClosure();
        this.fctOnCloseClick = this.onCloseClick.bind(this);
        this.fctOnClick = this.onClick.bind(this);
    }

    destroy(options)
    {
        if (this.closeButton)
        {
            this.closeButton.off(this.closeButton.EVENT_CLICK, this.fctOnCloseClick);
            this.closeButton.destroy(options);
        }

        if (this.graphics && this.graphics.bg)
        {
            this.graphics.bg.off("pointerup", this.fctOnClick);
        }
        else
        {
            console.log("this.graphics.bg or this.graphics was missing on destroy", this.graphics )
        }

        for (let key in this.graphics)
        {
            this.removeChild(this.graphics[key]);
            this.graphics[key].destroy(options);
        }
        delete this.graphics;

        for (let key in this.texts)
        {
            this.removeChild(this.texts[key]);
            this.texts[key].destroy(options);
        }
        delete this.texts;

        for (let key in this.sprites)
        {
            this.removeChild(this.sprites[key]);
            this.sprites[key].destroy(options);
        }
        delete this.sprites;

        super.destroy(options);
    }

    /*******************************************
    *   BUILD
    *******************************************/
    build()
    {
        super.build();

        this.buildBackground();
        let values = this.buildSprite();
        this.buildText(values.y, values.height);
        this.buildCloseButton();
    }

    clean()
    {
        for (let key in this.graphics)
        {
            this.graphics[key].clear();
        }
        super.clean();
    }

    buildBackground()
    {
        if (!this.graphics.bg)
        {
            this.graphics.bg = new GraphicsResponsive().init({"ui": this.UI});
            this.graphics.bg.interactive = true;
            this.graphics.bg.buttonMode = true;
            this.graphics.bg.on("pointerup", this.fctOnClick);//@

            this.addChild(this.graphics.bg);
        }

        let width = this.evaluate(this.BgWidth, 0, 0, 0, 0, this);
        let height = this.evaluate(this.BgHeight, 0, 0, 0, 0, this);
        let x = this.BgX;
        let y = this.BgY;

        this.graphics.bg.lineStyle({"color":this.StrokeColor, "width":this.StrokeWidth, "alignment":1});
        this.graphics.bg.beginFill(this.BackgroundColor, 1);
        if (this.CornerRadius > 0)
        {
            this.graphics.bg.drawRoundedRect(x, y, width, height, this.CornerRadius);
        }
        else
        {
            this.graphics.bg.drawRect(x, y, width, height);
        }
        this.graphics.bg.endFill();
    }

    buildSprite()
    {
        if (!this.graphics.spriteFrame)
        {
            this.graphics.spriteFrame = new GraphicsResponsive().init({"ui": this.UI});
            this.addChild(this.graphics.spriteFrame);
        }

        if (!this.sprites.image)
        {
            let texture = null;
            if (typeof this.TextureName === "string")
            {
                if (this.AtlasName)
                {
                    texture = Library.getTextureFromAtlas(this.AtlasName, this.TextureName);
                    if (!texture)
                    {
                        texture = Library.get2DTextureFromAtlas3D(this.AtlasName, this.TextureName);
                    }
                }
                else
                {
                    texture = Library.getTextureFromResources(this.TextureName);
                }
            }

            let sprite = new SpriteResponsive(texture).init({"ui": this.UI});
            this.sprites.image = sprite;

            this.addChild(this.sprites.image);
        }

        let width = this.evaluate(this.ImageZoneWidth, 0, 0, 0, 0, this);
        let height = this.evaluate(this.ImageZoneHeight, 0, 0, 0, 0, this);
        let x = this.evaluate(this.BgWidth + " / 2 - " + width + " / 2", 0, 0, 0, 0, this);
        let y = this.evaluate(this.BgHeight + " * 0.1", 0, 0, 0, 0, this);
        let cornerRadius = this.ImageZoneCornerRadius;

        this.graphics.spriteFrame.lineStyle({"color":this.ImageZoneStrokeColor, "width":this.ImageZoneStrokeWidth, "alignment":1});
        this.graphics.spriteFrame.beginFill(this.ImageZoneBackgroundColor, 1);
        if (cornerRadius > 0)
        {
            this.graphics.spriteFrame.drawRoundedRect(x, y, width, height, cornerRadius);
        }
        else
        {
            this.graphics.spriteFrame.drawRect(x, y, width, height);
        }
        this.graphics.spriteFrame.endFill();

        let ratio = this.sprites.image.height / this.sprites.image.width;
        let spriteWidth = width - cornerRadius;
        let spriteHeight = spriteWidth * ratio;

        if (this.sprites.image.width < this.sprites.image.height)
        {
            ratio = this.sprites.image.width / this.sprites.image.height;
            spriteHeight = height - cornerRadius;
            spriteWidth = spriteHeight * ratio;
        }
        
        let spriteX = x + (width - spriteWidth) / 2;
        let spriteY = y + (height - spriteHeight) / 2;

        this.sprites.image.width = spriteWidth;
        this.sprites.image.height = spriteHeight;
        this.sprites.image.position.set(spriteX, spriteY);

        return {"y": y, "height": height};
    }

    buildText(spriteY, spriteHeight)
    {
        if (!this.texts.desc)
        {
            this.texts.desc = new TextResponsive().init({"ui": this.UI});
            this.addChild(this.texts.desc);
        }

        let descLabel = this.Description;

        let fontSize =  this.evaluate(this.valueFormula(this.UI.Values.general.text.size)) * 0.9;
        fontSize = Math.max(fontSize, this.UI.ResponsiveManager.TextMinSize);
        
        let style = new TextStyle({
            fontFamily: this.UI.Values.general.text.font,
            fontSize,
            fontWeight: this.UI.Values.general.text.weight,
            fill: this.UI.Values.general.text.color,
            align: "center",
            wordWrap: true,
            wordWrapWidth: this.evaluate(this.BgWidth + " - " + this.TextPadding + " * 2", 0, 0, 0, 0, this)
        });

        let metrics = TextMetrics.measureText(descLabel, style);

        let availableSpace = this.evaluate(this.BgHeight + " - (" + spriteY + " + " + spriteHeight + ")", 0, 0, 0, 0, this);

        let x = this.evaluate(this.BgWidth + " / 2 - " + metrics.width + " / 2");
        let y = (spriteY + spriteHeight) + (availableSpace / 2 - metrics.height / 2);

        this.texts.desc.text = descLabel;
        this.texts.desc.style = style;
        this.texts.desc.width = metrics.width;
        this.texts.desc.height = metrics.height;
        this.texts.desc.position.set(x, y);

        
    }

    buildCloseButton()
    {
        if (!this.closeButton && this.ShowCloseButton)
        {
            this.closeButton = new CloseButton().init({"ui": this.UI, "scale": 0.8});
            this.closeButton.on(this.closeButton.EVENT_CLICK, this.fctOnCloseClick);//@@

            this.addChild(this.closeButton);
        }
        else if (this.closeButton && !this.ShowCloseButton)
        {
            this.closeButton.off(this.closeButton.EVENT_CLICK, this.fctOnCloseClick);
            this.removeChild(this.closeButton);

            this.closeButton.destroy({"children": true});
            delete this.closeButton;
        }

        if (this.closeButton)
        {
            this.closeButton.rX = [{on:"default", x: (this.BgWidth + " - " + this.closeButton.ButtonWidth + " / 3")}]
            this.closeButton.rY = [{on:"default", y: (this.closeButton.ButtonHeight + " / 4")}];

            this.closeButton.layout(Display.getSize());
        }
    }

    updateAlpha()
    {
        for(let key in this.graphics)
        {
            this.graphics[key].alpha = this.Alpha;
        }

        for(let key in this.sprites)
        {
            this.sprites[key].alpha = this.Alpha;
        }

        for(let key in this.texts)
        {
            this.texts[key].alpha = this.Alpha;
        }
    }

    //---------------------------------------------------
    //  EVENTS
    //---------------------------------------------------
    onCloseClick()
    {
        setTimeout(function()
        {
            this.emit(this.EVENT_CLOSE, this);
        }
        .bind(this), 1);
    }

    onClick()
    {
        this.emit(this.EVENT_CLICK, this);
    }
}