import { Point } from '@pixi/math';
import { string2hex }  from '@pixi/utils';
import CharacterFace from "../characters/CharacterFace.js";
import ContainerResponsive from "../responsive/ContainerResponsive.js";
import SpriteResponsive from "../responsive/SpriteResponsive.js";
import Library from "../../../Library.js";
import Utils from "../../../utils/Utils.js";

export default class MapArrow extends ContainerResponsive
{
    constructor()
    {
        super();
    }

    get IsPlayer() { return this.isPlayer; }
    get Width() { return this.strWidth; }
    get Height() { return this.strHeight; }
    get FaceSize() { return "(" + this.Height + " * 0.5)"; }
    get IconSize() { return "(" + this.Height + " * 0.5)"; }

    get TextureId() { return this.textureId; }
    set TextureId(newValue) { this.textureId = newValue; }

    get BackgroundColor() { return 0x61F7AD;}

    /*******************************************
    *   INITIALIZATION
    *******************************************/
    /**
        Parameters to pass to the init function:
        - ui:           UI section object where this component resides
        - rWidth:       Responsive equation representing the width of this component
        - rHeight:      Responsive equation representing the height of this component
        - isPlayer:     (Optional) If this arrow is representing the player on the map. Default is FALSE
        - textureId:    (Optional)If this pin is the objective, this is the texture id to place inside the arrow. Default is NULL
    */
    init(meta)
    {
        this.definition = meta.definition;
        this.isPlayer = ("isPlayer" in meta ? meta.isPlayer : false);
        this.textureId = ("textureId" in meta ? meta.textureId : null);
        this.strWidth = meta.rWidth;
        this.strHeight = meta.rHeight;

        this.blinking = false;

        return super.init(meta);
    }

    destroy(options)
    {
        if (this.background)
        {
            this.removeChild(this.background);
            this.background.destroy(options);
            delete this.background;
        }

        if (this.face)
        {
            this.removeChild(this.face);
            this.face.destroy(options);
            delete this.face;
        }

        if (this.icon)
        {
            this.removeChild(this.icon);
            this.icon.destroy(options);
            delete this.icon;
        }
    }

    /*******************************************
    *   BUILD
    *******************************************/
    build()
    {
        super.build();

        this.buildArrow();
        this.buildFace();
        this.buildIcon();
    }

    buildArrow()
    {
        if (!this.background)
        {
            let texture = Library.get2DTextureFromAtlas3D("misc3D", "arrow.png");
            this.background = new SpriteResponsive(texture).init({"ui": this.UI});

            this.addChild(this.background);
        }

        let sprite = this.background;
        sprite.anchor.set(0.5);

        let width = this.evaluate(this.Width, 0, 0, 0, 0);
        let height = this.evaluate(this.Height, 0, 0, 0, 0);

        sprite.width = width;
        sprite.height = height;
    }

    buildFace()
    {
        if (this.isPlayer)
        {
            let build = this.UI.CharacterManager.getCharacterBuild(this.UI.CharacterManager.CurrentCharacter);
            if (!this.face)
            {
                this.face = new CharacterFace().init({
                    "ui": this.UI,
                    "rHeight": this.FaceSize,
                    build
                });
                this.addChild(this.face);
            }
            else if (build != this.face.BuildId)
            {
                this.removeChild(this.face);
                this.face.destroy(options);
                delete this.face;

                this.buildFace();
            }

            let width = this.evaluate(this.Width, 0, 0, 0, 0);
            let height = this.evaluate(this.Height, 0, 0, 0, 0);
            let faceSize = this.evaluate(this.FaceSize, 0, 0, 0, 0);

            this.face.x = -faceSize / 2;
            this.face.y = -faceSize / 2;
        }
    }

    buildIcon()
    {
        if (this.TextureId)
        {
            if (!this.icon)
            {
                let texture = Library.getTextureFromAtlas("ui", this.TextureId);

                if (texture)
                {
                    this.icon = new SpriteResponsive(texture).init({"ui": this.UI});
                    this.icon.ratio = texture.orig.width / texture.orig.height;

                    this.addChild(this.icon);
                }
                else
                {
                    console.error(`texture ${this.TextureId} does not exist in atlas UI`);
                    return;
                }
            }

            let sprite = this.icon;

            let size = this.evaluate(this.IconSize, 0, 0, 0, 0);
            
            sprite.width = size * sprite.ratio;
            sprite.height = size;

            this.icon.x = -size * sprite.ratio / 2;
            this.icon.y = -size / 2;
        }
    }

    /*******************************************
    *   ACTIONS
    *******************************************/
    positionOnMap(objPlayerPinPos, objMapPos, objMapSize)
    {
        let angle = Math.atan2(
            objPlayerPinPos.y - (objMapPos.y + objMapSize.height / 2), 
            objPlayerPinPos.x - (objMapPos.x + objMapSize.width / 2)
        );

        this.background.rotation = angle;

        let x = objPlayerPinPos.x;
        let y = objPlayerPinPos.y;

        if (x > objMapPos.x + objMapSize.width || x < objMapPos.x)
        {
            if (x < objMapPos.x)
            {
                x = objMapPos.x;
            }
            else
            {
                x = objMapPos.x + objMapSize.width;
            }
        }
        if (y > objMapPos.y + objMapSize.height || y < objMapPos.y)
        {
            if (y < objMapPos.y)
            {
                y = objMapPos.y;
            }
            else
            {
                y = objMapPos.y + objMapSize.height;
            }
        }

        this.x = x;
        this.y = y;
    }
}