import DualTabPopup from "../../commons/popups/DualTabPopup.js";
import Library from "../../../Library.js";
import SleepZoneContent from "./SleepZoneContent.js";
import SpriteResponsive from "../../commons/responsive/SpriteResponsive.js";
import UpgradeZoneContent from "./UpgradeZoneContent.js";

export default class SleepZonePopup extends DualTabPopup
{
    constructor()
    {
        super();
    }

    get EVENT_CLOSE() { return "sleep-zone-window-close"; }

    get TabsDefinition()
    {
        let def = {
            "main": {
                "label": this.TabMainName,
                "build": this.buildSleepTabContent.bind(this),
            },
            "upgrade": {
                "label": this.TabUpgradeName,
                "build": this.buildUpgradeTabContent.bind(this),
            }
        };
        return def;
    }

    get PauseOnShow() { return false; }
    get ZoneId() { return this.UI.WorldManager.BARN_ZONE_SLEEP; }
    get ZoneName() { return this.UI.LabelManager.translate("Somm_Amelior_Titre"); }
    get ZoneLevel() { return this.UI.WorldManager.getBarnZoneLevel(this.ZoneId); }
    get TabMainName() { return this.UI.LabelManager.translate("Somm_Dormir"); }
    get TabUpgradeName() { return this.UI.LabelManager.translate("Somm_Ameilor"); }

    getLevelImage(level)
    {
        let img = null;

        if (level >= 1 && level <= 3)
        {
            img = new SpriteResponsive(
                Library.getTextureFromAtlas("barn", this.UI.getZoneTextureId(this.ZoneId, level))
            )
            .init({"ui": this.UI});
        }

        return img;
    }

    /*******************************************
    *   INITIALIZATION
    *******************************************/
    /**
        Parameters to pass to the init function:
        - ui:           UI section object where this component resides
        - clickToHide:  (Optional) If the popup should hide when the player clicks on the overlay behind. Default is TRUE
        - showOnStart   (Optional) If the popup should be shown on start. Default is TRUE
        - showClose:    (Optional) If the close button should be shown. Default is TRUE
        - createOverlay (Optional) If this popup should create it's own overlay for itself. If not, it will use the general UI overlay. Default is FALSE
        - sideBySide    (Optional) If this popup should be positioned side-by-side with other popups on screen. Default is TRUE
        - linked        (Optional) Linked popup to this one. If this one closes, the linked popup will close too. Default is NULL
    */
    init(meta)
    {
        this.ui = meta.ui;
        return super.init(meta);
    }

    createClosure ()
    {
        super.createClosure();

        this.fctOnSleepStart = this.onSleepStart.bind(this);
        this.fctOnSleepStop = this.onSleepStop.bind(this);
        this.fctOnSleepAnimationEnd = this.onSleepAnimationEnd.bind(this);
        this.fctOnUpgrade = this.onUpgrade.bind(this);
    }

    destroy(options)
    {
        if (this.content)
        {
            this.content.off(this.content.EVENT_START_ACTION, this.fctOnSleepStart);
            this.content.off(this.content.EVENT_STOP_ACTION, this.fctOnSleepStop);
            this.content.off(this.content.EVENT_DRAG_ANIMATION_END, this.fctOnSleepAnimationEnd);
            this.content.destroy(options);
        }

        if (this.upgrade)
        {
            this.upgrade.off(this.upgrade.EVENT_UPGRADE, this.fctOnUpgrade);
            this.upgrade.destroy(options);
        }

        super.destroy(options);
    }

    /*******************************************
    *   BUILD
    *******************************************/
    clean()
    {
        if (this.content)
        {
            this.content.clean();
        }

        if (this.upgrade)
        {
            this.upgrade.clean();
        }

        super.clean();
    }

    buildSleepTabContent()
    {
        if (!this.content)
        {
            this.content = new SleepZoneContent().init({
                "ui": this.UI,
                "rWidth": this.BgWidth,
                "rHeight": this.BgHeight
            });
            this.content.on(this.content.EVENT_START_ACTION, this.fctOnSleepStart);//@
            this.content.on(this.content.EVENT_STOP_ACTION, this.fctOnSleepStop);//@
            this.content.on(this.content.EVENT_DRAG_ANIMATION_END, this.fctOnSleepAnimationEnd);//@
        }

        this.removeChild(this.content);

        this.content.rX = [{on:"default", x: this.BgX}];
        this.content.rY = [{on:"default", y: this.BgY}];

        this.addChild(this.content);

        if (this.upgrade)
        {
            this.removeChild(this.upgrade);
        }
    }

    buildUpgradeTabContent()
    {
        if (!this.upgrade)
        {
            this.upgrade = new UpgradeZoneContent().init({
                "ui": this.UI,
                "zone": this.ZoneId, 
                "rWidth": this.BgWidth, 
                "rHeight": this.BgHeight
            });
            this.upgrade.on(this.upgrade.EVENT_UPGRADE, this.fctOnUpgrade);//@
        }
        else
        {
            this.removeChild(this.upgrade);
            
        }

        this.upgrade.rX = [{on:"default", x: this.BgX}];
        this.upgrade.rY = [{on:"default", y: this.BgY}];

        this.addChild(this.upgrade);

        this.removeChild(this.content);
    }

    upgradeZone()
    {
        setTimeout(function()
        {
            this.UI.Notifications.showZoneUpgrade(this.ZoneId, this.ZoneLevel, this.ZoneLevel + 1);
            this.UI.WorldManager.upgradeBarnZone(this.ZoneId);

            this.hide();
        }
        .bind(this), 1);
    }

    startAction(iCharacter)
    {
        this.dropped = null;
        setTimeout(function(iCharacter)
        {
            this.UI.CharacterManager.addCharacterToSleep(iCharacter);
            this.buildSleepTabContent();
        }
        .bind(this, iCharacter), 1);

        this.UI.AudioManager.playSfx("zone_sommeil");
    }

    stopAction(iCharacter)
    {
        setTimeout(function(iCharacter)
        {
            this.UI.CharacterManager.removeCharacterFromSleep(iCharacter);
            this.buildSleepTabContent();
        }
        .bind(this, iCharacter), 1);
    }

    /*******************************************
    *   ACTIONS
    *******************************************/
    hide()
    {
        //It prevents a lot of problems to just destroy the popup instead of just hiding it
        setTimeout(() => {
            super.hide();
            this.UI.destroyZonePopup(this)
        }, 1);
    }

    /*******************************************
    *   BUILD
    *******************************************/
    onSleepStart(iCharacter)
    {
        this.dropped = iCharacter;
    }

    onSleepStop(iCharacter)
    {
        this.stopAction(iCharacter);
    }

    onSleepAnimationEnd()
    {
        if (this.dropped || this.dropped === 0)
        {
            this.startAction(this.dropped);
        }
    }

    onUpgrade(sender)
    {
        this.upgradeZone();
    }
}