import EventEmitter from "eventemitter3";
import {Group} from "three";
import {Mesh} from "three";
import {MeshBasicMaterial} from "three";
import {PlaneGeometry} from "three";
import {Texture} from "three";
import {Vector2} from "three";
import Constants from "../../utils/Constants.js";
import Direction from "../../utils/Direction.js";
import Library from "../../Library.js";

export default class IndoorMapLayout extends EventEmitter
{
    constructor()
    {
        super();
    }

    get EVENT_DOOR_OPEN() { return "door-open"; }

    get Y_OFFSET() { return -0.0001; }
    get QUEUE_PER_FRAME() { return 3; }

    get WALL_DIRECTIONS() { return [
        this.WALL_DIRECTION_TOP,
        this.WALL_DIRECTION_RIGHT,
        this.WALL_DIRECTION_BOTTOM,
        this.WALL_DIRECTION_LEFT
    ]; }
    get WALL_DIRECTION_TOP() { return "top"; }
    get WALL_DIRECTION_RIGHT() { return "right"; }
    get WALL_DIRECTION_BOTTOM() { return "bottom"; }
    get WALL_DIRECTION_LEFT() { return "left"; }

    //---------------------------------------------------------
    //  DEPENDENCIES
    //---------------------------------------------------------
    get GameManager() { return this.Dependencies.get("GameManager"); }
    get ResponsiveManager() { return this.Dependencies.get("ResponsiveManager"); }
    get UIManager() { return this.Dependencies.get("UIManager"); }
    get ItemManager() { return this.Dependencies.get("ItemManager"); }
    //---------------------------------------------------------

    get Dependencies() { return this.dependencies; }
    get Environment() { return this.environment; }
    get Grid() { return this.environment.Grid; }
    get Scene() { return this.Environment.Scene; }
    get Player() { return this.Environment.Player; }
    get RoomId() { return this.roomId; }
    get Width() { return this.size.width; }
    get Height() { return this.size.height; }

    getTileTextureUrl(iX, iY, strDirection = null)
    {
        console.log("getTileTextureUrl", this.roomData);

        if (!strDirection) //this is a floor
        {
            if (this.roomData.floorPlan)
            {
                let floorplan = this.roomData.floorPlan.find(fp => fp.x === iX && fp.y === iY);

                if (floorplan && floorplan.texture)
                {
                    let url = floorplan.texture.key;

                    return url;
                }
            }
        }
        else //this is probably a wall
        {
            if (this.roomData.walls)
            {
                let walls = this.roomData.walls;

                let index = iX % walls.length;

                return this.roomData.walls[index];
            }
        }

        let url = Constants.getValue("INDOOR_" + (strDirection ? "WALL" : "FLOOR") + "_URL");
        url = url
            .replace("{0}", iX)
            .replace("{1}", iY)
            .replace("{2}", this.Environment.Id)
            .replace("{3}", this.RoomId);

        if (strDirection)
        {
            url = url.replace("{4}", strDirection);
        }

        return url;
    }

    getDoorTextureUrl(iX, iY)
    {
        let door = this.roomData.doors.find( door => door.pos.x === iX && door.pos.y === iY);

        if (door)
        {
            return door.asset;
        }

        let url = Constants.getValue("INDOOR_DOOR_URL");
        url = url
            .replace("{0}", iX)
            .replace("{1}", iY)
            .replace("{2}", this.Environment.Id)
            .replace("{3}", this.RoomId);

        return url;
    }

    getTileSize(strDirection)
    {
        return Constants.getValue("INDOOR_" + (strDirection ? "WALL" : "FLOOR") + "_TILE_SIZE");
    }

    getTileId(iX, iY, strDirection, bIsDoor = false)
    {
        let id = iX + "_" + iY;

        if (strDirection)
        {
             id += "_" + strDirection;
        }

        if (bIsDoor)
        {
             id += "_door";
        }

        return id;
    }

    getIsVertical(strDirection)
    {
        return strDirection == this.WALL_DIRECTION_TOP || strDirection == this.WALL_DIRECTION_BOTTOM;
    }

    /*******************************************
    *   INITIALIZATION
    *******************************************/
    /**
        Parameters to pass to the init function:
        - dependencies: Dependency container used to communicate with the different modules of the game
        - environment:  Environment where this floor lives
        - roomId:       Id of the room this floor should represents
        - width:        How many tiles wide is the room
        - height:       How many tiles of height is the room
    */
    init(meta)
    {
        this.dependencies = meta.dependencies;
        this.environment = meta.environment;
        this.roomId = meta.roomId;
        this.size = {
            "width": meta.width,
            "height": meta.height
        };

        this.loadQueue = [];
        this.inProcess = [];

        this.createGroups();

        this.geometry = {
            "floor": null,
            "wall": null,
            "door": null
        };

        this.createClosure();
        this.bindEvents();
        this.createBaseGeometry();

        return this;
    }

    createClosure()
    {

    }

    bindEvents()
    {

    }

    destroy()
    {
        for (let type in this.tiles)
        {
            let keys = Object.keys(this.tiles[type]);
            for (let i = 0; i < keys.length; i++)
            {
                if (this.tiles[type].tiles[keys[i]])
                {
                    this.tiles[type].tiles[keys[i]].objTexture.dispose();
                    this.unloadTile(this.tiles[type].tiles[keys[i]], type == "wall");
                    if (type == "door")
                    {
                        this.Scene.removeClickableMesh(keys[i]);
                    }
                }
            }
        }
        this.Scene.remove(this.tiles.floor);
        this.Scene.remove(this.tiles.wall);
        this.Scene.remove(this.tiles.door);

        delete this.tiles;

        this.geometry.floor.dispose();
        this.geometry.wall.dispose();
        for (let key in this.geometry.door)
        {
            if (this.geometry.door[key])
            {
                this.geometry.door[key].dispose();
            }
        }
        delete this.geometry;
    }

    createBaseGeometry()
    {
        //FLOOR
        let tileSize = this.getTileSize(false);
        let gridSize =this.Grid.CellSize;

        this.geometry.floor = new PlaneGeometry(
            tileSize.width * gridSize, 
            tileSize.height * gridSize, 
            10, 
            10
        );

        //WALL
        tileSize = this.getTileSize(true);

        this.geometry.wall = new PlaneGeometry(
            tileSize.width * gridSize, 
            tileSize.height * gridSize, 
            10, 
            10
        );

        //DOOR
        //The door geometries will be created upon texture load to fit the ratio
        this.geometry.door = {
            "visible" : null,
            "ground": null
        };
    }

    createGroups()
    {
        this.tiles = {
            "floor": new Group(),
            "wall": new Group(),
            "door": new Group()
        };

        this.tiles.floor.tiles = {};
        this.tiles.wall.tiles = {};
        this.tiles.door.tiles = {};

        this.Scene.add(this.tiles.floor);
        this.Scene.add(this.tiles.wall);
        this.Scene.add(this.tiles.door);

        //Thanks to this guy: https://stackoverflow.com/a/12666937/4191589
        //This prevents walls from begin rendered in front of the player/enemies/obstacles when the player walks along
        this.tiles.wall.renderOrder = -100;
        this.tiles.wall.onBeforeRender = function(renderer) { renderer.clearDepth(); };
    }

    /*******************************************
    *   DISPLAY
    *******************************************/
    createTile(iX, iY, objTexture, strDirection = null)
    {
        let material =  new MeshBasicMaterial({
            wireframe: false,
            opacity:1,
            transparent: false,
            map: objTexture
        });


        let tile = new Mesh(this.geometry[strDirection ? "wall" : "floor"], material);

        tile.rotation.x = (strDirection ? 0 : -Math.PI / 2);
        tile.rotation.y = (strDirection && !this.getIsVertical(strDirection) ? Math.PI / 2 : 0);
        tile.rotation.z = 0;

        tile.objTexture = objTexture;

        let tileGridSize = this.getTileSize(strDirection);

        let gridSize = this.Grid.CellSize;

        tile.position.x = iX * (tileGridSize.width * gridSize) + (strDirection && !this.getIsVertical(strDirection) ? 0 : (tileGridSize.width * gridSize) / 2);
        tile.position.y = (strDirection ? (tileGridSize.width * gridSize / 2) : 0);
        tile.position.z = iY * (tileGridSize.height * gridSize) + (strDirection && this.getIsVertical(strDirection) ? 0 : (tileGridSize.height * gridSize) / 2);



        let groupId = (strDirection ? "wall" : "floor");

        console.log("strDirection", strDirection);

        //In order to avoid clipping with the player, we need to offset the wall by a LOT.
        if(strDirection == "top")
        {
             tile.position.x -= 4.6;
             tile.position.y = 25.1;
             tile.position.z = -43;

            // console.log("START", strDirection, iX, iY, JSON.stringify(tile.position));
            //
            // document.addEventListener("keyup", (e) =>
            // {
            //     let strCode = e.code;
            //
            //     let offset = e.shiftKey ? 0.1 : 1;
            //     //console.log(strCode);
            //
            //     if (strCode === "KeyQ")
            //     {
            //         tile.position.x += offset;
            //     }
            //     else if (strCode === "KeyA")
            //     {
            //         tile.position.x -= offset;
            //     }
            //     else if (strCode === "KeyW")
            //     {
            //         tile.position.y += offset;
            //     }
            //     else if (strCode === "KeyS")
            //     {
            //         tile.position.y -= offset;
            //     }
            //     else if (strCode === "KeyE")
            //     {
            //         tile.position.z += offset;
            //     }
            //     else if (strCode === "KeyD")
            //     {
            //         tile.position.z -= offset;
            //     }
            //
            //     console.log("MOVE", strDirection, iX, iY, JSON.stringify(tile.position));
            //
            //
            //
            // })

        }
        else if (strDirection == "left")
        {
            // objTexture.offset = new Vector2(20, 0);

            tile.position.x = -10.1;
            tile.position.y = -7.8;
            tile.position.z -= 99.6;


        }

        this.tiles[groupId].add(tile);
        this.tiles[groupId].tiles[this.getTileId(iX, iY, strDirection)] = tile;
    }

    createDoor(iX, iY, bIsDoorVisible, objTexture, objDoor)
    {
        let material =  new MeshBasicMaterial({
            wireframe: false,
            opacity:1,
            transparent: true,
            map: objTexture
        });

        let geometry = null;//this.geometry.door[(bIsDoorVisible ? "visible" : "ground")];
        let cellSize = Constants.getValue("GRID_CELL_SIZE");
        if (!geometry)
        {
            let width = objTexture.frameSize.width * 0.05;
            let height = objTexture.frameSize.height * 0.05;

            let ratio = height / width;


            if (!bIsDoorVisible)
            {
                width = cellSize;
                height = cellSize * ratio;

            }
            else
            {
                let defaultDivider = this.ResponsiveManager.AssetDivider / 30;
                width /= defaultDivider;
                height /= defaultDivider;
            }
            /*else
            {
                width = objTexture.image.width / 25;
                height = objTexture.image.height / 25;
            }*/


            geometry = new PlaneGeometry(
                width,
                height,
                10,
                10
            );
            geometry.calculatedSize = {"width": width, "height": height};
        }
        //this.geometry.door[(bIsDoorVisible ? "visible" : "ground")] = geometry;

        let size = geometry.calculatedSize;
        let door = new Mesh(geometry, material);

        let direction = this.WALL_DIRECTION_RIGHT;
        if (iY == 0)
        {
            direction = this.WALL_DIRECTION_TOP;
        }
        else if (iX == 0)
        {
            direction = this.WALL_DIRECTION_LEFT;
        }
        else if (iY >= this.Height - 1)
        {
            direction = this.WALL_DIRECTION_BOTTOM;
        }

        if (bIsDoorVisible)
        {
            door.rotation.x = 0;//Math.PI / 180 * 10;//this.roomData.camera.rotation.x;
            door.rotation.y = 0;
            door.rotation.z = Math.PI / 180 * -1.65;//-this.roomData.camera.rotation.z;;
            
            console.log("x",  (180 / Math.PI) * this.roomData.camera.rotation.x);
            console.log("y", (180 / Math.PI) * this.roomData.camera.rotation.y);
            console.log("z", (180 / Math.PI) * this.roomData.camera.rotation.z);


        }
        else
        {
            door.rotation.x = -Math.PI / 2;
            door.rotation.y = 0;
            door.rotation.z = 0;


        }

        door.position.x = iX * cellSize + 0.01 * (direction == this.WALL_DIRECTION_RIGHT ? -1 : 1);
        door.position.y = (bIsDoorVisible ? (size.height / 2) : 0.1);
        door.position.z = iY * cellSize + 0.01 * (direction == this.WALL_DIRECTION_BOTTOM ? -1 : 1);

        if (!bIsDoorVisible)
        {
            if (this.getIsVertical(direction))
            {
                door.position.z += (size.height / 2) * (direction == this.WALL_DIRECTION_TOP ? -1 : 1);
            }
            else
            {
                door.position.x += (size.width / 2) * (direction == this.WALL_DIRECTION_LEFT ? -1 : 3);
            }
        }
        else
        {
            if (!this.getIsVertical(direction))
            {
                door.position.z += -(size.width / 2);
            }

            door.position.x -= 4
            door.position.y -= 24
            door.position.z -= 43

            document.addEventListener("keyup", (e) =>
            {
                let strCode = e.code;

                let offset = Math.PI / 180 * (e.shiftKey ? 0.1 : 1);
                //console.log(strCode);

                if (strCode === "KeyQ")
                {
                    door.rotation.x += offset;
                }
                else if (strCode === "KeyA")
                {
                    door.rotation.x -= offset;
                }
                else if (strCode === "KeyW")
                {
                    door.rotation.y += offset;
                }
                else if (strCode === "KeyS")
                {
                    door.rotation.y -= offset;
                }
                else if (strCode === "KeyE")
                {
                    door.rotation.z += offset;
                }
                else if (strCode === "KeyD")
                {
                    door.rotation.z -= offset;
                }

                console.log("MOVE", direction, iX, iY, 180 / Math.PI  * door.rotation.z);



            })
        }

        let id = this.getTileId(iX, iY, null, true);
        this.tiles.door.add(door);
        this.tiles.door.tiles[id] = door;

        this.Scene.addClickableMesh(id, door, size.width, size.height, this.onDoorClick.bind(this, this.Grid.getTileId(iX, iY), objDoor));
    }

    loadRoom(roomData)
    {
        this.roomData = roomData;
        this.loadFloor();
        this.loadWalls(roomData.camera);
        this.loadDoors(roomData.doors, roomData.camera);
    }

    //------------------------------------------
    //  FLOOR
    //------------------------------------------
    loadFloor()
    {
        let tileSize = this.getTileSize(false);
        let floorWidth = Math.ceil(this.Width / tileSize.width);
        let floorHeight = Math.ceil(this.Height / tileSize.height);

        for (let y = 0; y < floorHeight; y++)
        {
            for (let x = 0; x < floorWidth; x++)
            {
                if (!this.inProcess[this.getTileId(x, y, false)])
                {
                    this.addToLoadQueue(x, y);
                }
            }
        }
    }

    //------------------------------------------
    //  WALLS
    //------------------------------------------
    loadWalls(objCameraData)
    {
        let tileSize = this.getTileSize(true);
        let wallWidth = Math.ceil(this.Width / tileSize.width);
        let wallHeight = Math.ceil(this.Height / tileSize.height);

        //let directions = this.WALL_DIRECTIONS;

        //As the angle is isometric, we don't have a lot of time left for calculations
        let directions = objCameraData.walls;

        for (let i = 0; i < directions.length; i++)
        {
            let dir = directions[i];
            let isHorizontal = dir == this.WALL_DIRECTION_LEFT || dir == this.WALL_DIRECTION_RIGHT;

            for (let j = 0; j < (isHorizontal ? wallHeight : wallWidth); j++)
            {
                let x = (isHorizontal ? (dir == "right" ? wallWidth - 1 : 0) : j);
                let y = (isHorizontal ? j : (dir == "bottom" ? wallHeight - 1 : 0));

                if (!this.inProcess[this.getTileId(x, y, dir)])
                {
                    this.addToLoadQueue(x, y, dir);
                }
            }
        }
    }

    //------------------------------------------
    //  DOORS
    //------------------------------------------
    loadDoors(arrDoorsData, objCameraData)
    {
        let walls = objCameraData.walls;
        let tileSize = this.getTileSize(false);
        let floorWidth = Math.ceil(this.Width / tileSize.width);
        let floorHeight = Math.ceil(this.Height / tileSize.height);

        for (let i = 0; i < arrDoorsData.length; i++)
        {
            let objDoorData = arrDoorsData[i];


           let pos = objDoorData.pos;

            if (pos)
            {

                // let isVisible = (pos.y == 0 && walls.includes(this.WALL_DIRECTION_TOP)) ||
                //                 (pos.x == 0 && walls.includes(this.WALL_DIRECTION_LEFT)) ||
                //                 (pos.y == floorHeight - 1 && walls.includes(this.WALL_DIRECTION_BOTTOM)) ||
                //                 (pos.x == floorWidth - 1 && walls.includes(this.WALL_DIRECTION_RIGHT));

                let isVisible = objDoorData.placement === "wall";

                if (pos.y === null || pos.y === undefined)
                {
                    pos.y = 0;
                }

                if (!this.inProcess[this.getTileId(pos.x, pos.y, null, true)])
                {
                    this.addToLoadQueue(pos.x, pos.y, null, true, isVisible, objDoorData);
                }
            }
        }
    }

    unloadTile(strTileId, bIsWall)
    {
        let groupId = (bIsWall ? "wall" : "floor");
        let tile = this.tiles[groupId].tiles[strTileId];
        if (tile)
        {
            this.tiles[groupId].remove(tile);
            tile.material.dispose();

            delete this.tiles[groupId].tiles[strTileId];
        }
    }

    /*******************************************
    *   LOAD QUEUE
    *******************************************/
    addToLoadQueue(iX, iY, strDirection = null, bIsDoor = false, bIsDoorVisible = false, objDoor = null)
    {
        let restartQueue = this.loadQueue.length == 0;
        this.loadQueue.push([iX, iY, strDirection, bIsDoor, bIsDoorVisible, objDoor]);

        if (restartQueue)
        {
            this.updateLoadQueue();
        }
    }

    updateLoadQueue()
    {
        let loopCount = this.QUEUE_PER_FRAME;

        for (let i = 0; i < loopCount && this.loadQueue.length > 0; i++)
        {
            let meta = this.loadQueue.shift();
            let url = null;
            if (meta[3])
            {
                url = this.getDoorTextureUrl(meta[0], meta[1]).split("/").pop();

                let texture = Library.getTexture3D("labo3D", url);
                this.onTextureLoadComplete( meta[0], meta[1], meta[2], meta[3], meta[4], texture, meta[5]);

                continue;
            }
            else
            {
                url = this.getTileTextureUrl(meta[0], meta[1], meta[2])
            }
            let img = new Image();

            let id = this.getTileId(meta[0], meta[1], meta[2], meta[3]);
            this.inProcess[id] = img;

            console.log("updateLoadQueue", url, meta);

            img.onload = this.onTextureLoadComplete.bind(this, meta[0], meta[1], meta[2], meta[3], meta[4], img, meta[5]);
            img.onerror = this.onTextureLoadError.bind(this, meta[0], meta[1], meta[2], meta[3], meta[4], img);
            img.src = url;
        }

        if (this.loadQueue.length > 0)
        {
            window.requestAnimationFrame(this.updateLoadQueue.bind(this));
        }
    }

    /*******************************************
    *   DOOR ACTIONS
    *******************************************/
    goToDoor(iTileId, objDoor = null)
    {
        if (this.Player.CanMove)
        {
            let pos = this.Grid.getPosFromTileId(iTileId);
            let playerPos = this.Player.GridPos;

            let nearest = this.Grid.getNearestWalkableTile(
                pos.x,
                pos.y + 1,
                pos.x,
                pos.y + 1,
                false,
                null,
                false,
                false,
                false,
                5
            );

            let totalDiff = Math.abs(nearest.x - playerPos.x) + Math.abs(nearest.y - playerPos.y);
            if (totalDiff > 1)
            {
                let worldPos = this.Grid.gridToWorldPosition(nearest.x, nearest.y);
                //Quick & dirty hack to bypass the pointer click that often overrides this goTo call
                setTimeout(
                    () => this.Player.goTo(worldPos.x, worldPos.y, false, this.onOpenDoor.bind(this, iTileId, objDoor)),
                    1
                );
            }
            else
            {
                this.onOpenDoor(iTileId, objDoor);
            }
        }
    }

    openDoor(iTileId)
    {
        let pos = this.Grid.getPosFromTileId(iTileId);
        if (pos.x == 0)
        {
            this.Player.changeDirection(Direction.West);
        }
        else if (pos.x == this.Width - 1)
        {
            this.Player.changeDirection(Direction.East);
        }
        else if (pos.y == 0)
        {
            this.Player.changeDirection(Direction.North);
        }
        else
        {
            this.Player.changeDirection(Direction.South);
        }

        this.Player.stopMovement();
        this.Player.preventMovement();
        this.GameManager.pause();

        this.emit(this.EVENT_DOOR_OPEN, pos);
    }

    /*******************************************
    *   EVENTS
    *******************************************/
    onTextureLoadComplete(iX, iY, strDirection, bIsDoor, bIsDoorVisible, objImg, objDoor)
    {
        let texture = objImg instanceof Texture ? objImg : new Texture(objImg);
        texture.needsUpdate = true;

        let id = this.getTileId(iX, iY, strDirection, bIsDoor);
        delete this.inProcess[id];

        if (bIsDoor)
        {
            this.createDoor(iX, iY, bIsDoorVisible, texture, objDoor);
        }
        else
        {
            this.createTile(iX, iY, texture, strDirection);
        }
    }

    onTextureLoadError(iX, iY, strDirection, bIsDoor, bIsDoorVisible, objImg)
    {
        let id = this.getTileId(iX, iY, strDirection, bIsDoor);
        delete this.inProcess[id];
    }

    onDoorClick(iTileId, objDoor = null)
    {
        this.goToDoor(iTileId, objDoor);
    }

    onOpenDoor(iTileId, objDoor)
    {
        let bIsDoor = objDoor.door !== undefined && objDoor.door !== "";
        let bLeadOutdoor = objDoor.out;

        objDoor.openDoor = bIsDoor || bLeadOutdoor;
        objDoor.tileId = iTileId;

        this.checkDoorCondition(objDoor);


    }

    checkDoorCondition (objDoor)
    {
        if (!objDoor.condition)
        {
            this.applyDoorCondition(true, objDoor);
            return true;
        }

        let strCondition = objDoor.conditional.condition;

        if (strCondition === "has-item")
        {
            let strItem = objDoor.conditional.object;

            let iQuantity = this.ItemManager.getItemQuantity(strItem);

            this.applyDoorCondition(iQuantity > 0, objDoor);

            return;
        }
        else if (strCondition === "complete-minigame")
        {
            let strMinigame = objDoor.conditional.minigame.game;
            let iDifficulty = objDoor.conditional.minigame.difficulty;

            this.UIManager.startMinigame(strMinigame, iDifficulty, (success) => this.applyDoorCondition(success, objDoor));
            return;
        }


    }

    applyDoorCondition (success, objDoor)
    {
        if (success)
        {
            this.applyDoorAction(objDoor.actionSuccess, objDoor.tileId, objDoor.openDoor);
        }
        else
        {
            this.applyDoorAction(objDoor.actionFailed);
        }
    }

    applyDoorAction (obj, iTileId = -1, bOpenDoor = false)
    {
        let action = obj;
        let strType = action && action.type ? action.type : "";


        if (action && action.type && action.type === "dialog")
        {

            let strContent = action.showDialog;
            this.UIManager.showDialog("", strContent, null, (success) => this.onActionComplete(success, iTileId, bOpenDoor));

            return true;
        }
        else if (bOpenDoor)
        {
            this.openDoor(iTileId);
        }
    }



    onActionComplete (success, iTileId, bOpenDoor)
    {
        if (success && bOpenDoor)
        {
            this.openDoor(iTileId);
        }
    }
}