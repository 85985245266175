import EventEmitter from "eventemitter3";
import {Vector2} from "three";
import BaseScene from "../scenes/BaseScene.js";
import Constants from "../../utils/Constants.js";
import Direction from "../../utils/Direction.js";
import Enemy from "../objects/Enemy.js";
import MapGrid from "../map/MapGrid.js";
import MapMoveIcon from "../map/MapMoveIcon.js";
import Obstacle from "../objects/Obstacle.js";
import Player from "../objects/Player.js";
import Spit from "../objects/projectiles/Spit.js";
import DependencyContainer from "../../utils/DependencyContainer.js";
import Npc from "../objects/Npc.js";

export default class BaseEnvironment extends EventEmitter
{
    constructor(canvas)
    {
        super();

        this.canvas = canvas;
        this.dependencies = DependencyContainer.getInstance();
    }

    get TYPE_ENEMY() { return "enemy"; }
    get TYPE_NPC() { return "npc"; }

    //---------------------------------------------------------
    //  DEPENDENCIES
    //---------------------------------------------------------
    get AudioManager() { return this.dependencies.get("AudioManager"); }
    get CharacterManager() { return this.dependencies.get("CharacterManager"); }
    get GameManager() { return this.dependencies.get("GameManager"); }
    get ItemManager() { return this.dependencies.get("ItemManager"); }
    get LabelManager() { return this.dependencies.get("LabelManager"); }
    get ResponsiveManager() { return this.dependencies.get("ResponsiveManager"); }
    get SaveManager() { return this.dependencies.get("SaveManager"); }
    get UIManager() { return this.dependencies.get("UIManager"); }
    get WorldManager() { return this.dependencies.get("WorldManager"); }
    get TriggerManager() { return this.dependencies.get("TriggerManager"); }
    //---------------------------------------------------------

    get Id() { return "BASE"; }
    get Dependencies() { return this.dependencies; }

    get Scene() { return this.scene; }
    get Grid() { return this.grid; }
    get Player() { return this.player; }
    get Enemies() { return this.enemies; }
    get NPCs() { return this.npcs; }
    get MapMoveIcon() { return this.mapMoveIcon; }
    get IsHostile() { return false; }
    get IsIndoor() { return false; }
    get IsDialogMode() { return this.isDialogMode; }
    get IsCinematicMode() { return this.isCinematicMode; }
    get ObstacleParser() { return this.parser; }

    /*******************************************
    *   INITIALIZATION
    *******************************************/
    /**
        Parameters to pass to the init function:
        - parser:       Obstacle parser to populate the map with
    */
    init(params)
    {
        this.player = null;
        this.enemies = [];
        this.npcs = [];
        this.spits = [];
        this.globalId = 1;
        
        this.createClosure();
        this.bindEvents();

        this.parser = params ? params.parser : null;

        this.scene = this.getScene();
        //this.scene.init();

        if (this.scene.camera)
        {
            this.scene.camera.on(this.scene.camera.EVENT_GRID_POS_CHANGED, this.fctOnCameraTileChanged);
        }

        this.createGrid();
        this.createMapMoveIcon();
        this.createFloor();
        this.createObstacles();
        this.initPostProcessing();
        this.initUI();
    }

    getScene ()
    {
        return new BaseScene(this.canvas).init();
    }

    createClosure()
    {
        this.fctUpdate = this.update.bind(this);
        this.fctOnCameraTileChanged = this.onCameraTileChanged.bind(this);
    }

    bindEvents()
    {
        this.GameManager.on(this.GameManager.EVENT_UPDATE, this.fctUpdate);
    }

    removeCharacters (options)
    {
        if (this.player)
        {
            this.player.destroy(options);
            this.player = null;
        }

        if (this.enemies)
        {
            for (let i = 0; i < this.enemies.length; i++)
            {
                this.enemies[i].destroy(options);
            }

            this.enemies = [];
        }

        if (this.npcs)
        {
            for (let i = 0; i < this.npcs.length; i++)
            {
                this.npcs[i].destroy(options);
            }

            this.npcs = [];
        }
    }

    destroy(options)
    {
        this.GameManager.off(this.GameManager.EVENT_UPDATE, this.fctUpdate);


        this.removeCharacters(options);
        if (this.mapMoveIcon)
        {
            this.mapMoveIcon.destroy();
            delete this.mapMoveIcon;
        }

        if (this.Scene)
        {
            while(this.Scene.children.length > 0)
            {
                let child = this.Scene.children.shift();
                this.Scene.remove(child);

                if (child.geometry)
                {
                    child.geometry.dispose(); //BufferGeometry.dispose
                }

                if (child.material)
                {
                    child.material.dispose(); //Material.dispose
                }
            }

            this.Scene.destroy(options);
        }
    }

    createGrid()
    {
        this.grid = new MapGrid().init({
            "dependencies": this.dependencies,
            "width": Constants.getValue("MAP_WIDTH"),
            "height": Constants.getValue("MAP_HEIGHT"),
            "parser": this.parser
        });
    }

    createMapMoveIcon()
    {
        this.mapMoveIcon = new MapMoveIcon(this);
        this.mapMoveIcon.init(); 
    }

    createFloor()
    {
        console.warn("The createFloor method should be overriden by the child class");
    }

    createObstacles()
    {
        console.warn("The createObstacles method should be overriden by the child class");
    }

    initPostProcessing()
    {

    }

    initUI()
    {
        console.warn("The initUI method should be overriden by the child class");
    }

    getAll(strType)
    {
        if (this.TYPE_ENEMY)
        {
            return this.enemies;
        }
        else if (this.TYPE_NPC)
        {
            return this.npcs;
        }
        return [];
    }

    /*******************************************
    *   SPAWN/DESPAWN MANAGEMENT
    *******************************************/
    spawnPlayer(iX, iZ)
    {
        //TEMP
        //-------------------------------------
        this.player = new Player();
        this.player.init({
            id: this.globalId,
            visualCode: this.SaveManager.getFromSave(
                this.CharacterManager.characterBuildKey + this.CharacterManager.CurrentCharacter
            ),
            spawnPos: new Vector2(iX, iZ),
            direction: Direction.South,
            focus:true,
            environment: this
        });
        //-------------------------------------

        let fctDone = function(sender)
        {
            this.Scene.add(sender.mesh);
            this.Scene.Camera.startFollowing(sender.mesh);
        }
        .bind(this);

        if (!this.player.VisualsCreated)
        {
            this.player.once(BaseObject.EVENT_VISUALS_CREATED, fctDone);
        }
        else
        {
            fctDone(this.player);
        }
        
        this.globalId++;
    }

    spawnEnemy (strEnemyId, iX, iY, iSpawnPointX, iSpawnPointY, arrPathing = null)
    {
        let definition = this.WorldManager.getEnemyDefinition(strEnemyId);
        if (definition)
        {
            definition.id = strEnemyId;

            let enemy = new Enemy();
            enemy.init({
                "id": this.globalId,
                "spawnPos": new Vector2(iSpawnPointX, iSpawnPointY),
                "environment": this,
                "direction": Direction.South,
                "definition": definition,
                "pathing": arrPathing,
                "params": {}
            });

            enemy.spawn(new Vector2(iX, iY));
            this.enemies.push(enemy);

            this.Grid.trackMesh(enemy.Id, enemy.Mesh);

            this.globalId++;

            return enemy;
        }

        return null;
    }

    spawnNpc (strNpcType, iX, iY, fctCallback = null)
    {
        let definition = this.WorldManager.getNpcDefinition(strNpcType);

        if (!definition)
        {
            let enemy = this.WorldManager.getEnemyDefinition(strNpcType);

            if (enemy)
            {
                return this.spawnEnemy(strNpcType, iX, iY, fctCallback)
            }
        }
        if (definition)
        {
            definition.id = strNpcType;

            let npc = new Npc();
            npc.init({
                "id": this.globalId,
                "spawnPos": new Vector2(iX, iY),
                "environment": this,
                "direction": Direction.South,
                "definition": definition,
                "pathing": [],
                "params": {}
            });

            npc.spawn(new Vector2(iX, iY));

            this.npcs.push(npc);

            this.Grid.trackMesh(npc.Id, npc.Mesh);

            this.globalId++;

            return npc;
        }

        return null;
    }

    /**
        Be warned to always go through the MapObstacle object instance to 
        spawn and despawn objects from the field. This method is mainly used
        by the MapObstacle to create the obstacle instances in the environment.
    */
    spawnObstacle (objItem, iX, iY, iQuantity, objObstacle = null)
    {
        let spawnPos = new Vector2(iX, iY);

        //The obstacle object can be passed as a reference in case it was previously
        //sitting in a pool waiting to be spawned. Otherwise a new obstacle instance
        //is created and spawned in the environment


        if (!objObstacle)
        {

            if (objItem.definition && objItem.definition.enemy)
            {
                this.spawnEnemy(objItem.definition.key, iX, iY, iX, iY);
                return;
            }
            else
            {
                objObstacle = new Obstacle();
                objObstacle.init({
                    "id": this.globalId,
                    "spawnPos": spawnPos,
                    "environment": this,
                    "item": objItem,
                    "spawnOnCreate": false,
                    "params": {"quantity": iQuantity}
                });
            }
            this.globalId++;
        }

        if (objObstacle)
        {
            objObstacle.Quantity = iQuantity;
            objObstacle.spawn(spawnPos);
        }

        return objObstacle;
    }

    /**
        Spawns a spit instance towards a point in the world.
        @param objFromPos   Position where the spit originates from. Should be in ThreeJS world coordinates (not grid)
        @param objToPos     Position where the spit aims to. Should be in ThreeJS world coordinates (not grid)
        @param fMaxDist     Maximum number of tiles the spit can travel before being despawned
        @param fStunTime    Time (in seconds) a target will be stunned when hit by the spit
    */
    spawnSpit(objFromPos, objToPos, fMaxDist, fStunTime)
    {
        let spit = new Spit();
        spit.init({
            "id": this.globalId,
            "spawnPos": objFromPos,
            "targetPos": objToPos,
            "distance": fMaxDist,
            "stunTime": fStunTime,
            "environment": this,
            "spawnOnCreate": true
        });

        spit.spawn(spit.SpawnPos); 

        this.globalId++;
        this.spits.push(spit);

        return spit;
    }

    removeEnemy (objEnemy)
    {
        for (let i = 0; i < this.enemies.length; i++)
        {
            if (this.enemies[i].Id == objEnemy.Id)
            {
                this.enemies.splice(i, 1);
                break;
            }
        }

        this.Grid.stopTrackingMesh(objEnemy.Id);
        objEnemy.destroy();
    }

    removeNpc (strNpcType, fctCallback = null)
    {
        for (let i = this.npcs.length - 1; i >= 0; i--)
        {
            if (this.npcs[i].Definition.id == strNpcType)
            {
                this.Grid.stopTrackingMesh(this.npcs[i].Id);
                this.npcs[i].destroy();
                this.npcs.splice(i, 1);
            }
        }
    }

    /**
        Be warned to always go through the MapObstacle object instance to 
        spawn and despawn objects from the field. This method is mainly used
        by the MapObstacle to remove the obstacle instances from the environment.
    */
    removeObstacle(objObstacle, bDestroy = true)
    {
        if (bDestroy)
        {
            objObstacle.destroy();
        }
        else
        {
            objObstacle.despawn();
        }
    }

    removeSpit(objSpit, bDestroy = true)
    {
        for (let i = 0; i < this.spits.length; i++)
        {
            if (this.spits[i].Id == objSpit.Id)
            {
                this.spits.splice(i, 1);
                break;
            }
        }

        objSpit.despawn();

        if (bDestroy)
        {
            objSpit.destroy();
        }
    }

    hasNpc (strNpc)
    {
        for (let i = 0; i < this.npcs.length; i++)
        {
            let npc = this.npcs[i];

            if (npc && npc.definition.id === strNpc && npc.Scene !== null)
                return true;
        }

        return false;
    }

    /*******************************************
    *   PLAYER RELATED
    *******************************************/
    showPlayer(fctCallback = null)
    {
        
    }

    hidePlayer(fctCallback = null)
    {

    }

    clickOnRadio()
    {
        console.warn("Click on the radio isn't supported in this environment");
    }

    playerDropItem(objItem, iQuantity, objPos = null)
    {
        this.ItemManager.emit(this.ItemManager.EVENT_ITEM_DROPPED, objItem,iQuantity, -1, false);
    }

    stunAllEnemies(fDistance, fDuration)
    {
        let maxDist = Math.pow(fDistance, 2);
        let playerPos = this.Player.GridPos;

        for (let i = 0; i < this.enemies.length; i++)
        {
            if (!this.enemies[i].IsStun)
            {
                let enemyPos = this.enemies[i].GridPos;
                let sqrDist = Math.pow(playerPos.x - enemyPos.x, 2) + Math.pow(playerPos.y - enemyPos.y, 2);

                if (sqrDist <= maxDist)
                {
                    this.enemies[i].setStun(fDuration);
                }
            }
        }
    }

    getBackpackDropPosition(objPlayer)
    {
        //This should be overriden in the house and labo as the player can't drop
        //something on the ground inside. It'll always be in the forest.
        let gridPos = objPlayer.GridPos;
        return gridPos;
    }

    /*******************************************
    *   UTILITIES
    *******************************************/
    /**
        Hides the screen while stuff are loading in the forest thus preventing the player seeing loaded assets poping out of nowhere
    */
    forceLoadOverlay()
    {
        
    }

    startCinematicMode ()
    {
        this.isCinematicMode = true;
        this.WorldManager.emit(this.WorldManager.EVENT_CINEMATIC_MODE_START);
    }

    stopCinematicMode ()
    {
        this.isCinematicMode = false;
        this.WorldManager.allowWorldClick();

        if (this.Player)
            this.Player.enableMovement();

        this.WorldManager.emit(this.WorldManager.EVENT_CINEMATIC_MODE_END);
    }

    startDialogMode (strCharacterToFollow = null, bTiltCamera = false)
    {
        this.isDialogMode = true;
        this.WorldManager.emit(this.WorldManager.EVENT_DIALOG_MODE_START, strCharacterToFollow, bTiltCamera);
    }

    stopDialogMode()
    {
        this.isDialogMode = false;
        this.WorldManager.emit(this.WorldManager.EVENT_DIALOG_MODE_END);
    }

    /**
        Get closest spawnable around a spot on the grid
        
        @param iX       X position of the spot on the grid
        @param iY       Y postiion of the spot on the grid
        @param strType  Type of content to look for. Can be "enemy" or "npc"
        @param maxDist  (Optional) Maximum distance to look for spawnables. If less than 0 than every object is considered.
                        This value is in grid coordinates (not ThreeJs world coordinates). Default is -1

        @return         Nearest spawnable found. Returns NULL if nothing could be found

    */
    getClosest(iX, iY, strType, fMaxDist = -1)
    {
        let spawnables = null;
        if (strType == this.TYPE_ENEMY)
        {
            spawnables = this.enemies;
        }
        else if (strType == this.TYPE_NPC)
        {
            spawnables = this.npcs;
        }

        let max = Math.pow(fMaxDist, 2);
        let closest = null;
        if (spawnables)
        {
            let closestDist = null;
            for (let i = 0; i < spawnables.length; i++)
            {
                let pos = spawnables[i].GridPos;
                let sqrDist = Math.pow(pos.x - iX, 2) + Math.pow(pos.y - iY, 2);

                if ((fMaxDist < 0 || sqrDist <= max) && (closestDist === null || closestDist > sqrDist))
                {
                    closest = spawnables[i];
                    closestDist = sqrDist;
                }
            }
        }

        return closest;
    }

    /*******************************************
    *   UPDATE LOOP
    *******************************************/
    update(iDeltaTime)
    {
        this.updateMapMoveIcon(iDeltaTime);
    }

    updateMapMoveIcon(iDeltaTime)
    {
        if (this.MapMoveIcon)
        {
            this.MapMoveIcon.update(iDeltaTime);
        }
    }

    updateDisplay (iX, iY)
    {

    }

    /*******************************************
    *   EVENTS
    *******************************************/
    onCameraTileChanged(params)
    {
        this.updateDisplay(params[1].x, params[1].y);
    }
}