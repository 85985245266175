export default class Mutator
{
    static mutate (strMutator, value)
    {
        if (Mutator[strMutator])
        {
            return Mutator[strMutator](value);
        }

        console.warn(`No such mutator: ${strMutator}.`);

        return value;

    }

    static parseHex (value)
    {
        return parseInt(value, 16);
    }

    static parseAsInt (value)
    {
        return parseInt(value);
    }

    static parseAsFloat (value)
    {
        return parseFloat(value);
    }
}