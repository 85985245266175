import EventEmitter from "eventemitter3";
import Loader from "./Loader.js";
import Library from "../Library.js";
import Utils from "../utils/Utils.js";
import DependencyContainer from "../utils/DependencyContainer.js";

export default class MutantsLoader extends EventEmitter
{
    constructor (strInitialURL, strBasePath)
    {
        super();
        this.strBasePath = strBasePath;
        this.strInitialURL = strInitialURL;

        this.dependencies = DependencyContainer.getInstance();

        this.createClosure();
    }

    createClosure ()
    {
        this.fctOnPreloadReady = this.onPreloadingReady.bind(this);
        this.fctAssetsLoaded = this.onAssetsLoaded.bind(this);
        this.fctOnStartLoadAsset = this.onStartLoadAsset.bind(this);
        this.fctOnEndLoadAsset = this.onEndLoadAsset.bind(this);
        this.fctOnStartLoadGroup = this.onStartLoadGroup.bind(this);
        this.fctOnEndLoadGroup = this.onEndLoadGroup.bind(this);
        this.fctOnAtlasEndLoadAsset = this.onAtlasEndLoadAsset.bind(this);
    }


    start ()
    {
        this.loadPreloadingAssets();
    }

    loadCharacterAtlases(objAtlases)
    {
        this.loader.once("end-load-atlas", (objAtlases) => this.emit("end-load-atlas", objAtlases));
        this.loader.loadCharacterAtlases(objAtlases);
    }

    //Loading can be quite long, so we start by loading some assets to display during the loading phase
    loadPreloadingAssets ()
    {
        this.loader = new Loader(this.strInitialURL, this.strBasePath);
        this.loader.once("load-complete", this.fctOnPreloadReady);
        this.loader.on("start-load-group", this.fctOnStartLoadGroup);
        this.loader.on("end-load-group", this.fctOnEndLoadGroup);
        this.loader.on("start-load-asset", this.fctOnStartLoadAsset);
        this.loader.on("end-load-asset", this.fctOnEndLoadAsset);

        if (this.ui)
            this.ui.showPreloading();

    }

    onPreloadingReady ()
    {
        let strAssetsToLoad = Library.getData("url").assetsToLoad;

        if (this.loader)
        {

            this.loader.off("start-load-group", this.fctOnStartLoadGroup);
            this.loader.off("end-load-group", this.fctOnEndLoadGroup);
            this.loader.off("start-load-asset", this.fctOnStartLoadAsset);
            this.loader.off("end-load-asset", this.fctOnEndLoadAsset);
        }

        this.loader = new Loader(strAssetsToLoad, this.strBasePath);
        this.loader.once("load-complete", this.fctAssetsLoaded);
        this.loader.on("start-load-group", this.fctOnStartLoadGroup);
        this.loader.on("end-load-group", this.fctOnEndLoadGroup);
        this.loader.on("start-load-asset", this.fctOnStartLoadAsset);
        this.loader.on("end-load-asset", this.fctOnEndLoadAsset);
        this.loader.on("end-load-atlas-asset", this.fctOnAtlasEndLoadAsset);

        this.dependencies.get("UIManager").showLoadingUI(this);


        if (this.ui)
        {
            this.ui.showLoading();

        }


    }

    onStartLoadAsset (index, total, pct, strGroup, strKey)
    {
        //console.log("onStartLoadAsset", index, total, pct, strGroup, strKey);

    }

    onEndLoadAsset (index, total, pct, strGroup, strKey)
    {
        if (this.ui)
            this.ui.onAssetLoaded(index, total, pct, strGroup, strKey);
    }

    onStartLoadGroup (index, total, pct, strType)
    {
        //console.log("onStartLoadGroup", strType, index, total, pct);
    }

    onEndLoadGroup (index, total, pct, strType)
    {
        if (this.ui)
            this.ui.onGroupLoaded(index, total, pct, strType);
    }

    onAssetsLoaded ()
    {
        this.loader.off("start-load-group", this.fctOnStartLoadGroup);
        this.loader.off("end-load-group", this.fctOnEndLoadGroup);
        this.loader.off("start-load-asset", this.fctOnStartLoadAsset);
        this.loader.off("end-load-asset", this.fctOnEndLoadAsset);

        this.emit("load-complete");
    }

    onAtlasEndLoadAsset(strKey)
    {
        this.emit("end-load-atlas-asset", strKey);
    }


	async loadOneThreeAsset (strPath, strGroup = "threeGroup", callback = null)
    {
        let strKey =  Utils.basename(strPath);
        let t = Library.get3DTextureFromGroup(strGroup, strKey);

        if (t)
        {
            return t;
        }

        let texture = await this.loader.loadThreeTexture(strPath);

        Library.add3DTextureToGroup (strGroup, strKey, texture);

        return texture;
    }

    async loadOnePixiAsset (strKey, strPath, callback)
    {

        let t = Library.getTexture(strKey);

        if (t)
        {
            return t;
        }

        let texture = await this.loader.loadPixiTexture(strKey, strPath, callback);


        return texture;
    }

    setUI (ui)
    {
        this.ui = ui;
    }

}