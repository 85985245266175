import BaseCheat from "./BaseCheat.js";

export default class Antistress extends BaseCheat
{
    constructor(dependencies)
    {
        super(dependencies);
    }

    get CommandUse() { return "antistress [start/stop]"; }
    get CommandDesc() { return "Starts or stops the antistress action"; }

    //---------------------------------------------------------
    //  DEPENDENCIES
    //---------------------------------------------------------
    get ItemManager() { return this.dependencies.get("ItemManager"); }
    get WorldManager() { return this.dependencies.get("WorldManager"); }
    //---------------------------------------------------------

    parseCommand(strCmd, arrArguments)
    {
        if (strCmd == "antistress" && arrArguments.length >= 1)
        {
            let action = arrArguments[0].toLowerCase();
            if (action == "start" && this.WorldManager.Player.startAntiStress())
            {
                this.log("Starting the antistress action");
                return;
            }
            else if (action == "stop" && this.WorldManager.Player.stopAntiStress())
            {
                this.log("Stopping the antistress action");
                return;
            }

            this.warn("Cannot apply the action " + arrArguments[0] + " to the antistress action");
        }
    }
}