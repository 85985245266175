import BaseCheat from "./BaseCheat.js";

export default class Dialog extends BaseCheat
{
    constructor(dependencies)
    {
        super(dependencies);
    }

    get CommandUse() { return "choice [\"speaker\"] [\"content\"] [\"choice1...4\"]"; }
    get CommandDesc() { return "Displays a choice dialog on screen. Must have between 2 and 4 choices to display"; }

    //---------------------------------------------------------
    //  DEPENDENCIES
    //---------------------------------------------------------
    get UIManager() { return this.dependencies.get("UIManager"); }
    //---------------------------------------------------------

    parseCommand(strCmd, arrArguments)
    {
        if (strCmd == "choice" && arrArguments.length >= 4)
        {
            let args = arrArguments[0];

            for (let i = 1; i < arrArguments.length; i++)
            {
                args += " " + arrArguments[i];
            }

            args = this.extractFromStr(args);
            if (args.length >= 4)
            {
                let choices = {};

                for (let i = 0; i < 4; i++)
                {
                    if (i + 2 < args.length)
                    {
                        choices["Choice#" + (i+1)] = args[i+2];
                    }
                    else
                    {
                        break;
                    }
                }
                this.UIManager.showChoiceDialog(
                    args[0], 
                    args[1], 
                    choices, 
                    null, 
                    function(pick)
                    {
                        this.log(pick + " was picked");
                    }
                    .bind(this)
                );
                this.log("Choice dialog was shown with speaker: " + args[0] + " and content: " + args[1]);
            }
            else
            {
                this.warn("Could not properly extract data from this command");
            }

        }
    }

    extractFromStr(str)
    {
        let re = /"(.*?)"/g;
        let result = [];
        let current;
        while (current = re.exec(str))
        {
            result.push(current.pop());
        }
        return result.length > 0 ? result : [str];
    }
}