import MapItem from "./MapItem.js";
import ItemCodes from "./codes/ItemCodes.js";

export default class KeyItem extends MapItem
{
    constructor(strId, objDefinition)
    {
        super(strId, objDefinition);
    }

    get InventoryPageType() { return ItemCodes.TYPE_EQUIPMENT; }
    get IsDroppable() { return false; }
}