import {gsap} from "gsap";
import { string2hex }  from '@pixi/utils'; 
import Character from "./Character.js";
import ContainerResponsive from "../responsive/ContainerResponsive.js";
import Display from "../../../utils/Display.js";
import GraphicsResponsive from "../responsive/GraphicsResponsive.js";
import Lerp from "../../../utils/Lerp.js";
import WindowGauge from "../gauges/WindowGauge.js";

export default class CharacterViewer  extends ContainerResponsive
{
    constructor ()
    {
        super();
    }

    get GAUGE_DISPLAY_TIME() { return 1; }
    get GAUGE_FILL_ANIMATION_TIME() { return 0.5; }
    get GAUGE_FILL_DELAY_TIME() { return 0.18; }
    get GAUGE_SLIDE_ANIMATION_TIME() { return 0.2; }

    get BgWidth() { return this.strWidth; }
    get BgHeight() { return this.strHeight; }
    get BgX() { return 0; }
    get BgY() { return 0; }

    get BackgroundColor() { return string2hex(this.UI.Values.modal.background.color);}
    get StrokeWidth() { return this.evaluate(this.valueFormula(this.UI.Values.modal.stroke.size), 0, 0, 0, 0);}
    get CornerRadius() { return this.evaluate(this.valueFormula(this.UI.Values.modal.corner.radius), 0, 0, 0, 0);}
    get StrokeColor()
    { 
        if (this.isHighlighted)
        {
            return 0xc6ff57;
        }
        else
        {
            return string2hex("color" in this.UI.Values.modal.stroke ? this.UI.Values.modal.stroke.color : "#000000");
        }
    }

    /*******************************************
    *   INITIALIZATION
    *******************************************/
    /**
        Parameters to pass to the init function:
        - ui:           UI section object where this component resides
        - rWidth:       Reponsive width equation for this container
        - rHeight       Reponsive height equation for this container
    */
    init(meta)
    {
        this.graphics = {};

        this.strWidth = meta.rWidth;
        this.strHeight = meta.rHeight;
        this.rollOverMode = false;
        this.type = "character";
        this.arrDisplayGauge = [];
        this.gaugeFillAnimations = [];

        return super.init(meta);
    }

    createClosure()
    {
        super.createClosure();
        this.fctUpdate = this.update.bind(this);
    }

    bindEvents()
    {
        super.bindEvents();
        this.UI.GameManager.on(this.UI.GameManager.EVENT_UPDATE, this.fctUpdate);
    }

    destroy(options)
    {
        this.UI.GameManager.off(this.UI.GameManager.EVENT_UPDATE, this.fctUpdate);

        for (let key in this.graphics)
        {
            this.removeChild(this.graphics[key]);
            this.graphics[key].destroy(options);
        }
        delete this.graphics;

        this.character.destroy(options);
        delete this.character;

        super.destroy(options);
    }

    /*******************************************
    *   BUILD
    *******************************************/
    build ()
    {
        super.build();

        this.buildBackground();
        this.buildCharacter();
    }

    clean()
    {
        for (let key in this.graphics)
        {
            this.graphics[key].clear();
        }

        super.clean();
    }

    buildBackground()
    {
        if (!this.graphics.background)
        {
            this.graphics.background = new GraphicsResponsive().init({"ui":this.UI});
            this.graphics.background.interactive = true;

            this.addChild(this.graphics.background);
        }

        let graphics = this.graphics.background;

        let width = this.evaluate(this.BgWidth, 0, 0, 0, 0, this);
        let height = this.evaluate(this.BgHeight, 0, 0, 0, 0, this);
        let x = this.BgX;
        let y = this.BgY;

        let bgColor = this.BackgroundColor;
        let strokeWidth = this.StrokeWidth;
        let strokeColor = this.StrokeColor;
        let cornerRadius = this.CornerRadius;

        if (strokeWidth > 0)
        {
            graphics.lineStyle({"color":strokeColor, "width":strokeWidth, "alignment":1});
        }

        graphics.beginFill(bgColor, 1);
        if (cornerRadius > 0)
        {
            graphics.drawRoundedRect(x, y, width, height, cornerRadius);
        }
        else
        {
            graphics.drawRect(x, y, width, height);
        }
        graphics.endFill();
    }

    buildCharacter()
    {
        if (!this.character)
        {
            this.character = new Character().init({
                "ui": this.UI,
                "build": this.UI.CharacterManager.getCharacterBuild(this.UI.CharacterManager.CurrentCharacter)
            });

            this.addChild(this.character);
        }  

        this.character.rHeight = [{on:"default", height: `${this.BgHeight} * 90%`}];
        this.character.rScaleX = [{on:"default", scale: `self.scale.y`}];
        this.character.rX = [{on:"default", x: `(${this.BgWidth}) / 2 - (width / 2)`}];
        this.character.rY = [{on:"default", y: `(${this.BgHeight}) / 2 - (height / 2)`}];

        this.character.layout(Display.getSize());
    }

    /*******************************************
    *   GAUGE
    *******************************************/
    displayGaugeFor (response)
    {
        let hungerMax = this.UI.CharacterManager.getCharacterMaxHunger();
        let energyMax = this.UI.CharacterManager.getCharacterMaxEnergy();

        let hungerFrom = response.hunger.from / hungerMax;
        let hungerTo = response.hunger.to / hungerMax;
        let energyFrom = response.energy.from / energyMax;
        let energyTo = response.energy.to / energyMax;

        let arrGauges = [];

        let strHeight = `(height / ${response.affected.length})`;

        for (let i = 0; i < response.affected.length; i++)
        {
            let strAffected = response.affected[i];
            let rWidth = `(${this.strWidth} * 0.75)`;
            let gauge;

            if (strAffected === "hunger")
            {
                gauge = new WindowGauge();
                gauge.init({
                    "ui": this.UI,
                    "type": gauge.FOOD,
                    rWidth,
                    "value": hungerFrom,
                    "bgColor": 0x4d4362
                });
            }
            else
            {
                gauge = new WindowGauge();
                gauge.init({
                    "ui": this.UI,
                    "type": gauge.ENERGY,
                    rWidth,
                    "value": energyFrom,
                    "bgColor": 0x4d4362
                });
            }

            gauge.rX = [{on: "default", x: "(" + this.BgWidth + ") / 2 - (" + rWidth + ") / 2"}];
            gauge.rY = [{on: "default", y: `${strHeight} + ((${strHeight} * 2) * ${i})` }];


            this.addChild(gauge);

            //There seems to have a problem with GSAP where the value gets reset to 0 before 
            //the animation starts. Instead we execute the fill animation manually in the update loop function
            let animTime = this.GAUGE_FILL_ANIMATION_TIME;
            let anim = {
                "gauge": gauge,
                "from": (strAffected === "hunger" ? hungerFrom : energyFrom),
                "to": (strAffected === "hunger" ? hungerTo : energyTo),
                "delay": this.GAUGE_FILL_DELAY_TIME,
                "timeLeft": animTime,
                "totalTime": animTime
            };
            this.gaugeFillAnimations.push(anim);

            gauge.alpha = 0.25;
            gauge.y -= gauge.height;
            gsap.to(gauge, {y: "+=" + gauge.height, alpha: 1, duration: this.GAUGE_SLIDE_ANIMATION_TIME, ease: "power1.out"})

            arrGauges.push(gauge);

        }

        setTimeout ( () => this.removeGauges(arrGauges), this.GAUGE_DISPLAY_TIME * 1000);
    }

    removeGauges (arrGauges)
    {
        for (let i = 0; i < arrGauges.length; i++)
        {
            let gauge = arrGauges[i];
            gsap.to(gauge, {y: "+=" + gauge.height, alpha:0, duration: 0.1, ease:"power1.in", onComplete: () => this.disposeGauge(gauge)});
        }
    }

    disposeGauge (gauge)
    {
        gauge.parent.removeChild(gauge);
        gauge.destroy({"children": true});
    }

    /*******************************************
    *   DRAG & ROLLOVER
    *******************************************/
    highlight()
    {
        if (!this.isHighlighted)
        {
            this.isHighlighted = true;
            this.build();
        }

    }

    unhighlight()
    {
        if (this.isHighlighted)
        {
            this.isHighlighted = false;
            this.build();
        }
    }

    /*******************************************
    *   UPDATE LOOP
    *******************************************/
    update(fDeltaTime)
    {
        this.updateAnimation(fDeltaTime);
    }

    updateAnimation(fDeltaTime)
    {
        for (let i = this.gaugeFillAnimations.length - 1; i >= 0; i--)
        {
            let anim = this.gaugeFillAnimations[i];
            if (anim.delay > 0)
            {
                anim.delay -= fDeltaTime;
            }

            if (anim.delay <= 0)
            {
                anim.timeLeft -= fDeltaTime;
            }

            if (anim.timeLeft > 0)
            {
                let t = 1 - (anim.timeLeft / anim.totalTime);
                t = t*t*t * (t * (6*t - 15) + 10);

                anim.gauge.Value = Lerp.lerp(anim.from, anim.to, t);
            }
            else
            {
                this.gaugeFillAnimations.splice(i, 1);
            }
        }
    }
}