import { Application } from '@pixi/app';
import { Container } from '@pixi/display';
import _ from "lodash";
import EventEmitter from "eventemitter3";
import BaseMinigame from "./minigames/BaseMinigame.js";
import BarnUI from "./barn/BarnUI.js";
import CharacterCreatorUI from "./creator/CharacterCreatorUI.js";
import CharacterLoadingUI from "./loading/CharacterLoadingUI.js";
import Constants from "../utils/Constants.js";
import Display from "../utils/Display.js";
import HostileUI from "./hostile/HostileUI.js";
import Library from "../Library.js";
import LoadingUI from "./loading/LoadingUI.js";
import SplashUI from "./splash/SplashUI.js";

export default class UIManager extends EventEmitter
{
    constructor (canvas)
    {
        super();
        UIManager.instance = this;

        this.canvas = canvas;
        this.isFullscreen = false;
        this.arrLayoutables = [];

    }

    get EVENT_OPEN_BACKPACK() { return "open-backpack"; }

    get MINIGAME_TYPE_GADGET() { return BaseMinigame.TYPE_GADGET; }
    get MINIGAME_TYPE_HACKING() { return BaseMinigame.TYPE_HACKING; }
    get MINIGAME_TYPE_UNLOCK() { return BaseMinigame.TYPE_UNLOCK; }

    //---------------------------------------------------------
    //  DEPENDENCIES
    //---------------------------------------------------------
    get GameManager() { return this.dependencies.get("GameManager"); }
    get ResponsiveManager() { return this.dependencies.get("ResponsiveManager"); }
    get WorldManager() { return this.dependencies.get("WorldManager"); }
    //---------------------------------------------------------

    get PixiApp() { return this.app; }
    get Notifications() { return (this.CurrentUI ? this.CurrentUI.Notifications : null); }
    get CurrentUI() { return this.currentUI; }
    get UIValues() { return this.uiValues; }
    get MinigameValues() { return this.minigameValues; }

    /*******************************************
    *   INITIALIZATION
    *******************************************/
    init(dependencies)
    {
        this.dependencies = dependencies;
        this.currentUI = null;
        
        this.createClosure();
        this.bindEvents();

        this.buildApp(this.canvas);
    }

    createClosure ()
    {
        this.fctCinematicModeStart = this.onCinematicModeStart.bind(this);
        this.fctCinematicModeEnd = this.onCinematicModeEnd.bind(this);
        this.fctDialogModeStart = this.onDialogModeStart.bind(this);
        this.fctDialogModeEnd = this.onDialogModeEnd.bind(this);
    }

    bindEvents()
    {
        this.WorldManager.on(this.WorldManager.EVENT_CINEMATIC_MODE_START, this.fctCinematicModeStart);
        this.WorldManager.on(this.WorldManager.EVENT_CINEMATIC_MODE_END, this.fctCinematicModeEnd);
        this.WorldManager.on(this.WorldManager.EVENT_DIALOG_MODE_START, this.onDialogModeStart);
        this.WorldManager.on(this.WorldManager.EVENT_DIALOG_MODE_END, this.onDialogModeEnd);
    }

    buildApp (canvas)
    {
        this.canvas = canvas;

        Display.addMeasurable({display: canvas, closure: m => ({width:m.scrollWidth, height:m.scrollHeight})});

        let {width, height} = Display.getSize();

        this.app = new Application
        (
            {
                view: this.canvas,
                resolution:  window.devicePixelRatio,
                transparent: true,
                backgroundColor: 0,
                width,
                height,
                antialias: true

            }
        );

        this.container = new Container();
        this.app.stage.addChild(this.container);

        this.ResponsiveManager.addResponsive(this, {}, (size) => this.layout());
        document.body.removeChild(document.getElementById("font-loader"));


        //document.addEventListener("keyup", this.onCheckMemory.bind(this));
    }

    onCheckMemory (e)
    {
        let code = e.code;
        console.log("onCheckMemory", e);

        if (code === "Space")
        {
            let ctx = this.app.renderer.context;

            console.log(ctx);

            let memory = ctx.getExtension('GMAN_webgl_memory');

            console.log("memory", memory);
        }
    }

    /*******************************************
    *   UI SCREEN MANAGEMENT
    *******************************************/

    purgeUI()
    {
        if (this.CurrentUI)
        {
            this.container.removeChild(this.CurrentUI);
            this.CurrentUI.destroy({"children": true});
        }
    }

    /*******************************************
    *   LOADING UI
    *******************************************/
    showLoadingUI (loader)
    {
        if (!this.currentUI || this.currentUI.Id != "loading")
        {
            this.purgeUI();

            this.currentUI = new LoadingUI();
            this.currentUI.init({
                "dependencies": this.dependencies
            });
            loader.setUI(this.CurrentUI);
            this.container.addChild(this.currentUI);

            if (this.screenTransition)
            {
                this.app.stage.removeChild(this.screenTransition);
                this.app.stage.addChild(this.screenTransition);
            }
        }
        return this.CurrentUI;
    }

    /*******************************************
    *   LOADING UI
    *******************************************/
    showCharacterLoadingUI(bShowCreationMessage = false)
    {
        if (!this.currentUI || this.currentUI.Id != "characterloading")
        {
            this.purgeUI();

            this.currentUI = new CharacterLoadingUI();
            this.currentUI.init({
                "dependencies": this.dependencies,
                "showCreationMessage": bShowCreationMessage
            });
            this.container.addChild(this.currentUI);

            if (this.screenTransition)
            {
                this.app.stage.removeChild(this.screenTransition);
                this.app.stage.addChild(this.screenTransition);
            }
        }
        return this.CurrentUI;
    }

    /*******************************************
    *   SPLASH SCREEN UI
    *******************************************/
    showSplashUI ()
    {
        if (!this.currentUI || this.currentUI.Id != "splash")
        {
            this.purgeUI();

            this.currentUI = new SplashUI();
            this.currentUI.init({
                "dependencies": this.dependencies
            });
            this.currentUI.on(this.currentUI.EVENT_LAUNCH_FOREST, this.onTempLaunchScene.bind(this, this.currentUI.EVENT_LAUNCH_FOREST));
            this.currentUI.on(this.currentUI.EVENT_LAUNCH_BARN, this.onTempLaunchScene.bind(this, this.currentUI.EVENT_LAUNCH_BARN));
            this.currentUI.on(this.currentUI.EVENT_LAUNCH_LABO, this.onTempLaunchScene.bind(this, this.currentUI.EVENT_LAUNCH_LABO));
            this.container.addChild(this.currentUI);

            if (this.screenTransition)
            {
                this.app.stage.removeChild(this.screenTransition);
                this.app.stage.addChild(this.screenTransition);
            }
        }
        return this.CurrentUI;
    }

    /*******************************************
    *   HOSTILE ENVIRONMENT UI
    *******************************************/
    showHostileUI ()
    {
        this.purgeUI();

        this.currentUI = new HostileUI();
        this.currentUI.init({
            "dependencies": this.dependencies
        });
        this.container.addChild(this.currentUI);

        if (this.screenTransition)
        {
            this.app.stage.removeChild(this.screenTransition);
            this.app.stage.addChild(this.screenTransition);
        }
        
        return this.CurrentUI;
    }

    /*******************************************
    *   BARN ENVIRONMENT UI
    *******************************************/
    showBarnUI ()
    {
        if (!this.currentUI || this.currentUI.Id != "barn")
        {
            this.purgeUI();

            this.currentUI = new BarnUI();
            this.currentUI.init({
                "dependencies": this.dependencies
            });
            this.container.addChild(this.currentUI);

            if (this.screenTransition)
            {
                this.app.stage.removeChild(this.screenTransition);
                this.app.stage.addChild(this.screenTransition);
            }
        }
        return this.CurrentUI;
    }

    /*******************************************
    *   BARN ENVIRONMENT UI
    *******************************************/
    showCharacterCreatorUI ()
    {
        if (!this.currentUI || this.currentUI.Id != "creator")
        {
            this.purgeUI();

            this.currentUI = new CharacterCreatorUI();
            this.currentUI.init({
                "dependencies": this.dependencies
            });
            this.container.addChild(this.currentUI);

            if (this.screenTransition)
            {
                this.app.stage.removeChild(this.screenTransition);
                this.app.stage.addChild(this.screenTransition);
            }
        }
        return this.CurrentUI;
    }

    /*******************************************
    *   MINIGAMES
    *******************************************/
    startMinigame(strType, iDifficulty, fctCallback = null)
    {
        return this.CurrentUI.startMinigame(strType, iDifficulty, fctCallback);
    }

    /*******************************************
    *   LAYOUT
    *******************************************/
    layout()
    {
        if (this.currentUI)
        {
            this.currentUI.layout(this.onResize());
        }
    }

    showOverlay()
    {
        if (this.CurrentUI)
        {
            return this.CurrentUI.showOverlay();
        }
        return false;
    }

    hideOverlay()
    {
        if (this.CurrentUI)
        {
            return this.CurrentUI.hideOverlay();
        }
        return false;
    }

    /*******************************************
    *   UTILITIES
    *******************************************/
    canWorldClick(x, y)
    {
        if (this.CurrentUI)
        {
            return this.CurrentUI.canWorldClick(x, y);
        }
        return true;
    }

    enableUI()
    {
        //@TODO: Implement this function when the UI has been reimported
    }

    disableUIExcept(arrExceptKey)
    {
        //@TODO: Implement this function when the UI has been reimported
    }

    showSceneTransition(fctCallback)
    {
        this.CurrentUI.showSceneTransition(fctCallback);
    }

    /*******************************************
    *   DIALOGS
    *******************************************/
    showDialog(strSpeaker, strContent, strAudioId = null, fctCloseCallback = null)
    {
        if (this.CurrentUI && this.CurrentUI.showDialog)
        {
            return this.CurrentUI.showDialog(strSpeaker, strContent, strAudioId, fctCloseCallback);
        }
        return false;
    }

    showChoiceDialog(strSpeaker, strContent, arrChoices, strAudioId = null, fctChoiceCallback = null, fctCloseCallback = null)
    {
        if (this.CurrentUI && this.CurrentUI.showDialog)
        {
            return this.CurrentUI.showChoiceDialog(strSpeaker, strContent, arrChoices, strAudioId, fctChoiceCallback, fctCloseCallback);
        }
        return false;
    }

    /*******************************************
    *   EVENTS
    *******************************************/
    onResize ()
    {
        let size = Display.getSize();

        let rr = Constants.ResponsiveRatio;

        this.app.renderer.resize(size.width, size.height);

        return size;
    }

    onTempLaunchScene (eventId)
    {
        this.emit(eventId)
    }

    onLoadComplete(objSettings)
    {
        this.uiValues = Library.getData("ui");
        this.minigameValues = Library.getData("minigames");
    }

    onCinematicModeStart()
    {
        if (this.CurrentUI)
        {
            this.CurrentUI.startCinematicMode();
        }
    }

    onCinematicModeEnd()
    {
        if (this.CurrentUI)
        {
            this.CurrentUI.stopCinematicMode();
        }
    }

    onDialogModeStart(strCharacterToFollow = null, bTiltCamera = false)
    {
        if (this.CurrentUI)
        {
            this.CurrentUI.startDialogMode(strCharacterToFollow, bTiltCamera);
        }
    }

    onDialogModeEnd()
    {
        if (this.CurrentUI)
        {
            this.CurrentUI.stopDialogMode();
        }
    }

    /*------------------------------------------
    --   @TODO: TO REORGANIZE                 
    ------------------------------------------*/
    showMutationWindow(iCharacter, objMutation, fctCallback = null)
    {
        //@TODO: Implement this function when the UI has been reimported
    }
    /*------------------------------------------*/
}