export default class DependencyContainer
{
    constructor()
    {
        if (DependencyContainer.instance)
        {
            throw("DependencyContainer should not be instantiate");
        }

        this.dependencies = {};

        DependencyContainer.instance = this;
    }

    static getInstance ()
    {
        return DependencyContainer.instance;
    }

    getCommonManagers ()
    {
        let wm = this.get("WorldManager");
        let gm = this.get("GameManager");
        let rm = this.get("ResponsiveManager");
        let uim = this.get("UIManager");
        let cm = this.get("CharacterManager");
        let am = this.get("AudioManager");
        let im = this.get("ItemManager");
        let sm = this.get("SaveManager");

        return {wm, gm,rm, uim, cm, am, im, sm};
    }

    add(strKey, obj)
    {
        if (strKey in this.dependencies)
        {
            console.warn("There is already a depency for", strKey, "Overwriting the previous object with a new one")
        }
        this.dependencies[strKey] = obj;
    }

    get (strKey)
    {
        if (this.dependencies[strKey])
        {
            return this.dependencies[strKey];
        }

        console.warn("Cannot find dependency", strKey);
        return null;
    }

    getAll()
    {
        return this.dependencies;
    }
}

let __dependencies__ = new DependencyContainer();